import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import React from 'react'
import PropTypes from 'prop-types'

/**
 * Wrapper component for places where Drag and Drop functionality is needed.
 * It ensures that only one instance of HTML5Backend is created and used.
 * @param {Object} props Component props
 * @param {React.ReactNode} props.children Child element
 * @returns {React.Component}
 */
const DragAndDrop = ({ children }) => {
  return <DndProvider backend={HTML5Backend}>{children}</DndProvider>
}

DragAndDrop.propTypes = {
  children: PropTypes.any,
}

export { DragAndDrop }
