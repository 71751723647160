/**
 * This function returns the items that have modified their positions
 * in the updated array compared to the original array
 * @param {Array} original The original items array used for comparison
 * @param {Array} updated The upated array
 * @returns {Array} Returns items that have modified positions
 */
export const findPendingItems = (original, updated) => {
  // Maps to keep track of indices
  const originalIndexMap = new Map()
  original.forEach((item, index) => {
    originalIndexMap.set(item._id, index)
  })

  const updatedIndexMap = new Map()
  updated.forEach((item, index) => {
    updatedIndexMap.set(item._id, index)
  })

  const affectedItems = []

  // Check for changes in position or new additions in the updated array
  updated.forEach((item, index) => {
    const originalIndex = originalIndexMap.get(item._id)
    if (originalIndex === undefined || originalIndex !== index) {
      affectedItems.push({ ...item, position: index + 1 })
    }
  })

  // Check for deletions and add them as if they still occupied a space
  let deletedPosition = updated.length
  original.forEach((item) => {
    if (!updatedIndexMap.has(item._id)) {
      if (deletedPosition < original.length) {
        affectedItems.push({
          ...item,
          position: deletedPosition + 1,
          deleted: true,
        })
        deletedPosition++
      }
    }
  })

  return affectedItems.sort((a, b) => a.position - b.position)
}
