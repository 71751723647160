import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

/** Components */
import LevelOfAttention from 'components/level-of-attention'
import ListSelection from 'components/list-selection'
import Button from 'components/button'
import EntitySelector from 'components/entity-selector'
import IconsShowOff from 'components/utils-icon-showcase'
import Section from 'components/section/index'

import SlackTestingModule from 'modules/slack/index'

/* Store */
import { useStore } from 'store'

/* Actions */
import { openModal, closeModal, closeAllModals } from 'components/modal/actions'

import { NOT_FOUND_ROUTE } from 'routes'
import { format } from 'date-fns'
import { entityStatus } from '@decision-sciences/qontrol-common'
import { RouteTester } from 'components/route-tester/index'

const { ENTITY_STATUS_LABEL } = entityStatus

const PlaygroundPage = () => {
  const navigate = useNavigate()
  const {
    dispatch,
    state: {
      global: {
        environment: { SHOW_PLAYGROUND_PAGE },
      },
    },
  } = useStore()

  const showPage = SHOW_PLAYGROUND_PAGE === 'on'

  useEffect(() => {
    if (!showPage) {
      navigate(NOT_FOUND_ROUTE, true)
    }
  }, [showPage])

  /** Describe components */
  const LevelOfAttentionShowOff = () => {
    const [options, setOptions] = useState({
      needs_immediate_attention: {
        from: 35,
        to: 100,
      },
      review_recommended: {
        from: 20,
        to: 35,
      },
      validated: {
        from: 0,
        to: 20,
      },
    })

    return (
      <>
        <h3>Level Of Attention</h3>
        <LevelOfAttention
          title="Account"
          options={options}
          setOptions={setOptions}
        ></LevelOfAttention>
      </>
    )
  }

  /** Select List Items */
  const ListSelectionShowOff = () => {
    /** Mock items */
    const availableItems = [
      { _id: 'a', name: 'A' },
      { _id: 'b', name: 'B' },
      { _id: 'c', name: 'C' },
      { _id: 'd', name: 'D' },
      { _id: 'e', name: 'E' },
      { _id: 'f', name: 'F' },
      { _id: 'g', name: 'G' },
      { _id: 'h', name: 'H' },
      { _id: 'i', name: 'I' },
      { _id: 'j', name: 'J' },
      { _id: 'k', name: 'K' },
      { _id: 'l', name: 'L' },
    ]

    const [selectedItems, setSelectedItems] = useState([])

    return (
      <>
        <h3>List Selecion</h3>
        <ListSelection
          availableItems={availableItems}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          idKey="_id"
          displayKey="name"
          title="Assign to users"
          availableListName="Users"
          selectedListName="Selected Users"
        />
      </>
    )
  }

  const ButtonShowOff = () => {
    const possibleProps = [
      {},
      { green: true },
      { red: true },
      { orange: true },
      { liliac: true },
      { secondaryGray: true },
      { secondary: true },
      { secondaryRed: true },
      { secondaryOrange: true },
      { secondaryGreen: true },
      { secondaryGrayEmpty: true },
      { secondaryLiliac: true },
    ]

    return (
      <>
        <h3>Buttons</h3>
        <div
          style={{
            padding: 20,
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            background: '#fefefe',
            boxShadow: '0 2px 4px rgb(0 0 0 / 15%)',
            borderRadius: 4,
          }}
        >
          {possibleProps.map((prop, index) => (
            <div
              key={index}
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                gridAutoFlow: 'row',
                gap: '5px 15px',
                placeItems: 'center center',
              }}
            >
              <Button {...prop} value={'Button'} />
              <Button disabled {...prop} value={'Button'} />
              <div>
                <code style={{ fontSize: 14 }}>
                  {Object.keys(prop).length ? Object.keys(prop) : 'default'}
                </code>
              </div>
              <div style={{ fontSize: 14, marginBottom: 10 }}>
                <code style={{ marginRight: 20 }}>
                  {Object.keys(prop).length ? Object.keys(prop) : 'default'}
                </code>
                <code>disabled</code>
              </div>
            </div>
          ))}
        </div>
      </>
    )
  }

  const ModalShowOff = () => {
    const openSecondModal = () => {
      const secondModalUid = openModal(dispatch, {
        children: <h2>This is content in modal #2</h2>,
        loading: false,
        button: (
          <Button
            value="Close all modals"
            onClick={() => {
              closeAllModals(dispatch)
            }}
          />
        ),
        buttonSecondary: (
          <Button
            value="Close"
            onClick={() => {
              closeModal(dispatch, secondModalUid)
            }}
            secondaryGray
          />
        ),
        heading: 'Modal #2',
      })
    }

    const openFirstModal = () => {
      const firstModalUid = openModal(dispatch, {
        children: <h2>First Modal</h2>,
        loading: false,
        button: (
          <Button value="Open another one" onClick={() => openSecondModal()} />
        ),
        buttonSecondary: (
          <Button
            value="Close"
            onClick={() => {
              closeModal(dispatch, firstModalUid)
            }}
            secondaryGray
          />
        ),
        heading: 'Modal #1',
      })
    }

    return (
      <>
        <h3>Opening modals programatically</h3>
        <Button value="Open first modal" onClick={() => openFirstModal()} />
      </>
    )
  }

  return (
    <React.Fragment>
      <h1>Playground Page</h1>
      <p>
        {`Only accessible if SHOW_PLAYGROUND_PAGE environemnt variable
        is set to 'on'`}
      </p>
      <RouteTester />
      <Section header="Slack Testing" isExpandable defaultExpanded={false}>
        <SlackTestingModule />
      </Section>
      <LevelOfAttentionShowOff />
      <ListSelectionShowOff />
      <ButtonShowOff />
      <ModalShowOff />
      <EntitySelectorShowOff />
      <IconsShowOff />
    </React.Fragment>
  )
}

export default PlaygroundPage

const EntitySelectorShowOff = () => {
  const [entitySelectorData, setEntitySelectorData] = useState([])
  const [entitySelectorLoading, setEntitySelectorLoading] = useState(false)
  const [fetchQuery, setFetchQuery] = useState({
    pageIndex: 0,
    pageSize: 20,
    sort: [],
    filters: {},
  })

  useEffect(() => {
    setFetchQuery((prevQuery) => ({ ...prevQuery, pageIndex: 0 }))
  }, [JSON.stringify(fetchQuery.filters)])

  useEffect(() => {
    const { pageSize, pageIndex } = fetchQuery
    setEntitySelectorLoading(true)
    const timeout = Math.floor(Math.random() * 2500)
    setTimeout(() => {
      setEntitySelectorData([
        ...BASE_ENTITY_SELECTOR_DATA.slice(
          pageIndex * pageSize,
          (pageIndex + 1) * pageSize
        ),
      ])
      setEntitySelectorLoading(false)
    }, timeout)
  }, [JSON.stringify(fetchQuery)])

  return (
    <>
      <h3>Entity Selector</h3>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          gap: 20,
          position: 'relative',
        }}
      >
        <div
          style={{
            background: '#fefefe',
            boxShadow: '0 2px 4px rgb(0 0 0 / 15%)',
            borderRadius: 4,
            flex: '1 1 auto',
          }}
        >
          <EntitySelector
            filters={[
              {
                fieldKey: 'search',
                label: 'Search Filter',
                placeholder: 'This should have a blue search icon',
                type: 'SEARCH',
              },
              {
                fieldKey: 'text',
                label: 'Text Filter',
                placeholder: 'Text goes here',
                type: 'TEXT',
              },
              {
                fieldKey: 'dropdown',
                label: 'Dropdown Filter',
                type: 'DROPDOWN',
                placeholder: 'Single filter',
                options: [
                  {
                    label: 'Option 1',
                    value: 'opt_1',
                  },
                  {
                    label: 'Option 2',
                    value: 'opt_2',
                  },
                  {
                    label: 'Option 3',
                    value: 'opt_3',
                  },
                ],
              },
              {
                fieldKey: 'dropdownMultiselect',
                label: 'Dropdown Multiselect Filter',
                type: 'DROPDOWN_MULTISELECT',
                placeholder: 'Multi filter',
                options: [
                  {
                    label: 'Option Multi 1',
                    value: 'opt_1',
                  },
                  {
                    label: 'Option Multi 2',
                    value: 'opt_2',
                  },
                  {
                    label: 'Option Multi 3',
                    value: 'opt_3',
                  },
                ],
              },
              {
                fieldKey: 'dropdownTreeStructure',
                label: 'Dropdown Tree Structure',
                type: 'DROPDOWN_TREE',
                placeholder: 'Tree dropdown',
                options: [
                  {
                    label: 'Option 1',
                    value: 'opt_1',
                  },
                  {
                    label: 'Option 2',
                    value: 'opt_2',
                  },
                  {
                    label: 'Option 3',
                    value: 'opt_3',
                    options: [
                      {
                        label: 'Option 3-1',
                        value: 'opt_3_1',
                      },
                      {
                        label: 'Option 3-2',
                        value: 'opt_3_2',
                      },
                    ],
                  },
                  {
                    label: 'Option 4',
                    value: 'opt_4',
                    options: [
                      {
                        label: 'Option 4-1',
                        value: 'opt_4_1',
                      },
                      {
                        label: 'Option 4-2',
                        value: 'opt_4_2',
                      },
                      {
                        label: 'Option 4-3',
                        value: 'opt_4_3',
                      },
                    ],
                  },
                ],
              },

              {
                fieldKey: 'textInitialValue',
                label: 'Text Filter Initial Value',
                type: 'TEXT',
                initialValue: 'I am initial value',
              },
            ]}
            columns={[
              {
                header: 'Name',
                accessorKey: 'name',
                textAlign: 'left',
                cellTextAlign: 'left',
              },
              {
                header: 'Date Added',
                accessorFn: (row) =>
                  row.createdAt &&
                  format(new Date(row.createdAt), 'MM/dd/yyyy'),
              },
              {
                header: 'Status - Sort disabled',
                id: 'status',
                cell: (cell) => {
                  const { active } = cell.row.original
                  return <span>{ENTITY_STATUS_LABEL[active]}</span>
                },
                accessorKey: 'active',
                disableSortBy: true,
              },
            ]}
            actions={
              <>
                <Button
                  value="Disabled when loading"
                  loading={entitySelectorLoading}
                  disabled={entitySelectorLoading}
                />
                <Button value="I do nothing" />
              </>
            }
            data={entitySelectorData}
            loading={entitySelectorLoading}
            fetchData={setFetchQuery}
            fetchQuery={fetchQuery}
            totalRows={BASE_ENTITY_SELECTOR_DATA.length}
          />
        </div>
        <div
          style={{
            position: 'sticky',
            top: 0,
            alignSelf: 'flex-start',
            padding: 20,
            background: '#fefefe',
            boxShadow: '0 2px 4px rgb(0 0 0 / 15%)',
            borderRadius: 4,
            flex: '1 1 auto',
            overflow: 'auto',
          }}
        >
          <h3>Stored data for fetch</h3>
          <pre>{JSON.stringify(fetchQuery, null, 2)}</pre>
        </div>
      </div>
    </>
  )
}

const BASE_ENTITY_SELECTOR_DATA = Array.from({ length: 135 }).map((_, i) => ({
  _id: `data_${i}`,
  name: `Data ${i}`,
  createdAt: new Date(),
  active: Math.random() > 0.5,
}))
