import PropTypes from 'prop-types'
import Modal from 'components/modal/index'
import { closeModal, openModal } from 'components/modal/actions'
import Button from 'components/button/index'
import { ReactComponent as WarningIcon } from 'assets/icon_warning.svg'
import { ReactComponent as CheckIcon } from 'assets/icon_checkmark.svg'
import './style.scss'

const SingleClientAlertConfirmation = ({
  visible,
  alerts = [],
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal
      contentSeparator={true}
      rightAlignButtons={true}
      opened={visible}
      heading={
        <>
          <span className="margin-right-auto">Alerts Not Linked or Copied</span>
          <WarningIcon />
        </>
      }
      button={
        <Button
          data-cy="single-client-alert-confirmation"
          compact={true}
          secondaryGreen={true}
          onClick={onConfirm}
          value={
            <div className="align-row center gap-8">
              <CheckIcon width={20} height={20} />
              <span>Confirm</span>
            </div>
          }
        />
      }
      buttonSecondary={
        <Button
          data-cy="single-client-alert-cancel"
          compact={true}
          secondaryGray={true}
          onClick={onCancel}
          value="Cancel"
        />
      }
      className="single-client-alert-confirmation"
    >
      <p>
        The following single-client alerts will not be included when linking or
        copying settings within the Alerts section:
      </p>
      <p className="align-column">
        {alerts.map((alert) => (
          <div key={alert._id}>{alert.name}</div>
        ))}
      </p>
      <p>
        In order for the link or copy action to be applied to the excluded
        alerts, the client/BUs need to be added in the settings of the alert(s)
        above.
      </p>
      <p>
        Click the Confirm button to continue with your action. Or click the
        Cancel button to close this modal without changes.
      </p>
    </Modal>
  )
}

export const openSingleClientAlertConfirmationModal = (
  dispatch,
  alerts,
  onConfirm,
  onCancel
) => {
  const modalUuid = openModal(dispatch, {
    contentSeparator: true,
    rightAlignButtons: true,
    heading: (
      <>
        <span className="margin-right-auto">Alerts Not Linked or Copied</span>
        <WarningIcon />
      </>
    ),
    children: (
      <>
        <p>
          The following single-client alerts will not be included when linking
          or copying settings within the Alerts section:
        </p>
        <p>
          <span className="general-label">{alerts.length} Alerts</span>
          <div className="alerts-container">
            {alerts.map((alert) => (
              <div key={alert._id} className="alert-item">
                {alert.name}
              </div>
            ))}
          </div>
        </p>
        <p>
          In order for the link or copy action to be applied to the excluded
          alerts, the client/BUs need to be added in the settings of the
          alert(s) above.
        </p>
        <p>
          Click the Confirm button to continue with your action. Or click the
          Cancel button to close this modal without changes.{' '}
        </p>
      </>
    ),
    button: (
      <Button
        data-cy="single-client-alert-confirmation"
        compact={true}
        secondaryGreen={true}
        onClick={() => {
          onConfirm()
          closeModal(dispatch, modalUuid)
        }}
        value={
          <div className="align-row center gap-8">
            <CheckIcon width={20} height={20} />
            <span>Confirm</span>
          </div>
        }
      />
    ),
    buttonSecondary: (
      <Button
        data-cy="single-client-alert-cancel"
        compact={true}
        secondaryGray={true}
        onClick={() => {
          onCancel()
          closeModal(dispatch, modalUuid)
        }}
        value="Cancel"
      />
    ),
    className: 'single-client-alert-confirmation',
  })
}

SingleClientAlertConfirmation.propTypes = {
  visible: PropTypes.bool,
  alerts: PropTypes.array,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export { SingleClientAlertConfirmation }
