import React from 'react'
import PropTypes from 'prop-types'
import ReactSlider from 'react-slider'
import { TEXTS } from './constants'

import './style.scss'

/**
 * Level Of Attention Component
 * @param props the props
 * @param {String} props.title title
 * @param {Object<Object>} props.options options state
 * @param {Array<Object>} props.setOptions set options state function
 * @param {Boolean} props.viewOnly view only
 * @returns {React.Component}
 */
const LevelOfAttention = ({ title, options, setOptions, viewOnly = false }) => {
  /**
   * Update 'Needs Immediate Attention' Slider and the affected ones
   * @param {Array} newValues tuple of new values
   */
  const onChangeNeedsImmediateAttention = ([newFrom, newTo]) => {
    const newOptions = { ...options }

    /** Update current slider */
    newOptions.needs_immediate_attention.from = newFrom
    newOptions.needs_immediate_attention.to = newTo

    /** Update affected sliders */
    newOptions.review_recommended.to = newFrom

    /** If the new lower bound is less than the previous slider's lower bound */
    if (newFrom < options.review_recommended.from) {
      newOptions.review_recommended.from = newFrom
      newOptions.validated.to = newFrom

      /** If the new lower bound is less than the lowest slider's lower bound */
      if (newFrom < options.validated.from) {
        newOptions.validated.from = newFrom
      }
    }

    setOptions(newOptions)
  }

  /**
   * Update 'Review Recommended' Slider and the affected ones
   * @param {Array} newValues tuple of new values
   */
  const onChangeReviewRecommended = ([newFrom, newTo]) => {
    const newOptions = { ...options }

    /** Update current slider */
    newOptions.review_recommended.from = newFrom
    newOptions.review_recommended.to = newTo

    /** Update affected sliders */
    newOptions.validated.to = newFrom
    newOptions.needs_immediate_attention.from = newTo

    /** If the new lower bound is less than the lowest slider's lower bound */
    if (newFrom < options.validated.from) {
      newOptions.validated.from = newFrom
    }

    /** If the new higher bound is more than the highest slider's upper bound */
    if (newTo > options.needs_immediate_attention.to) {
      newOptions.needs_immediate_attention.to = newTo
    }

    setOptions(newOptions)
  }

  /**
   * Update 'Validated' Slider and the affected ones
   * @param {Array} newValues tuple of new values
   */
  const onChangeValidated = ([newFrom, newTo]) => {
    const newOptions = { ...options }

    /** Update current slider */
    newOptions.validated.from = newFrom
    newOptions.validated.to = newTo

    /** Update affected sliders */
    options.review_recommended.from = newTo

    /** If the new upper bound exceeds the next one's upper range */
    if (newTo > options.review_recommended.to) {
      options.needs_immediate_attention.from = newTo
      options.review_recommended.to = newTo

      /** If the new upper bound exceeds the highets one's higher bound */
      if (newTo > options.needs_immediate_attention.to) {
        options.needs_immediate_attention.to = newTo
      }
    }

    setOptions(newOptions)
  }

  /**
   * Slider Group Item Component
   * @param {Object} props the props
   * @param {Object} props.sliderValues the props
   * @param {Object} props.texts texts to show
   * @param {Object} props.color color theme of item
   * @param {Object} props.onChange slider change handler
   * @returns {React.Component}
   */
  const Item = ({ sliderValues, texts, color, onChange }) => {
    const { from, to } = sliderValues
    const { title, description, icon } = texts

    return (
      <div className="level-of-attention__item" key={title}>
        <div className="level-of-attention__item-header">
          <img
            alt="Icon"
            src={icon}
            className="level-of-attention__item-header-icon"
          />
          <p className="level-of-attention__item-header-text general-label">
            {title}
          </p>
        </div>
        <div className={`flow__separator flow__separator--${color}`}></div>
        <p className="level-of-attention__item-info general-description">
          {description}
        </p>
        <div className="level-of-attention__item__action">
          <div className="level-of-attention__item__action-slider-group">
            <ReactSlider
              className="level-of-attention__slider"
              value={[from, to]}
              trackClassName={`level-of-attention__slider-track level-of-attention__slider-track-${color}`}
              thumbClassName={`level-of-attention__slider-thumb level-of-attention__slider-thumb-${color}`}
              onChange={onChange}
            />
            <div className="level-of-attention__slider-helper">
              {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((number) => (
                <p className="general-label" key={number}>{`${number}%${
                  number === 100 ? '+' : ''
                }`}</p>
              ))}
            </div>
          </div>
          <p className="level-of-attention__item__action-slider-value general-label">
            {from}% {to === 100 ? 'to more than ' : 'up to '}
            {to}%
          </p>
        </div>
      </div>
    )
  }

  Item.propTypes = {
    sliderValues: PropTypes.object,
    texts: PropTypes.object,
    color: PropTypes.string,
    onChange: PropTypes.funcion,
  }

  return (
    <div className="level-of-attention">
      <p className="level-of-attention-header general-label">{title}</p>

      {/* Need Immediate Attention */}
      {Item({
        sliderValues: options.needs_immediate_attention,
        texts: TEXTS.needs_immediate_attention,
        color: 'red',
        onChange: !viewOnly && onChangeNeedsImmediateAttention,
      })}

      {/* Review Recommended */}
      {Item({
        sliderValues: options.review_recommended,
        texts: TEXTS.review_recommended,
        color: 'yellow',
        onChange: !viewOnly && onChangeReviewRecommended,
      })}

      {/* Validated */}
      {Item({
        sliderValues: options.validated,
        texts: TEXTS.validated,
        color: 'green',
        onChange: !viewOnly && onChangeValidated,
      })}
    </div>
  )
}

LevelOfAttention.propTypes = {
  title: PropTypes.string,
  options: (props, propName, componentName) => {
    const prop = props[propName]

    try {
      const keys = Object.keys(prop)

      if (
        !keys.includes('needs_immediate_attention') ||
        !keys.includes('review_recommended') ||
        !keys.includes('validated')
      ) {
        return new Error(
          `Invalid prop '${propName}' supplied to '${componentName}'. ${propName} needs the following keys: 'needs_immediate_attention', 'review_recommended', 'validated'.`
        )
      }

      if (!Object.values(prop).every((obj) => 'from' in obj && 'to' in obj)) {
        return new Error(
          `Invalid prop '${propName}' supplied to '${componentName}'. ${propName} needs a 'from' and a 'to' child in each key.`
        )
      }
    } catch (_) {
      return new Error(
        `Invalid prop '${propName}' supplied to '${componentName}'. Verification failed`
      )
    }
  },
  setOptions: PropTypes.func,
  viewOnly: PropTypes.bool,
}

LevelOfAttention.displayName = 'LevelOfAttention'

export default LevelOfAttention
