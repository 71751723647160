import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Tooltip from 'components/tooltip'

import { ReactComponent as WarningSVG } from 'assets/icon_warning.svg'

import './style.scss'

/**
 * Simple warning icon
 * @param {Object} params React Params
 * @param {String|Node} params.message Message to display on hovering the icon
 * @param {Function} params.onClick Callback on clicking the icon
 * @param {String} params.className Extra classnames to give to the icon container
 */
const WarningIcon = ({ message, onClick, className, ...others }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const extraProps = { ...others }

  if (message) {
    extraProps.onMouseEnter = () => {
      setShowTooltip(true)
    }
    extraProps.onMouseLeave = () => {
      setShowTooltip(false)
    }
  }

  return (
    <>
      <Tooltip show={showTooltip} content={message} />
      <div
        data-testid="warning-icon"
        className={classnames('warning-icon', {
          [className]: className,
          'warning-icon--clickable': !!onClick,
        })}
      >
        <WarningSVG
          data-testid="warning-icon-svg"
          tabIndex="0"
          className="warning-icon__svg"
          onClick={onClick}
          {...extraProps}
        />
      </div>
    </>
  )
}

WarningIcon.propTypes = {
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  others: PropTypes.array,
}

export default WarningIcon
