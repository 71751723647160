import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Loader from 'components/loader'
import { ModalContext } from 'contexts/modal'
import { useFocusTrap } from 'components/utils/custom-hooks'
import { useOnClickOutside } from 'hooks/outside-click'
import Draggable from 'react-draggable'

import './style.scss'

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : <>{children}</>
ConditionalWrapper.propTypes = {
  condition: PropTypes.bool,
  wrapper: PropTypes.func,
  children: PropTypes.node,
}

/**
 * Component which renders a Modal overlay
 *
 * @param {ModalProps} props Component props
 * @returns {React.ReactElement}
 */
const Modal = ({
  children,
  opened,
  setOpened = () => {},
  className,
  heading,
  icon,
  button,
  buttonSecondary,
  buttonsContent,
  loading,
  rightAlignButtons = false,
  stickyHeader = false,
  contentSeparator = false,
  footerMessage,
  dark = false,
  isDraggable = false,
  dragHandle = '.modal__heading',
  showOverlay = true,
  isContentPadded = true,
  dataTestId,
}) => {
  const modalRef = useRef()
  useEffect(() => {
    if (opened) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [opened])

  useFocusTrap(modalRef.current)

  useOnClickOutside(modalRef, () => setOpened(null))

  const draggableWrapper = (children) => (
    <Draggable bounds="body" handle={dragHandle}>
      {children}
    </Draggable>
  )

  return (
    <div
      data-testid={dataTestId || 'modal'}
      data-cy={dataTestId}
      className={cx('modal', className, {
        'modal--opened': opened,
      })}
    >
      {showOverlay && <div className="modal__overflow"></div>}
      <ModalContext.Provider value={{ modalRef }}>
        <ConditionalWrapper condition={isDraggable} wrapper={draggableWrapper}>
          <div
            ref={modalRef}
            className={cx('modal__content', { 'modal__content--dark': dark })}
          >
            {heading && (
              <div
                className={cx('modal__heading', {
                  'modal__heading--sticky': stickyHeader,
                })}
              >
                {heading}
                {icon && <div className="modal__icon">{icon}</div>}
              </div>
            )}

            <div
              className={cx('modal__children', {
                modal__children__padded: isContentPadded,
                'modal__children--content-separator': contentSeparator,
                'modal__children--content-separator--black':
                  contentSeparator && dark,
              })}
            >
              {children}
            </div>

            {button || buttonSecondary || buttonsContent ? (
              <div
                className={cx('modal__buttons', {
                  'modal__buttons--align-right': rightAlignButtons,
                })}
              >
                {footerMessage ? footerMessage : null}
                {button ? button : null}
                {buttonSecondary ? buttonSecondary : null}
                {buttonsContent ? buttonsContent : null}
              </div>
            ) : null}
          </div>
        </ConditionalWrapper>
        {loading && <Loader />}
      </ModalContext.Provider>
    </div>
  )
}

Modal.propTypes = {
  children: PropTypes.node,
  opened: PropTypes.any,
  className: PropTypes.string,
  heading: PropTypes.any,
  icon: PropTypes.any,
  button: PropTypes.node,
  buttonSecondary: PropTypes.node,
  buttonsContent: PropTypes.any,
  loading: PropTypes.bool,
  rightAlignButtons: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  contentSeparator: PropTypes.bool,
  footerMessage: PropTypes.node,
  setOpened: PropTypes.func,
  dark: PropTypes.bool,
  isDraggable: PropTypes.bool,
  dragHandle: PropTypes.string,
  showOverlay: PropTypes.bool,
  isContentPadded: PropTypes.bool,
  dataTestId: PropTypes.string,
}

export default Modal

/**
 * @typedef {Object} ModalProps
 * @property {string} [uid] Unique identifier. Used to identify modals opened programatically.
 * @property {any} children Child nodes
 * @property {boolean} opened Flag to toggle modal display
 * @property {Function} setOpened set the modal open/closed
 * @property {string} [className] Flag to toggle modal display
 * @property {any} [heading] Modal title
 * @property {any} [icon] Icon element to display next to the title
 * @property {any} [button] Action button
 * @property {any} [buttonSecondary] Secondary action button
 * @property {any} [buttonsContent] Component to incorporate multiple action buttons and/or other elements in the modal footer
 * @property {boolean} loading Loading flag
 * @property {boolean} [rightAlignButtons = false] Flag to align buttons to the right side of the footer. Default is left alignment.
 * @property {boolean} [stickyHeader = false] Flag to make the header sticky.
 * @property {node} [footerMessage] Message to be displayed on the footer
 * @property {boolean} dark Dark flag to have the modal with a dark background
 * @property {boolean} isDraggable Flag to disable dragging the modal
 * @property {string} dragHandle Selector for the drag handle
 * @property {boolean} showOverlay Flag to show/hide the overlay
 * @property {boolean} isContentPadded Flag to add padding to the content
 * @property {string} dataTestId id for testing purposes
 */
