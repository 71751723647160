import Api from 'easy-fetch-api'

/**
 * Gets all campaigns for a specific client
 * @param {*} companyId Company Id
 * @param {Object} signal - abort signal
 */
export const getCampaigns = (companyId, signal) =>
  new Promise((resolve, reject) => {
    Api.get({
      url: `/api/linked-in/client-campaigns/${companyId}`,
      signal,
    }).then((res) => {
      if (!res || res.error) {
        reject(res?.error)
      } else {
        resolve(res.data)
      }
    })
  })

/**
 * Gets all ads for a specific campaign and adset
 * @param {String} companyId Company Id
 * @param {String} [campaignId] Campaign Id
 * @param {Object} signal - abort signal
 */
export const getAds = (companyId, signal, campaignId, accountId) =>
  new Promise((resolve, reject) => {
    Api.get({
      url: `/api/linked-in/client-ads/${companyId}/${campaignId}/${accountId}`,
      signal,
    }).then((res) => {
      if (!res || res.error) {
        reject(res?.error)
      } else {
        resolve(res.data)
      }
    })
  })
