/** Prevent input event if the string contains any non-numeric character */
export const filterInputNumbers = (ev) =>
  ev.key.match(/[^\d.]/g) && ev.preventDefault()

// Allow just integer number
export const filterInputOnlyNumber = (ev) =>
  ev.key.match(/[^\d]/g) && ev.preventDefault()

// Allow negative number
export const filterNegativeNumber = (ev) =>
  ev.key.match(/[^-?\d]/g) && ev.preventDefault()
