import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { useOnClickOutside } from 'hooks/outside-click'
import { calculateListPosition } from 'components/utils/list-position'

import blueArrowIcon from 'assets/icon_arrow_blue.svg'

import ScssConstants from 'styles/shared.module.scss'
import './styles.scss'

/**
 * Tree Dropdown component
 * @param {Object} props the props
 * @param {Function} props.className extra class name
 * @param {Boolean} props.disabled the dropdown is disabled
 * @param {Function} props.onChange the handler to call on value change
 * @param {Object} props.options object of type { "option value": "Option label" }
 * @param {Object} props.selected the object of the selected option
 * @param {Boolean} props.forceOpenUpwards force the dropdown to open upwards
 * @param {String} [props.displayText] Display text
 */
const DropdownTree = ({
  className,
  disabled = false,
  onChange,
  options,
  selected,
  forceOpenUpwards = false,
  displayText,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [optionsDisplayedUpwards, setOptionsDisplayedUpwards] = useState(false)
  const dropdownRef = useRef()
  const [treeOpen, setTreeOpen] = useState('')

  useOnClickOutside(
    dropdownRef,
    () => dropdownOpen && setDropdownOpen(false) && setTreeOpen('')
  )

  const toggleDropdown = (option) => {
    if (disabled) {
      return
    }

    if (option) {
      const hasChildren = option.options?.length > 0
      if (hasChildren) {
        setTreeOpen(treeOpen === option.value ? '' : option.value)
        return
      }
      onChange(option)
    }

    setOptionsDisplayedUpwards(
      forceOpenUpwards
        ? true
        : calculateListPosition(
            dropdownOpen,
            dropdownRef,
            ScssConstants.dropdownHeight
          )
    )
    setDropdownOpen(!dropdownOpen)
  }

  return (
    <div
      className={cx('dropdown-tree__wrapper', {
        [className]: className,
      })}
      ref={dropdownRef}
      tabIndex={0}
    >
      <div
        className={cx('dropdown-tree', {
          'dropdown-tree--disabled': disabled,
        })}
      >
        <div
          className="dropdown-tree__label"
          role="button"
          onClick={() => toggleDropdown()}
        >
          <div className="dropdown-tree__text">
            {displayText || selected?.label || selected?.value || selected}
          </div>
          <img
            className={cx('dropdown-tree__icon', {
              'dropdown-tree__icon--up': forceOpenUpwards
                ? forceOpenUpwards
                : dropdownOpen,
            })}
            src={blueArrowIcon}
            alt="open table"
          />
        </div>
        {dropdownOpen && (
          <div
            className={cx('dropdown-tree__options', {
              'dropdown-tree__options--up': optionsDisplayedUpwards,
            })}
          >
            {options.map((option) => {
              const treeOpened = treeOpen === option.value
              const hasChildren = option.options?.length > 0
              return (
                <div
                  key={option.value}
                  className={cx('dropdown-tree__option', {
                    'dropdown-tree__option--opened': treeOpened,
                  })}
                  style={{ color: option.color }}
                  onClick={() => toggleDropdown(option)}
                >
                  <div className="dropdown-tree__option__label">
                    <div className="dropdown-tree__option__text">
                      {option.label}
                    </div>
                    {hasChildren && (
                      <img
                        className={cx('dropdown-tree__option__icon', {
                          'dropdown-tree__option__icon--down': treeOpened,
                        })}
                        src={blueArrowIcon}
                        alt="open table"
                      />
                    )}
                  </div>

                  {option.options?.map((child) => {
                    if (treeOpened) {
                      return (
                        <div
                          key={child.value}
                          className="dropdown-tree__option"
                          onClick={() => toggleDropdown(child)}
                        >
                          <div className="dropdown-tree__option__label">
                            <div className="dropdown-tree__option__text">
                              {child.label}
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

DropdownTree.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  selected: PropTypes.object,
  forceOpenUpwards: PropTypes.bool,
  displayText: PropTypes.string,
}

export default DropdownTree
