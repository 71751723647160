export const getFiscalErrors = (reportingConfig) => {
  const { initialFiscalPeriod } = reportingConfig?.fiscalPeriod || {}
  if (
    initialFiscalPeriod &&
    Object.entries(initialFiscalPeriod).some(
      ([key, value]) =>
        FISCAL_DROPDOWNS.includes(key) && value !== '' && value !== null
    )
  ) {
    const missingKeysObject = FISCAL_DROPDOWNS.reduce((result, key) => {
      // need this verification because the isEmpty returns true if the value is 0.
      if (
        initialFiscalPeriod[key] === null ||
        initialFiscalPeriod[key] === undefined ||
        initialFiscalPeriod[key] === ''
      ) {
        result[key] = 'This field is required'
      }
      return result
    }, {})

    if (Object.keys(missingKeysObject).length > 0) {
      return [false, missingKeysObject]
    } else {
      return [true, {}]
    }
  } else {
    return [true, {}]
  }
}

export const FISCAL_DROPDOWNS = [
  'fiscalYear',
  'fiscalQuarter',
  'fiscalQuarterStartYear',
  'fiscalQuarterStartMonth',
]

export const GLOBAL_REPORTS_INDUSTRIES = {
  eCommerce: [
    'Footwear and Apparel',
    'Home Goods and Lifestyle',
    'Grocery and Beverages',
    'Travel and Leisure',
  ].sort(),
  B2B: [
    'Marketing and Communication',
    'Payment and Financial Services',
    'Software and Collaboration Services',
    'Legal and Professional Services',
    'Entertainment',
  ].sort(),
  FETCH: [
    'Banking and Financial Services',
    'Health and Wellness',
    'Education and Learning',
    'Technology and Digital Services',
    'Legal and Professional Services',
  ].sort(),
}
