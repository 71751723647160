import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { format as formatDate } from 'date-fns'

/* Components */
import Button from 'components/button'
import Modal from 'components/modal/index'
import Table from 'components/table/beta'
import Tooltip from 'components/tooltip/index'
import LineDivider from 'components/line-divider/index'
import RoundRectangleButton from 'components/round-rectangle-button'
import WarningIcon from 'components/warning-icon'
import BudgetFilters from 'modules/companies/subsections/budget-pacing/components/budget-pacing-table/budget-filters'
import { FEATURE_NOT_IMPLEMENTED_TOOLTIP } from 'components/utils/tooltip'

/* Utils */
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'

/* Constants */

/* Icons */
import { ReactComponent as IconFilter } from 'assets/filters.svg'
import { ReactComponent as EditIcon } from 'assets/icon_edit.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'
import { ReactComponent as DownloadIcon } from 'assets/icon_download.svg'
import { utils, budgetPacing } from '@decision-sciences/qontrol-common'
import { filterBudgetPacing } from '../utils'

const { isEmpty } = utils.object
const { formatValue } = utils.string
const { BUDGET_PACING_KPI_TYPE, BUDGET_PACING_DATE_GRANULARITY } = budgetPacing
const INITIAL_FILTERS = {
  dateGranularity: Object.values(BUDGET_PACING_DATE_GRANULARITY),
}

/**
 * Renders the budget pacing table
 * @param {Object} props
 * @param {Boolean} props.isViewMode
 * @param {Array} props.budgets - Campaign budget pacing settings
 * @param {Object} props.errors -  - Object with errors for budget
 * @param {Function} props.onDeleteClicked - Method to be called when the delete button is clicked
 * @param {Function} props.onEditClicked -  Method to be called when the edit button is clicked
 * @param {Function} props.onDuplicateClicked -  Method to be called when the duplicate button is clicked
 * @param {Boolean} props.disabledActions -  A flag that tells us when to disable table's actions
 */
const BudgetPacingTable = ({
  isViewMode,
  budgets,
  errors,
  onDeleteClicked,
  onEditClicked,
  onDuplicateClicked,
  disabledActions,
}) => {
  const [openFilters, setOpenFilters] = useState(false)
  const [filter, setFilter] = useState({ ...INITIAL_FILTERS })
  const [filtersApplied, setFiltersApplied] = useState(false)
  const [displayedBudgets, setDisplayedBudgets] = useState(budgets)
  const [budgetIdToBeDeleted, setBudgetIdToBeDeleted] = useState('')
  const [roadMapTooltip, setRoadMapTooltip] = useState(false)

  const hasEditAccess = useAccess({
    feature: PERMISSIONS.BUDGET_PACING,
    type: PERMISSION_TYPES.EDIT,
  })

  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.BUDGET_PACING,
    type: PERMISSION_TYPES.DELETE,
  })

  const columns = [
    {
      header: '',
      accessorKey: 'edit-budget',
      size: 40,
      minSize: 40,
      maxSize: 70,
      textAlign: 'center',
      cellTextAlign: 'center',
      cell: (cell) => {
        if (isViewMode && !hasEditAccess) {
          return null
        }
        return (
          <EditIcon
            disabled={disabledActions}
            className={cx('cursor--pointer', {
              'filter-disabled': disabledActions,
            })}
            onClick={() => !disabledActions && onEditClicked(cell.row.original)}
          />
        )
      },
    },
    {
      header: '',
      id: 'duplicate',
      cell: (cell) => {
        if (isViewMode && !hasEditAccess) {
          return null
        }
        return (
          <div className="table__actions">
            <div
              className={cx('table__duplicate', {
                'filter-disabled': disabledActions,
              })}
              onClick={() =>
                !disabledActions && onDuplicateClicked(cell.row.original)
              }
            />
          </div>
        )
      },
      size: 20,
      minSize: 30,
      maxSize: 30,
    },
    {
      header: 'Budget Segment',
      accessorKey: 'budgetSegment',
      size: 300,
    },
    {
      header: 'Amount',
      accessorKey: 'budgetAmount',
      cell: (cell) => `$${formatValue(cell.row.original.budgetAmount)}`,
    },
    {
      header: 'Start Date',
      accessorKey: 'startDate',
      cell: (cell) =>
        formatDate(new Date(cell.row.original.startDate), 'M/d/yyyy'),
      sortingFn: dateSort.name,
    },
    {
      header: 'End Date',
      accessorKey: 'endDate',
      cell: (cell) =>
        formatDate(new Date(cell.row.original.endDate), 'M/d/yyyy'),
      sortingFn: dateSort.name,
    },
    {
      header: 'Date Gran.',
      accessorKey: 'dateGranularity',
      sortingFn: dateGranularitySort.name,
    },
    {
      header: 'KPI Type',
      accessorKey: 'kpiType',
      cell: (cell) => BUDGET_PACING_KPI_TYPE[cell.row.original.kpiType].label,
    },
    {
      header: 'KPI Target',
      accessorKey: 'kpiTarget',
      cell: (cell) => `${formatValue(cell.row.original.kpiTarget)}`,
    },
    {
      header: 'Conv.',
      accessorKey: 'associatedConversions',
      size: 90,
      cell: (cell) => cell.row.original.associatedConversions.length,
    },
    {
      header: '',
      accessorKey: 'delete-budget',
      textAlign: 'center',
      cellTextAlign: 'center',
      size: 50,
      minSize: 50,
      maxSize: 80,
      cell: (cell) => {
        if (isViewMode && !hasDeleteAccess) {
          return null
        }
        return (
          <DeleteIcon
            disabled={disabledActions}
            onClick={() =>
              !disabledActions && setBudgetIdToBeDeleted(cell.row.original._id)
            }
            className={cx('fill-red cursor--pointer', {
              'filter-disabled': disabledActions,
            })}
          />
        )
      },
    },
  ]

  const onApplyFilters = (newFilterData) => {
    const data = filterBudgetPacing(budgets, newFilterData)
    setDisplayedBudgets(data)
    setFiltersApplied(data.length !== budgets.length)
  }

  const onClearFilters = () => {
    setFiltersApplied(false)
    setDisplayedBudgets(budgets)
    setFilter({ ...INITIAL_FILTERS })
  }

  const checkTableRow = (row) => {
    if (isEmpty(errors)) {
      return false
    } else {
      const rowId = row.original.local__uuid
      return !isEmpty(errors[rowId])
    }
  }

  return (
    <>
      <div className="space-between padding-x-30 padding-y-10">
        <h3 className="generic-heading generic-heading--light generic-heading--no-margin padding-y-10">
          Added Budget Pacing Settings
        </h3>
        <div
          className="margin-left-16 align-row"
          onClick={() =>
            !disabledActions ? setOpenFilters(!openFilters) : null
          }
        >
          <div
            onMouseEnter={() => setRoadMapTooltip(true)}
            onMouseLeave={() => setRoadMapTooltip(false)}
          >
            <Tooltip
              show={roadMapTooltip}
              content={FEATURE_NOT_IMPLEMENTED_TOOLTIP}
            />
            <RoundRectangleButton
              contentRender={() => <DownloadIcon />}
              showIconUpsideDown={true}
              className="filter-disabled"
              contentClassName="keywords-table-title-action-icon-reversed"
            />
          </div>
          <RoundRectangleButton
            className={cx(
              'margin-left-16',
              {
                'filter-active': openFilters || filtersApplied,
              },
              { 'filter-disabled': disabledActions }
            )}
            contentRender={() => (
              <IconFilter
                className={
                  openFilters || filtersApplied
                    ? 'fill-white'
                    : 'fill-light-blue'
                }
              />
            )}
            contentClassName="keywords-table-title-action-icon"
          />
        </div>
      </div>
      <LineDivider />
      {openFilters ? (
        <BudgetFilters
          filter={filter}
          setFilter={setFilter}
          onApplyFilters={onApplyFilters}
          onClearFilters={onClearFilters}
        />
      ) : null}

      <div className="padding-x-30 padding-y-10">
        <Table
          customSortTypes={[dateGranularitySort, dateSort]}
          columns={columns}
          data={displayedBudgets}
          className={cx({
            'budget-table-overflow': displayedBudgets.length > 10,
          })}
          hasRowError={checkTableRow}
        />
      </div>
      {budgetIdToBeDeleted && (
        <Modal
          icon={<WarningIcon />}
          rightAlignButtons
          opened={!!budgetIdToBeDeleted}
          contentSeparator
          heading="Delete Budget Pacing Settings"
          button={
            <Button
              value="Confirm"
              green
              onClick={() => {
                onDeleteClicked(budgetIdToBeDeleted)
                setBudgetIdToBeDeleted('')
              }}
            />
          }
          buttonSecondary={
            <Button
              value={'Cancel'}
              onClick={() => setBudgetIdToBeDeleted(null)}
              secondaryGray
            />
          }
        >
          <>
            <div className="margin-bottom-16">
              You are about to delete an added budget pacing setting.
            </div>
            <div>
              Click the Confirm button to continue with deleting the setting.
              This action cannot be undone.
            </div>
          </>
        </Modal>
      )}
    </>
  )
}

const DATE_GRANULARITY_SORT_ORDER = {
  [BUDGET_PACING_DATE_GRANULARITY.DAILY]: 0,
  [BUDGET_PACING_DATE_GRANULARITY.WEEKLY]: 1,
  [BUDGET_PACING_DATE_GRANULARITY.MONTHLY]: 2,
  [BUDGET_PACING_DATE_GRANULARITY.QUARTERLY]: 3,
  [BUDGET_PACING_DATE_GRANULARITY.YEARLY]: 4,
  [BUDGET_PACING_DATE_GRANULARITY.CUSTOM]: 5,
}

const dateGranularitySort = {
  name: 'dateGranularity',
  implementation: (rowA, rowB, columnId) => {
    const granA = DATE_GRANULARITY_SORT_ORDER[rowA.getValue(columnId)]
    const granB = DATE_GRANULARITY_SORT_ORDER[rowB.getValue(columnId)]
    if (granA < granB) {
      return -1
    }
    return 1
  },
}
const dateSort = {
  name: 'budgetPacingDateSort',
  implementation: (rowA, rowB, columnId) => {
    const dateA = formatDate(new Date(rowA.getValue(columnId)), 'yyyy-MM-dd')
    const dateB = formatDate(new Date(rowB.getValue(columnId)), 'yyyy-MM-dd')
    if (dateA < dateB) {
      return -1
    }
    return 1
  },
}

export default BudgetPacingTable

BudgetPacingTable.propTypes = {
  isViewMode: PropTypes.bool.isRequired,
  budgets: PropTypes.array.isRequired,
  onDeleteClicked: PropTypes.func.isRequired,
  onEditClicked: PropTypes.func.isRequired,
  onDuplicateClicked: PropTypes.func.isRequired,
  disabledActions: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
}
