import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/* Utils */
import { v4 as uuidv4 } from 'uuid'

/* Store */
import { useStore } from 'store'
import { uploadProfileImage } from 'modules/users/actions'
import { showErrorMessage } from 'modules/notifications/actions'

/* Components */
import Modal from 'components/modal'
import Button from 'components/button'
import useSession from 'modules/session'
import FileUpload from 'components/file-upload/file-upload'
import ItemRow from 'components/item-row'
import Loader from 'components/loader'

/* Icons */
import avatarIcon from 'assets/avatar.svg'
import plusIcon from 'assets/icon_plus_blue.svg'

/* Styles */
import ScssConstants from 'styles/shared.module.scss'

const DEFAULT_AVATAR = avatarIcon

const UserAvatar = ({ editable }) => {
  const { dispatch } = useStore()
  const [, userData] = useSession()
  const [imageHash, setImageHash] = useState(uuidv4())
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [avatarState, setAvatarState] = useState(
    userData.profileImg ? AVATAR_STATE.LOADING : AVATAR_STATE.LOADED
  )
  const [fileLoaded, setFileLoaded] = useState(false)
  const [error, setError] = useState(false)
  const profileImage = userData.profileImg
    ? '/api/users/me/avatar'
    : DEFAULT_AVATAR

  const avatarSizePixels = useMemo(
    () => parseInt(ScssConstants.avatarSize) || 80,
    [ScssConstants.avatarSize]
  )

  const onSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    uploadProfileImage(fileLoaded, userData, dispatch)
      .then(() => {
        setOpenModal(false)
        setImageHash(uuidv4())
      })
      .catch((error) => {
        console.error('error updating user', error)
        showErrorMessage(
          error || 'There was an issue uploading the file',
          dispatch
        )
      })
      .finally(() => setLoading(false))
  }

  /** Handles all the logic that needs to happen when the cancel button is clicked */
  const onCancel = () => {
    setFileLoaded(null)
    setOpenModal(false)
    setError(false)
  }

  const isAvatarLoading = () => avatarState === AVATAR_STATE.LOADING

  return (
    <div className="my-account__avatar align-row">
      <div className="my-account__avatar__image">
        {isAvatarLoading() && <Loader size={avatarSizePixels} />}
        <img
          onLoad={() =>
            isAvatarLoading() && setAvatarState(AVATAR_STATE.LOADED)
          }
          onError={() => setAvatarState(AVATAR_STATE.ERROR)}
          className={cx('avatar', {
            'avatar--loading': isAvatarLoading(),
          })}
          src={
            avatarState === AVATAR_STATE.ERROR
              ? DEFAULT_AVATAR
              : `${profileImage}?${imageHash}`
          }
          alt="avatar"
        />
        {editable && (
          <div
            className="my-account__add-image align-center"
            onClick={() => setOpenModal(true)}
          >
            <img src={plusIcon} alt="add" />
          </div>
        )}
      </div>
      <div className="my-account__avatar__desc align-column">
        <div className="my-account__avatar__name">{userData.name}</div>
        <div className="my-account__avatar__email">{userData.email}</div>
      </div>

      {openModal && (
        <Modal opened={openModal} className="modal-upload-image">
          <div>
            <div className="heading-secondary">Update Profile Picture</div>
            {error && <div className="error">{error}</div>}
            {!fileLoaded ? (
              <FileUpload
                maxFileSize={5 * 1024 * 1024}
                renderOnDefaultState={
                  <div>
                    <div>Drag & Drop here to upload an image</div>
                    <div>or</div>
                    <div>Click to select a file</div>
                  </div>
                }
                onFilesSelected={(files) => setFileLoaded(files[0])}
                allowMultiple={false}
                throwError={setError}
              />
            ) : (
              <ItemRow
                item={{ value: fileLoaded.name }}
                onRemove={() => setFileLoaded(null)}
              />
            )}
            {fileLoaded ? (
              <div className="align-row upload-modal__buttons">
                <Button
                  value="Save"
                  onClick={onSubmit}
                  disabled={loading || error}
                />
                <Button
                  value="Cancel"
                  secondaryGray
                  onClick={onCancel}
                  disabled={loading}
                />
              </div>
            ) : (
              <div className="align-row upload-modal__file-limit align-row">
                File limit: 5MB
                <Button
                  value="Cancel"
                  onClick={onCancel}
                  disabled={loading}
                  secondaryGray
                />
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  )
}

/**
 * @enum Holding possible image load states
 */
const AVATAR_STATE = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
}

UserAvatar.propTypes = {
  editable: PropTypes.bool,
}

export default UserAvatar
