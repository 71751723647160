import PropTypes from 'prop-types'
import { format as formatDate } from 'date-fns'

/** Utils */
import { DEFAULT_DATE_FORMAT } from 'components/utils/date'

/** Constants */

/** Components */
import { AccountInformation } from 'components/account-icon'
import { utils, budgetPacing } from '@decision-sciences/qontrol-common'

const { getDateWithoutTimestamp } = utils.date
const { formatValue } = utils.string
const { BUDGET_PACING_KPI_TYPE } = budgetPacing
const { checkIfArrayContainsObjectWithProperty: alreadyExists } = utils.array

const addOptions = (field, value, label, options) => {
  if (!options) {
    return {}
  }
  if (!options[field].some((opt) => opt.value === value)) {
    value && options[field].push({ label, value })
  }
  return options
}

/**
 * Function to get filter option from activity logs array.
 * Options will be computed for user and value changed field.
 * There is a special case, dimension, which is a type of value that can be changed.
 * However, we can also filter on this.
 * For dimension, filter options will be fetched from the to and from field of the logs.
 * @param {Array} activityLogs Array of budget pacing changes
 * @returns {Object}
 */
export const getFilterOptions = (activityLogs) => {
  const options = { ...initialFilters }
  activityLogs.forEach((log) => {
    // Iterate over each filter that needs to be populated
    Object.values(FILTERS_TO_POPULATE).forEach((field) => {
      const value = log[field]
      let label = value

      if (field === FILTERS_TO_POPULATE.VALUE_CHANGED) {
        const changedField = log[field]
        label = VALUE_CHANGED_LABELS[value]

        // Add dimension to dimension filter
        if (changedField === VALUE_CHANGED_TYPES.DIMENSION) {
          const { to, from } = log
          !alreadyExists(options[changedField], 'value', to || '') &&
            addOptions(changedField, to, to, options)
          !alreadyExists(options[changedField], 'value', from || '') &&
            addOptions(changedField, from, from, options)
        }
      }

      // Add options to filter
      !alreadyExists(options[field], 'value', log[field]) &&
        addOptions(field, value, label, options)
    })
  })
  return options
}

export const formatRow = (value, field, forExport = false) => {
  if (field === VALUE_CHANGED_TYPES.CONVERSIONS) {
    return !forExport ? (
      <ConversionRow conversions={value} />
    ) : (
      value.map((conv) =>
        conv.publisher.concat(' ', conv.conversionName, ' ', conv.accountId)
      )
    )
  }
  if (
    [
      VALUE_CHANGED_TYPES.BUDGET_AMOUNT,
      VALUE_CHANGED_TYPES.KPI_TARGET,
    ].includes(field)
  ) {
    return formatValue(value, 2)
  }
  if (
    [VALUE_CHANGED_TYPES.START_DATE, VALUE_CHANGED_TYPES.END_DATE].includes(
      field
    ) &&
    value
  ) {
    return formatDate(new Date(value), `${DEFAULT_DATE_FORMAT}`)
  }
  if (field === VALUE_CHANGED_TYPES.KPI_TYPE && !!value) {
    return BUDGET_PACING_KPI_TYPE[value].label
  }
  return Array.isArray(value) ? value.join(', ') : value
}

export const filterActivityLogs = (logs, filter) => {
  const {
    user,
    valueChanged,
    dimensionName,
    date_to,
    date_from,
    budgetSegment,
  } = filter

  return logs.filter((item) => {
    if (
      date_from &&
      getDateWithoutTimestamp(item.date).getTime() <
        getDateWithoutTimestamp(date_from).getTime()
    ) {
      return false
    }

    if (
      date_to &&
      getDateWithoutTimestamp(item.date).getTime() >
        getDateWithoutTimestamp(date_to).getTime()
    ) {
      return false
    }

    if (
      budgetSegment &&
      !item.budgetSegment.toLowerCase().includes(budgetSegment.toLowerCase())
    ) {
      return false
    }

    if (user && !user.includes(item.user)) {
      return false
    }
    if (valueChanged && !valueChanged.includes(item.valueChanged)) {
      return false
    }
    if (dimensionName) {
      if (item.valueChanged !== VALUE_CHANGED_TYPES.DIMENSION) {
        return false
      }
      if (dimensionName !== item.to && dimensionName !== item.from) {
        return false
      }
    }
    return true
  })
}

const initialFilters = {
  user: [],
  valueChanged: [],
  dimensionName: [],
  date_to: null,
  date_from: null,
  budgetSegment: '',
}

const FILTERS_TO_POPULATE = {
  USER: 'user',
  VALUE_CHANGED: 'valueChanged',
}

export const ACTIVITY_FIELDS = {
  BUDGET_SEGMENT: 'budget_segment',
  USER: 'user',
  DATE: 'date',
  VALUE_CHANGED: 'value_changed',
  FROM: 'from',
  TO: 'to',
}

export const VALUE_CHANGED_TYPES = {
  DIMENSION: 'dimensionName',
  BUDGET_SEGMENT: 'budgetSegment',
  BUDGET_AMOUNT: 'budgetAmount',
  KPI_TYPE: 'kpiType',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  KPI_TARGET: 'kpiTarget',
  DIMENSION_INPUT: 'dimensionInput',
  CONVERSIONS: 'associatedConversions',
}

export const VALUE_CHANGED_LABELS = {
  [VALUE_CHANGED_TYPES.DIMENSION]: 'Dimension',
  [VALUE_CHANGED_TYPES.BUDGET_SEGMENT]: 'Budget Segment',
  [VALUE_CHANGED_TYPES.BUDGET_AMOUNT]: 'Budget Amount',
  [VALUE_CHANGED_TYPES.KPI_TYPE]: 'KPI Type',
  [VALUE_CHANGED_TYPES.START_DATE]: 'Start Date',
  [VALUE_CHANGED_TYPES.END_DATE]: 'End Date',
  [VALUE_CHANGED_TYPES.KPI_TARGET]: 'KPI Target',
  [VALUE_CHANGED_TYPES.DIMENSION_INPUT]: 'Dimension Input',
  [VALUE_CHANGED_TYPES.CONVERSIONS]: 'Associated Conversions',
}

export const ACTIVITY_LOG_CSV_EXPORT_HEADERS = [
  { label: 'Date of Change', key: 'date' },
  { label: 'User', key: 'user' },
  { label: 'Budget Segment', key: 'budgetSegment' },
  { label: 'Value Changed', key: 'valueChanged' },
  { label: 'From', key: 'from' },
  { label: 'To', key: 'to' },
]

const ConversionRow = ({ conversions }) => {
  if (!conversions.length) {
    return null
  }
  return (
    <div className="conversion-row">
      {conversions.map((conversion, idx) => {
        return (
          <AccountInformation
            key={idx}
            accountType={conversion.publisher}
            accountName={conversion.conversionName}
          />
        )
      })}
    </div>
  )
}

ConversionRow.propTypes = {
  conversions: PropTypes.array.isRequired,
}
