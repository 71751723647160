import Api from 'easy-fetch-api'
import { getUsers } from 'modules/users/actions'
import {
  showSuccessMessage,
  showErrorMessage,
} from 'modules/notifications/actions'
import { getCompanies } from 'modules/companies/actions'
import { TEAM_DEFAULT } from 'modules/teams/constants'

export const ACTIONS = {
  GET_TEAMS: 'teams.getTeams',
  GET_TEAM_BY_KEY: 'teams.getTeamByKey',
}

export const getTeams = async (dispatch, companyId, query = {}) => {
  const url = '/api/teams'
  if (companyId) {
    query.companies = [companyId]
  }
  const result = await Api.get({ url, query })
  if (!result || !result.success) {
    dispatch({ type: ACTIONS.GET_TEAMS, teams: [] })
    return console.error((result && result.error) || 'Error getting teams')
  }
  dispatch({ type: ACTIONS.GET_TEAMS, teams: result.teams })
  return result
}

export const getTeam = async (dispatch, teamKey) => {
  if (teamKey === 'new') {
    dispatch({ type: ACTIONS.GET_TEAM_BY_KEY, team: TEAM_DEFAULT })
    return Promise.resolve()
  }
  const url = `/api/teams/${teamKey}`
  const result = await Api.get({ url })
  if (!result || !result.success) {
    const errorMessage =
      (result && result.error) || `Error getting team by key ${teamKey}`
    return Promise.reject(errorMessage)
  }
  dispatch({ type: ACTIONS.GET_TEAM_BY_KEY, team: result.team })
  return Promise.resolve(result)
}

export const resetTeam = (dispatch) =>
  dispatch({ type: ACTIONS.GET_TEAM_BY_KEY, team: null })

/**
 * Retrieves all teams that have users which also belong to specific companies
 * @param {Array} companies List of company ids
 * @param {Array} [extraTeams]
 * @returns {Array}
 */
export const getTeamsWithUsersInCompanies = async (companies, extraTeams) => {
  const url = '/api/teams/companies'
  const query = { companies, extraTeams }
  const result = await Api.get({ url, query })
  if (!result || !result.success) {
    return console.error((result && result.error) || 'Error getting teams')
  }
  return result.teams
}

export const createUpdateTeam = (
  dispatch,
  team,
  usersInTeam = [],
  reports = [],
  companyId
) => {
  return new Promise((resolve, reject) => {
    let promise
    const payload = {
      url: '/api/teams',
      data: {
        team,
        usersInTeam: usersInTeam.map((el) => el._id),
        companyId,
        reports: reports.map((el) => ({
          _id: el._id,
          type: el.type,
        })),
      },
    }
    if (team._id) {
      promise = Api.patch(payload)
    } else {
      promise = Api.post(payload)
    }
    promise
      .then((res) => {
        if (res.error) {
          return resolve(res)
        }
        Promise.all([
          getTeams(dispatch, companyId),
          getUsers(dispatch),
          getCompanies(dispatch, { deleted: false }),
        ]).then(resolve)
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

export const deleteTeam = (dispatch, teamKey, companyId, filter) => {
  return new Promise((resolve, reject) => {
    Api.delete({ url: `/api/teams/${teamKey}` })
      .then((res) => {
        if (res.success) {
          showSuccessMessage('Team successfully deleted', dispatch)
          getTeams(dispatch, companyId, filter).then(resolve)
        } else {
          const { usedInUsers, usedInAlert } = res
          const error = `${
            usedInUsers ? 'There are users assigned to the team.' : ''
          } ${
            usedInAlert ? 'There are alerts assigned to the team.' : ''
          } Cannot delete`

          if (usedInUsers || usedInAlert) {
            showErrorMessage(error, dispatch)
          } else {
            showErrorMessage('Error deleting Team', dispatch)
          }
          reject(error)
        }
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

export const getTeamAlertNotifications = (teamKey) => {
  return new Promise((resolve, reject) => {
    Api.get({ url: `/api/teams/${teamKey}/alert-notifications` })
      .then((res) => {
        if (res.success) {
          resolve(res.alerts)
        } else {
          reject(
            res.error || 'Something went wrong fetching alert notifications'
          )
        }
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

export const getUnlinkedReports = () =>
  Api.get({ url: '/api/teams/unlinked-reports' }).catch(console.error)
