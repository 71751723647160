import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { Dropdown } from 'components/dropdown'
import removeIcon from 'assets/icon_minus.svg'
import iconDuplicate from 'assets/icon_duplicate.svg'
import './style.scss'

/**
 * Field dropdown component
 * @param {String} text Static text
 * @param {Object} selectedValue selected value
 * @param {String} description Description to be displayed on top.
 * @param {Array} options list of options
 * @param {Function} onChange function to execute upon selecting an item from the list
 * @param {Function} onRemove function to execute upon pressing the remove button
 * @param {Function} onDuplicate function to execute upon pressing the duplicate button
 * @param {Array<Function>} extraFields Extra Renders to display
 */
function FieldDropdown({
  text,
  selectedValue,
  options,
  onChange,
  onRemove,
  onDuplicate,
  extraFields,
  description,
  disabled,
}) {
  return (
    <div className="field-dropdown">
      {onDuplicate && !disabled && (
        <div className="field-dropdown__duplicate">
          <img src={iconDuplicate} alt="Duplicate" onClick={onDuplicate} />
        </div>
      )}
      {text && (
        <div
          data-testid="field-dropdown__field"
          className={cx('field-dropdown__field', {
            'field-dropdown__field--disabled': disabled,
          })}
        >
          {text}
          {description && (
            <span className="field-dropdown__description">{description}</span>
          )}
        </div>
      )}
      {extraFields && (
        <div className="field-dropdown__extra">{extraFields}</div>
      )}
      <Dropdown
        options={options}
        defaultState={selectedValue}
        onChange={onChange}
        className="field-dropdown__dropdown"
        disabled={disabled}
      />
      {!disabled && (
        <div className="field-dropdown__remove">
          <img src={removeIcon} alt="Remove" onClick={onRemove} />
        </div>
      )}
    </div>
  )
}

FieldDropdown.propTypes = {
  text: PropTypes.string,
  selectedValue: PropTypes.any,
  description: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onDuplicate: PropTypes.func,
  extraFields: PropTypes.array,
  disabled: PropTypes.bool,
}

export default FieldDropdown
