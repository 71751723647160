import Api from 'easy-fetch-api'

/**
 * Gets all campaigns for a specific client
 * @param {*} clientId Company Id
 * @param {Object} signal - abort signal
 */
export const getCampaigns = (clientId, signal) =>
  Api.get({
    url: `/api/google-sa360/client-campaigns/${clientId}`,
    signal,
  })
    .then((res) => res?.data)
    .catch((error) => error?.error)

/**
 * Gets all adgroups for a specific account - campaign
 * @param {String} clientId Company Id
 * @param {String} campaignId Campaign Id
 * @param {String} account_id Google DV360 account id
 * @param {Object} signal - abort signal
 */
export const getAdGroups = (clientId, signal, campaignId, account_id) =>
  Api.get({
    url: `/api/google-sa360/ad-groups/`,
    query: { clientId, campaignId, externalAccountId: account_id },
    signal,
  })
    .then((res) => res?.data)
    .catch((error) => error?.error)

/**
 * Gets all ads for a specific campaign and adset
 * @param {String} clientId Company Id
 * @param {String} adgroup_id Google DV360 AdGroup Id
 * @param {String} account_id Google DV360 Account Id
 * @param {String} campaign_id Google DV360 Campaign Id
 * @param {Object} signal - abort signal
 */
export const getAds = (clientId, signal, adgroup_id, account_id, campaign_id) =>
  Api.get({
    url: `/api/google-sa360/ads`,
    query: {
      clientId,
      adgroup_id,
      externalAccountId: account_id,
      campaign_id,
    },
    signal,
  })
    .then((res) => res?.data)
    .catch((error) => error?.error)
