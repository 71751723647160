import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'traqTemplates'

/** Initial state of the reducer */
const initialState = {
  list: null,
  userTraq: null,
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.GET_TRAQ_TEMPLATES]: (state, { traqTemplates }) => {
    return { ...state, list: traqTemplates }
  },

  [ACTIONS.PUT_TRAQ_TEMPLATE]: (state, { traqTemplate }) => {
    const newList = [...state.list]
    const foundIdx = newList.findIndex((traq) => traq._id === traqTemplate._id)
    if (foundIdx !== -1) {
      // Have the updated element show at the top of the list
      ;[newList[foundIdx], newList[0]] = [newList[0], traqTemplate]
    } else {
      newList.unshift(traqTemplate)
    }

    return { ...state, list: newList }
  },

  [ACTIONS.DELETE_TRAQ_TEMPLATE]: (state, { _id }) => ({
    ...state,
    list: state.list.filter((traq) => traq._id !== _id),
  }),

  [ACTIONS.GET_FAVOURITE_TRAQ_TEMPLATES]: (state, { userTraq }) => ({
    ...state,
    userTraq,
  }),

  [ACTIONS.UPDATE_LAST_VIEWED]: (state, { userTraqTemplate }) => {
    const { _id: userTraQTemplateId } = userTraqTemplate

    return {
      ...state,
      userTraq: [
        ...state.userTraq.filter(({ _id }) => _id !== userTraQTemplateId),
        {
          ...userTraqTemplate,
        },
      ],
    }
  },
}

export default { name, initialState, reduce }
