import React, { useState, useEffect, useRef } from 'react'
import { useStore } from 'store'
import { useNavigate } from 'react-router-dom'
import Button from 'components/button'
import Input from 'components/input'
import {
  setup2FA,
  getQrInfo,
  sendUserOTP,
  login2fa,
  updatePhoneNumber,
} from 'modules/users/actions'
import { isValidPhoneNumber } from 'components/validator'
import LoginRemember from 'components/login-remember'
import AuthenticationContainer from 'components/authentication'

import 'styles/authentication.scss'

const MultiFactorSetup = () => {
  const { state, dispatch } = useStore()
  const { userData, isLoggedIn } = state.session
  const navigate = useNavigate()
  const [code, setCode] = useState('')
  const [otpSent, setOtpSent] = useState(false)
  const [submitError, setSubmitError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [qrInfo, setQrInfo] = useState()
  const [phoneValidation, setPhoneValidation] = useState(
    userData && !!userData.tfaSecret
  )
  const [phoneNumberChange, setPhoneNumberChange] = useState(false)
  const [phone, setPhone] = useState('')
  const [error, setError] = useState()
  const qrRef = useRef()

  const [remember, setRemember] = useState(false)

  useEffect(() => {
    if (!userData) {
      setTimeout(() => {
        navigate('/login')
      }, 50)
    }
    if (isLoggedIn) {
      setTimeout(() => {
        if (userData.backupCode) {
          navigate('/')
        } else {
          navigate('/my-account/security')
        }
      }, 50)
    }
  }, [userData, isLoggedIn])
  useEffect(() => {
    if (phoneValidation && !userData.phone) {
      setPhoneNumberChange(true)
    }
    if (!qrInfo && !submitError && userData && !phoneValidation) {
      getQrInfo(userData._id)
        .then((res) => {
          qrRef.current.innerHTML = res.qr
          setQrInfo(res)
        })
        .catch(setSubmitError)
    } else {
      if (phoneValidation && !otpSent && !submitError) {
        sendOtp()
      }
    }
  }, [qrInfo, userData, phoneValidation])
  const sendOtp = () => {
    setLoading(true)
    sendUserOTP(userData._id)
      .then(() => {
        setOtpSent(true)
      })
      .catch((error) => {
        setSubmitError(error || 'Something went wrong.')
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const handleSubmit = (ev) => {
    ev.preventDefault()
    setSubmitError(null)
    setLoading(true)
    setCode('')
    setPhone('')
    if (phoneNumberChange) {
      return updatePhoneNumber(dispatch, userData._id, phone)
        .then(() => {
          setPhoneNumberChange(false)
          sendOtp()
        })
        .catch((err) => {
          setSubmitError(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
    if (phoneValidation) {
      return login2fa(dispatch, userData, code, remember, 'phone-validation')
        .then(() => {
          setTimeout(() => {
            if (userData.backupCode) {
              navigate('/login')
            } else {
              navigate('/my-account/security')
            }
          }, 50)
        })
        .catch((err) => {
          setSubmitError(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
    if (qrInfo && userData && code) {
      setup2FA(dispatch, userData._id, qrInfo.secret, code)
        .then((user) => {
          if (!user.isValidUser) {
            setPhoneValidation(true)
          } else {
            setTimeout(() => {
              if (userData.backupCode) {
                navigate('/login')
              } else {
                navigate('my-account/')
              }
            }, 50)
          }
        })
        .catch((err) => setSubmitError(err))
        .finally(() => setLoading(false))
    }
  }

  const getMessage = () => {
    if (phoneValidation) {
      const hiddenPhone =
        userData && userData.phone
          ? userData.phone.slice(-3).padStart(10, 'x')
          : ''
      if (loading) {
        return 'Sending you a code'
      }
      return otpSent
        ? `A code has been sent to ${hiddenPhone}. If you did not receive a code, you can request a new code. `
        : 'We may not be able to send messages to your country. Try again with a US phone number.'
    }
    if (qrInfo) {
      return 'Please use Google Authenticator on your phone to scan this QR code, then input the code displayed on your phone'
    }
  }
  const checkPhone = (phone) => {
    if (!isValidPhoneNumber(phone)) {
      setError('Invalid phone number.')
    }
  }
  const renderSetupForm = () => {
    return (
      <>
        {!phoneValidation && (
          <>
            <div ref={qrRef} className="authentication-container__qr qr-code" />
            {qrInfo && (
              <div className="authentication-container__description">
                Or manually enter the following key into the authenticator app:{' '}
                {qrInfo.secret}
              </div>
            )}
          </>
        )}
        <div className="authentication-container__description">
          {getMessage()}
        </div>

        <Input
          value={code}
          onChange={(e) => {
            !isNaN(e) && setCode(e)
            setSubmitError(null)
          }}
          maxLength={6}
          placeholder={'Authentication Code'}
        />
        <div className="authentication-container__links">
          {phoneValidation && (
            <>
              <span
                className="fake-link"
                onClick={() => {
                  sendOtp()
                }}
              >
                {` Resend Code `}
              </span>
              <span className="vertical-line">|</span>
              <span
                className="fake-link"
                onClick={() => {
                  setLoading(false)
                  setPhoneNumberChange(true)
                }}
              >
                {` Change Phone Number `}
              </span>
            </>
          )}
        </div>
        {phoneValidation && (
          <LoginRemember remember={remember} setRemember={setRemember} />
        )}
      </>
    )
  }
  const renderPhoneChangeForm = () => {
    return (
      <>
        <Input
          type="phone"
          disabled={loading}
          value={phone}
          onChange={(e) => {
            setPhone(e)
            setSubmitError(null)
            setError('')
          }}
          blur={() => checkPhone(phone)}
          placeholder={'New Phone Number'}
          autoComplete="tel"
        />
        {error && <div className="error">{error}</div>}
        <div className="authentication-container__links align-row">
          <span
            className="fake-link"
            onClick={() => {
              setPhoneNumberChange(false)
              setPhone('')
              setError('')
            }}
          >
            {` Back `}
          </span>
        </div>
      </>
    )
  }

  return (
    <AuthenticationContainer
      onSubmit={handleSubmit}
      title="Setup 2FA"
      error={submitError}
    >
      {phoneNumberChange ? renderPhoneChangeForm() : renderSetupForm()}
      <div className="align-row">
        <Button
          className="authentication-container__button button--big"
          type="submit"
          value="Confirm"
          onClick={handleSubmit}
        />
      </div>
    </AuthenticationContainer>
  )
}

export default MultiFactorSetup
