import Button from 'components/button/index'
import { PERMISSIONS, PERMISSION_TYPES, useAccess } from 'hooks/access'
import { LegalManagementModule } from 'modules/legal'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, redirect } from 'react-router-dom'

const LegalPage = () => {
  const navigate = useNavigate()

  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.LEGAL_SETTINGS,
    type: PERMISSION_TYPES.CREATE,
  })

  const hasReadAccess = useAccess({
    feature: PERMISSIONS.LEGAL_SETTINGS,
    type: PERMISSION_TYPES.READ,
  })

  /* On mount or permission change, if the user does not have access, redirect to unauthorized page */
  useEffect(() => {
    if (!hasReadAccess) {
      redirect('/unauthorized')
    }
  }, [hasReadAccess])

  return (
    <React.Fragment>
      <Helmet>
        <title>{'Legal items'}</title>
      </Helmet>
      <div className="heading" data-cy="page-heading">
        {'Legal items'}
        {hasCreateAccess && (
          <div className="heading__buttons">
            <Button
              value={'Add to Legal'}
              onClick={() => {
                navigate('/legal/cms/new')
              }}
            />
          </div>
        )}
      </div>
      <LegalManagementModule />
    </React.Fragment>
  )
}

export { LegalPage }
