import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'store'

import { getLegalViewItems } from 'modules/legal/actions'
import LegalViewItem from 'modules/legal/components/legal-view-item'

import '../styles.scss'

/**
 * Common component to render legal view list
 * @param {Object} props Component props
 * @param {String} props.displayArea Legal display area
 * @returns {React.Component}
 */
const LegalViewList = ({ displayArea }) => {
  const { dispatch, state } = useStore()
  const {
    legal: { viewList },
  } = state

  useEffect(() => {
    if (!viewList) {
      getLegalViewItems(dispatch, displayArea)
    }
  }, [])

  if (!viewList) {
    return null
  }

  return (
    viewList && (
      <div className="legal-view-list">
        {viewList.map((item, index) => {
          if (item.displayArea.includes(displayArea)) {
            const { _id } = item
            return (
              <div
                key={_id}
                className={`${
                  index < viewList.length - 1 ? 'border-right' : ''
                }`}
              >
                <LegalViewItem item={item} />
              </div>
            )
          }
        })}
      </div>
    )
  )
}

LegalViewList.propTypes = {
  displayArea: PropTypes.string,
}

export default LegalViewList
