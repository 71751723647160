import React, { useState, useMemo } from 'react'
import propTypes from 'prop-types'
import cx from 'classnames'

import Tooltip from 'components/tooltip/index'

// Utils
import { arrayFromRange, containsLogicalOperator } from './utils'

import './style.scss'

/**
 * @param {Object} params React Params
 * @param {String} [params.label] Label to display on top of text area
 * @param {Array} [params.highlights] Words to be highlighted. If words contain special characters make sure to provide a pattern to match them.
 * @param {String} [params.className] Classname to style component
 * @param {Number} [params.hoveredWordIndex] Index of the hovered word from the wordsToDisplay Array
 * @param {Function} [params.setHoveredWordIndex] Function to set hovered word index
 * @param {Function} [params.getTooltipForHighlight] Function that return tooltip content for highlighted words
 * @param {Object} [params.wordToColorsMap] Object that maps words to highlight colors
 * @param {Array<String>} [params.wordsToDisplay] Words to display with highlights in text area
 * @param {Array<String>} [params.replacedWordsToDisplay] Words to display with highlights in text area, if present will be displayed instead of wordsToDisplay
 * @returns {React.ComponentElement}
 */
const PanelWithHighlightedContent = ({
  label,
  highlights = [],
  className,
  hoveredWordIndex = null,
  setHoveredWordIndex = () => {},
  getTooltipForHighlight = null,
  wordToColorsMap,
  wordsToDisplay,
  replacedWordsToDisplay = [],
}) => {
  const [showTooltip, setShowTooltip] = useState({})

  const _getExpressionStartIndex = (wordIndex, words) => {
    let idx = wordIndex
    while (idx && idx >= 0 && !containsLogicalOperator(words[idx])) {
      idx--
    }
    return idx === 0 ? idx : idx + 1
  }
  const _getExpressionEndIndex = (wordIndex, words) => {
    let idx = wordIndex
    while (idx && idx < words.length && !containsLogicalOperator(words[idx])) {
      idx++
    }
    return idx === words.length - 1 ? idx : idx - 1
  }

  const hoveredExpressionWordIndexes = useMemo(() => {
    return arrayFromRange(
      _getExpressionStartIndex(hoveredWordIndex, wordsToDisplay),
      _getExpressionEndIndex(hoveredWordIndex, wordsToDisplay),
      1
    )
  }, [hoveredWordIndex])

  const _formatWord = (word, idx) => {
    const highlight = highlights.find((highlight) => word.includes(highlight))
    const style = highlight
      ? { style: { backgroundColor: wordToColorsMap[word] } }
      : {}

    return (
      <span
        key={idx}
        onMouseEnter={() => {
          if (highlight && getTooltipForHighlight) {
            setShowTooltip({ [idx]: true })
          }

          if (!containsLogicalOperator(word)) {
            setHoveredWordIndex(idx)
          }
        }}
        onMouseLeave={() => {
          if (highlight && getTooltipForHighlight) {
            setShowTooltip({ [idx]: false })
          }
          setHoveredWordIndex(null)
        }}
        className={cx({
          'hovered--expression': hoveredExpressionWordIndexes.includes(idx),
        })}
        {...style}
      >
        {replacedWordsToDisplay.length ? replacedWordsToDisplay[idx] : word}
        {getTooltipForHighlight && (
          <Tooltip
            content={<span {...style}>{getTooltipForHighlight(word)}</span>}
            show={showTooltip[idx]}
          />
        )}
      </span>
    )
  }

  return (
    <div
      id="panel-with-highlights"
      className={cx('panel-with-highlights', className)}
    >
      {label ? <div className="general-label">{label}</div> : null}
      <div className="text-panel">
        {wordsToDisplay.map((word, idx) => {
          return _formatWord(word, idx)
        })}
      </div>
    </div>
  )
}

PanelWithHighlightedContent.propTypes = {
  label: propTypes.string,
  highlights: propTypes.array,
  className: propTypes.string,
  hoveredWordIndex: propTypes.number,
  setHoveredWordIndex: propTypes.func,
  getTooltipForHighlight: propTypes.func,
  wordToColorsMap: propTypes.object,
  wordsToDisplay: propTypes.array,
  replacedWordsToDisplay: propTypes.array,
}

export default PanelWithHighlightedContent
