import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'legal'

/** Initial state of the reducer */
const initialState = {
  list: null,
  viewList: null,
  termsOfUse: null,
  currentEditedLegal: null,
  currentEditedLegalSection: null,
  users: null,
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.SET_LEGAL_ITEMS]: (state, { list, dirty }) => {
    return { ...state, list, listDirty: !!dirty }
  },

  [ACTIONS.SET_DISPLAY_LEGAL_ITEMS]: (state, { viewList }) => {
    const termsOfUse = viewList.find(({ isDeletable }) => !isDeletable)
    return { ...state, viewList, termsOfUse }
  },

  [ACTIONS.DELETE_LEGAL_ITEM]: (state, { id }) => {
    if (state.list) {
      const filteredList = [...state.list].filter(({ _id }) => _id !== id)
      return { ...state, list: filteredList }
    }
    return state
  },

  [ACTIONS.DELETE_LEGAL_SECTION]: (state, { id }) => {
    if (state.currentEditedLegal) {
      const filteredList = [...state.currentEditedLegal.sections].filter(
        ({ _id }) => _id !== id
      )
      return {
        ...state,
        currentEditedLegal: {
          ...state.currentEditedLegal,
          sections: filteredList,
        },
      }
    }
    return state
  },

  [ACTIONS.SET_CURRENT_EDITED_LEGAL]: (state, { legalItem }) => {
    if (legalItem && (!legalItem._id || legalItem.isNew)) {
      legalItem.orderIndex = state.list?.length || 0
    }
    return {
      ...state,
      currentEditedLegal: legalItem,
      currentEditedLegalSection: null,
    }
  },

  [ACTIONS.SET_CURRENT_EDITED_LEGAL_SECTION]: (state, { legalSection }) => {
    return {
      ...state,
      currentEditedLegalSection: legalSection,
    }
  },

  [ACTIONS.TOGGLE_LEGAL_DISPLAY]: (state, { id, display }) => {
    const { currentEditedLegal } = state
    if (currentEditedLegal) {
      const index = currentEditedLegal.sections.findIndex(
        ({ _id }) => _id === id
      )
      if (index < 0) {
        return state
      }
      const sections = [...currentEditedLegal.sections]
      sections[index] = { ...sections[index], display }
      return {
        ...state,
        currentEditedLegal: {
          ...currentEditedLegal,
          sections,
        },
      }
    }
    return state
  },

  [ACTIONS.SET_USERS]: (state, { users }) => {
    return { ...state, users }
  },
}

export default { name, initialState, reduce }
