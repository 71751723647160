import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { reports } from '@decision-sciences/qontrol-common'
import { v4 as uuid } from 'uuid'

/* Components */
import Modal from 'components/modal'
import Button from 'components/button'
import CollapsibleSection from 'components/collapsible-section'
import RoundRectangleButton from 'components/round-rectangle-button'
import ReportsTable from 'modules/companies/subsections/reports/reports-table'
import ReportsAddEditSection from 'modules/companies/subsections/reports/report-add-edit-section'
import ReportsFilterSection from 'modules/companies/subsections/reports/report-filter-section'

/* Icons */
import { ReactComponent as PlusIcon } from 'assets/icon_plus_blue.svg'
import { ReactComponent as IconFilter } from 'assets/filters.svg'
import { ReactComponent as EditIcon } from 'assets/icon_edit.svg'
import { ReactComponent as RemoveIcon } from 'assets/icon_minus_red.svg'
import { ReactComponent as WarningIcon } from 'assets/icon_warning.svg'

/* Utils */
import {
  PAGE_MODE,
  DEFAULT_REPORT,
  filterReports,
  getReportingsForUser,
} from 'modules/companies/subsections/reports/utils'
import { getTeamsWithUsersInCompanies } from 'modules/teams/actions'
import { useAccess, PERMISSION_TYPES, PERMISSIONS } from 'hooks/access'

/* Session && Access*/
import useSession from 'modules/session'

import './style.scss'

const { getSourceBasedOnURL } = reports

/** Reports Section sub-component */
const ReportsSection = ({
  viewMode,
  company,
  reportings,
  setCompany,
  users,
}) => {
  const [mode, setMode] = useState(PAGE_MODE.DEFAULT)
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(true)
  const [filtersOpened, setFiltersOpened] = useState(false)
  const [reportsToDelete, setReportsToDelete] = useState([])
  const [selectedReports, setSelectedReports] = useState([])
  const [teams, setTeams] = useState([])
  const [filter, setFilter] = useState({})
  const [visibleReports, setVisibleReports] = useState([])

  const [, userData] = useSession()
  const { _id: companyId, new: isNew } = company
  const { isSuperAdmin, firstName, lastName } = userData
  const isAddOrEditMode = [PAGE_MODE.EDIT, PAGE_MODE.ADD].includes(mode)

  const teamAccess = useAccess({
    feature: PERMISSIONS.TEAMS_INDEX,
    type: PERMISSION_TYPES.READ,
  })

  useEffect(() => {
    if (!teams?.length && !isNew) {
      teamAccess &&
        getTeamsWithUsersInCompanies([companyId]).then((res) => setTeams(res))
    }
  }, [JSON.stringify(teams), companyId, teamAccess])

  useEffect(() => {
    const reportingsToShow = getReportingsForUser(
      userData,
      reportings.map((reporting) => ({ ...reporting, id: uuid() }))
    )
    setVisibleReports(reportingsToShow)
  }, [JSON.stringify(reportings)])

  const saveEditReports = (reportToSave) => {
    let newReportings = [...reportings]
    if (mode === PAGE_MODE.EDIT) {
      const bulkEdit = selectedReports.length > 1
      if (bulkEdit) {
        const idsToEdit = selectedReports.map(({ _id }) => _id)
        newReportings = newReportings.map((report) => {
          if (idsToEdit.includes(report._id)) {
            return {
              ...report,
              ...reportToSave,
              changed: !!report._id,
            }
          }
          return report
        })
      } else {
        const reportIdx = newReportings.findIndex(
          ({ _id }) => _id === reportToSave._id
        )
        const report = newReportings[reportIdx]
        // If embed code changed, recalculate the source
        if (report.embedCode !== reportToSave.embedCode) {
          reportToSave.source = getSourceBasedOnURL(reportToSave)
        }
        if (reportToSave._id) {
          reportToSave.changed = true
        }
        newReportings[reportIdx] = reportToSave
      }
    } else {
      const newReport = {
        ...reportToSave,
        owner: { firstName: firstName, lastName: lastName },
        updatedAt: new Date(),
        source: getSourceBasedOnURL(reportToSave),
      }
      newReportings.push(newReport)
    }
    setCompany({ reportings: newReportings })
    setMode(PAGE_MODE.DEFAULT)
    setSelectedReports([])
  }

  const onDelete = () => {
    const newReportings = [...reportings]
    const newReportingsIds = newReportings.map((r) => r._id)

    reportsToDelete.forEach((reportToDelete) => {
      const indexOfReport = newReportingsIds.indexOf(reportToDelete._id)
      // Existing reports case
      if (reportToDelete._id) {
        const report = newReportings.find((r) => r._id === reportToDelete._id)
        newReportings.splice(indexOfReport, 1, {
          ...report,
          deleted: true,
        })
      } else {
        // Newly added reports case
        newReportings.splice(indexOfReport, 1)
        newReportingsIds.splice(indexOfReport, 1)
      }
    })
    setCompany({ reportings: newReportings })
    setReportsToDelete([])
    setSelectedReports([])
  }

  const onApplyFilters = () => {
    const newVisibleReports = filterReports(
      getReportingsForUser(userData, reportings),
      filter
    )
    setVisibleReports(newVisibleReports)
  }

  const _renderAddReportsButton = () => {
    return (
      <Button
        value={
          <div className="action-button">
            <PlusIcon />
            Link Report
          </div>
        }
        onClick={() => {
          setSelectedReports([DEFAULT_REPORT])
          setMode(PAGE_MODE.ADD)
        }}
        disabled={
          isAddOrEditMode ||
          Boolean(selectedReports.length) ||
          isPanelCollapsed ||
          filtersOpened ||
          viewMode
        }
        className="fixed-height"
        secondary
      />
    )
  }

  const _renderAddEditSection = () => {
    if (isAddOrEditMode) {
      return (
        <ReportsAddEditSection
          reportings={company?.reportings || []}
          reports={selectedReports}
          setReports={setSelectedReports}
          onCancel={() => {
            setMode(PAGE_MODE.DEFAULT)
            setSelectedReports([])
          }}
          onSave={saveEditReports}
          users={users}
          teams={teams}
          mode={mode}
        />
      )
    }
  }

  const getHeader = () => {
    if (mode === PAGE_MODE.ADD) {
      return 'Add Report'
    } else if (mode === PAGE_MODE.EDIT) {
      return selectedReports.length > 1 ? 'Edit Reports' : 'Edit Report'
    }
    return 'Manage Reports'
  }

  const _renderHeader = () => {
    return (
      <div
        data-cy="reports-section-header"
        className="reports-headers reports-section__header"
      >
        {getHeader()}
        <RoundRectangleButton
          className={cx('margin-left-16', {
            'filter-active': filtersOpened,
          })}
          disabled={isAddOrEditMode}
          contentClassName="filter-icon"
          onClick={() => {
            if (!isAddOrEditMode) {
              setFiltersOpened(!filtersOpened)
              selectedReports.length && setSelectedReports([])
            }
          }}
          contentRender={() => (
            <IconFilter
              className={filtersOpened ? 'fill-white' : 'fill-light-blue'}
            />
          )}
        />
      </div>
    )
  }

  const _renderActions = () => {
    if (isAddOrEditMode || filtersOpened || !isSuperAdmin) {
      return null
    }
    return (
      <div
        data-cy="reports-actions"
        className="reports-headers reports-section__actions"
      >
        <Button
          value={
            <div
              className={cx('action-button dark-text', {
                'action-button--disabled': !selectedReports.length,
              })}
            >
              <EditIcon /> Edit selected
            </div>
          }
          disabled={!selectedReports.length}
          onClick={() => setMode(PAGE_MODE.EDIT)}
          className="fixed-height"
          secondary
        />
        <Button
          value={
            <div
              className={cx('action-button dark-text', {
                'action-button--disabled': !selectedReports.length,
              })}
            >
              <RemoveIcon /> Remove selected
            </div>
          }
          disabled={!selectedReports.length}
          onClick={() => setReportsToDelete([...selectedReports])}
          className="fixed-height margin-left-10"
          secondaryRed
        />
      </div>
    )
  }

  const _renderDeleteModal = () => {
    if (!reportsToDelete.length) {
      return null
    }
    return (
      <Modal
        contentSeparator
        rightAlignButtons
        opened={Boolean(reportsToDelete.length)}
        button={<Button value="Confirm" onClick={onDelete} green />}
        buttonSecondary={
          <Button
            value="Cancel"
            onClick={() => setReportsToDelete([])}
            secondaryGray
          />
        }
        heading={
          <div className="delete-report-heading">
            Unlink Report <WarningIcon width={30} height={30} />
          </div>
        }
        className="delete-report-modal"
      >
        <div>
          <p>
            You are about to unlink a report from this client. This action
            cannot be undone.
          </p>
          <p>Click the Confirm button to continue.</p>
        </div>
      </Modal>
    )
  }

  const _renderFilterSection = () => {
    if (!filtersOpened) {
      return null
    }
    return (
      <ReportsFilterSection
        filter={filter}
        setFilter={setFilter}
        users={users}
        teams={teams}
        applyFilter={onApplyFilters}
      />
    )
  }

  return (
    <>
      {_renderDeleteModal()}
      <CollapsibleSection
        id="reports-section"
        header="Reports"
        defaultCollapsed={isPanelCollapsed}
        onCollapseListener={(value) => setIsPanelCollapsed(!value)}
        extras={isSuperAdmin ? _renderAddReportsButton() : null}
        wrapperClassName="form__section"
      >
        <div className="reports-section">
          {_renderHeader()}
          {_renderActions()}
          {_renderAddEditSection()}
          {_renderFilterSection()}
          <ReportsTable
            reports={visibleReports}
            selectedReports={selectedReports}
            setSelectedReports={setSelectedReports}
            setReportsToDelete={setReportsToDelete}
            setMode={setMode}
            mode={mode}
            disableActions={isAddOrEditMode || filtersOpened}
            viewMode={!isSuperAdmin || viewMode}
          />
        </div>
      </CollapsibleSection>
    </>
  )
}

ReportsSection.propTypes = {
  viewMode: PropTypes.bool,
  reportings: PropTypes.array.isRequired,
  setCompany: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
}

export default ReportsSection
