import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Api from 'easy-fetch-api'
import PropTypes from 'prop-types'
import { useStore } from 'store'

import Button from 'components/button'
import Input from 'components/input'
import { isValidEmail } from 'components/validator'
import AuthenticationContainer from 'components/authentication'

import useSession from 'modules/session'
import { setTempUserData, setUserData } from 'modules/session/actions'
import { decodeLoginRedirectPath } from 'components/utils/url'

import 'styles/authentication.scss'

const LoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [hasErrors, setHasErrors] = useState(false)
  const [submitError, setSubmitError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [emailConfirmed, setEmailConfirmed] = useState(false)
  const passwordInputRef = useRef()

  const { dispatch } = useStore()

  const [, user] = useSession()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (user) {
      Api.get({
        url: '/api/users/tfa-validation',
      }).then((result) => {
        if (result.validTfa) {
          // Redirect if user is already logged in and TFA is valid
          navigate(location?.state?.path || decodeLoginRedirectPath() || '/')
        }
      })
    }
  }, [])

  useEffect(() => {
    /* Every time an error is added, changed or removed we want to set the error flag accordingly */
    setHasErrors(Boolean(Object.keys(errors).length))
  }, [errors])

  // When the user clicks continue, the password field gets focused
  useEffect(() => {
    if (emailConfirmed) {
      passwordInputRef.current?.children?.[0]?.focus()
    }
  }, [emailConfirmed])

  const checkEmail = (email) => {
    if (!isValidEmail(email)) {
      setErrors({ email: 'Email format not valid', ...errors })
    }
  }

  const handleSubmit = (ev) => {
    checkEmail(email)

    ev.preventDefault()

    if (!isValidEmail(email)) {
      return
    }

    if (!hasErrors && emailConfirmed) {
      setLoading(true)
      doLogin()
    }

    if (!emailConfirmed) {
      setEmailConfirmed(true)
    }
  }

  const doLogin = () => {
    Api.post({
      url: '/api/users/login',
      data: { email, password },
    })
      .then((res) => {
        if (!res.success || res.error || !res.user) {
          return setSubmitError(
            res.error ||
              'Some of your login info is incorrect. Please try again.'
          )
        }
        handleSuccessLogin(res)
      })
      .catch((err) => {
        console.error(err)
        setSubmitError(err)
      })
      .finally(() => setLoading(false))
  }

  const handleSuccessLogin = (response) => {
    dispatch(setUserData(response.user))
    setTimeout(() => {
      if (response.tfa === 'auth') {
        return navigate('/multi-factor-auth')
      }
      if (response.tfa === 'setup') {
        return navigate('/multi-factor-setup')
      }

      // If the user has a valid TFA, but needs to accept the terms of use,
      // we redirect him to the terms of use page.
      if (response.hasToAcceptLegalTerms) {
        setTempUserData(response.user, response.user._id)
        return navigate(`/terms-of-use/${response.user._id}`)
      }

      if (!response.tfa) {
        window.location =
          location?.state?.path || decodeLoginRedirectPath() || '/'
      }
    }, 50)
  }

  return (
    <AuthenticationContainer
      title="Login"
      onSubmit={handleSubmit}
      error={submitError}
    >
      <Input
        type="text"
        name="email"
        disabled={loading}
        value={email}
        onChange={(e) => {
          setEmail(e)
          const currentErrors = { ...errors }
          delete currentErrors.email
          setErrors(currentErrors)
        }}
        blur={() => checkEmail(email)}
        placeholder={'Email'}
        error={email && errors.email}
        label="Email Address"
        className="input-wrapper--uppercase"
        data-cy="login-email-input"
      />
      <Input
        type="password"
        ref={passwordInputRef}
        name="password"
        autoComplete={emailConfirmed ? 'current-password' : 'off'}
        className={
          !emailConfirmed
            ? 'input-hidden input-wrapper--uppercase'
            : 'input-wrapper--uppercase'
        }
        disabled={loading}
        value={password}
        onChange={(e) => {
          setPassword(e)
        }}
        error={password && errors.password}
        placeholder={'Password'}
        label="Password"
        data-cy="login-password-input"
      />
      {emailConfirmed ? (
        <div className="align-row">
          <div className="authentication-container__forget align-center">
            Forget your <Link to={'/forgot-password'}> password</Link>?
          </div>
          <Button
            value="Log in"
            disabled={loading || !email || !password || hasErrors}
            onClick={handleSubmit}
            type="submit"
            big
            data-cy="login-button"
          />
        </div>
      ) : (
        <div className="align-row">
          <Button
            className="button--big"
            value="Next"
            disabled={!email}
            type="submit"
            data-cy="login-email-button"
          />
        </div>
      )}
    </AuthenticationContainer>
  )
}

LoginPage.propTypes = {
  location: PropTypes.object,
}

export default LoginPage
