import React from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import AssignUserModule from 'modules/users/assign-user'
import { assignUser } from 'modules/users/actions'
import useSession from 'modules/session'
import { useStore } from 'store'
import { NOT_FOUND_ROUTE } from 'routes'

const AssignUser = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { userId } = useParams()
  const [, user] = useSession()
  const { dispatch, state } = useStore()
  const { teams, companies, users } = state
  const isGlobal = location.pathname.indexOf('/global') > -1

  const existingUser =
    users.list && users.list.find((user) => user._id === userId)
  if (!existingUser) {
    navigate(NOT_FOUND_ROUTE, { replace: true })
    return null
  }

  // If we don't have valid data return to user edit page
  if (!teams.list || !companies.list || !users.list) {
    navigate(`/users/${userId}`)
    return null
  }

  const onAssign = (assignment) =>
    assignUser(dispatch, assignment)
      .then(() => setTimeout(() => navigate(`/users/${userId}`), 100))
      .catch(console.error)

  return (
    <AssignUserModule
      isAdmin={user.isSuperAdmin}
      isGlobal={isGlobal}
      user={users.list.find((user) => user._id === userId)}
      clients={companies.list}
      teams={teams.list}
      onAssign={onAssign}
    />
  )
}

export default AssignUser
