import React, { useState, useRef, useEffect } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { expressionParser } from '@decision-sciences/qontrol-common'
import Suggestions from 'components/suggestions'
import { saveSelection } from 'components/utils/caret-handling'
import { useEffectOnUpdate } from 'components/utils/custom-hooks'
import { ALERT_FILTER_ORDER } from 'modules/alerts/constants'

import './style.scss'

const { extractVariables, evaluate, OPERATORS, extractGroups } =
  expressionParser

/**
 * Displays a template builder, which allows writing / validating Formulae based on pre-defined metrics and operators.
 * Eg.
 * operators = ['+']
 * metrics = ['a', 'b']
 * In this case, 'a + b' is valid,
 * 'a + c' or 'a - b' will be invalid.
 * @param {Boolean} disabled Disabled flag
 * @param {Boolean} disabled Skip validation flag
 * @param {Array} metrics Metrics to be used for expression
 * @param {Array} operators Operators to be used for expression
 * @param {String} defaultValue Initial formula to display
 * @param {Function} onChange Function to call with (isValid, changes), where isValid shows if a formula is valid, and changes is the new value.
 * @param {String} error Error to display
 * @param {Boolean} freeTextEnabled Whether free text is enabled or not
 * @param {String} label Label to display
 * @param {String} className Class Name to add to the template element
 * @returns {JSX.Element}
 * @constructor
 */
const TemplateBuilder = ({
  disabled = false,
  skipValidation = false,
  metrics,
  operators = OPERATORS,
  defaultValue,
  onChange,
  error,
  freeTextEnabled,
  label,
  className = '',
  placeholder,
  validationPrefix = 'Expression is',
  validationPlaceholder = null,
}) => {
  const [value, setValue] = useState('')
  const [isValid, setIsValid] = useState(true)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const suggestionsRef = useRef(null)
  const textRef = useRef(null)
  const [caretPos, setCaretPos] = useState({ start: 0, end: 0 })

  useEffect(() => {
    if (defaultValue && !value) {
      textRef.current.innerHTML = defaultValue
        // escape html tags so they don't get rendered
        .replace(new RegExp('>', 'g'), '&gt;')
        .replace(new RegExp('<', 'g'), '&lt;')
      setValueAndValidate(textRef.current.innerText)
    }
  }, [defaultValue])

  /**
   * If metrics change, reevaluate CURRENT value.
   * This should not do anything if value changes
   */
  useEffectOnUpdate(() => {
    setValueAndValidate(value)
  }, [JSON.stringify(metrics)])

  /** Triggered when a suggestion is selected */
  const onSuggestionSelected = (suggestion) => {
    // Close suggestions
    setShowSuggestions(false)
    // Append text
    const isOperator = operators.indexOf(suggestion) > -1

    if (!textRef.current.innerText) {
      textRef.current.innerText = ' '
    }

    const lastNode = textRef.current.childNodes[caretPos.rangeIndex]

    const suggestionToEnter = isOperator ? suggestion : `[${suggestion}]`

    lastNode.data = `${lastNode.data.slice(
      0,
      caretPos.start
    )}${suggestionToEnter}${lastNode.data.slice(
      caretPos.start,
      lastNode.data.length
    )}`

    setValueAndValidate(textRef.current.innerText)

    // Place caret at the end of the text element
    const sel = window.getSelection()
    const range = sel.getRangeAt(0)

    const newOffset = caretPos.start + suggestionToEnter.length
    if (lastNode && newOffset < lastNode.length) {
      range.setStart(lastNode, newOffset)
      range.collapse(true)
      sel.removeAllRanges()
      sel.addRange(range)
    }

    // Focus text element
    textRef.current.focus()
  }

  /** Triggered on text change */
  const changeValue = (e) => {
    if (freeTextEnabled) {
      if (e.keyCode === 219) {
        let newValue = `${e.target.innerText}`
        // eslint-disable-next-line no-control-regex
        if (newValue.match(new RegExp('\n+', 'g'))) {
          return
        }
        newValue = `${newValue.slice(0, caretPos.start + 1)}${
          e.shiftKey ? '}' : ']'
        }${newValue.slice(caretPos.start + 1)}`

        textRef.current.innerText = newValue
        setValueAndValidate(newValue)
        // Place caret at the end of the text element
        const range = document.createRange()
        const sel = window.getSelection()
        const lastNode = textRef.current.childNodes[caretPos.rangeIndex]
        if (lastNode) {
          range.setStart(lastNode, caretPos.start + 1)
          range.collapse(true)
          sel.removeAllRanges()
          sel.addRange(range)
        }

        // Focus text element
        textRef.current.focus()
      }
    }
    // If ctrl + space, show suggestions
    if (e.keyCode === 32 && e.ctrlKey) {
      setShowSuggestions(true)
      // Focus in suggestions input
      setTimeout(() => {
        suggestionsRef.current.childNodes[0].focus()
      }, 50)
    } else {
      // Else set the value
      setValueAndValidate(e.target.innerText)
    }
    setCaretPos(saveSelection(e.target))
  }

  /** Sets the value of the text and validates it */
  const setValueAndValidate = (value) => {
    let isValid = true
    setValue(value)
    let groups = [value]

    if (freeTextEnabled) {
      groups = extractGroups(value).map((group) => group.formula)
    }
    groups.forEach((group) => {
      // Extract variables
      const variables = extractVariables(group)

      // Set dummy data for variables
      Object.keys(variables).forEach((v) => {
        if (metrics.some((metric) => metric === v)) {
          variables[v] = ''
        } else if (ALERT_FILTER_ORDER.includes(v)) {
          isValid = false
        } else {
          // exclude variables that are not allowed metrics
          delete variables[v]
        }
      })
      // Evaluate expression
      const evaluationResult = evaluate(group, variables, true) !== null
      if (!evaluationResult) {
        isValid = false
      }
    })

    if (!skipValidation) {
      setIsValid(isValid)
    }
    // Call onChange if available
    onChange &&
      onChange(
        isValid,
        value
          // turn back escaped html tags
          .replace(new RegExp('&gt;', 'g'), '>')
          .replace(new RegExp('&lt;', 'g'), '<')
      )
  }

  const handlePaste = (event) => {
    const text = event.clipboardData.getData('text/plain')
    event.preventDefault()
    event.target.innerText = `${event.target.innerText}${text}`
    const selection = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(textRef.current)
    range.collapse(false)
    selection.removeAllRanges()
    selection.addRange(range)
  }

  const suggestionsList = metrics.concat(operators)

  return (
    <div className={className}>
      {label ? <label className="template__label">{label}</label> : null}
      <div className="template">
        <div
          className={cx('template__text', {
            ['template__text--error']: Boolean(error),
            show: showSuggestions,
          })}
          data-testid="template-builder"
          spellCheck={false}
          autoCapitalize="off"
          autoCorrect="off"
          ref={textRef}
          onPaste={handlePaste}
          contentEditable={!disabled}
          placeholder={
            placeholder ||
            'Start typing formula and press Ctrl + Space for suggestions'
          }
          onKeyUp={changeValue}
          onMouseUp={(e) => {
            setCaretPos(saveSelection(e.target))
          }}
          {...(showSuggestions
            ? {
                style: {
                  height: `${textRef.current.innerHTML.length * 0.8}px`,
                },
              }
            : {})}
        />

        <div className={`template__hidden ${showSuggestions ? 'show' : ''}`}>
          <span>{value.slice(0, caretPos.start)}</span>

          <Suggestions
            key={JSON.stringify(suggestionsList)}
            ref={suggestionsRef}
            list={suggestionsList}
            show={showSuggestions}
            onBlur={() => setShowSuggestions(false)}
            onSelect={onSuggestionSelected}
          />

          <span>{value.slice(caretPos.start, value.length)}</span>
        </div>
      </div>

      {value && !skipValidation ? (
        <div className={cx('template__validity', { invalid: !isValid })}>
          {validationPrefix}{' '}
          <span
            data-testid="template-builder-status"
            className={`${!isValid ? 'invalid' : ''}`}
          >
            {isValid ? 'valid' : 'invalid'}
          </span>
        </div>
      ) : validationPlaceholder ? (
        <div className="template__validity template__validity--placeholder">
          {validationPlaceholder}
        </div>
      ) : null}
    </div>
  )
}

TemplateBuilder.propTypes = {
  disabled: PropTypes.bool,
  skipValidation: PropTypes.bool,
  metrics: PropTypes.array.isRequired,
  operators: PropTypes.array,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  freeTextEnabled: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  validationPrefix: PropTypes.string,
  validationPlaceholder: PropTypes.string,
}
export default TemplateBuilder
