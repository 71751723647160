import React from 'react'

import InputSearch from 'components/input-search'

const SearchMenu = () => {
  return (
    <>
      <div className="header__opened-menu__title">Global Search</div>
      <InputSearch
        list={[]}
        onSelect={() => {}}
        placeholder="Type to search..."
      />
    </>
  )
}

export default SearchMenu
