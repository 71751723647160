import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import DropdownWithSubsections from 'components/dropdown-with-subsections/index'

import { utils } from '@decision-sciences/qontrol-common'

import {
  DEFAULT_ELEMENTS_SINGLE,
  DEFAULT_ELEMENTS_MULTI,
} from 'modules/alerts/constants'

const { keyBy } = utils.array

export const AlertClientSelection = ({
  state,
  owner,
  error,
  setState,
  allCompanies,
  companiesList,
  user,
}) => {
  // Clients mapped by their _id for easy usage
  const clientsMap = useMemo(() => {
    if (!companiesList) {
      return {}
    }

    return {
      ...keyBy(companiesList, '_id'),
      ...companiesList.reduce(
        (prev, client) => ({ ...prev, ...keyBy(client.businessUnits, '_id') }),
        {}
      ),
    }
  }, [companiesList])

  const ownerCompanies = useMemo(() => {
    return owner.isSuperAdmin || user._id === owner._id
      ? allCompanies
      : // Filter out any companies or business units that alert owner doesn't belong to
        allCompanies.reduce((companies, option) => {
          const foundClient = owner.clients?.find(
            ({ clientId }) => clientId === option.value
          )
          const newSubsections = option.subsections.reduce(
            (subsections, option) => {
              const disabled = !foundClient?.businessUnits.some(
                (buId) => buId === option.value
              )
              if (disabled) {
                return subsections
              }
              return [...subsections, option]
            },
            []
          )
          if (!foundClient) {
            return companies
          }
          return [
            ...companies,
            {
              ...option,
              subsections: newSubsections,
            },
          ]
        }, [])
  }, [JSON.stringify(allCompanies), owner])

  const deactivatedCompanies = useMemo(() => {
    const deactivatedCompanies = new Set()
    state.alertRunning.forEach((item) => {
      if (item.running === false) {
        deactivatedCompanies.add(item.company)
      }
    })
    return [...deactivatedCompanies]
  }, [JSON.stringify(state.alertRunning)])

  const onClientsChange = (value) => {
    const newState = {
      companies: value,
      allCompaniesSelected: false,
      dynamicMetrics: [], // Reset Dynamic Metrics on Client Changes
    }

    // Changing between single/multi cient should set the default selectedElements object
    if (value.length > 1) {
      newState.selectedElements = mergeSelectedElements(DEFAULT_ELEMENTS_MULTI)
      newState.apiCallsInProgress = false
    } else {
      newState.selectedElements = mergeSelectedElements(DEFAULT_ELEMENTS_SINGLE)
      for (const [key, value] of Object.entries(newState.selectedElements)) {
        for (const element of value.elements) {
          newState.selectedElements[key].abortControllers[element] =
            new AbortController()
        }
      }
    }

    const availablePlatforms = new Set()
    value.forEach((clientId) => {
      const client = clientsMap[clientId]
      client.accounts.forEach((account) => {
        availablePlatforms.add(account.type)
      })
    })

    Object.keys(newState.selectedElements).forEach((accountType) => {
      if (!availablePlatforms.has(accountType)) {
        newState.selectedElements[accountType] = null
      }
    })

    newState.selectedAccounts = state.selectedAccounts.filter((acc) =>
      availablePlatforms.has(acc)
    )

    setState(newState)
  }

  /**
   * Function to apply the default settings on the selectedElements field for an account
   * while keeping the previous configuration
   * @param {Object} defaultStates Default states for Account selectedElements
   * @returns {Object}
   */
  const mergeSelectedElements = (defaultStates) => {
    return Object.entries(defaultStates).reduce(
      (acc, [platform, platformDefaults]) => {
        if (!state.selectedElements[platform]) {
          return acc
        }
        return {
          ...acc,
          [platform]: {
            ...platformDefaults,
            ...state.selectedElements[platform],
            // Have accounts and allAccountsSelected reset
            accounts: platformDefaults.accounts,
            allAccountsSelected: platformDefaults.allAccountsSelected,
          },
        }
      },
      {}
    )
  }

  const onAllClientsCheck = (value) => {
    setState({
      allCompaniesSelected: value,
      companies: [],
      dynamicMetrics: [], // Reset Dynamic Metrics on Client Changes
    })
  }

  return (
    <DropdownWithSubsections
      className="form__half"
      selectedItems={state.companies}
      options={ownerCompanies}
      defaultOptionText="Apply To"
      individualValueClassNames={{
        values: {
          deactivated: deactivatedCompanies,
        },
      }}
      label="Apply To"
      disabled={!user.isSuperAdmin && ownerCompanies.length <= 1}
      error={error}
      onChange={onClientsChange}
      selectAllOptions={
        owner.isSuperAdmin && user.isSuperAdmin
          ? {
              label: 'All Clients',
              allSelected: state.allCompaniesSelected,
              onCheck: onAllClientsCheck,
              ignoreDisabled: true,
            }
          : undefined
      }
    />
  )
}

AlertClientSelection.propTypes = {
  state: PropTypes.object.isRequired,
  owner: PropTypes.object.isRequired,
  error: PropTypes.string,
  setState: PropTypes.func.isRequired,
  allCompanies: PropTypes.array.isRequired,
  companiesList: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
}
