import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Tooltip from 'components/tooltip'

import './style.scss'

/**
 * Icon Component with a tooltip
 * @param options
 * @param {Node[]} options.children React children (ReactComponent svg preferred)
 * @param {String} options.className Extra classnames to be applied to icon parent
 * @param {String} options.tooltipClass Tooltip class
 * @param {String | Node} options.tooltip Tooltip content
 * @param {Function} options.onClick onClick callback
 * @param {Boolean} options.disabled Whether icon is disabled
 * @param {String} options.dataCy HTML attribute used by automated tests
 */
const Icon = ({
  children,
  className,
  tooltipClass,
  tooltip,
  onClick,
  disabled,
  dataCy,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  return (
    <div
      data-testid='icon'
      className={cx('icon-container', {
        [className]: className,
        'icon-container--clickable': !!onClick && !disabled,
        'icon-container--disabled': !!disabled,
      })}
      onMouseEnter={() => {
        tooltip && setTooltipOpen(true)
      }}
      onMouseLeave={() => {
        tooltip && setTooltipOpen(false)
      }}
      onClick={(e) => {
        onClick && !disabled && onClick(e)
      }}
      tabIndex="0"
      data-cy={dataCy}
    >
      <span className="icon-container__content">{children}</span>

      {tooltip && (
        <Tooltip
          className={tooltipClass}
          show={tooltipOpen}
          content={tooltip}
        />
      )}
    </div>
  )
}

Icon.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  tooltip: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  tooltipClass: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Icon
