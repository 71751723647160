import Api from 'easy-fetch-api'

export const ACTIONS = {
  GET_NOTIFICATION_GROUPS: 'notificationGroups.getAll',
  PUT_NOTIFICATION_GROUP: 'notificationGroups.putNotificationGroup',
  DELETE_NOTIFICATION_GROUP: 'notificationGroups.deleteNotificationGroup',
}

/**
 * Fetch notification groups
 * @param {Function} dispatch dispatch
 * @param {Object} query query object
 */
export const getNotificationGroups = (dispatch, query) => {
  return Api.get({ url: '/api/notification-group', query }).then((result) => {
    if (!result || result.error) {
      console.error(
        (result && result.error) || 'Error fetching notification groups'
      )
    } else {
      dispatch({
        type: ACTIONS.GET_NOTIFICATION_GROUPS,
        list: result.list,
      })
    }
  })
}

/**
 * Get one notification group
 * @param {String} key
 * @returns {Object} notification group
 */
export const getNotificationGroup = (key) =>
  Api.get({ url: `/api/notification-group/${key}` }).then((result) => {
    if (!result || result.error) {
      console.error(
        (result && result.error) ||
          `Error fetching notification group with key ${key}`
      )
    } else {
      return result.data
    }
  })

/**
 * Create / Update a notification group
 * @param {Function} dispatch dispatch
 * @param {Object} notificationGroup data
 */
export const createUpdateNotificationGroup = (dispatch, notificationGroup) =>
  new Promise((resolve, reject) => {
    const { _id } = notificationGroup
    const payload = {
      url: '/api/notification-group',
      data: { ...notificationGroup },
    }

    const promise = _id ? Api.put(payload) : Api.post(payload)
    promise
      .then((result) => {
        if (!result || !result.success) {
          reject(result?.error || `Could not save ${notificationGroup.name}`)
        } else {
          dispatch({
            type: ACTIONS.PUT_NOTIFICATION_GROUP,
            notificationGroup: result.data,
          })
          resolve(result)
        }
      })
      .catch(reject)
  })

/**
 * Delete a notification groups
 * @param {Function} dispatch dispatch
 * @param {String} id id of notification group to delete
 */
export const deleteNotificationGroup = (dispatch, id) =>
  Api.delete({ url: `/api/notification-group/${id}` }).then((result) => {
    if (!result || result.error) {
      console.error(
        (result && result.error) || 'Error deleting notification groups'
      )
    } else {
      dispatch({
        type: ACTIONS.DELETE_NOTIFICATION_GROUP,
        id,
      })
    }
  })
