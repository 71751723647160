import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/icon'

import { ReactComponent as DoubleChevrons } from 'assets/icon_double_chevrons.svg'
import { ReactComponent as LoadingIcon } from 'assets/icon_ring_loader.svg'

/**
 * Cell renderer for threshold recommendations
 * @param {Object} props Component props
 * @param {Object} props.threshold Threshold rendered in the table row
 * @param {Object} props.clientThresholds Configured client thresholds
 * @param {Object} props.recommendedThresholds Recommended thresholds
 * @param {Function} props.onThresholdChange Callback to change a specific threshold
 * @param {Object} props.recommendedThresholdsLoading Callback to change a specific threshold
 * @returns {React.FunctionComponent}
 */
const ThresholdRecommendationCell = ({
  threshold,
  clientThresholds,
  recommendedThresholds,
  onThresholdChange,
  recommendedThresholdsLoading,
}) => {
  const { _id } = threshold
  const disabled = !hasThresholdRecommendation(
    threshold,
    clientThresholds,
    recommendedThresholds
  )
  const recommendedValue = recommendedThresholds[_id]
  const loading = recommendedThresholdsLoading[_id]
  return (
    <div className="display-flex gap-8 align-items-center margin-right-16 associated-alerts__recommended-icon">
      <Icon
        disabled={disabled}
        onClick={() => onThresholdChange(recommendedValue.toString(), _id)}
      >
        <DoubleChevrons
          width={24}
          height={24}
          className="svg--blue flip--horizontal"
        />
      </Icon>
      <span className="italic-text">
        {loading ? (
          <LoadingIcon width="24px" height="24px" />
        ) : (
          <>
            {recommendedValue
              ? Intl.NumberFormat('en-US').format(recommendedValue)
              : '--'}
          </>
        )}
      </span>
    </div>
  )
}

ThresholdRecommendationCell.propTypes = {
  threshold: PropTypes.object.isRequired,
  clientThresholds: PropTypes.object.isRequired,
  recommendedThresholds: PropTypes.object.isRequired,
  onThresholdChange: PropTypes.func.isRequired,
  recommendedThresholdsLoading: PropTypes.object.isRequired,
}

/**
 * Header for threshold recommendations table column
 * @param {Object} props Component props
 * @param {Array} props.alertThresholds List of thresholds
 * @param {Object} props.clientThresholds Configured client thresholds
 * @param {Object} props.recommendedThresholds Recommended thresholds
 * @param {Function} props.openBulkAssignModal Callback to open the bulk assign confirmation modal
 * @returns {React.FunctionComponent}
 */
const ThresholdRecommendationHeader = ({
  alertThresholds,
  clientThresholds,
  recommendedThresholds,
  openBulkAssignModal,
}) => {
  const disabled = alertThresholds.every(
    (threshold) =>
      !hasThresholdRecommendation(
        threshold,
        clientThresholds,
        recommendedThresholds
      )
  )
  return (
    <div className="display-flex gap-8 align-items-center margin-right-16 associated-alerts__recommended-icon">
      <Icon disabled={disabled} onClick={openBulkAssignModal}>
        <DoubleChevrons
          width={24}
          height={24}
          className="svg--blue flip--horizontal"
        />
      </Icon>
      Recommended
    </div>
  )
}

ThresholdRecommendationHeader.propTypes = {
  alertThresholds: PropTypes.array.isRequired,
  clientThresholds: PropTypes.object.isRequired,
  recommendedThresholds: PropTypes.object.isRequired,
  openBulkAssignModal: PropTypes.func.isRequired,
}

const hasThresholdRecommendation = (
  threshold,
  clientThresholds,
  recommendedThresholds
) => {
  const { _id } = threshold
  const clientThreshold = clientThresholds[_id]
  if (!recommendedThresholds[_id]) {
    return false
  }
  return parseFloat(recommendedThresholds[_id]) !== parseFloat(clientThreshold)
}

export { ThresholdRecommendationCell, ThresholdRecommendationHeader }
