import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import Button from 'components/button/index'

/* Icons */
import { ReactComponent as IconSearch } from 'assets/icon_search.svg'

import './style.scss'

/**
 * Section which composes the EntitySelector component.
 * It renders the base action to apply filtering options and optional custom actions.
 * If no custom actions are given and no filtering config is available, it will not render anything.
 * @param {Object} props Component props
 * @param {React.ReactNode} [props.actions] Optional custom actions
 * @param {Boolean} props.hasFilters Flag to signal if filtering configuration was passed
 * @param {ButtonType} [props.applyFiltersButtonType = 'secondary'] Callback function to trigger data fetch with updated filters
 * @param {Function} props.onApplyFilters Callback function to trigger data fetch with updated filters
 * @param {Boolean} props.loading Loading state flag
 * @returns {React.FunctionComponent}
 */
const EntitySelectorActions = ({
  actions,
  hasFilters,
  applyFiltersButtonType = 'secondary',
  onApplyFilters,
  loading,
}) => {
  const buttonTypeProps = applyFiltersButtonType
    ? { [applyFiltersButtonType]: true }
    : undefined
  if (!actions && !hasFilters) {
    return null
  }

  return (
    <div className="entity-selector__actions">
      {hasFilters && (
        <Button
          {...buttonTypeProps}
          compact
          disabled={loading}
          value={
            <div className="align-center gap-8">
              <IconSearch width={16} height={16} className="fill-white" />
              <span>Apply search</span>
            </div>
          }
          onClick={onApplyFilters}
        />
      )}
      {actions && (
        <div className="entity-selector__extra-actions">{actions}</div>
      )}
    </div>
  )
}

export const ActionsOuterPropTypes = {
  actions: PropTypes.node,
}

EntitySelectorActions.propTypes = {
  ...ActionsOuterPropTypes,
  hasFilters: PropTypes.bool,
  applyFiltersButtonType: PropTypes.oneOf([
    'secondary',
    'secondaryRed',
    'secondaryOrange',
    'secondaryGreen',
    'secondaryGray',
    'secondaryGrayEmpty',
    'secondaryLiliac',
    'green',
    'red',
    'orange',
    'liliac',
  ]),
  onApplyFilters: PropTypes.func,
}

/**
 * @typedef {
 * 'secondary'
 * |'secondaryRed'
 * |'secondaryOrange'
 * |'secondaryGreen'
 * |'secondaryGray'
 * |'secondaryGrayEmpty'
 * |'secondaryLiliac'
 * |'green'
 * |'red'
 * |'orange'
 * |'liliac'} ButtonType
 */

export default EntitySelectorActions
