import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import Calendar from 'components/calendar'
import TimeComponent from 'components/time'
import './style.scss'

const DateTimeComponent = ({ value, onChange }) => {
  const [date, setDate] = useState(value || new Date())
  const [time, setTime] = useState(
    value ? format(new Date(value), 'HH:mm a') : ''
  )

  useEffect(() => {
    const dateToReturn = new Date(date)
    let hours = time.split(':')[0]
    let minutes = time.split(':')[1]
    const amPm = time.split(' ')[1]
    if (time) {
      if (!hours || isNaN(parseInt(hours))) {
        return
      }
      if (!minutes || isNaN(parseInt(minutes))) {
        return
      }
      hours = parseInt(hours)
      minutes = parseInt(minutes)
      if (amPm === 'PM' && hours <= 12) {
        hours = hours + 12
      }
      dateToReturn.setHours(hours)
      dateToReturn.setMinutes(minutes)
    } else {
      dateToReturn.setHours(0)
      dateToReturn.setMinutes(0)
    }
    onChange(dateToReturn)
  }, [date, time])

  return (
    <div className="date-time align-row">
      <Calendar format={'MM/dd/yyyy'} date={date} returnDate={setDate} />
      <TimeComponent value={time} onChange={setTime} placeholder={'00:00'} />
    </div>
  )
}

DateTimeComponent.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
}

export default DateTimeComponent
