import React, { useState, useEffect } from 'react'
import { useStore } from 'store'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'

import Input from 'components/input'
import { Dropdown } from 'components/dropdown'
import StickyFooter from 'components/sticky-footer'
import Spacer from 'components/spacer'
import Permissions from 'components/permissions'
import CollapsibleSection from 'components/collapsible-section'
import Table from 'components/table/beta'
import { CheckboxNoHooks } from 'components/checkbox/index'
import Button from 'components/button/index'

import { FIELD_TYPES, validate } from 'components/validator'
import { showErrorMessage } from 'modules/notifications/actions'
import { createUpdatePermissionGroup } from 'modules/permission-groups/actions'
import { getTooltipList } from 'components/utils/tooltip'
import { bulkUpdateUsers } from 'modules/users/actions'

import { useAccess, PERMISSION_TYPES, PERMISSIONS } from 'hooks/access'
import { PERMISSION_GROUP_DEFAULT } from 'modules/permission-groups/constants'
import {
  utils,
  accounts,
  entityStatus,
} from '@decision-sciences/qontrol-common'

const { compareIgnoreCase } = utils.string
const { ACCOUNT_TYPE_NAMES } = accounts
const { ENTITY_STATUS_LABEL, ENTITY_STATUS_OPTIONS } = entityStatus

/**
 * Create / Edit Permission Groups
 * @param {Object} options Options object
 * @param {Object} [options.defaultState] Pre-existing permission Group
 * @param {Function} [options.onDelete] To be called on deleting the Permission Group
 * @param {Boolean} [options.isNew] True if the Permission Group is new.
 * @param {Function} options.setDirty To be called on unsaved form changes
 * @returns {React.Component}
 */
const PermissionGroupsForm = ({
  defaultState = PERMISSION_GROUP_DEFAULT,
  onDelete,
  isNew = true,
  setDirty,
}) => {
  const [permissionGroup, setPermissionGroup] = useState(defaultState)

  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])
  const navigate = useNavigate()

  const { dispatch, state } = useStore()
  const { list: companies } = state.companies

  /**
   * In case users change (their permissions are reset)
   */
  useEffect(() => {
    setPermissionGroup((state) => ({ ...state, users: defaultState.users }))
  }, [JSON.stringify(defaultState.users)])

  const hasUserViewAccess = useAccess({
    feature: PERMISSIONS.USER_DATA_FORM,
    type: PERMISSION_TYPES.READ,
  })
  const hasUserEditAccess = useAccess({
    feature: PERMISSIONS.USER_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })
  const userTeamAccess = useAccess({
    feature: PERMISSIONS.ASSIGN_TEAMS_USER,
    type: PERMISSION_TYPES.READ,
  })
  const userAccountsAccess = useAccess({
    feature: PERMISSIONS.ASSIGN_CLIENTS_USER,
    type: PERMISSION_TYPES.READ,
  })

  const editField = (field, value) => {
    setDirty(true)
    setPermissionGroup((permissionGroup) => ({
      ...permissionGroup,
      [field]: value,
    }))
    setErrors({ ...errors, [field]: null })
  }

  const onSave = (e) => {
    e.preventDefault()
    // Validate
    setLoading(true)
    const [isValid, errors] = validate(ERROR_MAP, permissionGroup)

    if (!isValid) {
      setLoading(false)
      setErrors(errors)
      return
    }

    createUpdatePermissionGroup(dispatch, permissionGroup)
      .then(() => {
        setDirty(false)
        setTimeout(() => {
          navigate('/permission-groups')
        }, 100)
      })
      .catch((error) => {
        setErrors({ ...errors, general: error.toString() })

        showErrorMessage(
          `Could not save ${
            permissionGroup.name
          } due to error ${error.toString()}`,
          dispatch
        )
        setLoading(false)
      })
  }

  const getAccountTypes = (clients) => {
    const accounts = new Set([])
    if (!companies?.length) {
      return []
    }
    companies.forEach((company) => {
      let foundClient = null
      clients.some((client) => {
        if (client.clientId.toString() === company._id.toString()) {
          foundClient = client
          return true
        } else {
          const bu = client.businessUnits?.find((buId) =>
            company.businessUnits.some(
              (businessUnit) => businessUnit._id === buId
            )
          )
          if (bu) {
            foundClient = bu
            return true
          }
        }
      }, null)

      const companyAccounts = [...company.accounts]
      company.businessUnits.forEach(
        (bu) => bu.accounts.length && companyAccounts.push(...bu.accounts)
      )

      if (foundClient) {
        if (foundClient.accounts?.length) {
          companyAccounts.forEach((acc) => {
            foundClient.accounts.forEach((accId) => {
              if (accId === acc._id) {
                accounts.add(acc.type)
              }
            })
          })
        }
      }
    })

    return [...accounts]
  }

  let columns = [
    {
      header: 'Custom',
      size: 60,
      maxSize: 90,
      accessorFn: (row) => (!row.permissions ? 1 : 0),
      textAlign: 'center',
      cellTextAlign: 'center',
      className: 'custom-column',
      cell: (cell) =>
        cell.row.original.permissions ? (
          <div>
            <CheckboxNoHooks
              defaultValue={selectedUsers.some(
                (userId) => userId === cell.row.original._id
              )}
              onChange={(value) => {
                const newSelectedUsers = new Set(selectedUsers)
                if (value) {
                  newSelectedUsers.add(cell.row.original._id)
                } else {
                  newSelectedUsers.delete(cell.row.original._id)
                }
                setSelectedUsers([...newSelectedUsers])
              }}
            />
          </div>
        ) : null,
    },
    {
      header: '',
      id: 'actions',
      cell: (cell) => (
        <div className="table__actions">
          <div
            className="table__edit"
            onClick={() => navigate(`/users/${cell.row.original?._id}`)}
          />
        </div>
      ),
      size: 50,
      maxSize: 50,
    },
    {
      header: 'Name',
      accessorFn: (row) =>
        row.name ? row.name : `${row.firstName} ${row.lastName}`,
    },
    { header: 'Email', accessorKey: 'email' },
    {
      header: 'Account',
      accessorFn: (row) => {
        const accountsArray = getAccountTypes(row.clients)
        if (accountsArray.length === 0) {
          return 'N/A'
        }
        if (accountsArray.length === 1) {
          return ACCOUNT_TYPE_NAMES[accountsArray[0]]
        }
        return 'Multiple'
      },
      tooltip: (row) => {
        if (!row || row.isSuperAdmin) {
          return null
        }
        const accountsArray = getAccountTypes(row.clients)
        if (accountsArray.length <= 1) {
          return null
        }
        const userAccounts = accountsArray
          .map((type) => {
            return ACCOUNT_TYPE_NAMES[type]
          })
          .sort(compareIgnoreCase)
        return getTooltipList('Accounts', userAccounts)
      },
    },
    {
      header: 'Team',
      accessorFn: (row) => {
        if (row.isSuperAdmin || row.teams.length === 0) {
          return 'N/A'
        }
        if (row.teams.length === 1) {
          return row.teams[0].name
        }
        return 'Multiple'
      },
      tooltip: (row) => {
        if (!row || row.teams.length <= 1 || row.isSuperAdmin) {
          return null
        }
        const teams = row.teams.map((team) => team.name).sort(compareIgnoreCase)
        return getTooltipList('Teams', teams)
      },
    },
    {
      header: 'Updated at',
      id: 'updatedAt',
      accessorFn: (row) => format(new Date(row.updatedAt), 'MM-dd-yyyy HH:mm'),
    },
    {
      header: 'Status',
      id: 'status',
      accessorFn: (row) => {
        const { active } = row
        return ENTITY_STATUS_LABEL[active]
      },
    },
  ]
  if (!hasUserEditAccess) {
    columns = columns.filter((column) => column.id !== 'actions')
  }
  const hasCustomPermissionUsers = permissionGroup.users?.some(
    (user) => user.permissions
  )
  if (!hasUserEditAccess || !hasCustomPermissionUsers) {
    columns = columns.filter((column) => column.header !== 'Custom')
  }
  if (!userTeamAccess) {
    columns = columns.filter((column) => column.header !== 'Teams')
  }
  if (!userAccountsAccess) {
    columns = columns.filter((column) => column.header !== 'Accounts')
  }

  return (
    <>
      <form onSubmit={onSave} className="form permission-groups">
        <div className="form__section__body permission-groups__inputs">
          <div className="form__section__body__half-width-section left-side">
            <Input
              label="Permission Group Name"
              placeholder={'Enter Permission Group Name'}
              value={permissionGroup.name}
              onChange={(name) => {
                editField('name', name)
              }}
              error={errors.name}
              disabled={loading}
              className="input-wrapper--uppercase"
            />
          </div>
          <div className="form__section__body__half-width-section right-side">
            <Dropdown
              defaultState={permissionGroup.active}
              options={ENTITY_STATUS_OPTIONS}
              disabled={loading}
              label="Status"
              labelTop
              onChange={(active) => editField('active', active)}
              className="input-wrapper--uppercase"
            />
          </div>
        </div>

        {errors.general ? <div className="error">{errors.general}</div> : null}

        <Spacer margin="40px 0" />

        <CollapsibleSection
          defaultCollapsed={false}
          header="Permissions"
          uncollapsible={isNew}
        >
          <Permissions
            defaultValue={permissionGroup.permissions}
            onChange={(permissions) => editField('permissions', permissions)}
          />
        </CollapsibleSection>

        {permissionGroup.users?.length && hasUserViewAccess ? (
          <CollapsibleSection
            extras={
              hasUserEditAccess && hasCustomPermissionUsers ? (
                <Button
                  className="grey-disabled"
                  value="Override Permissions for Selected"
                  onClick={() => {
                    bulkUpdateUsers(dispatch, selectedUsers, {
                      permissions: null,
                    })
                  }}
                  disabled={!selectedUsers.length}
                />
              ) : null
            }
            header="Users"
          >
            <Table
              columns={columns}
              data={permissionGroup.users}
              paginationValues={[10, 50, 100]}
              showPagination={true}
              showSearchInput={true}
            />
          </CollapsibleSection>
        ) : null}
      </form>
      <StickyFooter
        buttons={[
          {
            value: isNew ? 'Save Permission Group' : 'Save Changes',
            onClick: onSave,
          },
          {
            value: 'Cancel',
            onClick: () => navigate('/permission-groups'),
            secondaryGray: true,
          },
          {
            value: 'Delete Permission Group',
            onClick: () => onDelete(defaultState),
            renderCondition: !isNew && onDelete,
            type: 'secondaryRed',
          },
        ]}
      />
    </>
  )
}

const ERROR_MAP = {
  name: FIELD_TYPES.REQUIRED,
  active: FIELD_TYPES.BOOLEAN,
}

PermissionGroupsForm.propTypes = {
  defaultState: PropTypes.object,
  isNew: PropTypes.bool,
  onDelete: PropTypes.func,
  setDirty: PropTypes.func.isRequired,
}

export default PermissionGroupsForm
