import React, { useState, useEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import isEqual from 'lodash.isequal'
import cx from 'classnames'

/** Components & Assets */
import { CheckboxNoHooks } from 'components/checkbox'
import { ReactComponent as SearchIcon } from 'assets/icon_search.svg'
import { ReactComponent as PlusIcon } from 'assets/icon_plus_blue.svg'

import Loader from 'components/loader'

/** Functions */
import { useOnClickOutside } from 'hooks/outside-click'
import { calculateListPosition } from 'components/utils/list-position'
import { preventScrollingBody } from 'components/utils/dom-manipulation'
import { utils } from '@decision-sciences/qontrol-common'

/** Styling */
import './style.scss'
import ScssConstants from '../../styles/shared.module.scss'

const { escapeRegExp } = utils.string

/**
 * Use DropDown hook for building Select elements
 * @param params
 * @param {Array} params.options  Can be array of Strings or Array of objects: {value: String, label: String}}
 * @param {String} [params.label] label to show
 * @param {String} [params.defaultState] default element selected
 * @param {Array} [params.selectedItems] used for multi dropdown
 * @param {Function} [params.onChange] Optional onChange callback
 * @param {String}[ params.error] error
 * @param {Boolean} [params.disabled] Disabled
 * @param {String} [params.defaultOptionText] Default text to show (placeholder)
 * @param {String} [params.className] Class Name
 * @param {Object} [params.selectAllOptions] Options for Select All
 * @param {Boolean} [params.selectAllOptions.label] Label to show in the dropdown next to the checkbox
 * @param {Boolean} [params.selectAllOptions.allSelectedLabel] Label to show when all options are selected
 * @param {Function} [params.selectAllOptions.onCheck] On checking All Selected
 * @param {Object} [params.individualValueClassNames] attributes set on individual values {<attribute>: ['value1', 'value2', ...]}
 * @param {Boolean} [params.individualValueClassNames.defaultValue] in case we have individualValueAttributes set but no values
 * @param {Array} [params.individualValueClassNames.defaultClassName] className set on individual values {<attribute>: ['value1', 'value2', ...]}
 * @param {Boolean} [params.showOptionsInPlaceholder] show selected options as placeholder
 * @param {Boolean} [params.hideSelectAllOnSearch] Hide "Select All" label on searching
 * @param {Function} [params.optionRenderer] Custom option renderer
 * @param {Boolean} [params.disableSearch] Disable search functionality
 * @param {String} [params.placeholder] Text placeholder to be displayed when there isn't an option selected
 * @param {Number} [params.optionsHeight] Max height of selectable option section in pixels
 * @param {Boolean} [params.buttonDropdown] Display the dropdwon as a button with a plus icon and options opening under it.
 * @param {React.ReactNode} [params.customIcon] Custom icon to display. By default it is a blue plus icon when buttonDropdown prop is set to true.
 * @param {Boolean} [params.multiSelect] Multiple-select dropdown
 * @param {Number} [params.buttonLabel] Label to display next to the button icon.
 * @param {Boolean} [params.dark] Dark mode for the dropdown.
 * @returns {any[]|String}
 */
const DropdownWithSubsections = ({
  options,
  label,
  defaultState = '',
  selectedItems = [],
  onChange,
  error,
  textOnlyError,
  disabled,
  defaultOptionText = '',
  className,
  selectAllOptions,
  individualValueClassNames,
  showOptionsInPlaceholder = true,
  hideSelectAllOnSearch = false,
  optionRenderer,
  disableSearch,
  placeholder = '',
  isLoading = false,
  optionsHeight,
  buttonDropdown,
  customIcon,
  multiSelect = true,
  buttonLabel,
  dark = false,
  ...other
}) => {
  const [listOpened, setListOpened] = useState(false)
  const [displayedOptions, setDisplayedOptions] = useState(options || [])
  const [searchValue, setSearchValue] = useState('')
  const [highlightedElIndex, setHighlightedElIndex] = useState(0)
  const [optionsDisplayedUpwards, setOptionsDisplayedUpwards] = useState(false)
  const searchRef = useRef()
  const selectedRef = useRef()
  const optionsRef = useRef()
  const dropdownRef = useRef()

  /**
   * The actual items displayed as selected
   * In case allSelected is checked, we might have an empty selectedItems array, when everything is technically checked.
   */
  const safeSelected = useMemo(() => {
    if (selectAllOptions?.allSelected) {
      const allOptions = options.reduce((prev, current) => {
        if (current.subsections) {
          const newArray = [
            ...prev,
            ...current.subsections.reduce(
              (prev, current) => [...prev, current.value],
              []
            ),
          ]

          if (!current.disabled) {
            newArray.push(current.value)
          }

          return newArray
        }
        return [...prev, current.value]
      }, [])

      return allOptions
    }

    return (
      (Array.isArray(selectedItems) || !selectedItems
        ? selectedItems
        : [selectedItems]) || []
    )
  }, [JSON.stringify(selectedItems), selectAllOptions])

  /** Create a unique ID */
  const id = useMemo(() => `use-dropdown-${uuidv4()}`, [])

  const previousOptionsRef = useRef(options)
  /** On Options change - set displayed options and handle deep comparison of options */
  useEffect(() => {
    if (!isEqual(options, previousOptionsRef.current)) {
      setDisplayedOptions(options)
    }
    previousOptionsRef.current = options
  }, [options])

  /** On list opened/closed */
  useEffect(() => {
    // Prevent scrolling body
    preventScrollingBody(listOpened)
  }, [listOpened])

  const getAdditionalClasses = (itemValue) => {
    if (!individualValueClassNames) {
      return []
    }

    return Object.entries(individualValueClassNames.values).reduce(
      (arr, [key, values]) => {
        if (!values) {
          return arr
        }
        const found = values.some((value) => value === itemValue)
        if (!found) {
          return individualValueClassNames.defaultClassName
            ? [...arr, individualValueClassNames.defaultClassName]
            : arr
        }
        return [...arr, key]
      },
      []
    )
  }

  const toggleListOpened = (value = null) => {
    if (disabled || isLoading) {
      return
    }

    if (!listOpened) {
      setSearchValue('')
      setHighlightedElIndex(0)
      setTimeout(() => {
        if (selectedRef.current) {
          optionsRef.current &&
            optionsRef.current.scrollTo({
              top: selectedRef.current.offsetTop,
            })
          setHighlightedElIndex(
            parseInt(selectedRef.current.getAttribute('data-index') - 1)
          )
        }

        searchRef && searchRef.current?.focus()
      })
    } else {
      setSearchValue('')
    }
    setOptionsDisplayedUpwards(
      calculateListPosition(
        listOpened,
        dropdownRef,
        optionsHeight || ScssConstants.multiSelectDropdownHeight
      )
    )
    if (value !== null) {
      setListOpened(value)
    } else {
      setListOpened(!listOpened)
    }
  }

  /** Key-down handler */
  const onKeyDown = (e) => {
    const { keyCode } = e
    const isSearchFocused = document.activeElement === searchRef.current
    if (isSearchFocused) {
      return
    }

    /** Space key */
    if (keyCode === 32) {
      e.preventDefault()
      return toggleListOpened()
    }
    let nextIndex = 0
    /** Down arrow */
    if (keyCode === 40) {
      e.preventDefault()
      if (highlightedElIndex < displayedOptions.length - 1) {
        nextIndex = highlightedElIndex + 1
      } else {
        nextIndex = 0
      }
    }
    /** Up arrow */
    if (keyCode === 38) {
      e.preventDefault()
      if (highlightedElIndex === 0) {
        nextIndex = displayedOptions.length - 1
      } else {
        nextIndex = highlightedElIndex - 1
      }
    }
    setHighlightedElIndex(nextIndex)
    const selected =
      optionsRef.current &&
      optionsRef.current.querySelector(`[data-index='${nextIndex + 1}']`)
    if (selected && optionsRef.current) {
      if (
        selected.offsetTop + selected.clientHeight >
        optionsRef.current.scrollTop + optionsRef.current.clientHeight
      ) {
        optionsRef.current.scrollTo({ top: selected.offsetTop })
      }
      if (selected.offsetTop < optionsRef.current.scrollTop) {
        optionsRef.current.scrollTo({ top: selected.offsetTop })
      }
    }
    if (listOpened) {
      /** Enter key */
      if (keyCode === 13) {
        if (listOpened && displayedOptions) {
          onOptionSelected(displayedOptions[highlightedElIndex || 0].value, e)
        }
        e.preventDefault()
        return toggleListOpened()
      }
    }
  }

  useEffect(() => {
    if (searchValue) {
      let newOptions = [...options]
      setHighlightedElIndex(0)
      const caseInsensitive = new RegExp(escapeRegExp(searchValue), 'i')
      newOptions = options.reduce((array, option) => {
        const filteredSubsections = Array.isArray(option.subsections)
          ? option.subsections.filter((sub) =>
              (sub.label || sub).match(caseInsensitive)
            )
          : []
        if (!filteredSubsections?.length) {
          return (option.label || option).match(caseInsensitive)
            ? [...array, option]
            : array
        } else {
          return [...array, { ...option, subsections: filteredSubsections }]
        }
      }, [])
      setDisplayedOptions(newOptions)
    } else {
      setDisplayedOptions(options)
    }
  }, [searchValue])

  /** On item changed handler */
  const onOptionSelected = (value, e, extra) => {
    if (e) {
      e.preventDefault()
    }
    let newValues
    if (multiSelect) {
      newValues = [...safeSelected]
      const index = newValues.indexOf(value)
      // For multi-select, if option is not selected, select it
      if (index === -1) {
        newValues.push(value)
      } else {
        // Else remove it
        newValues.splice(index, 1)
      }
    } else {
      // If single select, return selected option
      newValues = value
    }
    onChange(newValues, extra)
  }

  let selectedElement = defaultState

  const found = options?.find((el) => el.value === selectedElement)
  if (found) {
    selectedElement = found
  }

  // Compute what to show in the select element
  let visibleOption = null

  if (selectAllOptions?.allSelected && showOptionsInPlaceholder) {
    visibleOption =
      selectAllOptions?.allSelectedLabel ||
      selectAllOptions?.label ||
      'All Selected'
  } else {
    if (safeSelected.length && options.length && showOptionsInPlaceholder) {
      // Show dismissible selected elements

      const opts = safeSelected.reduce((arr, value) => {
        let found
        options.some((el) => {
          if (el.value === value) {
            found = el
            return true
          }
          const foundInSubsection = el.subsections?.find(
            (el) => el.value === value
          )
          if (foundInSubsection) {
            found = foundInSubsection
            return true
          }
          return false
        })
        if (found) {
          return arr.concat(found.label)
        }
        return arr
      }, [])

      visibleOption = opts.join(', ')
    } else {
      // Multi-select case with nothing selected
      visibleOption = defaultOptionText
    }
  }

  const classes = cx('drop-down input-wrapper', className, {
    'input-wrapper--error': !!error,
    'input-wrapper--button': buttonDropdown,
  })

  useOnClickOutside(dropdownRef, () => listOpened && toggleListOpened(false))

  return (
    <label data-testid="dropdown" htmlFor={id} className={classes} {...other}>
      {label && (
        <label data-cy="dropdown-label" className="general-label">
          {label}
        </label>
      )}
      {/* Select */}
      <div className="dropdown-with-subsections">
        <div
          className={cx('select', {
            'select--opened': listOpened,
            'select--disabled': disabled || options.length === 0 || isLoading,
            'select--loading': isLoading,
            select__button: buttonDropdown,
            'select--dark': dark,
          })}
          ref={dropdownRef}
          role="listbox"
          tabIndex={0}
          id={id}
          onClick={(e) => {
            if (
              !listOpened ||
              (listOpened && e.target === dropdownRef.current)
            ) {
              toggleListOpened()
            }
          }}
          onKeyDown={onKeyDown}
        >
          {/* Icon*/}
          {buttonDropdown ? (
            <div className="button-content">
              {customIcon ? (
                customIcon
              ) : (
                <PlusIcon
                  width={16}
                  height={16}
                  className={cx('fill-light-blue', {
                    'margin-right-5': buttonLabel,
                  })}
                />
              )}

              {buttonLabel ? buttonLabel : null}
            </div>
          ) : null}
          {/* Visible option */}
          <div
            role="option"
            id={`${id}-0`}
            data-index="0"
            aria-selected="true"
            className={cx('dropdown--pointer', {
              'dropdown--placeholder': visibleOption === defaultOptionText,
              'dropdown--placeholder--error': error || textOnlyError,
            })}
          >
            {customIcon ? customIcon : null}
            {visibleOption || placeholder}
          </div>
          {isLoading && <Loader className="select__loader" />}

          <div
            ref={optionsRef}
            className={cx('select__options', {
              'select__options--open': listOpened,
              'select__options--up': optionsDisplayedUpwards,
            })}
          >
            {disableSearch ? null : (
              <div
                className="select__search--bottom"
                onClick={(e) => e.stopPropagation()}
              >
                <SearchIcon className="fill-light-blue search-icon" alt="" />
                <input
                  placeholder="Search..."
                  ref={searchRef}
                  className={cx({ select__search: true, hidden: !listOpened })}
                  onChange={(e) => {
                    setSearchValue(e.target.value)
                  }}
                  value={searchValue}
                />
              </div>
            )}
            {disableSearch ? null : <div className="select__separator" />}
            {selectAllOptions &&
              ((hideSelectAllOnSearch && !searchValue.length) ||
                !hideSelectAllOnSearch) && (
                <div className="select__subdivision">
                  <div role="option" tabIndex="0" id={`${id}-0`} data-index={0}>
                    <CheckboxNoHooks
                      label={selectAllOptions?.label || 'All'}
                      defaultValue={selectAllOptions.allSelected}
                      onChange={(v) => selectAllOptions.onCheck(v)}
                    />
                  </div>
                  <div className="select__separator" />
                </div>
              )}

            {/* Other options */}
            {Array.isArray(displayedOptions) &&
              Boolean(displayedOptions.length) &&
              displayedOptions.map((option, index) => {
                // Skip disabled options without subsections.
                if (option.disabled && option.subsections?.length === 0) {
                  return null
                }
                const { value, label } = option
                let optionHtml = label

                const isOptionChecked =
                  safeSelected.indexOf(value) > -1 ||
                  (selectAllOptions?.ignoreDisabled &&
                    selectAllOptions?.allSelected)

                optionHtml = optionRenderer ? (
                  optionRenderer(option)
                ) : !option.noCheckbox && multiSelect ? (
                  <>
                    <CheckboxNoHooks
                      label={label}
                      isChecked={isOptionChecked}
                      disabled={option.disabled}
                    />
                    {option.description && (
                      <div className="select__description">
                        {option.description}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="select__no-checkbox">{label}</div>
                )

                let isSelected = false
                if (selectedElement) {
                  if (value === selectedElement.value) {
                    isSelected = true
                  }
                }

                const additionalClasses = getAdditionalClasses(option.value)

                return (
                  <div key={index} className="select__subdivision">
                    <div
                      tabIndex="0"
                      role="option"
                      id={`${id}-${index + 1}`}
                      data-index={index + 1}
                      ref={isSelected ? selectedRef : undefined}
                      className={cx(
                        {
                          selected: isSelected,
                          highlighted:
                            index === highlightedElIndex &&
                            !option.disabled &&
                            isSelected,
                          hasLabel: !!option.sectionLabel,
                          select__subdivision__main: option.subsections?.length,
                          disabled: option.disabled,
                        },
                        ...additionalClasses
                      )}
                      onClick={(e) => {
                        e.preventDefault()
                        if (!option.disabled) {
                          onOptionSelected(value, e, option.extra)
                        }
                        if (!multiSelect) {
                          setListOpened(false)
                        }
                      }}
                    >
                      {optionHtml}
                      {option.sectionLabel && (
                        <div className="select__section-label">
                          {option.sectionLabel}
                        </div>
                      )}
                    </div>
                    {(option.subsections || []).map((subsection, idx) => {
                      const additionalClasses = getAdditionalClasses(
                        subsection.mimicParent ? option.value : subsection.value
                      )

                      return (
                        <div
                          key={idx}
                          tabIndex="0"
                          role="option"
                          id={`${id}-${index + 1}-${idx + 1}`}
                          data-index={index + 1}
                          ref={isSelected ? selectedRef : undefined}
                          className={cx(
                            'select__subdivision__item',
                            {
                              selected: isSelected,
                              highlighted: idx === highlightedElIndex,
                              disabled: subsection.disabled,
                            },
                            ...additionalClasses
                          )}
                          onClick={(e) => {
                            e.preventDefault()
                            if (subsection.disabled || subsection.mimicParent) {
                              return
                            }
                            onOptionSelected(
                              subsection.value,
                              e,
                              subsection.extra
                            )
                            if (!multiSelect) {
                              setListOpened(false)
                            }
                          }}
                        >
                          {!multiSelect ? (
                            <div key={subsection.value}>{subsection.label}</div>
                          ) : (
                            <CheckboxNoHooks
                              label={subsection.label}
                              disabled={
                                subsection.disabled || subsection.mimicParent
                              }
                              isChecked={
                                subsection.mimicParent
                                  ? isOptionChecked
                                  : safeSelected.indexOf(subsection.value) >
                                      -1 ||
                                    (selectAllOptions?.ignoreDisabled &&
                                      selectAllOptions.allSelected)
                              }
                            />
                          )}
                        </div>
                      )
                    })}
                  </div>
                )
              })}

            {Boolean(options.length) && !displayedOptions.length && (
              <div
                className="select__no-results"
                onClick={(e) => e.stopPropagation()}
              >
                No Results
              </div>
            )}
          </div>
        </div>
      </div>

      {error && <div className="error-wrapper">{error}</div>}
    </label>
  )
}

DropdownWithSubsections.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  defaultState: PropTypes.any, // Used for single type dropdown
  selectedItems: PropTypes.any, // Used for multi type dropdown
  onChange: PropTypes.func,
  error: PropTypes.any,
  textOnlyError: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultOptionText: PropTypes.string,
  className: PropTypes.string,
  selectAllOptions: PropTypes.object,
  individualValueClassNames: PropTypes.object,
  showOptionsInPlaceholder: PropTypes.bool,
  hideSelectAllOnSearch: PropTypes.bool,
  optionRenderer: PropTypes.func,
  disableSearch: PropTypes.bool,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  optionsHeight: PropTypes.number,
  buttonDropdown: PropTypes.bool,
  customIcon: PropTypes.node,
  multiSelect: PropTypes.bool,
  buttonLabel: PropTypes.string,
  dark: PropTypes.bool,
}

export default DropdownWithSubsections
