import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'store'

/* Components */
import LabelWrapper from 'components/label-wrapper'

/* Subsections */
import KpiSection from 'modules/companies/subsections/kpi-section/index'
// import { ReportingView } from 'modules/companies/subsections/reporting/reporting-view'

/* Constants */
import {
  timezoneCountry,
  entityStatus,
} from '@decision-sciences/qontrol-common'

const { TIMEZONES } = timezoneCountry
const { ENTITY_STATUS_LABEL } = entityStatus

/**
 * Displays the view-only version of Client Form
 * @param {Object} params React Params
 * @param {Object} params.originalCompany Company data
 * @param {Array} [params.superAdmins] Special Metrics Array
 * @param {Array} [params.accounts] Accounts Array
 */
const ClientView = ({ originalCompany, superAdmins = [], accounts }) => {
  const { state } = useStore()

  const users = state.users.list

  const company = useMemo(() => {
    const extractUserName = (id) => {
      const user = users.find((user) => user._id === id)
      return user ? user.name : null
    }

    return {
      ...originalCompany,
      accountLead: extractUserName(originalCompany.accountLead),
      defaultApprover: extractUserName(originalCompany.defaultApprover),
      backupApprover: extractUserName(originalCompany.backupApprover),
    }
  }, [JSON.stringify(users), JSON.stringify(originalCompany)])

  return (
    <div className="client-view">
      <div className="align-row form__section__body">
        <div className="form__section__body__half-width-section">
          <LabelWrapper className="margin-bottom-22" label="Client Name">
            {company.name}
          </LabelWrapper>
          <div className="align-row margin-bottom-22">
            <LabelWrapper label="Office Phone">
              {company.contact.phone}
            </LabelWrapper>
            <LabelWrapper label="Alternate Phone">
              {company.contact.alternatePhone}
            </LabelWrapper>
          </div>

          <div className="align-row margin-bottom-22">
            <LabelWrapper label="Primary Contact">
              {company.contact.name}
            </LabelWrapper>
            <LabelWrapper label="Primary Contact Phone">
              {company.contact.primaryContactPhone}
            </LabelWrapper>
          </div>
          <LabelWrapper
            className="margin-bottom-22"
            label="Primary Contact Email"
          >
            {company.contact.email}
          </LabelWrapper>
          <LabelWrapper className="margin-bottom-22" label="Slack Channel">
            {company.slack}
          </LabelWrapper>
          <LabelWrapper label="Default Approver">
            {company.defaultApprover}
          </LabelWrapper>
          <LabelWrapper label="Backup Approver">
            {company.backupApprover}
          </LabelWrapper>
        </div>

        <div className="form__section__body__half-width-section right-side">
          {/* Client ID */}
          <LabelWrapper className="margin-bottom-22" label="Client ID">
            {company.clientId}
          </LabelWrapper>
          {/* Status */}
          <LabelWrapper className="margin-bottom-22" label="Status">
            {ENTITY_STATUS_LABEL[company.active]}
          </LabelWrapper>
          <LabelWrapper className="margin-bottom-22" label="Website">
            {company.webSite}
          </LabelWrapper>

          <div className="align-row margin-bottom-22">
            <LabelWrapper label="Country">{company.country}</LabelWrapper>
            <LabelWrapper label="State">{company.state}</LabelWrapper>
          </div>
          <LabelWrapper label="Time Zone" className="margin-bottom-22">
            {TIMEZONES.find((tmz) => tmz.name === company.timeZone)?.label}
          </LabelWrapper>
          <LabelWrapper label="Account Lead" className="margin-bottom-22">
            {company.accountLead}
          </LabelWrapper>
        </div>
      </div>

      {/* <ReportingView client={company} /> */}

      <KpiSection readOnly company={company} />
    </div>
  )
}

ClientView.propTypes = {
  originalCompany: PropTypes.object.isRequired,
  superAdmins: PropTypes.array,
  accounts: PropTypes.array,
}

export default ClientView
