import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import './style.scss'

/**
 * Toggle Component
 * @param {Object} params
 * @param {String} params.label Label to be shown alongside Toggle
 * @param {String} params.rightSideLabel Additional label to be shown to the right
 * @param {Boolean} params.value value for input
 * @param {Function} params.onChange On change handler
 * @param {Boolean} params.defaultChecked Default toggle state
 * @param {String} params.className Additional classnames for component
 * @param {Boolean} params.disabled Disabled state
 * @param {Boolean} params.showOnOff When true, an additional section with either "On" or "Off" is displayed
 * @param {Boolean} params.manualToggle When true, checking and unchecking is controlled from the outside.
 * @param {Object} params.other Other props
 * @constructor
 */
const Toggle = ({
  label,
  rightSideLabel,
  value,
  onChange,
  defaultChecked = false,
  className = '',
  disabled,
  showOnOff,
  manualToggle = false,
  ...other
}) => {
  const [checked, setChecked] = useState(defaultChecked)

  const changeHandler = (e) => {
    e.preventDefault()
    if (!disabled) {
      !manualToggle && setChecked(!checked)
      onChange && onChange(!checked)
    }
  }

  useEffect(() => {
    if (defaultChecked !== checked) {
      setChecked(defaultChecked)
    }
  }, [defaultChecked])

  const classes = cx('switch-container align-row', className, {
    'switch-container--disabled': disabled,
  })

  return (
    <div className={classes} data-testid="toggle-container">
      {label &&
        (typeof label === 'string' ? (
          <label className="switch-container__label">{label}</label>
        ) : (
          label
        ))}
      <div
        className="switch-container__switch-toggle"
        data-testid="toggle-label"
        onClick={changeHandler}
      >
        {showOnOff && (
          <span
            className="switch-container__on-off"
            data-testid="switch-container__on-off"
          >
            {checked ? 'ON' : 'OFF'}
          </span>
        )}
        <input
          data-testid="toggle-input"
          value={value}
          tabIndex="0"
          checked={checked}
          onChange={() => {}}
          className="switch-container__switch"
          type="radio"
          {...other}
        />
        <span
          data-testid="toggle-switch"
          className={`switch-button${checked ? ' switch-button--checked' : ''}`}
        />
      </div>
      {rightSideLabel && (
        <span className="switch-container__label right">{rightSideLabel}</span>
      )}
    </div>
  )
}

Toggle.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rightSideLabel: PropTypes.string,
  value: PropTypes.any,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  showOnOff: PropTypes.bool,
  manualToggle: PropTypes.bool,
}

export default Toggle
