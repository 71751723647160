import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { reports } from '@decision-sciences/qontrol-common'

/* Components */
import InputText from 'components/input'
import { Dropdown } from 'components/dropdown/index'
import CreateActions from 'modules/companies/subsections/campaign-exclusions-section/components/campaign-exclusions-create/create-actions/index'

/* Constants */
import {
  DEFAULT_REPORT,
  PAGE_MODE,
  PROPERTIES,
  getReportErrors,
} from 'modules/companies/subsections/reports/utils'

const { NAME, EMBED_CODE, TEAM_PERMISSIONS, USER_PERMISSIONS, SOURCE } =
  PROPERTIES
const { getSourceBasedOnURL } = reports

/**
 * Report Edit Section
 */
const ReportsAddEditSection = ({
  reports = [],
  reportings = [],
  onSave,
  onCancel,
  users,
  teams,
  mode,
}) => {
  const singleReport = reports.length === 1
  const [errors, setErrors] = useState({})
  const [report, setReport] = useState(
    singleReport ? reports[0] : DEFAULT_REPORT
  )

  const canSave = useMemo(() => {
    const hasTeams = report.teamPermissions?.length
    const hasUsers = report.userPermissions?.length
    return hasTeams || hasUsers
  }, [report])

  const onChangeProperty = (property, value) => {
    const updatedReport = { ...report }
    switch (property) {
      case TEAM_PERMISSIONS:
        updatedReport[property] = value.map((val) => {
          const team = teams.find(({ _id }) => _id === val)
          return { _id: val, name: team.name }
        })
        break
      case USER_PERMISSIONS:
        updatedReport[property] = value.map((val) => {
          const user = users.find(({ _id }) => _id === val)
          return {
            _id: val,
            firstName: user.firstName,
            lastName: user.lastName,
          }
        })
        break
      case EMBED_CODE:
        updatedReport[SOURCE] = getSourceBasedOnURL({
          ...updatedReport,
          [property]: value,
        })
        updatedReport[property] = value
        break
      default:
        updatedReport[property] = value
        break
    }
    setReport(updatedReport)
    errors[property] && setErrors({ ...errors, [property]: false })
  }

  const onSaveClicked = () => {
    const shouldCheckForErrors =
      mode === PAGE_MODE.ADD || (mode === PAGE_MODE.EDIT && singleReport)
    if (shouldCheckForErrors) {
      // Single Edit/Add mode
      const reportErrors = getReportErrors(report, reportings)
      const isValidReport = !Object.values(reportErrors).filter((r) => r).length
      if (isValidReport) {
        onSave(report)
      } else {
        setErrors(reportErrors)
      }
    } else {
      // Bulk Edit mode
      delete report.name
      delete report.embedCode
      onSave(report)
    }
  }

  return (
    <>
      <div className="reports-edit-section">
        <div className="reports-edit-section__column">
          <div
            className={cx('general-label', {
              'general-label--error': errors.name,
            })}
          >
            REPORT NAME
          </div>
          <InputText
            error={errors.name}
            value={report.name || ''}
            placeholder={singleReport ? 'Enter Name' : '<varies>'}
            onChange={(val) => onChangeProperty(NAME, val)}
            className={'width-50'}
            disabled={!singleReport}
          />
          <div className="general-label">TEAM PERMISSIONS</div>
          <Dropdown
            options={teams.map((user) => ({
              label: user.name,
              value: user._id,
            }))}
            defaultOptionText={singleReport ? 'Select Teams' : '<varies>'}
            selectedItems={report.teamPermissions.map((team) => team._id) || []}
            onChange={(val) => onChangeProperty(TEAM_PERMISSIONS, val)}
            error={errors.team}
            multiSelect={true}
            selectAll
            sortOptions
            optionsHeight={500}
          />
        </div>

        <div className="reports-edit-section__column">
          <div
            className={cx('general-label', {
              'general-label--error': errors.embedCode,
            })}
          >
            EMBED CODE
          </div>
          <InputText
            error={errors.embedCode}
            value={report.embedCode || ''}
            placeholder={singleReport ? 'Enter Code' : '<varies>'}
            onChange={(val) => onChangeProperty(EMBED_CODE, val)}
            className={'width-50'}
            disabled={!singleReport}
          />
          <div className="general-label">USER PERMISSIONS</div>
          <Dropdown
            options={users.map(({ name, _id, email }) => ({
              label: name,
              value: _id,
              description: email,
            }))}
            defaultOptionText={singleReport ? 'Select Users' : '<varies>'}
            selectedItems={
              report.userPermissions.map((user) => user._id || user) || []
            }
            onChange={(val) => onChangeProperty(USER_PERMISSIONS, val)}
            error={errors.users}
            multiSelect={true}
            selectAll
            sortOptions
            optionsHeight={500}
          />
        </div>
      </div>
      <CreateActions
        editMode={mode === PAGE_MODE.EDIT}
        saveDisabled={!canSave}
        onSaveClicked={onSaveClicked}
        onCancelClicked={onCancel}
        lineDivider={false}
        className={'borders-top-bottom'}
      />
    </>
  )
}
export default ReportsAddEditSection

ReportsAddEditSection.propTypes = {
  reports: PropTypes.array.isRequired,
  reportings: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
}
