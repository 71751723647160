import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// Hooks
import { useOnClickOutside } from 'hooks/outside-click'

// Components
import Loader from 'components/loader'
import InputText from 'components/input'
import Icon from 'components/icon'

import { useDebouncedEffect } from 'components/utils/custom-hooks'

// Assets
import { ReactComponent as IconSearch } from 'assets/icon_search.svg'
import { ReactComponent as ClearIcon } from 'assets/icon_close_red.svg'

import './style.scss'

/**
 * Component which can filter a list of items, displaying the results in a dropdown
 *
 * @param {Object} props Component props
 * @param {Array} props.list List of items to filter
 * @param {Array} props.options List of items to display
 * @param {Function} props.onSelect Callback fired when an item is selected
 * @param {String} props.valueAttribute Object field name to filter by
 * @param {String} props.secondaryAttribute Secondary object field name to filter by
 * @param {Number} props.itemsToShow Number of items visible
 * @param {String} props.placeholder Placeholder text
 * @param {*} props.defaultValue Default selected value
 * @param {Function} props.returnValue
 * @param {String} [params.error] error
 * @param {Function} [params.optionRenderer] Custom renderer method for the Options
 * @param {Boolean} props.hasButton Boolean flag for showing a button at the end of the input
 * @param {Boolean} props.hasIcon Boolean flag for showing a prefix icon
 * @param {ICON_VARIANT} props.iconVariant Color variant of the icon
 * @param {Boolean} props.small Whether input is the smaller one (smaller hight)
 * @param {Boolean} [params.loading] If true, display loading spinner
 * @param {Function} props.onSearch Callback for when searching something for manual Search Result display.
 * @param {Boolean} props.multiSelect There can be multiple elements selected at one. If so, don't close the list after selecting only one item
 * @returns {React.Component}
 */
const InputSearch = ({
  list,
  options,
  onSelect,
  valueAttribute = 'value',
  secondaryAttribute,
  itemsToShow = 5,
  placeholder = '',
  error = '',
  defaultValue,
  returnValue,
  hasIcon,
  iconVariant = ICON_VARIANT.GRAY,
  hasButton,
  small,
  loading,
  onSearch,
  disabled,
  optionRenderer,
  hasClear,
  multiSelect,
}) => {
  const [value, setValue] = useState('')
  const [items, setItems] = useState([])

  const dropdownRef = useRef()

  useOnClickOutside(dropdownRef, () => {
    if ((list || options)?.length) {
      setValue('')
      setItems([])
    }
  })

  /**
   * Whenever the search value changes (and stays changed for 300ms), onSearch is called.
   */
  useDebouncedEffect(
    () => {
      onSearch && onSearch(value)
    },
    500,
    [value, onSearch]
  )

  const inputSearchClass = cx('input-search', {
    'input-search--has-icon': hasButton,
    'input-search--has-icon-left': hasIcon,
    'input-search--small': small,
    [`input-search--color-${iconVariant}`]: iconVariant,
  })

  useEffect(() => {
    if (defaultValue !== value) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  const onValue = (val) => {
    setValue(val)
    if (val && list) {
      setItems(
        list.filter((el) => {
          // Search in value attribute
          let str = el[valueAttribute].toLowerCase()
          // Also search in secondary attribute, if provided
          if (secondaryAttribute) {
            str += el[secondaryAttribute].toLowerCase()
          }
          return str.indexOf(val.toLowerCase()) > -1
        })
      )
    } else {
      setItems([])
    }
  }

  const selectFirst = () => {
    if (items.length) {
      onValueSelected(items[0])
    }
  }

  const onValueSelected = (val) => {
    onSelect && onSelect(val)
    setValue('')
    setItems([])
  }

  const onOptionSelected = (op) => {
    onSelect && onSelect(op)
    !multiSelect && setValue('')
  }

  return (
    <div className={inputSearchClass} ref={dropdownRef}>
      {hasIcon && (
        <IconSearch className="input-search__look-glass fill-white" />
      )}

      <InputText
        onChange={onValue}
        value={value}
        onEnterKeyPressed={
          hasButton || !list?.length ? () => returnValue(value) : selectFirst
        }
        placeholder={placeholder}
        error={error}
        disabled={disabled}
        suffix={
          hasClear && value ? (
            <Icon
              onClick={() => {
                onValue('')
                returnValue('')
                onSearch('')
              }}
            >
              <ClearIcon />
            </Icon>
          ) : null
        }
      />
      {loading && <Loader className="input-search__loader" />}
      {list && (
        <div
          className="input-search__list"
          style={{ maxHeight: `${itemsToShow * 32}px` }}
        >
          {items.map((el, idx) => {
            return (
              <div
                key={idx}
                className={`input-search__el ${
                  idx === 0 ? 'input-search__el--selected' : ''
                }`}
                onClick={() => onValueSelected(el)}
              >
                {el[valueAttribute]}
                {secondaryAttribute ? (
                  <div className="input-search__sec">
                    &nbsp; ({el[secondaryAttribute]})
                  </div>
                ) : null}
              </div>
            )
          })}
        </div>
      )}
      {!!options?.length && (
        <div
          className="input-search__options"
          style={{ maxHeight: `${itemsToShow * 32}px` }}
        >
          {options.map((el, idx) => {
            const optionHtml = !optionRenderer ? el.label : optionRenderer(el)
            return (
              <div
                key={idx}
                className={`input-search__text`}
                onClick={() => onOptionSelected(el)}
              >
                {optionHtml}
              </div>
            )
          })}
        </div>
      )}
      {hasButton && (
        <div
          onClick={() => returnValue(value)}
          className="input-search__look-glass align-center"
        >
          <IconSearch alt="search" />
        </div>
      )}
    </div>
  )
}

/**
 * Constant enum holding possible icon variants
 * @enum {String}
 * @readonly
 */
const ICON_VARIANT = {
  BLUE: 'blue',
  WHITE: 'white',
  GRAY: 'gray',
}

InputSearch.propTypes = {
  list: PropTypes.array,
  options: PropTypes.array,
  error: PropTypes.any,
  optionRenderer: PropTypes.func, // Overwrite how each option renders via a function
  onSelect: PropTypes.func,
  valueAttribute: PropTypes.string,
  secondaryAttribute: PropTypes.string,
  itemsToShow: PropTypes.number,
  placeholder: PropTypes.string,
  returnValue: PropTypes.func,
  hasButton: PropTypes.bool,
  hasIcon: PropTypes.bool,
  iconVariant: PropTypes.oneOf(Object.values(ICON_VARIANT)),
  defaultValue: PropTypes.string,
  small: PropTypes.bool,
  loading: PropTypes.bool,
  onSearch: PropTypes.func,
  disabled: PropTypes.bool,
  hasClear: PropTypes.bool,
  multiSelect: PropTypes.bool,
}

export default InputSearch
