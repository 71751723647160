import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import { useNavigate } from 'react-router-dom'
import { socket, editLocks } from '@decision-sciences/qontrol-common'

import Overlay from 'components/overlay'
import { useSocket } from 'components/utils/socket'
import { preventScrollingBody } from 'components/utils/dom-manipulation'
import Button from 'components/button'
import useSession from 'modules/session'

import closeIcon from 'assets/icon_close_blue.svg'

import {
  getForType,
  lockEntity,
  unLockEntity,
  dispatchLock,
  dispatchUnLock,
  editLockObject,
  pingServer,
} from './actions'

import './style.scss'

const {
  EDIT_LOCK_ENTITIES,
  EDIT_LOCK_ENTITIES_DISPLAY,
  EDIT_LOCK_ENTITIES_REDIRECT_URL,
} = editLocks
const { NOTIFICATIONS } = socket

const isLocal = !process.env.NODE_ENV

/** Convert seconds to milliseconds */
const S_TO_MS = 1000

/** A lock-session lasts 15 minutes = 900 seconds */
const sessionDuration = 900 * S_TO_MS
/** Show the session-ending modal when there are 3 minutes (180 seconds) left until the session ends */
const showSessionEndWhenLeft = 180 * S_TO_MS
/** While the session is alive, notify the server each minute that the user is still here */
const notifyServerEvery = 60

export const editLockContext = createContext({})

/**
 * Edit Lock Context provider
 * A context provider for controlling the edit-locks on entities.
 * After inactivity on a page, a countdown of {sessionDuration} seconds begins.
 * After {showSessionEndWhenLeft} seconds of further inactivity, a modal is shown,
 * informing the user that their changes are about to be saved (if on a page that supports drafts) if they do nothing,
 * and they would be redirected out of the edit page.
 *
 * @param {Object} props props
 * @param {Object} props.children children
 * @returns {React.Component}
 */
const EditLockProvider = ({ children }) => {
  const {
    state: { editLocks },
    dispatch,
  } = useStore()
  const [, user] = useSession()
  const navigate = useNavigate()

  /** Type of locked entity */
  const [entityType, setEntityType] = useState(null)
  /** ID of locked entity */
  const [entityId, setEntityId] = useState(null)

  /** Show lock session ending modal - only when there's [showSessionEndWhenLeft] seconds left  */
  const [showSessionEnding, setShowSessionEnding] = useState(false)
  /** Seconds 'till edit-session ends */
  const [secondsTillSessionEnd, setSecondsTillSessionEnd] = useState(null)
  /** To execute before ending the session */
  const [onSessionEnd, setOnSessionEnd] = useState(null)
  /** Show a second button on the ending modal, that closes the session and executes session-ending functions */
  const [showEndSessionButton, setShowEndSessionButton] = useState(false)
  /** Current entity's index page */

  const reset = () => {
    /** Reset last activity time to stop the interval from checking it further */
    setLastActivityTime({ clear: true })
    setShowSessionEnding(false)
    setSecondsTillSessionEnd(null)
  }

  const unMount = useCallback(
    (
      { redirect, triggerCallback } = { redirect: true, triggerCallback: true }
    ) => {
      reset()

      if (entityType && entityId) {
        /** Handle session ending for each individual entity */
        unLockEntity(dispatch, entityId, entityType).then(() => {
          if (onSessionEnd && triggerCallback) {
            onSessionEnd()
          }
          redirect &&
            navigate(
              EDIT_LOCK_ENTITIES_REDIRECT_URL[entityType] || '/unauthorized',
              { replace: true }
            )
        })
      }
    },
    [onSessionEnd, entityId, entityType, JSON.stringify(editLocks), user?._id]
  )

  useEffect(() => {
    /** Edit page - lock entity and release it on unmount */
    if (entityType && entityId) {
      lockEntity(dispatch, entityId, entityType).catch(() => {
        /** In case the user could not lock the entity, kick them out of the edit page */
        unMount({ redirect: true, triggerCallback: false })
      })
    }
  }, [entityType, entityId])

  useEffect(() => {
    if (entityType && entityId) {
      /** Release lock on unmount */
      return () => {
        unMount({ redirect: false, triggerCallback: false })
      }
    }
  }, [entityType, entityId])

  /**
   * Set current time as last user acitivty
   * @param {Object} options options
   * @param {Boolean} [options.force] force settings time - ignore sessionEnding modal showing
   * @param {Boolean} [options.clear] clear set time
   */
  const setLastActivityTime = ({ force = false, clear = false } = {}) => {
    /** Only set activity time if the session ending modal is not already showing */
    if (!showSessionEnding || force) {
      window.lastActivity = clear ? null : Date.now()
      setShowSessionEnding(false)
      secondsTillSessionEnd && setSecondsTillSessionEnd(null)
    } else {
      return
    }
  }

  const checkIdleTime = () => {
    if (!window.lastActivity) {
      return
    }

    const currentTime = Date.now()
    const timeDiff = currentTime - window.lastActivity

    const secondsLeft = Math.round((sessionDuration - timeDiff) / S_TO_MS)
    if (showSessionEnding) {
      setSecondsTillSessionEnd(secondsLeft)
    }

    /** Session time is up */
    if (timeDiff > sessionDuration) {
      unMount({ redirect: true, triggerCallback: true })
    } else {
      /** Session is coming close to ending -> show modal */
      if (sessionDuration - timeDiff < showSessionEndWhenLeft + 1) {
        !showSessionEnding && setShowSessionEnding(true)
      }

      /**
       * Notify server every [notifyServerEvery] seconds about the user's presence
       * Add 1 second to the actual time left so that:
       *     - a ping is sent after the first second an entity page loads. This is usedful when a user comes back after closing the page, while there's less than a minute left on the backend before the session would be automatically ended.
       *     - a ping is not sent during the last minute.
       */
      if (secondsLeft > 0 && (secondsLeft + 1) % notifyServerEvery === 0) {
        pingServer(entityType, entityId).catch(() => {
          /** In case the ping was not accepted by the server, kick the user out of the edit page */
          unMount({ redirect: true, triggerCallback: false })
          getForType(dispatch, entityType)
        })
      }
    }
  }

  /**
   * Handle edit session.
   * Listen to the following events: onclick, onmousemove, onkeypress, onscroll.
   * On event, remember the time
   * Each second: Check for the time passed since the remembered event time
   */
  useEffect(() => {
    if (isLocal) {
      return
    }

    /** Only care about edit session if on an edit page - an entity is locked */
    if (entityId) {
      /** Listen to user events */
      document.addEventListener('click', setLastActivityTime)
      document.addEventListener('mousemove', setLastActivityTime)
      document.addEventListener('keypress', setLastActivityTime)
      document.addEventListener('scroll', setLastActivityTime)
      document.addEventListener('wheel', setLastActivityTime)

      /** Prohibit scrolling so that the orverlay stays in place */
      preventScrollingBody(showSessionEnding)

      /** Initialise last activity time */
      if (!window.lastActivity) {
        setLastActivityTime()
      }
      const interval = setInterval(checkIdleTime, 1000)

      /** Cleanup on unmount */
      return () => {
        document.removeEventListener('click', setLastActivityTime)
        document.removeEventListener('mousemove', setLastActivityTime)
        document.removeEventListener('keypress', setLastActivityTime)
        document.removeEventListener('scroll', setLastActivityTime)
        document.removeEventListener('wheel', setLastActivityTime)

        clearInterval(interval)
      }
    }
  }, [entityType, entityId, showSessionEnding])

  /** Locks for current entity type - except the locks the current user has set */
  const locks = useMemo(
    () =>
      entityType && editLocks?.[entityType]
        ? Object.entries(editLocks?.[entityType])
            .filter(([, lockValue]) => lockValue?.user !== user._id)
            .reduce(
              (acc, [entityId, lockValue]) => ({
                ...acc,
                [entityId]: lockValue,
              }),
              {}
            )
        : null,
    [JSON.stringify(editLocks), entityType, user]
  )

  // Once we figure out why the client sometimes takes 2 minutes to connect, remove this and add back the commented part below
  useEffect(() => {
    if (entityType) {
      /** Fetch locks */
      getForType(dispatch, entityType)
    }
  }, [entityType])

  /** Listen to lock changes for the current entity type from the server */
  const socket = useSocket({ room: entityType, roomNeeded: true })
  useEffect(() => {
    if (socket?.connected && entityType) {
      socket.on(
        NOTIFICATIONS.editLock.receive,
        ({ lock, entityId: receivedEntityId, user: lockingUser }) => {
          if (lock) {
            dispatchLock(
              dispatch,
              entityType,
              receivedEntityId,
              editLockObject(entityType, lockingUser)
            )

            /** If the user is on an edit-page they did not lock, get them out */
            if (
              user?._id !== lockingUser._id &&
              entityId === receivedEntityId
            ) {
              unMount({ redirect: true, triggerCallback: false })
            }
          } else {
            dispatchUnLock(dispatch, entityType, receivedEntityId)

            /** If a currently open edit page received an unlock event, kick the user out */
            if (entityId === receivedEntityId) {
              unMount({ redirect: true, triggerCallback: false })
            }
          }
        }
      )
    }

    return () => {
      socket?.removeAllListeners(NOTIFICATIONS.editLock.receive)
    }
  }, [socket?.connected, entityType, user?._id])

  const displayRemainingTime = () => {
    let minutes = Math.floor(secondsTillSessionEnd / 60)
    minutes = minutes < 10 ? `0${minutes}` : minutes

    let seconds = secondsTillSessionEnd % 60
    seconds = seconds < 10 ? `0${seconds}` : seconds

    return `${minutes}:${seconds}`
  }

  /** The copy on the session-ending modal differs for campaing builder */
  const isCampaignBuilder = entityType === EDIT_LOCK_ENTITIES.CAMPAIGN_BUILDER

  return (
    <editLockContext.Provider
      value={{
        EDIT_LOCK_ENTITIES,
        /** In order for the function not to execute immediately, set the state to a function that returns a function */
        setOnSessionEnd: (value) => setOnSessionEnd(() => value),
        setEntityType,
        setEntityId,
        /** Locks for current entity type - except the locks the current user has set */
        locks: locks,
        setShowEndSessionButton,
      }}
    >
      {children}
      {showSessionEnding && secondsTillSessionEnd && (
        <>
          <div className="edit-lock-session-ending">
            <div className="edit-lock-session-ending__close-wrapper"></div>
            <img
              alt="Icon Close"
              src={closeIcon}
              onClick={() => setLastActivityTime({ force: true })}
              className="edit-lock-session-ending__close"
            />
            <p className="edit-lock-session-ending__header">
              Session Ending Due to Inactivity
            </p>
            <p className="edit-lock-session-ending__copy">
              Due to inactivity, your session will time out in{' '}
              <span className="edit-lock-session-ending__timer">
                {displayRemainingTime()}
              </span>
              .
              <br />
              <br />
              If the session ends,{' '}
              {isCampaignBuilder
                ? 'your changes will be saved to the draft automatically'
                : 'any changes will not be saved'}
              .
              <br />
              <br />
              Please note, while{' '}
              {isCampaignBuilder
                ? `working in a ${EDIT_LOCK_ENTITIES_DISPLAY[entityType]} others are locked out from being able to edit the campaign`
                : `editing an ${EDIT_LOCK_ENTITIES_DISPLAY[entityType]} others are locked out from being able to edit it as well`}
              .
              <br />
              <br />
              Click the “Still Here” button below to continue with your current
              session.
            </p>
            <div className="edit-lock-session-ending__buttons">
              <Button
                value="Still here"
                onClick={() =>
                  /** Force settings user activity, even though the modal is showing */
                  setLastActivityTime({ force: true })
                }
                className="edit-lock-session-ending__extend"
              />
              {showEndSessionButton ? (
                <Button value="Save Draft & Exit" onClick={unMount} secondary />
              ) : null}
            </div>
          </div>

          <Overlay
            expanded={showSessionEnding}
            className="edit-lock-session-ending__overlay"
          />
        </>
      )}
    </editLockContext.Provider>
  )
}

EditLockProvider.propTypes = {
  children: PropTypes.any,
}

export default EditLockProvider
