import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/** Components */
import { RadioNoHooks } from 'components/radio/index'
import { ReactComponent as ArrowIcon } from 'assets/icon_arrow_blue.svg'
import LocationOptions from 'modules/flow/components/google/campaign/campaign-settings/target-locations/location-options'
import SearchLocations from 'modules/flow/components/google/campaign/campaign-settings/target-locations/search-locations'

/* Store & Actions */
import { editCampaign } from 'modules/flow/actions'
import { useStore } from 'store'

/* Constants */
import { accounts, flow } from '@decision-sciences/qontrol-common'

/* Styles */
import './target-locations.scss'

const { ACCOUNT_TYPES_MAP } = accounts
const { GOOGLE_LOCATION_GEO_TARGET_TYPE } = flow.google

/**
 * Renders the campaign target locations panel with its settings options
 * @param {Object} props - React Component Props for this component
 * @param {Object} props.campaign - Current campaign
 * @param {Function} props.updateSection Callback function to update campaign
 */
const TargetLocations = ({ campaign, updateSection, errors = {} }) => {
  const { dispatch } = useStore()
  const [isCollapsed, setIsCollapsed] = useState(false)

  // When option changed reset selected locations
  const onOptionChanged = (value) => {
    const newCampaign = {
      geo_target_type_setting: {
        location_geo_target_type: value,
      },
      locations_to_target: [],
    }
    editCampaign({
      dispatch,
      id: campaign.id,
      platform: ACCOUNT_TYPES_MAP.GOOGLE,
      data: newCampaign,
    })
  }

  return (
    <div className="form__section__body">
      <div className="campaign-component">
        <div className="campaign-component__title">
          Select Locations to Target
        </div>
        {Object.values(TARGET_LOCATIONS_OPTIONS).map(
          ({ label, value }, idx) => {
            return (
              <React.Fragment key={idx}>
                <RadioNoHooks
                  className="blue"
                  label={label}
                  checked={
                    campaign.geo_target_type_setting
                      ?.location_geo_target_type === value
                  }
                  onChange={() => onOptionChanged(value)}
                />
              </React.Fragment>
            )
          }
        )}
        {campaign.geo_target_type_setting?.location_geo_target_type ===
          TARGET_LOCATIONS_OPTIONS.ENTER_LOCATION.value && (
          <SearchLocations campaign={campaign} errors={errors} />
        )}
        <div
          className="align-row cursor--pointer"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <ArrowIcon
            className={cx('location_collapse_icon', {
              'location_collapse_icon--open': isCollapsed,
            })}
          />
          <div className="general-label-blue">Location Options</div>
        </div>
        {isCollapsed && <LocationOptions campaign={campaign} />}
      </div>
    </div>
  )
}
export const TARGET_LOCATIONS_OPTIONS = {
  ALL: {
    label: 'All Countries and Territories',
    value: GOOGLE_LOCATION_GEO_TARGET_TYPE.ALL_COUNTRIES_AND_TERRITORIES,
  },
  UNITED_STATES_AND_CANADA: {
    label: 'United States and Canada',
    value: GOOGLE_LOCATION_GEO_TARGET_TYPE.UNITED_STATES_AND_CANADA,
  },
  UNITED_STATES: {
    label: 'United States',
    value: GOOGLE_LOCATION_GEO_TARGET_TYPE.UNITED_STATES,
  },
  ENTER_LOCATION: {
    label: 'Enter Another Location',
    value: GOOGLE_LOCATION_GEO_TARGET_TYPE.ENTER_LOCATION,
  },
}
TargetLocations.propTypes = {
  campaign: PropTypes.object.isRequired,
  updateSection: PropTypes.func.isRequired,
  errors: PropTypes.object,
}
export default TargetLocations

