import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { alert } from '@decision-sciences/qontrol-common'
import { useTooltip } from 'components/tooltip/index'
import ExpressionBuilder from 'components/expression-builder'
import Loader from 'components/loader/index'
import { ALERT_MESSAGE_TOOLTIP } from 'components/utils/tooltip'
import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'
import CollapsibleSection from 'components/collapsible-section/index'

const { ALERT_TYPES_MAP } = alert

const DEFAULT_OPERATORS = ['+', '-', '*', '/', '(', ')']

const AlertMessage = ({
  alert,
  currentTemplate,
  operators = DEFAULT_OPERATORS,
  onChange,
  viewOnly = false,
  header = 'Alert Message',
  collapsible = false,
}) => {
  const [errors, setErrors] = useState(false)

  const messageMetrics = useMemo(() => {
    const metrics = []

    if (!alert) {
      return metrics
    }

    if (alert?.alertType === ALERT_TYPES_MAP.PERFORMANCE) {
      return currentTemplate?.metrics?.map((metric) => metric.key) || []
    } else if (alert.properties) {
      Object.entries(alert.properties).forEach(
        ([account, accountProperties]) => {
          accountProperties.forEach((resource) => {
            // Check if row is conditional
            if (resource.conditions) {
              resource.props.forEach((prop) => {
                if (prop.type && prop.property) {
                  metrics.push(`${account}.${prop.type}.${prop.property}`)
                }
              })
            } else if (resource.type && resource.property) {
              metrics.push(`${account}.${resource.type}.${resource.property}`)
            }
          })
        }
      )
    }

    return [...new Set([...metrics])]
  }, [currentTemplate, JSON.stringify(alert?.properties)])

  const handleChange = (isValid, message) => {
    if (viewOnly) {
      return
    }
    if (!isValid) {
      setErrors({ ...errors, message: 'Invalid Message' })
    }
    onChange(message)
  }

  if (!alert) {
    return <Loader />
  }

  return collapsible ? (
    <CollapsibleSection header={<AlertMessageHeader header={header} />} defaultCollapsed={false}>
      <AlertMessageBuilder
        alert={alert}
        viewOnly={viewOnly}
        messageMetrics={messageMetrics}
        operators={operators}
        handleChange={handleChange}
      />
    </CollapsibleSection>
  ) : (
    <>
      <AlertMessageHeader header={header} />
      <AlertMessageBuilder
        alert={alert}
        viewOnly={viewOnly}
        messageMetrics={messageMetrics}
        operators={operators}
        handleChange={handleChange}
      />
    </>
  )
}

const AlertMessageHeader = ({ header }) => {
  const [showTooltip, hideTooltip, Tooltip] = useTooltip(ALERT_MESSAGE_TOOLTIP)
  return (
    <div className="form__section__header">
      {header}
      <InfoIcon
        className="calculated-metrics__info fill-light-blue"
        alt="info"
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      />
      <Tooltip />
    </div>
  )
}

const AlertMessageBuilder = ({
  alert,
  viewOnly,
  messageMetrics,
  operators,
  handleChange,
}) => {
  return (
    <div className="form__section__body">
      <ExpressionBuilder
        freeTextEnabled
        disabled={viewOnly}
        metrics={messageMetrics}
        operators={operators}
        defaultValue={alert?.message}
        onChange={handleChange}
      />
    </div>
  )
}

AlertMessage.propTypes = {
  alert: PropTypes.object,
  currentTemplate: PropTypes.object,
  operators: PropTypes.array,
  onChange: PropTypes.func,
  viewOnly: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  collapsible: PropTypes.bool,
}

AlertMessageHeader.propTypes = {
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

AlertMessageBuilder.propTypes = {
  alert: PropTypes.object,
  viewOnly: PropTypes.bool,
  messageMetrics: PropTypes.array,
  operators: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export { AlertMessage }
