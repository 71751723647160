import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { format as formatDate } from 'date-fns'
import { CSVLink } from 'react-csv'
import cx from 'classnames'

// Custom Hooks
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'

// Icons
import { ReactComponent as DownloadIcon } from 'assets/icon_download.svg'
import { ReactComponent as IconFilter } from 'assets/filters.svg'

// Utils
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from 'components/utils/date'
import {
  filterActivityLogs,
  ACTIVITY_LOG_CSV_EXPORT_HEADERS,
  VALUE_CHANGED_LABELS,
  formatRow,
} from 'modules/companies/subsections/budget-pacing/components/budget-pacing-activity-log/utils'

// Components
import Loader from 'components/loader'
import RoundRectangleButton from 'components/round-rectangle-button'
import CollapsibleSection from 'components/collapsible-section'
import { utils } from '@decision-sciences/qontrol-common'
import ActivityLogSelector from './activity-log-filters'
import ActivityLogTable from './activity-log-table'

const { isEmpty } = utils.object

const ActivityLog = ({ viewMode, activityLogs, loading }) => {
  const [filtersOpened, setFiltersOpened] = useState(false)
  const [filtersApplied, setFiltersApplied] = useState(false)
  const [activityLogOpened, setActivityLogOpened] = useState(false)
  const [filter, setFilter] = useState({})
  const [displayedLogs, setDisplayedLogs] = useState([])

  const hasReadAccess = useAccess({
    feature: PERMISSIONS.BUDGET_PACING,
    type: PERMISSION_TYPES.READ,
  })

  useEffect(() => {
    setDisplayedLogs(activityLogs)
    if (!isEmpty(filter)) {
      setFilter({})
    }
  }, [JSON.stringify(activityLogs)])

  const onApplyFilters = () => {
    const filteredLogs = filterActivityLogs(activityLogs, filter)
    setFiltersApplied(filteredLogs.length !== activityLogs.length)
    setDisplayedLogs(filteredLogs)
  }

  const onClearFilters = () => {
    setDisplayedLogs(activityLogs)
    setFiltersApplied(false)
  }

  const exportData = useMemo(() => {
    const data = [...displayedLogs].map((log) => ({
      ...log,
      date: formatDate(
        new Date(log.date),
        `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`
      ),
      to: formatRow(log.to, log.valueChanged, true),
      from: formatRow(log.from, log.valueChanged, true),
      valueChanged: VALUE_CHANGED_LABELS[log.valueChanged],
    }))
    return data
  }, [JSON.stringify(displayedLogs)])

  const _renderBarButtons = () => {
    return (
      <div className="margin-left-16 align-row">
        {!viewMode && (
          <CSVLink
            data={exportData}
            headers={ACTIVITY_LOG_CSV_EXPORT_HEADERS}
            filename={`activitylog.csv`}
            asyncOnClick={true}
            separator=";"
            enclosingCharacter=""
          >
            <RoundRectangleButton
              className={cx('margin-left-16', {
                'filter-disabled': !activityLogOpened,
              })}
              contentRender={() => <DownloadIcon />}
              contentClassName="keywords-table-title-action-icon-reversed"
            />
          </CSVLink>
        )}

        <RoundRectangleButton
          className={cx(
            'margin-left-16',
            {
              'filter-active': filtersOpened || filtersApplied,
            },
            { 'filter-disabled': !activityLogOpened }
          )}
          onClick={() => activityLogOpened && setFiltersOpened(!filtersOpened)}
          contentRender={() => (
            <IconFilter
              className={
                filtersOpened || filtersApplied
                  ? 'fill-white'
                  : 'fill-light-blue'
              }
            />
          )}
          contentClassName="keywords-table-title-action-icon"
        />
      </div>
    )
  }

  const _renderFilters = () => {
    if (!filtersOpened || !activityLogOpened) {
      return null
    }
    return (
      <div className="activity-log__selector">
        <ActivityLogSelector
          filter={filter}
          setFilter={setFilter}
          onClearFilters={onClearFilters}
          onApplyFilters={onApplyFilters}
          activityLogs={displayedLogs}
        />
      </div>
    )
  }

  const _renderTable = () => {
    if (!activityLogOpened) {
      return null
    }
    return <ActivityLogTable activityLogs={displayedLogs} />
  }

  if (!hasReadAccess) {
    return null
  }

  if (loading) {
    return <Loader />
  }

  return (
    <CollapsibleSection
      id="activity-log-section"
      header="Activity Log"
      extras={_renderBarButtons()}
      defaultCollapsed={!activityLogOpened}
      onCollapseListener={(val) => {
        setActivityLogOpened(val)
        !val && setFiltersOpened(val)
      }}
    >
      <div>
        {_renderFilters()}
        {_renderTable()}
      </div>
    </CollapsibleSection>
  )
}
export default ActivityLog

ActivityLog.propTypes = {
  viewMode: PropTypes.bool,
  activityLogs: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
}
