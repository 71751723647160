import React from 'react'
import PropTypes from 'prop-types'
import warningIcon from 'assets/icon_warning_yellow.svg'
import { Dropdown } from 'components/dropdown'

export const renderOwnerCell = (cell) => {
  const owner = cell.row.original.owner
  return (
    <div className="align-row align-center">
      {owner.name ? owner.name : `${owner.firstName} ${owner.lastName}`}
      {!owner.active && (
        <div className="margin-left-10">
          <img src={warningIcon} alt="warning" />
        </div>
      )}
    </div>
  )
}

export const OwnerDropdown = ({
  isNew,
  currentUser,
  allUsers = [],
  selectedId = null,
  onChange,
  loading,
  loadingText,
  disabled,
}) => {
  const list = isNew ? [currentUser] : allUsers
  return (
    <Dropdown
      label="Owner"
      disabled={!!isNew || !currentUser.isSuperAdmin || disabled}
      options={list.map((user) => ({ value: user._id, label: user.name }))}
      defaultState={
        isNew ? currentUser._id : allUsers.length ? selectedId : null
      }
      defaultOptionText={loading ? loadingText : ''}
      onChange={onChange}
      className="form__half input-wrapper--uppercase"
      loading={loading}
    />
  )
}
OwnerDropdown.propTypes = {
  isNew: PropTypes.bool,
  currentUser: PropTypes.object.isRequired,
  allUsers: PropTypes.array,
  selectedId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  disabled: PropTypes.bool,
}
