import Api from 'easy-fetch-api'
import {
  showSuccessMessage,
  showErrorMessage,
} from 'modules/notifications/actions'

export const ACTIONS = {
  SET_ACCOUNTS: 'microsoft.setAccounts',
  SET_CAMPAIGNS: 'microsoft.setCampaigns',
  SET_SELECTED_CAMPAIGN: 'microsoft.setSelectedCampaign',
}

export const verify = (code, dispatch) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: `/api/microsoft/verify`,
      data: { code: code },
    }).then((res) => {
      if (res.error) {
        showErrorMessage(res.error, dispatch)
        return reject(res.error)
      }

      showSuccessMessage('Verified user successfully!', dispatch)
      resolve()
    })
  })
}

export const checkVerification = (dispatch) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: `/api/microsoft/check-verification`,
    }).then((res) => {
      if (!res.verified && res.verificationURL) {
        return reject({ url: res.verificationURL })
      }
      if (res.verified) {
        return resolve()
      }
      reject('Something went wrong')
    })
  })
}

/**
 * Action that will get all accounts for the dropdown in the alerts config page
 * @param dispatch
 */
export const getAllAccounts = (dispatch) => {
  Api.get({
    url: `/api/microsoft/accounts/all`,
  }).then((res) => {
    if ((res.data && res.data.error) || res.error) {
      showErrorMessage(res.error || res.data.error, dispatch)
    } else {
      dispatch({
        type: ACTIONS.SET_ACCOUNTS,
        user: res.data,
      })
    }
  })
}
/**
 * Action that will get all campaigns for the drop down in the alerts config page
 * @param clientId - the company id
 * @param {Object} signal - abort signal
 * @returns {Promise<any>}
 */
export const getAllCampaigns = (clientId, signal) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/microsoft/client-campaigns/${clientId}`,
      signal,
    }).then((res) => {
      if (!res || res.error) {
        reject(res?.error)
      } else {
        resolve(res.data)
      }
    })
  })
}

/**
 * Action that will get all ad groups for the dropdown in the alerts config page
 * @param clientId - the company id
 * @param campaignId
 * @param accountId
 * @param {Object} signal - abort signal
 * @returns {Promise<any>}
 */
export const getAllAdgroups = (clientId, signal, campaignId, accountId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/microsoft/get-adgroups/${accountId}/${campaignId}`,
      signal,
    }).then((res) => {
      if (!res || res.error) {
        reject(res?.error)
      } else {
        resolve(res.data)
      }
    })
  })
}

/**
 * Action that will get all ads for the drop down in the alerts config page
 * @param clientId - the company id
 * @param adgroupId
 * @param accountId
 * @param {Object} signal - abort signal
 * @returns {Promise<any>}
 */
export const getAllAds = (clientId, signal, adgroupId, accountId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/microsoft/get-ads/${accountId}/${adgroupId}`,
      signal,
    }).then((res) => {
      if (!res || res.error) {
        reject(res?.error)
      } else {
        resolve(res.data)
      }
    })
  })
}

/**
 * Action that will get all keywords for the drop down in the alerts config page
 * @param clientId - the company id
 * @param adgroupId
 * @param accountId
 * @param {Object} signal - abort signal
 * @returns {Promise<any>}
 */
export const getAllKeywords = (clientId, signal, adgroupId, accountId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/microsoft/get-keywords/${accountId}/${adgroupId}`,
      signal,
    }).then((res) => {
      if (!res || res.error) {
        reject(res?.error)
      } else {
        resolve(res.data)
      }
    })
  })
}

export const setSelectedCampaign = (selectedCampaign, dispatch) => {
  dispatch({ type: ACTIONS.SET_SELECTED_CAMPAIGN, selectedCampaign })
}
