import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Dropdown } from 'components/dropdown'
import Loader from 'components/loader'
import { AccountDropdownRow } from 'components/account-icon'

import { getCustomConversionsForAccounts } from 'modules/accounts/actions'

import { metrics, alert } from '@decision-sciences/qontrol-common'

import { AVAILABLE_PUBLISHERS_FOR_ALERT } from 'modules/alerts/constants'

const { mapKPIRow } = metrics
const { ALERT_TYPES_MAP } = alert

export const AlertDynamicMetricsSelection = ({
  state,
  setState,
  hasDynamicMetrics,
  companiesList,
  error,
}) => {
  const [specialMetricsLoading, setSpecialMetricsLoading] = useState(false)
  const [specialMetrics, setSpecialMetrics] = useState([])

  // Filter linked accounts for selected companies and fetch Custom Conversions
  const linkedAccounts = useMemo(() => {
    return state.companies.reduce((array, cId) => {
      const company = companiesList
        .reduce((array, company) => {
          return [...array, company, ...company.businessUnits]
        }, []) // Include business units in search
        .find((el) => el._id === cId)
      return array.concat(company?.accounts || [])
    }, [])
  }, [JSON.stringify(state.companies), JSON.stringify(companiesList)])

  /** Fetch special metrics (custom conversions) if we have linked accounts and the Template has Dynamic Metrics */
  useEffect(() => {
    if (!hasDynamicMetrics || linkedAccounts.length === 0) {
      return
    }
    const getSpecialMetrics = (type) => {
      setSpecialMetricsLoading(
        (specialMetricsLoading) => specialMetricsLoading + 1
      )
      getCustomConversionsForAccounts(linkedAccounts, type)
        .then((results) => {
          results.length &&
            setSpecialMetrics((specialMetrics) => [
              ...specialMetrics,
              ...results,
            ])
        })
        .catch(console.error)
        .finally(() => {
          setSpecialMetricsLoading(
            (specialMetricsLoading) => specialMetricsLoading - 1
          )
        })
    }

    const providers =
      AVAILABLE_PUBLISHERS_FOR_ALERT[ALERT_TYPES_MAP.PERFORMANCE]
    providers.forEach(getSpecialMetrics)
  }, [linkedAccounts, hasDynamicMetrics])

  const specialKPIs = useMemo(
    () =>
      specialMetrics
        .filter((el) => state.selectedAccounts.includes(el.type))
        .map(mapKPIRow),
    [JSON.stringify(specialMetrics), JSON.stringify(state.selectedAccounts)]
  )

  /* We have to add a "key" prop (accountID + ID) to KPIs because IDs are not unique */
  const dynamicMetrics = useMemo(
    () =>
      state.dynamicMetrics?.map((el) => ({
        ...el,
        key: `${el.accountId}_${el.id}`,
      })),
    [JSON.stringify(state.dynamicMetrics)]
  )

  useEffect(() => {
    let hasChanged = false

    const newDynamicMetrics = dynamicMetrics.filter(({ type }) => {
      const stillExists = state.selectedAccounts.includes(type)
      if (!stillExists) {
        hasChanged = true
      }
      return stillExists
    })

    if (hasChanged) {
      setState({ dynamicMetrics: newDynamicMetrics })
    }
  }, [JSON.stringify(state.selectedAccounts)])

  if (!hasDynamicMetrics) {
    return null
  }
  return (
    <div className="align-row">
      <Dropdown
        options={specialKPIs.map((el) => ({ ...el, value: el.key }))}
        defaultOptionText="Select Dynamic Metrics"
        selectedItems={dynamicMetrics?.map((el) => el.key) || []}
        disabled={!!specialMetricsLoading || !specialKPIs?.length}
        multiSelect
        onChange={(selected) => {
          const dynamicMetrics = specialKPIs.filter(
            (el) => selected.indexOf(el.key) > -1
          )
          setState({ dynamicMetrics })
        }}
        className="input-half-width"
        style={{ marginTop: '20px', maxWidth: '50%' }}
        error={error}
        optionRenderer={(option, selectedItems) => (
          <AccountDropdownRow option={option} selectedItems={selectedItems} />
        )}
      />
      {!!specialMetricsLoading && <Loader className="alerts__loader" />}
    </div>
  )
}

AlertDynamicMetricsSelection.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  companiesList: PropTypes.array.isRequired,
  hasDynamicMetrics: PropTypes.bool,
  error: PropTypes.string,
}
