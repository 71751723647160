import DeleteModal from 'components/delete-modal/index'
import useLeaveConfirm from 'components/leave-confirm'
import Loader from 'components/loader'
import { PERMISSIONS, PERMISSION_TYPES, useAccess } from 'hooks/access'
import { showErrorMessage } from 'modules/notifications/actions'
import useSession from 'modules/session'
import { TABLE_CONTAINER } from 'modules/table-filter-sort/constants'
import { deleteTeam, getTeam, resetTeam } from 'modules/teams/actions'
import CreateEditTeam from 'modules/teams/create-edit'
import React, { useEffect, useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom'
import { useStore } from 'store'

/**
 * Entry point for team management - create or edit
 * @returns {React.FunctionComponent}
 */
const TeamManagementPage = () => {
  const tableContainer = TABLE_CONTAINER.TEAM_MANAGEMENT

  const navigate = useNavigate()
  const { teamId } = useParams()

  const [, user] = useSession()

  const {
    dispatch,
    state: { teams, tableFilterSort, companies },
  } = useStore()

  const { team } = teams
  const { filter } = tableFilterSort.filterSort[tableContainer]
  const { currentCompany } = companies

  const [setDirty, LeaveConfirm] = useLeaveConfirm({})

  const hasReadAccess = useAccess({
    feature: PERMISSIONS.TEAM_DATA_FORM,
    type: PERMISSION_TYPES.READ,
  })

  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.TEAM_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })

  const hasEditAccess = useAccess({
    feature: PERMISSIONS.TEAM_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })

  const [teamToDelete, setTeamToDelete] = useState(null)
  const [loading, setLoading] = useState(false)

  const companyId = currentCompany?._id

  /** On page unmount, reset store state */
  useEffect(() => {
    return () => {
      resetTeam(dispatch)
    }
  }, [])

  /** On mount check for valid permissions */
  useEffect(() => {
    if (!hasCreateAccess || !hasEditAccess || !hasReadAccess) {
      redirect('/unauthorized')
    }
  }, [hasCreateAccess, hasEditAccess, hasReadAccess])

  useEffect(() => {
    setLoading(true)
    getTeam(dispatch, teamId)
      .catch((error) => {
        showErrorMessage(error, dispatch)
        navigate('/team-management')
      })
      .finally(() => setLoading(false))
  }, [teamId])

  const onDeleteTeam = (team) => {
    setLoading(true)

    deleteTeam(dispatch, teamId || team.key, companyId, filter)
      .then(() => {
        setDirty(false)
        // If success, redirect back to list page
        setTimeout(() => {
          navigate('/team-management')
        }, 100)
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
        setTeamToDelete(null)
      })
  }

  if (!team) {
    return <Loader />
  }

  return (
    <React.Fragment>
      <LeaveConfirm />
      <DeleteModal
        toDelete={teamToDelete}
        setOpen={setTeamToDelete}
        onDelete={() => onDeleteTeam(teamToDelete)}
        loading={loading}
        className="teams__modal"
      />
      <CreateEditTeam
        isAdmin={user.isSuperAdmin}
        companyId={companyId}
        onDelete={setTeamToDelete}
        loading={loading}
        setLoading={setLoading}
        setDirty={setDirty}
      />
    </React.Fragment>
  )
}

export default TeamManagementPage
