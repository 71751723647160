import React, { useState, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import Input from 'components/input'
import { useNavigate } from 'react-router-dom'
import { FIELD_TYPES, validate } from 'components/validator'
import { createUpdateCalculatedMetric } from 'modules/calculated-metrics/actions'
import TemplateBuilder from 'components/expression-builder'
import { Dropdown } from 'components/dropdown'
import { CheckboxNoHooks } from 'components/checkbox'
import Textarea from 'components/textarea'
import Tooltip from 'components/tooltip'
import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'
import scrollToClosestError from 'components/scroll-to-error'
import useSession from 'modules/session'
import { getUsersWithPermissions } from 'modules/users/actions'
import { FORMULA_TOOLTIP } from 'components/utils/tooltip'
import StickyFooter from 'components/sticky-footer'
import useLeaveConfirm from 'components/leave-confirm'
import { OwnerDropdown } from 'components/utils/owner'
import { NOT_FOUND_ROUTE } from 'routes'
import {
  utils,
  metrics,
  entityStatus,
  permissions,
} from '@decision-sciences/qontrol-common'
import { DUPLICATE_METRICS_KEY } from './index'

const { stringToID, compareIgnoreCase } = utils.string
const {
  METRICS,
  SPECIAL_METRICS,
  isDynamicMetric,
  METRICS_BUDGET_PACING,
  LIFETIME_BUDGET_METRICS,
} = metrics
const { ENTITY_STATUS_OPTIONS, ENTITY_STATUS } = entityStatus

const CreateEditCalculatedMetric = ({ selectedId, calculatedMetrics }) => {
  const navigate = useNavigate()
  const { dispatch } = useStore()
  const [, user] = useSession()
  const [showTooltip, setShowTooltip] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [expressionInvalid, setExpressionInvalid] = useState(false)
  const isNew = selectedId === 'new'
  let existing = null
  const [setDirty, LeaveConfirm] = useLeaveConfirm({})
  const [possibleOwners, setPossibleOwners] = useState(null)

  // Read from localStorage to check for duplicate metric
  let fromStorage = localStorage.getItem(DUPLICATE_METRICS_KEY)
  fromStorage = fromStorage && JSON.parse(fromStorage)
  // Make sure we remove localStorage item
  localStorage.removeItem(DUPLICATE_METRICS_KEY)

  if (isNew && fromStorage) {
    existing = {
      ...fromStorage,
      _id: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      name: `${fromStorage.name} Copy`,
      key: `${fromStorage.key}_copy`,
    }
  }

  // Get Existing metric from list of metrics based on ID
  if (!isNew) {
    const el = Object.values(calculatedMetrics).find(
      (el) => el._id === selectedId
    )
    existing = el && { ...el }
  }
  if (existing && existing.name) {
    existing.metrics &&
      existing.metrics.forEach((metric) => {
        existing.expression = existing.expression.replace(metric, `[${metric}]`)
      })
  }

  const [state, setState] = useState(
    existing
      ? {
          ...existing,
          owner: existing.owner._id,
          ownerName: `${existing.owner.firstName} ${existing.owner.lastName}`,
        }
      : {
          name: '',
          key: '',
          expression: '',
          metrics: [],
          active: ENTITY_STATUS.INACTIVE,
          description: '',
          isGlobal: false,
          owner: null,
        }
  )

  /** On mount check for valid permissions */
  useEffect(() => {
    if (!isNew && !existing && !existing?._id) {
      return navigate(NOT_FOUND_ROUTE, true)
    }
    if (!isNew) {
      getUsersWithPermissions([
        {
          feature: permissions.PERMISSIONS.CALCULATED_METRICS,
          type: permissions.PERMISSION_TYPES.CREATE,
        },
      ]).then((res) => {
        res.list && setPossibleOwners(res.list)
      })
    }
  }, [])

  /** Edit Name field */
  const editName = (value) => {
    setDirty(true)
    // Don't change key on edit
    const key = existing ? state.key : stringToID(value)
    setState({ ...state, name: value, key })
    setErrors({ ...errors, name: null })
  }

  const editField = (fieldName, value) => {
    const isDirty = state[fieldName] !== value
    setDirty(isDirty)
    setState({ ...state, [fieldName]: value })
    setErrors({ ...errors, [fieldName]: null })
  }

  /** Submit form */
  const onSubmit = () => {
    /* Validate */
    setLoading(true)
    let [isValid, errors] = validate(ERROR_MAP, state)

    // Add invalid expression error to the mix.
    if (expressionInvalid) {
      isValid = false
      errors.expression = 'Expression is invalid.'
    }

    if (!isValid) {
      setLoading(false)
      setErrors(errors)
      return scrollToClosestError()
    }

    // Extract metrics
    const metricList =
      state.expression.match(/\[(.*?)]/g)?.map((el) => {
        return el.replace(/[[\]]/g, '')
      }) || []
    // Replace [ ] in expression
    const expression = state.expression.replace(/[[\]]/g, '').trim()

    createUpdateCalculatedMetric(
      {
        _id: state._id,
        key: state.key,
        name: state.name,
        description: state.description || '',
        expression,
        metrics: metricList,
        active: state.active,
        owner: isNew ? user._id : state.owner,
        isGlobal: state.isGlobal,
      },
      dispatch
    )
      .then(() => {
        setDirty(false)
        setTimeout(() => navigate('/calculated-metrics'), 50)
      })
      .catch((err) => {
        if (typeof err === 'object') {
          setErrors({ ...errors, ...err })
        } else {
          setErrors({ ...errors, general: err })
        }
      })
      .finally(() => setLoading(false))
  }

  // Display Metrics + Special Metrics
  const allMetrics = useMemo(() => {
    const filteredSpecialMetrics = Object.keys(SPECIAL_METRICS).filter(
      (metric) => !isDynamicMetric(metric) || !state.isGlobal
    )
    return [
      ...Object.keys(METRICS),
      ...filteredSpecialMetrics,
      ...Object.keys(METRICS_BUDGET_PACING),
      ...Object.keys(LIFETIME_BUDGET_METRICS),
    ].sort(compareIgnoreCase)
  }, [state.isGlobal])

  return (
    <div className={`metrics form ${loading ? 'form--loading' : ''}`}>
      <LeaveConfirm />
      <Helmet>
        <title>{existing ? 'Edit Metric' : 'Create Metric'}</title>
      </Helmet>
      {/* Header */}
      <div className="heading" data-cy="page-heading">
        {existing ? 'Edit Metric' : 'Create Metric'}
      </div>

      {errors.general ? (
        <div className="error error--general">{errors.general}</div>
      ) : null}

      <section className="form__section">
        <div className="form__section__body">
          <div className="form__row">
            <Input
              label="Metric Name"
              placeholder="Metric Name"
              error={errors.name || errors.key}
              value={state.name}
              onChange={editName}
              className="form__half"
            />
            <Dropdown
              label="Status"
              options={ENTITY_STATUS_OPTIONS}
              defaultState={state.active}
              defaultOptionText="Status"
              onChange={(active) => editField('active', active)}
              error={errors.active}
              className="input-wrapper--uppercase"
            />
          </div>
          <div className="form__row">
            <OwnerDropdown
              currentUser={user}
              isNew={isNew}
              allUsers={possibleOwners || []}
              selectedId={state.owner}
              onChange={(owner) => editField('owner', owner)}
              loading={isNew ? false : !possibleOwners}
              loadingText={state.ownerName}
            />
          </div>
        </div>
      </section>

      <section className="form__section">
        <div className="form__section__header">Description</div>
        <div className="form__section__body">
          <Textarea
            className="form__row"
            value={state.description}
            onChange={(description) => editField('description', description)}
          />
        </div>
      </section>

      <section className="form__section">
        <div className="form__section__header">
          Formula{' '}
          <InfoIcon
            className="calculated-metrics__info fill-light-blue"
            alt={'info'}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          />
          <Tooltip content={FORMULA_TOOLTIP} show={showTooltip} />
        </div>

        <div className="form__section__body">
          <TemplateBuilder
            metrics={allMetrics}
            defaultValue={state.expression}
            onChange={(isValid, expression) => {
              setExpressionInvalid(!isValid)
              editField('expression', expression)
            }}
            error={errors.expression}
          ></TemplateBuilder>
        </div>

        <CheckboxNoHooks
          label="Global"
          isChecked={state.isGlobal}
          className="teams__global"
          onChange={() => editField('isGlobal', !state.isGlobal)}
        />
      </section>

      <StickyFooter
        buttons={[
          {
            value: existing ? 'Save Changes' : 'Save Metric',
            onClick: onSubmit,
          },
          {
            value: 'Cancel',
            onClick: () => navigate('/calculated-metrics'),
            secondaryGray: true,
          },
        ]}
      />
    </div>
  )
}

CreateEditCalculatedMetric.propTypes = {
  selectedId: PropTypes.string,
  calculatedMetrics: PropTypes.object.isRequired,
}

const ERROR_MAP = {
  name: FIELD_TYPES.REQUIRED,
  expression: FIELD_TYPES.REQUIRED,
  active: FIELD_TYPES.REQUIRED,
}

export default CreateEditCalculatedMetric
