import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Api from 'easy-fetch-api'
import { utils } from '@decision-sciences/qontrol-common'
import { RadioNoHooks } from 'components/radio'

import './style.scss'

const { SECONDS_IN_DAY } = utils
const MILLISECONDS_IN_DAY = SECONDS_IN_DAY * 1000

/**
 * Displays options to remember a device or not.
 * Fetches multi factor expiration platform setting from the back end.
 * @param {Object} props React Props
 * @param {Boolean} props.remember Whether to remember 2fa or not
 * @param {Function} props.setRemember Callback to set remember value
 * @returns {React.ComponentElement}
 */
const LoginRemember = ({ remember, setRemember }) => {
  const [multiFactorExpiration, setMultiFactorExpiration] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    Api.get({ url: '/api/security/multi-factor-expiration' })
      .then((result) => {
        if (result.mfa_timeout) {
          setMultiFactorExpiration(result.mfa_timeout.time)
        }
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }, [])

  if (loading) {
    return null
  }

  const rememberLabel = multiFactorExpiration
    ? `Remember this device for next ${
        multiFactorExpiration / MILLISECONDS_IN_DAY
      } days`
    : ''

  return (
    <div className="login-remember">
      {rememberLabel && (
        <RadioNoHooks
          label={rememberLabel}
          checked={remember}
          onChange={() => setRemember(true)}
        />
      )}
      <RadioNoHooks
        label="Do not remember this device"
        checked={!remember}
        onChange={() => setRemember(false)}
      />
    </div>
  )
}

LoginRemember.propTypes = {
  remember: PropTypes.bool,
  setRemember: PropTypes.func.isRequired,
}

export default LoginRemember
