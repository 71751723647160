import {
  budgetPacing,
  utils,
  granularities,
  accounts,
} from '@decision-sciences/qontrol-common'
import TextTag from 'components/text-tag'
import { ACCOUNT_TYPE_ICONS } from 'constants/account.js'

const {
  checkBudgetPacingSettingsOverlap,
  DATA_WAREHOUSE,
  PUBLISHER,
  ACCOUNTS,
} = budgetPacing
const { getDateWithoutTimestamp } = utils.date
const { isEmpty } = utils.object
const {
  GRANULARITIES: { CAMPAIGN, AD_GROUP },
} = granularities
const { ACCOUNT_TYPE_NAMES } = accounts

export const DIMENSION_SECTIONS = {
  [DATA_WAREHOUSE]: { label: 'Data Warehouse', iconColor: 'blue' },
  [CAMPAIGN]: { label: 'Campaign Taxonomy', iconColor: 'green' },
  [AD_GROUP]: { label: 'Ad Group / Ad Set Taxonomy', iconColor: 'purple' },
}

export const BUDGET_CSV_KEYS = {
  BUDGET_SEGMENT: 'budget segment',
  BUDGET_AMOUNT: 'budget amount',
  START_DATE: 'start date',
  END_DATE: 'end date',
  KPI_TYPE: 'kpi type',
  KPI_TARGET: 'kpi target',
}

export const BUDGET_PACING_TYPE = {
  STANDARD: 'STANDARD',
  CUSTOM: 'CUSTOM',
  UPLOAD: 'UPLOAD',
}

export const defaultDimensionGroupOption = {
  dimensionName: '',
  dimensionOrder: null,
  dimensionInput: [],
}

export const validateBudgetPacing = (budgets) => {
  let isValid = true
  let errors = {}

  const requiredFields = [
    'budgetSegment',
    'budgetAmount',
    'startDate',
    'endDate',
    'kpiType',
    'kpiTarget',
    'associatedConversions',
    'dateGranularity',
  ]

  const allFieldsInvalid = {}
  requiredFields.forEach((field) => {
    allFieldsInvalid[field] = true
  })

  budgets.forEach((budget) => {
    const emptyFields = {}
    requiredFields.forEach((field) => {
      if (!budget[field]) {
        emptyFields[field] = true
      }
    })

    if (Object.keys(emptyFields).length > 0) {
      isValid = false
      errors = {
        ...errors,
        [budget.local__uuid]: { ...emptyFields },
        requiredFields: true,
      }
    }

    if (budget.associatedConversions < 1) {
      isValid = false
      errors = {
        ...errors,
        [budget.local__uuid]: {
          ...errors[budget.local__uuid],
          associatedConversions: true,
        },
        requiredFields: true,
      }
    }

    if (budget.isCustomBudget) {
      const dimensionErrors = validateDimensionGroups(budget.dimensionGroups)
      if (!isEmpty(dimensionErrors)) {
        isValid = false
        errors = {
          ...errors,
          [budget.local__uuid]: {
            ...errors[budget.local__uuid],
            dimensionErrors,
          },
          requiredFields: true,
        }
      }
    }

    if (!errors.requiredFields) {
      const duplicatedBudget = isBudgetPacingDuplicated(budget, budgets)

      if (duplicatedBudget && !errors.isDuplicated) {
        isValid = false
        errors = {
          ...errors,
          [budget.local__uuid]: {
            ...allFieldsInvalid,
            isDuplicated: true,
          },
          [duplicatedBudget.local__uuid]: {
            ...allFieldsInvalid,
            isDuplicated: true,
          },
          isDuplicated: `${
            budget?._id || duplicatedBudget?._id
              ? 'A budget segment has been added with duplicate settings. See the table below to see the budget segment with duplicate settings.'
              : 'Duplicate budget pacing settings.'
          }`,
        }
      }
    }
  })

  // Validate Budget Pacing Settings overlapping
  const { isOverlap, budget, overlap } =
    checkBudgetPacingSettingsOverlap(budgets)
  if (isOverlap && !errors.isDuplicated && !errors.requiredFields) {
    isValid = false

    if (budget?._id || overlap?._id) {
      const budgetId = budget.local__uuid || budget?._id
      const overlapId = overlap.local__uuid || overlap?._id

      errors = {
        ...errors,
        [budgetId]: {
          ...errors[budgetId],
          budgetSegment: true,
          startDate: true,
          endDate: true,
        },
        [overlapId]: {
          ...errors[overlapId],
          budgetSegment: true,
          startDate: true,
          endDate: true,
        },
        isOverlap: budget.isCustomBudget
          ? 'Budget Name cannot be the same as an existing Budget Pacing Segment with the same Start and End Dates'
          : 'A budget segment has been added with date ranges that overlap. See the table below to see the budget segment with date ranges that overlap.',
      }
    } else {
      errors = {
        ...errors,
        [budget.local__uuid]: {
          ...errors[budget.local__uuid],
          budgetSegment: true,
          startDate: true,
          endDate: true,
        },
        [overlap.local__uuid]: {
          ...errors[overlap.local__uuid],
          budgetSegment: true,
          startDate: true,
          endDate: true,
        },
        isOverlap:
          'The same budget segment cannot have date ranges that overlap',
      }
    }
  }

  return [isValid, errors]
}

/**
 * Validates the dimension groups and checks for missing dimension inputs.
 * @param {Array} dimensionGroups - The array of dimension groups to validate.
 * @returns {Object} - An object containing the validation errors, where the key is the group index and the value is an array of mandatory dimensions missing input.
 */
export const validateDimensionGroups = (dimensionGroups) => {
  const errors = {}

  dimensionGroups.forEach((group, index) => {
    if (group.dimensionGroupOptions.length > 0) {
      group.dimensionGroupOptions.forEach((option, optionIndex) => {
        if (
          option.dimensionName &&
          option.dimensionInput &&
          option.dimensionInput.length === 0
        ) {
          // Add error for the group index if it doesn't exist
          if (!errors[index]) {
            errors[index] = []
          }
          // Add the dimension name to the errors array for the group
          errors[index] = { [optionIndex]: true }
        }
      })
    }
  })

  return errors
}

/**
 * Updates custom budgets by removing dimensionGroupOptions that don't have dimensionName, dimensionOrder, or dimensionInput.
 * @param {Array} budgets - The array of budgets to be updated.
 * @returns {Array} - The updated array of budgets.
 */
export const updateCustomBudgets = (budgets) => {
  return budgets.map((budget) => {
    if (budget.isCustomBudget) {
      const updatedDimensionGroups = budget.dimensionGroups
        .map((group) => {
          const filteredOptions = group.dimensionGroupOptions.filter(
            (option) => {
              // Filter out dimensionGroupOptions that don't have required properties
              return (
                option.dimensionName !== '' ||
                option.dimensionOrder !== null ||
                (Array.isArray(option.dimensionInput) &&
                  option.dimensionInput.length > 0)
              )
            }
          )

          // Remove empty dimensionGroupOptions
          return {
            ...group,
            dimensionGroupOptions:
              filteredOptions.length > 0 ? filteredOptions : undefined,
          }
        })
        .filter((group) => group.dimensionGroupOptions !== undefined) // Remove groups without dimensionGroupOptions

      return {
        ...budget,
        dimensionGroups: updatedDimensionGroups,
      }
    }
    return budget
  })
}

/**
 * Function which checks if a budget is duplicated - same budget segment, amount, start/end date, kpi type, kpiType target
 * @param {Object} budget Budget to be checked
 * @param {Array} list Budgets to check against
 * @returns {Object}
 */
export const isBudgetPacingDuplicated = (budget, list) => {
  const {
    local__uuid,
    budgetSegment,
    budgetAmount,
    startDate,
    endDate,
    kpiType,
    kpiTarget,
  } = budget

  return list.find(
    (b) =>
      b?.local__uuid !== local__uuid &&
      b.budgetSegment === budgetSegment &&
      b.budgetAmount === budgetAmount &&
      getDateWithoutTimestamp(b.startDate).getTime() ===
        getDateWithoutTimestamp(startDate).getTime() &&
      getDateWithoutTimestamp(b.endDate).getTime() ===
        getDateWithoutTimestamp(endDate).getTime() &&
      b.kpiType === kpiType &&
      b.kpiTarget === kpiTarget
  )
}

export const checkAllFieldsNull = (obj) => {
  for (const key in obj) {
    if (
      key === 'requiredFields' ||
      key === 'isDuplicated' ||
      key === 'dimensionErrors' ||
      key === 'isOverlap'
    ) {
      continue // Skip the 'requiredFields' field
    }

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (!checkAllFieldsNull(obj[key])) {
        return false
      }
    } else if (obj[key] !== null) {
      return false
    }
  }
  return true
}

export const filterBudgetPacing = (budgets, filter) => {
  return budgets.filter((item) => {
    if (
      filter.budgetAmount_from &&
      item.budgetAmount < filter.budgetAmount_from
    ) {
      return false
    }

    if (filter.budgetAmount_to && item.budgetAmount > filter.budgetAmount_to) {
      return false
    }

    if (
      filter.startDate &&
      getDateWithoutTimestamp(item.startDate).getTime() <
        getDateWithoutTimestamp(filter.startDate).getTime()
    ) {
      return false
    }

    if (
      filter.endDate &&
      getDateWithoutTimestamp(item.endDate).getTime() >
        getDateWithoutTimestamp(filter.endDate).getTime()
    ) {
      return false
    }

    if (
      filter.budgetSegment &&
      !item.budgetSegment
        .toLowerCase()
        .includes(filter.budgetSegment.toLowerCase()) &&
      !item.kpiType.toLowerCase().includes(filter.budgetSegment.toLowerCase())
    ) {
      return false
    }

    if (filter.dateGranularity) {
      return filter.dateGranularity.includes(item.dateGranularity)
    }

    return true
  })
}

export const getCustomDimensionOptions = (
  clientDimensions,
  existingDimensions
) => {
  const options = []
  Object.entries(DIMENSION_SECTIONS).forEach(([key, config]) => {
    const { label, iconColor } = config
    options.push({
      disabled: true,
      label: (
        <div className="section-header">
          <div className={`round-button round-button--${iconColor}`}></div>
          <div className="general-label">{label}</div>
        </div>
      ),
      subsections: Object.keys(clientDimensions[key] || []).map((dim) => ({
        label: dim,
        value: dim,
        disabled: existingDimensions.some(
          (dimension) =>
            dimension.dimensionName === dim && dimension.source === key
        ),
        extra: key,
      })),
    })
  })
  return options
}

export const getDimensionInputs = (dimension, allDimensions) => {
  const { source, dimensionName } = dimension
  const isAccounts = dimensionName === ACCOUNTS && source === DATA_WAREHOUSE
  const isPublisher = dimensionName === PUBLISHER && source === DATA_WAREHOUSE
  const dimensionValues =
    allDimensions[source]?.[dimensionName]?.dimensionValues || []

  if (isAccounts) {
    return dimensionValues.map(({ name, externalAccountId, type }) => ({
      label: name,
      value: externalAccountId,
      hint: externalAccountId,
      accountType: type,
    }))
  }
  if (isPublisher) {
    return dimensionValues.map((publisher) => ({
      label: ACCOUNT_TYPE_NAMES[publisher],
      value: publisher,
      accountType: publisher,
    }))
  }

  return dimensionValues.map((el) => ({
    label: el,
    value: el,
  }))
}

export const getIconBySource = (dimension) => {
  const { source } = dimension
  const color = DIMENSION_SECTIONS[source]?.iconColor
  return <div className={`round-button round-button--${color}`}></div>
}

export const getInputTextTag = (
  dimension,
  input,
  onClose,
  clientDimensions
) => {
  const { source, dimensionName } = dimension
  const isAccount = dimensionName === ACCOUNTS && source === DATA_WAREHOUSE
  const isPublisher = dimensionName === PUBLISHER && source === DATA_WAREHOUSE

  const tagProps = {
    label: input,
    key: input,
    onClose: onClose,
    light: true,
    small: true,
  }
  if (isAccount) {
    const accounts =
      clientDimensions[source][dimensionName].dimensionValues || []
    const account = accounts.find(
      ({ externalAccountId }) => externalAccountId === input
    )
    if (account) {
      const Icon = ACCOUNT_TYPE_ICONS[account.type]
      tagProps.label = (
        <div className="tag-with-icon">
          {<Icon width={20} height={20} />}
          {account.name}
        </div>
      )
    }
  }

  if (isPublisher) {
    const Icon = ACCOUNT_TYPE_ICONS[input]
    tagProps.label = (
      <div className="tag-with-icon">
        {<Icon width={20} height={20} />}
        {ACCOUNT_TYPE_NAMES[input]}
      </div>
    )
  }

  return <TextTag {...tagProps} />
}
