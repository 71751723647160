import React from 'react'
import PropTypes from 'prop-types'

import WarningIcon from 'components/warning-icon'
import Button from 'components/button'
import Modal from 'components/modal'

const SaveConventionModal = ({ open, onConfirm, onCancel }) => {
  return (
    <Modal
      className="naming-conventions__modal"
      icon={<WarningIcon />}
      rightAlignButtons
      opened={!!open}
      contentSeparator
      button={<Button value="Confirm" green onClick={onConfirm} />}
      buttonSecondary={
        <Button value={'Cancel'} onClick={onCancel} secondaryGray />
      }
      heading="Update Naming Convention"
    >
      <div>
        You are about to save updates to a naming convention. Confirm if you
        want to continue this action. This action cannot be undone.
      </div>
    </Modal>
  )
}

SaveConventionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default SaveConventionModal
