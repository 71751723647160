import cx from 'classnames'
import { CheckboxNoHooks } from 'components/checkbox/index'
import CollapsibleSection from 'components/collapsible-section/index'
import { Dropdown } from 'components/dropdown'
import InputText from 'components/input'
import useLeaveConfirm from 'components/leave-confirm/index'
import { RichTextEditor } from 'components/rich-text-editor/index'
import StickyFooter from 'components/sticky-footer/index'
import Tooltip from 'components/tooltip/index'
import { useEffectOnUpdate } from 'components/utils/custom-hooks'
import { FIELD_TYPES, validate } from 'components/validator'
import { PERMISSIONS, PERMISSION_TYPES, useAccess } from 'hooks/access'
import { showSuccessMessage } from 'modules/notifications/actions'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'store'
import { legal } from '@decision-sciences/qontrol-common'
import {
  createUpdateLegalItem,
  getLegalViewItems,
  updateCurrentEditedLegal,
} from './actions'
import { LegalSectionsTable } from './components/legal-sections-table'
import { UserTermsOfUseAcceptanceTable } from './components/users-terms-of-use-table'

import './styles.scss'

const {
  LEGAL_DISPLAY_AREA,
  LEGAL_DISPLAY_AREA_VALUES,
  LEGAL_TYPES,
  LEGAL_TYPE_VALUES,
} = legal

/**
 * Component for creating or editing a Legal item
 * @returns {React.Component}
 */
const CreateEditLegalItem = () => {
  const navigate = useNavigate()

  const { state, dispatch } = useStore()

  const {
    legal: { currentEditedLegal: legalItem },
  } = state

  const hasUserAcceptanceTableAccess = useAccess({
    feature: PERMISSIONS.TERMS_OF_USE_ACCEPTANCE_TABLE,
    type: PERMISSION_TYPES.READ,
  })

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  /* Check if Add sections button is hovered */
  const [hoverOnButton, setHoverOnButton] = useState(false)

  const [setDirty, LeaveConfirm] = useLeaveConfirm({
    safeRoutes: ['/legal/cms/:/section/:', '/legal/cms/:/section/new'],
  })

  /*
   * On mount, if the currently selected legalItem is dirty, mark the leave confirm component as dirty.
   * This is done to ensure the LeaveConfirm dialog shows up when coming back from the create/edit legal section page.
   */
  useEffect(() => {
    if (legalItem.isDirty) {
      setDirty(true)
    }
  }, [])

  /* Make page available only when the legalItem is fetched and stored */
  useEffect(() => {
    setLoading(!legalItem)
  }, [legalItem])

  /* When the legalItem.type changes, clear out fields that are not used for that type to avoid confusion */
  useEffectOnUpdate(() => {
    switch (legalItem.type) {
      case LEGAL_TYPES.SIMPLE_TEXT:
        editLegalItem('content', null)
        editLegalItem('sections', [])
        break
      case LEGAL_TYPES.DOCUMENT_WITHOUT_SECTIONS:
        editLegalItem('sections', [])
        break
      case LEGAL_TYPES.DOCUMENT_WITH_SECTIONS:
        editLegalItem('content', null)
        break
    }
  }, [legalItem.type])

  /* Helper function to edit a field on the legalItem object */
  const editLegalItem = (fieldName, value) => {
    setDirty(true)
    updateCurrentEditedLegal(dispatch, {
      ...legalItem,
      [fieldName]: value,
    })
  }

  /* Helper function for update the sections */
  const updateLegalSections = () => {
    const legalItemCopy = { ...legalItem }
    if (legalItemCopy.type === LEGAL_TYPES.DOCUMENT_WITH_SECTIONS) {
      legalItemCopy.sections.forEach(
        (section) =>
          (section.content = section?.pendingContent || section.content)
      )
    }
    return legalItemCopy
  }

  /* Handler for saving the data */
  const onSave = () => {
    setLoading(true)

    const [isValid, errors] = validate(ERROR_MAP, legalItem)

    if (!isValid) {
      setLoading(false)
      return setErrors(errors)
    }

    setDirty(false)
    const updatedLegal = updateLegalSections()
    createUpdateLegalItem(dispatch, updatedLegal)
      .then(() => {
        setLoading(false)
        getLegalViewItems(dispatch, LEGAL_DISPLAY_AREA.SIDE_NAV)
        setDirty(false)
        navigate(`/legal/cms`)
      })
      .catch(() => {
        setLoading(false)
      })
      .finally(() => {
        showSuccessMessage(
          `Legal documentation ${legalItem.isNew ? 'created' : 'updated'}`,
          dispatch
        )
      })
  }

  const canCreateNewSection = !!legalItem._id

  /* Handler for canceling the current edit or create operation */
  const onCancel = () => {
    navigate('/legal/cms')
  }

  const pageTitle = legalItem.isNew
    ? 'Add to Legal'
    : 'Edit Legal Documentation'

  if (loading || !legalItem) {
    return null
  }

  return (
    <>
      <div className="heading" data-cy="page-heading">
        {pageTitle}
      </div>
      <form className="form">
        <section className="align-row form__section__body">
          <div className="form__section__body__half-width-section">
            <InputText
              placeholder={'Enter Name'}
              label="Name"
              value={legalItem.name}
              onChange={(value) => editLegalItem('name', value)}
              error={errors.name}
              className="margin-bottom-22 input-wrapper--uppercase"
              disabled={loading}
            />
          </div>

          <div className="form__section__body__half-width-section">
            <Dropdown
              label="Type"
              options={LEGAL_TYPE_VALUES}
              className="margin-bottom-22 input-wrapper--uppercase"
              defaultState={legalItem.type}
              disabled={loading}
              error={errors.type}
              onChange={(value) => editLegalItem('type', value)}
            />
          </div>
        </section>

        <section className="align-column form__section__body">
          <div className="general-label">Display</div>
          <div className="general-description">
            {
              'If desired, select the location(s) to display the text from the Name field above.'
            }
          </div>
          {Object.entries(LEGAL_DISPLAY_AREA_VALUES).map(
            ([value, { longLabel }]) => (
              <div key={value} style={{ marginTop: '12px' }}>
                <CheckboxNoHooks
                  reversed
                  disabled={loading}
                  label={longLabel}
                  defaultValue={legalItem.displayArea?.includes(value)}
                  onChange={(checked) => {
                    if (checked) {
                      editLegalItem('displayArea', [
                        ...legalItem.displayArea,
                        value,
                      ])
                    } else {
                      editLegalItem(
                        'displayArea',
                        legalItem.displayArea.filter(
                          (displayArea) => displayArea !== value
                        )
                      )
                    }
                  }}
                />
              </div>
            )
          )}
        </section>

        {/* Render extra content for itme content editing */}
        {legalItem?.type === LEGAL_TYPES.DOCUMENT_WITHOUT_SECTIONS && (
          <section className="align-column form__section__body">
            <div className="general-label">Content</div>
            <div className="general-description">
              {
                'If this document has any display options above selected and content is added below, a link will be generated to view the content of this document.'
              }
            </div>
            <div style={{ marginTop: '12px' }}>
              <RichTextEditor
                error={errors.content}
                content={legalItem.content}
                onChange={(content) => editLegalItem('content', content)}
              />
            </div>
          </section>
        )}

        {/* Render extra content for section creation/view */}
        {legalItem?.type === LEGAL_TYPES.DOCUMENT_WITH_SECTIONS && (
          <section className="align-column form__section__body">
            {!canCreateNewSection && (
              <Tooltip
                show={!canCreateNewSection && hoverOnButton}
                content={
                  <div>
                    {
                      'Please save the legal documentation before creating a new section'
                    }
                  </div>
                }
              />
            )}
            <div className="align-row general-label space-between">
              <div>Content Sections</div>
              <div
                onClick={() =>
                  canCreateNewSection &&
                  navigate(`/legal/cms/${legalItem._id || 'new'}/section/new`)
                }
                onMouseEnter={() => setHoverOnButton(true)}
                onMouseLeave={() => setHoverOnButton(false)}
                className={cx('fake-link', {
                  'fake-link--disabled': !canCreateNewSection,
                })}
              >
                {'+ Add section'}
              </div>
            </div>
            <div className="general-description">
              {
                'Edit and order the sections of this document below. You can also add a new section or turn off displaying a section to save for later use. If a section is displayed below and this document has either Display options above selected, a link will be generated to view the content of this document.'
              }
            </div>
            <div style={{ marginTop: '12px' }}>
              <LegalSectionsTable />
            </div>
          </section>
        )}

        {!legalItem.isDeletable && hasUserAcceptanceTableAccess && (
          <div className="legal-users-table-wrapper">
            <div className="flow__separator" />
            <CollapsibleSection
              header="Users Who Accepted Terms of Use"
              defaultCollapsed={false}
              className="legal-users-table"
            >
              <UserTermsOfUseAcceptanceTable />
            </CollapsibleSection>
          </div>
        )}
      </form>

      <StickyFooter
        buttons={[
          {
            value: 'Save',
            onClick: onSave,
          },
          {
            value: 'Cancel',
            onClick: onCancel,
            secondaryGray: true,
          },
        ]}
      />

      <LeaveConfirm />
    </>
  )
}

const ERROR_MAP = {
  name: FIELD_TYPES.REQUIRED,
  type: FIELD_TYPES.REQUIRED,
}

export { CreateEditLegalItem }
