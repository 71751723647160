import { compareAsc, format } from 'date-fns'
import { utils, accounts, flow } from '@decision-sciences/qontrol-common'

import {
  ONE_DAY_AS_MILLISECONDS,
  THIRTY_MINUTES_AS_MILISECONDS,
} from 'constants/date'
import { ERRORS } from 'components/validator'
import { validateSchedules } from 'components/schedule/utils'

const { isEmpty } = utils.object
const { ACCOUNT_TYPES_MAP } = accounts
const { mergeWithoutArrays } = utils.array

const { GOOGLE, FACEBOOK, ODAX } = ACCOUNT_TYPES_MAP
const {
  BID_STRATEGY_TYPE,
  OBJECTIVES_MAP,
  DELIVERY_METHOD,
  ADSET_DESTINATION_TYPE,
  BUDGET_TYPE,
  OPTIMIZATION_GOAL,
  OBJECTIVES_MAP_LABELS,
  ODAX_OBJECTIVES_MAP_LABELS,
} = flow.facebook
const { GOOGLE_CAMPAIGN_OBJECTIVE_LABELS } = flow.google
const { checkPlacementConditions, PLACEMENTS_MAP, PLACEMENT_PLATFORM_MAP } =
  flow.facebook.placements

// #region Validation functions

/**
 * Validates the budget amount. It must be greater than or equal to 1 and don't start with ',' or '.'.
 * @param adSet - An Ad Set object
 * @returns {boolean}
 */
export const validateAdSetBudgetAmount = (adSet) => {
  const budgetAmount = adSet.budget.amount.toString()
  if (adSet.budget.budgetFlag) {
    return (
      budgetAmount >= 1 &&
      !(budgetAmount.startsWith(',') || budgetAmount.startsWith('.'))
    )
  }
  return true
}

/**
 * Validate minimum ad set spending limit if campaign spending limit has been set.
 * If a campaign spending limit was set, the minimum ad set spending limit has to be <= 90% of this else <=90% of campaign budget.
 * The sum of all minimum ad set spend limits of a campaign has to be lower than the campaign budget.
 * If budget type is lifetime budget, maximum limit has to be >= 30$ and difference between min and max has to be of min 30$.
 * @param adSet - Ad set object
 * @param campaign - Campaign object
 *@param adSets - Array of all brief adsets of type odax
 */

export const validateAdSetSpendingLimit = (adSet, campaign, adSets) => {
  let [isValid, errors] = [true, {}]
  const hasLimits = adSet.schedule.adSpendLimits.hasSpendLimits

  // If ad set has limits set then check them
  if (hasLimits) {
    const budgetType = campaign.data.budgetType
    const campaignBudget = campaign.data.budgetAmount
    const campaignAdSetsToCheck = adSets.filter(
      (adS) =>
        adS.campaignId === campaign._id &&
        adS.schedule.adSpendLimits.hasSpendLimits &&
        !adS.deleted
    )
    const minimumAdSetLimit = parseFloat(
      adSet.schedule.adSpendLimits.daily_minimum ||
        adSet.schedule.adSpendLimits.lifetime_minimum
    )

    if (
      campaign.data.spendingLimit &&
      minimumAdSetLimit > 0.9 * campaign.data.spendingLimit
    ) {
      isValid = false
      errors.invalid_min_spend_limit =
        'Minimum spending limit must be less than or equal to 90% of campaign spending limit'
    }

    if (
      !campaign.data.spendingLimit &&
      minimumAdSetLimit > 0.9 * campaignBudget
    ) {
      isValid = false
      errors.invalid_min_spend_limit =
        'Minimum spending limit must be less than or equal to 90% of campaign spending limit'
    }

    if (campaignAdSetsToCheck.length > 1) {
      let totalSpend = 0
      campaignAdSetsToCheck.forEach((adSet) => {
        const minSpendLimit = parseFloat(
          adSet.schedule.adSpendLimits.daily_minimum ||
            adSet.schedule.adSpendLimits.lifetime_minimum
        )
        totalSpend += minSpendLimit
      })

      if (totalSpend > campaignBudget) {
        isValid = false
        errors.invalid_total_spend =
          'Ad sets combined minimum spend is higher than campaign budget'
      }
    }

    if (budgetType === BUDGET_TYPE.LIFETIME_BUDGET) {
      const maxAdSetLimit = adSet.schedule.adSpendLimits.lifetime_maximum
      const threshold = 30

      if (maxAdSetLimit < threshold) {
        isValid = false
        errors.min_maximum_spend =
          'The maximum spend limit must be at least 30,00$'
      }

      if (maxAdSetLimit - minimumAdSetLimit < threshold) {
        isValid = false
        errors.invalid_maximum_spend =
          'There must be a 30,00$ difference between the maximum and minimum ad set spending limits'
      }
    }
  }

  return [isValid, errors]
}

/**
 * Validate the start time.
 * @param adSet - An Ad Set object
 * @returns {boolean}
 */
export const validateStartDateTime = (adSet) => {
  if (!adSet?.schedule) {
    return false
  }

  const {
    schedule: { startDate, startTime },
  } = adSet
  const date = `${startDate} ${startTime}`
  const selectedDate = new Date(date).getTime()
  const currentDate = new Date().getTime()

  // Time can not be in past
  return !(
    startTime &&
    startDate === format(new Date(), 'MM/dd/yyyy') &&
    selectedDate <= currentDate
  )
}

/**
 * Validate the start date
 * @param adSet - Ad Set object
 * @return {boolean}
 */
export const validateStartDateInPast = (adSet) => {
  if (!adSet?.schedule) {
    return false
  }
  const {
    schedule: { startDate, startTime },
  } = adSet
  // Date can not be in the past
  return !isPastDate(startDate, startTime)
}

/**
 * In case the budget optimization is set as 'daily budget', this function checks if the end date is at least 24 hours greater
 * than the selected start date. If CBO is 'lifetime budget' it checks if end date is after start date.
 * @param {Object} adSet - A list of AdSets objects
 * @returns {boolean} - true if valid, false if not valid
 */
export const validateStartDateAndEndDate = (adSet) => {
  if (!adSet?.schedule) {
    return false
  }

  const {
    schedule: { startDate, startTime, endDate, endTime, adSchedule },
    budget,
  } = adSet

  if (budget.type === BUDGET_TYPE.LIFETIME_BUDGET) {
    /* For Lifetime budget: Check if end date is missing or if it is before start date */
    if (!endDate || !endTime) {
      return false
    }

    const startDateAsTime = new Date(`${startDate} ${startTime}`).getTime()
    const endDateAsTime = new Date(`${endDate} ${endTime}`).getTime()

    /** Validates that End DateTime is 30 minutes greater than Start DateTime */
    if (endDateAsTime - startDateAsTime <= THIRTY_MINUTES_AS_MILISECONDS) {
      return false
    }
    if (adSchedule.isScheduled && adSchedule?.schedules) {
      return !adSchedule.schedules.some((time) => {
        // Check if 'from' and 'to' is both AM or PM and check values
        return (
          time.from.split(' ')[1] === time.to.split(' ')[1] &&
          time.from > time.to
        )
      })
    }
  } else if (budget.type === BUDGET_TYPE.DAILY_BUDGET) {
    /* For Daily budget: If end date is set, then the difference between start and end date must be 24 hours */
    return validateDateTime24Hours(adSet.schedule)
  }

  return true
}

/** Validates that End DateTime is 24 hours greater than Start DateTime */
export const validateDateTime24Hours = (schedule) => {
  const { startDate, startTime, endDate, endTime } = schedule
  if (endDate && endTime) {
    const startDateAsTime = new Date(`${startDate} ${startTime}`).getTime()
    const endDateAsTime = new Date(`${endDate} ${endTime}`).getTime()

    if (endDateAsTime - startDateAsTime <= ONE_DAY_AS_MILLISECONDS) {
      return false
    }
  }
  return true
}

/** Validate Optimization for Ad Delivery section */
export const validateOptimization = (adSetOptions, campaignData) => {
  let [isValid, errors] = [true, {}]
  if (campaignData?.budgetFlag) {
    if (
      campaignData.bidStrategy !== BID_STRATEGY_TYPE.LOWEST_COST_WITHOUT_CAP &&
      isEmpty(adSetOptions.costControl)
    ) {
      isValid = false
      errors.costControl = ERRORS.REQUIRED
    }
  }
  // For "App Events" the selection of an app event is required
  if (
    campaignData?.objective === OBJECTIVES_MAP.APP_INSTALLS &&
    adSetOptions?.optimization === OPTIMIZATION_GOAL.APP_EVENTS.value &&
    isEmpty(adSetOptions.appEvent)
  ) {
    isValid = false
    errors.appEvent = ERRORS.REQUIRED
  }
  return [isValid, errors]
}

/** Validate Conversions Event section */
export const validateConversionsEvent = (adSetOptions, campaignData) => {
  let [isValid, errors] = [true, {}]
  if (OBJECTIVES_MAP.CONVERSIONS === campaignData?.objective) {
    const eventType = adSetOptions.conversionEvent.type
    if (
      [
        ADSET_DESTINATION_TYPE.MESSENGER.value,
        ADSET_DESTINATION_TYPE.WEBSITE.value,
        ADSET_DESTINATION_TYPE.WHATSAPP.value,
      ].includes(eventType) &&
      isEmpty(adSetOptions.conversionEvent.facebookPixel)
    ) {
      isValid = false
      if (!errors.conversionEvent) {
        errors.conversionEvent = {}
      }
      errors.conversionEvent.facebookPixel = ERRORS.REQUIRED
    }
    if (
      [
        ADSET_DESTINATION_TYPE.MESSENGER.value,
        ADSET_DESTINATION_TYPE.WEBSITE.value,
      ].includes(eventType) &&
      isEmpty(adSetOptions.conversionEvent.event)
    ) {
      isValid = false
      if (!errors.conversionEvent) {
        errors.conversionEvent = {}
      }
      errors.conversionEvent.event = ERRORS.REQUIRED
    }
    if (
      [
        ADSET_DESTINATION_TYPE.APP.value,
        ADSET_DESTINATION_TYPE.WHATSAPP.value,
      ].includes(eventType) &&
      isEmpty(adSetOptions.appEvent)
    ) {
      isValid = false
      errors.appEvent = ERRORS.REQUIRED
    }
  }

  return [isValid, errors]
}

/**
 * Validates audience targeting data (for ad sets and saved audiences)
 * @param {Object} audienceData Ad Set Audience
 * @returns {Array} Tuple of {Bool} isValid -> Whether the audience data is valid or not and {Object} errors -> Errors that have been discovered
 */
export const validateAudienceTargeting = (audienceData) => {
  const MISSING_LOCATION_ERROR =
    'You must select at least one included location'

  const errors = {}

  let isValid = true

  const locationsArray = audienceData?.locations?.locationsArray || []

  if (
    !locationsArray.length ||
    !locationsArray.some(
      (location) => location.filter || location.filter.value === 'geo_locations'
    )
  ) {
    isValid = false
    if (!errors.locations) {
      errors.locations = {}
    }
    errors.locations.locationsArray = MISSING_LOCATION_ERROR
  } else {
    const countryGroupSet = new Set(
      locationsArray
        .filter((location) => location.type === 'region')
        .map((location) => location.name)
    )
    for (const location of locationsArray) {
      if (location.type === 'city' && countryGroupSet.has(location.region)) {
        errors.locations.locationsArray = `City ${location.name} already belongs to Region ${location.region}. Locations overlap.`
        isValid = false
        break
      }
    }
  }

  Object.keys(audienceData).forEach((key) => {
    if (!errors[key]) {
      errors[key] = {}
    }
  })

  // For connection types other than "all people" the facebook page is mandatory
  if (
    audienceData?.connections &&
    !audienceData.connections.allPeople &&
    !audienceData.connections.connectionType?.options?.connections?.length
  ) {
    isValid = false
    errors.connections = {
      ...(errors?.connections || {}),
      connectionType: {
        ...(errors?.connections?.connectionType || {}),
        options: {
          ...(errors?.connections?.connectionType?.options || {}),
          connections: 'Facebook page is mandatory',
        },
      },
    }
  }

  return [isValid, errors]
}

export const isPastDate = (date, time) => {
  if (!isEmpty(date)) {
    const finalDate = time ? `${date} ${time}` : date

    const newDate = time
      ? new Date(finalDate).getTime()
      : new Date(finalDate).setUTCHours(23, 59, 59)

    return newDate < new Date().getTime()
  }
  return false
}

/**
 * Helper function for check ad groups for start/end date in the past
 * @param {Array} assets Arrays of assets created for this brief
 * @returns {Array} Tuple of {Boolean} isInValidEndDate -> when we have an ad set with end date in the past and {Array} invalidAdSets -> Ad Sets with invalid start/end date
 */
export const startAndEndDateValidations = (assets) => {
  let isInValidEndDate = false
  const invalidAssets = []
  assets.forEach((asset) => {
    switch (asset.type) {
      case ACCOUNT_TYPES_MAP.FACEBOOK:
        {
          if (
            isPastDate(asset?.schedule?.startDate, asset?.schedule?.startTime)
          ) {
            invalidAssets.push(asset)
          }
          if (
            !isEmpty(asset?.schedule?.endDate) &&
            isPastDate(asset?.schedule?.endDate, asset?.schedule?.endTime)
          ) {
            isInValidEndDate = true
          }
        }
        break
      case ACCOUNT_TYPES_MAP.ODAX:
        {
          if (
            isPastDate(asset?.schedule?.start_date, asset?.schedule?.start_time)
          ) {
            invalidAssets.push(asset)
          }
          if (
            !isEmpty(asset?.schedule?.end_date) &&
            isPastDate(asset?.schedule?.end_date, asset?.schedule?.end_time)
          ) {
            isInValidEndDate = true
          }
        }
        break
      case ACCOUNT_TYPES_MAP.GOOGLE:
        {
          if (isPastDate(asset?.start_date)) {
            invalidAssets.push(asset)
          }
          if (!isEmpty(asset?.end_date) && isPastDate(asset?.end_date)) {
            isInValidEndDate = true
          }
        }
        break
    }
  })
  return [isInValidEndDate, invalidAssets]
}

/**
 * Helper function to call validation methods for each delivery type.
 * All errors will be under the `delivery` key.
 *
 * @param {Object} adSet AdSet to validate
 * @param {Array} allAds Arrays of Ads created for this brief
 * @returns {Array} Tuple of {Bool} isValid -> Whether the audience data is valid or not and {Object} errors -> Errors that have been discovered
 */
export const validateAdSetDelivery = (adSet, allAds) => {
  const adSetAds = allAds
    ? allAds.filter((item) => !item.deleted && item.adSetId === adSet.id)
    : []
  switch (adSet.schedule?.delivery?.type) {
    case DELIVERY_METHOD.SCHEDULED:
      return validateScheduledDelivery(adSet, adSetAds)
    default:
      return [true, {}]
  }
}

/** Validate the Facebook Page selection */
export const validateFacebookPage = (objective, adSet) => {
  if (
    ([OBJECTIVES_MAP.PAGE_LIKES, OBJECTIVES_MAP.LEAD_GENERATION].includes(
      objective
    ) ||
      (objective === OBJECTIVES_MAP.CONVERSIONS &&
        adSet.adSetOptions.conversionEvent?.type ===
          ADSET_DESTINATION_TYPE.WHATSAPP.value)) &&
    isEmpty(adSet.adSetOptions.facebookPage)
  ) {
    return [false, { facebookPage: ERRORS.REQUIRED }]
  }
  return [true, {}]
}

/**
 * Validate all aspects regarding Ads Scheduling
 * @param {Object} adSet Ad Set data
 * @param {Object } _campaignData campaign.data
 * @returns {Array<boolean,{}>|Array<boolean,{schedule: {items: {}}}>}
 */
export const validateAdsScheduling = (adSet, _campaignData) => {
  const adSchedule = adSet.schedule?.adSchedule
  const errors = {
    schedule: {
      items: {},
    },
  }
  let isValid = true
  const campaignData = mergeWithoutArrays(
    _campaignData,
    _campaignData.pendingChanges
  )
  if (
    (campaignData?.scheduledAds && campaignData.budgetFlag) ||
    adSchedule?.isScheduled
  ) {
    if (!adSchedule.timezone) {
      errors.schedule.timezone = ERRORS.REQUIRED
    }
    if (isEmpty(adSchedule.schedules)) {
      errors.schedule.schedule = `At least one schedule interval is required to create an AdSet with Scheduled Ads turned on. ${
        campaignData?.scheduledAds
          ? '(Scheduled Ads enabled at Campaign level)'
          : ''
      }`
      isValid = false
    } else {
      const [isValidSchedule, scheduleErrors] = validateSchedules(
        adSchedule.schedules
      )
      if (!isValidSchedule) {
        isValid = false
        errors.schedule.items = scheduleErrors
      }
    }

    return [isValid, errors]
  }
  return [true, {}]
}

// Check if adSet has Platfom selected
export const hasPlatformSelected = (adSetOptions) => {
  return (
    (adSetOptions.conversionEvent.platform &&
      (adSetOptions.conversionEvent.type === ADSET_DESTINATION_TYPE.APP.value ||
        (adSetOptions?.driveTrafficTo?.length &&
          adSetOptions?.driveTrafficTo[0] ===
            ADSET_DESTINATION_TYPE.APP.value))) ||
    adSetOptions.appToPromote?.platform
  )
}

/** Validate Apps to Promote & their corresponding Platform - only applies to App Installs and for one type of Conversions */
export const validateAdSetAppsToPromote = (objective, adSet, campaignData) => {
  let [isValid, errors] = [true, {}]
  let fieldToValidate = null
  if (objective === OBJECTIVES_MAP.APP_INSTALLS && !campaignData?.ios14Flag) {
    fieldToValidate = 'appToPromote'
  } else if (
    objective === OBJECTIVES_MAP.CONVERSIONS &&
    adSet.adSetOptions.conversionEvent?.type ===
      ADSET_DESTINATION_TYPE.APP.value
  ) {
    fieldToValidate = 'conversionEvent'
  }

  if (fieldToValidate) {
    const platform = adSet.adSetOptions[fieldToValidate]?.platform
    const appId = adSet.adSetOptions[fieldToValidate]?.appId
    errors[fieldToValidate] = {}
    if (!platform && fieldToValidate !== 'conversionEvent') {
      isValid = false
      errors[fieldToValidate].platform = ERRORS.REQUIRED
    }
    if (!appId) {
      isValid = false
      errors[fieldToValidate].appId = ERRORS.REQUIRED
    }
  }
  return [isValid, errors]
}

/** Validate Catalog and Product Set */
export const validateCatalogAndProductSet = (objective, adSet) => {
  const { catalog, productSet } = adSet.adSetOptions
  let [isValid, errors] = [true, {}]
  const isCatalogRequired = objective === OBJECTIVES_MAP.CATALOG_SALES
  const isProductSetRequired = objective === OBJECTIVES_MAP.CATALOG_SALES
  if (isCatalogRequired && isEmpty(catalog)) {
    isValid = false
    errors.catalog = ERRORS.REQUIRED
  }
  if (isProductSetRequired && isEmpty(productSet)) {
    isValid = false
    errors.productSet = ERRORS.REQUIRED
  }
  return [isValid, errors]
}

/**
 * Validate AdSet when Delivery method is set to scheduled.
 * This should be called after checking that the AdSet delivery type is Scheduled.
 *
 * Conditions to be fulfilled:
 * 1. For an AdSet to have this type, it must have at least one Ad created.
 * 2. At least two date ranged must be created
 * 3. A date range cannot have no ads selected
 * 4. A date range cannot have start date after end date
 * 5. All Ads created for the AdSet must be present in a Date Range.
 *
 * Keys used to return errors for conditions mentioned above:
 * 1. 'delivery.scheduled.noAds'
 * 2. 'delivery.scheduled.notEnoughDateRanges'
 * 3. 'delivery.scheduled.notAllAdsSelected'
 * 4. 'delivery.scheduled.invalidStartEndDates'
 * 5. 'delivery.scheduled.noAdsSelectedForRange'
 *
 * @param {Object} adSet AdSet to validate
 * @param {Array} adSetAds Arrays of Ads created for the AdSet
 * @returns {Array} Tuple of {Bool} isValid -> Whether the audience data is valid or not and {Object} errors -> Errors that have been discovered
 */
export const validateScheduledDelivery = (adSet, adSetAds) => {
  const errors = {
    scheduled: {
      noAds: null,
      notAllAdsSelected: null,
      notEnoughDateRanges: null,
      invalidStartEndDates: null,
      noAdsSelectedForRange: null,
    },
  }

  let isValid = true

  /* Check condition #1 */
  if (!adSetAds?.length) {
    isValid = false
    errors.scheduled.noAds =
      'There are no Ads created for this AdSet. Please create at least one Ad and then try to use the Scheduled Delivery method.'
  } else {
    const {
      schedule: { delivery },
    } = adSet
    /* Check condition #2 */
    if (!delivery?.dateRanges || delivery?.dateRanges?.length < 2) {
      isValid = false
      errors.scheduled.notEnoughDateRanges =
        'There need to be at least two Date Ranges created'
    } else {
      /* Check condition #3 */
      if (
        delivery.dateRanges.some(
          (dateRange) => !dateRange?.ads || !dateRange.ads.length
        )
      ) {
        isValid = false
        errors.scheduled.noAdsSelectedForRange =
          'There is a Date Range which has no Ads selected. Please select at least one Ad for it.'
      }

      if (
        delivery.dateRanges.some(
          (dateRange) =>
            compareAsc(
              new Date(dateRange?.startDate),
              new Date(dateRange?.endDate)
            ) === 1
        )
      ) {
        isValid = false
        errors.scheduled.invalidStartEndDates =
          'There is a Date Range which has the start date after the end date.'
      }

      /* Check condition #4 */
      const dateRangesAdIds = delivery.dateRanges.reduce(
        (acc, { ads }) =>
          (acc = [...acc, ...ads.filter((adId) => !acc.includes(adId))]),
        []
      )
      if (adSetAds.some(({ id }) => !dateRangesAdIds.includes(id))) {
        isValid = false
        errors.scheduled.notAllAdsSelected =
          'All Ads created for this AdSet must be part of a Date Range.'
      }
    }
  }

  return [isValid, errors]
}

// #endregion

// #region Auxiliary helper functions

/**
 * Function which finds the associated Campaign for an Ad Set
 *
 * @param {Array} campaignsArray List of all Campaigns created for a brief
 * @param {Object} adSet Ad Set object to get the Campaign for
 * @returns {Object | undefined}
 */
export const getCampaignForAdSet = (campaignsArray, adSet) => {
  return campaignsArray.find(
    (c) => c._id === adSet.campaignId || c.id === adSet.campaignId
  )
}
/**
 * Function which finds the associated Ads for an array of Ad Sets
 *
 * @param {Object} arguments Function object arguments
 * @param {Array} arguments.adSetsArray List of all AdSets created for a campaign
 * @param {Array} [arguments.ads = []] Ads array
 * @returns {Array | undefined}
 */
export const getAdSetAds = ({ adSetsArray, ads = [] }) =>
  ads.filter((ad) => adSetsArray.map((adSet) => adSet._id).includes(ad.adSetId))

/**
 * Function which checks if the Budget configuration is different at the Ad Set level than at Campaign level, if configured
 *
 * @param {Object} campaign Campaign to which the Ad Set belongs to
 * @param {Object} adSet The Ad Set to check
 * @returns {Boolean}
 */
export const shouldOverrideCampaignBudget = (campaign, adSet) => {
  // Campaign budget type and amount
  const { budgetAmount, budgetFlag, budgetType } = campaign.data

  // Ad Set budget type and amount
  const {
    budget: { type, amount },
  } = adSet

  // If budget is set on the campaign, return true if type or amount are different at the Ad Set level
  if (budgetFlag) {
    return budgetType !== type || budgetAmount !== amount
  }
  return false
}

/**
 * Calculates what available options for Platforms and Placements there are based on the Campaign and AdSet
 * @param {Object} campaign
 * @param {Object} adSet
 * @returns {any[]} [platformsMap, placementsMap] - array of maps for platforms and placements respectively
 * @private
 */
export const computeAvailableOptions = (campaign, adSet) => {
  // Placement Map. Each placement contains basic info about it + disabled & locked flags
  // eg { FACEBOOK_NEWS_FEED: { label: 'Facebook News Feed', disabled: false, locked: false } }
  const placementsMap = Object.values(PLACEMENTS_MAP).reduce(
    (obj, placement) => {
      const [disabled, locked] = checkPlacementConditions({
        campaign,
        adSet,
        placement,
      })
      obj[placement.key] = {
        key: placement.key,
        label: placement.label,
        adSetGroup: placement.adSetGroup,
        apiUnavailable: placement.apiUnavailable,
        disabled,
        locked,
      }
      return obj
    },
    {}
  )

  // Platforms Map. Each Platform contains basic info
  const platformsMap = Object.values(PLACEMENT_PLATFORM_MAP).reduce(
    (obj, platform) => {
      // Platform is disabled only if all children are either disabled or locked
      const disabled = !Object.values(placementsMap).find(
        (el) => el.adSetGroup === platform.group && !el.disabled && !el.locked
      )
      const locked = platform?.conditions?.locked
        ? checkPlacementConditions({ campaign, adSet, placement: platform })[1]
        : false

      obj[platform.value] = { ...platform, disabled, locked }
      return obj
    },
    {}
  )

  return [platformsMap, placementsMap]
}

/**
 * Keeps only the object entries that have `true` as a value.
 * @param {Object.<String, Boolean>} placements Placements object
 * @returns {Object.<String, Boolean>} Selected placements
 */
export const extractChosenPlacements = (placements) =>
  Object.keys(placements).reduce((acc, key) => {
    if (!placements[key]) {
      return acc
    }
    return { ...acc, [key]: placements[key] }
  }, {})

/**
 * Extract the available placement options for an Ad Set and Campaign Objective.
 * It handles both automatic and manual placements.
 * Returns only the entries which have `true` as value.
 * @param {Object} adSet Ad Set object
 * @param {String} objective Campaign objective
 * @returns {Object.<String, Boolean>}
 */
export const getAdSetPlacements = (adSet, objective) => {
  const {
    placements: { automatic, manual },
  } = adSet
  if (automatic) {
    const [, computedAvailablePlacements] = computeAvailableOptions(
      { objective: objective },
      adSet
    )
    return Object.keys(computedAvailablePlacements).reduce((acc, key) => {
      const placement = computedAvailablePlacements[key]
      if (!placement.disabled) {
        return { ...acc, [key]: true }
      }
      return acc
    }, {})
  }
  return extractChosenPlacements(manual.list)
}

/**
 * Function which returns the campaign objective label based on campaign type and objective
 * @param {String} type campaign type
 * @param {String} objective campaign objective
 * @returns {String}
 */
export const getCampaignObjectiveLabel = (type, objective) => {
  if (!type || !objective) {
    return ''
  }

  switch (type) {
    case ODAX:
      return ODAX_OBJECTIVES_MAP_LABELS[objective]
    case FACEBOOK:
      return OBJECTIVES_MAP_LABELS[objective]
    case GOOGLE:
      return GOOGLE_CAMPAIGN_OBJECTIVE_LABELS[objective]?.label
    default:
      return ''
  }
}

/**
 * Create a copy of the flow, with modifications made to adsets, to be used for beforeConfirm() method
 * @param {Object} flow Current flow object from the store
 * @param {Array<Object>} adSetsToBeModified Array of adset objects to be updated
 * @param {String} adSetType Adset type from ACCOUNT_TYPES_MAP
 * @param {Function} changes Function that returns an object consisting of the changes to be made to the given adset
 * @returns {Object} The copy of the flow object, with the changes made to adsets
 */
export const createNewFlowWithModifiedAdSets = (
  flow,
  adSetsToBeModified,
  adSetType,
  applyChanges
) => {
  const newFlow = structuredClone(flow)
  newFlow.adSets = {
    ...newFlow.adSets,
    [adSetType]: [...(newFlow.adSets[adSetType] || [])].map((item) => {
      if (adSetsToBeModified.map(({ id }) => id).includes(item.id)) {
        return {
          ...item,
          ...applyChanges(item),
        }
      }
      return item
    }),
  }
  return newFlow
}

// #endregion
