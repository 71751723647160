import { utils } from '@decision-sciences/qontrol-common'
import { ReactComponent as ErasedCover } from 'assets/erased-cover.svg'
import { ReactComponent as NumberThree } from 'assets/unauthorized-page/3.svg'
import { ReactComponent as NumberFour } from 'assets/unauthorized-page/4.svg'
import { ReactComponent as Gandalf } from 'assets/unauthorized-page/gandalf.svg'
import { ReactComponent as MazeLock } from 'assets/unauthorized-page/maze_lock.svg'
import { ReactComponent as PandaImg } from 'assets/unauthorized-page/panda_unauthorized.svg'
import { ReactComponent as RobotKitty } from 'assets/unauthorized-page/robot_kitty.svg'
import { ReactComponent as Tetris } from 'assets/unauthorized-page/tetris.svg'
import { getPrevLocation, history } from 'components/history'
import { buildLoginRedirectUrl } from 'components/utils/url'
import useSession from 'modules/session'
import { useEffect, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import 'styles/unauthorized.scss'

const { getRandomNumber } = utils.number

const UnauthorizedPage = () => {
  const [isLoggedIn] = useSession()
  const navigate = useNavigate()

  const [pageVariant, setPageVariant] = useState(null)

  // Get a random number between 1 and 4
  useEffect(() => {
    setPageVariant(getRandomNumber(10, 20))
  }, [])

  const goBack = () => {
    const prevLocation = getPrevLocation()
    if (prevLocation) {
      navigate(prevLocation || -1)
    } else {
      // check location state for invalid id
      if (history.location.state && history.length > 2) {
        navigate(-2)
      } else {
        navigate('/')
      }
    }
  }

  if (!isLoggedIn) {
    return <Navigate to={buildLoginRedirectUrl()} />
  }

  const getDisplayVariant = () => {
    if (!pageVariant) {
      return null
    }
    switch (pageVariant % 5) {
      case 0:
        return <GandalfVariant />
      case 1:
        return <RobotKittyVariant />
      case 2:
        return <MazeVariant />
      case 3:
        return <PandaVariant />
      case 4:
        return <TetrisVariant />
    }
  }

  const goBackTemplate = () => {
    return (
      <div className="info-text">
        You can <a onClick={goBack}>Go back</a> or{' '}
        <Link to={'/'}>Go to the Homepage</Link>.
      </div>
    )
  }

  const supportTemplate = () => {
    return (
      <div className="align-center info-text font-size-21">
        If you think this is a mistake, contact{' '}
        <a
          href={'https://3qdigital.atlassian.net/servicedesk/customer/portal/7'}
          target={'_blank'}
          rel="noopener noreferrer"
        >
          Qontrol Support Portal
        </a>
      </div>
    )
  }

  return (
    <div className="unauthorized-page align-column align-center gap-16">
      {getDisplayVariant()}
      {goBackTemplate()}
      {supportTemplate()}
    </div>
  )
}

export default UnauthorizedPage

const GandalfVariant = () => {
  return (
    <>
      <div className="img-container align-row align-items-center">
        <NumberFour />
        <Gandalf />
        <NumberThree />
      </div>
      <div className="title title__font-100">You shall not pass</div>
      <div className="align-center info-text margin-top-10">
        Sorry, you do not have access to this page.
      </div>
    </>
  )
}

const RobotKittyVariant = () => {
  return (
    <>
      <div className="img-container align-row align-items-center">
        <NumberFour />
        <RobotKitty />
        <NumberThree />
      </div>
      <div className="title title__font-100">Access denied</div>
      <div className="align-center info-text margin-top-10">
        Sorry, the zen robot kitty cannot grant you permission to access to that
        page.
      </div>
    </>
  )
}

const MazeVariant = () => {
  return (
    <>
      <div className="img-container">
        <NumberFour />
        <MazeLock />
        <NumberThree />
      </div>
      <div className="title title__font-100">Access denied</div>
      <div className="align-center info-text margin-top-10">
        Sorry, you do not have access to this page.
      </div>
    </>
  )
}

const PandaVariant = () => {
  return (
    <>
      <div className="img-container">
        <ErasedCover />
        <PandaImg className="img-left-180" />
      </div>
      <div className="title title__font title__font-100">What happened?</div>
      <div className="align-center info-text margin-top-10">
        Looks like you are not authorized to view that page.
      </div>
    </>
  )
}

const TetrisVariant = () => {
  return (
    <>
      <div className="img-container align-row align-items-center">
        <ErasedCover />
        <Tetris
          className="img-left-180"
          style={{ left: '50%', transform: 'translate(-50%)' }}
        />
      </div>
      <div className="title title__font title__font-100">What? How!?</div>
      <div className="align-center info-text margin-top-10">
        Sorry, you do not have access to this page.
      </div>
    </>
  )
}
