import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useStore } from 'store'
import { verify } from 'modules/microsoft-config/actions'

const MicrosoftVerifyAccount = () => {
  const { dispatch } = useStore()
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const err = searchParams.get('err')
  useEffect(() => {
    if (code) {
      verify(code, dispatch)
        .then(() => {
          navigate('/microsoft')
        })
        .catch((error) => {
          console.error(error)
          navigate('/microsoft')
        })
    } else {
      if (err) {
        setError(err)
      } else {
        navigate('/microsoft')
      }
    }
  }, [code])
  return (
    <h4>
      {error ? (
        <>
          <div>{error}</div>
          <a
            onClick={() => {
              navigate('/microsoft')
            }}
          >
            Go back
          </a>
        </>
      ) : (
        'Verifying Data. Please Wait!'
      )}
    </h4>
  )
}

export default MicrosoftVerifyAccount
