import Api, { RESPONSE_TYPES } from 'easy-fetch-api'
import {
  showSuccessMessage,
  showErrorMessage,
} from 'modules/notifications/actions'
import { handleCsvResponse } from 'components/utils/csv-download'

export const ACTIONS = {
  GET_COMPANIES: 'companies.getCompanies',
  CHANGE_STATUS: 'companies.changeUserStatus',
  SET_CURRENT_COMPANY: 'companies.setCurrentCompany',
  SET_BULK_BUSINESS_UNITS: 'companies.bulkBusinessUnits',
  UNDO_COPY_SETTINGS: 'companies.undoCopySettings',
  UNDO_COPY_SETTINGS_SECTION: 'companies.undoCopySettingsSection',
  INIT_COPY_SETTINGS: 'companies.initCopySettings',
  RESET_COPY_SETTINGS: 'companies.resetCopySettings',
}

export const getCompanies = async (dispatch, query, setState = true) => {
  let result = {}
  if (query?.companyIds && !query.companyIds.length) {
    result = { success: true, companies: [] }
    setState &&
      dispatch({ type: ACTIONS.GET_COMPANIES, companies: result.companies })
  } else {
    result = await Api.post({
      url: '/api/companies/get-companies',
      data: query,
    })

    if (!result || !result.success) {
      return console.error(
        (result && result.error) || 'Error getting companies'
      )
    }
    setState &&
      dispatch({ type: ACTIONS.GET_COMPANIES, companies: result.companies })
  }

  return result
}

export const getCompaniesWithFilterOptions = async (query, field) => {
  const result = await Api.post({
    url: '/api/companies/companies-with-filter-options',
    data: { newFilters: query, field: field },
  })
  if (!result || !result.success) {
    return console.error(
      (result && result.error) || 'Error getting companies for filters'
    )
  }
  return result
}

export const getCompaniesWithUser = async (userId) => {
  const query = { userId }
  const result = await Api.get({ url: '/api/companies/user', query })

  if (!result || !result.success) {
    return console.error((result && result.error) || 'Error getting companies')
  }

  return result.companies
}

export const createUpdateCompany = (
  dispatch,
  company,
  currentCompanyId,
  otherChanges
) => {
  return new Promise(async (resolve, reject) => {
    const data = {
      ...company,
      ...otherChanges,
    }

    let promise
    const payload = {
      url: '/api/companies',
      data,
    }

    if (company.new) {
      promise = Api.post(payload)
    } else {
      promise = Api.put(payload)
    }

    const res = await promise.catch((err) => {
      console.error(err)
      return reject(err?.message || err.toString())
    })

    if (!res) {
      return
    }

    if (res.error) {
      showErrorMessage(res.error, dispatch)
      return reject(res.error.message || res.error)
    }

    const allCompanies = await getCompanies(dispatch, {
      deleted: false,
      populateReportings: true,
    })
    const currentCompany = allCompanies.companies.find(
      (el) => el._id.toString() === currentCompanyId
    )
    // Overwrite res.company only if the updated client was also the selected client in the app
    if (currentCompany && company._id === currentCompany._id) {
      res.company = currentCompany
    }
    if (res.company._id === currentCompanyId) {
      dispatch(setCurrentCompany(res.company))
    }

    showSuccessMessage(
      `Saved client ${res.company.name} successfully`,
      dispatch
    )

    resolve(res)
  })
}

/**
 * Makes a put call to update a company's budgetPacingInactiveCampaigns flag
 * @param {String} companyId Company's _id
 * @param {Boolean} budgetPacingInactiveCampaigns Flag value to set
 * @returns {Promise<Boolean>} Resolves with true value of inactiveCampaignsFlag
 */
export const changeInactiveCampaignsFlag = (
  companyId,
  budgetPacingInactiveCampaigns
) => {
  return Api.put({
    url: `/api/companies`,
    data: { _id: companyId, budgetPacingInactiveCampaigns },
  })
    .then((res) => {
      if (!res.success) {
        console.error(res.error || 'Error changing company status')
        return !budgetPacingInactiveCampaigns
      }
      return res.company.budgetPacingInactiveCampaigns
    })
    .catch((error) => {
      console.error(error)
      return !budgetPacingInactiveCampaigns
    })
}

/**
 * Makes a put call to update a company's campaignComparisonInactiveCampaigns flag
 * @param {String} companyId Company's _id
 * @param {Boolean} campaignComparisonInactiveCampaigns Flag value to set
 * @returns {Promise<Boolean>} Resolves with true value of inactiveCampaignsFlag
 */
export const changeInactiveCampaignsForComparisonFlag = (
  companyId,
  campaignComparisonInactiveCampaigns
) => {
  return Api.put({
    url: `/api/companies`,
    data: { _id: companyId, campaignComparisonInactiveCampaigns },
  })
    .then((res) => {
      if (!res.success) {
        console.error(res.error || 'Error changing company status')
        return !campaignComparisonInactiveCampaigns
      }
      return res.company.campaignComparisonInactiveCampaigns
    })
    .catch((error) => {
      console.error(error)
      return !campaignComparisonInactiveCampaigns
    })
}

export const setCurrentCompany = (company) => ({
  type: ACTIONS.SET_CURRENT_COMPANY,
  company,
})

export const deleteCurrentCompany = (dispatch, clientId) => {
  return new Promise((resolve, reject) => {
    Api.delete({ url: `/api/companies/delete/${clientId}` })
      .then((res) => {
        if (!res.success) {
          return console.error(res.error || 'Error deleting company')
        }

        getCompanies(dispatch, { deleted: false })
          .then(() => resolve(res))
          .catch(reject)
      })
      .catch(reject)
  })
}

export const checkExistingName = async ({ _id, name }) => {
  return await Api.get({
    url: '/api/companies/get-existing',
    query: {
      _id,
      name,
    },
  })
    .then((res) => {
      return res.exists
    })
    .catch(console.error)
}

export const checkExistingClientId = async ({ _id, clientId }) => {
  return await Api.get({
    url: '/api/companies/get-existing',
    query: {
      _id,
      clientId,
    },
  })
    .then((res) => {
      return res.exists
    })
    .catch(console.error)
}

/** Reset Cached Data for Publishers */
export const resetCachedData = (dispatch, company) => {
  const { _id: clientId, accounts } = company
  const promises = []

  promises.push(
    Api.post({
      url: `/api/accounts/reset-cached-data`,
      data: { clientId, accounts },
    })
  )

  // promises.push(
  //   Api.post({
  //     url: `/api/naming-conventions/dimension-values/reset-cache`,
  //     data: {
  //       clientId,
  //       accounts: company.accounts.filter(({ active }) => active),
  //     },
  //   })
  // )

  Promise.all(promises)
    .then(() =>
      showSuccessMessage(
        `Cached data was reset for the current company's accounts`,
        dispatch
      )
    )
    .catch(() =>
      showErrorMessage(
        `An error occurred while trying to reset cached data`,
        dispatch
      )
    )
}

export const setIsViewMode = (dispatch, value) =>
  dispatch({ type: ACTIONS.VIEW_MODE, value })

/**
 * Fetches the globally configured Tableau report options
 * @returns {Promise}
 */
export const getGlobalReportOptions = () => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/global-settings/global-reports',
    })
      .then((result) => {
        if (!result || result.error) {
          reject(
            (result && result.error) ||
              'Error fetching global reports. Please refresh the page and try again.'
          )
        } else {
          resolve(result)
        }
      })
      .catch(reject)
  })
}

export const getCampaignExclusionsByCompanyId = async (companyId) => {
  const result = await Api.get({ url: `/api/campaign-exclusions/${companyId}` })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error getting campaign exclusion for company id: ${companyId}`
    )
  }

  return result
}

export const saveCampaignExclusion = async (companyId, exclusion) => {
  const result = await Api.post({
    url: `/api/campaign-exclusions/${companyId}`,
    data: exclusion,
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error saving campaign exclusion for company id: ${companyId}`
    )
  }

  return result
}

export const editCampaignExclusion = async (companyId, exclusion) => {
  const result = await Api.put({
    url: `/api/campaign-exclusions/${companyId}/rule/${exclusion._id}`,
    data: exclusion,
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error updating campaign exclusion for company id: ${companyId}`
    )
  }

  return result
}

export const deleteCampaignExclusion = async (companyId, exclusionId) => {
  const result = await Api.delete({
    url: `/api/campaign-exclusions/${companyId}/rule/${exclusionId}`,
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error deleting campaign exclusion with id: ${exclusionId} for company id: ${companyId}`
    )
  }

  return result
}

export const getExcludedCampaigns = async (
  companyId,
  { limit, page, sorting, filters }
) => {
  const result = await Api.get({
    url: `/api/campaign-excluded/${companyId}`,
    query: { limit, page, sorting, filters },
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error getting excluded campaigns for company id: ${companyId}`
    )
  }

  return result
}

export const exportCampaigns = async (clientId, { sorting, filters }) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/campaign-excluded/export/${clientId}?${new URLSearchParams({
        sorting: JSON.stringify(sorting),
        filters: JSON.stringify(filters),
      })}`,
      responseType: RESPONSE_TYPES.raw,
    }).then((response) => {
      handleCsvResponse(
        response,
        resolve,
        reject,
        `excluded-campaigns-${clientId}.csv`
      )
    })
  })
}

export const getCampaignComparisonsByCompanyId = async (companyId) => {
  const result = await Api.get({
    url: `/api/campaign-grouping/client/${companyId}`,
    headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' },
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error getting campaign comparison groups for company id: ${companyId}`
    )
  }

  return result
}

export const saveCampaignComparison = async (companyId, comparisonGroup) => {
  const result = await Api.post({
    url: `/api/campaign-grouping/${companyId}`,
    data: comparisonGroup,
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error saving campaign comparison groups for company id: ${companyId}`
    )
  }

  return result
}

export const editCampaignComparison = async (companyId, comparisonGroup) => {
  const result = await Api.put({
    url: `/api/campaign-grouping/${companyId}/group/${comparisonGroup._id}`,
    data: comparisonGroup,
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error updating campaign comparison groups for company id: ${companyId}`
    )
  }

  return result
}

export const deleteCampaignComparison = async (
  companyId,
  comparisonGroupId
) => {
  const result = await Api.delete({
    url: `/api/campaign-grouping/${companyId}/rule/${comparisonGroupId}`,
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error deleting campaign comparison groups with id: ${comparisonGroupId} for company id: ${companyId}`
    )
  }

  return result
}

export const getBudgetSegmentsByCompanyId = async (companyId) => {
  const result = await Api.get({
    url: `/api/budget-pacing/${companyId}/dimensions`,
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error getting budget segments for company id: ${companyId}`
    )
  }

  return result
}

export const getBudgetPacingSettingsByCompany = async (companyId) => {
  const result = await Api.get({
    url: `/api/budget-pacing/${companyId}`,
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error getting budget pacing settings for company id: ${companyId}`
    )
  }

  return result
}

export const saveBudgetPacing = async (companyId, budgetPacing) => {
  const result = await Api.post({
    url: `/api/budget-pacing/${companyId}`,
    data: budgetPacing,
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error saving budget pacing for company id: ${companyId}`
    )
  }

  return result
}

export const editBudgetPacing = async (companyId, budget) => {
  const result = await Api.put({
    url: `/api/budget-pacing/${companyId}/setting/${budget._id}`,
    data: budget,
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error updating budget pacing for company id: ${companyId}`
    )
  }

  return result
}

export const deleteBudgetPacing = async (companyId, budget) => {
  const id = budget._id
  const result = await Api.put({
    url: `/api/budget-pacing/delete/${companyId}/setting/${id}`,
    data: { ...budget, isDeleted: true },
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error deleting budget pacing for company id: ${companyId}`
    )
  }

  return result
}
export const saveConversionGrouping = async (clientId, conversionGrouping) => {
  const result = await Api.post({
    url: `/api/conversion-grouping/save-grouping`,
    data: conversionGrouping,
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error saving conversion grouping for company id: ${clientId}`
    )
  }

  return result
}

export const saveClientReporting = async (clientId, tableauConfig) => {
  const result = await Api.post({
    url: `/api/global-reports/client-reporting`,
    data: { clientId, tableauConfig },
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error saving client reporting for company id: ${clientId}`
    )
  }

  return result
}

export const getClientConversionGroupings = async (clientId) => {
  const result = await Api.get({
    url: `/api/conversion-grouping/all-for-client/${clientId}`,
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error getting conversion groupings for company id: ${clientId}`
    )
  }
  return result.groupings
}

export const deleteConversionGrouping = (conversionGroupingId) =>
  Api.delete({
    url: `/api/conversion-grouping/${conversionGroupingId}`,
  })

export const updateConversionGrouping = async (conversionGrouping) => {
  // Convert KPIs to an array of IDs if necessary
  if (conversionGrouping.kpis && Array.isArray(conversionGrouping.kpis)) {
    conversionGrouping.kpis = conversionGrouping.kpis.map((kpi) => {
      return typeof kpi === 'object' && kpi._id ? kpi._id : kpi
    })
  }

  const result = await Api.post({
    url: `/api/conversion-grouping/update-grouping`,
    data: conversionGrouping,
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) || `Error updating conversion grouping.`
    )
  }

  return result
}

export const setBulkEditCompanies = (dispatch, companies) => {
  dispatch({
    type: ACTIONS.SET_BULK_BUSINESS_UNITS,
    bulkEditList: companies,
  })
}
export const bulkEditCompanies = async (companyIds, changes) => {
  const result = await Api.post({
    url: `/api/companies/bulk-edit-companies`,
    data: { companyIds, changes },
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) || `Error bulk editing clients.`
    )
  }

  return result
}

export const checkForRestore = async (clientId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/companies/check-for-restore',
      query: { clientId },
    }).then((result) => {
      if (result.error || !result.success) {
        const defaultMessage = `Unable to check clientId ${clientId} for restore`
        console.error(defaultMessage, result.error)
        return reject(result.error || defaultMessage)
      }
      resolve(result)
    })
  })
}

export const restoreClient = async (clientId) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: '/api/companies/restore',
      data: { clientId },
    }).then((result) => {
      if (result.error || !result.success) {
        const defaultMessage = `Unable to restore for clientId ${clientId}`
        console.error(defaultMessage, result.error)
        return reject(result.error || defaultMessage)
      }
      resolve(result)
    })
  })
}

export const getClientByClientId = (clientId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/companies/${clientId}`,
    }).then((result) => {
      if (result.error || !result.company) {
        const defaultMessage = `Unable to restore for id ${clientId}`
        console.error(defaultMessage, result.error)
        return reject(result.error || defaultMessage)
      }
      resolve(result.company)
    })
  })
}

/**
 * Exports all client alerts in a CSV format
 * @param {String} clientId Client ID for which data is exported
 * @returns {Promise}
 */
export const exportAlertsCsv = async (clientId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/companies/export/alerts',
      responseType: RESPONSE_TYPES.raw,
      query: { clientId },
    })
      .then((response) => {
        handleCsvResponse(response, resolve, reject, 'alerts-export.csv')
      })
      .catch((error) => {
        console.error('Error exporting alerts', error)
        reject(error)
      })
  })
}

/**
 * Exports all client alerts in a CSV format
 * @param {String} clientId Client ID for which data is exported
 * @returns {Promise}
 */
export const exportAlertThresholdsCsv = async (clientId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/companies/export/alert-thresholds',
      responseType: RESPONSE_TYPES.raw,
      query: { clientId },
    })
      .then((response) => {
        handleCsvResponse(
          response,
          resolve,
          reject,
          'alert-thresholds-export.csv'
        )
      })
      .catch((error) => {
        console.error('Error exporting alert thresholds', error)
        reject(error)
      })
  })
}

/**
 * Action to update the links for a specific client ID
 * @param {String} clientId Client ID (not Business Unit) to update links for
 * @param {Object} links Links object (links[section][business unit id])
 * @returns {Promise} Resolves with the updated client.
 */
export const updateLinkSections = async (clientId, links) =>
  new Promise((resolve, reject) => {
    Api.put({
      url: '/api/companies/linked-sections',
      data: {
        clientId,
        links,
      },
    })
      .then(({ success, company, error }) => {
        if (!success) {
          reject(
            error?.message || error || 'Something went wrong linking section'
          )
        }

        resolve(company)
      })
      .catch((error) => {
        reject(
          error?.message || error || 'Something went wrong linking section'
        )
      })
  })

/**
 * Action to update the alert value for a specific alert ID and client ID
 * @param {String} value New alert value to update
 * @param {String} alertId Alert ID to update
 * @param {String} clientId Client ID associated with the alert
 * @returns {Promise} Resolves with the updated alert.
 */
export const updateAlertValue = async (value, alertId, clientId) =>
  new Promise((resolve, reject) => {
    Api.put({
      url: '/api/alerts/update-value',
      data: {
        value,
        alertId,
        clientId,
      },
    })
      .then(({ success, result, error }) => {
        if (!success) {
          reject(
            error?.message ||
              error ||
              'Something went wrong updating alert value'
          )
        } else {
          resolve(result)
        }
      })
      .catch((error) => {
        reject(
          error?.message || error || 'Something went wrong updating alert value'
        )
      })
  })

/**
 * Action to update the alert value and status for multiple alerts.
 * @param {Object} changes - Contains the new values to update (value, active).
 * @param {Array} alertIds - Array of alert IDs to be updated.
 * @param {String} clientId - Client ID associated with the alerts.
 * @returns {Promise<Object>} - Resolves with the updated alerts or an error if the update fails.
 */
export const bulkEditAlertValueStatus = async (changes, alertIds, clientId) => {
  try {
    const result = await Api.put({
      url: '/api/alerts/bulk-update-value-status',
      data: {
        changes,
        alertIds,
        clientId,
      },
    })

    if (!result || !result.success) {
      throw new Error((result && result.error) || `Error bulk editing alerts.`)
    }

    return result
  } catch (error) {
    console.error('Error bulk editing alerts:', error)
    throw error
  }
}

/**
 * Gets the list of single client alerts belonging to one of the clients in the array
 * @param {Array} clientIds List of company ids to get data for
 * @returns {Promise}
 */
export const getSingleClientAlerts = async (clientIds) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: `/api/companies/single-client-alerts`,
      data: { clientIds },
    })
      .then(({ success, alerts }) => {
        if (!success) {
          reject(
            `Error getting single client alerts for ${clientIds.join(', ')}`
          )
          return
        }
        resolve(alerts)
      })
      .catch((error) => {
        reject(
          error?.message ||
            error ||
            `Error getting single client alerts for ${clientIds.join(', ')}`
        )
      })
  })
}

export const getLatestSimulationResults = async (clientId) => {
  return await Api.get({
    url: `/api/companies/latest-simulation-results/${clientId}`,
  })
    .then(({ success, data }) => {
      if (success) {
        return data
      } else {
        console.error('Could not retrieve previous results')
      }
    })
    .catch((error) => {
      console.error(error)
      return {}
    })
}

export const initialiseBackFillClientdata = async (clientId) => {
  return await Api.get({
    url: '/api/companies/backfill/init-backfill-data',
    query: { clientId },
  })
    .then((result) => {
      if (!result) {
        return console.error('Error backfilling client data')
      }
      return {
        ...result,
      }
    })
    .catch((err) => console.error(err))
}

export const checkForActiveBackfillJobs = async (clientId) => {
  return await Api.get({
    url: '/api/companies/backfill/check-for-existing-backfill-jobs',
    query: { clientId },
  })
    .then((result) => {
      if (!result) {
        return console.error('Error checking for existing jobs.')
      }
      return {
        ...result,
      }
    })
    .catch((err) => console.error(err))
}

export const undoCopySettings = (dispatch) => {
  dispatch({
    type: ACTIONS.UNDO_COPY_SETTINGS,
  })
}

export const undoCopySettingsSection = (dispatch, section) => {
  dispatch({
    type: ACTIONS.UNDO_COPY_SETTINGS_SECTION,
    section,
  })
}

export const initCopySettingsSection = (dispatch, section, data) => {
  dispatch({
    type: ACTIONS.INIT_COPY_SETTINGS,
    section,
    data: data,
  })
}

export const resetCopySettings = (dispatch) => {
  dispatch({
    type: ACTIONS.RESET_COPY_SETTINGS,
  })
}
