import { utils } from '@decision-sciences/qontrol-common'

const { downloadFile, extractAttachmentFilename } = utils.download

export const handleCsvResponse = async (
  response,
  resolve,
  reject,
  fallbackFileName = 'export.csv'
) => {
  const { headers } = response
  const contentType = headers.get('content-type')
  if (contentType.includes('text/csv')) {
    const filename = extractAttachmentFilename(
      response.headers.get('content-disposition'),
      fallbackFileName
    )
    return response
      .clone()
      .blob()
      .then((blob) => {
        downloadFile(blob, filename)
        resolve()
      })
      .catch(reject)
  } else {
    return response
      .clone()
      .json()
      .then((data) => reject(data?.error || data))
      .catch(reject)
  }
}
