import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import { teamPropType } from 'components/utils/prop-types'

// Components
import ApplyToSection from 'modules/alerts/apply-to'
import AlertStartTime from 'modules/alerts/alert-start-time'
import BulkEdit from 'components/bulk-edit'
import { CheckboxNoHooks } from 'components/checkbox'
import { Dropdown } from 'components/dropdown'
import DropdownWithSubsections from 'components/dropdown-with-subsections'
import Recurrence from 'components/recurrence'
import SendNotificationOptions from 'components/send-notification-options'

import {
  bulkAddClients,
  bulkAddGranularity,
  bulkAddNotificationChannels,
  bulkDeleteAlerts,
  bulkDisableClients,
  bulkEditAlerts,
  bulkRemoveGranularity,
  bulkUpdateActive,
  bulkUpdateCategory,
  setBulkAlerts,
} from 'modules/alerts/actions'

// Constants
import { FEATURE_NOT_IMPLEMENTED_TOOLTIP } from 'components/utils/tooltip'
import {
  ACCOUNTS_TO_SELECT_ELEMENTS,
  AVAILABLE_PUBLISHERS_FOR_ALERT,
  NOTIFICATION_TYPES,
} from 'modules/alerts/constants'

/* Utils */
import { showErrorMessage } from 'modules/notifications/actions'

/* Actions */
import { getSuperAdmins } from 'modules/users/actions'
import {
  notifications,
  entityStatus,
  accounts,
  alert,
} from '@decision-sciences/qontrol-common'

const { DELIVERY_OPTIONS, DELIVERY_OPTIONS_MAP, FREQUENCY_OPTIONS } =
  notifications
const { ENTITY_STATUS_OPTIONS } = entityStatus
const { ACCOUNT_TYPES_MAP } = accounts
const { ALERT_TYPES_MAP } = alert

const AlertsBulkEdit = ({
  alertDeletionModal,
  alertCategories,
  allCompanies,
  columns,
  company,
  dispatch,
  isAdmin,
  loading,
  user,
  selectedAlerts,
  setBulkEdit,
  setSelectedAlerts,
  teams,
  navigateOnAction,
}) => {
  const {
    state: {
      companies,
      users: { list: users, superAdmins },
    },
  } = useStore()
  const navigate = useNavigate()
  const [selectedGranularity, setSelectedGranularity] = useState()
  const [selectedOption, setSelectedOption] = useState()
  const [errors, setErrors] = useState({})

  const [state, setState] = useState({
    companies: [companies.currentCompany._id],
    alertType: 'Performance',
    allCompaniesSelected: true,
    selectedAccounts: [],
    selectedElements: {},
  })

  const granularityOptions = [
    {
      disabled: true,
      label: 'Client',
      value: 'client',
      tooltip: FEATURE_NOT_IMPLEMENTED_TOOLTIP,
    },
    {
      disabled: true,
      label: 'Channel',
      value: 'channel',
      tooltip: FEATURE_NOT_IMPLEMENTED_TOOLTIP,
    },
    {
      disabled: selectedOption === 'Remove Granularity',
      label: 'Publisher',
      value: 'publisher',
    },
    { label: 'Other', value: 'other' },
  ]

  // Get clients names
  const allOptions = useMemo(() => {
    const allOptions = []
    allCompanies.forEach((option) => {
      allOptions.push(option.value)
      if (option?.subsections.length) {
        option.subsections.forEach((subsection) =>
          allOptions.push(subsection.value)
        )
      }
    })
    return allOptions
  }, [JSON.stringify(allCompanies)])

  /**
   * Get all companies for activeAccountOptions
   * @returns {Array<Object>}
   */

  const applyToCompanies = useMemo(() => {
    return companies?.list?.reduce((list, company) => {
      const found = []

      for (const c of allOptions) {
        if (c === company._id) {
          found.push(company)
          continue
        }
        const businessUnit = company.businessUnits.find((bu) => bu._id === c)
        if (businessUnit) {
          found.push(businessUnit)
        }
      }

      return [...list, ...found]
    }, [])
  }, [
    JSON.stringify(companies),
    JSON.stringify(allOptions),
    state.allCompaniesSelected,
  ])

  /**
   * Get active Accounts based on the AlertType selected and the active accounts from the Client
   * @returns {Array<String>}
   */
  const activeAccountOptions = useMemo(() => {
    return Object.keys(ACCOUNT_TYPES_MAP)
      .filter((accountType) =>
        applyToCompanies.some((company) => {
          const accountTypeValue = ACCOUNT_TYPES_MAP[accountType]
          if (
            !AVAILABLE_PUBLISHERS_FOR_ALERT[state.alertType].includes(
              accountTypeValue
            )
          ) {
            return false
          }
          return company.accounts.some((acc) => {
            return acc.type === accountTypeValue
          })
        })
      )
      .map((accType) => ACCOUNT_TYPES_MAP[accType])
  }, [JSON.stringify(applyToCompanies), state.alertType])

  /**
   * Check if all the selectedAlerts has alertType "Performance", and allCompaniesSelected
   */
  const canChangeGranularity = selectedAlerts.every((alert) => {
    return (
      alert.alertType === ALERT_TYPES_MAP.PERFORMANCE &&
      (alert.allCompaniesSelected || alert.companies.length > 1)
    )
  })

  /**
   * Fetch super admins on component mount
   */
  useEffect(() => {
    if (!superAdmins.length) {
      getSuperAdmins(dispatch)
    }
  }, [])

  /**
   * Reset selected Granularity on Option change
   */
  useEffect(() => {
    if (selectedOption) {
      setSelectedGranularity('')
      setState({ ...state, selectedAccounts: [], selectedElements: {} })
    }
  }, [selectedOption])

  /**
   * Reset selected SelectedAccounts and selectedElements on Granularity change
   */
  useEffect(() => {
    if (selectedGranularity) {
      setState({ ...state, selectedAccounts: [], selectedElements: {} })
    }
  }, [selectedGranularity])

  // Filter Teams - Super Admin can only set Global Teams
  //              - Limited Admin can only set Company Teams
  const filteredTeams = teams?.filter((t) =>
    isAdmin ? t.isGlobal : !t.isGlobal
  )

  /**
   * Render Add/Remove Granularity section
   */
  const renderGranularitySection = (value, onChange, isRemove) => {
    let accountOptions = activeAccountOptions

    /**
     *Merge all 'selectedAccounts' arrays into a single array for Remove Granularity
     */
    if (isRemove) {
      accountOptions = selectedAlerts.reduce((acc, obj) => {
        return acc.concat(obj.selectedAccounts)
      }, [])
    }

    return (
      <div>
        <div className="form__section select-granularity">
          <div
            className="form__section__body"
            style={{ paddingBottom: '20px' }}
          >
            <div className="display-flex space-between">
              <div style={{ fontSize: '1.31em' }}>Select Granularity</div>
              <div className="display-flex align-right">
                {granularityOptions.map((granularity) => (
                  <div style={{ marginLeft: '40px' }} key={granularity.value}>
                    <CheckboxNoHooks
                      label={granularity?.label}
                      isChecked={selectedGranularity === granularity.value}
                      disabled={granularity.disabled}
                      tooltip={granularity.tooltip ? granularity.tooltip : ''}
                      onChange={() =>
                        selectedGranularity === granularity.value
                          ? setSelectedGranularity('')
                          : setSelectedGranularity(granularity.value)
                      }
                      className="checkbox-first"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {(selectedGranularity === granularityOptions[2].value ||
          selectedGranularity === granularityOptions[3].value) && (
          <ApplyToSection
            errors={errors}
            setErrors={setErrors}
            editField={(fieldName, fieldValue) => {
              onChange({ ...value, [fieldName]: fieldValue })
              setState({ ...state, [fieldName]: fieldValue })
            }}
            key={selectedGranularity}
            state={state}
            setState={(newState) => {
              newState.selectedAccounts?.length
                ? onChange({ ...value, ...newState })
                : onChange(null)
              setState({ ...state, ...newState })
            }}
            accountsElements={ACCOUNTS_TO_SELECT_ELEMENTS}
            activeAccountOptions={accountOptions}
            isPerformanceAlert={true}
            publisherOnly={selectedGranularity === granularityOptions[2].value}
          />
        )}
      </div>
    )
  }

  const bulkEditOptions = [
    {
      label: 'Update Status',
      value: 'Update Status',
      action: (data, active) => {
        return bulkUpdateActive(
          dispatch,
          data.map((a) => a._id),
          active
        )
      },
      renderInput: (value, onChange) => {
        return (
          <Dropdown
            label="Set Status"
            labelTop
            defaultOptionText="Status"
            defaultState={value}
            options={ENTITY_STATUS_OPTIONS}
            onChange={onChange}
          />
        )
      },
    },
    {
      label: 'Update Category',
      value: 'Update Category',
      action: (data, change) => {
        return bulkUpdateCategory(
          dispatch,
          data.map((a) => a._id),
          change,
          company._id,
          user.isSuperAdmin
        )
      },
      renderInput: (value, onChange) => {
        return (
          <Dropdown
            label="Set Alert Category"
            labelTop
            defaultOptionText="Alert Category"
            options={alertCategories.map((c) => ({
              label: c.name,
              value: c._id,
            }))}
            defaultState={value}
            onChange={onChange}
          />
        )
      },
    },
    {
      label: 'Change Owner',
      value: 'Change Owner',
      action: (data, change) => {
        return bulkEditAlerts(
          dispatch,
          data.map((a) => a._id),
          {
            owner: change,
          },
          company._id
        )
      },
      renderInput: (value, onChange) => {
        return (
          <Dropdown
            label="Select Owner"
            labelTop
            defaultOptionText="Select Owner"
            options={superAdmins.map((c) => ({
              label: c.name,
              value: c._id,
            }))}
            defaultState={value}
            onChange={onChange}
          />
        )
      },
    },
    {
      label: 'Add Granularity',
      action: (data, change) => {
        return bulkAddGranularity(dispatch, data, change)
      },
      renderInputBelow: renderGranularitySection,
      value: 'Add Granularity',
      tooltip: !canChangeGranularity
        ? 'This option is not available due to one of the selected alerts being a single-client or settings alert.'
        : '',
      unselectable: !canChangeGranularity,
    },

    {
      label: 'Add Notification Channels',
      value: 'Add Notification Channels',
      action: (data, change) => {
        return bulkAddNotificationChannels(
          dispatch,
          data.map((a) => a._id),
          change,
          company._id
        )
      },
      inputLabel: 'Set Notification Channels',
      inputBelowForm: true,
      renderInputBelow: (value, onChange) => {
        const alertsCompanies = companies.list
          .filter((c) =>
            selectedAlerts.find((a) => a.companies.find((ac) => ac === c._id))
          )
          .map((c) => c._id)
        return (
          <SendNotificationOptions
            types={NOTIFICATION_TYPES}
            channels={DELIVERY_OPTIONS}
            onChange={(notificationOptions) => {
              const invalid = notificationOptions.some((value, index) => {
                /** If only slack is selected, validate it instantly */
                if (
                  value?.channels?.length === 1 &&
                  value?.channels?.[0] === DELIVERY_OPTIONS_MAP.SLACK
                ) {
                  return false
                }

                return (
                  !(
                    value.channels?.length &&
                    value?.entities.length &&
                    value?.type
                  ) ||
                  notificationOptions.some(
                    (option, idx) =>
                      JSON.stringify(option) === JSON.stringify(value) &&
                      idx !== index
                  )
                )
              })

              onChange(invalid ? null : notificationOptions)
            }}
            teams={filteredTeams}
            users={users}
            defaultState={state.notificationOptions}
            defaultTextType={'Select User / Team'}
            companies={alertsCompanies}
            allCompaniesSelected={state.allCompaniesSelected}
            showInGroup
          />
        )
      },
    },
    {
      label: 'Remove Granularity',
      action: (data, change) => {
        return bulkRemoveGranularity(dispatch, data, change)
      },
      renderInputBelow: (value, onChange) =>
        renderGranularitySection(value, onChange, true),
      value: 'Remove Granularity',
      tooltip: !canChangeGranularity
        ? 'This option is not available due to one of the selected alerts being a single-client or settings alert.'
        : '',
      unselectable: !canChangeGranularity,
    },
    {
      label: 'Alert Start Time',
      value: 'Alert Start Time',
      action: (data, startTime) => {
        return bulkEditAlerts(
          dispatch,
          data.map((a) => a._id),
          {
            startTime,
            changed: true,
          },
          company._id
        )
      },
      renderInputBelow: (value, onChange) => {
        return (
          <div className="form__section__body">
            <div className="general-label">Alert Start Date & Time</div>
            <AlertStartTime
              defaultValue={value}
              onChange={onChange}
              showHourlyInfo={true}
            />
          </div>
        )
      },
    },
    {
      label: 'Add Clients',
      value: 'Add Clients',
      action: (data, change) => {
        return bulkAddClients(
          dispatch,
          data.map((a) => a._id),
          change,
          company._id
        )
      },
      inputLabel: 'Select Clients to Apply Alerts to',
      renderInput: (value, onChange) => {
        if (!value) {
          value = {
            companies: [],
            allCompaniesSelected: false,
          }
        }
        return (
          <DropdownWithSubsections
            selectedItems={
              value.allCompaniesSelected ? allOptions : value.companies
            }
            options={allCompanies.reduce((companies, c) => {
              const subsections = c.subsections.filter(
                (subsection) =>
                  !selectedAlerts.every((alert) => {
                    return alert.companies.find(
                      (ac) => ac === subsection.value.toString()
                    )
                  })
              )
              const foundCompany = selectedAlerts.every((sa) => {
                return sa.companies.find((sc) => {
                  return sc === c.value.toString()
                })
              })
              return [
                ...companies,
                { ...c, disabled: !!foundCompany, subsections },
              ]
            }, [])}
            defaultOptionText="Apply To"
            onChange={(companies) =>
              onChange({
                companies,
                allCompaniesSelected: false,
              })
            }
            selectAllOptions={{
              label: 'All Clients',
              allSelected: value.allCompaniesSelected,
              onCheck: (allCompaniesSelected) => {
                onChange({
                  companies: [],
                  allCompaniesSelected,
                })
              },
            }}
          />
        )
      },
    },
    {
      label: 'Deactivate Clients',
      value: 'Deactivate Clients',
      action: (data, change) => {
        return bulkDisableClients(
          dispatch,
          data.map((a) => a._id),
          change
        )
      },
      inputLabel: 'Select Clients not to run on anymore',
      renderInput: (value, onChange) => {
        if (!value) {
          value = {
            companies: [],
            allCompaniesSelected: false,
          }
        }
        const companiesAlertsAreRunningOn = selectedAlerts.reduce(
          (set, alert) => {
            alert.alertRunning.forEach((item) => {
              if (item.running) {
                set.add(item.company)
              }
            })
            return set
          },
          new Set()
        )

        return (
          <DropdownWithSubsections
            selectedItems={
              value.allCompaniesSelected ? allOptions : value.companies
            }
            disabled={companiesAlertsAreRunningOn.size === 0}
            options={allCompanies.reduce((companies, company) => {
              const subsections = company.subsections.filter((sub) =>
                companiesAlertsAreRunningOn.has(sub.value.toString())
              )

              const foundCompany = companiesAlertsAreRunningOn.has(
                company.value.toString()
              )

              return [
                ...companies,
                { ...company, disabled: !foundCompany, subsections },
              ]
            }, [])}
            defaultOptionText="Apply To"
            onChange={(companies) =>
              onChange({
                companies,
                allCompaniesSelected: false,
              })
            }
            selectAllOptions={{
              label: 'Deactivate All Clients',
              allSelected: value.allCompaniesSelected,
              onCheck: (allCompaniesSelected) => {
                onChange({
                  companies: [],
                  allCompaniesSelected,
                })
              },
            }}
          />
        )
      },
    },
  ]

  /** If there aren't any alerts that are part of notificationGroups, we can change frequency as well */
  if (selectedAlerts.every((alert) => !alert.notificationGroup)) {
    bulkEditOptions.push({
      label: 'Update Frequency',
      value: 'Update Frequency',
      action: (data, recurrence) => {
        return bulkEditAlerts(
          dispatch,
          data.map((a) => a._id),
          {
            recurrence,
          },
          company._id
        )
      },
      inputLabel: 'Set Notification Frequency',
      renderInput: (value, onChange) => {
        return (
          <Recurrence
            defaultState={value}
            onChange={(value) => value && onChange(value.amount ? value : null)}
            frequencyOptions={FREQUENCY_OPTIONS.slice(0, 3)}
            showDateHoursFields={false}
            showInGroup
          />
        )
      },
    })
  }

  const onDelete = selectedAlerts.find((alert) => alert.active)
    ? null
    : (data) => {
        return bulkDeleteAlerts(
          dispatch,
          data,
          {
            deleted: true,
          },
          company._id
        )
      }
  return (
    <BulkEdit
      columns={columns}
      data={selectedAlerts}
      labels={{ delete: 'Delete Alerts', heading: 'Edit Alerts' }}
      onCancel={() => {
        if (navigateOnAction) {
          setBulkAlerts(dispatch, [], null)
          setTimeout(
            () => navigate(navigateOnAction === '-1' ? -1 : navigateOnAction),
            50
          )
        } else {
          setBulkEdit(false)
          setSelectedAlerts([])
        }
      }}
      selectedOption={(option) => setSelectedOption(option)}
      onDelete={onDelete}
      onError={(err) => showErrorMessage(err, dispatch)}
      options={bulkEditOptions}
      loading={loading}
    >
      {alertDeletionModal()}
    </BulkEdit>
  )
}

AlertsBulkEdit.propTypes = {
  alertDeletionModal: PropTypes.func.isRequired,
  alertCategories: PropTypes.array.isRequired,
  allCompanies: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  company: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  user: PropTypes.object.isRequired,
  selectedAlerts: PropTypes.array.isRequired,
  setBulkEdit: PropTypes.func.isRequired,
  setSelectedAlerts: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(teamPropType).isRequired,
  navigateOnAction: PropTypes.string,
}
export default AlertsBulkEdit
