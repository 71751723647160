/**
 * Removes an element from the DOM (if exists) based on given id
 * @param {string} id - The id of the element we want to remove from DOM
 */
export const removeElementFromDOMById = (id) => {
  const element = document.getElementById(id)
  element && element.parentNode.removeChild(element)
}

export const getMainElement = () => {
  return document.getElementsByTagName('main')[0]
}

/**
 * Prevent scrolling body
 * @param {Boolean} isOpened - flag for list/dropdown opened
 */
export const preventScrollingBody = (isOpened) => {
  const main = getMainElement()
  if (main) {
    if (isOpened) {
      main.style.overflow = 'hidden'
    } else {
      main.style.overflow = 'auto'
    }
  }
}

/**
 * Utility function to prevent default and stop propagating a DOM event.
 * @param {Event} event
 */
export const preventAndStopPropagation = (event) => {
  event.preventDefault()
  event.stopPropagation()
}

/**
 * Function which checks if a specific element is in the browsers viewport
 * @param {String} elementId ID of the DOM element
 * @returns {Boolean}
 */
export const isInViewport = (elementId) => {
  const element = document.getElementById(elementId)

  if (!element) {
    return false
  }

  const bounding = element.getBoundingClientRect()
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  )
}
