import { namingConventions, utils } from '@decision-sciences/qontrol-common'

const { DEFAULT_STATES } = namingConventions
const { keyBy } = utils.array

export const compareDimensionsOrder = (d1, d2) =>
  d1.dimensionOrder > d2.dimensionOrder ? 1 : -1

export const fillWithDefaultConventions = (conventions, defaultStates) => {
  if (!conventions) {
    return defaultStates || DEFAULT_STATES
  }

  const completeConventions = structuredClone(conventions)
  Object.entries(structuredClone(defaultStates || DEFAULT_STATES)).forEach(
    ([granularity, defaultState]) => {
      if (!completeConventions?.[granularity]) {
        completeConventions[granularity] = structuredClone(
          defaultState || DEFAULT_STATES[granularity]
        )
      }
    }
  )
  return completeConventions
}

export const checkForChanges = (
  conventions,
  oldConventions = DEFAULT_STATES
) => {
  if (!conventions || Array.isArray(conventions)) {
    return false
  }

  return Object.keys(conventions || DEFAULT_STATES).some((granularity) => {
    const oldConvention = (oldConventions || DEFAULT_STATES)[granularity]
    const convention = conventions[granularity]
    if (!convention && oldConvention !== convention) {
      return true
    }
    if (convention?.dimensions?.length !== oldConvention?.dimensions.length) {
      return true
    }
    if (convention.delimiter !== oldConvention.delimiter) {
      return true
    }
    return convention.dimensions.some((dimension, index) => {
      // Check for differences
      const isDifferent =
        dimension.dimensionName !==
          oldConvention.dimensions[index].dimensionName ||
        dimension.required !== oldConvention.dimensions[index].required ||
        dimension.freeForm !== oldConvention.dimensions[index].freeForm ||
        dimension.dimensionOrder !==
          oldConvention.dimensions[index].dimensionOrder

      return isDifferent
    })
  })
}

export const virtualToNamingConvention = (virtual) => {
  return Array.isArray(virtual) ? keyBy(virtual || [], 'granularity') : virtual
}

