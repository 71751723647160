import { utils, googleEnums } from '@decision-sciences/qontrol-common'

const { convertArrayToBreadcrumbs } = utils.array
const { capitalize } = utils.string

/**
 * Utility function to translate the audience segments data in a format used by the generic search with tree component
 * @param {Array} elements - The array of elements that need to be converted
 * @param {Array} path - The path from the first parent to the current leaf
 * @returns {Object} - An object tree in a format used by the generic search with tree component
 */
const convertAudienceSegmentsElements = (elements, path = []) => {
  const collector = {}

  if (elements?.length) {
    elements.forEach((element) => {
      if (element.children?.length) {
        // If there are children, it means this is a parent, so we go deeper in the tree re-calling the convert method
        // on the children elements
        collector[element.name] = convertAudienceSegmentsElements(
          element.children,
          [...path, element.name]
        )
      } else {
        // If there are no children, it means this is a leaf, so we set the id as it's name
        collector[element.name] = {
          id: generateElementId(element.name, path),
          name: element.name,
          resourceName: element.resourceName,
          type: element.type,
          typeLabel: element.typeLabel,
          item: true,
          path: [...path, element.name],
        }
      }
    })
  }

  return collector
}

/**
 * Builds the audience segment dropdown data from the data retrieved from the APIs
 * @param {Object} audienceDemographic - Audience Segments data for demographic option
 * @param {Object}  audienceInterests - Audience Segments data for interests option (affinity and in_market)
 * @param {Object} audienceDataSegments - Audience Segments data for segments option (combined_audiences and user_lists)
 * @returns {Object} - Represents an object in a format used by the Search component
 * Every key represents a parent on all the layers until the last one which is a leaf and can be selected
 * {
 *   Demographics: {
 *     Education: {
 *       ...
 *       {
 *         id: 'Something'
 *       }
 *     }
 *   }
 * }
 */
export const buildAudienceSegmentsDropdownData = (
  audienceDemographic,
  audienceInterests,
  audienceDataSegments
) => {
  const audienceSegments = {
    'Who They are': {
      // TODO uncomment this once we have a way to sync detailed demographics - https://3qdigital.atlassian.net/browse/AP-5307
      // Also delete the on roadmap text from optionDescription
      // ...convertAudienceSegmentsElements(
      //   audienceDemographic.detailed_demographic.tree
      // ),
      optionDescription: 'Detailed Demographics (On Roadmap)',
    },
    'What their interests and habits are': {
      ...convertAudienceSegmentsElements(audienceInterests.affinity.tree),
      optionDescription: 'Affinity',
    },
    'What they are actively researching or planning': {
      ...convertAudienceSegmentsElements(audienceInterests.in_market.tree),
      optionDescription: 'In-Market',
    },
    'How they have interacted with your business': {
      ...convertAudienceSegmentsElements(
        audienceDataSegments.combined_audiences.tree
      ),
      optionDescription: 'Remarketing and similar audiences',
    },
  }

  if (audienceDataSegments.user_lists.tree.rule_based?.length) {
    audienceSegments['Your combined audience segments'] = {
      ...audienceSegments['Your combined audience segments'],
      'Website visitors': convertAudienceSegmentsElements(
        audienceDataSegments.user_lists.tree.rule_based,
        ['Website visitors']
      ),
    }
  }

  if (audienceDataSegments.user_lists.tree.logical?.length) {
    audienceSegments['Your combined audience segments'] = {
      ...audienceSegments['Your combined audience segments'],
      'Custom combination segments': convertAudienceSegmentsElements(
        audienceDataSegments.user_lists.tree.logical,
        ['Custom combination segments']
      ),
    }
  }

  if (audienceDataSegments.user_lists.tree.crm_based?.length) {
    audienceSegments['Your combined audience segments'] = {
      ...audienceSegments['Your combined audience segments'],
      'Customer List': convertAudienceSegmentsElements(
        audienceDataSegments.user_lists.tree.crm_based,
        ['Customer List']
      ),
    }
  }

  if (audienceDataSegments.user_lists.tree.remarketing?.length) {
    audienceSegments['Your combined audience segments'] = {
      ...audienceSegments['Your combined audience segments'],
      'App Users': convertAudienceSegmentsElements(
        audienceDataSegments.user_lists.tree.remarketing,
        ['App Users']
      ),
    }
  }

  if (audienceDataSegments.user_lists.tree.similar?.length) {
    audienceSegments['Your combined audience segments'] = {
      ...audienceSegments['Your combined audience segments'],
      'Youtube Users': convertAudienceSegmentsElements(
        audienceDataSegments.user_lists.tree.similar,
        ['Youtube Users']
      ),
    }
  }

  // In case we have at least one combine audience segments, add its description property as well
  if (audienceSegments['Your combined audience segments']) {
    audienceSegments['Your combined audience segments'] = {
      ...audienceSegments['Your combined audience segments'],
      optionDescription: 'Combined segments',
    }
  }

  return audienceSegments
}

/**
 * Generates an unique id for a given element in the tree
 * @param {String} name - The element name for which we want to generate the id
 * @param {Array<string>} path - The list with all the parent nodes names in the tree
 * @returns {String} id - An unique id for the given element
 *
 * Example:
 * Given the tree => { 'A' : { 'B' : { name: 'C' } } }
 * For the element with the name = 'C'
 * Having its path as ['A', 'B']
 * Will return the id = 'A_B_C'
 */
const generateElementId = (name, path = []) => {
  return `${path.join('_')}_${name}`
}

/**
 * Returns the audience segment path as breadcrumbs
 * @param {Array<string>} path - An array with the path from the first parent node in the tree
 */
export const getAudiencePathAsBreadcrumbs = (path) => {
  if (!path) {
    return ''
  }

  return convertArrayToBreadcrumbs(path.slice(0, path.length - 1))
}

/** Convenient way to convert an audienceSegment to an item row format */
export const getAudienceSegmentAsItem = (audienceSegment) => {
  return {
    value: audienceSegment.name,
    description: getAudiencePathAsBreadcrumbs(audienceSegment.path),
    id: audienceSegment.id,
  }
}

export const getStatusLabel = (status) => {
  const statusLabel = Object.entries(googleEnums.AdGroupCriterionStatus)
    .find(([, el]) => el === status)?.[0]
    ?.toLowerCase()
  return statusLabel ? capitalize(statusLabel) : ''
}
