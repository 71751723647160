import Api from 'easy-fetch-api'

/**
 * Get Config data for a company by clientId
 * @param clientId - the company id
 * @return {*}
 */
export const getConfigByClient = (clientId) =>
  Api.get({ url: `/api/config/${clientId}` })
    .then((res) => res.config || {})
    .catch(console.error)
