import React, { useEffect } from 'react'
import { useParams, redirect } from 'react-router-dom'
import { useStore } from 'store'
import AlertTemplatesModule from 'modules/alert-templates'
import AlertTemplatesForm from 'modules/alert-templates/create-edit'
import AlertTemplatesView from 'modules/alert-templates/view'
import {
  getVariableMetrics,
  getCalculatedMetrics,
} from 'modules/calculated-metrics/actions'
import { deleteAlertTemplate } from 'modules/alert-templates/actions'
import { getAlertThresholds } from 'modules/alert-thresholds/actions'
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'

const AlertTemplatesPage = () => {
  const { alertTemplateKey } = useParams()
  const { dispatch, state } = useStore()
  const {
    calculatedMetrics: { variables, list: calculatedMetrics },
    companies: { currentCompany },
    alertThresholds: { list: alertThresholds },
  } = state
  const hasAccess = useAccess({
    feature: PERMISSIONS.ALERT_TEMPLATE_DATA_FORM,
    type: PERMISSION_TYPES.READ,
  })
  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.ALERT_TEMPLATE_DATA_FORM,
    type: PERMISSION_TYPES.DELETE,
  })
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.ALERT_TEMPLATE_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.ALERT_TEMPLATE_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })

  useEffect(() => {
    if (!currentCompany) {
      return
    }
    if (!hasAccess) {
      return redirect('/unauthorized')
    }
    if (!variables) {
      getVariableMetrics(dispatch)
    }
    if (!calculatedMetrics) {
      getCalculatedMetrics(dispatch)
    }
    if (!alertThresholds) {
      getAlertThresholds(dispatch)
    }
  }, [JSON.stringify(currentCompany)])

  // List mode
  if (!alertTemplateKey) {
    return (
      <AlertTemplatesModule
        history={history}
        hasViewAccess={hasAccess}
        hasEditAccess={hasEditAccess}
        hasCreateAccess={hasCreateAccess}
        onDelete={
          hasDeleteAccess ? (_id) => deleteAlertTemplate(dispatch, _id) : null
        }
      />
    )
  }

  if (hasEditAccess) {
    if (!variables || !calculatedMetrics) {
      return null
    }

    // Create/Edit mode
    return (
      <AlertTemplatesForm
        variables={variables}
        calculatedMetrics={calculatedMetrics}
        alertTemplateKey={alertTemplateKey}
        alertThresholds={alertThresholds}
        hasEditAccess={hasEditAccess}
        hasCreateAccess={hasCreateAccess}
      />
    )
  }

  return (
    <AlertTemplatesView
      alertTemplateKey={alertTemplateKey}
      hasViewAccess={hasAccess}
    />
  )
}

export default AlertTemplatesPage
