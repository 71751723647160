import { ACTIONS as SESSION_ACTIONS } from 'modules/session/actions'
import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'users'

/** Initial State of the reducer */
const initialState = {
  list: [],
  bulkEditList: [],
  superAdmins: [],
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.GET_USERS]: (state, { list }) => {
    return { ...state, list }
  },
  [ACTIONS.USER_UPDATED]: (state, { user }) => {
    const users = [...state.list]
    const index = users.findIndex((u) => u._id === user._id)
    if (index > -1) {
      users[index] = { ...user }
    }
    return { ...state, list: users }
  },
  [ACTIONS.CHANGE_STATUS]: (state, { id, active }) => {
    const users = [...state.list]
    const index = users.findIndex((user) => user._id === id)
    users[index] = { ...users[index], active }
    return { ...state, list: users }
  },
  [SESSION_ACTIONS.LOGOUT]: () => {
    return { ...initialState }
  },
  [ACTIONS.USERS_BULK_UPDATE]: (state, { list }) => {
    const users = [...state.list]
    if (list) {
      list.forEach((user) => {
        const index = users.findIndex((userData) => userData._id === user._id)
        if (index !== -1) {
          users[index] = user
        }
      })
    }
    return { ...state, list: users }
  },
  [ACTIONS.USERS_BULK_DELETE]: (state, { idList }) => {
    const users = [...state.list]
    idList.forEach((userId) => {
      const index = users.findIndex((userData) => userData._id === userId)
      if (index !== -1) {
        users.splice(index, 1)
      }
    })
    return { ...state, list: users }
  },
  [ACTIONS.SET_BULK_USERS]: (state, { bulkEditList }) => {
    return { ...state, bulkEditList }
  },
  [ACTIONS.GET_ADMINS]: (state, { superAdmins }) => {
    return { ...state, superAdmins }
  },
}

export default { initialState, name, reduce }
