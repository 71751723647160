import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { granularities } from '@decision-sciences/qontrol-common'

import Table from 'components/table/beta'
import DropdownTree from 'components/dropdown-tree'
import EntitySelectorFilters, {
  FILTER_TYPE,
} from 'components/entity-selector/filters'
import EntitySelectorActions from 'components/entity-selector/action-bar'
import LineDivider from 'components/line-divider/index'
import Button from 'components/button/index'
import RoundRectangleButton from 'components/round-rectangle-button/index'

import { ReactComponent as IconFilter } from 'assets/filters.svg'
import { ReactComponent as ClearIcon } from 'assets/clear_all_red.svg'
import { scrollToElement } from '../../../../components/utils/scroll'
import { highLightElement } from '../../../../components/utils/highlight'

const { GRANULARITIES, GRANULARITY_DETAILS } = granularities

const { ACCOUNT, CAMPAIGN, AD_GROUP } = GRANULARITIES

const EntityDetails = ({
  elementType,
  accountType,
  setSelectedEntity,
  accountId,
  triggerTableData,
  triggerTableColumns,
}) => {
  const [entityTableDisplaySize, setEntityTableDisplaySize] = useState(
    DEFAULT_ENTITY_TABLE_SIZE
  )
  const [filtersOpened, setFiltersOpened] = useState(false)
  const [filtersApplied, setFiltersApplied] = useState(false)
  const [filter, setFilter] = useState({})
  const [filteredTriggerTableData, setFilteredTriggerTableData] = useState([
    ...(triggerTableData || []),
  ])

  const filterData = (newFilters) => {
    let newFilteredEntities = [...triggerTableData]

    const {
      search,
      [elementType]: currentGranularity,
      ...granularityFilters
    } = newFilters

    if (search) {
      const regex = new RegExp(search, 'gi')
      newFilteredEntities = newFilteredEntities.filter(({ triggeredBy }) =>
        triggeredBy.match(regex)
      )
    }

    if (currentGranularity && currentGranularity?.length) {
      newFilteredEntities = newFilteredEntities.filter(({ _id }) =>
        currentGranularity.includes(_id)
      )
    }

    for (const [granularity, granularityFilter] of Object.entries(
      granularityFilters
    )) {
      if (granularityFilter.length) {
        newFilteredEntities = newFilteredEntities.filter(
          ({ [granularity]: parentGranularity }) =>
            granularityFilter.includes(parentGranularity)
        )
      }
    }

    setFilteredTriggerTableData([...newFilteredEntities])
  }

  const isAccount = elementType === ACCOUNT

  const filters = useMemo(() => {
    if (isAccount || !triggerTableData?.length) {
      return null
    }

    const filters = [
      {
        fieldKey: 'search',
        label: 'Search',
        placeholder: 'Search',
        type: FILTER_TYPE.SEARCH,
      },
      {
        fieldKey: elementType,
        label: elementType,
        placeholder: 'All',
        type: FILTER_TYPE.DROPDOWN_MULTISELECT,
        selectAll: 'All',
        options: [
          ...triggerTableData.map(({ _id: value, triggeredBy: label }) => ({
            value,
            label,
          })),
        ],
      },
    ]

    // If we find parent data, add columns
    const { campaign, adSet, adgroup_id } = triggerTableData[0]
    const adGroup = adSet || adgroup_id
    if (
      (elementType !== CAMPAIGN && campaign) ||
      (elementType !== AD_GROUP && adGroup)
    ) {
      const granularity = adSet ? 'adSet' : 'campaign'

      filters.push({
        fieldKey: granularity,
        label: granularity,
        placeholder: 'All',
        type: FILTER_TYPE.DROPDOWN_MULTISELECT,
        selectAll: 'All',
        options: [
          ...triggerTableData.map(({ [granularity]: label }) => ({
            value: label,
            label,
          })),
        ],
      })
    }

    return filters
  }, [JSON.stringify(triggerTableData)])

  const handleFiltersChange = (fieldKey) => (value) => {
    const changes = { ...filter, [fieldKey]: value }
    setFilter({ ...changes })
  }

  const onApplyFilters = () => {
    setFiltersApplied(true)
    filterData(filter)
  }

  const onClearFilters = () => {
    const newFilters = {}

    setFiltersApplied(false)
    setFilter(newFilters)
    filterData(newFilters)
  }

  const onSelectEntity = (entityId) => {
    setSelectedEntity(entityId ? entityId : null)
    entityId && scrollToElement('alert-details')
    highLightElement('highlight-section')
  }

  if (elementType === GRANULARITIES.ACCOUNT) {
    return null
  }

  return (
    <div
      className="alert-trigger-details__entity form__section"
      key={elementType}
    >
      <div className="heading-secondary space-between">
        {GRANULARITY_DETAILS[elementType].labels[accountType]} Details
        <div className="display-flex">
          <DropdownTree
            options={ENTITY_TABLE_SIZES}
            onChange={({ value }) => setEntityTableDisplaySize(value)}
            selected={!!entityTableDisplaySize}
            className="alert-trigger-details__entity-table-dropdown"
            displayText={`View: ${
              ENTITY_TABLE_SIZES.find(
                ({ value }) => value === entityTableDisplaySize
              )?.label
            }`}
          />
          {isAccount ? null : (
            <RoundRectangleButton
              className={cx('margin-left-16 padding-3', {
                'filter-active': filtersOpened || filtersApplied,
              })}
              onClick={() =>
                setFiltersOpened((filtersOpened) => !filtersOpened)
              }
              contentRender={() => (
                <IconFilter
                  className={
                    filtersOpened || filtersApplied
                      ? 'fill-white'
                      : 'fill-light-blue'
                  }
                />
              )}
            />
          )}
        </div>
      </div>
      {!isAccount && filtersOpened ? (
        <div className="form__section__body margin-bottom-20 padding-0 alert-filters">
          <h3 className="generic-heading generic-heading--no-margin padding-15">
            Filters
          </h3>
          <LineDivider />
          <div className="padding-10">
            <EntitySelectorFilters
              filters={filters}
              selectedFilters={filter}
              onFiltersChange={handleFiltersChange}
            />
          </div>
          <LineDivider />
          <EntitySelectorActions
            hasFilters
            onApplyFilters={onApplyFilters}
            actions={
              <Button
                secondaryRed
                compact
                disabled={!filtersApplied}
                onClick={onClearFilters}
                value={
                  <div className="align-row">
                    <ClearIcon width={16} height={16} className="fill-red" />
                    <div className="margin-left-10">Clear All</div>
                  </div>
                }
                className="clear-filters"
              />
            }
          />
        </div>
      ) : null}
      <Table
        columns={triggerTableColumns}
        data={filteredTriggerTableData || []}
        onSelect={(entity) => {
          const entityId = isAccount
            ? accountId.externalAccountId
            : entity[0]?._id
          onSelectEntity(entityId)
        }}
        singleSelection
        height={entityTableDisplaySize}
        className="alert-trigger-details__entity-table"
      />
    </div>
  )
}

EntityDetails.propTypes = {
  elementType: PropTypes.string.isRequired,
  accountType: PropTypes.string.isRequired,
  setSelectedEntity: PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired,
  triggerTableColumns: PropTypes.array.isRequired,
  triggerTableData: PropTypes.array.isRequired,
}

const DEFAULT_ENTITY_TABLE_SIZE = 700
const ENTITY_TABLE_SIZES = [
  {
    label: 'Short',
    value: 400,
  },
  {
    label: 'Default',
    value: DEFAULT_ENTITY_TABLE_SIZE,
  },
  {
    label: 'Tall',
    value: 1100,
  },
]

export default EntityDetails
