import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { metrics } from '@decision-sciences/qontrol-common'
import { KpiList } from 'modules/companies/subsections/kpi-section/kpi-list'
import { KpiViewOnly } from 'modules/companies/subsections/kpi-section/kpi-view'
import { KpiSelector } from 'modules/companies/subsections/kpi-section/kpi-selector'
import ButtonToggle from 'components/button-toggle/index'
import { ACCOUNT_ORDER } from 'modules/accounts/common'
import { KpiCompanyFilter } from './companies-filter/index'
import { useFetchKpis } from './use-fetch-kpi'
import { FacebookKpiAttribution } from './facebook-attribution/index'
import { mapKpiOption } from './utils'
import './style.scss'

const { mapKPIRow } = metrics

const SORT_FIELD = {
  PUBLISHER: 'type',
  KPI_NAME: 'name',
}

const CLIENT_SOURCE = 'Client'

/**
 * KPI section for clients and business units
 * @param {Object} params React Params
 * @param {Boolean} [params.isBusinessUnit = false] Flag to toggle some features on or off based on place used
 * @param {Object} params.company Viewed/edited company
 * @param {Object} [params.pendingChanges = {}] List of updated business units. Used to populate dropdowns properly
 * @param {Function} params.onChange Callback to persist changes to parent components
 * @param {Boolean} params.disabled Flag to disable editing
 * @param {Boolean} params.readOnly Toggle between read/edit modes
 * @param {Array} [params.kpis = []] List of kpis for the current account selection
 * @param {Object} [params.errors] Validation errors
 * @param {Function} [params.setErrors] Callback to manage validation errors
 * @param {Number} [params.loadingKpis] Amount of KPIs still loading
 * @returns {React.ReactElement}
 */
const KpiSection = ({
  isBusinessUnit = false,
  company,
  pendingChanges = {},
  onChange,
  disabled,
  readOnly,
  errors,
  setErrors,
  kpis = [],
  loadingKpis,
}) => {
  const { businessUnitKPIs: updatedBusinessUnits = [] } = pendingChanges
  const { primaryKPIs, secondaryKPIs, reportingKPIs, fbAttribution } = company

  const specialKPIs = useMemo(
    () =>
      kpis
        .sort((k1, k2) => ACCOUNT_ORDER[k1.type] - ACCOUNT_ORDER[k2.type])
        .map(mapKPIRow),
    [JSON.stringify(kpis)]
  )

  const [sortField, setSortField] = useState(SORT_FIELD.PUBLISHER)
  const [selectedCompanies, setSelectedCompanies] = useState([])

  const isCompanySelected = useMemo(() => {
    return (
      !company.businessUnits?.length ||
      selectedCompanies.some(
        (companyOrBusinessUnit) => companyOrBusinessUnit._id === company._id
      )
    )
  }, [JSON.stringify(company.businessUnits), JSON.stringify(selectedCompanies)])

  const companyGroups = selectedCompanies.length
    ? isCompanySelected
      ? [
          {
            id: company._id,
            label: CLIENT_SOURCE,
            tooltip: 'KPIs from Accounts with no Business Unit association',
          },
          ...selectedCompanies
            // TODO [Titus]: Rethink logic to embed companies properly and not filter out like this all over the place
            .filter((companyOrBu) => companyOrBu._id !== company._id)
            .map((bu) => ({ id: bu._id, label: bu.name })),
        ]
      : selectedCompanies.map((bu) => ({ id: bu._id, label: bu.name }))
    : null

  const extractKpiSubset = (kpiSet, kpiSource) => {
    const keys = (kpiSet || []).map(({ accountId, id }) => `${accountId}_${id}`)
    return kpiSource
      .filter((kpi) => keys.includes(kpi.key))
      .sort((o1, o2) => {
        switch (sortField) {
          case SORT_FIELD.PUBLISHER:
            return ACCOUNT_ORDER[o1.type] - ACCOUNT_ORDER[o2.type]
          case SORT_FIELD.KPI_NAME:
            if (o1.name !== o2.name) {
              return o1.name.localeCompare(o2.name)
            }
            if (o1.type !== o2.type) {
              return ACCOUNT_ORDER[o1.type] - ACCOUNT_ORDER[o2.type]
            }
            return o1.accountId.localeCompare(o2.accountId)
          default:
            return 0
        }
      })
  }

  const businessUnitAccounts = useMemo(() => {
    if (!selectedCompanies?.length || isBusinessUnit) {
      return []
    }
    return selectedCompanies.reduce((acc, businessUnit) => {
      // TODO [Titus]: Rethink logic to embed companies properly and not filter out like this all over the place
      if (businessUnit === company._id) {
        return acc
      }
      const accounts = businessUnit.accounts.filter(
        (account) => account.company === businessUnit._id
      )
      return [...acc, ...accounts]
    }, [])
  }, [JSON.stringify(selectedCompanies)])

  const [extraKpisRaw, extraKpisLoading] = useFetchKpis({
    viewMode: readOnly,
    accounts: businessUnitAccounts,
  })

  const extraKpis = useMemo(() => {
    return extraKpisRaw.map(mapKPIRow)
  }, [JSON.stringify(extraKpisRaw)])

  // Company primary KPIs
  const parsedPrimaryKPIs = useMemo(
    () => extractKpiSubset(primaryKPIs, specialKPIs),
    [JSON.stringify(primaryKPIs), JSON.stringify(specialKPIs), sortField]
  )

  // Company secondary KPIs
  const parsedSecondaryKPIs = useMemo(
    () => extractKpiSubset(secondaryKPIs, specialKPIs),
    [JSON.stringify(secondaryKPIs), JSON.stringify(specialKPIs), sortField]
  )

  // Company reporting KPIs
  const parsedReportingKPIs = useMemo(
    () => extractKpiSubset(reportingKPIs, specialKPIs),
    [JSON.stringify(reportingKPIs), JSON.stringify(specialKPIs), sortField]
  )

  /**
   * Extracts and maps the KPIs for one of the three configurations for a given BU.
   * @param {String} field KPI field to extract data from
   * @param {Object} bu Base business unit, taken from the company object
   * @param {Object} updatedBu Contains updates done to the BU
   * @returns {Array}
   */
  const extractKpiSetForBu = (field, bu, updatedBu) => {
    // If BU was updated, then take into account only latest KPI selections, ignore the ones coming from `company.businessUnits[index][field]`
    if (updatedBu?.[field]) {
      return updatedBu[field].map(mapKPIRow)
    }
    return (bu?.[field] || []).concat(updatedBu?.[field] || []).map(mapKPIRow)
  }

  /**
   * @type {{
   * [key: String]: {
   *  bu: Object,
   *  kpis: Array,
   *  primaryKPIs: Array,
   *  secondaryKPIs: Array,
   *  reportingKPIs: Array
   * }
   * }}
   */
  const companiesKpiMap = useMemo(() => {
    if (isBusinessUnit) {
      return {}
    }
    return selectedCompanies.reduce((acc, bu) => {
      if (!bu.accounts?.length) {
        return acc
      }
      const availableBuKpis = extraKpis.filter((kpi) =>
        bu.accounts.some(
          (account) =>
            account.externalAccountId === kpi.accountId ||
            account.externalAccountId === `act_${kpi.accountId}`
        )
      )
      const updatedBu = updatedBusinessUnits.find(
        (updatedBu) => updatedBu._id === bu._id
      )
      return {
        ...acc,
        [bu._id]: {
          bu,
          kpis: availableBuKpis,
          primaryKPIs: extractKpiSetForBu('primaryKPIs', bu, updatedBu),
          secondaryKPIs: extractKpiSetForBu('secondaryKPIs', bu, updatedBu),
          reportingKPIs: extractKpiSetForBu('reportingKPIs', bu, updatedBu),
        },
      }
    }, {})
  }, [
    JSON.stringify(primaryKPIs),
    JSON.stringify(secondaryKPIs),
    JSON.stringify(reportingKPIs),
    JSON.stringify(extraKpis),
    JSON.stringify(selectedCompanies),
    JSON.stringify(updatedBusinessUnits),
  ])

  const kpiSelectorOptions = useMemo(
    () => [
      ...specialKPIs.map(mapKpiOption(company._id)),
      ...Object.values(companiesKpiMap).reduce((acc, { kpis, bu }) => {
        // TODO [Titus]: Rethink logic to embed companies properly and not filter out like this all over the place
        if (bu._id === company._id) {
          return acc
        }
        return [...acc, ...kpis.map(mapKPIRow).map(mapKpiOption(bu._id))]
      }, []),
    ],
    [JSON.stringify(specialKPIs), companiesKpiMap]
  )

  const noKPIsSelected =
    !parsedPrimaryKPIs.length &&
    !parsedSecondaryKPIs.length &&
    !parsedReportingKPIs.length &&
    Object.values(companiesKpiMap).every(
      ({ primaryKPIs, secondaryKPIs, reportingKPIs }) =>
        !primaryKPIs.length && !secondaryKPIs.length && !reportingKPIs.length
    )

  const setKpiErrors = (field) => {
    const newErrors = field ? { ...errors?.facebookKPIAttribution } : null
    if (newErrors && newErrors[field]) {
      newErrors[field] = null
    }
    setErrors({
      ...errors,
      facebookKPIAttribution: newErrors,
    })
  }

  /**
   * Handler to change the sort field
   * @param {String} field Field to sort by. One of {@link SORT_FIELD}
   * @returns {void}
   */
  const selectSortField = (field) => () => {
    setSortField(field)
  }

  /**
   * Handler to update state when company filter changes
   * @param {Array} selectedCompanyIds List of ObjectIds of selected client & business units
   * @returns {void}
   */
  const handleCompanyFilter = (selectedCompanyIds) => {
    if (!company.businessUnits?.length) {
      return
    }
    const companySelection = [company, ...company.businessUnits].filter(
      (companyOrBusinessUnit) =>
        selectedCompanyIds.includes(companyOrBusinessUnit._id)
    )
    setSelectedCompanies(companySelection)
  }

  /**
   * Thunk function triggered when KPIs are updated for the client or BU
   * @param {String} field Field to persist updates to
   * @returns {(selected: String[]) => void}
   */
  const handleKpiChange = (field) => (selected) => {
    const updatedCompany = {}
    // If on client page and the client is selected OR on the BU page, then put the changes directly on the company object
    if (isCompanySelected || isBusinessUnit) {
      const updatedKpis = specialKPIs.filter(
        (el) => selected.indexOf(el.key) > -1
      )
      updatedCompany[field] = updatedKpis
    }
    if (!isBusinessUnit && selectedCompanies.length) {
      // Initialize array of businessUnitKPIs with _id and previously done updates from 'updatedBusinessUnits'
      updatedCompany.businessUnitKPIs = company.businessUnits.map(({ _id }) => {
        const businessUnitUpdates =
          updatedBusinessUnits.find((updatedBu) => updatedBu._id === _id) || {}
        return { _id, ...businessUnitUpdates }
      })
      Object.values(companiesKpiMap).forEach(({ bu, kpis }) => {
        // TODO [Titus]: Rethink logic to embed companies properly and not filter out like this all over the place
        if (bu._id !== company._id) {
          const selectedBuKpis = kpis.filter((kpi) =>
            selected.includes(kpi.key)
          )
          const buIndex = updatedCompany.businessUnitKPIs.findIndex(
            ({ _id }) => _id === bu._id
          )
          if (buIndex > -1) {
            updatedCompany.businessUnitKPIs[buIndex][field] = selectedBuKpis
          }
        }
      }, [])
    }
    onChange(updatedCompany, field)
    setKpiErrors()
  }

  /**
   * When rendered on client page, map selected KPIs from client and all selected BUs
   * @param {Array} clientKpis List of client KPIs selection
   * @param {String} buKpiField Field to extract BU KPI selection from - primaryKPIs, secondaryKPIs, reportingKPIs
   * @returns {Array}
   */
  const getSelectedKpis = (clientKpis, buKpiField) => {
    const selectedKpis = []
    if (clientKpis?.length && (isCompanySelected || isBusinessUnit)) {
      selectedKpis.push(...clientKpis.map((el) => el.key))
    }
    if (!isBusinessUnit) {
      Object.values(companiesKpiMap).forEach((buConfig) => {
        // TODO [Titus]: Rethink logic to embed companies properly and not filter out like this all over the place
        if (buConfig.bu._id !== company._id) {
          selectedKpis.push(...buConfig[buKpiField].map((el) => el.key))
        }
      }, [])
    }
    return selectedKpis
  }

  /**
   * Gets selected KPI list from the
   * @param {Array} companyKpis List of company KPIs
   * @param {String} field Field to extract data from
   * @returns {Array}
   */
  const getKpiList = (companyKpis, field) => {
    const kpiList = []
    if (isCompanySelected || isBusinessUnit) {
      kpiList.push(
        ...(companyKpis || []).map((kpi) => ({
          ...kpi,
          source: isBusinessUnit ? '' : CLIENT_SOURCE,
        }))
      )
    }
    const buKpis = Object.keys(companiesKpiMap).reduce((acc, buId) => {
      const { bu, [field]: kpis } = companiesKpiMap[buId]
      // TODO [Titus]: Rethink logic to embed companies properly and not filter out like this all over the place
      const isForCompany = bu._id === company._id
      if (isForCompany) {
        return acc
      }
      return [
        ...acc,
        ...kpis.map((kpi) => ({
          ...kpi,
          source: bu.name,
        })),
      ]
    }, [])

    const combinedList = [
      ...kpiList,
      ...extractKpiSubset(buKpis, extraKpis).map((kpi) => ({
        ...kpi,
        source: buKpis.find(({ key }) => key === kpi.key)?.source,
      })),
    ]

    return combinedList.sort((o1, o2) => {
      switch (sortField) {
        case SORT_FIELD.PUBLISHER:
          return ACCOUNT_ORDER[o1.type] - ACCOUNT_ORDER[o2.type]
        case SORT_FIELD.KPI_NAME:
          return o1.name
            .trim()
            .toLowerCase()
            .localeCompare(o2.name.trim().toLowerCase())
        default:
          return 0
      }
    })
  }

  if (readOnly) {
    return (
      <KpiViewOnly
        parsedPrimaryKPIs={parsedPrimaryKPIs}
        parsedSecondaryKPIs={parsedSecondaryKPIs}
        parsedReportingKPIs={parsedReportingKPIs}
        attribution={fbAttribution}
      />
    )
  }

  return (
    <>
      <div
        data-cy="client-kpis-section"
        className="form__section__body align-column"
      >
        <div className="align-baseline">
          <h3 data-cy="kpis-header" className="generic-heading">
            KPIs
          </h3>

          <div className="align-center gap-16 kpi-heading__extra">
            <div className="align-center gap-8">
              <div className="general-label general-label--no-bottom-margin">
                Sort by:
              </div>
              <ButtonToggle
                disabled={noKPIsSelected}
                selected={!noKPIsSelected && sortField === SORT_FIELD.PUBLISHER}
                onClick={selectSortField(SORT_FIELD.PUBLISHER)}
                label="Publisher"
              />
              <ButtonToggle
                disabled={noKPIsSelected}
                selected={!noKPIsSelected && sortField === SORT_FIELD.KPI_NAME}
                onClick={selectSortField(SORT_FIELD.KPI_NAME)}
                label="KPI Name"
              />
            </div>
            {!isBusinessUnit && (
              <KpiCompanyFilter
                company={company}
                onChange={handleCompanyFilter}
              />
            )}
          </div>
        </div>
        <div className="align-row">
          <div className="form__section__body__half-width-section">
            <KpiSelector
              sortField={sortField}
              sectionLabel="Primary"
              sectionLabelDataCy="kpi-primary-label"
              dropdownDataCy="kpi-primary-dropdown"
              defaultOption="Select Primary KPIs"
              options={kpiSelectorOptions}
              companyGroups={companyGroups}
              selectedItems={getSelectedKpis(parsedPrimaryKPIs, 'primaryKPIs')}
              disabled={
                disabled || loadingKpis > 0 || !kpiSelectorOptions.length
              }
              loading={!!loadingKpis || !!extraKpisLoading}
              onChange={handleKpiChange('primaryKPIs')}
            />
            <KpiList
              id="primary-kpis"
              kpis={getKpiList(parsedPrimaryKPIs, 'primaryKPIs')}
            />
          </div>
          <div className="form__section__body__half-width-section right-side">
            <KpiSelector
              sortField={sortField}
              sectionLabel="Secondary"
              sectionLabelDataCy="kpi-secondary-label"
              dropdownDataCy="kpi-secondary-dropdown"
              defaultOption="Select Secondary KPIs"
              options={kpiSelectorOptions}
              companyGroups={companyGroups}
              selectedItems={getSelectedKpis(
                parsedSecondaryKPIs,
                'secondaryKPIs'
              )}
              disabled={
                disabled || loadingKpis > 0 || !kpiSelectorOptions.length
              }
              loading={!!loadingKpis || !!extraKpisLoading}
              onChange={handleKpiChange('secondaryKPIs')}
            />
            <KpiList
              id="secondary-kpis"
              kpis={getKpiList(parsedSecondaryKPIs, 'secondaryKPIs')}
            />
          </div>
        </div>
        <div className="align-row margin-top-10">
          <div className="form__section__body__half-width-section">
            <KpiSelector
              sortField={sortField}
              sectionLabel="Reporting"
              sectionLabelDataCy="kpi-reporting-label"
              dropdownDataCy="kpi-reporting-dropdown"
              defaultOption="Select Reporting KPIs"
              options={kpiSelectorOptions}
              companyGroups={companyGroups}
              selectedItems={getSelectedKpis(
                parsedReportingKPIs,
                'reportingKPIs'
              )}
              disabled={
                disabled || loadingKpis > 0 || !kpiSelectorOptions.length
              }
              loading={!!loadingKpis || !!extraKpisLoading}
              onChange={handleKpiChange('reportingKPIs')}
            />
            <KpiList
              id="reporting-kpis"
              kpis={getKpiList(parsedReportingKPIs, 'reportingKPIs')}
            />
          </div>
        </div>
      </div>
      <FacebookKpiAttribution
        isBusinessUnit={isBusinessUnit}
        disabled={disabled}
        company={company}
        pendingChanges={pendingChanges}
        kpiCount={kpis.length}
        onChange={onChange}
        setKpiErrors={setKpiErrors}
        errors={errors}
      />
    </>
  )
}

KpiSection.propTypes = {
  isBusinessUnit: PropTypes.bool,
  company: PropTypes.object.isRequired,
  pendingChanges: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  kpis: PropTypes.array,
  loadingKpis: PropTypes.number,
}

export default KpiSection
