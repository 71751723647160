import { PERMISSIONS, PERMISSION_TYPES, useAccess } from 'hooks/access'
import { CreateEditLegalSection } from 'modules/legal/create-edit-section'
import React, { useEffect } from 'react'
import { useParams, redirect } from 'react-router-dom'
import { useStore } from 'store'

const LegalSectionPage = () => {
  const { id, sectionId } = useParams()

  const { state } = useStore()

  const {
    legal: { currentEditedLegal: legalItem },
  } = state

  const hasEditAccess = useAccess({
    feature: PERMISSIONS.LEGAL_SETTINGS,
    type: PERMISSION_TYPES.EDIT,
  })

  /* On mount or permission change, if the user does not have access, redirect to unauthorized page */
  useEffect(() => {
    if (!hasEditAccess) {
      redirect('/unauthorized')
    }
  }, [hasEditAccess])

  const pageTitle = `${legalItem?.name || '...'} - ${
    sectionId && sectionId === 'new' ? 'Add Section' : 'Edit Section'
  }`

  return (
    <React.Fragment>
      <div className="heading" data-cy="page-heading">
        {pageTitle}
      </div>
      <div>
        <CreateEditLegalSection legalId={id} sectionId={sectionId} />
      </div>
    </React.Fragment>
  )
}

export { LegalSectionPage }
