import React, { useState } from 'react'
import Api from 'easy-fetch-api'

import Button from 'components/button'
import Input from 'components/input'
import { isValidEmail } from 'components/validator'

import AuthenticationContainer from 'components/authentication'

import 'styles/authentication.scss'

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const [submitError, setSubmitError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const isDisabledNextButton = !!error || loading
  const hiddenEmail = email && `******${email.slice(6, email.length)}`

  const checkValidEmail = (email) => {
    const isValid = isValidEmail(email)
    if (!isValid) {
      setError('Invalid email format')
    } else {
      setError(null)
    }
    return isValid
  }

  const handleSubmit = (ev) => {
    ev.preventDefault()
    if (!checkValidEmail(email)) {
      return
    }
    setLoading(true)

    Api.post({
      url: '/api/users/forgot-password',
      data: { email },
    })
      .then((res) => {
        if (!res.success || res.error) {
          return setSubmitError(
            res.error || 'There is no user with this email.'
          )
        }
        setSuccess(true)
      })
      .catch((err) => {
        setSubmitError(err)
      })
      .finally(() => setLoading(false))
  }

  return (
    <AuthenticationContainer
      title="Recovery"
      onSubmit={handleSubmit}
      error={submitError}
    >
      <div className="authentication-container__description">
        {success
          ? `If there is an account with ${hiddenEmail} email address, an email will be sent out with instructions`
          : `Enter the email address for your account so we can help you create a new password.`}
      </div>

      {!success && (
        <Input
          type="text"
          disabled={loading}
          value={email}
          onChange={(e) => {
            setEmail(e)
            setError(false)
            setSubmitError(false)
          }}
          blur={() => checkValidEmail(email)}
          placeholder={'Enter Email Address'}
          label="Email Address"
          className="input-wrapper--uppercase"
        />
      )}

      {error && <div className="error">{error}</div>}

      {success ? (
        <div className="authentication-container__description">
          Follow the link in the email to reset your password.
        </div>
      ) : (
        <div className="align-row">
          <Button
            className="authentication-container__button button--big"
            value="Next"
            disabled={isDisabledNextButton}
            onClick={handleSubmit}
          />
        </div>
      )}
    </AuthenticationContainer>
  )
}

export default ForgotPasswordPage
