import { flow } from '@decision-sciences/qontrol-common'
const {
  ADSET_DESTINATION_TYPE,
  LEAD_METHODS,
  OPTIMIZATION_GOAL,
  OBJECTIVES_MAP,
  BID_STRATEGY_TYPE,
} = flow.facebook

export const BILLING_EVENTS = {
  APP_INSTALLS: { value: 'APP_INSTALLS', label: 'App Installs' },
  CLICKS: { value: 'CLICKS', label: 'Clicks' },
  IMPRESSIONS: { value: 'IMPRESSIONS', label: 'Impression' },
  LINK_CLICKS: { value: 'LINK_CLICKS', label: 'Link Click (CPC)' },
  OFFER_CLAIMS: { value: 'OFFER_CLAIMS', label: 'Offer Claims' },
  PAGE_LIKES: { value: 'PAGE_LIKES', label: 'Page Likes' },
  POST_ENGAGEMENT: {
    value: 'POST_ENGAGEMENT',
    label: 'Post Engagement',
  },
  THRUPLAY: { value: 'THRUPLAY', label: 'ThruPlay' },
  TWO_SECOND_CONTINUOUS_VIDEO_VIEWS: {
    value: 'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS',
    label: '2-Second Continuous Video View',
  },
  NONE: { value: 'NONE', label: 'None' },
}

export const AUTOMATED_APP_ADS = {
  AUTO_APP_INSTALLS: {
    label: 'App Installs',
    value: 'AUTO_APP_INSTALLS',
    description:
      'Maximize install volume by delivering your ads to the people most likely to install your app.',
    lowerCostDescription: 'Get the most app installs for your budget',
  },
  APP_INSTALLS_WITH_APP_EVENTS: {
    label: 'App Installs with App Events',
    value: 'APP_INSTALLS_WITH_APP_EVENTS',
    description:
      'Balance app install volume and app events to achieve an optimal combination of both.',
    lowerCostDescription:
      'Get as many app installs with app events as possible for your budget',
  },
  AUTO_APP_EVENTS: {
    label: 'App Events',
    value: 'AUTO_APP_EVENTS',
    description:
      'Maximize app events by delivering your ads to the people most likely to take an action in your app at least once.',
    lowerCostDescription: 'Get the most app events for your budget',
  },
  AUTO_VALUE: {
    label: 'Value',
    value: 'AUTO_VALUE',
    description:
      'Maximize value by delivering your ads to people likely to generate the most revenue in your app over 7 days.',
  },
}

export const BID_CONTROL_CONFIG = {
  [OPTIMIZATION_GOAL.REACH.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most 1,000 impressions using the lowest cost bid strategy.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most 1,000 impressions without bidding more than $%s in any auction using the bid cap bid strategy.`,
    },
  },
  [OPTIMIZATION_GOAL.LINK_CLICKS.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most link clicks using the lowest cost bid strategy.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most link clicks without bidding more than $%s in any auction using the bid cap bid strategy.`,
    },
    AMOUNT_COST: {
      message: `Facebook will aim to get the most link clicks and try to keep the average cost below $%s using the cost cap bid strategy. Some results may cost more and some may cost less.`,
    },
  },
  [OPTIMIZATION_GOAL.POST_ENGAGEMENT.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most post engagements using the lowest cost bid strategy.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most post engagements without bidding more than $%s in any auction using the bid cap bid strategy.`,
    },
    AMOUNT_COST: {
      message: `Facebook will aim to get the most post engagements and try to keep the average cost below $%s using the cost cap bid strategy. Some results may cost more and some may cost less.`,
    },
  },
  [OPTIMIZATION_GOAL.PAGE_LIKES.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most Page likes using the lowest cost bid strategy.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most Page likes without bidding more than $%s in any auction using the bid cap bid strategy.`,
    },
    AMOUNT_COST: {
      message: `Facebook will aim to get the most Page likes and try to keep the average cost below $%s using the cost cap bid strategy. Some results may cost more and some may cost less.`,
    },
  },
  [OPTIMIZATION_GOAL.EVENT_RESPONSES.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most event responses using the lowest cost bid strategy.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most event responses and try to keep the average cost below $%s using the bid cap bid strategy. Some results may cost more and some may cost less.`,
    },
    AMOUNT_COST: {
      message: `Facebook will aim to get the most event responses and try to keep the average cost below $%s using the cost cap bid strategy. Some results may cost more and some may cost less.`,
    },
  },
  [OPTIMIZATION_GOAL.IMPRESSIONS.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most 1,000 impressions using the lowest cost bid strategy.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most 1,000 impressions without bidding more than $%s in any auction using the bid cap bid strategy.`,
    },
    AMOUNT_COST: {
      message: `Facebook will aim to get the most 1,000 impressions without bidding more than $%s in any auction using the cost cap bid strategy.`,
    },
  },
  [OPTIMIZATION_GOAL.LANDING_PAGE_VIEWS.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most landing page views using the lowest cost bid strategy.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most landing page views without bidding more than $%s in any auction using the bid cap bid strategy.`,
    },
    AMOUNT_COST: {
      message: `Facebook will aim to get the most landing page views and try to keep the average cost below $%s using the cost cap bid strategy. Some results may cost more and some may cost less.`,
    },
  },
  [OPTIMIZATION_GOAL.DAILY_UNIQUE_REACH.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most 1,000 impressions using the lowest cost bid strategy.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most 1,000 impressions without bidding more than $%s in any auction using the bid cap bid strategy.`,
    },
    AMOUNT_COST: {
      message: `Facebook will aim to get the most landing page views and try to keep the average cost below $%s using the cost cap bid strategy. Some results may cost more and some may cost less.`,
    },
  },
  [OPTIMIZATION_GOAL.CONVERSIONS.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most conversions using the lowest cost bid strategy.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most conversions without bidding more than $%s in any auction using the bid cap bid strategy.`,
    },
    AMOUNT_COST: {
      message: `Facebook will aim to get the most conversions and try to keep the average cost below $%s using the cost cap bid strategy. Some results may cost more and some may cost less.`,
    },
  },
  [OPTIMIZATION_GOAL.CONVERSATIONS.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most results using the lowest cost bid strategy.`,
    },
    AMOUNT_COST: {
      message: `Facebook will aim to get the most results and try to keep the average cost below $%s using the cost cap bid strategy. Some results may cost more and some may cost less.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most results without bidding more than $%s in any auction using the bid cap bid strategy.`,
    },
  },
  [OPTIMIZATION_GOAL.APP_DOWNLOADS.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most conversions using the lowest cost bid strategy.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most conversions without bidding more than $%s in any auction using the bid cap bid strategy.`,
    },
    AMOUNT_COST: {
      message: `Facebook will aim to get the most conversions and try to keep the average cost below $%s using the cost cap bid strategy. Some results may cost more and some may cost less.`,
    },
  },
  [OPTIMIZATION_GOAL.STORE_TRAFFIC.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most store visits using the lowest cost bid strategy.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most store visits without bidding more than $%s in any auction using the bid cap bid strategy.`,
    },
  },
  [OPTIMIZATION_GOAL.APP_INSTALLS.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most app installs using the lowest cost bid strategy.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most app installs without bidding more than $%s in any auction using the bid cap bid strategy`,
    },
    AMOUNT_COST: {
      message: `Facebook will aim to get the most app installs and try to keep the average cost below $%s using the cost cap bid strategy. Some results may cost more and some may cost less.`,
    },
  },
  [OPTIMIZATION_GOAL.APP_EVENTS.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most conversions using the lowest cost bid strategy.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most conversions without bidding more than $%s in any auction using the bid cap bid strategy.`,
    },
    AMOUNT_COST: {
      message: `Facebook will aim to get the most conversions and try to keep the average cost below $%s using the cost cap bid strategy. Some results may cost more and some may cost less.`,
    },
  },
  [OPTIMIZATION_GOAL.THRUPLAY.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most ThruPlays using the lowest cost bid strategy.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most ThruPlays and try to keep the average cost below $%s using the bid cap bid strategy. Some results may cost more and some may cost less.`,
    },
    AMOUNT_COST: {
      message: `Facebook will aim to get the most ThruPlays and try to keep the average cost below $%s using the cost cap bid strategy. Some results may cost more and some may cost less.`,
    },
  },
  [OPTIMIZATION_GOAL.TWO_SECOND_CONTINUOUS_VIDEO_VIEWS.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most video views (2-second continuous) using the lowest cost bid strategy.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most video views (2-second continuous) without bidding more than $%s in any auction using the bid cap bid strategy.`,
    },
    AMOUNT_COST: {
      message: `Facebook will aim to get the most video views (2-second continuous) and try to keep the average cost below $%s using the cost cap bid strategy. Some results may cost more and some may cost less.`,
    },
  },
  [OPTIMIZATION_GOAL.LEAD_GENERATION.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most leads using the lowest cost bid strategy.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most leads without bidding more than $%s in any auction using the bid cap bid strategy.`,
    },
    AMOUNT_COST: {
      message: `Facebook will aim to get the most leads and try to keep the average cost below $%s using the cost cap bid strategy. Some results may cost more and some may cost less.`,
    },
  },
  [OPTIMIZATION_GOAL.CONVERSION_EVENTS.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most views content using the lowest cost bid strategy.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most views content and try to keep the average cost below $%s using the bid cap bid strategy. Some results may cost more and some may cost less.`,
    },
    AMOUNT_COST: {
      message: `Facebook will aim to get the most views content without bidding more than $%s in any auction using the cost cap bid strategy.`,
    },
  },
  [OPTIMIZATION_GOAL.CONVERSION_LEADS.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most results using the lowest cost bid strategy.`,
    },
    AMOUNT_BID: {
      message: `Facebook will aim to get the most results without bidding more than $%s in any auction using the bid cap bid strategy.`,
    },
  },
  [OPTIMIZATION_GOAL.VALUE.value]: {
    NO_AMOUNT: {
      message: `Facebook will aim to spend your entire budget and get the most purchase value using the highest value bid strategy. If you want to control your average minimum ROAS (return on ad spend), enter an amount.`,
    },
    AMOUNT: {
      message: `Facebook will aim to get the most purchase value and for your average ROAS (return on ad spend) to be %s or higher using the minimum ROAS bid strategy.`,
    },
  },
  [AUTOMATED_APP_ADS.AUTO_APP_INSTALLS.value]: {
    label: 'per app install',
    message: `Facebook won't bid more than this amount for any individual app install. You may have trouble spending your budget if this amount is too low.`,
  },
  [AUTOMATED_APP_ADS.AUTO_APP_EVENTS.value]: {
    label: 'per app event',
    message: `Facebook won't bid more than this amount for any individual app event. You may have trouble spending your budget if this amount is too low`,
  },
}

export const SPECIFIC_FB_OPTIMIZATION_EXTRA_LABEL = {
  [OPTIMIZATION_GOAL.REACH.value]: {
    [BID_STRATEGY_TYPE.LOWEST_COST_WITH_BID_CAP]: 'maximum bid in each auction',
  },
  [OPTIMIZATION_GOAL.IMPRESSIONS.value]: {
    [BID_STRATEGY_TYPE.LOWEST_COST_WITH_BID_CAP]: 'maximum bid in each auction',
  },
  [OPTIMIZATION_GOAL.LINK_CLICKS.value]: {
    [BID_STRATEGY_TYPE.COST_CAP]: 'average cost per link click',
    [BID_STRATEGY_TYPE.LOWEST_COST_WITH_BID_CAP]: 'maximum bid in each auction',
  },
  [OPTIMIZATION_GOAL.POST_ENGAGEMENT.value]: {
    [BID_STRATEGY_TYPE.COST_CAP]: 'average cost per post engagement',
    [BID_STRATEGY_TYPE.LOWEST_COST_WITH_BID_CAP]: 'maximum bid in each auction',
  },
  [OPTIMIZATION_GOAL.PAGE_LIKES.value]: {
    [BID_STRATEGY_TYPE.COST_CAP]: 'average cost per Page like',
    [BID_STRATEGY_TYPE.LOWEST_COST_WITH_BID_CAP]: 'maximum bid in each auction',
  },
  [OPTIMIZATION_GOAL.EVENT_RESPONSES.value]: {
    [BID_STRATEGY_TYPE.COST_CAP]: 'average cost per event response',
    [BID_STRATEGY_TYPE.LOWEST_COST_WITH_BID_CAP]: 'maximum bid in each auction',
  },
  [OPTIMIZATION_GOAL.LANDING_PAGE_VIEWS.value]: {
    [BID_STRATEGY_TYPE.COST_CAP]: 'average cost per landing page view',
    [BID_STRATEGY_TYPE.LOWEST_COST_WITH_BID_CAP]: 'maximum bid in each auction',
  },
  [OPTIMIZATION_GOAL.DAILY_UNIQUE_REACH.value]: {
    [BID_STRATEGY_TYPE.LOWEST_COST_WITH_BID_CAP]: 'maximum bid in each auction',
  },
  [OPTIMIZATION_GOAL.APP_INSTALLS.value]: {
    [BID_STRATEGY_TYPE.COST_CAP]: 'average cost per app install',
    [BID_STRATEGY_TYPE.LOWEST_COST_WITH_BID_CAP]: 'maximum bid in each auction',
  },
  [OPTIMIZATION_GOAL.CONVERSIONS.value]: {
    [BID_STRATEGY_TYPE.COST_CAP]: 'average cost per conversion',
    [BID_STRATEGY_TYPE.LOWEST_COST_WITH_BID_CAP]: 'maximum bid in each auction',
  },
  [OPTIMIZATION_GOAL.CONVERSATIONS.value]: {
    [BID_STRATEGY_TYPE.LOWEST_COST_WITH_BID_CAP]: 'maximum bid in each auction',
  },
  [OPTIMIZATION_GOAL.CONVERSION_LEADS.value]: {
    [BID_STRATEGY_TYPE.LOWEST_COST_WITH_BID_CAP]: 'maximum bid in each auction',
  },
  [OPTIMIZATION_GOAL.APP_EVENTS.value]: {
    [BID_STRATEGY_TYPE.COST_CAP]: 'average cost per conversion',
    [BID_STRATEGY_TYPE.LOWEST_COST_WITH_BID_CAP]: 'maximum bid in each auction',
  },
  [OPTIMIZATION_GOAL.THRUPLAY.value]: {
    [BID_STRATEGY_TYPE.COST_CAP]: 'average cost per ThruPlay',
    [BID_STRATEGY_TYPE.LOWEST_COST_WITH_BID_CAP]: 'maximum bid in each auction',
  },
  [OPTIMIZATION_GOAL.TWO_SECOND_CONTINUOUS_VIDEO_VIEWS.value]: {
    [BID_STRATEGY_TYPE.COST_CAP]:
      'average cost per video view (2-second continuous)',
    [BID_STRATEGY_TYPE.LOWEST_COST_WITH_BID_CAP]: 'maximum bid in each auction',
  },
  [OPTIMIZATION_GOAL.LEAD_GENERATION.value]: {
    [BID_STRATEGY_TYPE.COST_CAP]: 'average cost per lead',
    [BID_STRATEGY_TYPE.LOWEST_COST_WITH_BID_CAP]: 'maximum bid in each auction',
  },
  [OPTIMIZATION_GOAL.CONVERSION_EVENTS.value]: {
    [BID_STRATEGY_TYPE.COST_CAP]: 'average cost per view content',
    [BID_STRATEGY_TYPE.LOWEST_COST_WITH_BID_CAP]: 'maximum bid in each auction',
  },
}

/** Mapping for FB Optimization goals based on Objective. Allows default value */
export const SPECIFIC_OPTIMIZATION_GOALS = {
  [OBJECTIVES_MAP.BRAND_AWARENESS]: {
    goals: [OPTIMIZATION_GOAL.AD_RECALL_LIFT],
    default: OPTIMIZATION_GOAL.AD_RECALL_LIFT,
  },

  [OBJECTIVES_MAP.REACH]: {
    goals: [OPTIMIZATION_GOAL.REACH, OPTIMIZATION_GOAL.IMPRESSIONS],
    default: OPTIMIZATION_GOAL.REACH,
  },

  [OBJECTIVES_MAP.POST_ENGAGEMENT]: {
    goals: [
      OPTIMIZATION_GOAL.IMPRESSIONS,
      OPTIMIZATION_GOAL.POST_ENGAGEMENT,
      OPTIMIZATION_GOAL.DAILY_UNIQUE_REACH,
    ],
    default: OPTIMIZATION_GOAL.POST_ENGAGEMENT,
  },

  [OBJECTIVES_MAP.PAGE_LIKES]: {
    goals: [OPTIMIZATION_GOAL.PAGE_LIKES],
    default: OPTIMIZATION_GOAL.PAGE_LIKES,
  },

  [OBJECTIVES_MAP.EVENT_RESPONSES]: {
    goals: [
      OPTIMIZATION_GOAL.EVENT_RESPONSES,
      OPTIMIZATION_GOAL.IMPRESSIONS,
      OPTIMIZATION_GOAL.POST_ENGAGEMENT,
      OPTIMIZATION_GOAL.DAILY_UNIQUE_REACH,
    ],
    default: OPTIMIZATION_GOAL.EVENT_RESPONSES,
  },

  [OBJECTIVES_MAP.TRAFFIC]: {
    goals: {
      [ADSET_DESTINATION_TYPE.WEBSITE.value]: [
        OPTIMIZATION_GOAL.LANDING_PAGE_VIEWS,
        OPTIMIZATION_GOAL.LINK_CLICKS,
        OPTIMIZATION_GOAL.IMPRESSIONS,
        OPTIMIZATION_GOAL.DAILY_UNIQUE_REACH,
      ],
      [ADSET_DESTINATION_TYPE.APP.value]: [
        OPTIMIZATION_GOAL.LINK_CLICKS,
        OPTIMIZATION_GOAL.DAILY_UNIQUE_REACH,
      ],
      [ADSET_DESTINATION_TYPE.MESSENGER.value]: [
        OPTIMIZATION_GOAL.LINK_CLICKS,
        OPTIMIZATION_GOAL.IMPRESSIONS,
        OPTIMIZATION_GOAL.DAILY_UNIQUE_REACH,
      ],
      [ADSET_DESTINATION_TYPE.WHATSAPP.value]: [
        OPTIMIZATION_GOAL.LINK_CLICKS,
        OPTIMIZATION_GOAL.IMPRESSIONS,
        OPTIMIZATION_GOAL.DAILY_UNIQUE_REACH,
      ],
    },
    default: OPTIMIZATION_GOAL.LINK_CLICKS,
  },
  [OBJECTIVES_MAP.APP_INSTALLS]: {
    goals: [
      OPTIMIZATION_GOAL.APP_INSTALLS,
      OPTIMIZATION_GOAL.LINK_CLICKS,
      OPTIMIZATION_GOAL.APP_DOWNLOADS,
      OPTIMIZATION_GOAL.VALUE,
    ],
  },
  [OBJECTIVES_MAP.VIDEO_VIEWS]: {
    goals: [
      OPTIMIZATION_GOAL.THRUPLAY,
      OPTIMIZATION_GOAL.TWO_SECOND_CONTINUOUS_VIDEO_VIEWS,
    ],
    default: OPTIMIZATION_GOAL.THRUPLAY,
  },
  [OBJECTIVES_MAP.LEAD_GENERATION]: {
    goals: {
      [LEAD_METHODS.INSTANT_FORMS.value]: [
        OPTIMIZATION_GOAL.LEAD_GENERATION,
        OPTIMIZATION_GOAL.CONVERSION_LEADS,
      ],
      [LEAD_METHODS.AUTOMATED_CHAT.value]: [OPTIMIZATION_GOAL.LEAD_GENERATION],
      [LEAD_METHODS.CALLS.value]: [OPTIMIZATION_GOAL.CALLS],
    },
    default: OPTIMIZATION_GOAL.LEAD_GENERATION,
  },
  [OBJECTIVES_MAP.MESSAGES]: {
    goals: [
      OPTIMIZATION_GOAL.CONVERSATIONS,
      OPTIMIZATION_GOAL.LEAD_GENERATION,
      OPTIMIZATION_GOAL.LINK_CLICKS,
    ],
  },
  [OBJECTIVES_MAP.CONVERSIONS]: {
    goals: {
      [ADSET_DESTINATION_TYPE.WEBSITE.value]: [
        OPTIMIZATION_GOAL.CONVERSIONS,
        OPTIMIZATION_GOAL.VALUE,
        OPTIMIZATION_GOAL.LANDING_PAGE_VIEWS,
        OPTIMIZATION_GOAL.LINK_CLICKS,
        OPTIMIZATION_GOAL.IMPRESSIONS,
        OPTIMIZATION_GOAL.DAILY_UNIQUE_REACH,
      ],
      [ADSET_DESTINATION_TYPE.APP.value]: [
        OPTIMIZATION_GOAL.APP_EVENTS,
        OPTIMIZATION_GOAL.LINK_CLICKS,
        OPTIMIZATION_GOAL.DAILY_UNIQUE_REACH,
      ],
      [ADSET_DESTINATION_TYPE.MESSENGER.value]: [
        OPTIMIZATION_GOAL.CONVERSIONS,
        OPTIMIZATION_GOAL.CONVERSATIONS,
        OPTIMIZATION_GOAL.LINK_CLICKS,
        OPTIMIZATION_GOAL.IMPRESSIONS,
        OPTIMIZATION_GOAL.DAILY_UNIQUE_REACH,
      ],
      [ADSET_DESTINATION_TYPE.WHATSAPP.value]: [
        OPTIMIZATION_GOAL.CONVERSIONS,
        OPTIMIZATION_GOAL.LINK_CLICKS,
        OPTIMIZATION_GOAL.IMPRESSIONS,
        OPTIMIZATION_GOAL.DAILY_UNIQUE_REACH,
      ],
    },
  },
  [OBJECTIVES_MAP.CATALOG_SALES]: {
    goals: [
      OPTIMIZATION_GOAL.LINK_CLICKS,
      OPTIMIZATION_GOAL.CONVERSION_EVENTS,
      OPTIMIZATION_GOAL.VALUE,
      OPTIMIZATION_GOAL.IMPRESSIONS,
    ],
  },
  [OBJECTIVES_MAP.STORE_TRAFFIC]: {
    goals: [
      OPTIMIZATION_GOAL.DAILY_UNIQUE_REACH,
      OPTIMIZATION_GOAL.STORE_TRAFFIC,
    ],
    default: OPTIMIZATION_GOAL.DAILY_UNIQUE_REACH,
  },
}

export const FB_OPTIMIZATION_TO_BID_STRATEGY = {
  [OPTIMIZATION_GOAL.VALUE.value]: BID_STRATEGY_TYPE.LOWEST_COST_WITH_MIN_ROAS,
}

export const FACEBOOK_EVENT_TYPES = [
  'View Content',
  'Add to Cart',
  'Add to Wishlist',
  'Purchase',
  'Complete Registration',
  'Initiate Checkout',
  'Search',
  'Lead',
]

/**
 * Default cost control value when Campaign Budget Optimization is ON
 * and optimization is set to LOWEST_COST_WITH_BID_CAP (Bid Cap) or COST_CAP (Cost cap)
 */
export const DEFAULT_COST_CONTROL_CBO_BID = 2

export const EXTRA_COST_CONTROL_CBO = 5

export const COST_CONTROL_CONVERSION = 100
