import { ACTIONS } from './actions'
import { MAX_HISTORY_RECORDS, STORAGE_DATA_STORE_STATE } from './constants'

const localStorageJson = JSON.parse(
  localStorage[STORAGE_DATA_STORE_STATE] || null
)

/** Name of the reducer */
const name = 'dataStore'

/** Initial state of the reducer */
const initialState = {
  loading: false,
  tableLoading: false,
  rowsLoading: false,
  availableItems: [],
  rows: [],
  columns: [],
  unloadedItems: [],
  sortBy: [],
  dateFilter: {},
  dateFilterLoading: null,
  filters: [],
  history: {
    currentIndex: 0,
    snapshots: [{ rows: [], columns: [], sortBy: [], unloadedItems: [] }],
  },
  ...localStorageJson,
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.SET_LOADING]: (state, { loading }) => {
    return { ...state, loading }
  },
  [ACTIONS.SET_TABLE_LOADING]: (state, { loading }) => {
    return { ...state, tableLoading: loading }
  },
  [ACTIONS.SET_ROWS_LOADING]: (state, { loading }) => {
    return { ...state, rowsLoading: loading }
  },
  [ACTIONS.SET_ITEMS]: (state, { items }) => {
    return { ...state, availableItems: items }
  },
  [ACTIONS.SET_ROWS]: (state, { items }) => {
    localStorage.setItem(
      STORAGE_DATA_STORE_STATE,
      JSON.stringify({ rows: items, columns: state.columns })
    )
    return { ...state, rows: items }
  },
  [ACTIONS.SET_COLUMNS]: (state, { items }) => {
    localStorage.setItem(
      STORAGE_DATA_STORE_STATE,
      JSON.stringify({ rows: state.rows, columns: items })
    )
    return { ...state, columns: items }
  },
  [ACTIONS.ADD_ROW]: (state, { item }) => {
    const newState = {
      ...state,
      rows: [...state.rows, item],
      unloadedItems: [...state.unloadedItems, item],
    }
    const history = buildHistoryRecord(newState)
    localStorage.setItem(
      STORAGE_DATA_STORE_STATE,
      JSON.stringify({ rows: newState.rows, columns: state.columns })
    )
    return { ...newState, history }
  },
  [ACTIONS.ADD_COLUMN]: (state, { item }) => {
    const newState = {
      ...state,
      columns: [...state.columns, item],
      unloadedItems: [...state.unloadedItems, item],
    }
    const history = buildHistoryRecord(newState)
    localStorage.setItem(
      STORAGE_DATA_STORE_STATE,
      JSON.stringify({ rows: state.rows, columns: newState.columns })
    )
    return { ...newState, history }
  },
  [ACTIONS.REMOVE_ITEM]: (state, { item }) => {
    const { _id } = item
    const updatedRows = state.rows.filter((row) => row._id !== _id)
    const updatedColumns = state.columns.filter((column) => column._id !== _id)
    const unloadedItems = [...state.unloadedItems]
    const removedItemIndex = unloadedItems.findIndex(
      ({ _id: existingId }) => existingId === _id
    )
    if (removedItemIndex !== -1) {
      unloadedItems.splice(removedItemIndex, 1)
    } else {
      unloadedItems.push(item)
    }
    const newState = {
      ...state,
      rows: updatedRows,
      columns: updatedColumns,
      unloadedItems,
    }
    const history = buildHistoryRecord(newState)
    localStorage.setItem(
      STORAGE_DATA_STORE_STATE,
      JSON.stringify({ rows: updatedRows, columns: updatedColumns })
    )
    return { ...newState, history }
  },
  [ACTIONS.SET_SORT]: (state, { sortBy }) => {
    const newState = { ...state, sortBy }
    const history = buildHistoryRecord(newState)
    return { ...newState, history }
  },
  [ACTIONS.SET_DATE_FILTER]: (state, { dateFilter }) => {
    return { ...state, dateFilter }
  },
  [ACTIONS.SET_DATE_FILTER_LOADING]: (state, { loading }) => {
    return { ...state, dateFilterLoading: loading }
  },
  [ACTIONS.SET_FILTERS]: (state, { filters }) => {
    return { ...state, filters }
  },
  [ACTIONS.SET_UNLOADED_ITEMS]: (state, { items }) => {
    const updatedHistory = { ...state.history }
    const { currentIndex, snapshots } = updatedHistory
    snapshots[currentIndex].unloadedItems = items
    return {
      ...state,
      unloadedItems: items,
      history: {
        ...updatedHistory,
      },
    }
  },
  [ACTIONS.UNDO]: (state) => {
    const newIndex =
      state.history.currentIndex > 0 ? state.history.currentIndex - 1 : 0
    const snapshot = state.history.snapshots[newIndex] || initialState
    return {
      ...state,
      rows: snapshot.rows,
      columns: snapshot.columns,
      unloadedItems: snapshot.unloadedItems,
      sortBy: snapshot.sortBy,
      history: {
        ...state.history,
        currentIndex: newIndex,
      },
    }
  },
  [ACTIONS.REDO]: (state) => {
    const newIndex =
      state.history.currentIndex < MAX_HISTORY_RECORDS - 1
        ? state.history.currentIndex + 1
        : MAX_HISTORY_RECORDS - 1
    const snapshot = state.history.snapshots[newIndex]
    return {
      ...state,
      rows: snapshot.rows,
      columns: snapshot.columns,
      unloadedItems: snapshot.unloadedItems,
      sortBy: snapshot.sortBy,
      history: {
        ...state.history,
        currentIndex: newIndex,
      },
    }
  },
  [ACTIONS.RESET]: () => {
    return { ...initialState }
  },
  [ACTIONS.CLEAR_LOCAL_DATA_STORE_ITEMS]: () => {
    localStorage.removeItem(STORAGE_DATA_STORE_STATE)
  },
}

const buildHistoryRecord = (state) => {
  const newSnapshot = {
    rows: state.rows,
    columns: state.columns,
    sortBy: state.sortBy,
    unloadedItems: state.unloadedItems,
  }
  const newSnapshots = [...state.history.snapshots]

  if (newSnapshots.length >= MAX_HISTORY_RECORDS) {
    newSnapshots.shift()
  }
  if (state.history.currentIndex !== newSnapshots.length - 1) {
    newSnapshots.splice(state.history.currentIndex + 1)
  }
  newSnapshots.push(newSnapshot)

  const newIndex =
    state.history.currentIndex < MAX_HISTORY_RECORDS
      ? newSnapshots.length - 1
      : state.history.currentIndex

  return { currentIndex: newIndex, snapshots: newSnapshots }
}

export default { name, initialState, reduce }
