import React, { useState, useEffect } from 'react'
import { useStore } from 'store'
import { useNavigate, redirect } from 'react-router-dom'

import LoginRemember from 'components/login-remember'
import { getPrevLocation } from 'components/history'
import Button from 'components/button'
import { Dropdown } from 'components/dropdown'
import Input from 'components/input'
import AuthenticationContainer from 'components/authentication'

import { login2fa, sendUserOTP } from 'modules/users/actions'
import { setTempUserData } from 'modules/session/actions'
import { buildLoginRedirectUrl } from 'components/utils/url'

import 'styles/authentication.scss'

const multiFactorOptions = [
  { label: 'Google Authenticator', value: 'google' },
  { label: 'Text Message (SMS)', value: 'sms' },
  { label: 'Backup Code', value: 'backup' },
]

const MultiFactorAuthPage = () => {
  const navigate = useNavigate()
  const [multiFactorMethod, setMultiFactorMethod] = useState(
    multiFactorOptions[0].value
  )
  const [codeSection, setCodeSection] = useState(false)
  const [code, setCode] = useState(null)
  const [error, setError] = useState(null)
  const [submitError, setSubmitError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [remember, setRemember] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const { state, dispatch } = useStore()
  const { userData, isLoggedIn } = state.session

  useEffect(() => {
    if (!userData) {
      setTimeout(() => {
        return navigate(buildLoginRedirectUrl())
      }, 50)
    }
    if (isLoggedIn) {
      setTimeout(() => {
        return navigate(getPrevLocation() || '/')
      }, 50)
    }
  }, [userData, isLoggedIn])

  const sendOtp = () => {
    sendUserOTP(userData._id)
      .then(() => {
        setOtpSent(true)
      })
      .catch((error) => {
        setError(error || 'Something went wrong.')
      })
  }

  const handleSubmit = (ev) => {
    ev.preventDefault()
    setError(null)
    if (codeSection) {
      setLoading(true)
      login2fa(dispatch, userData, code, remember, multiFactorMethod)
        .then((response) => {
          if (multiFactorMethod === 'backup') {
            return setTimeout(() => {
              redirect('/my-account/security')
            }, 50)
          }

          if (response.hasToAcceptLegalTerms) {
            // If the user has a valid TFA, but needs to accept the terms of use,
            // we redirect him to the terms of use page.
            setTempUserData(response.user, response.user._id)
            return navigate(`/terms-of-use/${response.user._id}`)
          } else {
            setTimeout(() => {
              window.location = '/'
            }, 50)
          }
        })
        .catch((error) => {
          setSubmitError(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }
  const hiddenPhone = userData ? userData.phone.slice(-3).padStart(10, 'x') : ''
  const renderMessage = () => {
    if (codeSection) {
      if (multiFactorMethod === 'backup') {
        return `Enter your backup code below. If you lost your backup code, contact your administrator to reset multi-factor.`
      }
      switch (multiFactorMethod) {
        case 'google': {
          return `Please enter the 6-digit code generated in your Authenticator app.`
        }
        case 'sms': {
          return otpSent
            ? `A code has been sent to ${hiddenPhone}. If you did not
            receive a code, you can request a new code or select
            a different method below. `
            : `Sending Code`
        }
      }
    }
    return `We need to confirm your identity before you can continue. Select the method below.`
  }

  const renderTitle = () => {
    if (codeSection) {
      return multiFactorMethod === 'backup' ? 'Backup Code' : 'Enter Your Code'
    }
    return `2FA`
  }

  const renderLinks = () => {
    return (
      <div className="authentication-container__links">
        {multiFactorMethod === 'sms' && (
          <>
            <span
              className="fake-link"
              onClick={() => {
                sendOtp()
              }}
            >
              {` Resend Code `}
            </span>
            <span className="vertical-line">|</span>
          </>
        )}
        {multiFactorMethod !== 'backup' && (
          <>
            <span
              className="fake-link"
              onClick={() => {
                setMultiFactorMethod('backup')
              }}
            >
              Use Backup Code
            </span>
            <span className="vertical-line">|</span>
          </>
        )}
        <span
          className="fake-link"
          onClick={() => {
            setCodeSection(false)
            setOtpSent(false)
            setCode(null)
            setSubmitError(null)
          }}
        >
          Use Another Method
        </span>
      </div>
    )
  }
  const renderInput = () => {
    if (codeSection) {
      return multiFactorMethod === 'backup' ? (
        <Input
          type="text"
          autoFocus={true}
          value={code}
          onChange={setCode}
          maxLength={20}
          onEnterKeyPressed={handleSubmit}
          label="Backup Authentication Code"
          placeholder={'Enter Backup Authentication Code'}
          autoComplete="one-time-code"
          className={'input-wrapper--uppercase'}
        />
      ) : (
        <Input
          type="number"
          autoFocus={true}
          value={code}
          onChange={setCode}
          maxLength={6}
          onEnterKeyPressed={handleSubmit}
          label="Authentication Code"
          placeholder={'Enter Authentication Code'}
          autoComplete="one-time-code"
          className={'input-wrapper--uppercase'}
        />
      )
    }
    return (
      <Dropdown
        options={multiFactorOptions}
        defaultState={multiFactorMethod}
        onChange={(method) => {
          setMultiFactorMethod(method)
        }}
        label="Multi-Factor Authentication Method"
        className="input-wrapper--uppercase"
      />
    )
  }
  return (
    <AuthenticationContainer
      title={renderTitle()}
      onSubmit={handleSubmit}
      error={submitError}
    >
      <div className="authentication-container__description">
        {renderMessage()}
      </div>
      {renderInput()}

      {error && <div className="error">{error}</div>}
      {codeSection && renderLinks()}
      {codeSection && (
        <LoginRemember remember={remember} setRemember={setRemember} />
      )}
      <div className="authentication-container__cta align-row">
        <Button
          className="authentication-container__button button--big"
          value="Next"
          type="submit"
          disabled={loading || (codeSection && !code)}
          onClick={(ev) => {
            if (multiFactorMethod) {
              setCodeSection(true)
              if (multiFactorMethod === 'sms' && !otpSent) {
                sendOtp()
              }
            }
            handleSubmit(ev)
          }}
        />
      </div>
    </AuthenticationContainer>
  )
}

export default MultiFactorAuthPage
