import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/* Constants */
import { utils } from '@decision-sciences/qontrol-common'
import scssConstants from 'styles/shared.module.scss'

/* Styles */
import './style.scss'

const { CSS_UNIT } = utils.css

/**
 * A more convenient way to render a divider between two elements
 * @param {Object} params
 * @param {number} params.height - The height value of the divider
 * @param {CSS_UNIT | string} params.heightUnit - The css unit that we want to be used for height (default is PX)
 * @param {number} params.width - The width value of the divider
 * @param {CSS_UNIT | string} params.widthUnit - The css unit that we want to be used for width (default is PX)
 * @param {string} params.backgroundColor - The background color that we want to pe applied to the divider
 *
 * Example of usage:
 *
 * height: 100
 * heightUnit: 'px'
 * width: 100
 * widthUnit: '%'
 * backgroundColor: '#000000'
 */
const LineDivider = ({
  height,
  heightUnit,
  width,
  widthUnit,
  backgroundColor,
  className,
  exactWidth,
  margin,
}) => {
  return (
    <div
      data-testid="line-divider"
      className={cx('line-divider', className)}
      style={{
        height: `${height}${heightUnit}`,
        width: exactWidth || `${width}${widthUnit}`,
        backgroundColor: `${backgroundColor}`,
        margin,
      }}
    />
  )
}

LineDivider.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  heightUnit: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  widthUnit: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  exactWidth: PropTypes.string,
  margin: PropTypes.string,
}

LineDivider.defaultProps = {
  height: 1,
  heightUnit: CSS_UNIT.PX,
  width: 100,
  widthUnit: CSS_UNIT.PERCENTAGE,
  backgroundColor: scssConstants['colorFlowBorders'],
  className: '',
  exactWidth: null,
  margin: null,
}

export default LineDivider
