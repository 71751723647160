import { useState } from 'react'

import { useLoading, useDebouncedEffect } from 'components/utils/custom-hooks'

import { alert } from '@decision-sciences/qontrol-common'
const { ALERT_ELEMENT_TO_FIELD_NAME } = alert

/**
 * The amount of time that passes before any calls to the back-end are made.
 * Eg. A campaign is selected, the call for Ad Groups will be made only after ALERT_DEBOUNCE ms without selecting anything else.
 * This is to ensure that we don't need to fetch after each and every state change.
 */
const ALERT_DEBOUNCE = 1000

export function getTemplateExpression(allTemplates, template) {
  return (allTemplates.find((el) => el._id === template) || {}).expression || ''
}

/**
 * Hook for handling local to persisted data on Single Client Apply To Sections
 * @param {String} PLATFORM Platform constant (facebook, google, etc)
 * @param {Object} state Default state for localState, usually data from the database
 * @param {Function} onChange Callback for changing / persisting data
 * @param {Function} onChangeErrors Callback for changing (usually clearing) errors
 * @param {Object} validDataPersistenceConfig Configuration for different elements' requirements of correct data peristing.
 * Has structure of { [PLATFORM]: (changes, state) => { ... Do stuff with changes and state, return changes }}
 * Is used to intercept changes and add changes if needed.
 * @returns {Object}
 * localState {Object}: state to be used locally, updated instantly
 * setLocalState {Function}: setter for local state
 * toggleLoading {Function}: toggle a loading item
 * getIsLoading {Function}: get whether an item is loading or not
 * onCheck {Function}: called on checking an item under an element
 * onCheckAll {Function}: called on checking ALL on an item under an element
 */
export const useSingleClientApplyTo = (
  PLATFORM,
  state,
  onChange,
  onChangeErrors,
  validDataPersistenceConfig
) => {
  // Keep a local state to delay updating the main state immediately
  const [localState, setLocalState] = useState(state)

  const [isLoading, toggleLoading, getIsLoading] = useLoading(false)

  // Account change callback
  const onChangeAccounts = ({ accounts, allAccountsSelected }) => {
    onChangeErrors({ selectedAccounts: null })
    if (allAccountsSelected) {
      return onChange({ ...state, accounts, allAccountsSelected })
    }

    const changes = { ...state, accounts, allAccountsSelected }

    Object.keys(validDataPersistenceConfig).forEach((key) => {
      if (state[key]) {
        changes[key] = validDataPersistenceConfig[key](state[key], changes, key)
      }
    })

    onChange(changes)
  }

  const elementConfig = ALERT_ELEMENT_TO_FIELD_NAME[PLATFORM]
  // Ensure that calls don't get triggered on each and every state change
  useDebouncedEffect(
    () => {
      setLocalState(state)
    },
    ALERT_DEBOUNCE,
    [state]
  )

  const onCheckAll = (TYPE) => (allSelected) => {
    onChange({
      ...state,
      [TYPE]: {
        ...state[TYPE],
        [elementConfig[TYPE]]: [],
        allSelected,
      },
    })
    onChangeErrors({ [elementConfig[TYPE]]: null })
  }

  const onCheck = (TYPE, pendingChanges) => {
    const changes = validDataPersistenceConfig?.[TYPE]
      ? validDataPersistenceConfig[TYPE](pendingChanges, state, TYPE)
      : null

    onChange({
      ...state,
      [TYPE]: {
        ...state[TYPE],
        ...(changes || pendingChanges),
      },
    })
    onChangeErrors({ [elementConfig[TYPE]]: null })
  }

  return {
    localState,
    setLocalState,
    toggleLoading,
    getIsLoading,
    onCheck,
    onCheckAll,
    onChangeAccounts,
  }
}
