import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import Logo from 'components/logo'
import LegalViewList from 'modules/legal/components/legal-view-list'
import Notifications from 'modules/notifications/index'

import { legal } from '@decision-sciences/qontrol-common'

import '../../styles/authentication.scss'

const { LEGAL_DISPLAY_AREA } = legal

/**
 * Authentication Container to be used on all login / signup sections
 * @param {Object} params React Params
 * @param {String} params.title Heading to be displayed next to logo (also sets helmet)
 * @param {Function} params.onSubmit To be called on form submit
 * @param {String} params.error Error text to be displayed underneath section
 * @param {Node} params.children React Children
 */
const AuthenticationContainer = ({ title, onSubmit, error, children }) => {
  return (
    <>
      <Notifications />
      <section className="authentication-container">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div className="authentication-container__wrapper">
          <form
            data-testid="authentication-form"
            className="authentication-container__form"
            onSubmit={onSubmit}
          >
            <div className="authentication-container__header">
              <Logo />
              <div className="authentication-container__title">{title}</div>
            </div>
            {children}
            {error && (
              <div className="authentication-container__submit-error">
                {error}
              </div>
            )}
          </form>
        </div>
        <LegalViewList displayArea={LEGAL_DISPLAY_AREA.LOGIN} />
      </section>
    </>
  )
}

AuthenticationContainer.propTypes = {
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  children: PropTypes.node,
}

export default AuthenticationContainer
