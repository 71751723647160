import React, { useState } from 'react'
import cx from 'classnames'

import Api from 'easy-fetch-api'

import Input from 'components/input/index'
import { Dropdown } from 'components/dropdown/index'
import Button from 'components/button/index'
import Loader from 'components/loader/index'
import Section from 'components/section/index'
import InputWithConfirmation from 'components/input-with-confirmation/index'

const APImethods = {
  GET: {
    value: 'GET',
    label: 'GET',
    method: (payload) => Api.get(payload),
  },
  // POST: {
  //   value: 'POST',
  //   label: 'POST',
  //   method: (payload) => Api.post(payload),
  // },
}

const APIexamples = [
  {
    label: 'GET Alerts',
    value: '/api/alerts',
    method: APImethods.GET,
    query: {
      size: 0,
      skip: 0,
    },
  },
  {
    label: 'GET Alert',
    value: '/api/alerts/id/123',
    method: APImethods.GET,
  },
  {
    label: 'GET Alert Filters',
    value: '/api/alerts/filters',
    method: APImethods.GET,
  },
  {
    label: 'GET Alert Notification Groups',
    value: '/api/alerts/notification-groups',
    method: APImethods.GET,
    query: {
      filterData: {},
      company: '123',
    },
  },
  {
    label: 'GET Alert Names',
    value: '/api/alerts/names',
    method: APImethods.GET,
    query: {
      filterData: {},
      company: '123',
    },
  },
  {
    label: 'GET Company by client ID',
    value: '/api/companies/123',
    method: APImethods.GET,
  },
  {
    label: 'GET Companies With Filter Options',
    value: '/api/companies/companies-with-filter-options',
    method: APImethods.GET,
    query: {
      newFilters: {},
      field: '',
    },
  },
  {
    label: 'GET Companies With User',
    value: '/api/companies/user',
    method: APImethods.GET,
    query: {
      userId: '123',
    },
  },
]

export const RouteTester = () => {
  const [url, setUrl] = useState('')
  const [method, setMethod] = useState(APImethods.GET.value)
  const [response, setResponse] = useState(null)
  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState({})
  const [newQueryParam, setNewQueryParam] = useState('')

  const fetchData = () => {
    setLoading(true)
    setResponse(null)
    setIsError(false)
    const APIcall = APImethods[method].method

    APIcall({ url, query })
      .then((response) => {
        if (response.success === false) {
          setIsError(true)
        }
        setResponse(JSON.stringify(response))
      })
      .catch((error) => {
        setResponse(JSON.stringify(error))
        setIsError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getFormattedQuery = () => {
    return `?${Object.entries(query)
      .map(([key, value]) => `${key}=${JSON.stringify(value)}`)
      .join('&')}`
  }

  const addParamDisabled =
    !newQueryParam || typeof query[newQueryParam.trim()] !== 'undefined'

  const onAddParam = () => {
    if (addParamDisabled) {
      return
    }

    setNewQueryParam(() => '')
    setQuery((query) => ({ ...query, [newQueryParam.trim()]: '' }))
  }

  return (
    <Section header="Route Tester" isExpandable defaultExpanded={false}>
      <Dropdown
        defaultOptionText="Select an Example"
        label="Examples"
        onChange={(example) => {
          const { value, method, query } = APIexamples.find(
            ({ value }) => value === example
          )
          setUrl(value)
          setMethod(method.value)
          setQuery(query || {})
        }}
        labelTop
        options={APIexamples}
      />
      <div className="display-flex align-items-row gap-8 full-width">
        <div style={{ width: '100%' }}>
          <Dropdown
            label="Method"
            labelTop
            options={Object.values(APImethods)}
            onChange={setMethod}
            defaultState={method}
          />
          <Input
            value={url}
            onChange={setUrl}
            label="URL"
            onEnterKeyPressed={fetchData}
            placeholder={APIexamples[0].value}
          />
          <Input
            value={getFormattedQuery()}
            disabled
            label="Query"
            placeholder={'Query Params appear here'}
          />
        </div>

        <Button value={APImethods[method].label} onClick={fetchData} />
      </div>
      <div className="general-label general-label--no-bottom-margin">
        {Object.entries(query).map(([key, value]) => {
          const onConfirm = (newValue) => {
            try {
              const parsed = JSON.parse(newValue)
              setQuery((query) => ({ ...query, [key]: parsed }))

              setResponse(null)
              setIsError(false)
            } catch (error) {
              setResponse(error.toString())
              setIsError(true)
            }
          }

          const onRemove = () => {
            const newQuery = { ...query }
            delete newQuery[key]
            setQuery(newQuery)
          }
          return (
            <div
              key={key}
              className="display-flex gap-8 align-items-center margin-bottom-8"
            >
              <div style={{ width: '100px' }} className="general-label">
                {key}
              </div>
              <InputWithConfirmation
                label={key}
                labelTop
                defaultValue={JSON.stringify(value)}
                onConfirm={onConfirm}
                onCancel={onRemove}
              />
            </div>
          )
        })}

        <div className="display-flex gap-8">
          <Input
            value={newQueryParam}
            onChange={setNewQueryParam}
            label="Add New Query Param"
            onEnterKeyPressed={onAddParam}
          />
          <Button
            value="Add"
            disabled={addParamDisabled}
            onClick={onAddParam}
          />
        </div>
      </div>

      <div className="general-label general-label--no-bottom-margin">
        Response
      </div>
      <div
        className={cx('form__section__body max-height', {
          'form__section__body--error': isError,
        })}
      >
        {loading ? (
          <Loader inline />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: response }}></div>
        )}
      </div>
    </Section>
  )
}
