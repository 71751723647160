import React from 'react'
import PropTypes from 'prop-types'

/* Icons */
import logoImage from 'assets/3Q.svg'

const Logo = ({ click }) => (
  <img
    data-testid="logo-image"
    src={logoImage}
    className="three-q-logo"
    alt={'3q logo'}
    onClick={() => click && click()}
  />
)

Logo.propTypes = {
  click: PropTypes.func,
}

export default Logo
