import { ACTIONS } from './actions'
import { DEFAULT_DIMENSION } from './constants'

/** Name of the reducer */
const name = 'dataStoreGlobal'

/** Initial state of the reducer */
const initialState = {
  categories: null,
  dimensions: null,
  editedCategory: null,
  editedDimension: null,
  selectedDimensions: [],
  bulkEditActive: false,
  dimensionsFilters: {},
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.SET_CATEGORIES]: (state, { categories }) => {
    return { ...state, categories }
  },
  [ACTIONS.SET_DIMENSIONS]: (state, { dimensions }) => {
    return { ...state, dimensions }
  },
  [ACTIONS.SET_CATEGORY]: (state, { category }) => {
    return { ...state, editedCategory: category }
  },
  [ACTIONS.EDIT_CATEGORY]: (state, { category }) => {
    return {
      ...state,
      editedCategory: { ...state.editedCategory, ...category },
    }
  },
  [ACTIONS.SET_DIMENSION]: (state, { dimension }) => {
    return { ...state, editedDimension: dimension }
  },
  [ACTIONS.EDIT_DIMENSION]: (state, { dimension }) => {
    return {
      ...state,
      editedDimension: { ...state.editedDimension, ...dimension },
    }
  },
  [ACTIONS.BULK_EDIT]: (state, { bulkEditActive }) => {
    return {
      ...state,
      bulkEditActive,
      editedDimension: bulkEditActive ? { ...DEFAULT_DIMENSION } : null,
    }
  },
  [ACTIONS.SET_SELECTED_DIMENSIONS]: (state, { selectedDimensions }) => {
    return {
      ...state,
      selectedDimensions,
    }
  },
  [ACTIONS.SET_DIMENSIONS_FILTERS]: (state, { filters }) => {
    return {
      ...state,
      dimensionsFilters: filters,
    }
  },
  [ACTIONS.RESET]: () => {
    return { ...initialState }
  },
}

export default { name, initialState, reduce }
