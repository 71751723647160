import React from 'react'
import { flow } from '@decision-sciences/qontrol-common'

const { STEPS_MAP } = flow

export const FORMULA_TOOLTIP = (
  <>
    <div className="tooltip-title">Available logical operators</div>
    <div>{'Add (+), Subtract (-), Divide (/), Multiply (*)'}</div>
    <div>
      {'Greater Than (>, >=), Less Than (<, <=), Equals (==), Differs (!=)'}
    </div>
    <div>{'Logical operators (and, or, not)'}</div>
    <div>{'Group with brackets ( ... )'}</div>
  </>
)

export const ALERT_MESSAGE_TOOLTIP = (
  <>
    <div className="tooltip-title">How to use:</div>
    <div>
      {'When you wish to tie in a formula, just open a curly bracket: { }'}
    </div>
    <div>{'Then you can use Control + Space to view available operators'}</div>
    <div>
      {
        'Please note that only variables within curly brackets get evaluated and anything outside of curly brackets gets shown as it is.'
      }
    </div>
    <div>
      {'{[variable_name]} gets evaluated; [variable_name] gets shown as it is'}
    </div>
    <div className="tooltip-title">Available operators</div>
    <div>{'Add (+), Subtract (-), Divide (/), Multiply (*)'}</div>
    <div>{'Group with brackets ( ... )'}</div>
  </>
)

export const FEATURE_NOT_IMPLEMENTED_TOOLTIP = 'On Roadmap'
export const FEATURE_UNSUPPORTED = 'Currently unsupported via API'
export const FEATURE_NOT_AVAILABLE_CAMPAIGN_CREATION =
  'Not available upon campaign creation'

export const VIDEO_MESSAGE_TOOLTIP = (
  <>
    {
      'Video files must end in .mov or .mp4. The URL needs to be public or shareable.'
    }
  </>
)

export const RESERVED_CAMPAIGN_TOOLTIP = {
  [STEPS_MAP.CAMPAIGNS.idx]: 'Reach & Frequency Campaign',
  [STEPS_MAP.AD_CONTENT.idx]: 'Associated with a Reach & Frequency Campaign',
  [STEPS_MAP.ADS.idx]: 'Associated with a Reach & Frequency Campaign',
  [STEPS_MAP.AD_SETS.idx]: 'Associated with a Reach & Frequency Campaign',
}

export const PRIMARY_COMPARISON_PERIOD_TOOLTIP = (
  <>
    <div>{'The formula needs to be filled out and valid before'}</div>
    <div>{'the Current and Compare fields are available'}</div>
  </>
)

export const ALERT_CONTAINER_LABEL_TOOLTIP = (
  <>
    <b>Account:</b> Alerts will be siloed within each individual account. <br />
    <b>Client / BU:</b> Alerts will use data across multiple accounts within the
    Client / BU
  </>
)

/**
 * Function to get the tooltip list content.
 * Mainly used for table cells with multiple values.
 * @param {String} title Title that appears at the top of the tooltip list
 * @param {Array} list List of strings to display.
 * @param {Number} limit Limit of items to show; default: 5
 */
export const getTooltipList = (title, list, limit = 5) => {
  if (!list || !list.length) {
    return null
  }
  const items = []
  for (let i = 0; i < Math.min(list.length, limit); i++) {
    items.push(
      <div className="tooltip-item" key={i}>
        {list[i]}
      </div>
    )
  }
  if (list.length > limit) {
    items.push(
      <div key="more" className="tooltip-more">
        {`...and ${list.length - limit} more`}
      </div>
    )
  }
  return (
    <div>
      <div>
        <div className="tooltip-title">{title}</div>
      </div>
      {items}
    </div>
  )
}
