import { format } from 'date-fns'

export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy'
export const DEFAULT_TIME_FORMAT = 'hh:mm a'

/**
 * Function to clone a given date
 * @param {Date | string} date
 * @returns {Date} Cloned date
 */
export const cloneDate = (date) => {
  if (typeof date === 'string') {
    return new Date(date)
  } else {
    return new Date(date.valueOf())
  }
}

/**
 * Returns the formatted date and time pair
 * @param {Date} dateAndTime Date object
 * @param {string} [dateFormat] Optional format for date
 * @param {string} [timeFormat] Optional format for time
 * @returns {{date: string, time: string}}
 */
export const getFormattedDateAndTimePair = (
  dateAndTime,
  dateFormat,
  timeFormat
) => ({
  date: format(dateAndTime, dateFormat || DEFAULT_DATE_FORMAT),
  time: format(dateAndTime, timeFormat || DEFAULT_TIME_FORMAT),
})

/**
 * Function which returns tomorrow's date either from today or from a given date
 * @param {Date | string} [fromDate] Optional date to compute "tomorrow" from
 * @returns {Date}
 */
export const getTomorrowDate = (fromDate = new Date()) => {
  const tomorrow = cloneDate(fromDate)
  tomorrow.setDate(tomorrow.getDate() + 1)
  return tomorrow
}
