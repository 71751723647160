import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import { useIntermediaryStates } from 'hooks/intermediary-states'
import Button from 'components/button/index'
import LineDivider from 'components/line-divider/index'
import Icon from 'components/icon/index'
import { ReactComponent as WarningIconRed } from 'assets/icon_warning_round_red.svg'
import { ReactComponent as CheckIcon } from 'assets/icon_check_black.svg'
import { ReactComponent as CloseIcon } from 'assets/icon_close_small.svg'
import { ReactComponent as ViewIcon } from 'assets/icon_password_eye.svg'
import { ReactComponent as BlockIcon } from 'assets/icon_block.svg'

import ScssConstants from '../../styles/shared.module.scss'

const RestoreNotification = ({
  visible = false,
  restoreInfo = {},
  onRestore,
  onCancel,
  onArchivedFilter,
  onClose,
}) => {
  const notificationsContainer = document.getElementById(
    'notifications-container'
  )

  const { getLoadingFor, setLoadingFor } = useIntermediaryStates()

  const handleRestore = () => {
    setLoadingFor(ELEMENTS.RESTORE, true)
    onRestore().finally(() => setLoadingFor(ELEMENTS.RESTORE, false))
  }

  const handleSeeArchived = () => {
    setLoadingFor(ELEMENTS.SEE_ARCHIVED, true)
    onArchivedFilter().finally(() =>
      setLoadingFor(ELEMENTS.SEE_ARCHIVED, false)
    )
  }

  if (!notificationsContainer || !visible || !restoreInfo) {
    return null
  }

  const {
    existingUser: { name, email },
  } = restoreInfo

  return createPortal(
    <div className="user-restore-notification">
      <div className="user-restore-notification__icon">
        <Icon>
          <WarningIconRed />
        </Icon>
      </div>
      <LineDivider
        height={'100'}
        width={1}
        widthUnit="px"
        backgroundColor={ScssConstants.red}
      />
      <div className="user-restore-notification__content general-description">
        <div>User already exists with the entered email:</div>
        <b>
          {name} - {email}
        </b>
        <div>Would you like to restore this user?</div>
        <div className="user-restore-notification__actions">
          <Button
            compact
            secondaryRed
            disabled={getLoadingFor(ELEMENTS.RESTORE)}
            onClick={handleRestore}
            value={
              <Icon>
                <CheckIcon className="svg--red" /> Yes
              </Icon>
            }
          />
          <Button
            compact
            secondaryRed
            onClick={onCancel}
            value={
              <Icon>
                <BlockIcon width={14} height={14} /> No
              </Icon>
            }
          />
          <Button
            compact
            secondaryRed
            disabled={getLoadingFor(ELEMENTS.SEE_ARCHIVED)}
            onClick={handleSeeArchived}
            value={
              <Icon>
                <ViewIcon width={16} height={16} /> See all archived users
              </Icon>
            }
          />
        </div>
      </div>
      <Icon onClick={onClose} className="margin-left-22">
        <CloseIcon width={14} height={14} className="svg--red" />
      </Icon>
    </div>,
    notificationsContainer
  )
}

const ELEMENTS = {
  RESTORE: 'RESTORE',
  SEE_ARCHIVED: 'SEE_ARCHIVED',
}

RestoreNotification.propTypes = {
  visible: PropTypes.bool,
  restoreInfo: PropTypes.object,
  onRestore: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onArchivedFilter: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export { RestoreNotification }
