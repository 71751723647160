/* React */
import React, { useState, useEffect, useMemo } from 'react'
import {
  useNavigate,
  redirect,
  useOutletContext,
  useParams,
} from 'react-router-dom'
import PropTypes from 'prop-types'

/* Validators */
import { FIELD_TYPES, validate } from 'components/validator'

/* Components */
import EditCompanySubsections from 'modules/companies/edit-company-subsections'
import ClientForm from 'modules/companies/client-form'
import ClientView from 'modules/companies/client-view'
import StickyFooter from 'components/sticky-footer'
import Button from 'components/button'
import Modal from 'components/modal'
import SaveConventionModal from 'modules/naming-conventions/components/save-convention-modal'
import SaveOfflineDataModal from 'modules/offline-data/components/save-convention-modal'

/* Hooks */
import { useAccess, PERMISSION_TYPES, PERMISSIONS } from 'hooks/access'
import { useFetchKpis } from 'modules/companies/subsections/kpi-section/use-fetch-kpi'
import { useSocket } from 'components/utils/socket'

/* Utils */
import {
  checkForChanges,
  virtualToNamingConvention,
} from 'modules/naming-conventions/utils'
import { TABLE_CONTAINER } from 'modules/table-filter-sort/constants'

/* Store & Actions */
import { useStore } from 'store'
import {
  checkExistingClientId,
  checkExistingName,
  createUpdateCompany,
  deleteCurrentCompany,
  getCompanies,
  getCompaniesWithFilterOptions,
  resetCachedData,
  restoreClient,
} from 'modules/companies/actions'
import { getPossibleApprovers, getUsers } from 'modules/users/actions'
import { shouldDisplayOfflineDataDifferenceModal } from 'modules/offline-data/utils'
import {
  setCustomConversionsForAccounts,
  saveGoogleMccId,
} from 'modules/accounts/actions'
import {
  showErrorMessage,
  showSuccessMessage,
} from 'modules/notifications/actions'
import { saveFilter } from 'modules/table-filter-sort/actions'

/* Styles & local imports */
import {
  timezoneCountry,
  utils,
  accounts,
  socket,
} from '@decision-sciences/qontrol-common'
import { validateDataBeforeConfirm, getErrors } from './utils'
import { getFiscalErrors } from './subsections/reporting/utils'

import './style.scss'

const { COUNTRY_LABELS } = timezoneCountry
const { isEmpty } = utils.object
const { ACCOUNT_TYPES_MAP } = accounts
const { NOTIFICATIONS, CACHING_DONE_FOR } = socket

const CreateEditCompany = ({ companyId }) => {
  const { clientId } = useParams()
  const tableContainer = TABLE_CONTAINER.CLIENT_MANAGEMENT
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({ contact: {} })
  const [openModal, setOpenModal] = useState(false)
  const [allowedApprovers, setAllowedApprovers] = useState([])
  const [namingConventionsModal, setNamingConventionsModal] = useState(false)
  const [offlineDataModal, setOfflineDataModal] = useState(false)
  const [resetCacheDisabled, setResetCacheDisabled] = useState(true)

  const { dispatch, state } = useStore()
  const { currentCompany } = state.companies
  const { superAdmins } = state.users
  const { userData } = state.session
  const { updatedGoogleMccId } = state.accounts
  const filterSort = state.tableFilterSort.filterSort[tableContainer]

  const navigate = useNavigate()

  /* Default State */
  const {
    company,
    setCompany,
    pendingChanges,
    defaultCompany,
    reset,
    isViewMode,
    dirty,
    setDirty,
    LeaveConfirmModal,
    businessUnitData,
    usersData,
  } = useOutletContext()

  const socket = useSocket({ room: company._id, roomNeeded: true })

  useEffect(() => {
    if (socket?.connected) {
      socket.on(NOTIFICATIONS.cachingJobInfo.receive, (data) => {
        const { jobInProgress } = data[CACHING_DONE_FOR.NAMING_CONVENTIONS]
        setResetCacheDisabled(jobInProgress)
      })
      socket.emit(NOTIFICATIONS.cachingJobInfo.receive, {
        clientId: company._id,
      })
    }

    return () =>
      socket?.removeAllListeners(NOTIFICATIONS.cachingJobInfo.receive)
  }, [socket?.connected])

  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.CLIENT_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.CLIENT_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasViewAccess = useAccess({
    feature: PERMISSIONS.CLIENT_DATA_FORM,
    type: PERMISSION_TYPES.READ,
  })
  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.CLIENT_DATA_FORM,
    type: PERMISSION_TYPES.DELETE,
  })
  const hasReportingEditAccess = useAccess({
    feature: PERMISSIONS.REPORTS,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasAlertsEditAccess = useAccess({
    feature: PERMISSIONS.ASSIGNED_ALERTS,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasAlertThresholdsEditAccess = useAccess({
    feature: PERMISSIONS.ALERT_THRESHOLDS,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasAccountsEditAccess = useAccess({
    feature: PERMISSIONS.ACCOUNT_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasAssignUsersAccess = useAccess({
    feature: PERMISSIONS.ASSIGN_USERS_TEAMS_CLIENT,
    type: PERMISSION_TYPES.EDIT,
  })

  const hasNamingConventionsEditAccess = useAccess({
    feature: PERMISSIONS.NAMING_CONVENTIONS,
    type: PERMISSION_TYPES.EDIT,
  })

  const viewMode = useMemo(() => {
    if (company.deleted) {
      return true
    }
    const isNew = companyId === 'new'
    if (isNew) {
      // Both need to be checked because edit does not require create, they are independent
      if (hasCreateAccess || hasEditAccess) {
        return false
      }
    }
    return !hasEditAccess && hasViewAccess
  }, [companyId, company, hasEditAccess, hasCreateAccess, hasViewAccess])

  const companyAccounts = useMemo(
    () => company.accounts.filter((account) => account.company === company._id),
    [company]
  )

  const [kpis, loadingKpis] = useFetchKpis({
    viewMode,
    accounts: companyAccounts,
  })

  /** Persist kpis in store after they are fetched */
  useEffect(() => {
    setCustomConversionsForAccounts(dispatch, kpis)
  }, [JSON.stringify(kpis)])

  /** On mount check for valid permissions */
  useEffect(() => {
    const isNew = companyId === 'new'
    if ((isNew && !hasCreateAccess) || (!isNew && !hasViewAccess)) {
      redirect('/unauthorized')
    }
    if (!allowedApprovers.length) {
      getPossibleApprovers(company._id).then(
        (res) => res.list && setAllowedApprovers(res.list)
      )
    }
  }, [])

  // Reset cached data for all accounts linked to company( Fb pages and apps, dimensions, budget segments etc.)
  const resetData = () => {
    resetCachedData(dispatch, company)
  }

  /**
   * Handle showing modals before saving logic here:
   */
  const checkForModals = () => {
    if (
      checkForChanges(
        company.namingConventions,
        virtualToNamingConvention(defaultCompany.namingConventions)
      )
    ) {
      setNamingConventionsModal(true)
      return
    }

    if (
      shouldDisplayOfflineDataDifferenceModal(
        company.offlineData,
        defaultCompany.offlineData
      )
    ) {
      // Do not display modal unless offline data is valid
      const [isValidOfflineData, offlineDataErrors] =
        validateDataBeforeConfirm(company)
      if (isValidOfflineData) {
        setOfflineDataModal(true)
        return
      } else {
        return setErrors({ ...errors, offlineData: offlineDataErrors })
      }
    }

    onSave(errors)
  }

  /**
   * Applies final mapping or updates on the payload sent to the API via {@link onSave}
   * @returns {Object}
   */
  const getSavePayload = () => {
    if (company.new) {
      return company
    }

    const parsedPendingChanges = structuredClone(pendingChanges)

    /**
     * This was a bypass to not trigger `onSave` when updating business units KPIs from client level
     * Instead of saving updates on the `businessUnits` field, as it should, it saves updates on `businessUnitKPIs`
     * Before the save, this ensures that all data is sent correctly and on the corresponding fields
     * See KpiSection > handleKpiChange
     */
    if (pendingChanges.businessUnitKPIs) {
      delete parsedPendingChanges.businessUnitKPIs
      parsedPendingChanges.businessUnits = pendingChanges.businessUnitKPIs
    }
    return parsedPendingChanges
  }

  /** Submit handler */
  const onSave = async (errorsState) => {
    if (Object.keys(errorsState?.fiscalErrors || {}).length) {
      return
    }

    // Validate
    setLoading(true)
    let [isValid, errors] = validate(ERROR_MAP, company)

    const { _id, name, clientId } = company

    const nameExists = await checkExistingName({
      _id: _id,
      name: name,
    })

    if (nameExists) {
      isValid = false
      errors.name = 'Client name already exists'
    } else {
      errors.name ??= null
    }

    const clientIdExists = await checkExistingClientId({ _id, clientId })
    if (clientIdExists) {
      isValid = false
      errors.clientId = 'Client Id already exists.'
    } else {
      errors.clientId ??= null
    }

    if (company.tableauConfig) {
      if (company.tableauConfig.vertical) {
        if (!company.tableauConfig.reporting) {
          isValid = false
          errors = Object.assign(errors || {}, {
            tableauConfig: {
              reporting: 'required',
            },
          })
        }
      }

      const [isValidFiscalPeriod, fiscalErrors] = getFiscalErrors(
        company.tableauConfig
      )

      if (!isValidFiscalPeriod) {
        isValid = false
        errors = Object.assign(errors || {}, {
          tableauConfig: {
            fiscalErrors,
          },
        })
      }
    }

    if (!isValid) {
      setLoading(false)
      return setErrors(errors)
    }

    if (company.country !== COUNTRY_LABELS.US) {
      /* If the selected country is not US, we want to clean the state value (the field will also be hidden) */
      company.state = ''
    }

    setDirty(false)

    const { businessUnits: businessUnitsChanges, ...companyChanges } =
      getSavePayload()
    await createUpdateCompany(dispatch, companyChanges, currentCompany._id, {
      businessUnits: {
        ...businessUnitData.pendingChanges,
        ...businessUnitsChanges,
      },
      users: usersData.pendingClientUsers,
    })
      .then((res) => {
        if (res.error) {
          setDirty(true)
          return setErrors({ ...errors, general: res.error })
        } else {
          setErrors({ ...errors, general: null })
        }
        // Save mccId
        const googleAccountsPendingChanges = pendingChanges.accounts?.some(
          ({ changed, type }) => changed && type === ACCOUNT_TYPES_MAP.GOOGLE
        )
        if (googleAccountsPendingChanges && !isEmpty(updatedGoogleMccId)) {
          saveGoogleMccId(dispatch, clientId, updatedGoogleMccId)
        }
        if (res.company) {
          // Allow fields like users to remain as they are so that we don't have to reinitialize them
          reset({
            ...company,
            ...res.company,
            new: false,
          })

          businessUnitData.reset(businessUnitData.businessUnits)
          usersData.resetClientUsers({})
          // Refetch users
          getUsers(dispatch)
          setDirty(false)
        }

        if (
          companyId !== res.company._id ||
          clientId !== res.company.clientId
        ) {
          if (hasEditAccess) {
            navigate(`/company/${res.company.clientId}`, {
              state: { BYPASS_LEAVE_CONFIRM: true },
            })
          } else {
            navigate(`/client-management`, {
              state: { BYPASS_LEAVE_CONFIRM: true },
            })
          }
        }
      })
      .catch((err) => {
        const newErrors = getErrors(err)
        setErrors({ ...errors, ...newErrors })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const deleteCompany = () => {
    setLoading(true)
    deleteCurrentCompany(dispatch, clientId)
      .then((res) => {
        setLoading(false)

        if (res.error) {
          return setErrors({ ...errors, general: res.error })
        }
      })
      .finally(() => {
        setDirty(false)
        navigate(`/client-management`, {
          state: { BYPASS_LEAVE_CONFIRM: true },
        })
      })
      .catch((err) => {
        setLoading(false)
        setErrors({ ...errors, general: err })
      })
  }

  const restoreCompany = () => {
    setLoading(true)
    restoreClient(company.clientId)
      .then(() => {
        showSuccessMessage('Client restored successfully', dispatch)
        // Must re-fetch whole background stored company list so it contains the newly restored company
        getCompanies(dispatch, { deleted: false }).then(() => {
          // Must re-fetch visible company filters which dictate the list of visible companies in the table
          getCompaniesWithFilterOptions(filterSort.filter).then((res) => {
            const { options, companyIds } = res
            saveFilter(dispatch, tableContainer, {
              ...filterSort.filters,
              options,
              companyIds,
            })
            // Force refresh because states are not updated properly when :companyId changes
            navigate(0)
          })
        })
      })
      .catch((err) => {
        showErrorMessage(err, dispatch)
        setLoading(false)
      })
  }

  /**
   * Check if save button should be displayed in the sticky footer
   * @returns {Boolean}
   */
  const renderSaveClientCondition = () => {
    let condition = hasCreateAccess
    if (company._id && company.clientId !== 'new' && !company.deleted) {
      condition =
        hasEditAccess ||
        hasAccountsEditAccess ||
        hasAssignUsersAccess ||
        hasAlertsEditAccess ||
        hasReportingEditAccess ||
        hasAlertThresholdsEditAccess ||
        hasNamingConventionsEditAccess
    } else {
      if (company.deleted) {
        condition = !company.deleted
      }
    }
    return condition
  }

  /* Save the page if there are pending changers on certain sub-pages */
  useEffect(() => {
    if (!pendingChanges) {
      return
    }
    const thereArePendingAccountsOnClient = pendingChanges.accounts?.some(
      ({ changed }) => changed
    )

    // Check for Create/Edit BU
    const thereAreBuChanges = !!Object.keys(businessUnitData.pendingChanges)
      .length

    // reportings
    const thereArePendingReportings = pendingChanges.reportings?.length

    if (
      thereArePendingAccountsOnClient ||
      thereAreBuChanges ||
      thereArePendingReportings
    ) {
      checkForModals()
    }
  }, [
    JSON.stringify(pendingChanges?.accounts),
    JSON.stringify(pendingChanges?.reportings),
    JSON.stringify(businessUnitData?.pendingChanges),
  ])

  return (
    <div className="add-edit-companies">
      {dirty && <LeaveConfirmModal />}
      {openModal && (
        <Modal
          contentSeparator
          rightAlignButtons
          opened={openModal}
          button={
            <Button
              green
              value="Archive"
              disabled={loading || isViewMode}
              onClick={deleteCompany}
            />
          }
          buttonSecondary={
            <Button
              value="Cancel"
              disabled={loading || isViewMode}
              onClick={() => setOpenModal(false)}
              secondaryGray
            />
          }
          heading="Archive Client"
          className="alert-categories__modal"
        >
          <p>Please confirm you want to archive this client:</p>
          <b>{company.name}</b>
          <p>Note: an archived client and its data can be restored.</p>
        </Modal>
      )}
      {namingConventionsModal && (
        <SaveConventionModal
          open={namingConventionsModal}
          onConfirm={() => {
            onSave()
            setNamingConventionsModal(false)
          }}
          onCancel={() => {
            setCompany({ namingConventions: null })
            setTimeout(() => {
              setNamingConventionsModal(false)
              onSave()
            })
          }}
        />
      )}
      {offlineDataModal && (
        <SaveOfflineDataModal
          open={offlineDataModal}
          onConfirm={() => {
            onSave()
            setOfflineDataModal(false)
          }}
          onCancel={() => {
            setTimeout(() => {
              setOfflineDataModal(false)
            })
          }}
        />
      )}
      {viewMode ? (
        <ClientView
          originalCompany={company}
          superAdmins={superAdmins}
          accounts={company.accounts}
        />
      ) : (
        <ClientForm
          originalCompany={company}
          pendingChanges={pendingChanges}
          setCompany={(changes) => {
            setCompany({
              new: company.new,
              ...changes,
            })
            setDirty(true)
          }}
          setDirty={setDirty}
          setErrors={setErrors}
          kpis={kpis}
          loadingKpis={loadingKpis}
          errors={errors}
          loading={loading}
          allowedApprovers={allowedApprovers}
          superAdmins={superAdmins}
          accounts={companyAccounts}
        />
      )}

      {company && !loading && (
        <EditCompanySubsections
          history={history}
          editing={!!company._id}
          loading={loading}
          clientErrors={errors}
          setClientErrors={setErrors}
          kpis={kpis}
          loadingKpis={loadingKpis}
        />
      )}

      <StickyFooter
        buttons={[
          {
            value: 'Reset Cached Data',
            onClick: resetData,
            disabled: loading || isViewMode || resetCacheDisabled,
            tooltip: resetCacheDisabled && (
              <div>
                <div>There are caching jobs in progress for this client</div>
                <div>
                  If you think this might be inaccurate, please refresh the page
                </div>
              </div>
            ),
            renderCondition:
              !company.new && userData.isSuperAdmin && !company.deleted,
          },
          {
            value: company._id && !company.new ? 'Save Changes' : 'Save Client',
            onClick: checkForModals,
            disabled: loading || isViewMode,
            renderCondition: renderSaveClientCondition(),
          },
          {
            value: 'Restore',
            disabled: loading || !company.deleted,
            renderCondition:
              company._id &&
              userData.isSuperAdmin &&
              hasEditAccess &&
              company.deleted,
            onClick: restoreCompany,
          },
          {
            value: 'Cancel',
            onClick: () => {
              navigate('/client-management')
            },
            disabled: loading,
            secondaryGray: true,
          },
          {
            value: 'Archive',
            onClick: () => setOpenModal(true),
            disabled: loading || company.active || isViewMode,
            type: 'secondaryRed',
            renderCondition:
              company._id &&
              !company.deleted &&
              userData.isSuperAdmin &&
              currentCompany._id !== company._id &&
              hasDeleteAccess &&
              !company.new,
          },
        ]}
      />
    </div>
  )
}

CreateEditCompany.propTypes = {
  companyId: PropTypes.string,
}

const ERROR_MAP = {
  clientId: [FIELD_TYPES.REQUIRED, FIELD_TYPES.CLIENT_ID],
  name: FIELD_TYPES.REQUIRED,
  'contact.email': [FIELD_TYPES.EMAIL, FIELD_TYPES.REQUIRED],
  'contact.phone': [FIELD_TYPES.PHONE, FIELD_TYPES.REQUIRED],
  'contact.name': FIELD_TYPES.REQUIRED,
  active: [FIELD_TYPES.BOOLEAN, FIELD_TYPES.REQUIRED],
  defaultApprover: FIELD_TYPES.REQUIRED,
}

export default CreateEditCompany
