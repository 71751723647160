import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { format } from 'date-fns'

import { useStore } from 'store'

import { updateDevice, getBackupCode } from 'modules/users/actions'

import Button from 'components/button'
import Table from 'components/table/beta'
import Modal from 'components/modal'
import Spacer from 'components/spacer'

import deleteIcon from 'assets/icon_clear_blue.svg'

import Avatar from './user-avatar'
import ResetAuthenticator from './reset-authenticator'
import ResetPhoneAuth from './reset-phone-auth'
import ChangePasswordModalContent from './change-password-modal-content'

const MyAccountSecurity = () => {
  const location = useLocation()
  const { state } = useStore()
  const { userData } = state.session
  const { dispatch } = useStore()
  const navigate = useNavigate()

  /* Show password changing modal */
  const [showModal, setShowModal] = useState(false)

  const [showMultiFactorSection, setShowMultiFactorSection] = useState(false)
  const [showResetPhoneAuth, setShowResetPhoneAuth] = useState(false)
  const [backupCode, setBackupCode] = useState()
  const [backupCodeLoading, setBackupCodeLoading] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)

  /**
   * Function to fetch a backup code
   */
  const resetBackupCode = () => {
    setBackupCodeLoading(true)
    getBackupCode(dispatch, userData)
      .then(setBackupCode)
      .finally(() => {
        setBackupCodeLoading(false)
      })
  }

  useEffect(() => {
    if (location?.state?.forceChangePassword) {
      setShowModal(true)
    }
  }, [location])

  useEffect(() => {
    if (!userData.backupCode) {
      resetBackupCode()
    }
  }, [userData.backupCode])

  if (showMultiFactorSection) {
    return <ResetAuthenticator close={() => setShowMultiFactorSection(false)} />
  }

  if (showResetPhoneAuth) {
    return <ResetPhoneAuth close={() => setShowResetPhoneAuth(false)} />
  }

  return (
    <>
      <Helmet>
        <title>Account Security</title>
      </Helmet>
      {itemToDelete && (
        <Modal
          opened={!!itemToDelete}
          button={
            <Button
              value="Delete"
              onClick={() => {
                updateDevice(dispatch, { _id: itemToDelete, deleted: true })
                setItemToDelete(null)
              }}
            />
          }
          buttonSecondary={
            <Button
              value={'Cancel'}
              onClick={() => setItemToDelete(null)}
              secondaryGray
            />
          }
          heading={`Are you sure you want to delete this device?`}
          className="alert-categories__modal"
        />
      )}
      {showModal && (
        // Todo Ionut, check this
        <Modal opened={showModal}>
          <ChangePasswordModalContent
            close={() => setShowModal(false)}
            unClosable={location?.state?.forceChangePassword}
            afterSave={() => {
              const newHistoryState = { ...location?.state }

              /** After updating the password, remove the state of the page so that the modal doesn't show up again on refresh */
              if (location?.state?.forceChangePassword) {
                const lastLocation = location?.state?.lastLocation

                const url = lastLocation?.pathName
                navigate(url || '/', {
                  state: {
                    ...newHistoryState,
                    forceChangePassword: false,
                  },
                  replace: true,
                })
              }
            }}
          />
        </Modal>
      )}
      {backupCode && (
        <Modal
          opened={!!backupCode}
          button={
            <Button
              value="Ok"
              onClick={() => {
                setBackupCode(null)
              }}
            />
          }
          heading="Backup Code"
        >
          <div>Your backup code is {backupCode}. Please store it safely.</div>
        </Modal>
      )}
      <div className="align-column">
        <Avatar editable={true} />
        <div className="align-row">
          <div className="my-account-user__password-expiry align-row">
            <div className="align-column">
              {/* Password Last Updated */}
              <span className="general-label">Password Last Updated</span>
              <span>
                {userData.passwordUpdated
                  ? format(new Date(userData.passwordUpdated), 'MM/dd/yyyy')
                  : 'N/A'}
              </span>
            </div>

            {/* Passsword expires */}
            <div className="align-column">
              <span className="general-label">Passsword expires</span>
              <span>
                {userData.passwordUpdated
                  ? format(new Date(userData.passwordExpires), 'MM/dd/yyyy')
                  : 'N/A'}
              </span>
            </div>
          </div>
        </div>
        <div className="align-row">
          <Button value="Change Password" onClick={() => setShowModal(true)} />
          <Button
            loading={backupCodeLoading}
            value="Generate New Backup Code"
            onClick={() => {
              resetBackupCode()
            }}
          />
        </div>

        <Spacer />
        <div className="align-row">
          <div className="heading" data-cy="page-heading">
            Devices
          </div>

          <div className="my-account-user__auth-buttons align-row">
            <Button
              value="Reset Authenticator"
              onClick={() => setShowMultiFactorSection(true)}
              secondary={true}
            />
            <Button
              value="Reset Phone Authentication"
              onClick={() => setShowResetPhoneAuth(true)}
              secondary={true}
            />
          </div>
        </div>

        {_renderDeviceTable(userData, setItemToDelete)}
      </div>
    </>
  )
}

/** Helper for rendering devices for the user */
const _renderDeviceTable = (userData, setItemToDelete) => {
  const columns = [
    {
      header: '',
      id: 'actions',
      cell: (cell) => (
        <div className="table__actions">
          <div onClick={() => setItemToDelete(cell.row.original._id)}>
            <img src={deleteIcon} alt={'delete'} />
          </div>
        </div>
      ),
      accessorFn: (row) => (row.active ? 1 : 0),
      size: 50,
      maxSize: 100,
    },
    {
      header: 'Device Name',
      accessorFn: (row) =>
        row.device === 'Other' ? `${row.browser} on ${row.os}` : row.device,
    },
    {
      header: 'Last Activity',
      accessorFn: (row) =>
        row.lastActivity
          ? format(new Date(row.lastActivity), 'MM/dd/yyy | HH:mm aaa')
          : 'N/A',
    },
    {
      header: 'Expiration Date',
      accessorFn: (row) =>
        row.expires
          ? format(new Date(row.expires), 'MM/dd/yyy | HH:mm aaa')
          : 'N/A',
    },
    {
      header: 'Authorized',
      accessorFn: (row) => (row.isActive ? 'Yes' : 'No'),
    },
  ]

  return <Table columns={columns} data={userData.devices} />
}

export default MyAccountSecurity
