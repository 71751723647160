import { isValidEmail, isValidPhoneNumber } from 'components/validator/index'
import { csv } from '@decision-sciences/qontrol-common'

const { CSV_ARRAY_DELIMITER } = csv

export const CSV_HEADER_COLUMNS = {
  FIRST_NAME: {
    value: 'firstName',
    label: 'First Name',
  },
  LAST_NAME: { value: 'lastName', label: 'Last Name' },
  EMAIL: { value: 'email', label: 'Email' },
  MOBILE_PHONE: { value: 'phone', label: 'Mobile Phone' },
  CLIENT_ID: {
    value: 'clientID',
    label: 'Client',
  },
  BUSINESS_UNIT: {
    value: 'businessUnit',
    label: 'Business Unit',
  },
  ACCOUNT: {
    value: 'account',
    label: 'Account',
  },
  TEAM: { value: 'team', label: 'Team' },
  JOB_TITLE: { value: 'jobTitle', label: 'Job Title' },
  PERMISSION_GROUP: { value: 'permissionGroup', label: 'Permission Group' },
  TIMEZONE: {
    value: 'timeZone',
    label: 'Timezone',
  },
  STATUS: { value: 'active', label: 'Status' },
}

export const CSV_ROW_COLUMNS = {
  ...CSV_HEADER_COLUMNS,
  REMOVE: { value: 'remove', label: '' },
}

export const WIDE_COLUMNS = [
  CSV_HEADER_COLUMNS.EMAIL.value,
  CSV_HEADER_COLUMNS.CLIENT_ID.value,
  CSV_HEADER_COLUMNS.BUSINESS_UNIT.value,
  CSV_HEADER_COLUMNS.ACCOUNT.value,
  CSV_HEADER_COLUMNS.TEAM.value,
  CSV_HEADER_COLUMNS.TIMEZONE.value,
  CSV_HEADER_COLUMNS.PERMISSION_GROUP.value,
]

export const COLUMNS = Object.values(CSV_ROW_COLUMNS).map((col) => col.value)

export const DROPDOWN_COLUMNS = COLUMNS.filter(
  (col) =>
    ![
      CSV_HEADER_COLUMNS.FIRST_NAME.value,
      CSV_HEADER_COLUMNS.LAST_NAME.value,
      CSV_HEADER_COLUMNS.EMAIL.value,
      CSV_HEADER_COLUMNS.MOBILE_PHONE.value,
      CSV_HEADER_COLUMNS.JOB_TITLE.value,
      CSV_ROW_COLUMNS.REMOVE.value,
    ].includes(col)
)

export const SINGLE_SELECT_DROPDOWNS = [
  CSV_HEADER_COLUMNS.TIMEZONE.value,
  CSV_HEADER_COLUMNS.PERMISSION_GROUP.value,
  CSV_HEADER_COLUMNS.STATUS.value,
]

export const REQUIRED_FIELDS = [
  CSV_HEADER_COLUMNS.FIRST_NAME.value,
  CSV_HEADER_COLUMNS.LAST_NAME.value,
  CSV_HEADER_COLUMNS.EMAIL.value,
  CSV_HEADER_COLUMNS.MOBILE_PHONE.value,
  CSV_HEADER_COLUMNS.CLIENT_ID.value,
  CSV_HEADER_COLUMNS.PERMISSION_GROUP.value,
  CSV_HEADER_COLUMNS.TIMEZONE.value,
  CSV_HEADER_COLUMNS.STATUS.value,
]

export const getArray = (string) => string.split(CSV_ARRAY_DELIMITER)

export const OPTIONAL_FIELDS = Object.values(CSV_HEADER_COLUMNS)
  .map((col) => col.value)
  .filter((col) => !REQUIRED_FIELDS.includes(col))

export const validateColumnFormatting = (user, field, users) => {
  switch (field) {
    case CSV_ROW_COLUMNS.EMAIL.value:
      const emailAlreadyExists = users.some(
        (us) => us.id !== user.id && us[field] === user[field]
      )
      return emailAlreadyExists ? false : isValidEmail(user[field])
    case CSV_ROW_COLUMNS.MOBILE_PHONE.value:
      return isValidPhoneNumber(user[field])
    case CSV_ROW_COLUMNS.CLIENT_ID.value:
    case CSV_ROW_COLUMNS.BUSINESS_UNIT.value:
    case CSV_ROW_COLUMNS.ACCOUNT.value:
    case CSV_ROW_COLUMNS.TEAM.value:
      return !user[field]
        ? true
        : !!user[field].split(CSV_ARRAY_DELIMITER).length
    default:
      return true
  }
}

export const deleteUnwantedFields = (user) => {
  delete user.team
  delete user.clientID
  delete user.account
  delete user.businessUnit
  delete user.id
  return user
}

