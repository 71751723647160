import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getPrevLocation } from 'components/history'
import { buildLoginRedirectUrl } from 'components/utils/url'

// SVG
import { ReactComponent as ErasedCover } from 'assets/erased-cover.svg'
import { ReactComponent as PandaImg } from 'assets/panda.svg'
import { ReactComponent as OrangeFourImg } from 'assets/orange-4.svg'
import { ReactComponent as BlueFourImg } from 'assets/blue-4.svg'
import { ReactComponent as DonutImg } from 'assets/donut.svg'
import { ReactComponent as SlothImg } from 'assets/sloth.svg'
import { ReactComponent as CatImg } from 'assets/cat.svg'
import { ReactComponent as EllipseImg } from 'assets/ellipse71.svg'
import { ReactComponent as NotFoundImg } from 'assets/not-found.svg'
import useSession from '../modules/session'

const Page404 = () => {
  const [isLoggedIn] = useSession()
  const [randomPage, setRandomPage] = useState(null)
  const navigate = useNavigate()

  const goBack = () => {
    const prevLocation = getPrevLocation()
    if (prevLocation) {
      navigate(prevLocation || -1)
    } else {
      // check location state for invalid id
      if (history.state && history.length > 2) {
        navigate(-2)
      } else {
        navigate('/')
      }
    }
  }

  // Get a random number between 1 and 5
  useEffect(() => {
    setRandomPage(Math.floor(Math.random() * 5) + 1)
  }, [])

  if (!isLoggedIn) {
    setTimeout(() => {
      navigate(buildLoginRedirectUrl())
    }, 50)
    return null
  }

  const renderContent = () => {
    switch (randomPage) {
      case 1: {
        return notFoundTemplate()
      }
      case 2: {
        return donutTemplate(<OrangeFourImg />, <DonutImg />)
      }
      case 3: {
        return donutTemplate(<BlueFourImg />, <SlothImg />)
      }
      case 4: {
        return catTemplate()
      }
      case 5: {
        return pandaTemplate()
      }
      default: {
        return null
      }
    }
  }

  const notFoundTemplate = () => {
    return (
      <div className="align-column content-height">
        <div className="title title__font title__font-100 half">Whoops!</div>
        <div className="info-text margin-top-10 half">
          <span>
            Seems like the page you are looking for is not where it is supposed
            to be.
          </span>
          {goBackTemplate()}
        </div>
        <div className="align-row align-right">
          <NotFoundImg className="img-rigth-bottom" />
        </div>
      </div>
    )
  }

  const donutTemplate = (fourImg, donut) => {
    return (
      <div className="align-column align-center margin-top-30">
        <div className="align-row margin-bottom-22 ">
          {fourImg}
          {donut}
          {fourImg}
        </div>
        <div className="align-column align-center info-text half">
          <span>The page you were looking for&nbsp;𝙙𝙤𝙣𝙪𝙩&nbsp;exist.</span>
          <span>Sorry about that (the error and the pun).</span>
        </div>
        {goBackTemplate()}
      </div>
    )
  }

  const catTemplate = () => {
    return (
      <div className="align-column align-center margin-top-30">
        <div className="align-row margin-bottom-22 ">
          <BlueFourImg />
          <div className="img-container">
            <EllipseImg />
            <CatImg className="img-left-0" />
          </div>
          <BlueFourImg />
        </div>
        <div className="title title__font-90">Oops!</div>
        <div className="align-column align-center info-text half">
          <span>Sorry, you didn’t find the page you were looking</span>
          <span>for, but just look at what you did find! Awww..</span>
        </div>
        {goBackTemplate()}
      </div>
    )
  }

  const pandaTemplate = () => {
    return (
      <div className="align-column align-center">
        <div className="img-container">
          <ErasedCover />
          <PandaImg className="img-left-75" />
        </div>
        <div className="title title__font title__font-100">Whoops!</div>
        <div className="align-center info-text margin-top-10">
          Looks like that page does not exist.
        </div>
        {goBackTemplate()}
      </div>
    )
  }

  const goBackTemplate = () => {
    return (
      <div className="info-text margin-top-10">
        You can<a onClick={goBack}>Go back</a> or
        <Link to={'/'}>Go to the Homepage</Link>.
      </div>
    )
  }

  return renderContent()
}

export default Page404
