import DeleteModal from 'components/delete-modal/index'
import Loader from 'components/loader'
import useSession from 'modules/session'
import { TABLE_CONTAINER } from 'modules/table-filter-sort/constants'
import TeamManagementModule from 'modules/teams'
import { deleteTeam, getTeams } from 'modules/teams/actions'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useStore } from 'store'

/**
 * Entry point for team index page - shows a filterable table with all available teams
 * @returns {React.FunctionComponent}
 */
const TeamsIndexPage = () => {
  const tableContainer = TABLE_CONTAINER.TEAM_MANAGEMENT

  const { teamId } = useParams()

  const [, user] = useSession()

  const { dispatch, state } = useStore()
  const { list } = state.teams
  const filter = state.tableFilterSort.filterSort[tableContainer].filter
  const { currentCompany } = state.companies

  const [teamToDelete, setTeamToDelete] = useState(null)
  const [loading, setLoading] = useState(false)

  const companyId = currentCompany?._id

  const fetchTeamsList = useCallback(() => {
    setLoading(true)
    getTeams(dispatch, companyId, filter).finally(() => setLoading(false))
  }, [companyId, filter])

  useEffect(() => {
    fetchTeamsList()
  }, [fetchTeamsList])

  const onDeleteTeam = (team) => {
    setLoading(true)

    deleteTeam(dispatch, teamId || team.key, companyId, filter)
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
        setTeamToDelete(null)
      })
  }

  if (!list) {
    return <Loader />
  }

  return (
    <React.Fragment>
      <DeleteModal
        toDelete={teamToDelete}
        setOpen={setTeamToDelete}
        onDelete={() => onDeleteTeam(teamToDelete)}
        loading={loading}
        className="teams__modal"
      />
      <TeamManagementModule
        isAdmin={user.isSuperAdmin}
        onDelete={setTeamToDelete}
        tableContainer={tableContainer}
        loading={loading}
      />
    </React.Fragment>
  )
}

export default TeamsIndexPage
