import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

/* Components */
import Table from 'components/table/beta'
import { getTooltipList } from 'components/utils/tooltip'
import { DEFAULT_DATE_FORMAT } from 'components/utils/date'

/* Store & Actions */
import { useStore } from 'store'

/* Utils */
import { format } from 'date-fns'
import { utils, entityStatus } from '@decision-sciences/qontrol-common'

/* Styles */
import '../style.scss'

const { compareIgnoreCase } = utils.string
const { ENTITY_STATUS_LABEL } = entityStatus

/**
 * Component user to show and manage the users assigned to a team
 * @param {Object} props Component props
 * @param {Boolean} props.teamHasUsers Flag to conditionally render the table
 * @param {Array} [props.users = []] List of team members
 * @param {Function} props.setUsersToEdit Callback when user rows are selected
 * @param {Boolean} [props.hasEditAccess] Flag signaling if the logged in user has edit permissions
 * @returns {React.FunctionComponent}
 */
const TeamUsersTable = ({
  teamHasUsers,
  users = [],
  setUsersToEdit,
  hasEditAccess,
}) => {
  const { state } = useStore()
  const {
    companies: { list: companies },
  } = state

  const columns = [
    {
      header: ' ',
      id: 'edit',
      cell: (cell) => {
        const { _id } = cell.row.original
        if (!hasEditAccess) {
          return null
        }
        return (
          <div className="table__actions align-center">
            <Link className="table__edit" to={`/users/${_id}`} />
          </div>
        )
      },
      size: 30,
      maxSize: 60,
    },
    {
      header: 'Name',
      accessorFn: (row) => row.name,
      id: 'name',
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 100,
    },
    {
      header: 'Email',
      id: 'email',
      accessorFn: (row) => row.email,
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 200,
    },
    {
      header: 'Client',
      id: 'client',
      accessorFn: (row) => {
        if (!row?.clients?.length) {
          return 'No Client'
        }

        if (row?.clients?.length === 1) {
          return companies?.find(
            (company) => company._id === row?.clients?.[0]?.clientId
          )?.name
        }

        return 'Multiple'
      },
      tooltip: (row) => {
        if (!row || row.isSuperAdmin) {
          return null
        }
        const list = row.clients
          .filter((c) =>
            companies?.some(
              (company) => company._id.toString() === c.clientId.toString()
            )
          )
          .map((c) => c.clientId)

        if (list.length <= 1) {
          return null
        }
        const userCompanies = list
          .map((id) => {
            return companies && (companies.find((c) => c._id === id) || {}).name
          })
          .sort(compareIgnoreCase)
        return getTooltipList('Companies', userCompanies)
      },
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 150,
    },
    {
      header: 'Last Login',
      id: 'lastLogin',
      accessorFn: (row) =>
        row.lastLogin
          ? format(new Date(row.lastLogin), DEFAULT_DATE_FORMAT)
          : ' - ',
      size: 100,
      sortType: 'date',
    },
    {
      header: 'Status',
      id: 'status',
      cell: (cell) => {
        const { active } = cell.row.original
        return <span>{ENTITY_STATUS_LABEL[active]}</span>
      },
      accessorFn: (row) => (row.active ? 1 : 0),
      size: 60,
      maxSize: 130,
    },
  ]

  if (!teamHasUsers) {
    // Display an empty table for UX purposes.
    return <Table columns={columns} data={[]} />
  }

  return (
    <Table
      columns={columns}
      data={users}
      paginationValues={[10, 50, 100]}
      onSelect={hasEditAccess ? setUsersToEdit : null}
      height={350}
      showPagination={true}
      showSearchInput={true}
    />
  )
}

TeamUsersTable.propTypes = {
  teamHasUsers: PropTypes.bool.isRequired,
  users: PropTypes.array,
  setUsersToEdit: PropTypes.func.isRequired,
  hasEditAccess: PropTypes.bool,
}

export default TeamUsersTable
