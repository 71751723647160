import { useEffect, useMemo, useState, useRef } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

/* Components */
import Button from 'components/button'
import { CheckboxNoHooks } from 'components/checkbox'
import { Dropdown } from 'components/dropdown'
import InputText from 'components/input'
import StickyFooter from 'components/sticky-footer'
import { FIELD_TYPES, validate } from 'components/validator'
import AssignUserModal from 'modules/companies/assign-user-modal'
import TeamUsersTable from 'modules/teams/components/team-users-table'
import {
  AlertNotificationSection,
  validateAlertNotificationChanges,
} from 'modules/teams/components/alert-notifications'
import CollapsibleSection from 'components/collapsible-section/index'

/* Store & Actions */
import {
  createUpdateTeam,
  getTeamAlertNotifications,
  getUnlinkedReports,
} from 'modules/teams/actions'
import { useStore } from 'store'
import { showErrorMessage } from 'modules/notifications/actions'
import { fetchAlertsForTeam, fetchAlertsForUsers } from 'modules/alerts/actions'

/* Constants */
import { TEAM_DEFAULT } from 'modules/teams/constants'
import TeamReports from 'modules/teams/components/team-reports'

/* Hooks */
import { usePendingState } from 'components/utils/custom-hooks'
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'
import useSession from 'modules/session'

/* Utils */
import {
  entityStatus,
  platform,
  utils,
} from '@decision-sciences/qontrol-common'
import uniqBy from 'lodash.uniqby'

/* Assets */
import { ReactComponent as AddIcon } from 'assets/icon_plus_blue.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon_minus_red.svg'

/* Styles */
import './style.scss'

const { stringToID, compareIgnoreCase } = utils.string
const { TEAM_TYPES } = platform
const { equalIds } = utils.object
const { keyBy } = utils.array
const { ENTITY_STATUS_LABEL, ENTITY_STATUS_OPTIONS } = entityStatus

/**
 * Component used to create or edit a team
 * @param {Object} props Component props
 * @param {Boolean} props.isAdmin Flag specifying if the current user is an admin
 * @param {String} props.companyId ObjectId of the current company
 * @param {Function} props.onDelete Callback used when the delete action is triggered
 * @param {Boolean} [props.loading] Flag to disable team form when an action is in progress
 * @param {Function} props.setLoading Callback to toggle loading state
 * @param {Function} props.setDirty Callback to enable leave confirmation modal
 * @returns {React.FunctionComponent}
 */
const CreateEditTeam = ({
  isAdmin,
  companyId,
  onDelete,
  loading,
  setLoading,
  setDirty,
}) => {
  const [, user] = useSession()
  const { dispatch, state } = useStore()
  const {
    teams: { team: currentTeam },
    companies: { currentCompany },
  } = state
  const navigate = useNavigate()

  const membersRef = useRef()
  const notificationsRef = useRef()
  const reportsRef = useRef()

  /** State for the team */
  const [team, setTeam, pendingChanges] = usePendingState(currentTeam, {
    ...TEAM_DEFAULT,
    isGlobal: currentTeam?.isGlobal || !!user.isSuperAdmin,
  })
  const [teamAlerts, setTeamAlerts] = useState(null)
  const [alertNotificationAlerts, setAlertNotificationAlerts] = useState(null)

  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.TEAM_DATA_FORM,
    type: PERMISSION_TYPES.DELETE,
  })

  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.TEAM_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })

  const hasEditAccess = useAccess({
    feature: PERMISSIONS.TEAM_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })

  const hasUserAssignAccess = useAccess({
    feature: PERMISSIONS.ASSIGN_TEAMS_USER,
    type: PERMISSION_TYPES.CREATE,
  })

  const hasUserEditAccess = useAccess({
    feature: PERMISSIONS.ASSIGN_TEAMS_USER,
    type: PERMISSION_TYPES.EDIT,
  })

  const hasUserReadAccess = useAccess({
    feature: PERMISSIONS.USERS_INDEX,
    type: PERMISSION_TYPES.READ,
  })

  const hasAlertEditAccess = useAccess({
    feature: PERMISSIONS.ALERT_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })

  const hasAlertReadAccess = useAccess({
    feature: PERMISSIONS.ALERTS_INDEX,
    type: PERMISSION_TYPES.READ,
  })

  // Helper Flags
  const isCompanySpecificTeam = !team.isGlobal

  const isCreate = useMemo(() => !team._id, [team._id])

  // Filter users based on team company (if team is not global)
  const allUsers = useMemo(() => {
    if (isCompanySpecificTeam) {
      const teamCompanyId = team?.companies?.[0]?._id || currentCompany._id

      return state.users.list.filter((user) =>
        user.clients.find((el) => el.clientId === teamCompanyId)
      )
    }
    return state.users.list
  }, [state.users.list, isCompanySpecificTeam])

  const allUsersMap = useMemo(() => keyBy(allUsers, '_id'), [allUsers])

  // Handle user filtering effects
  // Eg. Global Team is unchecked and the number of available users is reduced
  useEffect(() => {
    // Automatically mark all unavailable users as removed
    const removedUsers = displayedUsers.filter((user) => !allUsersMap[user._id])
    setPendingUsers({
      ...pendingUsers,
      removed: uniqBy([...pendingUsers.removed, ...removedUsers], '_id'), // Make sure we generate no duplicates
    })

    // Remove team lead if not available anymore.
    if (team.teamLead && !allUsersMap[team.teamLead._id]) {
      setTeam({ teamLead: null })
    }
  }, [allUsersMap])

  const selectedUsers = allUsers.filter((user) =>
    user.teams.find((el) => el.key === team.key)
  )

  useEffect(() => {
    if (isCreate) {
      getUnlinkedReports().then(({ reports, unlinkedReports }) => {
        setTeam({ ...team, reports, unlinkedReports })
      })
    }
  }, [isCreate])

  const [showLinkReportsView, setShowLinkReportsView] = useState(false)
  const [errors, setErrors] = useState({})

  // Users
  const [assignUserModal, setAssignUsersModal] = useState(false)
  // Added or deleted users
  const [pendingUsers, setPendingUsers] = useState({ added: [], removed: [] })
  // Checked users
  const [selectedUsersToRemove, setSelectedUsersToRemove] = useState([])

  const teamHasUsers =
    selectedUsers?.length > 0 || pendingUsers?.added?.length > 0

  const isViewMode = useMemo(() => {
    if (team.isGlobal) {
      return !isAdmin
    }
    if (isCreate) {
      return !hasEditAccess
    }
    return !hasCreateAccess
  }, [team.isGlobal, isCreate, isAdmin, hasEditAccess, hasCreateAccess])

  useEffect(() => {
    // These run only if the team has been previously saved
    if (!isCreate) {
      // Fetch all alerts that have alert notifications for the edited team
      if (hasAlertReadAccess) {
        getTeamAlertNotifications(team.key)
          .then((alerts) => {
            setTeamAlerts(
              alerts.map((alert) => {
                const foundChannels = []
                alert.notificationOptions.forEach(({ entities, channels }) => {
                  if (entities.some((entity) => entity === team._id)) {
                    foundChannels.push(...channels)
                  }
                })

                return {
                  id: uuidv4(), // Generate a unique id so that we can change the alert if necessary
                  alert: alert._id,
                  alertName: alert.name,
                  channels: [...new Set(foundChannels)],
                }
              })
            )
          })
          .catch((error) => {
            setTeamAlerts([])
            showErrorMessage(error.message || error, dispatch)
          })
      }

      // Fetch all alerts available to the current user
      fetchAlertsForTeam(team.key)
        .then(setAlertNotificationAlerts)
        .catch((error) => {
          setAlertNotificationAlerts([])
          showErrorMessage(error.message || error, dispatch)
        })
    }
  }, [isCreate, hasAlertReadAccess])

  useEffect(() => {
    if (isCreate && hasEditAccess && pendingUsers?.added?.length) {
      const userIds = pendingUsers.added.map((el) => el._id)
      // Fetch all alerts for pending users to be added
      fetchAlertsForUsers(userIds)
        .then((results) => {
          if (!teamAlerts?.length) {
            setTeamAlerts([])
          }
          setAlertNotificationAlerts(results)
        })
        .catch((error) => {
          setAlertNotificationAlerts([])
          showErrorMessage(error.message || error, dispatch)
        })
    }
  }, [isCreate, hasEditAccess, pendingUsers])

  /**
   * Edit Team
   * Reset errors for fields that are being edited
   * @param {Object} changes Changes in a key: value format
   */
  const editTeam = (changes) => {
    setDirty(true)

    if (changes.name) {
      changes.key = stringToID(changes.name)
    }

    setTeam(changes)
    // Reset errors for fields that are being edited
    const newErrors = { ...errors }
    Object.keys(changes).forEach((key) => {
      newErrors[key] = null
    })
    setErrors(newErrors)
  }

  /** Edit isGlobal field */
  const editIsGlobal = (isGlobal) => {
    const newTeam = { ...team, isGlobal }

    if (!isGlobal) {
      // If deselecting isGlobal on an existing team without a company (it was created as global and never got to be non-global), add the currently selected one from the top-bar
      if (!team?.companies?.length) {
        newTeam.companies = [currentCompany._id]
      }
    } else {
      // If making it global, remove its team (it can be reassigned to a new client later on)
      newTeam.companies = []
    }

    setDirty(true)
    setTeam({ ...newTeam })
    setErrors({ ...errors, isGlobal: null })
  }

  /** Add users */
  const addUsers = (users) => {
    const newPendingUsers = { ...pendingUsers }

    users.forEach((user) => {
      // Adding a user that has just been removed
      if (
        newPendingUsers.removed.some(
          (removedUser) => removedUser._id === user._id
        )
      ) {
        newPendingUsers.removed = newPendingUsers.removed.filter(
          (removedUser) => removedUser._id !== user._id
        )
      } else {
        newPendingUsers.added = [...newPendingUsers.added, user]
      }
    })

    setPendingUsers(newPendingUsers)
    setDirty(newPendingUsers.added.length || newPendingUsers.removed.length)
  }

  /** Remove users */
  const removeUser = () => {
    const newPendingUsers = { ...pendingUsers }
    const changes = {}

    selectedUsersToRemove.forEach((user) => {
      // Removing a just added user
      if (
        newPendingUsers.added.some((addedUser) => addedUser._id === user._id)
      ) {
        newPendingUsers.added = newPendingUsers.added.filter(
          (addedUser) => addedUser._id !== user._id
        )
      } else {
        newPendingUsers.removed = [...newPendingUsers.removed, user]
      }

      if (equalIds(user, team.teamLead)) {
        changes.teamLead = null
      }
    })

    if (Object.values(changes).length) {
      setTeam({ ...changes })
    }

    setPendingUsers(newPendingUsers)
    setDirty(newPendingUsers.added.length || newPendingUsers.removed.length)
    setSelectedUsersToRemove([])
  }

  let displayedUsers = [...selectedUsers, ...pendingUsers.added]
  if (pendingUsers?.removed?.length > 0) {
    displayedUsers = displayedUsers.filter((user) => {
      return (
        pendingUsers?.removed.findIndex((deletedUser) => {
          return `${deletedUser._id}` === `${user._id}`
        }) === -1
      )
    })
  }

  const onSectionExpand = (sectionRef) => {
    ;[notificationsRef, membersRef, reportsRef].forEach((ref) => {
      if (ref.current === sectionRef.current) {
        ref.current?.onCollapse(false)
      } else {
        ref.current?.onCollapse(true)
      }
    })
  }

  /** Link reports to the team and removed them from "unlinkedReports" */
  const onReportsLinked = (reports) => {
    setTeam({
      ...team,
      reports: [...team.reports, ...reports].sort((a, b) =>
        compareIgnoreCase(a.name, b.name)
      ),
      unlinkedReports: team.unlinkedReports.filter(
        (ur) => !reports.some((r) => r._id === ur._id)
      ),
    })
  }

  /** Un-link reports from the team and move them under "unlinkedReports" */
  const onReportsDeleted = (reports) => {
    setTeam({
      ...team,
      reports: team.reports.filter(
        (report) => !reports.some((r) => r._id === report._id)
      ),
      unlinkedReports: [...team.unlinkedReports, ...reports].sort((a, b) =>
        compareIgnoreCase(a.name, b.name)
      ),
    })
  }

  /** Submit handler */
  const onSave = (e) => {
    e.preventDefault()

    // Validate
    setLoading(true)
    let [isValid, errors] = validate(ERROR_MAP, team)

    const [isValidNotifications, notificationErrors] =
      validateAlertNotificationChanges(team.alertUpdates)

    if (!isValidNotifications) {
      isValid = false
      errors.notifications = notificationErrors
    }

    if (!isValid) {
      setLoading(false)
      return setErrors(errors)
    }

    const teamObj = { ...pendingChanges }
    if (!isAdmin) {
      teamObj.companies = [companyId]
    } else if (!team) {
      // For new teams created by super admin set global flag
      // If toggled off, save current org
      if (!teamObj.isGlobal) {
        teamObj.companies = [companyId]
      }
    }

    createUpdateTeam(dispatch, teamObj, displayedUsers, team.reports, companyId)
      .then((res) => {
        if (res.error) {
          if (typeof res.error === 'object') {
            return setErrors({ ...errors, ...res.error })
          } else {
            return setErrors({ ...errors, general: res.error })
          }
        }

        setDirty(false)
        // If success, redirect back to list page
        setTimeout(() => {
          navigate('/team-management')
        }, 100)
      })
      .catch((err) => {
        setErrors({ ...errors, general: err })
      })
      .finally(() => setLoading(false))
  }

  return (
    <form onSubmit={onSave} className="form teams">
      <Helmet>
        <title>
          {isViewMode ? 'View Team' : team._id ? 'Edit Team' : 'Add Team'}
        </title>
      </Helmet>
      {/* Header */}
      <div className="heading" data-cy="page-heading">
        {isViewMode ? 'View Team' : team._id ? 'Edit Team' : 'Add Team'}
      </div>

      <section className="form__section">
        <div className="form__section__body">
          {/* <div className="form__row"> */}
          <div className="align-row">
            <div className="form__half">
              <div
                className={cx('general-label', {
                  'general-label--error': errors.name,
                })}
              >
                Team Name
              </div>
              {isViewMode ? (
                <p className="static_info">{team.name}</p>
              ) : (
                <InputText
                  placeholder={'Name'}
                  value={team.name}
                  onChange={(name) => editTeam({ name })}
                  error={errors.name}
                  disabled={loading || isViewMode}
                />
              )}
            </div>

            <div className="form__half">
              <div className="general-label">Team Type</div>

              {isViewMode ? (
                <p className="static_info">
                  {TEAM_TYPES[team.teamType || 'NONE'].label}
                </p>
              ) : (
                <Dropdown
                  defaultState={team.teamType || 'NONE'}
                  options={Object.values(TEAM_TYPES)}
                  onChange={(teamType) => editTeam({ teamType })}
                  error={errors.active}
                />
              )}
            </div>
          </div>

          <div className="align-row">
            <div className="form__half">
              <div className="general-label">Team Lead</div>
              {isViewMode ? (
                <p className="static_info">
                  {team.teamLead?.name
                    ? `${team.teamLead.firstName} ${team.teamLead.lastName}`
                    : 'None'}
                </p>
              ) : (
                <Dropdown
                  defaultState={team.teamLead?._id || team.teamLead}
                  defaultOptionText={'Select Team Lead'}
                  disabled={displayedUsers.length === 0}
                  options={displayedUsers.map((user) => ({
                    value: user._id,
                    label: user.name,
                  }))}
                  onChange={(teamLead) => editTeam({ teamLead })}
                />
              )}
            </div>

            <div className="form__half">
              <div className="general-label">Status</div>

              {isViewMode ? (
                <p className="static_info">
                  {ENTITY_STATUS_LABEL[team.active]}
                </p>
              ) : (
                <Dropdown
                  defaultState={team.active}
                  options={ENTITY_STATUS_OPTIONS}
                  onChange={(status) => editTeam({ active: status })}
                  error={errors.active}
                />
              )}
            </div>
          </div>

          <div>
            <div className="form__half">
              {!isViewMode ? (
                <CheckboxNoHooks
                  className="teams__global"
                  isChecked={team.isGlobal}
                  onChange={editIsGlobal}
                  label="Global Team"
                  defaultValue={team.isGlobal}
                  disabled={!user.isSuperAdmin}
                />
              ) : (
                <div className="margin-top-22">
                  <div className="general-label">Global Team</div>
                  <div className="static-info">
                    {team?.isGlobal ? 'Yes' : 'No'}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {hasUserEditAccess || (hasUserReadAccess && displayedUsers?.length) ? (
        <CollapsibleSection
          ref={membersRef}
          onCollapseListener={(value) => {
            if (value) {
              onSectionExpand(membersRef)
            }
          }}
          header="Team Members"
          wrapperClassName="form__section"
          extras={
            <div className="align-row users">
              <div className="align-row">
                {hasUserAssignAccess && !isViewMode && (
                  <Button
                    secondary
                    compact
                    value={
                      <div className="align-row gap-10 align-items-center">
                        <AddIcon width={10} height={10} />
                        <span>Add Users</span>
                      </div>
                    }
                    onClick={() => {
                      onSectionExpand(membersRef)
                      setAssignUsersModal(true)
                    }}
                    className={`fixed-height`}
                  />
                )}

                {selectedUsersToRemove.length > 0 && !isViewMode && (
                  <Button
                    compact
                    secondaryRed
                    value={
                      <div className="align-row gap-10 align-items-center">
                        <DeleteIcon width={18} height={18} />
                        <span>Remove Selected</span>
                      </div>
                    }
                    onClick={removeUser}
                    className="fixed-height"
                  />
                )}
              </div>
            </div>
          }
        >
          {assignUserModal && (
            <AssignUserModal
              open={assignUserModal}
              users={allUsers}
              hiddenUsers={[...displayedUsers]}
              onClose={() => {
                setAssignUsersModal(false)
              }}
              title="Assign Users to Team"
              onSave={(users) => {
                addUsers(users)

                setAssignUsersModal(false)
              }}
            />
          )}

          <TeamUsersTable
            teamHasUsers={teamHasUsers}
            users={displayedUsers}
            setUsersToEdit={setSelectedUsersToRemove}
            hasEditAccess={hasEditAccess}
          />
        </CollapsibleSection>
      ) : null}

      {hasAlertReadAccess && (
        <CollapsibleSection
          ref={notificationsRef}
          onCollapseListener={(value) => {
            if (value) {
              onSectionExpand(notificationsRef)
            }
          }}
          header="Notification Channels"
          wrapperClassName="form__section"
        >
          <AlertNotificationSection
            alerts={teamAlerts}
            availableAlerts={alertNotificationAlerts}
            changes={team.alertUpdates || {}}
            onChange={(alertUpdates) => editTeam({ alertUpdates })}
            readOnly={!hasAlertEditAccess}
            errors={errors.notifications}
            setErrors={(notifications) =>
              setErrors({ ...errors, notifications })
            }
          />
        </CollapsibleSection>
      )}

      {/* <CollapsibleSection
        ref={reportsRef}
        header="Reports"
        wrapperClassName="form__section"
        onCollapseListener={(value) => {
          if (value) {
            onSectionExpand(reportsRef)
          } else {
            setShowLinkReportsView(false)
          }
        }}
        extras={
          !isViewMode && (
            <div className="align-row">
              <Button
                secondary={true}
                compact={true}
                className="fixed-height"
                disabled={showLinkReportsView}
                value={
                  <div className="align-row gap-10 align-items-center">
                    <AddIcon width={10} height={10} />
                    <span>Link report</span>
                  </div>
                }
                onClick={() => {
                  onSectionExpand(reportsRef)
                  setShowLinkReportsView(true)
                }}
              />
            </div>
          )
        }
      >
        <TeamReports
          isViewMode={isViewMode}
          team={team}
          onReportsLinked={onReportsLinked}
          onReportsDeleted={onReportsDeleted}
          showLinkReportsView={showLinkReportsView}
          setShowLinkReportsView={setShowLinkReportsView}
          isSuperAdmin={user.isSuperAdmin}
          teamUsers={displayedUsers}
        />
      </CollapsibleSection> */}

      {errors.general ? <div className="error">{errors.general}</div> : null}

      <StickyFooter
        buttons={[
          {
            value: isCreate ? 'Save Team' : 'Save Changes',
            onClick: onSave,
            disabled: loading,
            renderCondition: !isViewMode,
          },
          {
            value: 'Cancel',
            onClick: () => navigate('/team-management'),
            disabled: loading,
            secondaryGray: true,
            renderCondition: !isViewMode,
          },
          {
            value: 'Delete Team',
            onClick: (e) => {
              e.preventDefault()
              onDelete(team)
            },
            disabled: loading,
            renderCondition: hasDeleteAccess && !isCreate,
            type: 'secondaryRed',
          },
          {
            value: 'Back',
            onClick: () => navigate(-1),
            renderCondition: isViewMode,
          },
        ]}
      />
    </form>
  )
}

CreateEditTeam.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  companyId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
}

const ERROR_MAP = {
  key: [FIELD_TYPES.REQUIRED, FIELD_TYPES.ID],
  name: FIELD_TYPES.REQUIRED,
}

export default CreateEditTeam
