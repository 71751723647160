import { accounts, flow, googleEnums } from '@decision-sciences/qontrol-common'
import { GOOGLE_BIDDING_IMPRESSION_SHARE_LOCATIONS } from 'modules/flow/components/google/campaign/campaign-settings/bidding/constants'
import {
  TYPES_OF_AD_CONTENT,
  AD_CONTENT_DEFAULTS,
} from 'modules/flow/constants'
/** Icons for campaign objectives  */
import GoogleSales from 'assets/google/google-sales-campaign.svg'
import GoogleLeads from 'assets/google/google-leads-campaign.svg'
import GoogleTraffic from 'assets/google/google-website-traffic.svg'
import GoogleConsideration from 'assets/google/google-product-and-brand.svg'
import GoogleReach from 'assets/google/google-brand-awareness.svg'
import GoogleAppPromotion from 'assets/google/google-app-promotions.svg'
import GoogleLocalStore from 'assets/google/google-local-store.svg'
import GoogleNoGoal from 'assets/google/google-no-goal.svg'

/** Icons for campaign type  */
import GoogleSearch from 'assets/icon_search.svg'
import GooglePerformance from 'assets/google/google-performance-max.svg'
import GoogleDisplay from 'assets/google/google-display.svg'
import GoogleShopping from 'assets/google/google-shopping.svg'
import GoogleVideo from 'assets/google/google-video.svg'
import GoogleApp from 'assets/google/google-app-type.svg'
import GoogleSmart from 'assets/google/google-smart-type.svg'
import GoogleLocal from 'assets/google/google-local-type.svg'
import GoogleDiscovery from 'assets/google/google-discovery.svg'

/** Constants */
import { CAMPAIGN_TARGETING_SOURCE } from 'modules/flow/components/google/campaign/campaign-settings/dynamic-search-ads'
import { TARGETING_SETTING_OBSERVATION } from 'modules/flow/components/google/common/components/audience-segments/constants'
import { TARGET_LOCATIONS_OPTIONS } from 'modules/flow/components/google/campaign/campaign-settings/target-locations/target-locations'

const { ACCOUNT_TYPES_MAP } = accounts
const { NegativeGeoTargetType, PositiveGeoTargetType } = googleEnums
const { GOOGLE } = ACCOUNT_TYPES_MAP
const {
  GOOGLE_CAMPAIGN_BIDDING,
  GOOGLE_CAMPAIGN_BIDDING_TYPE,
  GOOGLE_CAMPAIGN_OBJECTIVE_LABELS,
  GOOGLE_CAMPAIGN_TYPE_LABELS,
  GOOGLE_ADSET_TYPE,
  AdvertisingChannelSubTypeMap,
} = flow.google

export const CAMPAIGN_DEFAULTS = {
  type: GOOGLE,
  objective: null,
  conversion_goals: [],
  status: 'PAUSED',
  dynamic_search_ads_setting: {
    language_code: null,
    domain_name: null,
    feeds: null,
    use_supplied_urls_only: null,
    local__targeting_source: CAMPAIGN_TARGETING_SOURCE.GOOGLE_INDEX_ONLY.value,
  },
  advertising_channel_type: null,
  advertising_channel_sub_type: AdvertisingChannelSubTypeMap.SEARCH_EXPRESS,
  showContinue: true,
  geo_target_type_setting: {
    location_geo_target_type: TARGET_LOCATIONS_OPTIONS.UNITED_STATES.value,
    positive_geo_target_type: PositiveGeoTargetType.PRESENCE_OR_INTEREST,
    negative_geo_target_type: NegativeGeoTargetType.PRESENCE,
  },
  network_settings: {
    target_google_search: true,
    target_search_network: true,
    target_content_network: true,
  },
  campaign_budget: {
    amount_micros: null,
    explicitly_shared: false,
  },
  ad_rotation_mode: 'OPTIMIZE',
  runAdsOnSchedule: false,
  scheduleTimezone: '',
  schedules: [],
  url_custom_parameters: [],
  start_date: new Date(),
  end_date: undefined,
  languages: [],
  local__bidding: GOOGLE_CAMPAIGN_BIDDING.CONVERSIONS.value,
  local__bidding_type: GOOGLE_CAMPAIGN_BIDDING_TYPE.AUTOMATIC,
  local__setTargetCPA: false,
  local__setTargetROAS: false,
  local__setMaxCPCBidLimit: false,
  audience_setting: {
    use_audience_grouped: false,
  },
  keywords: [],
  audience_segments: [],
  targeting_setting: {
    target_restrictions: [{ ...TARGETING_SETTING_OBSERVATION }],
  },
  maximize_conversions: {
    target_cpa: 0,
  },
  maximize_conversion_value: {
    target_roas: 0,
  },
  target_spend: {
    cpc_bid_ceiling_micros: 0,
  },
  target_impression_share: {
    location: GOOGLE_BIDDING_IMPRESSION_SHARE_LOCATIONS.ANYWHERE_ON_PAGE.value,
    location_fraction_micros: 0,
    cpc_bid_ceiling_micros: 0,
  },
  local__audienceSegmentsForCampaign: true,
  local__audienceSegmentsTargeting: 'OBSERVATION',
}

export const GOOGLE_CAMPAIGN_OBJECTIVE = {
  ...GOOGLE_CAMPAIGN_OBJECTIVE_LABELS,
  SALES: { ...GOOGLE_CAMPAIGN_OBJECTIVE_LABELS.SALES, icon: GoogleSales },
  LEADS: { ...GOOGLE_CAMPAIGN_OBJECTIVE_LABELS.LEADS, icon: GoogleLeads },
  WEBSITE_TRAFFIC: {
    ...GOOGLE_CAMPAIGN_OBJECTIVE_LABELS.WEBSITE_TRAFFIC,
    icon: GoogleTraffic,
  },
  PRODUCT_AND_BRAND_CONSIDERATION: {
    ...GOOGLE_CAMPAIGN_OBJECTIVE_LABELS.PRODUCT_AND_BRAND_CONSIDERATION,
    icon: GoogleConsideration,
    onRoadMap: true,
  },
  BRAND_AWARENESS_AND_REACH: {
    ...GOOGLE_CAMPAIGN_OBJECTIVE_LABELS.BRAND_AWARENESS_AND_REACH,
    icon: GoogleReach,
    onRoadMap: true,
  },
  APP_PROMOTION: {
    ...GOOGLE_CAMPAIGN_OBJECTIVE_LABELS.APP_PROMOTION,
    icon: GoogleAppPromotion,
    onRoadMap: true,
  },
  LOCAL_STORE_VISITS_AND_PROMOTIONS: {
    ...GOOGLE_CAMPAIGN_OBJECTIVE_LABELS.LOCAL_STORE_VISITS_AND_PROMOTIONS,
    icon: GoogleLocalStore,
    onRoadMap: true,
  },
  NONE: {
    ...GOOGLE_CAMPAIGN_OBJECTIVE_LABELS.NONE,
    icon: GoogleNoGoal,
  },
}

export const CAMPAIGN_NETWORKS = {
  TARGET_SEARCH_NETWORK: {
    label: 'Include Google Search Partners',
    message:
      'Ads can appear near Google Search results and other Google sites when people search for terms that are relevant to your keywords',
    path: 'target_search_network',
  },
  TARGET_CONTENT_NETWORK: {
    label: 'Include Google Display Networks',
    message:
      'Easy way to get additional conversions at similar or lower costs than Search with unused Search budget',
    path: 'target_content_network',
  },
}

export const GOOGLE_CAMPAIGN_TYPE = {
  SEARCH: {
    ...GOOGLE_CAMPAIGN_TYPE_LABELS.SEARCH,
    icon: GoogleSearch,
  },
  PERFORMANCE_MAX: {
    ...GOOGLE_CAMPAIGN_TYPE_LABELS.PERFORMANCE_MAX,
    icon: GooglePerformance,
    onRoadMap: true,
  },
  DISPLAY: {
    ...GOOGLE_CAMPAIGN_TYPE_LABELS.DISPLAY,
    icon: GoogleDisplay,
  },
  SHOPPING: {
    ...GOOGLE_CAMPAIGN_TYPE_LABELS.SHOPPING,
    icon: GoogleShopping,
    onRoadMap: true,
  },
  VIDEO: {
    ...GOOGLE_CAMPAIGN_TYPE_LABELS.VIDEO,
    icon: GoogleVideo,
    onRoadMap: true,
    apiUnavailable: true,
  },
  APP: {
    ...GOOGLE_CAMPAIGN_TYPE_LABELS.APP,
    icon: GoogleApp,
    onRoadMap: true,
  },
  SMART: {
    ...GOOGLE_CAMPAIGN_TYPE_LABELS.SMART,
    icon: GoogleSmart,
    onRoadMap: true,
  },
  LOCAL: {
    ...GOOGLE_CAMPAIGN_TYPE_LABELS.LOCAL,
    icon: GoogleLocal,
    onRoadMap: true,
  },
  DISCOVERY: {
    ...GOOGLE_CAMPAIGN_TYPE_LABELS.DISCOVERY,
    icon: GoogleDiscovery,
    onRoadMap: true,
  },
}

export const GOOGLE_CAMPAIGN_RESULT = {
  WEBSITE: {
    title: 'Website',
    label: 'Website Visits',
    value: 'WEBSITE',
    objectives: [
      GOOGLE_CAMPAIGN_OBJECTIVE.SALES.value,
      GOOGLE_CAMPAIGN_OBJECTIVE.LEADS.value,
      GOOGLE_CAMPAIGN_OBJECTIVE.WEBSITE_TRAFFIC.value,
      GOOGLE_CAMPAIGN_OBJECTIVE.NONE.value,
    ],
  },
  PHONE_CALLS: {
    title: 'Phone Calls',
    label: 'Phone Calls',
    value: 'PHONE_CALLS',
    objectives: [
      GOOGLE_CAMPAIGN_OBJECTIVE.SALES.value,
      GOOGLE_CAMPAIGN_OBJECTIVE.LEADS.value,
      GOOGLE_CAMPAIGN_OBJECTIVE.NONE.value,
    ],
  },
  APP_DOWNLOADS: {
    title: 'App Downloads',
    label: 'App Downloads',
    value: 'APP_DOWNLOADS',
    objectives: [
      GOOGLE_CAMPAIGN_OBJECTIVE.SALES.value,
      GOOGLE_CAMPAIGN_OBJECTIVE.LEADS.value,
      GOOGLE_CAMPAIGN_OBJECTIVE.NONE.value,
    ],
  },
  STORE_VISITS: {
    title: 'Store Visits',
    label: 'Store Visits',
    value: 'STORE_VISITS',
    objectives: [
      GOOGLE_CAMPAIGN_OBJECTIVE.SALES.value,
      GOOGLE_CAMPAIGN_OBJECTIVE.LEADS.value,
    ],
  },
  LEADS: {
    title: 'Leads',
    label: 'Lead Form Submissions',
    value: 'LEADS',
    objectives: [GOOGLE_CAMPAIGN_OBJECTIVE.LEADS.value],
  },
}

export const ADSET_DEFAULTS = {
  adSetType: GOOGLE_ADSET_TYPE.SEARCH_STANDARD.value,
  webpages_exact_urls: [],
  webpages_custom_rules: [],
}

export const ADSET_DEFAULT_CPC_BID = 0.01

export const AD_DEFAULTS = {
  adContent: {
    selectedType: TYPES_OF_AD_CONTENT[GOOGLE].HEADLINES,
  },
  selectedItems: AD_CONTENT_DEFAULTS[GOOGLE],
  final_urls: [],
}

export const GOOGLE_ACCOUNT_DEPENDENT_FIELDS = {
  ASSETS: 'assets',
  AUDIENCE_SEGMENTS: 'audience_segments',
  FEEDS: 'dynamic_search_ads_setting.feeds',
  CONVERSIONS_GOALS: 'conversion_goals',
}

export const CAMPAIGN_ACCOUNT_DEPENDENT_FIELDS = {
  [ACCOUNT_TYPES_MAP.GOOGLE]: [
    GOOGLE_ACCOUNT_DEPENDENT_FIELDS.FEEDS,
    GOOGLE_ACCOUNT_DEPENDENT_FIELDS.AUDIENCE_SEGMENTS,
    GOOGLE_ACCOUNT_DEPENDENT_FIELDS.ASSETS,
    GOOGLE_ACCOUNT_DEPENDENT_FIELDS.CONVERSIONS_GOALS,
  ],
}
