import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Input from 'components/input'
import blueArrowIcon from 'assets/icon_arrow_blue.svg'
import whiteArrowIcon from 'assets/icon_arrow_white_leg.svg'
import whiteDoubleArrowIcon from 'assets/icon_arrow_white_double.svg'
import { useEffectOnUpdate } from 'components/utils/custom-hooks'
import './style.scss'

/**
 * List Selection Component
 * @param {Object} props the props
 * @param {Array<Object>} props.availableItems List of available items
 * @param {Array<Object>} props.selectedItems List of selected items
 * @param {Function} props.setSelectedItems Function to set selected items state
 * @param {String} [props.idKey] Identificator key for list items
 * @param {String} [props.displayKey] Display key for list items
 * @param {Function} props.title title of the component section
 * @param {Function} props.availableListName name of the 'available' list
 * @param {Function} props.selectedListName name of the 'selected' list
 * @param {React.ReactNode} [props.expandButton] Custom button to expand the list
 * @param {React.ReactNode} [props.initialOpenState] Initial open state
 */
const ListSelection = ({
  availableItems,
  selectedItems,
  setSelectedItems,
  idKey = 'value',
  displayKey = 'label',
  title,
  availableListName = 'Available Items',
  selectedListName = 'Selected Items',
  expandButton,
  initialOpenState = true,
}) => {
  const [open, setOpen] = useState(initialOpenState)

  const [availableDisplayItems, setAvailableDisplayItems] = useState(
    availableItems || []
  )
  const [selectedDisplayItems, setSelectedDisplayItems] = useState(
    selectedItems || []
  )

  /** A list of staged elements from available / selected lists. A staged element is ready to be moved from one list to the other */
  const [stagedAvailable, setStagedAvailable] = useState([])
  const [stagedSelected, setStagedSelected] = useState([])

  /** Search input for available / selected items */
  const [searchAvailable, setSearchAvailable] = useState('')
  const [searchSelected, setSearchSelected] = useState('')

  const matchRegex = (item, pattern) =>
    item.match(new RegExp(`.*${pattern}.*`, 'i'))

  /** Filter trough available items */
  const onSearchAvailable = (searchAvailable) => {
    const items = availableItems.filter(
      (item) =>
        !selectedItems.some((i) => i[idKey] === item[idKey]) &&
        matchRegex(item[displayKey], searchAvailable)
    )

    setAvailableDisplayItems(items)
  }

  /** Filtering trough selected items */
  const onSearchSelected = (searchSelected) => {
    const items = selectedItems.filter((item) =>
      matchRegex(item[displayKey], searchSelected)
    )

    setSelectedDisplayItems(items)
  }

  /** On clicking on an unstaged item, stage it for selection, othwrwise remove it from staged list */
  const onClickOnAvailableItem = (id) => {
    if (stagedAvailable.includes(id)) {
      setStagedAvailable(stagedAvailable.filter((itemId) => itemId !== id))
    } else {
      setStagedAvailable([...stagedAvailable, id])
    }
  }

  /** On clicking on an unstaged item, stage it for unselection, othwrwise remove id from unstage list */
  const onClickOnSelectedItem = (id) => {
    if (stagedSelected.includes(id)) {
      setStagedSelected(stagedSelected.filter((itemId) => itemId !== id))
    } else {
      setStagedSelected([...stagedSelected, id])
    }
  }

  /** On availableItems or selectedItems change, check if there are out of sync options (shown in both panels) and resync them */
  useEffect(() => {
    if (
      availableDisplayItems.some(
        (availableItem) =>
          !!selectedDisplayItems.find(
            (selectedItem) => selectedItem[idKey] === availableItem[idKey]
          )
      )
    ) {
      syncAvailableAndSelectedItems()
    }
  }, [JSON.stringify(availableItems), JSON.stringify(selectedItems)])

  /** On changing the available items */
  useEffectOnUpdate(() => {
    /** Update display Items */
    syncAvailableAndSelectedItems()

    // /** Reset the search fields */
    setSearchAvailable('')
    setSearchSelected('')

    // /** Reset staging states */
    setStagedAvailable([])
    setStagedSelected([])

    /** Remove items from the selected list that are no longer available */
    const newSelected =
      selectedItems?.filter((item) =>
        availableItems?.some((i) => item[idKey] === i[idKey])
      ) || []
    setSelectedItems(newSelected)
    setSelectedDisplayItems(newSelected)
  }, [JSON.stringify(availableItems)])

  const syncAvailableAndSelectedItems = () =>
    setAvailableDisplayItems(
      availableItems.filter(
        (availableItem) =>
          !selectedItems?.some(
            (selectedItem) => selectedItem[idKey] === availableItem[idKey]
          )
      )
    )

  /**
   * Moves elements from a list to another
   * @param {Object} options options
   * @param {Boolean} [options.select] if set, it moves items from the available list to the selected one. Otherwise it moves from selected to available.
   * @param {Boolean} [options.all] move all items, not only the staged ones. Defaults to false.
   */
  const onMoveItems = ({ select = true, all = false }) => {
    if (select) {
      /** Move from available to selected */

      let itemsToMove = [...stagedAvailable]
      if (all) {
        itemsToMove = [...availableDisplayItems.map((item) => item[idKey])]
      }

      const movedItems = availableItems.filter((item) =>
        itemsToMove.includes(item[idKey])
      )
      const newSelectedItems = [...selectedItems, ...movedItems]
      const newSelectedDisplayItems = [...selectedDisplayItems, ...movedItems]
      const newAvailableDisplayItems = availableDisplayItems.filter(
        (item) => !newSelectedItems.some((i) => i[idKey] === item[idKey])
      )

      setSelectedDisplayItems(newSelectedDisplayItems)
      setSelectedItems(newSelectedItems)
      setAvailableDisplayItems(newAvailableDisplayItems)
    } else {
      /** Move from Selected to Available */

      let itemsToMove = [...stagedSelected]
      if (all) {
        itemsToMove = [...selectedDisplayItems.map((item) => item[idKey])]
      }

      const movedItems = selectedItems.filter((item) =>
        itemsToMove.includes(item[idKey])
      )
      const newAvailableDisplayItems = [...availableDisplayItems, ...movedItems]
      const newSelectedItems = selectedItems.filter(
        (item) =>
          !newAvailableDisplayItems.some((i) => i[idKey] === item[idKey])
      )
      const newSelectedDisplayItems = selectedDisplayItems.filter(
        (item) =>
          !newAvailableDisplayItems.some((i) => i[idKey] === item[idKey])
      )

      setAvailableDisplayItems(newAvailableDisplayItems)
      setSelectedItems(newSelectedItems)
      setSelectedDisplayItems(newSelectedDisplayItems)
    }

    /** Reset staged states */
    setStagedAvailable([])
    setStagedSelected([])
  }

  /**
   *
   * @param {Object} props the props
   * @param {String} props.listName name of the list
   * @param {Function} props.onSearch search function
   * @param {Function} props.setSearchValue function to set searhc input field value
   * @param {String} props.searchValue value of the search field
   * @param {Array<Object>} props.listItems list of items
   * @param {Array<Object>} props.stagedList list of staged items
   * @param {Function} props.onClickOnItem Staging function
   * @returns {React.Component}
   */
  const ListGroup = ({
    listName,
    onSearch,
    setSearchValue,
    searchValue,
    listItems,
    stagedList,
    onClickOnItem,
  }) => (
    <div className="list-select__side">
      <p className="general-label">{listName}</p>
      <div className="list-select__side-group">
        <div className="list-select__search">
          <Input
            data-testid="list-select__input"
            searchBlue
            value={searchValue}
            placeholder="Search..."
            onChange={(value) => {
              setSearchValue(value)
              onSearch(value)
            }}
          />
        </div>
        <ul className="list-select__list">
          {listItems.map((item, idx) => (
            <li
              key={idx}
              className={cx('list-select__list-item', {
                'list-select__list-item--staged': stagedList.includes(
                  item[idKey]
                ),
              })}
              onClick={() => onClickOnItem(item[idKey])}
            >
              {item[displayKey]}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )

  ListGroup.propTypes = {
    listName: PropTypes.array,
    onSearch: PropTypes.func,
    setSearchValue: PropTypes.func,
    searchValue: PropTypes.string,
    listItems: PropTypes.array,
    stagedList: PropTypes.array,
    onClickOnItem: PropTypes.func,
  }

  return (
    <div className="list-select">
      <div className="list-select__header">
        <p className="general-label">{title}</p>
        {expandButton && !open ? (
          React.cloneElement(expandButton, { onClick: () => setOpen(!open) })
        ) : (
          <img
            alt="Blue Arrow Icon"
            src={blueArrowIcon}
            className={cx('list-select-toggle', {
              'list-select-toggle--invert': open,
            })}
            onClick={() => setOpen(!open)}
          />
        )}
      </div>
      <div
        className={cx('list-select__body', {
          'list-select__body--hide': !open,
        })}
      >
        {/* Available List */}
        {ListGroup({
          listName: availableListName,
          onSearch: onSearchAvailable,
          searchValue: searchAvailable,
          listItems: availableDisplayItems,
          stagedList: stagedAvailable,
          onClickOnItem: onClickOnAvailableItem,
          setSearchValue: setSearchAvailable,
        })}

        {/* Buttons */}
        <div className="list-select__action">
          {/* Move staged items to the right */}
          <div
            data-testid="list-select__action-button-right"
            className={cx('list-select__action-button', {
              /** Disable button if there are no staged items */
              'list-select__action-button--disabled': !stagedAvailable.length,
            })}
            onClick={onMoveItems}
          >
            <img
              alt="White Arrow Icon"
              src={whiteArrowIcon}
              className="list-select__action-icon"
            />
          </div>

          {/* Move all items to the right */}
          <div
            className={cx('list-select__action-button', {
              /** Disable button if there are no available items */
              'list-select__action-button--disabled':
                !availableDisplayItems.length,
            })}
            onClick={() => onMoveItems({ all: true })}
          >
            <img
              alt="White Double Arrow Icon"
              src={whiteDoubleArrowIcon}
              className="list-select__action-icon"
            />
          </div>

          {/* Move all items to the left */}
          <div
            className={cx('list-select__action-button', {
              /** Disable button if there are no selected items */
              'list-select__action-button--disabled':
                !selectedDisplayItems?.length,
            })}
            onClick={() => onMoveItems({ select: false, all: true })}
          >
            <img
              alt="White Double Arrow Icon"
              src={whiteDoubleArrowIcon}
              className="list-select__action-icon list-select__action-icon--reversed"
            />
          </div>

          {/* Move staged items to the left */}
          <div
            data-testid="list-select__action-button-left"
            className={cx('list-select__action-button', {
              /** Disable button if there are no staged selected items */
              'list-select__action-button--disabled': !stagedSelected.length,
            })}
            onClick={() => onMoveItems({ select: false })}
          >
            <img
              alt="White Arrow Icon"
              src={whiteArrowIcon}
              className="list-select__action-icon list-select__action-icon--reversed"
            />
          </div>
        </div>

        {/* Selected List */}
        {ListGroup({
          listName: selectedListName,
          onSearch: onSearchSelected,
          searchValue: searchSelected,
          listItems: selectedDisplayItems,
          stagedList: stagedSelected,
          onClickOnItem: onClickOnSelectedItem,
          setSearchValue: setSearchSelected,
        })}
      </div>
    </div>
  )
}

ListSelection.propTypes = {
  availableItems: PropTypes.array,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  idKey: PropTypes.string,
  displayKey: PropTypes.string,
  title: PropTypes.string,
  availableListName: PropTypes.string,
  selectedListName: PropTypes.string,
  expandButton: PropTypes.node,
  initialOpenState: PropTypes.bool,
}

export default ListSelection
