import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'components/tooltip'
import './style.scss'

/** Default hook for Radio */
const Radio = ({ label, defaultChecked = false, value, ...other }) => {
  const [checked, setChecked] = useState(defaultChecked)

  const Content = () =>
    _renderContent({ label, checked, value, onChange: setChecked, ...other })
  return [value, Content]
}

/** Non-hook Radio component */
export const RadioNoHooks = ({
  label,
  defaultChecked = false,
  value,
  onChange,
  disabled,
  description,
  hint,
  tooltip,
  ...other
}) => {
  const [checked, setChecked] = useState(defaultChecked)
  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    if (defaultChecked !== checked) {
      setChecked(defaultChecked)
    }
  }, [defaultChecked])

  const changeHandler = () => {
    setChecked(!checked)
    onChange && onChange(!checked)
  }

  const mouseHandler = (enter) => () => setShowTooltip(enter)

  return _renderContent({
    label,
    checked,
    value,
    onChange: changeHandler,
    disabled,
    description,
    hint,
    tooltip,
    mouseHandler,
    showTooltip,
    ...other,
  })
}

/** Private method to render content */
const _renderContent = ({
  label,
  checked,
  value,
  onChange,
  className,
  disabled,
  description,
  hint,
  tooltip,
  mouseHandler,
  showTooltip,
  ...other
}) => [
  <div
    data-testid="radio-input-wrapper"
    key={label}
    className={`radio input-wrapper ${className || ''} ${
      disabled ? 'radio--disabled' : ''
    } ${tooltip ? 'radio--tooltip-enabled' : ''}`}
    onMouseEnter={mouseHandler(true)}
    onMouseLeave={mouseHandler(false)}
  >
    <label>
      <input
        role="radio"
        type="radio"
        tabIndex="0"
        value={value}
        checked={checked}
        onChange={(e) => !disabled && onChange(e.currentTarget.value)}
        {...other}
      />
      <div className="label-wrapper">
        <div className="label-wrapper__label">
          <div className="label-wrapper__label__text">{label || ''}</div>
          {description && (
            <div className="radio__description">{description}</div>
          )}
        </div>
      </div>
    </label>
    {hint && (
      <div className="hint" data-testid="radio-hint">
        {hint}
      </div>
    )}
  </div>,
  tooltip && (
    <Tooltip
      data-testid="radio-tooltip"
      key={`tooltip-${label}`}
      content={tooltip}
      show={showTooltip}
    ></Tooltip>
  ),
]

const props = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  hint: PropTypes.node,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  tooltip: PropTypes.string,
}
Radio.propTypes = props
RadioNoHooks.propTypes = props
_renderContent.propTypes = {
  ...props,
  mouseHandler: PropTypes.func,
  showTooltip: PropTypes.bool,
}

export default Radio
