import { handleCsvResponse } from 'components/utils/csv-download'
import Api, { RESPONSE_TYPES } from 'easy-fetch-api'
import { showSuccessMessage } from 'modules/notifications/actions'
import { getPermissionGroups } from 'modules/permission-groups/actions'
import { getUserData, setUserData } from 'modules/session/actions'
import { user } from '@decision-sciences/qontrol-common'

const { buildUserCsvFilename } = user

export const ACTIONS = {
  GET_USERS: 'users.getUserList',
  CHANGE_STATUS: 'users.changeUserStatus',
  SET_BULK_USERS: 'users.setBulkUsers',
  USER_UPDATED: 'users.userUpdated',
  USERS_BULK_UPDATE: 'users.userBulkUpdate',
  USERS_BULK_DELETE: 'users.userBulkDelete',
  GET_ADMINS: 'users.getAdmins',
}

export const getUsers = async (dispatch, company, query = { filters: {} }) => {
  if (company) {
    query.company = company
  }
  const result = await Api.get({ url: '/api/users', query })
  if (!result || !result.success) {
    console.error(result?.error || 'Error getting user list')
    return { list: [], count: 0 }
  }
  if (dispatch) {
    dispatch({ type: ACTIONS.GET_USERS, list: result.list })
  }
  return { list: result.list, count: result.count }
}

/**
 * Get users with permissions
 * @param {Array<{feature: String, type: String}>} permissions
 * @returns {Promise<Array<Object>>}
 */
export const getUsersWithPermissions = (permissions = []) =>
  Api.get({
    url: '/api/users/with-permissions',
    query: { permissions: JSON.stringify(permissions) },
  }).catch(console.error)

/**
 * Creates/updates a user
 * @param dispatch {Function}
 * @param user {Object} to be saved
 * @returns {Promise<Array>|Promise<Object>}
 */
export const createUpdateUser = (dispatch, user) => {
  return new Promise((resolve, reject) => {
    let promise
    if (user._id) {
      promise = Api.put({ url: '/api/users', data: { user } })
    } else {
      promise = Api.post({ url: '/api/users', data: { user } })
    }
    promise
      .then((res) => {
        if (res.error) {
          return resolve(res)
        }
        getPermissionGroups(dispatch)
        getUsers(dispatch).then(() => resolve(res))
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

export const bulkAddUsers = (dispatch, users) => {
  return new Promise((resolve, reject) => {
    Api.post({ url: '/api/users/bulk-add', data: { users } })
      .then((res) => {
        if (res.error) {
          return resolve(res)
        }
        getUsers(dispatch).then(() => resolve(res))
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

export const updateUserFromMyAccount = (dispatch, user) => {
  return new Promise((resolve, reject) => {
    Api.put({ url: '/api/users/update-user', data: { user } })
      .then((res) => {
        if (res.error) {
          return resolve(res)
        }
        dispatch(setUserData(res.user))
        resolve(res)
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

export const updateUserFromSecurity = (dispatch, user) => {
  return new Promise((resolve, reject) => {
    Api.put({ url: '/api/users/update-user-password', data: { user } })
      .then((res) => {
        if (res.error) {
          return resolve(res)
        }
        dispatch(setUserData(res.user))
        resolve(res)
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

export const reactivateUser = (dispatch, email) => {
  return new Promise((resolve, reject) => {
    Api.put({ url: '/api/users/reactivate-user', data: { email } })
      .then(() => {
        getUsers(dispatch).then(resolve)
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

export const deleteUser = (dispatch, userId) => {
  return new Promise((resolve, reject) => {
    Api.delete({ url: `/api/users/${userId}` })
      .then((result) => {
        if (!result.success) {
          return reject(result.error || 'Something went wrong')
        }
        getPermissionGroups(dispatch)
        getUsers(dispatch).then(resolve)
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

export const setup2FA = (dispatch, userId, secret, otp) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: '/api/users/tfa-setup',
      data: { userId, secret, otp },
    })
      .then((res) => {
        if (!res.success || res.error || !res.user) {
          return reject(res.error || 'Unable to setup 2FA. Please try again.')
        }
        dispatch(setUserData(res.user))
        resolve(res.user)
      })
      .catch(reject)
  })
}

export const getQrInfo = (userId) => {
  return new Promise((resolve, reject) => {
    Api.get({ url: `/api/users/tfa-setup/${userId}` })
      .then(resolve)
      .catch(reject)
  })
}

export const login2fa = (dispatch, userData, code, remember, loginType) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: '/api/users/2fa-check',
      data: { userData, code, remember, loginType },
    })
      .then((res) => {
        if (!res.success || res.error || !res.user) {
          return reject(res.error || 'Wrong Code')
        }

        if (!res.hasToAcceptLegalTerms) {
          dispatch(setUserData(res.user))
        }

        resolve(res)
      })
      .catch(reject)
  })
}

export const sendUserOTP = (userId) => {
  return new Promise((resolve, reject) => {
    Api.post({ url: '/api/users/tfa-sms', data: { userId } })
      .then((res) => {
        if (!res.success || res.error) {
          return reject(res.error || 'Something went wrong')
        }
        resolve()
      })
      .catch(reject)
  })
}

export const sendUserOTPReset = (userId, newPhone) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: '/api/users/tfa-sms-new',
      data: { userId, newPhone },
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject(res.error || 'Something went wrong')
        }
        resolve()
      })
      .catch(reject)
  })
}

export const checkOtpVerificationCode = (otp) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: `/api/users/me/verify`,
      data: { otp },
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject(res.error || 'Something went wrong')
        }
        resolve()
      })
      .catch(reject)
  })
}
export const changeAuthSecret = (otp, secret) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: `/api/users/me/verify-new-secret`,
      data: { otp, secret },
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject(res.error || 'Something went wrong')
        }
        resolve()
      })
      .catch(reject)
  })
}

export const getBackupCode = (dispatch, userData) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/users/backup-code',
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject(res.error || 'Something went wrong')
        }
        const { backupCode } = res
        dispatch(setUserData({ ...userData, backupCode }))
        resolve(backupCode)
      })
      .catch(reject)
  })
}

export const updatePhoneNumber = (dispatch, userId, phone) => {
  return new Promise((resolve, reject) => {
    Api.post({ url: '/api/users/update-phone-number', data: { userId, phone } })
      .then((res) => {
        if (!res.success || res.error) {
          return reject(res.error || 'Something went wrong')
        }
        dispatch(setUserData(res.user))
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updatePhoneNumberPhoneAuth = (dispatch, userData, phone) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: '/api/users/update-phone-number-from-auth',
      data: { phone },
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject(res.error || 'Something went wrong')
        }
        dispatch(setUserData({ ...userData, phone }))
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateDevice = (dispatch, device) => {
  Api.post({ url: '/api/users/update-device', data: { device } }).then(
    (res) => {
      if (!res.success || res.error) {
        return console.error(res.error || 'Something went wrong')
      }
      getUserData(dispatch)
    }
  )
}

export const checkOldPassword = (password) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: `/api/users/me/check-old-password`,
      data: { password },
    })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

export const uploadProfileImage = (profileImg, userData, dispatch) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('file', new Blob([profileImg]))
    Api.postForm({
      url: `/api/users/upload-profile-image`,
      data: formData,
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject(res.error || 'Something went wrong')
        }
        dispatch(setUserData({ ...userData, profileImg: res.profileImg }))
        resolve()
      })
      .catch(reject)
  })
}

/**
 * Assigns or edits assignment of a User to a Client
 * @param dispatch {Function}
 * @param assignment {Object} assignment state { client, accounts, businessUnits, teams }
 */
export const assignUser = (dispatch, assignment) => {
  return new Promise((resolve, reject) => {
    Api.post({ url: '/api/users/assign-user', data: assignment })
      .then((res) => {
        if (!res.success || res.error) {
          return reject(res.error || 'Something went wrong')
        }
        dispatch({ type: ACTIONS.USER_UPDATED, user: res.user })
        resolve()
      })
      .catch(reject)
  })
}

/** Assigns multiple users to a company */
export const bulkAssignCompany = (dispatch, users, configs) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: `/api/users/bulk-assign-client`,
      data: { users, configs },
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject(res.error || 'Something went wrong')
        }
        resolve(res.list)
        showSuccessMessage('Successfully updated users', dispatch)
        dispatch({ type: ACTIONS.USERS_BULK_UPDATE, list: res.list })
      })
      .catch(reject)
  })
}

/** Unassigns multiple users from a company */
export const bulkUnassignCompany = (dispatch, users, configs) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: `/api/users/bulk-unassign-client`,
      data: { users, configs },
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject(res.error || 'Something went wrong')
        }
        resolve(res.list)
        showSuccessMessage('Successfully updated users', dispatch)
        dispatch({ type: ACTIONS.USERS_BULK_UPDATE, list: res.list })
      })
      .catch(reject)
  })
}

/** Assigns multiple users to a company */
export const bulkAssignTeam = (dispatch, users, teams) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: `/api/users/bulk-assign-team`,
      data: { users, teams },
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject(res.error || 'Something went wrong')
        }
        resolve(res.list)
        showSuccessMessage('Successfully updated users', dispatch)
        dispatch({ type: ACTIONS.USERS_BULK_UPDATE, list: res.list })
      })
      .catch(reject)
  })
}

/** Unassigns multiple users from a company */
export const bulkUnassignTeam = (dispatch, users, teams) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: `/api/users/bulk-unassign-team`,
      data: { users, teams },
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject(res.error || 'Something went wrong')
        }
        resolve(res.list)
        showSuccessMessage('Successfully updated users', dispatch)
        dispatch({ type: ACTIONS.USERS_BULK_UPDATE, list: res.list })
      })
      .catch(reject)
  })
}

export const getPopulatedTeams = () => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/users/me/get-populated-user-teams`,
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject(res.error || 'Something went wrong')
        }
        resolve(res.teams)
      })
      .catch(reject)
  })
}

/** Bulk Update users */
export const bulkUpdateUsers = (dispatch, idList, data) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: `/api/users/bulk-update`,
      data: { idList, data },
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject(res.error || 'Something went wrong')
        }
        getPermissionGroups(dispatch).finally(() => {
          showSuccessMessage('Successfully updated users', dispatch)
          dispatch({ type: ACTIONS.USERS_BULK_UPDATE, list: res.list })
          resolve(res.list)
        })
      })
      .catch(reject)
  })
}

export const bulkDelete = (dispatch, idList) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: `api/users/bulk-update`,
      data: { idList, data: { deleted: true } },
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject(res.error || 'Something went wrong')
        }
        showSuccessMessage('Successfully deleted users', dispatch)
        dispatch({ type: ACTIONS.USERS_BULK_DELETE, idList })
        getUsers(dispatch)
        getPermissionGroups(dispatch)
        resolve()
      })
      .catch(reject)
  })
}

export const setBulkUsers = (dispatch, editedUsers) => {
  dispatch({ type: ACTIONS.SET_BULK_USERS, bulkEditList: editedUsers })
}

export const resendUserInvite = (dispatch, user) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: `/api/users/resend-user-invite`,
      data: { user },
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject(res.error || 'Something went wrong')
        }
        showSuccessMessage('Invite successfully sent', dispatch)
        resolve()
      })
      .catch(reject)
  })
}

export const getSuperAdmins = (dispatch) => {
  getUsers(null, null, { filters: { superAdmin: true } })
    .then((result) => {
      dispatch({ type: ACTIONS.GET_ADMINS, superAdmins: result?.list || [] })
    })
    .catch(console.error)
}

export const getPossibleApprovers = (companyId) =>
  Api.get({ url: '/api/users/possible-approvers', query: { companyId } })

/**
 * Retrieves a Tableau trusted ticket from the server.
 * @returns {Promise}
 */
export const getTableauTicket = () => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/users/tableau',
    }).then((res) => {
      if (!res) {
        return reject('Something went wrong')
      }
      if (!res.success || res.error) {
        return reject(res.error || 'Something went wrong')
      }
      return resolve(res.ticket)
    })
  })
}

/**
 * Exports and downloads a CSV containing all filtered users
 * @param {String} company ObjectId of the current company
 * @param {Object} query Query containing filters and sort options to apply to the users data set
 * @returns {Promise}
 */
export const exportUsersCsv = async (company, query) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: '/api/users/export',
      responseType: RESPONSE_TYPES.raw,
      data: {
        company,
        query,
      },
    })
      .then((response) => {
        handleCsvResponse(response, resolve, reject, buildUserCsvFilename())
      })
      .catch(reject)
  })
}

export const checkForRestore = async (email) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/users/check-for-restore',
      query: { email },
    }).then((result) => {
      if (result.error || !result.success) {
        const defaultMessage = `Unable to check email ${email} for restore`
        console.error(defaultMessage, result.error)
        return reject(result.error || defaultMessage)
      }
      resolve(result)
    })
  })
}

export const restoreUser = async (id) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: `/api/users/restore`,
      data: { id },
    }).then((result) => {
      if (result.error || !result.success) {
        const defaultMessage = `Unable to restore for userId ${id}`
        console.error(defaultMessage, result.error)
        return reject(result.error || defaultMessage)
      }
      resolve(result)
    })
  })
}

export const getUserById = async (id) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/users/${id}`,
    }).then((result) => {
      if (result.error || !result.success) {
        const defaultMessage = `Cannot find user with id = ${id}`
        console.error(defaultMessage, result.error)
        return reject(result.error || defaultMessage)
      }
      resolve(result.user)
    })
  })
}
