/**
 * Common Flow Constants
 */
import { FIELD_TYPES } from 'components/validator'
import { flow, accounts, fileUpload } from '@decision-sciences/qontrol-common'

const { STEPS_MAP, BUILD_TYPE } = flow
const { ACCOUNT_TYPES_MAP } = accounts
const { ALLOWED_IMAGE_TYPES } = fileUpload

const { GOOGLE, FACEBOOK, ODAX } = ACCOUNT_TYPES_MAP

export const STEPS = Object.values(STEPS_MAP)

const DEFINE_BRIEF_ERROR_MAP = {
  summary: [FIELD_TYPES.REQUIRED],
  details: [FIELD_TYPES.REQUIRED],
  goal: [FIELD_TYPES.REQUIRED],
}
const REVIEW_BRIEF_ERROR_MAP = {
  defaultApprover: [FIELD_TYPES.REQUIRED],
  backupApprover: [FIELD_TYPES.REQUIRED],
}

export const STEPS_ERROR_MAP = {
  0: DEFINE_BRIEF_ERROR_MAP,
  1: REVIEW_BRIEF_ERROR_MAP,
}

export const STEP_ONE_INITIAL_STATE = {
  buildType: BUILD_TYPE.new,
  summary: '',
  details: '',
  goal: '',
  campaignsToBeUpdated: {
    facebook: [],
    google: [],
    googleDev: [],
    microsoft: [],
    linkedIn: [],
    tradeDesk: [],
  },
  selectedCriteria: [],
}

export const BRIEF_EXISTS_ERROR = 'This Brief Name already exists'

export const STEPS_ERRORS_FRONTEND = {
  accounts: 'Please select at least one account name/id.',
  adSetInvalidEndDate: 'Please set a valid end date to proceed.',
  adSetInvalidRange: 'Please set a valid range time to proceed.',
  budgetAmount:
    'Please set a valid budget amount, must be a value greater than or equal to 1',
  backupApprover: 'Please add a Backup Approver to proceed.',
  defaultApprover: 'Please add a Default Approver to proceed.',
  ad: 'One or more Ads require your attention.',
  campaign: 'One or more Campaigns require your attention.',
  adSets: 'One or more Ad Sets require your attention.',
  briefDetails: 'Errors on the Define Brief step need to be addressed.',
  unconfirmed: 'Please confirm all items to proceed.',
  callToActionButton: 'Please select a call to action button.',
  websiteURL: 'Please set a website URL.',
  template: 'Please select a template for the instant experience',
  startDateInPast: 'Please set a valid date to proceed.',
  save: 'An error occurred while saving this step, please try again',
}

export const TYPES_OF_AD_CONTENT = {
  [FACEBOOK]: {
    PRIMARY_TEXT: 'primaryTextList',
    HEADLINES: 'headlineList',
    DESCRIPTION: 'descriptionList',
    IMAGERY: 'imageList',
    VIDEO: 'videoList',
  },
  [ODAX]: {
    PRIMARY_TEXT: 'primaryTextList',
    HEADLINES: 'headlineList',
    DESCRIPTION: 'descriptionList',
    IMAGERY: 'imageList',
    VIDEO: 'videoList',
  },
  [GOOGLE]: {
    HEADLINES: 'headlineList',
    DESCRIPTION: 'descriptionList',
  },
}

export const AD_CONTENT_DEFAULTS = {
  [FACEBOOK]: {
    ...initializeContent(FACEBOOK),
  },
  [ODAX]: {
    ...initializeContent(ODAX),
  },
  [GOOGLE]: {
    ...initializeContent(GOOGLE),
  },
}

// Function used to initialize adContent lists
export function initializeContent(platform) {
  return Object.values(TYPES_OF_AD_CONTENT[platform]).reduce((obj, crt) => {
    obj[crt] = []
    obj.type = platform
    obj.confirmed = true
    return obj
  }, {})
}

/** Types of images accepted for upload for Facebook/ODAX*/
export const imageFileTypesAccepted = ALLOWED_IMAGE_TYPES.join(',')

export const INPUT_PROPS = {
  [FACEBOOK]: {
    PRIMARY_TEXT: {
      other: { proposedLength: 125 },
      hasCount: true,
      rows: 4,
      placeholder: 'Enter Primary Text',
    },
    HEADLINE: {
      other: { proposedLength: 40 },
      hasCount: true,
      rows: 4,
      placeholder: 'Enter Headline',
    },
    DESCRIPTION: {
      other: { proposedLength: 30 },
      hasCount: true,
      placeholder: 'Enter Description',
    },
    VIDEO: {
      fileTypesAccepted: 'video/*',
      maxFileSize: 4000,
    },
    IMAGE: {
      fileTypesAccepted: imageFileTypesAccepted,
      maxFileSize: 4000,
    },
  },
  [ODAX]: {
    PRIMARY_TEXT: {
      other: { proposedLength: 125 },
      hasCount: true,
      rows: 4,
      placeholder: 'Enter Primary Text',
    },
    HEADLINE: {
      other: { proposedLength: 40 },
      hasCount: true,
      rows: 4,
      placeholder: 'Enter Headline',
    },
    DESCRIPTION: {
      other: { proposedLength: 30 },
      hasCount: true,
      placeholder: 'Enter Description',
    },
    VIDEO: {
      fileTypesAccepted: 'video/*',
      maxFileSize: 4000,
    },
    IMAGE: {
      fileTypesAccepted: imageFileTypesAccepted,
      maxFileSize: 4000,
    },
  },
  [GOOGLE]: {
    HEADLINE: {
      hasCount: true,
      rows: 4,
      placeholder: 'Enter Headline',
      maxLength: 30,
    },
    DESCRIPTION: {
      hasCount: true,
      placeholder: 'Enter Description',
      rows: 3,
      maxLength: 90,
    },
  },
}

