import { useCallback, useEffect, useRef, useState } from 'react'
import { utils } from '@decision-sciences/qontrol-common'

const { mergeWithoutArrays, keyBy } = utils.array

/**
 * UseEffect for when we need it debounced
 * @param {Function} effect Function to run after <delay> ms
 * @param {Number} delay Time in ms to wait until useEffect's effect gets called
 * @param {Array} deps Dependencies
 */
export const useDebouncedEffect = (effect, delay, deps) => {
  const callback = useCallback(effect, deps)

  useEffect(() => {
    const handler = setTimeout(() => {
      callback()
    }, delay)

    // In case the dependencies change, restart the timer.
    return () => {
      clearTimeout(handler)
    }
  }, [callback, delay])
}

/**
 * useEffect that runs only after the first render
 * @param {Function} effect Function to run after from the second render after
 * @param {Array} deps Dependencies
 * @param {Function} effectBefore Function that runs before component was mounted. Used for debugging.
 */
export const useEffectOnUpdate = (effect, deps, effectBefore = null) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) {
      effect()
    } else {
      didMount.current = true
      effectBefore && effectBefore()
    }
  }, deps)
}

/**
 * Custom hook used to store the previous value of a field
 * @param {*} value - any value
 * @returns {*|undefined} returns the previous value of "value" or undefined if no previous value
 */
export const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

/**
 * Used for handling multiple loading states.
 * Example: const [isLoading, toggleLoading, getIsLoading] = useLoading()
 *          toggleLoading("GLOBAL_CONFIG")
 *          console.log(getIsLoading("GLOBAL_CONFIG") // true
 *          toggleLoading("GLOBAL_CONFIG")
 *          console.log(isLoading) // false
 * Where isLoading is true if ANY of the loading states is toggled as on.
 *       toggleLoading negates the currently set up value (false -> true and vice-versa)
 *       getIsLoading returns whether a key is loading or not. Now supports RegExp!
 */
export const useLoading = () => {
  const [loadingState, setLoadingState] = useState({})
  const isLoading = Object.values(loadingState).some((value) => value)
  const toggleLoading = (key, value = !loadingState[key]) =>
    setLoadingState((loadingState) => ({
      ...loadingState,
      [key]: value,
    }))

  /**
   * Get whether a key is loading or not
   * @param {String|RegExp} key Key to check for
   * @returns {Boolean}
   */
  const getIsLoading = (key) => {
    if (typeof key === 'string') {
      return Boolean(loadingState[key])
    }
    try {
      return Object.keys(loadingState).some(
        (_key) => _key.match(key) && loadingState[_key]
      )
    } catch (error) {
      console.error(error)
      return false
    }
  }

  return [isLoading, toggleLoading, getIsLoading]
}

/**
 * Takes a ref and traps the focus inside if there are any focusable elements
 * @param {Object} element Ref to trap focus into
 */
export const useFocusTrap = (element) => {
  useEffect(() => {
    if (!element) {
      return
    }

    const focusableElements = element.querySelectorAll(
      'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled])'
    )

    if (!focusableElements?.length) {
      return
    }

    // First focusable element in the ref's element
    const first = focusableElements[0]
    // Last focusable element in the ref's element
    const last = focusableElements[focusableElements.length - 1]

    first.focus()

    const listener = function (e) {
      const isTabPressed = e.key === 'Tab'

      if (!isTabPressed) {
        return
      }

      if (e.shiftKey) {
        /* shift + tab */ if (document.activeElement === first) {
          last.focus()
          e.preventDefault()
        }
      } /* tab */ else {
        if (document.activeElement === last) {
          first.focus()
          e.preventDefault()
        }
      }
    }

    // Add the listener function on keydown
    element.addEventListener('keydown', listener)

    // Remove event listener on cleanup
    return () => {
      element.removeEventListener('keydown', listener)
    }
  }, [element])
}

/**
 * Used for handling pending states.
 * Intended to be used with BE integrated entities that have ids but should work with anything.
 * @param {Object} initialState Initial state to give to the hook
 * @param {Object} [fallbackState] In case there's no initial state, pending changes will get filled with this one. Used for creating new entities.
 * @param {Boolean} [fallbackAsPendingChanges] Use fallbackState as pendingChanges regardless of initialState
 * @param {Boolean} [dontMergeDefault] Don't use mergeWithoutArrays when calculating fullState
 * @returns {Array} [fullState, onChange, pendingChanges, setDefaultState, reset]
 */
export const usePendingState = (
  initialState,
  fallbackState = {},
  fallbackAsPendingChanges = false,
  dontMergeDefault = false
) => {
  const [defaultState, setDefaultState] = useState(initialState || {})

  const pendingChangesInitialState =
    initialState && !fallbackAsPendingChanges ? {} : fallbackState

  // In case we have an id, we want to keep it in the pending changes
  if (initialState?._id) {
    pendingChangesInitialState._id = initialState._id
  }
  if (initialState?.id) {
    pendingChangesInitialState.id = initialState.id
  }

  const [pendingChanges, setPendingChanges] = useState(
    pendingChangesInitialState
  )

  const fullState = dontMergeDefault
    ? { ...defaultState, ...pendingChanges }
    : mergeWithoutArrays(defaultState, pendingChanges)

  const onChange = (changes, merge = false) => {
    if (typeof changes !== 'object') {
      return
    }

    // Reset on changes === null
    if (changes === null) {
      setPendingChanges(pendingChangesInitialState)
      return
    }

    setPendingChanges((pendingChanges) =>
      merge
        ? mergeWithoutArrays(pendingChanges, changes)
        : {
            ...pendingChanges,
            ...changes,
          }
    )
  }

  /**
   * Resets pending changes
   * @param {Object} resetData What to replace the default state with
   * @param {String | Array<String>} specificFields Specific field to reset, eg alertThresholds for clients.
   */
  const reset = (resetData, specificFields) => {
    const fieldList = Array.isArray(specificFields)
      ? specificFields
      : [specificFields]

    // Special case for specific field reset. Only the specified one gets removed from pending changes.
    if (specificFields) {
      const newPendingChanges = structuredClone(pendingChanges)

      fieldList.forEach((specificField) => {
        delete newPendingChanges[specificField]
      })

      setPendingChanges(newPendingChanges)

      return
    }

    const newPendingChanges = {}
    if (resetData?.id) {
      newPendingChanges.id = resetData.id
    }
    if (resetData?._id) {
      newPendingChanges._id = resetData._id
    }
    setDefaultState(resetData)
    setPendingChanges(newPendingChanges)
  }

  return [
    fullState,
    onChange,
    pendingChanges,
    defaultState,
    setDefaultState,
    reset,
  ]
}

/** Bulk edit list state management that works on paginated data */
export const useBulkEditState = (initialState) => {
  const [list, setList] = useState(initialState || [])

  const onChange = (items, map) => {
    if (!map) {
      return setList(items)
    }

    const allItems = {
      ...keyBy(list, '_id'),
      ...keyBy(items, '_id'),
    }

    setList(Object.keys(map).map((_id) => allItems[_id]))
  }

  return [list, onChange]
}
