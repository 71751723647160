import Api from 'easy-fetch-api'
import { dataStore } from '@decision-sciences/qontrol-common'
import { saveSort } from 'modules/table-filter-sort/actions'
import { TABLE_CONTAINER } from 'modules/table-filter-sort/constants'

const { DATA_STORE_ITEM_TYPE, DATA_STORE_ITEM_PLACEMENT } = dataStore

export const ACTIONS = {
  SET_LOADING: 'SET_LOADING',
  SET_TABLE_LOADING: 'SET_TABLE_LOADING',
  SET_ROWS_LOADING: 'SET_ROWS_LOADING',
  SET_ITEMS: 'SET_ITEMS',
  SET_ROWS: 'SET_ROWS',
  SET_COLUMNS: 'SET_COLUMNS',
  ADD_ROW: 'ADD_ROW',
  ADD_COLUMN: 'UPDATE_COLUMN',
  REMOVE_ITEM: 'REMOVE_ITEM',
  SET_SORT: 'SET_SORT',
  SET_UNLOADED_ITEMS: 'SET_UNLOADED_ITEMS',
  SET_DATE_FILTER: 'SET_DATE_FILTER',
  SET_DATE_FILTER_LOADING: 'SET_DATE_FILTER_LOADING',
  SET_FILTERS: 'SET_FILTERS',
  UNDO: 'UNDO',
  REDO: 'REDO',
  RESET: 'RESET',
  CLEAR_LOCAL_DATA_STORE_ITEMS: 'CLEAR_LOCAL_DATA_STORE_ITEMS',
}

/**
 * Loads all active categories and all their dimensions in the Data Store library
 * @param {Function} dispatch Store dispatch function
 * @param {String} searchTerm Keyword to search
 * @returns {Promise}
 */
export const getLibraryItems = (dispatch, searchTerm) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: ACTIONS.SET_LOADING, loading: true })
    const query = {}
    if (searchTerm) {
      query.searchTerm = searchTerm
    }
    Api.get({ url: '/api/data-store-reporting/list', query })
      .then((result) => {
        if (!result.success) {
          return reject('Unable to get categories and dimensions')
        }
        dispatch({ type: ACTIONS.SET_ITEMS, items: result.data })
        resolve(result.data)
      })
      .catch(reject)
      .finally(() => dispatch({ type: ACTIONS.SET_LOADING, loading: false }))
  })
}

/**
 * Gets the saved reports accessible for the logged in user
 * @returns {Array} Saved reports
 */
export const getUserReports = () => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/data-store-reporting/user-reports',
    })
      .then((response) => {
        if (!response?.success) {
          console.error('Unable to fetch user data store reports', response)
          return reject(
            'There was an error when fetching saved data store reports'
          )
        }
        resolve(response.data)
      })
      .catch((error) => {
        console.error('Unable to fetch user data store reports', error)
        reject('There was an error when fetching saved data store reports')
      })
  })
}

/**
 * Saves a data store report
 * @param {Object} report Report to save
 * @returns {Promise} Created report
 */
export const saveReport = async (report) => {
  const result = await Api.post({
    url: '/api/data-store-reporting/report',
    data: report,
  })
  if (!result || !result.success || !result.data) {
    console.error('Error saving data store report', result)
    return result
  }
  return result.data
}

/**
 * Gets the saved reports accessible for the logged in user
 * @returns {Promise} Saved reports
 */
export const updateReport = async (report) => {
  const result = await Api.put({
    url: `/api/data-store-reporting/report/${report._id}`,
    data: report,
  })
  if (!result || !result.success || !result.data) {
    console.error('Error saving data store report', result)
    return result
  }
  return result.data
}

/**
 * Universal function which will add an entity to the appropriate column based on the
 * {@link item.type} field. Used when the entity is added via the quick add button instead of drag and drop.
 * @param {Function} dispatch Dispatch function
 * @param {Object} item Dimension or metric to add
 */
export const addItem = (dispatch, item) => {
  let action
  switch (item.placement) {
    case DATA_STORE_ITEM_PLACEMENT.ROW:
      action = ACTIONS.ADD_ROW
      break
    case DATA_STORE_ITEM_PLACEMENT.COLUMN:
      action = ACTIONS.ADD_COLUMN
      break
    case DATA_STORE_ITEM_PLACEMENT.BOTH:
      action =
        item.type === DATA_STORE_ITEM_TYPE.DIMENSION
          ? ACTIONS.ADD_ROW
          : ACTIONS.ADD_COLUMN
      break
  }
  dispatch({ type: action, item })
}

/**
 * Adds a dimension to the list of table rows
 * @param {Function} dispatch Dispatch function
 * @param {Object} item Dimension to add as a table row
 */
export const addRow = (dispatch, item) => {
  dispatch({ type: ACTIONS.ADD_ROW, item })
}

/**
 * Adds a metric to the list of table columns
 * @param {Function} dispatch Dispatch function
 * @param {Object} item Metric to add as a table column
 */
export const addColumn = (dispatch, item) => {
  dispatch({ type: ACTIONS.ADD_COLUMN, item })
}

/**
 * Removes an dimension from the row or column list
 * @param {Function} dispatch Dispatch function
 * @param {Object} item The dimension to remove
 */
export const removeItem = (dispatch, item) => {
  dispatch({ type: ACTIONS.REMOVE_ITEM, item })
}

export const clearTable = (dispatch) => {
  dispatch({ type: ACTIONS.SET_ROWS, items: [] })
  dispatch({ type: ACTIONS.SET_COLUMNS, items: [] })
  dispatch({ type: ACTIONS.SET_FILTERS, filters: [] })
  dispatch({ type: ACTIONS.SET_TABLE_LOADING, loading: false })
}

export const selectReport = (
  dispatch,
  { rows, columns, sortBy, filters, dateFilter }
) => {
  dispatch({ type: ACTIONS.SET_ROWS, items: rows })
  dispatch({ type: ACTIONS.SET_COLUMNS, items: columns })
  setSort(dispatch, sortBy)
  dispatch({ type: ACTIONS.SET_FILTERS, filters })
  dispatch({ type: ACTIONS.SET_DATE_FILTER, dateFilter })
}

export const setSort = (dispatch, sortBy) => {
  dispatch({ type: ACTIONS.SET_SORT, sortBy })
  saveSort(dispatch, TABLE_CONTAINER.DATA_STORE, sortBy)
}

export const setTableLoading = (dispatch, loading) => {
  dispatch({ type: ACTIONS.SET_TABLE_LOADING, loading })
}

export const setRowsLoading = (dispatch, loading) => {
  dispatch({ type: ACTIONS.SET_ROWS_LOADING, loading })
}

export const setUnloadedItems = (dispatch, items) => {
  dispatch({ type: ACTIONS.SET_UNLOADED_ITEMS, items })
}

export const setDateFilter = (dispatch, dateFilter) => {
  dispatch({ type: ACTIONS.SET_DATE_FILTER, dateFilter })
}

export const setDateFilterLoading = (dispatch, loading) => {
  dispatch({ type: ACTIONS.SET_DATE_FILTER_LOADING, loading })
}

export const setFilters = (dispatch, filters) => {
  dispatch({ type: ACTIONS.SET_FILTERS, filters })
}

export const undo = (dispatch) => {
  dispatch({ type: ACTIONS.UNDO })
}

export const redo = (dispatch) => {
  dispatch({ type: ACTIONS.REDO })
}

export const resetDataStore = (dispatch) => {
  dispatch({ type: ACTIONS.RESET })
}

export const clearLocalDataStore = (dispatch) => {
  dispatch({ type: ACTIONS.CLEAR_LOCAL_DATA_STORE_ITEMS })
  dispatch({ type: ACTIONS.RESET })
}
