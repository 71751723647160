import isEqual from 'lodash.isequal'
import { utils } from '@decision-sciences/qontrol-common'

const { keyBy } = utils.array

export const compareDimensionsOrder = (d1, d2) =>
  d1.dimensionOrder > d2.dimensionOrder ? 1 : -1

export const virtualToOfflineData = (virtual) => {
  return Array.isArray(virtual) ? keyBy(virtual || [], 'granularity') : virtual
}

export const validateOfflineData = (offlineData) => {
  let isValid = true
  const errors = {
    dimensions: {},
    general: null,
    filename: null,
  }

  if (offlineData.dimensions?.length) {
    offlineData.dimensions.forEach((dimension) => {
      let isValidDimension = true
      const dimensionErrors = {}
      if (!dimension.dimensionType) {
        dimensionErrors.dimensionType = true
        errors.general =
          'A dimension type needs to be selected for all dimensions.'
        isValidDimension = false
      }

      if (!isValidDimension) {
        isValid = false
        errors.dimensions[dimension.dimensionId] = dimensionErrors
      }
    })
  } else {
    errors.general = 'Dimensions are required'
    isValid = false
  }

  return [isValid, errors]
}

export const shouldDisplayOfflineDataDifferenceModal = (_data1, _data2) => {
  if (
    !_data1 ||
    !_data2 ||
    (typeof _data1 !== 'object' && typeof _data2 !== 'object')
  ) {
    return false
  }

  // Case where we're sending the same object / array through.
  if (_data1 === _data2) {
    return false
  }

  _data1 = Array.isArray(_data1) ? _data1 : [_data1]
  _data2 = Array.isArray(_data2) ? _data2 : [_data2]

  // Having one entry without filename means it's a newly setup one, and the lack of it shouldn't be validated
  const newEntry = _data1.some(({ filename }) => !filename)

  if (newEntry) {
    return false
  }

  return _data1.some((data1) => {
    const data2 = _data2.find(
      ({ _id, id }) =>
        (data1.id && id && data1.id === id) ||
        (data1._id && _id && data1._id === _id)
    )

    if (!data1?._id || !data2?._id) {
      return true
    }

    if (
      data1.filename !== data2.filename ||
      data1.overwrite !== data2.overwrite
    ) {
      return true
    }

    const dimensions1 = data1?.dimensions || []
    const dimensions2 = data2?.dimensions || []

    if (dimensions1.length !== dimensions2.length) {
      return true
    }

    return !dimensions1.every((dimension1) => {
      delete dimension1._id
      return dimensions2.some((dimension2) => {
        delete dimension2._id
        return isEqual(dimension1, dimension2)
      })
    })
  })
}
