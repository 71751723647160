export default function scrollToClosestError() {
  setTimeout(() => {
    // <main> is the scrolled element
    const mainElement = document.querySelector('main')
    if (!mainElement) {
      return
    }

    // Get first error
    let element = document.querySelector('.error-wrapper')
    if (!element) {
      element = document.querySelector('.error')
    }

    // If an error element has been detected
    if (element) {
      // If element is visible, don't do anything
      if (isScrolledIntoView(element)) {
        return
      }
      // Else get element top position
      const top = element.getBoundingClientRect().top - 150

      mainElement.scrollBy({ top, left: 0, behavior: 'smooth' })
    }
  }, 10)
}

function isScrolledIntoView(el) {
  const { top, bottom } = el.getBoundingClientRect()
  return top >= 0 && bottom <= window.innerHeight
}
