import React, { useRef, useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import debounce from 'lodash.debounce'
import cx from 'classnames'
import { ModalContext } from 'contexts/modal'

import { useOnClickOutside } from 'hooks/outside-click'
import { preventScrollingBody } from 'components/utils/dom-manipulation'

import { TooltipPosition } from './constants'

import './style.scss'

/**
 * Easy to use useTooltip hook.
 * @param {String | Node} content Content to show inside the Tooltip
 * @returns {[showTooltip,hideTooltip,Tooltip]}
 * Returns a triple containing a
 * * {function} showTooltip  shows the tooltip;
 * * {function} hideTooltip hides the tooltip;
 * * {Node} Tooltip -> Tooltip component
 */
export const useTooltip = (content) => {
  const [visible, setVisible] = useState(false)

  const showTooltip = debounce(function () {
    setVisible(true)
  }, 50)

  const hideTooltip = debounce(function () {
    setVisible(false)
  }, 50)

  return [
    showTooltip,
    hideTooltip,
    () => {
      return content && visible ? (
        <Tooltip show={visible} content={content} />
      ) : null
    },
  ]
}

/**
 * Tooltip Component
 * @param {Object} params React Params
 * @param {Boolean} params.show Whether the Tooltip is displayed or not
 * @param {Node | Object} params.content Content to display inside the Tooltip.
 * In case @param params.content is an object, the following fields can be used:
 * {Node} [params.content.title] Title to display on top of modal
 * {Node} [params.content.content] Content to display below
 * @param {String} [params.position] Position where to show tooltip
 * @param {String} [params.className] External class name to add
 */
const Tooltip = ({ show, className, content, position }) => {
  const ref = useRef(null)
  const modal = useContext(ModalContext)
  const hasTitle = !!content?.title

  // In case the tooltip is used in a modal, we get the top and left position of the modal.
  // We need to calculate mouse positition relative to the modal window, not relative to the document.
  const modalBox = modal?.modalRef?.current?.getBoundingClientRect() || {
    x: 0,
    y: 0,
  }

  const onMouseMove = (e) => {
    if (show && ref.current) {
      const { height, width } = ref.current.getBoundingClientRect()
      let x = e.clientX - modalBox.x
      let y = e.clientY - modalBox.y

      // If the window is wider than 1000px and there is a modal, adjust coordinates
      if (window.innerWidth > 1000 && (modalBox.x !== 0 || modalBox.y !== 0)) {
        // Use the initial coordinates relative to the entire window
        x = e.clientX
        y = e.clientY
      }

      // In case the tooltip would exit the screen through the bottom, display above
      if (y + height > window.innerHeight || position === TooltipPosition.TOP) {
        ref.current.style.top = `${y - height - 20}px`
        ref.current.style.bottom = `auto`
      } else {
        ref.current.style.top = `${y + 20}px`
        ref.current.style.bottom = `auto`
      }
      // In case the tooltip would exit the screen through the right, display on the left
      if (x + width > window.innerWidth) {
        ref.current.style.right = `${
          modalBox?.left ? width / 4 - modalBox.left : width / 4
        }px`
        ref.current.style.left = `auto`
      } else {
        ref.current.style.right = `auto`
        ref.current.style.left = `${x - 40}px`
      }
    }
  }
  useEffect(() => {
    if (show) {
      window.addEventListener('mousemove', onMouseMove)
    }
    return () => window.removeEventListener('mousemove', onMouseMove)
  }, [show])

  if (!show) {
    return null
  }

  return (
    <div
      data-testid="tooltip"
      ref={ref}
      style={{ left: '-1000px', top: '-500px' }} // This is because the tooltip first appears, then is moved.
      className={cx('tooltip', {
        [className]: !!className,
        'tooltip--has-title': hasTitle,
      })}
    >
      {hasTitle ? (
        <>
          <div className="tooltip__title">{content.title}</div>
          <div className="tooltip__content">{content.content}</div>
        </>
      ) : (
        content
      )}
    </div>
  )
}

Tooltip.propTypes = {
  show: PropTypes.bool,
  content: PropTypes.node,
  className: PropTypes.string,
  parentRef: PropTypes.object,
  position: PropTypes.oneOf(Object.values(TooltipPosition)),
}

export default Tooltip

/**
 * Tooltip that doesn't follow the mouse, just stays tethered to the items under it.
 * @param {Object} params React Params
 * @param {String} params.tooltipClassName Tooltip class name to apply
 * @param {Boolean} params.isHoverActivated Tooltip appears when item is hovered (not clicked as usual)
 * @param {Node} params.content Tooltip Content
 * @param {Node} params.children Item to display
 */
export const StaticTooltip = ({
  tooltipClassName,
  children,
  isHoverActivated,
  content,
}) => {
  const [show, setShow] = useState(false)

  const childrenRef = useRef()
  const tooltipRef = useRef()
  const intervalRef = useRef()

  const appWrapper = document.getElementById('3q-app-container')

  const extraProps = {}

  const calculatePositions = () => {
    const pos = getTooltipPosition()
    if (pos) {
      tooltipRef.current.style.top = `${pos.top}px`
      tooltipRef.current.style.left = `${pos.left}px`
    } else {
      tooltipRef.current.style.top = `-1000px`
      tooltipRef.current.style.left = `-1000px`
    }
  }

  const onShow = (value) => {
    preventScrollingBody(value)
    setShow(value)
  }

  if (isHoverActivated) {
    extraProps.onMouseOver = () => onShow(true)
    extraProps.onMouseLeave = () => onShow(false)
  } else {
    extraProps.onClick = () => onShow(!show)
  }

  useOnClickOutside(childrenRef, () => onShow(false))

  const getTooltipPosition = () => {
    if (!tooltipRef.current || !childrenRef.current) {
      return
    }

    const DISTANCE = 16

    const sizes = tooltipRef.current?.getBoundingClientRect()
    const position = childrenRef.current?.getBoundingClientRect()

    const leftTether = position.x
    const topTether = position.y

    const tooltipWidth = sizes.width
    const tooltipHeight = sizes.height

    const positions = {
      left: leftTether - tooltipWidth - DISTANCE,
      top: Math.max(DISTANCE, topTether - tooltipHeight),
    }

    if (positions.left < 0) {
      positions.left = DISTANCE + leftTether + position.width
    }

    return positions
  }

  useEffect(() => {
    if (show && content) {
      intervalRef.current = setInterval(() => {
        calculatePositions()
      }, 10)
    } else {
      clearInterval(intervalRef.current)
    }

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [show])

  return (
    <div className="static-tooltip__wrapper" {...extraProps}>
      {show &&
        createPortal(
          <div
            ref={tooltipRef}
            className={cx('static-tooltip__content', {
              [tooltipClassName]: Boolean(tooltipClassName),
              'static-tooltip__content--clickable': !isHoverActivated,
            })}
            style={{ top: '-1000px', left: '-1000px' }}
          >
            {content}
          </div>,
          appWrapper
        )}
      <div ref={childrenRef} className="static-tooltip__wrapper__children">
        {children}
      </div>
    </div>
  )
}

StaticTooltip.propTypes = {
  isHoverActivated: PropTypes.bool,
  tooltipClassName: PropTypes.string,
  content: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}
