import React, { useState, useRef, useEffect } from 'react'
import Calendar from 'react-calendar'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import cx from 'classnames'

// Components
import Input from 'components/input'
import { DEFAULT_DATE_FORMAT } from 'components/utils/date'
import ButtonToggle from 'components/button-toggle/index'

// Hooks
import { useOnClickOutside } from 'hooks/outside-click'

// Icons
import calendarIcon from 'assets/icon_date_range.svg'

// Styles
import 'react-calendar/dist/Calendar.css'
import './style.scss'

/**
 * Calendar component wrapper based on {@link https://www.npmjs.com/package/react-calendar | React Calendar}
 *
 * @param {Object} props Component props
 * @param {*} props.date Input date
 * @param {Function} props.returnDate Callback executed when date changes
 * @param {String} props.className Extra class names to apply to container div
 * @param {String} props.placeholder Placeholder text
 * @param {String} [props.formatDate] Custom date format. Default to {@link DEFAULT_DATE_FORMAT}
 * @param {Boolean} [props.icon] Toogle calendar icon display on or off
 * @param {Boolean} [props.disabled] Flag for disabled state
 * @param {Boolean} [props.selected] Flag for selected state
 * @param {*} [props.minDate] Minimum date constraint - dates can be picked starting from minDate onwards
 * @param {*} [props.maxDate] Maximum date constraint - dates can be picked until this date
 * @param {Boolean} [props.error] - Boolean to indicate that the input value is incorect
 * @returns {React.Component}
 */
const CalendarComp = ({
  date,
  label,
  returnDate,
  className,
  placeholder,
  formatDate,
  icon,
  disabled,
  minDate,
  maxDate,
  error = false,
  renderAsButton,
  selected,
}) => {
  const [opened, setOpened] = useState(false)
  const calendarRef = useRef()
  const optionsRef = useRef()

  const theDate = date && typeof date === 'string' ? new Date(date) : date
  formatDate = formatDate ? formatDate : DEFAULT_DATE_FORMAT
  const setDate = (date) => {
    returnDate && returnDate(format(date, formatDate))
    setOpened(false)
  }

  const [optionsDisplayedUpwards, setOptionsDisplayedUpwards] = useState(false)
  const [optionsDisplayedFromRight, setOptionsDisplayedFromRight] =
    useState(false)
  useEffect(() => {
    if (opened && optionsRef.current && calendarRef.current) {
      // Set options to display upward/downward
      const { height, width } = optionsRef.current.getBoundingClientRect()
      const { top, left } = calendarRef.current.getBoundingClientRect()
      let effectiveHeight = height
      // fix for dropdown options that overlap the footer menu
      if (height < 100 || height > 210) {
        effectiveHeight += 55
      }
      setOptionsDisplayedUpwards(
        top + effectiveHeight > window.innerHeight - 74
      )

      // Set options to display from right/left
      setOptionsDisplayedFromRight(left + width > window.innerWidth)
    }
  }, [opened])

  useOnClickOutside(calendarRef, () => setOpened(false))

  return (
    <div
      className={className}
      ref={calendarRef}
      tabIndex={-1}
      data-testid={TEST_ID_WRAPPER}
      data-cy="calendar"
    >
      {label && (
        <div data-testid="input-label-parent-block" className="label-wrapper">
          <label>{label}</label>
        </div>
      )}
      <div
        className={cx('calendar-container', {
          'calendar-container--selected': selected,
          'calendar-container--disabled': disabled,
          'calendar-container--error': error,
        })}
      >
        {renderAsButton ? (
          <ButtonToggle
            className="calendar-container__input"
            label={date ? format(theDate, formatDate) : 'Select Date'}
            onClick={() => !disabled && setOpened(true)}
            icon={calendarIcon}
          />
        ) : (
          <Input
            className="calendar-container__input"
            onChange={() => {}}
            placeholder={placeholder}
            value={date ? format(theDate, formatDate) : ''}
            onClick={() => !disabled && setOpened(true)}
            date={icon}
            error={error}
            disabled={disabled}
          />
        )}
        {opened && !disabled && (
          <Calendar
            onChange={setDate}
            inputRef={optionsRef}
            className={cx({
              'react-calendar--upwards': optionsDisplayedUpwards,
              'react-calendar--from-right': optionsDisplayedFromRight,
            })}
            defaultValue={date ? new Date(date) : new Date()}
            calendarType="US"
            minDate={minDate ? new Date(minDate) : undefined}
            maxDate={maxDate ? new Date(maxDate) : undefined}
          />
        )}
      </div>
    </div>
  )
}

CalendarComp.propTypes = {
  date: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  formatDate: PropTypes.string,
  returnDate: PropTypes.func.isRequired,
  icon: PropTypes.bool,
  disabled: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  error: PropTypes.bool,
  selected: PropTypes.bool,
  renderAsButton: PropTypes.bool,
}

export default CalendarComp

export const TEST_ID_WRAPPER = 'calendar-wrapper'
export const TEST_ID_INPUT = 'input'
export const TEST_ID_CALENDAR = 'react-calendar'
