import Table from 'components/table/beta'
import PropTypes from 'prop-types'
import './style.scss'

const AssociatedAlertsTable = ({ alerts }) => {
  const columns = [
    {
      header: 'Alert',
      accessorKey: 'name',
    },
    {
      header: 'Used',
      accessorFn: (row) => {
        return row.thresholdsUsed
      },
      textAlign: 'center',
      size: 80,
      maxSize: 80,
    },
  ]

  // Sorting function to sort by descending value and then alphabetically
  const sortAlerts = (a, b) => {
    // Compare by thresholdsUsed in descending order
    if (a.thresholdsUsed > b.thresholdsUsed) {
      return -1
    }
    if (a.thresholdsUsed < b.thresholdsUsed) {
      return 1
    }
    // If thresholdsUsed is equal, sort alphabetically by name
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1
    }
    return 0
  }

  return (
    <Table
      height={430}
      disableSort
      columns={columns}
      data={alerts.sort(sortAlerts)}
    />
  )
}

AssociatedAlertsTable.propTypes = {
  alerts: PropTypes.array.isRequired,
}

export { AssociatedAlertsTable }
