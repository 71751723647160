import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { useOnClickOutside } from 'hooks/outside-click'

import ScssConstants from '../../styles/shared.module.scss'
import './styles.scss'

/**
 * Multi-color Dropdown component
 * @param {Object} props the props
 * @param {Object} props.options object of type { "option value": "Option label" }
 * @param {String} props.selected the value of the selected option
 * @param {Function} props.onChange the handler to call on value change
 * @param {Function} props.className extra class name
 * @param {String} props.defaultOptionText Default text to show (placeholder)
 * @param {Boolean} props.disabledColor the face of the dropdown has the color of a disabled dropdown
 * @param {Boolean} props.disabled the dropdown is disabled
 * @param {Boolean} props.noColorFace use defaut color
 */
const DropdownMultiColor = ({
  options,
  selected,
  onChange,
  defaultOptionText,
  className,
  disabledColor = false,
  disabled = false,
  noColorFace = false,
}) => {
  const [optionsOpen, setOptionsOpen] = useState(false)
  const [optionsDisplayedUpwards, setOptionsDisplayedUpwards] = useState(false)
  const dropdownRef = useRef()
  const optionsRef = useRef()
  useOnClickOutside(dropdownRef, () => optionsOpen && setOptionsOpen(false))

  const selectedColor = disabledColor
    ? ScssConstants.cardDisabled
    : (!noColorFace && options.find((op) => selected === op.value)?.color) ||
      ScssConstants.colorButton

  /** On list opened/closed */
  useEffect(() => {
    // Set options to display upward/downward
    if (optionsOpen && optionsRef.current && dropdownRef.current) {
      const { height } = optionsRef.current.getBoundingClientRect()
      const { top } = dropdownRef.current.getBoundingClientRect()
      let effectiveHeight = height
      // fix for dropdown options that overlap the footer menu
      if (height < 100 || height > 210) {
        effectiveHeight += 55
      }
      setOptionsDisplayedUpwards(
        top + effectiveHeight > window.innerHeight - 74
      )
    }
  }, [optionsOpen])

  const face = selected
    ? options.find(({ value }) => value === selected)?.label || selected
    : defaultOptionText

  return (
    <div
      className={cx('dropdown-multi-color__wrapper', {
        [className]: className,
      })}
      ref={dropdownRef}
      tabIndex={0}
      data-testid="dropdown-multi-color"
    >
      <div
        className={cx('dropdown-multi-color', {
          'dropdown-multi-color--disabled': disabled,
        })}
        style={{
          borderColor: selectedColor,
          color: selectedColor,
        }}
      >
        <div
          className="dropdown-multi-color__face"
          data-testid="dropdown-multi-color-face"
          role="button"
          onClick={() => {
            !disabled && setOptionsOpen(!optionsOpen)
          }}
        >
          <p className="dropdown-multi-color__face__text">{face}</p>
          {!disabled && (
            <span
              className={cx('dropdown-multi-color__icon', {
                'dropdown-multi-color__icon--upwards': optionsOpen,
              })}
            >
              <div className="dropdown-multi-color__icon__wrapper">
                <span
                  className="dropdown-multi-color__icon__elem"
                  style={{ background: selectedColor }}
                />
                <span
                  className="dropdown-multi-color__icon__elem"
                  style={{ background: selectedColor }}
                />
              </div>
            </span>
          )}
        </div>
        {optionsOpen && (
          <div
            ref={optionsRef}
            className={cx('dropdown-multi-color__options', {
              'dropdown-multi-color__options--up': optionsDisplayedUpwards,
            })}
            data-testid="dropdown-multi-color-options"
          >
            {options.map((option) => {
              return (
                <div
                  data-testid="dropdown-multi-color-option"
                  key={option.value}
                  className="dropdown-multi-color__option"
                  style={{ color: option.color }}
                  onClick={() => {
                    setOptionsOpen(false)
                    onChange(option)
                  }}
                >
                  {option.label}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

DropdownMultiColor.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array,
  selected: PropTypes.string,
  defaultOptionText: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  noColorFace: PropTypes.bool,
  disabledColor: PropTypes.bool,
}

export default DropdownMultiColor
