import { AccountIcon } from 'components/account-icon/index'
import LabelWrapper from 'components/label-wrapper/index'
import PropTypes from 'prop-types'
import { metrics } from '@decision-sciences/qontrol-common'
import { useMemo } from 'react'

const { SPECIAL_METRICS_FACEBOOK_ATTRIBUTIONS } = metrics

/**
 * Read only KPI section
 * @param {Object} props Component props
 * @param {Array} [props.parsedPrimaryKPIs] Primary KPIs array
 * @param {Array} [props.parsedSecondaryKPIs] Secondary KPIs array
 * @param {Array} [props.parsedReportingKPIs] Reporting KPIs array
 * @param {Object} props.facebookAttribution Facebook Attribution configuration
 * @returns {React.FunctionComponent}
 */
const KpiViewOnly = ({
  parsedPrimaryKPIs = [],
  parsedSecondaryKPIs = [],
  parsedReportingKPIs = [],
  facebookAttribution,
}) => {
  const attribution = useMemo(() => {
    return {
      primary: {
        clicks:
          facebookAttribution?.primaryKPIs?.clicks &&
          SPECIAL_METRICS_FACEBOOK_ATTRIBUTIONS[
            facebookAttribution.primaryKPIs.clicks
          ]?.label,
        views:
          facebookAttribution?.primaryKPIs?.views &&
          SPECIAL_METRICS_FACEBOOK_ATTRIBUTIONS[
            facebookAttribution.primaryKPIs.views
          ]?.label,
      },
      secondary: {
        clicks:
          facebookAttribution?.secondaryKPIs?.clicks &&
          SPECIAL_METRICS_FACEBOOK_ATTRIBUTIONS[
            facebookAttribution.secondaryKPIs.clicks
          ]?.label,
        views:
          facebookAttribution?.secondaryKPIs?.clicks &&
          SPECIAL_METRICS_FACEBOOK_ATTRIBUTIONS[
            facebookAttribution.secondaryKPIs.views
          ]?.label,
      },
      dynamic: {
        clicks:
          facebookAttribution?.dynamicMetrics?.clicks &&
          SPECIAL_METRICS_FACEBOOK_ATTRIBUTIONS[
            facebookAttribution.dynamicMetrics.clicks
          ]?.label,
        views:
          facebookAttribution?.dynamicMetrics?.clicks &&
          SPECIAL_METRICS_FACEBOOK_ATTRIBUTIONS[
            facebookAttribution.dynamicMetrics.views
          ]?.label,
      },
    }
  }, [JSON.stringify(facebookAttribution)])

  /**
   * Render read-only KPIs in a readable format with an icon.
   * @param {Object} kpi KPI data
   */
  const mapReadOnlyKpi = (kpi) => {
    return (
      <div className="client-view__kpi" key={kpi.key}>
        <AccountIcon accountType={kpi.type} />
        {kpi.label || kpi.name}
      </div>
    )
  }

  return (
    <>
      {/* Primary & Secondary KPIs & Reporting KPIs */}
      {parsedPrimaryKPIs?.length ||
      parsedSecondaryKPIs?.length ||
      parsedReportingKPIs?.length ? (
        <div className="form__section__body align-column">
          <h3 className="generic-heading">KPIs</h3>
          <div className="align-row">
            <div className="form__section__body__half-width-section">
              <LabelWrapper label="Primary">
                {parsedPrimaryKPIs?.map(mapReadOnlyKpi)}
              </LabelWrapper>
            </div>
            <div className="form__section__body__half-width-section right-side">
              <LabelWrapper label="Secondary">
                {parsedSecondaryKPIs?.map(mapReadOnlyKpi)}
              </LabelWrapper>
            </div>
          </div>
          <div className="align-row margin-top-10">
            <div className="form__section__body__half-width-section">
              <LabelWrapper label="Reporting">
                {parsedReportingKPIs?.map(mapReadOnlyKpi)}
              </LabelWrapper>
            </div>
          </div>
        </div>
      ) : null}

      {/* Special KPIs Facebook Attribution */}
      {(attribution.dynamic.clicks || attribution.dynamic.views) && (
        <div className="form__section__body align-column">
          <h3
            className="generic-heading"
            data-cy="kpi-facebook-attribution-kpis-label"
          >
            Facebook Attribution KPIs
          </h3>
          <div className="align-row">
            <div className="form__section__body__half-width-section align-row wrap">
              <LabelWrapper label="Primary">
                {attribution.primary.clicks && (
                  <div>{attribution.primary.clicks}</div>
                )}
                {attribution.primary.views && (
                  <div>{attribution.primary.views}</div>
                )}
              </LabelWrapper>
            </div>
            <div className="form__section__body__half-width-section right-side align-row wrap">
              <LabelWrapper label="Secondary">
                {attribution.secondary.clicks && (
                  <div>{attribution.secondary.clicks}</div>
                )}
                {attribution.secondary.views && (
                  <div>{attribution.secondary.views}</div>
                )}
              </LabelWrapper>
            </div>
          </div>
        </div>
      )}

      {/* Dynamic Metrics Attribution */}
      {(attribution.dynamic.clicks || attribution.dynamic.views) && (
        <div className="form__section__body align-column">
          <h3 className="generic-heading">Facebook Attribution</h3>
          <div className="form__section__body__half-width-section align-row wrap">
            <LabelWrapper label="Dynamic Events">
              <div className="align-row gap-16">
                {attribution.dynamic.clicks && (
                  <div>{attribution.dynamic.clicks}</div>
                )}
                {attribution.dynamic.views && (
                  <div>{attribution.dynamic.views}</div>
                )}
              </div>
            </LabelWrapper>
          </div>
        </div>
      )}
    </>
  )
}

KpiViewOnly.propTypes = {
  parsedPrimaryKPIs: PropTypes.array,
  parsedSecondaryKPIs: PropTypes.array,
  parsedReportingKPIs: PropTypes.array,
  facebookAttribution: PropTypes.object.isRequired,
}

export { KpiViewOnly }
