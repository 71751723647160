import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'store'
import cloneDeep from 'lodash.clonedeep'

/* Components */
import Loader from 'components/loader'
import StickyFooter from 'components/sticky-footer'
import ConfirmationModal from 'components/confirmation-modal'
import UsersCSVUpload from 'modules/users/import-users/csv-upload'
import ImportUsersTable from 'modules/users/import-users/import-users-table'

/* Store & Actions */
import { getTeams } from 'modules/teams/actions'
import { bulkAddUsers } from 'modules/users/actions'
import { getPermissionGroups } from 'modules/permission-groups/actions'

/* Utils */
import {
  entityStatus,
  timezoneCountry,
  csv,
} from '@decision-sciences/qontrol-common'
import {
  DROPDOWN_COLUMNS,
  SINGLE_SELECT_DROPDOWNS,
  getArray,
  deleteUnwantedFields,
} from './utils'

const { STATUS_LABELS } = entityStatus
const { TIMEZONES } = timezoneCountry
const { DELIMITERS } = csv

const ImportUserModule = () => {
  const navigate = useNavigate()
  const [csvFile, setCsvFile] = useState(null)
  const [userSelectedClients, setUserSelectedClients] = useState({})
  const [errors, setErrors] = useState({ required: {}, failed: {} })
  const [loading, setLoading] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const { dispatch, state } = useStore()
  const { list: clients } = state.companies
  const { list: permissionGroups } = state.permissionGroups
  const { list: teams } = state.teams

  /** On component mount, fetch available teams if necessary */
  useEffect(() => {
    if (!teams) {
      getTeams(dispatch, null)
    }
    if (!permissionGroups) {
      getPermissionGroups(dispatch)
    }
  }, [])

  /** When CSV data is available, set the selected clients object */
  useEffect(() => {
    if (csvFile && csvFile.data && csvFile.data.length) {
      const selectedUserClients = csvFile.data.reduce((acc, user) => {
        const { clientID, id } = user
        const userClients = []
        if (clientID.length) {
          getArray(clientID).forEach((client) => {
            const existingClient = clients.find(
              (cl) =>
                cl.clientId === client.trim() ||
                client
                  .split(DELIMITERS.PIPE)
                  .some((clientPart) => cl.name === clientPart.trim())
            )
            if (existingClient) {
              userClients.push({
                value: existingClient.name,
                label: existingClient.name,
                id: existingClient._id,
                clientId: existingClient.clientId,
                businessUnits: existingClient.businessUnits,
                accounts: existingClient.accounts,
              })
            }
          })
        }
        acc[id] = userClients
        return acc
      }, {})
      setUserSelectedClients(selectedUserClients)
    }
  }, [JSON.stringify(csvFile?.data)])

  // Available businessUnits/user based on selected clients
  const availableBusinessUnits = useMemo(
    () =>
      Object.keys(userSelectedClients).reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: userSelectedClients[curr].reduce(
            (acc, cl) => acc.concat(cl.businessUnits),
            []
          ),
        }),
        {}
      ),
    [JSON.stringify(userSelectedClients)]
  )

  // Available accounts/user based on selected clients
  const availableAccounts = useMemo(
    () =>
      Object.keys(userSelectedClients).reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: userSelectedClients[curr].reduce(
            (acc, cl) => acc.concat(cl.accounts),
            []
          ),
        }),
        {}
      ),
    [JSON.stringify(userSelectedClients)]
  )

  const setUsers = (users) => setCsvFile({ ...csvFile, data: users })

  const hasErrors = (errors) =>
    Object.values(errors).some((userError) =>
      typeof userError === 'object' ? hasErrors(userError) : userError
    )

  const formatUsersForImport = (users) => {
    const usersCopy = cloneDeep(users)
    const formattedUsers = usersCopy.map((user) => {
      DROPDOWN_COLUMNS.forEach((col) => {
        if (!SINGLE_SELECT_DROPDOWNS.includes(col)) {
          user[col] = getArray(user[col])
        }
      })
      user.active = user.active === STATUS_LABELS.ACTIVE ? true : false
      user.teams = teams
        .filter(({ name }) => user.team.includes(name))
        .map((t) => t._id)
      user.permissionGroup = permissionGroups.find(
        ({ name }) => name === user.permissionGroup
      )?._id
      user.timeZone = TIMEZONES.find(
        ({ label }) => label === user.timeZone
      )?.value

      user.clients = user.clientID.map((cl) => {
        const clientObject = clients.find((c) =>
          [c.name, c.clientId].includes(cl)
        )
        const bus = availableBusinessUnits[user.id]
          .filter(
            (bu) =>
              user.businessUnit.includes(bu.name) &&
              bu.parentCompany === clientObject._id
          )
          .map((bu) => bu._id)
        const accs = availableAccounts[user.id]
          .filter(
            (acc) =>
              user.account.includes(acc.externalAccountId) &&
              acc.company === clientObject._id
          )
          .map((acc) => acc._id)

        return {
          accounts: accs,
          businessUnits: bus,
          clientId: clientObject._id,
        }
      })
      return deleteUnwantedFields(user)
    })

    return formattedUsers
  }

  return (
    <div className="import-users-download" data-cy="import-users">
      <div data-cy="page-heading" className="heading">
        Import Users
      </div>
      {openDeleteModal && (
        <ConfirmationModal
          heading="Ready to Import Users"
          message={
            <div>
              <p>You are about to import {csvFile.data.length} users.</p>
              <p>
                When you click the Confirm button below, a welcome email will be
                sent to each user. They will then continue to set up their 2FA
                and will be able to access the application.
              </p>
            </div>
          }
          displayIcon={false}
          showModal={openDeleteModal}
          onCancelClicked={() => setOpenDeleteModal(false)}
          onConfirmClicked={() => {
            setLoading(true)
            setOpenDeleteModal(false)
            bulkAddUsers(dispatch, formatUsersForImport(csvFile.data)).then(
              (res) => {
                setLoading(false)
                if (res.success) {
                  navigate('/users', { replace: true })
                }
              }
            )
          }}
        />
      )}
      {loading ? (
        <Loader />
      ) : csvFile && csvFile.data ? (
        <div className="import-users">
          <p className="csv-file-name">{csvFile.name}</p>
          <ImportUsersTable
            users={csvFile.data}
            setUsers={setUsers}
            userClients={userSelectedClients}
            teams={teams}
            permissionGroups={permissionGroups}
            allClients={clients}
            setErrors={setErrors}
            errors={errors}
            businessUnits={availableBusinessUnits}
            accounts={availableAccounts}
          />
          <StickyFooter
            buttons={[
              {
                key: 'import-users-button',
                value: 'Import Users',
                onClick: () => setOpenDeleteModal(true),
                disabled: hasErrors(errors) || !csvFile?.data?.length,
              },
              {
                key: 'cancel-import-users-button',
                value: 'Cancel',
                onClick: () => {
                  setCsvFile(null), navigate('/users')
                },
                secondaryGray: true,
              },
            ]}
          />
        </div>
      ) : (
        <UsersCSVUpload setCsvFile={setCsvFile} />
      )}
    </div>
  )
}
export default ImportUserModule
