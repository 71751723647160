import React, { useState, useEffect } from 'react'
import { useStore } from 'store'

import Account from 'modules/microsoft-config/account'
import Campaigns from 'modules/microsoft-config/campaigns'
import { getAllAccounts } from 'modules/microsoft-config/actions'
import 'modules/google/style.scss'

const MicrosoftAds = () => {
  const { state, dispatch } = useStore()
  const [page, setPage] = useState('account')
  const { campaigns } = state.microsoft
  const { currentCompany: selectedOrg } = state.companies
  const pages = {
    account: <Account key={0} />,
    campaigns: !campaigns.empty && <Campaigns key={1} />,
  }
  useEffect(() => {
    if (selectedOrg && campaigns.empty) {
      getAllAccounts(dispatch)
    }
  }, [selectedOrg, campaigns])
  const renderMenu = () => {
    return (
      <div className="menu">
        {Object.keys(pages).map((pageName, index) => {
          return (
            <a
              onClick={() => {
                setPage(pageName)
              }}
              key={index}
              className={`menu__item ${
                pageName === page ? 'menu__item--selected' : ''
              }`}
            >
              {pageName}
            </a>
          )
        })}
      </div>
    )
  }

  return (
    <React.Fragment>
      {renderMenu()}
      {pages[page]}
    </React.Fragment>
  )
}

export default MicrosoftAds
