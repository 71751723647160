import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/button'
import InformationBlock, {
  INFORMATION_BLOCK_TYPE,
} from 'components/information-block'
import LineDivider from 'components/line-divider'

import { ReactComponent as IconSave } from 'assets/icon-save-white.svg'

/**
 * Renders the budget actions bar
 * @param {Boolean} props
 * @param {Boolean} props.loading - Loading state to disable the actions
 * @param {Function} props.onSaveClicked - Method to be called when save button is clicked
 * @param {Function} props.onCancelClicked - Method to be called when cancel button is clicked
 * @param {String} props.footerInfoText - info text to be displayed
 */
const BudgetActions = ({
  loading,
  saveDisabled,
  onSaveClicked = () => {},
  onCancelClicked = () => {},
  footerInfoText,
}) => {
  return (
    <>
      <LineDivider className="margin-bottom-16" width="unset" widthUnit="" />
      <div
        data-cy="budget-actions"
        className="padding-x-30 padding-y-10 budget-actions"
      >
        {footerInfoText && (
          <InformationBlock
            type={INFORMATION_BLOCK_TYPE.INFO}
            info={footerInfoText}
          />
        )}
        <div className="budget-actions__buttons">
          <Button
            green
            onClick={onSaveClicked}
            disabled={loading || saveDisabled}
            value={
              <div className="display-flex">
                <IconSave />
                <div className="budget-button-label">Save</div>
              </div>
            }
          />
          <Button
            className="budget-button-label"
            secondaryGray
            value="Cancel"
            disabled={loading}
            onClick={onCancelClicked}
          />
        </div>
      </div>
    </>
  )
}

export default BudgetActions

BudgetActions.propTypes = {
  loading: PropTypes.bool,
  saveDisabled: PropTypes.bool,
  onSaveClicked: PropTypes.func,
  onCancelClicked: PropTypes.func,
  footerInfoText: PropTypes.string,
}
