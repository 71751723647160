import { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Spacer from 'components/spacer'
import { ColorPickerModal } from 'components/color-picker'
import Loader from 'components/loader'
import StickyFooter from 'components/sticky-footer'
import useLeaveConfirm from 'components/leave-confirm'
import { platform } from '@decision-sciences/qontrol-common'
import { ReactComponent as EditIcon } from 'assets/icon_edit.svg'

import ScssConstants from 'styles/shared.module.scss'
import 'modules/platform-settings/global-settings.scss'
import { getPlatformSettings, savePlatformSettings } from '../actions'

const { SLACK_SECTIONS_CONFIG, PLATFORM_SETTINGS_TYPES_MAP } = platform

/**
 * Slack Colors Global Settings Page
 */
const SlackColors = () => {
  const [colors, setColors] = useState({})
  const [editedColor, setEditedColor] = useState()
  const [loading, setLoading] = useState(false)

  const colorsBackup = useRef(colors)

  const [setDirty, LeaveConfirm, isDirty] = useLeaveConfirm()

  /**
   * On component mount, fetch all the colors from global-settings
   */
  useEffect(() => {
    setLoading(true)
    getPlatformSettings(PLATFORM_SETTINGS_TYPES_MAP.SLACK_COLORS)
      .then((result) => {
        setColors(result)
        colorsBackup.current = result
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }, [])

  if (loading) {
    return <Loader />
  }

  /**
   * Save colors callback
   */
  const onSave = () => {
    setLoading(true)
    savePlatformSettings(PLATFORM_SETTINGS_TYPES_MAP.SLACK_COLORS, colors)
      .then((result) => {
        colorsBackup.current = result
        setDirty(false)
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }

  // On cancel, revert to previously saved colors and allow user to leave without confirming.
  const onCancel = () => {
    setDirty(false)
    setColors(colorsBackup.current)
  }

  return (
    <section className="slack-colors">
      <LeaveConfirm />
      {Object.values(SLACK_SECTIONS_CONFIG).map((section) => {
        return (
          <section
            key={section.name || 'default'}
            className="slack-colors__section"
            data-cy={`slack-colors-section-${
              section.name?.toLowerCase() || 'default'
            }`}
          >
            {section.name && (
              <>
                <Spacer />
                <div className="form__section__header">{section.name}</div>
              </>
            )}
            <div className="slack-colors__colors">
              {section.colors.map((color) => (
                <ColorCard
                  key={color.key}
                  name={color.name}
                  color={colors[color.key]}
                  onClickEdit={() => {
                    setEditedColor(color.key)
                  }}
                  isEdited={color.key === editedColor}
                  onChangeColor={(newColor) => {
                    if (newColor !== colors[color.key]) {
                      setColors((colors) => ({
                        ...colors,
                        [color.key]: newColor,
                      }))
                      setDirty(true)
                    }
                    setEditedColor(null)
                  }}
                />
              ))}
            </div>
          </section>
        )
      })}
      {isDirty && (
        <StickyFooter
          buttons={[
            {
              value: 'Save Changes',
              onClick: onSave,
              disabled: loading,
            },
            {
              value: 'Cancel',
              onClick: onCancel,
              secondaryGray: true,
            },
          ]}
        />
      )}
    </section>
  )
}

/**
 * Styled Color Card for Slack Colors Page
 * @param {Object} params react params
 * @param {String} params.name Name to be displayed
 * @param {String} params.color HEX color code. Eg: #AA31FC
 * @param {Function} params.onClickEdit Callback for when clicking the edit button
 */
const ColorCard = ({
  name,
  color = ScssConstants.colorButton,
  onClickEdit,
  isEdited,
  onChangeColor,
}) => {
  return (
    <div className="slack-colors__color-card">
      <div className="slack-colors__color-card__name">{name}</div>
      <div className="slack-colors__color-card__edit-section">
        <EditIcon
          className="slack-colors__color-card__edit"
          onClick={onClickEdit}
        />
        {isEdited && (
          <ColorPickerModal color={color} onChangeColor={onChangeColor} />
        )}
      </div>

      <div className="slack-colors__color-card__color">{color}</div>
      <div
        className="slack-colors__color-card__color-bubble"
        style={{ backgroundColor: color }}
      />
    </div>
  )
}

ColorCard.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClickEdit: PropTypes.func,
  isEdited: PropTypes.bool,
  onChangeColor: PropTypes.func,
}

export default SlackColors
