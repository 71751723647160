import PropTypes from 'prop-types'
import { format } from 'date-fns'
import Table from 'components/table/beta'
import CollapsibleSection from 'components/collapsible-section/index'

const AlertTriggerActivityLog = ({ activityLog }) => {
  const activityLogColumns = [
    {
      header: 'Date & Time',
      accessorKey: 'date',
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 100,
    },
    {
      header: 'User',
      accessorKey: 'user',
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 100,
    },
    {
      header: 'Details',
      className: 'capitalize',
      accessorKey: 'details',
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 100,
    },
  ]

  /**
   * Returns activity message
   * @param {*} activity Activity from activity log
   */
  const getActivityDescription = (activity) => {
    const { change, details } = activity
    if (!change) {
      return details
    }
    if (change.attribute === 'archived') {
      return change.to ? 'Archived Alert Trigger' : 'Unarchived Alert Trigger'
    }

    return `${change.attribute} Update: ${change.to}`
  }

  return (
    <div className="form__section">
      <CollapsibleSection header="Activity Log" defaultCollapsed={false}>
        <div className="form__section__body">
          <Table
            columns={activityLogColumns}
            data={activityLog.map((act) => ({
              ...act,
              details: getActivityDescription(act),
              date: format(new Date(act.date), `MM/dd/yyyy hh:mm a`),
            }))}
          />
        </div>
      </CollapsibleSection>
    </div>
  )
}

AlertTriggerActivityLog.propTypes = {
  activityLog: PropTypes.array,
}

export { AlertTriggerActivityLog }
