import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { HexColorPicker } from 'react-colorful'

import Button from 'components/button'
import ColorSwatch from 'components/color-swatch'
import Input from 'components/input'

import { ReactComponent as IconClose } from 'assets/icon_close_white.svg'

import './styles.scss'

/**
 * Color selector.
  @param {ColorPickerProps} props Component props
 */
export const ColorPicker = ({ color, onChangeColor }) => {
  return (
    <div className="color-picker">
      <HexColorPicker color={color} onChange={onChangeColor} />
    </div>
  )
}

/**
 * Color Picker Text Input.
  @param {ColorPickerProps} props Component props
 */
export const ColorPickerInput = ({ color, onChangeColor }) => {
  return (
    <div className="color-picker-input">
      <Input
        className="color-picker-input__input"
        value={color}
        onChange={(newColor) => {
          if (
            /^#(?:[0-9a-fA-F]*){1,2}$/i.test(newColor) &&
            newColor.length <= 7
          ) {
            onChangeColor(newColor)
          }
        }}
      />
      <ColorSwatch className="margin-left-10" color={color} />
    </div>
  )
}

/**
 * Modal containing a color picker, a color input, a swatch and a button.
  @param {ColorPickerProps} props Component props
 */
export const ColorPickerModal = ({ color, onChangeColor }) => {
  const [localColor, setLocalColor] = useState(color)
  const ref = useRef()

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const { top, left, width, height } = ref.current.getBoundingClientRect()

    const horizontalOffset = Math.min(window.innerWidth - (left + width), 0)
    const verticalOffset = Math.min(window.innerHeight - (top + height), 0)

    if (!horizontalOffset && !verticalOffset) {
      ref.current.style.visibility = 'visible'
      return
    }

    if (horizontalOffset && verticalOffset) {
      ref.current.style.transform = `translate(${horizontalOffset - width}px, ${
        verticalOffset - 50
      }px)`
    } else {
      if (horizontalOffset) {
        ref.current.style.transform = `translateX(${
          horizontalOffset - width
        }px)`
      }
      if (verticalOffset) {
        ref.current.style.transform = `translateY(${verticalOffset - 50}px)`
      }
    }

    ref.current.style.visibility = 'visible'
  }, [ref.current])

  return (
    <div ref={ref} className="color-picker-modal">
      <div className="color-picker-modal__header">
        <h3>Pick Color</h3>
        <div
          className="color-picker-modal__header__close"
          onClick={() => {
            onChangeColor(color)
          }}
        >
          <IconClose />
        </div>
      </div>
      <div className="color-picker-modal__content">
        <ColorPicker color={localColor} onChangeColor={setLocalColor} />
      </div>
      <div className="color-picker-modal__footer">
        <ColorPickerInput color={localColor} onChangeColor={setLocalColor} />
        <Button
          value="Save"
          data-testid="color-picker-modal__save"
          onClick={() => onChangeColor(localColor)}
        />
      </div>
    </div>
  )
}

const colorPickerPropTypes = {
  color: PropTypes.string,
  onChangeColor: PropTypes.func,
}

/**
 * @typedef {Object} ColorPickerProps
 * @property {String} color Hex color: Eg. #14FECC
 * @property {Function} onChangeColor Callback for color changes
 */

ColorPicker.propTypes = colorPickerPropTypes
ColorPickerInput.propTypes = colorPickerPropTypes
ColorPickerModal.propTypes = colorPickerPropTypes
