import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import debounce from 'lodash.debounce'
import { ReactComponent as IconArrowDirection } from 'assets/icon_arrow_grey.svg'
import { ReactComponent as IconArrowDirectionMore } from 'assets/icon_pagination.svg'

import Input from 'components/input'
import { Dropdown } from 'components/dropdown'
import './styles.scss'

/**
 * Implementation of TanStack React Table V8 Pagination
 * @param {Object} params React Params
 * @param {function} [params.customDownLeftRender] Optional custom element renderer in left side of table footer
 * @param {Function} params.onChangeTableView Callback function for when table paginaton, soritng or filtering is changed
 * @param {String} params.manualFilter Filter string coming from outside (E.g. From Filters)
 * @param {Function} params.setGlobalFilter Function to set global filter
 * @param {Boolean} params.showSearchInput show search input (defaults to true)
 * @param {Boolean} [params.showPagination] Whether to show/hide pagination
 * @param {String} params.searchInputPlaceholder search input placeholder (defaults to `Search...`)
 * @param {Array} params.paginationValues array of page size options
 * @param {Boolean} [params.showBlueIcon] Whether to use the blue icons
 * @param {Number} [params.manualCount] The number of total items
 * @returns {Node}
 */

const TablePagination = ({
  table,
  customDownLeftRender,
  onChangeTableView,
  manualFilter,
  setGlobalFilter,
  showSearchInput,
  showPagination,
  searchInputPlaceholder = 'Search...',
  paginationValues = [10, 20, 50],
  showBlueIcon,
  manualCount = -1,
}) => {
  const [searchValue, setSearchValue] = useState(null)
  const [lastFilter, setLastFilter] = useState(null) // Track the last filter to prevent circular updates

  const { pageSize, pageIndex } = table.getState().pagination
  const pageCount = table.getPageCount()
  const totalRows =
    manualCount !== -1 ? manualCount : table.getSortedRowModel().rows.length

  useEffect(() => {
    if (manualFilter !== searchValue) {
      setSearchValue(manualFilter)
      setLastFilter(manualFilter)
      setGlobalFilter(manualFilter)
    }
  }, [manualFilter])

  useEffect(() => {
    if (!paginationValues.includes(pageSize)) {
      table.setPageSize(paginationValues[0])
    }
  }, [paginationValues])

  const onSearch = (value) => {
    table.setPageIndex(0)

    // Prevent circular updates by checking if the new value is the same as the last filter
    if (lastFilter === value) return

    setLastFilter(value) // Update the last filter with the new value
    setGlobalFilter(String(value ?? ''))

    if (onChangeTableView) {
      onChangeTableView({
        pageIndex: 0,
        pageSize,
        filter: value,
        sort: table.getState().sortBy,
      })
    }
  }

  const debouncedOnSearch = useCallback(onSearch && debounce(onSearch, 500), [
    lastFilter,
  ])

  return (
    <div className="tfoot pagination align-row display-flex">
      {customDownLeftRender && customDownLeftRender()}
      {showSearchInput && (
        <Input
          searchBlue
          className="pagination__search"
          placeholder={searchInputPlaceholder}
          value={searchValue || ''}
          autoComplete="off"
          onChange={(value) => {
            setSearchValue(value)
            if (debouncedOnSearch) {
              debouncedOnSearch(value)
            }
          }}
          search
        />
      )}
      {showPagination && (
        <>
          <span>Rows per page</span>
          <Dropdown
            defaultState={pageSize}
            options={paginationValues.map((op) => ({
              value: op,
              label: op.toString(),
            }))}
            onChange={(e) => {
              table.setPageSize(e)
            }}
          />
          <span className="pagination__count">
            {pageCount ? pageIndex * pageSize + 1 : 0} -{' '}
            {Math.min((pageIndex + 1) * pageSize, totalRows)} of {totalRows}
          </span>
          <div className="pagination__buttons align-row">
            <IconArrowDirectionMore
              alt="first"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
              className={cx(`pagination__button`, {
                'pagination__button--blue': showBlueIcon,
                disabled: !table.getCanPreviousPage(),
              })}
            />
            <IconArrowDirection
              alt="previous"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              className={cx(`pagination__button`, {
                'pagination__button--blue': showBlueIcon,
                disabled: !table.getCanPreviousPage(),
              })}
            />
            <IconArrowDirection
              alt="next"
              style={{ transform: 'rotate(180deg)' }}
              className={cx(`pagination__button `, {
                'pagination__button--blue': showBlueIcon,
                disabled: !table.getCanNextPage(),
              })}
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            />
            <IconArrowDirectionMore
              alt="last"
              style={{ transform: 'rotate(180deg)' }}
              onClick={() => table.setPageIndex(pageCount - 1)}
              disabled={!table.getCanNextPage()}
              className={cx(`pagination__button`, {
                'pagination__button--blue': showBlueIcon,
                disabled: !table.getCanNextPage(),
              })}
            />
          </div>
        </>
      )}
    </div>
  )
}

TablePagination.propTypes = {
  customDownLeftRender: PropTypes.func,
  onChangeTableView: PropTypes.func,
  manualFilter: PropTypes.string,
  paginationValues: PropTypes.array,
  searchInputPlaceholder: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  showBlueIcon: PropTypes.bool,
  showPagination: PropTypes.bool,
  showSearchInput: PropTypes.bool,
  table: PropTypes.object.isRequired,
  manualCount: PropTypes.number,
}

export default TablePagination
