import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useStore } from 'store'

import { granularities } from '@decision-sciences/qontrol-common'

import ButtonToggle from 'components/button-toggle/index'
import Loader from 'components/loader/index'
import AlertsThresholdStatistics from 'modules/alerts/alerts-thresholds-statistics/real-data/index'
import CollapsibleSection from 'components/collapsible-section/index'
import Button from 'components/button/index'
import LineDivider from 'components/line-divider/index'
import { Dropdown } from 'components/dropdown'
import { AccountIcon } from 'components/account-icon'
import { RadioNoHooks } from 'components/radio/index'

import { ReactComponent as LaunchIcon } from 'assets/icon_launch_blue.svg'

const { GRANULARITIES } = granularities

const { ACCOUNT, CAMPAIGN, AD_GROUP, KEYWORD } = GRANULARITIES

const AlertDetailsSection = ({
  selectedEntity,
  setSelectedEntity,
  alert,
  alertTrigger,
  isAlertThresholdsLinked,
  emitModifiedThresholds,
  triggerTableData,
  accountType,
}) => {
  const { state } = useStore()
  const navigate = useNavigate()

  const {
    companies: { currentCompany: company },
  } = state

  const { entitiesWithDetails, elementType } = alertTrigger

  const [simplifiedView, setSimplifiedView] = useState(true)

  const getSelectedEntity = () => {
    if (!selectedEntity) {
      return {}
    }
    return entitiesWithDetails[elementType].find(
      ({ id }) => id === selectedEntity
    )
  }

  const selectedAlertTriggers = useMemo(() => {
    switch (elementType) {
      case ACCOUNT:
        return entitiesWithDetails.metrics
      case CAMPAIGN:
      case AD_GROUP:
      case KEYWORD:
        const entity = getSelectedEntity()
        return entity.metrics
    }
  }, [selectedEntity, elementType])

  const goToSettings = () => {
    let clientUrl = `/company/${company.clientId}`
    let businessUnitUrl = ''
    if (company.parentCompany) {
      clientUrl = `/company/${company.parentCompany.clientId}`
      businessUnitUrl = `/business-unit/${company.clientId}`
    }
    navigate(`${clientUrl}${businessUnitUrl}`)
  }

  if (!alert) {
    return <Loader />
  }

  return (
    <div className="form__section">
      <CollapsibleSection
        id="alert-details"
        header={'Alert Details'}
        extras={
          <div className="align-row gap-16">
            <Button
              secondary
              compact
              value={
                <div className="align-row gap-8">
                  <LaunchIcon width={16} height={16} />
                  <span>Go to Client/BU settings</span>
                </div>
              }
              onClick={goToSettings}
            />
            <LineDivider height={30} heightUnit="px" width={1} widthUnit="px" />
            <ButtonToggle
              label="Simplified"
              selected={simplifiedView}
              onClick={() => setSimplifiedView(true)}
            />
            <ButtonToggle
              label="Detailed"
              selected={!simplifiedView}
              onClick={() => setSimplifiedView(false)}
            />
          </div>
        }
        defaultCollapsed={false}
      >
        {elementType !== ACCOUNT ? (
          <div className="form__section__body">
            Select a trigger to display the alert details below
            <div className="general-label margin-top-10">Triggered By </div>
            <Dropdown
              options={triggerTableData?.map(({ triggeredBy, _id }) => ({
                label: triggeredBy,
                value: _id,
              }))}
              optionRenderer={({ label, value }) => (
                <>
                  <RadioNoHooks
                    checked={selectedEntity?.indexOf(value) > -1}
                    className="blue"
                    label={
                      <>
                        <AccountIcon accountType={accountType} />
                        {label}
                      </>
                    }
                  />
                </>
              )}
              className="margin-top-10 alert-trigger-details__dropdown"
              defaultOptionText="Triggered by"
              defaultState={selectedEntity}
              labelClassName="margin-botton-0"
              onChange={(value) => setSelectedEntity(value)}
            />
          </div>
        ) : null}

        <div className="form__section__body" id="highlight-section">
          <AlertsThresholdStatistics
            alert={alert}
            triggerValues={selectedAlertTriggers}
            company={company}
            isAlertThresholdsLinked={isAlertThresholdsLinked}
            parentClient={company.parentCompany}
            simplifiedView={simplifiedView}
            emitModifiedThresholds={emitModifiedThresholds}
            alertTriggerId={alertTrigger._id}
            selectedEntity={selectedEntity}
            accountId={alertTrigger.accountId.externalAccountId}
            elementType={elementType}
          />
        </div>
      </CollapsibleSection>
    </div>
  )
}

AlertDetailsSection.propTypes = {
  selectedEntity: PropTypes.string.isRequired,
  alert: PropTypes.string.isRequired,
  alertTrigger: PropTypes.string.isRequired,
  isAlertThresholdsLinked: PropTypes.func.isRequired,
  emitModifiedThresholds: PropTypes.func.isRequired,
  triggerTableData: PropTypes.array,
  accountType: PropTypes.string,
  setSelectedEntity: PropTypes.func,
}

export { AlertDetailsSection }
