import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table'

import { ACCOUNT_TYPES } from 'modules/accounts/common'

import Loader from 'components/loader/index'
import { DragAndDrop } from 'components/utils/drag-drop'
import { useEffectOnUpdate } from 'components/utils/custom-hooks'

import {
  generateElementStyling,
  renderEmptyContent,
  renderSortIcon,
  useTableTooltip,
} from './utils'
import { DragAndDropRow, Row } from './rows'

import { getSelectColumn } from './columns'

import TablePagination from './pagination'
import TableSearch from './tableSearch'
import './styles.scss'

/**
 * Implementation of TanStack React Table V8
 * @param {Object} params React Params
 * @param {String} [params.id] Unique id (automated testing purposes)
 * @param {Object[]} [params.columns] Table Column Configuration
 * @param {String} [params.className] Extra classnames
 * @param {Number} [params.height] Table body height in px
 * @param {Object[]} [params.data] Table Data
 * @param {Boolean} [params.disableSort] True if sorting is disabled
 * @param {Number} [params.manualCount] Number of total rows we're working with
 * @param {String} [params.manualFilter] Filter string coming from outside (E.g. From Filters)
 * @param {Object} [params.initialState = {}] Initial table state for sorting and pagination
 * @param {Boolean} [params.showSearchInput] Flag to show search in footer.
 * @param {Boolean} [params.showPagination] Flag to show pagination in footer.
 * @param {Array<Number>} [params.paginationValues] Optional options for configuring number of items per page.
 * @param {Boolean} [params.serverSideFetch = false] Flag to control sort and filters from outside the table
 * @param {Function} [params.onSelect] Callback for selecting rows (If falsy, checkboxes don't appear)
 * @param {Function} [params.onSortChanged] Optional callback to listen to/handle sorting changes
 * @param {String} [params.rowHeight] height of rows in pixels
 * @param {String} [params.tableContainer] A unique string for identifying the container where the table is rendered; used for persisting filters and sorting
 * @param {Boolean} [params.loading] Whether the data is loading or not
 * @param {boolean} [params.withDragDrop] enable drag and drop
 * @param {Array} [params.nonDeletableEntities] Array of ids, used for elements that should be deletable
 * @param {Boolean} [params.hasSearch] Whether table search is enabled
 * @param {Function} [params.renderExpandableContent] Custom renderer function for the expandable content
 * @param {Boolean} [params.hasExpandableRows = false] Flag to toggle row expansion on or off
 * @param {Boolean} [params.singleRowExpanded] Flag to indicate that a single row can be expanded at a time
 * @param {Boolean} [params.autoResetPageIndex] If set to true, pagination will be reset to the first page when page-altering state changes eg. data is updated, filters change, grouping changes, etc.
 * @param {Function} [params.onDragDrop] callback firing on item reordering
 * @param {Boolean} [params.manualPagination = false] Flag to control if we want to do manual pagination
 * @param {Number} [params.pageCount] The number of pages
 * @param {Function} [params.onChangeTableView] Callback function for when table paginaton, soritng or filtering is changed
 * @param {Function} [params.hideRowSelectionFn] Func for validating dynamically if the row selection checkbox should be visible
 * @param {Boolean} [params.keepRowSelection] Keep row selection in case of data filtering / loss
 * @param {Boolean} [props.singleSelection = false] Only one row can be selected. Use Radio instead of checkbox
 * @param {String} [params.emptyText] Text to display when no data
 * @returns {Node}
 */
const Table = ({
  id,
  columns,
  className,
  height,
  data,
  disableSort = false,
  initialState = { sortBy: [] },
  manualCount,
  manualFilter,
  serverSideFetch = false,
  showSearchInput,
  showPagination,
  paginationValues,
  onSelect,
  onSortChanged,
  tableContainer,
  loading,
  rowHeight,
  withDragDrop,
  renderExpandableContent = () => {},
  hasExpandableRows,
  hasSearch,
  singleRowExpanded,
  onDragDrop,
  manualPagination = false,
  nonDeletableEntities,
  pageCount,
  onChangeTableView = () => {},
  hideRowSelectionFn = () => {},
  onClick,
  autoResetPageIndex = true,
  customSortTypes,
  keepRowSelection,
  singleSelection = false,
  emptyText,
}) => {
  const [Tooltip, setTooltip] = useTableTooltip()

  const [sorting, setSorting] = useState(initialState.sortBy)
  const [pagination, setPagination] = useState(
    initialState?.pagination || { pageSize: 10, pageIndex: 0 }
  )

  const [rowSelection, setRowSelection] = useState({})
  const [globalFilter, setGlobalFilter] = useState('')
  const [expandedRows, setExpandedRows] = useState({})

  const setIsExpanded = (rowId) => (expanded) =>
    singleRowExpanded
      ? setExpandedRows({ [rowId]: expanded })
      : setExpandedRows({ ...expandedRows, [rowId]: expanded })

  useEffect(() => {
    if (onSelect) {
      const selectedRows = Object.keys(rowSelection).map((rowId) => ({
        ...data.find((row) => row._id === rowId),
        tableRowId: rowId.toString(), // toString is needed as ids are indexes by default (numeric)
      }))
      onSelect(selectedRows)
    }
  }, [JSON.stringify(rowSelection)])

  useEffectOnUpdate(() => {
    const filterSortPagination = {
      ...pagination,
      sort: sorting,
      filter: globalFilter,
    }
    onChangeTableView(filterSortPagination)
  }, [JSON.stringify(pagination)])

  /**
   * Function called when reordering rows by dragging.
   * It will remove the element from the `data` array from it's current index - `dragIndex`.
   * It will add it in it's new position at `hoverIndex`.
   *
   * @param {Number} dragIndex Current index of the dragged element
   * @param {Number} hoverIndex Index of the element over which it's hovering
   */
  const moveRow = (dragIndex, hoverIndex) => {
    /** Only execute reorder if drag and drop functionality is enabled */
    if (withDragDrop) {
      const dragRecord = data[dragIndex]
      const reorderedArray = [...data]
      reorderedArray.splice(dragIndex, 1)
      reorderedArray.splice(hoverIndex, 0, dragRecord)
      onDragDrop && onDragDrop(reorderedArray)
    }
  }

  // Use this memo to add / remove columns for extra table features, Eg. Selection Checkboxes, Locks, etc.
  const _columns = useMemo(() => {
    const _columns = [...columns]
    const _selectColumn = getSelectColumn(
      hideRowSelectionFn,
      nonDeletableEntities,
      singleSelection
    )
    if (onSelect) {
      _columns.unshift(_selectColumn)
    }
    return _columns
  }, [columns, singleSelection])

  const columnVisibility = useMemo(
    () =>
      _columns.reduce((acc, column) => {
        if (typeof column.hidden === 'boolean') {
          return { ...acc, [column.id]: !column.hidden }
        }
        return acc
      }, {}),
    [_columns]
  )

  let tableOptions = {
    columns: _columns,
    data,
    // Meta works much like a context and is accessible from anywhere under the table (immutable!)
    meta: {
      setTooltip,
      moveRow,
    },
    sortingFns: {
      name: (rowA, rowB, columnId) =>
        Intl.Collator('en', { caseFirst: 'upper' }).compare(
          rowA.getValue(columnId),
          rowB.getValue(columnId)
        ),
      publisher: (rowA, rowB, columnId) => {
        const publisherA = ACCOUNT_TYPES.find(
          ({ id }) => id === rowA.getValue(columnId).publisher
        )
        const publisherB = ACCOUNT_TYPES.find(
          ({ id }) => id === rowB.getValue(columnId).publisher
        )
        const publisherOrder = publisherA.order - publisherB.order
        const rowAName = rowA.getValue(columnId).name
        const rowBName = rowB.getValue(columnId).name
        const firstNameToCompare = !sorting[0].desc ? rowAName : rowBName
        const secondNameToCompare = sorting[0].desc ? rowAName : rowBName
        return (
          publisherOrder ||
          Intl.Collator('en', { caseFirst: 'upper' }).compare(
            firstNameToCompare,
            secondNameToCompare
          )
        )
      },
      date: (rowA, rowB, columnId) => {
        const dateA = new Date(rowA.getValue(columnId))
        const dateB = new Date(rowB.getValue(columnId))
        if (dateA > dateB) {
          return -1
        } else if (dateA < dateB) {
          return 1
        }
        return 0
      },
    },
    initialState,
    state: {
      columnVisibility,
      globalFilter,
      sorting,
      rowSelection: rowSelection,
      pageSize: initialState.pageSize || 10,
      pageIndex: initialState.pageIndex || 0,
      pagination,
    },
    enableRowSelection:
      Boolean(onSelect) && ((row) => !row.original.disableSelect),
    enableSorting: !disableSort,
    manualSorting: serverSideFetch,
    getFilteredRowModel: serverSideFetch ? null : getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: showPagination && getPaginationRowModel(),
    onRowSelectionChange: setRowSelection,
    getRowId: (row, index) => row._id || index,
    rowHeight,
    onPaginationChange: setPagination,
    autoResetPageIndex: autoResetPageIndex,
  }

  if (customSortTypes?.length) {
    customSortTypes.forEach((sortFn) => {
      tableOptions.sortingFns[sortFn.name] = sortFn.implementation
    })
  }

  if (manualPagination) {
    tableOptions = {
      ...tableOptions,
      manualPagination,
      pageCount,
    }
  }

  const table = useReactTable(tableOptions)

  // Listen for manualFilter changes and update accordingly
  useEffect(() => {
    if (!showPagination && !showSearchInput) {
      table.setGlobalFilter(manualFilter)
    }
  }, [showPagination, showSearchInput, manualFilter])

  const rowsById = table.getRowModel().rowsById

  useEffect(() => {
    if (Object.keys(rowSelection)?.length && !keepRowSelection) {
      const newSelectedOptions = {}

      Object.keys(rowsById).forEach((existingRow) => {
        if (rowSelection[existingRow]) {
          if (
            initialState.selectedRowIds &&
            !initialState.selectedRowIds.includes(existingRow)
          ) {
            return
          }

          newSelectedOptions[existingRow] = true
        }
      })

      setRowSelection(newSelectedOptions)
    }
  }, [rowsById, initialState.selectedRowIds, keepRowSelection])

  useEffect(() => {
    // Check if keepRowSelection is enabled
    if (keepRowSelection) {
      const newSelectedOptions = {}

      // Set selection based on initialState.selectedRowIds
      initialState.selectedRowIds.forEach((rowId) => {
        newSelectedOptions[rowId] = true
      })

      setRowSelection(newSelectedOptions)
    }
  }, [initialState.selectedRowIds, keepRowSelection])

  useEffect(() => {
    if (tableContainer) {
      setSorting(initialState.sortBy)
    }
  }, [JSON.stringify(initialState), tableContainer])

  // When sorting changes, propagate the changes to the parent component
  useEffect(() => {
    if (tableContainer) {
      onSortChanged && onSortChanged(sorting)
    }
  }, [JSON.stringify(sorting), tableContainer])

  return (
    <DragAndDrop>
      <div
        id={id}
        data-cy={id}
        className={cx('table-wrapper', {
          'table-wrapper--paginated': showPagination,
        })}
      >
        {hasSearch && (
          <TableSearch
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        <div
          className={cx('table', className, {
            'table--loading': loading,
            'table--dragndrop': withDragDrop,
            'table--clickable': onClick,
          })}
          style={height ? { maxHeight: `${height}px` } : {}}
        >
          <Tooltip />
          {loading && <Loader className="table__loader" />}
          <div className="table__head">
            {table.getHeaderGroups().map((headerGroup) => (
              <div className="table__row" key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <div
                    className={cx('table__header', {
                      'table__header--sortable':
                        !disableSort && header.column.getCanSort(),
                    })}
                    style={generateElementStyling(header)}
                    onClick={header.column.getToggleSortingHandler()}
                    onMouseOver={
                      header.column.headerTooltip
                        ? () => {
                            setTooltip(header.column.headerTooltip)
                          }
                        : null
                    }
                    onMouseLeave={
                      header.column.headerTooltip ? () => setTooltip() : null
                    }
                    key={header.id}
                  >
                    <div className="table__header__content">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      {renderSortIcon(header.column.getIsSorted())}
                    </div>
                  </div>
                ))}
                {hasExpandableRows && <div style={{ width: '44px' }} />}
              </div>
            ))}
          </div>
          <div className="table__body">
            {!table.getRowModel().rows.length &&
              renderEmptyContent(loading, emptyText)}
            {table
              .getRowModel()
              .rows.map((row) =>
                withDragDrop ? (
                  <DragAndDropRow
                    onClick={onClick}
                    key={row.id}
                    row={row}
                    table={table}
                  />
                ) : (
                  <Row
                    onClick={onClick}
                    key={row.id}
                    row={row}
                    expandableContent={renderExpandableContent(row)}
                    isExpanded={!!expandedRows[row.id]}
                    table={table}
                    setIsExpanded={setIsExpanded(row.id)}
                  />
                )
              )}
          </div>
        </div>
      </div>
      {(showPagination || showSearchInput) && (
        <TablePagination
          manualCount={manualCount}
          manualFilter={manualFilter}
          paginationValues={paginationValues}
          showSearchInput={showSearchInput}
          showPagination={showPagination}
          table={table}
          setGlobalFilter={setGlobalFilter}
          onChangeTableView={onChangeTableView}
        />
      )}
    </DragAndDrop>
  )
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number,
  data: PropTypes.array.isRequired,
  disableSort: PropTypes.bool,
  initialState: PropTypes.object,
  manualCount: PropTypes.number,
  manualFilter: PropTypes.string,
  showSearchInput: PropTypes.bool,
  showPagination: PropTypes.bool,
  paginationValues: PropTypes.array,
  serverSideFetch: PropTypes.bool,
  onSelect: PropTypes.func,
  onSortChanged: PropTypes.func,
  tableContainer: PropTypes.string,
  loading: PropTypes.bool,
  rowHeight: PropTypes.string,
  withDragDrop: PropTypes.bool,
  onDragDrop: PropTypes.func,
  manualPagination: PropTypes.bool,
  pageCount: PropTypes.number,
  onPaginationChange: PropTypes.func,
  onChangeTableView: PropTypes.func,
  hideRowSelectionFn: PropTypes.func,
  onClick: PropTypes.func,
  autoResetPageIndex: PropTypes.bool,
  customSortTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      implementation: PropTypes.func.isRequired,
    })
  ),
  keepRowSelection: PropTypes.bool,
  hasExpandableRows: PropTypes.bool,
  renderExpandableContent: PropTypes.func,
  singleRowExpanded: PropTypes.bool,
  nonDeletableEntities: PropTypes.array,
  hasSearch: PropTypes.bool,
  singleSelection: PropTypes.bool,
  emptyText: PropTypes.string,
}

export default Table
