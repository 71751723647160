import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { useTooltip } from 'components/tooltip/index'
import './style.scss'

/**
 * Displays a button-like Toggle
 * @param {Object} params
 * @param {String} params.className Extra classnames
 * @param {Boolean} params.disabled Flag to indicate button is disabled
 * @param {Node} params.label Label to display
 * @param {Boolean} params.selected If ButtonToggle is toggled or not
 * @param {String | Object} params.icon Icon src to display
 * @param {Boolean} params.tall Whether it should try to fill up as much space as possible vertically
 * @param {Function} params.onClick Callback when button is clicked
 * @param {Boolean} [params.canDeselect] Whether it should try to fill up as much space as possible vertically
 * @param {String} [params.tooltip] Tooltip text
 */
const ButtonToggle = ({
  className,
  disabled,
  label,
  selected,
  icon,
  iconAfterLabel,
  tall,
  onClick,
  canDeselect = false,
  tooltip,
  ...other
}) => {
  const [showTooltip, hideTooltip, Tooltip] = useTooltip(tooltip)

  return (
    <>
      <Tooltip />
      <button
        className={cx('button-toggle', {
          [className]: Boolean(className),
          ['button-toggle--selected']: selected,
          ['button-toggle--disabled']: disabled,
          ['button-toggle--no-click']: !onClick,
          ['button-toggle--tall']: tall,
        })}
        onClick={(e) => {
          e.preventDefault()
          if (onClick && !disabled && (!selected || canDeselect)) {
            onClick()
          }
        }}
        onMouseOver={showTooltip}
        onMouseOut={hideTooltip}
        {...other}
      >
        <div className="button-toggle__label">
          {icon &&
            (typeof icon === 'object' ? (
              icon
            ) : (
              <div
                data-testid="button-toggle__icon"
                style={{
                  WebkitMaskImage: `url(${icon})`,
                  maskImage: `url(${icon})`,
                  WebkitMaskRepeat: 'no-repeat',
                  maskRepeat: 'no-repeat',
                  WebkitMaskPosition: '50% 50%',
                  maskPosition: '50% 50%',
                  WebkitMaskSize: 'contain',
                  maskSize: 'contain',
                }}
                className="button-toggle__label__icon"
              />
            ))}
          {label && <span className="button-toggle__label__text">{label}</span>}
          {iconAfterLabel && (
            <div
              data-testid="button-toggle__icon--last"
              style={{
                WebkitMaskImage: `url(${iconAfterLabel})`,
                maskImage: `url(${iconAfterLabel})`,
                WebkitMaskRepeat: 'no-repeat',
                maskRepeat: 'no-repeat',
                WebkitMaskPosition: '50% 50%',
                maskPosition: '50% 50%',
                WebkitMaskSize: 'contain',
                maskSize: 'contain',
              }}
              className="button-toggle__label__icon button-toggle__label__icon--last"
            />
          )}
        </div>
      </button>
    </>
  )
}

ButtonToggle.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconAfterLabel: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  tall: PropTypes.bool,
  canDeselect: PropTypes.bool,
  tooltip: PropTypes.string,
}

export default ButtonToggle
