import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/** Store */
import { useStore } from 'store'

/** Components */
import { Dropdown } from 'components/dropdown'
import DropdownWithSubsections from 'components/dropdown-with-subsections'
import TagArea from 'components/tag-area'
import {
  InformationBlock,
  INFORMATION_BLOCK_TYPE,
} from 'components/information-block'
import InputText from 'components/input'
import { RadioNoHooks } from 'components/radio'
import { conditionalProp } from 'components/utils/prop-types'
import Loader from 'components/loader'
import Button from 'components/button'
import WarningIcon from 'components/warning-icon/index'

import { closeModal, openModal } from 'components/modal/actions'
import { createNewFlowWithModifiedAdSets } from 'modules/flow/utils/ad-set'

import { DYNAMIC_SEARCH_ADS_HINT } from 'modules/flow/components/google/common/utils'
import { accounts } from '@decision-sciences/qontrol-common'

const { ACCOUNT_TYPES_MAP } = accounts

const GoogleDynamicSearchAds = ({
  campaign,
  updateSection,
  errors = {},
  readOnly,
  goToCampaign,
  loading,
  adSetsWithSelectedCategoriesOrExactUrls = [],
  setBeforeConfirm,
}) => {
  const { state, dispatch } = useStore()
  const {
    google: { page_feeds: pageFeeds, languages: allLanguages },
  } = state

  const {
    account,
    dynamic_search_ads_setting: {
      domain_name,
      language_code,
      feeds,
      local__targeting_source,
    },
  } = campaign
  const languages = allLanguages[account]

  const [selectedFeeds, setSelectedFeeds] = useState(feeds || [])
  const [allSelected, setAllSelected] = useState(false)

  const setBeforeConfirmIfNeeded = (func) =>
    setBeforeConfirm &&
    adSetsWithSelectedCategoriesOrExactUrls.length &&
    setBeforeConfirm(func)

  const onDomainChange = (value) => {
    setBeforeConfirmIfNeeded(showEditedDSAWarning)
    updateSection('dynamic_search_ads_setting', 'domain_name', value, {
      ...errors,
      domain_name: null,
    })
  }

  const onLanguageSelected = (languageCode) => {
    setBeforeConfirmIfNeeded(showEditedDSAWarning)
    updateSection('dynamic_search_ads_setting', 'language_code', languageCode, {
      ...errors,
      language_code: null,
    })
  }

  /**
   * Function to change the feeds and use_supplied_urls_only fields based on targeting source selected.
   * If targeting source selected is google_index_only, then use_supplied_urls_only needs to be set to false and feeds needs to be null.
   * If targeting source selected is own_feeds_only then use_supplied_urls_only needs to be set to true.
   * If targeting source selected is google_and_own_feeds then use_supplied_urls_only needs to be set to false.
   * For the last two cases feeds is a required field and cannot be null.
   * doc: https://developers.google.com/google-ads/api/docs/dynamic-search-ads/dsa-page-feeds?hl=en
   * @param {String} targeting Selected targeting source
   */
  const onTargetingChange = (targeting) => {
    setBeforeConfirmIfNeeded(showEditedDSAWarning)
    updateSection(
      'dynamic_search_ads_setting',
      'local__targeting_source',
      targeting
    )

    if (targeting === CAMPAIGN_TARGETING_SOURCE.OWN_FEEDS_ONLY.value) {
      updateSection(
        'dynamic_search_ads_setting',
        'use_supplied_urls_only',
        true
      )
    } else if (
      targeting === CAMPAIGN_TARGETING_SOURCE.GOOGLE_AND_OWN_FEEDS.value
    ) {
      updateSection(
        'dynamic_search_ads_setting',
        'use_supplied_urls_only',
        false
      )
    } else {
      updateSection(
        'dynamic_search_ads_setting',
        'use_supplied_urls_only',
        false
      )
      updateSection('dynamic_search_ads_setting', 'feeds', null)
    }
  }

  const onPageFeedChange = (newPageFeeds) => {
    const campaignPageFeeds = pageFeeds[account]
      .filter((feed) => newPageFeeds.includes(feed.resourceName))
      .map((feed) => feed.resourceName)
    setSelectedFeeds(campaignPageFeeds)
    updateSection('dynamic_search_ads_setting', 'feeds', campaignPageFeeds, {
      ...errors,
      feeds: null,
    })
  }

  const onAllFeedsSelected = (allFeeds) => {
    if (allFeeds) {
      const selectedFeeds = pageFeeds[account]?.map((feed) => feed.resourceName)
      setSelectedFeeds(selectedFeeds)
      updateSection('dynamic_search_ads_setting', 'feeds', selectedFeeds, {
        ...errors,
        feeds: null,
      })
    } else {
      setSelectedFeeds([])
      updateSection('dynamic_search_ads_setting', 'feeds', [], {
        ...errors,
        feeds: null,
      })
    }
  }

  const onDeleteTag = (newFeeds) => {
    const feeds = newFeeds.map((feed) => feed.resourceName)
    setSelectedFeeds(feeds)
    updateSection('dynamic_search_ads_setting', 'feeds', feeds)
  }

  useEffect(() => {
    setAllSelected(selectedFeeds.length === pageFeeds[account]?.length)
  }, [JSON.stringify(selectedFeeds)])

  const clearAllSelectedCategoriesAndExactUrls = (flow) => {
    setBeforeConfirm && setBeforeConfirm(null)
    return createNewFlowWithModifiedAdSets(
      flow,
      adSetsWithSelectedCategoriesOrExactUrls,
      ACCOUNT_TYPES_MAP.GOOGLE,
      () => ({
        webpages_exact_urls: [],
        adSetRecommendedCategories: [],
      })
    )
  }

  const showEditedDSAWarning = () => (flow) => {
    return new Promise((resolve, reject) => {
      const budgetConfirmationModalUid = openModal(dispatch, {
        heading: 'Dynamic Search Ad Settings',
        icon: <WarningIcon />,
        rightAlignButtons: true,
        contentSeparator: true,
        children: (
          <>
            <p>
              You have dynamic settings added to an ad group associated with
              this campaign.
            </p>
            <p>
              If you update the Dynamic Search Ad Settings here, any dynamic
              settings will be cleared for all ad groups within this campaign.
            </p>
            <p>
              Click the Confirm button to continue with this change. This action
              cannot be undone.
            </p>
          </>
        ),
        button: (
          <Button
            value="Confirm"
            onClick={() => {
              const updatedFlow = clearAllSelectedCategoriesAndExactUrls(flow)
              closeModal(dispatch, budgetConfirmationModalUid)
              resolve(updatedFlow)
            }}
          />
        ),
        buttonSecondary: (
          <Button
            secondaryGray
            value="Cancel"
            onClick={() => {
              closeModal(dispatch, budgetConfirmationModalUid)
              reject()
            }}
          />
        ),
      })
    })
  }

  const _renderContent = () => (
    <>
      <div className="hint-wrapper margin-y-15">
        Enter the domain and select the language to use for all the dynamic ad
        groups in this campaign.
      </div>
      <div className="margin-top-10">
        <div
          className={cx('general-label', {
            'general-label--error': errors.domain_name,
          })}
        >
          Domain
        </div>
        <InputText
          disabled={readOnly}
          placeholder="Example: website.com"
          value={domain_name || ''}
          onChange={onDomainChange}
          error={errors.domain_name}
          showErrorMessage={false}
        />
      </div>
      <div
        className={cx('general-label', {
          'general-label--error': errors.language_code,
        })}
      >
        Language
      </div>
      <Dropdown
        defaultOptionText="Select Language"
        defaultState={language_code}
        options={
          languages?.map(({ name, code }) => ({
            label: name,
            value: code,
          })) || []
        }
        disabled={readOnly || languages?.length === 0}
        onChange={onLanguageSelected}
        error={errors.language_code}
        showErrorMessage={false}
      />
      <div className="margin-top-10">
        <div className="general-label">Select a targeting source</div>
        {Object.values(CAMPAIGN_TARGETING_SOURCE).map((targeting, idx) => {
          return (
            <RadioNoHooks
              key={idx}
              disabled={readOnly}
              className="blue"
              label={targeting.label}
              checked={local__targeting_source === targeting.value}
              onChange={() => onTargetingChange(targeting.value)}
            />
          )
        })}
        {local__targeting_source !==
          CAMPAIGN_TARGETING_SOURCE.GOOGLE_INDEX_ONLY.value && (
          <>
            <InformationBlock
              type={INFORMATION_BLOCK_TYPE.INFO}
              info={
                Object.values(CAMPAIGN_TARGETING_SOURCE).find(
                  (el) => el.value === local__targeting_source
                )?.info
              }
            />
            <div className="margin-top-10">
              <div className="hint-wrapper margin-y-15">
                Select one or more page feeds from the shared library to use
                this campaign:
              </div>
              <div
                className={cx('general-label', {
                  'general-label--error': !!errors.feeds,
                })}
              >
                Page Feeds
              </div>
              <DropdownWithSubsections
                selectedItems={selectedFeeds}
                disabled={readOnly || pageFeeds[account]?.length === 0}
                options={
                  pageFeeds[account]?.map(({ name, resourceName, id }) => ({
                    label: name,
                    value: resourceName,
                    id: id,
                  })) || []
                }
                error={!!errors.feeds}
                defaultOptionText="Select Page Feed(s) "
                onChange={onPageFeedChange}
                showOptionsInPlaceholder={false}
                selectAllOptions={{
                  label: 'Select All Page Feeds',
                  allSelected: allSelected,
                  onCheck: onAllFeedsSelected,
                }}
              />
              {selectedFeeds && (
                <TagArea
                  tagLabels={selectedFeeds.map((feed) => ({
                    label: pageFeeds[account]?.find(
                      (pageFeed) => pageFeed.resourceName === feed
                    )?.name,
                    resourceName: feed,
                  }))}
                  onDeleteTag={onDeleteTag}
                  allSelected={allSelected}
                  allSelectedLabel={'All Page Feeds'}
                  disabled={readOnly}
                />
              )}
            </div>
          </>
        )}
      </div>

      {readOnly && goToCampaign && (
        <Button value="Edit in Campaign Settings" onClick={goToCampaign} />
      )}
      {!readOnly && !!adSetsWithSelectedCategoriesOrExactUrls.length && (
        <InformationBlock
          type={INFORMATION_BLOCK_TYPE.WARNING}
          info="Editing these settings will clear any dynamic settings made for any of the ad groups within this campaign"
        />
      )}
    </>
  )
  return (
    <div className="form__section__body">
      <div className="dynamic-search-ads">
        <div className="dynamic-search-ads__title">
          Dynamic Search Ad Settings
        </div>
        <div className="hint-wrapper margin-y-15">
          {DYNAMIC_SEARCH_ADS_HINT}
        </div>

        {loading ? <Loader /> : _renderContent()}
      </div>
    </div>
  )
}

GoogleDynamicSearchAds.propTypes = {
  campaign: PropTypes.object.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool,
  loading: PropTypes.bool,
  goToCampaign: PropTypes.func,
  adSetsWithSelectedCategoriesOrExactUrls: PropTypes.array,
  updateSection: (props, propName, componentName) =>
    conditionalProp(props, propName, componentName, 'readOnly', 'function'),
}

export default GoogleDynamicSearchAds

export const CAMPAIGN_TARGETING_SOURCE = {
  GOOGLE_INDEX_ONLY: {
    value: 'google_index_only',
    label: 'Use Google’s index of my website',
  },
  OWN_FEEDS_ONLY: {
    value: 'own_feeds_only',
    label: 'Use URLs from my page feed only',
    info: 'This option includes only the webpages in your feed, whether you use custom labels or specific rules. Webpages from Google’s index of your site are not included. Examples: An “All Webpages” auto target will use all the URLs in your feed and no others. A target containing the word “book” will use only URLs that are in your feed and include the word “book”.',
  },
  GOOGLE_AND_OWN_FEEDS: {
    value: 'google_and_own_feeds',
    label: 'Use URLs from both Google’s index of my website and my page feed',
    info: 'This option includes pages found in either way. Use this option is you want to get maximum reach but also want to adjust your bid or creatives for specific pages.',
  },
}

