import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/* Components */
import InputText from 'components/input/index'
import { CheckboxNoHooks } from 'components/checkbox'
import LineDivider from 'components/line-divider/index'

/* Hooks */
import { useOnClickOutside } from 'hooks/outside-click'

/* Utils */
import { calculateListPosition } from 'components/utils/list-position'

/* Styles */
import 'components/dropdown/style.scss'
import iconMinus from 'assets/icon_minus_blue.svg'
import { ReactComponent as FiltersIcon } from 'assets/filters.svg'
import { ReactComponent as PlusIcon } from 'assets/icon_plus_blue.svg'
import { ReactComponent as EditIcon } from 'assets/icon_edit.svg'
import ScssConstants from 'styles/shared.module.scss'
import './style.scss'

/**
 * A custom Dropdown component that allows selecting multiple items and previews the selected items at the top of the dropdown.
 *
 * @param {Object} props - The props object.
 * @param {String} [props.className] React Children
 * @param {Array<Object>} props.options - The array of options to display in the dropdown.
 * @param {function} props.optionRenderer - The function that renders each option in the dropdown.
 * @param {Node} [props.defaultOptionToShow=''] - The default option to display.
 * @param {String} [params.placeholder] Text placeholder to be displayed when there isn't an option selected
 * @param {Boolean} [params.selectAll] Show selectAll/deselectAll
 * @param {Array<Object>} props.selectedItems - The array of selected items to display at the top of the dropdown.
 * @param {Boolean} [params.displayArrow] - Flag to display dropdown arrow at all times. Arrow is displayed by default only on click.
 * @param {Boolean} [params.disabled] - Condition to indicate dropdown should be disabled
 * @param {Boolean} [params.defaultOpened] - True if list should be by default opened
 * @param {function} [params.onDefaultOpen] - Function to be called after the list is oped by default
 * @param {function} props.onChange - The function to call when the selected items change.
 * @param {Boolean} [props.isSingleSelection] - True if we want only single selection in the dropdown
 * @param {Number} [props.optionsHeight] Max height of selectable option section in pixels
 */
export const DropdownPreviewSelected = ({
  className,
  options,
  optionsHeight,
  optionRenderer,
  defaultOptionToShow = '',
  placeholder = '',
  selectAll,
  selectedItems,
  onChange,
  error,
  displayArrow = false,
  labelHeader = '',
  customHeader = false,
  dark = false,
  disabled = false,
  defaultOpened = false,
  onDefaultOpen = () => {},
  isSingleSelection = false,
}) => {
  const [searchText, setSearchText] = useState('')
  const [listOpened, setListOpened] = useState(false)
  const [optionsDisplayedUpwards, setOptionsDisplayedUpwards] = useState(false)
  const [allSelected, setAllSelected] = useState(false)

  const dropdownRef = useRef()
  const optionsRef = useRef()
  const spanRef = useRef(null)
  const searchRef = useRef(null)

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchText.toLowerCase())
  )

  useEffect(() => {
    if (selectAll && options.length) {
      setAllSelected(selectedItems.length === options.length)
    }
  }, [JSON.stringify(selectedItems)])

  useEffect(() => {
    if (defaultOpened) {
      setListOpened(true)
      onDefaultOpen()
    }
  }, [defaultOpened])

  useEffect(() => {
    // Focus text element on show input
    if (listOpened && spanRef.current) {
      setTimeout(() => {
        spanRef.current.focus()
      }, 50)
    }
  }, [listOpened, spanRef.current])

  useEffect(() => {
    if (listOpened) {
      searchRef && searchRef.current?.focus()
    }
  }, [listOpened, searchRef.current])

  useOnClickOutside(dropdownRef, () => {
    if (listOpened) {
      setSearchText('')
      setListOpened(false)
    }
  })

  const toggleListOpened = () => {
    setOptionsDisplayedUpwards(
      calculateListPosition(
        listOpened,
        optionsRef,
        optionsHeight || ScssConstants.multiSelectDropdownHeight
      )
    )

    !disabled && setListOpened(!listOpened)
  }

  const onKeyDown = (e) => {
    const { keyCode } = e
    // Hitting enter closes the list
    if (keyCode === 13) {
      setSearchText('')
      setListOpened(false)
    }
  }

  /** On item changed handler */
  const onOptionSelected = (value, e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    let newArray
    if (!isSingleSelection) {
      newArray = [...selectedItems]
      const index = newArray.indexOf(value)
      // If option is not selected, select it
      if (index === -1) {
        newArray.push(value)
      } else {
        // Else remove it
        newArray.splice(index, 1)
      }
      selectAll && setAllSelected(newArray.length === options.length)
    } else {
      newArray = [value]
      setListOpened(false)
    }

    onChange(newArray)
  }

  const setAllOptionSelected = () => {
    let newSelections = []
    const allOptionsSelected =
      filteredOptions.length === options.length && allSelected

    // If all options selected, deselect all options
    if (allOptionsSelected) {
      onChange(newSelections)
      return
    }
    if (filteredOptions.length) {
      newSelections = filteredOptions.map((option) => option.value)
    } else {
      newSelections = options.map((option) => option.value)
    }

    onChange(newSelections)
  }

  const handleSearch = (value) => {
    setSearchText(value)
  }

  const selectedOptions = options.filter((option) =>
    selectedItems.includes(option.value)
  )

  const selectedOptionValues = !searchText
    ? selectedOptions.map((selectedOption) => selectedOption.value)
    : []

  const _renderIcon = () => {
    const plusIcon = <PlusIcon className="fill-light-blue" />
    const editIcon = <EditIcon className="fill-light-blue" />
    if (!selectedOptions?.length) {
      return plusIcon
    }
    return isSingleSelection ? editIcon : plusIcon
  }

  return (
    <div
      className={cx('dropdown-preview-selected select', {
        [className]: className,
        'select--disabled': disabled,
        'select--opened': listOpened || displayArrow,
        'dropdown-preview-selected--dark': dark,
        'dropdown-preview-selected__custom': customHeader,
        'dropdown-preview-selected__custom--closed':
          customHeader && !listOpened,
      })}
      data-testid="dropdown-preview-selected"
      role="listbox"
      ref={dropdownRef}
      tabIndex={0}
      onKeyDown={onKeyDown}
      onClick={toggleListOpened}
    >
      {customHeader ? (
        <>
          {!listOpened ? (
            _renderIcon()
          ) : (
            <div
              onClick={(e) => e.stopPropagation()}
              className="align-row width-100 height-100"
            >
              <div className="dropdown-filters-icon">
                <FiltersIcon className="fill-light-blue" />
              </div>
              <span
                ref={spanRef}
                role="textbox"
                className="dropdown-search"
                contentEditable={true}
                onInput={(e) => setSearchText(e.currentTarget.textContent)}
              />
            </div>
          )}
        </>
      ) : (
        <div
          role="option"
          className={cx('margin-left-10', { 'color-red-important': error })}
        >
          {selectedItems?.length ? (
            defaultOptionToShow
          ) : (
            <div className="dropdown--placeholder">{placeholder}</div>
          )}
        </div>
      )}

      <div
        ref={optionsRef}
        className={cx('dropdown-preview-selected-list', {
          'dropdown-preview-selected-list--up': optionsDisplayedUpwards,
        })}
        style={optionsHeight ? { maxHeight: `${optionsHeight}px` } : {}}
      >
        {listOpened && (
          <>
            {!customHeader ? (
              <>
                <div
                  className="select__search--bottom"
                  onClick={(e) => e.stopPropagation()}
                >
                  <InputText
                    customRef={searchRef}
                    type="text"
                    searchBlue
                    className="dropdown-search"
                    placeholder="Search..."
                    value={searchText}
                    onChange={handleSearch}
                  />
                </div>
                <LineDivider />
              </>
            ) : null}

            {labelHeader ? (
              <>
                <div className="padding-x-10 padding-y-10">{labelHeader}</div>
                <LineDivider />
              </>
            ) : null}

            {selectAll && filteredOptions.length ? (
              <>
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="dropdown-preview-selected-list__select-all"
                >
                  <div role="option" tabIndex="0" data-index={0}>
                    <CheckboxNoHooks
                      label={'Select All'}
                      className="display-flex"
                      defaultValue={
                        allSelected ? allSelected : !!selectedOptions.length
                      }
                      customIcon={
                        selectedOptions.length && !allSelected
                          ? iconMinus
                          : null
                      }
                      onChange={() => {
                        setAllSelected(!allSelected)
                        setAllOptionSelected()
                      }}
                    />
                  </div>
                </div>

                <LineDivider />
              </>
            ) : null}

            {!searchText &&
              selectedOptions.map((option, index) => (
                <div
                  key={index}
                  onClick={(e) => {
                    e.preventDefault()
                    onOptionSelected(option.value, e)
                  }}
                  className="dropdown-preview-selected-list__item"
                >
                  {optionRenderer
                    ? optionRenderer(option, selectedItems)
                    : option?.label}
                </div>
              ))}
            {selectedOptions.length ? (
              <LineDivider className="margin-bottom-16" />
            ) : null}
            {filteredOptions.length ? (
              filteredOptions
                .filter(
                  (option) => !selectedOptionValues.includes(option.value)
                )
                .map((option) => (
                  <div
                    className="dropdown-preview-selected-list__item"
                    key={option.value}
                    onClick={(e) => {
                      e.preventDefault()
                      onOptionSelected(option.value, e)
                    }}
                  >
                    {optionRenderer ? (
                      <div>{optionRenderer(option, selectedItems)}</div>
                    ) : (
                      option?.label
                    )}
                  </div>
                ))
            ) : (
              <div
                className="select__no-results"
                onClick={(e) => e.stopPropagation()}
              >
                No Results
              </div>
            )}
            {}
          </>
        )}
      </div>
    </div>
  )
}

DropdownPreviewSelected.propTypes = {
  isConversionDropdown: PropTypes.bool,
  options: PropTypes.array,
  optionRenderer: PropTypes.func,
  defaultOptionToShow: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  selectedItems: PropTypes.array,
  selectAll: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.any,
  dark: PropTypes.bool,
  labelHeader: PropTypes.string,
  customHeader: PropTypes.bool,
  displayArrow: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultOpened: PropTypes.bool,
  onDefaultOpen: PropTypes.func,
  isSingleSelection: PropTypes.bool,
  optionsHeight: PropTypes.number,
}

export default DropdownPreviewSelected
