/**
 * Maps a KPI object to the structure required for the dropdown option
 * @param {String} group Group the dropdown option belongs to
 * @returns {(kpi: Object) => Object}
 */
export const mapKpiOption = (group) => (kpi) => ({
  ...kpi,
  value: kpi.key,
  group,
})

/**
 * Groups clicks and views entries from all companies into two sets, while taking into account updates done to the original configuration
 * @param {Array} companies Initial companies objects
 * @param {Array} updatedCompanies Updated companies objects
 * @param {String} kpiField KPI field to extract data from -- primaryKPIs, secondaryKPIs, dynamicMetrics
 * @returns {{clicks: Set, views: Set}}
 */
export const extractAttributionSetFromCompanies = (
  companies,
  updatedCompanies,
  kpiField
) => {
  return companies.reduce(
    (acc, company) => {
      const updatedBusinessUnit = updatedCompanies.find(
        (bu) => bu._id === company._id
      )
      // If the field is not present on the original item nor on the updated entity, skip processing
      if (
        !company?.facebookKPIAttribution?.[kpiField] &&
        (!updatedBusinessUnit ||
          !updatedBusinessUnit.facebookKPIAttribution?.[kpiField])
      ) {
        acc.clicks.add(null)
        acc.views.add(null)
        return acc
      }
      // If we have the company in the updatedBusinessUnits (pendingChanges) array, then we use that as the source, otherwise the original object
      const { clicks, views } = updatedBusinessUnit?.facebookKPIAttribution?.[
        kpiField
      ]
        ? updatedBusinessUnit?.facebookKPIAttribution?.[kpiField]
        : company.facebookKPIAttribution[kpiField] || {}
      acc.clicks.add(clicks || null)
      acc.views.add(views || null)
      return acc
    },
    { clicks: new Set(), views: new Set() }
  )
}

/**
 * Extracts a configuration object which specifies if the clicks/views sets are the same across multiple companies and what their values are
 * @param {{clicks: Set, views: Set}} kpiSet Data set to extract data from
 * @returns {Object}
 */
export const getIntersectionAttributionValues = (kpiSet) => {
  const clickSetSize = kpiSet.clicks.size
  const viewSetSize = kpiSet.views.size
  const config = {
    clicks: { variable: clickSetSize > 1, value: null },
    views: { variable: viewSetSize > 1, value: null },
  }
  if (clickSetSize && clickSetSize < 2) {
    const [head] = kpiSet.clicks
    config.clicks.value = head
  }
  if (viewSetSize && viewSetSize < 2) {
    const [head] = kpiSet.views
    config.views.value = head
  }
  return config
}
