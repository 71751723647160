import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Api from 'easy-fetch-api'
import { useNavigate } from 'react-router-dom'
import merge from 'lodash.merge'
import { socket } from '@decision-sciences/qontrol-common'

import InputText from 'components/input'
import Button from 'components/button'
import {
  showSuccessMessage,
  showErrorMessage,
  showWarningMessage,
} from 'modules/notifications/actions'
import { useStore } from 'store'
import useSession from 'modules/session'
import { useSocket } from 'components/utils/socket'
import {
  initialiseBackFillClientdata,
  checkForActiveBackfillJobs,
} from 'modules/companies/actions'

import { API_CONFIG_FIELDS } from './constants'
import './style.scss'
const { NOTIFICATIONS } = socket

const ConfigModule = ({ company, config }) => {
  const configTypes = {}
  API_CONFIG_FIELDS.forEach((el) => (configTypes[el.key] = {}))

  const [fields, setFields] = useState(merge({}, config, configTypes))
  const [configLoading, setConfigLoading] = useState(false)
  const [backFillLoading, setBackFillLoading] = useState(false)

  const {
    dispatch,
    state: {
      global: {
        environment: { SHOW_CLIENTS_TOGGLE },
      },
    },
  } = useStore()
  const [, user] = useSession()
  const navigate = useNavigate()
  const isGlobal = company === '3q'
  const canToggleClients = SHOW_CLIENTS_TOGGLE === 'on' && isGlobal
  const showBackFillButton = !isGlobal && user && user.isSuperAdmin

  const socket = useSocket({ room: [`${company}`] })

  useEffect(() => {
    if (socket?.connected) {
      socket.on(NOTIFICATIONS.backFillClient.receive, (data) => {
        if (data.success) {
          setBackFillLoading(false)
          showSuccessMessage(
            'Client data has been succesfully backfilled',
            dispatch
          )
        } else {
          setBackFillLoading(false)
          showErrorMessage('Error backfilling client data', dispatch)
        }
      })
    }
    return () =>
      socket?.removeAllListeners(NOTIFICATIONS.backFillClient.receive)
  }, [socket?.connected])

  useEffect(() => {
    if (company && !isGlobal) {
      checkForActiveBackfillJobs(company).then((res) => {
        if (res && res.success) {
          setBackFillLoading(res.alreadyExists)
          res.alreadyExists &&
            showWarningMessage('Backfilling job already exists', dispatch)
        } else {
          showErrorMessage(res.error, dispatch)
        }
      })
    }
  }, [company])

  const onFieldChange = (configName, fieldName) => (value) => {
    const newFields = { ...fields }
    newFields[configName][fieldName] = value
    setFields(newFields)
  }

  const onBackfill = () =>
    initialiseBackFillClientdata(company).then((res) => {
      if (res.success) {
        setBackFillLoading(true)
        showSuccessMessage('Backfilling job created succesfully', dispatch)
      } else {
        showErrorMessage(res.error, dispatch)
      }
    })

  const saveConfig = (event) => {
    event.preventDefault()
    setConfigLoading(true)
    Api.post({
      url: `/api/config/${company}`,
      data: { fields },
    })
      .then(() => {
        showSuccessMessage('Company configuration saved', dispatch)
        navigate('/')
      })
      .catch(() => showErrorMessage('Something went wrong', dispatch))
      .finally(() => setConfigLoading(false))
  }

  const onClientsToggle = (setActive) => (event) => {
    event.preventDefault()
    setConfigLoading(true)
    Api.post({
      url: `/api/config/toggle-clients`,
      data: { active: setActive },
    })
      .then((result) => {
        if (result && result.success) {
          const message =
            result.modifiedCount > 0
              ? `${result.modifiedCount} ${
                  result.modifiedCount !== 1 ? 'clients were' : 'client was'
                } set ${
                  setActive === true ? 'active' : 'inactive'
                } successfully.`
              : `No clients were updated.`
          showSuccessMessage(message, dispatch)
        } else {
          showErrorMessage('Something went wrong', dispatch)
        }
      })
      .catch(() => showErrorMessage('Something went wrong', dispatch))
      .finally(() => setConfigLoading(false))
  }

  return (
    <div>
      {canToggleClients && (
        <div className="client-actions">
          <p>
            Use below buttons to set clients active / inactive for lower
            environments (STAGE, QA, DEV). These are mainly used by SFAW for
            testing purposes.
          </p>
          <p>
            Set Clients Active activates all clients, while Set Clients Inactive
            inactivates all except for the ones that have a dedicated Stage
            Slack channel, as those are used by the Qontrol team.
          </p>
          <div className="client-actions__buttons">
            <Button
              value="Set Clients Active"
              disabled={configLoading}
              onClick={onClientsToggle(true)}
            />
            <Button
              value="Set Clients Inactive"
              disabled={configLoading}
              onClick={onClientsToggle(false)}
            />
          </div>
        </div>
      )}

      <form className="config" onSubmit={saveConfig}>
        {API_CONFIG_FIELDS.map((account) => (
          <section className="config__item" key={account.key}>
            <h4>{account.name}</h4>
            {Object.keys(account.config).map((fieldKey) => (
              <InputText
                key={fieldKey}
                label={fieldKey}
                onChange={onFieldChange(account.key, fieldKey)}
                disabled={configLoading}
                value={fields[account.key][fieldKey]}
              />
            ))}
          </section>
        ))}
        <Button value="Save" disabled={configLoading} onClick={saveConfig} />
        {showBackFillButton ? (
          <Button
            value="Backfill client data"
            disabled={backFillLoading}
            loading={backFillLoading}
            onClick={onBackfill}
          />
        ) : null}
      </form>
    </div>
  )
}

ConfigModule.propTypes = {
  company: PropTypes.string.isRequired,
  config: PropTypes.object,
}

export default ConfigModule
