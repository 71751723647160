export const API_CONFIG_FIELDS = [
  {
    key: 'amazon',
    name: 'Amazon DSP and Amazon Advertising',
    config: {
      client_id: '',
      client_secret: '',
      refresh_token: '',
      agency_id: '',
    },
  },
  {
    key: 'google',
    name: 'Google',
    config: {
      customer_account_id: '',
      manager_account_id: '',
      client_id: '',
      client_secret: '',
      developer_token: '',
      refresh_token: '',
    },
  },
  {
    key: 'facebook',
    name: 'Meta',
    config: {
      token: '',
      user_token: '',
    },
  },
  {
    key: 'bing',
    name: 'Bing',
    config: {
      client_id: '',
      client_secret: '',
      developer_token: '',
      refresh_token: '',
      auth_token: '',
      expire_in: '',
      refresh_token_expired: '0',
    },
  },
  {
    key: 'tradeDesk',
    name: 'Trade Desk',
    config: {
      'TTD-Auth': '',
      partnerId: '',
    },
  },
  {
    key: 'linkedIn',
    name: 'LinkedIn',
    config: {
      bearer_auth_token: '',
      refresh_token: '',
      client_id: '',
      client_secret: '',
    },
  },
  {
    key: 'tiktok',
    name: 'Tiktok',
    config: {
      access_token: '',
      app_id: '',
      secret: '',
    },
  },
  {
    key: 'snapchat',
    name: 'SnapChat',
    config: {
      client_id: '',
      client_secret: '',
      refresh_token: '',
    },
  },
  {
    key: 'snowflake',
    name: 'SnowFlake',
    config: {
      account_id: '',
    },
  },
  {
    key: 'apple',
    name: 'Apple',
    config: {
      client_id: '',
      client_secret: '',
    },
  },
  {
    key: 'twitter',
    name: 'Twitter',
    config: {
      consumer_key: '',
      consumer_secret: '',
      access_token: '',
      token_secret: '',
    },
  },
  {
    key: 'reddit',
    name: 'Reddit',
    config: {
      sender_id: '',
      client_id: '',
      client_secret: '',
      refresh_token: '',
    },
  },
  {
    key: 'pinterest',
    name: 'Pinterest',
    config: {
      client_id: '',
      client_secret: '',
      refresh_token: '',
      scope: '',
    },
  },
]
