import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'store'

import { Dropdown } from 'components/dropdown'
import Button from 'components/button'
import cx from 'classnames'
import InputGroup from 'components/input-group'
import { notifications } from '@decision-sciences/qontrol-common'

import './style.scss'

const { DELIVERY_OPTIONS_MAP } = notifications

const emptyState = { channels: [], type: '', entities: [], readonly: false }

const SendNotificationOptions = ({
  channels,
  types,
  users,
  teams,
  onChange,
  errors = {},
  defaultState,
  defaultTextMethod = 'Select method',
  defaultTextType = 'Select User / Team',
  companies,
  readOnly,
  allCompaniesSelected,
  showInGroup,
}) => {
  const [state, setState] = useState([{ ...emptyState }])
  const [duplicates, setDuplicates] = useState({})
  const parsedTeams = teams
    ? teams.map((t) => ({ value: t._id, label: t.name }))
    : []

  const { state: store } = useStore()
  const {
    companies: { currentCompany },
  } = store

  const parsedUsers = (users || [])
    ?.filter((u) => {
      if (allCompaniesSelected) {
        return u.clients.length !== 0
      }

      const checkUserInClient = (client) => {
        return (companies?.length ? companies : [currentCompany._id])?.some(
          (companyId) =>
            companyId === client?.clientId ||
            client.businessUnits.some((bu) => bu === companyId)
        )
      }
      return u.clients?.some(checkUserInClient)
    })
    ?.map((u) => ({ value: u._id, label: u.name }))

  // On changing companies remove selected users that no longer belong to any selected company
  useEffect(() => {
    state.map((row, idx) => {
      if (state[idx].type === 'users') {
        // get selected users that still belong to a selected company
        const stillOkUsers = users
          .filter(
            (u) =>
              // User was selected
              state[idx].entities.includes(u._id) &&
              // User clients are still selected
              u.clients.some((client) =>
                (companies?.length
                  ? companies
                  : [currentCompany._id]
                )?.includes(client?.clientId)
              )
          )
          .map((u) => u._id)

        onDropdownSelected(idx, 'entities', stillOkUsers)
      }
    })
  }, [currentCompany, JSON.stringify(companies)])

  useEffect(() => {
    if (defaultState && defaultState.length) {
      setState(defaultState)
    }
  }, [JSON.stringify(defaultState)])

  const addRow = () => {
    const newState = [...state]
    newState.push({ ...emptyState })
    setState(newState)
    onChange(newState)
  }

  const removeRow = (idx, event) => {
    event.preventDefault()
    let newState = [...state]
    newState.splice(idx, 1)
    if (newState.length === 0) {
      newState = [{ ...emptyState }]
    }
    setDuplicates({ ...duplicates, [idx]: false })
    setState(newState)
    onChange(newState)
  }
  /** Generic dropdown change handler */
  const onDropdownSelected = (rowIndex, fieldName, fieldValue) => {
    const newState = [...state]
    newState[rowIndex][fieldName] = fieldValue
    // If type changes, reset the entities
    if (fieldName === 'type') {
      newState[rowIndex].entities = []
    }
    if (
      state.some((source, sourceIndex) =>
        state.some(
          (target, targetIndex) =>
            targetIndex !== sourceIndex &&
            JSON.stringify(target) === JSON.stringify(source)
        )
      )
    ) {
      setDuplicates({ ...duplicates, [rowIndex]: true })
    } else {
      setDuplicates({ ...duplicates, [rowIndex]: false })
    }

    setState(newState)
    onChange(newState)
  }

  return (
    <div className="send-notifications">
      {state.map((row, idx) => {
        let optionsForEntity = []
        if (state[idx].type === 'teams') {
          optionsForEntity = parsedTeams
        } else if (state[idx].type === 'users') {
          optionsForEntity = parsedUsers
        }
        const disabled = row.readonly
        const className = cx('send-notifications__row form__section__body', {
          'send-notifications__warning': row.companySpecific,
          'send-notifications__duplicate': duplicates[idx],
        })

        /** Show Users and Teams dropdowns if slack is not the only selected channel */
        const showUsersTeamsDropdowns = !(
          state[idx].channels?.length === 1 &&
          state[idx].channels[0] === DELIVERY_OPTIONS_MAP.SLACK
        )

        const renderOption = () => {
          return (
            <>
              <label className="send-notifications__label">
                Send notifications via:
              </label>
              <Dropdown
                options={channels}
                multiSelect={true}
                selectedItems={state[idx].channels || []}
                onChange={(value) => onDropdownSelected(idx, 'channels', value)}
                error={errors[idx] && errors[idx].channels}
                defaultOptionText={defaultTextMethod}
                disabled={disabled || readOnly}
              />
              {showUsersTeamsDropdowns && (
                <>
                  <label className="send-notifications__to send-notifications__label">
                    to
                  </label>
                  <Dropdown
                    options={types}
                    onChange={(value) => onDropdownSelected(idx, 'type', value)}
                    defaultState={state[idx].type}
                    error={errors[idx] && errors[idx].type}
                    defaultOptionText={defaultTextType}
                    disabled={disabled || readOnly || !showUsersTeamsDropdowns}
                  />
                  <Dropdown
                    options={optionsForEntity}
                    className="send-notifications__entities"
                    multiSelect={true}
                    onChange={(value) =>
                      onDropdownSelected(idx, 'entities', value)
                    }
                    selectedItems={state[idx].entities}
                    error={errors[idx] && errors[idx].entities}
                    disabled={disabled || readOnly || !showUsersTeamsDropdowns}
                    hasSearch
                  />
                </>
              )}

              {!readOnly && (
                <div className="send-notifications__buttons wrap">
                  <Button
                    value="–"
                    onClick={(e) => removeRow(idx, e)}
                    className="minus"
                    disabled={disabled}
                  />
                  {idx === state.length - 1 && (
                    <Button value="+" onClick={addRow} />
                  )}
                </div>
              )}
            </>
          )
        }
        /**
         * Alternate render style for Bulk Edit, same logic
         */
        if (showInGroup) {
          return (
            <InputGroup
              key={idx}
              error={duplicates[idx] && 'Duplicates are not allowed'}
              options={[
                {
                  render: <label>Send notifications via:</label>,
                  width: 200,
                },
                {
                  render: (
                    <Dropdown
                      options={channels}
                      multiSelect={true}
                      selectedItems={state[idx].channels || []}
                      onChange={(value) =>
                        onDropdownSelected(idx, 'channels', value)
                      }
                      error={errors[idx] && errors[idx].channels}
                      defaultOptionText={defaultTextMethod}
                      disabled={disabled || readOnly}
                    />
                  ),
                },
                {
                  render: (
                    <label className="send-notifications__to send-notifications__label">
                      to
                    </label>
                  ),
                  width: 62,
                  condition: showUsersTeamsDropdowns,
                },
                {
                  render: (
                    <Dropdown
                      options={types}
                      onChange={(value) =>
                        onDropdownSelected(idx, 'type', value)
                      }
                      defaultState={state[idx].type}
                      error={errors[idx] && errors[idx].type}
                      defaultOptionText={defaultTextType}
                      disabled={
                        disabled || readOnly || !showUsersTeamsDropdowns
                      }
                    />
                  ),
                  condition: showUsersTeamsDropdowns,
                },
                {
                  render: (
                    <Dropdown
                      options={optionsForEntity}
                      multiSelect={true}
                      onChange={(value) =>
                        onDropdownSelected(idx, 'entities', value)
                      }
                      selectedItems={state[idx].entities}
                      error={errors[idx] && errors[idx].entities}
                      disabled={
                        disabled || readOnly || !showUsersTeamsDropdowns
                      }
                      hasSearch
                    />
                  ),
                  condition: showUsersTeamsDropdowns,
                },
                {
                  render: (
                    <div
                      className="send-notifications__row__minus"
                      onClick={(e) => removeRow(idx, e)}
                    />
                  ),
                  condition: !readOnly,
                  width: 48,
                },
                {
                  render: (
                    <>
                      {idx === state.length - 1 && (
                        <div
                          className="send-notifications__row__plus"
                          onClick={addRow}
                        />
                      )}
                    </>
                  ),
                  condition: !readOnly,
                  width: 48,
                },
              ]}
            />
          )
        }
        return (
          <div key={idx} className={className}>
            {renderOption()}
          </div>
        )
      })}
    </div>
  )
}

SendNotificationOptions.propTypes = {
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  users: PropTypes.array,
  teams: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  defaultState: PropTypes.array,
  defaultTextMethod: PropTypes.string,
  defaultTextType: PropTypes.string,
  companies: PropTypes.array,
  readOnly: PropTypes.bool,
  allCompaniesSelected: PropTypes.bool,
  showInGroup: PropTypes.bool,
}

export default SendNotificationOptions
