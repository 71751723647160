import React from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { reports } from '@decision-sciences/qontrol-common'

/* Components */
import Table from 'components/table/beta/index'
import Icon from 'components/icon/index'

/* Icons */
import { ReactComponent as EditIcon } from 'assets/icon_edit.svg'
import { ReactComponent as RemoveIcon } from 'assets/icon_minus_red.svg'

/* Utils */
import { getTooltipList } from 'components/utils/tooltip'
import { PAGE_MODE } from './utils'

const { REPORT_SOURCE_LABEL, getSourceBasedOnURL } = reports

/**
 * Reporting Views Section
 */
const ReportsTable = ({
  reports = [],
  selectedReports,
  setSelectedReports,
  setReportsToDelete,
  disableActions,
  setMode,
  viewMode,
}) => {
  const columns = [
    {
      header: '',
      id: 'edit-report',
      size: 40,
      maxSize: 40,
      textAlign: 'center',
      cell: (cell) => (
        <Icon
          disabled={disableActions}
          onClick={() => {
            if (!disableActions) {
              setMode(PAGE_MODE.EDIT)
              setSelectedReports([cell.row.original])
            }
          }}
        >
          <EditIcon />
        </Icon>
      ),
      hidden: viewMode,
    },
    {
      header: 'Name',
      id: 'report-name',
      textAlign: 'left',
      accessorFn: (row) => row.name,
      size: 120,
    },
    {
      header: 'Source',
      id: 'report-source',
      textAlign: 'left',
      accessorFn: (row) =>
        REPORT_SOURCE_LABEL[row.source ? row.source : getSourceBasedOnURL(row)],
      size: 40,
    },
    {
      header: 'Creator',
      id: 'report-creator',
      textAlign: 'left',
      accessorFn: ({ owner }) => `${owner.firstName} ${owner.lastName}`,
      size: 80,
    },
    {
      header: 'Users',
      id: 'report-users',
      accessorFn: ({ userPermissions, teamPermissions }) =>
        userPermissions?.length + teamPermissions?.length,
      tooltip: ({ userPermissions, teamPermissions }) => {
        return userPermissions.length || teamPermissions.length
          ? getTooltipList('Users with Report Permissions ', [
              ...userPermissions.map(
                ({ firstName, lastName }) => `${firstName} ${lastName}`
              ),
              ...teamPermissions.map(({ name }) => name),
            ])
          : null
      },
      textAlign: 'left',
      size: 40,
      maxSize: 80,
    },
    {
      header: 'Modified',
      id: 'report-modified',
      accessorKey: 'updatedAt',
      cell: (cell) => {
        const updatedAt = cell.row.original.updatedAt
        return updatedAt ? format(new Date(updatedAt), 'MM/dd/yyyy') : ''
      },
      tooltip: ({ updatedAt }) =>
        updatedAt ? format(new Date(updatedAt), 'HH:mm a') : '',
      textAlign: 'left',
      size: 80,
      maxSize: 120,
    },
    {
      header: '',
      id: 'delete-report',
      textAlign: 'center',
      size: 40,
      maxSize: 40,
      cell: (cell) => (
        <Icon
          disabled={disableActions}
          onClick={() =>
            !disableActions && setReportsToDelete([cell.row.original])
          }
        >
          <RemoveIcon
            className={cx('fill-red', { disabled: disableActions })}
          />
        </Icon>
      ),
      hidden: viewMode,
    },
  ]

  return (
    <>
      <Table
        id="reports-table"
        columns={columns}
        data={reports.map((report) => ({
          ...report,
          disableSelect: disableActions,
        }))}
        onSelect={!viewMode ? setSelectedReports : null}
        initialState={{
          selectedRowIds: selectedReports.map((r) => r.tableRowId),
        }}
      />
    </>
  )
}

export default ReportsTable

ReportsTable.propTypes = {
  reports: PropTypes.array.isRequired,
  selectedReports: PropTypes.array.isRequired,
  setSelectedReports: PropTypes.func.isRequired,
  setReportsToDelete: PropTypes.func.isRequired,
  disableActions: PropTypes.bool,
  setMode: PropTypes.func.isRequired,
  viewMode: PropTypes.bool.isRequired,
}
