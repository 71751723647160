import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'components/dropdown/index'
import InformationBlock, {
  INFORMATION_BLOCK_TYPE,
} from 'components/information-block/index'
import { metrics } from '@decision-sciences/qontrol-common'
import { KpiCompanyFilter } from '../companies-filter/index'
import {
  extractAttributionSetFromCompanies,
  getIntersectionAttributionValues,
} from '../utils'
import './style.scss'

const { SPECIAL_METRICS_FACEBOOK_ATTRIBUTIONS } = metrics

const CLICK = 'click'
const VIEW = 'view'
const VARIABLE_PLACEHOLDER = '<varies>'
const ATTRIBUTION_FIELDS = {
  PRIMARY_KPIS: 'primaryKPIs',
  SECONDARY_KPIS: 'secondaryKPIs',
  DYNAMIC_METRICS: 'dynamicMetrics',
}
const ATTRIBUTION_KEYS = Object.values(ATTRIBUTION_FIELDS)
const METRIC_FIELDS = {
  CLICKS: 'clicks',
  VIEWS: 'views',
}

const DESELECT_OPTION = { label: '-', value: null }

/**
 * KPI section for clients and business units
 * @param {Object} params React Params
 * @param {Boolean} [params.isBusinessUnit = false] Flag to toggle some features on or off based on place used
 * @param {Object} params.company Viewed/edited company
 * @param {Object} params.pendingChanges Pending changes on company
 * @param {Function} params.onChange Change callback for Facebook Attribution
 * @param {Boolean} params.disabled Flag to disable editing
 * @param {Number} [params.kpiCount = 0] Number of available KPIs
 * @param {Function} [params.setKpiErrors] Callback to manage validation errors
 * @param {Object} [params.errors] Validation errors
 * @returns {React.ReactElement}
 */
const FacebookKpiAttribution = ({
  isBusinessUnit,
  company,
  pendingChanges,
  onChange,
  disabled,
  kpiCount = 0,
  setKpiErrors,
  errors,
}) => {
  const { facebookKPIAttribution: attribution } = company
  const { businessUnitKPIs: updatedBusinessUnits = [] } = pendingChanges

  const attributionErrors = errors?.facebookKPIAttribution

  const [selectedCompanies, setSelectedCompanies] = useState([])

  const isCompanySelected = useMemo(() => {
    // If we're on BU level, this must be `true` because all changes are persisted on the BU
    if (isBusinessUnit || !selectedCompanies.length) {
      return true
    }
    return selectedCompanies.some(
      (companyOrBusinessUnit) => companyOrBusinessUnit._id === company._id
    )
  }, [JSON.stringify(selectedCompanies), company._id])

  const getMixedAttributionKpiSets = () => {
    const primaryKpiSet = extractAttributionSetFromCompanies(
      selectedCompanies,
      [pendingChanges, ...updatedBusinessUnits],
      'primaryKPIs'
    )
    const secondaryKpiSet = extractAttributionSetFromCompanies(
      selectedCompanies,
      [pendingChanges, ...updatedBusinessUnits],
      'secondaryKPIs'
    )
    const dynamicKpiSet = extractAttributionSetFromCompanies(
      selectedCompanies,
      [pendingChanges, ...updatedBusinessUnits],
      'dynamicMetrics'
    )
    return {
      primaryKpiSet,
      secondaryKpiSet,
      dynamicKpiSet,
    }
  }

  /**
   * Extracts the set of attribution values, along with a `variable` flag for primary, secondary and dynamics
   *
   * @type {{
   *  primaryKPIs: {clicks: Object, views: Object}
   *  secondaryKPIs: {clicks: Object, views: Object}
   *  dynamicMetrics: {clicks: Object, views: Object}
   * }}
   */
  const attributionConfig = useMemo(() => {
    if (isBusinessUnit) {
      return ATTRIBUTION_KEYS.reduce((acc, key) => {
        const kpiConfig = attribution[key]
          ? attribution[key]
          : { clicks: null, views: null }
        return {
          ...acc,
          [key]: {
            clicks: {
              variable: false,
              value: kpiConfig.clicks,
            },
            views: {
              variable: false,
              value: kpiConfig.views,
            },
          },
        }
      }, {})
    }
    const { primaryKpiSet, secondaryKpiSet, dynamicKpiSet } =
      getMixedAttributionKpiSets()
    return {
      primaryKPIs: getIntersectionAttributionValues(primaryKpiSet),
      secondaryKPIs: getIntersectionAttributionValues(secondaryKpiSet),
      dynamicMetrics: getIntersectionAttributionValues(dynamicKpiSet),
    }
  }, [
    isBusinessUnit,
    JSON.stringify(selectedCompanies),
    attribution,
    pendingChanges,
  ])

  const defaultSelections = useMemo(() => {
    if (isCompanySelected || selectedCompanies.length === 1 || isBusinessUnit) {
      return attribution
    }
    if (selectedCompanies.length === 1) {
      const selectedBuId = selectedCompanies[0]._id
      const updatedBu = updatedBusinessUnits.find(
        (updatedBu) => updatedBu._id === selectedBuId
      )
      return updatedBu && updatedBu?.facebookKPIAttribution
        ? updatedBu.facebookKPIAttribution
        : selectedCompanies[0].facebookKPIAttribution
    } else {
      const { primaryKPIs, secondaryKPIs, dynamicMetrics } = attributionConfig
      return {
        primaryKPIs: {
          clicks: primaryKPIs.clicks.value,
          views: primaryKPIs.views.value,
        },
        secondaryKPIs: {
          clicks: secondaryKPIs.clicks.value,
          views: secondaryKPIs.views.value,
        },
        dynamicMetrics: {
          clicks: dynamicMetrics.clicks.value,
          views: dynamicMetrics.views.value,
        },
      }
    }
  }, [
    isCompanySelected,
    JSON.stringify(selectedCompanies),
    attributionConfig,
    attribution,
  ])

  const dropdownOptions = useMemo(() => {
    return Object.values(SPECIAL_METRICS_FACEBOOK_ATTRIBUTIONS).reduce(
      (acc, el) => {
        if (!el.value) {
          return acc
        }
        if (el.value.includes(CLICK)) {
          acc.clicks.push(el)
        }
        if (el.value.includes(VIEW)) {
          acc.views.push(el)
        }
        return acc
      },
      {
        clicks: [DESELECT_OPTION],
        views: [DESELECT_OPTION],
      }
    )
  }, [])

  /**
   * Handler to update state when company filter changes
   * @param {Array} selectedCompanyIds List of ObjectIds of selected client & business units
   * @returns {void}
   */
  const handleCompanyFilter = (selectedCompanyIds) => {
    if (!company.businessUnits?.length) {
      return
    }
    const companySelection = [company, ...company.businessUnits].filter(
      (companyOrBusinessUnit) =>
        selectedCompanyIds.includes(companyOrBusinessUnit._id)
    )
    setSelectedCompanies(companySelection)
  }

  const getUpdatedAttributionForClient = (
    clientAttribution,
    attributionSetField,
    metricField,
    el
  ) => {
    const metric = {
      ...clientAttribution?.[attributionSetField],
      [metricField]: el,
    }
    if (metricField === METRIC_FIELDS.CLICKS) {
      if (!metric.views) {
        metric.views = null
      }
    } else {
      if (!metric.clicks) {
        metric.clicks = null
      }
    }
    return metric
  }

  const handleAttributionChange =
    (attributionSetField, metricField) => (el) => {
      const updatedCompany = {}
      // If the current company is selected in the list, update it's attribution config
      if (isCompanySelected) {
        updatedCompany.facebookKPIAttribution = {
          ...attribution,
          [attributionSetField]: getUpdatedAttributionForClient(
            attribution,
            attributionSetField,
            metricField,
            el
          ),
        }
      }

      // Update the attribution config for all selected business units, if at client level
      if (
        !isBusinessUnit &&
        !(isCompanySelected && selectedCompanies.length === 1)
      ) {
        const updatedBusinessUnitAttribution = company.businessUnits.map(
          ({ _id, facebookKPIAttribution }) => {
            const businessUnitUpdates =
              updatedBusinessUnits.find((updatedBu) => updatedBu._id === _id) ||
              {}
            const businessUnitPayload = {
              _id,
              ...businessUnitUpdates,
            }
            if (
              selectedCompanies.some(
                (companyOrBusinessUnit) => companyOrBusinessUnit._id === _id
              )
            ) {
              const businessUnitAttribution = {
                ...facebookKPIAttribution,
                ...(businessUnitUpdates?.facebookKPIAttribution || {}),
              }
              return {
                ...businessUnitPayload,
                facebookKPIAttribution: {
                  ...businessUnitAttribution,
                  [attributionSetField]: getUpdatedAttributionForClient(
                    businessUnitAttribution,
                    attributionSetField,
                    metricField,
                    el
                  ),
                },
              }
            }
            return businessUnitPayload
          }
        )
        updatedCompany.businessUnitKPIs = updatedBusinessUnitAttribution
      }
      onChange(updatedCompany, 'facebookKPIAttribution')
      setKpiErrors && setKpiErrors(attributionSetField)
    }

  return (
    <div
      data-cy="client-facebook-attribution-section"
      className="form__section__body align-column kpi-section__attribution"
    >
      <div className="align-baseline space-between">
        <h3
          className="generic-heading"
          data-cy="kpi-facebook-attribution-kpis-label"
        >
          Facebook Attribution
        </h3>
        {!isBusinessUnit && (
          <KpiCompanyFilter company={company} onChange={handleCompanyFilter} />
        )}
      </div>
      <div className="align-column gap-10">
        <div className="align-row">
          <div className="form__section__body__half-width-section">
            <div
              className="general-label"
              style={{ width: '100%' }}
              data-cy="kpi-facebook-attribution-kpis-primary-label"
            >
              Primary
            </div>
            <div className="align-row">
              <Dropdown
                data-cy="kpi-facebook-attribution-kpis-primary-clicks-dropdown"
                className="input-half-width"
                options={dropdownOptions.clicks}
                disabled={disabled || !kpiCount}
                defaultOptionText="-"
                overwriteSelectedText={
                  attributionConfig.primaryKPIs.clicks.variable
                    ? VARIABLE_PLACEHOLDER
                    : null
                }
                defaultState={
                  attributionConfig.primaryKPIs.clicks.variable
                    ? '-'
                    : defaultSelections?.primaryKPIs?.clicks
                }
                onChange={handleAttributionChange(
                  ATTRIBUTION_FIELDS.PRIMARY_KPIS,
                  METRIC_FIELDS.CLICKS
                )}
                error={
                  !!attributionErrors?.primaryKPIs &&
                  !defaultSelections?.primaryKPIs?.clicks
                }
              />
              <Dropdown
                data-cy="kpi-facebook-attribution-kpis-primary-views-dropdown"
                className="input-half-width right-half-input"
                options={dropdownOptions.views}
                disabled={disabled || !kpiCount}
                defaultOptionText="-"
                overwriteSelectedText={
                  attributionConfig.primaryKPIs.views.variable
                    ? VARIABLE_PLACEHOLDER
                    : null
                }
                defaultState={
                  attributionConfig.primaryKPIs.views.variable
                    ? '-'
                    : defaultSelections?.primaryKPIs?.views
                }
                onChange={handleAttributionChange(
                  ATTRIBUTION_FIELDS.PRIMARY_KPIS,
                  METRIC_FIELDS.VIEWS
                )}
                error={
                  !!attributionErrors?.primaryKPIs &&
                  !defaultSelections?.primaryKPIs?.views
                }
              />
            </div>
            {attributionErrors?.primaryKPIs && (
              <InformationBlock
                type={INFORMATION_BLOCK_TYPE.ERROR}
                info={attributionErrors?.primaryKPIs}
              />
            )}
          </div>
          <div className="form__section__body__half-width-section right-side">
            <div
              className="general-label"
              style={{ width: '100%' }}
              data-cy="kpi-facebook-attribution-kpis-secondary-label"
            >
              Secondary
            </div>
            <div className="align-row">
              <Dropdown
                data-cy="kpi-facebook-attribution-kpis-secondary-clicks-dropdown"
                className="input-half-width"
                options={dropdownOptions.clicks}
                disabled={disabled || !kpiCount}
                defaultOptionText="-"
                overwriteSelectedText={
                  attributionConfig.secondaryKPIs.clicks.variable
                    ? VARIABLE_PLACEHOLDER
                    : null
                }
                defaultState={
                  attributionConfig.secondaryKPIs.clicks.variable
                    ? '-'
                    : defaultSelections?.secondaryKPIs?.clicks
                }
                onChange={handleAttributionChange(
                  ATTRIBUTION_FIELDS.SECONDARY_KPIS,
                  METRIC_FIELDS.CLICKS
                )}
                error={
                  !!attributionErrors?.secondaryKPIs &&
                  !defaultSelections?.secondaryKPIs?.clicks
                }
              />
              <Dropdown
                data-cy="kpi-facebook-attribution-kpis-secondary-views-dropdown"
                className="input-half-width right-half-input"
                options={dropdownOptions.views}
                disabled={disabled || !kpiCount}
                defaultOptionText="-"
                overwriteSelectedText={
                  attributionConfig.secondaryKPIs.views.variable
                    ? VARIABLE_PLACEHOLDER
                    : null
                }
                defaultState={
                  attributionConfig.secondaryKPIs.views.variable
                    ? '-'
                    : defaultSelections?.secondaryKPIs?.views
                }
                onChange={handleAttributionChange(
                  ATTRIBUTION_FIELDS.SECONDARY_KPIS,
                  METRIC_FIELDS.VIEWS
                )}
                error={
                  !!attributionErrors?.secondaryKPIs &&
                  !defaultSelections?.secondaryKPIs?.views
                }
              />
            </div>
            {attributionErrors?.secondaryKPIs && (
              <InformationBlock
                type={INFORMATION_BLOCK_TYPE.ERROR}
                info={attributionErrors?.secondaryKPIs}
              />
            )}
          </div>
        </div>
        <div className="align-row">
          <div className="form__section__body__half-width-section">
            <div
              className="general-label"
              style={{ width: '100%' }}
              data-cy="kpi-facebook-attribution-dynamic-events-label"
            >
              Dynamic Events
            </div>
            <div className="align-row">
              <Dropdown
                data-cy="kpi-facebook-attribution-dynamic-events-clicks-dropdown"
                options={dropdownOptions.clicks}
                defaultOptionText="-"
                disabled={disabled || !kpiCount}
                className="input-half-width"
                overwriteSelectedText={
                  attributionConfig.dynamicMetrics.clicks.variable
                    ? VARIABLE_PLACEHOLDER
                    : null
                }
                defaultState={
                  attributionConfig.dynamicMetrics.clicks.variable
                    ? '-'
                    : defaultSelections?.dynamicMetrics?.clicks
                }
                onChange={handleAttributionChange(
                  ATTRIBUTION_FIELDS.DYNAMIC_METRICS,
                  METRIC_FIELDS.CLICKS
                )}
                error={
                  !!attributionErrors?.dynamicMetrics &&
                  !defaultSelections?.dynamicMetrics?.clicks
                }
              />
              <Dropdown
                data-cy="kpi-facebook-attribution-dynamic-events-views-dropdown"
                options={dropdownOptions.views}
                defaultOptionText="-"
                disabled={disabled || !kpiCount}
                className="input-half-width right-half-input"
                overwriteSelectedText={
                  attributionConfig.dynamicMetrics.views.variable
                    ? VARIABLE_PLACEHOLDER
                    : null
                }
                defaultState={
                  attributionConfig.dynamicMetrics.views.variable
                    ? '-'
                    : defaultSelections?.dynamicMetrics?.views
                }
                onChange={handleAttributionChange(
                  ATTRIBUTION_FIELDS.DYNAMIC_METRICS,
                  METRIC_FIELDS.VIEWS
                )}
                error={
                  !!attributionErrors?.dynamicMetrics &&
                  !defaultSelections?.dynamicMetrics?.views
                }
              />
            </div>
            {attributionErrors?.dynamicMetrics && (
              <InformationBlock
                type={INFORMATION_BLOCK_TYPE.ERROR}
                info={attributionErrors?.dynamicMetrics}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

FacebookKpiAttribution.propTypes = {
  isBusinessUnit: PropTypes.bool,
  company: PropTypes.object.isRequired,
  pendingChanges: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  kpiCount: PropTypes.number,
  setKpiErrors: PropTypes.func,
}

export { FacebookKpiAttribution }
