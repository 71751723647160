import { ACTIONS as SESSION_ACTIONS } from 'modules/session/actions'
import { ACTIONS } from './actions'
import { COPY_SETTINGS_STATUS } from './constants'
const STORAGE_KEY_COMPANY = 'lc-3q-company'
export const STORAGE_KEY_ALL_COMPANIES = 'lc-3q-companies'

/** Name of the reducer */
const name = 'companies'

/** Initial state of the reducer */
const initialState = {
  list: null,
  currentCompany: JSON.parse(localStorage.getItem(STORAGE_KEY_COMPANY) || null),
  copySettings: {},
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.GET_COMPANIES]: (state, { companies }) => {
    // Store Companies on local-storage. Only used to check access in router where we don't have store access !!
    localStorage.setItem(
      STORAGE_KEY_ALL_COMPANIES,
      JSON.stringify(
        companies.map((c) => ({ clientId: c.clientId, _id: c._id }))
      )
    )
    return { ...state, list: companies }
  },

  [ACTIONS.SET_CURRENT_COMPANY]: (state, { company }) => {
    localStorage.setItem(STORAGE_KEY_COMPANY, JSON.stringify(company))
    return { ...state, currentCompany: company }
  },

  [SESSION_ACTIONS.LOGOUT]: () => {
    localStorage.removeItem(STORAGE_KEY_COMPANY)
    localStorage.removeItem(STORAGE_KEY_ALL_COMPANIES)
    return initialState
  },

  [ACTIONS.CHANGE_STATUS]: (state, { clientId, active }) => {
    const companies = [...state.list]
    const index = companies.findIndex((c) => c.clientId === clientId)
    companies[index] = { ...companies[index], active }
    return { ...state, list: companies }
  },

  [ACTIONS.SET_BULK_BUSINESS_UNITS]: (state, { bulkEditList }) => {
    return { ...state, bulkEditList }
  },

  [ACTIONS.UNDO_COPY_SETTINGS]: (state) => {
    const newCopySettingsState = { ...state.copySettings }
    for (const section of Object.values(newCopySettingsState)) {
      section.status = COPY_SETTINGS_STATUS.REVERTING
    }
    return { ...state, copySettings: newCopySettingsState }
  },

  [ACTIONS.UNDO_COPY_SETTINGS_SECTION]: (state, { section }) => {
    const sectionState = state.copySettings?.[section]
    const newSectionState = { ...sectionState }
    if (newSectionState) {
      newSectionState.currentIndex = newSectionState.currentIndex - 1
      newSectionState.status = COPY_SETTINGS_STATUS.REVERTED
    }
    return {
      ...state,
      copySettings: {
        ...state.copySettings,
        [section]: {
          ...state.copySettings[section],
          ...newSectionState,
        },
      },
    }
  },

  [ACTIONS.INIT_COPY_SETTINGS]: (state, { section, data }) => {
    let newCompanyCopySettings = {}
    const companyState = state.copySettings?.[section]
    if (companyState) {
      const currentIndex = companyState.currentIndex + 1
      const newHistory = [...companyState.history]
      newHistory[currentIndex] = { data: data }
      newHistory.splice(currentIndex + 1)
      newCompanyCopySettings = {
        history: newHistory,
        currentIndex: currentIndex,
        status: COPY_SETTINGS_STATUS.COPIED,
      }
    } else {
      newCompanyCopySettings = {
        history: [{ data: data }],
        currentIndex: 0,
        status: COPY_SETTINGS_STATUS.COPIED,
      }
    }
    const newState = {
      ...state,
      copySettings: {
        ...state.copySettings,
        [section]: {
          ...(state.copySettings?.[section] || {}),
          ...newCompanyCopySettings,
        },
      },
    }
    return newState
  },

  [ACTIONS.RESET_COPY_SETTINGS]: (state) => {
    return {
      ...state,
      copySettings: {},
    }
  },
}

export default { name, initialState, reduce }
