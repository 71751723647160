import React, { useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { fileUpload, flow } from '@decision-sciences/qontrol-common'

/* Utils */
import { useDropzone } from 'react-dropzone'
import { removeElementFromDOMById } from 'components/utils/dom-manipulation'
import { setUploadInProgress } from 'modules/flow/actions'
import {
  clearErrors,
  getAcceptedFiles,
  getFileMetadata,
  getUploadingFileByName,
  getUploadPercentFromRequestProgressData,
  keepUniqueFiles,
  setReaderFailHandlers,
} from 'components/file-upload/utils'

/* Components */
import UploadingFiles from 'components/file-upload/components/uploading-files'
import DragOverlay from 'components/file-upload/components/drag-overlay'
import UploadDefaultState from 'components/file-upload/components/upload-default-state'

/* Store */
import { useStore } from 'store'

/* Constants */
import {
  DEFAULT_ACCEPTED_UPLOAD_FILE_TYPE,
  DUMMY_VIDEO_ELEMENT,
  FILE_UPLOAD_STATE,
  MAX_ALLOWED_UPLOAD_SIZE_IN_MB,
} from 'components/file-upload/constants'

/* Styles */
import 'components/file-upload/style.scss'

const { AD_CONTENT_TYPES } = flow.facebook
const { sendFileUploadRequest } = fileUpload
/**
 * Allows the upload of a file, shows the upload progress of each individual file and returns the data related to the uploaded files
 * @param {Function} props - Component's props
 * @param {Function} props.throwError - A setter for the error state used in case the upload process has errors
 * @param {number} props.maxFileSize - The max allowed file size (in MB)
 * @param {string} props.fileTypesAccepted -  The type of files accepted to be uploaded (as mime types - ex: video/mp4,video/avi)
 * @param {Boolean} props.allowMultiple - A flag that tells if multiple uploads at a time are allowed
 * @param {string|Function} props.renderOnDefaultState - A plain string or a function that returns the default state of the upload file widget
 * @param {Function} props.onFileUploadDone - The callback that returns the result when a file is uploaded with success
 * @param {Array} props.filesOnState - The files that are already uploaded. Used to prevent the upload of the same file
 * @param {Boolean} props.disabled - A flag that enforces the disable of the upload functionality
 * @param {Function} props.onFileSelected - The callback that return the selected file
 * @param {Boolean} props.dark - Boolean to indicate drop zone component should have dark styling
 * @param {number} props.maxFiles - The maximum number of files that can be uploaded.
 */
const AdContentFileUpload = ({
  throwError,
  maxFileSize = MAX_ALLOWED_UPLOAD_SIZE_IN_MB,
  fileTypesAccepted = DEFAULT_ACCEPTED_UPLOAD_FILE_TYPE,
  allowMultiple = false,
  renderOnDefaultState,
  onFileUploadDone,
  filesOnState,
  onLoadingStatusChanged,
  uploadApiPath,
  onFileSelected,
  disabled,
  dark = false,
  maxFiles,
}) => {
  /* Stores the state of the file upload component (default, drag_over or uploading) */
  const [displayState, setDisplayState] = useState(FILE_UPLOAD_STATE.DEFAULT)
  /* Contains all the uploading files and their details about the upload process */
  const [uploadingFiles, setUploadingFiles] = useState([])
  const { dispatch, state } = useStore()
  const { uploadInProgress } = state.flow
  /* We extract only the current array with files for a convenient use within the upload logic */
  const alreadyExistingFiles = filesOnState?.current || []
  const controllerRef = useRef({})

  useEffect(() => {
    setUploadInProgress(dispatch, false)
    return () => {
      /** On unmount, remove the video dummy element, if it exists */
      removeElementFromDOMById(DUMMY_VIDEO_ELEMENT)
    }
  }, [])

  useEffect(() => {
    if (!uploadingFiles?.length) {
      /* When the uploadingFiles has changed, we want to check if there array is empty in order to set the default view back */
      setDisplayState(FILE_UPLOAD_STATE.DEFAULT)
      onLoadingStatusChanged && onLoadingStatusChanged(false)
    }
  }, [JSON.stringify(uploadingFiles)])

  /**
   * Is called to notify every progress made on the upload process for a given file
   *
   * @param {Number} state - The state we should display within the file upload component
   * @param {Object} requestProgressData - Contains the upload percentage and the file name
   */
  const onReceivedUploadingEvent = (state, requestProgressData) => {
    if (
      requestProgressData?.fileName &&
      requestProgressData?.uploadPercent &&
      state === FILE_UPLOAD_STATE.UPLOADING
    ) {
      onReceivedUploadingDisplayState(state, requestProgressData)
    } else if (state === FILE_UPLOAD_STATE.DEFAULT) {
      removeFileFromUploadingFiles(requestProgressData.fileName)
    }
  }

  /**
   * Removes a file from the uploading files based on given name and sets the uploading files state
   * @param {String} fileName - The name of the file that needs to be removed from the array
   */
  const removeFileFromUploadingFiles = (fileName) => {
    setTimeout(() => {
      setUploadingFiles((previous) =>
        previous.filter((e) => e.fileName !== fileName)
      )
    }, 200)
  }

  /**
   * Updates the upload percent of an existing file within the uploading files and sets the uploading files state
   * @param {Object} requestProgressData - An object that contains the new file details (uploadPercent, fileName)
   */
  const onUploadingFileDetailsChanged = (requestProgressData) => {
    setUploadingFiles((previous) => {
      previous.forEach((e) => {
        if (e.fileName === requestProgressData.fileName) {
          /**
           * Since we are setting a default 5% value on the upload process for a better user experience
           * we do not want to allow values under 5% afterwards since it would not be a good UX for the user
           * (it would be like a increase, decrease and after increase again behavior)
           */
          e.uploadPercent =
            requestProgressData.uploadPercent > 5
              ? requestProgressData.uploadPercent
              : 5
        }
      })

      return [...previous]
    })

    /* If the upload reached the 100% limit - we remove it with a small delay to improve the user experience */
    if (requestProgressData?.uploadPercent === 100) {
      removeFileFromUploadingFiles(requestProgressData.fileName)
    }
  }

  /**
   * Adds a new file to the uploading files and sets the uploading files state
   * @param {Object} requestProgressData - An object that contains the new file details (uploadPercent, fileName)
   */
  const addFileToUploadingFiles = (requestProgressData) => {
    setUploadingFiles((previous) => {
      previous.push({
        fileName: requestProgressData.fileName,
        uploadPercent: requestProgressData.uploadPercent,
      })

      return previous
    })
  }

  /**
   * Handles all the cases related to the uploading state when a request progress was received
   * @param {Number} state - The state we should display within the file upload component
   * @param {Object} requestProgressData - Contains the upload percentage and the file name
   */
  const onReceivedUploadingDisplayState = (state, requestProgressData) => {
    const uploadingFile = getUploadingFileByName(
      uploadingFiles,
      requestProgressData.fileName
    )

    if (uploadingFile) {
      /* If the file already exists, we update its details */
      onUploadingFileDetailsChanged(requestProgressData)
    } else {
      /* If it's a new file, we add it to the uploading files */
      addFileToUploadingFiles(requestProgressData)
    }

    /* After the uploading files were set, we set the display state as well */
    setDisplayState(state)
  }

  /**
   * Handler for when a file is selected to be uploaded
   * @param {Object} parsedFile - The selected file to be uploaded
   * @param {String} parsedFile.resolution - The resolution of the file to be uploaded
   * @param {String} parsedFile.isImage - A flag that tells us if the file is an image
   * @param {string|Blob} parsedFile.originalFile - The original file before parsing
   */
  const uploadFile = async (parsedFile) => {
    setUploadInProgress(dispatch, true)
    const { resolution, originalFile, isImage, duration, width, height } =
      parsedFile

    /**
     * Initially we set a default percentage of 5.
     * This is made just to improve the user experience
     */
    onReceivedUploadingEvent(FILE_UPLOAD_STATE.UPLOADING, {
      fileName: originalFile.name,
      uploadPercent: 5,
    })

    /**
     * The entire upload process is delayed with 300 ms to improve the user experience
     * In all the cases for smaller files - without this enforce the user would
     * just see some flashes within the template/design
     */
    setTimeout(async () => {
      try {
        const response = await sendFileUploadRequest(
          uploadApiPath,
          originalFile,
          (requestProgressData) => {
            /* Every time we receive a progress data from the request upload process, we notify the file upload component */
            onReceivedUploadingEvent(FILE_UPLOAD_STATE.UPLOADING, {
              fileName: originalFile.name,
              uploadPercent:
                getUploadPercentFromRequestProgressData(requestProgressData),
            })
          },
          {
            width,
            height,
            type: isImage ? AD_CONTENT_TYPES.IMAGE : AD_CONTENT_TYPES.VIDEO,
            duration,
            value: originalFile.name,
            size: originalFile.size,
          },
          controllerRef.current[originalFile.name]
        )

        /**
         *  When the upload is done
         *  We tell the file upload component it can set its display state as default
         *  And we send the fileName in order to decide if there are still other files uploading
         */
        onReceivedUploadingEvent(FILE_UPLOAD_STATE.DEFAULT, {
          fileName: originalFile.name,
        })

        if (response?.success) {
          const uploadedFile = response.data

          /* If the upload was successful we save the data related on the step */
          onFileUploadDone(uploadedFile)
          setUploadInProgress(dispatch, false)
        }
      } catch (error) {
        /**
         *  If the upload failed
         *  We tell the file upload component it can set its display state as default
         *  And we send the fileName in order to decide if there are still other files uploading
         */
        onReceivedUploadingEvent(FILE_UPLOAD_STATE.DEFAULT, {
          fileName: originalFile.name,
        })
        setUploadInProgress(dispatch, false)
        onFileSelected && onFileSelected(originalFile)
        throwError(error)
      }
    }, 300)
  }

  /**
   *
   * @param {Object|FileReader} reader - the file reader within we have the parsed file used to obtain the resolution of the file
   * @param {Object} file - The selected file to be uploaded
   */
  const onLoadReader = (reader, file) => {
    const isImage = file.type.indexOf('image/') === 0
    const isVideo = file.type.indexOf('video/') === 0

    let parsedFile = {
      name: file.name,
      originalFile: file,
      isImage: isImage,
    }

    /**
     * We need to set the resolution of the file (image or video) and right after
     * we will run the uploadFile to continue the upload process
     */
    getFileMetadata(reader, file, isImage, isVideo).then((metadata) => {
      parsedFile = { ...parsedFile, ...(metadata || {}) }

      uploadFile(parsedFile).then()
    })
  }

  /**
   * Callback that runs every time a file (or more) are dropped or selected in order to be uploaded
   * @param {Array} files - An array with all the files dropped or selected
   */
  const onFilesDropped = (files) => {
    if (!disabled) {
      clearErrors(throwError)
      const acceptedFiles = keepUniqueFiles(
        getAcceptedFiles(files, maxFileSize, throwError),
        alreadyExistingFiles,
        throwError
      )
      if (maxFiles) {
        const allFiles = [...alreadyExistingFiles, ...acceptedFiles]
        if (maxFiles < allFiles.length) {
          throwError(`Maximum number of files accepted is ${maxFiles}.`)
          return
        }
      }

      /* If the file is already uploaded - we quit the upload file logic */
      if (!acceptedFiles?.length) {
        setDisplayState(FILE_UPLOAD_STATE.DEFAULT)
        return
      }

      /* Set the loading status as true */
      onLoadingStatusChanged && onLoadingStatusChanged(true)

      /**
       * Loop through each valid file in order to parse it,
       * send it do the server to be uploaded and save it on the ad content step
       */
      const abortControllers = {}
      acceptedFiles.forEach((file) => {
        abortControllers[file.name] = new AbortController()

        const reader = new FileReader()
        const textReader = new FileReader()

        setReaderFailHandlers(reader)

        /**
         * We set the onload callback on the reader that will be triggered
         * when the files was completely read (reads the file as a Buffer)
         */
        reader.onload = () => onLoadReader(reader, file)

        /* Calls the reader to read image as buffer - calls the onload method above */
        textReader.onload = function (evt) {}
        reader.readAsArrayBuffer(file)
        textReader.readAsText(file, 'UTF-8')
      })
      controllerRef.current = abortControllers
    }
  }

  /* Handler for when a file is selected */
  const onDrop = useCallback(onFilesDropped, [
    maxFileSize,
    throwError,
    onFileUploadDone,
    filesOnState,
    dispatch,
    onReceivedUploadingEvent,
  ])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: fileTypesAccepted,
    multiple: allowMultiple,
    disabled: uploadingFiles.length || uploadInProgress,
    onDragEnter: () => setDisplayState(FILE_UPLOAD_STATE.DRAG_OVER),
    onDragLeave: () => setDisplayState(FILE_UPLOAD_STATE.DEFAULT),
  })

  const onCancelFileUpload = (fileName) => {
    controllerRef.current[fileName]?.abort()
    controllerRef.current = { ...controllerRef.current, [fileName]: null }
  }

  return (
    <div {...getRootProps()} className="upload-modal">
      <input {...getInputProps()} />
      <UploadDefaultState
        disabled={disabled || uploadInProgress}
        state={displayState}
        render={renderOnDefaultState}
        dark={dark}
      />
      <DragOverlay state={displayState} />
      <UploadingFiles
        files={uploadingFiles}
        state={displayState}
        onRemove={onCancelFileUpload}
        dark={dark}
      />
    </div>
  )
}

AdContentFileUpload.propTypes = {
  throwError: PropTypes.func,
  fileTypesAccepted: PropTypes.string,
  allowMultiple: PropTypes.bool,
  renderOnDefaultState: PropTypes.any,
  maxFileSize: PropTypes.number,
  loading: PropTypes.bool,
  onFileUploadDone: PropTypes.func,
  filesOnState: PropTypes.object,
  onLoadingStatusChanged: PropTypes.func,
  uploadApiPath: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onFileSelected: PropTypes.func,
  dark: PropTypes.bool,
  maxFiles: PropTypes.number,
}

export default AdContentFileUpload
