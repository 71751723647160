import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'calculatedMetrics'

/** Initial state of the reducer */
const initialState = {
  list: null,
  listWithDetails: null,
  variables: null,
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.GET_CALCULATED_METRICS]: (state, { calculatedMetrics }) => {
    return { ...state, list: calculatedMetrics }
  },
  [ACTIONS.GET_CALCULATED_METRICS_WITH_DETAILS]: (
    state,
    { calculatedMetrics }
  ) => {
    return { ...state, listWithDetails: calculatedMetrics, list: null }
  },
  [ACTIONS.GET_VARIABLE_METRICS]: (state, { variables }) => ({
    ...state,
    variables,
  }),
}

export default { name, initialState, reduce }
