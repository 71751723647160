import { entityStatus } from '@decision-sciences/qontrol-common'
const { ENTITY_STATUS } = entityStatus

export const TEAM_DEFAULT = {
  name: '',
  key: '',
  companies: [],
  isGlobal: false,
  active: ENTITY_STATUS.ACTIVE,
}

export const TEAM_REPORT_TYPE = {
  EMBED: 'embedReport',
  GLOBAL: 'globalReport',
}

export const REPORT_TYPE_OPTIONS = [
  { label: 'Embed', value: TEAM_REPORT_TYPE.EMBED },
  { label: 'Global', value: TEAM_REPORT_TYPE.GLOBAL },
]

export const REPORT_STATUS_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
]

export const getCompanyAndBUNames = (report) => {
  let buString = ''
  let company = report.company
  if (report.parentCompany) {
    company = report.parentCompany
    buString = ` | BU: ${report.company}`
  }
  return `Client: ${company}${buString}`
}
