import PropTypes from 'prop-types'

import { format } from 'date-fns'

/**
 * Reusable modified at cell component
 * @param {Object} props
 * @param {Object} props.row Table row
 * @param {Object} props.row.original Any object rendered within the table
 */
const ModifiedAtCell = (props) =>
  format(new Date(props.row.original.updatedAt), 'MM/dd/yyyy')

export default ModifiedAtCell

ModifiedAtCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.object,
  }).isRequired,
}
