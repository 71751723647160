import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import MyAccountEditUser from 'modules/users/user-account-setup/my-account-edit'
import MyAccountSecurity from 'modules/users/user-account-setup/my-account-security'

import 'styles/my-account.scss'
import { NOT_FOUND_ROUTE } from 'routes'

const MyAccount = () => {
  const { section } = useParams()
  const navigate = useNavigate()

  const sections = {
    account: <MyAccountEditUser />,
    security: <MyAccountSecurity />,
  }

  /** On mount, check for valid section */
  useEffect(() => {
    if (section && !sections[section]) {
      navigate(NOT_FOUND_ROUTE, { replace: true })
    }
  }, [])

  return <div className="my-account align-column">{sections[section]}</div>
}

export default MyAccount
