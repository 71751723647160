import { AccountDropdownRow } from 'components/account-icon/index'
import { Dropdown } from 'components/dropdown/index'
import PropTypes from 'prop-types'

/**
 * Renders the section for managing KPIs
 * @param {Object} props Component props
 * @param {React.ReactNode} props.sectionLabel Label to render above the dropdown
 * @param {String} props.sectionLabelDataCy data-cy attribute for the label
 * @param {String} props.dropdownDataCy data-cy attribute for the dropdown
 * @param {String} props.defaultOption Dropdown placeholder
 * @param {Array} props.options List of options available in the dropdown
 * @param {Array} [props.selectedItems = []] List of currently selected items
 * @param {Array} [props.companyGroups = null] Groups together KPIs per company/BU
 * @param {Boolean} [props.disabled] Disabled flag
 * @param {Boolean} [props.loading] Loading flag
 * @param {Function} props.onChange Callback function to change selection
 * @returns {React.FunctionComponent}
 */
const KpiSelector = ({
  sectionLabel,
  sectionLabelDataCy,
  dropdownDataCy,
  defaultOption,
  options,
  selectedItems = [],
  companyGroups = null,
  disabled,
  loading,
  onChange,
}) => {
  return (
    <>
      <div className="general-label" data-cy={sectionLabelDataCy}>
        {sectionLabel}
      </div>
      <Dropdown
        selectAll
        multiSelect
        isConversionDropdown
        showOptionsInPlaceholder={false}
        data-cy={dropdownDataCy}
        options={options}
        groups={companyGroups}
        defaultOptionText={defaultOption}
        selectedItems={selectedItems}
        optionRenderer={(option, selectedItems) => (
          <AccountDropdownRow option={option} selectedItems={selectedItems} />
        )}
        disabled={disabled}
        loading={loading}
        onChange={onChange}
        optionsHeight={585}
      />
    </>
  )
}

KpiSelector.propTypes = {
  sectionLabel: PropTypes.node.isRequired,
  sectionLabelDataCy: PropTypes.string.isRequired,
  dropdownDataCy: PropTypes.string.isRequired,
  defaultOption: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectedItems: PropTypes.array,
  companyGroups: PropTypes.array,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export { KpiSelector }
