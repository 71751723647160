import PropTypes from 'prop-types'
import React, { useState } from 'react'

/* Components */
import Table from 'components/table/beta'
import EntitySelectorFilters, { FiltersOuterPropTypes } from './filters'
import EntitySelectorActions, { ActionsOuterPropTypes } from './action-bar'

import './style.scss'

/**
 * Component used to search, filter, sort and select a collection of entities.
 * It accepts custom filtering options, custom actions and a configuration for the table.
 * @param {Object} props Component props
 * @param {Array} [props.filters = []] Configuration for filters. {@link FiltersOuterPropTypes}
 * @param {React.ReactNode} [props.actions] Configuration for custom actions. {@link ActionsOuterPropTypes}
 * @param {Array} props.columns Columns configuration for the table
 * @param {Array} props.data Entities to display in the table
 * @param {Number} props.totalRows Number of total rows that match the fetch crieria
 * @param {Function} [props.onSelect] Optional select callback triggered when row selection state changes.
 * @param {Array<Number>} [props.paginationValues] Optional options for configuring number of items per page.
 * @param {Boolean} props.loading Loading state flag
 * @param {Boolean} props.disableSort Disable sort on table columns flag
 * @param {String} props.deselectRow Deselect row id
 * @param {Function} props.fetchData Callback to trigger data fetching with updated filters
 * @param {Function} props.fetchQuery Currently applied pagination, filters and sort configs
 * @param {Boolean} props.error When set to true don't display table
 * @returns {React.FunctionComponent}
 */
const EntitySelector = ({
  filters = [],
  actions,
  columns,
  data,
  totalRows,
  onSelect,
  paginationValues,
  loading,
  disableSort,
  deselectRow,
  fetchData,
  fetchQuery,
  error,
}) => {
  const hasFilters = !!filters.length
  const initialFilters = filters.reduce((acc, { fieldKey, initialValue }) => {
    if (initialValue) {
      return { ...acc, [fieldKey]: initialValue }
    }
    return acc
  }, {})
  const [selectedFilters, setSelectedFilters] = useState(initialFilters || {})

  const handleFiltersChange = (fieldKey) => (value) => {
    setSelectedFilters((prevFilters) => ({ ...prevFilters, [fieldKey]: value }))
  }

  const onApplyFilters = () => {
    if (!hasFilters) {
      return
    }
    fetchData({ ...fetchQuery, filters: selectedFilters })
  }

  const onTableTriggeredFetch = (tableState) => {
    fetchData({ ...tableState, filters: selectedFilters })
  }

  return (
    <div className="entity-selector">
      <EntitySelectorFilters
        filters={filters}
        loading={loading}
        selectedFilters={selectedFilters}
        onFiltersChange={handleFiltersChange}
      />
      <EntitySelectorActions
        loading={loading}
        actions={actions}
        hasFilters={hasFilters}
        onApplyFilters={onApplyFilters}
      />
      <div>
        {!error ? (
          <Table
            columns={columns}
            data={data}
            paginationValues={paginationValues}
            selectAll={!!onSelect}
            onSelect={onSelect}
            showSearchInput={false}
            loading={loading}
            deselectRow={deselectRow}
            disableSort={disableSort}
            onChangeTableView={onTableTriggeredFetch}
            initialState={fetchQuery}
            manualPagination
            showBlueIcon
            manualCount={Math.ceil(totalRows)}
            totalRows={totalRows}
            showPagination={true}
          />
        ) : null}
      </div>
    </div>
  )
}

EntitySelector.propTypes = {
  ...FiltersOuterPropTypes,
  actions: ActionsOuterPropTypes.actions,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  totalRows: PropTypes.number.isRequired,
  onSelect: PropTypes.func,
  paginationValues: PropTypes.arrayOf(PropTypes.number),
  loading: PropTypes.bool,
  fetchData: PropTypes.func.isRequired,
  fetchQuery: PropTypes.object.isRequired,
  deselectRow: PropTypes.string,
  disableSort: PropTypes.bool,
  error: PropTypes.bool,
}

export default EntitySelector
