import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useStore } from 'store'
import { Helmet } from 'react-helmet'

import PermissionGroupsForm from 'modules/permission-groups/create-edit'

import {
  getPermissionGroup,
  deletePermissionGroup,
  getDuplicatedPermissionGroup,
  setEditedPermissionGroup,
} from 'modules/permission-groups/actions'

import Button from 'components/button'
import Modal from 'components/modal'
import Loader from 'components/loader'

import { useAccess, PERMISSION_TYPES, PERMISSIONS } from 'hooks/access'
import useLeaveConfirm from 'components/leave-confirm'
import { showErrorMessage } from 'modules/notifications/actions'

/**
 * Entry point for permission group management - create or edit
 * @returns {React.FunctionComponent}
 */
const PermissionGroupManagementPage = () => {
  const { key } = useParams()
  const navigate = useNavigate()
  const { dispatch, state } = useStore()
  const {
    permissionGroups: { permissionGroup },
  } = state

  const { search } = useLocation()

  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.PERMISSION_GROUPS,
    type: PERMISSION_TYPES.CREATE,
  })
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.PERMISSION_GROUPS,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.PERMISSION_GROUPS,
    type: PERMISSION_TYPES.DELETE,
  })

  const [setDirty, LeaveConfirm] = useLeaveConfirm({})

  const [loading, setLoading] = useState(false)
  const [duplicatedPermissionGroup, setDuplicatedPermissionGroup] =
    useState(null)
  const [permissionGroupToDelete, setPermissionGroupToDelete] = useState(null)

  const queryParams = new URLSearchParams(search)
  const duplicationTargetId = queryParams.get('base')

  const isCreate = key === 'new'

  useEffect(() => {
    return () => setEditedPermissionGroup(dispatch, null)
  }, [])

  /* On mount check if the user has permissions to view or edit */
  useEffect(() => {
    if ((isCreate && !hasCreateAccess) || (!isCreate && !hasEditAccess)) {
      navigate('/unauthorized', { replace: true })
    }
  }, [hasCreateAccess, hasEditAccess])

  useEffect(() => {
    if (!hasCreateAccess || !hasEditAccess) {
      return
    }
    setLoading(true)
    if (!duplicationTargetId) {
      getPermissionGroup(dispatch, key)
        .catch((error) => {
          showErrorMessage(error, dispatch)
          navigate('/permission-groups')
        })
        .finally(() => setLoading(false))
    } else {
      getDuplicatedPermissionGroup(duplicationTargetId)
        .then(setDuplicatedPermissionGroup)
        .catch((error) => {
          showErrorMessage(error, dispatch)
          navigate('/permission-groups')
        })
        .finally(() => setLoading(false))
    }
  }, [key, duplicationTargetId, hasCreateAccess, hasEditAccess])

  useEffect(() => {
    if (duplicatedPermissionGroup) {
      const updatedPermissionGroup = { ...duplicatedPermissionGroup }
      delete updatedPermissionGroup._id
      delete updatedPermissionGroup.users
      // Append copy to name, if it has one append number of copy
      const regex = /(copy) ?(\d)*$/g
      const copyIdentifier = duplicatedPermissionGroup.name.match(regex)?.[0]
      if (copyIdentifier) {
        const [, copyNumber] = copyIdentifier.split(' ')
        const number = copyNumber ? parseInt(copyNumber) : 0

        updatedPermissionGroup.name = duplicatedPermissionGroup.name.replace(
          regex,
          `copy ${number + 1}`
        )
      } else {
        updatedPermissionGroup.name = `${duplicatedPermissionGroup.name} copy`
      }
      setEditedPermissionGroup(dispatch, updatedPermissionGroup)
    }
  }, [duplicatedPermissionGroup])

  const headerText = isCreate ? 'Add Permission Group' : 'Edit Permission Group'

  const handleDelete = () => {
    setLoading(true)
    deletePermissionGroup(dispatch, permissionGroupToDelete._id)
      .then(() => {
        setDirty(false)
        setTimeout(() => {
          navigate('/permission-groups')
          setPermissionGroupToDelete(null)
        }, 100)
      })
      .catch((error) => {
        console.error(error)
        setPermissionGroupToDelete(null)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (
    !permissionGroup ||
    (duplicationTargetId && !duplicatedPermissionGroup) ||
    loading
  ) {
    return <Loader />
  }

  return (
    <section className="permission-groups">
      <LeaveConfirm />
      {permissionGroupToDelete && hasDeleteAccess && (
        <Modal
          opened={!!permissionGroupToDelete}
          button={
            <Button value="Delete" onClick={handleDelete} disabled={loading} />
          }
          buttonSecondary={
            <Button
              value="Cancel"
              disabled={loading}
              onClick={() => setPermissionGroupToDelete(null)}
              secondaryGray
            />
          }
          heading={`Are you sure you want to delete the ${permissionGroupToDelete.name} Permission Group?`}
          className="alert-categories__modal"
        />
      )}
      <Helmet>
        <title>{headerText}</title>
      </Helmet>
      <div className="heading" data-cy="page-heading">
        {headerText}
      </div>
      <PermissionGroupsForm
        defaultState={permissionGroup}
        isNew={isCreate}
        hasCreateAccess={hasCreateAccess}
        hasEditAccess={hasEditAccess}
        onDelete={
          hasDeleteAccess &&
          !permissionGroup?.users?.length &&
          !duplicationTargetId
            ? setPermissionGroupToDelete
            : undefined
        }
        setDirty={setDirty}
      />
    </section>
  )
}

export default PermissionGroupManagementPage
