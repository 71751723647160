export const BUSINESS_UNIT_DEFAULT = {
  new: true,
  key: '',
  name: '',
  contact: {
    name: '',
    email: '',
    phone: '',
    primaryContactPhone: '',
  },
  accountLead: null,
  slack: '',
  defaultApprover: null,
  backupApprover: null,
  clientId: '',
  accounts: [],
  users: [],
  reportings: [],
  facebookKPIAttribution: {
    primaryKPIs: {},
    secondaryKPIs: {},
  },
  tableauConfig: {
    vertical: null,
    reporting: null,
  },
  offlineData: [],
}
