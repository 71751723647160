import { ACCOUNT_TYPES } from 'modules/accounts/common.js'

import { ReactComponent as GlobalProviderIcon } from 'assets/global-provider.svg'
import { campaignExclusions } from '@decision-sciences/qontrol-common'

const {
  CAMPAIGN_EXCLUSION_RULE_PROPERTIES,
  CAMPAIGN_EXCLUSIONS_OPERATION_TYPES,
} = campaignExclusions

export const PROPERTY_TYPES = [
  {
    label: 'Account',
    value: CAMPAIGN_EXCLUSION_RULE_PROPERTIES.ACCOUNT_ID,
  },
  {
    label: 'Campaign Name',
    value: CAMPAIGN_EXCLUSION_RULE_PROPERTIES.CAMPAIGN_NAME,
  },
  {
    label: 'Channel Type',
    value: CAMPAIGN_EXCLUSION_RULE_PROPERTIES.CHANNEL_TYPE,
  },
]

export const ALL_ENTITIES_SELECTED = {
  ACCOUNTS: 'ALL_ACCOUNTS',
  CHANNEL_TYPES: 'ALL_CHANNEL_TYPES',
}

export const CHECKBOX_LABELS = {
  alertsLabel: 'Exclude from Alerts and EmbarQ',
  reportingLabel: 'Exclude from Reporting',
}

export const PUBLISHERS = [
  {
    id: 'GLOBAL',
    name: 'Global',
    icon: GlobalProviderIcon,
  },
  ACCOUNT_TYPES[3],
  ACCOUNT_TYPES[4],
  ACCOUNT_TYPES[7],
  ACCOUNT_TYPES[9],
  ACCOUNT_TYPES[10],
  ACCOUNT_TYPES[14],
  ACCOUNT_TYPES[15],
  ACCOUNT_TYPES[0],
  ACCOUNT_TYPES[1],
  ACCOUNT_TYPES[2],
  ACCOUNT_TYPES[13],
  ACCOUNT_TYPES[16],
]

export const defaultRule = {
  property: CAMPAIGN_EXCLUSION_RULE_PROPERTIES.CAMPAIGN_NAME,
  value: null,
  operation: CAMPAIGN_EXCLUSIONS_OPERATION_TYPES[0].value,
}

export const defaultPublisherConfig = {
  rules: [{ ...defaultRule }],
  operand: 'AND',
}
