import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import checkIcon from 'assets/icon_check_green.svg'
import cancelIcon from 'assets/icon_clear_red.svg'

import './style.scss'

/**
 * Returns a React Input Component with a clickable information mark
 * @param {Object} params Component Parameters
 * @param {String} [params.defaultValue] Value that is initially set for the input
 * @param {String} [params.className] Class Name(s) that need(s) to be applied to Input
 * @param {Boolean} [params.disabled] If disabled, user won't be able to input text
 * @param {Function} [params.onChange] Gets called with current input value on typing in the input
 * @param {Function} params.onConfirm Gets called with current input value (after validations) on clicking the confirmation button
 * @param {Function} params.onCancel Gets called on clicking the cancel button
 * @param {Object} [params.validationOptions] Object holding validation-related attributes
 * @param {Function} [params.validationOptions.validate] If it exists, changing the input value will be cancelled if validate(new value) is false
 * @param {Function} [params.validationOptions.transform] The value will be transformed into the result of transform function. (Ex. You might want to run parseInt(value) before validating and returning it)
 * @param {String} [params.error] Error text to display
 * @returns {React.Component}
 */
const InputWithConfirmation = ({
  defaultValue,
  className,
  disabled,
  onChange = () => {},
  onConfirm,
  onCancel,
  validationOptions = {
    validate: null,
    transform: null,
  },
  error,
  ...other
}) => {
  const [value, setValue] = useState(defaultValue?.toString() || '')
  const [confirmDisabled, setConfirmDisabled] = useState(false)

  useEffect(() => {
    if (error) {
      setConfirmDisabled(true)
    }
  }, [error])

  useEffect(() => {
    setValue(value)
  }, [defaultValue])

  /** On input change */
  const onInputChange = (ev) => {
    const { transform } = validationOptions

    let newValue = ev.target.value
    if (transform && newValue) {
      const transformed = transform(newValue)
      if (transformed) {
        newValue = transformed
      }
    }

    setConfirmDisabled(false)
    setValue(newValue)
    ev.target.value = newValue
    onChange && onChange(newValue)
  }

  return (
    <div
      className={cx('confirm-input', {
        [className]: className,
        'confirm-input--error': Boolean(error),
      })}
    >
      <input
        onChange={!disabled ? onInputChange : () => {}}
        value={value || ''}
        autoFocus
        onKeyDown={(ev) => {
          const { validate } = validationOptions

          if (ev.key === 'Enter') {
            onConfirm(value)
            return
          }

          if (validate) {
            if (ev.key === 'Backspace') {
              return
            }

            const newValue = `${ev.target.value}${ev.key}`
            if (!validate(newValue)) {
              return ev.preventDefault()
            }
          }
        }}
        className="confirm-input__field"
        {...other}
      />
      <div className="confirm-input__buttons">
        {onCancel && (
          <img
            alt="Cancel Icon"
            src={cancelIcon}
            role="button"
            onClick={() => {
              onCancel()
            }}
            className="confirm-input__buttons__button"
          />
        )}
        <img
          alt="Check Icon"
          src={checkIcon}
          role="button"
          onClick={() => {
            onConfirm(value)
          }}
          className={cx('confirm-input__buttons__button', {
            'confirm-input__buttons__button--disabled': confirmDisabled,
          })}
        />
      </div>
    </div>
  )
}

InputWithConfirmation.propTypes = {
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  validationOptions: PropTypes.object,
  error: PropTypes.node,
}

export default InputWithConfirmation
