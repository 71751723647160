import React from 'react'
import PropTypes from 'prop-types'

import { accounts, alert } from '@decision-sciences/qontrol-common'

import { SinglePerformanceGoogle } from './providers/google'
import { SinglePerformanceFacebook } from './providers/facebook'
import { SinglePerformanceMicrosoft } from './providers/microsoft'
import { SinglePerformanceTradeDesk } from './providers/trade-desk'
import { SinglePerformanceLinkedIn } from './providers/linkedin'
import { SinglePerformanceTikTok } from './providers/tiktok'
import { SinglePerformanceAmazonDSP } from './providers/amazon-dsp'
import { SinglePerformanceDV360 } from './providers/dv360'
import { SinglePerformanceSA360 } from './providers/sa360'
import { SinglePerformanceAmazonAdvertising } from './providers/amazon-advertising'

import './style.scss'

const { ALERT_ELEMENT_TO_FIELD_NAME } = alert

const { ACCOUNT_TYPES_MAP } = accounts
const {
  GOOGLE,
  FACEBOOK,
  MICROSOFT,
  TRADE_DESK,
  LINKED_IN,
  TIKTOK,
  AMAZON_DSP,
  DV360,
  SA360,
  AMAZON_ADVERTISING,
} = ACCOUNT_TYPES_MAP

const COMPONENTS = {
  [GOOGLE]: SinglePerformanceGoogle,
  [FACEBOOK]: SinglePerformanceFacebook,
  [MICROSOFT]: SinglePerformanceMicrosoft,
  [TRADE_DESK]: SinglePerformanceTradeDesk,
  [LINKED_IN]: SinglePerformanceLinkedIn,
  [TIKTOK]: SinglePerformanceTikTok,
  [AMAZON_DSP]: SinglePerformanceAmazonDSP,
  [DV360]: SinglePerformanceDV360,
  [AMAZON_ADVERTISING]: SinglePerformanceAmazonAdvertising,
  [SA360]: SinglePerformanceSA360,
}

export const SinglePerformanceApplyTo = ({
  alert,
  onChangeAlert,
  accounts,
  errors,
  setErrors,
  readOnly,
}) => {
  const clientId = alert.companies[0]

  if (alert.companies.length !== 1) {
    return null
  }

  const selectedAccountsMap = alert.selectedAccounts.reduce(
    (prev, current) => ({ ...prev, [current]: true }),
    {}
  )

  const onChangeSelectedElements = (platform) => (selectedElements) => {
    onChangeAlert(
      {
        selectedElements: {
          ...alert.selectedElements,
          [platform]: selectedElements,
        },
      },
      true
    )
  }

  const onChangeError = (TYPE) => (newErrors) => {
    setErrors({
      ...errors,
      selectedElements: {
        ...errors.selectedElements,
        [TYPE]: {
          ...(errors.selectedElements?.[TYPE] || {}),
          ...newErrors,
        },
      },
    })
  }

  /**
   * Generic Props for different Account Types
   * These are not mandatory to use, if ever the need arises, the props can be filled in manually.
   * @param {String} TYPE Account Type
   * @returns {Object}
   */
  const getGenericProps = (TYPE) => {
    return {
      clientId,
      state: alert.selectedElements[TYPE] || {},
      onChange: onChangeSelectedElements(TYPE),
      accounts: accounts[TYPE] || [],
      elementConfig: ALERT_ELEMENT_TO_FIELD_NAME[TYPE],
      errors: errors.selectedElements?.[TYPE] || {},
      onChangeErrors: onChangeError(TYPE),
      readOnly,
    }
  }

  const renderSinglePerformancePlatform = (TYPE) => {
    if (!selectedAccountsMap[TYPE] || !accounts[TYPE]) {
      return null
    }

    const SinglePeformanceComponent = COMPONENTS[TYPE]

    return <SinglePeformanceComponent {...getGenericProps(TYPE)} />
  }

  return (
    <>
      {/* We're using COMPONENTS to map instead selectedAccountsMap so as to allow different configurations should the need arise */}
      {Object.keys(COMPONENTS).map((TYPE) =>
        renderSinglePerformancePlatform(TYPE)
      )}
    </>
  )
}

SinglePerformanceApplyTo.propTypes = {
  alert: PropTypes.object.isRequired,
  onChangeAlert: PropTypes.func.isRequired,
  accounts: PropTypes.object.isRequired,
  errors: PropTypes.object,
  setErrors: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}
