import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

/* Components */
import Section from 'components/section/index'
import Button from 'components/button/index'
import InputGroup from 'components/input-group/index'
import Icon from 'components/icon/index'
import CheckboxSelectAll, {
  useSelectAll,
} from 'components/checkbox-select-all/index'
import { Dropdown } from 'components/dropdown/index'
import ButtonToggle from 'components/button-toggle/index'
import Modal from 'components/modal/index'
import Table from 'components/table/beta/index'
import InputText from 'components/input/index'
import Loader from 'components/loader/index'
import InformationBlock, {
  INFORMATION_BLOCK_TYPE,
} from 'components/information-block/index'

/* Utils */
import { notifications, utils } from '@decision-sciences/qontrol-common'

/* Assets */
import { ReactComponent as DeleteIcon } from 'assets/icon_minus_red.svg'
import { ReactComponent as RemoveIcon } from 'assets/icon_table_remove.svg'
import { ReactComponent as AddIcon } from 'assets/icon_plus_blue.svg'
import { ReactComponent as AddIcon2 } from 'assets/icon_plus_white.svg'
import { ReactComponent as SaveIcon } from 'assets/icon_save-green.svg'

import editIcon from 'assets/icon_edit.svg'

import './style.scss'

const { keyBy } = utils.array

const { DELIVERY_OPTIONS, DELIVERY_OPTIONS_MAP } = notifications

const { SMS, EMAIL, SLACK } = DELIVERY_OPTIONS_MAP

const AVAILABLE_DELIVERY_OPTIONS = DELIVERY_OPTIONS.filter(({ value }) =>
  [SMS, EMAIL].includes(value)
)

const EMPTY_ALERT_CONFIG = {
  alert: null,
  alertName: null,
  channels: [],
}

/**
 * Mini-module for handling Alert Notification settings at the Team level.
 *
 * @param {Object} params React Params
 * @param {Array} [params.alerts] Alerts with notification options
 * @param {Array} [params.availableAlerts] Alerts that the current user has access to
 * @param {Object} params.changes Object containing fresh changes for alert notifications
 * @param {Function} params.onChange Callback for making changes to alert notifications.
 * @param {Boolean} [params.readOnly] Whether edits should be allowed or not.
 * @param {Object} [params.errors] Object of validation errors
 * @param {Function} params.setErrors Callback for changing notification errors
 */
export const AlertNotificationSection = ({
  alerts,
  availableAlerts,
  changes,
  onChange,
  readOnly,
  errors = {},
  setErrors,
}) => {
  const [isBulkEdit, setIsBulkEdit] = useState(false)
  const [bulkEditState, setBulkEditState] = useState({})
  const [addModalState, setAddModalState] = useState(null)
  const [addModalSearch, setAddModalSearch] = useState('')

  const alertsWithChanges = useMemo(() => {
    if (!alerts) {
      return []
    }

    const remainingChanges = structuredClone(changes)

    const alertsWithChanges = alerts.reduce((prev, current) => {
      const change = changes[current.id]
      if (!change) {
        return [...prev, current]
      }

      if (change.removed) {
        delete remainingChanges[current.id]
        return prev
      }

      delete remainingChanges[current.id]
      return [...prev, { ...current, ...change }]
    }, [])

    // Any remaining changes are additions, since removals and updates are handled and deleted when processing
    const wholeList = [
      ...alertsWithChanges,
      ...Object.values(remainingChanges).filter(({ added }) => added),
    ]

    if (wholeList.length) {
      return wholeList
    }

    // If there are no found alerts, default with an empty notification channel
    return [...wholeList, ...Object.values(getDefaultNotificationSetting())]
  }, [alerts, changes])

  const availableAlertsMap = useMemo(() => {
    if (!availableAlerts) {
      return []
    }

    const existingAlerts = new Set(alertsWithChanges.map(({ alert }) => alert))

    // Here filter based on what's already selected
    return keyBy(
      availableAlerts
        .filter(({ _id }) => !existingAlerts.has(_id))
        .map(({ name, _id }) => ({ label: name, value: _id })),
      'value'
    )
  }, [alertsWithChanges, availableAlerts])

  const {
    allCheckedValue,
    toggleAll,
    getIndividualValue,
    toggleSelected,
    isSomeSelected,
    selectedItems,
  } = useSelectAll(alertsWithChanges, 'id')

  const shownError = useMemo(() => {
    return Object.values(errors).find(
      (error) => error?.channels || error?.alert
    )
  }, [errors])

  const getAlertChanges = (alertId) => {
    const [id, change] =
      Object.entries(changes).find(([id, change]) => {
        return change.alert === alertId
      }) || []

    return change || {}
  }

  const getChangesForItemDeletion = (id, newChanges = { ...changes }) => {
    if (newChanges[id]) {
      if (newChanges[id].added) {
        delete newChanges[id]
      } else if (newChanges[id]) {
        newChanges[id].removed = true
      }
    } else {
      const found = alerts.find(
        (notificationOption) => notificationOption.id === id
      )
      if (found) {
        newChanges[id] = { ...found, removed: true }
      }
    }

    return newChanges
  }

  const getChangesForChannelEdit = (
    id,
    channels,
    newChanges = { ...changes }
  ) => {
    if (newChanges[id]) {
      newChanges[id].channels = channels
    } else {
      const found = alerts.find(
        (notificationOption) => notificationOption.id === id
      )
      if (found) {
        newChanges[id] = { ...found, channels: channels }
      } else {
        newChanges[id] = {
          ...EMPTY_ALERT_CONFIG,
          id: id,
          added: true,
          channels,
        }
      }
    }

    return newChanges
  }

  const isRemovalDisabled =
    alertsWithChanges.length === 1 && !alertsWithChanges[0].alert

  const renderActionSection = () => {
    if (readOnly || !onChange) {
      return null
    }

    const onRemoveSelected = () => {
      const newChanges = { ...changes }
      const newErrors = { ...errors }
      Object.keys(selectedItems).forEach((id) => {
        getChangesForItemDeletion(id, newChanges)
        newErrors[id] = null
      })

      setErrors(newErrors)
      onChange(newChanges)
      toggleAll(false)
    }

    return (
      <div className="teams-alert-notifications__actions">
        <div className="teams-alert-notifications__actions__main">
          <Button
            data-cy="teams-alert-notifications-add"
            disabled={!alerts || isBulkEdit || !availableAlerts}
            compact
            secondary
            onClick={() => {
              setAddModalState([])
            }}
            value={
              <div className="align-row gap-10 align-items-center">
                <AddIcon width={12} height={12} />
                <span>Add Alerts</span>
              </div>
            }
          />
        </div>
        <div className="teams-alert-notifications__actions__secondary">
          <CheckboxSelectAll
            disabled={!alerts || isBulkEdit}
            className="button button--secondary perform-qa__button"
            value={allCheckedValue}
            label="Select All"
            onClick={() => toggleAll()}
          />
          <ButtonToggle
            className="teams-alert-notifications__actions__toggle-edit"
            icon={editIcon}
            disabled={!alerts || !isSomeSelected}
            onClick={() => {
              setIsBulkEdit(!isBulkEdit)
              setBulkEditState({})
            }}
            selected={isBulkEdit}
            label="Edit Selected"
          />
          <Button
            disabled={
              !alerts || !isSomeSelected || isBulkEdit || isRemovalDisabled
            }
            compact
            secondaryRed
            onClick={onRemoveSelected}
            value={
              <div className="align-row gap-10 align-items-center">
                <DeleteIcon width={16} height={16} />
                <span>Remove Selected</span>
              </div>
            }
          />
        </div>
      </div>
    )
  }

  const renderBulkEditDropdown = () => {
    if (!isBulkEdit) {
      return null
    }
    return (
      <Dropdown
        labelTop
        selectAll
        label="Notification Methods"
        multiSelect
        selectedItems={bulkEditState.channels}
        reorderSelectedOnTop={false}
        className="half-width"
        options={AVAILABLE_DELIVERY_OPTIONS}
        defaultOptionText="Select Notification Methods"
        onChange={(values) => setBulkEditState({ channels: values })}
      />
    )
  }

  const renderBulkEditActions = () => {
    if (!isBulkEdit) {
      return null
    }

    const onChangeSelected = () => {
      const channels = bulkEditState?.channels

      if (channels) {
        let newChanges = { ...changes }
        const newErrors = { ...errors }

        Object.keys(selectedItems).forEach((id) => {
          newChanges = getChangesForChannelEdit(id, channels, newChanges)
          newErrors[id] = null
        })

        setErrors(newErrors)
        onChange(newChanges)
      }
    }

    const onConfirm = () => {
      onChangeSelected()

      toggleAll(false)
      setBulkEditState({})
      setIsBulkEdit(false)
    }

    return (
      <div className="teams-alert-notifications__bulk__actions">
        <Button
          disabled={!alerts || !isSomeSelected}
          compact
          secondaryGreen
          onClick={onConfirm}
          value={
            <div className="align-row gap-10 align-items-center">
              <SaveIcon width={16} height={16} />
              <span>Save</span>
            </div>
          }
        />
        <Button
          secondaryGray
          compact
          onClick={() => {
            setBulkEditState({})
            setIsBulkEdit(false)
          }}
          value="Cancel"
        />
      </div>
    )
  }

  const renderListSection = () => {
    const onAddSingleItem = () => {
      const newId = uuidv4()
      onChange({
        ...changes,
        [newId]: {
          ...EMPTY_ALERT_CONFIG,
          id: newId,
          added: true,
        },
      })
    }

    const onChangeAlert = (alertNotification, alertId) => {
      const { id } = alertNotification
      const selectedAlert = availableAlertsMap[alertId]
      const oldChange = getAlertChanges(alertId)

      const newChanges = { ...changes }

      const change = newChanges[id]

      if (change) {
        const newId = oldChange.id || id

        newChanges[newId] = {
          ...change,
          id: newId,
          alert: selectedAlert.value,
          alertName: selectedAlert.label,
        }

        // Replace previously deleted value with new value, so we don't have repeat changes stored
        if (oldChange.id) {
          delete newChanges[id]
          delete newChanges[newId].removed
          delete newChanges[newId].added
        }
      } else {
        // If there's no previous change and we attempt to change the alert, we have to make 2 entries: one for the change and one for the removal
        if (!oldChange.removed) {
          newChanges[id] = {
            ...alertNotification,
            removed: true,
          }
        }
        let newId = oldChange.id || uuidv4()

        if (alertNotification.added) {
          newId = alertNotification.id
        }

        newChanges[newId] = {
          ...alertNotification,
          id: newId,
          alert: selectedAlert.value,
          alertName: selectedAlert.label,
        }

        // Mark the new entry as added or unmark the old entry as removed
        if (oldChange.id) {
          newChanges[newId].removed = false
        } else {
          newChanges[newId].added = true
        }
      }

      setErrors({ ...errors, [id]: null })
      onChange(newChanges)
    }

    const onChangeChannels = (id, channels) => {
      const newChanges = getChangesForChannelEdit(id, channels)
      setErrors({ ...errors, [id]: null })
      onChange(newChanges)
    }

    const onRemoveSingleItem = (id) => {
      const newChanges = getChangesForItemDeletion(id)
      toggleSelected(id, false)
      setErrors({ ...errors, [id]: null })
      onChange(newChanges)
    }

    return (
      <section className="teams-alert-notifications__alerts">
        {alertsWithChanges.map((alert, index) => {
          return (
            <InputGroup
              key={alert.id}
              options={[
                {
                  render: (
                    <CheckboxSelectAll
                      isBig
                      isBlue
                      disabled={isBulkEdit}
                      value={getIndividualValue(alert.id)}
                      onClick={() => toggleSelected(alert.id)}
                    />
                  ),
                  condition: !readOnly,
                  width: '35px',
                },
                {
                  render: readOnly ? (
                    <div>{alert.alertName}</div>
                  ) : (
                    <Dropdown
                      disabled={isBulkEdit || readOnly}
                      defaultOptionText={
                        alert.alertName ? alert.alertName : 'Select Alert'
                      }
                      onChange={(changes) => onChangeAlert(alert, changes)}
                      options={Object.values(availableAlertsMap)}
                    />
                  ),
                  label: index === 0 && 'Alert',
                  error: errors[alert.id]?.alert,
                },
                {
                  render: readOnly ? (
                    <div>
                      {AVAILABLE_DELIVERY_OPTIONS.reduce(
                        (prev, { value, label }) => {
                          if (!alert.channels.includes(value)) {
                            return prev
                          }

                          return `${prev}${prev.length ? ', ' : ''}${label}`
                        },
                        ''
                      )}
                    </div>
                  ) : (
                    <Dropdown
                      reorderSelectedOnTop={false}
                      selectAll
                      disabled={isBulkEdit || readOnly}
                      options={AVAILABLE_DELIVERY_OPTIONS}
                      selectedItems={alert.channels}
                      multiSelect
                      defaultOptionText="Select Notification Methods"
                      onChange={(channels) =>
                        onChangeChannels(alert.id, channels)
                      }
                    />
                  ),
                  label: index === 0 && 'Notification Options',
                  error: errors[alert.id]?.channels,
                },
                {
                  render: (
                    <Icon
                      disabled={
                        isBulkEdit ||
                        // Disable deletion for single items without an alert selected
                        isRemovalDisabled
                      }
                      onClick={() => onRemoveSingleItem(alert.id)}
                    >
                      <RemoveIcon />
                    </Icon>
                  ),
                  condition: !readOnly,
                  width: '35px',
                },
              ]}
            />
          )
        })}
        {shownError && (
          <InformationBlock
            style={{
              display: 'inline-flex',
              width: 'unset',
              marginTop: '10px',
            }}
            type={INFORMATION_BLOCK_TYPE.ERROR}
            info={
              shownError.channels
                ? 'Added alerts must have at least one Notification Method selected'
                : 'Please select an alert'
            }
          />
        )}
        {!readOnly && (
          <Button
            disabled={!Object.keys(availableAlertsMap).length || isBulkEdit}
            className="margin-top-15"
            compact
            secondary
            onClick={onAddSingleItem}
            value={
              <div className="align-row gap-10 align-items-center">
                <AddIcon2 width={18} height={18} />
                <span>Add Alert Notification</span>
              </div>
            }
          />
        )}
      </section>
    )
  }

  const renderAddModal = () => {
    if (!addModalState) {
      return null
    }

    const onCloseModal = () => {
      setAddModalSearch('')
      setAddModalState(null)
    }

    const onAddSelected = () => {
      // If there are no found alerts, default with an empty notification channel
      if (!Object.keys(changes).length && !alerts.length) {
        changes = getDefaultNotificationSetting()
      }

      const newChanges = { ...changes }

      addModalState.forEach(({ label, value }) => {
        const oldChange = getAlertChanges(value)

        if (oldChange.removed) {
          delete newChanges[oldChange.id].removed
          newChanges[oldChange.id].channels = []
        } else {
          const newId = uuidv4()
          newChanges[newId] = {
            id: newId,
            alertName: label,
            alert: value,
            channels: [],
            added: true, // Mark as added, we'll just delete the entry in case we attempt to remove it
          }
        }
      })
      onChange(newChanges)
      onCloseModal()
    }

    return (
      <Modal
        className="teams-alert-notifications__modal"
        heading="Add Alerts"
        contentSeparator
        opened={addModalState}
        rightAlignButtons
        button={
          <Button
            disabled={!addModalState?.length}
            compact
            secondary
            onClick={onAddSelected}
            value={
              <div className="align-row gap-10 align-items-center">
                <AddIcon width={12} height={12} />
                <span>
                  Add Selected{' '}
                  {addModalState.length ? `(${addModalState.length})` : ''}
                </span>
              </div>
            }
          />
        }
        buttonSecondary={
          <Button secondaryGray compact onClick={onCloseModal} value="Cancel" />
        }
      >
        <InputText
          className="margin-bottom-10"
          value={addModalSearch}
          onChange={setAddModalSearch}
          search
          placeholder="Search"
        />
        <Table
          columns={[
            {
              header: 'Alert',
              accessorKey: 'label',
            },
          ]}
          data={Object.entries(availableAlertsMap).map(([_id, alert]) => ({
            _id,
            ...alert,
          }))}
          onSelect={setAddModalState}
          manualFilter={addModalSearch}
          height={500}
          keepRowSelection
          initialState={{
            selectedRowIds: addModalState.map((r) => r.tableRowId),
          }}
        />
      </Modal>
    )
  }

  const heading = readOnly
    ? 'Notification Channels'
    : 'Manage Notification Channels'

  if (readOnly && !alerts?.length) {
    return null
  }

  return (
    <>
      {renderAddModal()}
      <Section
        header={
          <h3 className="generic-heading generic-heading--no-margin">
            {heading}
          </h3>
        }
        className="teams-alert-notifications"
      >
        {renderActionSection()}
        {renderBulkEditDropdown()}
        {renderBulkEditActions()}
        {renderListSection()}
      </Section>
    </>
  )
}

export const validateAlertNotificationChanges = (changes = {}) => {
  let isValid = true
  const errors = {}

  Object.values(changes).forEach(({ id, removed, channels, alert }) => {
    errors[id] = {}
    if (removed || !alert) {
      return
    }

    // If slack remains as a channel we don't really care as it has nothing to do with teams
    if (!channels?.length || (channels.length === 1 && channels[0] === SLACK)) {
      isValid = false
      errors[id].channels = 'required'
    }
  })

  return [isValid, errors]
}

AlertNotificationSection.propTypes = {
  changes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  alerts: PropTypes.array,
  availableAlerts: PropTypes.array,
  readOnly: PropTypes.bool,
  errors: PropTypes.object,
  setErrors: PropTypes.func.isRequired,
}

const getDefaultNotificationSetting = () => {
  const newId = uuidv4()

  return {
    [newId]: {
      ...EMPTY_ALERT_CONFIG,
      id: newId,
      added: true,
    },
  }
}
