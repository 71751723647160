import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { utils } from '@decision-sciences/qontrol-common'

import LineDivider from 'components/line-divider'
import InputText from 'components/input'
import PublisherSelection from 'components/publisher-selection'
import { CheckboxNoHooks } from 'components/checkbox'
import InformationBlock from 'components/information-block'
import { ACCOUNT_TYPES } from 'modules/accounts/common'

import {
  CHECKBOX_LABELS,
  defaultPublisherConfig,
  PUBLISHERS,
} from '../../constants'
import CreateActions from './create-actions'
import Rules from './rules'

import '../../style.scss'

const { getUniqueListByKey } = utils.array

/**
 * Create campaign exclusion panel & its functionalities
 * @param {Object} props
 * @param {Object} props.company - The company we're editing Campaign Exclusion Settings for
 * @param {Object} props.fields - The fields for the input group rule
 * @param {Function} props.onSaveClicked - Method to be called when the save button is clicked
 * @param {Function} props.onCancelClicked - Method to be called when the cancel button is clicked
 * @param {Object} props.editedExclusion - Exclusion object to be edited
 * @param {Function} props.setEditedExclusion - Method to be called to set the edited exclusion
 * @param {String} [props.businessUnitClientId] - Business unit's key for which we render the campaign exclusion section
 * @param {String} props.exclusions - Current client exclusions
 */
const CampaignExclusionSettings = ({
  company,
  onSaveClicked,
  onCancelClicked,
  fields,
  editedExclusion,
  setEditedExclusion,
  businessUnitClientId,
  exclusions,
}) => {
  const [publishers, setPublishers] = useState([...PUBLISHERS])
  const [exclusion, setExclusion] = useState({})

  useEffect(() => {
    // When an editedExclusion is provided (on edit exclusion action triggered) update the providers
    if (editedExclusion?._id) {
      setPublishers((currentPublishers) =>
        currentPublishers.map((publisher) => {
          const updatedPublisher = { ...publisher }
          const publisherKey = publisher.id.toUpperCase()

          if (
            (editedExclusion.globalConfig &&
              publisherKey === 'GLOBAL' &&
              !publisher.disabled) ||
            (editedExclusion.publishersConfig?.[publisherKey] &&
              !publisher.disabled)
          ) {
            // If the updated exclusion has the current publisher, then mark it as selected
            updatedPublisher.selected = true
          } else {
            // If the updated exclusion does NOT have the current publisher, then mark it as unselected
            updatedPublisher.selected = false
          }

          return updatedPublisher
        })
      )
    }
  }, [JSON.stringify(editedExclusion)])

  const getIconByPublisherKey = (publisherKey) => {
    return publisherKey !== 'GLOBAL'
      ? ACCOUNT_TYPES.find((p) => p.id.toUpperCase() === publisherKey)?.icon
      : ''
  }

  // We create a map with all the accounts by publisher to easily use them afterwards at the input group level
  const accountsByPublisher = useMemo(() => {
    const accounts = company.accounts

    const accountsMap = accounts.reduce((collector, account) => {
      const publisherKey = account.type.toUpperCase()
      const accountAsOption = {
        label: account.name,
        value: account.key,
        Icon: getIconByPublisherKey(publisherKey),
      }

      if (!collector[publisherKey]) {
        collector[publisherKey] = []
      }

      collector[publisherKey].push(accountAsOption)

      if (!collector.GLOBAL) {
        collector.GLOBAL = []
      }

      // Update the global accounts with the latest unique values from all the publishers
      collector.GLOBAL = Array.from(
        getUniqueListByKey([...collector.GLOBAL, accountAsOption], 'value')
      )

      return collector
    }, {})

    // Whenever the accounts change, we also want to mark as disabled the publishers
    // that do not have accounts
    setPublishers((currentPublishers) => {
      return currentPublishers.map((publisher) => {
        if (
          !accountsMap[publisher.id.toUpperCase()] &&
          publisher.id !== 'GLOBAL'
        ) {
          publisher.disabled = true
        } else {
          publisher.disabled = false
        }

        return publisher
      })
    })

    return accountsMap
  }, [JSON.stringify(company), businessUnitClientId])

  const validate = () => {
    const updatedExclusion = { ...exclusion }
    let isValid = true

    // Checks for the existence of any other exclusion with the same name on 'Save' click
    updatedExclusion.duplicatedName = exclusions.some((exclusion) => {
      if (
        exclusion.name === updatedExclusion.name &&
        exclusion._id !== updatedExclusion._id
      ) {
        isValid = false
        return true
      }
    })

    if (
      !updatedExclusion.globalConfig &&
      (!updatedExclusion.publishersConfig ||
        !Object.keys(updatedExclusion.publishersConfig).length)
    ) {
      isValid = false
    }

    if (updatedExclusion.globalConfig) {
      if (updatedExclusion.globalConfig.rules.some((rule) => rule.duplicated)) {
        isValid = false
      }
      for (const rule of updatedExclusion.globalConfig.rules) {
        if (
          !rule.value ||
          (rule.value && Array.isArray(rule.value) && rule.value.length === 0)
        ) {
          isValid = false
          rule.value = []
        } else if (
          rule.value &&
          typeof rule.value === 'string' &&
          rule.value === ''
        ) {
          isValid = false
          rule.value = ''
        }
      }
    } else if (
      updatedExclusion.publishersConfig &&
      Object.keys(updatedExclusion.publishersConfig).length
    ) {
      if (
        Object.keys(updatedExclusion.publishersConfig).some((publisher) =>
          updatedExclusion.publishersConfig[publisher].rules.some(
            (rule) => rule.duplicated
          )
        )
      ) {
        isValid = false
      }
      // We check for the rule value not to be empty as the field is mandatory
      for (const publisherKey of Object.keys(
        updatedExclusion.publishersConfig
      )) {
        for (const rule of updatedExclusion.publishersConfig[publisherKey]
          .rules) {
          if (
            !rule.value ||
            (rule.value && Array.isArray(rule.value) && rule.value.length === 0)
          ) {
            isValid = false
            rule.value = []
          } else if (
            rule.value &&
            typeof rule.value === 'string' &&
            rule.value === ''
          ) {
            isValid = false
            rule.value = ''
          }
        }
      }
    }
    setExclusion(updatedExclusion)
    return isValid
  }

  // Validate exclusion's data whenever it's changed
  const [isExclusionValid, errors] = useMemo(() => {
    const newErrors = {}
    let isValid = true

    if (exclusion.name === '') {
      newErrors.name = true
      isValid = false
    } else if (!exclusion.name) {
      isValid = false
    }

    return [isValid, newErrors]
  }, [JSON.stringify(exclusion)])

  /**
   * Whenever the publishers state (selection) is changed, build the rule accordingly.
   * If a publisher is selected, keep it or append it if it's not already in the rule's publishersConfig or globalConfig.
   */
  useEffect(() => {
    let updatedExclusion =
      editedExclusion?._id && !Object.keys(exclusion).length
        ? JSON.parse(JSON.stringify(editedExclusion))
        : { ...exclusion }

    // We loop through all the publishers and build the rule properties accordingly
    publishers.forEach((publisher) => {
      if (publisher.disabled) {
        return
      }

      if (publisher.id === 'GLOBAL') {
        // In the case of global publisher, we set the globalConfig as {} if it's selected or null otherwise.
        if (publisher.selected === true) {
          updatedExclusion.globalConfig =
            // editedExclusion?._id &&
            Object.keys(updatedExclusion.globalConfig || {}).length
              ? { ...updatedExclusion.globalConfig }
              : {
                  ...defaultPublisherConfig,
                }
        } else if (!editedExclusion?.globalConfig) {
          delete updatedExclusion.globalConfig
        }
      } else {
        // In the case of a custom publisher, we set the publishersConfig[publisherKey]
        // If it's selected, we keep the publishersConfig[publisherKey] value if it's already present or set a default {} for it.
        // If it's unselected, we remove the publishersConfig[publisherKey] value
        const publisherKey = publisher.id.toUpperCase()
        if (publisher.selected) {
          updatedExclusion = {
            ...updatedExclusion,
            publishersConfig: {
              ...(updatedExclusion.publishersConfig || {}),
              // Keep existing publisher's rule data or set the default values
              [publisherKey]: updatedExclusion.publishersConfig?.[
                publisherKey
              ] || {
                ...defaultPublisherConfig,
              },
            },
          }
        } else if (
          updatedExclusion.publishersConfig?.[publisherKey] &&
          !editedExclusion?.publishersConfig?.[publisherKey]
        ) {
          delete updatedExclusion.publishersConfig[publisherKey]
        }
      }
    })

    setExclusion(updatedExclusion)
    if (updatedExclusion._id === editedExclusion._id) {
      setEditedExclusion({})
    }
  }, [JSON.stringify(publishers)])

  const onRulePropertyChanged = (value, property) =>
    setExclusion({ ...exclusion, [property]: value, duplicatedName: false })

  const cleanCurrentExclusion = () => {
    const updatedExclusion = { ...exclusion }

    if (updatedExclusion.globalConfig) {
      for (const rule of updatedExclusion.globalConfig.rules) {
        rule.value = null
      }
    } else if (
      updatedExclusion.publishersConfig &&
      Object.keys(updatedExclusion.publishersConfig).length
    ) {
      for (const publisherKey of Object.keys(
        updatedExclusion.publishersConfig
      )) {
        for (const rule of updatedExclusion.publishersConfig[publisherKey]
          .rules) {
          rule.value = null
        }
      }
    }

    setExclusion(updatedExclusion)
  }

  return (
    <>
      <div className="campaign-exclusions-section__title">Settings</div>
      <LineDivider className="margin-bottom-16" width="unset" widthUnit="" />
      <div className="width-50 padding-right-0">
        <div
          className={cx('general-label', {
            'campaign-exclusions-section__labels label-error':
              exclusion.duplicatedName,
          })}
        >
          RULE NAME
        </div>
        <InputText
          error={errors.name || exclusion.duplicatedName}
          value={exclusion.name}
          placeholder="Enter Rule Name"
          onChange={(value) => onRulePropertyChanged(value, 'name')}
        />
        {exclusion.duplicatedName ? (
          <InformationBlock
            className="name-information-block"
            info="Campaign Exclusion Rule name already exists."
          />
        ) : null}
      </div>
      <PublisherSelection
        label="APPLY TO"
        publishers={publishers}
        onSelectionChanged={setPublishers}
      />
      <div>
        <LineDivider />
      </div>
      <CheckboxNoHooks
        label={CHECKBOX_LABELS.alertsLabel}
        defaultValue={exclusion.excludeFromAlerts}
        reversed
        onChange={(value) => onRulePropertyChanged(value, 'excludeFromAlerts')}
      />
      <CheckboxNoHooks
        label={CHECKBOX_LABELS.reportingLabel}
        defaultValue={exclusion.excludeFromReporting}
        reversed
        onChange={(value) =>
          onRulePropertyChanged(value, 'excludeFromReporting')
        }
      />
      <Rules
        fields={fields}
        exclusion={exclusion}
        publishers={publishers}
        setExclusion={setExclusion}
        accountsByPublisher={accountsByPublisher}
      />
      {!errors.publishers ? (
        <CreateActions
          saveDisabled={!isExclusionValid}
          onCancelClicked={() => {
            cleanCurrentExclusion()
            onCancelClicked()
          }}
          onSaveClicked={() => {
            if (validate()) {
              onSaveClicked(exclusion)
            }
          }}
        />
      ) : null}
    </>
  )
}

export default CampaignExclusionSettings

CampaignExclusionSettings.propTypes = {
  company: PropTypes.object.isRequired,
  onCancelClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  editedExclusion: PropTypes.object,
  setEditedExclusion: PropTypes.func,
  businessUnitClientId: PropTypes.string,
  exclusions: PropTypes.array,
}
