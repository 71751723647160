import stopIcon from 'assets/icon_stop_hexagonal_red.svg'
import watchIcon from 'assets/icon_watch_yellow.svg'
import checkIcon from 'assets/icon_check_green_larger.svg'

export const TEXTS = {
  needs_immediate_attention: {
    icon: stopIcon,
    title: 'Need Immediate Attention',
    description:
      'When the selected alerts are triggered, set a percentage range at which the alert would need the highest level of attention.',
  },
  review_recommended: {
    icon: watchIcon,
    title: 'Review Recommended',
    description:
      'When the selected alerts are triggered, set a percentage range at which the alert would need attention.',
  },
  validated: {
    icon: checkIcon,
    title: 'Validated',
    description:
      'When the selected alerts are triggered, set a percentage range at which the alert would be little to no significance.',
  },
}
