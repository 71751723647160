import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'

import cx from 'classnames'
import Button from 'components/button/index'
import Loader from 'components/loader'
import Modal from 'components/modal/index'
import StickyFooter from 'components/sticky-footer/index'
import Table from 'components/table/beta'
import { format } from 'date-fns'
import { PERMISSIONS, PERMISSION_TYPES, useAccess } from 'hooks/access'
import { showSuccessMessage } from 'modules/notifications/actions'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'store'
import { legal } from '@decision-sciences/qontrol-common'

import {
  deleteLegalItem,
  getLegalItems,
  reorderLegal,
  saveLegalItems,
  setCurrentEditedLegal,
} from './actions'

const { LEGAL_DISPLAY_AREA_VALUES, LEGAL_TYPES } = legal

const LegalManagementModule = () => {
  const navigate = useNavigate()

  const { dispatch, state } = useStore()

  const hasEditAccess = useAccess({
    feature: PERMISSIONS.LEGAL_SETTINGS,
    type: PERMISSION_TYPES.EDIT,
  })

  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.LEGAL_SETTINGS,
    type: PERMISSION_TYPES.DELETE,
  })

  const {
    legal: { list, listDirty },
  } = state

  const [loading, setLoading] = useState(true)
  const [legalItemToDelete, setLegalItemToDelete] = useState(null)
  const [deleteInProgress, setDeleteInProgress] = useState(false)

  /** On mount, fetch legal items so they are always updated in the store as well and reset the currently edited legal */
  useEffect(() => {
    setCurrentEditedLegal(dispatch, null)
    getLegalItems(dispatch).finally(setLoading(false))
  }, [])

  const columns = [
    {
      header: ' ',
      cell: (cell) => {
        const { _id } = cell.row.original
        return (
          <div className="table__actions align-center">
            <div
              className={hasEditAccess ? 'table__edit' : 'table__view'}
              onClick={() => {
                setTimeout(() => {
                  navigate(`/legal/cms/${_id}`)
                }, 100)
              }}
            />
          </div>
        )
      },
      size: 40,
    },
    {
      header: 'Name',
      accessorKey: 'name',
      textAlign: 'left',
      size: 200,
      sortingFn: 'name',
    },
    {
      header: 'Display',
      accessorFn: (row) => {
        return row.displayArea?.length
          ? row.displayArea
              .map((area) => LEGAL_DISPLAY_AREA_VALUES[area].shortLabel)
              .join('; ')
          : 'None'
      },
      textAlign: 'left',
      size: 200,
    },
    {
      header: 'Linked',
      accessorFn: (row) =>
        row.type !== LEGAL_TYPES.SIMPLE_TEXT && row.displayArea?.length
          ? 'Yes'
          : 'No',
      size: 150,
      textAlign: 'left',
    },
    {
      header: 'Last Modified',
      accessorFn: (row) => {
        return row.updatedAt
          ? format(new Date(row.updatedAt), 'MM/dd/yyyy')
          : '-'
      },
      width: 100,
      textAlign: 'left',
    },
    {
      id: 'delete',
      header: '',
      cell: (cell) => {
        if (!hasDeleteAccess) {
          return null
        }
        const disabled = !cell.row.original.isDeletable
        return (
          <DeleteIcon
            className={cx('table__delete', {
              disabled,
              'fill-grey': disabled,
              'fill-red': !disabled,
            })}
            alt={'delete'}
            onClick={() => !disabled && setLegalItemToDelete(cell.row.original)}
          />
        )
      },
      size: 40,
      hide: !hasDeleteAccess,
    },
  ]

  /* Handler for delete operation */
  const onDelete = () => {
    if (legalItemToDelete.isDeletable) {
      setDeleteInProgress(true)
      deleteLegalItem(dispatch, legalItemToDelete._id)
        .then(() => showSuccessMessage('Legal documentation deleted', dispatch))
        .finally(() => {
          setLegalItemToDelete(null)
          setDeleteInProgress(false)
        })
    }
  }

  /* Handler for save operation */
  const onSave = () => {
    saveLegalItems(dispatch, list).then(() =>
      showSuccessMessage('Legal item list updated', dispatch)
    )
  }

  /* Handler for canceling the current edit or create operation  */
  const onCancel = () => {
    setLoading(true)
    getLegalItems(dispatch).finally(setLoading(false))
  }

  return loading || !list ? (
    <Loader />
  ) : (
    <>
      <Table
        withDragDrop={hasEditAccess}
        enableSort={false}
        onDragDrop={(reorderedArray) => reorderLegal(dispatch, reorderedArray)}
        columns={columns}
        data={list}
      />
      {/* If the legal items in the list got reordered, display the sticky footer to save or discard the changes */}
      {listDirty && (
        <StickyFooter
          buttons={[
            {
              value: 'Save',
              onClick: onSave,
            },
            {
              value: 'Cancel',
              onClick: onCancel,
              secondaryGray: true,
            },
          ]}
        />
      )}

      {legalItemToDelete && (
        <Modal
          opened={!!legalItemToDelete}
          button={
            <Button
              disabled={deleteInProgress}
              value="Delete"
              onClick={() => onDelete()}
            />
          }
          buttonSecondary={
            <Button
              disabled={deleteInProgress}
              value="Cancel"
              onClick={() => setLegalItemToDelete(null)}
              secondaryGray
            />
          }
          heading="Are you sure you want to delete this legal documentation?"
          className="alert-categories__modal"
        />
      )}
    </>
  )
}

export { LegalManagementModule }
