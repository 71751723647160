import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Api from 'easy-fetch-api'
import { useStore } from 'store'
import { showSuccessMessage } from 'modules/notifications/actions'
import StickyFooter from 'components/sticky-footer'
import { Dropdown } from 'components/dropdown'
import Input from 'components/input'
import Loader from 'components/loader'
import useLeaveConfirm from 'components/leave-confirm'
import { useOnClickOutside } from 'hooks/outside-click'
import { platform } from '@decision-sciences/qontrol-common'

import '../global-settings.scss'

const {
  PLATFORM_SECURITY_SETTINGS_MAP,
  TIME_UNITS,
  PLATFORM_SECURITY_DEFAULTS,
} = platform

const {
  session_expiration_timeout,
  session_expiration_warning_timeout,
  mfa_timeout,
  password_settings_timeout,
} = PLATFORM_SECURITY_SETTINGS_MAP

const Security = () => {
  const { dispatch } = useStore()

  const backupState = useRef({
    passwordExpiration: PLATFORM_SECURITY_DEFAULTS.PASSWORD_EXPIRATION,
    multiFactorReset: PLATFORM_SECURITY_DEFAULTS.MULTI_FACTOR_RESET,
    sessionExpiration: PLATFORM_SECURITY_DEFAULTS.SESSION_EXPIRATION,
    inactivityWarningTimeout:
      PLATFORM_SECURITY_DEFAULTS.SESSION_TIMEOUT_WARNING,
  })

  // Local States
  const [passwordExpiration, setPasswordExpiration] = useState(
    PLATFORM_SECURITY_DEFAULTS.PASSWORD_EXPIRATION
  )

  const [multiFactorReset, setMultiFactorReset] = useState(
    PLATFORM_SECURITY_DEFAULTS.MULTI_FACTOR_RESET
  )

  const [sessionExpiration, setSessionExpiration] = useState(
    PLATFORM_SECURITY_DEFAULTS.SESSION_EXPIRATION
  )

  const [inactivityWarningTimeout, setInactivityWarningTimeout] = useState(
    PLATFORM_SECURITY_DEFAULTS.SESSION_TIMEOUT_WARNING
  )

  const [setDirty, LeaveConfirm, isDirty] = useLeaveConfirm()

  const [loading, setLoading] = useState(true)

  const passwordExpirationRef = useRef()

  useEffect(() => {
    Api.get({ url: '/api/security' })
      .then((result) => {
        if (!result || result.error) {
          console.error(
            (result && result.error) ||
              'Error fetching platform security settings. Please refresh the page and try again.'
          )
        } else {
          if (result[session_expiration_timeout]) {
            setSessionExpiration(result[session_expiration_timeout])
          }
          if (
            result[password_settings_timeout] &&
            result[password_settings_timeout]?.time !== 0
          ) {
            setPasswordExpiration(result[password_settings_timeout])
          }
          if (result[mfa_timeout]) {
            setMultiFactorReset(result[mfa_timeout])
          }
          if (result[session_expiration_warning_timeout]) {
            setInactivityWarningTimeout(
              result[session_expiration_warning_timeout]
            )
          }

          backupState.current = {
            passwordExpiration: result[password_settings_timeout],
            multiFactorReset: result[mfa_timeout],
            sessionExpiration: result[session_expiration_timeout],
            inactivityWarningTimeout:
              result[session_expiration_warning_timeout],
          }

          setLoading(false)
        }
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useOnClickOutside(passwordExpirationRef, () => {
    if (passwordExpiration?.time === 0) {
      setPasswordExpiration(PLATFORM_SECURITY_DEFAULTS.PASSWORD_EXPIRATION)
    }
  })

  const onSave = () => {
    setLoading(true)
    Api.put({
      url: '/api/security',
      data: {
        [password_settings_timeout]: passwordExpiration,
        [mfa_timeout]: multiFactorReset,
        [session_expiration_timeout]: sessionExpiration,
        [session_expiration_warning_timeout]: inactivityWarningTimeout,
      },
    })
      .then((result) => {
        backupState.current = {
          passwordExpiration: result[password_settings_timeout],
          multiFactorReset: result[mfa_timeout],
          sessionExpiration: result[session_expiration_timeout],
          inactivityWarningTimeout: result[session_expiration_warning_timeout],
        }
        showSuccessMessage('Security settings updated successfully.', dispatch)
        setDirty(false)
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }

  const onCancel = () => {
    const {
      passwordExpiration,
      multiFactorReset,
      sessionExpiration,
      inactivityWarningTimeout,
    } = backupState.current

    setPasswordExpiration(passwordExpiration)
    setMultiFactorReset(multiFactorReset)
    setSessionExpiration(sessionExpiration)
    setInactivityWarningTimeout(inactivityWarningTimeout)

    setDirty(false)
  }

  return (
    <section>
      <LeaveConfirm />

      {loading ? (
        <Loader />
      ) : (
        <section className="security">
          <div
            data-cy="security-password-settings"
            className="form__section__body"
          >
            <h3 className="generic-heading">Password Settings</h3>
            <p>
              Enter the number of days to establish when a password expires.
            </p>
            <SecurityRow
              state={passwordExpiration}
              setState={(value) => {
                setDirty(true)
                setPasswordExpiration(value)
              }}
              timeOptions={[TIME_UNITS.DAYS]}
              forwardsRef={passwordExpirationRef}
            />
          </div>
          <div data-cy="security-mfa-settings" className="form__section__body">
            <h3 className="generic-heading">Multi-Factor Authentication</h3>
            <p>
              Enter the value and time frame to force the user to go through
              multi-factor reauthentication.
            </p>
            <SecurityRow
              state={multiFactorReset}
              setState={(value) => {
                setDirty(true)
                setMultiFactorReset(value)
              }}
              timeOptions={[TIME_UNITS.DAYS]}
            />
          </div>
          <div
            data-cy="security-session-expiration"
            className="form__section__body"
          >
            <h3 className="generic-heading">Session Expiration</h3>
            <p>
              Enter the value and time frame to force an open session to end,
              which will require logging back into the application.
            </p>
            <SecurityRow
              state={sessionExpiration}
              setState={(value) => {
                setDirty(true)
                setSessionExpiration(value)
              }}
              timeOptions={[TIME_UNITS.MINUTES, TIME_UNITS.HOURS]}
            />

            {/* Temporary section for QA */}
            <h3 className="generic-heading generic-heading--margin-top">
              Session Expiration Warning
            </h3>
            <p>
              {`A user will get a warning popup ${
                inactivityWarningTimeout.time /
                TIME_UNITS[inactivityWarningTimeout.unit].time
              } ${TIME_UNITS[
                inactivityWarningTimeout.unit
              ].label.toLowerCase()} before the session expires`}
            </p>
            <SecurityRow
              state={inactivityWarningTimeout}
              setState={(value) => {
                setDirty(true)
                setInactivityWarningTimeout(value)
              }}
              timeOptions={[TIME_UNITS.SECONDS, TIME_UNITS.MINUTES]}
              minMS={3 * TIME_UNITS.MINUTES.time}
            />
          </div>
        </section>
      )}

      {isDirty && (
        <StickyFooter
          buttons={[
            {
              value: 'Save Changes',
              onClick: onSave,
              disabled: loading,
            },
            {
              value: 'Cancel',
              onClick: onCancel,
              secondaryGray: true,
            },
          ]}
        />
      )}
    </section>
  )
}

/**
 * Displays a number input next to a time unit selection dropdown for time sensitive security settings
 * @param {Object} params React Params
 * @param {Object} params.state State of security setting
 * params.state.seconds -> Number of seconds until setting expires
 * params.state.unit -> Time unit (MINUTES / HOURS / DAYS)
 * @param {Function} params.setState Setter for State
 * @param {Number} params.minMS Minimum number of milliseconds
 * @param {Object} params.forwardsRef ref
 * @param {Array<Object | String>} params.timeOptions Time options to display
 * @returns {Node}
 */
const SecurityRow = ({
  state,
  setState,
  timeOptions,
  forwardsRef,
  minMS = 0,
}) => {
  const { time, unit } = state
  const NoOfMsPerUnit = TIME_UNITS[unit].time
  return (
    <div className="form__row form__half">
      <Input
        className="half-width"
        value={time / NoOfMsPerUnit}
        onChange={(value) => {
          setState({
            ...state,
            time: value * NoOfMsPerUnit,
          })
        }}
        min={
          minMS ? (minMS + (minMS % NoOfMsPerUnit)) / NoOfMsPerUnit : undefined
        }
        placeholder="Enter Value"
        type="number"
        ref={forwardsRef}
      />
      <Dropdown
        className="half-width fake-disabled"
        onChange={(unit) => {
          let newMS =
            Math.round(time / TIME_UNITS[state.unit].time) *
            TIME_UNITS[unit].time
          if (newMS < minMS) {
            newMS = minMS + (minMS % TIME_UNITS[unit].time)
          }
          setState({ ...state, unit, time: newMS })
        }}
        defaultState={unit}
        options={timeOptions}
        disabled={timeOptions.length <= 1}
      />
    </div>
  )
}

SecurityRow.propTypes = {
  state: PropTypes.shape({
    time: PropTypes.number,
    unit: PropTypes.string,
  }).isRequired,
  setState: PropTypes.func.isRequired,
  timeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  forwardsRef: PropTypes.object,
  minMS: PropTypes.number,
}
export default Security
