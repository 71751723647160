import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import cloneDeep from 'lodash.clonedeep'
import { format } from 'date-fns'
import { utils, socket } from '@decision-sciences/qontrol-common'

/* Actions */
import {
  getBudgetPacingSettingsByCompany,
  getBudgetSegmentsByCompanyId,
  saveBudgetPacing,
  deleteBudgetPacing,
  editBudgetPacing,
} from 'modules/companies/actions'
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'

/* Utils*/
import { updateCustomBudgets } from 'modules/companies/subsections/budget-pacing/components/utils'
import { ReactComponent as PlusIcon } from 'assets/icon_plus_blue.svg'

/* Components */
import Button from 'components/button'
import Loader from 'components/loader'
import CollapsibleSection from 'components/collapsible-section'
import { DEFAULT_DATE_FORMAT } from 'components/utils/date'
import BudgetPacingTable from './components/budget-pacing-table/index'
import BudgetPacingSettings from './components/budget-pacing-create/index'
import ActivityLog from './components/budget-pacing-activity-log/index'

import './style.scss'

const { flattenArrayByField } = utils.array

const { CACHING_DONE_FOR } = socket
/**
 * Budget Pacing Section and all its related business logic
 * @param {Object} props
 * @param {Boolean} props.isViewMode
 * @param {Object} props.currentCompany - Current Edited company
 * @param {String} props.companyId - Company's id for which we render the Budget Pacing Section section
 */
const BudgetPacingSection = ({
  isViewMode,
  currentCompany,
  inactiveCampaignsFlag,
  onChangeInactiveCampaignsFlag,
  companyId,
}) => {
  const { flags, setFlags } = useOutletContext()
  const [campaignBudgetPacingList, setCampaignBudgetPacingList] = useState([])
  const [isPanelOpened, setIsPanelOpened] = useState(false)
  const [budgetSegments, setBudgetSegments] = useState([])
  const [activityLogs, setActivityLogs] = useState([])
  const [dimensions, setDimensions] = useState({})
  const [budgetPacingSettings, setBudgetPacingSettings] = useState([])
  const [loading, setLoading] = useState(false)
  const [isCacheInProgress, setIsCacheInProgress] = useState(false)
  const [isEditingBudget, setIsEditingBudget] = useState(false)
  const displayTable = budgetPacingSettings?.length
  const [errors, setErrors] = useState({})

  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.BUDGET_PACING,
    type: PERMISSION_TYPES.CREATE,
  })

  useEffect(() => {
    if (
      flags[companyId]?.[CACHING_DONE_FOR.BUDGET_PACING_ACTIVE_CAMPAIGNS] ||
      flags[companyId]?.[CACHING_DONE_FOR.BUDGET_PACING_INACTIVE_CAMPAIGNS] ||
      flags[companyId]?.[CACHING_DONE_FOR.BUDGET_PACING_ACTIVE_AD_GROUPS]
    ) {
      getBudgetSegmentsList()
      setFlags(companyId, {
        [CACHING_DONE_FOR.BUDGET_PACING_ACTIVE_CAMPAIGNS]: false,
        [CACHING_DONE_FOR.BUDGET_PACING_INACTIVE_CAMPAIGNS]: false,
        [CACHING_DONE_FOR.BUDGET_PACING_ACTIVE_AD_GROUPS]: false,
      })
    }
  }, [
    flags[companyId]?.[CACHING_DONE_FOR.BUDGET_PACING_ACTIVE_CAMPAIGNS],
    flags[companyId]?.[CACHING_DONE_FOR.BUDGET_PACING_INACTIVE_CAMPAIGNS],
    flags[companyId]?.[CACHING_DONE_FOR.BUDGET_PACING_ACTIVE_AD_GROUPS],
  ])

  useEffect(() => {
    fetchBudgetPacingSettings()
    getBudgetSegmentsList()
    setCampaignBudgetPacingList([
      { local__uuid: uuidv4(), clientId: currentCompany._id },
    ])
  }, [])

  const fetchBudgetPacingSettings = () => {
    getBudgetPacingSettingsByCompany(companyId)
      .then((res) => {
        if (res) {
          const { budgetPacingSettings } = res
          const nonDeletedSettings = budgetPacingSettings
            .filter((setting) => !setting.isDeleted)
            .map((el) => ({
              ...el,
              startDate: format(new Date(el.startDate), DEFAULT_DATE_FORMAT),
              endDate: format(new Date(el.endDate), DEFAULT_DATE_FORMAT),
            }))
          const activityLogs = flattenArrayByField(
            budgetPacingSettings,
            'activityLog'
          ).sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          )
          setBudgetPacingSettings(nonDeletedSettings)
          setActivityLogs(activityLogs)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getBudgetSegmentsList = () => {
    getBudgetSegmentsByCompanyId(companyId).then((res) => {
      if (res) {
        const {
          budgetSegments,
          dimensions,
          cacheInProgress,
          cacheInProgressForInactiveCampaigns,
        } = res
        if (setFlags) {
          setFlags(companyId, {
            inactiveCampaignsCaching: cacheInProgressForInactiveCampaigns,
          })
        }
        setIsCacheInProgress(cacheInProgress)
        setBudgetSegments(budgetSegments)
        setDimensions(dimensions)
      }
    })
  }

  const handleError = (error) => {
    console.error(error.message || JSON.stringify(error))
    setLoading(false)
  }

  const onSaveClicked = () => {
    setLoading(true)
    let action = saveBudgetPacing
    let data = updateCustomBudgets(campaignBudgetPacingList)
    if (isEditingBudget && data[0]?._id) {
      action = editBudgetPacing
      data = data[0]
    }
    action(companyId, data)
      .then((response) => {
        if (response.success) {
          fetchBudgetPacingSettings()
          resetPanel()
        }
      })
      .catch(handleError)
  }

  const resetPanel = () => {
    setIsPanelOpened(false)
    setIsEditingBudget(false)
    setCampaignBudgetPacingList([
      { local__uuid: uuidv4(), clientId: currentCompany._id },
    ])
  }

  const onCancelClicked = () => {
    resetPanel()
    setErrors({})
  }

  const onDeleteClicked = (budgetId) => {
    setLoading(true)
    const budget = budgetPacingSettings.find(
      (budget) => budget._id === budgetId
    )
    deleteBudgetPacing(companyId, budget).then((res) => {
      if (res.success) {
        fetchBudgetPacingSettings()
      }
    })
  }

  const setEditMode = (budget) => {
    setIsPanelOpened(true)
    setIsEditingBudget(true)

    const editedBudget = cloneDeep(budget)
    setCampaignBudgetPacingList([editedBudget])
  }

  const onDuplicateClicked = (budget) => {
    const newBudget = { ...budget }
    newBudget.local__uuid = uuidv4()
    delete newBudget._id
    newBudget.budgetSegment = newBudget.isCustomBudget
      ? `${newBudget.budgetSegment} Copy`
      : newBudget.budgetSegment
    setEditMode(newBudget)
  }

  const updateBudgetPacing = (data) => {
    setCampaignBudgetPacingList(data)
  }

  const updateInactiveCampaignsFlag = async (value) => {
    setLoading(true)
    await onChangeInactiveCampaignsFlag(value)
    getBudgetSegmentsList()
    setLoading(false)
  }

  const _renderBudgetPacingSettings = () => {
    if (!isPanelOpened) {
      return null
    }

    if (loading) {
      return <Loader />
    }

    return (
      <div className="budget-pacing-section">
        <BudgetPacingSettings
          budgetSegments={budgetSegments}
          dimensions={dimensions}
          onSaveClicked={onSaveClicked}
          onCancelClicked={onCancelClicked}
          currentCompany={currentCompany}
          isEditingBudget={isEditingBudget}
          errors={errors}
          setErrors={setErrors}
          existingBudgetPacingSettings={budgetPacingSettings}
          budgets={campaignBudgetPacingList}
          updateBudgetPacing={updateBudgetPacing}
          inactiveCampaignsFlag={inactiveCampaignsFlag}
          onChangeInactiveCampaignsFlag={updateInactiveCampaignsFlag}
        />
      </div>
    )
  }

  const _renderBudgetPacingTable = () => {
    if (!displayTable) {
      return
    }

    if (loading || isCacheInProgress) {
      return <Loader />
    }

    return (
      <div className="budget-pacing-section">
        <BudgetPacingTable
          isViewMode={isViewMode}
          errors={errors}
          budgets={budgetPacingSettings}
          onDeleteClicked={onDeleteClicked}
          onEditClicked={setEditMode}
          onDuplicateClicked={onDuplicateClicked}
          disabledActions={isPanelOpened}
        />
      </div>
    )
  }

  const _renderAddButton = () => {
    if (isViewMode || !hasCreateAccess) {
      return null
    }

    return (
      <Button
        value={
          <div className="action-button">
            <PlusIcon />
            Add to Budget Pacing
          </div>
        }
        disabled={isPanelOpened || isCacheInProgress}
        onClick={() => setIsPanelOpened(true)}
        className="fixed-height"
        secondary
        tooltip={isCacheInProgress ? 'Cache in progress' : null}
      />
    )
  }
  return (
    <CollapsibleSection
      id="budget-pacing-section"
      header="Budget Pacing"
      defaultCollapsed={isPanelOpened}
      onCollapseListener={(value) => setIsPanelOpened(!value)}
      extras={_renderAddButton()}
      wrapperClassName="form__section"
    >
      <div className="budget-pacing">
        {_renderBudgetPacingSettings()}
        {_renderBudgetPacingTable()}
        <div className="activity-log">
          <ActivityLog
            viewMode={isViewMode}
            activityLogs={activityLogs}
            loading={loading}
          />
        </div>
      </div>
    </CollapsibleSection>
  )
}
export default BudgetPacingSection

BudgetPacingSection.propTypes = {
  isViewMode: PropTypes.bool,
  currentCompany: PropTypes.object.isRequired,
  companyId: PropTypes.string.isRequired,
  inactiveCampaignsFlag: PropTypes.bool,
  onChangeInactiveCampaignsFlag: PropTypes.func.isRequired,
}
