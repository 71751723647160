import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

/* Components */
import Section from 'components/section'
import DropdownWithSubsections from 'components/dropdown-with-subsections/index'
import { SinglePerformanceAccountRow } from 'modules/alerts/single-performance-apply-to/single-performance-account-row'

/* Hooks */
import { useSingleClientApplyTo } from 'modules/alerts/utils'

/* Actions */
import {
  getAllCampaigns,
  getAllCampaignTypes,
  getAdGroups,
  getAds,
  getAllAdTypes,
  getGoogleKeywords,
  getGoogleAudiences,
  getGoogleNetworks,
  getAllDevices,
} from 'modules/google/actions'

import { ACCOUNTS_TO_SELECT_ELEMENTS } from 'modules/alerts/constants'

import {
  accounts,
  granularities,
  utils,
} from '@decision-sciences/qontrol-common'

import 'modules/alerts/single-performance-apply-to/style.scss'

const { GOOGLE } = accounts.ACCOUNT_TYPES_MAP

const {
  ACCOUNT,
  CAMPAIGN,
  CAMPAIGN_TYPE,
  AD_GROUP,
  AD,
  KEYWORD,
  AD_TYPE,
  AUDIENCE,
  NETWORK,
  DEVICE,
} = granularities.GRANULARITIES

const { GRANULARITY_DETAILS } = granularities

/**
 * Single Performance Section for GOOGLE
 * @param {Object} params React Params
 * @param {String} params.clientId Alert Client ID
 * @param {Function} params.onChange On Change callback. Call with new localState for selectedElements[platform]
 * @param {Object} params.localState selectedElements[platform]
 * @param {Array} params.accounts Available Accounts for platform
 * @param {Object} params.elementConfig Array name per platform key. Eg: CAMPAIGN: campaigns
 * @param {Object} params.errors Object of errors
 * @param {Function} params.onChangeErrors Callback for changing errors
 * @param {Boolean} params.readOnly Toggle between read/edit modes
 * @returns {Node}
 */
export const SinglePerformanceGoogle = ({
  clientId,
  onChange,
  state,
  accounts,
  elementConfig,
  errors,
  onChangeErrors,
  readOnly,
}) => {
  const {
    localState,
    toggleLoading,
    getIsLoading,
    onCheck,
    onCheckAll,
    onChangeAccounts,
  } = useSingleClientApplyTo(
    GOOGLE,
    state,
    onChange,
    onChangeErrors,
    validDataPersistenceConfig
  )

  // Data States
  const [campaigns, setCampaigns] = useState(null)
  const [adGroups, setAdGroups] = useState({})
  const [ads, setAds] = useState({})
  const [keywords, setKeywords] = useState({})
  const [audiences, setAudiences] = useState(null)
  const [devices, setDevices] = useState(null)
  const [campaignTypes, setCampaignTypes] = useState(null)
  const [adTypes, setAdTypes] = useState(null)
  const [networks, setNetworks] = useState(null)

  const availableAccounts = accounts.map(({ externalAccountId, name }) => ({
    value: externalAccountId,
    label: name || externalAccountId,
    description: externalAccountId,
  }))

  /* ACCOUNT section */
  const accountMap = useMemo(() => {
    return availableAccounts.reduce(
      (prev, current) => ({
        ...prev,
        [current.value]: current,
      }),
      {}
    )
  }, [availableAccounts])

  const actualSelectedAccounts =
    (localState.allAccountsSelected
      ? Object.keys(accountMap)
      : localState.accounts) || []

  const hasAccounts = state.accounts?.length || state.allAccountsSelected

  const renderAccountSection = () => {
    if (!state.elements?.includes(ACCOUNT)) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Accounts"
          selectedItems={state[ACCOUNT]?.accounts.map((account) => account.id)}
          disabled={!hasAccounts || readOnly}
          label={'Account'}
          options={
            state.allAccountsSelected
              ? availableAccounts
              : availableAccounts.filter((acc) =>
                  state.accounts?.includes(acc.value)
                )
          }
          onChange={(accounts) =>
            onCheck(ACCOUNT, {
              accounts: accounts.map((value) => ({
                name: accountMap[value].label,
                id: accountMap[value].value,
              })),
              allSelected: false,
            })
          }
          selectAllOptions={{
            label: 'All Accounts',
            allSelected: state[ACCOUNT]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(ACCOUNT),
          }}
          error={errors[elementConfig[ACCOUNT]]}
        />
      </div>
    )
  }
  /* End of ACCOUNT section */

  /* CAMPAIGN Section */

  useEffect(() => {
    if (!campaigns && !getIsLoading(CAMPAIGN)) {
      toggleLoading(CAMPAIGN, true)
      getAllCampaigns(clientId)
        .then(setCampaigns)
        .catch(console.error)
        .finally(() => {
          toggleLoading(CAMPAIGN, false)
        })
    }
  }, [JSON.stringify(localState.elements)])

  const campaignsInAccounts = useMemo(() => {
    const campaignsInAccountsMap = {}

    const accountsToParse = state.allAccountsSelected
      ? availableAccounts.map(({ value }) => value)
      : state.accounts

    if (!accountsToParse || !campaigns?.length) {
      return campaignsInAccountsMap
    }

    // Turn array to map for easy access
    const accountsSet = new Set(accountsToParse)

    // Filter campaigns based on selected accounts
    campaigns.forEach((campaign) => {
      if (accountsSet.has(campaign.account_id)) {
        campaignsInAccountsMap[campaign.resource_name] = campaign
      }
    })

    return campaignsInAccountsMap
  }, [JSON.stringify(state.accounts), state.allAccountsSelected, campaigns])

  const campaignsByAccount = utils.array.arrayKeyBy(
    Object.values(campaignsInAccounts) || [],
    'account_id'
  )

  const getCampaignOptions = (getIsDisabled) => {
    if (!campaignsByAccount) {
      return []
    }

    const options = Object.keys(campaignsByAccount).map((accountId) => ({
      subsections: campaignsByAccount[accountId].map((campaign) => ({
        value: campaign.resource_name,
        label: campaign.name,
        disabled: getIsDisabled && getIsDisabled(campaign),
      })),
      disabled: true,
      noCheckbox: true,
      label: accountMap[accountId].label,
      value: accountId,
    }))

    return options
  }

  const renderCampaignSection = () => {
    if (!state.elements?.includes(CAMPAIGN)) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Campaigns"
          selectedItems={(state[CAMPAIGN]?.campaigns || []).map(
            ({ resource_name }) => resource_name
          )}
          disabled={!hasAccounts || readOnly}
          label={'Campaign'}
          options={getCampaignOptions()}
          onChange={(campaigns) => {
            onCheck(CAMPAIGN, {
              campaigns: campaigns.map(
                (campaign) => campaignsInAccounts[campaign]
              ),
              allSelected: false,
            })
          }}
          selectAllOptions={{
            label: 'All Campaigns',
            allSelected: state[CAMPAIGN]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(CAMPAIGN),
          }}
          error={errors[elementConfig[CAMPAIGN]]}
        />
      </div>
    )
  }
  /* End of CAMPAIGN Section */

  /* AD_GROUP Section */

  /**
   * Fetch ad groups for a campaign
   * @param {String} resource resource_name field from Google
   */
  const fetchAdGroups = (resource) => {
    const loaderKey = `${AD_GROUP}_${resource}`

    if (!getIsLoading(loaderKey) && !adGroups[resource]) {
      toggleLoading(loaderKey, true)
      getAdGroups(clientId, null, resource)
        .then((adGroups) => {
          setAdGroups((previous) => ({
            ...(previous || {}),
            [resource]: adGroups,
          }))
        })
        .catch(console.error)
        .finally(() => {
          toggleLoading(loaderKey, false)
        })
    }
  }

  useEffect(() => {
    if (!localState[AD_GROUP]?.campaigns) {
      return
    }
    if (
      (localState?.accounts.length && !localState?.allAccountsSelected) ||
      (localState?.allAccountsSelected && localState?.accounts.length === 0)
    ) {
      localState[AD_GROUP].campaigns.forEach((campaign) => {
        fetchAdGroups(campaign.resource_name)
      })
    }
  }, [localState[AD_GROUP]?.campaigns])

  const getAvailableAdGroupMap = (element) => {
    if (!element || !element.campaigns) {
      return {}
    }

    return element.campaigns.reduce(
      (prev, current) => ({
        ...prev,
        ...(adGroups[current.resource_name] || []).reduce(
          (prev, current) => ({ ...prev, [current.resource_name]: current }),
          {}
        ),
      }),
      {}
    )
  }

  const getAdGroupOptions = (element, getIsDisabled) => {
    if (!element || !element.campaigns) {
      return []
    }

    return element.campaigns.reduce(
      (prev, current) => [
        ...prev,
        {
          label: current.name,
          disabled: true,
          noCheckbox: true,
          subsections: (adGroups[current.resource_name] || []).map(
            (adGroup) => ({
              label: adGroup.name,
              value: adGroup.resource_name,
              disabled: getIsDisabled && getIsDisabled(adGroup),
            }),
            []
          ),
        },
      ],
      []
    )
  }

  const adGroupAvailableAdGroupMap = useMemo(() => {
    return getAvailableAdGroupMap(localState[AD_GROUP])
  }, [adGroups, localState[AD_GROUP]])

  const renderAdGroupSection = () => {
    if (!state.elements?.includes(AD_GROUP)) {
      return null
    }

    const hasCampaigns = state[AD_GROUP]?.campaigns?.length

    const campaignOptions = getCampaignOptions(({ resource_name }) =>
      getIsLoading(`${AD_GROUP}_${resource_name}`)
    )

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Campaigns"
          selectedItems={(state[AD_GROUP]?.campaigns || []).map(
            ({ resource_name }) => resource_name
          )}
          disabled={!hasAccounts || readOnly}
          label={'Campaign'}
          options={campaignOptions}
          onChange={(campaigns) =>
            onCheck(AD_GROUP, {
              campaigns: campaigns.map(
                (campaign) => campaignsInAccounts[campaign]
              ),
            })
          }
        />
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Ad Groups"
          selectedItems={(state[AD_GROUP]?.adGroups || []).map(
            ({ resource_name }) => resource_name
          )}
          label={'Ad Groups'}
          options={getAdGroupOptions(localState[AD_GROUP])}
          disabled={!hasCampaigns || readOnly}
          isLoading={getIsLoading(new RegExp(AD_GROUP))}
          onChange={(adGroups) => {
            onCheck(AD_GROUP, {
              adGroups: adGroups.map(
                (adGroup) => adGroupAvailableAdGroupMap[adGroup]
              ),
              allSelected: false,
            })
          }}
          selectAllOptions={{
            label: 'All Ad Groups',
            allSelected: state[AD_GROUP]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(AD_GROUP),
          }}
          error={errors[elementConfig[AD_GROUP]]}
        />
      </div>
    )
  }
  /* End of AD_GROUP Section */

  /* AD Section */

  /**
   * Fetch ads for an ad group
   * @param {String} resource resource_name field from Google
   */
  const fetchAds = (resource) => {
    const loaderKey = `${AD}_${resource}`

    if (!getIsLoading(loaderKey) && !ads[resource]) {
      toggleLoading(loaderKey, true)
      getAds(clientId, null, resource)
        .then((ads) => {
          setAds((previous) => ({
            ...(previous || {}),
            [resource]: ads,
          }))
        })
        .catch(console.error)
        .finally(() => {
          toggleLoading(loaderKey, false)
        })
    }
  }

  useEffect(() => {
    if (!localState[AD]?.campaigns) {
      return
    }

    localState[AD].campaigns.forEach((campaign) => {
      fetchAdGroups(campaign.resource_name)
    })
  }, [localState[AD]?.campaigns])

  useEffect(() => {
    if (!localState[AD]?.adGroups) {
      return
    }

    localState[AD].adGroups.forEach((adGroup) => {
      fetchAds(adGroup.resource_name)
    })
  }, [localState[AD]?.adGroups])

  const getAvailableAdsMap = (element) => {
    if (!element || !element.adGroups) {
      return {}
    }

    return element.adGroups.reduce((prev, current) => {
      return {
        ...prev,
        ...(ads[current.resource_name] || []).reduce(
          (prev, current) => ({ ...prev, [current.id]: current }),
          {}
        ),
      }
    }, {})
  }

  const adAvailableAdGroupMap = useMemo(() => {
    return getAvailableAdGroupMap(localState[AD])
  }, [adGroups, localState[AD]])

  const adAvailableAdMap = useMemo(() => {
    return getAvailableAdsMap(localState[AD])
  }, [localState[AD]?.adGroups, ads])

  const renderAdSection = () => {
    if (!state.elements?.includes(AD)) {
      return null
    }

    const hasCampaigns = state[AD]?.campaigns?.length

    const hasAdGroups = state[AD]?.adGroups?.length

    const adOptions =
      localState[AD]?.adGroups?.reduce(
        (prev, current) => [
          ...prev,
          {
            label: current.name,
            disabled: true,
            noCheckbox: true,
            subsections: (ads[current.resource_name] || []).map(
              (ad) => ({
                label: ad.name,
                value: ad.id,
              }),
              []
            ),
          },
        ],
        []
      ) || []

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Campaigns"
          selectedItems={(state[AD]?.campaigns || []).map(
            ({ resource_name }) => resource_name
          )}
          label={'Campaign'}
          disabled={readOnly}
          options={getCampaignOptions(({ resource_name }) =>
            getIsLoading(`${AD_GROUP}_${resource_name}`)
          )}
          onChange={(campaigns) =>
            onCheck(AD, {
              campaigns: campaigns.map(
                (campaign) => campaignsInAccounts[campaign]
              ),
            })
          }
        />
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Ad Groups"
          selectedItems={(state[AD]?.adGroups || []).map(
            ({ resource_name }) => resource_name
          )}
          disabled={!hasCampaigns || readOnly}
          isLoading={getIsLoading(new RegExp(AD_GROUP))}
          label={'Ad Groups'}
          options={getAdGroupOptions(localState[AD], ({ resource_name }) =>
            getIsLoading(`${AD}_${resource_name}`)
          )}
          onChange={(adGroups) => {
            onCheck(AD, {
              adGroups: adGroups.map(
                (adGroup) => adAvailableAdGroupMap[adGroup]
              ),
              allSelected: false,
            })
          }}
        />
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Ads"
          selectedItems={(state[AD]?.ads || []).map(({ id }) => id)}
          label={'Ads'}
          options={adOptions}
          disabled={!hasAdGroups || readOnly}
          isLoading={getIsLoading(new RegExp(AD))}
          onChange={(ads) => {
            onCheck(AD, {
              ads: ads.map((ad) => adAvailableAdMap[ad]),
              allSelected: false,
            })
          }}
          selectAllOptions={{
            label: 'All Ads',
            allSelected: state[AD]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(AD),
          }}
          error={errors[elementConfig[AD]]}
        />
      </div>
    )
  }
  /* End of AD Section */

  /* KEYWORD Section */

  /**
   * Fetch Keywords for an ad group
   * @param {String} resource resource_name field from Google
   */
  const fetchKeywords = (resource) => {
    const loaderKey = `${KEYWORD}_${resource}`

    if (!getIsLoading(loaderKey) && !keywords[resource]) {
      toggleLoading(loaderKey, true)
      getGoogleKeywords(clientId, null, resource)
        .then((keywords) => {
          setKeywords((previous) => ({
            ...(previous || {}),
            [resource]: keywords,
          }))
        })
        .catch(console.error)
        .finally(() => {
          toggleLoading(loaderKey, false)
        })
    }
  }

  useEffect(() => {
    if (!localState[KEYWORD]?.campaigns) {
      return
    }

    localState[KEYWORD].campaigns.forEach((campaign) => {
      fetchAdGroups(campaign.resource_name)
    })
  }, [localState[KEYWORD]?.campaigns])

  useEffect(() => {
    if (!localState[KEYWORD]?.adGroups) {
      return
    }

    localState[KEYWORD].adGroups.forEach((adGroup) => {
      fetchKeywords(adGroup.resource_name)
    })
  }, [localState[KEYWORD]?.adGroups])

  const keywordAvailableAvailableAdGroupMap = useMemo(() => {
    return getAvailableAdGroupMap(localState[KEYWORD])
  }, [adGroups, localState[KEYWORD]])

  const getAvailableKeywordMap = (element) => {
    if (!element || !element.adGroups) {
      return {}
    }

    return element.adGroups.reduce(
      (prev, current) => ({
        ...prev,
        ...(keywords[current.resource_name] || []).reduce(
          (prev, current) => ({ ...prev, [current.id]: current }),
          {}
        ),
      }),
      {}
    )
  }

  const keywordAvailableKeywordMap = useMemo(
    () => getAvailableKeywordMap(localState[KEYWORD]),
    [keywords, localState[KEYWORD]]
  )

  const renderKeywordSection = () => {
    if (!state.elements?.includes(KEYWORD)) {
      return null
    }

    const hasCampaigns = state[KEYWORD]?.campaigns?.length

    const hasAdGroups = state[KEYWORD]?.adGroups?.length

    const keywordOptions =
      localState[KEYWORD]?.adGroups?.reduce(
        (prev, current) => [
          ...prev,
          {
            label: current.name,
            disabled: true,
            noCheckbox: true,
            subsections: (keywords[current.resource_name] || []).map(
              (ad) => ({
                label: ad.name,
                value: ad.id,
              }),
              []
            ),
          },
        ],
        []
      ) || []

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Campaigns"
          selectedItems={(state[KEYWORD]?.campaigns || []).map(
            ({ resource_name }) => resource_name
          )}
          label={'Campaign'}
          options={getCampaignOptions(({ resource_name }) =>
            getIsLoading(`${AD_GROUP}_${resource_name}`)
          )}
          disabled={readOnly}
          onChange={(campaigns) =>
            onCheck(KEYWORD, {
              campaigns: campaigns.map(
                (campaign) => campaignsInAccounts[campaign]
              ),
            })
          }
        />
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Ad Groups"
          selectedItems={(state[KEYWORD]?.adGroups || []).map(
            ({ resource_name }) => resource_name
          )}
          disabled={!hasCampaigns || readOnly}
          isLoading={getIsLoading(new RegExp(AD_GROUP))}
          label={'Ad Groups'}
          options={getAdGroupOptions(localState[KEYWORD], ({ resource_name }) =>
            getIsLoading(`${KEYWORD}_${resource_name}`)
          )}
          onChange={(adGroups) => {
            onCheck(KEYWORD, {
              adGroups: adGroups.map(
                (adGroup) => keywordAvailableAvailableAdGroupMap[adGroup]
              ),
            })
          }}
        />
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Keywords"
          selectedItems={(state[KEYWORD]?.keywords || []).map(({ id }) => id)}
          disabled={!hasAdGroups || readOnly}
          isLoading={getIsLoading(new RegExp(KEYWORD))}
          label={'Keywords'}
          options={keywordOptions}
          onChange={(keywords) => {
            onCheck(KEYWORD, {
              allSelected: false,
              keywords: keywords.map(
                (keywords) => keywordAvailableKeywordMap[keywords]
              ),
            })
          }}
          selectAllOptions={{
            label: 'All Keywords',
            allSelected: state[KEYWORD]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(KEYWORD),
          }}
          error={errors[elementConfig[KEYWORD]]}
        />
      </div>
    )
  }
  /* End of KEYWORD Section */

  /* AUDIENCE Section */
  const audienceIsDisplayed = state.elements?.includes(AUDIENCE)

  useEffect(() => {
    if (
      !getIsLoading(AUDIENCE) &&
      audienceIsDisplayed &&
      actualSelectedAccounts.length
    ) {
      toggleLoading(AUDIENCE, true)
      getGoogleAudiences(clientId, null, actualSelectedAccounts)
        .then(setAudiences)
        .catch(console.error)
        .finally(() => {
          toggleLoading(AUDIENCE, false)
        })
    }
  }, [JSON.stringify(actualSelectedAccounts), audienceIsDisplayed])

  const getId = (audience) => `${audience.id}_${audience.granularity}`

  const audienceMap = (audiences || []).reduce(
    (prev, current) => ({
      ...prev,
      [getId(current)]: current,
    }),
    {}
  )

  const renderAudienceSection = () => {
    if (!audienceIsDisplayed) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Audiences"
          selectedItems={(state[AUDIENCE]?.audiences || []).map((audience) =>
            getId(audience)
          )}
          label={'Audiences'}
          isLoading={getIsLoading(AUDIENCE)}
          options={(audiences || []).map((audience) => ({
            value: getId(audience),
            label: audience.name,
            description:
              GRANULARITY_DETAILS[audience.granularity].labels.google,
          }))}
          disabled={readOnly}
          onChange={(audiences) => {
            onCheck(AUDIENCE, {
              allSelected: false,
              audiences: audiences.map((audience) => audienceMap[audience]),
            })
          }}
          selectAllOptions={{
            label: 'All Audiences',
            allSelected: state[AUDIENCE]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(AUDIENCE),
          }}
          error={errors[elementConfig[AUDIENCE]]}
        />
      </div>
    )
  }
  /* End of AUDIENCE Section */

  /* DEVICE Section */

  const deviceDisplayed = state.elements?.includes(DEVICE)

  useEffect(() => {
    if (!getIsLoading(DEVICE) && deviceDisplayed) {
      toggleLoading(DEVICE, true)
      getAllDevices(clientId)
        .then(setDevices)
        .catch(console.error)
        .finally(() => {
          toggleLoading(DEVICE, false)
        })
    }
  }, [deviceDisplayed])

  const deviceMap = (devices || []).reduce(
    (prev, device) => ({
      ...prev,
      [device.id]: device,
    }),
    {}
  )

  const renderDeviceSection = () => {
    if (!deviceDisplayed) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Devices"
          selectedItems={(state[DEVICE]?.devices || []).map(({ id }) => id)}
          label={'Devices'}
          isLoading={getIsLoading(DEVICE)}
          options={(devices || []).map((device) => ({
            value: device.id,
            label: device.name,
          }))}
          disabled={readOnly}
          onChange={(devices) => {
            onCheck(DEVICE, {
              allSelected: false,
              devices: devices.map((device) => deviceMap[device]),
            })
          }}
          selectAllOptions={{
            label: 'All Devices',
            allSelected: state[DEVICE]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(DEVICE),
          }}
          error={errors[elementConfig[DEVICE]]}
        />
      </div>
    )
  }
  /* End of AUDIENCE Section */

  /* CAMPAIGN_TYPE Section */

  const campaignTypesDisplayed = state.elements?.includes(CAMPAIGN_TYPE)

  useEffect(() => {
    if (!getIsLoading(CAMPAIGN_TYPE) && campaignTypesDisplayed) {
      toggleLoading(CAMPAIGN_TYPE, true)
      getAllCampaignTypes(clientId)
        .then(setCampaignTypes)
        .catch(console.error)
        .finally(() => {
          toggleLoading(CAMPAIGN_TYPE, false)
        })
    }
  }, [campaignTypesDisplayed])

  const campaignTypeMap = (campaignTypes || []).reduce(
    (prev, campaignType) => ({
      ...prev,
      [campaignType.id]: campaignType,
    }),
    {}
  )

  const renderCampaignTypeSection = () => {
    if (!campaignTypesDisplayed) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Campaign Types"
          selectedItems={(state[CAMPAIGN_TYPE]?.campaignTypes || []).map(
            ({ id }) => id
          )}
          isLoading={getIsLoading(CAMPAIGN_TYPE)}
          label={'Campaign Types'}
          options={(campaignTypes || []).map((campaignType) => ({
            value: campaignType.id,
            label: campaignType.name,
          }))}
          disabled={readOnly}
          onChange={(campaignTypes) => {
            onCheck(CAMPAIGN_TYPE, {
              allSelected: false,
              campaignTypes: campaignTypes.map(
                (campaignType) => campaignTypeMap[campaignType]
              ),
            })
          }}
          selectAllOptions={{
            label: 'All Campaign Types',
            allSelected: state[CAMPAIGN_TYPE]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(CAMPAIGN_TYPE),
          }}
          error={errors[elementConfig[CAMPAIGN_TYPE]]}
        />
      </div>
    )
  }
  /* End of CAMPAIGN_TYPE Section */

  /* AD_TYPE Section */
  const adTypesDisplayed = state.elements?.includes(AD_TYPE)

  useEffect(() => {
    if (!getIsLoading(AD_TYPE) && adTypesDisplayed) {
      toggleLoading(AD_TYPE, true)
      getAllAdTypes(clientId)
        .then((response) => {
          setAdTypes(response)
        })
        .catch(console.error)
        .finally(() => {
          toggleLoading(AD_TYPE, false)
        })
    }
  }, [adTypesDisplayed])

  const adTypeMap = (adTypes || []).reduce(
    (prev, adType) => ({
      ...prev,
      [adType.id]: adType,
    }),
    {}
  )

  const renderAdTypeSection = () => {
    if (!adTypesDisplayed) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Ad Types"
          isLoading={getIsLoading(AD_TYPE)}
          selectedItems={(state[AD_TYPE]?.adTypes || []).map(({ id }) => id)}
          label={'Ad Types'}
          options={(adTypes || []).map((adType) => ({
            value: adType.id,
            label: adType.name,
          }))}
          disabled={readOnly}
          onChange={(adTypes) => {
            onCheck(AD_TYPE, {
              allSelected: false,
              adTypes: adTypes.map((adType) => adTypeMap[adType]),
            })
          }}
          selectAllOptions={{
            label: 'All Ad Types',
            allSelected: state[AD_TYPE]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(AD_TYPE),
          }}
          error={errors[elementConfig[AD_TYPE]]}
        />
      </div>
    )
  }
  /* End of AD_TYPE Section */

  /* NETWORK Section */
  const networksDisplayed = state.elements?.includes(NETWORK)

  useEffect(() => {
    if (
      !getIsLoading(NETWORK) &&
      networksDisplayed &&
      actualSelectedAccounts.length
    ) {
      toggleLoading(NETWORK, true)
      getGoogleNetworks(clientId, null, actualSelectedAccounts)
        .then(setNetworks)
        .catch(console.error)
        .finally(() => {
          toggleLoading(NETWORK, false)
        })
    }
  }, [JSON.stringify(actualSelectedAccounts), networksDisplayed])

  const networkMap = (networks || []).reduce(
    (prev, network) => ({
      ...prev,
      [network.id]: network,
    }),
    {}
  )

  const renderNetworkSection = () => {
    if (!networksDisplayed) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Networks"
          isLoading={getIsLoading(NETWORK)}
          selectedItems={(state[NETWORK]?.networks || []).map(({ id }) => id)}
          label={'Networks'}
          options={(networks || []).map((network) => ({
            value: network.id,
            label: network.name,
          }))}
          disabled={readOnly}
          onChange={(networks) => {
            onCheck(NETWORK, {
              allSelected: false,
              networks: networks.map((network) => networkMap[network]),
            })
          }}
          selectAllOptions={{
            label: 'All Networks',
            allSelected: state[NETWORK]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(NETWORK),
          }}
          error={errors[elementConfig[NETWORK]]}
        />
      </div>
    )
  }
  /* End of AD_TYPE Section */

  return (
    <Section>
      <SinglePerformanceAccountRow
        state={state}
        onChangeAccounts={onChangeAccounts}
        readOnly={readOnly}
        onChange={onChange}
        platform={GOOGLE}
        availableAccounts={availableAccounts}
        availableElements={ACCOUNTS_TO_SELECT_ELEMENTS[GOOGLE]}
        elementConfig={elementConfig}
        errors={errors}
        setErrors={onChangeErrors}
      />
      {renderAccountSection()}
      {renderCampaignSection()}
      {renderAdGroupSection()}
      {renderAdSection()}
      {renderKeywordSection()}
      {renderAudienceSection()}
      {renderDeviceSection()}
      {renderCampaignTypeSection()}
      {renderAdTypeSection()}
      {renderNetworkSection()}
    </Section>
  )
}

SinglePerformanceGoogle.propTypes = {
  clientId: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  elementConfig: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onChangeErrors: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

/** Data Validation functions, ensured data is dynamically removed based on parent element removals */
const validateCampaignChanges = (
  changes,
  { allAccountsSelected, accounts }
) => {
  if (changes.campaigns && !allAccountsSelected) {
    const availableAccountSet = new Set(accounts)
    changes.campaigns = changes.campaigns.filter(({ account_id }) =>
      availableAccountSet.has(account_id)
    )
  }
  return changes
}

const validateAdGroupChanges = (changes, state, TYPE) => {
  changes = validateCampaignChanges(changes, state, TYPE)
  if (changes.campaigns) {
    const { adGroups } = state[TYPE]
    if (adGroups?.length) {
      const availableCampaignsSet = new Set(
        changes.campaigns.map(({ resource_name }) => resource_name)
      )
      changes.adGroups = adGroups.filter(({ campaign }) =>
        availableCampaignsSet.has(campaign)
      )
    }
  }

  return changes
}

const validateAdChanges = (changes, state, TYPE) => {
  changes = validateAdGroupChanges(changes, state, TYPE)

  if (changes.adGroups) {
    const { ads } = state[TYPE]

    if (ads?.length) {
      const availableAdGroupSet = new Set(
        changes.adGroups.map(({ resource_name }) => resource_name)
      )
      changes.ads = ads.filter(({ ad_group }) =>
        availableAdGroupSet.has(ad_group.resource_name)
      )
    }
  }

  return changes
}

const validateKeywordChanges = (changes, state, TYPE) => {
  changes = validateAdGroupChanges(changes, state, TYPE)

  if (changes.adGroups) {
    const { keywords } = state[TYPE]

    const availableAdGroupSet = new Set(
      changes.adGroups.map(({ resource_name }) => resource_name)
    )

    changes.keywords = keywords.filter(({ ad_group }) =>
      availableAdGroupSet.has(ad_group.resource_name)
    )
  }

  return changes
}

const validDataPersistenceConfig = {
  [ACCOUNT]: (changes, { allAccountsSelected, accounts }) => {
    if (changes.accounts && !allAccountsSelected) {
      const availableAccountSet = new Set(accounts)
      changes.accounts = changes.accounts.filter(({ id }) =>
        availableAccountSet.has(id)
      )
    }
    return changes
  },
  [CAMPAIGN]: validateCampaignChanges,
  [AD_GROUP]: validateAdGroupChanges,
  [AD]: validateAdChanges,
  [KEYWORD]: validateKeywordChanges,
  [AUDIENCE]: (changes, { allAccountsSelected, accounts }) => {
    if (changes.audiences && !allAccountsSelected) {
      const availableAccountSet = new Set(accounts)
      changes.audiences = changes.audiences.filter(({ account_id }) =>
        availableAccountSet.has(account_id)
      )
    }
    return changes
  },
  [NETWORK]: (changes, { allAccountsSelected, accounts }) => {
    if (changes.networks && !allAccountsSelected) {
      const availableAccountSet = new Set(accounts)
      changes.networks = changes.networks.filter(
        ({ account_id, non_account_related }) =>
          non_account_related || availableAccountSet.has(account_id)
      )
    }
    return changes
  },
}
