import { flow } from '@decision-sciences/qontrol-common'

const { ODAX_OBJECTIVES_MAP, CTA_VALUE } = flow.facebook
const { ADSET_CONVERSION } = flow.facebook.odax

// Render conditions for separate fields inside the Ad Options panel
export const AD_OPTION_FIELD_CONDITIONS = {
  WEBSITE_URL: {
    disabled: [
      {
        campaignObjective: ODAX_OBJECTIVES_MAP.ODAX_APP_PROMOTION,
      },
      { campaignObjective: ODAX_OBJECTIVES_MAP.ODAX_SALES },
    ],
  },
  BUILD_URL: {
    disabled: [{ campaignObjective: ODAX_OBJECTIVES_MAP.ODAX_SALES }],
  },
  // Disabled Display Link and Deep link section
  LINK_SECTION: {
    disabled: [{ campaignObjective: ODAX_OBJECTIVES_MAP.ODAX_SALES }],
  },
  DISPLAY_LINK: {
    disabled: [],
  },
  DEEP_LINK: {
    disabled: [{ campaignObjective: ODAX_OBJECTIVES_MAP.ODAX_AWARENESS }],
  },
}

export const AD_PANELS_CONDITIONS = {
  AD_OPTIONS: {
    disabled: [
      {
        campaignObjective: ODAX_OBJECTIVES_MAP.ODAX_AWARENESS,
        'ad.has_website_url': false,
      },
      {
        campaignObjective: ODAX_OBJECTIVES_MAP.ODAX_SALES,
        'ad.website_url.call_to_action_btn': CTA_VALUE.BUY_TICKETS,
        'adSet.promoted_object.local__conversion':
          ADSET_CONVERSION.WEBSITE.value,
      },
    ],
  },
  LANGUAGES: {
    disabled: [
      {
        campaignObjective: ODAX_OBJECTIVES_MAP.ODAX_SALES,
        'ad.website_url.call_to_action_btn': CTA_VALUE.BUY_TICKETS,
        'adSet.promoted_object.local__conversion':
          ADSET_CONVERSION.WEBSITE.value,
      },
    ],
  },
  INFO_LABELS: {
    disabled: [
      {
        campaignObjective: ODAX_OBJECTIVES_MAP.ODAX_SALES,
        'ad.website_url.call_to_action_btn': CTA_VALUE.BUY_TICKETS,
        'adSet.promoted_object.local__conversion':
          ADSET_CONVERSION.WEBSITE.value,
      },
      { campaignObjective: ODAX_OBJECTIVES_MAP.ODAX_AWARENESS },
      {
        campaignObjective: ODAX_OBJECTIVES_MAP.ODAX_SALES,
        'adSet.promoted_object.local__conversion': ADSET_CONVERSION.APP.value,
      },
    ],
  },
  AD_DESTINATION: {
    disabled: [{ campaignObjective: ODAX_OBJECTIVES_MAP.ODAX_AWARENESS }],
  },
}

/**
 * Checks whether an Ad can have the Website URL section depending on the Ad itself and the parent AdSet and Campaign
 * @return {boolean}
 */
export const canHaveWebsiteSection = (ad, adSet, campaign) => {
  const objective = campaign.objective
  return OBJECTIVES_FOR_WEBSITE_URL.includes(objective)
}

// Objectives for which the "Add Website URL" checkbox should appear -> which toggles the Ad Options Panel
const OBJECTIVES_FOR_WEBSITE_URL = [
  ODAX_OBJECTIVES_MAP.ODAX_AWARENESS,
  ODAX_OBJECTIVES_MAP.ODAX_ENGAGEMENT,
]
// Ad panels which are rendered conditionally based  on either campaign objective/adset/ad selections
export const CONDITIONAL_AD_FIELDS = {
  AD_OPTIONS: 'AD_OPTIONS',
  LANGUAGES: 'LANGUAGES',
  INFO_LABELS: 'INFO_LABELS',
  AD_DESTINATION: 'AD_DESTINATION',
}
export const { AD_OPTIONS, AD_DESTINATION, LANGUAGES, INFO_LABELS } =
  CONDITIONAL_AD_FIELDS
