import React, { useEffect } from 'react'
import {
  useParams,
  redirect,
  useOutletContext,
  useLocation,
} from 'react-router-dom'

import CreateEditAccount from 'modules/accounts/create-edit'
import Loader from 'components/loader'

import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'

const AccountEditPage = () => {
  const { state } = useLocation()
  const { accountKey } = useParams()

  const { company } = useOutletContext()

  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.ACCOUNT_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.ACCOUNT_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })

  // Force user out if not a valid route or no access
  useEffect(() => {
    if (company) {
      const noAccess = !accountKey && !hasCreateAccess
      const noEditAccess = !!accountKey && !hasEditAccess
      if (noAccess || noEditAccess) {
        redirect('/unauthorized')
      }
    }
  }, [company, accountKey, hasCreateAccess, hasEditAccess])

  if (company) {
    return <CreateEditAccount accountKey={accountKey} buState={state} />
  }
  return <Loader />
}

export default AccountEditPage
