import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/** Icon */
import { ReactComponent as CloseIcon } from 'assets/icon_close_white.svg'
import { ReactComponent as EditIcon } from 'assets/icon_edit_white.svg'

/** Styling */
import './style.scss'

/**
 * Text tag component used with close icon
 * @param {Object} props Component props
 * @param {String} props.label Text to display on tag
 * @param {Function} [props.onClose] Function applied on close icon
 * @param {Function} [props.onEdit] Function applied on edit icon
 * @param {String} [props.color] Custom background color for the tag
 * @param {Boolean} [props.disabled] Tag is disabled
 * @param {Boolean} [props.error] True if tag has a validation error
 * @param {Node} [props.customIcon] Custom icon for tag
 * @param {Boolean}[props.light] Ligh mode for tag. Close icon will be blue in light mode.
 * @param {Boolean}[props.small]
 * @returns {React.Component}
 */
const TextTag = ({
  label,
  onClose,
  onEdit,
  color,
  disabled,
  error,
  customIcon,
  light,
  small,
}) => {
  const tagContainerStyle =
    color && !error ? { backgroundColor: color } : undefined

  const closeIcon = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    !disabled ? onClose() : null
  }

  return (
    <div
      className={cx('tag-container', {
        'tag-container--disabled': disabled,
        'tag-container--error': error,
        'tag-container--light': light,
        'tag-container--small': small,
      })}
      style={tagContainerStyle}
    >
      {onEdit && (
        <EditIcon
          data-testid="tag-edit-icon"
          onClick={!disabled ? onEdit : null}
          className="cursor--pointer"
        />
      )}
      <div
        className={cx('tag-label', {
          'tag-label--error': error,
          'tag-label--light': light,
        })}
      >
        {label}
      </div>
      {onClose && (
        <CloseIcon
          data-testid="tag-close-icon"
          className="tag-close-icon"
          onClick={closeIcon}
        />
      )}
      {customIcon}
    </div>
  )
}

TextTag.propTypes = {
  label: PropTypes.string,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  customIcon: PropTypes.node,
  light: PropTypes.bool,
  small: PropTypes.bool,
}

export default TextTag
