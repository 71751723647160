import React from 'react'

/**
 * Use this Page however you see fit.
 */
const Sandbox = () => {
  return <section></section>
}

export default Sandbox
