import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import { format } from 'date-fns'

// Components
import BulkEdit from 'components/bulk-edit'
import { Reporting } from 'modules/companies/subsections/reporting'

// Constants
import { TABLEAU_REPORTING_DEFAULT } from 'modules/companies/constants'

// Utils
import { getTooltipList } from 'components/utils/tooltip'

// Actions
import { setBulkEditCompanies } from 'modules/companies/actions'
import { entityStatus, utils } from '@decision-sciences/qontrol-common'

const { ENTITY_STATUS_LABEL } = entityStatus
const { isEmpty } = utils.object

const BulkEditCompanies = ({ onApplyChanges, onCancelChanges }) => {
  const { dispatch, state } = useStore()
  const {
    companies: { bulkEditList },
    users: { list: usersList },
  } = state

  const [bulkEditChanges, setBulkEditChanges] = useState({})
  const [errors, setErrors] = useState({})

  const bulkEditingBus = bulkEditList.some((comp) => !!comp.parentCompany)
  const clientUsers = (row) =>
    usersList.filter((user) =>
      user.clients.find((el) =>
        row.parentCompany
          ? el.clientId === row.parentCompany &&
            el.businessUnits.includes(row._id)
          : el.clientId === row._id
      )
    )

  // Clear bulk edit states on unmount
  useEffect(
    () => () => {
      setBulkEditCompanies(dispatch, [])
      setBulkEditChanges({})
    },
    []
  )

  const hasErrors = () => {
    const errorKeys = Object.keys(errors)
    if (errorKeys.length) {
      return errorKeys.some((key) => !isEmpty(errors[key]))
    }
    return false
  }
  const columns = [
    {
      header: COLUMN_HEADERS.CLIENT,
      accessorKey: 'name',
      textAlign: 'left',
      size: 100,
      sortType: 'name',
    },
    {
      header: COLUMN_HEADERS.BUSINESS_UNITS,
      accessorFn: (row) => (row.businessUnits ? row.businessUnits.length : 0),
      tooltip: (row) => {
        if (!row.businessUnits || !row.businessUnits.length) {
          return null
        }
        return getTooltipList(
          'Business Units',
          row.businessUnits.map((item) => item.name)
        )
      },
      size: 90,
      textAlign: 'right',
      sortingFn: 'alphanumeric',
    },
    {
      header: COLUMN_HEADERS.ACCOUNTS,
      accessorFn: (row) => {
        return (row.businessUnits || []).reduce((arr, businessUnit) => {
          return [...arr, ...businessUnit.accounts]
        }, row.accounts || []).length
      },
      tooltip: (row) => {
        const allAccounts = (row.businessUnits || []).reduce(
          (arr, businessUnit) => {
            return [
              ...arr,
              ...businessUnit.accounts.map((acc) => ({
                ...acc,
                name: `${acc.name} *`,
              })),
            ]
          },
          row.accounts || []
        )
        if (!allAccounts.length) {
          return null
        }

        return getTooltipList(
          'Accounts',
          allAccounts.map((item) => item.name)
        )
      },
      size: 90,
      textAlign: 'right',
      sortingFn: 'alphanumeric',
    },
    {
      header: COLUMN_HEADERS.USERS,
      accessorKey: COLUMN_HEADERS.USERS,
      accessorFn: (row) => {
        return clientUsers(row)?.length
      },
      tooltip: (row) => {
        if (clientUsers(row)?.length) {
          return getTooltipList(
            'Users',
            clientUsers(row)?.map((item) => item.name)
          )
        }
      },
      size: 90,
      textAlign: 'right',
      sortingFn: 'alphanumeric',
    },
    {
      header: COLUMN_HEADERS.DATE_ADDED,
      accessorFn: (row) =>
        row.createdAt
          ? format(new Date(row.createdAt), 'MM/dd/yyyy')
          : format(new Date(), 'MM/dd/yyyy'),
      textAlign: 'center',
      size: 80,
      sortingFn: 'date',
    },
    {
      header: COLUMN_HEADERS.STATUS,
      id: 'status',
      cell: (cell) => {
        const { active } = cell.row.original
        const { deleted } = cell.row.original
        return <span>{deleted ? 'Archived' : ENTITY_STATUS_LABEL[active]}</span>
      },
      size: 80,
      textAlign: 'center',
      accessorKey: 'status',
      accessorFn: (row) => (row.active ? 1 : 0),
    },
  ]

  const bulkEditColumns = bulkEditingBus
    ? columns.filter((col) => !col.header.match(COLUMN_HEADERS.BUSINESS_UNITS))
    : columns

  const bulkEditOptions = [
    {
      label: 'Fiscal Options',
      value: 'Fiscal Options',
      className: 'bulk-edit__reporting',
      renderInputBelow: () => (
        <Reporting
          reportingConfig={
            bulkEditChanges.tableauConfig || TABLEAU_REPORTING_DEFAULT
          }
          setErrors={setErrors}
          errors={errors}
          displayIndustry={false}
          onChange={(changes) =>
            setBulkEditChanges({ ...bulkEditChanges, tableauConfig: changes })
          }
        />
      ),
    },
  ]
  const bulkEditLabels = {
    heading: bulkEditingBus ? 'Bulk Edit Business Units' : 'Bulk Edit Clients',
  }
  const bulkEditButtons = [
    {
      value: 'Apply Changes',
      onClick: () => onApplyChanges(bulkEditChanges),
      disabled: !Object.keys(bulkEditChanges).length || hasErrors(),
    },
    {
      value: 'Cancel',
      onClick: onCancelChanges,
      secondaryGray: true,
    },
  ]
  return (
    <BulkEdit
      data={bulkEditList}
      columns={bulkEditColumns}
      options={bulkEditOptions}
      labels={bulkEditLabels}
      actionButtons={bulkEditButtons}
    />
  )
}
export default BulkEditCompanies

BulkEditCompanies.propTypes = {
  onApplyChanges: PropTypes.func.isRequired,
  onCancelChanges: PropTypes.func.isRequired,
}

export const COLUMN_HEADERS = {
  ACTIONS: ' ',
  CLIENT: 'Client',
  BUSINESS_UNITS: 'Business Units',
  ACCOUNTS: 'Accounts',
  USERS: 'Users',
  DATE_ADDED: 'Date Added',
  STATUS: 'Status',
}
