import Api from 'easy-fetch-api'
import { getAlertTemplates } from 'modules/alert-templates/actions'
export const ACTIONS = {
  GET_ALERTS: 'alerts.getAll',
  GET_ALERT_CATEGORIES: 'alerts.getCategories',
  SET_BULK_ALERTS: 'alerts.setBulkAlerts',
}

export const getAlertsPaginated = async (
  companyId,
  query = {},
  paginationData,
  sortData
) => {
  if (companyId) {
    query.company = companyId
  }
  if (sortData) {
    query.sortData = sortData.reduce(
      (prev, current) => ({
        ...prev,
        [current.id]: current.desc ? -1 : 1,
      }),
      {}
    )
  }

  return await Api.get({
    url: '/api/alerts',
    query: { ...query, ...paginationData },
  }).then((result) => {
    if (!result || !result.success) {
      return console.error((result && result.error) || 'Error getting alerts')
    }
    return {
      list: result.list,
      total: result.total,
    }
  })
}

export const getAlertsFromCache = async (companyId) => {
  return await Api.get({
    url: '/api/alerts/get-from-cache',
    query: { companyId },
  })
    .then((result) => {
      if (!result || !result.success) {
        return console.error(
          (result && result.error) || 'Error getting alerts from cache.'
        )
      }
      return {
        list: result.list,
        total: result.total,
      }
    })
    .catch((err) => console.error(err))
}

export const initGetAlertsPaginated = async (
  companyId,
  query = {},
  paginationData,
  sortData,
  excludeSingleClientAlerts = false
) => {
  if (companyId) {
    query.company = companyId
  }
  query.excludeSingleClientAlerts = excludeSingleClientAlerts
  if (sortData) {
    query.sortData = sortData.reduce(
      (prev, current) => ({
        ...prev,
        [current.id]: current.desc ? -1 : 1,
      }),
      {}
    )
  }

  return await Api.get({
    url: '/api/alerts/init-get-alerts',
    query: { ...query, ...paginationData },
  }).then((result) => {
    if (!result || !result.success) {
      return console.error((result && result.error) || 'Error getting alerts')
    }
    return {
      list: result.list,
      total: result.total,
    }
  })
}

export const fetchAlert = (_id, client, query = {}) => {
  return Api.get({
    url: `/api/alerts/id/${_id}`,
    query: { client, query },
  }).then((result) => {
    if (!result || !result.success) {
      throw new Error((result && result.error) || 'Error getting alert')
    }
    return result.alert
  })
}

export const getAlertFilters = () => {
  return Api.get({ url: '/api/alerts/filters' }).then((result) => {
    if (!result || !result.success) {
      return console.error(
        (result && result.error) || 'Error getting alert filters'
      )
    }

    return {
      owners: result.owners,
      categories: result.categories,
      metrics: result.metrics,
    }
  })
}

/**
 * Fetch simple alert list, non-paginated
 * @param {String} company company id
 * @param {Object} [filterData] Optional filters
 * @param {String} [groupType] Group type: ACCOUNT/CLIENT_BU
 * @returns {Object[]}
 */
export const fetchAlerts = (company, filterData = {}, groupType) => {
  const query = {}

  if (filterData) {
    query.filterData = filterData
  }
  if (company) {
    query.company = company
  }
  if (groupType) {
    query.groupType = groupType
  }

  return Api.get({
    url: '/api/alerts/notification-groups',
    query,
  }).then((result) => {
    if (!result || !result.success) {
      return console.error((result && result.error) || 'Error getting alerts')
    }
    return result.list
  })
}

/**
 * Fetch simple alert list, non-paginated, contains mainly {_id, name and alertType}
 * @param {String} company company id
 * @param {Object} [filterData] Optional filters
 * @returns {Object[]}
 */
export const getAlertNames = (company, filterData = {}) => {
  const query = {}

  if (filterData) {
    query.filterData = filterData
  }
  if (company) {
    query.company = company
  }
  return Api.get({
    url: '/api/alerts/names',
    query,
  }).then((result) => {
    if (!result || !result.success) {
      return console.error((result && result.error) || 'Error getting alerts')
    }
    return result.list
  })
}

/**
 * Create/Update alert handler
 * After alert is saved, it refreshes Alert List together with Alert Templates & Alert Categories
 */
export const createUpdateAlert = async (dispatch, alert) => {
  try {
    let promise
    const payload = { url: '/api/alerts', data: alert }
    if (alert._id) {
      promise = Api.put(payload)
    } else {
      promise = Api.post(payload)
    }

    return await promise.then((res) => {
      if (res.error) {
        throw new Error(res.error)
      }

      getAlertTemplates(dispatch)
      getAlertCategories(dispatch)
      return res.data
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getAlertCategories = (dispatch, query) =>
  Api.get({ url: '/api/alerts/categories', query }).then((result) => {
    if (!result || !result.success) {
      return console.error((result && result.error) || 'Error getting alerts')
    }
    dispatch({ type: ACTIONS.GET_ALERT_CATEGORIES, list: result.list, query })
  })

export const updateStatus = (alertId, company, running) => {
  return new Promise((resolve, reject) => {
    Api.put({
      url: `/api/alerts/updateStatus/${alertId}`,
      data: {
        running,
        company,
      },
    })
      .then((res) => {
        if (res.error) {
          console.error('Error updating alert status')
          return reject(res)
        }

        resolve(res)
      })
      .catch(reject)
  })
}

/** Delete Alert & update Alert, Templates & Categories lists */
export const deleteAlert = (dispatch, _id, isSuperAdmin, companyId) => {
  return new Promise((resolve, reject) => {
    Api.delete({ url: `/api/alerts/${_id}` })
      .then((result) => {
        if (!result || !result.success) {
          return console.error(
            (result && result.error) || 'Error deleting alert'
          )
        }

        getAlertTemplates(dispatch)
        getAlertCategories(dispatch)

        resolve(result)
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

/**
 * Bulk Edit Alerts
 * @param {Function} dispatch Dispatch
 * @param {Array} alerts List of alerts to edit
 * @param {Object} data Data to change
 */
export const bulkEditAlerts = (dispatch, alerts, data) => {
  return new Promise((resolve, reject) => {
    Api.put({
      url: `/api/alerts/bulk`,
      data: {
        list: alerts,
        data,
      },
    })
      .then((res) => {
        if (res.error) {
          return reject(res.error)
        }
        getAlertTemplates(dispatch)
        getAlertCategories(dispatch)
        resolve(res.list)
      })
      .catch(reject)
  })
}

/**
 * Bulk Delete Alerts
 * @param {Function} dispatch Dispatch
 * @param {Array} alerts List of alerts to delete
 */
export const bulkDeleteAlerts = (dispatch, alerts) => {
  return new Promise((resolve, reject) => {
    Api.put({
      url: `/api/alerts/bulk-delete`,
      data: {
        list: alerts.map((alert) => alert._id),
      },
    })
      .then((res) => {
        if (res.error) {
          return reject(res.error)
        }
        getAlertTemplates(dispatch)
        const updatedList = alerts.map((alert) => ({ ...alert, deleted: true }))
        resolve(updatedList)
      })
      .catch(reject)
  })
}

/**
 * Bulk Add Granularity to Alerts
 * @param {Function} dispatch Dispatch
 * @param {Array} alerts list of selected alerts
 * @param {Object} changes selected granularity for each account
 */
export const bulkAddGranularity = (dispatch, alerts, changes) => {
  return new Promise((resolve, reject) => {
    Api.put({
      url: `/api/alerts/bulk-add-granularity`,
      data: {
        alertIds: alerts.map((alert) => alert._id),
        changes,
      },
    })
      .then((res) => {
        if (res.error) {
          return reject(res)
        }

        resolve(res.list)
      })
      .catch(reject)
  })
}

/**
 * Bulk Remove Granularity from Alerts
 * @param {Function} dispatch Dispatch
 * @param {Array} alerts list of selected alerts
 * @param {Object} changes selected granularity for each account
 */
export const bulkRemoveGranularity = (dispatch, alerts, changes) => {
  return new Promise((resolve, reject) => {
    Api.put({
      url: `/api/alerts/bulk-remove-granularity`,
      data: {
        alertIds: alerts.map((alert) => alert._id),
        changes,
      },
    })
      .then((res) => {
        if (res.error) {
          return reject(res)
        }

        resolve(res.list)
      })
      .catch(reject)
  })
}

/**
 * Bulk Add Notification Channels to Alerts
 * @param {Function} dispatch Dispatch
 * @param {Array} alertIds Array of alert ids to add notification channels to
 * @param {Array} notificationOptions Notification channels to add
 */
export const bulkAddNotificationChannels = (
  dispatch,
  alertIds,
  notificationOptions
) => {
  return new Promise((resolve, reject) => {
    Api.put({
      url: `/api/alerts/bulk-add-channels`,
      data: {
        list: alertIds,
        notificationOptions,
      },
    })
      .then((res) => {
        if (res.error) {
          return reject(res)
        }
        resolve(res.list)
      })
      .catch(reject)
  })
}

/**
 * Bulk Add Clients to Alerts
 * @param {Function} dispatch Dispatch
 * @param {Array} alertIds Array of alert ids to add notification channels to
 * @param {Object} data Clients information to add
 * @param {Boolean} data.allSelected Whether all Clients are selected
 * @param {Array} data.companies Company ids to add
 */
export const bulkAddClients = (dispatch, alertIds, data) => {
  return new Promise((resolve, reject) => {
    Api.put({
      url: `/api/alerts/bulk-add-clients`,
      data: {
        list: alertIds,
        data: data,
      },
    })
      .then((res) => {
        if (res.error) {
          return reject(res)
        }
        resolve(res.list)
      })
      .catch(reject)
  })
}

/**
 * Bulk Update Category
 * @param {Function} dispatch Dispatch
 * @param {Array} alertIds Array of alert ids
 * @param {String} category Category to set to alerts
 */
export const bulkUpdateCategory = (
  dispatch,
  alertIds,
  category,
  companyId,
  isSuperAdmin
) => {
  return new Promise((resolve, reject) => {
    Api.put({
      url: `/api/alerts/bulk-update-category`,
      data: {
        list: alertIds,
        category,
      },
    })
      .then((res) => {
        if (res.error) {
          return reject(res)
        }
        dispatch({
          type: ACTIONS.GET_ALERT_CATEGORIES,
          list: res.categories,
        })
        resolve(res.list)
      })
      .catch(reject)
  })
}

/**
 * Bulk Disable Clients from Alerts
 * @param {Function} dispatch Dispatch
 * @param {Array} alertIds Array of alert ids to disable clients from
 * @param {Object} data Clients to disable + all allClientsSelected check
 */
export const bulkDisableClients = (dispatch, alertIds, data) => {
  return new Promise((resolve, reject) => {
    Api.put({
      url: `/api/alerts/bulk-update-status`,
      data: {
        list: alertIds,
        clientIds: data.companies,
        allClients: data.allCompaniesSelected,
        running: false,
      },
    })
      .then((res) => {
        if (res.error) {
          return reject(res.error)
        }

        resolve(res.list)
      })
      .catch(reject)
  })
}

/**
 * Bulk Edit Active flag for Alerts
 * @param {Function} dispatch Dispatch
 * @param {Array} alerts List of alerts to edit
 * @param {Boolean} active Whether the alerts should be active or not
 */
export const bulkUpdateActive = (dispatch, alerts, active) => {
  return new Promise((resolve, reject) => {
    Api.put({
      url: `/api/alerts/bulk-update-active`,
      data: {
        list: alerts,
        active,
      },
    })
      .then((res) => {
        if (res.error) {
          return reject(res.error)
        }

        resolve(res.list)
      })
      .catch(reject)
  })
}
/**
 * Set Alerts to bulk edit
 * @param {Function} dispatch Dispatch
 * @param {String[]} editedalerts Array of alert ids to edit
 * @param {String} navigateOnAction Where to navigate
 */
export const setBulkAlerts = (dispatch, editedalerts, navigateOnAction) => {
  dispatch({
    type: ACTIONS.SET_BULK_ALERTS,
    bulkEditList: editedalerts,
    navigateOnAction: navigateOnAction,
  })
}

/**
 * Get the enum values for settings properties that have dynamic values
 * @param {Object} options options
 * @param {Array} options.selectedCompanies Selected companies
 * @param {String} options.account_type Account Type (this is so far only used for facebook)
 * @param {String} [options.parent] If set, fetch the children of this content type
 * @param {String} options.granularity Granularity
 * @param {String} options.prop Property key
 * @returns {Promise}
 */
export const getEnumValuesFromApi = ({
  selectedCompanies,
  account_type,
  parent = null,
  granularity,
  prop,
}) =>
  Api.post({
    url: `/api/alerts/get-enum-values-for-alert-settings-fields`,
    data: {
      selectedCompanies,
      account_type,
      parent,
      granularity,
      prop,
    },
  })

/**
 * Fetch simple alert list, non-paginated
 * Contains alerts with clients that have users in the team.
 * @param {String} teamKey Team key
 * @returns {Object[]}
 */
export const fetchAlertsForTeam = (teamKey) => {
  const query = {}

  if (!teamKey) {
    return Promise.reject('Team Key is required')
  }

  return Api.get({
    url: `/api/alerts/team/${teamKey}`,
    query,
  }).then((result) => {
    if (!result || !result.success) {
      return console.error(
        (result && result.error) || 'Error getting alerts for team'
      )
    }
    return result.list
  })
}

/**
 * Fetch alerts for userIds
 * Contains alerts with clients that have users in userIds
 * @param {String} userIds UserIds
 * @returns {Object[]}
 */
export const fetchAlertsForUsers = (userIds) => {
  if (!userIds?.length) {
    return Promise.reject('Must specify users in order to retrieve alerts')
  }

  return Api.post({
    url: `/api/alerts/team/users`,
    data: {
      userIds,
    },
  }).then((result) => {
    if (!result || !result.success) {
      return console.error(
        (result && result.error) || 'Error getting alerts for users'
      )
    }
    return result.list
  })
}
