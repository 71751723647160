import Api from 'easy-fetch-api'
import { showErrorMessage } from '../notifications/actions'

export const ACTIONS = {
  SET_VERIFIED: 'google.setVerified',
  SET_CALL_ASSET_CONVERSION_ACTIONS: 'google.setCallAssetConversionActions',
  SET_CONVERSION_GOALS: 'google.setConversionGoals',
  SET_CUSTOMER_CONVERSION_GOALS: 'google.setCustomerConversionGoals',
  SET_CUSTOM_CONVERSION_GOALS: 'google.setCustomConversionGoals',
  SET_LANGUAGES: 'google.setLanguages',
  SET_PAGE_FEEDS: 'google.setPageFeeds',
  SET_CAMPAIGN_AUDIENCE_DEMOGRAPHIC: 'google.getCampaignAudienceDemographic',
  SET_CAMPAIGN_AUDIENCE_INTERESTS: 'google.getCampaignAudienceInterests',
  SET_CAMPAIGN_AUDIENCE_DATA_SEGMENTS: 'google.getCampaignAudienceDataSegments',
  SET_DOMAIN_CATEGORIES: 'google.setDomainCategories',
  SET_BIDDING_STRATEGIES: 'google.setBiddingStrategies',
  SET_SHARED_CAMPAIGN_BUDGETS: 'google.setSharedCampaignBudgets',
  SET_CONVERSION_ACTIONS: 'google.setConversionActions',
}

/**
 * Retrieves the google campaign audience demographic data (used for audience segments)
 */
export const getCampaignAudienceDemographic = async (
  dispatch,
  clientId,
  accountId
) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/google-sync/campaign-audience-demographic`,
      query: { clientId, accountId },
    }).then((response) => {
      if (!response || response.error) {
        reject(response.error)
      } else {
        dispatch({
          type: ACTIONS.SET_CAMPAIGN_AUDIENCE_DEMOGRAPHIC,
          data: response,
        })
        resolve(response)
      }
    })
  })
}

/**
 * Retrieves the google campaign audience interests data (used for audience segments)
 */
export const getCampaignAudienceInterests = async (
  dispatch,
  clientId,
  accountId
) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/google-sync/campaign-audience-interests`,
      query: { clientId, accountId },
    }).then((response) => {
      if (!response || response.error) {
        reject(response.error)
      } else {
        dispatch({
          type: ACTIONS.SET_CAMPAIGN_AUDIENCE_INTERESTS,
          data: response,
        })
        resolve(response)
      }
    })
  })
}

/**
 * Retrieves the google campaign audience data segments data (used for audience segments)
 */
export const getCampaignAudienceDataSegments = (
  dispatch,
  clientId,
  accountId
) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/google-sync/campaign-audience-data-segments`,
      query: { clientId, accountId },
    }).then((response) => {
      if (!response || response.error) {
        reject(response.error)
      } else {
        dispatch({
          type: ACTIONS.SET_CAMPAIGN_AUDIENCE_DATA_SEGMENTS,
          data: response,
        })
        resolve(response)
      }
    })
  })
}

export const getAllCampaigns = async (clientId, signal) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/google/client-campaigns/${clientId}`,
      signal,
    })
      .then((res) => {
        if (!res || res.error) {
          reject(res?.error)
        } else {
          resolve(res.data)
        }
      })
      .catch(reject)
  })
}

export const getAllCampaignTypes = async (clientId, signal) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/google/client-campaign-types/${clientId}`,
      signal,
    })
      .then((res) => {
        if (!res || res.error) {
          reject(res?.error)
        } else {
          resolve(res.data)
        }
      })
      .catch(reject)
  })
}

export const getAllDevices = async (clientId, signal) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/google/client-devices/${clientId}`,
      signal,
    })
      .then((res) => {
        if (!res || res.error) {
          reject(res?.error)
        } else {
          resolve(res.data)
        }
      })
      .catch(reject)
  })
}

export const getAllAdTypes = async (clientId, signal) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/google/client-ad-types/${clientId}`,
      signal,
    })
      .then((res) => {
        if (!res || res.error) {
          reject(res?.error)
        } else {
          resolve(res.data)
        }
      })
      .catch(reject)
  })
}

/**
 * Gets all adgroups for a specific campaign
 * @param {*} clientId Company Id
 * @param {*} campaignResourceName Google campaign resource name
 */
export const getAdGroups = async (clientId, signal, campaignResourceName) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/google/ad-groups/`,
      query: { clientId, campaignResourceName },
      signal,
    })
      .then((res) => {
        if (!res || res.error) {
          reject(res?.error)
        } else {
          resolve(res.data)
        }
      })
      .catch(reject)
  })
}

/**
 * Gets all ads for a specific adgroup
 * @param {*} clientId Company Id
 * @param {*} adGroupResourceName Google ad group resource name
 */
export const getAds = async (clientId, signal, adGroupResourceName) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/google/ads/`,
      query: { clientId, adGroupResourceName },
      signal,
    })
      .then((res) => {
        if (!res || res.error) {
          reject(res?.error)
        } else {
          resolve(res.data)
        }
      })
      .catch(reject)
  })
}

/**
 * Gets all keywords for a specific campaign and adset
 * @param {String} clientId Company Id
 * @param {String} adGroupResourceName Google Ad Group resource name
 */
export const getGoogleKeywords = async (
  clientId,
  signal,
  adGroupResourceName
) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/google/keywords/`,
      query: { clientId, adGroupResourceName },
      signal,
    })
      .then((res) => {
        if (!res || res.error) {
          reject(res?.error)
        } else {
          resolve(res.data)
        }
      })
      .catch(reject)
  })
}

/**
 * Gets all audiences from campaign and adGroup level
 * @param {String} clientId Company Id
 * @param {Array<String>} accounts Account Ids
 */
export const getGoogleAudiences = async (clientId, signal, accounts) => {
  if (!accounts) {
    return []
  }
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/google/audiences/`,
      query: { clientId, accounts },
      signal,
    })
      .then((res) => {
        if (!res || res.error) {
          reject(res?.error)
        } else {
          resolve(res.data)
        }
      })
      .catch(reject)
  })
}

/**
 * Gets all networks
 * @param {String} clientId Company Id
 * @param {Array<String>} accounts Account Ids
 */
export const getGoogleNetworks = async (clientId, signal, accounts) => {
  if (!accounts) {
    return []
  }

  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/google/networks/`,
      query: { clientId, accounts },
      signal,
    })
      .then((res) => {
        if (!res || res.error) {
          reject(res?.error)
        } else {
          resolve(res.data)
        }
      })
      .catch(reject)
  })
}

/**
 * Gets all conversion goals for a specific account
 * @param {Function} dispatch Dispatch
 * @param {String} clientId Company Id
 * @param {String} accountId Account Id
 */
export const getConversionGoals = (dispatch, clientId, accountId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/google/conversion-actions/`,
      query: { clientId, accountId },
    }).then((res) => {
      if (!res || res.error) {
        reject(res.error)
      } else {
        const data = res.data
        dispatch({
          type: ACTIONS.SET_CONVERSION_GOALS,
          accountId,
          data,
        })
        resolve(data)
      }
    })
  })
}

export const getLanguages = (dispatch, accountId, clientId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/google-sync/campaign-languages`,
      query: { clientId, accountId },
    }).then((res) => {
      if (!res || res.error) {
        reject(res?.error)
      } else {
        const data = res.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        )
        dispatch({
          type: ACTIONS.SET_LANGUAGES,
          accountId,
          data,
        })
        resolve(data)
      }
    })
  })
}

export const getPageFeeds = (dispatch, accountId, clientId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/google-sync/campaign-page-feeds`,
      query: { clientId, accountId },
    }).then((res) => {
      if (!res || res.error) {
        reject(res?.error)
      } else {
        const data = res
        dispatch({
          type: ACTIONS.SET_PAGE_FEEDS,
          accountId,
          data,
        })
        resolve(data)
      }
    })
  })
}

/**
 * Get locations to target for campaign page
 */
export const searchLocationsToTarget = (search, clientId, accountId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/google-sync/campaign/${search}`,
      query: { clientId, accountId },
    }).then((res) => {
      if (!res || res.error) {
        reject(res.error)
      } else {
        resolve(res)
      }
    })
  })
}

/**
 * Retrieves the Google domain categories
 */
export const getDomainCategories = async (
  dispatch,
  accountId,
  clientId,
  domain,
  language
) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/google-sync/ad-group/domain-categories`,
      query: {
        domain,
        language,
        clientId,
        accountId,
      },
    }).then((response) => {
      if (!response || response.error) {
        reject(response.error)
      } else {
        dispatch({
          type: ACTIONS.SET_DOMAIN_CATEGORIES,
          accountId,
          data: response,
          domain,
          language,
        })
        resolve(response)
      }
    })
  })
}

/**
 * Get generated keywords ideas for url and/or keywords
 */
export const getGeneratedKeywordIdeas = (clientId, url, keywords, accountId) =>
  Api.post({
    url: `/api/google-sync/ad-group/generate-keyword-ideas`,
    data: {
      url: url,
      keywords: keywords,
      clientId,
      accountId,
    },
  }).then((response) => {
    if (!response || response.error) {
      return console.error(
        (response && response.error) || 'Error getting keyword ideas'
      )
    }
    return response
  })

/**
 * Fetch assets from a specific account of a specific type
 * @param {String} clientId Client ID
 * @param {String} accountId Google Account ID
 * @param {Number} type Google Asset Type enum
 * @returns {Array<Object>}
 */
export const getAssets = async (clientId, accountId, type) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/google-assets',
      query: { type, accountId, clientId },
    })
      .then((response) => {
        if (!response || !response.success || !response.data) {
          return reject(response?.error || 'Could not fetch Google Assets')
        }

        resolve(response.data)
      })
      .catch(reject)
  })
}

/**
 * Fetch assets from a specific account of a specific type
 * @param {String} clientId Client ID
 * @param {String} accountId Google Account ID
 * @param {Number} type Google Asset Type enum
 * @returns {Array<Object>}
 */
export const getAssetLinks = async (clientId, accountId, type) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/google-assets/associations',
      query: { type, accountId, clientId },
    })
      .then((response) => {
        if (!response || !response.success || !response.data) {
          return reject(response?.error || 'Could not fetch Google Assets')
        }

        resolve(response.data)
      })
      .catch(reject)
  })
}

export const createUpdateAsset = async (
  clientId,
  accountId,
  type,
  asset,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    const payload = {
      url: '/api/google-assets/',
      data: {
        asset,
        clientId,
        accountId,
        type,
      },
    }

    const promise = asset.remoteId ? Api.put(payload) : Api.post(payload)

    promise
      .then((result) => {
        if (!result || !result.success || result.error) {
          dispatch && showErrorMessage(result.error, dispatch, false)
          return reject(
            result.error ||
              'Could not update Google Asset. Something went wrong.'
          )
        }

        resolve(result.data)
      })
      .catch(reject)
  })
}

export const getCallConversionActions = async (
  dispatch,
  clientId,
  accountId
) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/google/call-conversion-actions',
      query: { accountId, clientId },
    })
      .then((response) => {
        if (!response || !response.success || !response.data) {
          return reject(
            response?.error || 'Could not fetch Call Conversion Actions'
          )
        }

        dispatch({
          type: ACTIONS.SET_CALL_ASSET_CONVERSION_ACTIONS,
          callAssetConversionActions: response.data,
        })
        resolve(response.data)
      })
      .catch(reject)
  })
}

export const getCustomerConversionGoals = async (
  dispatch,
  clientId,
  accountId
) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/google/customer-conversion-goals',
      query: { accountId, clientId },
    })
      .then((response) => {
        if (!response || !response.success || !response.data) {
          return reject(
            response?.error || 'Could not fetch Customer Conversion Goals'
          )
        }

        dispatch({
          type: ACTIONS.SET_CUSTOMER_CONVERSION_GOALS,
          data: response.data,
          accountId,
        })
        resolve(response.data)
      })
      .catch(reject)
  })
}

export const getCustomConversionGoals = async (
  dispatch,
  clientId,
  accountId
) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/google/custom-conversion-goals',
      query: { accountId, clientId },
    })
      .then((response) => {
        if (!response || !response.success || !response.data) {
          return reject(
            response?.error || 'Could not fetch Custom Conversion Goals'
          )
        }

        dispatch({
          type: ACTIONS.SET_CUSTOM_CONVERSION_GOALS,
          data: response.data,
          accountId,
        })
        resolve(response.data)
      })
      .catch(reject)
  })
}

export const getBiddingStrategies = async (dispatch, clientId, accountId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/google/bidding-strategies',
      query: { accountId, clientId },
    })
      .then((response) => {
        if (!response || !response.success || !response.data) {
          return reject(response?.error || 'Could not fetch Bidding Strategies')
        }

        dispatch({
          type: ACTIONS.SET_BIDDING_STRATEGIES,
          data: response.data,
          accountId,
        })
        resolve(response.data)
      })
      .catch(reject)
  })
}

export const getSharedCampaignBudgets = async (
  dispatch,
  clientId,
  accountId
) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/google/shared-campaign-budgets',
      query: { accountId, clientId },
    })
      .then((response) => {
        if (!response || !response.success || !response.data) {
          return reject(
            response?.error || 'Could not fetch Shared Campaign Budgets'
          )
        }

        dispatch({
          type: ACTIONS.SET_SHARED_CAMPAIGN_BUDGETS,
          data: response.data,
          accountId,
        })
        resolve(response.data)
      })
      .catch(reject)
  })
}

export const getConversionActions = async (dispatch, clientId, accountId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/google/conversion-actions',
      query: { accountId, clientId },
    })
      .then((response) => {
        if (!response || !response.success || !response.data) {
          return reject(response?.error || 'Could not fetch Conversion Actions')
        }

        dispatch({
          type: ACTIONS.SET_CONVERSION_ACTIONS,
          data: response.data,
          accountId,
        })
        resolve(response.data)
      })
      .catch(reject)
  })
}
