import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import './style.scss'

/**
 * Renders content wrapper with a label
 * @param {Object} params React Params
 * @param {String} [params.label] Label to display
 * @param {*} params.children React Children
 * @param {String} [params.className] React Children
 * @returns {React.ComponentElement}
 */
const LabelWrapper = ({ label, children, className, black, green, grey }) => {
  const isValidChild = (child) => {
    return child !== null && typeof child !== 'undefined' && child !== ''
  }
  if (
    !isValidChild(children) ||
    React.Children.toArray(children).every((child) => !isValidChild(child))
  ) {
    return null
  }
  return (
    <div
      data-testid="label-wrap"
      className={cx('label-wrap', {
        'label-wrap--black': black,
        'label-wrap--green': green,
        'label-wrap--grey': grey,
      })}
    >
      <label className="label-wrap__label">{label}</label>
      <div
        data-testid="label-wrap__body"
        className={cx('label-wrap__body', {
          [className]: className,
        })}
      >
        {children}
      </div>
    </div>
  )
}

LabelWrapper.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  black: PropTypes.bool,
  green: PropTypes.bool,
  grey: PropTypes.bool,
}

export default LabelWrapper
