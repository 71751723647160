import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Button from 'components/button'

const OPERANDS = [
  ['AND', 'left-button'],
  ['OR', 'right-button'],
]
/**
 * Convenient way to render the operand buttons
 * @param {Object} props
 * @param {Object} props.publisher - The publisher for which
 * @param {Function} props.onOperandChanged - Method to be called when the operand value changes
 * @param {Function} props.hidden - Flag that tells us to hide the operand button
 */
const OperandButton = ({ publisher, onOperandChanged, hidden }) => {
  if (hidden) {
    return null
  }

  return (
    <div className="margin-bottom-16 margin-top-16 display-flex">
      {OPERANDS.map(([operand, classes]) => (
        <Button
          key={operand}
          value={operand}
          className={cx('campaign-exclusions-basic-button', classes, {
            'button-selected': publisher.config?.operand === operand,
          })}
          onClick={() =>
            publisher.config?.operand !== operand &&
            onOperandChanged(operand, publisher)
          }
        />
      ))}
    </div>
  )
}

export default OperandButton

OperandButton.propTypes = {
  publisher: PropTypes.object.isRequired,
  onOperandChanged: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
}
