import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'store'
import { format } from 'date-fns'
import isEqual from 'lodash.isequal'

import useSession from 'modules/session'
import { fetchAlerts } from 'modules/alerts/actions'

import Button from 'components/button'
import Table from 'components/table/beta'
import Filters, { FilterSection } from 'components/filters'
import Input from 'components/input'
import { Dropdown } from 'components/dropdown'
import { getTooltipList } from 'components/utils/tooltip'
import Loader from 'components/loader'
import DropdownWithSubsections from 'components/dropdown-with-subsections'

import { useAccess, PERMISSION_TYPES, PERMISSIONS } from 'hooks/access'

import { getNotificationGroups } from 'modules/notification-groups/actions'
import {
  resetFilterSort,
  saveFilter,
  saveSort,
} from 'modules/table-filter-sort/actions'
import { defaultFilterSortConfig } from 'modules/table-filter-sort/constants'
import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'
import {
  timezoneCountry,
  accounts,
  utils,
} from '@decision-sciences/qontrol-common'

import './styles.scss'

const { TIMEZONES } = timezoneCountry
const { ACCOUNT_TYPES_MAP, ACCOUNT_TYPE_NAMES } = accounts
const { getCommonFields } = utils.object
const { compareIgnoreCase } = utils.string

const NotificationGroups = ({ onDelete, tableContainer }) => {
  const navigate = useNavigate()
  const { dispatch, state } = useStore()
  const { alerts, notificationGroups, users, companies } = state
  const allNotificationGroups = notificationGroups?.list
  const allAlertCategories = alerts?.categories || []
  const allCompanies = companies?.list || []
  const allUsers = users?.list || []
  const [, user] = useSession()
  const companyId = user.isSuperAdmin
    ? null
    : state.companies?.currentCompany?._id
  const [loading, setLoading] = useState()
  const [allAlerts, setAllAlerts] = useState(null)

  const filterSort = state.tableFilterSort.filterSort[tableContainer]

  const mapFilterStateToQuery = (state) => {
    const {
      name,
      owner,
      source,
      publishers,
      alerts,
      alertCategories,
      companies,
    } = state

    const query = { alerts: {}, notificationgroup: {} }

    if (name?.length) {
      query.notificationgroup.name = name
    }

    if (owner !== undefined) {
      query.notificationgroup.owner = owner
    }

    if (source !== undefined && source !== '') {
      query.notificationgroup.global = source
    }

    if (publishers?.length) {
      query.alerts.publishers = publishers
    }

    if (alerts?.length) {
      query.alerts._id = alerts
    }

    if (alertCategories?.length) {
      query.alerts.category = alertCategories
    }

    if (companies?.length) {
      query.alerts.companies = companies
    }

    return query
  }

  useEffect(() => {
    getNotificationGroups(
      dispatch,
      filterSort?.filter ? mapFilterStateToQuery(filterSort.filter) : {}
    ).finally(() => setLoading(false))
  }, [JSON.stringify(filterSort?.filter), allNotificationGroups?.length])

  useEffect(() => {
    setLoading(true)
    return () => {
      getNotificationGroups(dispatch)
    }
  }, [])

  useEffect(() => {
    if (!allAlerts) {
      fetchAlerts(user.isSuperAdmin ? null : companyId)
        .then(setAllAlerts)
        .catch(console.error)
    }
  }, [JSON.stringify(allAlerts), companyId])

  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.NOTIFICATION_GROUPS,
    type: PERMISSION_TYPES.CREATE,
  })
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.NOTIFICATION_GROUPS,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasViewAccess = useAccess({
    feature: PERMISSIONS.NOTIFICATION_GROUPS,
    type: PERMISSION_TYPES.READ,
  })
  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.NOTIFICATION_GROUPS,
    type: PERMISSION_TYPES.DELETE,
  })

  const columns = [
    {
      header: ' ',
      cell: (cell) => {
        const { _id } = cell.row.original
        if (!hasViewAccess) {
          return null
        }
        return (
          <div className="table__actions align-center">
            <div
              className={hasEditAccess ? 'table__edit' : 'table__view'}
              onClick={() => navigate(`/notification-groups/${_id}`)}
            />
          </div>
        )
      },
      size: 40,
      maxSize: 85,
    },
    {
      header: 'Name',
      accessorKey: 'name',
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 130,
      sortingFn: 'name',
    },
    {
      header: 'Alerts',
      accessorKey: 'alerts',
      accessorFn: (row) => (row.alerts ? row.alerts?.length : 0),
      tooltip: (row) => {
        if (!row.alerts || !row.alerts.length) {
          return null
        }
        return getTooltipList(
          'Alerts',
          row.alerts.map((item) => item.alert?.name)
        )
      },
      size: 90,
      textAlign: 'left',
      cellTextAlign: 'left',
      sortType: 'number',
    },
    {
      header: 'Source',
      accessorKey: 'global',
      accessorFn: (row) => (row.global ? 'Global' : 'Client-Specific'),
      size: 90,
      textAlign: 'left',
      cellTextAlign: 'left',
    },
    {
      header: 'Owner',
      accessorKey: 'owner',
      accessorFn: (row) =>
        row.owner.name || `${row.owner.firstName} ${row.owner.lastName}`,
      size: 90,
      textAlign: 'left',
      cellTextAlign: 'left',
    },
    {
      header: 'Start Time',
      accessorKey: 'startTime',
      cell: (cell) => {
        const { startTime } = cell.row.original
        if (Object.keys(startTime).length > 0) {
          const timezone = TIMEZONES.find(({ iana }) => {
            return iana === startTime?.timeZone
          })?.value
          return (
            <div>
              {startTime.date} {startTime.hours}:{startTime.minutes}
              {startTime.amPm}
              <br />
              {timezone ? timezone : ''}
            </div>
          )
        }
      },
      size: 90,
      textAlign: 'left',
      cellTextAlign: 'left',
    },
    {
      header: 'Last Modified',
      accessorKey: 'updatedAt',
      accessorFn: (row) => format(new Date(row.updatedAt), 'MM/dd/yyyy'),
      textAlign: 'center',
      cellTextAlign: 'center',
      size: 80,
      sortType: 'datetime',
    },
    {
      id: 'delete',
      header: '',
      cell: (cell) =>
        hasDeleteAccess ? (
          <DeleteIcon
            className="table__delete fill-red"
            alt={'delete'}
            onClick={() => onDelete(cell.row.original)}
          />
        ) : null,
      textAlign: 'center',
      size: 40,
      maxSize: 60,
    },
  ]

  const companiesWithBUs = allCompanies.map((company) => ({
    value: company._id,
    label: company.name,
    subsections: company.businessUnits.map((bu) => ({
      value: bu._id,
      label: bu.name,
    })),
  }))

  if (!allNotificationGroups || loading) {
    return <Loader />
  }

  const sortChanged = (newSort) => {
    if (filterSort?.sort && !isEqual(newSort, filterSort.sort)) {
      saveSort(dispatch, tableContainer, newSort)
    }
  }

  return (
    <div className="notification-groups-index">
      <Helmet>
        <title>Notification Groups</title>
      </Helmet>
      <div className="heading" data-cy="page-heading">
        Notification Groups
        {hasCreateAccess && (
          <div className="heading__buttons">
            <Button
              value="Create Notification Group"
              onClick={() => {
                navigate('/notification-groups/new')
              }}
            />
          </div>
        )}
      </div>
      <NotificationGroupFilters
        allAlerts={allAlerts}
        allAlertCategories={allAlertCategories}
        allUsers={allUsers}
        companiesWithBUs={companiesWithBUs}
        tableContainer={tableContainer}
        filters={filterSort?.filter}
      />
      <Table
        columns={columns}
        data={allNotificationGroups || []}
        initialState={{ sortBy: filterSort?.sort }}
        tableContainer={tableContainer}
        onSortChanged={sortChanged}
      />
    </div>
  )
}

NotificationGroups.propTypes = {
  onDelete: PropTypes.func.isRequired,
  tableContainer: PropTypes.string.isRequired,
}

/**
 * Filter module for Notification Groups
 * @param {Object} param params
 * @param {Array<Object>} param.allUsers all users
 * @param {Array<Object>} param.allAlerts all alerts
 * @param {Array<Object>} param.allAlertCategories all alert categories
 * @param {Array<Object>} param.companiesWithBUs all companies with Business Unit subsections
 * @param {String} param.tableContainer the container for notification group table
 * @param {Object} param.filters the filters applied to the notification group table
 * @returns {React.Component}
 */
const NotificationGroupFilters = ({
  allUsers = [],
  allAlerts = [],
  allAlertCategories = [],
  companiesWithBUs = [],
  tableContainer,
  filters,
}) => {
  const initialFilterState =
    defaultFilterSortConfig.filterSort[tableContainer].filter
  const [state, setState] = useState()
  const { dispatch } = useStore()

  useEffect(() => {
    setState(filters)
  }, [filters])

  const applyFilters = () => {
    saveFilter(dispatch, tableContainer, state)
  }

  const clearFilters = () => {
    resetFilterSort(dispatch, tableContainer)
  }

  const onSelectAllClients = (checked) => {
    setState({
      ...state,
      companies: checked ? undefined : [],
    })
  }
  const allPublishers = Object.keys(ACCOUNT_TYPES_MAP)
    ?.filter((key) => !['DV360', 'SNOWFLAKE', 'ODAX'].includes(key))
    ?.map((key) => ACCOUNT_TYPES_MAP[key])

  const [initial, current] = getCommonFields(initialFilterState, state)

  return (
    <Filters
      onApply={applyFilters}
      onClear={clearFilters}
      disableClearOnUnmount={true}
      initialApplied={!isEqual(initial, current)}
    >
      <FilterSection label="Notification Group Name">
        <Input
          onChange={(value) => setState({ ...state, name: value })}
          value={state?.name || ''}
          placeholder="Notification Group Name"
        />
      </FilterSection>
      <FilterSection label="Owner">
        <Dropdown
          defaultOptionText="Owner"
          options={allUsers
            ?.map(({ _id, name }) => ({
              label: name,
              value: _id,
            }))
            .sort((a, b) => compareIgnoreCase(a.label, b.label))}
          selectedItems={state?.owner || []}
          deselectLabel="All Owners"
          onChange={(owner) => setState({ ...state, owner })}
          multiSelect
        />
      </FilterSection>
      <FilterSection label="Source">
        <Dropdown
          defaultOptionText="Source"
          options={[
            { label: 'Global', value: true },
            { label: 'Client-Specific', value: false },
          ]}
          defaultState={state?.source === undefined ? '' : state.source}
          deselectLabel="All Sources"
          onChange={(source) => setState({ ...state, source })}
        />
      </FilterSection>
      <FilterSection label="Publisher">
        <DropdownWithSubsections
          defaultOptionText="All Publishers"
          options={Object.keys(ACCOUNT_TYPES_MAP)
            ?.filter((key) => !['DV360', 'SNOWFLAKE', 'ODAX'].includes(key))
            ?.map((key) => ({
              label: ACCOUNT_TYPE_NAMES[ACCOUNT_TYPES_MAP[key]],
              value: ACCOUNT_TYPES_MAP[key],
            }))
            .sort((a, b) => compareIgnoreCase(a.label, b.label))}
          selectAllOptions={{
            label: 'All Publishers',
            allSelected: state?.publishers?.length === allPublishers.length,
            onCheck: () => {
              const allSelected =
                state?.publishers?.length === allPublishers.length
              !allSelected
                ? setState({
                    ...state,
                    publishers: allPublishers,
                  })
                : setState({
                    ...state,
                    publishers: [],
                  })
            },
          }}
          defaultState={state?.publishers || []}
          deselectLabel="All Publishers"
          selectedItems={state?.publishers || []}
          onChange={(publishers) => {
            setState({
              ...state,
              publishers: publishers,
            })
          }}
        />
      </FilterSection>
      <FilterSection label="Alert">
        <Dropdown
          defaultOptionText="Alert"
          options={allAlerts
            ?.map(({ _id, name }) => ({
              label: name,
              value: _id,
            }))
            .sort((a, b) => compareIgnoreCase(a.label, b.label))}
          selectedItems={state?.alerts || []}
          deselectLabel="All Alerts"
          onChange={(alerts) => setState({ ...state, alerts })}
          multiSelect
        />
      </FilterSection>
      <FilterSection label="Alert Category">
        <Dropdown
          defaultOptionText="Alert Category"
          options={allAlertCategories
            ?.map(({ _id, name }) => ({
              label: name,
              value: _id,
            }))
            .sort((a, b) => compareIgnoreCase(a.label, b.label))}
          selectedItems={state?.alertCategories || []}
          deselectLabel="All Alert Categories"
          onChange={(alertCategories) =>
            setState({ ...state, alertCategories })
          }
          multiSelect
        />
      </FilterSection>
      <FilterSection label="Clients">
        <DropdownWithSubsections
          onChange={(companies) => setState({ ...state, companies })}
          selectedItems={state?.companies || []}
          options={companiesWithBUs.sort((a, b) =>
            compareIgnoreCase(a.label, b.label)
          )}
          defaultOptionText="Clients"
          selectAllOptions={{
            label: 'All Clients',
            allSelected:
              state?.companies === undefined ||
              state?.companies?.length === companiesWithBUs?.length,
            onCheck: (checked) => onSelectAllClients(checked),
          }}
        />
      </FilterSection>
    </Filters>
  )
}

NotificationGroupFilters.propTypes = {
  allUsers: PropTypes.array,
  companiesWithBUs: PropTypes.array,
  allAlerts: PropTypes.array,
  allAlertCategories: PropTypes.array,
  tableContainer: PropTypes.string.isRequired,
  filters: PropTypes.object,
}

export default NotificationGroups
