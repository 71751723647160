import { accounts, flow } from '@decision-sciences/qontrol-common'
import { AD_OPTION_FIELD_CONDITIONS as FACEBOOK_AD_FIELDS } from 'modules/flow/components/facebook/ad/fields'
import {
  AD_OPTION_FIELD_CONDITIONS as ODAX_AD_FIELDS,
  AD_PANELS_CONDITIONS,
} from 'modules/flow/components/facebook/odax/ad/fields'

const { ACCOUNT_TYPES_MAP } = accounts
const { checkSingleCondition } = flow.facebook.placements
const { FACEBOOK, ODAX } = ACCOUNT_TYPES_MAP

/**
 *  Checks whether we should render a certain field, given the current Objective and AdSet
 *
 *  @param {String} field one of {@see AD_OPTION_FIELD_CONDITIONS}
 *  @param {Object} adSet
 *  @param {String} campaignObjective
 *  @param {String} platform one of {@see ACCOUNT_TYPES_MAP}
 */
export const shouldRenderAdOptionElement = (
  field,
  adSet,
  campaignObjective,
  platform = FACEBOOK
) => {
  let conditionsMap
  if (platform === FACEBOOK) {
    conditionsMap = FACEBOOK_AD_FIELDS
  }
  if (platform === ODAX) {
    conditionsMap = ODAX_AD_FIELDS
  }
  const renderCondition = conditionsMap[field]?.disabled
  return !renderCondition?.some((condition) =>
    checkSingleCondition({ campaignObjective, adSet }, condition)
  )
}

/**
 *  Checks whether we should render a certain fpanel, given the current Objective, AdSet and/or Ad selections
 *
 *  @param {Object} ad
 *  @param {String} field one of {@see AD_OPTION_FIELD_CONDITIONS }
 *  @param {Object} adSet
 *  @param {String} campaignObjective
 *  @param {String} platform one of {@see ACCOUNT_TYPES_MAP }
 */
export const shouldRenderAdPanel = (
  ad,
  adSet,
  campaignObjective,
  field,
  platform = ODAX
) => {
  let conditionsMap

  if (platform === ODAX) {
    conditionsMap = AD_PANELS_CONDITIONS
  }
  const renderCondition = conditionsMap[field]?.disabled

  return !renderCondition?.some((condition) =>
    checkSingleCondition({ campaignObjective, ad, adSet }, condition)
  )
}

/**
 * Clears null values of placements for legacy and odax objectives
 * Ex:
 * ad : {
 *  ...,
 *  placements: {
 *    Media: {
 *      FACEBOOK_NEWS_FEED : null,
 *      FACEBOOK_MARKETPLACE: {...}
 *    }
 *  }
 * }
 * Res:
 * ad : {
 *  ...,
 *  placements: {
 *    Media: {
 *      FACEBOOK_MARKETPLACE: {...}
 *    }
 *  }
 * }
 * @param {Array} ads Array containing ads
 * @returns {Array} Array containing the ads with the updated placements for facbook and odax ads
 */
export const clearRestoredPlacementsForAds = (ads) => {
  const modifiedAds = []
  for (const ad of ads) {
    if (
      [ACCOUNT_TYPES_MAP.ODAX, ACCOUNT_TYPES_MAP.FACEBOOK].includes(ad.type)
    ) {
      const modifiedAd = { ...ad }
      const restoredPlacementsKeys = Object.entries(
        modifiedAd.placements?.Media || []
      ).reduce((acc, [key, val]) => {
        if (!val) {
          return [...acc, key]
        }
        return acc
      }, [])
      restoredPlacementsKeys.forEach((key) => {
        delete modifiedAd.placements.Media[key]
      })
      if (
        restoredPlacementsKeys.length &&
        !Object.keys(modifiedAd.placements.Media).length
      ) {
        delete modifiedAd.placements.Media
        if (!Object.keys(modifiedAd.placements).length) {
          modifiedAd.placements = null
        }
      }
      modifiedAds.push(modifiedAd)
    } else {
      modifiedAds.push(ad)
    }
  }
  return modifiedAds
}
