import { accounts } from '@decision-sciences/qontrol-common'
import {
  CAMPAIGN_DEFAULTS as FACEBOOK_CAMPAIGN_DEFAULTS,
  ADSET_DEFAULTS as FACEBOOK_ADSET_DEFAULTS,
  AD_DEFAULTS as FACEBOOK_AD_DEFAULTS,
} from 'modules/facebook/field-map-frontend'
import {
  CAMPAIGN_DEFAULTS as ODAX_CAMPAIGN_DEFAULTS,
  ADSET_DEFAULTS as ODAX_ADSET_DEFAULTS,
  AD_DEFAULTS as ODAX_AD_DEFAULTS,
} from 'modules/odax/field-map-frontend'

import {
  CAMPAIGN_DEFAULTS as GOOGLE_CAMPAIGN_DEFAULTS,
  ADSET_DEFAULTS as GOOGLE_ADSET_DEFAULTS,
  AD_DEFAULTS as GOOGLE_AD_DEFAULTS,
} from 'modules/google/field-map-frontend'

const { ACCOUNT_TYPES_MAP } = accounts
const { FACEBOOK, GOOGLE, ODAX } = ACCOUNT_TYPES_MAP

export const INITIAL_STATES = {
  campaign: {
    [FACEBOOK]: FACEBOOK_CAMPAIGN_DEFAULTS,
    [GOOGLE]: GOOGLE_CAMPAIGN_DEFAULTS,
    [ODAX]: ODAX_CAMPAIGN_DEFAULTS,
  },
  adSet: {
    [FACEBOOK]: FACEBOOK_ADSET_DEFAULTS,
    [ODAX]: ODAX_ADSET_DEFAULTS,
    [GOOGLE]: GOOGLE_ADSET_DEFAULTS,
  },
  ad: {
    [FACEBOOK]: FACEBOOK_AD_DEFAULTS,
    [GOOGLE]: GOOGLE_AD_DEFAULTS,
    [ODAX]: ODAX_AD_DEFAULTS,
  },
}
