import Api from 'easy-fetch-api'

export const ACTIONS = {
  SET_LOADING: 'global.setLoading',
  SET_ENVIRONMENT: 'global.setEnvironment',
  SET_CONFIG: 'global.setConfig',
}

export const setGlobalLoading = (dispatch, loading) =>
  dispatch({ type: ACTIONS.SET_LOADING, loading })

export const getEnvironment = (dispatch) =>
  Api.get({ url: `/api/global/environment` })
    .then((result) => {
      if (!result || result.error || !result.data) {
        console.error('Error fetching Environment variables')
      } else {
        dispatch({ type: ACTIONS.SET_ENVIRONMENT, environment: result.data })
      }
      return result
    })
    .catch(console.error)

export const getConfig = (dispatch) => {
  Api.get({ url: `/api/global/config` })
    .then((result) => {
      if (!result || result.error || !result.data) {
        console.error('Error fetching application configuration')
      } else {
        dispatch({ type: ACTIONS.SET_CONFIG, config: result.data })
      }
      return result
    })
    .catch(console.error)
}
