import React, { useEffect, useState } from 'react'
import { useParams, redirect, useNavigate } from 'react-router-dom'
import { legal } from '@decision-sciences/qontrol-common'

/* Hooks */
import { useStore } from 'store'
import { useAccess, PERMISSIONS, PERMISSION_TYPES } from 'hooks/access'

/* Actions */
import { getLegalItemById, setCurrentEditedLegal } from 'modules/legal/actions'

/* Components */
import Loader from 'components/loader'
import StickyFooter from 'components/sticky-footer'
import { CreateEditLegalItem } from 'modules/legal/create-edit'
import { LegalItemContent } from 'modules/legal/components/legal-item-content'

/* Assets */
import { ReactComponent as Arrow } from 'assets/icon_arrow_white_leg.svg'

const { LEGAL_TYPE_VALUES } = legal

const LegalItemPage = () => {
  const { id } = useParams()

  const { state, dispatch } = useStore()

  const navigate = useNavigate()

  const {
    legal: { currentEditedLegal },
  } = state

  const [loading, setLoading] = useState(true)

  const hasViewAccess = useAccess({
    feature: PERMISSIONS.LEGAL_SETTINGS,
    type: PERMISSION_TYPES.READ,
  })

  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.LEGAL_SETTINGS,
    type: PERMISSION_TYPES.CREATE,
  })

  const hasEditAccess = useAccess({
    feature: PERMISSIONS.LEGAL_SETTINGS,
    type: PERMISSION_TYPES.EDIT,
  })

  const hasCreativeAccess = id !== 'new' ? hasEditAccess : hasCreateAccess

  /* On mount or permission change, if the user does not have access, redirect to unauthorized page */
  useEffect(() => {
    /* If it is create and does not have create access OR tries to edit and does not have edit access */
    if (!hasViewAccess) {
      redirect('/unauthorized')
    }
  }, [hasViewAccess])

  /** On component mount or ID change, check if data refresh is needed and set the current edited Legal Item */
  useEffect(() => {
    let shouldRefreshStore = !currentEditedLegal
    if (currentEditedLegal) {
      const { _id, isNew } = currentEditedLegal
      shouldRefreshStore = (id === 'new' && !isNew) || id !== _id
    }
    if (shouldRefreshStore) {
      if (id === 'new') {
        setCurrentEditedLegal(dispatch, {
          id: null,
          name: '',
          type: LEGAL_TYPE_VALUES[0].value,
          displayArea: [],
          sections: [],
          isNew: true,
          isDeletable: true,
        })
        setLoading(false)
      } else {
        getLegalItemById(dispatch, id).finally(() => setLoading(false))
      }
    } else {
      setLoading(false)
    }
  }, [id])

  useEffect(() => {
    setLoading(!currentEditedLegal)
  }, [currentEditedLegal])

  if (loading) {
    return <Loader />
  }
  return (
    <section className="legal-item-page">
      {hasCreativeAccess ? (
        <CreateEditLegalItem id={id} />
      ) : (
        <>
          <LegalItemContent
            classes="legal-item-page__view"
            withTimestamp
            item={currentEditedLegal}
          />
          <StickyFooter
            buttons={[
              {
                value: (
                  <div className="display-flex gap-8 align-items-center">
                    <Arrow style={{ transform: 'rotate(180deg)' }} />
                    Back
                  </div>
                ),
                onClick: () => navigate('/legal/cms'),
                secondary: true,
                compact: true,
              },
            ]}
          />
        </>
      )}
    </section>
  )
}

export { LegalItemPage }
