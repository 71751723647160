import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useOutletContext } from 'react-router-dom'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { useSocket } from 'components/utils/socket'
import Section from 'components/section/index'
import RoundRectangleButton from 'components/round-rectangle-button/index'
import Button from 'components/button/index'
import Icon from 'components/icon/index'

import {
  getExcludedCampaigns,
  exportCampaigns,
} from 'modules/companies/actions'

// Icons
import { ReactComponent as DownloadIcon } from 'assets/icon_download.svg'
import { ReactComponent as IconFilter } from 'assets/filters.svg'
import { ReactComponent as IconSearch } from 'assets/icon_search.svg'
import { ReactComponent as IconWarning } from 'assets/icon_warning.svg'

import { accounts, socket } from '@decision-sciences/qontrol-common'

import ExcludedCampaignsFilter from './excluded-campaigns-filter'
import ExcludedCampaignsTable from './excluded-campaigns-table'

import './style.scss'

const { ACCOUNT_TYPE_NAMES } = accounts
const { CACHING_DONE_FOR } = socket

/**
 * Excluded Campaigns Section
 * Contains Campaigns table and filters for it.
 * @param {Object} params React Params
 * @param {Array} params.exclusions Client's exclusions
 * @param {String} params.clientId Client's _id
 * @param {Array} params.accounts Client's accounts
 */
const ExcludedCampaigns = ({ exclusions, accounts, clientId }) => {
  const { flags, setFlags } = useOutletContext()
  const [filtersOpened, setFiltersOpened] = useState(false)
  const [filterValues, setFilterValues] = useState(null)
  const [appliedFilters, setAppliedFilters] = useState({})

  const [sectionExpanded, setSectionExpanded] = useState(false)
  const [loading, setLoading] = useState(false)

  const [campaigns, setCampaigns] = useState([])
  const [total, setTotal] = useState(0)
  const [paginationData, setPaginationData] = useState({
    pageSize: 10,
    pageIndex: 0,
  })

  const availablePublishers = useMemo(() => {
    return [...new Set(accounts.map(({ type }) => type))].map((value) => ({
      value,
      label: ACCOUNT_TYPE_NAMES[value],
    }))
  }, [JSON.stringify(accounts)])

  useEffect(() => {
    if (flags[clientId]?.[CACHING_DONE_FOR.CAMPAIGN_EXCLUSIONS]) {
      loadExcludedCampaign()
      setFlags(clientId, {
        [CACHING_DONE_FOR.CAMPAIGN_EXCLUSIONS]: false,
      })
    }
  }, [flags[clientId]?.[CACHING_DONE_FOR.CAMPAIGN_EXCLUSIONS]])

  const loadExcludedCampaign = useCallback(() => {
    if (!sectionExpanded) {
      return
    }

    setLoading(true)
    getExcludedCampaigns(clientId, {
      limit: paginationData.pageSize,
      page: paginationData.pageIndex,
      filters: appliedFilters,
      sorting: paginationData.sort || {},
    })
      .then(({ list, count }) => {
        setCampaigns(list)
        setTotal(count)
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [
    JSON.stringify(appliedFilters),
    JSON.stringify(paginationData),
    sectionExpanded,
  ])
  // Fetch campaigns based on appliedFilters and pagination data (page number, items per page, etc)
  useEffect(() => {
    loadExcludedCampaign()
  }, [
    JSON.stringify(appliedFilters),
    JSON.stringify(paginationData),
    sectionExpanded,
  ])

  const _renderBarButtons = () => {
    return (
      <div className="margin-left-16 align-row align-items-center gap-16">
        {flags[clientId]?.excludedCampaignsCaching && (
          <Icon tooltip="Excluded Campaign list might be temporarily out of date due to changes made to Campaign Exclusions.">
            <IconWarning />
          </Icon>
        )}
        <RoundRectangleButton
          contentRender={() => <DownloadIcon />}
          disabled={loading || !sectionExpanded}
          showIconUpsideDown={true}
          onClick={() =>
            exportCampaigns(clientId, {
              sorting: paginationData.sort || {},
              filters: appliedFilters,
            })
          }
          contentClassName="keywords-table-title-action-icon-reversed"
        />

        <RoundRectangleButton
          className={cx({
            'filter-active': filtersOpened,
          })}
          onClick={() => {
            setFiltersOpened(!filtersOpened)
          }}
          disabled={loading || !sectionExpanded}
          contentRender={() => (
            <IconFilter
              className={filtersOpened ? 'fill-white' : 'fill-light-blue'}
            />
          )}
          contentClassName="keywords-table-title-action-icon"
        />
      </div>
    )
  }

  const renderSectionContent = () => {
    const display = []
    if (filtersOpened) {
      display.push(
        <ExcludedCampaignsFilter
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          availableExclusions={exclusions}
          availableAccounts={accounts.map(
            ({ name, type, _id, externalAccountId }) => ({
              key: _id,
              value: _id,
              label: name,
              type,
              accountId: externalAccountId,
            })
          )}
          availablePublishers={availablePublishers}
        />,
        <Button
          compact
          value={
            <div className="align-center gap-8">
              <IconSearch width={16} height={16} className="fill-white" />
              <span>Apply search</span>
            </div>
          }
          onClick={() => setAppliedFilters(filterValues)}
        />
      )
    }

    display.push(
      <ExcludedCampaignsTable
        campaigns={campaigns}
        total={total}
        paginationData={paginationData}
        setPaginationData={setPaginationData}
        loading={loading}
      />
    )

    return display
  }

  return (
    <Section
      isExpandable
      defaultExpanded={sectionExpanded}
      header={
        <h3
          data-cy="campaign-exclusion-header"
          className="generic-heading generic-heading--no-margin display-flex space-between align-items-center"
        >
          Excluded Campaigns {_renderBarButtons()}
        </h3>
      }
      onExpand={setSectionExpanded}
    >
      {renderSectionContent()}
    </Section>
  )
}

ExcludedCampaigns.propTypes = {
  exclusions: PropTypes.array.isRequired,
  clientId: PropTypes.string.isRequired,
  accounts: PropTypes.array.isRequired,
}

export default ExcludedCampaigns
