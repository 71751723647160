import Api from 'easy-fetch-api'
import { showErrorMessage } from 'modules/notifications/actions'

/**
 * Run end-to-end alert execution test
 * @param {Function} dispatch
 * @param {String} alertId Alert ID to run test on
 * @param {Array<String>} accountIds External Account IDs to run test on
 * @param {Boolean} dontSendConventionalNotifications Don's send conventional notifications set on the alert
 */
export const run = (
  dispatch,
  alertId,
  accountIds,
  dontSendConventionalNotifications
) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: `/api/test/run-e2e-test`,
      data: { alertId, accountIds, dontSendConventionalNotifications },
    }).then((result) => {
      if (!result || !result.success) {
        const error = `Error running alert execution test`
        showErrorMessage(error, dispatch)
        return reject(error)
      }

      return resolve(result)
    })
  })
}
