import { utils } from '@decision-sciences/qontrol-common'

import {
  CUSTOM_COLUMNS_ITEM_PLACEMENT,
  CUSTOM_COLUMNS_EXTERNAL_ACTIONS,
  CUSTOM_COLUMNS_DND_ORIGINS,
} from 'modules/custom-columns/constants'
import { TABLEAU_SHEETS } from 'modules/global-reports/utils'

const { capitalize } = utils.string

export const ACTIONS = {
  TOGGLE_CUSTOM_COLUMNS: 'customColumns.toggleCustomColumn',
  TOGGLE_PAUSE_AUTOMATIC_LOADING: 'customColumns.togglePauseAutomaticLoading',
  SET_PAUSE_AUTOMATIC_LOADING: 'customColumns.setPauseAutomaticLoading',
  SET_LOADING: 'customColumns.setLoading',
  SEARCH_LIBRARY_ITEMS: 'customColumns.searchLibraryItems',
  ADD_ROW: 'customColumns.addRow',
  ADD_COLUMN: 'customColumns.addColumn',
  REPLACE_ROW: 'customColumns.replaceRow',
  REMOVE_COLUMN: 'customColumns.removeColumn',
  SET_REPORT_SHEET: 'customColumns.setReportSheet',
  SET_ITEMS: 'customColumns.setItems',
  EXECUTE_EXTERNAL_ACTION: 'customColumns.executeExternalAction',
  EXTERNAL_ACTION_FINISHED: 'customColumns.externalActionFinished',
  INSERT_AFTER_ITEM: 'customColumns.insertAfterItem',
  MOVE_ITEM: 'customColumns.moveItem',
  SET_PENDING_COLUMNS: 'customColumns.setPendingColumns',
  RESET_PENDING_COLUMNS: 'customColumns.resetPendingColumns',
  RESET_PENDING_ROWS: 'customColumns.resetPendingRows',
}

/**
 * Toggle custom columns section
 * @param {Function} dispatch Dispatch function
 */
export const toggleCustomColumns = (dispatch) => {
  dispatch({
    type: ACTIONS.TOGGLE_CUSTOM_COLUMNS,
  })
}

/**
 * Set loading state
 * @param {Function} dispatch Dispatch function
 * @param {Boolean} loading Loading value to set
 */
export const setLoading = (dispatch, loading) => {
  dispatch({
    type: ACTIONS.SET_LOADING,
    loading,
  })
}

/**
 * Sets pending columns that need to be sent to Tableau report
 * @param {Function} dispatch Dispatch function
 */
export const setPendingColumns = (dispatch) => {
  dispatch({
    type: ACTIONS.SET_PENDING_COLUMNS,
  })
}

/**
 * Resets pending columns
 * @param {Function} dispatch Dispatch function
 */
export const resetPendingColumns = (dispatch) => {
  dispatch({
    type: ACTIONS.RESET_PENDING_COLUMNS,
  })
}

/**
 * Resets pending columns
 * @param {Function} dispatch Dispatch function
 */
export const resetPendingRows = (dispatch) => {
  dispatch({
    type: ACTIONS.RESET_PENDING_ROWS,
  })
}

/**
 * Toggle pause automatic loading
 * @param {Function} dispatch Dispatch function
 */
export const togglePauseAutomaticLoading = (dispatch) => {
  dispatch({
    type: ACTIONS.TOGGLE_PAUSE_AUTOMATIC_LOADING,
  })
}

/**
 * Toggle pause automatic loading
 * @param {Function} dispatch Dispatch function
 */
export const setPauseAutomaticLoading = (dispatch, value) => {
  dispatch({
    type: ACTIONS.SET_PAUSE_AUTOMATIC_LOADING,
    value,
  })
}

/**
 * Search library items for search term
 * @param {Function} dispatch Dispatch function
 * @param {String} searchTerm Search term
 */
export const searchLibraryItems = (dispatch, searchTerm) => {
  dispatch({
    type: ACTIONS.SEARCH_LIBRARY_ITEMS,
    query: searchTerm,
  })
}

/**
 * Function which will add an entity to rows ro columns
 * @param {Function} dispatch Dispatch function
 * @param {Object} item Dimension or metric to add
 */
export const addItem = (dispatch, item) => {
  let action
  switch (item.placement) {
    case CUSTOM_COLUMNS_ITEM_PLACEMENT.ROW:
      action = ACTIONS.REPLACE_ROW
      break
    case CUSTOM_COLUMNS_ITEM_PLACEMENT.COLUMN:
      action = ACTIONS.ADD_COLUMN
      break
  }
  dispatch({ type: action, item })
}

/**
 * Function which will insert an entity to rows or columns after an existing item
 * @param {Function} dispatch Dispatch function
 * @param {Object} item Dimension or metric to insert
 * @param {Object} previousItem Dimension or metric after which to insert item
 */
export const inserAfterItem = (dispatch, item, previousItem) => {
  dispatch({ type: ACTIONS.INSERT_AFTER_ITEM, item, previousItem })
}

/**
 * Function which will move an entity after an existing item
 * @param {Function} dispatch Dispatch function
 * @param {Object} item Dimension or metric to insert
 * @param {Object} previousItem Dimension or metric after which to insert item
 */
export const moveItem = (dispatch, item, previousItem) => {
  dispatch({ type: ACTIONS.MOVE_ITEM, item, previousItem })
}

/**
 * Function which will remove an entity from rows or columns
 * @param {Function} dispatch Dispatch function
 * @param {Object} item Dimension or metric to add
 */
export const removeItem = (dispatch, item) => {
  let action
  switch (item.placement) {
    case CUSTOM_COLUMNS_ITEM_PLACEMENT.ROW:
      action = ACTIONS.REPLACE_ROW
      break
    case CUSTOM_COLUMNS_ITEM_PLACEMENT.COLUMN:
      action = ACTIONS.REMOVE_COLUMN
      break
  }
  dispatch({ type: action, item })
}

/**
 * Set sheet from report
 * @param {Function} dispatch Dispatch function
 * @param {String} sheet Report sheet to set
 */
export const setReportSheet = (dispatch, sheet) => {
  dispatch({
    type: ACTIONS.SET_REPORT_SHEET,
    sheet,
  })
}

/**
 * Set items for report sheet
 * @param {Function} dispatch Dispatch function
 * @param {String} sheet Report sheet to set
 * @param {Array} items Items for the report
 */
export const setItems = async (dispatch, sheet, items) => {
  dispatch({
    type: ACTIONS.SET_ITEMS,
    sheet,
    items,
  })
}

/**
 * Function which will mark the finish of an external action
 * @param {Function} dispatch Dispatch function
 */
export const externalActionFinished = (dispatch) => {
  dispatch({
    type: ACTIONS.EXTERNAL_ACTION_FINISHED,
  })
}

/**
 * Function for initializing the data for different report sheets
 * @param {Function} dispatch Dispatch function
 * @param {Object} param Action parameters
 * @param {String} param.sheet Report sheet for which we want to initialise the column selection data
 * @param {Object} param.data Object containing the data need for initialization
 */
export const setColumnsSelectionData = (dispatch, { sheet, data }) => {
  const items = {}
  switch (sheet) {
    case TABLEAU_SHEETS.KPI_TRENDS:
      {
        if (data.selectedDateGranularity) {
          items.rows = [
            {
              dimension: {
                name: capitalize(
                  data.selectedDateGranularity.label.toLowerCase()
                ),
              },
              accept: CUSTOM_COLUMNS_DND_ORIGINS.ROW,
              placement: CUSTOM_COLUMNS_ITEM_PLACEMENT.ROW,
              _id: data.selectedDateGranularity.value,
              isExternal: true,
              originalItem: data.selectedDateGranularity,
              origin: CUSTOM_COLUMNS_DND_ORIGINS.ROW,
            },
          ]
        }
      }
      break
    case TABLEAU_SHEETS.PERIOD_COMPARISONS:
      {
        items.rows = [
          {
            dimension: {
              name: 'Date range',
            },
            accept: CUSTOM_COLUMNS_DND_ORIGINS.ROW,
            placement: CUSTOM_COLUMNS_ITEM_PLACEMENT.ROW,
            _id: 'date-range',
            isExternal: true,
            action: () => {
              dispatch({
                type: ACTIONS.EXECUTE_EXTERNAL_ACTION,
                action: CUSTOM_COLUMNS_EXTERNAL_ACTIONS.OPEN_DATE_RANGE_MODAL,
              })
            },
          },
        ]
      }
      break
  }

  if (data.availableItems) {
    items.availableItems = [...(data.availableItems || [])]
    items.data = [...(data.availableItems || [])]
  }

  setItems(dispatch, sheet, { ...data, ...items })
}
