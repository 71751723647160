import React from 'react'
import PropTypes from 'prop-types'

import { utils, accounts } from '@decision-sciences/qontrol-common'

const { capitalize } = utils.string
const { ACCOUNT_TYPE_NAMES } = accounts

/**
 * Convenient way to render the rule's header
 * @param {Object} props
 * @param {Object} props.publisher - The publisher for which we want to render the header
 */
const RuleHeader = ({ publisher }) => {
  const ProviderIcon = publisher.icon

  return (
    <div className="campaign-exclusions-rule-title">
      <ProviderIcon />
      <div className="campaign-exclusions-rule-subtitle">
        {publisher.id !== 'GLOBAL'
          ? capitalize(ACCOUNT_TYPE_NAMES[publisher.id])
          : publisher.name}
      </div>
    </div>
  )
}

export default RuleHeader

RuleHeader.propTypes = {
  publisher: PropTypes.object.isRequired,
}
