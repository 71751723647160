import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import './style.scss'

const Spacer = ({ className, small, big, light, margin }) => {
  return (
    <div
      data-testid='spacer'
      style={{ margin }}
      className={cx('spacer', {
        [className]: className,
        'spacer--small': small,
        'spacer--big': big,
        'spacer--light': light,
      })}
    />
  )
}

Spacer.propTypes = {
  className: PropTypes.string,
  small: PropTypes.bool,
  big: PropTypes.bool,
  light: PropTypes.bool,
  margin: PropTypes.string,
}

export default Spacer
