import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import cx from 'classnames'
import { useStore } from 'store'

// Components
import Button from 'components/button'
import AuthenticationContainer from 'components/authentication'

import {
  setUserData,
  logout,
  getTempUserData,
  removeTempUser,
  updateUserFromSignUp,
  updateUserTimestampLegalTerms,
} from 'modules/session/actions'
import { getLegalViewItems } from 'modules/legal/actions'

import { legal } from '@decision-sciences/qontrol-common'

const { LEGAL_TYPES, LEGAL_DISPLAY_AREA } = legal

const termsToken = 'trmToken'

const TermsOfUsePage = () => {
  const { dispatch, state } = useStore()
  const { userId } = useParams()
  const navigate = useNavigate()
  const {
    legal: { termsOfUse },
  } = state

  const [submitError, setSubmitError] = useState(null)
  const [disabledBtn, setDisabledBtn] = useState(true)
  const [token, setToken] = useState(true)
  const [userTemp, setUser] = useState(true)

  const checkForScrollBar = () => {
    const div = document.getElementById(termsToken)
    setDisabledBtn(div.scrollHeight > div.clientHeight)
  }

  const checkScrollPosition = (ev) => {
    ev.preventDefault()
    const content = document.getElementById(termsToken)
    const { scrollTop, scrollHeight, offsetHeight } = content

    const shouldBeDisabled = scrollHeight - offsetHeight - scrollTop > 1

    if (shouldBeDisabled !== disabledBtn) {
      setDisabledBtn(shouldBeDisabled)
    }
  }

  /** On mount, log the user out */
  useEffect(() => {
    if (!userId) {
      logout(dispatch, true)
    }
  }, [])

  useEffect(() => {
    if (!termsOfUse) {
      getLegalViewItems(dispatch, LEGAL_DISPLAY_AREA.LOGIN)
    }
    const { token, userTemp } = getTempUserData()
    if (token && userTemp) {
      setToken(token)
      setUser(userTemp)
    } else {
      navigate('/')
      return
    }
    if (
      termsOfUse &&
      termsOfUse.type === LEGAL_TYPES.DOCUMENT_WITHOUT_SECTIONS
    ) {
      const content = legalSection(termsOfUse.name, termsOfUse.content)
      document.getElementById(termsToken).appendChild(content)
    }
    if (termsOfUse && termsOfUse.type === LEGAL_TYPES.DOCUMENT_WITH_SECTIONS) {
      termsOfUse.sections.forEach((item) => {
        const content = legalSection(item.name, item.content)
        document.getElementById(termsToken).appendChild(content)
      })
    }
    checkForScrollBar()
  }, [termsOfUse])

  const legalSection = (title, content) => {
    const div = document.createElement('div')
    div.innerHTML = `<h1>${title}</h1>${content}`
    return div
  }

  const goBack = () => {
    removeTempUser()
    navigate(-1)
  }

  const handleSubmit = (ev) => {
    ev.preventDefault()
    setDisabledBtn(true)
    removeTempUser()

    let action = updateUserFromSignUp
    let data = { ...userTemp, _id: token, active: true }

    if (userId) {
      action = updateUserTimestampLegalTerms
      data = { ...data, userId }
    }

    action(data)
      .then((res) => {
        if (!res.success || res.error || !res.user) {
          return setSubmitError(
            res.error || 'Something went wrong. Please try again.'
          )
        }

        if (res.user && userId) {
          dispatch(setUserData(res.user))
          return navigate('/')
        }

        if (res.user.tfaSecret) {
          setTimeout(() => {
            navigate('/login')
          }, 50)
        } else {
          setTimeout(() => {
            dispatch(setUserData(res.user))
            navigate('/multi-factor-setup')
          }, 50)
        }
      })
      .catch((err) => {
        console.error(err)
        setSubmitError(err)
      })
  }
  return (
    <AuthenticationContainer
      title="Terms of Use"
      onSubmit={handleSubmit}
      error={submitError}
    >
      <div
        id={termsToken}
        className="standard-container max-height"
        onScroll={checkScrollPosition}
      ></div>
      <div className="align-row">
        <div
          className="authentication-container__forget align-center"
          style={{ marginRight: 'auto' }}
        >
          <a onClick={goBack}>Cancel</a>
        </div>
        <Button
          className={cx('authentication-container__button', {
            'light-grey-disabled': disabledBtn,
          })}
          value="Accept Terms of Use"
          onClick={handleSubmit}
          disabled={disabledBtn}
          big
          type="submit"
        />
      </div>
    </AuthenticationContainer>
  )
}
export default TermsOfUsePage

