/**
 * Calculates and sets the direction the dropdown has to open: up / down
 * @param {Boolean} listOpened
 * @param {Object} dropdownRef
 * @param {Number} dropdownHeight height of the dropdown
 * @param {Object} optionsRef To be provided for DropdownList
 */
export const calculateListPosition = (
  listOpened,
  dropdownRef,
  dropdownHeight,
  optionsRef
) => {
  // Before the list gets opened
  if (!listOpened) {
    let positionOnPage
    let bottom
    if (dropdownRef?.current) {
      positionOnPage = dropdownRef.current.getBoundingClientRect()
      bottom = positionOnPage?.bottom
    } else if (optionsRef?.current) {
      positionOnPage = optionsRef.current.getBoundingClientRect()
      bottom = 0
    }
    const top = positionOnPage?.top
    if (top && typeof bottom === 'number') {
      // Since the dropdown is not open yet, we're using the fixed max height of the dropdown in the calculations
      const height = parseInt(dropdownHeight, 10)
      const stickyFooterHeightApproximation = 74
      const headerHeightApproximation = 91 // 56 header height + 35 padding on most pages
      let lastAvailablePositionDownwards =
        window.innerHeight - stickyFooterHeightApproximation
      let availableSpaceUpwards = top - headerHeightApproximation

      let parentTable = null
      for (const tableClassName of SCROLLABLE_TABLES_WITH_DROPDOWNS) {
        if (parentTable) {
          break
        }
        parentTable = dropdownRef.current.closest(tableClassName)
      }

      if (parentTable) {
        const { top: tableTop, bottom: tableBottom } =
          parentTable.getBoundingClientRect()
        lastAvailablePositionDownwards = tableBottom
        availableSpaceUpwards = top - tableTop
      }

      const availableSpaceDownwards = lastAvailablePositionDownwards - bottom

      // Decision whether to display upwards or not
      return (
        bottom + height > lastAvailablePositionDownwards &&
        (availableSpaceUpwards > height ||
          // In case the dropdown wouldn't completely fit upwards but there's still more space than downwards
          availableSpaceUpwards > availableSpaceDownwards)
      )
    }
  } else {
    // Reset so that next time we open the dropdown the algorithm has time to calculate before showing content.
    return false
  }
}

const SCROLLABLE_TABLES_WITH_DROPDOWNS = [
  '.table',
  '.import-users-table',
  '.conversion-groups-table',
]
