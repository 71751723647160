// Retrieve Reportings from company or bussiness units
const getCurrentCompanyReportings = (currentCompanyId, companies) => {
  const currentCompanyWithReportings = companies?.find(
    (company) =>
      company._id === currentCompanyId ||
      company.businessUnits.some(({ _id }) => _id === currentCompanyId)
  )

  if (!currentCompanyWithReportings) {
    return null
  }

  if (currentCompanyWithReportings._id === currentCompanyId) {
    return currentCompanyWithReportings.reportings
  } else {
    const currentBuCompany = currentCompanyWithReportings.businessUnits?.find(
      (bu) => bu._id === currentCompanyId
    )
    return currentBuCompany ? currentBuCompany.reportings : null
  }
}

export { getCurrentCompanyReportings }
