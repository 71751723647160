import { flow } from '@decision-sciences/qontrol-common'
const {
  OBJECTIVES_MAP_ARRAY,
  ODAX_OBJECTIVES_MAP_ARRAY,
  DETAILED_TARGETING_FILTER,
  OBJECTIVES_MAP_LABELS,
  ODAX_OBJECTIVES_MAP_LABELS,
  GENDER_TYPES,
  ADSET_DESTINATION_TYPE,
  OBJECTIVES_MAP,
  WHATSAPP_DEFAULT_WEBSITE_URL,
  MESSENGER_DEFAULT_WEBSITE_URL,
  FILTER_OPTIONS_SPECIAL_LOCATION_VALUES,
} = flow.facebook

const {
  MESSENGER: { value: MESSENGER },
  APP: { value: APP },
  WHATSAPP: { value: WHATSAPP },
} = ADSET_DESTINATION_TYPE

/** This file is for Generic Facebook-related stuff (brief related or non-brief related) that are only needed on the FE */

// this is the dropdown data for Update Selection dropdown on define brief page
// tried to connect the values with the keys in CAMPAIGN_DEFAULTS
// this will matter when showing different campaign sections to be updated
export const SELECTION_CRITERIA_CAMPAIGNS = [
  { label: 'Ad Schedule', value: 'Ad Schedule' },
  { label: 'Bidding', value: 'Bidding' },
  { label: 'Budget', value: 'Budget' },
  { label: 'Content', value: 'Content' },
  { label: 'Conversions', value: 'Conversions' },
  { label: 'Locations', value: 'Locations' },
  { label: 'Placements', value: 'Placements' },
  { label: 'Platforms', value: 'Platforms' },
  { label: 'Targeting', value: 'Targeting' },
]

export const AUDIENCES_TYPES = ['SAVED', 'CUSTOM', 'LOOKALIKE']

export const FILTER_OPTIONS_LOCATIONS = [
  {
    label: 'Exclude Location',
    value: 'excluded_geo_locations',
  },
  {
    label: 'Include All Areas',
    value: 'geo_locations',
  },
]

export const FILTER_OPTIONS_SPECIAL_LOCATIONS = [
  {
    label: 'Only Include Cities',
    value: FILTER_OPTIONS_SPECIAL_LOCATION_VALUES.MULTI_CITY_INCLUDE,
  },
  {
    label: 'Exclude Cities',
    value: FILTER_OPTIONS_SPECIAL_LOCATION_VALUES.MULTI_CITY_EXCLUDE,
  },
]

export const CONNECTION_AUDIENCE_TABS = {
  fbPages: 'fbPages',
  apps: 'apps',
  events: 'events',
  advanced: 'advanced',
}

export const ODAX_CONNECTION_AUDIENCE_TABS = {
  facebook_pages: 'facebook_pages',
  apps: 'apps',
  events: 'events',
  advanced: 'advanced',
}

export const CONNECTION_AUDIENCE_TEXTS = {
  fbPages: {
    connections: 'People who like',
    friends_of_connections: 'Friends of people who like',
    excluded_connections: 'Exclude people who like',
  },
  apps: {
    connections: 'People who used',
    friends_of_connections: 'Friends of people who used',
    excluded_connections: 'Exclude people who used',
  },
  events: {
    connections: 'People who are going to',
    friends_of_connections: 'Friends of people who are going to',
    excluded_connections: 'Exclude people who are going to',
  },
}

export const ODAX_CONNECTION_AUDIENCE_TEXTS = {
  facebook_pages: {
    connections: 'People who like',
    friends_of_connections: 'Friends of people who like',
    excluded_connections: 'Exclude people who like',
  },
  apps: {
    connections: 'People who used',
    friends_of_connections: 'Friends of people who used',
    excluded_connections: 'Exclude people who used',
  },
  events: {
    connections: 'People who are going to',
    friends_of_connections: 'Friends of people who are going to',
    excluded_connections: 'Exclude people who are going to',
  },
}

/** When Special Ad Categories are set for a Campaign, certain Audience settings have to be limited */
export const SPECIAL_AD_CATEGORIES_AUDIENCE_LIMITS = {
  age: { min: '18', max: '65+' },
  // All genders or no gender
  gender: GENDER_TYPES[0].value,
  location: {
    minRadius: '15',
    allowedCityTypes: ['radius'],
    types: [
      'subcity',
      'neighborhood',
      'metro_area',
      'small_geo_area',
      'subneighborhood',
      'electoral_district',
      'zip',
    ],
    // Unsupported filters
    filters: [
      FILTER_OPTIONS_LOCATIONS[0].value,
      FILTER_OPTIONS_SPECIAL_LOCATIONS[1].value,
    ],
  },
  detailed_targeting: {
    // Unsupported filters
    filters: [DETAILED_TARGETING_FILTER.EXCLUDE],
    types: ['Behaviors', 'Demographics'],
  },
  isLookalike: false,
}

export const AD_DESTINATION_TYPE = {
  APP: 'APP',
  STORE_LOCATOR: 'STORE_LOCATOR',
  WEBSITE_URL: 'WEBSITE_URL',
  INSTANT_EXPERIENCE: 'INSTANT_EXPERIENCE',
  PLAYABLE_SOURCE: 'PLAYABLE_SOURCE',
  MANUAL_DESTINATION: 'MANUAL_DESTINATION',
  DYNAMIC_DESTINATION: 'DYNAMIC_DESTINATION',
  FACEBOOK_EVENT: 'FACEBOOK_EVENT',
}

export const FB_OBJECTIVE_CATEGORIES_KEY = {
  Awareness: 'Awareness',
  Consideration: 'Consideration',
  Conversions: 'Conversions',
}

export const FB_OBJECTIVE_CATEGORIES = [
  { label: 'Awareness', value: FB_OBJECTIVE_CATEGORIES_KEY.Awareness },
  { label: 'Consideration', value: FB_OBJECTIVE_CATEGORIES_KEY.Consideration },
  { label: 'Conversions', value: FB_OBJECTIVE_CATEGORIES_KEY.Conversions },
]

export const OBJECTIVES_WITH_LABEL = [
  ...OBJECTIVES_MAP_ARRAY.map((el) => ({
    label: OBJECTIVES_MAP_LABELS[el],
    value: el,
  })),
]

export const ODAX_OBJECTIVES_WITH_LABEL = [
  ...ODAX_OBJECTIVES_MAP_ARRAY.map((el) => ({
    label: ODAX_OBJECTIVES_MAP_LABELS[el],
    value: el,
  })),
]
export const LOCATION_TYPES = [
  {
    label: 'People living in or recently in this location',
    value: 'home#recent',
    description:
      'Whose home or most recent location is within the selected area',
  },
  {
    label: 'People living in this location',
    value: 'home',
    description: 'Whose home is within the selected area',
  },
  {
    label: 'People recently in this location',
    value: 'recent',
    description: 'Whose most recent location is within the selected area',
  },
  {
    label: 'People traveling in this location',
    value: 'travel_in',
    description:
      'Whose most recent location is within the selected area, but whose home is more then 125 mi/200 km away.',
  },
]

export const OBJECT_STORE_URLS = {
  AMAZON: 'amazon_app_store',
  CANVAS: 'fb_canvas',
  GAMEROOM: 'fb_gameroom',
  ANDROID: 'google_play',
  INSTANT_GAME: 'instant_game',
  IPHONE: 'itunes',
  IPAD: 'itunes_ipad',
  WINDOWS: 'windows_10_store',
}

export const INITIAL_URL_PARAMS = {
  buildCustomParams: false,
  dynamicUrlParams: {},
  dynamicUrl: '',
  customUrlParams: [{ id: 0, name: '', value: '' }],
}

export const ODAX_INITIAL_URL_PARAMS = {
  build_custom_params: false,
  dynamic_url_params: {},
  dynamic_url: '',
  custom_url_params: [{ id: 0, name: '', value: '' }],
}

export const BUDGET_TYPE_OPTIONS = [
  { label: 'Daily Budget', value: 'Daily Budget' },
  { label: 'Lifetime Budget', value: 'Lifetime Budget' },
]

/**
 * Returns the default Website URL value for a given configuration
 * @param {Object} options options
 * @param {Object} options.campaign campaign object
 * @param {Object} options.adSet adSet object
 * @returns {String} Website URL value
 */
export const getDefaultWebsiteUrl = ({ campaign, adSet }) => {
  const { TRAFFIC, APP_INSTALLS, MESSAGES, CONVERSIONS } = OBJECTIVES_MAP

  const adSetDestination =
    adSet.adSetOptions.driveTrafficTo?.[
      adSet.adSetOptions.driveTrafficTo?.length - 1
    ]

  const conversionAdSetDestination = adSet.adSetOptions?.conversionEvent?.type

  const campaignObjective = campaign?.data?.objective

  const messengerDesination = adSetDestination === MESSENGER
  const whatsappDestination = adSetDestination === WHATSAPP

  switch (campaignObjective) {
    case TRAFFIC: {
      if (messengerDesination) {
        return MESSENGER_DEFAULT_WEBSITE_URL
      }

      if (adSetDestination === APP) {
        return adSet.adSetOptions.conversionEvent?.objectStoreUrl
      }

      break
    }

    case APP_INSTALLS: {
      return adSet.adSetOptions.appToPromote?.objectStoreUrl
    }

    case MESSAGES: {
      if (messengerDesination) {
        return MESSENGER_DEFAULT_WEBSITE_URL
      }

      if (whatsappDestination) {
        return WHATSAPP_DEFAULT_WEBSITE_URL
      }

      break
    }

    case CONVERSIONS: {
      if (conversionAdSetDestination === MESSENGER) {
        return MESSENGER_DEFAULT_WEBSITE_URL
      }

      if (conversionAdSetDestination === APP) {
        return adSet.adSetOptions.conversionEvent?.objectStoreUrl
      }

      if (conversionAdSetDestination === WHATSAPP) {
        return WHATSAPP_DEFAULT_WEBSITE_URL
      }

      break
    }
  }

  return null
}

/**
 * We have different types of rendering for the destination panel based on the
 * campaign objective. Thus, we also need to decide the default destination type
 * based on that.
 */
export const DEFAULT_DESTINATION_TYPE_BY_CAMPAIGN_OBJECTIVE = {
  [OBJECTIVES_MAP.APP_INSTALLS]: AD_DESTINATION_TYPE.APP,
  [OBJECTIVES_MAP.CONVERSIONS]: AD_DESTINATION_TYPE.WEBSITE_URL,
}

/* Values used for the primary or secondary destination dropdowns */
export const PRIMARY_SECONDARY_DESTINATION_OPTIONS = [
  {
    label: 'App',
    value: 'APP',
  },
  {
    label: 'Website',
    value: 'WEBSITE',
  },
]
