import React from 'react'
import PropTypes from 'prop-types'

import DropdownWithSubsections from 'components/dropdown-with-subsections/index'

import { accounts } from '@decision-sciences/qontrol-common'

const { ACCOUNT_TYPE_NAMES } = accounts

/**
 * Simple group of Account Selector and Element selector that's present on all Platform Apply To sections
 * @param {Object} params React Params
 * @param {String} params.platform Platform key, used for dropdown labels
 * @param {Object} params.state selectedElements[platform]
 * @param {Function} params.onChange Call with new state
 * @param {Function} params.onChangeAccounts Callback for changing accounts
 * @param {Array} params.availableAccounts Usable accounts for platform
 * @param {Array} params.availableElements Usable elements for platform
 * @param {Object} params.elementConfig Map of each element type and its corresponding array Eg. CAMPAIGN: campaigns
 * @param {Object} params.errors element errors
 * @param {Function} params.setErrors error changing callback
 * @param {Boolean} params.readOnly Toggle between read/edit modes
 * @returns
 */
export const SinglePerformanceAccountRow = ({
  platform,
  state,
  onChange,
  onChangeAccounts,
  availableAccounts,
  availableElements,
  elementConfig,
  errors,
  setErrors,
  readOnly,
}) => {
  // Callback for changing elements, also initializes new values
  const onChangeElements = (elements) => {
    const newState = {
      accounts: state.accounts || [],
      allAccountsSelected: state.allAccountsSelected || false,
      elements,
    }

    elements.forEach((element) => {
      if (!state[element]) {
        // Clean slate for new elements
        newState[element] = {
          [elementConfig[element]]: [],
          allSelected: false,
        }
      } else {
        newState[element] = state[element]
      }
    })

    setErrors({ ...errors, elements: null })
    onChange(newState)
  }

  return (
    <div className="sc-apply-to__row">
      <DropdownWithSubsections
        className="sc-apply-to__field"
        placeholder="Select Accounts"
        selectedItems={state.accounts}
        label={platform ? `${ACCOUNT_TYPE_NAMES[platform]} Account` : 'Account'}
        options={availableAccounts}
        disabled={readOnly}
        onChange={(accounts) =>
          onChangeAccounts({ accounts, allAccountsSelected: false })
        }
        selectAllOptions={{
          label: 'All Accounts',
          allSelected: state.allAccountsSelected,
          onCheck: (allAccountsSelected) => {
            onChangeAccounts({ accounts: [], allAccountsSelected })
          },
        }}
        error={errors.selectedAccounts}
      />
      <DropdownWithSubsections
        className="sc-apply-to__field"
        placeholder="Select Element"
        label={platform ? `${ACCOUNT_TYPE_NAMES[platform]} Element` : 'Element'}
        disabled={readOnly}
        onChange={onChangeElements}
        selectedItems={state.elements}
        options={availableElements}
        error={errors.elements}
      />
    </div>
  )
}

SinglePerformanceAccountRow.propTypes = {
  platform: PropTypes.string,
  state: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeAccounts: PropTypes.func.isRequired,
  availableAccounts: PropTypes.array.isRequired,
  availableElements: PropTypes.array.isRequired,
  elementConfig: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}
