import React from 'react'
import PropTypes from 'prop-types'

/**
 * Renders the default state message for the file upload component
 * @param {Object} props - The component's react props
 * @param {String} props.render - What to render on as default message (otherwise it will fallback to the default message)
 */
export const UploadDefaultMessage = ({ render }) => {
  return (
    <div className="upload-modal__dropzone__message">
      {render || (
        <>
          <div>Drag & Drop files here to upload</div>
          <div>or click to select a file</div>
        </>
      )}
    </div>
  )
}

UploadDefaultMessage.propTypes = {
  render: PropTypes.any,
}

export default UploadDefaultMessage
