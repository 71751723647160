import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './style.scss'

const Suggestions = React.forwardRef(
  ({ list, onSelect, onBlur, show = true, ...other }, ref) => {
    const [value, setValue] = useState('')
    const [filtered, setFiltered] = useState(list)
    const [focusedIndex, setFocusedIndex] = useState(-1)
    const listRef = useRef(null)

    /** Detect component onBlur */
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside)
      return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    // Reset on changing list
    useEffect(() => {
      resetComponent()
    }, [JSON.stringify(list)])

    /** Triggered on text change -> filters the list */
    const onChange = (e) => {
      const { value } = e.target
      setValue(value)
      if (!value) {
        setFiltered(list)
      } else {
        const val = value.toLowerCase()
        setFiltered(
          list
            .filter((el) => el.toLowerCase().indexOf(val) > -1)
            .sort((a, b) =>
              a.toLowerCase().indexOf(val) < b.toLowerCase().indexOf(val)
                ? -1
                : 1
            )
        )
      }
    }

    /** Triggered when a value is selected -> resets everything */
    const onSubmit = (item) => {
      onSelect && onSelect(item)
      resetComponent()
    }

    /** Keydown listener for up/down arrows and enter */
    const onKeyDown = (e) => {
      // Up arrow
      if (e.keyCode === 38) {
        e.stopPropagation()
        e.preventDefault()
        if (focusedIndex > 0) {
          listRef.current.childNodes[focusedIndex - 1].focus()
          setFocusedIndex(focusedIndex - 1)
        }
      }

      // Down arrow
      if (e.keyCode === 40) {
        e.stopPropagation()
        e.preventDefault()
        if (focusedIndex < filtered.length - 1) {
          listRef.current.childNodes[focusedIndex + 1].focus()
          setFocusedIndex(focusedIndex + 1)
        }
      }

      // Enter
      if (e.keyCode === 13) {
        e.stopPropagation()
        e.preventDefault()
        let elementToSubmit = filtered[focusedIndex]
        if (!elementToSubmit) {
          elementToSubmit = filtered[0]
        }
        elementToSubmit && onSubmit(elementToSubmit)
      }
    }

    /** On list blur reset focused index counter */
    const onListBlur = () => {
      setFocusedIndex(-1)
    }

    /** Handles clicking outside -> closes component if onBlur is specified */
    const handleClickOutside = (event) => {
      if (ref && !ref.current.contains(event.target)) {
        if (onBlur) {
          onBlur()
          resetComponent()
        }
      }
    }

    /** Reset component */
    const resetComponent = () => {
      setFiltered(list)
      setFocusedIndex(-1)
      setValue('')
    }

    return (
      <div
        data-testid="suggestions-list"
        className={`suggestions ${!show ? 'suggestions--hide' : ''}`}
        onKeyDown={onKeyDown}
        ref={ref}
        {...other}
      >
        <input
          type="text"
          data-testid="suggestions-input"
          className="suggestions__input"
          value={value}
          onChange={onChange}
        />
        {filtered.length ? (
          <div
            className="suggestions__list"
            data-testid="suggestions-elements"
            ref={listRef}
            onBlur={onListBlur}
          >
            {filtered.map((el, idx) => (
              <div
                key={idx}
                className="suggestions__el"
                onClick={() => onSubmit(el)}
                tabIndex="0"
              >
                {el}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    )
  }
)

Suggestions.propTypes = {
  list: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  show: PropTypes.bool,
}
export default Suggestions
