import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import { useStore } from 'store'
import isEqual from 'lodash.isequal'

import { format } from 'date-fns'

import Table from 'components/table/beta'
import Filters from 'components/filters'
import Input from 'components/input'

import { getAlertThresholds } from 'modules/alert-thresholds/actions'

import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'
import { utils } from '@decision-sciences/qontrol-common'

const { shallowComparison, getCommonFields } = utils.object

/**
 * Displays a list of alert Thresholds
 * @param {Object} props Props
 * @param {Array} props.alertThresholds List of Alert Thresholds to show
 * @param {Function} [props.onDelete] Called on attempting to delete an Alert threshold
 * @param {Boolean} [props.hasEditAccess]
 * @returns {React.Component}
 */
const AlertThresholdsModule = ({
  alertThresholds,
  onDelete,
  hasEditAccess,
}) => {
  const navigate = useNavigate()

  // Fetch all thresholds if the user just created a Threshold
  const { dispatch } = useStore()
  const location = useLocation()

  useEffect(() => {
    if (location?.state?.clearFilters) {
      getAlertThresholds(dispatch)
    }
  }, [location?.state?.clearFilters])

  const columns = [
    { header: '', accessorKey: '', size: 20, maxSize: 30, id: 'spacing' },
    {
      header: '',
      id: 'actions',
      cell: (cell) =>
        hasEditAccess ? (
          <div className="table__actions">
            <div
              className="table__edit"
              onClick={() =>
                navigate(`/alert-thresholds/${cell.row.original.key}`)
              }
            />
          </div>
        ) : null,

      size: 50,
      maxSize: 70,
    },

    {
      header: 'Name',
      accessorKey: 'name',
      textAlign: 'left',
      cellTextAlign: 'left',
      sortingFn: 'name',
      size: 240,
    },
    {
      header: 'Key',
      accessorKey: 'key',
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 220,
    },
    {
      header: 'Default Value',
      accessorFn: (row) => {
        return row.value
      },
      cell: ({ row }) => {
        return new Intl.NumberFormat('en-US').format(row.original.value)
      },
      textAlign: 'right',
      cellTextAlign: 'right',
      size: 160,
      sortType: 'numberInTag',
    },
    {
      id: 'updatedAt',
      header: 'Last Modified',
      accessorFn: (row) => row.updatedAt && new Date(row.updatedAt),
      cell: (cell) =>
        cell.getValue('updatedAt') &&
        format(cell.getValue('updatedAt'), 'MM/dd/yyyy hh:mm aa'),
      textAlign: 'center',
      cellTextAlign: 'center',
      size: 220,
    },
    {
      id: 'delete',
      header: '',
      cell: (cell) => {
        if (!onDelete || cell.row.original.templates?.length) {
          return <></>
        }
        return (
          <DeleteIcon
            className="table__delete fill-red"
            alt={'delete'}
            onClick={() => onDelete(cell.row.original)}
          />
        )
      },
      size: 40,
      maxSize: 60,
    },
  ]
  return (
    <>
      <AlertThresholdFilters />

      <Table
        showPagination={true}
        showSearchInput={true}
        columns={columns}
        data={alertThresholds}
        paginationValues={[10, 20, 50, 100]}
        initialState={{
          sortBy: [{ id: 'updatedAt', desc: true }],
          pageSize: 10,
          pageIndex: 0,
        }}
      />
    </>
  )
}

const DEFAULT_FILTER_STATE = { name: '', value: '' }

/**
 * Filters for Alert Thresholds
 * @returns {React.Component}
 */
const AlertThresholdFilters = () => {
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({})
  const { dispatch } = useStore()

  const [initial, current] = getCommonFields(DEFAULT_FILTER_STATE, state)

  const applyFilters = () => {
    setLoading(true)
    getAlertThresholds(dispatch, state).finally(() => setLoading(false))
  }

  const clearFilters = () => {
    if (!shallowComparison(state, {})) {
      setLoading(true)
      setState({})
      getAlertThresholds(dispatch).finally(() => setLoading(false))
    }
  }

  return (
    <Filters
      loading={loading}
      onApply={applyFilters}
      onClear={clearFilters}
      initialApplied={!isEqual(initial, current)}
    >
      <Input
        className="margin-bottom-22"
        onChange={(name) => setState({ ...state, name })}
        value={state.name}
        placeholder="Enter Threshold name or key"
      />
      <Input
        type="number"
        onChange={(value) => setState({ ...state, value })}
        value={state.value}
        placeholder="Enter default value"
      />
    </Filters>
  )
}

AlertThresholdsModule.propTypes = {
  alertThresholds: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  hasEditAccess: PropTypes.bool,
}
export default AlertThresholdsModule
