import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'editLocks'

/**
 * Initial state of the reducer - dynamically fill with entity types as keys and list of locks as values
 * [entityType]: {
    [entityId]: {
      user: { _id: String, firstName: String, lastName: String },
      timeOfLock: Date
    },
   },
 */
const initialState = {}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.GET_LOCKS_FOR_TYPE]: (state, { entityType, locks }) => ({
    ...state,
    [entityType]: locks,
  }),

  [ACTIONS.ADD_LOCK_FOR_ENTITY]: (state, { entityType, entityId, object }) => ({
    ...state,
    [entityType]: {
      ...(state[entityType] || {}),
      [entityId]: { ...object, timeOfLock: Date.now() },
    },
  }),

  [ACTIONS.REMOVE_LOCK_FOR_ENTITY]: (state, { entityType, entityId }) => {
    if (!state?.[entityType]?.[entityId]) {
      return { ...state }
    }

    const newState = { ...state }

    delete newState[entityType][entityId]

    return {
      ...newState,
    }
  },
}

export default { name, initialState, reduce }
