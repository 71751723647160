import PropTypes from 'prop-types'
import { StaticTooltip } from 'components/tooltip'
import { AssociatedAlertsTable } from './table'

import './style.scss'

const AssociatedAlerts = ({ alerts }) => {
  return (
    <div className="associated-alerts">
      {alerts?.length ? (
        <StaticTooltip
          tooltipClassName="associated-alerts__tooltip"
          content={<AssociatedAlertsTable alerts={alerts} />}
        >
          <div className="hoverable">{alerts.length}</div>
        </StaticTooltip>
      ) : (
        0
      )}
    </div>
  )
}

AssociatedAlerts.propTypes = {
  alerts: PropTypes.array.isRequired,
}

export { AssociatedAlerts }
