import React from 'react'
import PropTypes from 'prop-types'
import { useDrag, useDrop } from 'react-dnd'
import cx from 'classnames'

import { flexRender } from '@tanstack/react-table'

import Icon from 'components/icon'
import Arrow from 'components/arrow'

import { ReactComponent as IconDragDrop } from 'assets/icon_drag_drop.svg'

import { generateElementStyling } from './utils'

const DND_ITEM_TYPE = 'row'

export const Row = ({
  table,
  row,
  onClick,
  isExpanded,
  setIsExpanded,
  expandableContent,
}) => {
  const { setTooltip } = table.options.meta
  return (
    <>
      <div className="table__row" key={row.id}>
        {row.getVisibleCells().map((cell) => {
          return (
            <div
              className="table__cell"
              style={generateElementStyling(cell)}
              onMouseOver={
                cell.column.columnDef.tooltip
                  ? () => {
                      setTooltip(
                        cell.column.columnDef.tooltip(cell.row.original)
                      )
                    }
                  : null
              }
              onMouseLeave={
                cell.column.columnDef.tooltip ? () => setTooltip() : null
              }
              onClick={(e) => {
                onClick && onClick(row.original, e)
                if (cell.column.columnDef.onClick) {
                  cell.column.columnDef.onClick(row.original, e)
                }
              }}
              key={cell.id}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </div>
          )
        })}
        {!!expandableContent && (
          <Arrow
            className="expandable-row-arrow"
            expanded={isExpanded}
            onChange={setIsExpanded}
          />
        )}
      </div>
      {!!expandableContent && isExpanded && expandableContent}
    </>
  )
}

Row.propTypes = {
  table: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  expandable: PropTypes.bool,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func,
  expandableContent: PropTypes.node,
}

export const DragAndDropRow = ({ table, row, onClick }) => {
  const { index } = row
  const { moveRow } = table.options.meta

  const dropRef = React.useRef(null)
  const dragRef = React.useRef(null)

  const [dropProps, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    drop(item) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      moveRow(dragIndex, hoverIndex)
    },
    collect(monitor) {
      return {
        hovered: monitor.isOver(),
      }
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  preview(drop(dropRef))
  drag(dragRef)

  const dragDropHandle = () => (
    <div
      ref={dragRef}
      className="table__dnd__handle"
      style={{
        cursor: isDragging ? 'grabbing' : 'grab',
      }}
    >
      <Icon>
        <IconDragDrop />
      </Icon>
    </div>
  )

  return (
    <div
      ref={dropRef}
      style={{ opacity }}
      className={cx('table__row__container', {
        'table__row__container--hovered': dropProps.hovered,
      })}
    >
      {dragDropHandle()}
      <Row onClick={onClick} table={table} row={row} />
    </div>
  )
}

DragAndDropRow.propTypes = {
  table: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}
