import React, { useEffect, useState, useMemo } from 'react'
import Modal from 'components/modal'
import Table from 'components/table/beta'
import Button from 'components/button'
import propTypes from 'prop-types'
import { useDebouncedEffect } from 'components/utils/custom-hooks'

const AssignUserModal = ({
  open,
  users,
  onClose,
  title,
  onSave,
  hiddenUsers,
}) => {
  const [selectedUsers, setSelectedUsers] = useState([])
  const [displayedUsers, setDisplayedUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('')

  const selectableUsers = useMemo(() => {
    return (
      users &&
      users.filter((user) => {
        return (
          !hiddenUsers ||
          hiddenUsers.findIndex((pu) => pu._id === user._id) === -1 ||
          hiddenUsers.length === 0
        )
      })
    )
  }, [JSON.stringify(users), JSON.stringify(hiddenUsers)])

  useEffect(() => {
    if (!open) {
      setSearch('')
      setDisplayedUsers(selectableUsers)
    }
  }, [open, JSON.stringify(selectableUsers)])

  useDebouncedEffect(
    () => {
      setLoading(true)
      if (search) {
        const found = selectableUsers.filter((user) => {
          return (
            user.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            user.email.toLowerCase().indexOf(search.toLowerCase()) !== -1
          )
        })
        setDisplayedUsers(found)
      } else {
        setDisplayedUsers(selectableUsers)
      }
      setLoading(false)
    },
    250,
    [search]
  )

  if (!open || !users) {
    return null
  }
  const columns = [
    { header: 'Name', accessorKey: 'name' },
    { header: 'Email', accessorKey: 'email' },
  ]

  return (
    <Modal
      opened={open}
      button={
        <Button
          value="Add Selected Users"
          onClick={() => {
            onSave(selectedUsers)
          }}
          disabled={!selectedUsers.length}
        />
      }
      buttonSecondary={
        <Button
          value="Cancel"
          onClick={() => {
            onClose()
          }}
          secondaryGray
        />
      }
      heading={title}
      className="users__assign-modal"
    >
      <Table
        columns={columns}
        data={displayedUsers}
        onSelect={setSelectedUsers}
        height={270}
        loading={loading}
        hasSearch
      />
    </Modal>
  )
}

AssignUserModal.propTypes = {
  title: propTypes.string,
  open: propTypes.bool,
  users: propTypes.array,
  onClose: propTypes.func,
  onSave: propTypes.func,
  hiddenUsers: propTypes.array,
}

export default AssignUserModal
