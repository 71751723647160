import { getMainElement } from './dom-manipulation'

/**
 * Scrolls to a given element in the page. The element is searched by the `id` attribute.
 * @param {String} elementId ID of the element to scroll into view
 * @param {ScrollIntoViewOptions} [options = { behavior: 'smooth' }] Additional scroll options
 */
export const scrollToElement = (
  elementId,
  options = { behavior: 'smooth' }
) => {
  const element = document.getElementById(elementId)
  element && element.scrollIntoView({ ...options })
}

export const scrollToElementWithOffset = (elementId, offset) => {
  const element = document.getElementById(elementId).getBoundingClientRect().y
  const main = getMainElement()
  main &&
    main.scrollTo({
      top: main.scrollTop + element - offset,
      behavior: 'smooth',
    })
}
