import { googleEnums } from '@decision-sciences/qontrol-common'
import { getStatusLabel } from './utils'

export const TARGETING_SETTING_OBSERVATION = {
  targeting_dimension: 'AUDIENCE',
  bid_only: true,
}

export const TARGETING_SETTING_TARGETING = {
  targeting_dimension: 'AUDIENCE',
  bid_only: false,
}

export const TARGETING_SETTING_LABELS = {
  OBSERVATION: 'Observation',
  TARGETING: 'Targeting',
}

export const AUDIENCE_SEGMENT_STATUSES = [
  {
    label: getStatusLabel(googleEnums.AdGroupCriterionStatus.ENABLED),
    value: googleEnums.AdGroupCriterionStatus.ENABLED,
  },
  {
    label: getStatusLabel(googleEnums.AdGroupCriterionStatus.PAUSED),
    value: googleEnums.AdGroupCriterionStatus.PAUSED,
  },
]
