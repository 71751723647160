import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'global'

/** Initial state of the reducer */
const initialState = {
  loading: true,
  environment: {},
  config: {},
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.SET_LOADING]: (state, { loading }) => {
    return { ...state, loading }
  },

  [ACTIONS.SET_ENVIRONMENT]: (state, { environment }) => {
    return { ...state, environment }
  },

  [ACTIONS.SET_CONFIG]: (state, { config }) => {
    return { ...state, config }
  },
}

export default { name, initialState, reduce }
