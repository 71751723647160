import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useStore } from 'store'

import InputText from 'components/input'
import Button from 'components/button'
import StickyFooter from 'components/sticky-footer'

import { changeAuthSecret, getQrInfo } from 'modules/users/actions'

const ResetAuthenticator = ({ close }) => {
  const { state } = useStore()
  const { userData } = state.session
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState(null)
  const [qrData, setQrData] = useState(null)
  const qrRef = useRef()

  // upon mounting get qr code and secret
  useEffect(() => {
    getQrInfo(userData._id)
      .then((res) => {
        qrRef.current.innerHTML = res.qr
        setQrData(res)
      })
      .catch(() => setError('Error getting QR code.'))
  }, [])

  const checkVerificationCode = () => {
    setLoading(true)
    changeAuthSecret(code, qrData.secret)
      .then(() => close())
      .catch(() => {
        setError('Something went wrong. Please try again.')
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="align-column">
      <div className="heading-secondary">Set Up Authenticator</div>

      <div className="form__section__body align-column spacing-bottom">
        <div className="my-account-user__option-text">OPTION 1</div>
        <div className="my-account-user__cursive-text">
          Scan to receive Authentication Code
        </div>
        <div ref={qrRef} className="qr-code" />
      </div>
      <div className="form__section__body align-column spacing-bottom">
        <div className="my-account-user__option-text">OPTION 2</div>
        <div className="my-account-user__cursive-text">
          Manually enter the following key into the authenticator app:
        </div>
        <div className="my-account-user__secret-text">
          {qrData ? qrData.secret : ''}
        </div>
        <div className="my-account-user__option-text">Steps</div>
        <div className="my-account-user__cursive-text">
          {' '}
          1. Open your Authenticator App
        </div>
        <div className="my-account-user__cursive-text">
          2. Choose to enter the key manually
        </div>
        <div className="my-account-user__cursive-text">
          3. Enter the key above and proceed
        </div>
        <div className="my-account-user__cursive-text">
          4. Choose an account name
        </div>
        <div className="my-account-user__cursive-text">
          5. Enter the 6-digit code below
        </div>
      </div>
      <div className="my-account-user__set-up-auth">
        <div className="form__section__body align-column half-width">
          <div className="my-account-user__cursive-text">
            Authentication Code
          </div>
          <InputText
            type="number"
            value={code}
            onChange={setCode}
            disabled={loading}
          />
          {error && <div className="error">{error}</div>}
          <Button
            value="Turn On Multi-Factor Authentication"
            onClick={() => checkVerificationCode()}
            disabled={loading}
          />
        </div>
      </div>

      <StickyFooter
        buttons={[
          { value: 'Go Back', onClick: () => close(), disabled: loading },
        ]}
      />
    </div>
  )
}
ResetAuthenticator.propTypes = {
  close: PropTypes.func,
}

export default ResetAuthenticator
