import isEqual from 'lodash.isequal'
import {
  STEP_ONE_INITIAL_STATE,
  AD_CONTENT_DEFAULTS,
} from 'modules/flow/constants'
import { CAMPAIGN_BUDGET_DEFAULTS as ODAX_CAMPAIGN_BUDGET_DEFAULTS } from 'modules/odax/field-map-frontend'
import { INITIAL_STATES } from 'modules/flow/utils/initial-states'
import { ACTIONS } from 'modules/flow/actions'
import {
  config,
  utils,
  accounts,
  flow,
  granularities,
} from '@decision-sciences/qontrol-common'

const {
  getAdSetsAsArray,
  getTotalNotDeletedCampaigns,
  STATUS,
  STEP_INDEX_MAP,
  STEPS_MAP,
  getTotalNotDeletedAdSets,
  getTotalNotDeletedAds,
} = flow
const { CAMPAIGN_STATUS } = flow.facebook.status
const { GRANULARITIES } = granularities
const { LC_USER_DATA } = config
const { arrayKeyBy, keyBy, mergeWithoutArrays } = utils.array
const { ACCOUNT_TYPES_MAP } = accounts

const { FACEBOOK, ODAX } = ACCOUNT_TYPES_MAP

/** Name of the reducer */
const name = 'flow'

/** Initial state of the Brief */
const initialState = {
  _id: 'new',
  name: '',
  status: STATUS.DRAFT.key,
  crtStep: 0,
  defineBriefData: structuredClone(STEP_ONE_INITIAL_STATE),
  briefDetails: {
    defaultApprover: null,
    backupApprover: null,
  },
  campaigns: {},
  adSets: {},
  ads: {},
  clientId: null,
  clientAccounts: [], // TODO raul - investigate if we should remove this from top-level
  lastCompletedStep: -1,
  loading: false,
  // id of item with open settings
  openItem: null,
  didCreateNow: false,
  // id of item for disabled button of confirm changes
  disableConfirmation: null,
  uploadInProgress: null,
  syncErrors: [],
  // Brief avigation "request"
  navigation: {
    step: null,
    entityId: null,
  },
  accounts: [],
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.SET_DID_CREATE_NOW]: (state, { value }) => ({
    ...state,
    didCreateNow: value,
  }),

  [ACTIONS.SET_CLIENT_ACCOUNTS]: (state, { accounts }) => ({
    ...state,
    clientAccounts: accounts,
  }),

  [ACTIONS.SET_STEP]: (state, { step }) => ({
    ...state,
    crtStep: step,
    loading: false,
    openItem: null,
  }),

  [ACTIONS.SET_FLOW]: (state, { flow }) => {
    /* Set Step 4 (Ad Groups /  Sets) flow data */
    const adSetsFromFlow = getAdSetsAsArray(flow)
    const adSetsFromDeletedBriefs =
      flow.accounts?.reduce((arr, crt) => {
        return [
          ...arr,
          ...(crt.deletedBrief ? getAdSetsAsArray(crt.deletedBrief) : []),
        ]
      }, []) || []

    ;[...adSetsFromFlow, ...adSetsFromDeletedBriefs].forEach((adSet) => {
      const data = adSet.data
      delete adSet.data
      Object.assign(adSet, data)
    })

    return {
      ...state,
      ...flow,
      didCreateNow: state.didCreateNow,
      loading: false,
    }
  },

  [ACTIONS.RESET_FLOW]: (state, { clientId }) => {
    return {
      ...structuredClone(initialState),
      didCreateNow: state.didCreateNow,
      clientId: clientId || null,
    }
  },

  [ACTIONS.SET_LOADING]: (state, { loading }) => ({ ...state, loading }),

  /* Update step data  */
  [ACTIONS.SET_STEP_DATA]: (state, { data }) => {
    const _data = mergeWithoutArrays({ ...state }, data)

    return {
      ..._data,
      loading: false,
    }
  },

  [ACTIONS.ADD_CAMPAIGNS]: (state, { platform, data }) => {
    const newCampaign = {
      ...structuredClone(INITIAL_STATES.campaign[platform]),
      id: data.id,
      name: data.name,
      account: data.account,
      copiedId: data.copiedId,
      confirmed: false,
      pendingChanges: null,
      lastUpdatedBy: _getCurrentUserId(),
      changed: true,
    }

    const modifiedCampaigns = {
      ...state.campaigns,
      [platform]: [...(state.campaigns?.[platform] || []), newCampaign],
    }

    return {
      ...state,
      campaigns: modifiedCampaigns,
    }
  },

  [ACTIONS.DUPLICATE_CAMPAIGNS]: (state, { platform, data }) => {
    const newCampaign = {
      ...data,
      confirmed: false,
      pendingChanges: null,
      lastUpdatedBy: _getCurrentUserId(),
      changed: true,
    }

    const modifiedCampaigns = {
      ...state.campaigns,
      [platform]: [...(state.campaigns?.[platform] || []), newCampaign],
    }

    return {
      ...state,
      campaigns: modifiedCampaigns,
    }
  },

  /**
   * Initialize campaign defaults for facebook legacy campaign
   * @param {Object} state Current state
   * @param {Object} args Action payload
   * @param {String} args.platform Platform of campaign : facebook
   * @param {String} args.id Unique identifier
   * @param {Object} args.data Campaign data
   */
  [ACTIONS.INITIALIZE_FACEBOOK_DATA]: (state, { platform, id, data }) => {
    const modifiedCampaigns = {
      ...state.campaigns,
      [platform]: [...state.campaigns[platform]].map((item) => {
        if (item.id !== id) {
          return item
        }
        // Making a copy so item won't reference an object in the store
        item = { ...item }
        item.pendingChanges = mergeWithoutArrays({
          ...(item.pendingChanges || {}),
          lastUpdatedBy: JSON.parse(localStorage.getItem(LC_USER_DATA))?._id,
        })
        item.isOdax = false
        item.data = data
        item.changed = true
        item.confirmed = false

        return item
      }),
    }

    return {
      ...state,
      campaigns: modifiedCampaigns,
    }
  },

  /**
   * Initialize campaign defaults for odax campaign
   * Odax campaigns initially have "facebook" as a platform.
   * Givent this, after adding the campaign in campaigns.odax and initializing it, it also has to be deleted from campaigns.facebook
   * @param {Object} state Current state
   * @param {Object} args Action payload
   * @param {String} args.platform Platform of campaign : odax
   * @param {Object} args.campaign Campaign data
   */
  [ACTIONS.INITIALIZE_ODAX_DATA]: (state, { platform, campaign }) => {
    const initializedCampaign = {
      ...campaign,
      ...structuredClone(INITIAL_STATES.campaign[platform]),
    }
    const newFacebookCampaigns = state.campaigns[FACEBOOK].filter(
      (camp) => camp.id !== campaign.id
    )

    const modifiedCampaigns = {
      ...state.campaigns,
      [platform]: [
        ...(state.campaigns?.[platform] || []),
        mergeWithoutArrays(initializedCampaign, campaign.pendingChanges),
      ],
      [FACEBOOK]: newFacebookCampaigns,
    }

    return {
      ...state,
      campaigns: modifiedCampaigns,
    }
  },

  /**
   * Restore CBO defaults for odax campaign
   * @param {Object} state Current state
   * @param {Object} args Action payload
   * @param {Object} args.campaignId Campaign id
   */
  [ACTIONS.RESET_ODAX_CBO]: (state, { campaignId }) => {
    const modifiedCampaigns = {
      ...state.campaigns,
      [ODAX]: [
        ...(state.campaigns?.[ODAX] || []).map((item) =>
          _changeMap(item, { ...ODAX_CAMPAIGN_BUDGET_DEFAULTS }, campaignId)
        ),
      ],
    }

    return {
      ...state,
      campaigns: modifiedCampaigns,
    }
  },

  /**
   * For facebook campaigns we will use this function since at creation, we do not know whether the campaign will be legacy or odax.
   * Initialization with campaign defaults will be done through initializeFacebookData and initializeOdaxData
   * @param {Object} state Current state
   * @param {Object} args Action payload
   * @param {String} args.platform Platform of campaign : facebook or odax
   * @param {Object} args.data Campaign data used to fetch account, name and copiedId
   */
  [ACTIONS.INITIALIZE_EMPTY_CAMPAIGN]: (state, { platform, data }) => {
    const newCampaign = {
      id: data.id,
      name: data.name,
      type: platform,
      account: data.account,
      copiedId: data.copiedId,
      confirmed: false,
      pendingChanges: null,
      deleted: false,
      lastUpdatedBy: _getCurrentUserId(),
      changed: true,
      status:
        INITIAL_STATES.campaign[platform]?.status || CAMPAIGN_STATUS.PAUSED,
    }

    const modifiedCampaigns = {
      ...state.campaigns,
      [platform]: [...(state.campaigns?.[platform] || []), newCampaign],
    }

    return {
      ...state,
      campaigns: modifiedCampaigns,
    }
  },

  [ACTIONS.EDIT_CAMPAIGNS]: (
    state,
    { platform, id, data, confirmed, merge, errors }
  ) => {
    const modifiedCampaigns = {
      ...state.campaigns,
      [platform]: [...state.campaigns[platform]].map((item) =>
        _changeMap(item, data, id, confirmed, merge, errors)
      ),
    }

    return {
      ...state,
      campaigns: modifiedCampaigns,
    }
  },

  [ACTIONS.ADD_EDIT_AD_CONTENT]: (
    state,
    { platform, campaignId, data, forceChanges }
  ) => {
    const modifiedAdContent = {
      ...(state.adContent || {}),
    }

    if (!modifiedAdContent[platform]) {
      modifiedAdContent[platform] = []
    }

    const foundIdx = modifiedAdContent[platform].findIndex(
      (adContent) => adContent.campaignId === campaignId
    )

    if (foundIdx === -1) {
      modifiedAdContent[platform].push({
        ...AD_CONTENT_DEFAULTS[platform],
        id: campaignId,
        campaignId: campaignId,
        pendingChanges: data,
        type: platform,
        changed: true,
      })
    } else {
      let item = {
        ...AD_CONTENT_DEFAULTS[platform],
        ...modifiedAdContent[platform][foundIdx],
        lastUpdatedBy: _getCurrentUserId(),
        changed: true,
        id: campaignId,
      }
      if (forceChanges) {
        item = mergeWithoutArrays(item, data)
      } else if (_checkChanges(item, data)) {
        item.pendingChanges = mergeWithoutArrays(
          {
            ...(item.pendingChanges || {}),
            lastUpdatedBy: JSON.parse(localStorage.getItem(LC_USER_DATA))?._id,
          },
          data
        )
        item.confirmed = false
      }

      modifiedAdContent[platform][foundIdx] = item
    }

    return {
      ...state,
      adContent: modifiedAdContent,
    }
  },

  [ACTIONS.CANCEL_ITEM]: (state, { step, platform, id }) => {
    const field = Object.values(STEPS_MAP).find(
      ({ idx }) => idx === step
    )?.field
    if (!field) {
      return state
    }
    return {
      ...state,
      [field]: {
        ...state[field],
        [platform]: [...state[field][platform]].reduce(
          (items, item) => _cancelReduce(items, item, id),
          []
        ),
      },
    }
  },

  [ACTIONS.CANCEL_STEP]: (state, { step }) => {
    const field = STEP_INDEX_MAP[step]?.field
    if (!field) {
      return state
    }
    const changes = {}

    Object.keys(state[field]).forEach((platform) => {
      changes[platform] = [...state[field][platform]].reduce(
        (items, item) => _cancelReduce(items, item),
        []
      )
    })

    return {
      ...state,
      [field]: {
        ...state[field],
        ...changes,
      },
    }
  },

  [ACTIONS.DELETE_CAMPAIGNS]: (state, { platform, data }) => {
    // remove campaigns saved in db from db by adding deleted flag and the ones not saved yet from step
    const modifiedCampaigns = {
      ...state.campaigns,
      [platform]: data._id
        ? [...state.campaigns[platform]].map((item) =>
            item.id === data.id
              ? { ...item, deleted: true, changed: true }
              : item
          )
        : [...state.campaigns[platform]].filter((item) => item.id !== data.id),
    }

    // Also delete campaign's ad content in the same way
    const modifiedAdContent = {
      ...state.adContent,
      [platform]: [...state.adContent[platform]].reduce((prev, current) => {
        if (current.campaignId === data._id || current.campaignId === data.id) {
          if (current._id) {
            return [...prev, { ...current, deleted: true, changed: true }]
          } else {
            return prev
          }
        }
        return [...prev, current]
      }, []),
    }

    /* Check if there are valid (not deleted) campaigns in order to set the lastCompletedStep accordingly */
    const hasCampaigns = Boolean(
      getTotalNotDeletedCampaigns({ ...state, campaigns: modifiedCampaigns })
    )

    return {
      ...state,
      campaigns: modifiedCampaigns,
      adContent: modifiedAdContent,
      /* If there are no valid campaigns, we set the lastCompletedStep of the brief as the campaign step */
      lastCompletedStep: hasCampaigns
        ? state.lastCompletedStep
        : STEPS_MAP.CAMPAIGNS.idx,
    }
  },

  [ACTIONS.ADD_ADSET]: (state, { platform, data }) => {
    const adSets = state.adSets || {}
    const modifiedAdSets = {
      ...adSets,
    }

    if (!modifiedAdSets[platform]) {
      modifiedAdSets[platform] = []
    }

    modifiedAdSets[platform] = [
      ...modifiedAdSets[platform],
      {
        ...structuredClone(INITIAL_STATES.adSet[platform]),
        ...data,
        confirmed: false,
        pendingChanges: null,
        changed: true,
        lastUpdatedBy: _getCurrentUserId(),
      },
    ]

    return {
      ...state,
      adSets: modifiedAdSets,
    }
  },

  [ACTIONS.EDIT_ADSET]: (
    state,
    { platform, id, data, confirmed, merge, errors }
  ) => {
    const adSets = state.adSets || {}
    const modifiedAdSets = {
      ...adSets,
      [platform]: [...(adSets[platform] || [])].map((item) =>
        _changeMap(item, data, id, confirmed, merge, errors)
      ),
    }

    let thereAreAdSets = true
    if (data?.deleted) {
      // Set last completed step accordingly
      thereAreAdSets = Boolean(getTotalNotDeletedAdSets(modifiedAdSets))
    }

    return {
      ...state,
      adSets: modifiedAdSets,
      lastCompletedStep: thereAreAdSets
        ? state.lastCompletedStep
        : STEPS_MAP.AD_SETS.idx,
    }
  },

  /**
   * Bulk update list of items, works only on ads, campaigns and adsets
   */
  [ACTIONS.BULK_UPDATE_STEP]: (state, { step, ids, changes, confirmed }) => {
    const field = STEP_INDEX_MAP[step]?.field
    const updates = {}

    if (
      ![
        STEPS_MAP.AD_SETS.field,
        STEPS_MAP.ADS.field,
        STEPS_MAP.CAMPAIGNS.field,
      ].includes(field)
    ) {
      return state
    }

    Object.keys(state[field]).forEach((platform) => {
      updates[platform] = [...state[field][platform]].map((item) => {
        if (!ids || ids.includes(item.id)) {
          if (changes.deleted) {
            item.deleted = true
          }
          if (confirmed) {
            item = mergeWithoutArrays(
              { ...item, lastUpdatedBy: _getCurrentUserId() },
              changes
            )
          } else {
            item.confirmed = false
            item.pendingChanges = mergeWithoutArrays(
              {
                ...(item.pendingChanges || {}),
                lastUpdatedBy: _getCurrentUserId(),
              },
              changes
            )
          }
          item.changed = true
        }
        return item
      })
    })

    const newState = {
      ...state,
      [field]: {
        ...state[field],
        ...updates,
      },
    }

    if (changes.deleted) {
      switch (field) {
        case STEPS_MAP.CAMPAIGNS.field:
          if (!getTotalNotDeletedCampaigns(newState)) {
            newState.lastCompletedStep = STEPS_MAP.CAMPAIGNS.idx
          }
          break

        case STEPS_MAP.AD_SETS.field:
          if (!getTotalNotDeletedAdSets(newState)) {
            newState.lastCompletedStep = STEPS_MAP.AD_SETS.idx
          }
          break

        case STEPS_MAP.ADS.field:
          if (!getTotalNotDeletedAds(newState)) {
            newState.lastCompletedStep = STEPS_MAP.AD_SETS.idx
          }
          break
      }
    }
    return newState
  },

  [ACTIONS.ADD_AD]: (state, { platform, data }) => {
    const ads = state.ads || {}
    const modifiedAds = {
      ...ads,
    }

    if (!modifiedAds[platform]) {
      modifiedAds[platform] = []
    }

    modifiedAds[platform] = [
      ...modifiedAds[platform],
      {
        ...structuredClone(INITIAL_STATES.ad[platform]),
        ...data,
        confirmed: false,
        changed: true,
        lastUpdatedBy: _getCurrentUserId(),
        pendingChanges: null,
      },
    ]

    return {
      ...state,
      ads: modifiedAds,
    }
  },

  [ACTIONS.EDIT_AD]: (state, { platform, id, data, merge, errors }) => {
    const ads = state.ads || {}
    const modifiedAds = {
      ...ads,
      [platform]: [...(ads[platform] || [])].map((item) =>
        _changeMap(item, data, id, undefined, merge, errors)
      ),
    }

    let thereAreAds = true
    if (data?.deleted) {
      // Set last completed step accordingly
      thereAreAds = Boolean(getTotalNotDeletedAds(modifiedAds))
    }

    return {
      ...state,
      ads: modifiedAds,
      lastCompletedStep: thereAreAds
        ? state.lastCompletedStep
        : STEPS_MAP.ADS.idx,
    }
  },

  [ACTIONS.TOGGLE_ITEM_OPEN]: (state, { id, value }) => {
    return {
      ...state,
      openItem: value ? id : null,
    }
  },

  [ACTIONS.DISABLE_CONFIRMATION]: (state, { id, value }) => {
    return {
      ...state,
      disableConfirmation: value ? id : null,
    }
  },

  [ACTIONS.SET_UPLOAD_IN_PROGRESS]: (state, { value }) => {
    return {
      ...state,
      uploadInProgress: value,
    }
  },

  [ACTIONS.UPDATE_BRIEF]: (state, { changes }) => {
    return { ...state, ...changes, lastUpdatedBy: _getCurrentUserId() }
  },

  [ACTIONS.NAVIGATE]: (state, { step, entityId }) => {
    return { ...state, navigation: { step, entityId } }
  },

  [ACTIONS.BATCH_SET_ERRORS]: (state, { actions }) => {
    // In case there are no actions, we don't do anything
    if (!Array.isArray(actions) || !actions.length) {
      return state
    }

    const newState = structuredClone(state)

    const structure = arrayKeyBy(actions, 'granularity')

    Object.keys(structure).forEach((granularity) => {
      structure[_granularityToField(granularity)] = arrayKeyBy(
        structure[granularity],
        'platform'
      )
      delete structure[granularity]
    })

    Object.keys(structure).forEach((granularity) =>
      Object.keys(structure[granularity]).forEach((platform) => {
        // Updates contains all the actions of a certain granularity and platform
        const updates = keyBy(structure[granularity][platform], 'id')

        newState[granularity][platform] = newState[granularity][platform].map(
          (item) => {
            const update = updates[item.id]
            if (update) {
              return { ...item, errors: update.errors }
            }
            return item
          }
        )
      })
    )

    return newState
  },
}

/**
 * Checks whether the changes would actually affect the object or not.
 * @param {Object} item Item to check
 * @param {Object} changes Changes
 * @returns {boolean} Whether the changes introduced are different from the source object
 * @private
 */
const _checkChanges = (item, changes) => {
  const actualItem = mergeWithoutArrays(item, item.pendingChanges)
  delete actualItem.pendingChanges

  return !isEqual(actualItem, mergeWithoutArrays(actualItem, changes))
}

/**
 * To be used when mapping items that support pending changes in the reducer.
 * @param {Object} item Item to be mapped
 * @param {Object} changes Changes to be applied
 * @param {String} id Target Object id
 * @param {Boolean} confirmed Whether the item should be set to a specific confirmed status
 * @param {Boolean} merge Whether the item's pendingChanges should simply take over,
 * @returns {Object}
 * @private
 */
const _changeMap = (item, changes, id, confirmed, merge, errors) => {
  if (item.id !== id) {
    return item
  }

  if (typeof errors !== 'undefined') {
    item.errors = errors
  }

  if (!_checkChanges(item, changes)) {
    return item
  }
  // Making a copy so item won't reference an object in the store
  item = { ...item }
  item.changed = true

  if (changes.deleted) {
    item.deleted = true
    return item
  }
  item.pendingChanges = mergeWithoutArrays(
    {
      ...(item.pendingChanges || {}),
      lastUpdatedBy: _getCurrentUserId(),
    },
    changes
  )

  item.confirmed = typeof confirmed !== 'undefined' ? Boolean(confirmed) : false

  if (merge) {
    item = mergeWithoutArrays(item, item.pendingChanges)
    item.pendingChanges = null
  }

  return item
}

const _cancelReduce = (items, item, id) => {
  // If item is not the one we're looking for, don't do anything to it
  if (id && item.id !== id) {
    return [...items, item]
  }
  // If item hasn't been saved before, remove it altogether
  if (!item._id && !item.previouslyConfirmed) {
    return items
  }
  // Making a copy so item won't reference an object in the store
  item = { ...item }
  item.pendingChanges = null
  item.confirmed = !!item.previouslyConfirmed
  return [...items, item]
}

const _getCurrentUserId = () =>
  JSON.parse(localStorage.getItem(LC_USER_DATA))?._id

const _granularityToField = (granularity) => {
  switch (granularity) {
    case GRANULARITIES.CAMPAIGN:
      return 'campaigns'
    case GRANULARITIES.AD_GROUP:
      return 'adSets'
    case GRANULARITIES.AD:
      return 'ads'
  }
}

export default { name, initialState: structuredClone(initialState), reduce }
