import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'store'

/* Components */
import { Dropdown } from 'components/dropdown/index'

/* Hooks */
import { useLoading } from 'components/utils/custom-hooks'

/* Actions */
import { getUsers } from 'modules/users/actions'

/* Constants */
import { platform } from '@decision-sciences/qontrol-common'

const { TEAM_TYPES } = platform

/**
 * Dropdown to select account lead, based on users in leadership teams
 * @param {Object} params React Params
 * @param {Object} params.defaultState Default state of the dropdown
 * @param {Function} params.onChange Callback function to be called when the value changes
 * @param {Boolean} params.isViewMode Whether the dropdown is in view mode
 * @returns {React.ComponentElement}
 */
const AccountLead = ({ defaultState, onChange, isViewMode }) => {
  const [isLoading, toggleLoading] = useLoading()
  const { state, dispatch } = useStore()

  const users = state.users.list

  /** On component mount, fetch users if necessary */
  useEffect(() => {
    if (!users.length) {
      toggleLoading('users', true)
      getUsers(dispatch, null)
    } else {
      toggleLoading('users', false)
    }
  }, [users.length])

  const usersInLeadershipTeams = useMemo(() => {
    return users.filter(
      (user) =>
        user.active &&
        !user.deleted &&
        user.teams.some(
          (team) =>
            team.active &&
            !team.deleted &&
            team.teamType === TEAM_TYPES.ACCOUNT_LEAD.value
        )
    )
  }, [users.length])

  return (
    <Dropdown
      data-cy="create-edit-client-form-account-lead"
      defaultOptionText="Account Lead"
      label="Account Lead"
      options={usersInLeadershipTeams.map((user) => ({
        value: user._id,
        label: user.name,
      }))}
      defaultState={defaultState || ''}
      deselectLabel={'Account Lead'}
      disabled={isViewMode || !usersInLeadershipTeams.length}
      loading={isLoading}
      labelTop
      onChange={onChange}
    />
  )
}

AccountLead.propTypes = {
  defaultState: PropTypes.string,
  isViewMode: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default AccountLead

