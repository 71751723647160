import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

/* Utils */
import Api from 'easy-fetch-api'
import { isValidPassword, ERRORS } from 'components/validator'

/* Components */
import AuthenticationContainer from 'components/authentication'
import Button from 'components/button'
import Input from 'components/input'

/* Styles */
import 'styles/authentication.scss'

const ResetPasswordPage = () => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState(null)
  const [submitError, setSubmitError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()

  const email = searchParams.get('email')
  const token = searchParams.get('token')

  /* Every time a value from the passwords fields is changed, we want to validate the form */
  useEffect(() => {
    /* If the new password input value is present, we validate it and display an error if needed. */
    if (password && !isValidPassword(password)) {
      return setError(ERRORS.PASSWORD)
    }

    /**
     * If the confirm password input valid is present, we validate that it matches the new password value and
     * we display an error if needed.
     */
    if (confirmPassword && password !== confirmPassword) {
      return setError('The passwords do not match.')
    }

    /* If there are no errors, we clear the error state. */
    setError(null)
  }, [password, confirmPassword])

  const handleSubmit = async (ev) => {
    /* Check if all the data that we need is present and valid in order to make the request. */
    if (!canMakeResetPasswordRequest()) {
      ev.preventDefault()
      return
    }

    ev.preventDefault()
    setError(null)
    setLoading(true)

    Api.post({
      url: '/api/users/reset-password',
      data: { email, resetToken: token, password },
    })
      .then((res) => {
        if (!res.success || res.error) {
          return setSubmitError(
            res.error || 'Something went wrong. Please try again!'
          )
        }
        setSuccess(true)
      })
      .catch((err) => {
        setSubmitError(err)
      })
      .finally(() => setLoading(false))
  }

  const canMakeResetPasswordRequest = () => {
    /**
     * All of the following criteria has to be met in order to be able to send a reset password request
     * The password values should be present and valid (we don't have errors when validating them)
     * No other reset password request is in progress
     * We have a resetToken(read from the URL)
     * We have an email (read from the URL)
     */
    return (
      !error &&
      !loading &&
      token &&
      email &&
      password &&
      confirmPassword &&
      password === confirmPassword
    )
  }

  return (
    <AuthenticationContainer
      title="New Password"
      onSubmit={handleSubmit}
      error={submitError}
    >
      <div className="authentication-container__description">
        {success
          ? `Your password was successfully created. Try to login again with the new credentials`
          : 'Enter your new password below. It must be at least 14 characters long, contain at least one number, one uppercase and one lowercase letter, and one special character: ~`!@#$%^&*()-_+={}[]|;:”<>’./?'}
      </div>
      {!success && (
        <>
          <Input
            type="password"
            disabled={loading}
            value={password}
            onChange={(value) => {
              setPassword(value)
              submitError && setSubmitError(null)
            }}
            placeholder={'Enter Password'}
            label="New Password"
            className="input-wrapper--uppercase"
          />
          <Input
            type="password"
            disabled={loading}
            value={confirmPassword}
            onChange={(value) => {
              setConfirmPassword(value)
              submitError && setSubmitError(null)
            }}
            placeholder={'Enter Password'}
            label="Confirm Password"
            className="input-wrapper--uppercase"
          />
        </>
      )}

      <div className="error">{error || ''}</div>

      {!success && (
        <div className="align-row">
          <Button
            className="authentication-container__button button--big"
            value="Next"
            disabled={!canMakeResetPasswordRequest()}
            onClick={handleSubmit}
          />
        </div>
      )}

      {success && (
        <div className="authentication-container__resend-code align-row">
          <Link to={'/login'}>Go back to login</Link>
        </div>
      )}
    </AuthenticationContainer>
  )
}

export default ResetPasswordPage
