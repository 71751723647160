import Api, { RESPONSE_TYPES } from 'easy-fetch-api'
import { handleCsvResponse } from 'components/utils/csv-download'
import { changeHistory } from '@decision-sciences/qontrol-common'

const { buildHistoryCsvFilename } = changeHistory

export const ACTIONS = {
  SET_HISTORY: 'SET_HISTORY',
  SET_HISTORY_FILTER: 'SET_HISTORY_FILTER',
  SET_HISTORY_FILTER_DATA: 'SET_HISTORY_FILTER_DATA',
  SET_HISTORY_COUNT: 'SET_HISTORY_COUNT',
}

export const getPlatformSettings = (type, sortingDisabled) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/global-settings',
      query: { type, sortingDisabled },
    })
      .then((result) => {
        if (!result || result.error) {
          reject(
            (result && result.error) ||
              'Error fetching global settings. Please refresh the page and try again.'
          )
        } else {
          resolve(result)
        }
      })
      .catch(reject)
  })
}

export const savePlatformSettings = (type, data) => {
  return new Promise((resolve, reject) =>
    Api.put({
      url: '/api/global-settings',
      data: {
        type,
        data,
      },
    })
      .then(resolve)
      .catch(reject)
  )
}

export const setFilters = (dispatch, filters) =>
  dispatch({ type: ACTIONS.SET_HISTORY_FILTER, filters })

/**
 * Fetches all available change history entries paginated
 * @param {Function} dispatch Store dispatch
 * @param {Object | null} [filter = null] Optional filtering
 * @param {Number} [page = 0] Page to get
 * @param {Number} [limit = 10] Number of items to get per page
 */
export const getHistory = (dispatch, filter = {}, page = 0, limit = 10) => {
  return new Promise((resolve, reject) => {
    const skip = page * limit

    Api.post({ url: '/api/change-history', data: { filter, skip, limit } })
      .then((response) => {
        if (!response.success) {
          console.error('Unable to get change history')
          return reject(response?.error || response)
        }
        const history = response.history || []
        const count = response.count || 0
        dispatch({ type: ACTIONS.SET_HISTORY, history })
        dispatch({ type: ACTIONS.SET_HISTORY_COUNT, count })
        resolve()
      })
      .catch(reject)
  })
}

/**
 * Fetches all available change history filtering data
 * @param {Function} dispatch Store dispatch
 */
export const getFilterData = (dispatch) => {
  return new Promise((resolve, reject) => {
    Api.get({ url: `/api/change-history/filters` })
      .then((response) => {
        if (!response.success) {
          console.error('Unable to get change history filters')
          return reject(response?.error || response)
        }

        dispatch({
          type: ACTIONS.SET_HISTORY_FILTER_DATA,
          filter_data: response.filter_data || {},
        })
        resolve()
      })
      .catch(reject)
  })
}

/**
 * Get all filtered history changes to export via a CSV
 * @param {Object} date So we know which CSV to return
 */
export const exportCsv = async (date) =>
  new Promise((resolve, reject) => {
    Api.post({
      url: '/api/change-history/export/download',
      responseType: RESPONSE_TYPES.raw,
      data: { date },
    })
      .then((response) => {
        handleCsvResponse(
          response,
          resolve,
          reject,
          buildHistoryCsvFilename(date)
        )
      })
      .catch(reject)
  })

/**
 * Initiate CSV export
 * @param {Object | null} [filters = null] Optional filtering
 */
export const initiateExportCsv = async (filters) =>
  Api.post({
    url: '/api/change-history/export/initialise',
    responseType: RESPONSE_TYPES.raw,
    data: { filters: filters || {} },
  })
