import Api from 'easy-fetch-api'

export const ACTIONS = {
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_CATEGORY: 'SET_CATEGORY',
  EDIT_CATEGORY: 'EDIT_CATEGORY',
  SET_DIMENSIONS: 'SET_DIMENSIONS',
  SET_DIMENSION: 'SET_DIMENSION',
  EDIT_DIMENSION: 'EDIT_DIMENSION',
  SET_SELECTED_DIMENSIONS: 'SET_SELECTED_DIMENSIONS',
  BULK_EDIT: 'BULK_EDIT',
  SET_DIMENSIONS_FILTERS: 'SET_DIMENSIONS_FILTERS',
  RESET: 'RESET',
}

export const setCategory = (dispatch, category) =>
  dispatch({ type: ACTIONS.SET_CATEGORY, category })

export const editCategoryField = (dispatch, category) =>
  dispatch({ type: ACTIONS.EDIT_CATEGORY, category })

export const setDimension = (dispatch, dimension) =>
  dispatch({ type: ACTIONS.SET_DIMENSION, dimension })

export const editDimensionField = (dispatch, dimension) =>
  dispatch({ type: ACTIONS.EDIT_DIMENSION, dimension })

export const selectDimensions = (dispatch, selectedDimensions) => {
  dispatch({ type: ACTIONS.SET_SELECTED_DIMENSIONS, selectedDimensions })
}

export const toggleBulkEdit = (dispatch, bulkEditActive) => {
  dispatch({
    type: ACTIONS.BULK_EDIT,
    bulkEditActive,
  })
}

export const reorderCategories = (dispatch, categoriesReordered) => {
  dispatch({ type: ACTIONS.SET_CATEGORIES, categories: categoriesReordered })
}

export const filterDimensions = (dispatch, filters) => {
  dispatch({ type: ACTIONS.SET_DIMENSIONS_FILTERS, filters })
}

export const resetGlobalDataStore = (dispatch) => {
  dispatch({ type: ACTIONS.RESET })
}

/**
 * Fetches all available data store categories
 * @param {Function} dispatch Store dispatch
 * @param {String | null} [searchTerm = null] Optional search term for filtering
 */
export const getAllCategories = (dispatch, searchTerm = null) => {
  return new Promise((resolve, reject) => {
    Api.get({ url: '/api/data-store/categories' })
      .then((response) => {
        if (!response.success) {
          console.error('Unable to get data store categories')
          return reject(response?.error || response)
        }
        const categories = response.categories || []
        dispatch({ type: ACTIONS.SET_CATEGORIES, categories })
        resolve()
      })
      .catch(reject)
  })
}

/**
 * Searches through all available data store categories
 * @param {String | null} [searchTerm = null] Optional search term for filtering
 */
export const searchCategories = (searchTerm = null) => {
  return new Promise((resolve, reject) => {
    Api.get({ url: '/api/data-store/categories', query: { searchTerm } })
      .then((response) => {
        if (!response.success) {
          console.error('Unable to get data store categories')
          return reject(response?.error || response)
        }
        const categories = response.categories || []
        resolve(categories)
      })
      .catch(reject)
  })
}

/**
 * Fetches a specific data store category given it's ObjectId
 * @param {Function} dispatch Store dispatch
 * @param {String} id ObjectId of the searched category
 * @returns {Promise<void>}
 */
export const getCategory = (dispatch, id) => {
  return new Promise((resolve, reject) => {
    Api.get({ url: `/api/data-store/category/${id}` })
      .then((response) => {
        if (!response.success || !response.category) {
          console.error(`Unable to get category with id ${id}`)
          reject(response?.error || response)
        }
        const category = response.category
        setCategory(dispatch, category)
        resolve()
      })
      .catch(reject)
  })
}

/**
 * Performs a create or update operation, depending on the existence of `category._id` field
 * @param {Object} category Category payload
 * @returns {Promise}
 */
export const upsertCategory = async (category) => {
  return new Promise((resolve, reject) => {
    if (!category._id) {
      Api.post({
        url: '/api/data-store/category',
        data: category,
      })
        .then((response) => {
          if (!response.success) {
            console.error('Unable to create category', response)
            return reject(response?.error || response)
          }
          resolve(response)
        })
        .catch((err) => {
          console.error('Unable to create category', err)
          return reject(err)
        })
    } else {
      Api.put({
        url: `/api/data-store/category/${category._id}`,
        data: category,
      })
        .then((response) => {
          if (!response.success) {
            console.error('Unable to update category', response)
            return reject(response?.error || response)
          }
          resolve(response)
        })
        .catch((err) => {
          console.error('Unable to update category', err)
          return reject(err)
        })
    }
  })
}

/**
 * Deletes a category and all associations with other entities
 * @param {String} id ObjectId of the category to delete
 * @returns {Promise<void>}
 */
export const deleteCategory = (id) => {
  return new Promise((resolve, reject) => {
    Api.delete({
      url: `/api/data-store/category/${id}`,
    })
      .then((response) => {
        if (!response.success) {
          console.error('Unable to delete category', response)
          return reject(response?.error || response)
        }
        resolve(response)
      })
      .catch((err) => {
        console.error('Unable to delete category', err)
        return reject(err)
      })
  })
}

/**
 * Updates the display field for a category
 * @param {String} id ObjectId of the category to edit
 * @param {Boolean} display Updated display flag
 * @returns {Promise}
 */
export const editCategoryDisplay = (id, display) => {
  return new Promise((resolve, reject) => {
    Api.put({
      url: `/api/data-store/category/display/${id}`,
      data: { display },
    })
      .then((response) => {
        if (!response.success) {
          console.error('Unable to update category display', response)
          return reject(response?.error || response)
        }
        resolve(response)
      })
      .catch((err) => {
        console.error('Unable to update category display', err)
        return reject(err)
      })
  })
}

/**
 * Updates the order of all categories
 * @param {Array} categoriesReordered Reordered list of categories
 * @returns {Promise}
 */
export const saveCategoriesOrder = (categoriesReordered) => {
  return new Promise((resolve, reject) => {
    Api.put({
      url: `/api/data-store/category/reorder`,
      data: categoriesReordered,
    })
      .then((response) => {
        if (!response.success) {
          console.error('Unable to reorder categories', response)
          return reject(response?.error || response)
        }
        resolve(response)
      })
      .catch((err) => {
        console.error('Unable to reorder categories', err)
        return reject(err)
      })
  })
}

/**
 * Fetches all available data store dimensions
 * @param {Function} dispatch Store dispatch
 * @param {Object} filters Applied filters
 */
export const getAllDimensions = (dispatch, filters) => {
  return new Promise((resolve, reject) => {
    Api.post({ url: '/api/data-store/dimensions', data: filters })
      .then((response) => {
        if (!response.success) {
          console.error('Unable to get data store dimensions')
          return
        }
        const dimensions = response.dimensions || []
        dispatch({ type: ACTIONS.SET_DIMENSIONS, dimensions })
        resolve()
      })
      .catch(reject)
  })
}

/**
 * Searches through available data store dimensions
 * @param {String} [searchTerm = ''] Applied filters
 */
export const searchDimensions = (searchTerm = '') => {
  return new Promise((resolve, reject) => {
    Api.post({ url: '/api/data-store/dimensions', data: { searchTerm } })
      .then((response) => {
        if (!response.success) {
          console.error('Unable to get data store dimensions')
          return
        }
        const dimensions = response.dimensions || []
        resolve(dimensions)
      })
      .catch(reject)
  })
}

/**
 * Creates a data store dimension
 * @param {Object} dimension Dimension to create
 * @returns {Promise}
 */
export const createDimension = (dimension) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: '/api/data-store/dimension',
      data: dimension,
    })
      .then((response) => {
        if (!response.success) {
          reject(response)
          return
        }
        resolve(response)
      })
      .catch((error) => {
        console.error('Unable to create dimension', error)
        reject(error)
      })
  })
}

/**
 * Performs a bulk edit for selected dimensions
 * @param {Array} selectedDimensions List of objects containing dimensions which will be edited
 * @param {Object} edits Edits which will overwrite dimension fields
 * @returns {Promise}
 */
export const bulkEditDimensions = (selectedDimensions, edits) => {
  return new Promise((resolve, reject) => {
    Api.put({
      url: '/api/data-store/dimension/bulk-edit',
      data: { selectedDimensions, edits },
    })
      .then((response) => {
        if (!response.success) {
          reject(response)
          return
        }
        resolve(response)
      })
      .catch((error) => {
        console.error('Unable to bulk edit dimensions', error)
        reject(error)
      })
  })
}

/**
 * Bulk deletes a list of dimensions
 * @param {Array<string>} dimensionIds List of ObjectIds to bulk delete
 * @returns {Promise<void>}
 */
export const deleteDimensions = (dimensionIds) => {
  return new Promise((resolve, reject) => {
    Api.delete({
      url: `/api/data-store/dimension`,
      query: { idList: dimensionIds },
    })
      .then((response) => {
        if (!response.success) {
          reject(response?.error || response)
          return
        }
        resolve(response)
      })
      .catch((err) => {
        console.error('Unable to delete categories', err)
        return reject(err)
      })
  })
}
