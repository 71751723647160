import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'components/dropdown/index'
import { useDebouncedEffect } from 'components/utils/custom-hooks'
import './style.scss'

const KpiCompanyFilter = ({ company, onChange }) => {
  const companyAndBusinessUnits = useMemo(() => {
    return [
      { ...company, name: 'Client' },
      ...(company.businessUnits.filter((bu) => bu.active) || []),
    ]
  }, [company])

  const [filteredCompanies, setFilteredCompanies] = useState([])

  useEffect(() => {
    setFilteredCompanies(
      companyAndBusinessUnits.map(
        (companyOrBusinessUnit) => companyOrBusinessUnit._id
      )
    )
  }, [])

  useDebouncedEffect(() => onChange(filteredCompanies), 500, [
    JSON.stringify(filteredCompanies),
  ])

  const onSelectedCompaniesChange = (selectedCompanyIds) => {
    if (!selectedCompanyIds.length) {
      setFilteredCompanies([company._id])
      return
    }
    setFilteredCompanies(selectedCompanyIds)
  }

  if (!company.businessUnits?.length) {
    return null
  }

  return (
    <div className="align-center gap-8 kpi-section__company-filter">
      <div className="general-label general-label--no-bottom-margin">
        Filter:
      </div>
      <Dropdown
        multiSelect
        selectAll
        reorderSelectedOnTop={false}
        selectedItems={filteredCompanies}
        options={companyAndBusinessUnits.map((company) => ({
          label: company.name,
          value: company._id,
        }))}
        overwriteSelectedText={
          companyAndBusinessUnits.length === filteredCompanies.length
            ? 'All'
            : null
        }
        onChange={onSelectedCompaniesChange}
        style={{ minWidth: 0 }}
      />
    </div>
  )
}

KpiCompanyFilter.propTypes = {
  company: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export { KpiCompanyFilter }
