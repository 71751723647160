import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import Input from 'components/input'
import debounce from 'lodash.debounce'

const TableSearch = ({ globalFilter, setGlobalFilter }) => {
  const [value, setValue] = useState(globalFilter)

  const setGlobalFilterDebounce = useCallback(
    debounce((newValue) => setGlobalFilter(newValue || undefined), 200),
    []
  )

  useEffect(() => setGlobalFilterDebounce(value), [value])

  return (
    <div className="table__search">
      <Input
        search
        value={value || ''}
        onChange={setValue}
        autoComplete="off"
        placeholder="Search"
      />
    </div>
  )
}

TableSearch.propTypes = {
  globalFilter: PropTypes.object,
  setGlobalFilter: PropTypes.func.isRequired,
}
export default TableSearch
