import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { permissions } from '@decision-sciences/qontrol-common'
import { Dropdown } from 'components/dropdown'

import cross_red from 'assets/icon_fill_cross_red.svg'
import cross_grey from 'assets/icon_clear_grey.svg'
import plus_green from 'assets/icon_plus_green.svg'
import minus_red from 'assets/icon_minus_red.svg'
import check_green from 'assets/icon_check_green.svg'
import unchecked_grey from 'assets/icon_check_grey_unchecked.svg'
import { ReactComponent as Checkbox_checked } from 'assets/icon_checkbox_square_grey.svg'
import { ReactComponent as Checkbox_unchecked } from 'assets/icon_checkbox_empty_white.svg'
import { ReactComponent as Checkbox_indeterminate } from 'assets/icon_checkbox_indeterminate_grey.svg'

import './style.scss'

const {
  PERMISSION_CONFIG,
  PERMISSION_TYPES,
  PERMISSION_CONFIG_LABELS,
  PERMISSION_CONFIG_DEFAULTS,
  PERMISSION_CATEGORY_APPLICABLE_PERMISSIONS,
} = permissions

/**
 *
 * @param {Object} params React Params
 * @param {Object} params.defaultValue Default Permissions Value {DAYS_PASSWORD_EXPIRE: { READ: true ... }}
 * @param {Function} params.onChange On changing a permission value
 * @param {Boolean} [params.viewMode] If true, content is not editable
 * @param {Object} [params.baseValue] Base Permissions that need to be overwritten
 */
const Permissions = ({ defaultValue, onChange, viewMode, baseValue }) => {
  const [state, setState] = useState(defaultValue || {})

  useEffect(() => {
    setState(defaultValue || {})
  }, [defaultValue])

  /**
   * Get an object of extra permissions to be applied
   * Gets READ to true if checking other permissions
   * or gets other permissions to false if unchecking READ
   * @param {String} category Category Key
   * @param {Boolean} isIndex If permission checked is an index permission (Eg. Alerts Index)
   * @param {Boolean} permissions Existing permissions
   * @param {Boolean} hasTruePermissions If we're checking a permission to true or not
   * @returns {Object}
   */
  const getPermissionOverridesForCategory = (
    category,
    isIndex,
    permissions,
    hasTruePermissions
  ) => {
    const newPermissions = {}
    if (isIndex && !hasTruePermissions) {
      Object.entries(PERMISSION_CONFIG[category].items).forEach(
        ([key, data]) => {
          const { permissions, indexException } = data
          if (!indexException) {
            newPermissions[key] = { ...permissions }
          }
        }
      )
    } else if (!isIndex && hasTruePermissions) {
      const indexEntry = Object.entries(PERMISSION_CONFIG[category].items).find(
        ([, value]) => {
          return value.index === true
        }
      )

      if (indexEntry) {
        newPermissions[indexEntry[0]] = {
          ...permissions[indexEntry[0]],
          READ: true,
        }
      }
    }
    return newPermissions
  }

  /**
   * Triggered on permission change.
   * Changes the current permission and also checks for dependent permission changes (if "indexException" is not present)
   * Examples:
   *  - on READ permission de-select, all other permissions in the row get de-selected
   *  - on any permission select, READ is also selected
   *  - on any index permission deselect (eg AlertsIndex) all permissions in the category get deselected
   */
  const onPermissionChange = (
    newPermissions,
    permissionKey,
    isIndex,
    category
  ) => {
    const hasTruePermissions = Object.values(
      newPermissions[permissionKey]
    ).some((perm) => perm)

    // Override permissions for the entire category if no "indexException" flag is present
    if (!PERMISSION_CONFIG[category].items[permissionKey]?.indexException) {
      newPermissions = {
        ...newPermissions,
        ...getPermissionOverridesForCategory(
          category,
          isIndex,
          newPermissions,
          hasTruePermissions
        ),
      }
    }
    setState(newPermissions)
    onChange(newPermissions)
  }

  /**
   * Checks whether one type of permission is checked for all Category Items, some, or none
   * @param {*} categoryKey Key of category (Eg. Security)
   * @param {*} permissionType Type of Permission (Eg. CREATE)
   * @returns {String} 'ALL' / 'NONE' / 'SOME'
   */
  const getCategoryState = (categoryKey, permissionType) => {
    const applicablePermissions =
      PERMISSION_CATEGORY_APPLICABLE_PERMISSIONS[categoryKey][permissionType]
    let foundOne = false
    if (!applicablePermissions) {
      return 'N/A'
    }
    const allChecked = applicablePermissions.every((permissionKey) => {
      const permission = state[permissionKey]?.[permissionType]
      const basePermission =
        baseValue && baseValue[permissionKey]?.[permissionType]
      if (permission || (typeof permission === 'undefined' && basePermission)) {
        foundOne = true
        return true
      }
    })
    if (allChecked) {
      return 'ALL'
    }
    return foundOne ? 'SOME' : 'NONE'
  }

  /**
   * Check / uncheck all items from category
   * @param {*} categoryState ALL || SOME || NONE || N/A
   * @param {*} categoryKey Category Key from PERMISSIONS_CONFIG
   * @param {*} permissionType Eg. CREATE, READ, APPROVE...
   */
  const onCheckCategory = (categoryState, categoryKey, permissionType) => {
    const newValue = categoryState !== 'ALL'
    const fieldsToUpdate = {}
    const { items } = PERMISSION_CONFIG[categoryKey]
    Object.keys(items).forEach((permissionKey) => {
      if (
        typeof items[permissionKey].permissions[permissionType] !== 'undefined'
      ) {
        fieldsToUpdate[permissionKey] = {
          ...state[permissionKey],
          [permissionType]: newValue,
        }
        if (permissionType === PERMISSION_TYPES.READ) {
          if (!newValue) {
            fieldsToUpdate[permissionKey] =
              PERMISSION_CONFIG_DEFAULTS[permissionKey]
          }
        } else {
          if (newValue) {
            fieldsToUpdate[permissionKey][PERMISSION_TYPES.READ] = true
          }
        }
      }
    })

    let newPermissions = {
      ...state,
      ...fieldsToUpdate,
    }
    newPermissions = {
      ...newPermissions,
      ...getPermissionOverridesForCategory(
        categoryKey,
        Object.values(items).some((item) => item.index) &&
          permissionType === PERMISSION_TYPES.READ,
        newPermissions,
        newValue
      ),
    }
    setState(newPermissions)
    onChange(newPermissions)
  }

  return (
    <table className={cx('permissions', { 'permissions--view': viewMode })}>
      <thead>
        <tr className="permissions__heading">
          <th className="permission__label"></th>
          {Object.keys(PERMISSION_TYPES).map((permission) => (
            <th key={permission} className="permissions__heading__header">
              {permission}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {Object.entries(PERMISSION_CONFIG).map(
          ([categoryKey, { categoryLabel, items }]) => {
            return (
              <React.Fragment key={categoryKey}>
                <tr className="permissions__category">
                  <td className="permissions__category__label">
                    {categoryLabel}
                  </td>
                  {Object.keys(PERMISSION_TYPES).map((permission) => {
                    const iconMap = {
                      ALL: Checkbox_checked,
                      NONE: Checkbox_unchecked,
                      SOME: Checkbox_indeterminate,
                    }

                    const categoryState = getCategoryState(
                      categoryKey,
                      permission
                    )
                    const Icon = iconMap[categoryState]

                    return (
                      <td
                        className="permissions__category__check"
                        key={permission}
                      >
                        {Icon && !viewMode && (
                          <Icon
                            className="permissions__category__check__icon"
                            alt="permission"
                            onClick={() =>
                              onCheckCategory(
                                categoryState,
                                categoryKey,
                                permission
                              )
                            }
                          />
                        )}
                      </td>
                    )
                  })}
                </tr>
                {Object.entries(items).map(
                  ([permissionKey, { label, index }]) => {
                    return (
                      <Permission
                        key={permissionKey}
                        permissionKey={permissionKey}
                        category={categoryKey}
                        viewMode={viewMode}
                        label={label}
                        state={state}
                        baseValues={
                          baseValue ? baseValue?.[permissionKey] : null
                        }
                        onChange={(newPermissions) =>
                          onPermissionChange(
                            newPermissions,
                            permissionKey,
                            index,
                            categoryKey
                          )
                        }
                      />
                    )
                  }
                )}
              </React.Fragment>
            )
          }
        )}
      </tbody>
    </table>
  )
}

/**
 * Displays a Permission Row
 * @param {Object} params
 * @param {String} params.category category of the permission (CAMPAIGNS, ALERTS)
 * @param {String} params.permissionKey type of permission (CAMPAIGN_BRIEF_DATA_FORM, CAMPAIGN_BUILDS_INDEX, etc)
 * @param {String} params.label Label to display next to permission row
 * @param {Object} [params.state] State of all permission values
 * @param {Function} params.onChange
 * @param {Boolean} params.viewMode If true, clicking does nothing
 * @param {Object} [params.baseValues] Base Permission values ({READ: true, CREATE: false})
 *
 */
const Permission = ({
  category,
  permissionKey,
  label,
  state,
  onChange,
  viewMode,
  baseValues,
}) => {
  const values = state[permissionKey]

  /**
   * Called on permission change
   *  - Automatically checks READ permission if necessary
   *  - Automatically checks permissions marked as "dependencies"
   *
   * @param {String} key Permission key (READ, CREATE)
   * @param {Boolean} value Whether the permission is on or off
   */
  const onPermissionChange = (key, value) => {
    const newPermissions = { ...state }
    let newPermissionsForKey = { ...values, [key]: value }

    /* Automatically check/uncheck READ if one of the others is checked/unchecked */
    if (key === PERMISSION_TYPES.READ) {
      if (!value) {
        newPermissionsForKey = { ...PERMISSION_CONFIG_DEFAULTS[permissionKey] }
      }
    } else if (value) {
      newPermissionsForKey[PERMISSION_TYPES.READ] = true
    }
    newPermissions[permissionKey] = newPermissionsForKey

    /* Automatically check dependent permissions */
    const dependencies =
      PERMISSION_CONFIG[category].items[permissionKey].dependencies?.[key]
    if (dependencies && value) {
      for (const dependency of dependencies) {
        const { item, type } = dependency
        if (!newPermissions[item]?.[type]) {
          newPermissions[item] = { ...newPermissions[item], [type]: true }
        }
      }
    }

    /* Automatically un-check permissions that depend on this one */
    Object.entries(PERMISSION_CONFIG[category].items).forEach(
      ([permKey, settings]) => {
        settings.dependencies &&
          Object.entries(settings.dependencies).forEach(
            ([permType, values]) => {
              // If we find a permission that is dependent on the current one, un-check it
              if (
                values.find(
                  (value) => value.item === permissionKey && value.type === key
                )
              ) {
                if (!newPermissions[permKey]) {
                  newPermissions[permKey] = {}
                }
                newPermissions[permKey][permType] = false
              }
            }
          )
      }
    )

    onChange({ ...newPermissions })
  }

  return (
    <tr className="permission">
      <td className="permission__label">{label}</td>
      {Object.keys(PERMISSION_TYPES).map((permission) => {
        const disabled =
          typeof PERMISSION_CONFIG_DEFAULTS[permissionKey][permission] ===
          'undefined'
        let icon = cross_grey
        let automation_value = 'cross_grey'
        const actualValue =
          typeof values?.[permission] !== 'undefined'
            ? values[permission]
            : !!baseValues?.[permission]

        if (!disabled) {
          icon = actualValue ? check_green : cross_red
          automation_value = actualValue ? 'check_green' : 'cross_red'
        }
        if (
          baseValues !== null &&
          typeof values?.[permission] !== 'undefined' &&
          actualValue !== !!baseValues?.[permission]
        ) {
          icon = values?.[permission] ? plus_green : minus_red
          automation_value = values?.[permission] ? 'plus_green' : 'minus_red'
        }
        return (
          <td
            key={permission}
            className={cx('permission__item', {
              'permission__item--disabled': disabled,
              'permission__item--view': viewMode,
            })}
          >
            <img
              className="permission__item__icon"
              src={icon}
              alt="permission"
              data-cy={automation_value}
              onClick={() =>
                !disabled &&
                !viewMode &&
                onPermissionChange(permission, !actualValue)
              }
            />
          </td>
        )
      })}
    </tr>
  )
}

/**
 * Permission Filter (dropdown + permission types)
 * @param {Object} params React Params
 * @param {Object} params.state Current State
 * @param {Array} params.state.types Permission Types ['READ', 'CREATE'] {@see PERMISSION_TYPES}
 * @param {String} params.state.permission Permission Key to check
 * @param {Function} params.onChange To call with new state
 */
export const PermissionFilter = ({
  state = {
    types: [],
    permission: '',
  },
  onChange,
}) => {
  const onRemoveType = (type) => {
    const newState = { ...state }
    newState.types = newState.types.filter((t) => {
      return t !== type
    })
    onChange(newState)
  }

  const onAddType = (type) => {
    const newState = { ...state }
    newState.types = [...newState.types, type]
    onChange(newState)
  }

  return (
    <div className="permission-filter">
      <Dropdown
        className="permission-filter__dropdown"
        options={Object.entries(PERMISSION_CONFIG_LABELS).map(
          ([value, label]) => ({ value, label })
        )}
        defaultOptionText="Permission"
        deselectLabel="None"
        defaultState={state.permission}
        onChange={(permission) => {
          onChange({ ...state, permission, types: [] })
        }}
      />
      {state.permission && (
        <div className="permission-filter__types">
          {Object.keys(PERMISSION_CONFIG_DEFAULTS[state.permission]).map(
            (type) => {
              const enabled = state.types.some((t) => t === type)
              return (
                <label
                  className={cx('permission-filter__types__item', {
                    'permission-filter__types__item--checked': enabled,
                  })}
                  key={type}
                  onClick={() =>
                    enabled ? onRemoveType(type) : onAddType(type)
                  }
                >
                  {type}
                  <img
                    className="permission-filter__types__icon"
                    src={enabled ? check_green : unchecked_grey}
                    alt="permission"
                  />
                </label>
              )
            }
          )}
        </div>
      )}
    </div>
  )
}

/**
 * Permissions Legend
 */
export const PermissionsLegend = () => {
  return (
    <div className="permissions-legend">
      <div className="permissions-legend__item">
        <img alt="Check Green" src={check_green} />
        Granted
      </div>
      <div className="permissions-legend__item">
        <img alt="Cross Red" src={cross_red} />
        Denied
      </div>
      <div className="permissions-legend__item">
        <img alt="Plus Green" src={plus_green} />
        Added
      </div>
      <div className="permissions-legend__item">
        <img alt="Minus Red" src={minus_red} />
        Removed
      </div>
      <div className="permissions-legend__item">
        <img alt="Cross Grey" src={cross_grey} />
        Not Available
      </div>
    </div>
  )
}

Permissions.propTypes = {
  defaultValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  viewMode: PropTypes.bool,
  baseValue: PropTypes.object,
}

Permission.propTypes = {
  category: PropTypes.string.isRequired,
  permissionKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  viewMode: PropTypes.bool,
  baseValues: PropTypes.object,
}

PermissionFilter.propTypes = {
  state: PropTypes.shape({
    types: PropTypes.array,
    permission: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
}

export default Permissions
