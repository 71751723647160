import React, { useState, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Prompt from 'react-router-prompt'

import Modal from 'components/modal'
import Button from 'components/button'
import Icon from 'components/icon/index'

import { ReactComponent as CheckmarkIcon } from 'assets/icon_check_no_border.svg'

import './style.scss'

/**
 * Custom Hook for leave confirmation on pages.
 * In extreme cases, you can use navigate() with BYPASS_LEAVE_CONFIRM equal to true
 * @param {Object} params Parameters
 * @param {Array} [params.safeRoutes] Array of routes that are safe. Example of safe route: '/company/:/account'. in this case, : is a wildcard
 * @param {Function} [params.onLeave] Callback function to be called when pressing the Discard button inside the modal
 * @param {Node} [customContent] Custom content to render inside Leave Confirm
 * @param {Node} [customHeader] Custom header to render
 * @returns {Array} Triple ([setUnsafeToLeave, Content, isUnsafeToLeave])
 * When unsafeToLeave is set to true, attempting to navigate to another page will activate the Modal from <Content />
 */
const useLeaveConfirm = (params) => {
  const { safeRoutes, onLeave, customHeader, customContent } = params || {}

  const [showModal, setShowModal] = useState(false)
  const [unsafeToLeave, setUnsafeToLeave] = useState(false)

  const location = useLocation()
  const lastLocationAttempt = useRef()

  const navigate = useNavigate()

  const stopUser = (nextLocation) => {
    if (!nextLocation) {
      return true
    }

    if (
      nextLocation.state?.BYPASS_LEAVE_CONFIRM ||
      location.pathname === nextLocation.pathname
    ) {
      return false
    }

    if (!safeRoutes?.length) {
      lastLocationAttempt.current = nextLocation
      setShowModal(true)
      return true
    }

    // Check if nextLocation is a safe route
    const safeRoute = safeRoutes?.some((route) => {
      const routeSplit = route.split('/')
      const nextSplit = nextLocation.pathname.split('/')
      if (routeSplit.length !== nextSplit.length) {
        return false
      }

      return routeSplit.every((item, index) => {
        if (item.startsWith(':')) {
          return true
        }
        return item === nextSplit[index]
      })
    })

    if (!safeRoute) {
      lastLocationAttempt.current = nextLocation
      setShowModal(true)
    }
    return !safeRoute
  }

  const onConfirm = () => {
    if (lastLocationAttempt.current) {
      setShowModal(false)
      setUnsafeToLeave(false)
      onLeave && onLeave()
      setTimeout(() => {
        navigate(lastLocationAttempt.current.pathname)
      }, 100)
    }
  }

  const onCancel = () => {
    setShowModal(false)
  }

  const Content = () => {
    return (
      <>
        <Prompt
          when={
            // if unsafeToLeave, we want to check other conditions as well
            unsafeToLeave
              ? (location) => stopUser(location?.nextLocation)
              : false
          }
          beforeConfirm={onConfirm}
          beforeCancel={onCancel}
        />

        {showModal && (
          <Modal
            dataTestId="exit-modal"
            className="leave-confirm__modal"
            heading={
              <div className="leave-confirm__modal__heading">
                {customHeader || 'Unsaved Changes'}
              </div>
            }
            opened={showModal}
            contentSeparator
            button={
              <Button
                dataTestId="exit-modal-button-confirm"
                secondaryGreen
                compact
                value={
                  <Icon>
                    <CheckmarkIcon width={16} height={16} /> Continue
                  </Icon>
                }
                onClick={onConfirm}
              />
            }
            buttonSecondary={
              <Button
                dataTestId="exit-modal-button-cancel"
                compact
                value="Cancel"
                onClick={onCancel}
                secondaryGray
              />
            }
          >
            {customContent || (
              <>
                <div>You have unsaved changes.</div>
                <div className="margin-top-22">
                  Click the Continue button to move forward without saving your
                  updates, or click Cancel to close this modal.
                </div>
              </>
            )}
          </Modal>
        )}
      </>
    )
  }

  return [setUnsafeToLeave, Content, unsafeToLeave]
}

export default useLeaveConfirm
