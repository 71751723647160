import { utils } from '@decision-sciences/qontrol-common'

const { isEmpty } = utils.object

export const REDIRECT_QUERY_PARAM_NAME = 'redirectUrl'

/**
 * For embedded URLs that can't be called via XHR (e.g. Tableau) because of CORS
 *
 * We need to detect if a URL loads correctly or not (URL could be used to load an image or an iframe) - and there's no way of detecting the status code
 * This is a hack I found online with an object that falls back to a 5px div in case the URL doesn't load
 *
 * @param {String} url - URL to load
 * @return {Promise<void>}
 */
export const isValidEmbeddedURL = (url) => {
  return new Promise((resolve, reject) => {
    const obj = document.createElement('object')
    obj.innerHTML = '<div class="check-embedded-url"><div/>' // fallback
    obj.style.display = 'block' // so height=5px will work
    obj.style.visibility = 'hidden' // to hide before loaded
    obj.style.position = 'absolute'
    obj.style.left = '-99999px'
    obj.data = url

    function isReallyLoaded(obj) {
      return obj.offsetHeight !== 5 // fallback height
    }

    /** Chrome calls always, Firefox on load */
    obj.onload = function () {
      obj.remove()
      isReallyLoaded(obj) ? resolve() : reject()
    }

    /** Firefox on error */
    obj.onerror = function () {
      obj.remove()
      reject()
    }

    /** Safari doesn't call onload/onerror, so we need to check manually */
    const isSafari = window.safari !== undefined
    if (isSafari) {
      const interval = function () {
        if (obj.offsetHeight > 0) {
          if (isReallyLoaded(obj)) {
            interval.count++
            // needs less than 400ms to fallback
            if (interval.count > 4) {
              obj.remove()
              resolve()
            }
          } else {
            obj.remove()
            reject()
          }
          return
        }

        setTimeout(interval, 100)
      }
      interval.count = 0
      setTimeout(interval, 100)
    }

    document.body.appendChild(obj)
  })
}

/**
 * Tests if a potential URL is only the pathname or the full URL
 * @param {String} rawUrl Raw URL to test
 * @returns {Boolean}
 */
export const isPathnameOnly = (rawUrl) => {
  const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/
  return !pattern.test(rawUrl)
}

/**
 * Extracts only the pathname from a URL to prevent redirects to other hosts
 * @param {String} rawUrl Raw URL to process
 * @returns {String | null}
 */
export const extractPathname = (rawUrl) => {
  if (isPathnameOnly(rawUrl)) {
    return rawUrl
  }
  try {
    const url = new URL(rawUrl)
    return url.pathname
  } catch (error) {
    return null
  }
}

/**
 * Builds the `redirectUrl` query param to use for redirection after login
 * @returns {String}
 */
export const buildLoginRedirectUrl = () => {
  const currentPath = window.location.pathname
  if (isEmpty(currentPath) || currentPath === '/') {
    return '/login'
  }
  return `/login?${REDIRECT_QUERY_PARAM_NAME}=${encodeURIComponent(
    currentPath
  )}`
}

/**
 * Cleans and decodes the `redirectUrl` query param and returns the pathname
 * @returns {String | null}
 */
export const decodeLoginRedirectPath = () => {
  const path = new URLSearchParams(location.search).get(
    REDIRECT_QUERY_PARAM_NAME
  )
  if (isEmpty(path)) {
    return null
  }
  const cleanedPath = extractPathname(path)
  return decodeURIComponent(cleanedPath)
}

