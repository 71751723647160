import React, { useEffect, useMemo } from 'react'
import cx from 'classnames'
import propTypes from 'prop-types'
import { arrayFromRange } from 'components/panel-with-highlighted-content/utils'
import { processFormula } from './utils'

/**
 * @param {Object} params React Params
 * @param {Array} [params.highlights] Words to be highlighted. If words contain special characters make sure to provide a pattern to match them.
 * @param {Number} [params.hoveredWordIndex] Index of the hovered word from the wordsToDisplay Array
 * @param {String} [params.className] Classname to style component
 * @param {Object} [params.wordToColorsMap] Object that maps words to highlight colors
 * @param {Array<String>} [params.wordsToDisplay] Words to display with highlights in text area
 * @param {Array<String>} [params.sampleDataWords] Sample data words to diplay instead of original words
 * @returns {React.ComponentElement}
 */
const AlertFormulaSimplifiedView = ({
  highlights = [],
  hoveredWordIndex = null,
  className,
  wordToColorsMap,
  wordsToDisplay,
  sampleDataWords,
}) => {
  const expressions = useMemo(
    () => processFormula(wordsToDisplay),
    [wordsToDisplay]
  )

  return (
    <div className={cx(className, 'simplified-view')}>
      <SimplifiedViewPanel
        label="All need to be true"
        alertFormulaWords={wordsToDisplay}
        sampleDataWords={sampleDataWords}
        highlights={highlights}
        wordToColorsMap={wordToColorsMap}
        hoveredWordIndex={hoveredWordIndex}
        rows={expressions.filter(({ allNeedsToBeTrue }) => allNeedsToBeTrue)}
      />
      <SimplifiedViewPanel
        label="One needs to be true"
        alertFormulaWords={wordsToDisplay}
        sampleDataWords={sampleDataWords}
        highlights={highlights}
        wordToColorsMap={wordToColorsMap}
        hoveredWordIndex={hoveredWordIndex}
        rows={expressions.filter(({ allNeedsToBeTrue }) => !allNeedsToBeTrue)}
      />
    </div>
  )
}

AlertFormulaSimplifiedView.propTypes = {
  highlights: propTypes.array,
  hoveredWordIndex: propTypes.number,
  className: propTypes.string,
  wordToColorsMap: propTypes.object,
  wordsToDisplay: propTypes.array,
  sampleDataWords: propTypes.array,
}

export default AlertFormulaSimplifiedView

const SimplifiedViewPanel = ({
  label,
  alertFormulaWords,
  sampleDataWords,
  rows,
  highlights = [],
  wordToColorsMap = {},
  hoveredWordIndex = null,
}) => {
  const refs = useMemo(
    () =>
      rows.reduce((acc, value, idx) => {
        acc[idx] = React.createRef()
        return acc
      }, {}),
    [rows]
  )

  useEffect(() => {
    if (typeof hoveredWordIndex === 'number') {
      const rowIndex = rows.findIndex(
        ({ originalIndexesInterval }) =>
          hoveredWordIndex >= originalIndexesInterval[0] &&
          hoveredWordIndex <= originalIndexesInterval[1]
      )
      refs[rowIndex]?.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [hoveredWordIndex, rows])

  return (
    <div className="simplified-view__panel">
      <div className="general-label">{label}</div>
      <div className="simplified-view__table">
        {rows.map(({ originalIndexesInterval, splitOn }, idx) => {
          const originalIndexes = arrayFromRange(
            originalIndexesInterval[0],
            originalIndexesInterval[1],
            1
          )
          let subExpressions = [{ indexes: originalIndexes, separator: null }]
          if (splitOn) {
            subExpressions = []
            let currentIndex = originalIndexesInterval[0]
            splitOn.forEach((logicalOperator) => {
              subExpressions.push({
                indexes: arrayFromRange(
                  currentIndex,
                  logicalOperator.index - 1,
                  1
                ),
                separator: logicalOperator.value,
              })
              currentIndex = logicalOperator.index + 1
            })
            subExpressions.push({
              indexes: arrayFromRange(
                currentIndex,
                originalIndexesInterval[1],
                1
              ),
              separator: null,
            })
          }
          return (
            <div
              ref={refs[idx]}
              key={idx}
              className={cx('simplified-view__table__row', {
                'simplified-view__table__row--highlighted':
                  originalIndexes.includes(hoveredWordIndex),
              })}
            >
              {subExpressions.map(({ indexes, separator }) => (
                <>
                  <SubExpression
                    indexes={indexes}
                    highlights={highlights}
                    alertFormulaWords={alertFormulaWords}
                    wordToColorsMap={wordToColorsMap}
                    sampleDataWords={sampleDataWords}
                  />
                  {separator && (
                    <div className="simplified-view__table__row__subexpression">
                      <div className="simplified-view__table__row__subexpression__expression">
                        {`-- ${separator} --`}
                      </div>
                      <div className="simplified-view__table__row__subexpression__sample-data">
                        {`-- ${separator} --`}
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const SubExpression = ({
  indexes,
  highlights,
  alertFormulaWords,
  wordToColorsMap,
  sampleDataWords,
}) => {
  const _formatWord = (wordIndex, isSampleData) => {
    const highlight = highlights.find((highlight) =>
      alertFormulaWords[wordIndex]?.includes(highlight)
    )
    const style = highlight
      ? {
          style: {
            backgroundColor: wordToColorsMap[alertFormulaWords[wordIndex]],
          },
        }
      : {}

    const word = isSampleData
      ? sampleDataWords[wordIndex]
      : alertFormulaWords[wordIndex]

    // Check if the word is a number and format it accordingly
    const formattedWord =
      isSampleData && !isNaN(word)
        ? new Intl.NumberFormat('en-US').format(parseFloat(word))
        : word

    return (
      <span key={wordIndex} {...style}>
        {formattedWord}
      </span>
    )
  }
  return (
    <div className="simplified-view__table__row__subexpression">
      <div className="simplified-view__table__row__subexpression__expression">
        {indexes.map((index) => _formatWord(index))}
      </div>
      <div className="simplified-view__table__row__subexpression__sample-data">
        {indexes.map((index) => _formatWord(index, true))}
      </div>
    </div>
  )
}

SubExpression.propTypes = {
  alertFormulaWords: propTypes.array.isRequired,
  sampleDataWords: propTypes.array.isRequired,
  highlights: propTypes.array,
  wordToColorsMap: propTypes.object,
  indexes: propTypes.array.isRequired,
}

SimplifiedViewPanel.propTypes = {
  label: propTypes.string,
  alertFormulaWords: propTypes.array.isRequired,
  sampleDataWords: propTypes.array.isRequired,
  rows: propTypes.array.isRequired,
  highlights: propTypes.array,
  wordToColorsMap: propTypes.object,
  hoveredWordIndex: propTypes.number,
}
