import React, { useState, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import isEqual from 'lodash.isequal'
import cx from 'classnames'
import { useStore } from 'store'
import { userPropType } from 'components/utils/prop-types'
import Button from 'components/button'
import Table from 'components/table/beta'
import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'
import Modal from 'components/modal'
import { getTooltipList } from 'components/utils/tooltip'
import { renderOwnerCell } from 'components/utils/owner'
import { editLockContext } from 'contexts/edit-lock'
import { saveSort } from 'modules/table-filter-sort/actions'
import { access, permissions } from '@decision-sciences/qontrol-common'

import './style.scss'

const { hasAccess } = access
const { PERMISSIONS, PERMISSION_TYPES } = permissions

const VariablesModule = ({ variables, userData, onDelete, tableContainer }) => {
  const navigate = useNavigate()
  const { dispatch, state } = useStore()
  const filterSort = state.tableFilterSort.filterSort[tableContainer]
  const [itemToDelete, setItemToDelete] = useState(null)
  const [loading, setLoading] = useState(true)

  /** Has edit permission */
  const hasEditAccess = hasAccess(userData, [
    { feature: PERMISSIONS.VARIABLES, type: PERMISSION_TYPES.EDIT },
  ])
  /** Edit locks */
  const { setEntityType, locks, EDIT_LOCK_ENTITIES } =
    useContext(editLockContext)

  /** Tell the Edit Lock context what entity we're currently on */
  useEffect(() => {
    setEntityType(EDIT_LOCK_ENTITIES.VARIABLE)

    /** It's important to clear the type upon leaving the page */
    return () => {
      setEntityType(null)
    }
  }, [setEntityType])

  /** Disable loader only after the locks have been found for the entities */
  useEffect(() => {
    setLoading(!locks)
  }, [locks])

  const columns = [
    {
      header: '',
      id: 'actions',
      accessorKey: 'actions',
      cell: (cell) => {
        const { key, _id } = cell.row.original
        const locked = locks?.[_id]

        return (
          <div className="table__actions">
            <div
              className={cx('', {
                table__edit: !locked && hasEditAccess,
                table__view: !locked && !hasEditAccess,
                table__lock: locked,
              })}
              onClick={() => !locked && navigate(`/variables/${key}`)}
            ></div>
          </div>
        )
      },
      tooltip: (row) => {
        /** Edit lock */
        const locked = locks?.[row._id]

        return locked
          ? getTooltipList(locked.tooltipHeader, [locked.tooltipText])
          : null
      },
      size: 55,
      minSize: 55,
      maxSize: 85,
    },
    {
      header: 'Variable Key',
      id: 'key',
      accessorKey: 'key',
      sortingFn: 'name',
      size: 160,
    },
    {
      header: 'Variable Name',
      id: 'name',
      accessorKey: 'name',
      sortingFn: 'name',
      size: 160,
    },
    {
      header: 'Global',
      accessorKey: 'isGlobal',
      id: 'global',
      accessorFn: (row) => (row.isGlobal ? 'Yes' : 'No'),
      size: 60,
    },
    {
      header: 'Timespan',
      id: 'recurrenceAmount',
      accessorKey: 'recurrenceAmount',
      accessorFn: (row) =>
        `Value for the past ${row.recurrenceAmount} ${row.recurrenceType}`,
      sortType: 'alphanumeric',
    },
    {
      header: 'Reference Time',
      id: 'startType',
      accessorKey: 'startType',
      accessorFn: (row) =>
        row.startAmount
          ? `${row.startAmount} ${row.startType} prior`
          : 'at runtime',
      size: 80,
      sortType: 'timeStrings',
    },
    {
      header: 'Owner',
      cell: renderOwnerCell,
      tooltip: (row) => {
        if (!row.owner.active) {
          return 'Inactive User'
        }
      },
      sortType: 'alphanumeric',
    },
    {
      header: 'Date Modified',
      accessorKey: 'updatedAt',
      accessorFn: (row) => row.updatedAt,
      id: 'updatedAt',
      cell: (cell) => {
        const { _id, deletable, updatedAt } = cell.row.original
        const locked = locks?.[_id]

        return (
          <div className="align-row" style={{ width: '100%' }}>
            {updatedAt && format(new Date(updatedAt), 'MM/dd/yyyy')}
            {!locked && deletable && (
              <DeleteIcon
                className="table__delete fill-red"
                alt={'delete'}
                onClick={() => setItemToDelete(_id)}
              />
            )}
          </div>
        )
      },
      size: 130,
      sortType: 'alphanumeric',
    },
  ]

  const sortChanged = (newSort) => {
    if (!isEqual(newSort, filterSort.sort)) {
      saveSort(dispatch, tableContainer, newSort)
    }
  }

  return (
    <div className="variables page">
      {itemToDelete && (
        <Modal
          opened={!!itemToDelete}
          button={
            <Button
              value="Delete"
              onClick={() => {
                onDelete(itemToDelete)
                setItemToDelete(null)
              }}
            />
          }
          buttonSecondary={
            <Button
              value={'Cancel'}
              onClick={() => setItemToDelete(null)}
              secondaryGray
            />
          }
          heading={`Are you sure you want to delete "${
            variables.find((variable) => variable._id === itemToDelete).name
          }"?`}
          className="alert-categories__modal"
        />
      )}

      <Helmet>
        <title>Metric Variables</title>
      </Helmet>

      <div className="heading" data-cy="page-heading">
        Metric Variables
        {hasAccess(userData, [
          { feature: PERMISSIONS.VARIABLES, type: PERMISSION_TYPES.CREATE },
        ]) ? (
          <div className="heading__buttons">
            <Button
              value="Create Variable"
              onClick={() => navigate('/variables/new')}
            >
              New Variable
            </Button>
          </div>
        ) : null}
      </div>
      {variables && (
        <Table
          columns={columns}
          data={variables}
          initialState={{ sortBy: filterSort.sort }}
          loading={loading}
          onSortChanged={sortChanged}
          tableContainer={tableContainer}
        />
      )}
    </div>
  )
}

VariablesModule.propTypes = {
  variables: PropTypes.array.isRequired,
  userData: userPropType.isRequired,
  onDelete: PropTypes.func.isRequired,
  tableContainer: PropTypes.string.isRequired,
}

export default VariablesModule
