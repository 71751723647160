import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useStore } from 'store'
import { useNavigate, useSearchParams } from 'react-router-dom'

import useSession from 'modules/session'
import { setCurrentCompany } from 'modules/companies/actions'
import { getNotificationsLazy } from 'modules/notifications/actions'
import { finishEmulatingUser } from 'modules/session/actions'
import { clearLocalDataStore } from 'modules/data-store/actions'

import { getUserCompanies } from 'components/utils/user'
import Logo from 'components/logo'
import { useSocket } from 'components/utils/socket'

import notificationIcon from 'assets/icon_notification_bell.svg'
import { ReactComponent as ClientSvg } from 'assets/client.svg'
import { ReactComponent as EmulateIcon } from 'assets/user_emulating.svg'
import { ReactComponent as LogoutIcon } from 'assets/icon_slide_right.svg'
import blueArrowIcon from 'assets/icon_arrow_blue.svg'
import whiteArrowIcon from 'assets/icon_arrow_white_down.svg'

import { useOnClickOutside } from 'hooks/outside-click'
import { socket, alertTriggers } from '@decision-sciences/qontrol-common'

import SearchMenu from './search-menu'
import AddMenu from './add-menu'
import NotificationsMenu from './notifications-menu'

import './style.scss'

const { NOTIFICATIONS } = socket
const { ALERT_TRIGGER_STATUS } = alertTriggers

const Header = () => {
  const navigate = useNavigate()
  const [, user] = useSession()
  const { dispatch, state } = useStore()
  const [selectedSection, setSelectedSection] = useState(null)
  const [openedSections, setOpenedSections] = useState({})
  const [searchParams, setSearchParams] = useSearchParams()

  const {
    companies: { currentCompany, list: companies },
    notifications: { userNotifications },
    global: {
      environment: { NR_ALERT_NOTIFICATIONS_TO_LOAD_AT_ONCE },
    },
  } = state

  // There are unread notifications
  // const unreadNotifications = userNotifications?.data?.some(
  //   (item) => !item?.read
  // )

  const multipleCompanies =
    user && (user.clients?.length > 1 || user.isSuperAdmin)
  const userCompanies = getUserCompanies(user, companies)
  const changeClient = (parentClientId, clientId) => {
    // On company change, store it
    let company = companies.find((c) => c.clientId === parentClientId)
    if (clientId) {
      company = company.businessUnits.find((b) => b.clientId === clientId)
    }
    dispatch(setCurrentCompany(company))
    clearLocalDataStore(dispatch)

    if (company?._id) {
      searchParams.set('cid', company?._id, { replace: true })
    }
    setSearchParams(searchParams)
    // Then refresh the app
    navigate(0)
  }
  const companiesOptions = []

  userCompanies.forEach((company, c) => {
    companiesOptions.push({
      id: c,
      value: company.clientId,
      label: company.name,
      hasChildren: company.businessUnits?.length > 0,
      click: () => changeClient(company.clientId),
    })
    if (company.businessUnits && company.businessUnits.length) {
      company.businessUnits.forEach((bu, b) => {
        companiesOptions.push({
          id: `${c}-${b}`,
          value: bu.clientId,
          label: bu.name,
          parent: company._id,
          parentKey: company.clientId,
          click: () => changeClient(company.clientId, bu.clientId),
        })
      })
    }
  })

  const openMenuClass = cx('header__opened-menu ', {
    large: selectedSection === 'search' || selectedSection === 'notifications',
  })

  const parentCompanyName = userCompanies.find(
    (c) => c._id === currentCompany?.parentCompany
  )?.name

  const headerRef = useRef()
  useOnClickOutside(headerRef, () => setSelectedSection(null))

  // Listen to changes on Active cards => disable notifications of modified active cards
  // const socket = useSocket({ room: [currentCompany?._id, user?._id] })
  // useEffect(() => {
  //   if (socket) {
  //     socket.on(NOTIFICATIONS.alertTriggers.receive, ({ from, newCard }) => {
  //       // If notifications pane is closed and card has been changed from 'Active'
  //       if (
  //         newCard ||
  //         (selectedSection !== 'notifications' &&
  //           from === ALERT_TRIGGER_STATUS.ACTIVE)
  //       ) {
  //         // silently refetch the notifications
  //         getNotificationsLazy(
  //           dispatch,
  //           user._id,
  //           {
  //             selectedClient: userNotifications.showCompanySpecificNotifications
  //               ? currentCompany?._id
  //               : null,
  //             viewUnreadOnly: userNotifications.viewUnreadOnly,
  //             showAlertNotifications: userNotifications.showAlertNotifications,
  //             showBriefNotifications: userNotifications.showBriefNotifications,
  //           },
  //           parseInt(NR_ALERT_NOTIFICATIONS_TO_LOAD_AT_ONCE, 10)
  //         )
  //       }
  //     })

  //     // Signal for Brief related notifications
  //     socket.on(NOTIFICATIONS.briefRelatedNotification.receive, () => {
  //       // silently refetch the notifications
  //       getNotificationsLazy(
  //         dispatch,
  //         user._id,
  //         {
  //           selectedClient: userNotifications.showCompanySpecificNotifications
  //             ? currentCompany?._id
  //             : null,
  //           viewUnreadOnly: userNotifications.viewUnreadOnly,
  //           showAlertNotifications: userNotifications.showAlertNotifications,
  //           showBriefNotifications: userNotifications.showBriefNotifications,
  //         },
  //         parseInt(NR_ALERT_NOTIFICATIONS_TO_LOAD_AT_ONCE, 10)
  //       )
  //     })
  //   }

  //   return () => socket?.removeAllListeners(NOTIFICATIONS.alertTriggers.receive)
  // }, [
  //   socket,
  //   JSON.stringify(userNotifications),
  //   user?._id,
  //   currentCompany?._id,
  //   selectedSection,
  // ])

  return (
    <header className="header align-row" ref={headerRef} tabIndex={0}>
      <Logo
        click={() => {
          navigate('/')
        }}
      />

      <div className="header__menu align-row">
        {
          // Hidden from view until further notice per Abby's request (AP-8831)
          /* <div
          className={`header__search-box header__section`}
          onClick={() => {
            selectedSection === 'search'
              ? setSelectedSection(null)
              : setSelectedSection('search')
          }}
        >
          <SearchIcon className="fill-white" alt="search" />
        </div> */
        }
        {/* <div
          className="header__notification  header__section"
          onClick={() => {
            selectedSection === 'notifications'
              ? setSelectedSection(null)
              : setSelectedSection('notifications')
          }}
        >
          {unreadNotifications && (
            <div className="header__notification__number" />
          )}
          <img src={notificationIcon} alt="notification" />
        </div> */}

        <EmulateComponent user={user} />

        <div
          className={cx('header__client header__section align row', {
            client: selectedSection === 'client',
            'not-loaded':
              !currentCompany || (multipleCompanies && !userCompanies.length),
          })}
          onClick={() => {
            selectedSection === 'client'
              ? setSelectedSection(null)
              : setSelectedSection('client')
          }}
        >
          <ClientSvg />
          <span className="client-name">
            {currentCompany?.parentCompany &&
              `(${parentCompanyName || 'Business Unit'}) `}
            {currentCompany ? currentCompany.name : 'Client Name'}
          </span>
          {user && <span className={'qontrolUID'}>{user._id}</span>}
        </div>
      </div>

      <div className={openMenuClass}>
        {showSelectedSection(
          selectedSection,
          companiesOptions,
          userNotifications?.data || [],
          currentCompany,
          openedSections,
          setOpenedSections,
          userNotifications?.loading
        )}
      </div>
    </header>
  )
}

const showSelectedSection = (
  selected,
  options,
  notifications,
  currentCompany,
  openedSections,
  setOpenedSections,
  notificationsLoading
) => {
  let content = ''
  switch (selected) {
    case 'search':
      content = <SearchMenu />
      break
    // case 'notifications':
    //   content = (
    //     <NotificationsMenu
    //       data={notifications}
    //       loading={notificationsLoading}
    //     />
    //   )
    // break
    case 'client':
      content = (
        <>
          <div className="header__opened-menu__title">Switch Client</div>
          <div className="header__opened-menu__list">
            {options.map((option, o) => {
              const isSelected =
                option.value === currentCompany.clientId &&
                option.parent === currentCompany.parentCompany
              const classes = cx('header__opened-menu__item', {
                'header__opened-menu__item--selected': isSelected,
                'header__opened-menu__item--hidden': option.parentKey
                  ? !openedSections[option.parentKey]
                  : null,
              })
              const chevronClasses = cx('header__opened-menu__item__chevron', {
                'header__opened-menu__item__chevron--open':
                  openedSections[option.value],
              })
              if (
                isSelected &&
                option.parentKey &&
                openedSections[option.parentKey] === undefined
              ) {
                setOpenedSections({
                  ...openedSections,
                  [option.parentKey]: true,
                })
              }
              return (
                <div key={o} className={classes} onClick={() => option.click()}>
                  <span>
                    {option.parent && ' - '}
                    {option.label}
                  </span>
                  {option.icon && <img src={option.icon} alt={option.label} />}
                  {option.hasChildren && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation()
                        setOpenedSections({
                          ...openedSections,
                          [option.value]: !openedSections[option.value],
                        })
                      }}
                      className={chevronClasses}
                    >
                      <img
                        src={isSelected ? whiteArrowIcon : blueArrowIcon}
                        alt="arrow icon"
                      />
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </>
      )
      break
    case 'add':
      content = <AddMenu />
      break
    default:
      break
  }
  return content
}

const EmulateComponent = ({ user }) => {
  const { dispatch } = useStore()
  const [isClicked, setIsClicked] = useState(false)

  if (!user.emulatedBy) {
    return null
  }

  return (
    <div
      className="header__emulate"
      onClick={() => setIsClicked((isClicked) => !isClicked)}
    >
      <div
        className={cx('header__emulate__wrapper', {
          'header__emulate__wrapper--light-blue': isClicked,
        })}
      >
        <div
          className={cx('header__emulate__icon', {
            'header__emulate__icon--with-border': !isClicked,
          })}
        >
          <EmulateIcon width={20} height={20} />
        </div>
        <div className="header__emulate__info">
          <span>Emulating</span>
          <div>
            <strong>
              {user.firstName} {user.lastName}
            </strong>
          </div>
        </div>
      </div>
      {isClicked && (
        <div
          className="header__emulate__stop"
          onClick={() => finishEmulatingUser(dispatch)}
        >
          <div className="header__emulate__stop__icon">
            <LogoutIcon width={20} height={20} />
          </div>
          <div>Stop Emulating User</div>
        </div>
      )}
    </div>
  )
}

EmulateComponent.propTypes = {
  user: PropTypes.object,
}

export default Header
