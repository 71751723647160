import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { campaignExclusions } from '@decision-sciences/qontrol-common'

const { CAMPAIGN_EXCLUSION_RULE_PROPERTIES } = campaignExclusions

/**
 * Renders the rule input labels
 * @param {Object} props
 * @param {Object} props.rule - Current displayed rule
 */
const InputGroupHeading = ({ rule }) => {
  const isCampaignNameProperty = () => {
    return rule.property === CAMPAIGN_EXCLUSION_RULE_PROPERTIES.CAMPAIGN_NAME
  }

  const getSecondLabel = () => {
    return isCampaignNameProperty()
      ? 'CAMPAIGN NAME CRITERIA'
      : rule.property === CAMPAIGN_EXCLUSION_RULE_PROPERTIES.ACCOUNT_ID
      ? 'ACCOUNT'
      : 'CHANNEL TYPE'
  }

  return (
    <div className="campaign-exclusions-section__labels display-flex">
      <div
        className={cx('general-label', {
          'property-type-label-big': isCampaignNameProperty(),
          'property-type-label-small': !isCampaignNameProperty(),
        })}
      >
        PROPERTY TYPE
      </div>
      <div
        className={cx('general-label', {
          'label-error': rule.value === '' || rule.duplicated,
        })}
      >
        {getSecondLabel()}
      </div>
    </div>
  )
}

export default InputGroupHeading

InputGroupHeading.propTypes = {
  rule: PropTypes.object.isRequired,
}

