import React, { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useOutletContext, useNavigate } from 'react-router-dom'

import CreateEditCompany from 'modules/companies/create-edit'
import BulkEditCompanies from 'modules/companies/bulk-edit-companies'
import { useStore } from 'store'
import { getSuperAdmins } from 'modules/users/actions'
import { useAccess, PERMISSION_TYPES, PERMISSIONS } from 'hooks/access'
import { setBulkEditCompanies } from 'modules/companies/actions'
import { applyBulkEditChanges } from 'modules/companies/utils'

const CompanyEditPage = () => {
  const { isViewMode, company, setCompany, setDirty } = useOutletContext()
  const navigate = useNavigate()
  const { state, dispatch } = useStore()
  const { list: companies, bulkEditList } = state.companies

  const { superAdmins } = state.users
  const bulkEditingBus = useMemo(
    () => bulkEditList?.some((comp) => !!comp.parentCompany),
    [JSON.stringify(bulkEditList || [])]
  )

  const createMode = company.new
  const companyBus = company.businessUnits

  /**
   * Fetch super admins on component mount
   */
  useEffect(() => {
    if (!superAdmins.length) {
      getSuperAdmins(dispatch)
    }
  }, [])

  const hasEditAccess = useAccess({
    feature: PERMISSIONS.CLIENT_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })

  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.CLIENT_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })

  const pageTitle = () => {
    if (hasEditAccess || hasCreateAccess) {
      if (isViewMode) {
        return 'View Client'
      }

      if (createMode && hasCreateAccess) {
        return 'Add Client'
      }

      if (!createMode && hasEditAccess) {
        return 'Edit Client'
      }
    }

    return 'View Client'
  }

  // const applyBulkEditChangesToBus = (busChanges) => {
  //   const changedBus = applyBulkEditChanges(bulkEditList, busChanges)
  //   const modifiedBus = [...companyBus].map((existingBu) => {
  //     const modifiedBu = changedBus.find((bu) => bu._id === existingBu._id)
  //     return modifiedBu ? modifiedBu : existingBu
  //   })
  //   setCompany({ businessUnits: modifiedBus })
  //   setDirty(true)
  //   setBulkEditCompanies(dispatch, [])
  // }

  // const onCancelBulkEditChanges = () => {
  //   setBulkEditCompanies(dispatch, [])
  //   navigate(`/company/${company.clientId}`)
  // }

  return (
    <React.Fragment>
      {/* {bulkEditingBus && (
        <BulkEditCompanies
          onApplyChanges={applyBulkEditChangesToBus}
          onCancelChanges={onCancelBulkEditChanges}
        />
      )} */}
      {companies && !bulkEditingBus ? (
        <>
          <Helmet>
            <title>{pageTitle()}</title>
          </Helmet>
          <div className="heading" data-cy="page-heading">
            {pageTitle()}
          </div>
          <CreateEditCompany companyId={company._id} />
        </>
      ) : null}
    </React.Fragment>
  )
}

export default CompanyEditPage
