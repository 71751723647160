export const campaignConfig = {
  name: 'Campaign',
  type: 'object',
  fields: {
    ad_serving_optimization_status: {
      name: 'Ad Serving Optimization Status',
      type: 'enum',
      enums: [
        'Unspecified',
        'Unknown',
        'Optimize',
        'Conversion Optimize',
        'Rotate',
        'Rotate Indefinitely',
        'Unavailable',
      ],
    },
    advertising_channel_sub_type: {
      name: 'Advertising Channel Sub-Type',
      type: 'enum',
      enums: [
        'Unspecified',
        'Unknown',
        'Search Mobile App',
        'Display Mobile App',
        'Search Express',
        'Display Express',
        'Shopping Smart Ads',
        'Display Gmail Ad',
        'Display Smart Campaign',
        'Video Outstream',
        'Video Action',
        'Video Non Skippable',
        'App Campaign',
        'App Campaign For Engagement',
        'Shopping Comparison Listing Ads',
      ],
      action_type: 'input_once',
    },
    advertising_channel_type: {
      name: 'Advertising Channel Type',
      type: 'enum',
      enums: [
        'Unspecified',
        'Unknown',
        'Search',
        'Display',
        'Shopping',
        'Hotel',
        'Video',
        'Multi Channel',
      ],
      action_type: 'input_once',
    },
    app_campaign_setting: {
      name: 'App Campaign Settings',
      type: 'object',
      fields: {
        app_id: {
          name: 'App ID',
          type: 'string',
        },
        app_store: {
          name: 'App Store',
          type: 'enum',
          enums: [
            'Unspecified',
            'Unknown',
            'Apple App Store',
            'Google App Store',
          ],
        },
        bidding_strategy_goal_type: {
          name: 'Bidding Strategy Goal Type',
          type: 'enum',
          enums: [
            'Unspecified',
            'Unknown',
            'OPTIMIZE_IN_APP_CONVERSIONS_TARGET_INSTALL_COST',
            'OPTIMIZE_IN_APP_CONVERSIONS_TARGET_CONVERSION_COST',
            'OPTIMIZE_RETURN_ON_ADVERTISING_SPEND',
          ],
        },
      },
    },
    base_campaign: {
      name: 'Base Campaign',
      type: 'string',
      action_type: 'output',
    },
    bidding_strategy_type: {
      name: 'Bidding Strategy Type',
      type: 'enum',
      enums: [
        'UNSPECIFIED',
        'UNKNOWN',
        'ENHANCED_CPC',
        'MANUAL_CPC',
        'MANUAL_CPM',
        'PAGE_ONE_PROMOTED',
        'TARGET_CPA',
        'TARGET_OUTRANK_SHARE',
        'TARGET_ROAS',
        'TARGET_SPEND',
        'MAXIMIZE_CONVERSIONS',
        'MAXIMIZE_CONVERSION_VALUE',
        'PERCENT_CPC',
        'MANUAL_CPV',
        'TARGET_CPM',
        'TARGET_IMPRESSION_SHARE',
        'COMMISSION',
      ],
    },
    campaign_budget: {
      name: 'Campaign Budget',
      type: 'campaign_budget',
    },
    dynamic_search_ads_setting: {
      name: 'Dynamic Ads Setting',
      type: 'object',
      fields: {
        domain_name: {
          name: 'Domain Name',
          type: 'string',
        },
        feeds: {
          name: 'Feeds',
          type: 'array',
          action_type: 'output',
        },
        language_code: {
          name: 'Language Code',
          type: 'string',
        },
        use_supplied_urls_only: {
          name: 'Use Supply URLS only',
          type: 'boolean',
        },
      },
    },
    end_date: {
      name: 'End Date',
      type: 'string',
    },
    experiment_type: {
      name: 'Experiment Type',
      type: 'enum',
      enums: ['UNSPECIFIED', 'UNKNOWN', 'BASE', 'DRAFT', 'EXPERIMENT'],
    },
    final_url_suffix: {
      name: 'Final URL Suffix',
      type: 'string',
    },
    frequency_caps: {
      name: 'Frequency Caps',
      type: 'array',
      array_fields: {
        cap: {
          name: 'Cap',
          type: 'number',
        },
        key: {
          name: 'Key',
          type: 'object',
          fields: {
            event_type: {
              name: 'Event Type',
              type: 'enum',
              enums: [
                'UNSPECIFIED',
                'UNKNOWN',
                'AD_GROUP_AD',
                'AD_GROUP',
                'CAMPAIGN',
              ],
            },
            time_length: {
              name: 'Time Length',
              type: 'number',
            },
            time_unit: {
              name: 'Time Unit',
              type: 'enum',
              enums: ['UNSPECIFIED', 'UNKNOWN', 'DAY', 'WEEK', 'MONTH'],
            },
          },
        },
      },
    },
    geo_target_type_setting: {
      name: 'Geo Target Type Settings',
      type: 'object',
      fields: {
        negative_geo_target_type: {
          name: 'Negative Geotarget Type',
          type: 'enum',
          enums: {
            '0': 'UNSPECIFIED',
            '1': 'UNKNOWN',
            '4': 'PRESENCE_OR_INTEREST',
            '5': 'PRESENCE',
          },
        },
        positive_geo_target_type: {
          name: 'Positive Geotarget Type',
          type: 'enum',
          enums: {
            '0': 'UNSPECIFIED',
            '1': 'UNKNOWN',
            '5': 'PRESENCE_OR_INTEREST',
            '6': 'SEARCH_INTEREST',
            '7': 'PRESENCE',
          },
        },
      },
    },
    hotel_setting: {
      name: 'Hotel Settings',
      type: 'object',
      fields: {
        hotel_center_id: {
          name: 'Hotel Center ID',
          type: 'number',
        },
      },
    },
    id: {
      name: 'Campaign ID',
      type: 'number',
      action_type: 'output',
    },
    labels: {
      name: 'Labels',
      type: 'array',
    },
    name: {
      name: 'Campaign Name',
      type: 'string',
    },
    network_settings: {
      name: 'Network Settings',
      type: 'object',
      fields: {
        target_content_network: {
          name: 'Target Content Network',
          type: 'boolean',
        },
        target_google_search: {
          name: 'Target Google Search',
          type: 'boolean',
        },
        target_partner_search_network: {
          name: 'Target Partner search network',
          type: 'boolean',
        },
        target_search_network: {
          name: 'Target Search network',
          type: 'boolean',
        },
      },
    },
    payment_mode: {
      name: 'Payment Mode',
      type: 'enum',
      enums: {
        '0': 'UNSPECIFIED',
        '1': 'UNKNOWN',
        '4': 'CLICKS',
        '5': 'CONVERSION_VALUE',
        '6': 'CONVERSIONS',
        '7': 'GUEST_STAY',
      },
    },
    real_time_bidding_setting: {
      name: 'Real Time Bidding Setting',
      type: 'object',
      fields: {
        opt_in: {
          name: 'Opt In',
          type: 'boolean',
        },
      },
    },
    resource_name: {
      name: 'Resource Name',
      type: 'string',
      action_type: 'output',
    },
    selective_optimization: {
      name: 'Selective Optimization',
      type: 'object',
      fields: {
        conversion_actions: {
          name: 'Conversion Actions',
          type: 'array',
        },
      },
    },
    serving_status: {
      name: 'Serving Status',
      type: 'enum',
      enums: [
        'UNSPECIFIED',
        'UNKNOWN',
        'SERVING',
        'NONE',
        'ENDED',
        'PENDING',
        'SUSPENDED',
      ],
    },
    shopping_setting: {
      name: 'Shopping Setting',
      type: 'object',
      fields: {
        campaign_priority: {
          name: 'Campaign Priority',
          type: 'number',
        },
        enable_local: {
          name: 'Enable Local',
          type: 'boolean',
        },
        merchant_id: {
          name: 'Merchant ID',
          type: 'number',
        },
        sales_country: {
          name: 'Sales Country',
          type: 'string',
        },
      },
    },
    start_date: {
      name: 'Start Date',
      type: 'string',
    },
    status: {
      name: 'Campaign Status',
      type: 'enum',
      enums: ['UNSPECIFIED', 'UNKNOWN', 'ENABLED', 'PAUSED', 'REMOVED'],
    },
    targeting_setting: {
      name: 'Targeting Setting',
      type: 'object',
      fields: {
        target_restriction_operations: {
          name: 'Target Restriction Operations',
          type: 'array',
          array_fields: {
            operator: {
              name: 'Operator',
              type: 'enum',
              enums: ['UNSPECIFIED', 'UNKNOWN', 'ADD', 'REMOVE'],
            },
            value: {
              name: 'Value',
              type: 'object',
              fields: {
                bid_only: {
                  name: 'Bid Only',
                  type: 'boolean',
                },
                targeting_dimension: {
                  name: 'Targeting Dimension',
                  type: 'enum',
                  enums: [
                    'UNSPECIFIED',
                    'UNKNOWN',
                    'KEYWORD',
                    'AUDIENCE',
                    'TOPIC',
                    'GENDER',
                    'AGE_RANGE',
                    'PLACEMENT',
                    'PARENTAL_STATUS',
                    'INCOME_RANGE',
                  ],
                },
              },
            },
          },
        },
        target_restrictions: {
          name: 'Target Restrictions',
          type: 'object_array',
          array_fields: {
            bid_only: {
              name: 'Bid Only',
              type: 'boolean',
            },
            targeting_dimension: {
              name: 'Targeting Dimension',
              type: 'enum',
              enums: [
                'UNSPECIFIED',
                'UNKNOWN',
                'KEYWORD',
                'AUDIENCE',
                'TOPIC',
                'GENDER',
                'AGE_RANGE',
                'PLACEMENT',
                'PARENTAL_STATUS',
                'INCOME_RANGE',
              ],
            },
          },
        },
      },
    },
    tracking_settings: {
      name: 'Tracking Settings',
      type: 'object',
      action_type: 'output',
      fields: {
        tracking_url: {
          name: 'Tracking URL',
          type: 'string',
        },
      },
    },
    tracking_url_template: {
      name: 'Tracking URL template',
      type: 'string',
    },
    url_custom_parameters: {
      name: 'Custom URL Parameters',
      type: 'array',
      array_fields: {
        key: {
          name: 'Key',
          type: 'string',
        },
        value: {
          name: 'Value',
          type: 'string',
        },
      },
    },
    vanity_pharma: {
      name: 'Vanity Pharma',
      type: 'object',
      fields: {
        vanity_pharma_display_url_mode: {
          name: 'Vanity Pharma Display URL mode',
          type: 'enum',
          enums: [
            'UNSPECIFIED',
            'UNKNOWN',
            'MANUFACTURER_WEBSITE_URL',
            'WEBSITE_DESCRIPTION',
          ],
        },
      },
    },
    video_brand_safety_suitability: {
      name: 'Video Brand Safety Suitability',
      type: 'enum',
      action_type: 'output',
      enums: [
        'UNSPECIFIED',
        'UNKNOWN',
        'EXPANDED_INVENTORY',
        'STANDARD_INVENTORY',
        'LIMITED_INVENTORY',
      ],
    },
  },
}
