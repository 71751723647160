import React from 'react'
import PropTypes from 'prop-types'

/* Utils */
import cx from 'classnames'

/* Components */
import UploadDefaultMessage from './upload-default-message'

/* Constants */
import { FILE_UPLOAD_STATE } from '../constants'

/**
 * Renders the default state of the file upload component
 * @param {Object} props - The component's react props
 * @param {String} props.render - What to render on default state (otherwise it will fallback to the default message)
 * @param {Number} props.state - The current display state of the parent component
 * @param {Boolean} props.disabled - A flag that enforces the disable of the upload functionality
 * @param {Boolean} props.dark - Flag to change styling for dark mode
 */
const UploadDefaultState = ({ state, render, disabled, dark }) => {
  return state === FILE_UPLOAD_STATE.DEFAULT ? (
    <div
      className={cx(
        'upload-modal__dropzone align-column align-center',
        {
          'displayed-under': state === FILE_UPLOAD_STATE.DRAG_OVER,
          'upload-modal__dropzone--dark': dark,
        },
        {
          disabled: disabled,
        }
      )}
    >
      <div
        className={cx('upload-modal__dropzone__message', {
          'upload-modal__dropzone__message--dark': dark,
        })}
      >
        <UploadDefaultMessage render={render} />
      </div>
    </div>
  ) : null
}

UploadDefaultState.propTypes = {
  state: PropTypes.number.isRequired,
  render: PropTypes.any,
  disabled: PropTypes.bool,
  dark: PropTypes.bool,
}

export default UploadDefaultState
