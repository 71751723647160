import React from 'react'

import { useStore } from 'store'
import Notification from './notification'
import { clearNotification } from './actions'

import './style.scss'

// General Component for notifications
// Select Action from actions.js (success, error, alert, clear)
// Action required param will be the message itself
// Depending on the action chosen it will set the notification type
// An Individual Id will be created on the reducer

const Notifications = () => {
  const { state, dispatch } = useStore()
  const { notifications } = state.notifications

  return (
    <div
      id="notifications-container"
      className="notification-container align-column"
    >
      {notifications.map((notification, n) => (
        <Notification
          key={n}
          data={notification}
          clearNotification={clearNotification}
          dispatch={dispatch}
        />
      ))}
    </div>
  )
}

export default Notifications
