import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import InputText from 'components/input'
import Button from 'components/button'
import { slackMessage } from 'modules/slack/actions'
import Loader from 'components/loader/index'

const Slack = ({ userList }) => {
  const [message, setMessage] = useState('')
  const { state, dispatch } = useStore()
  const [loading, setLoading] = useState(false)
  const { companies } = state

  const [customSlackChannel, setCustomSlackChannel] = useState(
    companies.currentCompany.slack
  )

  const convertMessage = () => {
    const tags = message.match(/(@)\w+[_ .]*\w*/g)
    const newMessage = message.split(/(@)\w+[_ .]*\w*/g).map((messagePart) => {
      if (messagePart === '@') {
        if (tags && tags.length) {
          const name = tags ? tags.shift().slice(1) : ''
          const [firstName, lastName] = name.split(' ')
          const user = userList.find((user) => {
            if (lastName) {
              return (
                (user.name.indexOf(firstName) !== -1 &&
                  user.name.indexOf(lastName) !== -1) ||
                (user.profile.real_name.indexOf(firstName) !== -1 &&
                  user.profile.real_name.indexOf(lastName) !== -1)
              )
            }
            return (
              user.name.indexOf(firstName) !== -1 ||
              user.profile.real_name.indexOf(firstName) !== -1
            )
          })
          if (user) {
            return `<@${user.id}>`
          } else {
            return `@${name}`
          }
        }
      }
      return messagePart
    })
    return newMessage.join('')
  }
  const sendMessage = () => {
    setLoading(true)
    slackMessage(dispatch, convertMessage(), customSlackChannel).finally(() => {
      setLoading(false)
    })
    setMessage('')
  }
  return (
    <>
      <InputText
        disabled={loading}
        label="Slack Channel (Selected Client's Slack Channel by default)"
        placeholder="Input Slack Channel"
        value={customSlackChannel}
        onChange={setCustomSlackChannel}
      />
      <InputText
        disabled={loading}
        label="Message"
        onEnterKeyPressed={sendMessage}
        placeholder="Input Slack Message"
        value={message}
        onChange={setMessage}
      />
      <div className="align-row align-right margin-top-22">
        {loading && <Loader inline size={36} />}
        <Button disabled={loading} value="Send" onClick={sendMessage} />
      </div>
    </>
  )
}
Slack.propTypes = {
  userList: PropTypes.array,
}

export default Slack
