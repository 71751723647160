import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import InputText from 'components/input'
import Button from 'components/button'
import {
  FIELD_TYPES,
  ERRORS,
  isValidPassword,
  validate,
} from 'components/validator'
import { updateUserFromSecurity, checkOldPassword } from 'modules/users/actions'

/**
 *
 * @param {Object} params params
 * @param {Function} params.close close modal
 * @param {Boolean} params.unClosable the modal cannot be closed
 * @param {Function} params.afterSave function to execute after updating the password
 * @returns {React.Component}
 */
const ChangePasswordForm = ({ close, unClosable, afterSave }) => {
  const { dispatch, state } = useStore()
  const { userData } = state.session
  const [user, setUser] = useState({
    _id: userData._id,
    password: '',
    repeatPassword: '',
    oldPassword: '',
  })

  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const isDisabledSaveButtonNoValues = !(
    user.password &&
    user.repeatPassword &&
    user.oldPassword
  )
  const isDisabledSaveButtonErrors =
    errors.general ||
    errors.password ||
    errors.repeatPassword ||
    errors.oldPassword

  /** On any field update, remove the error corresponding to it */
  const onFieldUpdate = (fieldName) => {
    setErrors({ ...errors, [fieldName]: null, general: null })
  }

  const clearData = () => {
    setUser({ ...user, password: '', repeatPassword: '', oldPassword: '' })
    setErrors({})
  }

  /** Edit a field */
  const editField = (fieldName, value) => {
    const userFields = { ...user }
    userFields[fieldName] = value
    setUser(userFields)
    onFieldUpdate(fieldName)
  }

  const compareOldPasswords = (oldPassword) => {
    if (oldPassword) {
      checkOldPassword(oldPassword)
        .then((res) => {
          if (res.error) {
            return setErrors({ oldPassword: res.error })
          }
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          setErrors({ ...errors, general: err })
        })
    }
  }

  const checkPassword = (pass, recheck) => {
    const errorField = recheck ? 'repeatPassword' : 'password'
    if (pass) {
      if (!isValidPassword(pass)) {
        return setErrors({
          ...errors,
          [errorField]: ERRORS.PASSWORD,
        })
      }
      if (
        user.password &&
        user.repeatPassword &&
        user.password !== user.repeatPassword
      ) {
        return setErrors({
          ...errors,
          [errorField]: 'The passwords do not match.',
        })
      }

      setErrors({})
    }
  }

  /** Save handler */
  const onSave = (ev) => {
    ev.preventDefault()
    setErrors({})
    setLoading(true)

    /* Validate */
    const checkValidFields = {
      password: user.password,
      repeatPassword: user.repeatPassword,
    }
    const [isValid, errors] = validate(ERROR_MAP, checkValidFields)
    if (!isValid) {
      setLoading(false)
      return setErrors(errors)
    }

    if (user.oldPassword === user.password) {
      setLoading(false)
      return setErrors({
        ...errors,
        general: 'Old and new passwords are the same.',
      })
    }

    if (user.repeatPassword !== user.password) {
      setLoading(false)
      return setErrors({
        ...errors,
        general: 'The passwords do not match.',
      })
    }

    const userToSave = {
      _id: userData._id,
      password: user.password,
      oldPassword: user.oldPassword,
    }
    /* Save the user */
    updateUserFromSecurity(dispatch, userToSave)
      .then((res) => {
        if (res.error) {
          return setErrors({ general: res.error })
        }
        setLoading(false)
        setUser({ ...user, password: '', repeatPassword: '', oldPassword: '' })
        afterSave()
        close()
      })
      .catch((err) => {
        setLoading(false)
        setErrors({ ...errors, general: err })
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="align-column">
      <form onSubmit={onSave} className="my-account-user__passwords">
        <div className="heading-secondary">
          {unClosable ? 'Password Expired' : 'Change Password'}{' '}
        </div>
        {unClosable ? (
          <p className="my-account-user__passwords__hint">
            Your password has expired and needs to be updated.{' '}
          </p>
        ) : null}
        <div className="align-column">
          <InputText
            type="password"
            label={'Old password'}
            value={user.oldPassword}
            onChange={editField.bind(null, 'oldPassword')}
            error={errors.oldPassword}
            blur={() => compareOldPasswords(user.oldPassword)}
            disabled={loading}
            placeholder="Enter Old Password"
            className="input-wrapper--uppercase"
          />
          <InputText
            type="password"
            value={user.password}
            label={'New password'}
            onChange={editField.bind(null, 'password')}
            error={errors.password}
            blur={() => checkPassword(user.password, false)}
            disabled={loading}
            placeholder="Enter New Password"
            className="input-wrapper--uppercase"
          />
          <InputText
            type="password"
            value={user.repeatPassword}
            label={'Confirm New password'}
            onChange={editField.bind(null, 'repeatPassword')}
            error={errors.repeatPassword}
            blur={() => checkPassword(user.repeatPassword, true)}
            disabled={loading}
            placeholder="Enter New Password"
            className="input-wrapper--uppercase"
          />
        </div>
        {errors && errors.general ? (
          <div className="error">{errors.general}</div>
        ) : null}
        <div className="align-row confirm-buttons align-right">
          <Button
            value="Save"
            onClick={onSave}
            disabled={
              loading ||
              isDisabledSaveButtonNoValues ||
              !!isDisabledSaveButtonErrors
            }
          />
          {!unClosable ? (
            <Button
              value="Cancel"
              onClick={() => {
                clearData()
                close()
              }}
              disabled={loading}
              secondaryGray
            />
          ) : null}
        </div>
      </form>
    </div>
  )
}

const ERROR_MAP = {
  password: [FIELD_TYPES.PASSWORD, FIELD_TYPES.REQUIRED],
  repeatPassword: [FIELD_TYPES.PASSWORD, FIELD_TYPES.REQUIRED],
}

ChangePasswordForm.propTypes = {
  close: PropTypes.func,
  unClosable: PropTypes.bool,
  afterSave: PropTypes.func.isRequired,
}

export default ChangePasswordForm
