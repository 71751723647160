import { v4 as uuidv4 } from 'uuid'
import Api from 'easy-fetch-api'

const NOTIFICATION_AUTOCLOSE_TIME = 5000

export const ACTIONS = {
  SHOW_SUCCESS: 'notifications.showSuccessMessage',
  SHOW_WARNING: 'notifications.showWarningMessage',
  SHOW_ERROR: 'notifications.showErrorMessage',
  SHOW_ALERT: 'notifications.showAlertMessage',
  CLEAR_NOTIFICATION: 'notifications.clearNotification',
  CLEAR_ALERT_NOTIFICATIONS: 'notifications.clearUserNotifications',
  GET_USER_NOTIFICATIONS: 'notifications.userNotifications',
  MARK_NOTIFICATION_AS_READ: 'notifications.markAsRead',
  MARK_ALL_NOTIFICATIONS_AS_READ: 'notifications.markAllAsRead',
  DISABLE_NOTIFICATION: 'notifications.disableNotification',
  REMOVE_ALERT_NOTIFICATION: 'notifications.removeUserNotification',
  ADD_ALERT_NOTIFICATION: 'notifications.addAlertNotification',
  GET_USER_NOTIFICATIONS_LAZY: 'notifications.getUserNotificationsLazy',
  ADD_USER_NOTIFICATIONS: 'notifications.addUserNotificationsLazy',
  EMPTY_OUT_NOTIFICATIONS: 'notifications.emptyOutUserNotifications',
  ADD_NOTIFICATION_TO_TOP: 'notifications.addToTop',
  TOGGLE_COMPANY_FILTER: 'notifications.toggleCompanyFilter',
  TOGGLE_ALERT_FILTER: 'notifications.toggleAlertilter',
  TOGGLE_BRIEF_FILTER: 'notifications.toggleBriefFilter',
  TOGGLE_UNREAD_ONLY: 'notifications.toggleBUnreadOnlyFilter',
}

export const TOAST_NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
}

export const showSuccessMessage = (message, dispatch, autoHide = true) => {
  const id = uuidv4()
  dispatch({ type: ACTIONS.SHOW_SUCCESS, message, id })
  setTimeout(() => {
    autoHide && dispatch({ type: ACTIONS.CLEAR_NOTIFICATION, id })
  }, NOTIFICATION_AUTOCLOSE_TIME)
}

export const showWarningMessage = (message, dispatch, autoHide = true) => {
  const id = uuidv4()
  dispatch({ type: ACTIONS.SHOW_WARNING, message, id })
  setTimeout(() => {
    autoHide && dispatch({ type: ACTIONS.CLEAR_NOTIFICATION, id })
  }, NOTIFICATION_AUTOCLOSE_TIME)
}

export const showErrorMessage = (message, dispatch, autoHide = true) => {
  const id = uuidv4()
  if (typeof message === 'object') {
    message = JSON.stringify(message)
  }

  // Also log error in case error is too long to read quickly
  dispatch({ type: ACTIONS.SHOW_ERROR, message, id })
  setTimeout(() => {
    autoHide && dispatch({ type: ACTIONS.CLEAR_NOTIFICATION, id })
  }, NOTIFICATION_AUTOCLOSE_TIME)
}

export const showAlertMessage = (message, dispatch) => {
  dispatch({ type: ACTIONS.SHOW_ALERT, message, id: uuidv4() })
}

/**
 * Fetch notifications for a user paginated
 * @param {Function} dispatch
 * @param {String} userId user id
 * @param {Object} filters filters
 * @param {Number} batchSize batch size
 * @param {Array} [nrNotificationsToLoad] tuple of [min, max] elements to bring
 */
export const getNotificationsLazy = async (
  dispatch,
  userId,
  filters,
  batchSize,
  nrNotificationsToLoad
) => {
  dispatch({
    type: ACTIONS.GET_USER_NOTIFICATIONS_LAZY,
    userNotifications: { loading: true },
  })

  if (!nrNotificationsToLoad || !nrNotificationsToLoad[1]) {
    nrNotificationsToLoad = [0, batchSize]
  }

  const {
    selectedClient,
    viewUnreadOnly,
    showAlertNotifications,
    showBriefNotifications,
  } = filters

  const res = await Api.get({
    url: `/api/notifications`,
    query: {
      selectedClient,
      numberOfItemsToLoad: nrNotificationsToLoad,
      viewUnreadOnly,
      showAlertNotifications,
      showBriefNotifications,
    },
  }).catch((err) => {
    console.error(`Error getting Notifications`, err)
  })

  if (!res) {
    return
  }

  if (!res.success) {
    dispatch({
      type: ACTIONS.GET_USER_NOTIFICATIONS_LAZY,
      userNotifications: { loading: false },
    })
    return console.error(res.error || 'Error getting user notifications')
  }

  // If getting first batch, empty the already brought ones
  if (nrNotificationsToLoad[0] === 0) {
    dispatch({
      type: ACTIONS.EMPTY_OUT_NOTIFICATIONS,
    })
  }

  // If there are elements left unloaded
  if (res.result?.elementsLeft) {
    dispatch({
      user: userId,
      type: ACTIONS.ADD_USER_NOTIFICATIONS,
      data: res.result?.notifications,
      loading: false,
      company: selectedClient,
      nrNotificationsToLoad: nrNotificationsToLoad.map(
        (nr) => parseInt(nr, 10) + batchSize
      ),
      elementsLeft: true,
    })
  } else {
    dispatch({
      user: userId,
      type: ACTIONS.ADD_USER_NOTIFICATIONS,
      data: res.result?.notifications,
      loading: false,
      company: selectedClient,
      elementsLeft: false,
    })
  }
}

/**
 * Mark notification as read
 * @param {String} id alert trigger id of notitifaction to mark
 * @param {Function} dispatch dispatch function
 * @returns {Function}
 */
export const markNotificationAsRead = (id, dispatch) =>
  Api.post({
    url: `/api/notifications/mark-as-read/`,
    data: { ids: [id] },
  })
    .then((res) => {
      if (!res.success) {
        return console.error(res.error || 'Error marking notification as read')
      }
      dispatch({
        type: ACTIONS.MARK_NOTIFICATION_AS_READ,
        id,
      })
    })
    .catch(console.error)

/**
 * Mark all notifications as read
 * @param {Array<Object>} items notitifactions to mark
 * @param {Function} dispatch dispatch function
 * @returns {Function}
 */
export const markAllNotificationsAsRead = (items, dispatch) =>
  Api.post({
    url: `/api/notifications/mark-as-read/`,
    data: { ids: items.map((d) => d.id) },
  })
    .then((res) => {
      if (!res.success) {
        return console.error(
          res.error || 'Error marking all notifications as read'
        )
      }
      dispatch({
        type: ACTIONS.MARK_ALL_NOTIFICATIONS_AS_READ,
      })
    })
    .catch(console.error)

/**
 * Clear notifications
 * @param {Function} dispatch dispatch function
 * @param {Array<Object>} items notitifactions to clear
 * @returns {Function}
 */
export const clearNotifications = (dispatch, items) => {
  return Api.post({
    url: `/api/notifications/clear`,
    data: {
      ids: items.map(({ id }) => id),
    },
  })
    .then((res) => {
      if (!res.success) {
        return console.error(res.error || 'Error clearing notification')
      }

      dispatch({
        type: ACTIONS.CLEAR_ALERT_NOTIFICATIONS,
        ids: items.map(({ id }) => id),
      })
    })
    .catch(console.error)
}

/**
 * Disable a notification
 * @param {String} id alert trigger id of notitifaction to disable
 * @param {Function} dispatch dispatch function
 * @returns {Function}
 */
export const disableNotification = (id, dispatch) =>
  dispatch({
    type: ACTIONS.DISABLE_NOTIFICATION,
    id,
  })

/**
 * Remove a notification
 * @param {String} alertTriggerId alert trigger id of notitifaction to remove
 * @param {Function} dispatch dispatch function
 * @returns {Function}
 */
export const removeAlertNotification = (alertTriggerId, dispatch) =>
  dispatch({
    type: ACTIONS.REMOVE_ALERT_NOTIFICATION,
    alertTriggerId,
  })

export const clearNotification = (id, dispatch) => {
  dispatch({ type: ACTIONS.CLEAR_NOTIFICATION, id })
}

export const addNotificationToTop = (dispatch, notification) =>
  dispatch({ type: ACTIONS.ADD_NOTIFICATION_TO_TOP, notification })

export const setShowCompanySpecificNotifications = (
  dispatch,
  showCompanySpecificNotifications
) =>
  dispatch({
    type: ACTIONS.TOGGLE_COMPANY_FILTER,
    showCompanySpecificNotifications,
  })

export const setShowAlertNotifications = (dispatch, showAlertNotifications) =>
  dispatch({ type: ACTIONS.TOGGLE_ALERT_FILTER, showAlertNotifications })

export const setShowBriefNotifications = (dispatch, showBriefNotifications) =>
  dispatch({ type: ACTIONS.TOGGLE_BRIEF_FILTER, showBriefNotifications })

export const setViewUnreadOnly = (dispatch, viewUnreadOnly) =>
  dispatch({ type: ACTIONS.TOGGLE_UNREAD_ONLY, viewUnreadOnly })
