export const ACTIONS = {
  SAVE_SORT: 'tableFilterSort.saveSort',
  SAVE_FILTER: 'tableFilterSort.saveFilter',
  RESET_FILTER_SORT: 'tableFilterSort.resetFilter',
  SAVE_PAGINATION: 'tableFilterSort.savePagination',
}

export const saveSort = (dispatch, tableContainer, sort) => {
  dispatch({
    type: ACTIONS.SAVE_SORT,
    tableContainer,
    sort,
  })
}

export const saveFilter = (dispatch, tableContainer, filter) => {
  dispatch({
    type: ACTIONS.SAVE_FILTER,
    tableContainer,
    filter,
  })
}

export const resetFilterSort = (dispatch, tableContainer) => {
  dispatch({
    type: ACTIONS.RESET_FILTER_SORT,
    tableContainer,
  })
}

export const savePagination = (dispatch, tableContainer, pagination) => {
  dispatch({
    type: ACTIONS.SAVE_PAGINATION,
    tableContainer,
    pagination,
  })
}
