import uniqBy from 'lodash.uniqby'
import { ACTIONS, TOAST_NOTIFICATION_TYPES } from './actions'

/** Initial State of the reducer */
const initialState = {
  notifications: [],
  userNotifications: {
    /* Used by the app wrapper to know whether it has to re-fetch notifications */
    user: null,
    data: [],
    loading: false,
    /* Used by the app wrapper to know whether it has to re-fetch notifications */
    company: null,
    nrNotificationsToLoad: null,
    /* There unfetched notifications */
    elementsLeft: true,
    showCompanySpecificNotifications: true,
    showAlertNotifications: true,
    showBriefNotifications: true,
    viewUnreadOnly: false,
  },
}

/** Name of the reducer */
const name = 'notifications'

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.SHOW_SUCCESS]: (state, { message, id }) => {
    return {
      ...state,
      notifications: [
        ...state.notifications,
        { id, type: TOAST_NOTIFICATION_TYPES.SUCCESS, message },
      ],
    }
  },

  [ACTIONS.SHOW_WARNING]: (state, { message, id }) => {
    return {
      ...state,
      notifications: [
        ...state.notifications,
        { id, type: TOAST_NOTIFICATION_TYPES.WARNING, message },
      ],
    }
  },

  [ACTIONS.SHOW_ERROR]: (state, { message, id }) => {
    return {
      ...state,
      notifications: [
        ...state.notifications,
        { id, type: TOAST_NOTIFICATION_TYPES.ERROR, message },
      ],
    }
  },

  [ACTIONS.CLEAR_NOTIFICATION]: (state, { id }) => {
    return {
      ...state,
      notifications: [...state.notifications].filter(
        (notification) => notification.id !== id
      ),
    }
  },

  /* ------ Notifications for Notification Pane ------- */

  [ACTIONS.GET_USER_NOTIFICATIONS]: (state, { userNotifications }) => ({
    ...state,
    userNotifications: {
      ...state.userNotifications,
      ...userNotifications,
      data: [
        ...(state.userNotifications?.data || []),
        ...(userNotifications?.data || []),
      ],
    },
  }),

  [ACTIONS.EMPTY_OUT_NOTIFICATIONS]: (state) => ({
    ...state,
    userNotifications: {
      ...state.userNotifications,
      data: [],
    },
  }),

  [ACTIONS.ADD_NOTIFICATION_TO_TOP]: (state, { notification }) => ({
    ...state,
    userNotifications: {
      ...state.userNotifications,
      data: [notification, ...(state.userNotifications.data || [])],
    },
  }),

  [ACTIONS.GET_USER_NOTIFICATIONS_LAZY]: (state, { userNotifications }) => ({
    ...state,
    userNotifications: {
      ...state.userNotifications,
      ...userNotifications,
      data: [
        ...(state.userNotifications.data || []),
        ...(userNotifications.data || []),
      ],
    },
  }),

  [ACTIONS.ADD_USER_NOTIFICATIONS]: (state, { data, ...rest }) => ({
    ...state,
    userNotifications: {
      ...state.userNotifications,
      data: uniqBy(
        [...(state.userNotifications.data || []), ...(data || [])],
        'id'
      ),
      ...rest,
    },
  }),

  [ACTIONS.MARK_NOTIFICATION_AS_READ]: (state, { id }) => ({
    ...state,
    userNotifications: {
      ...state.userNotifications,
      data: state.userNotifications.data.map((item) =>
        item.id === id ? { ...item, read: true } : item
      ),
    },
  }),

  [ACTIONS.MARK_ALL_NOTIFICATIONS_AS_READ]: (state) => ({
    ...state,
    userNotifications: {
      ...state.userNotifications,
      data: state.userNotifications.data.map((item) => ({
        ...item,
        read: true,
      })),
    },
  }),

  [ACTIONS.CLEAR_ALERT_NOTIFICATIONS]: (state, { ids }) => ({
    ...state,
    userNotifications: {
      ...state.userNotifications,
      data: state.userNotifications.data.filter(
        (notification) => !ids.some((id) => notification.id === id)
      ),
    },
  }),

  [ACTIONS.DISABLE_NOTIFICATION]: (state, { id }) => ({
    ...state,
    userNotifications: {
      ...state.userNotifications,
      data: state.userNotifications.data.map((notification) =>
        notification.id === id
          ? { ...notification, disabled: true }
          : notification
      ),
    },
  }),

  [ACTIONS.REMOVE_ALERT_NOTIFICATION]: (state, { alertTriggerId }) => ({
    ...state,
    userNotifications: {
      ...state.userNotifications,
      data: state.userNotifications.data.filter(
        (item) => item.alertTriggerId !== alertTriggerId
      ),
    },
  }),

  [ACTIONS.ADD_ALERT_NOTIFICATION]: (state, { data }) => ({
    ...state,
    userNotifications: {
      ...state.userNotifications,
      data: uniqBy(
        [
          {
            ...(state.userNotifications?.data?.find((notif) => notif.top) ||
              {}),
          },
          { ...data },
          ...(state.userNotifications?.data?.filter((notif) => !notif.top) ||
            []),
        ],
        'id'
      ),
    },
  }),

  [ACTIONS.TOGGLE_COMPANY_FILTER]: (
    state,
    { showCompanySpecificNotifications }
  ) => ({
    ...state,
    userNotifications: {
      ...state.userNotifications,
      showCompanySpecificNotifications,
    },
  }),

  [ACTIONS.TOGGLE_ALERT_FILTER]: (state, { showAlertNotifications }) => ({
    ...state,
    userNotifications: {
      ...state.userNotifications,
      showAlertNotifications,
      // If deactivating alert notifications, make sure the brief notifications are visible
      showBriefNotifications: !showAlertNotifications
        ? true
        : state.userNotifications.showBriefNotifications,
    },
  }),

  [ACTIONS.TOGGLE_BRIEF_FILTER]: (state, { showBriefNotifications }) => ({
    ...state,
    userNotifications: {
      ...state.userNotifications,
      showBriefNotifications,
      // If deactivating brief notifications, make sure the alert notifications are visible
      showAlertNotifications: !showBriefNotifications
        ? true
        : state.userNotifications.showAlertNotifications,
    },
  }),

  [ACTIONS.TOGGLE_UNREAD_ONLY]: (state, { viewUnreadOnly }) => ({
    ...state,
    userNotifications: { ...state.userNotifications, viewUnreadOnly },
  }),
}

export default { initialState, name, reduce }
