import React from 'react'
import PropTypes from 'prop-types'

/* Constants */
import { FILE_UPLOAD_STATE } from '../constants'

/**
 * Displays the on drag overlay for the file upload component
 * @param {Object} props - The component's react props
 * @param {Number} props.state - The current display state of the parent component
 */
export const DragOverlay = ({ state }) => {
  /* Renders a light blue overlay above the file upload component
    when the user hovers it while dragging one more more files */
  return state === FILE_UPLOAD_STATE.DRAG_OVER ? (
    <>
      <div className="upload-modal__empty-box">
        <div className="upload-modal__empty-box__overlay-rectangle" />
      </div>
    </>
  ) : (
    ''
  )
}

DragOverlay.propTypes = {
  state: PropTypes.number.isRequired,
}

export default DragOverlay
