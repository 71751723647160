import React, { useState } from 'react'
import { format } from 'date-fns'
import { useNavigate, useLocation } from 'react-router-dom'

import { useStore } from 'store'
import { PERMISSIONS, PERMISSION_TYPES, useAccess } from 'hooks/access'

import cx from 'classnames'
import Button from 'components/button/index'
import Modal from 'components/modal/index'
import Table from 'components/table/beta'
import Toggle from 'components/toggle/index'

import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'

import {
  deleteLegalSection,
  reorderLegalSections,
  toggleLegalSectionDisplay,
} from '../actions'

const LegalSectionsTable = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { dispatch, state } = useStore()

  const {
    legal: { currentEditedLegal },
  } = state

  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.LEGAL_SETTINGS,
    type: PERMISSION_TYPES.DELETE,
  })

  const [loading, setLoading] = useState(false)
  const [sectionToDelete, setSectionToDelete] = useState(null)

  const columns = [
    {
      header: ' ',
      cell: (cell) => {
        const { _id } = cell.row.original
        return (
          <div className="table__actions align-center">
            <div
              className={'table__edit'}
              onClick={() => {
                setTimeout(() => {
                  navigate(`${pathname}/section/${_id || 'new'}`)
                }, 100)
              }}
            />
          </div>
        )
      },
      size: 40,
    },
    {
      header: 'Section name',
      accessorKey: 'name',
      textAlign: 'left',
      size: 150,
    },
    {
      header: 'Last Modified',
      accessorFn: (row) => {
        return row.lastModified
          ? format(new Date(row.lastModified), 'MM/dd/yyyy')
          : '-'
      },
      size: 100,
      textAlign: 'left',
    },
    {
      id: 'display-toggle',
      header: 'Display',
      cell: (cell) => {
        const { _id, display } = cell.row.original
        return (
          <Toggle
            reversed
            value={display}
            defaultChecked={display}
            onChange={(checked) =>
              toggleLegalSectionDisplay(dispatch, _id, checked)
            }
          />
        )
      },
      size: 40,
    },
    {
      id: 'delete',
      header: '',
      cell: (cell) => {
        if (!hasDeleteAccess) {
          return null
        }
        const disabled = loading
        return (
          <DeleteIcon
            className={cx('table__delete', {
              disabled,
              'fill-grey': disabled,
              'fill-red': !disabled,
            })}
            alt={'delete'}
            onClick={() => {
              setSectionToDelete(cell.row.original)
            }}
          />
        )
      },
      size: 40,
    },
  ]

  const onDelete = () => {
    if (hasDeleteAccess) {
      setLoading(true)
      deleteLegalSection(dispatch, sectionToDelete._id)
        .then(() => {
          setSectionToDelete(null)
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <>
      <Table
        withDragDrop
        enableSorting={false}
        onDragDrop={(reorderedArray) =>
          reorderLegalSections(dispatch, currentEditedLegal, reorderedArray)
        }
        columns={columns}
        data={currentEditedLegal.sections}
      />
      {sectionToDelete && (
        <Modal
          opened={!!sectionToDelete}
          button={
            <Button
              disabled={loading}
              value="Delete"
              onClick={() => onDelete()}
            />
          }
          buttonSecondary={
            <Button
              disabled={loading}
              value="Cancel"
              onClick={() => setSectionToDelete(null)}
              secondaryGray
            />
          }
          heading="Are you sure you want to delete this legal section?"
          className="alert-categories__modal"
        />
      )}
    </>
  )
}

export { LegalSectionsTable }
