import React, { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Button from 'components/button'
import Tooltip from 'components/tooltip/index'
import { FEATURE_NOT_IMPLEMENTED_TOOLTIP } from 'components/utils/tooltip'
import { CheckboxNoHooks } from 'components/checkbox/index'
import Icon from 'components/icon/index'
import Modal from 'components/modal/index'

import { ReactComponent as IconEdit } from 'assets/icon_edit.svg'
import { ReactComponent as IconCustom } from 'assets/icon_custom.svg'
import { ReactComponent as FileIcon } from 'assets/icon_file_upload.svg'
import { ReactComponent as DownloadIcon } from 'assets/icon_download.svg'
import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'
import { ReactComponent as WarningIcon } from 'assets/icon_warning.svg'

import { BUDGET_PACING_TYPE } from '../../utils'

import '../../../style.scss'

const BudgetPacingHeading = ({
  selectedType,
  setSelectedType,
  inactiveCampaignsFlag,
  onChangeInactiveCampaignsFlag,
}) => {
  const {
    flags,
    setFlags,
    company: { _id: clientId },
  } = useOutletContext()
  const [roadMapTooltip, setRoadMapTooltip] = useState(false)
  const [inactiveCampaignModal, setInactiveCampaignModal] = useState(false)

  /* [AP-7308] Hidden until we will be able to implement upload/export csv file */
  // const downloadTemplate = () => {
  //   const url = '/budget-pacing-template.csv'
  //   const a = document.createElement('a')
  //   a.href = url
  //   a.download = url
  //   document.body.appendChild(a)
  //   a.click()
  // }

  const renderIncludeInactiveCampaigns = () => {
    return (
      <div className="include-inactive-campaigns">
        {inactiveCampaignModal && (
          <Modal
            opened={inactiveCampaignModal}
            className="include-inactive-campaigns__modal"
            rightAlignButtons
            button={
              <Button
                value="Confirm"
                green
                onClick={() =>
                  onChangeInactiveCampaignsFlag(true).then(() => {
                    setInactiveCampaignModal(false)
                    if (setFlags) {
                      setFlags(clientId, { inactiveCampaignsCaching: true })
                    }
                  })
                }
              />
            }
            buttonSecondary={
              <Button
                value="Cancel"
                secondaryGray
                onClick={() => setInactiveCampaignModal(false)}
              />
            }
            icon={<WarningIcon />}
            heading="Include Inactive Campaigns"
            contentSeparator
          >
            <p className="first-paragraph">
              Selecting this option includes inputs from CURRENTLY PAUSED
              campaigns' Budget Segment dimension in your taxonomy. Only use
              this if you need to include campaigns that are currently paused
              but will go live or were previously live during this budget
              period.
            </p>
            <p>
              Please note that if all legacy campaign names have not been
              updated, selecting this option could include incorrect inputs.
              Ensure that all Budget Segment input selections match the
              appropriate campaigns.{' '}
            </p>
            <p>Confirming this option can take several minutes to process.</p>
            <p className="last-paragraph">Click Confirm to proceed.</p>
          </Modal>
        )}
        {flags[clientId]?.inactiveCampaignsCaching && inactiveCampaignsFlag && (
          <Icon
            className="icon--warning"
            tooltip="Dimensions list does not currently contain inactive campaigns data, getting inactive campaign data is still under processing."
          >
            <WarningIcon />
          </Icon>
        )}
        <CheckboxNoHooks
          reversed
          defaultValue={inactiveCampaignsFlag}
          onChange={(value) => {
            if (value) {
              setInactiveCampaignModal(true)
            } else {
              onChangeInactiveCampaignsFlag(false).then(() => {
                setInactiveCampaignModal(false)
                if (setFlags) {
                  setFlags(clientId, { inactiveCampaignsCaching: false })
                }
              })
            }
          }}
          label={
            <div className="include-inactive-campaigns__label">
              <div>Include Inactive Campaigns</div>
              <Icon
                width={24}
                height={24}
                tooltip={
                  <div className="include-inactive-campaigns__tooltip">
                    <Icon>
                      <WarningIcon />
                    </Icon>
                    <div>
                      <p className="first-paragraph">
                        Check to include inactive campaigns' inputs from the
                        Budget Segment position in your taxonomy.
                      </p>
                      <p className="last-paragraph">
                        Checking this can take several minutes to process.
                      </p>
                    </div>
                  </div>
                }
                tooltipClass="include-inactive-campaigns__tooltip"
                className="include-inactive-campaigns__info"
              >
                <InfoIcon width={18} height={18} />
              </Icon>
            </div>
          }
        />
      </div>
    )
  }

  const renderExtraHeaderItems = () => {
    switch (selectedType) {
      case BUDGET_PACING_TYPE.UPLOAD:
        return (
          <div
            className="align-row"
            onMouseEnter={() => setRoadMapTooltip(true)}
            onMouseLeave={() => setRoadMapTooltip(false)}
          >
            <Tooltip
              show={roadMapTooltip}
              content={FEATURE_NOT_IMPLEMENTED_TOOLTIP}
            />
            <DownloadIcon className="icon-upside-down margin-top-auto margin-bottom-auto" />
            <a className="flow__link margin-left-10 margin-top-auto margin-bottom-auto">
              Download CSV Template
            </a>
          </div>
        )

      default:
        return <>{renderIncludeInactiveCampaigns()}</>
    }
  }
  return (
    <div
      data-cy="budget-pacing-heading"
      className="space-between padding-x-30 padding-y-15"
    >
      <div className="display-flex">
        <Button
          className={cx('budget-button-value', {
            'button-selected button-selected--active':
              selectedType === BUDGET_PACING_TYPE.STANDARD,
          })}
          secondary
          onClick={() => setSelectedType(BUDGET_PACING_TYPE.STANDARD)}
          value={
            <div className="display-flex">
              <IconEdit
                className={cx({
                  'fill-white': selectedType === BUDGET_PACING_TYPE.STANDARD,
                })}
              />
              <div className="budget-button-label">Enter manually</div>
            </div>
          }
        />
        <Button
          className={cx('budget-button-value margin-left-10', {
            'button-selected button-selected--active':
              selectedType === BUDGET_PACING_TYPE.CUSTOM,
          })}
          secondary
          onClick={() => setSelectedType(BUDGET_PACING_TYPE.CUSTOM)}
          value={
            <div className="display-flex">
              <IconCustom
                className={cx({
                  'fill-white': selectedType === BUDGET_PACING_TYPE.CUSTOM,
                })}
              />
              <div className="budget-button-label">Custom Budget Pacing</div>
            </div>
          }
        />
        <div
          onMouseEnter={() => setRoadMapTooltip(true)}
          onMouseLeave={() => setRoadMapTooltip(false)}
        >
          <Tooltip
            show={roadMapTooltip}
            content={FEATURE_NOT_IMPLEMENTED_TOOLTIP}
          />
          <Button
            className={cx('budget-button-value margin-left-10', {
              'button-selected button-selected--active':
                selectedType === BUDGET_PACING_TYPE.UPLOAD,
            })}
            style={{ opacity: 0.7 }}
            secondary
            // onClick={() => setSelectedType(BUDGET_PACING_TYPE.UPLOAD)} // [AP-7308] Hidden until we will be able to implement upload/export csv file
            value={
              <div className="display-flex">
                <FileIcon />
                <div className="budget-button-label">Upload CSV</div>
              </div>
            }
          />
        </div>
      </div>
      {renderExtraHeaderItems()}
    </div>
  )
}
export default BudgetPacingHeading

BudgetPacingHeading.propTypes = {
  selectedType: PropTypes.string,
  setSelectedType: PropTypes.func,
  inactiveCampaignsFlag: PropTypes.bool,
  onChangeInactiveCampaignsFlag: PropTypes.func.isRequired,
}
