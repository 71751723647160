import { utils } from '@decision-sciences/qontrol-common'

const { snakeCaseToTitleCase } = utils.string
const { getUniqueListByKey } = utils.array

/**
 * Constructs a map with all the channel types dropdown options by publisher based on the given possible
 * channel types values.
 *
 * Given: {
 *   "facebook": [
 *     "PAID_SOCIAL"
 *   ],
 *   "linkedin": [
 *     "UNSPECIFIED",
 *   ],
 * }
 *
 * Returned:
 *
 * {
 *     "GLOBAL": [
 *         {
 *             "label": "Paid Social",
 *             "value": "PAID_SOCIAL"
 *         },
 *         {
 *             "label": "Unspecified",
 *             "value": "UNSPECIFIED"
 *         }
 *     ],
 *     "facebook": [
 *         {
 *             "label": "Paid Social",
 *             "value": "PAID_SOCIAL"
 *         }
 *     ],
 *     "linkedin": [
 *        {
 *             "label": "Unspecified",
 *             "value": "UNSPECIFIED"
 *        }
 *     ]
 * }
 */
export const getChannelTypesByPublisher = (fields) => {
  if (!fields.CHANNEL_TYPE?.possibleValues) {
    return {}
  }

  /**
   * Constructs a map with all the channel type by publishers + the GLOBAL property having
   * all the unique channel types from all the publishers
   */
  return Object.keys(fields.CHANNEL_TYPE?.possibleValues).reduce(
    (collector, publisherKey) => {
      const channelTypes = fields.CHANNEL_TYPE.possibleValues[publisherKey].map(
        (e) => ({ label: snakeCaseToTitleCase(String(e)), value: e })
      )

      // Set the channel types for the individual publisher
      collector[publisherKey] = channelTypes

      // Update the global channel types with the latest unique values from all the publishers
      collector.GLOBAL = Array.from(
        getUniqueListByKey([...collector.GLOBAL, ...channelTypes], 'value')
      )

      return collector
    },
    { GLOBAL: [] }
  )
}

