import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import CheckboxSection from 'components/checkbox-section/index'

/**
 * Renders the Clients section
 * @param {Object} params React Params
 * @param {Boolean} params.viewOnly Read-Only mode
 * @param {Array} params.userClients user.clients array
 * @param {Array<Object>} params.clients List of client objects
 * @param {Object} params.initialSelection Contains selected acount ObjectIds for all users
 * @param {Function} params.onChange To be called with new user.clients array
 * @param {Boolean} params.hasSearch Whether global search is enabled
 */
const BusinessUnitsBulkUnassign = ({
  viewOnly,
  userClients = [],
  clients = [],
  initialSelection = {},
  onChange,
  hasSearch = true,
}) => {
  // List of Client Objects representing currently selected clients.
  const selectedClients = useMemo(() => {
    if (!userClients || !clients) {
      return []
    }
    return clients.filter((client) =>
      userClients.some((userClient) => userClient.clientId === client._id)
    )
  }, [JSON.stringify(userClients), JSON.stringify(clients)])

  /**
   * Config for business units
   * Each config works under the structure:
   * @example <caption>Config Example</caption>
   * {
   *  title: Company Name
   *  items: <list of business unit ids>
   *  selected: <list of selected business unit ids>
   *  onChange: <function to call with new selected list of ids>
   * }
   */
  const businessUnitConfigs = useMemo(() => {
    return clients.reduce((array, client) => {
      if (client.businessUnits?.length) {
        return [
          ...array,
          {
            title: client.name,
            items: client.businessUnits.map((businessUnit) => ({
              label: businessUnit.name,
              value: businessUnit._id,
              disabled: !initialSelection[businessUnit._id],
            })),
            selectedItems:
              userClients.find(
                (clientData) => clientData.clientId === client._id
              )?.businessUnits || [],
            clientId: client._id,
            onChange: (businessUnits) =>
              onChange(
                getClientListForBusinessUnitChange(
                  userClients,
                  client._id,
                  businessUnits
                )
              ),
          },
        ]
      }
      return array
    }, [])
  }, [JSON.stringify(selectedClients), JSON.stringify(userClients)])

  const allBusinessUnitsSelected = useMemo(
    () =>
      businessUnitConfigs.every(({ selectedItems, items }) => {
        const availableItems = items.filter((item) => !item.disabled)
        return (
          !availableItems.length ||
          availableItems.every(({ value }) => selectedItems.includes(value))
        )
      }),
    [JSON.stringify(businessUnitConfigs)]
  )

  // Business unit list change handler
  const getClientListForBusinessUnitChange = (
    userClients,
    clientId,
    businessUnits
  ) => {
    const newClients = [...userClients]
    const clientIdx = userClients.findIndex(
      (uClient) => uClient.clientId === clientId
    )
    if (clientIdx !== -1) {
      const removedBusinessUnits = (
        newClients[clientIdx].businessUnits || []
      ).filter((bu) => !businessUnits.some((newBu) => newBu === bu))
      const newAccounts = new Set(newClients[clientIdx].accounts)
      const client = clients.find((client) => client._id === clientId)
      if (!client) {
        return
      }
      removedBusinessUnits.forEach((buId) => {
        const foundBusinessUnit = client.businessUnits.find(
          (bu) => bu._id === buId
        )
        if (foundBusinessUnit) {
          foundBusinessUnit.accounts.forEach((acc) =>
            newAccounts.delete(acc._id)
          )
        }
      })
      newClients[clientIdx].businessUnits = businessUnits
      newClients[clientIdx].accounts = [...newAccounts]
    } else {
      newClients.push({ clientId, businessUnits, accounts: [] })
    }
    return newClients
  }

  const onSelectAllBusinessUnits = () => {
    let newClients = [...userClients]

    for (const config of businessUnitConfigs) {
      if (allBusinessUnitsSelected) {
        newClients = getClientListForBusinessUnitChange(
          newClients,
          config.clientId,
          []
        )
        continue
      }
      newClients = getClientListForBusinessUnitChange(
        newClients,
        config.clientId,
        config.items.filter((item) => !item.disabled).map(({ value }) => value)
      )
    }

    onChange(newClients)
  }

  return (
    <div className="clients-and-accounts">
      <CheckboxSection
        hasSearch={hasSearch}
        label="Business Units"
        config={businessUnitConfigs}
        onClickSelectAll={onSelectAllBusinessUnits}
        allSelected={allBusinessUnitsSelected}
      />
    </div>
  )
}

BusinessUnitsBulkUnassign.propTypes = {
  userClients: PropTypes.array,
  clients: PropTypes.array,
  initialSelection: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  hasSearch: PropTypes.bool,
}

export { BusinessUnitsBulkUnassign }
