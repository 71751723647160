import React, { useState, useContext, useEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useStore } from 'store'
import { formatDistanceToNowStrict } from 'date-fns'
import { useNavigate } from 'react-router-dom'

/* Components */
import useSession from 'modules/session'
import Loader from 'components/loader'
import { useSocket } from 'components/utils/socket'
import Tooltip from 'components/tooltip'

/* Contexts */
import { dataRefreshContext } from 'contexts/data-refresh'

/* Assets */
import unreadIcon from 'assets/icon_notifications_unread.svg'
import readIcon from 'assets/icon_notifications_read.svg'
import disabledIcon from 'assets/icon_notifications_disabled.svg'
import closeIcon from 'assets/icon_clear_red.svg'
import disabledCloseIcon from 'assets/icon_clear_grey.svg'
import { ReactComponent as MessageAllSVG } from 'assets/icon_message_all.svg'
import { ReactComponent as MessageUnReadSVG } from 'assets/icon_message_unread.svg'
import { ReactComponent as PublicSVG } from 'assets/icon_public.svg'
import { ReactComponent as CompanySVG } from 'assets/avatar.svg'
import { ReactComponent as BriefSVG } from 'assets/icon_briefs.svg'
import { ReactComponent as AlertsSVG } from 'assets/active-alert-triggers.svg'

/* Actions */
import {
  markAllNotificationsAsRead,
  markNotificationAsRead,
  clearNotifications,
  disableNotification,
  removeAlertNotification,
  getNotificationsLazy,
  setShowAlertNotifications,
  setShowBriefNotifications,
  setShowCompanySpecificNotifications,
  setViewUnreadOnly,
} from 'modules/notifications/actions'

/* Constants */
import { alertTriggers, socket } from '@decision-sciences/qontrol-common'
import {
  NOTIFICATION_TYPES_CONFIG,
  getTitle,
  getBody,
  getRedirectUrl,
} from './notification-config'

const { ALERT_TRIGGER_STATUS } = alertTriggers
const { NOTIFICATIONS } = socket

/**
 * Notification item to show in notification panel
 * @param {Object} props props
 * @param {Object[]} props.data array of notifications
 * @param {Boolean} props.loading data is loading
 * @returns {React.Component}
 */
const NotificationsMenu = ({ data = [], loading = false }) => {
  const { dispatch, state } = useStore()
  const {
    companies: { currentCompany },
    notifications: {
      userNotifications: {
        elementsLeft,
        nrNotificationsToLoad,
        showAlertNotifications,
        showBriefNotifications,
        showCompanySpecificNotifications,
        viewUnreadOnly,
      },
    },
    global: {
      environment: { NR_ALERT_NOTIFICATIONS_TO_LOAD_AT_ONCE },
    },
  } = state
  const [, user] = useSession()
  const panelRef = useRef()

  /* Tooltip with explanation for the filters */
  const [tooltipContent, setTooltipContent] = useState(null)

  const { setDataRefresh, setOptions: setDataRefreshOptions } =
    useContext(dataRefreshContext)

  // Listen to changes on Active cards => disable notifications of modified active cards
  const socket = useSocket({ room: [currentCompany?._id, user?._id] })
  useEffect(() => {
    if (socket) {
      socket.on(
        NOTIFICATIONS.alertTriggers.receive,
        ({ id, from, newCard }) => {
          // newly triggered alert
          if (newCard) {
            setDataRefreshOptions({
              handlers: () => {
                getNotificationsLazy(
                  dispatch,
                  user._id,
                  {
                    selectedClient: showCompanySpecificNotifications
                      ? currentCompany?._id
                      : null,
                    viewUnreadOnly,
                    showAlertNotifications,
                    showBriefNotifications,
                  },
                  parseInt(NR_ALERT_NOTIFICATIONS_TO_LOAD_AT_ONCE, 10)
                )
              },
              overlay: false,
            })
            setDataRefresh(true)
          } else if (from === ALERT_TRIGGER_STATUS.ACTIVE) {
            // already existing notification's trigger got changed from 'Active'
            disableNotification(id, dispatch)

            setDataRefreshOptions({
              handlers: () => removeAlertNotification(id, dispatch),
              overlay: false,
            })
            setDataRefresh(true)
          }
        }
      )

      // Signal for Brief related notifications
      socket.on(NOTIFICATIONS.briefRelatedNotification.receive, () => {
        setDataRefreshOptions({
          handlers: () => {
            getNotificationsLazy(
              dispatch,
              user._id,
              {
                selectedClient: showCompanySpecificNotifications
                  ? currentCompany?._id
                  : null,
                viewUnreadOnly,
                showAlertNotifications,
                showBriefNotifications,
              },
              parseInt(NR_ALERT_NOTIFICATIONS_TO_LOAD_AT_ONCE, 10)
            )
          },
          overlay: false,
        })
      })
    }

    return () => socket?.removeAllListeners(NOTIFICATIONS.alertTriggers.receive)
  }, [socket, JSON.stringify(data), user, currentCompany])

  // Load more items on scrolling to the bottom of the pane
  useEffect(() => {
    if (panelRef?.current) {
      panelRef.current.addEventListener('scroll', trackScrolling)

      return () => {
        panelRef?.current?.removeEventListener('scroll', trackScrolling)
      }
    }
  }, [panelRef, elementsLeft, nrNotificationsToLoad])

  const trackScrolling = (ev) => {
    const isBottom = (el) =>
      // -2 -> give it some slack to react well on any screen size
      el.scrollHeight - el.scrollTop - 2 <= el.clientHeight

    if (isBottom(ev.target) && elementsLeft) {
      getNotificationsLazy(
        dispatch,
        user._id,
        {
          selectedClient: showCompanySpecificNotifications
            ? currentCompany?._id
            : null,
          viewUnreadOnly,
          showAlertNotifications,
          showBriefNotifications,
        },
        parseInt(NR_ALERT_NOTIFICATIONS_TO_LOAD_AT_ONCE, 10),
        nrNotificationsToLoad
      )
    }
  }

  /** Refetch notifications when changing filters */
  useEffect(() => {
    getNotificationsLazy(
      dispatch,
      user._id,
      {
        selectedClient: showCompanySpecificNotifications
          ? currentCompany?._id
          : null,
        viewUnreadOnly,
        showAlertNotifications,
        showBriefNotifications,
      },
      parseInt(NR_ALERT_NOTIFICATIONS_TO_LOAD_AT_ONCE, 10)
    )
  }, [
    showCompanySpecificNotifications,
    viewUnreadOnly,
    showAlertNotifications,
    showBriefNotifications,
  ])

  return (
    <div className="notifications">
      {/* Top section of the notification panel */}
      <div className="notifications-top">
        <p className="notifications__title">Notifications</p>

        {/* Filters */}
        <div className="notifications__filters">
          {/* Read / Unread filter */}
          {viewUnreadOnly ? (
            <MessageUnReadSVG
              className={cx(
                'notifications__filter notifications__filter--active',
                {
                  'notifications__filter--disabled': loading,
                }
              )}
              onClick={() => !loading && setViewUnreadOnly(dispatch, false)}
              onMouseOver={() => setTooltipContent('View all')}
              onMouseLeave={() => setTooltipContent(null)}
            />
          ) : (
            <MessageAllSVG
              className={cx('notifications__filter', {
                'notifications__filter--disabled': loading,
              })}
              onClick={() => !loading && setViewUnreadOnly(dispatch, true)}
              onMouseOver={() => setTooltipContent('View unread')}
              onMouseLeave={() => setTooltipContent(null)}
            />
          )}

          {/* Global / Company specific filter */}
          {showCompanySpecificNotifications ? (
            <CompanySVG
              className={cx('notifications__filter svg--white', {
                'notifications__filter--disabled': loading,
              })}
              onClick={() =>
                !loading && setShowCompanySpecificNotifications(dispatch, false)
              }
              onMouseOver={() => setTooltipContent('All clients')}
              onMouseLeave={() => setTooltipContent(null)}
            />
          ) : (
            <PublicSVG
              className={cx('notifications__filter', {
                'notifications__filter--active':
                  !showCompanySpecificNotifications,
                'notifications__filter--disabled': loading,
              })}
              onClick={() =>
                !loading && setShowCompanySpecificNotifications(dispatch, true)
              }
              onMouseOver={() => setTooltipContent('Selected client')}
              onMouseLeave={() => setTooltipContent(null)}
            />
          )}

          {/* Filter for only showing Alert-trigger / Brief notifications */}
          <AlertsSVG
            className={cx(
              'notifications__filter notifications__filter--alert',
              {
                'notifications__filter--active': showAlertNotifications,
                'notifications__filter--disabled': loading,
              }
            )}
            onClick={() =>
              !loading &&
              setShowAlertNotifications(dispatch, !showAlertNotifications)
            }
            onMouseOver={() => setTooltipContent('Alert notifications')}
            onMouseLeave={() => setTooltipContent(null)}
          />
          <BriefSVG
            className={cx(
              'notifications__filter notifications__filter--brief',
              {
                'notifications__filter--active': showBriefNotifications,
                'notifications__filter--disabled': loading,
              }
            )}
            onClick={() =>
              !loading &&
              setShowBriefNotifications(dispatch, !showBriefNotifications)
            }
            onMouseOver={() =>
              setTooltipContent('Campaign builder notifications')
            }
            onMouseLeave={() => setTooltipContent(null)}
          />
          <Tooltip content={tooltipContent} show={Boolean(tooltipContent)} />
        </div>
      </div>

      {/* Bulk action items */}
      <div className="notifications__actions">
        <div
          className={cx('notifications__actions__item', {
            'notifications__actions__item--disabled': loading,
          })}
          onClick={() => {
            markAllNotificationsAsRead(data, dispatch)
          }}
        >
          Mark all as read
        </div>
        <div
          className={cx('notifications__actions__item', {
            'notifications__actions__item--disabled': loading,
          })}
          onClick={() => {
            clearNotifications(
              dispatch,
              data.filter((d) => d.read)
            )
          }}
        >
          Clear All
        </div>
      </div>

      {/* Notification list */}
      <div
        ref={panelRef}
        className={cx('notifications__list', {
          'notifications__list--loading': loading,
        })}
      >
        {[
          data?.find(({ top }) => !!top) || { invalidItem: true },
          ...(data?.filter(({ top }) => !top) || []),
        ]
          // Even though we're filtering form the API, we need this so that already brought, but read notifications are no longer shown
          ?.filter(
            ({ read, invalidItem }) =>
              !invalidItem && (!viewUnreadOnly || !read)
          )
          .map(({ id, alert, alertName, name, companyName, ...other }) => {
            return (
              <NotificationItem
                key={id}
                id={id}
                className="notifications__list__item"
                name={alertName || name}
                companyName={
                  !showCompanySpecificNotifications ? companyName : null
                }
                alertName={alert?.name}
                {...other}
              />
            )
          })}

        {loading && <Loader />}
      </div>
    </div>
  )
}

/**
 * Notification item to show in notification panel
 * @param {Object} props props
 * @param {String} props.className class
 * @param {String} props.name name of notification
 * @param {String} props.triggeredBy triggered by
 * @param {Object} props.linkContent link content of notification.
 * @param {String} props.linkContent.path path the link leads to
 * @param {String} props.linkContent.text text of url
 * @param {Object} props.linkContent.destinationState state to set on the destination page
 * @param {String} props.createdAt date of sending notification
 * @param {String} props.id id of the notification
 * @param {Boolean} props.read notification is read
 * @param {Boolean} props.disabled notification is greyed out and no action can be done on it
 * @param {String} props.type notification type; @oneof {@link NOTIFICATION_TYPES}
 * @param {String} props.userFullName full name of user
 * @param {String} props.comment comment from campaign brief
 * @param {String} props.briefName name of a campaign brief
 * @param {String} props.alertTriggerId Alert Trigger Id
 * @param {String} props.briefId ID of Campaign Brief
 * @param {String} props.companyName Company Name
 * @param {String} props.alertName Alert name the notification belongs to
 * @param {String} props.accountId Account ID the notification belongs to
 * @param {Boolean} props.success E2E Alert executor result
 * @returns {React.Component}
 */
const NotificationItem = React.memo(
  ({
    className,
    name,
    triggeredBy,
    createdAt,
    id,
    read = false,
    disabled = false,
    linkContent,
    type,
    userFullName,
    comment,
    briefName,
    alertTriggerId,
    briefId,
    companyName,
    alertName,
    accountId,
    success,
  }) => {
    const { dispatch } = useStore()
    const navigate = useNavigate()

    let readFlagIcon = unreadIcon
    if (read) {
      readFlagIcon = readIcon
    }
    if (disabled) {
      readFlagIcon = disabledIcon
    }

    /* Title of notification */
    const title = useMemo(
      () => getTitle(type, name, userFullName, alertName, accountId, success),
      [type, name, userFullName, alertName, accountId, success]
    )

    /* Body of notification */
    const body = useMemo(
      () => getBody(type, triggeredBy, comment, briefName),
      [type, triggeredBy, comment, briefName]
    )

    /* Path the notification takes the user to */
    const redirectUrl = useMemo(
      () => getRedirectUrl(type, briefId, alertTriggerId),
      [type, briefId, alertTriggerId]
    )

    const notificationConfig = NOTIFICATION_TYPES_CONFIG[type] || {}

    return (
      <div
        className={cx('notification-item', {
          'notification-item--disabled': disabled,
          [className]: className,
        })}
      >
        <img
          alt="Red Flag Icon"
          className="notification-item__status"
          src={readFlagIcon}
        />
        <img
          alt="Close Icon"
          className="notification-item__clear"
          src={disabled ? disabledCloseIcon : closeIcon}
          onClick={() => {
            clearNotifications(dispatch, [{ id }])
          }}
        />
        <div className="notification-item__info">
          <p className="notification-item__info__message">{title}</p>
          {body}

          {/* For notifications that need redirecting with a preexisting state */}
          {linkContent ? (
            <p
              className="fake-link"
              onClick={() => {
                navigate(redirectUrl || linkContent.path, {
                  state: {
                    ...linkContent.destinationState,
                  },
                })
                markNotificationAsRead(id, dispatch)
              }}
            >
              {linkContent.text}
            </p>
          ) : null}
          <div className="notification-item__info__footer">
            <div
              className={cx(`notification-item__info__footer__tag`, {
                [`notification-item__info__footer__tag--disabled`]: disabled,
                [`notification-item__info__footer__tag--inverted`]:
                  notificationConfig.inverted,
              })}
              style={
                notificationConfig.inverted
                  ? {
                      color: notificationConfig.color,
                      borderColor: notificationConfig.color,
                    }
                  : {
                      backgroundColor: notificationConfig.color,
                    }
              }
            >
              {notificationConfig.tag}
            </div>
            {companyName ? (
              <div className="notification-item__info__footer__tag notification-item__info__footer__tag--company">
                {companyName}
              </div>
            ) : null}
            <div className="notification-item__info__footer__right">
              <div className="notification-item__info__footer__time">
                {formatDistanceToNowStrict(new Date(createdAt))} ago
              </div>
              <div
                className={cx('notification-item__info__footer__details', {
                  'notification-item__info__footer__details--disabled':
                    disabled,
                })}
                onClick={() => {
                  navigate(redirectUrl || linkContent.path, {
                    state: linkContent
                      ? {
                          ...linkContent.destinationState,
                        }
                      : null,
                  })
                  markNotificationAsRead(id, dispatch)
                }}
              >
                Details
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

NotificationsMenu.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
}

NotificationItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  triggeredBy: PropTypes.string,
  createdAt: PropTypes.string,
  id: PropTypes.string.isRequired,
  read: PropTypes.bool,
  disabled: PropTypes.bool,
  linkContent: PropTypes.object,
  type: PropTypes.string,
  userFullName: PropTypes.string,
  comment: PropTypes.string,
  briefName: PropTypes.string,
  alertTriggerId: PropTypes.string,
  briefId: PropTypes.string,
  companyName: PropTypes.string,
  alertName: PropTypes.string,
  accountId: PropTypes.string,
  success: PropTypes.bool,
}

export default NotificationsMenu
