import React, { useState, useMemo, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useStore } from 'store'
import { useNavigate } from 'react-router-dom'

// Components
import Input from 'components/input'
import TextArea from 'components/textarea'
import { Dropdown } from 'components/dropdown'
import Toggle from 'components/toggle'
import scrollToClosestError from 'components/scroll-to-error'
import SendNotificationOptions from 'components/send-notification-options'
import StickyFooter from 'components/sticky-footer'
import { FIELD_TYPES, ERRORS, validate } from 'components/validator'
import AlertProperties, {
  isSettingsOperation,
} from 'modules/alerts/alert-properties'
import useLeaveConfirm from 'components/leave-confirm'
import { PrimaryComparisonPeriodSection } from 'modules/alerts/primary-comparison-period'
import { AlertMessage } from 'modules/alerts/alert-message'
// import Tooltip from 'components/tooltip'

/* Sections */
import ApplyToSection from 'modules/alerts/apply-to'
import { AlertClientSelection } from 'modules/alerts/client-selection'
import { AlertTemplateSection } from 'modules/alerts/template-section'
// import { AlertEmbarqSection } from 'modules/alerts/embarq-section'
import { AlertNotificationGroupSection } from 'modules/alerts/alert-notification-section'
import { OwnerDropdown } from 'components/utils/owner'

// Functions
import { usePendingState } from 'components/utils/custom-hooks'
import { getTeamsWithUsersInCompanies } from 'modules/teams/actions'
import { getVariableMetrics } from 'modules/calculated-metrics/actions'

// Constants
// import { ALERT_CONTAINER_LABEL_TOOLTIP } from 'components/utils/tooltip'
import { useAccess, PERMISSION_TYPES, PERMISSIONS } from 'hooks/access'
import {
  DEFAULT_ELEMENTS_SINGLE,
  NOTIFICATION_TYPES,
} from 'modules/alerts/constants'

// Assets
import lockIcon from 'assets/icon_lock.svg'
// import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'

// Contexts
import { editLockContext } from 'contexts/edit-lock'

import {
  time,
  utils,
  accounts,
  entityStatus,
  alert,
  metrics,
  notifications,
  granularities,
} from '@decision-sciences/qontrol-common'

import {
  ACCOUNTS_TO_SELECT_ELEMENTS,
  AVAILABLE_PUBLISHERS_FOR_ALERT,
} from './constants'
import './styles.scss'

const { isConversionsMetric, isRevenueMetric, METRIC_TYPES } = metrics
const { fromRecurrenceToCronString } = time
const { isEmpty } = utils.object
const { ACCOUNT_TYPES_MAP } = accounts
const { ENTITY_STATUS_OPTIONS } = entityStatus
const {
  ALERT_TYPES,
  ALERT_ELEMENT_TO_FIELD_NAME,
  ALERT_TIME,
  // ALERT_GRANULARITY,
  // ALERT_GRANULARITY_LABELS,
  ALERT_TYPES_MAP,
  ALERTS_CONTAINER_DROPDOWN,
} = alert
const { DELIVERY_OPTIONS, DELIVERY_OPTIONS_MAP, FREQUENCY_OPTIONS_MAP } =
  notifications
const {
  HOURLY_UNSELECTABLE_GRANULARITIES,
  STATIC_METRIC_UNSELECTABLE_ACCOUNT_TYPES,
} = granularities

/**
 * Displays a Create / Edit form for Alerts
 * @param {Object} options
 * @param {Object} options.user Current User
 * @param {Object} options.alert Alert to edit
 * @param {Array} options.allCompanies All Companies
 * @param {Array} options.alertCategories Alert Categories list
 * @param {Array} options.alertTemplates Alert Templates list
 * @param {Array} options.users User list
 * @param {Function} options.saveAlert On Saving the Alert
 * @param {Function} options.displayError Function to call with error
 * @param {String} [options.company] Id of currently selected company
 * @param {Array} options.possibleOwners List of users that can be Owners for the alert
 * @returns {React.ComponentElement}
 */
const CreateEditAlert = ({
  alert,
  user,
  allCompanies,
  alertCategories,
  alertTemplates,
  saveAlert,
  displayError,
  users,
  company,
  possibleOwners,
}) => {
  // Get available account type options for the selected accounts in 'Apply to' section
  const {
    dispatch,
    state: {
      companies,
      calculatedMetrics: { variables },
    },
  } = useStore()
  const { currentCompany } = companies
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)

  const [setDirty, LeaveConfirm, dirty] = useLeaveConfirm({})
  const [accountsElements, setAccountsElements] = useState(
    ACCOUNTS_TO_SELECT_ELEMENTS
  )

  const navigate = useNavigate()
  const isAdmin = user.isSuperAdmin
  const hasViewAccess = useAccess({
    feature: PERMISSIONS.ALERT_DATA_FORM,
    type: PERMISSION_TYPES.READ,
  })
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.ALERT_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.ALERT_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })

  const teamAccess = useAccess({
    feature: PERMISSIONS.TEAMS_INDEX,
    type: PERMISSION_TYPES.READ,
  })

  /** Edit locks */
  const { setEntityType, setEntityId, EDIT_LOCK_ENTITIES, setOnSessionEnd } =
    useContext(editLockContext)

  const [state, setState] = usePendingState(
    alert,
    {
      isNew: true,
      name: '',
      description: '',
      category: '',
      template: '',
      traqDisplayedAtTheTop: false,
      active: true,
      time: '',
      alertType: null,
      companies: [companies.currentCompany._id],
      alertRunning: [],
      recurrence: {},
      notificationOptions: [],
      elementType: null,
      dynamicMetrics: [],
      apiCallsInProgress: {},
      container: ALERTS_CONTAINER_DROPDOWN[0].value,
      /**
       * Apply to elements
       * Eg.
       * {
       *  facebook: {
       *    elements: ["Ad Sets"],
       *    campaigns: [c1], // so we know which campaign the adsets belong to
       *    adSets: [a1, a2, a3],
       *  }
       * }
       *
       */
      selectedElements: {},
      keywords: [],
      urls: [],
      selectedAccounts: [],
      properties: {},
      accountTypesWithPausedCampaigns: {},
      allCompaniesSelected: false,
      required: false,
      message: '',
      owner: { _id: user._id, isSuperAdmin: user.isSuperAdmin },
    },
    false,
    true
  )

  const editMode = !state.isNew
  /**
   * Tell the Edit Lock context:
   * - what entity we're currently on
   * - the entity type we're currently on
   * - the actions to happen before unlocking the entity
   */
  useEffect(() => {
    if (editMode && state._id) {
      setEntityId(state._id)
      setEntityType(EDIT_LOCK_ENTITIES.ALERT)
      setOnSessionEnd(() => setDirty(false))

      /** It's important to clear everything when leaving the page */
      return () => {
        setEntityId(null)
        setEntityType(null)
        setOnSessionEnd(null)
      }
    }
  }, [state._id, editMode])

  useEffect(() => {
    if (!variables) {
      getVariableMetrics(dispatch, currentCompany._id)
    }
  }, [JSON.stringify(currentCompany)])

  // The runningOnCompany flag marks whether the alert is active for the selected company (upper right corner)
  const runningOnCompany = useMemo(() => {
    if (state.alertRunning.length) {
      return state.alertRunning.find((el) => el.company === company)?.running
    }
    return false
  }, [JSON.stringify(state?.alertRunning)])

  // Setting the "runningOnCompany" flag means deactivating or activating the currently selecting company's ability to run the alert
  const setRunningOnCompany = (running) => {
    const newAlertRunning = structuredClone(state.alertRunning)
    const alertRunningIndex = newAlertRunning.findIndex(
      (el) => el.company === company
    )

    if (alertRunningIndex === -1) {
      newAlertRunning.push({
        company,
        running,
      })
    } else {
      newAlertRunning[alertRunningIndex].running = running
    }

    setState({ alertRunning: newAlertRunning })
  }

  const deactivatedCompanies = new Set()
  state.alertRunning.forEach((item) => {
    if (item.running === false) {
      deactivatedCompanies.add(item.company)
    }
  })

  const [clientTeams, setClientTeams] = useState([])
  // const [showAlertContainerTooltip, setShowAlertContainerTooltip] =
  //   useState(false)

  const readOnlyCanToggleMode = !isAdmin && hasViewAccess && state.required

  const defaultsPropertiesObj = (accountsSelected) =>
    accountsSelected.reduce(function (acc, cur) {
      acc[cur] = [{}]
      return acc
    }, {})

  // If Limited Admin is editing a Global Alert, make it restrictive mode - only some fields are editable
  const isRestrictiveEdit = editMode && !isAdmin && state.isGlobal
  const isLocked = isRestrictiveEdit && state.required

  const isPerformanceAlert = state.alertType === ALERT_TYPES_MAP.PERFORMANCE
  const isAccountAlert = state.container === ALERTS_CONTAINER_DROPDOWN[0].value

  const singleCompanyAlert =
    state.companies.length === 1 &&
    !state.allCompaniesSelected &&
    isPerformanceAlert

  const owner = useMemo(
    () => (editMode ? users.find(({ _id }) => state.owner === _id) : user),
    [JSON.stringify(users), state?.owner]
  )

  const ownerCompanies = useMemo(() => {
    return owner.isSuperAdmin || user._id === owner._id
      ? allCompanies
      : // Filter out any companies or business units that alert owner doesn't belong to
        allCompanies.reduce((companies, option) => {
          const foundClient = owner.clients?.find(
            ({ clientId }) => clientId === option.value
          )
          const newSubsections = option.subsections.reduce(
            (subsections, option) => {
              const disabled = !foundClient?.businessUnits.some(
                (buId) => buId === option.value
              )
              if (disabled) {
                return subsections
              }
              return [...subsections, option]
            },
            []
          )
          if (!foundClient) {
            return companies
          }
          return [
            ...companies,
            {
              ...option,
              subsections: newSubsections,
            },
          ]
        }, [])
  }, [JSON.stringify(allCompanies), owner])

  const allOptions = useMemo(() => {
    const allOptions = []
    ownerCompanies.forEach((option) => {
      if (!option.disabled) {
        allOptions.push(option.value)
        if (option?.subsections.length) {
          option.subsections.forEach(
            (subsection) =>
              !subsection.disabled && allOptions.push(subsection.value)
          )
        }
      }
    })
    return allOptions
  }, [JSON.stringify(ownerCompanies)])

  const applyToCompanies = useMemo(() => {
    const companyListToIterate = state.allCompaniesSelected
      ? allOptions
      : state.companies

    return companies?.list?.reduce((list, company) => {
      const found = []

      for (const c of companyListToIterate) {
        if (c === company._id) {
          found.push(company)
          continue
        }
        const businessUnit = company.businessUnits.find((bu) => bu._id === c)
        if (businessUnit) {
          found.push(businessUnit)
        }
      }

      return [...list, ...found]
    }, [])
  }, [
    JSON.stringify(state.companies),
    JSON.stringify(companies),
    JSON.stringify(allOptions),
    state.allCompaniesSelected,
  ])

  const accountsInSingleClient = useMemo(() => {
    if (!singleCompanyAlert) {
      return null
    }

    return applyToCompanies[0].accounts.reduce((aggregator, account) => {
      return {
        ...aggregator,
        [account.type]: [...(aggregator[account.type] || []), account],
      }
    }, {})
  }, [JSON.stringify(applyToCompanies), singleCompanyAlert])

  /** Update list of Alert Templates to use -> we can only use Non-Global templates on Alerts with 1 Company */
  const filteredTemplates = useMemo(() => {
    if (!alertTemplates?.length) {
      return []
    }

    let templates = alertTemplates.filter(
      (item) => item.isGlobal || singleCompanyAlert
    )

    let granularityUnselectable = false
    Object.entries(HOURLY_UNSELECTABLE_GRANULARITIES).map(([key, value]) => {
      granularityUnselectable =
        granularityUnselectable ||
        state.selectedElements?.[key]?.elements?.some((el) =>
          value.includes(el)
        )
    })
    templates = templates.map((el) => ({
      ...el,
      unselectable:
        granularityUnselectable &&
        el.recurrenceType === FREQUENCY_OPTIONS_MAP.HOURS,
    }))

    return templates
  }, [
    JSON.stringify(alertTemplates),
    singleCompanyAlert,
    state.selectedElements,
  ])

  const isHourlyTemplate =
    filteredTemplates?.find((el) => el._id === state.template)
      ?.recurrenceType === FREQUENCY_OPTIONS_MAP.HOURS

  const currentTemplate = useMemo(() => {
    return filteredTemplates.find((el) => el._id === state.template)
  }, [JSON.stringify(filteredTemplates), state.template])

  /**
   * Get active Accounts based on the AlertType selected and the active accounts from the Client
   * If there are hourly metrics selected: disable a publisher altohether if all of its granularities are unsupported
   * @returns {Array<String>}
   */
  const activeAccountOptions = useMemo(() => {
    if (!state.alertType) {
      return []
    }

    const formulaContainsStaticMetrics = currentTemplate?.metrics?.some(
      (metric) => {
        if (metric.type === METRIC_TYPES.VARIABLE) {
          if (!variables) {
            return false
          }

          const foundVariable = variables.find((el) => el.key === metric.key)
          return (
            !isConversionsMetric(foundVariable.metric) &&
            !isRevenueMetric(foundVariable.metric)
          )
        } else {
          return (
            !isConversionsMetric(metric.key) && !isRevenueMetric(metric.key)
          )
        }
      }
    )

    const newAvailablePublishers = Object.keys(ACCOUNT_TYPES_MAP)
      .filter((accountType) => {
        const accountTypeValue = ACCOUNT_TYPES_MAP[accountType]

        if (
          formulaContainsStaticMetrics &&
          Object.values(STATIC_METRIC_UNSELECTABLE_ACCOUNT_TYPES).includes(
            accountTypeValue
          )
        ) {
          return false
        }

        const thereAreAvailableAccountsForPublisher = applyToCompanies.some(
          (company) => {
            if (
              !AVAILABLE_PUBLISHERS_FOR_ALERT[state.alertType].includes(
                accountTypeValue
              )
            ) {
              return false
            }
            return company.accounts.some((acc) => {
              return acc.type === accountTypeValue
            })
          }
        )

        const numberOfDisabledGranularitiesPerPublisher =
          HOURLY_UNSELECTABLE_GRANULARITIES[accountTypeValue]?.length
        const numberOfTotalGranularitiesPerPublisher =
          ACCOUNTS_TO_SELECT_ELEMENTS[accountTypeValue]?.length
        const publisherGranularitiesAllDisabled =
          isHourlyTemplate &&
          numberOfDisabledGranularitiesPerPublisher ===
            numberOfTotalGranularitiesPerPublisher

        return (
          thereAreAvailableAccountsForPublisher &&
          !publisherGranularitiesAllDisabled
        )
      })
      .map((accType) => ACCOUNT_TYPES_MAP[accType])

    // Deselect unavailable publishers and their granularities
    const newSelectedAccounts = state.selectedAccounts.filter((publisher) =>
      newAvailablePublishers.includes(publisher)
    )
    const newSelectedElements = Object.entries(
      state.selectedElements || {}
    ).reduce(
      (acc, [publisher, values]) =>
        newAvailablePublishers.includes(publisher)
          ? {
              ...acc,
              [publisher]: {
                ...values,
                elements: values?.elements.filter(
                  (granularity) =>
                    !isHourlyTemplate ||
                    !HOURLY_UNSELECTABLE_GRANULARITIES[publisher]?.includes(
                      granularity
                    )
                ),
              },
            }
          : { ...acc },
      {}
    )

    setState({
      selectedAccounts: newSelectedAccounts,
      selectedElements: newSelectedElements,
    })

    return newAvailablePublishers
  }, [
    JSON.stringify(applyToCompanies),
    state.alertType,
    currentTemplate,
    isHourlyTemplate,
  ])

  /** On Alert Template Change, adjust the Dynamic Metric selection option */
  const hasDynamicMetrics = useMemo(
    () =>
      !!filteredTemplates
        .find((el) => el._id === state.template)
        ?.metrics?.find((metric) => metric.isDynamicMetric),
    [state.template, JSON.stringify(filteredTemplates)]
  )

  useEffect(() => {
    const alreadySelectedTeams = state.notificationOptions.reduce(
      (arr, option) => {
        if (option.type === 'teams') {
          return [...arr, ...option.entities]
        } else {
          return arr
        }
      },
      []
    )

    teamAccess &&
      getTeamsWithUsersInCompanies(state.companies, alreadySelectedTeams)
        .then((teams) => {
          setClientTeams(teams)
        })
        .catch(console.error)
  }, [JSON.stringify(state.companies), teamAccess, state.allCompaniesSelected])

  /** Generic field change handler */
  const editField = (fieldName, fieldValue) => {
    setState({ [fieldName]: fieldValue }, true)
    setErrors({ ...errors, [fieldName]: undefined })

    // Avoid unnecesarily setting the alert as dirty
    if (JSON.stringify(state?.[fieldName]) !== JSON.stringify(fieldValue)) {
      setDirty(true)
    }
  }

  /** Notifications options callback */
  const setNotificationOptions = (notificationOptions) => {
    setState({ notificationOptions })
    setErrors({
      ...errors,
      notificationOptions: null,
    })
  }

  const validateSelectedElements = (accountTypes, selectedElements) => {
    const errors = {}
    let isValid = true
    accountTypes.forEach((type) => {
      if (!errors[type]) {
        errors[type] = {}
      }
      if (!selectedElements[type]?.elements.length) {
        errors[type].elements = 'You need to select at least one element type'
        isValid = false
      } else {
        if (singleCompanyAlert) {
          if (
            !selectedElements[type].accounts?.length &&
            !selectedElements[type].allAccountsSelected
          ) {
            errors[type]['selectedAccounts'] =
              'At least one account needs to be selected.'
            isValid = false
          }
          selectedElements[type].elements.forEach((element) => {
            const field = ALERT_ELEMENT_TO_FIELD_NAME[type][element]
            if (
              field &&
              !selectedElements[type][element]?.[field]?.length &&
              !selectedElements[type][element]?.allSelected
            ) {
              errors[type][field] = `Field can't be empty`
              isValid = false
            }
          })
        }
      }
    })
    return { isValid, errors }
  }
  /** Validate form */
  const validateForm = (stateObject) => {
    const errorMap = { ...ERROR_MAP }

    if (isAccountAlert) {
      errorMap.selectedAccounts = FIELD_TYPES.AT_LEAST_ONE
    }

    let [isValid, errors] = validate(errorMap, stateObject)

    if (
      (isAdmin &&
        !stateObject.allCompaniesSelected &&
        isEmpty(stateObject.companies)) ||
      (!isAdmin && isEmpty(stateObject.companies))
    ) {
      isValid = false
      errors.companies = true
    }
    const elementsValidation =
      singleCompanyAlert &&
      validateSelectedElements(
        stateObject.selectedAccounts,
        stateObject.selectedElements
      )

    if (elementsValidation && !elementsValidation.isValid) {
      errors.selectedElements = elementsValidation.errors
      isValid = false
    }

    // Validate recurrence
    errors.recurrence = {}
    if (!fromRecurrenceToCronString(state.recurrence, new Date())) {
      errors.recurrence.general = 'Invalid frequency settings'
      isValid = false
    }
    // Validate recurrence amount
    if (!state.recurrence.amount && !errors.recurrence.general) {
      isValid = false
      errors.recurrence.amount = ERRORS.REQUIRED
    }

    /** Since the startTime component always sets the current date, there is no case when the start time is invalid */

    // Validate notification options
    errors.notificationOptions = {}
    state.notificationOptions.forEach((row, idx) => {
      errors.notificationOptions[idx] = {}
      // Only validate if something is filled
      if (!row.channels?.length && isEmpty(row.type) && !row.entities.length) {
        return
      }

      /** If slack is the only selected channel, validate */
      const onlySlack =
        row.channels?.length === 1 &&
        row.channels[0] === DELIVERY_OPTIONS_MAP.SLACK
      if (!onlySlack) {
        if (!row.channels?.length) {
          isValid = false
          errors.notificationOptions[idx].channels = ERRORS.REQUIRED
        }
        if (isEmpty(row.type)) {
          isValid = false
          errors.notificationOptions[idx].type = ERRORS.REQUIRED
        }
        if (!row.entities.length) {
          isValid = false
          errors.notificationOptions[idx].entities = ERRORS.REQUIRED
        }
      }
      // Search for duplicates
      if (
        state.notificationOptions.some(
          (option, index) =>
            JSON.stringify(option) === JSON.stringify(row) && idx !== index
        )
      ) {
        isValid = false
        errors.notificationOptions[idx].entities = `Can't have duplicates`
      }
    })
    // Validate Alert Properties section
    if (!isPerformanceAlert) {
      errors.properties = {}
      stateObject.selectedAccounts.forEach((acc) => {
        if (stateObject.properties[acc]) {
          stateObject.properties[acc].forEach((row, idx) => {
            let currRow = [row]
            // conditional row
            if (row.conditions) {
              currRow = row.props
            }

            currRow.forEach((condRow, condIdx) => {
              if (!errors.properties?.[acc]) {
                errors.properties[acc] = { [idx]: [] }
              } else if (!errors.properties?.[acc]?.[idx]) {
                errors.properties[acc][idx] = []
              }

              if (!condRow.property) {
                isValid = false
                errors.properties[acc][idx][condIdx] = 'Property is required'
              } else if (!condRow.operation) {
                isValid = false
                errors.properties[acc][idx][condIdx] = 'Operation is required'
              } else if (
                !isSettingsOperation(condRow.operation) &&
                condRow.value !== 0 &&
                isEmpty(condRow.value)
              ) {
                isValid = false
                errors.properties[acc][idx][condIdx] = 'Value is required'
              }
            })
          })
        } else {
          isValid = false
          // Display error in case stateObject.properties[acc] doesn't exist.
          errors.properties[acc] = [['Property is Required']]
        }
      })
    } else {
      // If performance alert validate Template formula
      if (
        !stateObject.template ||
        !filteredTemplates.find((el) => el._id === stateObject.template)
      ) {
        isValid = false
        errors.template = ERRORS.REQUIRED
      }

      // In case of Dynamic Metrics ensure we have some selected
      if (hasDynamicMetrics && !state.dynamicMetrics.length) {
        isValid = false
        errors.dynamicMetrics = ERRORS.REQUIRED
      }

      // Check that at least one element is selected in 'Apply to' for every selected account
      stateObject.selectedAccounts.forEach((acc) => {
        if (!stateObject.selectedElements[acc]?.elements.length) {
          if (!errors?.elements) {
            errors.elements = {}
          }

          errors.elements[acc] = ERRORS.AT_LEAST_ONE
          isValid = false
        }
      })
    }

    if (!isValid) {
      setErrors(errors)
      scrollToClosestError()
    }
    return isValid
  }

  /** Save form */
  const saveForm = (e) => {
    e.preventDefault()
    let objToSave = {
      ...state,
      runningOnCompany,
    }
    if (!readOnlyCanToggleMode) {
      objToSave = {
        ...objToSave,
        notificationOptions: state.notificationOptions.filter(
          (option) => !option.readonly
        ),
      }
    }
    if (!isPerformanceAlert) {
      objToSave.properties = state.properties
    } else {
      objToSave.properties = {}
    }

    // Clear selected accounts and selected elements if the alert container selected as client/bu
    if (!isAccountAlert) {
      objToSave.selectedAccounts = []
      objToSave.selectedElements = {}
    }

    if (readOnlyCanToggleMode || validateForm(objToSave)) {
      setLoading(true)
      saveAlert(objToSave)
        // On save success redirect to List page
        .then(() => {
          setDirty(false)
          setTimeout(() => navigate('/alerts'), 50)
        })
        .catch((err) => {
          console.error(err)
          displayError(`Error saving Alert. ${err}`)
          // showErrorMessage(err, dispatch)
        })
        .finally(() => setLoading(false))
    }
  }

  const className = cx('alerts form', { 'form--loading': loading })

  const footerButtons = [
    {
      value: 'Cancel',
      onClick: () => navigate('/alerts'),
      secondaryGray: true,
    },
  ]
  if (hasEditAccess || hasCreateAccess) {
    footerButtons.unshift({
      value: editMode ? 'Save Changes' : 'Save Alert',
      onClick: saveForm,
      disabled: Object.values(state.apiCallsInProgress || {}).some(
        (apiCallInProgress) => apiCallInProgress
      ),
    })
  }

  // const messageMetrics = useMemo(() => {
  //   const metrics = []

  //   if (isPerformanceAlert) {
  //     return currentTemplate?.metrics?.map((metric) => metric.key) || []
  //   } else if (state.properties) {
  //     Object.entries(state.properties).forEach(
  //       ([account, accountProperties]) => {
  //         accountProperties.forEach((resource) => {
  //           // Check if row is conditional
  //           if (resource.conditions) {
  //             resource.props.forEach((prop) => {
  //               if (prop.type && prop.property) {
  //                 metrics.push(`${account}.${prop.type}.${prop.property}`)
  //               }
  //             })
  //           } else if (resource.type && resource.property) {
  //             metrics.push(`${account}.${resource.type}.${resource.property}`)
  //           }
  //         })
  //       }
  //     )
  //   }

  //   return [...new Set([...metrics])]
  // }, [isPerformanceAlert, currentTemplate, JSON.stringify(state.properties)])

  const disableGranularityForHourlyTemplates = (
    selectedElements,
    accountsElements
  ) => {
    if (isHourlyTemplate) {
      Object.entries(HOURLY_UNSELECTABLE_GRANULARITIES).forEach(
        ([key, value]) => {
          if (Object.keys(selectedElements).includes(key)) {
            // Disable granularities for the selected account
            const accountElements = accountsElements[key]
            accountsElements[key] = accountElements.map((el) =>
              value.includes(el.value) ? { ...el, disabled: true } : el
            )
          }
        }
      )
    }

    return accountsElements
  }

  const checkGranularitySelection = (newSelectedElements = null) => {
    const selectedElements = newSelectedElements || state.selectedElements
    const updatedElements = disableGranularityForHourlyTemplates(
      selectedElements,
      { ...ACCOUNTS_TO_SELECT_ELEMENTS }
    )

    setAccountsElements(updatedElements)
  }

  useEffect(() => {
    if (state.template) {
      checkGranularitySelection()
    }
  }, [state.template])

  const onChangeAlertType = (alertType) => {
    setDirty(true)
    if (alertType === ALERT_TYPES_MAP.PERFORMANCE) {
      // If Performance Alert - reset Properties field
      setState({
        alertType,
        properties: {},
        selectedElements: state.selectedAccounts.reduce(
          (acc, key) => ({
            ...acc,
            [key]: DEFAULT_ELEMENTS_SINGLE[key],
          }),
          {}
        ),
      })
    } else {
      // If Settings Alert, reset formula and granularity field
      setState({
        alertType,
        template: '',
        selectedElements: {},
        granularity: null,
      })
    }
    setErrors({ ...errors, alertType: null })
  }

  return (
    <form className={className} onSubmit={saveForm}>
      {dirty ? <LeaveConfirm /> : null}
      <Helmet>
        <title>{editMode ? 'Edit Alert' : 'Create Alert'}</title>
      </Helmet>
      {/* Header */}
      <div className="heading" data-cy="page-heading">
        {editMode ? 'Edit Alert' : 'Create Alert'}
      </div>
      {/* General fields */}
      {!isRestrictiveEdit && (
        <section className="form__section">
          <div className="form__section__body alerts-create-edit-alert">
            <div className="form__row">
              <Input
                className="form__half"
                placeholder="Name"
                label="Alert Name"
                onChange={(val) => editField('name', val)}
                value={state.name}
                error={errors.name}
              />

              {/* {isPerformanceAlert && (
                <Dropdown
                  disableSearch={true}
                  options={Object.values(ALERT_GRANULARITY).map(
                    (granularity) => ({
                      label: ALERT_GRANULARITY_LABELS[granularity],
                      value: granularity,
                    })
                  )}
                  defaultState={state.granularity}
                  className="form__half input-wrapper--uppercase"
                  label="Granularity"
                  overwriteSelectedText={state.granularity ? null : '-'}
                  error={errors.granularity}
                  onChange={(granularity) =>
                    editField('granularity', granularity)
                  }
                />
              )} */}
              {/* This was moved up, the original location is commented below */}
              <Dropdown
                defaultState={state.category}
                options={(alertCategories || []).map((item) => ({
                  value: item._id,
                  label: item.name,
                }))}
                label="Alert Categories"
                defaultOptionText="Alert Category"
                error={errors.category}
                onChange={(category) => {
                  editField('category', category)
                  setErrors({ ...errors, category: null })
                }}
                className="form__half input-wrapper--uppercase"
              />
            </div>

            <div className="form__row">
              <AlertClientSelection
                state={state}
                setState={(state) => {
                  setDirty(true)
                  setErrors({ ...errors, companies: null })
                  setState(state)
                }}
                allCompanies={allCompanies}
                companiesList={companies?.list || []}
                user={user}
                owner={owner || user}
                error={errors.companies}
              />

              {/* This was moved up, the original location is commented below */}
              <Dropdown
                label="Required"
                options={['Required', 'Optional']}
                onChange={(value) =>
                  editField('required', value === 'Required')
                }
                disabled={!isAdmin}
                defaultState={state.required ? 'Required' : 'Optional'}
                className="form__half input-wrapper--uppercase"
              />

              {/* <div className="form__half">
                <div className="input-wrapper input-wrapper--uppercase alerts__tooltip-label">
                  <p className="label-wrapper">Alert Container</p>
                  <InfoIcon
                    onMouseEnter={() => setShowAlertContainerTooltip(true)}
                    onMouseLeave={() => setShowAlertContainerTooltip(false)}
                  />
                  <Tooltip
                    content={ALERT_CONTAINER_LABEL_TOOLTIP}
                    show={showAlertContainerTooltip}
                  />
                </div>

                <Dropdown
                  className="input-wrapper--uppercase"
                  options={ALERTS_CONTAINER_DROPDOWN}
                  defaultState={state.container}
                  onChange={(container) => editField('container', container)}
                />
              </div> */}
              {/* <Dropdown
                defaultState={state.category}
                options={(alertCategories || []).map((item) => ({
                  value: item._id,
                  label: item.name,
                }))}
                label="Alert Categories"
                defaultOptionText="Alert Category"
                error={errors.category}
                onChange={(category) => {
                  editField('category', category)
                  setErrors({ ...errors, category: null })
                }}
                className="form__half input-wrapper--uppercase"
              /> */}
            </div>

            <div className="form__row">
              <Dropdown
                options={ALERT_TYPES}
                defaultState={state.alertType}
                className="form__half input-wrapper--uppercase"
                label="Type"
                defaultOptionText="Alert Type (Performance or Settings)"
                error={errors.alertType}
                onChange={onChangeAlertType}
              />
              {/* <Dropdown
                label="Required"
                options={['Required', 'Optional']}
                onChange={(value) =>
                  editField('required', value === 'Required')
                }
                disabled={!isAdmin}
                defaultState={state.required ? 'Required' : 'Optional'}
                className="form__half input-wrapper--uppercase"
              /> */}
              {/* This was moved up, the original location is commented below */}
              <div className="form__half">
                <div className="form__row">
                  <Dropdown
                    options={ENTITY_STATUS_OPTIONS}
                    defaultState={state.active}
                    className="form__half input-wrapper--uppercase"
                    label="Active"
                    onChange={(status) => editField('active', status)}
                  />
                  <Dropdown
                    options={ALERT_TIME}
                    defaultOptionText="Select Time"
                    defaultState={state.time}
                    className="form__half input-wrapper--uppercase"
                    label="Time"
                    error={errors.time}
                    onChange={(time) => {
                      editField('time', time)
                      setErrors({ ...errors, time: null })
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="form__row">
              <OwnerDropdown
                currentUser={user}
                isNew={!editMode}
                allUsers={possibleOwners || []}
                selectedId={owner?._id || state.owner}
                onChange={(owner) => editField('owner', owner)}
                loading={!editMode ? false : !possibleOwners}
                loadingText={state.ownerName}
              />
            </div>
            {/* <div className="form__row">
              <div className="form__half">
                <div className="form__row">
                  <Dropdown
                    options={ENTITY_STATUS_OPTIONS}
                    defaultState={state.active}
                    className="form__half input-wrapper--uppercase"
                    label="Active"
                    onChange={(status) => editField('active', status)}
                  />
                  <Dropdown
                    options={ALERT_TIME}
                    defaultOptionText="Select Time"
                    defaultState={state.time}
                    className="form__half input-wrapper--uppercase"
                    label="Time"
                    error={errors.time}
                    onChange={(time) => {
                      editField('time', time)
                      setErrors({ ...errors, time: null })
                    }}
                  />
                </div>
              </div>
            </div> */}
          </div>
        </section>
      )}
      {/* Description */}
      {!isRestrictiveEdit && (
        <section className="form__section">
          <div className="form__section__header">Description</div>
          <div className="form__section__body">
            <TextArea
              placeholder="Alert description"
              onChange={(val) => editField('description', val)}
              value={state.description}
              error={errors.key}
            />
          </div>
        </section>
      )}
      {isRestrictiveEdit && (
        <section className="form__section">
          <div className="form__section__body">
            <div className="form__section__header">{state.name}</div>
            {state.description && <div>{state.description}</div>}
            <div className="alerts__use-alert">
              <span>Use Alert</span>{' '}
              <Toggle
                defaultChecked={runningOnCompany}
                onChange={setRunningOnCompany}
                disabled={!state.active || (isLocked && !readOnlyCanToggleMode)}
              />
              {isLocked && <img alt="lock" src={lockIcon} />}
            </div>
          </div>
        </section>
      )}
      {/* Apply to section */}
      {isAccountAlert && (
        <ApplyToSection
          accountsElements={accountsElements}
          accountsInSingleClient={accountsInSingleClient}
          activeAccountOptions={activeAccountOptions}
          editField={editField}
          errors={errors}
          filteredTemplates={filteredTemplates}
          isPerformanceAlert={isPerformanceAlert}
          label="Apply To"
          readOnlyCanToggleMode={readOnlyCanToggleMode}
          state={state}
          setErrors={setErrors}
          setState={setState}
          singleCompanyAlert={singleCompanyAlert}
        />
      )}
      {state.alertType &&
        !isPerformanceAlert &&
        state.selectedAccounts?.length >= 1 && (
          <section className="form__section">
            <div className="form__section__header">Properties</div>
            {/* Display properties for selected account types: facebookg, google, ... */}
            {activeAccountOptions?.map(
              (acc, idx) =>
                state.selectedAccounts.includes(acc) && (
                  <AlertProperties
                    key={idx}
                    errors={errors.properties?.[acc] || {}}
                    properties={
                      state.properties?.[acc] ||
                      defaultsPropertiesObj([acc])[acc]
                    }
                    setProperties={(values) =>
                      editField('properties', {
                        ...state.properties,
                        [acc]: values,
                      })
                    }
                    accountTypesWithPausedCampaigns={
                      state.accountTypesWithPausedCampaigns
                        ? state.accountTypesWithPausedCampaigns
                        : {}
                    }
                    setAccountTypesWithPausedCampaigns={(value) => {
                      editField('accountTypesWithPausedCampaigns', { ...value })
                    }}
                    account_type={acc}
                    selectedCompanies={
                      state.allCompaniesSelected
                        ? ownerCompanies.reduce((acc, curr) => {
                            if (curr.subsections.length) {
                              return [
                                ...acc,
                                curr.value,
                                ...curr.subsections.map(({ value }) => value),
                              ]
                            }

                            return [...acc, curr.value]
                          }, [])
                        : state.companies
                    }
                  />
                )
            )}
          </section>
        )}
      <AlertTemplateSection
        state={state}
        setState={(state) => {
          setDirty(true)
          setState(state)
        }}
        errors={errors}
        setErrors={setErrors}
        filteredTemplates={filteredTemplates}
        isPerformanceAlert={isPerformanceAlert}
        isRestrictiveEdit={isRestrictiveEdit}
        companiesList={companies?.list || []}
        hasDynamicMetrics={hasDynamicMetrics}
      />
      {isPerformanceAlert && (
        <PrimaryComparisonPeriodSection
          state={currentTemplate}
          errors={errors}
          hasEditAccess={false}
          variables={variables}
        />
      )}
      <section className="form__section">
        <AlertMessage
          alert={state}
          currentTemplate={currentTemplate}
          onChange={(message) => editField('message', message)}
        />
      </section>
      {/* <AlertEmbarqSection
        state={state}
        editField={editField}
        errors={errors}
        setErrors={setErrors}
        messageMetrics={messageMetrics}
        isPerformanceAlert={isPerformanceAlert}
        filteredTemplates={filteredTemplates}
      /> */}
      <AlertNotificationGroupSection
        errors={errors}
        state={state}
        setState={setState}
        editField={editField}
        hasEditAccess={hasEditAccess}
        isRestrictiveEdit={isRestrictiveEdit}
        readOnlyCanToggleMode={readOnlyCanToggleMode}
      />
      <section className="form__section">
        <div className="form__section__header">Notification Channels</div>
        <SendNotificationOptions
          types={NOTIFICATION_TYPES}
          channels={DELIVERY_OPTIONS}
          onChange={setNotificationOptions}
          teams={clientTeams}
          users={users}
          errors={errors.notificationOptions || {}}
          defaultState={state.notificationOptions}
          defaultTextType={'Select User / Team'}
          companies={state.companies}
          readOnly={readOnlyCanToggleMode}
          allCompaniesSelected={state.allCompaniesSelected}
        />
      </section>
      <StickyFooter buttons={footerButtons} />
    </form>
  )
}

const ERROR_MAP = {
  name: FIELD_TYPES.REQUIRED,
  category: FIELD_TYPES.REQUIRED,
  time: FIELD_TYPES.REQUIRED,
  alertType: FIELD_TYPES.REQUIRED,
}

CreateEditAlert.propTypes = {
  alert: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  allCompanies: PropTypes.array.isRequired,
  alertCategories: PropTypes.array,
  alertTemplates: PropTypes.array,
  saveAlert: PropTypes.func.isRequired,
  displayError: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  company: PropTypes.string,
  possibleOwners: PropTypes.array,
}

export default CreateEditAlert
