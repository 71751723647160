import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useStore } from 'store'
import UserManagementModule from 'modules/users'
import UserCreateEdit from 'modules/users/create-edit'
import { getTeams } from 'modules/teams/actions'
import { getPermissionGroups } from 'modules/permission-groups/actions'
import useSession from 'modules/session'
import Loader from 'components/loader'

const UserManagementPage = () => {
  const { userId } = useParams()
  const [, user] = useSession()
  const { dispatch, state } = useStore()
  const { list: teams } = state.teams
  const { list: users } = state.users
  const { list: permissionGroups } = state.permissionGroups

  const { currentCompany } = state.companies

  /** On mount, fetch data */
  useEffect(() => {
    if (!teams) {
      getTeams(dispatch, currentCompany ? currentCompany._id : null)
    }
    if (!permissionGroups) {
      getPermissionGroups(dispatch)
    }
  }, [])

  if (!users) {
    return <Loader />
  }

  if (userId) {
    return <UserCreateEdit userId={userId} isAdmin={user.isSuperAdmin} />
  } else {
    return <UserManagementModule />
  }
}

export default UserManagementPage
