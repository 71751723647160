import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/* Constants */
import {
  WIDE_COLUMNS,
  CSV_HEADER_COLUMNS,
  CSV_ROW_COLUMNS,
  COLUMNS,
  DROPDOWN_COLUMNS,
  validateColumnFormatting,
  REQUIRED_FIELDS,
  OPTIONAL_FIELDS,
  SINGLE_SELECT_DROPDOWNS,
  getArray,
} from 'modules/users/import-users/utils'

/* Utils */
import { ReactComponent as RemoveIcon } from 'assets/icon_minus.svg'
import { ReactComponent as IconWarning } from 'assets/icon_warning.svg'

/* Components */
import { Dropdown } from 'components/dropdown'
import { CheckboxNoHooks } from 'components/checkbox'
import DropdownPreviewSelected from 'components/dropdown-preview-selected/index'
import InputText from 'components/input/index'
import Icon from 'components/icon'
import Tooltip from 'components/tooltip'
import { entityStatus, csv, timezoneCountry, utils } from '@decision-sciences/qontrol-common'

const { ENTITY_STATUS_WITH_VALUES } = entityStatus
const { CSV_ARRAY_DELIMITER } = csv
const { TIMEZONES } = timezoneCountry
const { isEmpty } = utils.object

const ImportUsersTable = ({
  users = [],
  setUsers,
  userClients,
  teams,
  permissionGroups,
  allClients,
  errors,
  setErrors,
  businessUnits,
  accounts,
}) => {
  const [showTooltip, setShowTooltip] = useState(null)

  /** On component mount, set the errors object */
  useEffect(() => {
    if (users.length && userClients) {
      let newErrors = { ...errors }
      users.forEach((user) => {
        const fieldsToCheck = [...REQUIRED_FIELDS, ...OPTIONAL_FIELDS]
        fieldsToCheck.forEach((field) => {
          newErrors = updateErrors(newErrors, user, field)
        })
      })
      setErrors(newErrors)
    }
  }, [JSON.stringify(users), JSON.stringify(userClients)])

  const availableTeams = useMemo(
    () =>
      Object.keys(userClients).reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: teams?.filter((team) =>
            userClients[curr].some(
              (client) => client.clientId === team.companies?.[0]?._id
            )
          ),
        }),
        {}
      ),

    [JSON.stringify(teams), JSON.stringify(users)]
  )

  const globalTeams = teams?.filter((team) => team.isGlobal)

  const updateClientDependentFields = (user, values) => {
    const clients = getArray(values)
    const availableBus = userClients[user.id].reduce((acc, client) => {
      if (clients.includes(client.value)) {
        acc = [...acc, ...client.businessUnits.map(({ name }) => name)]
      }
      return acc
    }, [])

    const availableAccs = userClients[user.id].reduce((acc, client) => {
      if (clients.includes(client.value)) {
        acc = [
          ...acc,
          ...client.accounts.map(({ externalAccountId }) => externalAccountId),
        ]
      }
      return acc
    }, [])

    const availableUserTeams = [
      ...(availableTeams[user.id] || []),
      ...globalTeams,
    ].map((team) => team.name)

    const newBus = getArray(user.businessUnit).filter((bu) =>
      availableBus.includes(bu)
    )

    const newAccs = getArray(user.account).filter((acc) =>
      availableAccs.includes(acc)
    )

    const newTeams = getArray(user.team).filter((team) =>
      availableUserTeams.includes(team)
    )

    return {
      ...user,
      businessUnit: newBus.join(CSV_ARRAY_DELIMITER),
      account: newAccs.join(CSV_ARRAY_DELIMITER),
      team: newTeams.join(CSV_ARRAY_DELIMITER),
    }
  }

  const editField = (values, user, column) => {
    let editedUser = { ...user, [column]: values }
    // Update business units and accounts based on new clients selected
    if (column === CSV_HEADER_COLUMNS.CLIENT_ID.value && userClients[user.id]) {
      editedUser = updateClientDependentFields(editedUser, values)
    }

    const newUsers = users.map((us) => (us.id === user.id ? editedUser : us))
    setUsers(newUsers)
  }

  /**
   * Function to check for failed inputs for dropdown fields
   * For input fields this returns false since there are no options to check against
   * For clientID it checks against name and clientID.
   * For businessUnit and account is also checks if clientID is selected.
   * Optional field are checked only if they are completed
   * @param {Object} user User object
   * @param {String} column column
   * @param {boolean} isRequired If column is required
   * @return {boolean}
   */
  const hasFailedInputs = (column, user, isRequired) => {
    const availableOptions = geOptionsForColumn(column, user)
    const isDropdown = DROPDOWN_COLUMNS.includes(column)
    const fieldValues = isDropdown ? getArray(user[column]) : user[column]

    let inputFailedCondition = Array.isArray(fieldValues)
      ? fieldValues.some(
          (val) => !availableOptions?.map((op) => op.value).includes(val)
        )
      : false

    if (column === CSV_HEADER_COLUMNS.CLIENT_ID.value) {
      inputFailedCondition =
        availableOptions?.filter(
          (opt) =>
            fieldValues.includes(opt.value) ||
            fieldValues.includes(opt.clientId)
        ).length < fieldValues.length
    }

    if (
      [
        CSV_HEADER_COLUMNS.BUSINESS_UNIT.value,
        CSV_HEADER_COLUMNS.ACCOUNT.value,
      ].includes(column)
    ) {
      inputFailedCondition = isEmpty(user.clientID)
        ? true
        : inputFailedCondition
    }

    if (isRequired) {
      return inputFailedCondition
    }

    return !isEmpty(fieldValues) ? inputFailedCondition : false
  }

  const updateErrors = (existingErrors, user, field) => {
    const newRequired = { ...existingErrors.required }
    const newFailed = { ...existingErrors.failed }
    const isRequired = REQUIRED_FIELDS.includes(field)
    const isValidFormatting = validateColumnFormatting(user, field, users)
    const fieldValues = user[field]

    if (isRequired) {
      newRequired[user.id] = {
        ...newRequired[user.id],
        [field]: isEmpty(fieldValues),
      }
      // Check for valid format only if required field is completed
      if (!isEmpty(fieldValues)) {
        newFailed[user.id] = {
          ...newFailed[user.id],
          [field]:
            !isValidFormatting || hasFailedInputs(field, user, isRequired),
        }
      }
    } else {
      newFailed[user.id] = {
        ...newFailed[user.id],
        [field]: !isValidFormatting || hasFailedInputs(field, user, isRequired),
      }
    }

    return { required: newRequired, failed: newFailed }
  }

  const _renderRowSection = (userIdx, column, value) => {
    const columns = COLUMNS
    const first = columns.indexOf(column) === 0
    const second = columns.indexOf(column) === 1
    const last = columns.indexOf(column) === columns.length - 1
    const wider = WIDE_COLUMNS.includes(column)
    const failedInput = errors.failed[userIdx] && errors.failed[userIdx][column]
    const tooltip = `${userIdx}-${column}`
    const showFailedTooltip = showTooltip === tooltip

    const itemClass = cx('row-section', {
      'row-section--wider': wider,
      'row-section--last': last,
      'row-section--first': first,
      'row-section--second': second,
      'row-section--error-format': failedInput,
      'row-section--error-required':
        errors.required[userIdx] && errors.required[userIdx][column],
    })

    return (
      <div
        className={itemClass}
        onMouseEnter={() => failedInput && setShowTooltip(tooltip)}
        onMouseLeave={() => showFailedTooltip && setShowTooltip(null)}
      >
        {value}
        <Tooltip
          show={showFailedTooltip}
          content={
            <>
              <IconWarning />
              Failed inputs
            </>
          }
        />
      </div>
    )
  }

  const geOptionsForColumn = (column, user) => {
    switch (column) {
      case CSV_ROW_COLUMNS.ACCOUNT.value:
        return accounts[user.id]?.map((acc) => ({
          value: acc.externalAccountId,
          label: acc.externalAccountId,
          id: acc._id,
        }))
      case CSV_ROW_COLUMNS.BUSINESS_UNIT.value:
        return businessUnits[user.id]?.map((bu) => ({
          value: bu.name,
          label: bu.name,
          id: bu._id,
        }))
      case CSV_HEADER_COLUMNS.CLIENT_ID.value:
        return allClients.map((cl) => ({
          value: cl.name,
          label: cl.name,
          id: cl._id,
          clientId: cl.clientId,
          businessUnits: cl.businessUnits,
          accounts: cl.accounts,
        }))
      case CSV_HEADER_COLUMNS.TEAM.value:
        return [...(globalTeams || []), ...(availableTeams[user.id] || [])].map(
          (team) => ({
            value: team.name,
            label: team.name,
            id: team._id,
          })
        )
      case CSV_HEADER_COLUMNS.PERMISSION_GROUP.value:
        return (permissionGroups || []).map((pg) => ({
          value: pg.name,
          label: pg.name,
          id: pg._id,
        }))
      case CSV_HEADER_COLUMNS.STATUS.value:
        return ENTITY_STATUS_WITH_VALUES
      case CSV_HEADER_COLUMNS.TIMEZONE.value:
        return TIMEZONES.map((zone) => ({
          value: zone.label,
          label: zone.label,
        }))
    }
  }

  const getSelectedItemsForColumn = (user, column) => {
    switch (column) {
      case CSV_HEADER_COLUMNS.CLIENT_ID.value:
        return userClients[user.id]?.map((cl) => cl.value) || []
      default:
        return SINGLE_SELECT_DROPDOWNS.includes(column)
          ? [user[column]]
          : user[column].split(CSV_ARRAY_DELIMITER)
    }
  }

  const getNewValue = (newValues) =>
    Array.isArray(newValues)
      ? newValues.filter((v) => !!v).join(CSV_ARRAY_DELIMITER)
      : newValues

  const _renderDropdownValue = (option, selectedItems) => {
    return (
      <div>
        <CheckboxNoHooks
          label={option.label}
          isChecked={selectedItems.indexOf(option.value) > -1}
        />
      </div>
    )
  }

  const replaceIdsWithCompanyName = (companies) =>
    getArray(companies)
      .map((comp) => {
        const existingClient = allClients.find(
          (cl) => cl.name === comp.trim() || cl.clientId === comp.trim()
        )
        return existingClient ? existingClient.name : comp
      })
      .join('||')

  const getRowValue = (column, user) => {
    const isDropdown = DROPDOWN_COLUMNS.includes(column)
    const isRemove = column === CSV_ROW_COLUMNS.REMOVE.value

    if (isDropdown) {
      const selectedItems = getSelectedItemsForColumn(user, column)
      const options = geOptionsForColumn(column, user) || []
      const isSingleSelect = SINGLE_SELECT_DROPDOWNS.includes(column)
      const optionText =
        column === CSV_HEADER_COLUMNS.CLIENT_ID.value
          ? replaceIdsWithCompanyName(user[column])
          : user[column]

      return isSingleSelect ? (
        <Dropdown
          hasSearch
          displaySearchInOptions
          defaultOptionText={user[column]}
          defaultState={user[column]}
          characterToJoinSelected={CSV_ARRAY_DELIMITER}
          selectedItems={selectedItems}
          options={options}
          optionsHeight={300}
          onChange={(newValues) =>
            editField(getNewValue(newValues), user, column)
          }
        />
      ) : (
        <DropdownPreviewSelected
          options={options}
          optionsHeight={300}
          selectedItems={selectedItems}
          displayArrow
          defaultOptionToShow={optionText}
          placeholder={optionText}
          optionRenderer={(option, selectedItems) =>
            _renderDropdownValue(option, selectedItems)
          }
          onChange={(values) => {
            let newValues = values
            // If there are failed inputs, remove them
            if (errors.failed[user.id][column]) {
              newValues = values.filter((v) =>
                options.find((opt) => opt.value === v || opt.clientId === v)
              )
            }
            editField(getNewValue(newValues), user, column)
          }}
          selectAll
          disabled={
            [
              CSV_ROW_COLUMNS.ACCOUNT.value,
              CSV_ROW_COLUMNS.BUSINESS_UNIT.value,
            ].includes(column)
              ? !userClients[user.id]?.length
              : false
          }
        />
      )
    }

    if (isRemove) {
      return (
        <Icon
          onClick={() => {
            const newErrors = { ...errors }
            delete newErrors.required[user.id]
            delete newErrors.failed[user.id]
            const newUsers = users.filter((us) => us.id !== user.id)
            setUsers(newUsers)
            setErrors(newErrors)
          }}
          className={'padding-10'}
        >
          <RemoveIcon />
        </Icon>
      )
    }

    return (
      <InputText
        type="text"
        className={'no-background'}
        value={user[column]}
        onChange={(val) => editField(getNewValue(val), user, column)}
      />
    )
  }

  const _renderTableRow = (columns) =>
    users.map((user, idx) => (
      <div key={idx} className="row">
        {columns.map((col) =>
          _renderRowSection(user.id, col, getRowValue(col, user))
        )}
      </div>
    ))

  const _renderTableHeader = (columns) => (
    <div className="row row-header">
      {columns.map((col) => {
        const label = Object.values(CSV_HEADER_COLUMNS).find(
          (obj) => obj.value === col
        )?.label
        return _renderRowSection(null, col, label)
      })}
    </div>
  )

  return (
    <div className="import-users-table">
      {users.length ? (
        <>
          <div className="scrollable-columns">
            {_renderTableHeader(COLUMNS)}
            {_renderTableRow(COLUMNS)}
          </div>
        </>
      ) : null}
    </div>
  )
}
export default ImportUsersTable

ImportUsersTable.propTypes = {
  setUsers: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  editField: PropTypes.func.isRequired,
  userClients: PropTypes.object.isRequired,
  teams: PropTypes.array.isRequired,
  permissionGroups: PropTypes.array.isRequired,
  allClients: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  businessUnits: PropTypes.array.isRequired,
}

