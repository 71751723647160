import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/* Icons */
import { ReactComponent as WarningIcon } from 'assets/icon_warning.svg'
import { ReactComponent as ErrorIcon } from 'assets/icon_warning_round_red.svg'
import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'

/* Styles */
import './style.scss'

export const InformationBlock = ({
  type = INFORMATION_BLOCK_TYPE.ERROR,
  className,
  info = '',
  style,
}) => {
  /* Decides the icon and the class of the information block based on it's type */
  const [{ Icon, iconClasses }, classes] = useMemo(() => {
    return [INFORMATION_BLOCK_ICONS[type], INFORMATION_BLOCK_CLASSES[type]]
  }, [type, className])

  return (
    <div
      data-testid="information-block"
      className={cx('information-block', classes, className)}
      style={style}
    >
      <div className="image-box">
        <Icon
          className={cx('image', iconClasses)}
          data-testid="information-block-image"
        />
      </div>
      <div data-testid="information-block-info" className="general-description">
        {info}
      </div>
    </div>
  )
}

export default InformationBlock

export const INFORMATION_BLOCK_TYPE = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
  SIMULATION: 'SIMULATION',
}

InformationBlock.propTypes = {
  type: PropTypes.oneOf(Object.values(INFORMATION_BLOCK_TYPE)),
  info: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}

const INFORMATION_BLOCK_ICONS = {
  [INFORMATION_BLOCK_TYPE.ERROR]: {
    Icon: ErrorIcon,
    iconClasses: 'fill-red',
  },
  [INFORMATION_BLOCK_TYPE.WARNING]: {
    Icon: WarningIcon,
    iconClasses: 'fill-orange',
  },
  [INFORMATION_BLOCK_TYPE.INFO]: {
    Icon: InfoIcon,
    iconClasses: 'fill-blue',
  },
  [INFORMATION_BLOCK_TYPE.SIMULATION]: {
    Icon: WarningIcon,
    iconClasses: 'svg--liliac',
  },
}

const INFORMATION_BLOCK_CLASSES = {
  [INFORMATION_BLOCK_TYPE.ERROR]: 'information-block-error',
  [INFORMATION_BLOCK_TYPE.WARNING]: 'information-block-warning',
  [INFORMATION_BLOCK_TYPE.INFO]: 'information-block-info',
  [INFORMATION_BLOCK_TYPE.SIMULATION]: 'information-block-simulation',
}
