import Api from 'easy-fetch-api'
import { utils } from '@decision-sciences/qontrol-common'

const { stringToID } = utils.string

export const ACTIONS = {
  SET_LEGAL_ITEMS: 'legal.setLegalItems',
  SET_DISPLAY_LEGAL_ITEMS: 'legal.setDisplayLegalItems',
  SET_CURRENT_EDITED_LEGAL: 'legal.setCurrentEditedLegal',
  SET_CURRENT_EDITED_LEGAL_SECTION: 'legal.setCurrentEditedLegalSection',
  SET_USERS: 'legal.setUsers',
  DELETE_LEGAL_ITEM: 'legal.deleteItem',
  DELETE_LEGAL_SECTION: 'legal.deleteSection',
  TOGGLE_LEGAL_DISPLAY: 'legal.toggleLegalDisplay',
}

/**
 * Fetches the list of legal documents.
 * This fetches only fields needed for the table in the CMS part, not the whole entity with relations.
 *
 * @param {Function} dispatch Dispatch function
 * @returns {Object} API response
 */
export const getLegalItems = async (dispatch) => {
  const result = await Api.get({ url: '/api/legal' })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) || 'Error getting legal items'
    )
  }

  dispatch({ type: ACTIONS.SET_LEGAL_ITEMS, list: result.data })
  return result
}

/**
 * Fetches a legal docummentation by ID.
 * This will set the `currentEditedLegal` field in the store with the legal item returned by the server.
 *
 * @param {Function} dispatch Dispatch function
 * @param {String} id Legal Document ID
 * @returns {Object} API response
 */
export const getLegalItemById = async (dispatch, id) => {
  const result = await Api.get({ url: `/api/legal/${id}` })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) || `Error getting legal item for id ${id}`
    )
  }

  dispatch({
    type: ACTIONS.SET_CURRENT_EDITED_LEGAL,
    legalItem: { ...result.data, isDirty: false },
  })
  return result
}

/**
 * Sets the currently edited legal docummentation.
 * This will set the `currentEditedLegal` field in the store with the given input.
 *
 * @param {Function} dispatch Dispatch function
 * @param {Object} legalItem Legal item object
 * @returns {Object} API response
 */
export const setCurrentEditedLegal = async (dispatch, legalItem) => {
  dispatch({
    type: ACTIONS.SET_CURRENT_EDITED_LEGAL,
    legalItem,
  })
  return legalItem
}

/**
 * Updates the curreny edited legal item
 * @param {Function} dispatch Dispatch function
 * @param {Object} legalItem Legal item object
 * @returns {Object} Updated legal item object
 */
export const updateCurrentEditedLegal = async (dispatch, legalItem) => {
  dispatch({
    type: ACTIONS.SET_CURRENT_EDITED_LEGAL,
    legalItem: { ...legalItem, isDirty: true },
  })
  return legalItem
}

/**
 * Creates or updates a given legal item.
 *
 * @param {Function} dispatch Dispatch function
 * @param {Object} legalItem Legal item object to create or update
 * @returns {Promise<Object>} API response
 */
export const createUpdateLegalItem = async (dispatch, legalItem) => {
  return new Promise(async (resolve, reject) => {
    delete legalItem.isNew
    delete legalItem.isDirty

    legalItem.slug = stringToID(legalItem.name)

    const payload = { url: `/api/legal`, data: legalItem }

    let apiCall
    if (legalItem._id) {
      apiCall = Api.put(payload)
    } else {
      apiCall = Api.post(payload)
    }

    try {
      const result = await apiCall
      if (!result || !result.success) {
        console.error((result && result.error) || `Error saving legal item`)
        reject(result)
      }

      /** Update the currently selected legal */
      dispatch({
        type: ACTIONS.SET_CURRENT_EDITED_LEGAL,
        legalItem: result.data,
      })

      resolve(result.data)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

/**
 * Deletes a legal item.
 *
 * @param {Function} dispatch Dispatch function
 * @param {String} id Legal item ID
 * @returns {Object}
 */
export const deleteLegalItem = async (dispatch, id) => {
  const result = await Api.delete({ url: `/api/legal/${id}` })
  if (!result || !result.success) {
    return console.error(
      (result && result.error) || `Error deleting legal item`
    )
  }

  dispatch({ type: ACTIONS.DELETE_LEGAL_ITEM, id })

  return result
}

/**
 * Updates the legal items list order.
 * Used when the legal items are reordered with the table drag and drop to reflect the changes real-time.
 * This does not make any calls to API to persist the new order of the legal items.
 *
 * @param {Function} dispatch Dispatch function
 * @param {Array<Object>} reorderedLegalItems Reordered legal items array
 */
export const reorderLegal = (dispatch, reorderedLegalItems) => {
  reorderedLegalItems.forEach((item, index) => (item.orderIndex = index))
  dispatch({
    type: ACTIONS.SET_LEGAL_ITEMS,
    list: reorderedLegalItems,
    dirty: true,
  })
}

/**
 * This function will update all legal items `orderIndex` field.
 * It is used in correlation with the table drag and drop reordering, to persist the changes.
 * This is the only operation allowed on the legal items overview page.
 *
 * @param {Function} dispatch Dispatch function
 * @param {Array<Object>} legalItems Reordered legal items array
 */
export const saveLegalItems = async (dispatch, legalItems) => {
  const result = await Api.post({ url: '/api/legal/reorder', data: legalItems })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) || 'Error getting legal items'
    )
  }

  dispatch({ type: ACTIONS.SET_LEGAL_ITEMS, list: legalItems })
  return result
}

/* LEGAL VIEW RELATED ACTIONS */

/**
 * Fetches the list of legal items that should be displayed in a certain area of the app
 *
 * @param {Function} dispatch Dispatch function
 * @param {String} area Filter for displayArea
 * @returns {Object}
 */
export const getLegalViewItems = async (dispatch, area) => {
  const result = await Api.get({ url: `/api/legal/view?area=${area}` })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) || 'Error getting legal view items'
    )
  }
  dispatch({
    type: ACTIONS.SET_DISPLAY_LEGAL_ITEMS,
    viewList: result.data.splice(0, 5),
  })
  return result
}

/**
 * Fetches a legal item by it's unique slug.
 * This will not return sections that have `display` set to false.
 *
 * @param {String} slug Unique identifier for a legal item
 * @returns {Object}
 */
export const getLegalBySlug = async (slug) => {
  const result = await Api.get({ url: `/api/legal/view/${slug}` })
  if (!result || !result.success) {
    return console.error(
      (result && result.error) || 'Error getting legal item by slug'
    )
  }

  return result.data
}

/** LEGAL SECTION RELATED ACTIONS */

/**
 * Creates a new legal section and sets it as the currently edited one
 *
 * @param {Function} dispatch Dispatch function
 * @returns {Object} Newly created legal item
 */
export const setCurrentEditedLegalSection = async (dispatch, legalSection) => {
  dispatch({
    type: ACTIONS.SET_CURRENT_EDITED_LEGAL_SECTION,
    legalSection,
  })
  return legalSection
}

/**
 * Updates the curreny edited legal section
 *
 * @param {Function} dispatch Dispatch function
 * @param {Object} legalSection Legal section object
 * @returns {Object} Updated legal section object
 */
export const updateCurrentEditedLegalSection = async (
  dispatch,
  legalSection
) => {
  dispatch({
    type: ACTIONS.SET_CURRENT_EDITED_LEGAL_SECTION,
    legalSection: { ...legalSection, isDirty: true },
  })
  return legalSection
}

/**
 * Creates or updates a legal section for a legal documentation entity
 *
 * @param {Function} dispatch Dispatch function
 * @param {Object} legalSection Legal section object to create or update
 * @param {String} legalId Legal documentation ID to which this section belongs to
 * @returns {Promise<*>}
 */
export const createUpdateLegalSection = async (
  dispatch,
  legalSection,
  legalId
) => {
  return new Promise(async (resolve, reject) => {
    delete legalSection.isNew
    delete legalSection.isDirty

    const payload = { url: `/api/legal/section/${legalId}`, data: legalSection }

    let apiCall
    if (legalSection._id) {
      apiCall = Api.put(payload)
    } else {
      apiCall = Api.post(payload)
    }

    try {
      const result = await apiCall
      if (!result || !result.success) {
        console.error((result && result.error) || `Error saving legal section`)
        reject(result)
      }

      resolve(result.data)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

/**
 * Deletes a legal section by it's ID
 *
 * @param {Function} dispatch Dispatch function
 * @param {String} id ID of the legal section to delete
 * @returns {Promise<*>}
 */
export const deleteLegalSection = async (dispatch, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Api.delete({ url: `/api/legal/section/${id}` })
      if (!result || !result.success) {
        return console.error(
          (result && result.error) || `Error deleting legal section`
        )
      }
      dispatch({ type: ACTIONS.DELETE_LEGAL_SECTION, id })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

/**
 * Updates only the display field of a legal section
 *
 * @param {Function} dispatch Dispatch function
 * @param {String} id ID of the legal section
 * @param {boolean} display The new value for the `display` property
 * @returns {Promise}
 */
export const toggleLegalSectionDisplay = async (dispatch, id, display) => {
  const result = await Api.put({
    url: `/api/legal/section/${id}/display`,
    data: { display },
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) || `Error deleting legal section`
    )
  }

  dispatch({ type: ACTIONS.TOGGLE_LEGAL_DISPLAY, id, display })

  return result
}

/**
 * Sets the `sections` array of the currently edited legal item to the new reordered one.
 * This action does not make any API calls to save it to the DB.
 *
 * @param {Function} dispatch Dispatch function
 * @param {Object} legalItem The legal item for which to reorder the sections
 * @param {Array<Object>} reorderedLegalSections The reordered legal sections array
 */
export const reorderLegalSections = (
  dispatch,
  legalItem,
  reorderedLegalSections
) => {
  reorderedLegalSections.forEach((item, index) => (item.orderIndex = index))

  legalItem.sections = [...reorderedLegalSections]
  legalItem.isDirty = true

  dispatch({
    type: ACTIONS.SET_CURRENT_EDITED_LEGAL,
    legalItem,
  })
}

/* USER RELATED ACTIONS */

/**
 * Fetches the filtered or unfiltered list of all the users that accepted the terms of use.
 *
 * @param {Function} dispatch Dispatch function
 * @param {Object} query Filters
 * @returns {Promise<*>}
 */
export const getUsersWhoAcceptedTOU = async (dispatch, query) => {
  return new Promise(async (resolve, reject) => {
    const result = await Api.post({
      url: `/api/users/legal-accepted`,
      data: query,
    })

    if (!result || !result.success) {
      console.error(
        (result && result.error) || `Error getting users who accepted TOU`
      )
      return reject(result?.error)
    }

    dispatch({
      type: ACTIONS.SET_USERS,
      users: result.list,
    })

    resolve(result.list)
  })
}

