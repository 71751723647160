import Api from 'easy-fetch-api'

/**
 * Gets all accounts for a specific client
 * @param {*} clientId Company Id
 */
export const getAccounts = async (clientId) =>
  new Promise((resolve, reject) => {
    Api.get({
      url: `/api/tiktok/client-accounts/${clientId}`,
    }).then((res) => {
      if (!res || res.error) {
        reject(res.error)
      } else {
        resolve(res.data)
      }
    })
  })

/**
 * Gets all campaigns for a specific client
 * @param {*} clientId Company Id
 * @param {Object} signal - abort signal
 */
export const getCampaigns = (clientId, signal) =>
  new Promise((resolve, reject) => {
    Api.get({
      url: `/api/tiktok/client-campaigns/${clientId}`,
      signal,
    }).then((res) => {
      if (!res || res.error) {
        reject(res?.error)
      } else {
        resolve(res.data)
      }
    })
  })

/**
 * Gets all placements for a specific client
 * @param {*} clientId Company Id
 * @param {Object} signal - abort signal
 */
export const getPlacements = (clientId, signal) =>
  new Promise((resolve, reject) => {
    Api.get({
      url: `/api/tiktok/ad-group-placements/${clientId}`,
      signal,
    }).then((res) => {
      if (!res || res.error) {
        reject(res?.error)
      } else {
        resolve(res.data)
      }
    })
  })

/**
 * Gets all adgroups for a specific account - campaign
 * @param {String} clientId Company Id
 * @param {String} campaignId Campaign Id
 * @param {String} account_id TikTok account id
 * @param {Object} signal - abort signal
 */
export const getAdGroups = (clientId, signal, campaignId, account_id) =>
  new Promise((resolve, reject) => {
    Api.get({
      url: `/api/tiktok/ad-groups/`,
      query: { clientId, campaignId, externalAccountId: account_id },
      signal,
    }).then((res) => {
      if (!res || res.error) {
        reject(res?.error)
      } else {
        resolve(res.data)
      }
    })
  })

/**
 * Gets all ads for a specific campaign and adset
 * @param {String} clientId Company Id
 * @param {String} adgroup_id TikTok AdGroup Id
 * @param {String} account_id TikTok Account Id
 * @param {Object} signal - abort signal
 */
export const getAds = (clientId, signal, adgroup_id, account_id) =>
  new Promise((resolve, reject) => {
    Api.get({
      url: `/api/tiktok/ads`,
      query: { clientId, adgroup_id, externalAccountId: account_id },
      signal,
    }).then((res) => {
      if (!res || res.error) {
        reject(res?.error)
      } else {
        resolve(res.data)
      }
    })
  })
