import { v4 as uuid } from 'uuid'

import * as ModalDefinition from './index'

export const ACTIONS = {
  OPEN_MODAL: 'modal.openModal',
  CLOSE_MODAL: 'modal.closeModal',
  CLOSE_ALL_MODALS: 'modal.closeAll',
}

/**
 * Action to programatically open a modal. The opened modal needs to be closed manually as it would be closed when used as a component.
 * @param {Function} dispatch Dispatch function
 * @param {ModalDefinition.ModalProps} modalConfig Component props
 * @returns {String} The created modals uid
 */
export const openModal = (dispatch, modalConfig) => {
  const uid = uuid()
  dispatch({ type: ACTIONS.OPEN_MODAL, instance: { ...modalConfig, uid } })
  return uid
}

/**
 * Action to programatically close a modal
 * @param {Function} dispatch Dispatch function
 * @param {String} uid Modal identifier
 */
export const closeModal = (dispatch, uid) => {
  dispatch({ type: ACTIONS.CLOSE_MODAL, uid })
}

/**
 * Action to programatically close all opened modals
 * @param {Function} dispatch Dispatch function
 */
export const closeAllModals = (dispatch) => {
  dispatch({ type: ACTIONS.CLOSE_ALL_MODALS })
}
