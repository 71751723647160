import React, { useMemo, useState, useRef, useEffect } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import merge from 'lodash.merge'
import { Dropdown } from 'components/dropdown'
import Input from 'components/input'
import DateTimeComponent from 'components/date-time'
import { CheckboxNoHooks } from 'components/checkbox'
import TimeComponent from 'components/time'
import iconMinus from 'assets/icon_minus.svg'
import iconPlus from 'assets/icon_plus_blue.svg'
import { useOnClickOutside } from 'hooks/outside-click'
import { showErrorMessage } from 'modules/notifications/actions'
import { getEnumValuesFromApi } from 'modules/alerts/actions'
import { useEffectOnUpdate } from 'components/utils/custom-hooks'
import {
  publisherFieldMap,
  accounts,
  granularities,
} from '@decision-sciences/qontrol-common'

const { ACCOUNT_TYPES_MAP } = accounts
const { GRANULARITIES } = granularities
const { FIELD_MAP } = publisherFieldMap
const { FIELD_OPERATIONS_MAP, FIELD_TYPES } = publisherFieldMap.operations

const { GOOGLE, FACEBOOK, MICROSOFT, TRADE_DESK, TIKTOK, AMAZON_DSP, DV360 } =
  ACCOUNT_TYPES_MAP
const { ACCOUNT, CAMPAIGN, AD_GROUP, AD, KEYWORD, INSERTION_ORDER } =
  GRANULARITIES

/**
 * Alert Properties Component
 * @param {Object} props props
 * @param {Array} props.properties properties
 * @param {Array} props.errors errors
 * @param {Function} props.setProperties set properties function
 * @param {String} props.account_type account type
 * @param {Array} props.selectedCompanies selected companies of alert
 * @returns {React.Component}
 */
const AlertProperties = ({
  errors = [],
  properties,
  setProperties,
  accountTypesWithPausedCampaigns,
  setAccountTypesWithPausedCampaigns,
  account_type,
  selectedCompanies,
}) => {
  errors = errors || []
  const { dispatch } = useStore()

  /**
   * Fetch enum values from the API, if they are needed
   * Store the values to use in a separate (final) state, and save newly brought ones into a state.
   * When the state changes, merge the contents of the final state with the new state
   * This is done as a workaround to avoid stale states. There are multiple calls going out for different field types,
   * and each of them would update the collection of enum values it had before sending the request. Therefore, if there are multiple
   * calls happening at the same time, the final list of enum values would not contain all of their results.
   */
  const [enumValuesFromApi, setEnumValuesFromApi] = useState({})
  const [enumValuesFromApiFinal, setEnumValuesFromApiFinal] =
    useState(enumValuesFromApi)
  useEffect(() => {
    if (Object.keys(enumValuesFromApi).length) {
      setEnumValuesFromApiFinal(
        merge({}, enumValuesFromApiFinal, enumValuesFromApi)
      )
    } else {
      setEnumValuesFromApiFinal(enumValuesFromApi)
    }
  }, [JSON.stringify(enumValuesFromApi)])

  const [fetchEnumValuesFromApInProgress, setFetchEnumValuesFromApInProgress] =
    useState(false)

  // When companies change, refetch enums
  useEffectOnUpdate(() => {
    /** Reset the values to avoid unexpected concurrency issues */
    setEnumValuesFromApi({})
    getEnumsFromApi({ changedByCompanies: true })
  }, [JSON.stringify(selectedCompanies)])

  // When props change, check if we have new fields that need enum values
  useEffect(() => {
    const fieldsThatNeedEnumsFromApi = getFieldsThatNeedEnumsFromApi()

    // If there are fields that need enums from the API
    if (Object.keys(fieldsThatNeedEnumsFromApi).length) {
      /** Find fields that do not yet have values and are not being fetched right now */
      const fieldsToFetchFor = {}

      Object.keys(fieldsThatNeedEnumsFromApi).forEach((granularity) => {
        const granularityFields = fieldsThatNeedEnumsFromApi[granularity]

        const fieldsWithoutValuesNotBeingFetchedYet = granularityFields.filter(
          (field) =>
            !enumValuesFromApiFinal?.[granularity]?.[field] &&
            !fetchEnumValuesFromApInProgress?.[granularity]?.[field]
        )

        fieldsWithoutValuesNotBeingFetchedYet.forEach((field) => {
          if (!fieldsToFetchFor[granularity]) {
            fieldsToFetchFor[granularity] = []
          }
          fieldsToFetchFor[granularity].push(field)
        })
      })

      if (Object.keys(fieldsToFetchFor).length) {
        getEnumsFromApi({ fieldsThatNeedEnumsFromApi: fieldsToFetchFor })
      }
    }
  }, [JSON.stringify(properties)])

  /**
   * Find field types that need enum values from the API
   * @returns {Object} {[granularity]: {[field]: true}}
   */
  const getFieldsThatNeedEnumsFromApi = () => {
    const getList = (propList) =>
      propList.reduce((acc, prop) => {
        const { property, type, props } = prop

        /** Property is part of a conditional chain */
        if (props) {
          return { ...acc, ...getList(props) }
        } else {
          // Get mapping of property
          const fieldMap = type
            ? getOnlySettingsFields(FIELD_MAP[account_type][type])
            : {}
          const fieldMapping = property ? fieldMap[property] : null

          if (fieldMapping?.enumFromApi) {
            return { ...acc, [type]: [...(acc[type] || []), property] }
          }

          return acc
        }
      }, {})

    return getList(properties)
  }

  /**
   * Fetch enums from API and set them
   * @param {Object} options options
   * @param {Boolean} [options.changedByCompanies] if set, it means the refetch is happening because the comapnies have changed
   * @param {Boolean} [options.fieldsThatNeedEnumsFromApi] fields to get values for
   */
  const getEnumsFromApi = async ({
    changedByCompanies = false,
    fieldsThatNeedEnumsFromApi = null,
  }) => {
    if (
      !fieldsThatNeedEnumsFromApi ||
      !Object.keys(fieldsThatNeedEnumsFromApi)?.length
    ) {
      fieldsThatNeedEnumsFromApi = getFieldsThatNeedEnumsFromApi()
    }

    // If there are no fields that need enums from the API, stop
    if (!Object.keys(fieldsThatNeedEnumsFromApi).length) {
      return
    }

    const fetchEnumPromises = []
    const enumValues = {}

    setFetchInProgressForProps(fieldsThatNeedEnumsFromApi, true)

    for (const type of Object.keys(fieldsThatNeedEnumsFromApi)) {
      const props = fieldsThatNeedEnumsFromApi[type]

      for (const prop of props) {
        // If the companies did not change, Check if for this type we already have the values fetched
        if (
          !changedByCompanies &&
          enumValuesFromApiFinal?.[type]?.[prop]?.length
        ) {
          continue
        }

        fetchEnumPromises.push(
          new Promise(async (resolve) => {
            const res = await getEnumValuesFromApi({
              selectedCompanies,
              account_type,
              granularity: type,
              prop,
            }).catch((err) => showErrorMessage(err, dispatch))

            if (res.error) {
              showErrorMessage(res.error, dispatch)
            }

            const { parent } = res || {}

            if (parent?.length) {
              enumValues[type] = {
                ...(enumValues?.[type] || {}),
                [prop]: parent,
              }
            }

            resolve()
          })
        )
      }
    }

    await Promise.all(fetchEnumPromises)
    setEnumValuesFromApi(enumValues)

    setFetchInProgressForProps(fieldsThatNeedEnumsFromApi, false)
  }

  const setFetchInProgressForProps = (fieldsThatNeedEnumsFromApi, value) => {
    const newFetchInProgress = { ...fetchEnumValuesFromApInProgress }

    for (const type of Object.keys(fieldsThatNeedEnumsFromApi)) {
      if (!newFetchInProgress[type]) {
        newFetchInProgress[type] = {}
      }

      const props = fieldsThatNeedEnumsFromApi[type]
      for (const prop of props) {
        /** Unset fetch in progress for the current prop */
        newFetchInProgress[type][prop] = value
      }
    }

    setFetchEnumValuesFromApInProgress(newFetchInProgress)
  }

  return (
    <div className="form__section__body">
      <div className="general-label property-settings-type">
        {account_type} ads properties
      </div>
      {account_type === ACCOUNT_TYPES_MAP.GOOGLE ? (
        <CheckboxNoHooks
          label={'Paused Campaigns'}
          isChecked={accountTypesWithPausedCampaigns[account_type]}
          onChange={() => {
            setAccountTypesWithPausedCampaigns({
              ...accountTypesWithPausedCampaigns,
              [account_type]: !accountTypesWithPausedCampaigns[account_type],
            })
          }}
          className="checkbox-first"
        />
      ) : null}

      <div className="alerts__property__labels">
        <div className="general-label">Property Type</div>
        <div className="general-label">Property</div>
        <div className="general-label"></div>
        <div className="general-label">Value</div>
      </div>

      {properties.map((row, idx) => {
        const property = (
          key,
          propRow,
          indexInConditional = null,
          showSeparator = true
        ) => (
          <React.Fragment key={key}>
            <Property
              idx={idx}
              row={propRow}
              properties={properties}
              setProperties={setProperties}
              error={
                indexInConditional >= 0 && indexInConditional !== null
                  ? errors[idx]?.[indexInConditional]
                  : errors[idx]?.[0]
              }
              indexInConditional={indexInConditional}
              account_type={account_type}
              enumValuesFromApi={enumValuesFromApiFinal}
              fetchContentInProgress={
                fetchEnumValuesFromApInProgress?.[propRow.type]?.[
                  propRow.property
                ] || false
              }
              selectedCompanies={selectedCompanies}
              disptach={dispatch}
            />
            {idx < properties.length - 1 && showSeparator && (
              <div className="flow__separator"></div>
            )}
          </React.Fragment>
        )

        const { conditions, props } = row

        // Conditional row
        if (conditions) {
          const conditionClassName = (cond, index) =>
            cx('alerts__property__condition-option', {
              'alerts__property__condition-option--selected':
                conditions[index] === cond,
            })

          const selectCondition = (value, index) => {
            const newProperties = [...properties]
            newProperties[idx].conditions[index] = value
            setProperties(newProperties)
          }

          const conditionSwitch = (index) => (
            <div className="alerts__property__condition" key={index}>
              <div
                className={conditionClassName('and', index)}
                role="button"
                onClick={() => {
                  selectCondition('and', index)
                }}
              >
                AND
              </div>
              <div
                className={conditionClassName('or', index)}
                role="button"
                onClick={() => {
                  selectCondition('or', index)
                }}
              >
                OR
              </div>
            </div>
          )

          return props.map((prop, index) => (
            <>
              {property(
                `${idx}_${index}`,
                prop,
                index,
                index === props.length - 1
              )}
              {index < props.length - 1 && conditionSwitch(index)}
            </>
          ))
        }

        // Normal row
        return property(idx, row)
      })}
    </div>
  )
}

/* Property component
It can be either a normat row or part of a conditional
When it a normal row, it simply containt its data
When it is part of a conditinal row, the row it belongs to has the following sturcture:
  {
    conditions: Array(n - 1)
    props: Array(n)
  }
where - n is the number of rows in the conditional chain
      - conditions contains values: 'and', 'or'
      - props' elements have the same data as a normal row would
**/
const Property = ({
  idx,
  row,
  properties,
  setProperties,
  error,
  indexInConditional,
  account_type,
  enumValuesFromApi,
  fetchContentInProgress,
  selectedCompanies,
  dispatch,
}) => {
  const conditionalProperty =
    indexInConditional !== null && indexInConditional >= 0

  const isSettings = isSettingsOperation(row.operation)

  const fieldMap = row.type
    ? getOnlySettingsFields(FIELD_MAP[account_type][row.type])
    : {}

  const fieldMapping = row.property ? fieldMap[row.property] : null
  const deprecatedProperty = row.property && !fieldMap[row.property]

  let fieldType = FIELD_TYPES.INPUT
  if (fieldMapping?.enum) {
    fieldType = FIELD_TYPES.ENUM
  } else if (fieldMapping?.type) {
    fieldType = fieldMapping?.type
  }

  let childFieldType = FIELD_TYPES.INPUT
  if (fieldMapping?.child?.enum) {
    childFieldType = FIELD_TYPES.ENUM
  } else if (fieldMapping?.child?.type) {
    childFieldType = fieldMapping?.child?.type
  }

  const mappedChild = fieldMapping?.child
  // Only render child options if the field has childs mapped and the current row's operation allows it
  const checkChildValues = mappedChild && !isSettings

  // When changing operations to one without children, delete previously set children data for this row
  useEffect(() => {
    if (!checkChildValues) {
      rowUpdated('childValue', null)
      rowUpdated('childOperation', null)
    }
  }, [checkChildValues])

  const rowUpdated = (fieldName, fieldValue) => {
    const newProperties = properties

    if (!conditionalProperty) {
      // When type changes, reset the row
      if (fieldName === 'type') {
        newProperties[idx] = { [fieldName]: fieldValue }
      } else {
        // Else just update the current field
        newProperties[idx][fieldName] = fieldValue
      }
    } else {
      // When type changes, reset the row
      if (fieldName === 'type') {
        newProperties[idx].props[indexInConditional] = {
          [fieldName]: fieldValue,
        }
      } else {
        // Else just update the current field
        newProperties[idx].props[indexInConditional][fieldName] = fieldValue
      }
    }

    setProperties(newProperties)
  }

  const removeRow = () => {
    const newProperties = properties

    // if normal, simply delete
    if (!conditionalProperty) {
      newProperties.splice(idx, 1)
    } else {
      // if before deletion there are only 2 left in this conditional => turn back to normal
      if (newProperties[idx].props.length === 2) {
        newProperties[idx] = properties[idx].props.splice(
          indexInConditional - 1,
          1
        )?.[0]
      } else {
        newProperties[idx].conditions.splice(indexInConditional - 1, 1)
        newProperties[idx].props.splice(indexInConditional, 1)
      }
    }
    setProperties(newProperties)
  }

  const addConditionalRow = () => {
    const newProperties = properties

    // if normal, transform into condtionals
    if (!conditionalProperty) {
      newProperties[idx] = {
        conditions: ['and'],
        props: [properties[idx], {}],
      }
    } else {
      // if already conditional, just add one more condition
      newProperties[idx].conditions.splice(indexInConditional, 0, 'and')
      newProperties[idx].props.splice(indexInConditional + 1, 0, {})
    }

    setProperties(newProperties)
  }

  const operationOptions = useMemo(() => {
    const operations = []

    // If there are no operations specified for the type, use default ones
    const operationsObject = {
      ...(fieldMapping?.operations || FIELD_OPERATIONS_MAP),
    }

    Object.keys(operationsObject).forEach((section) => {
      Object.values(operationsObject[section]).forEach((op, idx) => {
        operations.push({
          label: op,
          value: op,
          sectionLabel: idx === 0 ? section : null,
        })
      })
    })

    return operations
  }, [JSON.stringify(fieldMapping), JSON.stringify(row)])

  const [plusDropdownOpen, setPlusDropdownOpen] = useState(false)

  const propAddDropdownRef = useRef()

  useOnClickOutside(
    propAddDropdownRef,
    () => plusDropdownOpen && setPlusDropdownOpen(false)
  )

  const [fetchChildrenInProgress, setFetchChildrenInProgress] = useState(false)
  const [childEnumValues, setChildEnumValues] = useState({})

  /**
   * Fetch children enums of a content type from API and set them
   * @param {Boolean} parent Get the children options of this parent
   */
  const getChildrenEnumsFromApi = async (parent) => {
    setFetchChildrenInProgress(true)
    const res = await getEnumValuesFromApi({
      selectedCompanies,
      account_type,
      parent,
      granularity: row.type,
      prop: row.property,
    }).catch((err) => showErrorMessage(err, dispatch))

    if (res.error) {
      showErrorMessage(res.error, dispatch)
    }

    const { child } = res || {}

    if (child?.length) {
      const newEnumValues = {}
      if (!newEnumValues[row.type]) {
        newEnumValues[row.type] = {}
      }
      newEnumValues[row.type][row.property] = child
      setChildEnumValues({ ...newEnumValues })

      // If the new child array doesn not contain the already selected option, reset the child
      if (!child.some((el) => el === row.childValue)) {
        rowUpdated('childValue', null)
      }
    }

    setFetchChildrenInProgress(false)
  }

  /** Listen to changes on parent values from the API or the selected coampanies */
  useEffectOnUpdate(() => {
    /** If the values from the API have been reset, reset the child values, the field's selected parent and child values */
    if (!Object.keys(enumValuesFromApi || {}).length) {
      rowUpdated('value', null)
      rowUpdated('childValue', null)
      rowUpdated('childOperation', null)
      setChildEnumValues({})
    } else if (
      /** If there is a parent selected for the current row */
      enumValuesFromApi?.[row.type]?.[row.property]?.length &&
      row.value &&
      !fetchChildrenInProgress
    ) {
      getChildrenEnumsFromApi(row.value)
    }
  }, [JSON.stringify(enumValuesFromApi?.[row.type]?.[row.property])])

  return (
    <>
      <div
        className={cx('alerts__property', {
          'alerts__property--has-error': error || deprecatedProperty,
          'alerts__property--plus-dropdown-open': plusDropdownOpen,
        })}
      >
        {/* Type */}
        <Dropdown
          options={Object.entries(ALERT_PROPERTIES_MAP(account_type))
            .filter((el) =>
              ACCOUNT_TYPE_GRANULARITIES[account_type]?.includes(el[0])
            )
            .map((el) => ({
              value: el[0],
              label: el[1],
            }))}
          defaultState={row.type}
          defaultOptionText="Select Property Type"
          onChange={(val) => rowUpdated('type', val)}
        />
        {/* Property */}
        <Dropdown
          options={Object.keys(fieldMap).map((el) => ({
            value: el,
            label: fieldMap[el].label || el,
          }))}
          defaultState={row.property}
          defaultOptionText="Select Property"
          hasSearch
          displaySearchInOptions
          onChange={(val) => {
            rowUpdated('property', val)
            rowUpdated('value', '')
          }}
        />

        <div
          className={cx('alerts__property-group', {
            'alerts__property-group--reverse': mappedChild,
          })}
        >
          {/* Operation*/}
          <Dropdown
            className="alerts__property-operation"
            options={operationOptions}
            defaultState={row.childOperation || row.operation}
            onChange={(val) => {
              if (mappedChild) {
                // If not settings, set the operation to the child, and set the parent operation to "Equals"
                if (!isSettingsOperation(val)) {
                  rowUpdated('childOperation', val)
                  rowUpdated('operation', FIELD_OPERATIONS_MAP.NUMERIC.EQ)
                } else {
                  rowUpdated('childOperation', null)
                  rowUpdated('operation', val)
                  rowUpdated('childValue', null)
                }
              } else {
                rowUpdated('operation', val)
                isSettings && rowUpdated('value', '')
              }
            }}
            defaultOptionText="Please Select"
          />

          {/* Value */}
          <div
            className={cx('alerts__property__value', {
              'alerts__property__value--reverse': mappedChild,
            })}
          >
            {fieldType === FIELD_TYPES.ENUM && (
              <Dropdown
                options={
                  fieldMapping.enumFromApi
                    ? enumValuesFromApi?.[row.type]?.[row.property] || []
                    : fieldMapping.enum
                }
                defaultState={
                  !mappedChild && isSettings
                    ? ''
                    : mappedChild && fetchContentInProgress
                    ? 'Loading'
                    : row.value
                }
                onChange={(val) => {
                  rowUpdated('value', val)
                  getChildrenEnumsFromApi(val)
                }}
                disabled={
                  (isSettings && !mappedChild) ||
                  (mappedChild &&
                    (fetchContentInProgress || fetchChildrenInProgress))
                }
                defaultOptionText={
                  mappedChild && fetchContentInProgress
                    ? 'Loading'
                    : isSettings
                    ? ''
                    : 'Please Select'
                }
                className={cx('alerts__property__value__dropdown-values', {
                  'alerts__property__value__dropdown--faked': isSettings,
                })}
              />
            )}
            {fieldType === FIELD_TYPES.BOOLEAN && (
              <Dropdown
                options={['Yes', 'No']}
                defaultState={isSettings ? '' : row.value}
                onChange={(val) => rowUpdated('value', val)}
                disabled={isSettings && !mappedChild}
                defaultOptionText={isSettings ? '' : 'Please Select'}
              />
            )}
            {(fieldType === FIELD_TYPES.INPUT ||
              fieldType === FIELD_TYPES.NUMBER) && (
              <Input
                onChange={(val) => rowUpdated('value', val)}
                value={row.value}
                placeholder={fieldType === FIELD_TYPES.NUMBER ? '##' : ''}
                disabled={isSettings && !mappedChild}
                type={fieldType === FIELD_TYPES.NUMBER ? 'number' : 'text'}
              />
            )}
            {fieldType === FIELD_TYPES.DATE &&
              (isSettings && !mappedChild ? (
                <Input onChange={() => {}} disabled={true} />
              ) : (
                <DateTimeComponent
                  value={row.value}
                  onChange={(val) => rowUpdated('value', val)}
                />
              ))}
            {fieldType === FIELD_TYPES.MINUTES && (
              <TimeComponent
                className="alerts__property__value__time"
                value={row.value}
                onChange={(val) => rowUpdated('value', val)}
                placeholder={'00:00'}
              />
            )}
          </div>
        </div>

        {mappedChild && (
          <>
            {/* Child fields */}
            {/* Value */}
            <div className="alerts__property__value alerts__property__value--child alerts__property__value--reverse">
              {childFieldType === FIELD_TYPES.ENUM && (
                <Dropdown
                  options={
                    fieldMapping?.child?.enumFromApi
                      ? childEnumValues?.[row.type]?.[row.property] || []
                      : fieldMapping?.child?.enum
                  }
                  defaultState={
                    isSettings && !checkChildValues
                      ? ''
                      : fetchChildrenInProgress || fetchContentInProgress
                      ? 'Loading'
                      : row.childValue
                  }
                  onChange={(val) => rowUpdated('childValue', val)}
                  disabled={
                    (isSettings && !checkChildValues) ||
                    fetchChildrenInProgress ||
                    fetchContentInProgress ||
                    (fieldMapping?.child?.enumFromApi &&
                      !childEnumValues?.[row.type]?.[row.property]?.length)
                  }
                  defaultOptionText={
                    isSettings
                      ? ''
                      : fetchChildrenInProgress
                      ? 'Loading'
                      : 'Please Select'
                  }
                  className="alerts__property__value__dropdown-values"
                />
              )}
              {childFieldType === FIELD_TYPES.BOOLEAN && (
                <Dropdown
                  options={['Yes', 'No']}
                  defaultState={
                    isSettings && !checkChildValues ? '' : row.childValue
                  }
                  onChange={(val) => rowUpdated('childValue', val)}
                  disabled={isSettings && !checkChildValues}
                  defaultOptionText={isSettings ? '' : 'Please Select'}
                />
              )}
              {(childFieldType === FIELD_TYPES.INPUT ||
                childFieldType === FIELD_TYPES.NUMBER) && (
                <Input
                  onChange={(val) => rowUpdated('childValue', val)}
                  value={row.childValue}
                  placeholder={fieldType === FIELD_TYPES.NUMBER ? '##' : ''}
                  disabled={isSettings && !checkChildValues}
                  type={fieldType === FIELD_TYPES.NUMBER ? 'number' : 'text'}
                />
              )}
              {childFieldType === FIELD_TYPES.DATE &&
                (isSettings ? (
                  <Input onChange={() => {}} disabled={true} />
                ) : (
                  <DateTimeComponent
                    value={row.childValue}
                    onChange={(val) => rowUpdated('childValue', val)}
                  />
                ))}
            </div>
          </>
        )}

        {/* Add/ Remove */}
        <div
          className={cx('alerts__property__icon', {
            'alerts__property__icon--disabled':
              properties.length === 1 && !conditionalProperty,
          })}
          onClick={() => removeRow()}
        >
          <img src={iconMinus} alt="remove" />
        </div>
        <div
          ref={propAddDropdownRef}
          tabIndex="0"
          className="alerts__property__icon"
          onClick={() => setPlusDropdownOpen(!plusDropdownOpen)}
        >
          <img src={iconPlus} alt="add" />

          {plusDropdownOpen && (
            <div className="alerts__property__value__dropdown">
              <div
                className="alerts__property__value__dropdown-item"
                role="button"
                onClick={() => {
                  setPlusDropdownOpen(false)
                  setProperties([...properties, {}])
                }}
              >
                Add row
              </div>
              <div
                className="alerts__property__value__dropdown-item"
                role="button"
                onClick={() => {
                  setPlusDropdownOpen(false)
                  addConditionalRow()
                }}
              >
                And / Or
              </div>
            </div>
          )}
        </div>
      </div>
      {error ? (
        <div className="error alerts__property__error">{error}</div>
      ) : null}
      {deprecatedProperty ? (
        <div className="error alerts__property__error">
          The previously selected property ({row.property}) is no longer
          supported. Please remove or change it as soon as possible.
        </div>
      ) : null}
    </>
  )
}

/** Filter the fields to not show the ones that have the excludeFromSettings flag set to true */
const getOnlySettingsFields = (fieldMap) => {
  return Object.entries(fieldMap).reduce((obj, [key, fields]) => {
    if (!fields.excludeFromSettings) {
      obj[key] = fields
    }
    return obj
  }, {})
}

const ALERT_PROPERTIES_MAP = (account_type) => ({
  [ACCOUNT]: 'Account Settings',
  [CAMPAIGN]: 'Campaign Settings',
  [AD_GROUP]:
    account_type === FACEBOOK ? 'Ad Set Settings' : 'Ad Group Settings',
  [AD]: 'Ad Settings',
  [KEYWORD]: 'Keyword Settings',
  [INSERTION_ORDER]: 'Insertion Order Settings',
})

const ACCOUNT_TYPE_GRANULARITIES = {
  [FACEBOOK]: [ACCOUNT, CAMPAIGN, AD_GROUP, AD],
  [GOOGLE]: [ACCOUNT, CAMPAIGN, AD_GROUP, AD, KEYWORD],
  [TIKTOK]: [ACCOUNT, CAMPAIGN, AD_GROUP, AD],
  [MICROSOFT]: [KEYWORD],
  [TRADE_DESK]: [CAMPAIGN, AD_GROUP, AD],
  [AMAZON_DSP]: [ACCOUNT, CAMPAIGN, AD_GROUP, AD],
  [DV360]: [ACCOUNT, CAMPAIGN, AD_GROUP, AD, INSERTION_ORDER],
}

/** Check if an operation is a SETTINGS type operation */
export const isSettingsOperation = (operation) => {
  return !!Object.values(FIELD_OPERATIONS_MAP.SETTINGS).find(
    (op) => op === operation
  )
}

AlertProperties.propTypes = {
  errors: PropTypes.object,
  properties: PropTypes.array.isRequired,
  setProperties: PropTypes.func.isRequired,
  accountTypesWithPausedCampaigns: PropTypes.object.isRequired,
  setAccountTypesWithPausedCampaigns: PropTypes.func.isRequired,
  account_type: PropTypes.string.isRequired,
  selectedCompanies: PropTypes.array,
}

Property.propTypes = {
  idx: PropTypes.number,
  row: PropTypes.object,
  properties: PropTypes.array,
  setProperties: PropTypes.func,
  error: PropTypes.string,
  indexInConditional: PropTypes.number,
  account_type: PropTypes.string,
  enumValuesFromApi: PropTypes.object,
  fetchContentInProgress: PropTypes.bool,
  selectedCompanies: PropTypes.array,
  dispatch: PropTypes.func,
}

export default AlertProperties
