import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import InputText from 'components/input/index'
import { Dropdown } from 'components/dropdown/index'
import { AccountDropdownRow } from 'components/account-icon/index'

/**
 * Excluded Campaigns Filters component
 * @param {Object} params React Params
 * @param {Function} params.setFilterValues Filter values
 * @param {Object} params.filterValues Filter values
 * @param {String} params.filterValues.search Search Value
 * @param {String} params.filterValues.exclusion Campaign Exclusion id
 * @param {String} params.filterValues.publisher Publisher (account type)
 * @param {String} params.filterValues.account Account id
 * @param {Array} params.availableAccounts List of available Accounts
 * @param {Array} params.availableExclusions List of available Exclusions
 * @param {Array} params.availablePublishers List of available Publishers
 */
const ExcludedCampaignsFilter = ({
  filterValues,
  setFilterValues,
  availableExclusions = [],
  availablePublishers = [],
  availableAccounts = [],
}) => {
  const [filteredAccounts, setFilteredAccounts] = useState(null)

  const fullPublisherList = [
    { sortTop: true, value: 'GLOBAL', label: 'Global' },
    ...availablePublishers,
  ]

  useEffect(() => {
    if (!filterValues) {
      setFilterValues({
        exclusions: availableExclusions.map(({ _id }) => _id),
        publishers: fullPublisherList.map(({ value }) => value),
        accounts: availableAccounts.map(({ key }) => key),
      })
    }
  }, [filterValues])

  if (!filterValues) {
    return null
  }

  const onChangePublishers = (publishers) => {
    const newFilterValues = {
      ...filterValues,
      publishers,
    }
    if (filterValues.accounts) {
      const selectedAccountsSet = new Set(filterValues.accounts)
      const publisherSet = new Set(publishers)

      const remainingAvailableAccounts = (newFilterValues.accounts =
        availableAccounts.filter((account) => publisherSet.has(account.type)))

      setFilteredAccounts(remainingAvailableAccounts)

      newFilterValues.accounts = remainingAvailableAccounts
        .filter((account) => selectedAccountsSet.has(account.key))
        .map(({ key }) => key)
    }

    setFilterValues(newFilterValues)
  }

  return (
    <section className="excluded-campaigns-filter">
      <div className="excluded-campaigns-filter__row">
        <InputText
          value={filterValues.search}
          onChange={(search) => setFilterValues({ ...filterValues, search })}
          label="Search"
          placeholder="Search"
          search
        />
        <Dropdown
          label="Exclusion"
          defaultOptionText="All"
          selectAll="All Exclusions"
          multiSelect={true}
          hasSearch={true}
          displaySearchInOptions={true}
          selectedItems={filterValues.exclusions}
          onChange={(exclusions) =>
            setFilterValues({ ...filterValues, exclusions })
          }
          overwriteSelectedText={
            availableExclusions?.length === filterValues.exclusions?.length
              ? 'All'
              : undefined
          }
          labelTop
          options={availableExclusions.map(({ _id, name }) => ({
            label: name,
            value: _id,
          }))}
          sortOptions
        />
      </div>
      <div className="excluded-campaigns-filter__row">
        <Dropdown
          label="Publisher"
          multiSelect={true}
          defaultOptionText="All"
          selectAll="All Publishers"
          labelTop
          selectedItems={filterValues.publishers}
          onChange={onChangePublishers}
          options={fullPublisherList}
          sortOptions
          overwriteSelectedText={
            fullPublisherList?.length === filterValues.publishers?.length
              ? 'All'
              : undefined
          }
        />
        <Dropdown
          label="Account"
          defaultOptionText="All"
          selectAll="All Accounts"
          labelTop
          multiSelect={true}
          selectedItems={filterValues.accounts}
          onChange={(accounts) =>
            setFilterValues({ ...filterValues, accounts })
          }
          options={filteredAccounts || availableAccounts}
          optionRenderer={(option, selectedItems) => (
            <AccountDropdownRow option={option} selectedItems={selectedItems} />
          )}
          sortOptions
          overwriteSelectedText={
            (filteredAccounts || availableAccounts)?.length ===
            filterValues.accounts?.length
              ? 'All'
              : undefined
          }
        />
      </div>
    </section>
  )
}

ExcludedCampaignsFilter.propTypes = {
  filterValues: PropTypes.object.isRequired,
  setFilterValues: PropTypes.func.isRequired,
  availableAccounts: PropTypes.array,
  availableExclusions: PropTypes.array,
  availablePublishers: PropTypes.array,
}

export default ExcludedCampaignsFilter
