import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as WarningIcon } from 'assets/icon_warning.svg'
import Icon from '../../components/icon'

import { TOAST_NOTIFICATION_TYPES } from './actions'

const Notification = ({ data, clearNotification, dispatch }) => {
  return (
    <div className={`notification align-row ${data.type}`}>
      {data.type === TOAST_NOTIFICATION_TYPES.WARNING ? (
        <div className="warning-icon">
          <Icon className="margin-left-auto">
            <WarningIcon />
          </Icon>
        </div>
      ) : null}
      <span className="message">{data.message}</span>
      <span
        className="close align-center"
        onClick={() => clearNotification(data.id, dispatch)}
      >
        ×
      </span>
    </div>
  )
}
Notification.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  clearNotification: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default Notification
