import React, { useCallback, useState } from 'react'

import PropTypes from 'prop-types'

/* Utils */
import { useDropzone, ErrorCode } from 'react-dropzone'

/* Components */
import DragOverlay from 'components/file-upload/components/drag-overlay'
import UploadDefaultState from 'components/file-upload/components/upload-default-state'

/* Constants */
import {
  DEFAULT_ACCEPTED_UPLOAD_FILE_TYPE,
  FILE_SIZE_UNIT,
  FILE_UPLOAD_STATE,
  MAX_ALLOWED_UPLOAD_SIZE_IN_BYTES,
} from '../constants'
import { getFormattedFileSize } from '../utils'

const FileUpload = ({
  throwError,
  maxFileSize = MAX_ALLOWED_UPLOAD_SIZE_IN_BYTES,
  fileTypesAccepted = DEFAULT_ACCEPTED_UPLOAD_FILE_TYPE,
  allowMultiple = false,
  renderOnDefaultState,
  onFilesSelected,
  loading,
  dark,
}) => {
  /* Stores the state of the file upload component (default, drag_over or uploading) */
  const [displayState, setDisplayState] = useState(FILE_UPLOAD_STATE.DEFAULT)
  const [errors, setErrors] = useState([])

  /**
   * Callback that runs every time a file (or more) are dropped or selected in order to be uploaded
   * @param {Array} files - An array with all the files dropped or selected
   * @param {Array} rejectedFiles - An array with all the files dropped or selected which do not meet the upload criteria
   */
  const onFilesDropped = (files, rejectedFiles) => {
    if (!allowMultiple && files?.length > 1) {
      throwError && throwError('Only one file at a time is allowed')
      setDisplayState(FILE_UPLOAD_STATE.DEFAULT)
      return
    }

    if (rejectedFiles.length) {
      setErrors(
        rejectedFiles.map(({ errors, file }) => ({
          filename: file.name,
          errors: errors.map(getParsedErrorMessage),
        }))
      )
    } else if (errors.length) {
      setErrors([])
    }

    onFilesSelected && onFilesSelected(files)
    setDisplayState(FILE_UPLOAD_STATE.DEFAULT)
  }

  /* Handler for when a file is selected */
  const onDrop = useCallback(onFilesDropped, [
    maxFileSize,
    throwError,
    onFilesSelected,
    loading,
  ])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: fileTypesAccepted,
    disabled: loading,
    maxSize: maxFileSize,
    onDragEnter: () => setDisplayState(FILE_UPLOAD_STATE.DRAG_OVER),
    onDragLeave: () => setDisplayState(FILE_UPLOAD_STATE.DEFAULT),
  })

  /**
   * Parses error messages based on the error code, in a more readable way
   * @param {{code: String, message: String}} error Error thrown from react-dropzone
   * @returns {String}
   */
  const getParsedErrorMessage = (error) => {
    switch (error.code) {
      case ErrorCode.FileTooLarge:
        return `Files must be under ${getFormattedFileSize(maxFileSize)}MB`
      case ErrorCode.FileInvalidType:
      case ErrorCode.FileTooSmall:
      case ErrorCode.TooManyFiles:
      default:
        return error.message
    }
  }

  return (
    <div {...getRootProps()} className="upload-modal">
      <input {...getInputProps()} />

      <UploadDefaultState
        state={displayState}
        render={renderOnDefaultState}
        dark={dark}
      />
      <DragOverlay state={displayState} />
      {errors.map(({ filename, errors }) => (
        <div key={filename} className="error">
          <span>{filename}: </span>
          {errors.map((error, index) => (
            <span key={index}>{error}</span>
          ))}
        </div>
      ))}
    </div>
  )
}

FileUpload.propTypes = {
  throwError: PropTypes.func,
  fileTypesAccepted: PropTypes.string,
  allowMultiple: PropTypes.bool,
  renderOnDefaultState: PropTypes.any,
  maxFileSize: PropTypes.number,
  loading: PropTypes.bool,
  onFilesSelected: PropTypes.func,
  dark: PropTypes.bool,
}

export default FileUpload
