import React from 'react'
import PropTypes from 'prop-types'

import WarningIcon from 'components/warning-icon'
import Button from 'components/button'
import Modal from 'components/modal'

const SaveOfflineDataModal = ({ open, onConfirm, onCancel }) => {
  return (
    <Modal
      className="offline-data__modal"
      icon={<WarningIcon />}
      rightAlignButtons
      opened={!!open}
      contentSeparator
      button={<Button value="Confirm" green onClick={onConfirm} />}
      buttonSecondary={
        <Button value={'Cancel'} onClick={onCancel} secondaryGray />
      }
      heading="Update Import Offline Data Settings"
    >
      <div>
        You are about to save updates to the Import Offline Data settings.
        Confirm if you want to continue with this action. This action cannot be
        undone.
      </div>
    </Modal>
  )
}

SaveOfflineDataModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default SaveOfflineDataModal
