import React from 'react'
import PropTypes from 'prop-types'
import { format as formatDate } from 'date-fns'

/* Components */
import TablePaginated from 'components/table/beta'

/* Utils */
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from 'components/utils/date'
import { VALUE_CHANGED_LABELS, formatRow } from './utils'

/**
 * Renders the budget pacing activity logs table
 * @param {Object} props
 * @param {Object} props.activityLogs - Object with logs for budget pacing
 */
const ActivityLogTable = ({ activityLogs }) => {
  const columns = [
    {
      header: 'Date of Change',
      accessorKey: 'date',
      cell: (cell) =>
        formatDate(
          new Date(cell.row.original.date),
          `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`
        ),
      size: 70,
    },
    {
      header: 'User',
      accessorFn: (row) => row.user,
      size: 70,
    },
    {
      header: 'Budget Segment',
      accessorFn: (row) => row.budgetSegment,
      size: 80,
    },
    {
      header: 'Value Changed',
      accessorFn: (row) => VALUE_CHANGED_LABELS[row.valueChanged],
      size: 70,
    },
    {
      header: 'From',
      accessorKey: 'from',
      cell: ({ cell }) => {
        const { row } = cell
        return formatRow(row.original.from, row.original.valueChanged)
      },
      size: 110,
    },
    {
      header: 'To',
      accessorKey: 'to',
      cell: ({ cell }) => {
        const { row } = cell
        return formatRow(row.original.to, row.original.valueChanged)
      },
      size: 110,
      height: 40,
    },
  ]
  return (
    <div className="padding-x-30 padding-y-10">
      <TablePaginated
        id="activity-log-table"
        columns={columns}
        data={activityLogs.map((log, idx) => ({ ...log, _id: idx }))}
        showSearchInput={false}
        showPagination={activityLogs.length > 10}
      />
    </div>
  )
}
export default ActivityLogTable

ActivityLogTable.propTypes = {
  activityLogs: PropTypes.array.isRequired,
}
