import React, { useEffect, useState, useMemo } from 'react'
import propTypes from 'prop-types'
// import { useStore } from 'store'
// import { subDays } from 'date-fns'
import {
  // socket,
  // metrics,
  utils,
  // alert,
  // currency,
} from '@decision-sciences/qontrol-common'

// Hooks
// import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'
// import { useSocket } from 'components/utils/socket'
// import useSession from 'modules/session'

// Utils
import { getAlertTemplateById } from 'modules/alert-templates/actions'
import { getColorMap } from 'components/panel-with-highlighted-content/utils'
// import { getClientConversionGroupings } from 'modules/companies/actions'
// import {
//   runThresholdSimulation as runSimulation,
//   initDailyPerformanceReferenceTableData,
//   initDailyPerformanceReferenceTableConversions,
// } from 'modules/alerts/alerts-thresholds-statistics/actions'

// Constants / utils
// import {
//   FREQUENCY_COLUMNS,
//   MOCK_FREQUENCY_DATA,
//   DAILY_PERFORMANCE_REFERENCE_METRIC_LABELS,
//   DAILY_PERFORMANCE_REFERENCE_KPI_LABELS,
//   FREQUENCY_TOOLTIP_MESSAGE,
//   DAILY_REFERENCE_TABLE_TOOLTIP,
//   getDailyPerformanceReferenceColumns,
//   mapSimulationResults,
// } from 'modules/alerts/alerts-thresholds-statistics/utils'

// Components
import Modal from 'components/modal'
import Table from 'components/table/beta'
import Button from 'components/button'
import ButtonToggle from 'components/button-toggle/index'
// import Input from 'components/input'
import Loader from 'components/loader'
import {
  // useTooltip,
  StaticTooltip,
} from 'components/tooltip'
// import LoadingOverlay from 'components/loading-overlay/index'
// import { showErrorMessage } from 'modules/notifications/actions'
// import DropdownTree from 'components/dropdown-tree'
import { BulkAssignRecommendationsModal } from 'modules/alerts/alerts-thresholds-statistics/threshold-recommendations/modal'
// import {
//   ThresholdRecommendationCell,
//   ThresholdRecommendationHeader,
// } from 'modules/alerts/alerts-thresholds-statistics/threshold-recommendations/cell'
import { AssociatedAlertsTable } from 'modules/alerts/alerts-thresholds-statistics/associated-alerts/table'
import { AssociatedAlerts } from 'modules/alerts/alerts-thresholds-statistics/associated-alerts/index'
import AlertFormulaSection from 'modules/alerts/alerts-thresholds-statistics/alert-formula-section/index'

// Icons
// import { ReactComponent as SaveIcon } from 'assets/save.svg'
import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'
import { ReactComponent as CheckmarkIcon } from 'assets/icon_checkmark.svg'
// import { ReactComponent as ReloadIcon } from 'assets/icon_load_reload.svg'
// import { ReactComponent as LoadingIcon } from 'assets/icon_ring_loader.svg'

import '../style.scss'

const { isEmpty } = utils.object

// const { NOTIFICATIONS } = socket
// const { DAILY_PERFORMANCE_REFERENCE_SUPPORTED_METRICS, METRICS } = metrics
// const { ALERT_GRANULARITY } = alert
// const { CURRENCY_TYPES, CURRENCY_SYMBOLS, convertCurrency } = currency

// const getClientCurrency = (company, parentClient, alert) => {
//   const isClientOrBu = alert?.granularity === ALERT_GRANULARITY.CLIENT
//   return isClientOrBu
//     ? company?.primaryCurrency ||
//         parentClient?.primaryCurrency ||
//         CURRENCY_TYPES.USD
//     : CURRENCY_TYPES.USD
// }

const AlertsThresholdModal = ({
  alert,
  onCancel,
  onSave,
  company,
  isAlertThresholdsLinked,
  copyFromCompany,
  parentClient,
  defaultClient,
}) => {
  // const [, user] = useSession()
  // const { dispatch } = useStore()
  // const canView = useAccess({
  //   feature: PERMISSIONS.ALERT_THRESHOLDS,
  //   type: PERMISSION_TYPES.READ,
  // })
  // const canEdit = useAccess({
  //   feature: PERMISSIONS.ALERT_THRESHOLDS,
  //   type: PERMISSION_TYPES.EDIT,
  // })
  // const isViewMode =
  //   canView && (!canEdit || isAlertThresholdsLinked || copyFromCompany)
  const isViewMode = true

  // const hasAlertGranularity =
  //   alert?.granularity && alert?.granularity !== ALERT_GRANULARITY.OTHER

  // const clientCurrency = getClientCurrency(company, parentClient, alert)

  const [alertThresholds, setAlertThresholds] = useState([])
  const [modifiedThresholds, setModifiedThresholds] = useState({})
  const [recommendedThresholds, setRecommendedThresholds] = useState({})
  // const [recommendedThresholdsLoading, setRecommendedThresholdsLoading] =
  //   useState({})
  const [recommendedThresholdsPerMetric, setRecommendedThresholdsPerMetric] =
    useState({})
  const [modifiedThresholdsForSimulation, setModifiedThresholdsForSimulation] =
    useState({})
  const [formula, setFormula] = useState('')
  const [loading, setLoading] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [bulkAssignModalVisible, setBulkAssignModalVisible] = useState(false)
  const [hoveredSampleDataWordIndex, setHoveredSampleDataWordIndex] =
    useState(null)
  // const [simulationRunning, setSimulationRunning] = useState(false)
  // const [
  //   showOverlayToStartRunningSimulation,
  //   setShowOverlayToStartRuningSimulation,
  // ] = useState(false)
  // const [estimatedTriggerFrequency, setEstimatedTriggerFrequency] = useState([
  //   ...MOCK_FREQUENCY_DATA,
  // ])
  const [isSimplifiedViewMode, setIsSimplifiedViewMode] = useState(true)

  // Inherit alert thresholds from parent if necessary
  const companyThresholds = isAlertThresholdsLinked
    ? parentClient.alertThresholds
    : company.alertThresholds

  // const [
  //   loadingDailyPerformanceReferenceTable,
  //   setLoadingDailyPerformanceReferenceTable,
  // ] = useState(false)
  // const [
  //   loadingDailyPerformanceReferenceConversions,
  //   setLoadingDailyPerformanceReferenceConversions,
  // ] = useState(false)
  // const [dailyPerformanceReferenceMetric, setDailyPerformanceReferenceMetric] =
  //   useState(Object.values(DAILY_PERFORMANCE_REFERENCE_SUPPORTED_METRICS)[0])
  // const [dailyPerformanceReferenceData, setDailyPerformanceReferenceData] =
  //   useState({})
  // const [conversionGroups, setConversionGroups] = useState([])

  // const fetchGroupings = async () => {
  //   try {
  //     const results = await getClientConversionGroupings(company._id)
  //     return results
  //   } catch (e) {
  //     console.error(e)
  //     return []
  //   }
  // }

  /**
   * Map recommended threshold: metric type -> threshold
   * Set loaders for each threshold
   * Don't recommend if:
   *     - there is no metric type set on the threshold
   *     - the threshold represents a percentage
   * @param {Array<Object>} alertThresholds list of thresholds
   * @param {Object} recommendations threshold recommendations per metric type
   */
  const updateRecommendedThresholds = (
    alertThresholds,
    recommendations = {}
  ) => {
    if (!alertThresholds.length) {
      setRecommendedThresholdsPerMetric((existingRecommendationsPerMetric) => ({
        ...existingRecommendationsPerMetric,
        ...recommendations,
      }))

      return
    } else if (!alert.time) {
      return
    }

    recommendations = { ...recommendations, ...recommendedThresholdsPerMetric }

    if (!Object.keys(recommendations).length) {
      return
    }

    const newRecommendedThresholdsLoading = {}
    const newThresholdRecommendations = alertThresholds.reduce(
      (acc, { _id, metric, percentage }) => {
        const value = recommendations?.[metric]

        if (!metric || percentage) {
          newRecommendedThresholdsLoading[_id] = false

          return {
            ...acc,
            [_id]: null,
          }
        }

        newRecommendedThresholdsLoading[_id] =
          recommendedThresholds[_id] || recommendedThresholds[_id] === 0
            ? false
            : !value && value !== 0

        return {
          ...acc,
          [_id]:
            recommendedThresholds[_id] || recommendedThresholds[_id] === 0
              ? recommendedThresholds[_id]
              : value,
        }
      },
      {}
    )

    setRecommendedThresholds((existingRecommendations) => ({
      ...existingRecommendations,
      ...newThresholdRecommendations,
    }))
    // setRecommendedThresholdsLoading((existingRecommendationsLoading) => ({
    //   ...existingRecommendationsLoading,
    //   ...newRecommendedThresholdsLoading,
    // }))
  }

  // Fetch daily performance reference table data
  // useEffect(() => {
  //   if (!hasAlertGranularity) {
  //     return
  //   }
  //   setLoadingDailyPerformanceReferenceTable(true)
  //   initDailyPerformanceReferenceTableData(alert._id, company._id).catch(
  //     (error) => {
  //       showErrorMessage(error, dispatch)
  //       setLoadingDailyPerformanceReferenceTable(false)
  //     }
  //   )

  //   setLoadingDailyPerformanceReferenceConversions(true)
  //   initDailyPerformanceReferenceTableConversions(alert._id, company._id).catch(
  //     (error) => {
  //       showErrorMessage(error, dispatch)
  //       setLoadingDailyPerformanceReferenceConversions(false)
  //     }
  //   )

  //   fetchGroupings().then((res) => {
  //     if (res) {
  //       setConversionGroups([...res])
  //     }
  //   })
  // }, [JSON.stringify(alertThresholds), alert?.time, hasAlertGranularity])

  // const [showTooltip, hideTooltip, Tooltip] = useTooltip(
  //   FREQUENCY_TOOLTIP_MESSAGE
  // )
  // const [
  //   showDailyReferenceTooltip,
  //   hideDailyReferenceTooltip,
  //   DailyReferenceTooltip,
  // ] = useTooltip(DAILY_REFERENCE_TABLE_TOOLTIP)

  const alertThresholdValues = useMemo(() => {
    return alertThresholds.reduce(
      (acc, threshold) => ({
        ...acc,
        [threshold.key]: !isEmpty(modifiedThresholds[threshold._id])
          ? modifiedThresholds[threshold._id]
          : threshold.value,
      }),
      {}
    )
  }, [JSON.stringify(alertThresholds), modifiedThresholds])

  useEffect(() => {
    if (alert && alert.template && !alertThresholds.length) {
      const {
        template: { _id },
      } = alert
      setLoading(true)
      getAlertTemplateById(_id)
        .then((result) => {
          const { thresholds, templateFormula } = result
          if (thresholds) {
            setAlertThresholds(thresholds)
            // if (alert?.time && hasAlertGranularity) {
            //   setRecommendedThresholdsLoading(
            //     thresholds.reduce(
            //       (acc, { _id }) => ({ ...acc, [_id]: true }),
            //       {}
            //     )
            //   )
            // }

            updateRecommendedThresholds(alertThresholds)
          }
          if (templateFormula) {
            setFormula(templateFormula)
          }
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
          console.error(e)
        })
    }
  }, [alert, JSON.stringify(alertThresholds)])

  // Initialise modified thresholds
  // useEffect(() => {
  //   const alertThresholdsIds = alertThresholds.map(({ _id }) => _id)
  //   const clientThr = companyThresholds.some(({ globalThreshold }) =>
  //     alertThresholdsIds.includes(globalThreshold)
  //   )
  //   if (!Object.keys(modifiedThresholds).length && clientThr) {
  //     const modified = {}
  //     alertThresholds.forEach((thr) => {
  //       const clientThr = companyThresholds.find(
  //         ({ globalThreshold }) => globalThreshold === thr._id
  //       )
  //       if (clientThr) {
  //         modified[thr._id] = clientThr.value
  //       }
  //     })
  //     setModifiedThresholds(modified)
  //   }
  // }, [JSON.stringify(companyThresholds), JSON.stringify(alertThresholds)])

  const wordsToHighlight = alertThresholds.map((thr) => `[${thr.key}]`)
  const colorMap = getColorMap(wordsToHighlight)

  // const onSaveChanges = () => {
  //   const modifiedThrs = Object.keys(modifiedThresholds)
  //   // If no thresholds changed, close the panel
  //   if (!modifiedThrs.length) {
  //     return onCancel()
  //   }
  //   const newCompanyThresholds = [...companyThresholds]
  //   modifiedThrs.forEach((id) => {
  //     const cliThrIdx = newCompanyThresholds.findIndex(
  //       ({ globalThreshold }) => globalThreshold === id
  //     )
  //     if (modifiedThresholds[id]) {
  //       const newThreshold = {
  //         globalThreshold: id,
  //         value: parseFloat(modifiedThresholds[id]),
  //         updatedAt: Date.now(),
  //       }
  //       if (cliThrIdx !== -1) {
  //         newCompanyThresholds[cliThrIdx] = newThreshold
  //       } else {
  //         newCompanyThresholds.push(newThreshold)
  //       }
  //     } else {
  //       if (cliThrIdx !== -1) {
  //         newCompanyThresholds.splice(cliThrIdx, 1)
  //       }
  //     }
  //   })
  //   onSave(newCompanyThresholds)
  // }

  const thresholdsChanged = () =>
    Object.keys(modifiedThresholds).some((thr) => {
      const existingThr = companyThresholds.find(
        ({ globalThreshold }) => globalThreshold === thr
      )
      if (existingThr) {
        return existingThr.value !== modifiedThresholds[thr]
      }
      return !!modifiedThresholds[thr]
    })

  // useEffect(() => {
  //   if (Object.keys(modifiedThresholdsForSimulation).length) {
  //     setShowOverlayToStartRuningSimulation(true)
  //   } else {
  //     setShowOverlayToStartRuningSimulation(false)
  //   }
  // }, [JSON.stringify(modifiedThresholdsForSimulation)])

  // const runThresholdSimulation = () => {
  //   setShowOverlayToStartRuningSimulation(false)
  //   setSimulationRunning(true)
  //   runSimulation({
  //     alertId: alert._id,
  //     companyId: company._id,
  //     modifiedThresholds: modifiedThresholdsForSimulation,
  //   }).catch(() => {
  //     setShowOverlayToStartRuningSimulation(true)
  //     setSimulationRunning(false)
  //   })
  // }

  // const socket = useSocket({ room: `${user._id}-${company._id}-${alert._id}` })
  // useEffect(() => {
  //   if (socket?.connected) {
  //     socket.on(NOTIFICATIONS.thresholdSimulation.receive, ({ result }) => {
  //       if (!result) {
  //         setSimulationRunning(false)
  //         setShowOverlayToStartRuningSimulation(true)

  //         showErrorMessage(
  //           `Error running simulation. Please try again`,
  //           dispatch
  //         )
  //       } else {
  //         setEstimatedTriggerFrequency([...mapSimulationResults(result)])
  //         setSimulationRunning(false)
  //       }
  //     })
  //   }

  //   return () => {
  //     socket?.removeAllListeners(NOTIFICATIONS.thresholdSimulation.receive)
  //   }
  // }, [socket?.connected])

  // const formatCurrency = (value, currency) => {
  //   const formattedValue = new Intl.NumberFormat('en-US').format(
  //     Math.round(value)
  //   )
  //   const symbol = CURRENCY_SYMBOLS[currency] || '$'
  //   return `${symbol}${formattedValue}`
  // }

  // const mapKpiStatistics = (data, oldestDate, currencyMap) => {
  //   const mapped = {}

  //   for (const type of [
  //     'individual_statistics',
  //     'group_conversion_statistics',
  //     'group_revenue_statistics',
  //   ]) {
  //     for (const [period, valuesPerPeriod] of Object.entries(data[type])) {
  //       for (let [metric, values] of Object.entries(valuesPerPeriod)) {
  //         if (type === 'group_revenue_statistics') {
  //           metric = `${metric}_Revenue`
  //         }
  //         if (!mapped[metric]) {
  //           mapped[metric] = []
  //         }
  //         mapped[metric].push({
  //           period,
  //           ...Object.entries(values).reduce((acc, [statistic, value]) => {
  //             let displayedValue = '--'
  //             if (
  //               new Date(oldestDate) <=
  //               subDays(new Date(), period.replace('_', ''))
  //             ) {
  //               if (metric.includes('_Revenue')) {
  //                 value = convertCurrency(
  //                   value,
  //                   CURRENCY_TYPES.USD,
  //                   clientCurrency,
  //                   currencyMap
  //                 )
  //                 displayedValue = formatCurrency(value, clientCurrency)
  //               } else {
  //                 const formattedValue = new Intl.NumberFormat('en-US').format(
  //                   Math.round(value)
  //                 )
  //                 displayedValue = formattedValue
  //               }
  //             }
  //             return {
  //               ...acc,
  //               [statistic]: displayedValue,
  //             }
  //           }, {}),
  //         })
  //       }
  //     }
  //   }

  //   return mapped
  // }

  // const socketDailyPerformanceReference = useSocket({
  //   room: `${company._id}-${alert._id}`,
  // })
  // useEffect(() => {
  //   if (socketDailyPerformanceReference?.connected) {
  //     socketDailyPerformanceReference.on(
  //       NOTIFICATIONS.dailyPerformanceReferenceConversions.receive,
  //       ({ success, data, currency }) => {
  //         if (!success) {
  //           setLoadingDailyPerformanceReferenceConversions(false)
  //           showErrorMessage(
  //             `Error fetching Daily Performance reference table conversions. Try opening the modal again.`,
  //             dispatch
  //           )
  //         } else {
  //           const mapped = mapKpiStatistics(
  //             data.statistics,
  //             data.oldestDate,
  //             currency
  //           )

  //           setDailyPerformanceReferenceData(
  //             (dailyPerformanceReferenceData) => ({
  //               ...dailyPerformanceReferenceData,
  //               ...mapped,
  //             })
  //           )

  //           setLoadingDailyPerformanceReferenceConversions(false)

  //           updateRecommendedThresholds(
  //             alertThresholds,
  //             data.threshold_recommendations
  //           )
  //         }
  //       }
  //     )
  //     socketDailyPerformanceReference.on(
  //       NOTIFICATIONS.dailyPerformanceReferenceRegularMetrics.receive,
  //       ({ success, data, currency }) => {
  //         if (!success) {
  //           setLoadingDailyPerformanceReferenceTable(false)
  //           showErrorMessage(
  //             `Error fetching Daily Performance reference table data. Try opening the modal again.`,
  //             dispatch
  //           )
  //         } else {
  //           const mapped = Object.entries(data.statistics).reduce(
  //             (acc, [metric, value]) => ({
  //               ...acc,
  //               [metric]: Object.entries(value).reduce(
  //                 (valueAcc, [period, values]) => [
  //                   ...valueAcc,
  //                   {
  //                     period,
  //                     ...Object.entries(values).reduce(
  //                       (acc, [statKey, statValue]) => {
  //                         let displayedValue = '--'
  //                         const oldestDate = new Date(data.oldestDate)
  //                         const periodStart = subDays(
  //                           new Date(),
  //                           period.replace('_', '') - 1
  //                         )
  //                         oldestDate.setHours(0, 0, 0, 0)
  //                         periodStart.setHours(0, 0, 0, 0)
  //                         if (data.hasRecentData && oldestDate <= periodStart) {
  //                           if (metric === METRICS.Cost.key) {
  //                             statValue = convertCurrency(
  //                               statValue,
  //                               CURRENCY_TYPES.USD,
  //                               clientCurrency,
  //                               currency
  //                             )
  //                             displayedValue = formatCurrency(
  //                               statValue,
  //                               clientCurrency
  //                             )
  //                           } else {
  //                             const formattedValue = new Intl.NumberFormat(
  //                               'en-US'
  //                             ).format(Math.round(statValue))
  //                             displayedValue = formattedValue
  //                           }
  //                         }
  //                         return {
  //                           ...acc,
  //                           [statKey]: displayedValue,
  //                         }
  //                       },
  //                       {}
  //                     ),
  //                   },
  //                 ],
  //                 []
  //               ),
  //             }),
  //             {}
  //           )

  //           setDailyPerformanceReferenceData(
  //             (dailyPerformanceReferenceData) => ({
  //               ...dailyPerformanceReferenceData,
  //               ...mapped,
  //             })
  //           )

  //           updateRecommendedThresholds(
  //             alertThresholds,
  //             data.threshold_recommendations
  //           )
  //           setLoadingDailyPerformanceReferenceTable(false)
  //         }
  //       }
  //     )
  //   }

  //   return () => {
  //     socketDailyPerformanceReference?.removeAllListeners(
  //       NOTIFICATIONS.dailyPerformanceReferenceConversions.receive
  //     )
  //     socketDailyPerformanceReference?.removeAllListeners(
  //       NOTIFICATIONS.dailyPerformanceReferenceRegularMetrics.receive
  //     )
  //   }
  // }, [
  //   socketDailyPerformanceReference?.connected,
  //   JSON.stringify(alertThresholds),
  //   recommendedThresholdsLoading,
  //   alert?.time,
  // ])

  const _renderCancelModal = () => (
    <Modal
      opened={!!cancelModal}
      contentSeparator
      rightAlignButtons
      button={
        <Button
          value={
            <>
              <CheckmarkIcon width={18} height={18} />
              CONTINUE
            </>
          }
          onClick={onCancel}
          secondaryGreen
        />
      }
      buttonSecondary={
        <Button
          value={'CANCEL'}
          onClick={() => {
            setCancelModal(!cancelModal)
          }}
          secondaryGray
        />
      }
      heading={'Unsaved Changes'}
      className="cancel-modal"
    >
      You have unsaved changes.
      <div className="margin-top-10">
        Click the Continue button to move forward without saving your updates,
        or click Cancel to close this modal.
      </div>
    </Modal>
  )

  const tooltipAlerts = Object.values(
    alertThresholds.reduce((prev, current) => {
      current.alerts.forEach((alert) => {
        if (prev[alert._id]) {
          prev[alert._id].thresholdsUsed += alert.thresholdsUsed
        } else {
          prev[alert._id] = structuredClone(alert)
        }
      })
      return prev
    }, {})
  )

  const onThresholdChange = (newValue, _id) => {
    setModifiedThresholds((currentModifiedThresholds) => ({
      ...currentModifiedThresholds,
      [_id]: newValue,
    }))

    const globalThreshold = alertThresholds.find(
      ({ _id: thresholdId }) => thresholdId === _id
    )
    const { key, value: globalThresholdValue } = globalThreshold
    const companyThresholdValue = companyThresholds.find(
      ({ globalThreshold }) => globalThreshold === _id
    )?.value

    // Only set threshold for simulation in case it differs from the company threshold or the global one
    const actualUsedValue = companyThresholdValue || globalThresholdValue

    if (newValue === '') {
      if (companyThresholdValue) {
        setModifiedThresholdsForSimulation({
          ...modifiedThresholdsForSimulation,
          [key]: globalThresholdValue,
        })
      } else {
        // The threshold is not modified vs the actually used value
        setModifiedThresholdsForSimulation((currentModifiedThresholds) => {
          delete currentModifiedThresholds[key]
          return currentModifiedThresholds
        })
      }
    } else if (
      parseFloat(newValue.replaceAll(',', '')) !== parseFloat(actualUsedValue)
    ) {
      setModifiedThresholdsForSimulation((currentModifiedThresholds) => {
        return { ...currentModifiedThresholds, [key]: newValue }
      })
    } else {
      // The threshold is not modified vs the actually used value
      setModifiedThresholdsForSimulation((currentModifiedThresholds) => {
        delete currentModifiedThresholds[key]
        return currentModifiedThresholds
      })
    }
  }

  const onBulkThresholdChange = (overwrite) => {
    Object.entries(recommendedThresholds).forEach(([key, value]) => {
      const clientThreshold = modifiedThresholds[key]
      if (overwrite) {
        if (value) {
          onThresholdChange(value.toString(), key)
        }
      } else {
        if (!clientThreshold && value) {
          onThresholdChange(value.toString(), key)
        }
      }
    })
    changeBulkAssignModalVisibility(false)()
  }

  const changeBulkAssignModalVisibility = (visible) => () => {
    setBulkAssignModalVisible(visible)
  }

  // const dailyPerformanceDropdownOptions = useMemo(() => {
  //   const toRet = []
  //   const conversionOptions = []
  //   const revenueOptions = []
  //   let selectedByDefault

  //   for (let value of Object.keys(dailyPerformanceReferenceData)) {
  //     if (DAILY_PERFORMANCE_REFERENCE_METRIC_LABELS[value]) {
  //       toRet.push({
  //         value,
  //         label: DAILY_PERFORMANCE_REFERENCE_METRIC_LABELS[value] || value,
  //       })

  //       if (!selectedByDefault) {
  //         selectedByDefault = value
  //       }

  //       continue
  //     }

  //     if (value.includes('_Revenue')) {
  //       let priority
  //       let label

  //       if (DAILY_PERFORMANCE_REFERENCE_KPI_LABELS[value]) {
  //         label = DAILY_PERFORMANCE_REFERENCE_KPI_LABELS[value]
  //         priority = -1
  //       } else {
  //         value = value.replace('_Revenue', '')
  //         // Get the priority of the conversion group
  //         priority =
  //           conversionGroups.find(({ name }) => name === value)?.priority || 1
  //         label = `${value} Revenue`
  //         value = `${value}_Revenue`
  //       }

  //       revenueOptions.push({ value, label, priority })

  //       if (!selectedByDefault) {
  //         selectedByDefault = value
  //       }

  //       continue
  //     }

  //     if (!selectedByDefault) {
  //       selectedByDefault = value
  //     }

  //     let label = value
  //     let priority

  //     if (DAILY_PERFORMANCE_REFERENCE_KPI_LABELS[value]) {
  //       label = DAILY_PERFORMANCE_REFERENCE_KPI_LABELS[value]
  //       priority = -1
  //     } else {
  //       // Get the priority of the conversion group
  //       priority =
  //         conversionGroups.find(({ name }) => name === value)?.priority || 1
  //     }

  //     conversionOptions.push({ value, label, priority })
  //   }

  //   if (conversionOptions.length) {
  //     toRet.push({
  //       label: 'Conversions',
  //       value: 'conversions',
  //       options: [
  //         ...conversionOptions.sort((a, b) =>
  //           a.priority < b.priority ? -1 : 1
  //         ),
  //       ],
  //     })
  //   }

  //   if (revenueOptions.length) {
  //     toRet.push({
  //       label: 'Revenue',
  //       value: 'revenue',
  //       options: [
  //         ...revenueOptions.sort((a, b) => (a.priority < b.priority ? -1 : 1)),
  //       ],
  //     })
  //   }

  //   setDailyPerformanceReferenceMetric(selectedByDefault)

  //   return toRet
  // }, [dailyPerformanceReferenceData, JSON.stringify(conversionGroups)])

  const columns = [
    {
      header: 'Alert Threshold',
      id: 'name',
      accessorKey: 'name',
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 160,
    },
    {
      header: 'Alert Threshold Key',
      id: 'key',
      cell: (cell) => (
        <span
          style={{ backgroundColor: colorMap[`[${cell.row.original.key}]`] }}
        >
          {cell.row.original.key}
        </span>
      ),
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 200,
    },
    {
      header: 'Global',
      id: 'global-threshold',
      cell: (cell) => (
        <span
          className={
            modifiedThresholds[cell.row.original._id]
              ? 'alert-thresholds-panel__default'
              : 'font-bold'
          }
        >
          {new Intl.NumberFormat('en-US').format(cell.row.original.value)}
        </span>
      ),
      textAlign: 'right',
      cellTextAlign: 'right',
      size: 96,
    },
    {
      header: 'Client',
      id: 'client-threshold',
      size: 96,
      cell: (cell) => {
        const { _id } = cell.row.original
        const value = modifiedThresholds[_id]
        return (
          <span className="font-bold">{value ? value.toString() : '--'}</span>
        )
      },
      textAlign: 'right',
      cellTextAlign: 'right',
    },
    // {
    //   header: 'Client',
    //   id: 'client-threshold',
    //   size: 96,
    //   cell: (cell) => {
    //     const { _id } = cell.row.original
    //     const value = modifiedThresholds[_id]

    //     const defaultThreshold = defaultClient.alertThresholds.find(
    //       ({ globalThreshold }) => globalThreshold === _id
    //     )

    //     return (
    //       <Input
    //         className={'client-threshold'}
    //         placeholder=" -- "
    //         onBlur={(ev) => onThresholdChange(ev.target.value, _id)}
    //         value={value?.toString()}
    //         type="budget"
    //         disabled={isViewMode || simulationRunning}
    //         min={0}
    //         isBordered
    //         hasChanges={value && defaultThreshold?.value !== value}
    //       />
    //     )
    //   },
    //   textAlign: 'right',
    //   cellTextAlign: 'right',
    // },
    // {
    //   header: () => {
    //     return (
    //       <ThresholdRecommendationHeader
    //         alertThresholds={alertThresholds}
    //         clientThresholds={modifiedThresholds}
    //         recommendedThresholds={recommendedThresholds}
    //         openBulkAssignModal={changeBulkAssignModalVisibility(true)}
    //       />
    //     )
    //   },
    //   textAlign: 'right',
    //   id: 'recommended-threshold',
    //   size: 118,
    //   cell: (cell) => {
    //     return (
    //       <ThresholdRecommendationCell
    //         threshold={cell.row.original}
    //         clientThresholds={modifiedThresholds}
    //         recommendedThresholds={recommendedThresholds}
    //         recommendedThresholdsLoading={recommendedThresholdsLoading}
    //         onThresholdChange={onThresholdChange}
    //       />
    //     )
    //   },
    // },
    {
      header: () => {
        return (
          <div className="display-flex gap-8 align-items-center associated-alerts__info-icon">
            Other Alerts
            <StaticTooltip
              tooltipClassName="associated-alerts__tooltip"
              content={<AssociatedAlertsTable alerts={tooltipAlerts} />}
            >
              <InfoIcon />
            </StaticTooltip>
          </div>
        )
      },
      id: 'alerts',
      cell: ({
        row: {
          original: { alerts },
        },
      }) => <AssociatedAlerts alerts={alerts} />,
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 130,
      maxSize: 130,
    },
  ]

  return (
    <Modal
      opened={!!alert}
      contentSeparator
      rightAlignButtons
      // button={
      //   !isViewMode ? (
      //     <Button
      //       value={
      //         <>
      //           <SaveIcon />
      //           <span className="save-button-text"> SAVE</span>
      //         </>
      //       }
      //       onClick={onSaveChanges}
      //       secondaryGreen
      //     />
      //   ) : null
      // }
      buttonSecondary={
        <Button
          value={isViewMode ? 'CLOSE' : 'CANCEL'}
          onClick={() => {
            if (thresholdsChanged()) {
              setCancelModal(!cancelModal)
              return
            }
            onCancel()
          }}
          secondaryGray
        />
      }
      heading={
        <>
          {alert.name}
          <div className="modal__heading__view-mode">
            <ButtonToggle
              label="Simplified"
              selected={isSimplifiedViewMode}
              onClick={() => setIsSimplifiedViewMode(true)}
            />
            <ButtonToggle
              label="Detailed"
              selected={!isSimplifiedViewMode}
              onClick={() => setIsSimplifiedViewMode(false)}
            />
          </div>
        </>
      }
      className="alert-threshold-modal"
    >
      {cancelModal ? _renderCancelModal() : null}
      {bulkAssignModalVisible && (
        <BulkAssignRecommendationsModal
          visible={bulkAssignModalVisible}
          onAssign={onBulkThresholdChange}
          onClose={changeBulkAssignModalVisibility(false)}
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <>
          <AlertFormulaSection
            fullText={formula}
            highlights={wordsToHighlight}
            colorsMap={colorMap}
            pattern={/((?:\[[^[\]]*\])|(?:[+-]*\s*[0-9]+\s*[+-]*)|(?:[()]))/g}
            separator={' '}
            hoveredWordIndex={hoveredSampleDataWordIndex}
            setHoveredWordIndex={setHoveredSampleDataWordIndex}
            alertThresholdValues={alertThresholdValues}
            isSimplifiedViewMode={isSimplifiedViewMode}
          />
          <Table
            columns={columns}
            data={alertThresholds || []}
            className={'thresholds-table margin-bottom-16'}
          />
          {/* <div className="align-row">
            <div className="frequency-table-wrapper">
              <div className="general-label display-flex">
                Estimated Trigger Frequency
                <InfoIcon
                  onMouseEnter={showTooltip}
                  onMouseLeave={hideTooltip}
                />
                <Tooltip />
              </div>
              <div className="frequency-table">
                <LoadingOverlay
                  visible={
                    showOverlayToStartRunningSimulation || simulationRunning
                  }
                  loading={simulationRunning}
                >
                  {showOverlayToStartRunningSimulation ? (
                    <div
                      className="frequency-table__overlay-text frequency-table__overlay-text--pointer"
                      onClick={() => runThresholdSimulation()}
                    >
                      <ReloadIcon className="svg--liliac" />
                      <p>Run Simulation</p>
                    </div>
                  ) : (
                    <div className="frequency-table__overlay-text frequency-table__overlay-text--lower">
                      <div className="frequency-table__overlay-text-first-row">
                        <LoadingIcon />
                        <p>Running Simulation</p>
                      </div>
                      <p className="frequency-table__overlay-text-second-row">
                        May take up to a few minutes
                      </p>
                    </div>
                  )}
                </LoadingOverlay>
                <Table
                  columns={FREQUENCY_COLUMNS}
                  data={estimatedTriggerFrequency}
                />
              </div>
            </div>
            <div className="daily-performance-ref-table-wrapper">
              <div className="daily-performance-ref-table-info display-flex">
                <div className="general-label display-flex">
                  {`Performance Reference | 1 ${alert.time} ${
                    hasAlertGranularity ? alert?.granularity : 'Account'
                  }  `}
                  {DAILY_PERFORMANCE_REFERENCE_METRIC_LABELS[
                    dailyPerformanceReferenceMetric
                  ] ||
                    DAILY_PERFORMANCE_REFERENCE_KPI_LABELS[
                      dailyPerformanceReferenceMetric
                    ]}
                  <InfoIcon
                    onMouseEnter={showDailyReferenceTooltip}
                    onMouseLeave={hideDailyReferenceTooltip}
                  />
                  <DailyReferenceTooltip />
                </div>
                <div className="display-flex">
                  {loadingDailyPerformanceReferenceConversions ? (
                    <LoadingIcon width="24px" height="24px" />
                  ) : null}
                  <DropdownTree
                    className="daily-performance-ref-table-dropdown"
                    options={dailyPerformanceDropdownOptions}
                    onChange={({ value }) =>
                      value && setDailyPerformanceReferenceMetric(value)
                    }
                    selected={
                      DAILY_PERFORMANCE_REFERENCE_METRIC_LABELS[
                        dailyPerformanceReferenceMetric
                      ] ||
                      DAILY_PERFORMANCE_REFERENCE_KPI_LABELS[
                        dailyPerformanceReferenceMetric
                      ]
                    }
                    forceOpenUpwards={true}
                    disabled={!hasAlertGranularity}
                  />
                </div>
              </div>
              <div className="daily-performance-ref-table">
                <LoadingOverlay visible={loadingDailyPerformanceReferenceTable}>
                  <LoadingIcon />
                </LoadingOverlay>
                <Table
                  disableSort={true}
                  columns={getDailyPerformanceReferenceColumns(
                    dailyPerformanceReferenceMetric
                  )}
                  data={
                    dailyPerformanceReferenceData[
                      dailyPerformanceReferenceMetric
                    ] || []
                  }
                  emptyText="No data to display. If this is unexpected, check this alert’s granularity setting."
                />
              </div>
            </div>
          </div> */}
        </>
      )}
    </Modal>
  )
}

AlertsThresholdModal.propTypes = {
  alert: propTypes.object.isRequired,
  onCancel: propTypes.func.isRequired,
  onSave: propTypes.func.isRequired,
  company: propTypes.object.isRequired,
  parentClient: propTypes.object,
  isAlertThresholdsLinked: propTypes.bool,
  defaultClient: propTypes.object.isRequired,
  copyFromCompany: propTypes.object,
}

export default AlertsThresholdModal
