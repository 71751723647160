import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  entityStatus,
  timezoneCountry,
} from '@decision-sciences/qontrol-common'

/* Store & Actions */
import { useStore } from 'store'
import {
  bulkUpdateUsers,
  bulkDelete,
  bulkAssignCompany,
  bulkUnassignCompany,
  bulkAssignTeam,
  setBulkUsers,
  bulkUnassignTeam,
} from 'modules/users/actions'

/* Hooks */
import { useAccess, PERMISSION_TYPES, PERMISSIONS } from 'hooks/access'
import useSession from 'modules/session'

/* Constants */

/* Components */
import { Dropdown } from 'components/dropdown'
import { showErrorMessage } from 'modules/notifications/actions'
import BulkEdit from 'components/bulk-edit'
import CheckboxSection from 'components/checkbox-section'
import InputText from 'components/input'
import InformationBlock, {
  INFORMATION_BLOCK_TYPE,
} from 'components/information-block/index'
import { BusinessUnitsBulkUnassign } from 'modules/users/bulk-edit/unassign-business-units'
import { AccountsBulkUnassign } from 'modules/users/bulk-edit/unassign-accounts'

import ClientsAndAccounts from 'modules/users/clients-and-accounts/index'

/* Icons */
import { ReactComponent as AddIcon } from 'assets/icon_plus_green.svg'
import { ReactComponent as RemoveIcon } from 'assets/icon_minus_red.svg'
import { ReactComponent as EditIcon } from 'assets/icon_edit.svg'

/* Styles */
import './style.scss'

const { ENTITY_STATUS_OPTIONS } = entityStatus
const { TIMEZONES } = timezoneCountry

/**
 * Component which handles bulk editing for selected users
 * @param {Object} props Component props
 * @param {Function} props.setBulkEdit Callback to edit bulk editing
 * @param {Array} props.selectedItems List of selected users
 * @param {Function} props.setSelectedItems Callback to change list of selected users
 * @param {Array} props.columns Column configuration for the table
 * @returns {React.FunctionComponent}
 */
const BulkEditUsers = ({
  setBulkEdit,
  selectedItems,
  setSelectedItems,
  columns,
}) => {
  // Stores
  const { dispatch, state } = useStore()
  const [, userData] = useSession()

  // Store data
  const { list: companies } = state.companies
  const { list: teams } = state.teams
  const { list: permissionGroups } = state.permissionGroups

  const selectedClientsAndAccountsMap = useMemo(() => {
    const map = {
      accounts: {},
      businessUnits: {},
      clients: {},
      teams: {},
    }
    selectedItems.forEach((user) => {
      user.clients.forEach(({ clientId, accounts, businessUnits }) => {
        map.clients[clientId] = true
        accounts.forEach((accountId) => (map.accounts[accountId] = true))
        businessUnits.forEach(
          (businessUnitId) => (map.businessUnits[businessUnitId] = true)
        )
      })
      user.teams.forEach((team) => (map.teams[team._id || team] = true))
    })

    return map
  }, [JSON.stringify(selectedItems)])

  const commonCompanies = companies.filter(
    ({ _id }) => selectedClientsAndAccountsMap.clients[_id]
  )

  // Companies that only hold the accounts that users are assigned to
  const companiesWithAccounts = useMemo(() => {
    const list = []
    companies.forEach((company) => {
      const businessUnitsWithAccounts = company.businessUnits.filter(
        ({ accounts }) => accounts.length
      )

      if (company.accounts.length || businessUnitsWithAccounts) {
        list.push({ ...company, businessUnits: businessUnitsWithAccounts })
      }
    })

    return list
  }, [])

  // Permissions
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.USER_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasAssignTeamAccess = useAccess({
    feature: PERMISSIONS.ASSIGN_TEAMS_USER,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasAssignClientsAccess = useAccess({
    feature: PERMISSIONS.ASSIGN_CLIENTS_USER,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasPermissionGroupsReadAccess = useAccess({
    feature: PERMISSIONS.PERMISSION_GROUPS,
    type: PERMISSION_TYPES.READ,
  })

  const getTeamOptions = () => {
    // Check if all items share the same company
    let sharedCompany = null
    const foundSharedCompany = false
    for (let i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i].teams.length !== 1) {
        sharedCompany = false
        break
      } else {
        if (foundSharedCompany && sharedCompany !== selectedItems[i].teams[0]) {
          sharedCompany = false
          break
        } else {
          sharedCompany = selectedItems[i].teams[0]
        }
      }
    }
    return teams
      .filter((team) => team.isGlobal || team._id === sharedCompany)
      .map((team) => {
        return { value: team._id, label: team.name }
      })
  }

  const getIdList = (userList) => userList.map((user) => user._id)

  const bulkEditOptions = [
    {
      label: 'Assign to Teams',
      value: 'Assign to Teams',
      icon: AddIcon,
      disabled: !hasAssignTeamAccess,
      action: (data, change) => {
        const idList = getIdList(data)
        return bulkAssignTeam(dispatch, idList, change)
      },
      renderInput: (value, onChange) => {
        return (
          <Dropdown
            label="Team"
            labelTop
            defaultOptionText="Select Team"
            selectedItems={value}
            multiSelect={true}
            selectAll
            options={getTeamOptions()}
            onChange={onChange}
          />
        )
      },
    },
    {
      label: 'Remove from Team',
      value: 'Remove from Team',
      icon: RemoveIcon,
      disabled: !hasAssignTeamAccess,
      action: (data, change) => {
        const idList = getIdList(data)
        return bulkUnassignTeam(dispatch, idList, change)
      },
      renderInputBelow: (value, onChange) => {
        const items = teams
          .filter((team) => selectedClientsAndAccountsMap.teams[team._id])
          .map((team) => ({
            value: team._id,
            label: team.name,
          }))

        const allSelected = items.length === (value || []).length

        return (
          <CheckboxSection
            hasSearch
            label="Teams"
            config={{
              items,
              selectedItems: value || [],
              onChange,
            }}
            onClickSelectAll={() =>
              allSelected
                ? onChange([])
                : onChange(items.map(({ value }) => value))
            }
            allSelected={allSelected}
          />
        )
      },
      renderCallout: () => (
        <InformationBlock
          type={INFORMATION_BLOCK_TYPE.INFO}
          info="Select the items below you want to have the user removed from."
        />
      ),
    },
    {
      label: 'Assign to Client',
      value: 'Assign to Client',
      icon: AddIcon,
      disabled: !hasAssignClientsAccess,
      action: (data, change) => {
        const idList = getIdList(data)
        return bulkAssignCompany(dispatch, idList, change)
      },
      renderInputBelow: (value, onChange) => {
        return (
          <ClientsAndAccounts
            userClients={value || []}
            clients={companies}
            onChange={onChange}
            showAccounts={false}
            showBusinessUnits={false}
          />
        )
      },
    },
    {
      label: 'Remove from Client',
      value: 'Remove from Client',
      icon: RemoveIcon,
      disabled: !hasAssignClientsAccess,
      action: (data, change) => {
        const idList = getIdList(data)
        return bulkUnassignCompany(dispatch, idList, change)
      },
      renderCallout: () => (
        <InformationBlock
          type={INFORMATION_BLOCK_TYPE.INFO}
          info="Select the items below you want to have the user removed from."
        />
      ),
      renderInputBelow: (value, onChange) => {
        return (
          <ClientsAndAccounts
            userClients={value || []}
            clients={commonCompanies}
            onChange={onChange}
            showAccounts={false}
            showBusinessUnits={false}
          />
        )
      },
    },
    {
      label: 'Assign to Business Unit',
      value: 'Assign to Business Unit',
      icon: AddIcon,
      disabled: !hasAssignClientsAccess,
      action: (data, change) => {
        const idList = getIdList(data)
        return bulkAssignCompany(dispatch, idList, change)
      },
      renderInputBelow: (value, onChange) => {
        return (
          <ClientsAndAccounts
            userClients={value || []}
            clients={companies.filter(
              ({ businessUnits }) => businessUnits.length
            )}
            onChange={onChange}
            showAccounts={false}
          />
        )
      },
    },
    {
      label: 'Remove from Business Unit',
      value: 'Remove from Business Unit',
      icon: RemoveIcon,
      disabled: !hasAssignClientsAccess,
      action: (data, change) => {
        const idList = getIdList(data)
        return bulkUnassignCompany(dispatch, idList, change)
      },
      renderInputBelow: (value, onChange) => {
        return (
          <BusinessUnitsBulkUnassign
            userClients={value || []}
            clients={commonCompanies}
            initialSelection={selectedClientsAndAccountsMap.businessUnits}
            onChange={onChange}
          />
        )
      },
      renderCallout: () => (
        <InformationBlock
          type={INFORMATION_BLOCK_TYPE.INFO}
          info="Select the items below you want to have the user removed from."
        />
      ),
    },
    {
      label: 'Assign to Accounts',
      value: 'Assign to Accounts',
      icon: AddIcon,
      disabled: !hasAssignClientsAccess,
      action: (data, change) => {
        const idList = getIdList(data)
        return bulkAssignCompany(dispatch, idList, change)
      },
      renderInputBelow: (value, onChange) => {
        return (
          <ClientsAndAccounts
            userClients={value || []}
            clients={companiesWithAccounts}
            onChange={onChange}
          />
        )
      },
    },
    {
      label: 'Remove from Accounts',
      value: 'Remove from Accounts',
      icon: RemoveIcon,
      disabled: !hasAssignClientsAccess,
      action: (data, change) => {
        const idList = getIdList(data)
        return bulkUnassignCompany(dispatch, idList, change)
      },
      renderInputBelow: (value, onChange) => {
        return (
          <AccountsBulkUnassign
            userClients={value || []}
            clients={commonCompanies}
            initialSelection={selectedClientsAndAccountsMap.accounts}
            onChange={onChange}
          />
        )
      },
      renderCallout: () => (
        <InformationBlock
          type={INFORMATION_BLOCK_TYPE.INFO}
          info="Select the items below you want to have the user removed from."
        />
      ),
    },
    {
      label: 'Update Job Title',
      value: 'Update Job Title',
      icon: EditIcon,
      disabled: !hasEditAccess,
      action: (data, jobTitle) => {
        const idList = getIdList(data.filter((user) => !user.isSuperAdmin))
        return bulkUpdateUsers(dispatch, idList, {
          jobTitle,
        })
      },
      renderInput: (value, onChange) => {
        return (
          <InputText
            value={value}
            onChange={onChange}
            placeholder="Enter Job Title"
            label="Job Title"
          />
        )
      },
    },
    {
      label: 'Update Permissions',
      value: 'Update Permissions',
      icon: EditIcon,
      disabled: !hasPermissionGroupsReadAccess,
      action: (data, permissionGroup) => {
        const idList = getIdList(data.filter((user) => !user.isSuperAdmin))
        return bulkUpdateUsers(dispatch, idList, {
          permissionGroup: permissionGroup || null,
          permissions: null,
        })
      },
      renderInput: (value, onChange) => {
        return (
          <Dropdown
            labelTop
            label="Permission Group"
            defaultOptionText="Select Permission Group"
            defaultState={value}
            options={(permissionGroups || []).map((pg) => ({
              label: pg.name,
              value: pg._id,
            }))}
            onChange={onChange}
          />
        )
      },
    },
    {
      label: 'Update Status',
      value: 'Update Status',
      disabled: !hasEditAccess,
      icon: EditIcon,
      action: (data, change) => {
        const idList = getIdList(data)
        return bulkUpdateUsers(dispatch, idList, {
          active: change,
        })
      },
      renderInput: (value, onChange) => {
        return (
          <Dropdown
            label="Status"
            labelTop
            defaultOptionText="Status"
            defaultState={value}
            options={ENTITY_STATUS_OPTIONS}
            onChange={onChange}
          />
        )
      },
    },
    {
      label: 'Update Time Zone',
      value: 'Update Time Zone',
      icon: EditIcon,
      disabled: !hasEditAccess,
      action: (data, timeZone) => {
        const idList = getIdList(data)
        return bulkUpdateUsers(dispatch, idList, {
          timeZone,
        })
      },
      renderInput: (value, onChange) => {
        return (
          <Dropdown
            label="Time Zone"
            labelTop
            defaultOptionText="Select Time Zone"
            defaultState={value}
            options={TIMEZONES}
            onChange={onChange}
          />
        )
      },
    },
  ]

  return (
    <BulkEdit
      columns={columns}
      data={selectedItems}
      labels={{ delete: 'Archive Users', heading: 'Edit Users' }}
      onCancel={() => {
        setBulkEdit(false)
        setSelectedItems([])
        setBulkUsers(dispatch, [])
      }}
      onDelete={
        userData.isSuperAdmin &&
        ((data) => {
          setSelectedItems([])
          setBulkEdit(false)
          setBulkUsers(dispatch, [])
          return bulkDelete(
            dispatch,
            data.map((user) => user._id)
          )
        })
      }
      onError={(err) => showErrorMessage(err, dispatch)}
      options={bulkEditOptions}
    />
  )
}

BulkEditUsers.propTypes = {
  setBulkEdit: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
}

export default BulkEditUsers
