import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'components/dropdown'
import Input from 'components/input'
import { weekDays } from 'components/schedule/utils'
import { utils, notifications } from '@decision-sciences/qontrol-common'
import { ERRORS } from 'components/validator'
import InputGroup from 'components/input-group'
import './style.scss'

const { shallowComparison } = utils.object
const { FREQUENCY_OPTIONS, DATE_TYPE, FREQUENCY_OPTIONS_MAP } = notifications

const Recurrence = ({
  onChange,
  showDateHoursFields = true,
  showTypeDropdown = false,
  defaultState,
  errors = {},
  frequencyOptions,
  disabled,
  showInGroup,
  amountPlaceholder,
  ...other
}) => {
  const [showTime, setShowtime] = useState(false)
  const [recurrence, setRecurrence] = useState({
    type: DATE_TYPE[0].value,
    amount: '',
    frequency: '',
    weekDay: '',
    monthDay: '',
    hours: '',
    minutes: '',
  })

  useEffect(() => {
    if (!shallowComparison(defaultState, recurrence)) {
      setRecurrence({ ...recurrence, ...defaultState })
    }
  }, [defaultState])

  const hasGeneralError = errors.general && ' '

  /** Listen for changes in the recurrence field */
  const recurrenceChanged = (fieldName, fieldValue) => {
    const newRecurrence = { ...recurrence, [fieldName]: fieldValue }
    setRecurrence(newRecurrence)
    onChange(newRecurrence)
  }

  const DateAndHoursContent = (
    <>
      {recurrence.frequency === 'weeks' && (
        <>
          <span className="recurrence__at">on</span>
          <Dropdown
            options={weekDays}
            defaultState={recurrence.weekDay}
            error={hasGeneralError}
            onChange={(weekDay) => {
              recurrenceChanged('weekDay', weekDay)
            }}
          />
        </>
      )}
      {recurrence.frequency === 'months' && (
        <>
          <span className="recurrence__at">on day</span>
          <Input
            type="number"
            min={1}
            max={31}
            value={recurrence.monthDay}
            onChange={(value) => recurrenceChanged('monthDay', value)}
            error={hasGeneralError}
          />
        </>
      )}
      {showTime && (
        <>
          <span className="recurrence__at">at</span>
          <Input
            type="number"
            min={0}
            max={23}
            maxLength={2}
            placeholder="hh"
            value={recurrence.hours}
            onChange={(value) => recurrenceChanged('hours', value)}
            className="recurrence__time"
          />
          :
          <Input
            type="number"
            min={0}
            max={59}
            maxLength={2}
            placeholder="mm"
            value={recurrence.minutes}
            onChange={(value) => recurrenceChanged('minutes', value)}
            className="recurrence__time"
          />
        </>
      )}
    </>
  )

  return (
    <div className="recurrence-wrapper" {...other}>
      {showInGroup ? (
        <div className="recurrence--group">
          <InputGroup
            options={[
              {
                render: (
                  <label data-cy="send-notification-label">
                    Send a notification every:
                  </label>
                ),
                width: 224,
                minWidth: 'max-content',
              },
              {
                render: (
                  <Dropdown
                    options={frequencyOptions || FREQUENCY_OPTIONS}
                    className="recurrence__frequency"
                    defaultState={recurrence.frequency}
                    defaultOptionText="Time Frame"
                    onChange={(frequency) => {
                      recurrenceChanged('frequency', frequency)
                      setShowtime(
                        ['days', 'weeks', 'months'].indexOf(frequency) > -1
                      )
                    }}
                    error={errors.frequency}
                    disabled={disabled}
                    data-cy="send-notification-time-frame"
                  />
                ),
                width: 140,
                error:
                  errors.frequency || hasGeneralError ? ERRORS.REQUIRED : '',
              },
              {
                render:
                  recurrence.frequency !== FREQUENCY_OPTIONS_MAP.MINUTES ? (
                    <Input
                      type="number"
                      className="recurrence__amount"
                      placeholder="Enter Value"
                      min={0}
                      value={recurrence.amount}
                      onChange={(value) => recurrenceChanged('amount', value)}
                      disabled={disabled || !recurrence.frequency}
                      data-cy="send-notification-value"
                    />
                  ) : (
                    <Dropdown
                      options={[5, 10, 15, 30, 45]}
                      className="recurrence__frequency"
                      defaultState={recurrence.amount}
                      defaultOptionText="Select Value"
                      onChange={(amount) => {
                        recurrenceChanged('amount', amount)
                      }}
                      disabled={disabled}
                      data-cy="send-notification-value"
                    />
                  ),
                error: errors.amount || hasGeneralError ? ERRORS.REQUIRED : '',
                width: 160,
              },
            ]}
          />
        </div>
      ) : (
        <div className="recurrence">
          {showTypeDropdown && (
            <p className="recurrence__type">{DATE_TYPE[0].label}</p>
          )}
          <Input
            type="number"
            className="recurrence__amount"
            placeholder={amountPlaceholder || 'Number'}
            min={0}
            value={recurrence.amount}
            error={errors.amount || hasGeneralError ? ERRORS.REQUIRED : ''}
            onChange={(value) => {
              // Prevent value to accept  0 as first character
              if (!value.toString().startsWith('0')) {
                recurrenceChanged('amount', value)
              }
            }}
            disabled={disabled}
          />
          <Dropdown
            options={frequencyOptions || FREQUENCY_OPTIONS}
            className="recurrence__frequency"
            defaultState={recurrence.frequency}
            defaultOptionText="Time Frame"
            onChange={(frequency) => {
              recurrenceChanged('frequency', frequency)
              setShowtime(['days', 'weeks', 'months'].indexOf(frequency) > -1)
            }}
            error={errors.frequency}
            disabled={disabled}
            data-cy="send-notification-time-frame"
          />
          {showDateHoursFields ? DateAndHoursContent : null}
          {showTypeDropdown && recurrence.type === DATE_TYPE[0].value && (
            <span className="recurrence__ago">ago</span>
          )}
        </div>
      )}
    </div>
  )
}

Recurrence.propTypes = {
  onChange: PropTypes.func.isRequired,
  showDateHoursFields: PropTypes.bool,
  showTypeDropdown: PropTypes.bool,
  defaultState: PropTypes.object,
  errors: PropTypes.object,
  frequencyOptions: PropTypes.array,
  disabled: PropTypes.bool,
  showInGroup: PropTypes.bool,
  amountPlaceholder: PropTypes.string,
}

export default Recurrence
