import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import CheckboxSection from 'components/checkbox-section/index'

/**
 * Renders the Accounts section
 * @param {Object} params React Params
 * @param {Boolean} params.viewOnly Read-Only mode
 * @param {Array} params.userClients user.clients array
 * @param {Array<Object>} [params.clients = []] List of client objects
 * @param {Object} [params.initialSelection = {}] Contains selected acount ObjectIds for all users
 * @param {Function} params.onChange To be called with new user.clients array
 * @param {Boolean} params.hasSearch Whether global search is enabled
 */
const AccountsBulkUnassign = ({
  viewOnly,
  userClients = [],
  clients = [],
  initialSelection = {},
  onChange,
  hasSearch = true,
}) => {
  // List of Client Objects representing currently selected clients.
  const selectedClients = useMemo(() => {
    if (!userClients || !clients) {
      return []
    }
    return clients.filter((client) =>
      userClients.some((userClient) => userClient.clientId === client._id)
    )
  }, [JSON.stringify(userClients), JSON.stringify(clients)])

  /**
   * Config for accounts
   * Each config works under the structure:
   * @example <caption>Account Config Example</caption>
   * {
   *  title: Company Name
   *  subtitle: Business Unit Name // In case account belongs to business unit
   *  items: <list of account ids>
   *  selected: <list of selected account ids>
   *  onChange: <function to call with new selected list of ids>
   * }
   */
  const accountsConfig = useMemo(() => {
    return clients.map((client) => {
      const businessUnitAccounts = []

      if (client.businessUnits?.length) {
        client.businessUnits.forEach((businessUnit) => {
          if (businessUnit.accounts?.length) {
            businessUnitAccounts.push(
              ...businessUnit.accounts.map((account) => ({
                value: account._id,
                label: account.name,
                type: account.type,
                disabled: !initialSelection[account._id],
              }))
            )
          }
        })
      }

      return {
        title: client.name,
        items: [
          ...businessUnitAccounts,
          ...client.accounts.map((account) => ({
            value: account._id,
            label: account.name,
            type: account.type,
            disabled: !initialSelection[account._id],
          })),
        ],
        clientId: client._id,
        selectedItems:
          userClients.find((clientData) => clientData.clientId === client._id)
            ?.accounts || [],
        onChange: (accounts) =>
          onChange(
            getClientListForAccountsChange(userClients, client._id, accounts)
          ),
      }
    }, [])
  }, [JSON.stringify(selectedClients), JSON.stringify(userClients)])

  const allAccountsSelected = useMemo(
    () =>
      accountsConfig.every(({ selectedItems, items }) => {
        const availableItems = items.filter((item) => !item.disabled)
        return (
          !availableItems.length ||
          availableItems.every(({ value }) => selectedItems.includes(value))
        )
      }),
    [JSON.stringify(accountsConfig.map(({ selectedItems }) => selectedItems))]
  )

  // Account list change handler
  const getClientListForAccountsChange = (userClients, clientId, accounts) => {
    const newClients = [...userClients]
    const clientIdx = userClients.findIndex(
      (uClient) => uClient.clientId === clientId
    )
    if (clientIdx !== -1) {
      newClients[clientIdx].accounts = accounts
    } else {
      newClients.push({ clientId, businessUnits: [], accounts })
    }
    return newClients
  }

  const onSelectAllAccounts = () => {
    let newClients = [...userClients]
    // We need to keep this since we can have multiple account configs per client, therefore, we might have overlapping changes and new changes will have stale values
    const newSelectedAccountsPerClient = {}

    for (const config of accountsConfig) {
      if (allAccountsSelected) {
        newClients = getClientListForAccountsChange(
          newClients,
          config.clientId,
          []
        )
        continue
      }

      const newAccountsForConfig = [
        ...new Set([
          ...(newSelectedAccountsPerClient[config.clientId] || []),
          ...config.items
            .filter((item) => !item.disabled)
            .map(({ value }) => value),
        ]),
      ]

      // We set every newly added account in a temporary variable so we always have fresh data
      if (!newSelectedAccountsPerClient[config.clientId]) {
        newSelectedAccountsPerClient[config.clientId] = []
      }
      newSelectedAccountsPerClient[config.clientId] = newAccountsForConfig

      newClients = getClientListForAccountsChange(
        newClients,
        config.clientId,
        newAccountsForConfig
      )
    }

    onChange(newClients)
  }

  return (
    <div className="clients-and-accounts">
      <CheckboxSection
        hasSearch={hasSearch}
        label="Accounts"
        config={accountsConfig}
        onClickSelectAll={onSelectAllAccounts}
        allSelected={allAccountsSelected}
      />
    </div>
  )
}

AccountsBulkUnassign.propTypes = {
  userClients: PropTypes.array,
  clients: PropTypes.array,
  initialSelection: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  hasSearch: PropTypes.bool,
}

export { AccountsBulkUnassign }
