import { DragAndDrop } from 'components/utils/drag-drop'
import ActiveAlertTriggers from 'modules/alert-triggers/active-alert-triggers'
import React from 'react'

const ActiveAlertTriggersPage = () => {
  return (
    <DragAndDrop>
      <div className="alert-trigger-details-page page">
        <ActiveAlertTriggers />
      </div>
    </DragAndDrop>
  )
}

export default ActiveAlertTriggersPage
