import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useNavigate, redirect } from 'react-router-dom'
import { useStore } from 'store'
import cx from 'classnames'
import InputText from 'components/input'
import Button from 'components/button'
import { Dropdown } from 'components/dropdown'
import { FIELD_TYPES, validate } from 'components/validator'
import scrollToClosestError from 'components/scroll-to-error'
import Modal from 'components/modal'
import { CheckboxNoHooks } from 'components/checkbox'
import StickyFooter from 'components/sticky-footer'
import useSession from 'modules/session'
import useLeaveConfirm from 'components/leave-confirm'
import Permissions, { PermissionsLegend } from 'components/permissions'
import CollapsibleSection from 'components/collapsible-section'
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'
import ClientsAndAccounts from 'modules/users/clients-and-accounts'
import {
  checkForRestore,
  createUpdateUser,
  deleteUser,
  getUserById,
  getUsers,
  reactivateUser,
  restoreUser,
} from 'modules/users/actions'
import DropdownWithSubsections from 'components/dropdown-with-subsections'
import LabelWrapper from 'components/label-wrapper'
import { NOT_FOUND_ROUTE } from 'routes'
import { usePendingState } from 'components/utils/custom-hooks'
import {
  timezoneCountry,
  entityStatus,
} from '@decision-sciences/qontrol-common'
import { RestoreNotification } from 'modules/users/restore-popover'
import { TABLE_CONTAINER } from 'modules/table-filter-sort/constants'
import { saveFilter } from 'modules/table-filter-sort/actions'
import { USER_DEFAULT } from 'modules/users/constants'
import {
  showErrorMessage,
  showSuccessMessage,
} from 'modules/notifications/actions'
import Loader from 'components/loader/index'

const { TIMEZONES } = timezoneCountry
const {
  ENTITY_STATUS_LABEL,
  ENTITY_STATUS_OPTIONS,
  ENTITY_STATUS_FOR_FILTERS,
} = entityStatus

const CreateEditUser = ({ userId, isAdmin }) => {
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showPermissionUncheckModal, setShowPermissionUncheckModal] =
    useState(false)
  const [errors, setErrors] = useState({})
  const [existingUser, setExistingUser] = useState(null)
  const [loading, setLoading] = useState(false)
  const permissionsRef = useRef()

  const navigate = useNavigate()
  const [, userData] = useSession()
  const { dispatch, state } = useStore()
  const { list: permissionGroups } = state.permissionGroups
  const { list: clients } = state.companies

  const teams = useMemo(() => {
    return state.teams.list?.filter((team) => team.active)
  }, [state.teams.list])

  const isNew = userId === 'new'

  const [user, setUser, pendingChanges] = usePendingState(existingUser, {
    ...USER_DEFAULT,
  })

  useEffect(() => {
    if (errors) {
      setErrors({})
    }
    if (!isNew) {
      setLoading(true)
      getUserById(userId)
        .then((user) => {
          setExistingUser({ ...user })
          setUser({ ...user })
        })
        .catch((error) => {
          showErrorMessage(error, dispatch)
          navigate(NOT_FOUND_ROUTE, { replace: true })
        })
        .finally(() => setLoading(false))
    }
  }, [isNew, userId])

  const _goToUserList = () => {
    navigate('/users', { state: { BYPASS_LEAVE_CONFIRM: true } })
  }

  const [setDirty, LeaveConfirm] = useLeaveConfirm({})

  const editAccess = useAccess({
    feature: PERMISSIONS.USER_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })
  const createAccess = useAccess({
    feature: PERMISSIONS.USER_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })
  const hasAssignUserAccess = useAccess({
    feature: PERMISSIONS.ASSIGN_CLIENTS_USER,
    type: PERMISSION_TYPES.EDIT,
  })

  const readAccess = useAccess({
    feature: PERMISSIONS.USER_DATA_FORM,
    type: PERMISSION_TYPES.READ,
  })
  const deleteAccess = useAccess({
    feature: PERMISSIONS.USER_DATA_FORM,
    type: PERMISSION_TYPES.ARCHIVE,
  })

  /**
   * Whether the logged in user has Permission Group READ access.
   * In case they don't, they won't be able to see the Permission Group dropdown, or the Custom Permissions component.
   * @type {Boolean}
   */
  const hasPermissionGroupReadAccess = useAccess({
    feature: PERMISSIONS.PERMISSION_GROUPS,
    type: PERMISSION_TYPES.READ,
  })

  /**
   * Whether the logged in user has Permission Group EDIT access.
   * In case they don't, we won't allow editing custom permissions either, as it causes a security loophole.
   * @type {Boolean}
   */
  const hasPermissionGroupEditAccess = useAccess({
    feature: PERMISSIONS.PERMISSION_GROUPS,
    type: PERMISSION_TYPES.EDIT,
  })

  /**
   * Whether the logged in user has Teams READ access.
   * In case they don't, they won't be able to see the Teams dropdown.
   * @type {Boolean}
   */
  const hasTeamsReadAccess = useAccess({
    feature: PERMISSIONS.TEAM_DATA_FORM,
    type: PERMISSION_TYPES.READ,
  })

  /** On mount check for valid permissions */
  useEffect(() => {
    if ((isNew && !createAccess) || (!isNew && !readAccess)) {
      redirect('/unauthorized')
    }
  }, [])

  /** Save handler */
  const onSave = (ev) => {
    ev.preventDefault()
    setErrors({})
    setLoading(true)

    /* Validate */
    const userObj = { ...user }
    const validationMap = {
      ...ERROR_MAP,
    }
    if (!user.isSuperAdmin) {
      validationMap.permissionGroup = FIELD_TYPES.REQUIRED
    }
    const [isValid, errors] = validate(validationMap, userObj)
    if (!isValid) {
      setLoading(false)
      setErrors(errors)
      scrollToClosestError()
      return false
    }

    if (Object.prototype.hasOwnProperty.call(pendingChanges, 'backupCode')) {
      delete userObj.backupCode
    }
    if (Object.prototype.hasOwnProperty.call(pendingChanges, 'inviteToken')) {
      delete userObj.inviteToken
    }

    /* Save the user */
    createUpdateUser(dispatch, pendingChanges)
      .then((res) => {
        if (res.error) {
          if (res.error === 'duplicate_user_deleted') {
            return setShowModal(true)
          } else {
            setErrors({ ...errors, general: res.error })
            return scrollToClosestError()
          }
        }
        setDirty(false)
        // If success, redirect back to list page
        setTimeout(() => {
          navigate('/users')
        }, 100)
      })
      .catch((err) => {
        if (typeof err === 'object') {
          setErrors({ ...errors, ...err })
        } else {
          setErrors({ ...errors, general: err })
        }
      })
      .finally(() => setLoading(false))
  }
  /** Delete user handler */
  const onDelete = (ev) => {
    ev.preventDefault()
    setLoading(true)
    deleteUser(dispatch, userId)
      .then((res) => {
        if (res.error) {
          return setErrors({ ...errors, general: res.error })
        }
        setDirty(false)
        _goToUserList()
      })
      .finally(() => setLoading(false))
  }

  const handleRestore = (id) => {
    return new Promise((resolve) => {
      setDirty(false)
      restoreUser(id)
        .then(({ userId }) => {
          showSuccessMessage('User restored successfully', dispatch)
          // Must re-fetch whole user list so it contains the newly restored user
          getUsers(dispatch).then(() => {
            resolve(userId)
          })
        })
        .catch((err) => {
          setDirty(true)
          showErrorMessage(`User failed to restore. ${err}`, dispatch)
          resolve()
        })
    })
  }

  const triggerRestore = () => {
    handleRestore(user._id).then(() => navigate(0))
  }

  const userPermissionGroup = (permissionGroups || []).find(
    (pg) => pg._id === user.permissionGroup
  )

  const viewOnly = user.deleted || (!isNew && !editAccess && readAccess)

  if (loading) {
    return <Loader />
  }

  return (
    <div
      className="user-data-form"
      data-cy={isNew ? 'add-user-form' : 'edit-user-form'}
    >
      <LeaveConfirm />
      <Helmet>
        <title>{isNew ? 'Add' : 'Edit'} User</title>
      </Helmet>
      <div className="heading" data-cy="page-heading">
        {viewOnly ? 'View' : isNew ? 'Add' : 'Edit'} User
      </div>
      {viewOnly ? (
        <UserView
          user={user}
          setUser={setUser}
          permissionsRef={permissionsRef}
          setDirty={setDirty}
          errors={errors}
          setErrors={setErrors}
          isAdmin={isAdmin}
          permissionGroups={permissionGroups}
          loading={loading}
        />
      ) : (
        <UserForm
          isNew={isNew}
          user={user}
          setUser={setUser}
          permissionsRef={permissionsRef}
          setDirty={setDirty}
          errors={errors}
          setErrors={setErrors}
          isAdmin={isAdmin}
          permissionGroups={permissionGroups}
          loading={loading}
          teams={teams}
          hasPermissionGroupReadAccess={hasPermissionGroupReadAccess}
          hasTeamsReadAccess={hasTeamsReadAccess}
          restoreUser={handleRestore}
        />
      )}

      {!user.isSuperAdmin && (
        <CollapsibleSection
          defaultCollapsed={false}
          header="Clients & Accounts"
        >
          <ClientsAndAccounts
            userClients={user?.clients}
            onChange={(clients) => {
              const removedClients = user.clients.filter(
                (uc) => !clients.some((c) => c.clientId === uc.clientId)
              )
              let newTeams = [...user.teams]
              removedClients.forEach(({ clientId }) => {
                newTeams = newTeams.filter((team) => {
                  const foundTeam = teams.find(
                    (t) => t._id === team._id || t._id === team
                  )
                  if (!foundTeam) {
                    return false
                  }
                  if (foundTeam.isGlobal) {
                    return true
                  }
                  return foundTeam.companies?.[0]?._id !== clientId
                })
              })
              setUser({ clients, teams: newTeams })
              setDirty(true)
            }}
            clients={clients}
            viewOnly={user.deleted || !hasAssignUserAccess}
          />
        </CollapsibleSection>
      )}
      {!user.isSuperAdmin && hasPermissionGroupReadAccess && readAccess && (
        <CollapsibleSection
          extras={
            <CheckboxNoHooks
              isChecked={!!user.permissions}
              label="Customize Permissions"
              disabled={viewOnly || !hasPermissionGroupEditAccess}
              reversed
              onClick={() => {
                const hasCustomPermissions = !user.permissions
                if (hasCustomPermissions && permissionsRef.current) {
                  permissionsRef.current.focus()
                }
                if (
                  hasCustomPermissions ||
                  !Object.values(user.permissions).length
                ) {
                  setUser({
                    permissions: hasCustomPermissions ? {} : null,
                  })
                  setDirty(true)
                } else {
                  setShowPermissionUncheckModal(true)
                }
              }}
            />
          }
          ref={permissionsRef}
          defaultCollapsed={
            viewOnly || !hasPermissionGroupEditAccess
              ? false
              : !user.permissions
          }
          header="Permissions"
        >
          <Permissions
            viewMode={
              viewOnly || !user.permissions || !hasPermissionGroupEditAccess
            }
            defaultValue={user.permissions || undefined}
            onChange={(permissions) => {
              setUser({ permissions })
            }}
            baseValue={userPermissionGroup?.permissions}
          />
        </CollapsibleSection>
      )}
      {showModal && (
        <Modal
          className="users__reactivate"
          opened={showModal}
          heading="Reactivate Account"
          button={
            <Button
              value="Reactivate"
              onClick={(e) => {
                e.preventDefault()
                setLoading(true)
                reactivateUser(dispatch, user.email)
                  .then(() => {
                    setDirty(false)
                    _goToUserList()
                  })
                  .finally(() => setLoading(false))
              }}
            />
          }
          buttonSecondary={
            <Button
              value="Cancel"
              secondaryGray
              onClick={(e) => {
                e.preventDefault()
                setShowModal(false)
              }}
            />
          }
        >
          A user with the email address <strong>{user.email}</strong> already
          exists but was deactivated. Do you want to reactivate the account?
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          contentSeparator
          rightAlignButtons
          opened={showDeleteModal}
          button={
            <Button
              green
              value="Confirm"
              onClick={(ev) => onDelete(ev)}
              disabled={loading}
            />
          }
          buttonSecondary={
            <Button
              value="Cancel"
              disabled={loading}
              onClick={() => setShowDeleteModal(false)}
              secondaryGray
            />
          }
          heading="Archive user"
          className="alert-categories__modal"
        >
          <p>Please confirm you want to archive this user:</p>
          <p>
            <b>
              {user.name} - {user.email}
            </b>
          </p>
          <p>Note: an archived user and their data can be restored.</p>
        </Modal>
      )}
      {showPermissionUncheckModal && (
        <Modal
          opened={showPermissionUncheckModal}
          button={
            <Button
              value="Confirm"
              onClick={() => {
                setUser({
                  permissions: null,
                })
                setDirty(true)
                setShowPermissionUncheckModal(false)
              }}
              disabled={loading}
            />
          }
          buttonSecondary={
            <Button
              value="Cancel"
              disabled={loading}
              onClick={() => setShowPermissionUncheckModal(false)}
              secondaryGray
            />
          }
          heading="Warning"
          className="alert-categories__modal"
        >
          {`By unchecking this box, the permission chart will return to the default
        Permissions given by the user's Permission Group (if any).`}
        </Modal>
      )}
      <StickyFooter
        buttons={[
          {
            value: 'Save',
            onClick: onSave,
            renderCondition: user.deleted
              ? false
              : isNew
              ? createAccess
              : editAccess,
          },
          {
            value: 'Restore',
            renderCondition: user.deleted,
            onClick: triggerRestore,
          },
          {
            value: user.deleted ? 'Back' : 'Cancel',
            onClick: () => navigate('/users'),
            secondaryGray: true,
          },
          {
            value: 'Archive',
            onClick: () => setShowDeleteModal(true),
            renderCondition:
              !user.deleted && !isNew && userData.isSuperAdmin && deleteAccess,
            type: 'secondaryRed',
          },
        ]}
      >
        {!!user.permissions && !user.isSuperAdmin && <PermissionsLegend />}
      </StickyFooter>
    </div>
  )
}

/**
 * Display editable user form for Create / Edit
 * @param {Object} params React Params
 * @param {Boolean} params.isNew Flag specifying if it's create mode
 * @param {Object} params.user User data
 * @param {Object} params.permissionsRef Ref for permissions section
 * @param {Object} [params.errors] Errors
 * @param {Function} params.setUser Called with user changes
 * @param {Function} params.setDirty Called on unsaved changes
 * @param {Boolean} params.isAdmin If current user is admin
 * @param {Boolean} params.loading If form is loading
 * @param {Boolean} params.hasPermissionGroupReadAccess Whether the user has permission to view Permission Groups
 * @param {Boolean} params.hasTeamsReadAccess
 * @param {Array} [params.permissionGroups] Saved permission group list
 * @param {Array} [params.teams] Team list
 * @param {Function} params.restoreUser Function to restore the user
 */
const UserForm = ({
  isNew,
  user,
  setUser,
  permissionsRef,
  setDirty,
  errors = {},
  setErrors,
  isAdmin,
  permissionGroups = [],
  loading,
  teams = [],
  hasPermissionGroupReadAccess,
  hasTeamsReadAccess,
  restoreUser,
}) => {
  const tableContainer = TABLE_CONTAINER.USER_MANAGEMENT

  const navigate = useNavigate()

  const { dispatch, state } = useStore()
  const filterSort = state.tableFilterSort.filterSort[tableContainer]

  const [showPermissionsLink, setShowPermissionsLink] = useState(false)
  const [restoreInfo, setRestoreInfo] = useState(null)

  /** Edit a field */
  const editField = (fieldName, value) => {
    setDirty(true)
    setUser({ [fieldName]: value })
    setErrors({ ...errors, [fieldName]: null })
  }

  useEffect(() => {
    setShowPermissionsLink(!!permissionsRef.current && !user.isSuperAdmin)
  }, [permissionsRef.current, user.isSuperAdmin])

  const globalTeamConfig = teams
    ?.filter((team) => team.isGlobal)
    .map((team) => ({ value: team._id, label: team.name }))
  const clientTeams = teams
    ?.filter((team) =>
      user.clients?.some(
        (client) => client.clientId === team.companies?.[0]?._id
      )
    )
    .reduce((object, team) => {
      const newObject = { ...object }

      if (!newObject[team.companies[0].name]) {
        newObject[team.companies[0].name] = []
      }
      newObject[team.companies[0].name].push(team)
      return newObject
    }, {})

  const className = cx('form', {
    'form--loading': loading,
  })

  const clearErrorOnField = (field) => {
    if (errors[field]) {
      setErrors({ ...errors, [field]: null })
    }
  }

  const triggerRestoreValidation = () => {
    if (!user.email || !isNew) {
      return
    }
    checkForRestore(user.email)
      .then((restoreInfo) => {
        if (restoreInfo.canRestore) {
          setErrors({ email: true })
          setRestoreInfo(restoreInfo)
          return
        }
        clearErrorOnField('email')
        if (restoreInfo) {
          setRestoreInfo(null)
        }
      })
      .catch(console.error)
  }

  const closeRestoreNotification = () => setRestoreInfo(null)

  const handleRestore = () => {
    return new Promise((resolve) => {
      restoreUser(restoreInfo.existingUser._id).then((userId) => {
        resolve()
        closeRestoreNotification()
        navigate(`/users/${userId}`, { replace: true })
      })
    })
  }

  const cancelRestorePrompt = () => {
    setUser({ ...USER_DEFAULT })
    clearErrorOnField('email')
    closeRestoreNotification()
  }

  const seeArchivedUsers = async () => {
    return new Promise((resolve) => {
      setDirty(false)
      const newFilters = {
        ...filterSort.filter,
        status: ENTITY_STATUS_FOR_FILTERS.ARCHIVED,
      }
      saveFilter(dispatch, tableContainer, newFilters)
      closeRestoreNotification()
      setTimeout(() => {
        navigate('/users')
      }, 100)
      resolve()
    })
  }

  return (
    <form className={className}>
      <RestoreNotification
        visible={!!restoreInfo}
        restoreInfo={restoreInfo}
        onCancel={cancelRestorePrompt}
        onClose={closeRestoreNotification}
        onRestore={handleRestore}
        onArchivedFilter={seeArchivedUsers}
      />
      {errors.general ? (
        <div className="error error--general">{errors.general}</div>
      ) : null}

      <section className="form__section">
        <div
          className="form__section__body"
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          <div className="form__half">
            <div className="form__row">
              <InputText
                placeholder="First Name"
                label="First Name"
                value={user.firstName}
                onChange={editField.bind(null, 'firstName')}
                error={errors.firstName}
                className="form__half input-wrapper--uppercase"
              />
              <InputText
                placeholder="Last Name"
                label="Last Name"
                value={user.lastName}
                onChange={editField.bind(null, 'lastName')}
                error={errors.lastName}
                className="form__half input-wrapper--uppercase"
              />
            </div>
            <div className="form__row">
              <InputText
                placeholder="Work Email"
                label="Work Email"
                type="email"
                value={user.email}
                onChange={editField.bind(null, 'email')}
                blur={triggerRestoreValidation}
                error={errors.email}
                className="input-wrapper--uppercase"
              />
            </div>
            <div className="form__row">
              <InputText
                placeholder="Mobile Phone"
                type="phone"
                label="Mobile Phone"
                value={user.phone}
                onChange={editField.bind(null, 'phone')}
                error={errors.phone}
                className="form__half input-wrapper--uppercase"
              />
              <InputText
                placeholder="Alternate Phone"
                type="phone"
                label="Alternate Phone"
                value={user.alternatePhone}
                onChange={editField.bind(null, 'alternatePhone')}
                error={errors.alternatePhone}
                className="form__half input-wrapper--uppercase"
              />
            </div>
            {hasPermissionGroupReadAccess && (
              <Dropdown
                defaultOptionText="Permission Group"
                label={
                  <>
                    <span>Permission Group</span>
                    <div
                      className={cx('users__permission-trigger', {
                        'users__permission-trigger--disabled':
                          !showPermissionsLink,
                      })}
                      onClick={() => {
                        showPermissionsLink && permissionsRef.current.focus()
                      }}
                    >
                      Modify User Permissions
                    </div>
                  </>
                }
                options={(permissionGroups || []).map((pg) => ({
                  value: pg._id,
                  label: pg.name,
                }))}
                disabled={user.isSuperAdmin}
                className="form__row input-wrapper--uppercase"
                defaultState={user.isSuperAdmin ? null : user.permissionGroup}
                error={errors.permissionGroup}
                onChange={(permissionGroup) => {
                  setUser({
                    permissionGroup,
                    permissions: permissionGroup ? null : {},
                  })
                  setErrors({ ...errors, permissionGroup: null })
                  setDirty(true)
                }}
              />
            )}
            {isAdmin && (
              <div className="form__row">
                <CheckboxNoHooks
                  className="users__super-admin"
                  label="Super Admin"
                  onChange={(isSuperAdmin) =>
                    editField('isSuperAdmin', !user.isSuperAdmin)
                  }
                  isChecked={user.isSuperAdmin}
                />
              </div>
            )}
          </div>

          <div className="form__half">
            <Dropdown
              defaultOptionText="Status"
              label="Status"
              options={ENTITY_STATUS_OPTIONS}
              defaultState={user.active}
              className="form__row input-wrapper--uppercase"
              error={errors.active}
              onChange={(active) => editField('active', active)}
            />
            <InputText
              placeholder="Job Title"
              label="Job Title"
              value={user.jobTitle}
              onChange={editField.bind(null, 'jobTitle')}
              className="form__row input-wrapper--uppercase"
            />
            <Dropdown
              defaultOptionText="Timezone"
              label="Timezone"
              options={TIMEZONES}
              defaultState={user.timeZone}
              className="form__row input-wrapper--uppercase"
              error={errors.timeZone}
              onChange={(timeZone) => editField('timeZone', timeZone)}
            />

            {hasTeamsReadAccess && (
              <DropdownWithSubsections
                className="form__row input-wrapper--uppercase"
                label="Teams"
                selectedItems={(user.teams || []).map(
                  (team) => team._id || team
                )}
                defaultOptionText="Select Teams"
                showOptionsInPlaceholder={true}
                options={[
                  {
                    label: 'Global',
                    value: 'global',
                    noCheckbox: true,
                    disabled: true,
                    subsections: globalTeamConfig,
                  },
                  ...Object.entries(clientTeams || {}).map(
                    ([clientName, teams]) => ({
                      label: clientName,
                      value: clientName,
                      noCheckbox: true,
                      disabled: true,
                      subsections: teams.map((team) => ({
                        label: team.name,
                        value: team._id,
                      })),
                    })
                  ),
                ]}
                onChange={(teams) => editField('teams', teams)}
              />
            )}
          </div>
        </div>
      </section>
    </form>
  )
}

/**
 * Display editable user form for Create / Edit
 * @param {Object} params React Params
 * @param {Object} params.user User data
 * @param {Array} [params.permissionGroups] Saved permission group list
 * @param {Array} [params.teams] Team list from reducer
 */
const UserView = ({ user, permissionGroups = [], teams = [] }) => {
  return (
    <div className="user-data-view">
      <section className="form__section">
        <div
          className="form__section__body"
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          <div className="form__section__body__half-width-section">
            <div className="align-row margin-bottom-22">
              <LabelWrapper className="form__half" label="First Name">
                {user.firstName}
              </LabelWrapper>
              <LabelWrapper className="form__half" label="Last Name">
                {user.lastName}
              </LabelWrapper>
            </div>
            <LabelWrapper className="margin-bottom-22" label="Work Email">
              {user.email}
            </LabelWrapper>
            <div className="align-row margin-bottom-22">
              <LabelWrapper className="form__half" label="Mobile Phone">
                {user.phone}
              </LabelWrapper>
              <LabelWrapper className="form__half" label="Alternate Phone">
                {user.alternatePhone}
              </LabelWrapper>
            </div>
            <LabelWrapper label="Permission Group">
              {
                (permissionGroups || []).find(
                  (pg) => pg._id === user.permissionGroup
                )?.name
              }
            </LabelWrapper>
          </div>
          <div className="form__section__body__half-width-section">
            <LabelWrapper className="margin-bottom-22" label="Status">
              {ENTITY_STATUS_LABEL[user.active]}
            </LabelWrapper>
            <LabelWrapper className="margin-bottom-22" label="Job Title">
              {user.jobTitle}
            </LabelWrapper>
            <LabelWrapper className="margin-bottom-22" label="Time Zone">
              {
                TIMEZONES.find((timezone) => timezone.value === user.timeZone)
                  ?.label
              }
            </LabelWrapper>
            <LabelWrapper className="margin-bottom-22" label="Teams">
              {(user.teams || []).map((team) => (
                <span className="enumeration" key={team._id}>
                  {team.name}
                </span>
              ))}
            </LabelWrapper>
          </div>
        </div>
      </section>
    </div>
  )
}

CreateEditUser.propTypes = {
  userId: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
}

UserForm.propTypes = {
  isNew: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  permissionsRef: PropTypes.object.isRequired,
  setDirty: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  permissionGroups: PropTypes.array,
  loading: PropTypes.bool,
  teams: PropTypes.array,
  hasPermissionGroupReadAccess: PropTypes.bool,
  hasTeamsReadAccess: PropTypes.bool,
  restoreUser: PropTypes.func.isRequired,
}

UserView.propTypes = {
  user: PropTypes.object.isRequired,
  permissionGroups: PropTypes.array,
  teams: PropTypes.array,
}

/**
 * Necessary validation for specific fields.
 * `permissionGroup` is conditionally validated based on the `user.isSuperAdmin` flag
 */
const ERROR_MAP = {
  firstName: FIELD_TYPES.REQUIRED,
  lastName: FIELD_TYPES.REQUIRED,
  email: [FIELD_TYPES.REQUIRED, FIELD_TYPES.EMAIL],
  phone: [FIELD_TYPES.REQUIRED, FIELD_TYPES.PHONE],
  alternatePhone: [FIELD_TYPES.PHONE],
  active: FIELD_TYPES.REQUIRED,
  timeZone: FIELD_TYPES.REQUIRED,
}

export default React.memo(CreateEditUser, (prevProps, props) => {
  return prevProps.userId === props.userId
})
