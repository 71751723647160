import Api from 'easy-fetch-api'

/**
 * Saves an alert category (updates if _id exists)
 * @param {Object} data Alert Category data to save
 * @returns {Promise}
 */
export const saveAlertCategory = (data) => {
  return new Promise((resolve, reject) => {
    const payload = { url: `/api/alerts/category`, data }
    const promise = data._id ? Api.put(payload) : Api.post(payload)
    promise
      .then((res) => {
        if (res.success) {
          resolve()
        } else {
          reject(res.error || 'Something went wrong')
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * Deletes an alert category
 * @param {String} _id id to delete
 * @returns {Promise}
 */
export const deleteAlertCategory = (_id) => {
  return new Promise((resolve, reject) => {
    Api.delete({ url: `/api/alerts/category/${_id}` })
      .then((res) => {
        if (res.success) {
          resolve()
        } else {
          reject(res.error || 'Something went wrong')
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}
