import { ACTIONS } from './actions'
const name = 'microsoft'

const initialState = {
  campaigns: { empty: true },
  user: { empty: true },
  selectedCampaign: null,
}

const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.SET_ACCOUNTS]: (state, { user }) => {
    return { ...state, user }
  },
  [ACTIONS.SET_CAMPAIGNS]: (state, { campaigns }) => {
    return { ...state, campaigns }
  },
  [ACTIONS.SET_SELECTED_CAMPAIGN]: (state, { selectedCampaign }) => {
    return { ...state, selectedCampaign }
  },
}

export default { name, reduce, initialState }
