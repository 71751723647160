import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Input from 'components/input'
import Button from 'components/button'
import { CheckboxNoHooks } from 'components/checkbox'
import { Dropdown } from 'components/dropdown'

/**
 * Recursive Data Container for google api
 */
const GoogleDataContainer = ({ data, config, setData, objectKey }) => {
  const { name, type, fields, enums, action_type } = config ? config : {}
  const [edit, setEdit] = useState(false)
  const dropDownOptions =
    enums &&
    Object.entries(enums).map(([key, value]) => {
      return {
        label: value,
        value: key,
      }
    })

  const newSetData = (value, key) => {
    const newData = {}
    if (type === 'enum') {
      setData(parseInt(value), objectKey)
    }
    if (type === 'object') {
      newData[key] = value
      setData(newData, objectKey)
    } else {
      setData(value, objectKey)
    }
  }
  const handleData = () => {
    switch (type) {
      case 'string': {
        return edit ? (
          <div className="fields__input">
            <Input
              value={data}
              onChange={(value) => {
                newSetData(value, objectKey)
              }}
            />
          </div>
        ) : (
          <span>{data}</span>
        )
      }
      case 'boolean': {
        return (
          <span>
            <CheckboxNoHooks
              defaultValue={data}
              disabled={!edit}
              onChange={(e) => {
                newSetData(e, objectKey)
              }}
            />
          </span>
        )
      }
      case 'enum': {
        return edit ? (
          <span>
            <Dropdown
              label=""
              defaultState={enums ? data : ''}
              options={dropDownOptions}
              onChange={(value) => {
                newSetData(value, objectKey)
              }}
            />
          </span>
        ) : (
          <span>{config.enums[data]}</span>
        )
      }
      case 'array': {
        return <span>[{data.toString()}]</span>
      }
      case 'object_array': {
        return (
          <div className="object">
            [
            {data.map((array_object, index) => {
              return (
                <div key={index}>
                  {Object.entries(array_object).map(([key, field]) => {
                    return (
                      <div key={key}>
                        <GoogleDataContainer
                          objectKey={key}
                          data={field}
                          config={config.array_fields[key]}
                          setData={newSetData}
                        />
                      </div>
                    )
                  })}
                </div>
              )
            })}
            ]
          </div>
        )
      }
      case 'object': {
        return (
          <div className="object">
            {Object.entries(data).map(([key, field]) => {
              if (!fields[key]) {
                return <React.Fragment key={key} />
              }
              const className = fields
                ? fields[key].type !== 'object'
                  ? 'object__field'
                  : ''
                : ''
              return (
                <div className={className} key={key}>
                  <GoogleDataContainer
                    objectKey={key}
                    data={field}
                    config={fields[key]}
                    setData={newSetData}
                  />
                </div>
              )
            })}
          </div>
        )
      }
      default:
        return <></>
    }
  }
  return (
    <>
      <div className="object__field__name">{name}</div> {` `}:{` `}{' '}
      {handleData()}
      {['output', 'input_once'].indexOf(action_type) === -1 &&
        ['object', 'object_array', 'campaign_budget', 'array'].indexOf(type) ===
          -1 && (
          <Button
            className="fields__button"
            onClick={() => setEdit(!edit)}
            secondaryGray={!edit}
            value={edit ? 'Cancel' : 'Edit'}
          />
        )}
    </>
  )
}

GoogleDataContainer.propTypes = {
  data: PropTypes.any.isRequired,
  config: PropTypes.object,
  setData: PropTypes.func,
  objectKey: PropTypes.string,
}

export default GoogleDataContainer
