import { userNotifications } from '@decision-sciences/qontrol-common'

const { NOTIFICATION_TYPES } = userNotifications

const {
  BRIEF_APPROVED,
  BRIEF_SENT_BACK,
  BRIEF_READY_FOR_APPROVAL,
  BRIEF_PUBLISHED,
  BUILD_FAILED,
  COMMENT,
  ALERT_TRIGGER,
  PASSWORD_UPDATE,
  E2E_ALERT_RESULT,
  PARTIALLY_FAILED,
} = NOTIFICATION_TYPES

/**
 * Type of notifications
 * @property {String} tag Tag of notification
 * @property {String} color color of the tag
 * @property {String} title title of notification
 * @property {String} link link 'Details' leads to
 * @property {Node} body HTML code of notification body
 */
export const NOTIFICATION_TYPES_CONFIG = {
  [BRIEF_APPROVED]: {
    tag: 'Approved',
    color: '#6FCF97',
    title: 'Brief Approved',
    link: (briefId) => `briefs/${briefId}/final-review`,
    body: (briefName) => (
      <p className="notification-item__info__brief">{briefName}</p>
    ),
  },
  [BRIEF_SENT_BACK]: {
    tag: 'Sent back',
    color: '#FBB679',
    title: 'Brief Sent Back',
    link: (briefId) => `briefs/${briefId}/final-review`,
    body: (briefName) => (
      <p className="notification-item__info__brief">{briefName}</p>
    ),
  },
  [BRIEF_READY_FOR_APPROVAL]: {
    tag: 'Ready',
    color: '#00C1D5',
    title: 'Brief Ready for Approval',
    link: (briefId) => `briefs/${briefId}/final-review`,
    body: (briefName) => (
      <p className="notification-item__info__brief">{briefName}</p>
    ),
  },
  [BRIEF_PUBLISHED]: {
    tag: 'Build published',
    color: '#5DC88A',
    title: 'Brief Published',
    link: (briefId) => `briefs/${briefId}`,
    body: (briefName) => (
      <p className="notification-item__info__brief">{briefName}</p>
    ),
  },
  [BUILD_FAILED]: {
    tag: 'Build failed',
    color: '#E85E5E',
    title: 'Build failure',
    link: (briefId) => `briefs/${briefId}`,
    body: (briefName) => (
      <p className="notification-item__info__brief">{briefName}</p>
    ),
  },
  [PARTIALLY_FAILED]: {
    tag: 'Partial Build Failure',
    color: '#E85E5E',
    inverted: true,
    title: 'Partial Build Failure',
    link: (briefId) => `briefs/${briefId}`,
    body: (briefName) => (
      <p className="notification-item__info__brief">{briefName}</p>
    ),
  },
  [COMMENT]: {
    tag: 'Comment',
    color: '#969898',
    title: (userFullName) => `New Comment from ${userFullName}`,
    link: (briefId) => `briefs/${briefId}/final-review`,
    body: (comment, briefName) => (
      <>
        <p className="notification-item__info__comment">{comment}</p>
        <p className="notification-item__info__brief">{briefName}</p>
      </>
    ),
  },
  [ALERT_TRIGGER]: {
    tag: 'Active',
    color: '#E85E5E',
    link: (alertTriggerId) => `/alert-triggers/${alertTriggerId}`,
    body: (entities) => (
      <p className="notification-item__info__trigger">
        Triggered by:{' '}
        <span className="notification-item__info__trigger-data">
          {entities}
        </span>
      </p>
    ),
  },
  [PASSWORD_UPDATE]: {
    tag: 'System',
    color: '#bccbcc',
    title: '',
  },
  [E2E_ALERT_RESULT]: {
    tag: 'Test',
    color: '#E85E5E',
    link: `/e2e-alert-executor`,
    title: (alertName, accountId, success) =>
      `Test ${
        success ? 'finished successfully' : 'failed'
      } for Alert: ${alertName}, Account: ${accountId}`,
  },
}

export const getTitle = (
  type,
  name,
  userFullName,
  alertName,
  accountId,
  success
) => {
  switch (type) {
    case BRIEF_APPROVED:
    case BRIEF_SENT_BACK:
    case BRIEF_PUBLISHED:
    case BUILD_FAILED:
    case BRIEF_READY_FOR_APPROVAL:
    case PARTIALLY_FAILED:
      return NOTIFICATION_TYPES_CONFIG[type].title

    case COMMENT:
      return NOTIFICATION_TYPES_CONFIG[type].title(userFullName)

    case ALERT_TRIGGER:
    case PASSWORD_UPDATE:
      return name

    case E2E_ALERT_RESULT:
      return NOTIFICATION_TYPES_CONFIG[type].title(
        alertName,
        accountId,
        success
      )

    default:
      return null
  }
}

export const getBody = (type, triggeredBy, comment, briefName) => {
  switch (type) {
    case BRIEF_APPROVED:
    case BRIEF_SENT_BACK:
    case BRIEF_PUBLISHED:
    case BUILD_FAILED:
    case BRIEF_READY_FOR_APPROVAL:
    case PARTIALLY_FAILED:
      return NOTIFICATION_TYPES_CONFIG[type].body(briefName)

    case COMMENT:
      return NOTIFICATION_TYPES_CONFIG[type].body(comment, briefName)

    case ALERT_TRIGGER:
      return NOTIFICATION_TYPES_CONFIG[type].body(triggeredBy)

    default:
      return null
  }
}

export const getRedirectUrl = (type, briefId, alertTriggerId) => {
  switch (type) {
    case BRIEF_APPROVED:
    case BRIEF_SENT_BACK:
    case BRIEF_PUBLISHED:
    case BUILD_FAILED:
    case BRIEF_READY_FOR_APPROVAL:
    case COMMENT:
    case PARTIALLY_FAILED:
      return NOTIFICATION_TYPES_CONFIG[type].link(briefId)

    case ALERT_TRIGGER:
      return NOTIFICATION_TYPES_CONFIG[type].link(alertTriggerId)

    case E2E_ALERT_RESULT:
      return NOTIFICATION_TYPES_CONFIG[type].link

    default:
      return null
  }
}

