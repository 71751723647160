import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import './styles.scss'

export default function Overlay({ expanded, className = '', ...other }) {
  useEffect(() => {
    if (expanded) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [expanded])

  // always remove body class on un-mounting
  useEffect(() => {
    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [])

  return (
    <div
      data-testid="overlay"
      className={cx('overlay', className, { 'overlay--expanded': expanded })}
      {...other}
    />
  )
}

Overlay.propTypes = {
  expanded: PropTypes.bool,
  className: PropTypes.string,
}
