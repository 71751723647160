import Button from 'components/button/index'
import cx from 'classnames'
import { CheckboxNoHooks } from 'components/checkbox/index'
import { Dropdown } from 'components/dropdown'
import InputText from 'components/input'
import useLeaveConfirm from 'components/leave-confirm/index'
import Loader from 'components/loader/index'
import Modal from 'components/modal/index'
import { RichTextEditor } from 'components/rich-text-editor/index'
import StickyFooter from 'components/sticky-footer/index'
import { FIELD_TYPES, validate } from 'components/validator'
import { PERMISSIONS, PERMISSION_TYPES, useAccess } from 'hooks/access'
import { showSuccessMessage } from 'modules/notifications/actions'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'store'
import {
  createUpdateLegalSection,
  deleteLegalSection,
  getLegalItemById,
  setCurrentEditedLegalSection,
  updateCurrentEditedLegal,
  updateCurrentEditedLegalSection,
} from './actions'
import './styles.scss'

/**
 * @param {{legalId: string, sectionId: string}} props Component props
 * @returns {React.Component}
 */
const CreateEditLegalSection = ({ legalId, sectionId }) => {
  const navigate = useNavigate()

  const { state, dispatch } = useStore()

  const {
    legal: {
      currentEditedLegal: legalItem,
      currentEditedLegalSection: legalSection,
    },
  } = state

  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.LEGAL_SETTINGS,
    type: PERMISSION_TYPES.DELETE,
  })

  const [loading, setLoading] = useState(true)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [errors, setErrors] = useState({})

  const [setDirty, LeaveConfirm] = useLeaveConfirm({})

  /**
   * For the following two `useEffect` hooks
   * On component mount or IDs change, check
   *  1.  If the currently edited legal item is set
   *  1.1 If not, fetch it now
   *  1.2 If set, check if id's match and if they do not, fetch the legal item
   *  2.  Check if the currently edited legal section is set
   *  2.1 If not, check it if it a create operation
   *  2.2 If is create, set it to the default value
   *  2.3 If is update, search for it in the currently edited legal items's sections
   */
  useEffect(() => {
    if (legalItem) {
      const { _id } = legalItem
      if (legalId !== 'new' && _id !== legalId) {
        getLegalItemById(dispatch, _id)
      }
    } else {
      getLegalItemById(dispatch, legalId)
    }
  }, [legalId])

  useEffect(() => {
    if (legalItem) {
      if (sectionId === 'new') {
        setCurrentEditedLegalSection(dispatch, {
          id: null,
          name: null,
          display: false,
          content: null,
          isNew: true,
          onTop: false,
        })
      } else {
        const legalSection = legalItem.sections.find(
          ({ _id }) => _id === sectionId
        )
        setCurrentEditedLegalSection(dispatch, legalSection)
      }
    }
  }, [sectionId, legalItem])

  /* Enable page only after all data is available */
  useEffect(() => {
    setLoading(!legalItem || !legalSection)
  }, [legalItem, legalSection])

  /* Helper function to edit a field in the legal section */
  const editLegalSection = (fieldName, value) => {
    setErrors({ ...errors, [fieldName]: undefined })
    setDirty(true)
    updateCurrentEditedLegalSection(dispatch, {
      ...legalSection,
      lastModified: new Date(),
      [fieldName]: value,
    })
  }

  const onSave = () => {
    setLoading(true)

    // In order to be able to validate for missing content, we need to temporarily strip all html tags
    const contentToValidate = legalSection.content.replace(/<[^>]+>/g, '')

    const [isValid, errors] = validate(ERROR_MAP, {
      ...legalSection,
      content: contentToValidate,
    })

    if (!isValid) {
      setLoading(false)
      return setErrors(errors)
    }

    createUpdateLegalSection(dispatch, legalSection, legalId)
      .then((data) => {
        const sections = legalItem.sections || []
        const existingSectionIndex = sections.findIndex(
          (section) => section._id === data._id
        )
        if (existingSectionIndex !== -1) {
          sections.splice(existingSectionIndex, 1)
        }

        legalSection.onTop ? sections.unshift(data) : sections.push(data)
        updateCurrentEditedLegal(dispatch, {
          ...legalItem,
          sections,
        })
      })
      .finally(() => {
        showSuccessMessage(
          `Legal section ${legalSection.isNew ? 'created' : 'updated'}`,
          dispatch
        )
        setLoading(false)
        setDirty(false)
        navigate(`/legal/cms/${legalId}`)
      })
  }

  /* Handler for canceling the current edit or create operation */
  const onCancel = () => {
    navigate(`/legal/cms/${legalItem._id}`)
  }

  /* Handler for deletion of a legal section */
  const onDelete = () => {
    deleteLegalSection(dispatch, legalSection._id).finally(() => {
      navigate(`/legal/cms/${legalItem._id}`)
    })
  }

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <form className="form">
        <section className="align-column form__section__body">
          <div className="align-row">
            <div className="form__section__body__half-width-section">
              <InputText
                placeholder={'Enter Section Name'}
                label="Section Name"
                value={legalSection.name || ''}
                onChange={(value) => editLegalSection('name', value)}
                error={errors.name}
                className="margin-bottom-22 input-wrapper--uppercase"
                disabled={loading}
              />
            </div>

            <div className="form__section__body__half-width-section">
              <Dropdown
                label="Display"
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
                className="margin-bottom-22 input-wrapper--uppercase"
                defaultState={legalSection.display}
                disabled={loading}
                error={errors.display}
                onChange={(value) => editLegalSection('display', value)}
              />
            </div>
          </div>

          <div className="form__section__body__half-width-section">
            <CheckboxNoHooks
              reversed
              disabled={legalSection.display === false}
              label={'Move to the top of the list'}
              defaultValue={legalSection.onTop}
              onChange={(checked) => editLegalSection('onTop', checked)}
            />
          </div>
        </section>

        <section className="align-column form__section__body">
          <div
            className={cx('general-label', {
              'general-label--error': errors.content,
            })}
          >
            Content
          </div>
          <RichTextEditor
            content={legalSection.content || ''}
            onChange={(content) => editLegalSection('content', content)}
            error={errors.content}
          />
        </section>
      </form>

      <StickyFooter
        buttons={[
          {
            value: legalSection.isNew ? 'Save Section' : 'Save Changes',
            onClick: onSave,
          },
          {
            value: 'Cancel',
            onClick: onCancel,
            secondaryGray: true,
          },
          {
            value: 'Delete Section',
            onClick: () => setDeleteModalOpen(true),
            type: 'secondaryRed',
            renderCondition:
              !legalSection.isNew && sectionId !== 'new' && hasDeleteAccess,
          },
        ]}
      />

      <LeaveConfirm />

      {deleteModalOpen && (
        <Modal
          opened={deleteModalOpen}
          button={
            <Button
              disabled={loading}
              value="Delete"
              onClick={() => onDelete()}
            />
          }
          buttonSecondary={
            <Button
              disabled={loading}
              value="Cancel"
              onClick={() => setDeleteModalOpen(false)}
              secondaryGray
            />
          }
          heading="Are you sure you want to delete this legal section?"
          className="alert-categories__modal"
        />
      )}
    </>
  )
}

const ERROR_MAP = {
  name: FIELD_TYPES.REQUIRED,
  content: FIELD_TYPES.REQUIRED,
}

CreateEditLegalSection.propTypes = {
  legalId: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
}

export { CreateEditLegalSection }
