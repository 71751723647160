import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import EntitySelectorFilters, {
  FILTER_TYPE,
} from 'components/entity-selector/filters'
import EntitySelectorActions from 'components/entity-selector/action-bar'
import LineDivider from 'components/line-divider/index'
import Button from 'components/button/index'

// Icons
import { ReactComponent as ClearIcon } from 'assets/icon_clear_red.svg'

// Utils
import { getFilterOptions } from './utils'

/**
 * Component to filter activity logs
 * @param {Object} props Component props
 * @param {Object} props.filter Filter object
 * @param {Function} props.setFilter Function to set filters
 * @param {Function} props.onApplyFilters Function to apply selected filters
 * @param {Function} props.onClearFilters Function to clear selected filters
 * @param {Array} props.activityLogs Budget Pacing activity logs for client
 * @returns {React.FunctionComponent}
 */
const ActivityLogSelector = ({
  filter,
  setFilter,
  onApplyFilters,
  onClearFilters,
  activityLogs,
}) => {
  const [filterOptions, setFilterOptions] = useState({})

  useEffect(() => {
    if (!Object.keys(filterOptions).length) {
      const options = getFilterOptions(activityLogs)
      setFilterOptions({ ...options })
    }
  }, [])

  const filters = [
    {
      fieldKey: 'budgetSegment',
      label: 'Search',
      placeholder: 'Search',
      type: FILTER_TYPE.SEARCH,
    },
    {
      fieldKey: 'user',
      label: 'User',
      placeholder: 'Select User',
      type: FILTER_TYPE.DROPDOWN_SUBSECTIONS,
      selectAllOptions: { label: 'All Users' },
      options: filterOptions.user || [],
    },
    {
      fieldKey: 'date',
      label: 'Date of Change Range',
      fields: [
        {
          fieldKey: 'date_from',
          placeholder: 'Select Date',
          type: FILTER_TYPE.DATE,
          maxDate: filter.date_to,
        },
        {
          fieldKey: 'date_to',
          placeholder: 'Select Date',
          type: FILTER_TYPE.DATE,
          minDate: filter.date_from,
        },
      ],
      type: FILTER_TYPE.CLUSTER,
    },
    {
      fieldKey: 'dimensionName',
      label: 'Dimension',
      placeholder: 'Select Dimension',
      type: FILTER_TYPE.DROPDOWN,
      options: filterOptions.dimensionName || [],
    },
    {
      fieldKey: 'valueChanged',
      label: 'Value Changed',
      placeholder: 'Select Value Changed',
      type: FILTER_TYPE.DROPDOWN_SUBSECTIONS,
      selectAllOptions: { label: 'All Values' },
      options: filterOptions.valueChanged || [],
    },
  ]

  const handleFiltersChange = (fieldKey) => (value) => {
    const changes = { ...filter, [fieldKey]: value }
    setFilter(changes)
  }

  const clearFilters = () => {
    setFilter({})
    onClearFilters()
  }

  return (
    <>
      <EntitySelectorFilters
        filters={filters}
        selectedFilters={filter}
        onFiltersChange={handleFiltersChange}
      />
      <LineDivider />
      <EntitySelectorActions
        hasFilters
        onApplyFilters={onApplyFilters}
        actions={
          <Button
            onClick={clearFilters}
            className="clear-filters"
            value={
              <div className="align-row">
                <ClearIcon width={16} height={16} className="fill-red" />
                <div className="margin-left-10">Clear</div>
              </div>
            }
          />
        }
      />
    </>
  )
}

export default ActivityLogSelector

ActivityLogSelector.propTypes = {
  activityLogs: PropTypes.array.isRequired,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
}
