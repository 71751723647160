import Api from 'easy-fetch-api'

export const getAlertTrigger = (alertTriggerId) =>
  Api.get({
    url: `/api/alert-trigger/id/${alertTriggerId}`,
  }).catch((err) => console.error('Error getting alert', err))

export const updateAlertTrigger = (alertTriggerId, data) =>
  Api.put({
    url: `/api/alert-trigger`,
    data: {
      id: alertTriggerId,
      data,
    },
  }).catch((err) => console.error('Error updating status', err))

/**
 * Fetch Alert Triggers for a user
 *
 * @typedef {Object} NumberOfItemsToLoad
 * @property {Number[]} active tuple of [min, max] number of active items to load
 * @property {Object} acknowledged tuple of [min, max] number of acknowledged items to load
 * @property {Object} completed tuple of [min, max] number of completed items to load
 * @property {Object} loadOnlyOneType if it has a value, load only one type
 *
 * @param {Object} options options
 * @param {NumberOfItemsToLoad} options.numberOfItemsToLoad object containing number of items to load for different types
 * @param {String} options.selectedClient selected client
 * @param {Object} options.filters query filters
 * @returns {Promise}
 */
export const getAlertTriggersForUserPaginated = ({
  numberOfItemsToLoad,
  selectedClient = null,
  filters = null,
}) =>
  Api.get({
    url: `/api/alert-trigger`,
    query: { numberOfItemsToLoad, filters, selectedClient },
  }).catch((err) => console.error(`Error getting Alert Triggers`, err))

export const getAlertTriggersArchived = ({
  min,
  max,
  filters = null,
  sorting = null,
  company,
}) =>
  Api.get({
    url: `/api/alert-trigger/archived`,
    query: { min, max, filters, company, sorting },
  }).catch((err) => console.error(`Error getting Alert Triggers`, err))
