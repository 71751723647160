import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import isEqual from 'lodash.isequal'

/* Hooks */
import { useAccess, PERMISSION_TYPES, PERMISSIONS } from 'hooks/access'

/* Store & Actions */
import { useStore } from 'store'
import {
  resetFilterSort,
  saveFilter,
  saveSort,
} from 'modules/table-filter-sort/actions'

/* Components */
import Filters from 'components/filters'
import Input from 'components/input'
import { Dropdown } from 'components/dropdown'
import Button from 'components/button'
import Table from 'components/table/beta'

/* Utils */
import { format } from 'date-fns'
import { defaultFilterSortConfig } from 'modules/table-filter-sort/constants'

import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'
import { entityStatus, utils } from '@decision-sciences/qontrol-common'

import './style.scss'

const { ENTITY_STATUS_LABEL } = entityStatus
const { compareIgnoreCase } = utils.string
const { getCommonFields } = utils.object

const TeamManagementModule = ({
  isAdmin,
  onDelete,
  tableContainer,
  loading,
}) => {
  const navigate = useNavigate()
  const { dispatch, state } = useStore()
  const {
    teams: { list },
  } = state
  const filterSort = state.tableFilterSort.filterSort[tableContainer]
  const [ownersList, setOwnersList] = useState([])
  const [clientsList, setClientsList] = useState([])
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.TEAM_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.TEAM_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })
  const hasReadAccess = useAccess({
    feature: PERMISSIONS.TEAM_DATA_FORM,
    type: PERMISSION_TYPES.READ,
  })
  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.TEAM_DATA_FORM,
    type: PERMISSION_TYPES.DELETE,
  })

  useEffect(() => {
    const ownerObject = {}
    const clientObject = {}
    list.forEach((team) => {
      ownerObject[team.owner._id] = team.owner
      team.companies.forEach((company) => {
        clientObject[company._id] = company
      })
    })
    setOwnersList(Object.values(ownerObject))
    setClientsList(Object.values(clientObject))
  }, [list])

  const columns = [
    {
      isPlaceholder: true,
      id: 'actions',
      cell: (cell) => {
        if (!hasReadAccess) {
          return null
        }
        return (
          <div className="table__actions">
            <div
              className={hasEditAccess ? 'table__edit' : 'table__view'}
              onClick={() => navigate(`/team/${cell.row.original.key}`)}
            />
          </div>
        )
      },
      minSize: 50,
      maxSize: 50,
    },
    { id: 'name', Header: 'Name', accessorKey: 'name', size: 300 },
    {
      header: 'Date Added',
      id: 'createdAt',
      cell: (cell) =>
        format(new Date(cell.row.original.createdAt), 'MM/dd/yyyy'),
      accessorKey: 'createdAt',
      sortingFn: 'date',
      size: 150,
    },
    {
      header: 'Status',
      id: 'status',
      accessorFn: (row) => ENTITY_STATUS_LABEL[row.active],
      size: 80,
    },
    {
      id: 'delete',
      header: '',
      cell: (cell) =>
        hasDeleteAccess ? (
          <DeleteIcon
            className="fill-red"
            alt={'delete'}
            onClick={() => onDelete(cell.row.original)}
          />
        ) : null,
      textAlign: 'center',
      minSize: 50,
      maxSize: 50,
    },
  ]

  const sortChanged = (newSort) => {
    if (!isEqual(newSort, filterSort.sort)) {
      saveSort(dispatch, tableContainer, newSort)
    }
  }

  return (
    <div className="teams page">
      <Helmet>
        <title>Teams</title>
      </Helmet>
      <div className="heading" data-cy="page-heading">
        Teams
        {hasCreateAccess ? (
          <div className="heading__buttons">
            <Button value="Add Team" onClick={() => navigate('/team/new')} />
          </div>
        ) : null}
      </div>
      <TeamFilters
        dispatch={dispatch}
        clients={clientsList}
        owners={ownersList.sort((a, b) => compareIgnoreCase(a.name, b.name))}
        isAdmin={isAdmin}
        tableContainer={tableContainer}
        filters={filterSort.filter}
      />
      <Table
        columns={columns}
        data={list}
        tableContainer={tableContainer}
        initialState={{ sortBy: filterSort.sort }}
        onSortChanged={sortChanged}
        loading={loading}
      />
    </div>
  )
}

TeamManagementModule.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  tableContainer: PropTypes.string.isRequired,
  loading: PropTypes.bool,
}

const TeamFilters = ({
  dispatch,
  clients,
  owners,
  tableContainer,
  filters,
}) => {
  const initialFilterState =
    defaultFilterSortConfig.filterSort[tableContainer].filter
  const [state, setState] = useState()

  useEffect(() => {
    setState(filters)
  }, [filters])

  const applyFilters = () => {
    const { name, clients, owner } = state
    const query = {}
    if (name) {
      query.name = name
    }
    if (clients) {
      query.clients = clients
    }
    if (owner) {
      query.owner = owner
    }
    saveFilter(dispatch, tableContainer, query)
  }

  const clearFilters = () => {
    resetFilterSort(dispatch, tableContainer)
  }

  const clientOptionList = clients.map(({ _id, name }) => ({
    label: name,
    value: _id,
  }))

  clientOptionList.unshift({ value: 'global', label: 'Global Team' })

  const [initial, current] = getCommonFields(initialFilterState, state)

  return (
    <Filters
      onApply={applyFilters}
      onClear={clearFilters}
      disableClearOnUnmount={true}
      initialApplied={!isEqual(initial, current)}
    >
      <Input
        onChange={(value) => setState({ ...state, name: value })}
        value={state?.name || ''}
        placeholder="Name"
      />

      <Dropdown
        defaultOptionText="Clients"
        options={clientOptionList.sort((a, b) =>
          compareIgnoreCase(a.label, b.label)
        )}
        selectedItems={state?.clients || []}
        multiSelect
        selectAll
        showAsFilters
        onChange={(clients) => setState({ ...state, clients })}
      />

      <Dropdown
        defaultOptionText="Owner"
        options={owners.map(({ _id, name }) => ({
          label: name,
          value: _id,
        }))}
        defaultState={state?.owner || ''}
        deselectLabel="Owner"
        onChange={(owner) => setState({ ...state, owner })}
      />
    </Filters>
  )
}

TeamFilters.propTypes = {
  dispatch: PropTypes.func.isRequired,
  owners: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  tableContainer: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
}

export default TeamManagementModule
