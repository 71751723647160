import { utils, validator } from '@decision-sciences/qontrol-common'

const { isEmpty } = utils.object
const { isValidPassword: _isValidPassword } = validator

export const FIELD_TYPES = {
  NAME: 'name',
  PHONE: 'phone',
  EMAIL: 'email',
  REQUIRED: 'required',
  POSITIVE_NUMBER: 'number',
  ID: 'id',
  PASSWORD: 'password',
  BOOLEAN: 'boolean',
  CONFIRMED: 'confirmed,',
  ARRAY_REQUIRED: 'array_required',
  AT_LEAST_ONE: 'at_least_one',
  CLIENT_ID: 'client_id',
}

export const ERRORS = {
  required: (field) => `${field} is required`,
  REQUIRED: 'This field is required',
  INVALID_AMOUNT: 'Invalid Amount',
  PASSWORD:
    'Your password must be at least 14 characters long, contain at least one number, one uppercase and one lowercase letter, and one special character: ~`!@#$%^&*()-_+={}[]|;:”<>’./?',
  CONFIRMATION: 'You have unconfirmed items.',
  AT_LEAST_ONE: 'Please select at least one option.',
}

/**
 * Validate Phone Number
 * @param {String} phoneNumber
 * @returns {boolean}
 */
export const isValidPhoneNumber = (phoneNumber) => {
  const regex =
    /[\+\d]([0-9\s-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/

  return regex.test(phoneNumber)
}

/**
 * Validate email
 * @param {String} email
 * @returns {boolean}
 */
export const isValidEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email)
}

/**
 * Checks if a field is in valid ID format (only lowercase and _)
 * @param string {String} input
 * @return {boolean}
 */
export const isValidID = (string) => {
  const regex = /[^A-Za-z0-9_]+/g
  return !regex.test(string)
}

/**
 * Checks if a given clientId is valid.
 * A valid clientId contains only alphanumeric characters, dashes, and underscores.
 *
 * @param {string} clientId - The clientId to validate.
 * @returns {boolean}
 */
export const isValidClientId = (clientId) => {
  const regex = /^[a-zA-Z0-9-_]+$/
  return regex.test(clientId)
}

/**
 * Checks if a field is a number and if the number is positive
 * @param string {String} input
 * @return {boolean}
 */
export const isPositiveNumber = (string) => {
  try {
    const number = parseFloat(string)
    return number > 0
  } catch (e) {
    return false
  }
}

/**
 * Checks if a password is valid
 *  - at least 14 characters
 *  - at least 1 uppercase letter
 *  - at least 1 lowercase letter
 *  - at least 1 digit
 * @param string {String} input
 * @return {boolean}
 */
export const isValidPassword = (string) => {
  return _isValidPassword(string)
}

/**
 * Checks if a name  is valid (no numbers, special characters like *&_=)
 * @param string {String} input
 * @return {boolean}
 */
export const isValidName = (string) => {
  try {
    const regex = /^[a-zA-Z][0-9a-zA-Z .,'-]*$/
    return regex.test(string)
  } catch (e) {
    return false
  }
}

/**
 * Generic Validate function
 * @param fieldErrorMap {Object} Field MAP to be validated.
 * Example: { email: FIELD_TYPES.EMAIL, name: FIELD_TYPES.REQUIRED, ...etc }
 * Also supports nested items like "contact.email" : FIELD_TYPES.EMAIL
 * @param state {Object} object or state to validate against
 * @returns {*[]}
 */
export const validate = (fieldErrorMap = {}, state) => {
  let isValid = true

  // Construct initial empty error map
  let errors = {}
  Object.keys(state).forEach((fieldName) => {
    errors[fieldName] = null
  })

  // Iterate through all the required checks
  Object.keys(fieldErrorMap).forEach((fieldKey) => {
    const fieldType = fieldErrorMap[fieldKey]
    let fieldValue = state[fieldKey]
    // Case for nested field
    if (fieldKey.indexOf('.') > -1) {
      const [field, subField] = fieldKey.split('.')
      fieldValue = state[field][subField]
    }

    // Validate the field against one or more field types
    if (Array.isArray(fieldType)) {
      fieldType.some((type) => {
        ;[isValid, errors] = _checkField(
          fieldKey,
          fieldValue,
          type,
          isValid,
          errors
        )

        // If there was an error on the crt check don't go through the rest of them
        if (errors[fieldKey]) {
          return true
        }
      })
    } else {
      ;[isValid, errors] = _checkField(
        fieldKey,
        fieldValue,
        fieldType,
        isValid,
        errors
      )
    }
  })

  return [isValid, errors]
}

/**
 * Checks a field for validity
 * @param fieldKey {String} key of the field
 * @param fieldValue {String} value
 * @param type {String} type of the field (one of @FIELD_TYPES)
 * @param isValid {Boolean}
 * @param errors {Object} map for errors
 * @returns {*[]}
 * @private
 */
function _checkField(fieldKey, fieldValue, type, isValid, errors) {
  let errorFound = null

  switch (type) {
    case FIELD_TYPES.NAME:
      if (fieldValue && !isValidName(fieldValue)) {
        errorFound =
          'Invalid name format. No numbers or special characters allowed'
      }
      break

    case FIELD_TYPES.PHONE:
      if (fieldValue && !isValidPhoneNumber(fieldValue)) {
        errorFound = 'Invalid phone number format'
      }
      break

    case FIELD_TYPES.EMAIL:
      if (fieldValue && !isValidEmail(fieldValue)) {
        errorFound = 'Invalid email format'
      }
      break

    case FIELD_TYPES.REQUIRED:
      if (isEmpty(fieldValue)) {
        errorFound = ERRORS.REQUIRED
      } else if (Array.isArray(fieldValue) && fieldValue.length === 0) {
        errorFound = ERRORS.REQUIRED
      }
      break

    case FIELD_TYPES.ID:
      if (fieldValue && !isValidID(fieldValue)) {
        errorFound = 'Only letters, numbers and underscores are allowed'
      }
      break

    case FIELD_TYPES.CLIENT_ID:
      if (fieldValue && !isValidClientId(fieldValue)) {
        errorFound =
          'Only letters, numbers, dashes, and underscores are allowed'
      }
      break

    case FIELD_TYPES.POSITIVE_NUMBER:
      if (fieldValue && !isPositiveNumber(fieldValue)) {
        errorFound = 'Invalid number format'
      }
      break
    case FIELD_TYPES.PASSWORD:
      if (fieldValue && !isValidPassword(fieldValue)) {
        errorFound = ERRORS.PASSWORD
      }
      break
    case FIELD_TYPES.BOOLEAN:
      if (typeof field === 'boolean') {
        errorFound = ERRORS.REQUIRED
      }
      break
    case FIELD_TYPES.CONFIRMED:
      for (const item of fieldValue) {
        if (!item.confirmed && !item.deleted) {
          errorFound = ERRORS.CONFIRMATION
          break
        }
      }
      break
    case FIELD_TYPES.ARRAY_REQUIRED:
      if (!Array.isArray(fieldValue) || fieldValue.length === 0) {
        errorFound = ERRORS.REQUIRED
      }
      break

    case FIELD_TYPES.AT_LEAST_ONE:
      if (
        !fieldValue ||
        (Array.isArray(fieldValue) && fieldValue.length === 0)
      ) {
        errorFound = ERRORS.AT_LEAST_ONE
      }
      break
  }

  if (errorFound) {
    // Case for nested field
    if (fieldKey.indexOf('.') > -1) {
      const [field, subField] = fieldKey.split('.')
      const errorField = { ...errors[field], [subField]: errorFound }
      return [false, { ...errors, [field]: errorField }]
    }

    // Case for regular field
    return [false, { ...errors, [fieldKey]: errorFound }]
  }

  // No error found on this field
  return [isValid, errors]
}

/**
 * Extract errors for placement based on media item selected
 * @param {Object} media media object
 * @param {Object} constraints Placement constraints for a device type
 * @returns {string|null}
 */
export const getPlacementMediaErrors = (media, constraints) => {
  // Check if any warnings are necessary for placement
  const warnings = []
  if (constraints) {
    const { width, height, size } = media

    // If there are any resolution related constraints and the width / height can't be determined, show warning.
    if (
      (constraints.min_width ||
        constraints.min_height ||
        constraints.min_aspect_ratio ||
        constraints.max_aspect_ratio) &&
      (isNaN(width) || isNaN(height))
    ) {
      warnings.push(
        `Could not determine resolution of ${
          media.type || 'media file'
        }. Please review media for placement.`
      )
    } else {
      const aspectRatio = width / height

      if (constraints.min_width && width < constraints.min_width) {
        warnings.push(
          `Image width must be at least ${constraints.min_width}px.`
        )
      }

      if (constraints.min_height && height < constraints.min_height) {
        warnings.push(
          `Image height must be at least ${constraints.min_height}px.`
        )
      }

      if (
        constraints.min_aspect_ratio &&
        aspectRatio < constraints.min_aspect_ratio
      ) {
        warnings.push(
          `Aspect ratio must be at least ${constraints.min_aspect_ratio}.`
        )
      }

      if (
        constraints.max_aspect_ratio &&
        aspectRatio > constraints.max_aspect_ratio
      ) {
        warnings.push(
          `Aspect ratio must be at most ${constraints.max_aspect_ratio}.`
        )
      }
    }
    if (
      (constraints.min_duration || constraints.max_duration) &&
      !media.duration
    ) {
      warnings.push(
        'Could not determine video duration. Please review video for placement.'
      )
    } else {
      if (constraints.min_duration > media.duration) {
        warnings.push(
          `Video can't be shorter than ${constraints.min_duration} seconds.`
        )
      }
      if (constraints.max_duration < media.duration) {
        warnings.push(
          `Video can't be longer than ${constraints.max_duration} seconds.`
        )
      }
    }

    if (size && constraints.max_file_size && size > constraints.max_file_size) {
      warnings.push(
        `Your file is too big. Please find a way to reduce the filesize and try again.`
      )
    }
  }

  return warnings.length ? warnings.join('\n') : null
}

/**
 * Validate email
 * @param {String} url
 * @returns {boolean}
 */
export const isValidURL = (url) => {
  const regex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm
  return regex.test(url)
}
