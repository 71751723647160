import { ACTIONS } from './actions'
import { STORAGE_MENU_STATE } from './constants'

const localStorageJson = JSON.parse(localStorage[STORAGE_MENU_STATE] || null)

/** Name of the reducer */
const name = 'leftMenu'

/** Initial State of the reducer */
const initialState = {
  opened: false,
  selectedFixedTab: null,
  selectedSlidingTabs: [],
  selectedSlidingTab: null,
  // Override with values from local storage
  ...localStorageJson,
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.SET_LEFT_MENU]: (state, { menuState }) => {
    return { ...state, ...menuState }
  },

  [ACTIONS.EXPAND_COLLAPSIBLE_DRAWER]: (state, { collapsibleDrawerKey }) => {
    const newSelectedSLidingTabs = state.selectedSlidingTabs.map((tab) =>
      collapsibleDrawerKey === tab.key ? { ...tab, expanded: true } : tab
    )

    return {
      ...state,
      selectedSlidingTabs: newSelectedSLidingTabs,
    }
  },
}

export default { initialState, name, reduce }
