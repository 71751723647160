import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames'

import './style.scss'

const RoundRectangleButton = ({
  onClick,
  className = '',
  title,
  contentRender,
  width = 32,
  contentClassName = '',
  disabled,
  dataCy,
}) => {
  const style = {
    width: typeof width === 'number' ? `${width}px` : width,
  }

  return (
    <div
      title={title}
      style={style}
      onClick={() => onClick && onClick()}
      className={cx(`round-rectangle-button ${className}`, {
        disabled: disabled,
      })}
      data-cy={dataCy || 'round-rectangle-button'}
      data-testid="round-rectangle-button"
    >
      <div className={`round-rectangle-button__content ${contentClassName}`}>
        {contentRender && contentRender()}
      </div>
    </div>
  )
}

export default RoundRectangleButton

RoundRectangleButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  title: PropTypes.string,
  contentRender: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  dataCy: PropTypes.string,
}
