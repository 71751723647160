import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import { CheckboxNoHooks } from 'components/checkbox'

import Tooltip from 'components/tooltip'
import { AccountIcon } from 'components/account-icon'
import CheckboxSelectAll, {
  CHECKBOX_VALUES,
} from 'components/checkbox-select-all'
import Section from 'components/section/index'
import SearchInput from 'components/search-input/index'

import './style.scss'

/**
 * Searchable Checkbox Section.
 * @param {Object} params React Params
 * @param {String} params.label Header to display
 * @param {Config|Config[]} params.config Configuration(s) to display
 * @param {Boolean} params.hasSearch Whether the checkbox section is searchable
 * @param {Function} params.onClickSelectAll Callback when selecting ALL, also its existence decides whether the checkbox appears or not
 * @param {Boolean} params.allSelected Whether all items from ALL configs are selected
 * @param {Boolean} [params.showWhenEmpty = false] Flag to show the section in a disabled state even when there is no config passed
 */
const CheckboxSection = ({
  label,
  config,
  hasSearch,
  onClickSelectAll,
  allSelected,
  showWhenEmpty = false,
}) => {
  const [search, setSearch] = useState('')

  const someSelected = useMemo(() => {
    const configArray = Array.isArray(config) ? config : [config]
    return configArray.some(({ selectedItems, items }) => {
      return items.some(({ value }) => selectedItems.includes(value))
    })
  }, [JSON.stringify(config)])

  const configs = useMemo(() => {
    const configArray = Array.isArray(config) ? config : [config]
    if (!search) {
      return configArray
    }
    return configArray.reduce((configs, config) => {
      const remainingItems = config.items?.filter((item) =>
        item.label?.match(new RegExp(`${search}`, 'i'))
      )
      if (remainingItems.length) {
        return [...configs, { ...config, items: remainingItems }]
      }
      return configs
    }, [])
  }, [search, config])

  const isEmpty = !config || (Array.isArray(config) && !config.length)

  if (isEmpty && !showWhenEmpty) {
    return null
  }

  return (
    <Section
      headerClassName="searchable-section__heading"
      dataTestId="searchable-section"
      header={
        <>
          {label && <h3>{label}</h3>}
          <div className="searchable-section__heading__actions">
            {hasSearch && (
              <SearchInput
                disabled={isEmpty}
                value={search}
                onChange={setSearch}
              />
            )}
            {onClickSelectAll && (
              <CheckboxSelectAll
                disabled={isEmpty}
                label="Select All"
                value={
                  allSelected
                    ? CHECKBOX_VALUES.ALL
                    : someSelected
                    ? CHECKBOX_VALUES.SOME
                    : CHECKBOX_VALUES.NONE
                }
                isBlue
                hasBorders
                onClick={onClickSelectAll}
              />
            )}
          </div>
        </>
      }
    >
      {configs.map((config, index) => (
        <ConfigComponent key={index} config={config} disabled={isEmpty} />
      ))}
    </Section>
  )
}

/**
 * Takes a config and displays accordingly
 * @param {Object} params React params
 * @param {Config} params.config Config to display
 * @param {Config} params.disabled Global disable of the section
 */
const ConfigComponent = ({ config, disabled }) => {
  const baseNumberOfItems = config.title ? 6 : 8
  const [noOfItems, setNoOfItems] = useState(baseNumberOfItems)
  const [hoveredItem, setHoveredItem] = useState(null)

  /** List of items which are not disabled. Only these must be taken into consideration when computin and triggering "Select All" */
  const availableItems = useMemo(
    () => (config.items || []).filter((item) => !item.disabled),
    [JSON.stringify(config.items)]
  )

  const allSelected =
    availableItems.length &&
    availableItems.every((item) =>
      (config.selectedItems || []).some(
        (selectedItem) => selectedItem === item.value
      )
    )

  const someSelected = availableItems.some((item) =>
    (config.selectedItems || []).some(
      (selectedItem) => selectedItem === item.value
    )
  )

  const renderShowMore = () => {
    if (config.items.length <= baseNumberOfItems) {
      return null
    }
    return (
      <div
        onClick={() =>
          setNoOfItems(
            config.items.length > noOfItems
              ? config.items.length
              : baseNumberOfItems
          )
        }
        className="searchable-section__item__show-more"
      >
        {config.items.length > noOfItems ? '+ Show More' : '- Show Less'}
      </div>
    )
  }

  return (
    <div className="searchable-section__content__wrapper">
      <div className="searchable-section__content">
        {config.title && (
          <div className="searchable-section__content__info">
            <div className="searchable-section__content__info__title">
              {config.title}
            </div>
            {config.subtitle && (
              <div className="searchable-section__content__info__subtitle">
                {config.subtitle}
              </div>
            )}
            <CheckboxSelectAll
              disabled={!availableItems.length || disabled}
              value={
                allSelected
                  ? CHECKBOX_VALUES.ALL
                  : someSelected
                  ? CHECKBOX_VALUES.SOME
                  : CHECKBOX_VALUES.NONE
              }
              className="margin-top-15"
              label="Select All"
              isBlue
              onClick={() => {
                const newSelectedItems = new Set(config.selectedItems)
                availableItems.forEach((item) => {
                  if (allSelected) {
                    newSelectedItems.delete(item.value)
                    return
                  }
                  newSelectedItems.add(item.value)
                })
                config.onChange && config.onChange([...newSelectedItems])
              }}
            />
          </div>
        )}
        <div className="searchable-section__content__body">
          {config.items.map((item, index) => {
            if (index >= noOfItems) {
              return null
            }
            return (
              <div key={item.value} className="searchable-section__item">
                <CheckboxNoHooks
                  reversed
                  disabled={item.disabled || disabled}
                  label={
                    <span
                      onMouseEnter={() => {
                        setHoveredItem(item.value)
                      }}
                      onMouseLeave={() => {
                        setHoveredItem(null)
                      }}
                      className="searchable-section__item__label"
                    >
                      <AccountIcon accountType={item.type} />
                      {item.label}
                      <Tooltip
                        show={hoveredItem === item.value}
                        content={item.label}
                      />
                    </span>
                  }
                  isChecked={config.selectedItems?.some(
                    (selectedItem) => selectedItem === item.value
                  )}
                  onChange={() => {
                    const newSelectedItems = new Set(config.selectedItems)

                    if (!newSelectedItems.has(item.value)) {
                      newSelectedItems.add(item.value)
                    } else {
                      newSelectedItems.delete(item.value)
                    }
                    config.onChange && config.onChange([...newSelectedItems])
                  }}
                />
              </div>
            )
          })}
        </div>
      </div>
      {renderShowMore()}
    </div>
  )
}
CheckboxSection.propTypes = {
  label: PropTypes.string,
  config: PropTypes.any.isRequired,
  hasSearch: PropTypes.bool,
  onClickSelectAll: PropTypes.func,
  allSelected: PropTypes.bool,
  showWhenEmpty: PropTypes.bool,
}

ConfigComponent.propTypes = {
  label: PropTypes.string,
  config: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    items: PropTypes.array,
    selectedItems: PropTypes.array,
    onChange: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
}

export default CheckboxSection

/**
 * @typedef {Object} Config
 * @property {String} title Title displayed on the left side
 * @property {String} subtitle Subtitle displayed on the left side. Won't display without title.
 * @property {Array} items Items to display on the right side. Structure is { label, value }
 * @property {String[]} selectedItems Items that are selected on the right side. List of values that are equal to some params.config.items.$.value
 * @property {Function} onChange To be called with desired selectedItems
 */
