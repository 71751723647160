import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import PanelWithHighlightedContent from 'components/panel-with-highlighted-content/index'
import {
  containsLogicalOperator,
  containsRelationalOperator,
  getColorMap,
} from 'components/panel-with-highlighted-content/utils'

import { findNextThresholdAndEcuationParameters } from 'modules/companies/utils'

import AlertFormulaSimplifiedView from './alert-formula-simplified-view'

const DEFAULT_RIGHT_SIDE_COMPARISON_WITH_THRESHOLDS = '200'

/**
 * @param {Object} params React Params
 * @param {Boolean} [params.simulated = true] Flag to determine if it was simulation
 * @param {Object} [params.triggerValues] Actual trigger values
 * @param {String} [params.fullText] Full text to display with highlights in text area
 * @param {Array} [params.highlights] Words to be highlighted. If words contain special characters make sure to provide a pattern to match them.
 * @param {Object} [params.colorsMap] Map of words to highlight to color. If not supplied it will be calculated using base colors.
 * @param {String} [params.separator] Text separator. Used to replace new lines with. If not supplied, empty string will be used.
 * @param {Object} [params.pattern] Pattern used to split fullText into separate words. If not provided, it will match the exact words provided in highlights.
 * @param {Number} [params.hoveredWordIndex] Index of the hovered word from the wordsToDisplay Array
 * @param {Function} [params.setHoveredWordIndex] Function to set hovered word index
 * @param {Object} [params.alertThresholdValues] Object mapping alert threshold names to their values
 * @param {Boolean} [params.isSimplifiedViewMode] Flag that indicates if simplified view mode is selected
 * @returns {React.ComponentElement}
 */
const AlertFormulaSection = ({
  simulated = true,
  triggerValues,
  fullText,
  highlights,
  colorsMap,
  pattern,
  separator,
  hoveredWordIndex,
  setHoveredWordIndex,
  alertThresholdValues,
  isSimplifiedViewMode,
}) => {
  const matchPattern = new RegExp(
    pattern ? pattern : `\\b(${highlights.join('|')})\\b`
  )

  const [wordToColorsMap, setWordToColorsMap] = useState(colorsMap)

  useEffect(() => {
    if (!Object.keys(wordToColorsMap).length) {
      const newColorsMap = getColorMap(highlights)
      setWordToColorsMap(newColorsMap)
    }
  }, [colorsMap])

  const wordsToDisplay = fullText
    .replace(/\n/g, separator)
    .split(matchPattern)
    .filter((word) => word !== '' && word !== ' ')

  const thresholdRegExp = new RegExp(
    `\\[${Object.keys(alertThresholdValues).join('\\]|\\[')}\\]`,
    'g'
  )
  const metricRegExp = new RegExp(/(\[[^[\]]*\])/g)

  const [sampleDataWordsToDisplay, setSampleDataWordsToDisplay] =
    useState(wordsToDisplay)

  const _containsOnlyParenthesis = (word) => {
    const regEx = new RegExp(/^[()[\]{}]+$/)
    return regEx.test(word)
  }

  const getTriggerValue = (metric, defaultValue = '1') => {
    if (simulated) {
      return defaultValue
    }
    if (!triggerValues) {
      return undefined
    }
    return Object.entries(triggerValues).find(([key]) => {
      return `[${key}]` === metric
    })?.[1]
  }

  useEffect(() => {
    const newWordsToDisplay = [...wordsToDisplay]
    for (let idx = 0; idx <= newWordsToDisplay.length - 1; idx++) {
      if (!metricRegExp.test(newWordsToDisplay[idx])) {
        continue
      }

      const previousWord = idx >= 1 ? newWordsToDisplay[idx - 1] : null
      const nextWord =
        idx <= newWordsToDisplay.length - 2 ? newWordsToDisplay[idx + 1] : null

      // Denominator
      if (previousWord?.includes('/')) {
        newWordsToDisplay[idx] = getTriggerValue(newWordsToDisplay[idx], '100')
        continue
      }

      // Threshold
      if (thresholdRegExp.test(newWordsToDisplay[idx])) {
        newWordsToDisplay[idx] = newWordsToDisplay[idx].replace(
          thresholdRegExp,
          (matched) =>
            alertThresholdValues[matched.substring(1, matched.length - 1)]
        )
        continue
      }

      // Regular Metric
      const {
        nextThrValue,
        addToThr,
        thrSign,
        incrementSing,
        isOnRightSideOfComparison,
      } = findNextThresholdAndEcuationParameters({
        currentWordIndex: idx,
        words: newWordsToDisplay,
        alertThresholdValues,
      })
      if (!isNaN(nextThrValue)) {
        let multiplyBy = 1
        if (nextWord?.includes('/')) {
          multiplyBy = 100
        }
        if (isOnRightSideOfComparison) {
          newWordsToDisplay[idx] = getTriggerValue(
            newWordsToDisplay[idx],
            DEFAULT_RIGHT_SIDE_COMPARISON_WITH_THRESHOLDS
          )
        } else {
          newWordsToDisplay[idx] = getTriggerValue(
            newWordsToDisplay[idx],
            `${(
              (addToThr + thrSign * nextThrValue) * multiplyBy +
              incrementSing * 1
            ).toFixed(0)}`
          )
        }
        continue
      }

      // Individual Metric
      if (
        (!(previousWord && !_containsOnlyParenthesis(previousWord)) ||
          (containsLogicalOperator(previousWord) &&
            !containsRelationalOperator(previousWord))) &&
        (!(nextWord && !_containsOnlyParenthesis(nextWord)) ||
          (containsLogicalOperator(nextWord) &&
            !containsRelationalOperator(nextWord)))
      ) {
        newWordsToDisplay[idx] = getTriggerValue(newWordsToDisplay[idx])
        continue
      }
      // If none of the above that means it is a metric compared to a numeric (non threshold) value
      newWordsToDisplay[idx] = getTriggerValue(newWordsToDisplay[idx])
    }
    setSampleDataWordsToDisplay(
      newWordsToDisplay.filter((word) => word !== '' && word !== ' ')
    )
  }, [alertThresholdValues, triggerValues])

  return (
    <>
      {isSimplifiedViewMode ? (
        <AlertFormulaSimplifiedView
          highlights={highlights}
          className={'margin-bottom-16'}
          hoveredWordIndex={hoveredWordIndex}
          wordsToDisplay={wordsToDisplay}
          wordToColorsMap={wordToColorsMap}
          sampleDataWords={sampleDataWordsToDisplay}
        />
      ) : (
        <PanelWithHighlightedContent
          label={'Alert Formula'}
          highlights={highlights}
          className={'margin-bottom-16'}
          hoveredWordIndex={hoveredWordIndex}
          wordToColorsMap={wordToColorsMap}
          wordsToDisplay={wordsToDisplay}
        />
      )}
      <PanelWithHighlightedContent
        label={`Alert Formula${simulated ? ' (Sample Data)' : ''}`}
        replacedWordsToDisplay={sampleDataWordsToDisplay}
        highlights={highlights}
        className={'margin-bottom-16 sample-data'}
        hoveredWordIndex={hoveredWordIndex}
        setHoveredWordIndex={setHoveredWordIndex}
        getTooltipForHighlight={(word) => word.substring(1, word.length - 1)}
        wordToColorsMap={wordToColorsMap}
        wordsToDisplay={wordsToDisplay}
      />
    </>
  )
}

AlertFormulaSection.propTypes = {
  simulated: propTypes.bool,
  triggerValues: propTypes.object,
  fullText: propTypes.string.isRequired,
  highlights: propTypes.array,
  colorsMap: propTypes.object,
  pattern: propTypes.object,
  separator: propTypes.string,
  hoveredWordIndex: propTypes.number,
  setHoveredWordIndex: propTypes.func,
  alertThresholdValues: propTypes.object.isRequired,
  isSimplifiedViewMode: propTypes.bool.isRequired,
}

export default AlertFormulaSection
