import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

/* Hooks */
import { useSingleClientApplyTo } from 'modules/alerts/utils'

/* Components */
import Section from 'components/section'
import DropdownWithSubsections from 'components/dropdown-with-subsections/index'
import { SinglePerformanceAccountRow } from 'modules/alerts/single-performance-apply-to/single-performance-account-row'

/* Actions */
import {
  getClientCampaigns,
  getAdSets,
  getAds,
  getPlatforms,
  getDevices,
  getFormats,
} from 'modules/facebook/actions'

import {
  ACCOUNTS_TO_SELECT_ELEMENTS,
  getFacebookPlacements,
} from 'modules/alerts/constants'

import {
  alert,
  accounts,
  granularities,
  utils,
} from '@decision-sciences/qontrol-common'

import 'modules/alerts/single-performance-apply-to/style.scss'

const { FACEBOOK } = accounts.ACCOUNT_TYPES_MAP

const {
  ACCOUNT,
  CAMPAIGN,
  AD_GROUP,
  AD,
  PLATFORM,
  PLACEMENTS,
  DEVICE,
  FORMAT,
} = granularities.GRANULARITIES
const { ALERT_ELEMENT_TO_FIELD_NAME } = alert

/**
 * Single Performance Section for Facebook
 * @param {Object} params React Params
 * @param {String} params.clientId Alert Client ID
 * @param {Function} params.onChange On Change callback. Call with new state for selectedElements[platform]
 * @param {Object} params.state selectedElements[platform]
 * @param {Array} params.accounts Available Accounts for platform
 * @param {Object} params.elementConfig Array name per platform key. Eg: CAMPAIGN: campaigns
 * @param {Object} params.errors Object of errors
 * @param {Function} params.onChangeErrors Callback for changing errors
 * @param {Boolean} params.readOnly Toggle between read/edit modes
 * @returns {Node}
 */
export const SinglePerformanceFacebook = ({
  clientId,
  onChange,
  state,
  accounts,
  elementConfig,
  errors,
  onChangeErrors,
  readOnly,
}) => {
  const {
    localState,
    toggleLoading,
    getIsLoading,
    onCheck,
    onCheckAll,
    onChangeAccounts,
  } = useSingleClientApplyTo(
    FACEBOOK,
    state,
    onChange,
    onChangeErrors,
    validDataPersistenceConfig
  )

  // Data States
  const [campaigns, setCampaigns] = useState(null)
  const [adGroups, setAdGroups] = useState({})
  const [ads, setAds] = useState({})
  const [platforms, setPlatforms] = useState(null)
  const [placements, setPlacements] = useState(null)
  const [devices, setDevices] = useState(null)
  const [formats, setFormats] = useState(null)

  const adGroupField = ALERT_ELEMENT_TO_FIELD_NAME[FACEBOOK][AD_GROUP]

  const stripFacebookAccount = (account) => account.replace('act_', '')

  const availableAccounts = accounts.map(({ externalAccountId, name }) => ({
    value: stripFacebookAccount(externalAccountId),
    label: name || externalAccountId,
    description: externalAccountId,
  }))

  /* ACCOUNT section */
  const accountMap = useMemo(() => {
    return availableAccounts.reduce(
      (prev, current) => ({
        ...prev,
        [stripFacebookAccount(current.value)]: current,
      }),
      {}
    )
  }, [availableAccounts])

  const hasAccounts = state.accounts?.length || state.allAccountsSelected

  const renderAccountSection = () => {
    if (!state.elements?.includes(ACCOUNT)) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Accounts"
          selectedItems={state[ACCOUNT]?.accounts.map((account) => account.id)}
          disabled={!hasAccounts || readOnly}
          label={'Account'}
          options={
            state.allAccountsSelected
              ? availableAccounts
              : availableAccounts.filter((acc) =>
                  state.accounts?.includes(acc.value)
                )
          }
          onChange={(accounts) =>
            onCheck(ACCOUNT, {
              accounts: accounts.map((value) => ({
                name: accountMap[value].label,
                id: accountMap[value].value,
              })),
              allSelected: false,
            })
          }
          selectAllOptions={{
            label: 'All Accounts',
            allSelected: state[ACCOUNT]?.allSelected,
            onCheck: onCheckAll(ACCOUNT),
          }}
          error={errors[elementConfig[ACCOUNT]]}
        />
      </div>
    )
  }
  /* End of ACCOUNT section */

  /* CAMPAIGN Section */

  useEffect(() => {
    if (!campaigns && !getIsLoading(CAMPAIGN)) {
      toggleLoading(CAMPAIGN, true)
      getClientCampaigns(clientId)
        .then(setCampaigns)
        .catch(console.error)
        .finally(() => {
          toggleLoading(CAMPAIGN, false)
        })
    }
  }, [JSON.stringify(localState.elements)])

  const campaignsInAccounts = useMemo(() => {
    const campaignsInAccountsMap = {}

    const accountsToParse = state.allAccountsSelected
      ? availableAccounts.map(({ value }) => value)
      : state.accounts

    if (!accountsToParse || !campaigns?.length) {
      return campaignsInAccountsMap
    }

    // Turn array to map for easy access
    const accountsSet = new Set(accountsToParse)

    // Filter campaigns based on selected accounts
    campaigns.forEach((campaign) => {
      if (accountsSet.has(campaign.account_id)) {
        campaignsInAccountsMap[campaign.id] = campaign
      }
    })

    return campaignsInAccountsMap
  }, [JSON.stringify(state.accounts), state.allAccountsSelected, campaigns])

  const campaignsByAccount = utils.array.arrayKeyBy(
    Object.values(campaignsInAccounts) || [],
    'account_id'
  )

  const getCampaignOptions = (getIsDisabled) => {
    if (!campaignsByAccount) {
      return []
    }

    const options = Object.keys(campaignsByAccount).map((accountId) => ({
      subsections: campaignsByAccount[accountId].map((campaign) => ({
        value: campaign.id,
        label: campaign.name,
        disabled: getIsDisabled && getIsDisabled(campaign),
      })),
      disabled: true,
      noCheckbox: true,
      label: accountMap[accountId].label,
      value: accountId,
    }))

    return options
  }

  const renderCampaignSection = () => {
    if (!state.elements?.includes(CAMPAIGN)) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Campaigns"
          selectedItems={(state[CAMPAIGN]?.campaigns || []).map(({ id }) => id)}
          disabled={!hasAccounts || readOnly}
          label={'Campaign'}
          options={getCampaignOptions()}
          onChange={(campaigns) => {
            onCheck(CAMPAIGN, {
              campaigns: campaigns.map(
                (campaign) => campaignsInAccounts[campaign]
              ),
              allSelected: false,
            })
          }}
          selectAllOptions={{
            label: 'All Campaigns',
            allSelected: state[CAMPAIGN]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(CAMPAIGN),
          }}
          error={errors[elementConfig[CAMPAIGN]]}
        />
      </div>
    )
  }
  /* End of CAMPAIGN Section */

  /* AD_GROUP Section */

  /**
   * Fetch ad groups for a campaign
   * @param {String} resource id field from Google
   */
  const fetchAdGroups = (resource) => {
    const loaderKey = `${AD_GROUP}_${resource}`

    if (!getIsLoading(loaderKey) && !adGroups[resource]) {
      toggleLoading(loaderKey, true)
      getAdSets(clientId, null, resource)
        .then((adGroups) => {
          setAdGroups((previous) => ({
            ...(previous || {}),
            [resource]: adGroups,
          }))
        })
        .catch(console.error)
        .finally(() => {
          toggleLoading(loaderKey, false)
        })
    }
  }

  useEffect(() => {
    if (!localState[AD_GROUP]?.campaigns) {
      return
    }

    localState[AD_GROUP].campaigns.forEach((campaign) => {
      fetchAdGroups(campaign.id)
    })
  }, [localState[AD_GROUP]?.campaigns])

  const getAvailableAdGroupMap = (element) => {
    if (!element || !element.campaigns) {
      return {}
    }

    return element.campaigns.reduce(
      (prev, current) => ({
        ...prev,
        ...(adGroups[current.id] || []).reduce(
          (prev, current) => ({ ...prev, [current.id]: current }),
          {}
        ),
      }),
      {}
    )
  }

  const getAdGroupOptions = (element, getIsDisabled) => {
    if (!element || !element.campaigns) {
      return []
    }

    return element.campaigns.reduce(
      (prev, current) => [
        ...prev,
        {
          label: current.name,
          disabled: true,
          noCheckbox: true,
          subsections: (adGroups[current.id] || []).map(
            (adGroup) => ({
              label: adGroup.name,
              value: adGroup.id,
              disabled: getIsDisabled && getIsDisabled(adGroup),
            }),
            []
          ),
        },
      ],
      []
    )
  }

  const adGroupAvailableAdGroupMap = useMemo(() => {
    return getAvailableAdGroupMap(localState[AD_GROUP])
  }, [adGroups, localState[AD_GROUP]])

  const renderAdGroupSection = () => {
    if (!state.elements?.includes(AD_GROUP)) {
      return null
    }

    const hasCampaigns = state[AD_GROUP]?.campaigns?.length

    const campaignOptions = getCampaignOptions(({ id }) =>
      getIsLoading(`${AD_GROUP}_${id}`)
    )

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Campaigns"
          selectedItems={(state[AD_GROUP]?.campaigns || []).map(({ id }) => id)}
          disabled={!hasAccounts || readOnly}
          label={'Campaign'}
          options={campaignOptions}
          onChange={(campaigns) =>
            onCheck(AD_GROUP, {
              campaigns: campaigns.map(
                (campaign) => campaignsInAccounts[campaign]
              ),
            })
          }
        />
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Ad Groups"
          selectedItems={(state[AD_GROUP]?.[adGroupField] || []).map(
            ({ id }) => id
          )}
          label={'Ad Groups'}
          options={getAdGroupOptions(localState[AD_GROUP])}
          disabled={!hasCampaigns || readOnly}
          isLoading={getIsLoading(new RegExp(AD_GROUP))}
          onChange={(adGroups) => {
            onCheck(AD_GROUP, {
              [adGroupField]: adGroups.map(
                (adGroup) => adGroupAvailableAdGroupMap[adGroup]
              ),
              allSelected: false,
            })
          }}
          selectAllOptions={{
            label: 'All Ad Groups',
            allSelected: state[AD_GROUP]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(AD_GROUP),
          }}
          error={errors[elementConfig[AD_GROUP]]}
        />
      </div>
    )
  }
  /* End of AD_GROUP Section */

  /* AD Section */

  /**
   * Fetch ads for an ad group
   * @param {String} resource id field from Google
   */
  const fetchAds = (resource) => {
    const loaderKey = `${AD}_${resource}`

    if (!getIsLoading(loaderKey) && !ads[resource]) {
      toggleLoading(loaderKey, true)
      getAds(clientId, null, resource)
        .then((ads) => {
          setAds((previous) => ({
            ...(previous || {}),
            [resource]: ads,
          }))
        })
        .catch(console.error)
        .finally(() => {
          toggleLoading(loaderKey, false)
        })
    }
  }

  useEffect(() => {
    if (!localState[AD]?.campaigns) {
      return
    }

    localState[AD].campaigns.forEach((campaign) => {
      fetchAdGroups(campaign.id)
    })
  }, [localState[AD]?.campaigns])

  useEffect(() => {
    if (!localState[AD]?.adGroups) {
      return
    }

    localState[AD].adGroups.forEach((adGroup) => {
      fetchAds(adGroup.id)
    })
  }, [localState[AD]?.adGroups])

  const getAvailableAdsMap = (element) => {
    if (!element || !element.adGroups) {
      return {}
    }

    return element.adGroups.reduce((prev, current) => {
      return {
        ...prev,
        ...(ads[current.id] || []).reduce(
          (prev, current) => ({ ...prev, [current.id]: current }),
          {}
        ),
      }
    }, {})
  }

  const adAvailableAdGroupMap = useMemo(() => {
    return getAvailableAdGroupMap(localState[AD])
  }, [adGroups, localState[AD]])

  const adAvailableAdMap = useMemo(() => {
    return getAvailableAdsMap(localState[AD])
  }, [localState[AD]?.adGroups, ads])

  const renderAdSection = () => {
    if (!state.elements?.includes(AD)) {
      return null
    }

    const hasCampaigns = state[AD]?.campaigns?.length

    const hasAdGroups = state[AD]?.adGroups?.length

    const adOptions =
      localState[AD]?.adGroups?.reduce(
        (prev, current) => [
          ...prev,
          {
            label: current.name,
            disabled: true,
            noCheckbox: true,
            subsections: (ads[current.id] || []).map(
              (ad) => ({
                label: ad.name,
                value: ad.id,
              }),
              []
            ),
          },
        ],
        []
      ) || []

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Campaigns"
          selectedItems={(state[AD]?.campaigns || []).map(({ id }) => id)}
          label={'Campaign'}
          disabled={readOnly}
          options={getCampaignOptions(({ id }) =>
            getIsLoading(`${AD_GROUP}_${id}`)
          )}
          onChange={(campaigns) =>
            onCheck(AD, {
              campaigns: campaigns.map(
                (campaign) => campaignsInAccounts[campaign]
              ),
            })
          }
        />
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Ad Groups"
          selectedItems={(state[AD]?.adGroups || []).map(({ id }) => id)}
          disabled={!hasCampaigns || readOnly}
          isLoading={getIsLoading(new RegExp(AD_GROUP))}
          label={'Ad Groups'}
          options={getAdGroupOptions(localState[AD], ({ id }) =>
            getIsLoading(`${AD}_${id}`)
          )}
          onChange={(adGroups) => {
            onCheck(AD, {
              adGroups: adGroups.map(
                (adGroup) => adAvailableAdGroupMap[adGroup]
              ),
              allSelected: false,
            })
          }}
        />
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Ads"
          selectedItems={(state[AD]?.ads || []).map(({ id }) => id)}
          label={'Ads'}
          options={adOptions}
          disabled={!hasAdGroups || readOnly}
          isLoading={getIsLoading(new RegExp(AD))}
          onChange={(ads) => {
            onCheck(AD, {
              ads: ads.map((ad) => adAvailableAdMap[ad]),
              allSelected: false,
            })
          }}
          selectAllOptions={{
            label: 'All Ads',
            allSelected: state[AD]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(AD),
          }}
          error={errors[elementConfig[AD]]}
        />
      </div>
    )
  }
  /* End of AD Section */

  /* PLATFORM Section */
  const platformDisplayed = state.elements?.includes(PLATFORM)

  useEffect(() => {
    if (!getIsLoading(PLATFORM) && platformDisplayed) {
      toggleLoading(PLATFORM, true)
      getPlatforms(clientId)
        .then((response) => {
          setPlatforms(response)
        })
        .catch(console.error)
        .finally(() => {
          toggleLoading(PLATFORM, false)
        })
    }
  }, [platformDisplayed])

  const platformMap = (platforms || []).reduce(
    (prev, adType) => ({
      ...prev,
      [adType.id]: adType,
    }),
    {}
  )

  const renderPlatformSection = () => {
    if (!platformDisplayed) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Platforms"
          isLoading={getIsLoading(PLATFORM)}
          selectedItems={(state[PLATFORM]?.platforms || []).map(({ id }) => id)}
          label={'Platforms'}
          options={(platforms || []).map((platform) => ({
            value: platform.id,
            label: platform.name,
          }))}
          disabled={readOnly}
          onChange={(platforms) => {
            onCheck(PLATFORM, {
              allSelected: false,
              platforms: platforms.map((platform) => platformMap[platform]),
            })
          }}
          selectAllOptions={{
            label: 'All Platforms',
            allSelected: state[PLATFORM]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(PLATFORM),
          }}
          error={errors[elementConfig[PLATFORM]]}
        />
      </div>
    )
  }
  /* End of PLATFORM Section */

  /* PLACEMENTS Section */
  const placementDisplayed = state.elements?.includes(PLACEMENTS)

  useEffect(() => {
    if (!getIsLoading(PLACEMENTS) && placementDisplayed) {
      toggleLoading(PLACEMENTS, true)
      getFacebookPlacements()
        .then((response) => {
          setPlacements(response)
        })
        .catch(console.error)
        .finally(() => {
          toggleLoading(PLACEMENTS, false)
        })
    }
  }, [placementDisplayed])

  const placementMap = (placements || []).reduce(
    (prev, placement) => ({
      ...prev,
      [placement.id]: placement,
    }),
    {}
  )

  const renderPlacementSection = () => {
    if (!placementDisplayed) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Placements"
          isLoading={getIsLoading(PLACEMENTS)}
          selectedItems={(state[PLACEMENTS]?.placements || []).map(
            ({ id }) => id
          )}
          label={'Placements'}
          options={(placements || []).map((placement) => ({
            value: placement.id,
            label: placement.name,
          }))}
          disabled={readOnly}
          onChange={(placements) => {
            onCheck(PLACEMENTS, {
              allSelected: false,
              placements: placements.map(
                (placement) => placementMap[placement]
              ),
            })
          }}
          selectAllOptions={{
            label: 'All Placements',
            allSelected: state[PLACEMENTS]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(PLACEMENTS),
          }}
          error={errors[elementConfig[PLACEMENTS]]}
        />
      </div>
    )
  }
  /* End of PLACEMENTS Section */

  /* DEVICE Section */
  const devicesDisplayed = state.elements?.includes(DEVICE)

  useEffect(() => {
    if (!getIsLoading(DEVICE) && devicesDisplayed) {
      toggleLoading(DEVICE, true)
      getDevices(clientId)
        .then((response) => {
          setDevices(response)
        })
        .catch(console.error)
        .finally(() => {
          toggleLoading(DEVICE, false)
        })
    }
  }, [devicesDisplayed])

  const devicesMap = (devices || []).reduce(
    (prev, device) => ({
      ...prev,
      [device.id]: device,
    }),
    {}
  )

  const renderDeviceSection = () => {
    if (!devicesDisplayed) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Placements"
          isLoading={getIsLoading(DEVICE)}
          selectedItems={(state[DEVICE]?.devices || []).map(({ id }) => id)}
          label={'Mobile Devices'}
          options={(devices || []).map((device) => ({
            value: device.id,
            label: device.name,
          }))}
          disabled={readOnly}
          onChange={(devices) => {
            onCheck(DEVICE, {
              allSelected: false,
              devices: devices.map((device) => devicesMap[device]),
            })
          }}
          selectAllOptions={{
            label: 'All Devices',
            allSelected: state[DEVICE]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(DEVICE),
          }}
          error={errors[elementConfig[DEVICE]]}
        />
      </div>
    )
  }
  /* End of DEVICE Section */

  /* FORMAT Section */
  const formatDisplayed = state.elements?.includes(FORMAT)

  useEffect(() => {
    if (!getIsLoading(FORMAT) && formatDisplayed) {
      toggleLoading(FORMAT, true)
      getFormats(clientId)
        .then((response) => {
          setFormats(response)
        })
        .catch(console.error)
        .finally(() => {
          toggleLoading(FORMAT, false)
        })
    }
  }, [formatDisplayed])

  const formatsMap = (formats || []).reduce(
    (prev, format) => ({
      ...prev,
      [format.id]: format,
    }),
    {}
  )

  const renderFormatSection = () => {
    if (!formatDisplayed) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Formats"
          isLoading={getIsLoading(FORMAT)}
          selectedItems={(state[FORMAT]?.formats || []).map(({ id }) => id)}
          label={'Formats'}
          options={(formats || []).map((format) => ({
            value: format.id,
            label: format.name,
          }))}
          disabled={readOnly}
          onChange={(formats) => {
            onCheck(FORMAT, {
              allSelected: false,
              formats: formats.map((format) => formatsMap[format]),
            })
          }}
          selectAllOptions={{
            label: 'All Formats',
            allSelected: state[FORMAT]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(FORMAT),
          }}
          error={errors[elementConfig[FORMAT]]}
        />
      </div>
    )
  }
  /* End of FORMAT Section */

  return (
    <Section>
      <SinglePerformanceAccountRow
        state={state}
        onChange={onChange}
        onChangeAccounts={onChangeAccounts}
        readOnly={readOnly}
        platform={FACEBOOK}
        availableAccounts={availableAccounts}
        availableElements={ACCOUNTS_TO_SELECT_ELEMENTS[FACEBOOK]}
        elementConfig={elementConfig}
        errors={errors}
        setErrors={onChangeErrors}
      />
      {renderAccountSection()}
      {renderCampaignSection()}
      {renderAdGroupSection()}
      {renderAdSection()}
      {renderPlatformSection()}
      {renderPlacementSection()}
      {renderDeviceSection()}
      {renderFormatSection()}
    </Section>
  )
}

SinglePerformanceFacebook.propTypes = {
  clientId: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  elementConfig: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onChangeErrors: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

/** Data Validation functions, ensured data is dynamically removed based on parent element removals */
const validateCampaignChanges = (
  changes,
  { allAccountsSelected, accounts }
) => {
  if (changes.campaigns && !allAccountsSelected) {
    const availableAccountSet = new Set(accounts)
    changes.campaigns = changes.campaigns.filter(({ account_id }) =>
      availableAccountSet.has(account_id)
    )
  }
  return changes
}

const validateAdGroupChanges = (changes, state, TYPE) => {
  changes = validateCampaignChanges(changes, state, TYPE)
  if (changes.campaigns) {
    const { adGroups } = state[TYPE]
    if (adGroups?.length) {
      const availableCampaignsSet = new Set(
        changes.campaigns.map(({ id }) => id)
      )
      changes.adGroups = adGroups.filter(({ campaign_id }) =>
        availableCampaignsSet.has(campaign_id)
      )
    }
  }

  return changes
}

const validateAdChanges = (changes, state, TYPE) => {
  changes = validateAdGroupChanges(changes, state, TYPE)

  if (changes.adGroups) {
    const { ads } = state[TYPE]

    if (ads?.length) {
      const availableAdGroupSet = new Set(changes.adGroups.map(({ id }) => id))
      changes.ads = ads.filter(({ adset_id }) =>
        availableAdGroupSet.has(adset_id)
      )
    }
  }

  return changes
}

const validDataPersistenceConfig = {
  [ACCOUNT]: (changes, { allAccountsSelected, accounts }) => {
    if (changes.accounts && !allAccountsSelected) {
      const availableAccountSet = new Set(accounts)
      changes.accounts = changes.accounts.filter(({ id }) =>
        availableAccountSet.has(id)
      )
    }
    return changes
  },
  [CAMPAIGN]: validateCampaignChanges,
  [AD_GROUP]: validateAdGroupChanges,
  [AD]: validateAdChanges,
}
