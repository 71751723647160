import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import PropTypes from 'prop-types'

import CollapsibleSection from 'components/collapsible-section'
import Button from 'components/button'
import Loader from 'components/loader'
import { SectionLinkControl } from 'modules/companies/section-link-components'

import {
  deleteCampaignExclusion,
  editCampaignExclusion,
  getCampaignExclusionsByCompanyId,
  saveCampaignExclusion,
} from 'modules/companies/actions'

import { ReactComponent as PlusIcon } from 'assets/icon_plus_blue.svg'

import CampaignExclusionsTable from './components/campaign-exclusions-table'
import CampaignExclusionSettings from './components/campaign-exclusions-create'
import ExcludedCampaigns from './components/excluded-campaigns/index'

import './style.scss'

/**
 * Campaign Exclusion Section and all its related business logic
 * @param {Object} props
 * @param {Boolean} props.isViewMode
 * @param {Object} props.company - Company for which we render the campaign exclusion section
 * @param {String} [props.businessUnitClientId] - Business unit's key for which we render the campaign exclusion section
 * @param {Object} [props.links] - Links Object - holds the linked business units for the Campaign Exclusions section
 * @param {Function} props.onChangeLinks - Callback to change links
 */
const CampaignExclusionsSection = ({
  isViewMode,
  company,
  businessUnitClientId,
  links,
  onChangeLinks,
}) => {
  const { setFlags } = useOutletContext()
  const [campaignExclusion, setCampaignExclusion] = useState({})
  const [isExclusionSettingsPanelOpened, setIsExclusionSettingsPanelOpened] =
    useState(false)
  const [isExclusionPanelPending, setIsExclusionPanelPending] = useState(false)
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false)
  const [editedExclusion, setEditedExclusion] = useState({})
  const [openedSubSection, setOpenedSubSection] = useState(true)
  const hasExclusions = campaignExclusion.exclusions?.length

  const companyId = company._id || company.id

  const isLinked = links[companyId]

  useEffect(() => {
    // Fetch the campaign exclusions on mount
    fetchCampaignExclusions()
  }, [])

  const cleanStates = () => {
    setIsExclusionSettingsPanelOpened(false)
    setIsDeleteInProgress(false)
    setIsExclusionPanelPending(false)
  }

  const handleError = (error) => {
    console.error(error.message || JSON.stringify(error))
    cleanStates()
  }

  const fetchCampaignExclusions = () => {
    getCampaignExclusionsByCompanyId(companyId).then((res) => {
      if (res) {
        const { exclusions, fields } = res
        setCampaignExclusion({ exclusions: exclusions || [], fields })
        cleanStates()
      } else {
        setCampaignExclusion({ exclusions: [] })
      }
    })
  }

  const onEditClicked = (exclusion) => {
    setEditedExclusion(exclusion)
    setIsExclusionSettingsPanelOpened(true)
  }

  const onCancelClicked = () => {
    setIsExclusionSettingsPanelOpened(false)
    setEditedExclusion({})
  }

  const onDeleteClicked = (exclusionId) => {
    setIsDeleteInProgress(true)
    deleteCampaignExclusion(companyId, exclusionId)
      .then((response) => {
        if (response.success) {
          fetchCampaignExclusions()
          if (setFlags) {
            setFlags(company._id, { excludedCampaignsCaching: true })
          }
        }
      })
      .catch(handleError)
  }

  const onSaveClicked = (newExclusion) => {
    let action = saveCampaignExclusion

    // If the exclusion already has an _id, then it's the edit scenario
    if (newExclusion._id) {
      action = editCampaignExclusion
    }

    setIsExclusionPanelPending(true)
    action(companyId, newExclusion)
      .then((response) => {
        if (response.success) {
          fetchCampaignExclusions()
          if (setFlags) {
            setFlags(company._id, { excludedCampaignsCaching: true })
          }
        }
      })
      .catch(handleError)
  }

  const _renderAddExclusionButton = () => {
    if (isViewMode || isLinked) {
      return null
    }
    return (
      <Button
        value={
          <div className="action-button">
            <PlusIcon />
            Add Exclusion
          </div>
        }
        onClick={() => setIsExclusionSettingsPanelOpened(true)}
        className="fixed-height"
        disabled={
          isExclusionSettingsPanelOpened || !campaignExclusion.exclusions
        }
        secondary
      />
    )
  }

  const _renderCreateExclusionPanel = () => {
    if (!isExclusionSettingsPanelOpened) {
      return <div></div>
    }

    if (isExclusionPanelPending) {
      return <Loader />
    }

    return (
      <div className="campaign-exclusions-section">
        <CampaignExclusionSettings
          company={company}
          fields={campaignExclusion.fields || {}}
          onSaveClicked={onSaveClicked}
          onCancelClicked={onCancelClicked}
          editedExclusion={editedExclusion}
          setEditedExclusion={setEditedExclusion}
          businessUnitClientId={businessUnitClientId}
          exclusions={campaignExclusion.exclusions}
        />
      </div>
    )
  }

  const _renderExclusionsTable = () => {
    if (!hasExclusions) {
      return
    }

    if (isDeleteInProgress) {
      return <Loader />
    }

    return (
      <div className="campaign-exclusions-section">
        <CampaignExclusionsTable
          isViewMode={isViewMode || isLinked}
          disabledActions={isExclusionSettingsPanelOpened}
          exclusions={campaignExclusion.exclusions}
          onDeleteClicked={onDeleteClicked}
          onEditClicked={onEditClicked}
        />
      </div>
    )
  }

  return (
    <CollapsibleSection
      data-cy="campaign-exclusion-section"
      header={
        <div className="display-flex align-row gap-8">
          <div>Campaign Exclusions</div>
          {!isViewMode && (
            <div className="display-flex align-row gap-8">
              {/* <SectionLinkControl
                client={company}
                links={links}
                onChangeLinks={onChangeLinks}
                isSectionOpen={openedSubSection}
              /> */}
            </div>
          )}
        </div>
      }
      uncollapsible={!campaignExclusion.exclusions}
      defaultCollapsed={!isExclusionSettingsPanelOpened}
      extras={_renderAddExclusionButton()}
      wrapperClassName="form__section"
      onCollapseListener={setOpenedSubSection}
    >
      {!campaignExclusion.exclusions ? (
        <Loader />
      ) : (
        <>
          {_renderCreateExclusionPanel()}
          {_renderExclusionsTable()}
          {Boolean(hasExclusions) && (
            <ExcludedCampaigns
              clientId={company._id}
              accounts={company.accounts}
              exclusions={campaignExclusion.exclusions}
            />
          )}
        </>
      )}
    </CollapsibleSection>
  )
}

export default CampaignExclusionsSection

CampaignExclusionsSection.propTypes = {
  isViewMode: PropTypes.bool,
  company: PropTypes.object.isRequired,
  businessUnitClientId: PropTypes.string,
  links: PropTypes.object,
  onChangeLinks: PropTypes.func.isRequired,
}
