import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Api from 'easy-fetch-api'

import { useStore } from 'store'

import Calendar from 'components/calendar'
import { Dropdown } from 'components/dropdown'
import Filters from 'components/filters/index'
import Input from 'components/input'
import Loader from 'components/loader/index'
import Table from 'components/table/beta'
import { getTooltipList } from 'components/utils/tooltip'

import { utils } from '@decision-sciences/qontrol-common'

import { getUsersWhoAcceptedTOU } from '../actions'

const { compareIgnoreCase } = utils.string
const { shallowComparison } = utils.object

const UserTermsOfUseAcceptanceTable = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [queryString, setQueryString] = useState('')
  const [serverTimezone, setServerTimezone] = useState()

  const { state, dispatch } = useStore()

  const {
    legal: { users },
    companies: { list: companyList },
  } = state

  /**
   * Fetch server timezone to display in table header
   */
  useEffect(() => {
    Api.get({
      url: '/api/global-settings/server-timezone',
    })
      .then((result) => {
        setServerTimezone(result?.timezone)
      })
      .catch(console.error)
  }, [])

  /* On component mount or `queryString` change, fetch the list of users */
  useEffect(() => {
    getUsersWhoAcceptedTOU(dispatch, { queryString })
      .then(() => {
        if (error) {
          setError(null)
        }
      })
      .catch(() => setError('There was an error while getting the users'))
  }, [queryString])

  useEffect(() => {
    setLoading(!users)
  }, [users])

  const columns = [
    {
      header: 'Name',
      accessorKey: 'name',
      textAlign: 'left',
      size: 150,
      sortingFn: 'name',
    },
    {
      header: 'Email',
      accessorKey: 'email',
      textAlign: 'left',
      size: 150,
    },
    {
      header: 'Client',
      accessorFn: (row) => {
        if (!row?.clients?.length) {
          return '-'
        }

        if (row.clients.length === 1) {
          return row.clients[0].clientId?.name || '-'
        }

        return 'Multiple'
      },
      tooltip: (row) => {
        if (!row?.clients) {
          return null
        }
        if (row.clients.length <= 1) {
          return null
        }
        const clients = row.clients
          .map(({ clientId }) => clientId?.name)
          .sort(compareIgnoreCase)
        return getTooltipList('Clients', clients)
      },
      textAlign: 'left',
      size: 120,
    },
    {
      header: `Date accepted${
        serverTimezone ? ` (${serverTimezone.name})` : ''
      }`,
      accessorFn: (row) => new Date(row.timestampLegalTerms),
      cell: (cell) => cell.row.original.timestampLegalTerms,
      size: 100,
      textAlign: 'left',
      sortingFn: 'date',
    },
  ]

  if (loading && !error) {
    return <Loader />
  }

  return (
    <>
      {error && <div className="error error--with-container">{error}</div>}
      <UserFilters disabled={loading || error} companies={companyList} />
      <Table
        searchInputPlaceholder="Search by name..."
        loading={loading}
        onSearch={setQueryString}
        columns={columns}
        data={users || []}
        paginationValues={[10, 25, 50, 100]}
        showPagination
        showSearchInput
      />
    </>
  )
}

const FILTER_INITIAL_STATE = {
  queryString: '',
  clientId: null,
  dateStart: null,
  dateEnd: null,
}

/**
 *
 * @param {{disabled: boolean, companies: Object[]}} props Component props
 * @returns {React.Component}
 */
const UserFilters = ({ disabled, companies }) => {
  const { dispatch } = useStore()

  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({ ...FILTER_INITIAL_STATE })
  const [companyFilter, setCompanyFilter] = useState([])

  useEffect(() => {
    const companyFilter = [{ label: 'All', value: null }]
    if (companies?.length) {
      companies.forEach((company) => {
        companyFilter.push({ value: company._id, label: company.name })
        if (company.businessUnits?.length) {
          company.businessUnits.forEach((bu) => {
            companyFilter.push({ value: bu._id, label: ` - ${bu.name}` })
          })
        }
      })
    }
    setCompanyFilter(companyFilter)
  }, [companies])

  const applyFilters = () => {
    setLoading(true)
    const { queryString, clientId, dateStart, dateEnd } = state
    const query = {}
    if (queryString) {
      query.queryString = queryString
    }
    if (clientId) {
      query.clientId = clientId
    }
    if (dateStart) {
      query.dateStart = dateStart
    }
    if (dateEnd) {
      query.dateEnd = dateEnd
    }
    getUsersWhoAcceptedTOU(dispatch, query).finally(() => setLoading(false))
  }

  const clearFilters = () => {
    if (!shallowComparison(state, FILTER_INITIAL_STATE)) {
      setLoading(true)
      setState({ ...FILTER_INITIAL_STATE })
      getUsersWhoAcceptedTOU(dispatch, {}).finally(() => setLoading(false))
    }
  }

  return (
    <Filters
      loading={loading || disabled}
      onApply={applyFilters}
      onClear={clearFilters}
    >
      <Input
        searchBlue
        onChange={(value) => setState({ ...state, queryString: value })}
        value={state.queryString}
        placeholder="Search"
      />
      <Dropdown
        label={'Clients'}
        options={companyFilter}
        defaultState={state.clientId}
        onChange={(status) => setState({ ...state, clientId: status })}
        className="input-wrapper--uppercase"
      />
      <div className="input-wrapper">
        <div className="legal-filters label label-wrapper">
          Accepted date range
        </div>
        <div className="align-row space-between">
          <Calendar
            icon
            placeholder="Start Date"
            format={'MM/dd/yyyy'}
            className="flow__row__half"
            date={state.dateStart}
            returnDate={(val) =>
              setState((state) => {
                const newState = { ...state, dateStart: val }
                if (new Date(val) > new Date(state.dateEnd)) {
                  newState.dateEnd = val
                }
                return newState
              })
            }
            maxDate={new Date()}
          />
          <Calendar
            icon
            placeholder="End Date"
            format={'MM/dd/yyyy'}
            className="flow__row__half"
            date={state.dateEnd}
            returnDate={(val) =>
              setState((state) => {
                const newState = { ...state, dateEnd: val }
                if (new Date(val) < new Date(state.dateEnd)) {
                  newState.dateStart = val
                }
                return newState
              })
            }
            maxDate={new Date()}
          />
        </div>
      </div>
    </Filters>
  )
}

UserFilters.propTypes = {
  disabled: PropTypes.bool,
  companies: PropTypes.array,
}

export { UserTermsOfUseAcceptanceTable }
