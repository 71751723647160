import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

// Utils
import { runThresholdSimulation as runSimulation } from 'modules/alerts/alerts-thresholds-statistics/actions'
import { useEffectOnUpdate } from 'components/utils/custom-hooks'

// Hooks
import { useSocket } from 'components/utils/socket'
import useSession from 'modules/session'
import { useStore } from 'store'

import { showErrorMessage } from 'modules/notifications/actions'

import Button from 'components/button/index'
import Icon from 'components/icon/index'

import { socket } from '@decision-sciences/qontrol-common'

import { ReactComponent as IconSimulate } from 'assets/icon_sync_reset.svg'
import { ReactComponent as IconInfo } from 'assets/icon_info.svg'
import { ReactComponent as IconUndo } from 'assets/icon_undo.svg'
import { getLatestSimulationResults } from './actions'

const { NOTIFICATIONS } = socket

export const SimulationButton = ({ loading, disabled, onClick }) => {
  return (
    <Button
      className={cx('fixed-height')}
      secondaryLiliac
      disabled={disabled || loading}
      compact
      onClick={onClick}
      value={
        <Icon className="">
          <IconSimulate
            className={cx({
              'spinning-animation': loading,
              'icon-overload-disabled': loading,
            })}
            width={24}
            height={24}
          />
          SIMULATE
          <Icon
            tooltipClass="info--icon-tooltip"
            tooltip={
              <div>
                Run simulation for Last 7 Day Trigger Frequency for all alerts
                based on updated thresholds from most recently updated alert.
              </div>
            }
          >
            <IconInfo />
          </Icon>
        </Icon>
      }
    />
  )
}

SimulationButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export const RevertButton = ({ disabled, onClick }) => {
  return (
    <Button
      className="fixed-height"
      secondary
      compact
      onClick={onClick}
      disabled={disabled}
      value={
        <Icon>
          <IconUndo width={24} height={24} />
          REVERT TO LAST SAVE
        </Icon>
      }
    />
  )
}

RevertButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export const useSimulationResults = ({
  client,
  onSimulationResults,
  setLatestSimulation,
  thresholdChanges,
  revertThresholds,
}) => {
  const [, user] = useSession()
  const { dispatch, state } = useStore()

  const { active: alertThresholds } = state.alertThresholds

  const [loading, setLoading] = useState(false)
  const [simulationNeeded, setSimulationNeeded] = useState(false)
  const [modifiedThresholdsForSimulation, setModifiedThresholdsForSimulation] =
    useState([])
  const [previousSimulationThresholds, setPreviousSimulationThresholds] =
    useState({})
  const [revertIsActive, setRevertIsActive] = useState(false)

  const socket = useSocket({ room: `${user._id}-${client._id}` })

  const simulationsDisabled = !simulationNeeded || client.new

  const thresholdChangeReduce = (prev, current) => {
    if (!current.changed || !alertThresholds) {
      return prev
    }
    const globalThreshold = alertThresholds.find(
      ({ _id }) => _id === current.globalThreshold
    )
    if (!globalThreshold) {
      return prev
    }
    return { ...prev, [globalThreshold.key]: current.value }
  }

  useEffectOnUpdate(() => {
    setSimulationNeeded(!!thresholdChanges)
  }, [thresholdChanges])

  useEffect(() => {
    if (!client.new) {
      getLatestSimulationResults(client._id).then((result) => {
        if (result?.thresholds) {
          setPreviousSimulationThresholds(
            (result?.thresholds || []).reduce(thresholdChangeReduce, {})
          )
        }
        if (result?.simulationResults) {
          setLatestSimulation(result.simulationResults)
        }
      })
    }
  }, [client._id])

  useEffect(() => {
    if (!thresholdChanges) {
      setModifiedThresholdsForSimulation({})
      return
    }

    const modifiedThresholds = thresholdChanges.reduce(
      thresholdChangeReduce,
      {}
    )

    // If there's a change missing from the previous simulation, replace it with either the value from the client or the default threshold value
    Object.keys(previousSimulationThresholds).forEach((thresholdKey) => {
      if (typeof modifiedThresholds[thresholdKey] === 'undefined') {
        const globalThreshold = alertThresholds.find(
          ({ key }) => key === thresholdKey
        )
        if (globalThreshold) {
          const clientThreshold = client.alertThresholds.find(
            ({ globalThreshold }) => globalThreshold._id === globalThreshold
          )

          modifiedThresholds[thresholdKey] = clientThreshold
            ? clientThreshold.value
            : globalThreshold.value
        }
      }
    })

    setModifiedThresholdsForSimulation(modifiedThresholds)
  }, [thresholdChanges, alertThresholds])

  useEffect(() => {
    if (socket?.connected) {
      socket.on(NOTIFICATIONS.thresholdSimulation.receive, ({ result }) => {
        if (!result) {
          showErrorMessage(
            `Error running simulation. Please try again`,
            dispatch
          )
        } else {
          onSimulationResults(result)
          setSimulationNeeded(false)
          setRevertIsActive(true)
        }
        setLoading(false)
      })
    }

    return () => {
      socket?.removeAllListeners(NOTIFICATIONS.thresholdSimulation.receive)
    }
  }, [socket?.connected])

  const simulate = (alertId) => {
    setLoading(true)

    runSimulation({
      alertId,
      companyId: client._id,
      modifiedThresholds: modifiedThresholdsForSimulation,
    }).catch(() => {
      setSimulationNeeded(true)
      setLoading(false)
    })
  }

  return {
    loading,
    simulationsDisabled,
    simulationNeeded,
    thresholdChanges,
    simulate,
    revertThresholds: (...props) => {
      setRevertIsActive(false)
      revertThresholds(...props)
    },
    revertIsActive,
  }
}
