import Api from 'easy-fetch-api'
import {
  showSuccessMessage,
  showErrorMessage,
} from 'modules/notifications/actions'

export const slackMessage = (dispatch, message, channel) => {
  return Api.post({
    url: `/api/slack/message`,
    data: {
      message,
      channel,
    },
  }).then((result) => {
    if (!result.success || result.error) {
      const errorMessage = result.error
      return showErrorMessage(
        `Unable to send Slack message.\n ${errorMessage}`,
        dispatch
      )
    }
    showSuccessMessage(`Sent message to ${channel}`, dispatch)
  })
}

export const getUsers = () => Api.get({ url: `api/slack/users` })
