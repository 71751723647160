import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { useDebouncedEffect } from 'components/utils/custom-hooks'

import './style.scss'

/**
 * Renders a "boxy" search input with debounced search trigger
 * @param {Object} props Component props
 * @param {Boolean} [props.disabled] Flag to disable searching
 * @param {String} [props.value = ''] Current value entered in the input
 * @param {Function} props.onChange Change handler called when the search term changes
 * @param {String} [props.placeholder = 'Search']
 * @returns {React.FunctionComponent}
 */
const SearchInput = ({
  disabled,
  value = '',
  onChange,
  placeholder = 'Search',
}) => {
  const [inputValue, setInputValue] = useState(value)

  // Debounce every keystroke by 1 second
  useDebouncedEffect(
    () => {
      onChange(inputValue)
    },
    1000,
    [inputValue]
  )

  return (
    <div className={cx('search-input', { 'search-input--disabled': disabled })}>
      <div className="search-input__icon" />
      <input
        disabled={disabled}
        placeholder={placeholder}
        value={inputValue}
        onChange={(e) => !disabled && setInputValue(e.target.value)}
        className="search-input__input"
      />
    </div>
  )
}

SearchInput.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

export default SearchInput
