import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { useStore } from 'store'

import InputGroup from 'components/input-group'
import { Dropdown } from 'components/dropdown'
import Input from 'components/input'
import CalendarComp from 'components/calendar/index'
import { useEffectOnUpdate } from 'components/utils/custom-hooks'
import { DEFAULT_DATE_FORMAT } from 'components/utils/date'
import InformationBlock from 'components/information-block'

import {
  timezoneCountry,
  notifications,
  time,
} from '@decision-sciences/qontrol-common'

import './styles.scss'

const { AMERICAN_TIMEZONES } = timezoneCountry
const { FREQUENCY_OPTIONS_MAP } = notifications
const { shiftStartTimeToTimezone } = time

/**
 * Input Group for Alert Start Time
 */
const AlertStartTime = ({
  defaultValue,
  onChange,
  error,
  viewOnly = false,
  recurrence = {},
  showHourlyInfo = false,
}) => {
  const [state, setState] = useState(getNewData(defaultValue))

  const [minutes, setMinutes] = useState(state.minutes)
  const [hours, setHours] = useState(state.hours)

  const {
    state: {
      global: { environment },
    },
  } = useStore()

  useEffectOnUpdate(
    () => {
      if (state.hours && state.minutes && state.amPm) {
        onChange(state)
      } else {
        onChange(null)
      }
    },
    [JSON.stringify(state)],
    () => {
      if (JSON.stringify(state) !== JSON.stringify(defaultValue)) {
        onChange(state)
      }
    }
  )

  useEffect(() => {
    if (recurrence.frequency === FREQUENCY_OPTIONS_MAP.MINUTES) {
      onChangeMinutes('00')
      setState({
        ...state,
        minutes: '00',
      })
    }
  }, [recurrence.frequency])

  useEffect(() => {
    const newState = getNewData(defaultValue)
    const { minutes, hours } = newState
    setState(newState)
    setMinutes(minutes)
    setHours(hours)
  }, [JSON.stringify(defaultValue)])

  const onChangeHours = (hours) => {
    let numericValue = parseInt(hours)
    if (!hours) {
      numericValue = 12
    }
    if (
      isNaN(numericValue) ||
      numericValue > 12 ||
      !hours.toString().match(/^\d*$/)
    ) {
      return
    }
    setHours(hours)
  }

  const onChangeMinutes = (minutes) => {
    let numericValue = parseInt(minutes)
    if (!minutes) {
      numericValue = 0
    }
    if (
      isNaN(numericValue) ||
      numericValue > 59 ||
      !minutes.toString().match(/^\d*$/)
    ) {
      return
    }
    setMinutes(minutes)
  }

  const showstartTimeInfo =
    showHourlyInfo ||
    [FREQUENCY_OPTIONS_MAP.MINUTES, FREQUENCY_OPTIONS_MAP.HOURS].includes(
      recurrence.frequency
    )

  const startTimeInServerTime = useMemo(() => {
    if (!showstartTimeInfo) {
      return null
    }

    const startTimeOnServer = shiftStartTimeToTimezone(
      state,
      environment?.TIMEZONE
    )

    return format(startTimeOnServer, 'hh:MM aa')
  }, [showstartTimeInfo, JSON.stringify(state), environment?.TIMEZONE])

  return (
    <>
      <div className="alert-start-time" data-cy="date-and-time">
        <div className="input-wrapper">
          <InputGroup
            error={error}
            options={[
              {
                render: (
                  <label data-cy="start-running-on-label">
                    Start running the alert on:{' '}
                  </label>
                ),
                width: 231,
                maxWidth: 'max-content',
              },
              {
                render: (
                  <CalendarComp
                    icon
                    date={state.date}
                    minDate={new Date()}
                    returnDate={(date) => setState({ ...state, date })}
                    disabled={viewOnly}
                  />
                ),
                width: 156,
              },
            ]}
          />
        </div>
        <div className="input-wrapper">
          <InputGroup
            error={error}
            options={[
              {
                render: (
                  <label data-cy="start-running-at-label">
                    Start running the alert at:{' '}
                  </label>
                ),
                width: 227,
                maxWidth: 'max-content',
              },
              {
                render: (
                  <Input
                    value={hours}
                    onChange={onChangeHours}
                    blur={() => {
                      const newHours =
                        !hours || parseInt(hours) === 0
                          ? 12
                          : hours.padStart(2, '0')
                      setState({ ...state, hours: newHours })
                      setHours(newHours)
                    }}
                    viewOnly={viewOnly}
                    data-cy="date-and-time-hours"
                  />
                ),
                width: 56,
              },
              {
                render: (
                  <Input
                    value={minutes}
                    onChange={onChangeMinutes}
                    blur={() => {
                      const newMinutes = minutes.padStart(2, '0')
                      setState({
                        ...state,
                        minutes: newMinutes,
                      })
                      setMinutes(newMinutes)
                    }}
                    viewOnly={viewOnly}
                    data-cy="date-and-time-minutes"
                    disabled={
                      recurrence.frequency === FREQUENCY_OPTIONS_MAP.MINUTES
                    }
                  />
                ),
                width: 56,
              },
              {
                render: (
                  <Dropdown
                    options={['AM', 'PM']}
                    onChange={(amPm) => setState({ ...state, amPm })}
                    defaultState={state.amPm}
                    disabled={viewOnly}
                    data-cy="date-and-time-am-pm"
                  />
                ),
                width: 77,
              },
              {
                render: (
                  <Dropdown
                    options={AMERICAN_TIMEZONES.map((tz) => ({
                      label: `${tz.name}`,
                      value: tz.iana,
                    }))}
                    onChange={(timeZone) => setState({ ...state, timeZone })}
                    defaultState={state.timeZone}
                    disabled={viewOnly}
                    data-cy="date-and-time-timezone"
                  />
                ),
                width: 88,
              },
            ]}
          />
        </div>
      </div>
      {showstartTimeInfo ? (
        <InformationBlock
          className="alert-start-time-info"
          info={`Hourly alerts would run in the following interval: ${startTimeInServerTime} - 11:59 PM ${environment?.TIMEZONE}`}
        />
      ) : null}
    </>
  )
}

AlertStartTime.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.object,
  error: PropTypes.string,
  viewOnly: PropTypes.bool,
  recurrence: PropTypes.object,
  showHourlyInfo: PropTypes.bool,
}

/**
 * Get new Start Time object based on local timezone
 * @param {Object} defaultValue Default Value of Alert Start Time
 */
const getNewData = (defaultValue) => {
  if (!defaultValue) {
    const date = new Date()
    const timeZone =
      AMERICAN_TIMEZONES.find(
        (tz) => tz.number === -date.getTimezoneOffset() / 60
      )?.iana || 'America/Los_Angeles'

    return {
      hours: format(date, 'hh'),
      minutes: format(date, 'mm'),
      amPm: format(date, 'aa'),
      timeZone,
      date: format(date, DEFAULT_DATE_FORMAT),
    }
  }
  // Convert numbers to '00' format
  return {
    ...defaultValue,
    hours: defaultValue.hours.toString().padStart(2, '0'),
    minutes: defaultValue.minutes.toString().padStart(2, '0'),
  }
}
export default AlertStartTime
