import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'notificationGroups'

/** Initial state of the reducer */
const initialState = {
  list: null,
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.GET_NOTIFICATION_GROUPS]: (state, { list }) => {
    return { ...state, list }
  },

  [ACTIONS.PUT_NOTIFICATION_GROUP]: (state, { notificationGroup }) => {
    const newList = [...(state.list || [])]
    const foundIdx = newList.findIndex(
      (item) => item._id === notificationGroup._id
    )
    if (foundIdx !== -1) {
      // Have the updated element show at the top of the list
      ;[newList[foundIdx], newList[0]] = [newList[0], notificationGroup]
    } else {
      newList.unshift(notificationGroup)
    }

    return { ...state, list: newList }
  },

  [ACTIONS.DELETE_NOTIFICATION_GROUP]: (state, { id }) => ({
    ...state,
    list: state.list.filter((item) => item._id !== id),
  }),
}

export default { name, initialState, reduce }
