import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import Spacer from 'components/spacer'
import CheckboxSection from 'components/checkbox-section'

import './style.scss'

/**
 * Renders the Clients and Accounts section
 * @param {Object} params React Params
 * @param {Array} params.userClients user.clients array
 * @param {Array<Object>} params.clients List of client objects
 * @param {Function} params.onChange To be called with new user.clients array
 * @param {Boolean} params.viewOnly Read-Only mode
 * @param {Boolean} params.hasSearch Whether global search is enabled
 * @param {Boolean} params.showBusinessUnits Whether to show business units or not
 * @param {Boolean} params.showAccounts Whether to show accounts or not
 */
const ClientsAndAccounts = ({
  userClients = [],
  clients = [],
  onChange,
  viewOnly,
  hasSearch = true,
  showBusinessUnits = true,
  showAccounts = true,
}) => {
  // Client list change handler
  const getClientListForClientChange = (userClients, clients) => {
    const newClients = []
    clients.forEach((clientId) => {
      const foundClient = userClients.find(
        (client) => client.clientId === clientId
      )
      if (foundClient) {
        newClients.push(foundClient)
      } else {
        newClients.push({
          accounts: [],
          businessUnits: [],
          clientId,
        })
      }
    })
    return newClients
  }

  // Business unit list change handler
  const getClientListForBusinessUnitChange = (
    userClients,
    clientId,
    businessUnits
  ) => {
    const newClients = [...userClients]
    const clientIdx = userClients.findIndex(
      (uClient) => uClient.clientId === clientId
    )
    if (clientIdx !== -1) {
      const removedBusinessUnits = (
        newClients[clientIdx].businessUnits || []
      ).filter((bu) => !businessUnits.some((newBu) => newBu === bu))
      const newAccounts = new Set(newClients[clientIdx].accounts)
      const client = clients.find((client) => client._id === clientId)
      if (!client) {
        return
      }
      removedBusinessUnits.forEach((buId) => {
        const foundBusinessUnit = client.businessUnits.find(
          (bu) => bu._id === buId
        )
        if (foundBusinessUnit) {
          foundBusinessUnit.accounts.forEach((acc) =>
            newAccounts.delete(acc._id)
          )
        }
      })
      newClients[clientIdx].businessUnits = businessUnits
      newClients[clientIdx].accounts = [...newAccounts]
    }
    return newClients
  }

  // Account list change handler
  const getClientListForAccountsChange = (userClients, clientId, accounts) => {
    const newClients = [...userClients]
    const clientIdx = userClients.findIndex(
      (uClient) => uClient.clientId === clientId
    )
    if (clientIdx !== -1) {
      newClients[clientIdx].accounts = accounts
    }
    return newClients
  }

  // List of Client Objects representing currently selected clients.
  const selectedClients = useMemo(() => {
    if (!userClients || !clients) {
      return []
    }
    return clients.filter((client) =>
      userClients.some((userClient) => userClient.clientId === client._id)
    )
  }, [JSON.stringify(userClients), JSON.stringify(clients)])

  /**
   * Config for business units
   * Each config works under the structure:
   * @example <caption>Config Example</caption>
   * {
   *  title: Company Name
   *  items: <list of business unit ids>
   *  selected: <list of selected business unit ids>
   *  onChange: <function to call with new selected list of ids>
   * }
   */
  const businessUnitConfigs = useMemo(() => {
    return selectedClients.reduce((array, client) => {
      if (client.businessUnits?.length) {
        return [
          ...array,
          {
            title: client.name,
            items: client.businessUnits.map((businessUnit) => ({
              label: businessUnit.name,
              value: businessUnit._id,
            })),
            selectedItems:
              userClients.find(
                (clientData) => clientData.clientId === client._id
              )?.businessUnits || [],
            clientId: client._id,
            onChange: (businessUnits) =>
              onChange(
                getClientListForBusinessUnitChange(
                  userClients,
                  client._id,
                  businessUnits
                )
              ),
          },
        ]
      }
      return array
    }, [])
  }, [JSON.stringify(selectedClients), JSON.stringify(userClients)])

  const allBusinessUnitsSelected = useMemo(
    () =>
      businessUnitConfigs.length > 0 &&
      businessUnitConfigs.every(({ selectedItems, items }) => {
        return items.every(({ value }) => selectedItems.includes(value))
      }),
    [JSON.stringify(businessUnitConfigs)]
  )

  const onSelectAllBusinessUnits = () => {
    let newClients = [...userClients]

    for (const config of businessUnitConfigs) {
      if (allBusinessUnitsSelected) {
        newClients = getClientListForBusinessUnitChange(
          newClients,
          config.clientId,
          []
        )
        continue
      }
      newClients = getClientListForBusinessUnitChange(
        newClients,
        config.clientId,
        config.items.map(({ value }) => value)
      )
    }

    onChange(newClients)
  }

  /**
   * Config for accounts
   * Each config works under the structure:
   * @example <caption>Account Config Example</caption>
   * {
   *  title: Company Name
   *  subtitle: Business Unit Name // In case account belongs to business unit
   *  items: <list of account ids>
   *  selected: <list of selected account ids>
   *  onChange: <function to call with new selected list of ids>
   * }
   */
  const accountsConfig = useMemo(() => {
    return selectedClients
      .filter(
        (client) =>
          client.accounts.length > 0 ||
          (client.businessUnits.length > 0 &&
            client.businessUnits.some(
              (businessUnit) => businessUnit.accounts.length > 0
            ))
      )
      .map((client) => {
        const businessUnitAccounts = []

        if (client.businessUnits?.length) {
          client.businessUnits.forEach((businessUnit) => {
            const foundClient = userClients.find(
              (uc) => uc.clientId === client._id
            )

            if (!foundClient) {
              return false
            }

            if (
              businessUnit.accounts?.length &&
              foundClient.businessUnits?.some(
                (buId) => buId === businessUnit._id
              )
            ) {
              businessUnitAccounts.push(
                ...businessUnit.accounts.map((account) => ({
                  value: account._id,
                  label: account.name,
                  type: account.type,
                }))
              )
            }
          })
        }

        return {
          title: client.name,
          items: [
            ...businessUnitAccounts,
            ...client.accounts.map((account) => ({
              value: account._id,
              label: account.name,
              type: account.type,
            })),
          ],
          clientId: client._id,
          selectedItems:
            userClients.find((clientData) => clientData.clientId === client._id)
              ?.accounts || [],
          onChange: (accounts) =>
            onChange(
              getClientListForAccountsChange(userClients, client._id, accounts)
            ),
        }
      }, [])
  }, [
    JSON.stringify(selectedClients),
    JSON.stringify(businessUnitConfigs),
    JSON.stringify(userClients),
  ])

  const allAccountsSelected = useMemo(
    () =>
      accountsConfig.length > 0 &&
      accountsConfig.every(({ selectedItems, items }) =>
        items.every(({ value }) => selectedItems.includes(value))
      ),
    [JSON.stringify(accountsConfig.map(({ selectedItems }) => selectedItems))]
  )

  const onSelectAllAccounts = () => {
    let newClients = [...userClients]
    // We need to keep this since we can have multiple account configs per client, therefore, we might have overlapping changes and new changes will have stale values
    const newSelectedAccountsPerClient = {}

    for (const config of accountsConfig) {
      if (allAccountsSelected) {
        newClients = getClientListForAccountsChange(
          newClients,
          config.clientId,
          []
        )
        continue
      }

      const newAccountsForConfig = [
        ...new Set([
          ...(newSelectedAccountsPerClient[config.clientId] || []),
          ...config.items.map(({ value }) => value),
        ]),
      ]

      // We set every newly added account in a temporary variable so we always have fresh data
      if (!newSelectedAccountsPerClient[config.clientId]) {
        newSelectedAccountsPerClient[config.clientId] = []
      }
      newSelectedAccountsPerClient[config.clientId] = newAccountsForConfig

      newClients = getClientListForAccountsChange(
        newClients,
        config.clientId,
        newAccountsForConfig
      )
    }

    onChange(newClients)
  }

  /**
   * Config for clients
   */
  const clientsConfig = {
    items: clients.map((client) => ({ value: client._id, label: client.name })),
    selectedItems: selectedClients.map((client) => client._id),
    onChange: (clients) =>
      onChange(getClientListForClientChange(userClients, clients)),
  }

  const allClientsSelected = useMemo(
    () => clients.every(({ _id }) => clientsConfig.selectedItems.includes(_id)),
    [JSON.stringify(clientsConfig.selectedItems)]
  )

  const onSelectAllClients = () => {
    if (allClientsSelected) {
      return onChange(getClientListForClientChange(userClients, []))
    }
    onChange(
      getClientListForClientChange(
        userClients,
        clients.map(({ _id }) => _id)
      )
    )
  }

  // If there are no clients, there's nothing to display
  if (!clients) {
    return null
  }

  if (viewOnly) {
    const filteredClients = userClients.filter((uc) =>
      clients.some((client) => client._id === uc.clientId)
    )
    if (!filteredClients?.length) {
      return null
    }
    return (
      <div className="clients-accounts-view">
        {filteredClients.map((uc, index) => {
          const currentClient = clients.find(
            (client) => client._id === uc.clientId
          )
          const clientBusinessUnits = currentClient.businessUnits.filter((bu) =>
            uc.businessUnits.some((buId) => buId === bu._id)
          )
          const clientAccounts = currentClient.accounts.filter((acc) =>
            uc.accounts.some((accId) => accId === acc._id)
          )
          return (
            <React.Fragment key={uc.clientId}>
              <div className="clients-accounts-view__column">
                <h4>Client</h4>
                <div>{currentClient?.name}</div>
                {clientBusinessUnits?.length ? (
                  <div className="clients-accounts-view__column__item">
                    <h4>Business Units</h4>
                    {clientBusinessUnits.map((cbu) => (
                      <div key={cbu._id}>{cbu.name}</div>
                    ))}
                  </div>
                ) : null}
                {clientAccounts?.length ? (
                  <div className="clients-accounts-view__column__item">
                    <h4>Accounts</h4>
                    {clientAccounts.map((ca) => (
                      <div key={ca._id}>{ca.name}</div>
                    ))}
                  </div>
                ) : null}
              </div>
              {(index + 1) % 3 === 0 && index + 1 < filteredClients.length ? (
                <Spacer margin="23px 0 8px 0" />
              ) : null}
            </React.Fragment>
          )
        })}
      </div>
    )
  }
  return (
    <div className="clients-and-accounts">
      <CheckboxSection
        hasSearch={hasSearch}
        label="Clients"
        config={clientsConfig}
        onClickSelectAll={onSelectAllClients}
        allSelected={allClientsSelected}
      />
      {showBusinessUnits && (
        <CheckboxSection
          showWhenEmpty
          hasSearch={hasSearch}
          label="Business Units"
          config={businessUnitConfigs}
          onClickSelectAll={onSelectAllBusinessUnits}
          allSelected={allBusinessUnitsSelected}
        />
      )}
      {showAccounts && (
        <CheckboxSection
          showWhenEmpty
          hasSearch={hasSearch}
          label="Accounts"
          config={accountsConfig}
          onClickSelectAll={onSelectAllAccounts}
          allSelected={allAccountsSelected}
        />
      )}
    </div>
  )
}

ClientsAndAccounts.propTypes = {
  userClients: PropTypes.array,
  clients: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  hasSearch: PropTypes.bool,
  showBusinessUnits: PropTypes.bool,
  showAccounts: PropTypes.bool,
}

export default ClientsAndAccounts
