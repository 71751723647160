import { useState } from 'react'

/**
 * Custom hook to handle error and loading states for multiple fields or areas in a component.
 * This is useful when there are multiple errors or loading states needed for a component to aviod declaring local state objects for both of those.
 * Basically, in those components, the logic embedded in this hook would be needed to handle all intermediary render states.
 *
 * @returns {{
 * getErrorFor: Function,
 * setErrorFor: Function,
 * getLoadingFor: Function,
 * setLoadingFor: Function,
 * clearErrors: Function,
 * clearLoading: Function,
 * onSuccess: Function,
 * onFailure: Function
 * }}
 */
export const useIntermediaryStates = () => {
  const [error, setError] = useState({})
  const [loading, setLoading] = useState({})

  /**
   * Gets the stored error for the given key
   * @param {string} key Identifier to get the stored error for
   * @returns {string | boolean | Object | null}
   * @example
   * // Error object: { catalogs: 'Unable to fetch catalogs', productSets: null }
   * // returns `Unable to fetch catalogs`
   * getErrorFor('catalogs')
   */
  const getErrorFor = (key) => {
    return error ? error[key] : null
  }

  /**
   * Sets the error for the given key
   * @param {string} key Identifier to set the error for
   * @param {string | boolean | Object | null} error Value to store
   * @example
   * // Error object: { catalogs: 'Unable to fetch catalogs', productSets: null }
   * setErrorFor('catalogs', null)
   * // Error object: { catalogs: null, productSets: null }
   */
  const setErrorFor = (key, error) => {
    setError({ ...error, [key]: error })
  }

  /**
   * Gets the stored loading state for the given key
   * @param {string} key Identifier to get the stored loading state for
   * @returns {boolean}
   * @example
   * // Loading object: { catalogs: true, productSets: false }
   * // returns true
   * getLoadingFor('catalogs')
   */
  const getLoadingFor = (key) => {
    return loading ? loading[key] : null
  }

  /**
   * Sets the loading state for the given key
   * @param {string} key Identifier to set the error for
   * @param {boolean} isLoading Boolean flag for loading state
   * @example
   * // Error object: { catalogs: true }
   * setLoadingFor('catalogs', false)
   * // Error object: { catalogs: false }
   */
  const setLoadingFor = (key, isLoading) => {
    setLoading((prevState) => ({ ...prevState, [key]: isLoading }))
  }

  /**
   * Generic success handler for a given key, it sets the loading state to `false` and clears the errors
   * @param {string} key Identifier to set states for
   */
  const onSuccess = (key) => {
    setLoadingFor(key, false)
    setErrorFor(key, null)
  }

  /**
   * Generic failure handler for a given key, it sets the loading state to `false` and sets the error
   * @param {string} key Identifier to set states for
   * @param {string | boolean | Object | null} error Error object
   */
  const onFailure = (key, error) => {
    setLoadingFor(key, false)
    setErrorFor(key, error)
  }

  /**
   * Clears all stored errors
   */
  const clearErrors = () => setError({})

  /**
   * Clears all stored loading states
   */
  const clearLoading = () => setLoading({})

  return {
    getErrorFor,
    setErrorFor,
    getLoadingFor,
    setLoadingFor,
    clearErrors,
    clearLoading,
    onSuccess,
    onFailure,
  }
}
