import PropTypes from 'prop-types'
import React from 'react'
import ReactQuill from 'react-quill'
import cx from 'classnames'
import 'react-quill/dist/quill.snow.css'
import { formats, modules, QuillToolbar } from './toolbar'

/**
 * Basic implementation for a rich text editor.
 * Based on React Quill {@link https://github.com/zenoamaro/react-quill Docs}
 *
 * @param {Object} props Component props
 * @param {String} props.content Content to display and edit
 * @param {String} [props.placeholder] Placeholder for the editor
 * @param {String} props.onChange Callback function fired when content changes
 * @param {String} props.error Validation error on this field
 * @returns {React.Component}
 */
const RichTextEditor = ({ content, placeholder, onChange, error }) => {
  const handleChange = (value) => {
    onChange && onChange(value?.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;'))
  }

  return (
    <div className={cx('editor-container', { error: error })}>
      <QuillToolbar />
      <ReactQuill
        preserveWhitespace
        theme="snow"
        defaultValue={content || ''}
        onChange={handleChange}
        placeholder={placeholder || ''}
        modules={modules}
        formats={formats}
      />
    </div>
  )
}

RichTextEditor.propTypes = {
  content: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
}

export { RichTextEditor }
