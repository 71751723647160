import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { useOnClickOutside } from 'hooks/outside-click'
import { calculateListPosition } from 'components/utils/list-position'

import { ReactComponent as Icon } from 'assets/icon_triangle_up.svg'
import { ReactComponent as MinusIcon } from 'assets/icon_remove_dash.svg'
import { ReactComponent as ArrowIcon } from 'assets/icon_arrow_blue.svg'

import { alert } from '@decision-sciences/qontrol-common'
import './style.scss'

const { ALERT_VALUE_MAP } = alert

export const ALERT_DETAILS_MAP = {
  [ALERT_VALUE_MAP.HIGH]: {
    icon: <Icon width={20} height={20} fill="#198819" />,
  },
  [ALERT_VALUE_MAP.MEDIUM]: {
    icon: <MinusIcon width={20} height={20} />,
  },
  [ALERT_VALUE_MAP.LOW]: {
    icon: (
      <Icon width={20} height={20} fill="#e85e5e" className="triangle-down" />
    ),
  },
}

const AlertValueCell = ({ alertId, value, disabled, onChange }) => {
  const [optionsDisplayedUpwards, setOptionsDisplayedUpwards] = useState(true)
  const [listOpened, setListOpened] = useState(false)

  const ref = useRef()
  const optionsRef = useRef()

  useOnClickOutside(ref, () => {
    listOpened && setListOpened(false)
  })

  const toggleListOpened = () => {
    if (disabled) {
      return
    }

    setOptionsDisplayedUpwards(
      calculateListPosition(listOpened, optionsRef, 200)
    )

    setListOpened(!listOpened)
  }

  const handleOptionClick = (option) => {
    onChange(option, alertId)
    setListOpened(false)
  }

  return (
    <div
      className={cx('alert-value-cell', {
        'dropdown-open': listOpened,
        disabled,
      })}
      onClick={toggleListOpened}
      ref={ref}
    >
      {ALERT_DETAILS_MAP[value].icon}
      <ArrowIcon width={16} height={16} className="alert-value-cell__icon" />
      <div
        className={cx('alert-value-cell__options', {
          'alert-value-cell__options--up': optionsDisplayedUpwards,
        })}
        ref={optionsRef}
      >
        {listOpened && (
          <>
            {Object.keys(ALERT_VALUE_MAP).map((key) => (
              <div
                key={key}
                onClick={() => handleOptionClick(ALERT_VALUE_MAP[key])}
                className="display-flex padding-10"
              >
                {ALERT_DETAILS_MAP[ALERT_VALUE_MAP[key]].icon}
                <div className="margin-left-10 align-center">
                  {ALERT_VALUE_MAP[key]}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

AlertValueCell.propTypes = {
  alertId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default AlertValueCell
