import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'permissionGroups'

/** Initial state of the reducer */
const initialState = {
  list: null,
  permissionGroup: null,
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.GET_PERMISSION_GROUPS]: (state, { permissionGroups }) => {
    return { ...state, list: permissionGroups }
  },
  [ACTIONS.GET_PERMISSION_GROUP]: (state, { permissionGroup }) => {
    return { ...state, permissionGroup }
  },
  [ACTIONS.PUT_PERMISSION_GROUP]: (state, { permissionGroup }) => {
    if (!state.list) {
      return state
    }
    const newList = [...state.list]
    const foundIdx = newList.findIndex(
      (group) => group._id === permissionGroup._id
    )
    if (foundIdx !== -1) {
      // Have the updated element show at the top of the list
      ;[newList[foundIdx], newList[0]] = [newList[0], permissionGroup]
    } else {
      newList.unshift(permissionGroup)
    }

    return { ...state, list: newList }
  },

  [ACTIONS.DELETE_PERMISSION_GROUP]: (state, { _id }) => {
    if (!state.list) {
      return state
    }
    return {
      ...state,
      list: state.list.filter((group) => group._id !== _id),
    }
  },
}

export default { name, initialState, reduce }
