import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'alertThresholds'

/** Initial state of the reducer */
const initialState = {
  list: null,
  active: null, // Array for alert thresholds with associated active alerts
  query: null,
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.GET_ALERT_THRESHOLDS]: (state, { alertThresholds, query }) => {
    return { ...state, list: alertThresholds, query }
  },
  [ACTIONS.PUT_ALERT_THRESHOLD]: (state, { alertThreshold }) => {
    const newList = [...state.list]
    const foundIdx = newList.findIndex(
      (threshold) => threshold._id === alertThreshold._id
    )
    if (foundIdx !== -1) {
      newList[foundIdx] = alertThreshold
    } else {
      newList.unshift(alertThreshold)
    }

    return { ...state, list: newList }
  },

  [ACTIONS.DELETE_ALERT_THRESHOLD]: (state, { _id }) => ({
    ...state,
    list: state.list.filter((threshold) => threshold._id !== _id),
  }),
  [ACTIONS.GET_ACTIVE_ALERT_THRESHOLDS]: (
    state,
    { activeAlertThresholds }
  ) => ({
    ...state,
    active: activeAlertThresholds,
  }),
}

export default { name, initialState, reduce }
