import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { metrics, utils } from '@decision-sciences/qontrol-common'

/* Icons */
import { ReactComponent as PlusIcon } from 'assets/icon_plus_white.svg'

/* Utils */
import { useStore } from 'store'

/* Components */
import Button from 'components/button'
import LineDivider from 'components/line-divider/index'
import InformationBlock, {
  INFORMATION_BLOCK_TYPE,
} from 'components/information-block'
import BudgetPacingHeading from './panel-heading/index'
import BudgetPacingList from './budget-pacing-list/index'
import BudgetActions from './budget-actions/index'
import {
  BUDGET_PACING_TYPE,
  checkAllFieldsNull,
  defaultDimensionGroupOption,
  validateBudgetPacing,
} from '../utils'
import BudgetCSVUpload from './csv-upload/index'

const { mapKPIRow } = metrics
const { getUniqueObjects } = utils.array

/**
 * Budget Pacing create/edit panel & its functionalities
 * @param {Object} props
 * @param {Function} props.onSaveClicked - Method to be called when the save button is clicked
 * @param {Function} props.onCancelClicked - Method to be called when the cancel button is clicked
 * @param {Boolean} props.isEditingBudget- Is budget in edit mode
 * @param {Object} props.currentCompany- Current Edited company
 * @param {Function} props.updateBudgetPacing - Method to be called to update budget pacing settings
 * @param {String} props.budgets - Current client budgets
 * @param {Object} props.errors -  - Object with errors for budget
 * @param {Function} props.setErrors - Callback for update the errors
 * @param {Array} props.budgetSegments - Current client budget segments
 * @param {Object} props.dimensions - Current client dimension object
 * @param {Array} props.existingBudgetPacingSettings - Existing Budget Pacing Settings for client from db
 */
const BudgetPacingSettings = ({
  onSaveClicked,
  onCancelClicked,
  isEditingBudget,
  currentCompany,
  updateBudgetPacing,
  budgetSegments,
  dimensions,
  budgets,
  errors = {},
  setErrors,
  existingBudgetPacingSettings = [],
  inactiveCampaignsFlag,
  onChangeInactiveCampaignsFlag,
}) => {
  const [selectedType, setSelectedType] = useState(BUDGET_PACING_TYPE.STANDARD)
  const [csvFileName, setCsvFileName] = useState(null)
  const [footerInfoText, setFooterInfoText] = useState('')
  const isCustomBudget =
    selectedType === BUDGET_PACING_TYPE.CUSTOM || budgets[0]?.isCustomBudget

  const { state } = useStore()
  const { conversions } = state.accounts

  const conversionList = useMemo(() => {
    const allKpis = [
      ...(currentCompany.primaryKPIs || []),
      ...(currentCompany.secondaryKPIs || []),
      ...(currentCompany.reportingKPIs || []),
    ]

    const kpi = getUniqueObjects(['accountId', 'id'], allKpis).map(mapKPIRow)

    return kpi.filter((el) => conversions.some((c) => c.id === el.id))
  }, [
    currentCompany._id,
    JSON.stringify(conversions),
    JSON.stringify(currentCompany.primaryKPIs),
    JSON.stringify(currentCompany.secondaryKPIs),
    JSON.stringify(currentCompany.reportingKPIs),
  ])

  useEffect(() => {
    if (isCustomBudget && selectedType !== BUDGET_PACING_TYPE.CUSTOM) {
      setSelectedType(BUDGET_PACING_TYPE.CUSTOM)
    }
  }, [selectedType, isCustomBudget, JSON.stringify(budgets)])

  const hasValidDimensionInputs = useMemo(() => {
    if (isCustomBudget) {
      const budget = budgets[0]
      return budget.dimensionGroups.some((group) =>
        group.dimensionGroupOptions.some(
          (option) =>
            option.dimensionName !== '' && option.dimensionInput.length > 0
        )
      )
    }
    return true
  }, [budgets[0], isCustomBudget])

  const changeSelectedType = (type) => {
    const isCustomBudget = type === BUDGET_PACING_TYPE.CUSTOM
    const newItem = {
      local__uuid: uuidv4(),
      clientId: currentCompany._id,
      isCustomBudget,
    }
    if (isCustomBudget) {
      newItem.dimensionGroups = [
        {
          dimensionGroupOptions: [defaultDimensionGroupOption],
        },
      ]
    }
    setErrors({})
    updateBudgetPacing([newItem])
    setSelectedType(type)
  }

  const onAddClicked = () => {
    const newItem = {
      local__uuid: uuidv4(),
      clientId: currentCompany._id,
    }
    updateBudgetPacing([...budgets, newItem])
  }

  const onAddDimensionGroup = () => {
    const updatedBudgets = budgets.map((budget) => {
      return (budget = {
        ...budget,
        dimensionGroups: [
          ...budget.dimensionGroups,
          {
            dimensionGroupOptions: [defaultDimensionGroupOption],
          },
        ],
      })
    })
    updateBudgetPacing(updatedBudgets)
  }

  const validate = () => {
    // Create a set to keep track of the IDs of objects already in budgets
    const budgetIds = new Set(budgets.map((budget) => budget._id))

    // Filter out objects from existingBudgetPacingSettings that are already in budgets
    const uniqueExistingBudgetPacingSettings =
      existingBudgetPacingSettings.filter(
        (setting) => !budgetIds.has(setting._id)
      )

    // Combine budgets with the filtered existingBudgetPacingSettings, avoiding duplicates
    const combinedBudgets = [...budgets, ...uniqueExistingBudgetPacingSettings]

    const [isValid, errors] = validateBudgetPacing(combinedBudgets)

    setErrors(errors)
    return isValid
  }

  const onUpdateBudget = (updatedObject) => {
    const { budgets, error } = updatedObject

    setErrors({ ...errors, ...error })
    resetErrors()
    updateBudgetPacing(budgets)
  }

  const resetErrors = () => {
    if (checkAllFieldsNull(errors)) {
      setErrors({ ...errors, requiredFields: false })
    }

    if (errors.isDuplicated) {
      const newErrors = {}
      budgets.forEach((budget) => {
        newErrors[budget.local__uuid] = {}
      })
      setErrors({ ...newErrors, isDuplicated: false })
    }

    if (errors?.isOverlap) {
      const newErrors = {}
      budgets.forEach((budget) => {
        newErrors[budget.local__uuid] = {}
      })
      setErrors({ ...newErrors, isOverlap: false })
    }
  }

  return (
    <>
      {!isEditingBudget ? (
        <>
          <BudgetPacingHeading
            inactiveCampaignsFlag={inactiveCampaignsFlag}
            onChangeInactiveCampaignsFlag={onChangeInactiveCampaignsFlag}
            selectedType={selectedType}
            setSelectedType={changeSelectedType}
          />
          <LineDivider />
        </>
      ) : null}
      {selectedType !== BUDGET_PACING_TYPE.UPLOAD || csvFileName ? (
        <>
          <div className="padding-top-16">
            <BudgetPacingList
              budgets={budgets}
              dimensions={dimensions}
              errors={errors}
              isEditingBudget={isEditingBudget}
              budgetSegmentsList={budgetSegments}
              conversionList={conversionList}
              updateBudget={onUpdateBudget}
              setFooterInfoText={setFooterInfoText}
            />
          </div>
          {errors.requiredFields ? (
            <div className="padding-x-30 margin-bottom-16">
              <InformationBlock
                className="budget-information-block"
                type={INFORMATION_BLOCK_TYPE.ERROR}
                info={'All fields are required'}
              />
            </div>
          ) : null}
          {errors.isOverlap ? (
            <div className="padding-x-30 margin-bottom-16">
              <InformationBlock
                className="budget-information-block"
                type={INFORMATION_BLOCK_TYPE.ERROR}
                info={errors.isOverlap}
              />
            </div>
          ) : null}
          {errors.isDuplicated ? (
            <div className="padding-x-30 margin-bottom-16">
              <InformationBlock
                className="budget-information-block"
                type={INFORMATION_BLOCK_TYPE.ERROR}
                info={errors.isDuplicated}
              />
            </div>
          ) : null}
          {(!isEditingBudget || isCustomBudget) &&
          selectedType !== BUDGET_PACING_TYPE.UPLOAD ? (
            <>
              <div className="space-between padding-x-30 padding-y-10">
                <Button
                  className="budget-button-value button-selected"
                  secondary
                  disabled={
                    isCustomBudget && budgets[0]?.dimensionGroups.length > 2
                  }
                  onClick={() =>
                    isCustomBudget ? onAddDimensionGroup() : onAddClicked()
                  }
                  value={
                    <div className="display-flex">
                      <PlusIcon className="fill-white" />
                      <div className="budget-button-label">
                        {isCustomBudget
                          ? 'Add Dimension Group'
                          : 'Add Budget Segment'}
                      </div>
                    </div>
                  }
                />
              </div>
            </>
          ) : null}
        </>
      ) : (
        <BudgetCSVUpload
          setCsvFileName={setCsvFileName}
          updateBudget={updateBudgetPacing}
        />
      )}
      <BudgetActions
        onCancelClicked={onCancelClicked}
        saveDisabled={!hasValidDimensionInputs}
        onSaveClicked={() => {
          if (validate()) {
            onSaveClicked()
          }
        }}
        footerInfoText={footerInfoText}
      />
    </>
  )
}

export default BudgetPacingSettings

BudgetPacingSettings.propTypes = {
  onSaveClicked: PropTypes.func.isRequired,
  onCancelClicked: PropTypes.func.isRequired,
  budgetSegments: PropTypes.array.isRequired,
  currentCompany: PropTypes.object.isRequired,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  isEditingBudget: PropTypes.bool,
  updateBudgetPacing: PropTypes.func,
  budgets: PropTypes.array,
  dimensions: PropTypes.object,
  existingBudgetPacingSettings: PropTypes.array,
  inactiveCampaignsFlag: PropTypes.bool,
  onChangeInactiveCampaignsFlag: PropTypes.func.isRequired,
}
