import React from 'react'
import PropTypes from 'prop-types'

import { FREQUENCY_OPTIONS } from '@decision-sciences/qontrol-common/src/notifications'

import Table from 'components/table/beta'
import Recurrence from 'components/recurrence'
import useLeaveConfirm from 'components/leave-confirm'
import AlertStartTime from 'modules/alerts/alert-start-time'

export const AlertNotificationGroupSection = ({
  state = {},
  hasEditAccess,
  setState,
  editField,
  errors,
  readOnlyCanToggleMode,
  isRestrictiveEdit,
}) => {
  const [setDirty] = useLeaveConfirm({})
  return (
    <div>
      {state.notificationGroup ? (
        <section className="form__section">
          <div className="form__section__header">Notification Group</div>
          <div className="form__section__body">
            <p className="form__section__body__explanation">
              This alert belongs to the group below. Click the remove icon to
              remove this alert from the group and revert the start time to its
              original setting.
            </p>
            <Table
              columns={[
                {
                  header: '',
                  id: 'actions',
                  cell: (cell) => (
                    <div
                      className={hasEditAccess ? 'embarq-template__remove' : ''}
                      onClick={() => {
                        if (hasEditAccess) {
                          setDirty(true)
                          setState({
                            notificationGroupIdRemoved: cell.row.original._id,
                            notificationGroup: null,
                          })
                        }
                      }}
                    />
                  ),
                  size: 5,
                  maxSize: 55,
                },
                {
                  header: 'Group Name',
                  accessorFn: (row) => row?.name,
                },
              ]}
              data={[state.notificationGroup]}
            />
            <p className="form__section__body__explanation">
              The start time for this alert has been overridden by the group and
              is not editable here.
            </p>
            <AlertStartTime
              defaultValue={state.notificationGroup?.startTime}
              viewOnly
              onChange={() => {}}
              recurrence={state.recurrence}
            />
          </div>
        </section>
      ) : (
        <section
          className="form__section"
          data-cy="alert-start-time-and-frequency"
        >
          <div className="form__section__header">
            Alert Start Time & Frequency
          </div>
          <div className="form__section__body">
            <span className="general-label" data-cy="alert-start-date-label">
              Alert Start Date & Time
            </span>
            <AlertStartTime
              defaultValue={state.startTime}
              viewOnly={!hasEditAccess}
              onChange={(startTime) => {
                hasEditAccess && editField('startTime', startTime)
                // If start time doesn't have set default value on component mount we don't need to trigger dirty state
                if (!state.startTime) {
                  setDirty(false)
                }
              }}
              recurrence={state.recurrence}
            />
            <span
              className="general-label margin-top-10"
              data-cy="alert-frequency-label"
            >
              Alert Frequency
            </span>
            <Recurrence
              showInGroup
              defaultState={state.recurrence}
              onChange={(recurrence) =>
                hasEditAccess && editField('recurrence', recurrence)
              }
              errors={errors?.recurrence}
              frequencyOptions={FREQUENCY_OPTIONS.slice(0, 3).reverse()}
              showDateHoursFields={false}
              disabled={
                !hasEditAccess ||
                isRestrictiveEdit ||
                readOnlyCanToggleMode ||
                state.notificationGroup
              }
            />
          </div>
        </section>
      )}
    </div>
  )
}

AlertNotificationGroupSection.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,
  hasEditAccess: PropTypes.bool,
  editField: PropTypes.func,
  errors: PropTypes.object,
  isRestrictiveEdit: PropTypes.bool,
  readOnlyCanToggleMode: PropTypes.bool,
}
