import { ReactComponent as PlusIcon } from 'assets/icon_plus_blue.svg'
import { ReactComponent as RemoveIcon } from 'assets/icon_remove_circle_outline.svg'
import { AccountInformation } from 'components/account-icon/index'
import Button from 'components/button/index'
import { isInViewport } from 'components/utils/dom-manipulation'
import { scrollToElement } from 'components/utils/scroll'
import PropTypes from 'prop-types'
import { useState } from 'react'
import './style.scss'

const DEFAULT_VISIBLE_LIMIT = 10

/**
 * Renders a list of selected KPIs without additional actions
 * @param {Object} props Component props
 * @param {String} [props.id] Custom ID to attach to the DOM element
 * @param {Array} [props.kpis = []] Selected KPIs
 * @returns {React.FunctionComponent}
 */
const KpiList = ({ id, kpis = [] }) => {
  const [visibleLimit, setVisibleLimit] = useState(DEFAULT_VISIBLE_LIMIT)

  const canShowMore = kpis.length > DEFAULT_VISIBLE_LIMIT
  const isListExpanded = visibleLimit !== DEFAULT_VISIBLE_LIMIT

  const toggleListVisibleSize = () => {
    if (!canShowMore) {
      return
    }
    if (isListExpanded && !isInViewport(id)) {
      scrollToElement(id)
    }
    setVisibleLimit(isListExpanded ? DEFAULT_VISIBLE_LIMIT : kpis.length)
  }

  const getExternalAccountId = (kpi) => {
    const { accountId, accountName, source } = kpi
    let externalAccountId = `Account: ${accountName} (${accountId})`
    if (source) {
      externalAccountId = `${externalAccountId} | ${source}`
    }
    return externalAccountId
  }

  if (!kpis.length) {
    return null
  }

  return (
    <div id={id} className="kpi-list">
      {kpis.slice(0, visibleLimit).map((kpi) => {
        const { label, type, key } = kpi
        return (
          <AccountInformation
            key={key}
            accountName={label}
            accountType={type}
            externalAccountId={getExternalAccountId(kpi)}
          />
        )
      })}
      {canShowMore && (
        <Button
          compact
          secondary
          value={
            <div className="align-center gap-8">
              {isListExpanded ? (
                <RemoveIcon width={14} height={14} />
              ) : (
                <PlusIcon width={10} height={10} />
              )}
              <span>
                {isListExpanded ? 'Show less' : `Show all (${kpis.length})`}
              </span>
            </div>
          }
          onClick={toggleListVisibleSize}
          className="width-100"
        />
      )}
    </div>
  )
}

KpiList.propTypes = {
  id: PropTypes.string,
  kpis: PropTypes.array,
}

export { KpiList }
