import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'store'
import isEqual from 'lodash.isequal'
import { format } from 'date-fns'

// Components
import Button from 'components/button'
import Table from 'components/table/beta'
import Loader from 'components/loader'
import CompanyFilters from 'modules/companies/client-filters'
import { COLUMN_HEADERS } from 'modules/companies/bulk-edit-companies'

// Constants
import { TABLE_CONTAINER } from 'modules/table-filter-sort/constants'

// Utils
import { getTooltipList } from 'components/utils/tooltip'

// Hooks
import { useAccess, PERMISSION_TYPES, PERMISSIONS } from 'hooks/access'

// Actions
import { saveSort } from 'modules/table-filter-sort/actions'
import { entityStatus } from '@decision-sciences/qontrol-common'
import { getCompanies } from 'modules/companies/actions'

import './style.scss'

const { ENTITY_STATUS_LABEL } = entityStatus

const CompanyManagementModule = () => {
  const tableContainer = TABLE_CONTAINER.CLIENT_MANAGEMENT
  const navigate = useNavigate()
  const { dispatch, state } = useStore()
  const {
    users: { list: usersList },
  } = state

  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.CLIENT_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.CLIENT_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasViewAccess = useAccess({
    feature: PERMISSIONS.CLIENT_DATA_FORM,
    type: PERMISSION_TYPES.READ,
  })

  const [tableLoading, setTableLoading] = useState(false)
  const [tableCompanies, setTableCompanies] = useState([])
  const clientUsers = (row) =>
    usersList.filter((user) =>
      user.clients.find((el) =>
        row.parentCompany
          ? el.clientId === row.parentCompany &&
            el.businessUnits.includes(row._id)
          : el.clientId === row._id
      )
    )
  const filterSort = state.tableFilterSort.filterSort[tableContainer]

  useEffect(() => {
    if (filterSort?.filter && filterSort.filter.companyIds !== null) {
      setTableLoading(true)
      const query = {
        companyIds: filterSort.filter.companyIds,
      }
      getCompanies(dispatch, query, false)
        .then((res) => {
          if (res && res.companies) {
            setTableCompanies(res.companies)
          }
        })
        .finally(() => setTableLoading(false))
    } else {
      // If no filters have been applied or initial page load, we load all companies
      setTableLoading(true)
      getCompanies(dispatch, { deleted: false }, false)
        .then((res) => {
          if (res && res.companies) {
            setTableCompanies(res.companies)
          }
        })
        .finally(() => setTableLoading(false))
    }
  }, [JSON.stringify(filterSort?.filter)])

  const columns = [
    {
      header: COLUMN_HEADERS.ACTIONS,
      id: COLUMN_HEADERS.ACTIONS,
      cell: (cell) => {
        const { clientId } = cell.row.original
        const isClientDeleted = cell.row.original.deleted
        if (!hasViewAccess) {
          return null
        }
        return (
          <div className="table__actions align-center">
            <div
              className={
                hasEditAccess && !isClientDeleted
                  ? 'table__edit'
                  : 'table__view'
              }
              onClick={() => {
                setTimeout(() => {
                  navigate(`/company/${clientId}`)
                }, 100)
              }}
            />
          </div>
        )
      },
      size: 40,
      maxSize: 40,
    },
    {
      header: COLUMN_HEADERS.CLIENT,
      accessorKey: 'name',
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 300,
      sortingFn: 'name',
    },
    {
      header: COLUMN_HEADERS.BUSINESS_UNITS,
      id: COLUMN_HEADERS.BUSINESS_UNITS,
      accessorFn: (row) => (row.businessUnits ? row.businessUnits.length : 0),
      tooltip: (row) => {
        if (!row.businessUnits || !row.businessUnits.length) {
          return null
        }
        return getTooltipList(
          'Business Units',
          row.businessUnits.map((item) => item.name)
        )
      },
      size: 90,
      textAlign: 'right',
      cellTextAlign: 'right',
      sortingFn: 'alphanumeric',
    },
    {
      header: COLUMN_HEADERS.ACCOUNTS,
      id: COLUMN_HEADERS.ACCOUNTS,
      accessorFn: (row) => {
        return (row.businessUnits || []).reduce((arr, businessUnit) => {
          return [...arr, ...businessUnit.accounts]
        }, row.accounts || []).length
      },
      tooltip: (row) => {
        const allAccounts = (row.businessUnits || []).reduce(
          (arr, businessUnit) => {
            return [
              ...arr,
              ...businessUnit.accounts.map((acc) => ({
                ...acc,
                name: `${acc.name} *`,
              })),
            ]
          },
          row.accounts || []
        )
        if (!allAccounts.length) {
          return null
        }

        return getTooltipList(
          'Accounts',
          allAccounts.map((item) => item.name)
        )
      },
      size: 90,
      textAlign: 'right',
      cellTextAlign: 'right',
      sortType: 'alphanumeric',
    },
    {
      id: COLUMN_HEADERS.USERS,
      header: COLUMN_HEADERS.USERS,
      accessorFn: (row) => {
        return clientUsers(row)?.length
      },
      tooltip: (row) => {
        if (clientUsers(row)?.length) {
          return getTooltipList(
            'Users',
            clientUsers(row)?.map((item) => item.name)
          )
        }
      },
      size: 90,
      textAlign: 'right',
      cellTextAlign: 'right',
      sortingFn: 'alphanumeric',
    },
    {
      header: COLUMN_HEADERS.DATE_ADDED,
      id: COLUMN_HEADERS.DATE_ADDED,
      cell: (cell) =>
        format(new Date(cell.row.original.createdAt), 'MM/dd/yyyy'),
      accessorKey: 'createdAt',
      textAlign: 'center',
      cellTextAlign: 'center',
      size: 80,
      sortUndefined: 1,
      sortingFn: 'date',
    },
    {
      header: COLUMN_HEADERS.STATUS,
      id: 'status',
      cell: (cell) => {
        const { active } = cell.row.original
        const { deleted } = cell.row.original
        return <span>{deleted ? 'Archived' : ENTITY_STATUS_LABEL[active]}</span>
      },
      size: 80,
      textAlign: 'center',
      cellTextAlign: 'center',
      accessorFn: (row) => (row.active ? 1 : 0),
      sortType: 'alphanumeric',
    },
  ]

  const sortChanged = (newSort) => {
    if (!isEqual(newSort, filterSort.sort)) {
      saveSort(dispatch, tableContainer, newSort)
    }
  }

  if (!tableCompanies) {
    return <Loader />
  }
  // const _renderEditButton = () => {
  //   if (!selectedCompanies.length) {
  //     return
  //   }
  //   return (
  //     <Button
  //       value="Edit Selected"
  //       secondary
  //       onClick={() => {
  //         setBulkEditCompanies(dispatch, selectedCompanies)
  //         setBulkEdit(true)
  //       }}
  //     />
  //   )
  // }

  const _renderAddButton = () => {
    if (!hasCreateAccess) {
      return
    }
    return (
      <Button value="Add Client" onClick={() => navigate('/company/new')} />
    )
  }

  // Bulk Edit related properties
  // const clearStates = () => {
  //   setBulkEdit(false)
  //   setSelectedCompanies([])
  // }

  // const onApplyChanges = async (changes) => {
  //   const companyIds = selectedCompanies.map((comp) => comp._id)
  //   const clearedChanges = removeEmptyKeys(changes)
  //   await bulkEditCompanies(companyIds, clearedChanges)
  //   clearStates()
  //   getCompanies(dispatch, { deleted: false })
  // }

  // if (bulkEdit) {
  //   return (
  //     <BulkEditCompanies
  //       onApplyChanges={onApplyChanges}
  //       onCancelChanges={clearStates}
  //     />
  //   )
  // }

  return (
    <div className="companies">
      <Helmet>
        <title>Clients</title>
      </Helmet>
      <div className="heading" data-cy="page-heading">
        Clients
        <div className="heading__buttons">
          {/* {_renderEditButton()} */}
          {_renderAddButton()}
        </div>
      </div>
      <CompanyFilters tableContainer={tableContainer} />
      <Table
        loading={tableLoading}
        columns={columns}
        data={tableCompanies.map((company) => ({
          ...company,
          disableSelect: company.deleted,
        }))}
        // onSelect={hasEditAccess ? setSelectedCompanies : null}
        initialState={{ sortBy: filterSort.sort }}
        tableContainer={tableContainer}
        onSortChanged={sortChanged}
      />
    </div>
  )
}

export default CompanyManagementModule
