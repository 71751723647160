import React from 'react'

import CheckboxSelectAll, {
  CHECKBOX_VALUES,
} from 'components/checkbox-select-all/index'
import { RadioNoHooks } from 'components/radio/index'

export const getSelectColumn = (
  hideRowSelectionFn,
  nonDeletableEntities,
  singleSelection
) => {
  return {
    id: 'select',
    header: ({ table }) => {
      // In case there is no row selectable in the table, do not display the checkbox
      if (!table.getCoreRowModel().flatRows.some((row) => row.getCanSelect())) {
        return null
      }

      if (singleSelection) {
        return null
      }

      return (
        <CheckboxSelectAll
          className="table__select"
          value={
            table.getIsAllRowsSelected()
              ? CHECKBOX_VALUES.ALL
              : table.getIsSomeRowsSelected()
              ? CHECKBOX_VALUES.SOME
              : CHECKBOX_VALUES.NONE
          }
          onClick={table.getToggleAllRowsSelectedHandler()}
        />
      )
    },
    cell: (cell) => {
      if (hideRowSelectionFn && hideRowSelectionFn(cell)) {
        return null
      }

      if (
        nonDeletableEntities &&
        nonDeletableEntities.includes(cell.row.original._id)
      ) {
        return <div className="table__checkbox" />
      }

      const rows = Object.values(cell.table.getRowModel().rowsById)

      if (singleSelection) {
        return (
          <RadioNoHooks
            checked={cell.row.getIsSelected()}
            onChange={() => {
              rows.map((r) => r.toggleSelected(false))
              cell.row.toggleSelected()
            }}
            className="blue"
          />
        )
      }

      return (
        <CheckboxSelectAll
          className="table__select"
          disabled={cell.row.original.disableSelect}
          value={
            cell.row.getIsSelected()
              ? CHECKBOX_VALUES.ALL
              : CHECKBOX_VALUES.NONE
          }
          isBlue
          onClick={() => cell.row.toggleSelected()}
        />
      )
    },
    size: 40,
    maxSize: 40,
  }
}
