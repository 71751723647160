import React from 'react'
import PropTypes from 'prop-types'

import Modal from 'components/modal'
import Button from 'components/button'

/**
 * Params for Delete Modal
 * @param {Object} params React Params
 * @param {Object} params.toDelete Object to delete
 * @param {Function} params.setOpen Setter for open
 * @param {String} params.className ClassName to set to Modal
 * @param {Boolean} params.loading Whether the modal is loading or not
 * @param {Function} params.onDelete Function to call when deleting
 */
const DeleteModal = ({ toDelete, className, setOpen, loading, onDelete }) => {
  if (!toDelete) {
    return null
  }

  return (
    <Modal
      opened={Boolean(toDelete)}
      button={
        <Button
          dataTestId="primary-button"
          disabled={loading}
          value="Delete"
          onClick={onDelete}
        />
      }
      buttonSecondary={
        <Button
          dataTestId="secondary-button"
          disabled={loading}
          value="Cancel"
          onClick={() => setOpen(false)}
          secondaryGray
        />
      }
      heading={`Are you sure you want to delete ${toDelete.name || 'this'}?`}
      className={className}
    />
  )
}

DeleteModal.propTypes = {
  toDelete: PropTypes.object,
  setOpen: PropTypes.func.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
}

export default DeleteModal
