import React, { useState, useEffect, useMemo } from 'react'
import cx from 'classnames'
import { useStore } from 'store'
import useSession from 'modules/session'
import { useNavigate, useLocation } from 'react-router-dom'
import Tooltip from 'components/tooltip'
import arrowsIcon from 'assets/icon_menu_arrows.svg'
import { setLeftMenu } from 'components/left-menu/actions'
import { getCurrentCompanyReportings } from 'components/utils/reports'
import { logout } from 'modules/session/actions'
import Button from 'components/button'
import { access, legal, flow } from '@decision-sciences/qontrol-common'
import { useAccess, PERMISSION_TYPES, PERMISSIONS } from 'hooks/access'
import { getReportingsForUser } from 'modules/companies/subsections/reports/utils'

import logoutIcon from 'assets/icon_slide_right.svg'
import iconCheck from 'assets/icon_check_white.svg'
import iconCross from 'assets/icon_fill_cross_white.svg'
import { ReactComponent as ArrowIcon } from 'assets/icon_arrow_blue.svg'

import {
  FIXED_LEFT_MENU_TABS,
  OPERATIONAL_TABS,
  ROUTE_TO_TAB,
  LEFT_MENU_2_ROWS_LENGTH,
  ANALYTICS_KEYS,
} from './constants'
import './style.scss'

const { STATUS } = flow
const { hasAccess, routeAccess } = access
const { LEGAL_DISPLAY_AREA, LEGAL_TYPES } = legal

const LeftMenu = () => {
  const { dispatch } = useStore()
  const [, userData] = useSession()
  const navigate = useNavigate()
  const location = useLocation()

  const currentRoute = location.pathname

  const {
    state: {
      // globalReports,
      companies: { currentCompany, list },
      legal: { viewList },
      traqTemplates,
      flow,
      leftMenu: menuState,
      global: {
        environment: { HIDE_DATA_STORE, HIDE_AUTOMATED_QA },
      },
    },
  } = useStore()

  // const { favList: globalReportFavList, viewList: globalReportViewList } =
  //   globalReports || {}
  const [logoutTabOpen, setLogoutTabOpen] = useState(false)
  const [animationsDisabled, setAnimationsDisabled] = useState(true)
  /**
   * Method that checks if the user has access to a Tab by checking the route linked to it
   * @param {{route: String}} tab
   * @returns {Boolean}
   */
  const hasTabAccess = (tab) => {
    const requiredPermissions = routeAccess[tab.route]
    return hasAccess(userData, requiredPermissions)
  }

  /** Method that checks if a user has access to any of the Client Tabs */
  const accessClientsTab = !!OPERATIONAL_TABS.find(hasTabAccess)

  // TODO [hub analysis]robert remove this when reports analysis implementation is complete
  const HIDE_HUB_ANALYSIS = userData.isSuperAdmin ? false : true

  /** Filter fixed tabs */
  const fixedTabs = FIXED_LEFT_MENU_TABS(
    userData.isSuperAdmin ? 'off' : HIDE_DATA_STORE,
    HIDE_AUTOMATED_QA,
    HIDE_HUB_ANALYSIS
  ).filter((tab) => {
    // For fixed tabs check if we have at least one secondary tab that has access
    return tab.openMenuOptions.find(hasTabAccess)
  })

  const { opened, selectedFixedTab, selectedSlidingTab, selectedSlidingTabs } =
    menuState
  const [hoveredItem, setHoveredItem] = useState(null)

  const hideSlidingMenu = useMemo(() => {
    return hideSlidingMenuByCurrentRoute(currentRoute, flow)
  }, [currentRoute, JSON.stringify(flow)])

  // Disable animations for 100ms after hideSlidingMenu changes to avoid unwanted animations
  useEffect(() => {
    setAnimationsDisabled(true)
    const timeout = setTimeout(() => {
      setAnimationsDisabled(false)
    }, 100)

    return () => {
      clearTimeout(timeout)
    }
  }, [hideSlidingMenu])

  const menuClass = cx('left-menu-container align-row', {
    'left-menu-container--opened': opened,
    'left-menu-container--closed': !opened,
    'left-menu-container--hide-sliding': hideSlidingMenu,
    'left-menu-container--animations-disabled': animationsDisabled,
  })
  const slidingMenuClass = cx(
    'left-menu-container__sliding-menu align-column',
    {
      'left-menu-container__sliding-menu--hidden': hideSlidingMenu,
    }
  )
  const switcherClass = cx('left-menu-container__switch align-center', {
    'left-menu-container__switch--rotate': !opened,
    'left-menu-container__switch--hidden': hideSlidingMenu,
  })

  /** Filter a list of tabs by access */
  const filterTabsByAccess = (tabs) => {
    if (!tabs) {
      return []
    }
    return tabs.filter((el) => hasTabAccess(el))
  }

  const traQDashboardAccess = useAccess({
    feature: PERMISSIONS.TRAQ_DASHBOARD,
    type: PERMISSION_TYPES.READ,
  })

  // TODO [Titus]: To be replaced with permissions when implementing permissions for Data Store
  const hasDataStoreAccess = true

  /** Triggered when clicking something in the menu. Alters the menu and redirects to the clicked page */
  const alterMenuState = (isFixedMenu, tab, opened, isSubItem) => {
    // saving in localstorage the menu state
    // opened              => if the menu is collapsed or expanded
    // selectedFixedTab    => current selected tab on the non-sliding (fixed) menu(fb, google....)
    // selectedSlidingTabs => set the options menu for the sliding right menu (different with each fixed tab)
    // selectedSlidingTab  => set the selected tab (which will change the route to the desired section)

    // Filter available secondary tabs
    const slidingTabOptions = filterTabsByAccess(tab.openMenuOptions)

    let newSelectedSlidingTabs

    if (isFixedMenu) {
      newSelectedSlidingTabs = [
        ...menuState.selectedSlidingTabs.filter(({ hidden }) => !hidden),
      ]
    } else if (tab.collapsible) {
      newSelectedSlidingTabs = [
        ...menuState.selectedSlidingTabs.filter(({ key }) => key !== tab.key),
        tab,
      ]
    } else if (isSubItem) {
      const parentSlidingTab = menuState.selectedSlidingTabs.find(({ items }) =>
        items?.some(({ key }) => key === tab.key)
      )
      newSelectedSlidingTabs = [
        ...menuState.selectedSlidingTabs.filter(
          ({ key }) => key !== parentSlidingTab.key
        ),
        {
          ...parentSlidingTab,
          expanded: true,
          items: [
            ...parentSlidingTab.items.filter(({ key }) => key !== tab.key),
            { ...tab, opened },
          ],
        },
      ]
    } else {
      newSelectedSlidingTabs = menuState.selectedSlidingTabs
    }

    // Construct new state
    const newState = {
      opened,
      selectedFixedTab: isFixedMenu ? tab.key : menuState.selectedFixedTab,
      selectedSlidingTabs: newSelectedSlidingTabs,
      selectedSlidingTab: isFixedMenu ? slidingTabOptions?.[0]?.key : tab.key,
    }

    // Save new State
    setLeftMenu(dispatch, newState)

    // Depending on what the user selected, redirect to the proper page
    if (isFixedMenu && slidingTabOptions.length) {
      let route = slidingTabOptions[0].route
      if (tab.key === 'USER_SETTINGS') {
        route = `${slidingTabOptions[0].route}${slidingTabOptions[0].section}`
      }
      navigate(route)
    } else {
      let route = tab.route
      if (selectedFixedTab === 'USER_SETTINGS') {
        route = `${tab.route}${tab.section}`
      }
      navigate(route)
    }
  }

  /** Fill Analytics menu with data */
  useEffect(() => {
    const analyticsTab = fixedTabs.find((t) => t.key === 'ANALYTICS')
    let traQTemplatedFavsToAdd = []
    let globalTemplatesToAdd = []

    const routePrefix = currentRoute.split('/')[1]
    const id = currentRoute.split('/')[2]

    // if (globalReportFavList.length && analyticsTab) {
    //   globalTemplatesToAdd =
    //     globalReportFavList.map(({ name, _id, isCustom }) => ({
    //       key: _id,
    //       label: name,
    //       route: isCustom ? `/reports/${_id}` : `/tableau-dashboard/${_id}`,
    //       access: true,
    //     })) || []
    // } else if (routePrefix === 'tableau-dashboard' && !id) {
    //   // we are currently on a reportings page
    //   navigate('/tableau-dashboard')
    // }

    // If there are favourite traQ Templates selected by the user, fill them in
    if (
      traqTemplates?.userTraq?.length &&
      analyticsTab &&
      traQDashboardAccess
    ) {
      traQTemplatedFavsToAdd =
        traqTemplates?.userTraq
          ?.filter(
            ({ traqTemplate, favorite }) =>
              !traqTemplate?.deleted && favorite && traqTemplate?.active
          )
          ?.map(({ traqTemplate: { name, _id } }) => ({
            key: _id,
            label:
              name?.length > LEFT_MENU_2_ROWS_LENGTH
                ? `${name.substring(0, LEFT_MENU_2_ROWS_LENGTH)}...`
                : name,
            route: `/embarq-dashboard/${_id}`,
            access: true,
            tooltip: name?.length > LEFT_MENU_2_ROWS_LENGTH ? name : false,
          })) || []
    } else if (routePrefix === 'embarq-dashboard' && !id) {
      // we are currently on a traq-dashboard page
      navigate('/embarq-dashboard')
    }

    if (analyticsTab?.openMenuOptions) {
      analyticsTab.openMenuOptions = analyticsTab.openMenuOptions.map(
        (option) => {
          const { key, collapsible } = option

          if (!collapsible) {
            return option
          } else {
            let items

            if (key === ANALYTICS_KEYS.TRAQ_DASHBOARD) {
              items = [...traQTemplatedFavsToAdd]
            } else if (key === ANALYTICS_KEYS.TABLEAU_DASHBOARD) {
              items = [...globalTemplatesToAdd]
            }

            return { ...option, items }
          }
        }
      )
    }

    /** Select corresponding menu element based on current route */
    let route = `/${routePrefix}`
    if (currentRoute === '/') {
      return
    }
    if (routePrefix === 'my-account') {
      route = currentRoute
    }

    let foundItem
    // let foundItem = menuState && menuState.selectedFixedTab
    let selectedSlidingTab

    // if (route === '/reports' || route === '/tableau-dashboard') {
    //   // find global report corresponding to id in url``
    //   foundItem = foundItem || fixedTabs.find((i) => i.key === 'ANALYTICS')
    //   selectedSlidingTab = foundItem.openMenuOptions?.find(
    //     (op) => op.key === (id || ANALYTICS_KEYS.TABLEAU_DASHBOARD)
    //   )?.key

    //   // Sub-item case
    //   if (!selectedSlidingTab) {
    //     selectedSlidingTab = foundItem.openMenuOptions
    //       .find(({ key }) => key === ANALYTICS_KEYS.TABLEAU_DASHBOARD)
    //       ?.items?.find((op) => op?.key === id)?.key
    //   }
    // }
    //  else
    if (route === '/embarq-dashboard') {
      // find traq-dashboard corresponding to id in url
      foundItem = foundItem || fixedTabs.find((i) => i.key === 'ANALYTICS')
      const id = currentRoute.split('/')[2] || ANALYTICS_KEYS.TRAQ_DASHBOARD
      selectedSlidingTab = foundItem.openMenuOptions?.find(
        (op) => op?.key === id
      )?.key

      // Sub-item case
      if (!selectedSlidingTab) {
        selectedSlidingTab = foundItem.openMenuOptions
          .find(({ key }) => key === ANALYTICS_KEYS.TRAQ_DASHBOARD)
          ?.items?.find((op) => op?.key === id)?.key
      }
    } else {
      // In case we have something like /alert-triggers and /alert-triggers/archive set up in ROUTE_TO_TAB
      const routeKey = ROUTE_TO_TAB[currentRoute] || ROUTE_TO_TAB[route]
      foundItem = fixedTabs.find((item) =>
        item.openMenuOptions?.find((sItem) => sItem.key === routeKey)
      )
      selectedSlidingTab = routeKey
    }

    if (route === '/legal' && currentRoute.split('/')[2] === 'cms') {
      // In case we edit an item from legal list
      const legalRoute = `${route}/${currentRoute.split('/')[2]}`
      selectedSlidingTab = ROUTE_TO_TAB[legalRoute]
    }

    // If no found item default to General
    if (!foundItem) {
      foundItem = fixedTabs.find((el) => el.key === 'GENERAL')
    }

    // If we're on a sub-menu, forcefully keep its parent collapsible drawer open
    const isSubItem =
      menuState.selectedSlidingTabs?.some(({ expanded }) => !!expanded) || id

    let newSelectedSlidingTabs = foundItem?.openMenuOptions.filter(
      ({ hidden }) => !hidden
    )

    if (isSubItem) {
      const parentSlidingTab = menuState.selectedSlidingTabs.find(({ items }) =>
        items?.some(({ key }) => key === id)
      )

      if (parentSlidingTab) {
        const currentSubTab = parentSlidingTab.items.find(
          ({ key }) => key === id
        )

        newSelectedSlidingTabs = [
          ...menuState.selectedSlidingTabs.filter(
            ({ key }) => key !== parentSlidingTab.key
          ),
          {
            ...parentSlidingTab,
            expanded: true,
            items: [
              ...parentSlidingTab.items.filter(({ key }) => key !== id),
              { ...currentSubTab, opened },
            ],
          },
        ]
      }
    }

    const newMenuState = {
      ...menuState,
      selectedFixedTab: foundItem?.key,
      selectedSlidingTabs: newSelectedSlidingTabs,
      selectedSlidingTab,
    }

    setLeftMenu(dispatch, newMenuState, true)
  }, [
    currentCompany,
    JSON.stringify(traqTemplates.userTraq),
    // JSON.stringify(globalReportFavList),
    currentRoute,
  ])

  const logoutTabClass = cx(
    'left-menu-container__logout left-menu-container__fixed-tabs',
    {
      ['left-menu-container__logout--open']: logoutTabOpen,
    }
  )

  /** TraQ Templates associated to the currently seleted comapny */
  const traQTemplatesOfCurrentCompany = useMemo(
    () =>
      traqTemplates?.list?.filter(
        ({ allCompaniesSelected, companies }) =>
          allCompaniesSelected || companies?.includes(currentCompany?._id)
      ) || [],
    [JSON.stringify(traqTemplates?.list), currentCompany?._id]
  )

  return (
    <div className={menuClass}>
      <div
        className={switcherClass}
        onClick={() => setLeftMenu(dispatch, { ...menuState, opened: !opened })}
      >
        <img src={arrowsIcon} alt={'menu switcher'} />
      </div>
      <div className="left-menu-container__fixed-menu align-column">
        {fixedTabs.map((fixedTab, f) => {
          const disabled =
            fixedTab.disabled ||
            // Disable reports menu option if there are no reports and no traQ templates for the selected company
            (fixedTab.key === 'ANALYTICS' &&
              !getCurrentCompanyReportings(currentCompany?._id, list)?.length &&
              !traQTemplatesOfCurrentCompany?.length &&
              !globalReportViewList?.length &&
              !hasDataStoreAccess)

          let icon = fixedTab.icon
          if (selectedFixedTab === fixedTab.key) {
            icon = fixedTab.iconActive
          }
          if (disabled && fixedTab?.iconDisabled) {
            icon = fixedTab.iconDisabled
          }

          const fixedTabClass = cx(
            'left-menu-container__fixed-tabs align-center',
            {
              'left-menu-container__fixed-tabs--selected':
                selectedFixedTab === fixedTab.key,
              'left-menu-container__fixed-tabs--hide-no-access':
                !accessClientsTab && fixedTab.key === 'GENERAL',
              'left-menu-container__fixed-tabs--disabled': disabled,
            }
          )
          return (
            <div
              data-cy={`left-menu-item-${fixedTab.key}`}
              key={f}
              className={fixedTabClass}
              onClick={() =>
                !disabled && alterMenuState(true, fixedTab, opened)
              }
            >
              {fixedTab.icon === 'avatar' ? (
                <span className="align-center">
                  {userData.isSuperAdmin
                    ? '3Q'
                    : `${userData.firstName.match(
                        /^\b(\w)/g
                      )}${userData.lastName.match(/^\b(\w)/g)}`}
                </span>
              ) : (
                <img
                  src={icon}
                  alt={fixedTab.label}
                  style={{ height: '22px', width: '22px' }}
                />
              )}
            </div>
          )
        })}
        <div className={logoutTabClass}>
          <div
            className="left-menu-container__logout__overlay"
            onClick={() => {
              setLogoutTabOpen(false)
            }}
          />
          <div
            className="left-menu-container__logout__button"
            onClick={() => {
              setLogoutTabOpen(!logoutTabOpen)
            }}
          >
            <img src={logoutIcon} alt="logout" />
          </div>

          <div className="left-menu-container__logout__popup">
            Log out?{' '}
            <div style={{ display: 'flex' }}>
              <Button
                onClick={() => {
                  logout(dispatch)
                  setLogoutTabOpen(false)
                }}
                isIconButton
                value={iconCheck}
                green
              />
              <Button
                onClick={() => {
                  setLogoutTabOpen(false)
                }}
                value={iconCross}
                isIconButton
                red
              />
            </div>
          </div>
        </div>
      </div>
      <div className={slidingMenuClass}>
        {filterTabsByAccess(selectedSlidingTabs)
          ?.sort((a, b) => {
            const { order: orderA } = a
            const { order: orderB } = b

            return orderA <= orderB ? -1 : 1
          })
          ?.map((tab, idx) => (
            <div
              key={idx}
              className={cx('left-menu-container__tabs', {
                'left-menu-container__tabs--show': opened,
                'left-menu-container__tabs--column': tab.expanded && tab.items,
              })}
              style={
                tab.expanded && tab.items
                  ? { height: `${(tab.items.length + 1) * 56}px` }
                  : null
              }
            >
              <div
                className={cx('left-menu-container__tabs__item', {
                  'left-menu-container__tabs--selected':
                    selectedSlidingTab === tab.key,
                })}
                onClick={() =>
                  alterMenuState(
                    false,
                    { ...tab, expanded: !tab.expanded },
                    opened
                  )
                }
                onMouseEnter={() => setHoveredItem(tab.key)}
                onMouseLeave={() => setHoveredItem(null)}
              >
                <span className="tab-label">{tab.label}</span>
                {tab.icon && !opened && (
                  <div
                    className="tab-image"
                    style={{
                      WebkitMaskImage: `url(${tab.icon})`,
                      maskImage: `url(${tab.icon})`,
                      WebkitMaskRepeat: 'no-repeat',
                      maskRepeat: 'no-repeat',
                      WebkitMaskPosition: '50% 50%',
                      maskPosition: '50% 50%',
                      WebkitMaskSize: 'contain',
                      maskSize: 'contain',
                      backgroundColor: 'white',
                    }}
                  />
                )}
                {hoveredItem === tab.key && (!opened || !!tab.tooltip) && (
                  <Tooltip show={true} content={tab.tooltip || tab.label} />
                )}
                {tab.collapsible && opened ? (
                  <ArrowIcon
                    className={cx('collapse_icon', {
                      'collapse_icon--open': tab.expanded,
                    })}
                  />
                ) : null}
              </div>
              {/* Expanded collapisble menu items */}
              {tab.expanded &&
              tab.items?.sort((a, b) => {
                const { label: labelA } = a
                const { label: labelB } = b

                return labelA <= labelB ? -1 : 1
              }) ? (
                <div className={slidingMenuClass}>
                  {tab.items.map((innerTab, idx) => (
                    <div
                      key={idx}
                      className={cx(
                        'left-menu-container__tabs left-menu-container__tabs__item',
                        {
                          'left-menu-container__tabs--show': opened,
                          'left-menu-container__tabs--selected':
                            selectedSlidingTab === innerTab.key,
                        }
                      )}
                      onClick={() => {
                        alterMenuState(false, innerTab, opened, true)
                      }}
                      onMouseEnter={() => setHoveredItem(innerTab.key)}
                      onMouseLeave={() => setHoveredItem(null)}
                    >
                      <span className="tab-label tab-label--sub-item">
                        {innerTab.label}
                      </span>

                      {hoveredItem === innerTab.key &&
                        (!opened || !!innerTab.tooltip) && (
                          <Tooltip
                            show={true}
                            content={innerTab.tooltip || innerTab.label}
                          />
                        )}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          ))}
        {opened && viewList ? (
          <div className="legal-items align-column">
            {viewList.map((item) => {
              if (item.displayArea.includes(LEGAL_DISPLAY_AREA.SIDE_NAV)) {
                const { _id } = item
                return (
                  <div
                    key={_id}
                    className={cx(
                      'left-menu-legal-item',
                      'left-menu-container__tabs--show',
                      {
                        'left-menu-legal-item--active':
                          currentRoute === `/legal/${item.slug}`,
                      },
                      {
                        'left-menu-legal-item--text':
                          item.type === LEGAL_TYPES.SIMPLE_TEXT,
                      }
                    )}
                    onClick={() => {
                      if (item.type !== LEGAL_TYPES.SIMPLE_TEXT) {
                        navigate(`/legal/${item.slug}`)
                      }
                    }}
                  >
                    <span className="tab-label align-center">{item.name}</span>
                  </div>
                )
              }
            })}
          </div>
        ) : null}
      </div>
    </div>
  )
}

/**
 * Ascenrtain whether the sliding menu should show based on the current route and current brief.
 * @param {String} currentRoute Route for decision making
 * @param {Object} flow Currently edited brief (if any), for Approved Brief left menu exception
 */
const hideSlidingMenuByCurrentRoute = (currentRoute, flow = {}) => {
  // checking for different routes in the future
  return !!(
    currentRoute.includes('/briefs') &&
    !!currentRoute.split('/')[2] &&
    flow.status !== STATUS.APPROVED.key
  )
}

export default LeftMenu
