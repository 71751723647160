import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

// Assets
import { ReactComponent as Arrow } from 'assets/icon_arrow_chevron.svg'

// Styles
import './style.scss'

/**
 * Group of inputs
 * @param {InputGroupProps} params Component props
 * @returns {React.Component}
 */
const InputGroup = React.forwardRef(
  ({ options = [], className, error, fixedWidth, ...others }, ref) => {
    const classes = cx(
      'input-group',
      {
        'input-group--error': !!error,
        'input-group--fixed-width': fixedWidth,
      },
      className
    )

    /**
     * Memorized object composed of flags which conditionally render labels and hints.
     */
    const { hasLabels, hasHints } = useMemo(() => {
      return options.reduce(
        (acc, option) => {
          if (option.label && !acc.hasLabels) {
            acc = { ...acc, hasLabels: true }
          }
          if (option.hint && !acc.hasHints) {
            acc = { ...acc, hasHints: true }
          }
          return acc
        },
        { hasLabels: false, hasHints: false }
      )
    }, [options])

    /**
     * Computes the grid dimension in the form of `minmax(min, max)`
     * @param {Object} args
     * @param {String | Number} [args.min] Minimum dimension for the element
     * @param {String | Number} [args.max] Maximum dimension for the element
     * @returns {String}
     */
    const getMinMax = ({ min, max }) =>
      `minmax(${parseDimension(min) || 0}, ${parseDimension(max) || '1fr'})`

    /**
     * If the given dimension is not a string, it will concatenate it with `px`
     * @param {String | Number} dimension Dimension to parse
     * @returns {String}
     */
    const parseDimension = (dimension) =>
      typeof dimension === 'number' ? `${dimension}px` : dimension

    /**
     * Helper function which builds the grid diemnsion for an option
     * @param {InputGroupOption} option Option entry to build dimensions for
     * @returns {String}
     */
    const extractDimensionsForOption = (option) => {
      const { width, minWidth, maxWidth } = option

      if (width === 0) {
        return 'auto'
      }

      if (typeof width === 'string') {
        return width === 'auto' ? getMinMax({}) : width
      }

      const parsedWidth = parseDimension(width)

      if (width !== undefined) {
        if (!fixedWidth) {
          return width
            ? parsedWidth
            : getMinMax({ min: minWidth, max: maxWidth })
        } else {
          return getMinMax({
            min: parsedWidth || minWidth,
            max: maxWidth || parsedWidth,
          })
        }
      } else {
        return getMinMax({ min: minWidth, max: maxWidth })
      }
    }

    /**
     * Memorized object which contains the components which need to be rendered grouped by type, along with the final grid layout style.
     */
    const { gridConfig, labels, elements, hints, errors } = useMemo(() => {
      const optionsDimensions = []
      const labels = []
      const elements = []
      const hints = []
      const errors = []

      options.forEach((option) => {
        const { label, render, condition, hint, error } = option
        // If condition === false, it shouldn't display anything
        if (condition === false) {
          return
        }
        optionsDimensions.push(extractDimensionsForOption(option))
        labels.push(label)
        elements.push(render)
        hints.push(hint)
        errors.push(error)
      })

      const gridConfig = {
        gridTemplateColumns:
          optionsDimensions.join(' ') ||
          `repeat(${options.length}, minmax(0, 1fr))`,
      }

      return { labels, elements, hints, gridConfig, errors }
    }, [options])

    return (
      <div ref={ref} className={classes} style={gridConfig} {...others}>
        {/* Render Labels */}
        {hasLabels &&
          labels?.map((label, index) => (
            <div
              key={`${label}_${index}`}
              className="input-group__label general-label"
            >
              {label}
            </div>
          ))}

        {/* Render Elements */}
        <div className="input-group__elements" style={gridConfig}>
          {elements.map((element, index) => (
            <div
              key={index}
              className={cx('input-group__element', {
                'error-border': errors[index],
                'error-right-border': errors[index + 1],
              })}
            >
              {element}
            </div>
          ))}
        </div>

        {/* Render hints */}
        {hasHints &&
          hints?.map((hint, index) => (
            <div key={`${hint}_${index}`} className="input-group__hint">
              {hint}
            </div>
          ))}

        <span className="input-group__error">{error}</span>
      </div>
    )
  }
)

/**
 * Simple arrow up / arrow down component for InputGroup
 * @param {Object} params React Params
 * @param {Function} params.onClick Callback for arrow clicks. Returns +1 or -1
 * @param {Boolean} params.disabledUp Whether up arrow is disabled or not
 * @param {Boolean} params.disabledDown Whether down arrow is disabled or not
 */
export const InputGroupArrows = ({ onClick, disabledUp, disabledDown }) => {
  return (
    <div className="input-group-arrows">
      <Arrow
        data-testid="input-group-arrow-up"
        className={cx(
          'input-group-arrows__arrow input-group-arrows__arrow--up',
          {
            'input-group-arrows__arrow--disabled': disabledUp,
          }
        )}
        onClick={(e) => {
          e.preventDefault()
          !disabledUp && onClick(+1)
        }}
        onMouseDown={(e) => e.preventDefault()}
      />
      <Arrow
        data-testid="input-group-arrow-down"
        className={cx(
          'input-group-arrows__arrow input-group-arrows__arrow--down',
          {
            'input-group-arrows__arrow--disabled': disabledDown,
          }
        )}
        onMouseDown={(e) => e.preventDefault()}
        onClick={(e) => {
          e.preventDefault()
          !disabledDown && onClick(-1)
        }}
      />
    </div>
  )
}

InputGroupArrows.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabledDown: PropTypes.bool,
  disabledUp: PropTypes.bool,
}

InputGroup.propTypes = {
  options: PropTypes.array,
  error: PropTypes.string,
  fixedWidth: PropTypes.bool,
  className: PropTypes.string,
}

export default InputGroup

/**
 * @typedef {Object} InputGroupProps
 * @property {Array<InputGroupOption>} options Array containing sections config
 * @property {String} error Error for input group
 * @property {Boolean} [fixedWidth] If true, widths set on options will be fixed
 * @property {String} className Extra CSS classes. Will apply to container element
 *
 * @typedef {Object} InputGroupOption
 * @property {Boolean} condition If false, the Component will not be shown
 * @property {Number} width Option width. {@link InputGroupProps.fixedWidth} determines if in pixels or as flex basis
 * @property {String} [label] Label to display above Component
 * @property {String} [hint] Hint to display below Component
 * @property {Object} [style] Extra styling that should go on the option
 * @property {React.ReactNode | Function} render Component to render
 */
