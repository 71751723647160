import Api from 'easy-fetch-api'

/**
 * Gets all campaigns for a specific client
 * @param {String} clientId Company Id
 * @param {Object} signal - abort signal
 */
export const getCampaigns = (clientId, signal) =>
  new Promise((resolve, reject) => {
    Api.get({
      url: `/api/amazon-advertising/client-campaigns/${clientId}`,
      signal,
    }).then((res) => {
      if (!res || res.error) {
        reject(res?.error)
      } else {
        resolve(res.data)
      }
    })
  })
