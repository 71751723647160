import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Input from 'components/input'
import { Dropdown } from 'components/dropdown'
import { useOnClickOutside } from 'hooks/outside-click'
import './style.scss'

export const TIME_MINUTES_FORMAT = {
  DEFAULT: 0, // Allow any minutes value (1 to 59)
  FULL_HOURS: 1, // Allow only 00 minutes value
  QUARTER_MINUTES: 2, // Allow only 00, 15, 30, 45 minutes values
}

const TimeComponent = ({
  value = '',
  onChange,
  placeholder,
  disabled,
  timezone,
  minutesFormat = TIME_MINUTES_FORMAT.DEFAULT,
  error,
  ...other
}) => {
  const [time, setTime] = useState('')
  const [meridian, setMeridian] = useState('AM')
  const autoCompleteAfter = 2
  const ref = useRef()
  let parsedValue = time

  const [timers, setTimers] = useState([])
  const setTimerToAutoComplete = (val, delay) => {
    setTimers([
      ...timers,
      setTimeout(() => {
        let [hh, mm] = val.split(':')

        if (minutesFormat === TIME_MINUTES_FORMAT.FULL_HOURS) {
          mm = '00'
        } else if (minutesFormat === TIME_MINUTES_FORMAT.QUARTER_MINUTES) {
          // Converts any given minute value to the closest quarter minute value
          // from 01 to 07 -> 00, from 08 to 15 -> 15, from 16 to 22 -> 15 etc.
          mm = ((((+mm + 7.5) / 15) | 0) * 15) % 60
          hh = (((+mm / 105 + 0.5) | 0) + +hh) % 24
        }

        let limitHour = 12 < hh && hh < 24 ? hh - 12 : 12
        mm = mm.toString().length === 1 ? `0${mm}` : mm
        hh = hh.toString().length === 1 ? `0${hh}` : hh

        limitHour =
          limitHour.toString().length === 1 ? `0${limitHour}` : limitHour
        const limitValues = `${hh > 12 ? limitHour : hh}:${mm > 59 ? 59 : mm}`

        setTime(limitValues)
        onChange(returnValue(limitValues, meridian))
      }, delay * 1000),
    ])
  }

  const format = ({ key, delay }) => {
    const backspacePressed = key === 'Backspace'

    timers.map((t) => clearTimeout(t))
    setTimers([])

    // Check for non-numbers
    if (time.search(/^[^\d]+$/gi) !== -1) {
      setTimerToAutoComplete('00:00', delay)
    } else {
      // Format input to always match the HH:MM pattern
      switch (time.length) {
        case 0:
          setTimerToAutoComplete('00:00', delay)
          break
        case 1:
          setTimerToAutoComplete(`0${parsedValue}:00`, delay)
          break

        case 2:
          // Add : after HH while typing
          if (!backspacePressed) {
            parsedValue += ':'

            setTime(parsedValue)
            onChange(returnValue(parsedValue, meridian))
            setTimerToAutoComplete(`${parsedValue}00`, delay)
          } else {
            setTimerToAutoComplete(`${parsedValue}:00`, delay)
          }
          break

        case 3:
          setTimerToAutoComplete(`${parsedValue}00`, delay)
          break

        case 4:
          setTimerToAutoComplete(
            `${parsedValue.slice(0, 2)}:0${parsedValue.slice(3, 4)}`,
            delay
          )
          break

        default:
          setTimerToAutoComplete(parsedValue.slice(0, 5), delay)
          break
      }
    }
  }

  useOnClickOutside(ref, () => format({ delay: 0 }))

  useEffect(() => {
    const parts = value.split(' ')
    setTime(parts[0])
    setMeridian(parts[1] || 'AM')
  }, [value])

  const returnValue = (time, meridian) => {
    return `${time} ${meridian}`
  }

  return (
    <div {...other}>
      <Input
        className={cx({ 'time--error': error })}
        data-testid="time-input"
        disabled={disabled}
        value={parsedValue}
        onKeyDown={(ev) => {
          const { key, keyCode } = ev
          const allowed = [8, 37, 39] // allow backspace and arrows left-right
          if (allowed.indexOf(keyCode) === -1 && isNaN(key)) {
            ev.preventDefault()
          }
        }}
        onChange={(val) => {
          setTime(val)
          onChange(returnValue(val, meridian))
        }}
        maxLength={5}
        onKeyUp={(ev) => {
          format({ key: ev.key, delay: autoCompleteAfter })
        }}
        placeholder={placeholder}
        ref={ref}
        hint={timezone}
        error={error}
      />
      <Dropdown
        className="time__dropdown"
        options={['AM', 'PM']}
        disabled={disabled}
        defaultState={meridian}
        onChange={(val) => {
          setMeridian(val)
          onChange(returnValue(time, val))
        }}
      />
    </div>
  )
}

TimeComponent.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  timezone: PropTypes.string,
  minutesFormat: PropTypes.oneOf(Object.values(TIME_MINUTES_FORMAT)),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default TimeComponent
