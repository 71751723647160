import { v4 as uuidv4 } from 'uuid'
import Api from 'easy-fetch-api'
import Cookies from 'js-cookie'
import { redirect } from 'react-router-dom'
import {
  ACTIONS as NOTIFICATION_ACTIONS,
  showErrorMessage,
} from 'modules/notifications/actions'
import { setGlobalLoading } from 'modules/global/actions'
import { openUserIsLoggedInModal } from 'modules/users/utils'
import { config } from '@decision-sciences/qontrol-common'

const { COOKIE_NAME, EMULATE_COOKIE_NAME } = config

// Temporary Token and userTemp is for sign-up page
export const LC_TEMP_USER_DATA = 'lc-temp-3q-user'
export const LC_TEMP_USER_TOKEN = 'lc-temp-3q-user-token'

export const ACTIONS = {
  GET_USER_DATA: 'session.getUserData',
  SET_TEMP_USER_DATA: 'session.setTempUserData',
  LOGOUT: 'session.logout',
  SET_EXPIRATION: 'session.setExpiration',
}

export const getUserData = (dispatch) =>
  Api.get({
    url: '/api/users/me',
    headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' },
  }).then((result) => {
    if (result && !result.error) {
      dispatch({ type: ACTIONS.GET_USER_DATA, userData: result })
    }
  })

export const setUserData = (userData) => ({
  type: ACTIONS.GET_USER_DATA,
  userData,
})

// Set temporary user for sign-up
export const setTempUserData = (userTemp, token) => {
  localStorage.setItem(LC_TEMP_USER_DATA, JSON.stringify(userTemp))
  localStorage.setItem(LC_TEMP_USER_TOKEN, token)
}

export const getTempUserData = () => {
  const userTemp = JSON.parse(localStorage.getItem(LC_TEMP_USER_DATA))
  const token = localStorage.getItem(LC_TEMP_USER_TOKEN)
  return { userTemp, token }
}

export const removeTempUser = () => {
  localStorage.removeItem(LC_TEMP_USER_DATA)
  localStorage.removeItem(LC_TEMP_USER_TOKEN)
}

export const updateUserFromSignUp = (data) => {
  return Api.post({
    url: '/api/users/update-from-sign-up',
    data: data,
  })
}

export const updateUserTimestampLegalTerms = (data) => {
  return Api.post({
    url: '/api/users/update-terms-of-use',
    data: data,
  })
}

export const logout = (dispatch, noRedirect) =>
  Api.get({ url: '/api/users/logout' }).then((result) => {
    if (result.success) {
      Cookies.remove(COOKIE_NAME)
      Cookies.remove(EMULATE_COOKIE_NAME)
      dispatch({ type: ACTIONS.LOGOUT })
      if (!noRedirect) {
        setTimeout(() => {
          redirect('/login')
        }, 50)
      }
    } else {
      const id = uuidv4()

      dispatch({
        type: NOTIFICATION_ACTIONS.SHOW_ERROR,
        message: `Could not terminate session on the Server. Please retry.`,
        id,
      })
    }
  })

export const setExpiration = (dispatch, expires, inactivityWarningTimeout) => {
  dispatch({
    type: ACTIONS.SET_EXPIRATION,
    expires,
    inactivityWarningTimeout,
  })
}

// This is to let the server know the user is here
export const pingServer = () => {
  Api.get({ url: '/api/users/here' })
}

export const getSession = async () => {
  return await Api.get({ url: '/api/users/session' }).catch(() => {
    console.error('Could not retrieve active session data.')
    return {}
  })
}

/**
 * Start user emulation - 2 step
 * 1. Initialize emulation - check if the user is logged in and if so, show a warning modal
 * 2. Start emulation - start the emulation process
 *
 * @param {Function} dispatch
 * @param {String} emulatedUserId - user ID to emulate
 * @param {String} currentCompanyId - current client ID of the original user, used to be able to revert to it once emulation ends
 * @param {Boolean} [bypassWarning] - if the super admin decides to emulate a user even if he has an active session
 */
export const emulateUser = (
  dispatch,
  emulatedUserId,
  currentCompanyId,
  bypassWarning = false
) => {
  setGlobalLoading(dispatch, true)
  Api.post({
    url: `/api/emulate/initialize`,
    data: { emulatedUserId, bypassWarning },
  })
    .then((response) => {
      if (response.userIsLoggedIn) {
        setGlobalLoading(dispatch, false)
        openUserIsLoggedInModal(dispatch, emulatedUserId, currentCompanyId)
      } else {
        Api.post({
          url: `/api/emulate/start`,
          data: { emulatedUser: response.user },
        })
          .then((resEmulate) => {
            setEmulatedByCompanyId(currentCompanyId)
            dispatch(setUserData(resEmulate.user))
            window.location = '/'
          })
          .catch(() => {
            setGlobalLoading(dispatch, false)
            showEmulationError(dispatch)
          })
      }
    })
    .catch(() => {
      setGlobalLoading(dispatch, false)
      showEmulationError(dispatch)
    })
}

/** Generic error to be shown if emulation doesn't work properly */
export const showEmulationError = (dispatch) =>
  showErrorMessage('Something went wrong. Please try again', dispatch)

/** End user emulation */
export const finishEmulatingUser = (dispatch) => {
  setGlobalLoading(dispatch, true)
  Api.post({ url: `/api/emulate/stop` })
    .then((response) => {
      dispatch(setUserData(response.user))
      const originalCompanyId = getAndRemoveEmulatedByCompanyId()
      window.location = `/users?cid=${originalCompanyId}`
    })
    .catch(() => {
      setGlobalLoading(dispatch, false)
      showEmulationError(dispatch)
    })
}

const EMULATED_BY_COMPANY_KEY = 'lc-3q-emulatedby-company'

/** Used to store the company ID for the user performing the Emulation, used to be able to revert to it once emulation ends */
const setEmulatedByCompanyId = (currentCompanyId) =>
  localStorage.setItem(EMULATED_BY_COMPANY_KEY, currentCompanyId)

/** Read the stored company ID for the original user and clear it from storage */
const getAndRemoveEmulatedByCompanyId = () => {
  const companyId = localStorage.getItem(EMULATED_BY_COMPANY_KEY)
  localStorage.removeItem(EMULATED_BY_COMPANY_KEY)
  return companyId
}
