/** Corresponds to the GoogleAdsApi @see {@link googleEnums.TargetImpressionShareLocation} enum */
export const GOOGLE_BIDDING_IMPRESSION_SHARE_LOCATIONS = {
  ANYWHERE_ON_PAGE: {
    value: 'ANYWHERE_ON_PAGE',
    label: 'Anywhere on results page',
  },
  TOP_OF_PAGE: { value: 'TOP_OF_PAGE', label: 'Top of results page' },
  ABSOLUTE_TOP_OF_PAGE: {
    value: 'ABSOLUTE_TOP_OF_PAGE',
    label: 'Absolute top of results page',
  },
}
