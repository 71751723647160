import Api from 'easy-fetch-api'
import {
  flow,
  accounts,
  socket,
  utils,
} from '@decision-sciences/qontrol-common'

/* Constants */
import {
  AD_CONTENT_DEFAULTS,
  TYPES_OF_AD_CONTENT,
} from 'modules/flow/constants'
import { SPECIAL_AD_CATEGORIES_AUDIENCE_LIMITS } from 'modules/facebook'

/* Utils */
import { showErrorMessage, showSuccessMessage } from '../notifications/actions'
import { buildSyncErrors } from './utils/error'
import { clearRestoredPlacementsForAds } from './utils/ad'

const { BUYING_TYPE_MAP } = flow.facebook
const { ACCOUNT_TYPES_MAP } = accounts
const { NOTIFICATIONS } = socket
const { confirmMap } = utils.brief
const { mergeWithoutArrays } = utils.array
const {
  getAdSetsFromBrief,
  getArrayByStep,
  getCampaignsFromBrief,
  STATUS,
  STEPS_MAP,
  STEP_INDEX_MAP,
} = flow
const { AD_PLACEMENT_GROUP_BY_PLACEMENT, EDIT_PLACEMENT_EDITABLE_OPTIONS } =
  flow.facebook.placements
const { AD_PLACEMENT_GROUP_BY_PLACEMENT: ODAX_AD_PLACEMENT_GROUP } =
  flow.facebook.odaxPlacements
const { PLACEMENT_BY_FORMAT } = flow.facebook.previews
const { PLACEMENT_BY_FORMAT: ODAX_PLACEMENT_BY_FORMAT } =
  flow.facebook.odaxPreviews

const { FACEBOOK, ODAX } = ACCOUNT_TYPES_MAP

export const ACTIONS = {
  /*
   * The flag is used for a special case to differentiate two similar cases
   * 1) Receive flow details just after it was created (should navigate then to the step 2)
   * 2) Receive flow details when the user access directly the step 1 by URL or from briefs table (keep the user on step 1)
   */
  SET_DID_CREATE_NOW: 'flow.didCreateNow',
  SET_FLOW: 'flow.setFlow',
  PUT_FLOW: 'flow.putFlow',
  SET_STEP: 'flow.setStep',
  SAVE_DRAFT: 'flow.saveDraft',
  APPROVE: 'flow.approve',
  SET_STEP_DATA: 'flow.addStepData',
  SET_LOADING: 'flow.loading',
  RESET_FLOW: 'flow.reset',
  SET_FLOW_ID: 'flow.setFlowId',
  VIEW_MODE: 'flow.setIsViewMode',

  BULK_UPDATE_STEP: 'flow.bulkUpdateStep',

  ADD_CAMPAIGNS: 'flow.addCampaign',
  EDIT_CAMPAIGNS: 'flow.editCampaign',
  DUPLICATE_CAMPAIGNS: 'flow.duplicateCampaign',
  DELETE_CAMPAIGNS: 'flow.deleteCampaign',
  INITIALIZE_ODAX_DATA: 'flow.initializeOdaxCampaign',
  RESET_ODAX_CBO: 'flow.resetOdaxCampaignBudget',
  INITIALIZE_FACEBOOK_DATA: 'flow.initializeFacebookCampaign',
  INITIALIZE_EMPTY_CAMPAIGN: 'flow.initializeEmptyCampaign',

  ADD_ADSET: 'flow.addAdSet',
  EDIT_ADSET: 'flow.editAdSet',

  ADD_AD: 'flow.addAd',
  EDIT_AD: 'flow.editAd',

  ADD_EDIT_AD_CONTENT: 'flow.addEditAdContent',

  SET_CLIENT_ACCOUNTS: 'flow.setCurrentClientAccounts',
  TOGGLE_ITEM_OPEN: 'flow.toggleItemOpen',
  DISABLE_CONFIRMATION: 'flow.disablefirmation',
  SET_UPLOAD_IN_PROGRESS: 'flow.setUploadInProgress',
  UPDATE_BRIEF: 'flow.updateBrief',

  CANCEL_ITEM: 'flow.cancelItem',
  CANCEL_STEP: 'flow.cancelStep',
  UNSET_CONFIRMATION: 'flow.unsetConfirmation',
  BATCH_SET_ERRORS: 'flow.batchSetErrors',

  NAVIGATE: 'flow.navigate',
}

/** Setter for didCreateNow flag */
export const setDidCreateNow = (dispatch, value) =>
  dispatch({ type: ACTIONS.SET_DID_CREATE_NOW, value })

/** Set client accounts */
export const setCurrentClientAccounts = (dispatch, accounts) => {
  dispatch({ type: ACTIONS.SET_CLIENT_ACCOUNTS, accounts })
}

/** Setter for upload */
export const setUploadInProgress = (dispatch, value) => {
  dispatch({ type: ACTIONS.SET_UPLOAD_IN_PROGRESS, value })
}

/** Get selected flow's data */
export const getFlowDetails = (dispatch, _id) => {
  dispatch({ type: ACTIONS.SET_LOADING, loading: true })
  return Api.get({ url: `/api/briefs/brief/${_id}` })
    .then((result) => {
      if (!result || result.error || !result.data) {
        console.error('Error fetching Campaign Brief Flow')
      } else {
        dispatch({
          type: ACTIONS.SET_FLOW,
          flow: result.data,
        })
      }
      return result
    })
    .catch(console.error)
}

/** Copy selected flow's data */
export const copyFlowDetails = async (dispatch, _id) => {
  dispatch({ type: ACTIONS.SET_LOADING, loading: true })

  return Api.post({ url: `/api/briefs/brief/copy/${_id}` })
    .then((result) => {
      if (!result || result.error) {
        console.error('Error duplicating Campaign Brief Flow', result?.error)
      } else {
        dispatch({
          type: ACTIONS.SET_FLOW,
          flow: result.data,
        })
        return result.data
      }
    })
    .catch(console.error)
}

/**
 * Saves the current step + all flow steps that are unsaved.
 * If flowId === 'new' it also creates the flow (first save)
 * If not it just updates the steps array
 */
export const saveStepsData = ({
  dispatch,
  flow,
  allowedSteps,
  draft,
  stayOnStep,
  user,
  navigate,
}) => {
  const {
    defineBriefData,
    briefDetails,
    name,
    clientId,
    deleted,
    status,
    crtStep,
    accounts,
    syncErrors,
    editors,
  } = flow
  const data = {
    defineBriefData,
    briefDetails,
    name,
    clientId,
    deleted,
    status,
    crtStep,
    accounts,
    syncErrors,
  }

  if (draft) {
    data.savedAsDraft = data.crtStep
  } else {
    data.savedAsDraft = null
  }

  const filterAndMap = (step) =>
    getArrayByStep(flow, step)
      .filter(({ changed }) => changed)
      .map((item) => ({ ...item, changed: false }))

  // Add user to editors array
  const userInfo = {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
  }

  if (editors && !editors.some((editor) => editor.email === userInfo.email)) {
    data.editors = [...editors, userInfo]
  }

  // Campaigns Step
  const changedCampaigns = filterAndMap(STEPS_MAP.CAMPAIGNS.idx).map(
    (campaign) => {
      const updatedCampaign = { ...campaign }
      if (
        campaign.type === ACCOUNT_TYPES_MAP.FACEBOOK &&
        !('isOdax' in updatedCampaign)
      ) {
        updatedCampaign.isOdax = false
      }
      return updatedCampaign
    }
  )
  if (changedCampaigns.length) {
    data.campaigns = changedCampaigns
  }

  // Ad Content Step
  const changedAdContent = filterAndMap(STEPS_MAP.AD_CONTENT.idx).map(
    (adContent) => ({
      ...adContent,
      videoList: adContent.videoList?.map((mediaItem) =>
        mediaItem.changed ? mediaItem : { _id: mediaItem._id }
      ),
      imageList: adContent.imageList?.map((mediaItem) => {
        if (mediaItem.copiedFromFbk) {
          return mediaItem
        }

        return mediaItem.changed ? mediaItem : { _id: mediaItem._id }
      }),
    })
  )

  if (changedAdContent.length) {
    data.adContent = changedAdContent
  }
  // Ad Sets Step
  const changedAdSets = filterAndMap(STEPS_MAP.AD_SETS.idx)
  if (changedAdSets.length) {
    data.adSets = changedAdSets
  }

  // Ads Step
  const changedAds = filterAndMap(STEPS_MAP.ADS.idx)
  if (changedAds.length) {
    data.ads = clearRestoredPlacementsForAds(changedAds)
  }

  // Add resolved flag to syncErrors
  const changedEntities = [...changedCampaigns, ...changedAdSets, ...changedAds]
  if (changedEntities.length) {
    data.syncErrors = syncErrors.map((error) => {
      const { entityId } = error
      const foundEntity = changedEntities.find(
        (entity) => entity._id === entityId
      )
      if (foundEntity) {
        return { ...error, resolved: true }
      }
      return { ...error }
    })
  }

  /* Save current step */
  return new Promise((resolve, reject) => {
    dispatch({ type: ACTIONS.SET_LOADING, loading: true })
    const oldStep = flow.crtStep

    Api.post({
      url: `/api/briefs/brief/${flow._id}`,
      data,
    })
      .then((result) => {
        // Error case
        if (result.error || !result.data) {
          console.error('Error saving flow step', result.error)
          return reject(result.error)
        }

        dispatch({ type: ACTIONS.SET_FLOW, flow: result.data })

        if (stayOnStep) {
          goToStep(dispatch, oldStep)
        } else if (!draft) {
          // If the brief is not saved as a draft, we advance to next step in flow
          nextStep(dispatch, { ...flow, ...result.data }, allowedSteps)
        } else {
          navigate('/briefs')
        }

        resolve(result.data)
      })
      .catch((err) => {
        console.error('Unexpected error while saving flow step', err)
        return reject(err)
      })
      .finally(() => {
        dispatch({ type: ACTIONS.SET_LOADING, loading: false })
      })
  })
}

/** Check if there is a next step */
export const hasNextStep = (flow, allowedSteps) => {
  return [...allowedSteps].some((step) => step > (flow.crtStep || 0))
}

/** Check if there is a prev. step */
export const hasPrevStep = (flow, allowedSteps) => {
  return [...allowedSteps].some((step) => step < (flow.crtStep || 0))
}

/** Get previous step idx */
const getPrevStepIdx = (flow, allowedSteps) => {
  return [...allowedSteps].reverse().find((step) => step < (flow.crtStep || 0))
}

/** Get next step idx */
export const getNextStepIdx = (flow, allowedSteps) => {
  return [...allowedSteps].find((step) => step > (flow.crtStep || 0))
}

/** Convenient way to get the brief base page ufrl with the id within **/
export const getBriefBaseUrlWithId = (id) => `/briefs/${id}`

/** Go to next step */
export const nextStep = (dispatch, flow, allowedSteps) => {
  if (!allowedSteps) {
    return
  }
  const nextStepAvailable = hasNextStep(flow, allowedSteps)
  const nextStep = getNextStepIdx(flow, allowedSteps)
  if (nextStepAvailable) {
    dispatch({ type: ACTIONS.NAVIGATE, step: nextStep })
  }
}

/** Go to prev step */
export const prevStep = (dispatch, flow, allowedSteps) => {
  if (!allowedSteps) {
    return
  }
  const prevStepAvailable = hasPrevStep(flow, allowedSteps)
  const prevStep = getPrevStepIdx(flow, allowedSteps)
  if (prevStepAvailable) {
    dispatch({ type: ACTIONS.NAVIGATE, step: prevStep })
  }
}

/** Go to a certain step, if it's available */
export const goToStep = (dispatch, step) =>
  dispatch({ type: ACTIONS.NAVIGATE, step })

/**
 * Go to a certain step and expand the entity
 * @param {Function} dispatch Dispatch function
 * @param {Number} step Brief step to navigate to
 * @param {String} entityId Entity UUID - NOT ObjectId.
 * `getEntityUuidByObjectId` can be used to easily get the UUID based on the ObectId
 */
export const goToBriefEntity = (dispatch, step, entityId) => {
  dispatch({ type: ACTIONS.NAVIGATE, step, entityId })
}

/**
 * Clears the navigation request from the store.
 * This is called even if the navigation did not occur.
 * @param {Function} dispatch Dispatch function
 */
export const clearNavigation = (dispatch) => {
  dispatch({ type: ACTIONS.NAVIGATE, step: null, entityId: null })
}

/** Add data to a certain step */
export const addStepData = (dispatch, data) =>
  dispatch({ type: ACTIONS.SET_STEP_DATA, data })

/** Mark a flow as deleted */
export const deleteFlow = (dispatch, flow) => {
  return new Promise((resolve, reject) => {
    Api.delete({ url: `/api/briefs/brief/${flow._id}` })
      .then(() => {
        dispatch({ type: ACTIONS.RESET_FLOW })
        resolve()
      })
      .catch(reject)
  })
}

export const newFlow = (dispatch, clientId) =>
  dispatch({ type: ACTIONS.RESET_FLOW, clientId })

/* Campaign actions */

/**
 * For facebook campaigns, at creation, we do not know whether the campaign will be odax or legacy.
 * Facebook campaigns will be initialized as "empty" and then campaign defaults will be added based on selected experience.
 * Functions for initializing legacy and odax campaigns: {@link initializeFacebookCampaign} and {@link initializeOdaxCampaign}
 * @param {Function} dispatch
 * @param {String} platform
 * @param {Object} data
 */
export const addCampaign = (dispatch, platform, data) => {
  if (platform !== FACEBOOK) {
    dispatch({ type: ACTIONS.ADD_CAMPAIGNS, platform, data })
    dispatch({
      type: ACTIONS.ADD_EDIT_AD_CONTENT,
      campaignId: data._id || data.id,
      platform,
      data: {},
      forceChanges: true,
    })
  } else {
    dispatch({ type: ACTIONS.INITIALIZE_EMPTY_CAMPAIGN, platform, data })
  }
}

/**
 * Duplicate campaign
 * @param {Function} dispatch
 * @param {String} platform
 * @param {Object} data
 */
export const duplicateCampaign = (dispatch, platform, data) => {
  dispatch({ type: ACTIONS.DUPLICATE_CAMPAIGNS, platform, data })
  dispatch({
    type: ACTIONS.ADD_EDIT_AD_CONTENT,
    campaignId: data._id || data.id,
    platform,
    data: {},
    forceChanges: true,
  })
}

/**
 * Function to initialise facebook legacy campaign defaults and ad content.
 * Campaing defaults will be initialized under campaign.data
 * @param {Function} dispatch
 * @param {String} id
 * @param {String} platform
 * @param {Object} data
 */
export const initializeFacebookCampaign = (dispatch, id, platform, data) => {
  dispatch({ type: ACTIONS.INITIALIZE_FACEBOOK_DATA, platform, id, data })
  addEditAdContent(dispatch, platform, id, {}, true)
}

/**
 * Function to initialise facebook ODAX campaign defaults and ad content.
 * Campaign defaults will be initialised under campaign, not under campaign.data as for legacy
 */
export const initializeOdaxCampaign = (dispatch, platform, campaign) => {
  dispatch({ type: ACTIONS.INITIALIZE_ODAX_DATA, platform, campaign })
  addEditAdContent(dispatch, platform, campaign.id, {}, true)
}

/**
 * Function to reset facebook ODAX campaign CBO defaults
 */
export const resetOdaxCampaignBudget = (dispatch, campaignId) => {
  dispatch({ type: ACTIONS.RESET_ODAX_CBO, campaignId })
}

export const editCampaign = ({
  dispatch,
  id,
  platform,
  data,
  confirmed,
  merge = false,
  errors,
}) =>
  dispatch({
    type: ACTIONS.EDIT_CAMPAIGNS,
    id,
    platform,
    data,
    confirmed,
    merge,
    errors,
  })

/**
 * Batch Set Errors for different platforms and granularities
 * @param {Function} dispatch Dispatch
 * @param {Array} actions Actions Array
 * @param {String} actions.$.platform Item's platform from ACCOUNT_TYPES_MAP
 * @param {String} actions.$.id Item's uuid
 * @param {'CAMPAIGN' | 'ACCOUNT' | 'AD_GROUP'} actions.$.granularity Item's granularity (GRANULARITIES)
 * @param {Object} [actions.$.errors] Errors to set for specific item
 */
export const batchSetErrors = (dispatch, actions) => {
  dispatch({ type: ACTIONS.BATCH_SET_ERRORS, actions })
}

export const deleteCampaign = (dispatch, platform, data) =>
  dispatch({ type: ACTIONS.DELETE_CAMPAIGNS, platform, data })

/* Ad-set Actions */
export const addAdSet = (dispatch, platform, data) => {
  dispatch({
    type: ACTIONS.ADD_ADSET,
    platform,
    data,
  })
}
export const updateAdSet = ({
  dispatch,
  id,
  platform,
  data,
  confirmed,
  merge = false,
  errors,
}) => {
  dispatch({
    type: ACTIONS.EDIT_ADSET,
    id,
    platform,
    data,
    confirmed,
    merge,
    errors,
  })
}

export const bulkUpdateStep = (dispatch, step, ids, changes, confirmed) => {
  dispatch({ type: ACTIONS.BULK_UPDATE_STEP, step, ids, changes, confirmed })
}

/* Ad Actions */
export const addAd = (dispatch, platform, data) => {
  dispatch({ type: ACTIONS.ADD_AD, platform, data })
}

export const updateAd = ({
  dispatch,
  id,
  platform,
  data,
  merge = false,
  errors,
}) => dispatch({ type: ACTIONS.EDIT_AD, id, platform, data, merge, errors })

/* Ad Content actions */
export const addEditAdContent = (
  dispatch,
  platform,
  campaignId,
  data,
  forceChanges
) => {
  dispatch({
    type: ACTIONS.ADD_EDIT_AD_CONTENT,
    platform,
    campaignId,
    data,
    forceChanges,
  })
}

export const copyAdContent = (
  dispatch,
  adContent = [],
  content,
  campaignId,
  campaignType
) => {
  let updatedAdContent
  const idx = adContent.findIndex(
    (c) => c?.campaignId.toString() === campaignId.toString()
  )
  if (idx === -1) {
    updatedAdContent = { campaignId, isNew: true, ...content }
    adContent.push({ campaignId, isNew: true, ...content })
  } else {
    const contentTypes = Object.values(TYPES_OF_AD_CONTENT[campaignType])
    contentTypes.forEach((contentType) => {
      const newContent = mergeWithoutArrays(
        adContent[idx],
        adContent[idx].pendingChanges
      )
      delete newContent.pendingChanges
      adContent[idx] = {
        ...newContent,
        [contentType]: [...newContent[contentType], ...content[contentType]],
      }
    })
    updatedAdContent = adContent[idx]
  }
  addEditAdContent(dispatch, campaignType, campaignId, updatedAdContent)
}

/**
 * Deletes a file from the AWS S3 bucket
 * @param {String} location Path of the file to delete in the form of `/foldername/filename`.
 * @param {Boolean} [fromPublicBucket = false] Flag to pass in the query params if the deleted file is from the public bucket
 * @returns {Promise}
 */
export const deleteFile = (location, fromPublicBucket = false) => {
  return new Promise((resolve, reject) => {
    const query = new URLSearchParams({ fromPublicBucket }).toString()
    Api.delete({
      url: `/api/media/${location}?${query}`,
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject('Error deleting file.')
        }
        resolve()
      })
      .catch((err) => reject(err))
  })
}

export const getAdAccountDetailedTargetingCategories = async (
  companyId,
  accountId
) => {
  return await Api.get({
    url: `/api/facebook/detailed-targeting-categories`,
    query: { companyId, accountId },
  })
    .then((result) => {
      if (!result || result.error) {
        console.error('Error fetching Campaign Brief Flow')
      } else {
        return result.detailedTargetingCategories
      }
    })
    .catch(console.error)
}

export const getAdAccountDetailedTargetingSearch = async (
  companyId,
  accountId,
  query
) => {
  return await Api.get({
    url: `/api/facebook/detailed-targeting-search`,
    query: { companyId, accountId, query },
  })
    .then((result) => {
      if (!result || result.error) {
        console.error('Error fetching Campaign Brief Flow')
      } else {
        return result.detailedTargetingSearch
      }
    })
    .catch(console.error)
}

/**
 * @param {String} companyId - ID of the currently selected company
 * @param {Object} query Query params to search by
 * @see {@link FacebookCommon.TargetingSearchQueryParams}
 * @returns {Promise<{success: boolean, data: Array}>}
 */
export const getAdvancedTargetingOptions = async (companyId, query) => {
  return await Api.get({
    url: `/api/facebook/targeting-search`,
    query: { ...query, companyId },
  })
    .then((result) => {
      if (!result || !result.success || result.error) {
        console.error('Error fetching advanced targeting search result', result)
        return !result ? { success: false } : result
      } else {
        return result
      }
    })
    .catch(console.error)
}

/** Get all catalogs for Biz Account */
/* TODO: [Titus] Make sure this is not used anywhere before deleting it */
export const getCatalogs = (accountId) =>
  Api.get({ url: '/api/facebook/get-catalogs/', query: { accountId } })
    .then((result) => {
      if (result.catalogs && !result.catalogs.error) {
        return result.catalogs.sort((a, b) => (a.name < b.name ? -1 : 1))
      } else {
        console.error(result)
      }
    })
    .catch(console.error)

export const getProductSets = (catalogId) =>
  Api.get({ url: `/api/facebook/get-product-sets/${catalogId}` })
    .then((result) => {
      if (result.productSets && !result.productSets.error) {
        return result.productSets.data
          .map((el) => ({ id: el.id, name: el.name }))
          .sort((a, b) => (a.name < b.name ? -1 : 1))
      } else {
        console.error(result)
      }
    })
    .catch(console.error)

export const getFacebookPages = (accountId, clientId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/get-facebook-pages`,
      query: { accountId, clientId },
    })
      .then((result) => resolve(result.list))
      .catch(reject)
  })
}

export const getLocations = async (search) => {
  const params = {
    url: `/api/facebook/get-locations`,
  }
  const locationsArray = [
    ['country_group', 'country', 'region'],
    ['city'],
    ['zip'],
    ['geo_market'],
  ]

  const promises = []

  if (search) {
    locationsArray.forEach((types) => {
      params.query = {
        search,
        types,
      }
      promises.push(Api.get({ ...params, query: { search, types } }))
    })
  } else {
    promises.push(Api.get(params))
  }

  const settled = await Promise.allSettled(promises)

  return settled.reduce((prev, current) => {
    if (current.status !== 'fulfilled' || !current?.value?.locations) {
      return prev
    } else {
      return { ...prev, ...current.value.locations }
    }
  }, {})
}

export const getEvents = (accountId, clientId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/events`,
      query: { accountId, clientId },
    })
      .then((result) => resolve(result.events))
      .catch(reject)
  })
}

export const getApps = (accountId, clientId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/apps`,
      query: { accountId, clientId },
    })
      .then((result) => resolve(result.apps))
      .catch(reject)
  })
}

/** Get custom audience list */
export const getCustomAudiences = (accountId) => {
  return new Promise((resolve, reject) => {
    Api.get({ url: '/api/custom-audience/', query: { accountId } })
      .then((result) => {
        if (result.list) {
          resolve(result.list)
        } else {
          console.error(result)
        }
      })
      .catch(reject)
  })
}

export const getLanguages = (accountId) => {
  return new Promise((resolve, reject) => {
    Api.get({ url: '/api/accounts/languages/', query: { accountId } })
      .then((result) => {
        if (result.languages) {
          resolve(result.languages)
        } else {
          console.error(result)
        }
      })
      .catch(reject)
  })
}

export const getInstagramAccounts = (accountId, clientId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/get-instagram-accounts`,
      query: { accountId, clientId },
    })
      .then((result) => {
        if (!result.list || result.error) {
          return reject(result.error)
        }
        resolve(result.list)
      })
      .catch(reject)
  })
}

export const getWhatsAppPages = (accountId, clientId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/get-whatsapp-pages`,
      query: { accountId, clientId },
    })
      .then((result) => resolve(result.list || []))
      .catch(reject)
  })
}

export const getInstantExperiencesOfPage = (clientId, pageId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/instant-experiences`,
      query: { clientId, pageId },
    })
      .then((result) => resolve(result))
      .catch(reject)
  })
}

export const getOfflineEvents = (accountId, clientId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/offline-events`,
      query: { accountId, clientId },
    })
      .then((result) => resolve(result))
      .catch(reject)
  })
}

export const confirmItem = (dispatch, flow, step, platform, id) => {
  // Create deep copy of the flow
  const _flow = structuredClone(flow)

  const field = STEP_INDEX_MAP[step]?.field
  if (!field) {
    return _flow
  }

  _flow[field] = {
    ..._flow[field],
    [platform]: [..._flow[field][platform]].map((item) => confirmMap(item, id)),
  }

  dispatch({
    type: ACTIONS.SET_FLOW,
    flow: _flow,
  })

  return _flow
}

export const confirmSteps = (dispatch, flow, stepsToConfirm) => {
  // Create deep copy of the flow
  const flowCloneObj = structuredClone(flow)
  for (const step of stepsToConfirm) {
    const field = STEP_INDEX_MAP[step]?.field

    if (!field) {
      return
    }
    const changes = {}

    Object.keys(flowCloneObj[field]).forEach((platform) => {
      changes[platform] = [...flowCloneObj[field][platform]].map((item) =>
        confirmMap(item)
      )
    })

    flowCloneObj[field] = { ...flowCloneObj[field], ...changes }
  }

  dispatch({
    type: ACTIONS.SET_FLOW,
    flow: flowCloneObj,
  })

  return flowCloneObj
}

export const cancelItem = (dispatch, step, platform, id) => {
  dispatch({
    type: ACTIONS.CANCEL_ITEM,
    step,
    platform,
    id,
  })
}

export const cancelStep = (dispatch, step) => {
  dispatch({
    type: ACTIONS.CANCEL_STEP,
    step,
  })
}

export const getCatalogProducts = (catalogId, clientId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/catalog-products`,
      query: { catalogId, clientId },
    })
      .then((result) => resolve(result))
      .catch(reject)
  })
}

export const getCatalogEventSources = (catalogId, clientId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/catalog-event-sources`,
      query: { catalogId, clientId },
    })
      .then((result) => resolve(result))
      .catch(reject)
  })
}

export const getCampaignsForCopy = (
  filter,
  campaignId,
  accountId,
  clientId,
  page,
  limit,
  campaignType
) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/briefs/get-copy-campaigns',
      query: {
        filter,
        campaignId,
        accountId,
        clientId,
        page,
        limit,
        campaignType,
      },
    })
      .then((result) => {
        resolve(result)
      })
      .catch(reject)
  })
}

export const getCopyAdContent = (
  campaignIds,
  contentTypes,
  clientId,
  accountId,
  socket
) => {
  return new Promise((resolve, reject) => {
    socket.emit(NOTIFICATIONS.getCopyAdContent.initialise_single_call, {
      campaignIds: campaignIds,
      contentTypes: contentTypes,
      clientId,
      accountId,
    })

    socket.on(NOTIFICATIONS.getCopyAdContent.receive, (result) => {
      if (result.success) {
        resolve(result)
      } else {
        reject(result.error)
      }
    })
  })
}

export const getGoogleCopyAdContent = (
  campaigns,
  contentTypes,
  clientId,
  accountId
) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: '/api/briefs/get-google-adcontent',
      data: {
        campaigns,
        accountId,
        clientId,
      },
    })
      .then((result) => {
        // Keep only unique contents for the selected content types
        const content = result.data.reduce(
          (acc, curr) => {
            contentTypes.forEach((contentType) => {
              curr[contentType].forEach((content) => {
                if (
                  !acc[contentType].some(
                    (existingContent) => existingContent.value === content.value
                  )
                ) {
                  acc[contentType].push(content)
                }
              })
            })
            return acc
          },
          contentTypes.reduce(
            (initial, type) => ({ ...initial, [type]: [] }),
            {}
          )
        )

        resolve({ success: true, data: content })
      })
      .catch(reject)
  })
}
// Get Ad Sets based on query
export const getAdSets = (query) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/briefs/get-ad-sets',
      query,
    })
      .then(resolve)
      .catch(reject)
  })
}

export const uploadMedia = (imageList, videoList) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: '/api/briefs/copy-assets-to-s3',
      data: { imageList, videoList },
    })
      .then((result) => {
        resolve(result)
      })
      .catch(reject)
  })
}

export const resetAdContent = (data, itemId) => {
  let newData = data
  if (!newData?.campaignId) {
    newData = {
      ...newData,
      campaignId: itemId,
      ...AD_CONTENT_DEFAULTS[data.type],
    }
  }
  return newData
}

// Fetch pixels
export const getPixels = (accountId, companyId) =>
  new Promise((resolve, reject) => {
    Api.get({
      url: '/api/facebook/get-pixels',
      query: { companyId, accountId },
    })
      .then((result) => {
        if (result.list) {
          resolve(result.list)
        } else {
          reject(result)
        }
      })
      .catch(reject)
  })

// Fetch Account's owned pixels
export const getOwnedPixels = (accountId, companyId) =>
  new Promise((resolve, reject) => {
    Api.get({
      url: '/api/facebook/get-owned-pixels',
      query: { companyId, accountId },
    })
      .then((result) => {
        if (result.list) {
          resolve(result.list)
        } else {
          reject(result)
        }
      })
      .catch(reject)
  })

// Fetch Account's client pixels
export const getClientPixels = (accountId, companyId) =>
  new Promise((resolve, reject) => {
    Api.get({
      url: '/api/facebook/get-client-pixels',
      query: { accountId, companyId },
    })
      .then((result) => {
        if (result.list) {
          resolve(result.list)
        } else {
          reject(result)
        }
      })
      .catch(reject)
  })

export const getFacebookPageForms = (companyId, pageId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/facebook/get-page-forms',
      query: { companyId, pageId },
    })
      .then((result) => {
        if (result.list) {
          resolve(result.list)
        } else {
          reject(result)
        }
      })
      .catch(reject)
  })
}

export const toggleItemOpen = (dispatch, id, value) => {
  dispatch({
    type: ACTIONS.TOGGLE_ITEM_OPEN,
    id,
    value,
  })
}

export const unsetConfirmation = (dispatch, crtStep, id) => {
  dispatch({
    type: ACTIONS.UNSET_CONFIRMATION,
    id: id,
    stepIndex: crtStep,
  })
}

export const setDisabledConfirmedStep = (dispatch, id, value) => {
  dispatch({
    type: ACTIONS.DISABLE_CONFIRMATION,
    id: id,
    value,
  })
}

/** If Special Ad Categories are added to a Campaign, AdSet Audiences have to be limited */
export const limitAudiencesForSpecialAdCategories = (adSet) => {
  const newAdSet = { ...adSet }
  const audiences = adSet?.adSetAudience || {}
  const newAudiences = { ...audiences }

  /** Check if we have settings that have to be limited. If they exist, limit them */

  // Age
  if (audiences.locations?.ageMin) {
    newAudiences.locations.ageMin =
      SPECIAL_AD_CATEGORIES_AUDIENCE_LIMITS.age.min
  }
  if (audiences.locations?.ageMax) {
    newAudiences.locations.ageMax =
      SPECIAL_AD_CATEGORIES_AUDIENCE_LIMITS.age.max
  }

  // Gender
  if (audiences.locations?.gender) {
    newAudiences.locations.gender = SPECIAL_AD_CATEGORIES_AUDIENCE_LIMITS.gender
  }

  // Locations
  if (audiences.locations) {
    const { locationsArray } = audiences.locations
    if (locationsArray?.length) {
      const newLocationsArray = locationsArray.filter(
        ({ type, distance, radius, filter }) =>
          // Filter out excluded locations
          !SPECIAL_AD_CATEGORIES_AUDIENCE_LIMITS.location.filters.includes(
            filter
          ) &&
          // Filter out unsupported types
          !SPECIAL_AD_CATEGORIES_AUDIENCE_LIMITS.location.types.includes(
            type
          ) &&
          // Keep only cities that have a radius
          (type === 'city'
            ? SPECIAL_AD_CATEGORIES_AUDIENCE_LIMITS.location.allowedCityTypes.includes(
                distance
              ) &&
              radius >= SPECIAL_AD_CATEGORIES_AUDIENCE_LIMITS.location.minRadius
            : true)
      )
      newAudiences.locations.locationsArray = [...newLocationsArray]
    }
  }

  // Detailed Targeting
  if (audiences.detailedTargeting?.selectedItems) {
    const newDetailedTargeting =
      audiences.detailedTargeting?.selectedItems.filter(
        ({ filter, path }) =>
          // Filter unsupoorted filters
          !SPECIAL_AD_CATEGORIES_AUDIENCE_LIMITS.detailed_targeting.filters.includes(
            filter
          ) &&
          // Filter unsupported types
          !path.some((type) =>
            SPECIAL_AD_CATEGORIES_AUDIENCE_LIMITS.detailed_targeting.types.includes(
              type
            )
          )
      )

    newAudiences.detailedTargeting.selectedItems = [...newDetailedTargeting]
  }

  newAdSet.adSetAudience = { ...newAudiences }

  return newAdSet
}

/**
 * Uploads a video from Ad Content to Facebook for processing
 *
 * @param {String} clientId Company ID
 * @param {String} accountId Facebook Ad Account ID
 * @param {Object} payload Video payload
 * @returns {Promise<String>} Promise containing the Facebook video ID
 */
export const uploadVideo = (clientId, accountId, payload) => {
  const qs = new URLSearchParams({
    clientId,
    accountId,
  }).toString()
  return new Promise((resolve, reject) => {
    Api.post({
      url: `/api/facebook/ad-video?${qs}`,
      data: payload,
    })
      .then((result) => {
        if (result?.success) {
          resolve(result.media)
        } else {
          reject(result)
        }
      })
      .catch(reject)
  })
}

/**
 * Gets details for an AdVideo from Facebook
 *
 * @param {String} clientId Company ID
 * @param {String} videoId AdVideo ID
 * @returns {Promise<Object>} Promise containing the Facebook video ID
 */
export const getVideoDetails = (clientId, videoId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/ad-video/${videoId}`,
      query: { clientId },
    })
      .then((result) => {
        if (result?.success) {
          resolve(result.data)
        } else {
          reject(result)
        }
      })
      .catch(reject)
  })
}

/**
 * Gets the list of Ad Images from Facebook which can be used as thumbnails.
 * Supports filtering.
 *
 * @param {String} clientId Company ID
 * @param {String} accountId Facebook Ad Account ID
 * @param {Object} filters Filtering options. See endpoint implementation for object model
 * @returns {Promise<
 * {
 *  account_id: string,
 *  created_time: string,
 *  hash: string,
 *  width:number,
 *  height:number,
 *  id: string,
 *  name: string,
 *  permalink_url: string,
 *  url: string,
 *  url_128:string,
 *  status: string,
 *  updated_time: string
 * }[]>} Promise containing the Facebook video ID
 */
export const getAdImages = (clientId, accountId, filters) => {
  const qs = new URLSearchParams({
    clientId,
    accountId,
  }).toString()
  return new Promise((resolve, reject) => {
    Api.post({
      url: `/api/facebook/ad-images?${qs}`,
      data: filters,
    })
      .then((result) => {
        if (result?.success) {
          resolve(result.data)
        } else {
          reject(result)
        }
      })
      .catch(reject)
  })
}

/**
 * Gets the list of Ad Videos from Facebook.
 * Supports filtering.
 * @typedef {string} iframe
 *
 * @param {String} clientId Company ID
 * @param {String} accountId Facebook Ad Account ID
 * @param {Object} filters Filtering options. See endpoint implementation for object model
 * @param {Object} socket The socket where the events will be emitted
 * @returns {Promise<
 * {
 *  account_id: string,
 *  created_time: string,
 *  description: string,
 *  id: string,
 *  title: string,
 *  format: Array,
 *  embed_html: iframe
 * }[]>} Promise containing the Facebook video ID
 */
export const getAdVideos = (clientId, accountId, filters, socket) => {
  return new Promise((resolve, reject) => {
    socket.emit(NOTIFICATIONS.getAdVideos.initialise_single_call, {
      adAccount: accountId,
      clientId: clientId,
      filter: filters,
    })
    socket.on(NOTIFICATIONS.getAdVideos.receive, (result) => {
      if (result.success) {
        resolve(result.data)
      } else {
        reject(result.error)
      }
    })
  })
}

export const setLoadingState = (state, dispatch) => {
  dispatch({ type: ACTIONS.SET_LOADING, loading: state })
}

/**
 * Fetches Facebook Ad Previews
 * @param {Object} params Parameters object
 * @param {Object} params.ad Ad item
 * @param {String} params.accountId Account id
 * @param {String} params.adFormat Ad Format
 * @param {String} params.groupKey Placement Group Key
 * @param {Boolean} params.forceTranslation Default translation preview (overrides custom stuff)
 * @param {Boolean} params.translationLocale Language identifier for translation (Language Name)
 * @returns {Promise<unknown>}
 */
export const getFacebookAdPreviews = ({
  ad,
  accountId,
  adFormat,
  groupKey = '',
  forceTranslation,
  translationLocale,
}) => {
  return new Promise((resolve, reject) => {
    const _ad = structuredClone(ad)
    const selectedAdContents = _ad.selectedItems || _ad.selected_items
    const mediaOptions = {}
    const isOdax = _ad.type === ODAX

    const placement = isOdax
      ? ODAX_PLACEMENT_BY_FORMAT[adFormat]
      : PLACEMENT_BY_FORMAT[adFormat]

    const group = isOdax
      ? ODAX_AD_PLACEMENT_GROUP[placement].key
      : AD_PLACEMENT_GROUP_BY_PLACEMENT[placement].key

    if (placement && _ad.placements) {
      const mediaItems =
        _ad.placements[EDIT_PLACEMENT_EDITABLE_OPTIONS.MEDIA.label] || {}
      const selectedMedia = mediaItems[placement] || mediaItems[group]

      if (selectedMedia) {
        if (selectedMedia.isImage) {
          selectedAdContents.imageList = Array.isArray(selectedMedia)
            ? selectedMedia
            : [selectedMedia]
          selectedAdContents.videoList = []
        } else {
          selectedAdContents.videoList = Array.isArray(selectedMedia)
            ? selectedMedia
            : [selectedMedia]
          selectedAdContents.imageList = []
        }
      }

      const headlines =
        _ad.placements[EDIT_PLACEMENT_EDITABLE_OPTIONS.HEADLINE.label] || {}

      const selectedHeadline = headlines[placement] || headlines[group]

      if (selectedHeadline) {
        selectedAdContents.headlineList = Array.isArray(selectedHeadline)
          ? selectedHeadline
          : [selectedHeadline]
      }

      const labels =
        _ad.placements[EDIT_PLACEMENT_EDITABLE_OPTIONS.PRIMARY_TEXT.label] || {}
      const selectedPrimaryText = labels[placement] || labels[group]

      if (selectedPrimaryText) {
        selectedAdContents.primaryTextList = Array.isArray(selectedPrimaryText)
          ? selectedPrimaryText
          : [selectedPrimaryText]
      }

      const urls =
        _ad.placements[EDIT_PLACEMENT_EDITABLE_OPTIONS.DESTINATION.label] || {}
      const selectedUrl = urls[placement] || urls[group]
      if (selectedUrl) {
        selectedAdContents.urlList = Array.isArray(selectedUrl)
          ? selectedUrl
          : [selectedUrl]
      }

      const cropSettings =
        _ad.placements[EDIT_PLACEMENT_EDITABLE_OPTIONS.CROP.label] || {}
      const crops = cropSettings[placement] || cropSettings[group]
      if (crops) {
        mediaOptions.crops = crops
      }

      const backgroundSettings =
        _ad.placements[EDIT_PLACEMENT_EDITABLE_OPTIONS.BACKGROUND.label] || {}
      const backgrounds =
        backgroundSettings[placement] || backgroundSettings[group]
      if (backgrounds) {
        mediaOptions.backgrounds = backgrounds
      }

      const poll = _ad.placements[EDIT_PLACEMENT_EDITABLE_OPTIONS.POLL.label]

      if (poll) {
        mediaOptions.poll = poll
      }
    }

    Api.post({
      url: `/api/facebook/get-ad-previews`,
      data: {
        selectedAdContents,
        account: {
          facebookId: accountId,
        },
        ad: _ad,
        adFormat,
        mediaOptions,
        groupKey,
        forceTranslation,
        translationLocale,
      },
    })
      .then((adPreviews) => {
        if (Array.isArray(adPreviews)) {
          resolve(adPreviews)
        } else {
          reject(adPreviews?.error?.response?.error?.error_user_msg || true)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const changeBriefStatus = (dispatch, flowId, status) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: ACTIONS.SET_LOADING, loading: true })
    Api.put({
      url: `/api/briefs/brief/${flowId}/status`,
      data: {
        status,
      },
    })
      .then(({ success, brief }) => {
        if (success) {
          dispatch({
            type: ACTIONS.UPDATE_BRIEF,
            changes: {
              status,
            },
          })
          return resolve(brief)
        } else {
          return reject('Something went wrong')
        }
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        dispatch({ type: ACTIONS.SET_LOADING, loading: false })
      })
  })
}

/**
 * Approve a brief - this is the method that Synchronizes our data in Facebook
 * If errors come up on save, they are returned and displayed both in the console and in a toast
 * @return {Promise}
 */
export const approveBrief = (dispatch, flowId) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: ACTIONS.SET_LOADING, loading: true })
    return Api.post({
      url: `/api/briefs/final-submit/${flowId}`,
    })
      .then((response) => {
        if (response.success) {
          resolve(response.data)
          showSuccessMessage(
            'Brief Approved and publisher sync started',
            dispatch
          )
          dispatch({
            type: ACTIONS.UPDATE_BRIEF,
            changes: { status: STATUS.PUBLISHING.key },
          })
        } else {
          const errors = buildSyncErrors(response)
          errors.forEach((error) => showErrorMessage(error, dispatch, false))
          reject(errors)
        }
      })
      .catch(reject)
      .finally(() => {
        dispatch({ type: ACTIONS.SET_LOADING, loading: false })
      })
  })
}

/**
 * Returns outdated Reach and Frequency Ad Sets in a {...[campaign name]: ad set} format
 * @param flow Brief
 * @returns {{}|null}
 */
export const getOutdatedReachAndFrequencyAdSets = (flow) => {
  const rfCampaigns = getCampaignsFromBrief(flow)[FACEBOOK].filter(
    (campaign) => campaign.data?.buyingType === BUYING_TYPE_MAP.RESERVED
  )

  if (!rfCampaigns?.length) {
    return null
  }

  const campaigns = {}

  getAdSetsFromBrief(flow)[FACEBOOK].forEach((adSet) => {
    const { campaignId, schedule } = adSet
    rfCampaigns.some((campaign) => {
      const { _id, name } = campaign

      if (_id === campaignId) {
        const startDate = new Date(
          `${schedule.startDate} ${schedule.startTime}`
        )
        if ((startDate.valueOf() - Date.now()) / 1000 / 60 / 60 / 24 < 3) {
          if (!campaigns[name]) {
            campaigns[name] = []
          }
          campaigns[name].push(adSet)
          return true
        }
      }
      return false
    })
  })

  if (!Object.keys(campaigns).length) {
    return null
  }

  return campaigns
}

/**
 * Gets the sync errors for a Brief and updates it in the store
 * @param {Function} dispatch Dispatch function
 * @param {String} briefId Brief ID
 */
export const getSyncErrors = (dispatch, briefId) => {
  Api.get({
    url: `/api/briefs/sync-errors/${briefId}`,
  })
    .then((response) => {
      if (!response || !response.success) {
        console.error('Unable to fetch sync errors', response)
        return
      }
      dispatch({
        type: ACTIONS.UPDATE_BRIEF,
        changes: {
          syncErrors: response.data,
        },
      })
    })
    .catch((error) => console.error(error))
}

/** Convenient way to access the step's path directly based on its idx **/
export const STEPS_PATH_MAP = Object.keys(STEPS_MAP).reduce((result, key) => {
  return { ...result, ...{ [STEPS_MAP[key].idx]: STEPS_MAP[key].path } }
}, {})

/**
 * Sync odax sales campaigns
 * Remove once all steps for sales are implemented
 * @param {Function} dispatch dispatch
 * @param {Array} campaigns Array of odax sales campaigns
 */
export const syncOdaxSalesCampaigns = (dispatch, campaigns) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: ACTIONS.SET_LOADING, loading: true })
    return Api.post({
      url: `/api/campaign/odax/sync-odax-campaigns`,
      data: {
        campaigns: campaigns,
      },
    })
      .then((response) => {
        if (response.success) {
          resolve(response.data)
          showSuccessMessage('Odax Campaign synced successfully', dispatch)
        } else {
          const errors = buildSyncErrors(response)
          errors.forEach((error) => showErrorMessage(error, dispatch, false))
          reject(errors)
        }
      })
      .catch(reject)
      .finally(() => {
        dispatch({ type: ACTIONS.SET_LOADING, loading: false })
      })
  })
}

/**
 * Sync ODAX sales adSets
 * Remove once all steps for sales are implemented
 * @param {Function} dispatch dispatch
 * @param {Array} adSets Array of odax sales adSets
 */
export const syncOdaxAdSets = (dispatch, adSets) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: ACTIONS.SET_LOADING, loading: true })
    return Api.post({
      url: `/api/adset/odax/sync-odax-adsets`,
      data: {
        adSets,
      },
    })
      .then((response) => {
        if (response.success) {
          resolve(response.data)
          showSuccessMessage('Odax AdSets synced successfully', dispatch)
        } else {
          const errors = buildSyncErrors(response)
          errors.forEach((error) => showErrorMessage(error, dispatch, false))
          reject(errors)
        }
      })
      .catch(reject)
      .finally(() => {
        dispatch({ type: ACTIONS.SET_LOADING, loading: false })
      })
  })
}

export const splitDeleteAccountsFromBrief = (
  dispatch,
  briefId,
  { newBriefsDetails, deleted, allFailed }
) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: ACTIONS.SET_LOADING, loading: true })
    return Api.post({
      url: `/api/briefs/brief/${briefId}/split-delete`,
      data: { newBriefsDetails, deleted, allFailed },
    })
      .then((res) => {
        if (!res.success) {
          console.error(res.error)
        }
        resolve(res.brief)
      })
      .catch((error) => {
        console.error(error)
        reject()
      })
      .finally(() => {
        dispatch({ type: ACTIONS.SET_LOADING, loading: false })
      })
  })
}

/**
 * Sync ODAX sales ads
 * Remove once all steps for sales are implemented
 * @param {Function} dispatch dispatch
 * @param {Array} ads Array of odax sales ads
 */
export const syncOdaxAds = (dispatch, ads) => {
  return new Promise((resolve) => {
    dispatch({ type: ACTIONS.SET_LOADING, loading: true })
    return Api.post({
      url: `/api/ad/odax/sync-odax-ads`,
      data: {
        ads,
      },
    })
      .then((response) => {
        if (response.success) {
          resolve(response.data)
          showSuccessMessage('Odax Ads synced successfully', dispatch)
        } else {
          const errors = buildSyncErrors(response)
          errors.forEach((error) => showErrorMessage(error, dispatch, false))
          console.error(errors)
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        dispatch({ type: ACTIONS.SET_LOADING, loading: false })
      })
  })
}
