import React from 'react'
import { useOutletContext } from 'react-router-dom'
import CreateEditBusinessUnit from 'modules/business-units'
import Loader from 'components/loader'

const BusinessUnits = () => {
  const { company, currentBusinessUnitData } = useOutletContext()
  const { businessUnit } = currentBusinessUnitData

  if (!company || !businessUnit) {
    return <Loader />
  }

  return (
    <React.Fragment>
      <div
        className="heading"
        data-cy={
          !businessUnit.new
            ? 'edit-business-unit-page-heading'
            : 'add-business-unit-page-heading'
        }
      >
        {!businessUnit.new ? 'Edit' : 'Add'} Business Unit
      </div>
      <CreateEditBusinessUnit
        isCreate={businessUnit.new}
        businessUnitClientId={businessUnit.clientId}
        businessUnitId={businessUnit._id}
      />
    </React.Fragment>
  )
}

export default BusinessUnits
