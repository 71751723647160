import React, { useEffect, useState } from 'react'
import Api from 'easy-fetch-api'
import { useParams, useNavigate } from 'react-router-dom'
import CompanyConfig from 'modules/config'
import { useStore } from 'store'
import { NOT_FOUND_ROUTE } from 'routes'
import Loader from 'components/loader/index'

const ConfigurationPage = () => {
  const { company } = useParams()
  const navigate = useNavigate()
  const [config, setConfig] = useState(null)
  const [loading, setLoading] = useState(null)
  const companyId = company || '3q'
  const {
    state: {
      companies: { currentCompany },
    },
  } = useStore()

  useEffect(() => {
    if (companyId !== '3q' && companyId !== currentCompany.clientId) {
      navigate(NOT_FOUND_ROUTE, true)
    } else {
      setLoading(true)
      Api.get({ url: `/api/config/${companyId}` })
        .then((res) => {
          if (res.success) {
            setConfig(res.config)
          } else {
            console.error(res)
          }
        })
        .catch(console.error)
        .finally(() => setLoading(false))
    }
  }, [currentCompany])

  if (loading) {
    return <Loader />
  }

  return <CompanyConfig company={companyId} config={config} />
}

export default ConfigurationPage
