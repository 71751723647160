import React, { useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import cx from 'classnames'

import Modal from 'components/modal/index'
import PropTypes from 'prop-types'

import { LegalItemContent } from 'modules/legal/components/legal-item-content'


import closeIcon from 'assets/icon_close_blue.svg'
import { legal } from '@decision-sciences/qontrol-common'

const { LEGAL_TYPES } = legal

/**
 * Legal View Item.
 * Component for display legal content
 * @param {Object} params React Params
 * @param {Object} params.item Legal Data
 */
const LegalViewItem = ({ item }) => {
  const [openModal, setOpenModal] = useState(false)
  const { slug } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  if (item && slug && item.slug === slug) {
    setTimeout(() => {
      setOpenModal(true)
    }, 100)
  }

  const closeModal = () => {
    if (slug) {
      // Remove params on close modal
      const url = location.pathname
      navigate(url.split('/')[0])
    }
    setOpenModal(false)
  }

  return (
    <div className="legal-modal">
      {openModal && (
        <Modal opened={openModal}>
          <img
            src={closeIcon}
            alt="close"
            className="flow-modal-content__close-icon"
            onClick={() => closeModal()}
          />
          <div id={item._id} className="modal-view">
            <LegalItemContent item={item} classes={'modal-content'} />
          </div>
        </Modal>
      )}
      <div
        onClick={() => {
          item.type !== LEGAL_TYPES.SIMPLE_TEXT ? setOpenModal(true) : null
        }}
        className={cx(
          'text',
          { 'text-link': item.type !== LEGAL_TYPES.SIMPLE_TEXT },
          { 'text--simple': item.type === LEGAL_TYPES.SIMPLE_TEXT }
        )}
      >
        {item.name}
      </div>
    </div>
  )
}

LegalViewItem.propTypes = {
  item: PropTypes.object,
}

export default LegalViewItem

