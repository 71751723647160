import { ACCOUNT_TYPE_ICONS } from 'constants/account'
import { accounts } from '@decision-sciences/qontrol-common'

const { ACCOUNT_TYPES_MAP, ACCOUNT_TYPE_NAMES } = accounts

const {
  GOOGLE,
  FACEBOOK,
  MICROSOFT,
  TRADE_DESK,
  LINKED_IN,
  TIKTOK,
  DV360,
  AMAZON_DSP,
  AMAZON_ADVERTISING,
  TWITTER,
  APPLE,
  GOOGLE_CAMPAIGN_MANAGER,
  SNAPCHAT,
  SA360,
  GOOGLE_ANALYTICS,
  REDDIT,
  PINTEREST,
} = ACCOUNT_TYPES_MAP

export const ACCOUNT_ORDER = {
  [GOOGLE]: 0,
  [MICROSOFT]: 1,
  [SA360]: 2,
  [GOOGLE_ANALYTICS]: 3,
  [FACEBOOK]: 4,
  [LINKED_IN]: 5,
  [TIKTOK]: 6,
  [REDDIT]: 7,
  [TWITTER]: 8,
  [SNAPCHAT]: 9,
  [PINTEREST]: 10,
  [AMAZON_ADVERTISING]: 11,
  [GOOGLE_CAMPAIGN_MANAGER]: 12,
  [DV360]: 13,
  [TRADE_DESK]: 14,
  [AMAZON_DSP]: 15,
  [APPLE]: 16,
}

// Keep them in this order so we don't have to order them whenever they get rendered on the FE
export const ACCOUNT_TYPES = [
  {
    id: GOOGLE,
    name: ACCOUNT_TYPE_NAMES[GOOGLE],
    icon: ACCOUNT_TYPE_ICONS[GOOGLE],
    order: ACCOUNT_ORDER[GOOGLE],
  },
  {
    id: MICROSOFT,
    name: ACCOUNT_TYPE_NAMES[MICROSOFT],
    icon: ACCOUNT_TYPE_ICONS[MICROSOFT],
    order: ACCOUNT_ORDER[MICROSOFT],
  },
  {
    id: SA360,
    name: ACCOUNT_TYPE_NAMES[SA360],
    icon: ACCOUNT_TYPE_ICONS[SA360],
    order: ACCOUNT_ORDER[SA360],
  },
  {
    id: GOOGLE_ANALYTICS,
    name: ACCOUNT_TYPE_NAMES[GOOGLE_ANALYTICS],
    icon: ACCOUNT_TYPE_ICONS[GOOGLE_ANALYTICS],
    order: ACCOUNT_ORDER[GOOGLE_ANALYTICS],
  },
  {
    id: FACEBOOK,
    name: ACCOUNT_TYPE_NAMES[FACEBOOK],
    icon: ACCOUNT_TYPE_ICONS[FACEBOOK],
    order: ACCOUNT_ORDER[FACEBOOK],
  },

  {
    id: LINKED_IN,
    name: ACCOUNT_TYPE_NAMES[LINKED_IN],
    icon: ACCOUNT_TYPE_ICONS[LINKED_IN],
    order: ACCOUNT_ORDER[LINKED_IN],
  },

  {
    id: TIKTOK,
    name: ACCOUNT_TYPE_NAMES[TIKTOK],
    icon: ACCOUNT_TYPE_ICONS[TIKTOK],
    order: ACCOUNT_ORDER[TIKTOK],
  },
  {
    id: REDDIT,
    name: ACCOUNT_TYPE_NAMES[REDDIT],
    icon: ACCOUNT_TYPE_ICONS[REDDIT],
    order: ACCOUNT_ORDER[REDDIT],
  },
  {
    id: TWITTER,
    name: ACCOUNT_TYPE_NAMES[TWITTER],
    icon: ACCOUNT_TYPE_ICONS[TWITTER],
    order: ACCOUNT_ORDER[TWITTER],
  },
  {
    id: SNAPCHAT,
    name: ACCOUNT_TYPE_NAMES[SNAPCHAT],
    icon: ACCOUNT_TYPE_ICONS[SNAPCHAT],
    order: ACCOUNT_ORDER[SNAPCHAT],
  },

  {
    id: PINTEREST,
    name: ACCOUNT_TYPE_NAMES[PINTEREST],
    icon: ACCOUNT_TYPE_ICONS[PINTEREST],
    order: ACCOUNT_ORDER[PINTEREST],
  },

  {
    id: AMAZON_ADVERTISING,
    name: ACCOUNT_TYPE_NAMES[AMAZON_ADVERTISING],
    icon: ACCOUNT_TYPE_ICONS[AMAZON_ADVERTISING],
    order: ACCOUNT_ORDER[AMAZON_ADVERTISING],
  },
  {
    id: GOOGLE_CAMPAIGN_MANAGER,
    name: ACCOUNT_TYPE_NAMES[GOOGLE_CAMPAIGN_MANAGER],
    icon: ACCOUNT_TYPE_ICONS[GOOGLE_CAMPAIGN_MANAGER],
    order: ACCOUNT_ORDER[GOOGLE_CAMPAIGN_MANAGER],
  },
  {
    id: DV360,
    name: ACCOUNT_TYPE_NAMES[DV360],
    icon: ACCOUNT_TYPE_ICONS[DV360],
    order: ACCOUNT_ORDER[DV360],
  },
  {
    id: TRADE_DESK,
    name: ACCOUNT_TYPE_NAMES[TRADE_DESK],
    icon: ACCOUNT_TYPE_ICONS[TRADE_DESK],
    order: ACCOUNT_ORDER[TRADE_DESK],
  },

  {
    id: AMAZON_DSP,
    name: ACCOUNT_TYPE_NAMES[AMAZON_DSP],
    icon: ACCOUNT_TYPE_ICONS[AMAZON_DSP],
    order: ACCOUNT_ORDER[AMAZON_DSP],
  },
  {
    id: APPLE,
    name: ACCOUNT_TYPE_NAMES[APPLE],
    icon: ACCOUNT_TYPE_ICONS[APPLE],
    order: ACCOUNT_ORDER[APPLE],
  },
]
