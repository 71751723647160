import React, { useState, Children, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Arrow from 'components/arrow/index'

import './style.scss'

const Section = ({
  header,
  children,
  className,
  dataTestId,
  headerClassName,
  bodyClassName,
  isExpandable,
  defaultExpanded = true,
  onExpand,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded)

  useEffect(() => {
    setExpanded(defaultExpanded)
  }, [defaultExpanded])

  const onClickArrow = (value) => {
    onExpand && onExpand(value)
    setExpanded(value)
  }

  return (
    <section
      data-testid={dataTestId}
      className={cx('section', {
        [className]: Boolean(className),
      })}
    >
      <div
        className={cx('section__header', {
          ['section__header--expandable']: isExpandable,
          [headerClassName]: Boolean(headerClassName),
        })}
      >
        {header}
        {isExpandable && (
          <Arrow
            className="section__header__arrow"
            expanded={expanded}
            onChange={onClickArrow}
          />
        )}
      </div>
      {(!isExpandable || expanded) &&
        Children.map(children, (child, index) => (
          <div
            key={index}
            className={cx('section__body', {
              [bodyClassName]: Boolean(bodyClassName),
            })}
          >
            {child}
          </div>
        ))}
    </section>
  )
}

Section.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  isExpandable: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  onExpand: PropTypes.func,
}

export default Section
