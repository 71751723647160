import Cookies from 'js-cookie'
import { STORAGE_MENU_STATE } from 'components/left-menu/constants'
import { config } from '@decision-sciences/qontrol-common'
import { ACTIONS } from './actions'

const { COOKIE_NAME, LC_USER_DATA, LC_TABLE_FILTER_SORT } = config

/** Name of the reducer */
const name = 'session'

const isLoggedIn = !!Cookies.get(COOKIE_NAME)
let defaultUserData = _getUserData()

if (!isLoggedIn) {
  localStorage.removeItem(LC_USER_DATA)
  localStorage.removeItem(LC_TABLE_FILTER_SORT)
  defaultUserData = null
}

/** Initial state of the reducer */
const initialState = {
  isLoggedIn,
  userData: defaultUserData,
  expires: false,
  inactivityWarningTimeout: 1000 * 60 * 15, // 15 minutes by default
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.GET_USER_DATA]: (state, { userData }) => {
    localStorage.setItem(
      LC_USER_DATA,
      JSON.stringify({
        ...userData,
      })
    )
    return { ...state, userData, isLoggedIn: !!Cookies.get(COOKIE_NAME) }
  },
  [ACTIONS.LOGOUT]: (state) => {
    localStorage.removeItem(LC_USER_DATA)
    localStorage.removeItem(LC_TABLE_FILTER_SORT)
    localStorage.removeItem(STORAGE_MENU_STATE)
    return {
      ...state,
      userData: null,
      isLoggedIn: false,
    }
  },
  [ACTIONS.SET_EXPIRATION]: (state, { expires, inactivityWarningTimeout }) => {
    const newState = { ...state, expires }

    if (inactivityWarningTimeout) {
      newState.inactivityWarningTimeout = inactivityWarningTimeout
    }

    return newState
  },
}

function _getUserData() {
  let userData = null
  try {
    userData = JSON.parse(localStorage.getItem(LC_USER_DATA))
  } catch (e) {
    console.error(e)
  }
  return userData
}

export default { name, initialState, reduce }

