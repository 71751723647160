import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

/** Components */
import InputSearch from 'components/input-search/index'
import FieldDropdown from 'components/field-dropdown/index'

/* Store & Actions */
import { useStore } from 'store'
import { editCampaign } from 'modules/flow/actions'
import { searchLocationsToTarget } from 'modules/google/actions'

/* Constants */
import { accounts } from '@decision-sciences/qontrol-common'

const { ACCOUNT_TYPES_MAP } = accounts

const SearchLocations = ({ campaign, errors = {} }) => {
  const {
    dispatch,
    state: { companies },
  } = useStore()
  const clientId = companies.currentCompany._id
  const locations_to_target = campaign?.locations_to_target || []

  const [locations, setLocations] = useState([])
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (search) {
      editCampaign({
        dispatch,
        id: campaign.id,
        platform: ACCOUNT_TYPES_MAP.GOOGLE,
        errors: { ...errors, locations_to_target: null },
      })

      setLocations([])
      setLoading(true)
      searchLocationsToTarget(search, clientId, campaign.account)
        .then((data) => {
          if (data) {
            setLocations(data)
          }
        })
        .finally(() => setLoading(false))
    } else {
      setLocations([])
    }
  }, [search])

  // Reset input value and options
  const resetInputSearch = () => {
    setSearch('')
    setLocations([])
  }

  const onCampaignChanged = (newCampaign) =>
    editCampaign({
      dispatch,
      id: campaign.id,
      platform: ACCOUNT_TYPES_MAP.GOOGLE,
      data: newCampaign,
    })

  const onSelectLocation = (location, target) => {
    resetInputSearch()
    const item = { ...location, exclude: target }
    onCampaignChanged({
      locations_to_target: [...locations_to_target, item],
    })
  }

  const onRemoveLocation = (item) => {
    onCampaignChanged({
      locations_to_target: locations_to_target.filter(
        (location) => location.name !== item.name
      ),
    })
  }

  const _renderLocationOption = (option) => {
    const name = option.name.replace(/,/g, ', ')
    return (
      <div className="align-row">
        <div>{name}</div>
        <div className="general-description location-type">
          {option.geoType}
        </div>
        <div className="location-tag">
          <div
            className="general-label-blue"
            onClick={(ev) => {
              ev.stopPropagation()
              onSelectLocation(option, false)
            }}
          >
            {TARGET_TYPE.TARGET}
          </div>
          <div
            className="general-label-blue"
            onClick={(ev) => {
              ev.stopPropagation()
              onSelectLocation(option, true)
            }}
          >
            {TARGET_TYPE.EXCLUDE}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {/* Show existing locations */}
      <div className="margin-bottom-22">
        {locations_to_target.map((item, index) => (
          <div key={index} className="selected-location">
            <FieldDropdown
              text={item.name.replace(/,/g, ', ')}
              description={item.geoType}
              options={[TARGET_TYPE.TARGET, TARGET_TYPE.EXCLUDE]}
              selectedValue={
                item.exclude ? TARGET_TYPE.EXCLUDE : TARGET_TYPE.TARGET
              }
              onChange={(status) => {
                locations_to_target[index].exclude =
                  status === TARGET_TYPE.EXCLUDE
                onCampaignChanged({
                  locations_to_target: locations_to_target,
                })
              }}
              onRemove={() => onRemoveLocation(item)}
            />
          </div>
        ))}
      </div>
      <div className="margin-bottom-22">
        <InputSearch
          hasIcon
          defaultValue={search}
          loading={loading}
          options={locations}
          valueAttribute="name"
          secondaryAttribute="type"
          optionRenderer={_renderLocationOption}
          iconVariant={'blue'}
          error={errors.locations_to_target}
          placeholder={'Enter a location to target or exclude'}
          onSearch={setSearch}
          onSelect={(el) => {
            onSelectLocation(el, false)
          }}
        />
      </div>
    </>
  )
}
export const TARGET_TYPE = {
  TARGET: 'Target',
  EXCLUDE: 'Exclude',
}
SearchLocations.propTypes = {
  campaign: PropTypes.object.isRequired,
  errors: PropTypes.object,
}
export default SearchLocations

