import React from 'react'
import PropTypes from 'prop-types'

import LineDivider from 'components/line-divider'
import InformationBlock from 'components/information-block'

import RuleHeader from './rule-header'
import { defaultRule } from '../../../constants'
import OperandButton from './operand-button'
import InputGroupHeading from './input-group-heading'
import RuleInputGroup from './rule-input-group'

/**
 * Publisher rules section and its functionality
 * @param {Object} props
 * @param {Object} props.exclusion - The exclusion object for witch we want to render the specific rules
 * @param {Array} props.publishers - A list with the publishers that tells us which ones are selected
 * @param {Function} props.setExclusion - Method to be called to update the exclusion
 * @param {string} props.accountsByPublisher - A map with accounts by publisher
 * @param {object} props.fields - The fields for the input group property dropdown
 */
const Rules = ({
  exclusion,
  publishers,
  setExclusion,
  accountsByPublisher,
  fields,
}) => {
  const setOperand = (value, publisher) => {
    updateConfig(publisher, {
      ...publisher.config,
      operand: value,
    })
  }

  const updateConfig = (publisher, updatedPublisherConfig) => {
    if (publisher.id === 'GLOBAL') {
      setExclusion({ ...exclusion, globalConfig: updatedPublisherConfig })
    } else {
      setExclusion({
        ...exclusion,
        publishersConfig: {
          ...exclusion.publishersConfig,
          [publisher.id.toUpperCase()]: updatedPublisherConfig,
        },
      })
    }
  }

  const onAddClicked = (publisher) => {
    const updatedPublisherConfig = {
      ...publisher.config,
      rules: [...publisher.config.rules, { ...defaultRule }],
    }

    updateConfig(publisher, updatedPublisherConfig)
  }

  const onRemoveClicked = (index, publisher) => {
    const updatedPublisherConfig = {
      ...publisher.config,
      rules: [...publisher.config.rules.filter((e, i) => i !== index)],
    }
    updateConfig(publisher, updatedPublisherConfig)
  }

  const checkForDuplicateRules = (rules) => {
    rules.some((existingRule, index) => {
      rules.some((checkedRule, checkedRuleIndex) => {
        if (
          checkedRuleIndex !== index &&
          JSON.stringify(existingRule.value) ===
            JSON.stringify(checkedRule.value) &&
          existingRule.property === checkedRule.property &&
          existingRule.value !== ''
        ) {
          existingRule.duplicated = checkedRule.duplicated = true
        }
      })
    })
  }

  const onRuleChanged = (newRule, publisher, ruleIndex) => {
    const publisherKey = publisher.id.toUpperCase()

    if (publisherKey === 'GLOBAL') {
      // Set the new rule's data based on the given index within the global config rules
      const updatedGlobalConfigRules = exclusion.globalConfig.rules.map(
        (r, i) =>
          ruleIndex === i
            ? { ...newRule, duplicated: false }
            : { ...r, duplicated: false }
      )

      checkForDuplicateRules(updatedGlobalConfigRules)

      setExclusion({
        ...exclusion,
        globalConfig: {
          ...exclusion.globalConfig,
          rules: updatedGlobalConfigRules,
        },
      })
    } else {
      // Set the new rule's data based on the given index and publisherKey within the publisher config rules
      const updatedPublisherRules = exclusion.publishersConfig[
        publisherKey
      ].rules.map((r, i) =>
        ruleIndex === i
          ? { ...newRule, duplicated: false }
          : { ...r, duplicated: false }
      )

      checkForDuplicateRules(updatedPublisherRules)

      setExclusion({
        ...exclusion,
        publishersConfig: {
          ...exclusion.publishersConfig,
          [publisherKey]: {
            ...exclusion.publishersConfig[publisherKey],
            rules: updatedPublisherRules,
          },
        },
      })
    }
  }

  // If there is no globalConfig nor any publisherConfig data we hide this rules section
  if (
    !exclusion.globalConfig &&
    (!exclusion.publishersConfig ||
      !Object.keys(exclusion.publishersConfig).length)
  ) {
    return null
  }

  const _renderRules = (rule) => {
    // If there isn't any publisher selected, hide the rules section
    if (!rule.globalConfig && !rule.publishersConfig) {
      return null
    }

    // Determine the current selected publishers (either the global alone, or 1+ custom publishers)
    const publishersToRenderRulesFor = publishers
      .filter((p) => p.selected && !p.disabled)
      .sort((a, b) => (a.id < b.id ? -1 : 1))
      .map((p) => ({
        ...p,
        // We set the details about the rules (and operand) within a common config property to easily render both use cases
        config:
          p.id === 'GLOBAL'
            ? rule.globalConfig
            : rule.publishersConfig?.[p.id.toUpperCase()],
      }))

    return (
      <>
        {/* Loop and display each publisher */}
        {publishersToRenderRulesFor.map((publisher, publisherIndex) => (
          <div
            key={publisherIndex}
            className="campaign-exclusions-section__rule-detail"
          >
            <RuleHeader publisher={publisher} />

            {/* Loop and display each rule from the publisher */}
            {publisher.config?.rules?.map((rule, index) => (
              <div key={index} className="margin-bottom-16">
                <InputGroupHeading rule={rule} />
                <RuleInputGroup
                  publisher={publisher}
                  publishers={publishers}
                  rule={rule}
                  onRuleChanged={(newRule) =>
                    onRuleChanged(newRule, publisher, index)
                  }
                  onRemoveClicked={() => onRemoveClicked(index, publisher)}
                  onAddClicked={() => onAddClicked(publisher)}
                  numberOfRules={publisher.config?.rules?.length}
                  accountsByPublisher={accountsByPublisher}
                  fields={fields}
                />
                <OperandButton
                  onOperandChanged={setOperand}
                  hidden={index >= publisher.config.rules.length - 1}
                  publisher={publisher}
                />
              </div>
            ))}
            {publisher.config?.rules.some((rule) => rule.duplicated) ? (
              <InformationBlock
                className="margin-bottom-16 duplicate-information-block"
                info="A campaign exclusion rule cannot have duplicate criteria"
              />
            ) : null}
            {publisherIndex < publishersToRenderRulesFor.length - 1 ? (
              <LineDivider />
            ) : null}
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <LineDivider className="margin-bottom-16" width="unset" widthUnit="" />
      <div className="campaign-exclusions-section__title">Rules</div>
      <LineDivider className="margin-bottom-16" width="unset" widthUnit="" />
      {_renderRules(exclusion)}
    </>
  )
}

export default Rules

Rules.propTypes = {
  exclusion: PropTypes.object.isRequired,
  publishers: PropTypes.array.isRequired,
  setExclusion: PropTypes.func.isRequired,
  accountsByPublisher: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
}
