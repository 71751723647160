import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useStore } from 'store'
import {
  getVariableMetrics,
  getCalculatedMetrics,
} from 'modules/calculated-metrics/actions'
import VariableAddEdit from 'modules/calculated-metrics/variables/create-edit'
import Loader from 'components/loader'

const MetricVariableManagementPage = () => {
  const { variableKey } = useParams()
  const { dispatch, state } = useStore()
  const { variables, list } = state.calculatedMetrics
  const {
    session: { userData },
  } = state

  useEffect(() => {
    if (!variables) {
      getVariableMetrics(dispatch)
    }
  }, [variables])

  useEffect(() => {
    if (!list) {
      getCalculatedMetrics(dispatch)
    }
  }, [list])

  if (!list || !variables) {
    return <Loader />
  }

  return (
    <VariableAddEdit
      dispatch={dispatch}
      variables={variables}
      variableKey={variableKey}
      calculatedMetrics={list}
      userData={userData}
    />
  )
}

export default MetricVariableManagementPage
