import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ReactComponent as Checkbox_checked } from 'assets/icon_checkbox_square_grey.svg'
import { ReactComponent as Checkbox_unchecked } from 'assets/icon_checkbox_empty_white.svg'
import { ReactComponent as Checkbox_indeterminate } from 'assets/icon_checkbox_indeterminate_grey.svg'

import './style.scss'

/**
 * Checkbox for "All Selected" situations
 * @param {Object} params React Params
 * @param {'ALL'|'NONE'|'SOME'} params.value State of the checkbox
 * @param {Node} params.label Label for the checkbox
 * @param {String} params.className Extra classname
 * @param {Function} params.onClick On change callback
 * @param {Boolean} params.hasBorders Purely aesthetic, true means it has a border around
 * @param {Boolean} params.isBlue Purely aesthetic, true means the checkbox is colored blue
 * @param {Boolean} params.isBig Purely aesthetic, true means the checkbox is slightly bigger
 * @param {Boolean} params.disabled Flag to disable the select all checkbox
 */
const CheckboxSelectAll = ({
  value = CHECKBOX_VALUES.NONE,
  className,
  label,
  onClick,
  hasBorders,
  isBlue,
  isBig,
  disabled = false,
}) => {
  return (
    <div
      data-testid="checkbox-select-all"
      className={cx('checkbox-select-all', {
        [className]: Boolean(className),
        'checkbox-select-all--clickable': Boolean(onClick),
        'checkbox-select-all--bordered': hasBorders,
        'checkbox-select-all--blue': isBlue,
        'checkbox-select-all--big': isBig,
        'checkbox-select-all--disabled': disabled,
        'checkbox-select-all--checked': value === CHECKBOX_VALUES.ALL,
        'checkbox-select-all--indeterminate': value === CHECKBOX_VALUES.SOME,
        'checkbox-select-all--none': value === CHECKBOX_VALUES.NONE,
      })}
      onClick={!disabled ? onClick : null}
    >
      <div className="checkbox-select-all__icon">{iconMap[value]}</div>
      {Boolean(label) && (
        <div className="checkbox-select-all__label">{label}</div>
      )}
    </div>
  )
}

export const CHECKBOX_VALUES = {
  ALL: 'ALL',
  NONE: 'NONE',
  SOME: 'SOME',
}

/**
 * Custom Hook for easy usage of indeterminate checkbox states
 * While it CAN be used without CheckboxSelectAll, it was built and intended for this CheckboxSelectAll and was not tested without it.
 * @param {Object[]} allItems Items of format { _id: ... }
 * @param {String} idKey custom id key, for items that have a different identifier than _id
 * @param {Functon} onChange Callback for when selected items are changed
 */
export const useSelectAll = (allItems, idKey = '_id', onChange) => {
  const [selectedItems, setSelectedItems] = useState({})

  const toggleSelected = (ids, value) => {
    const newSelected = { ...selectedItems }

    // Check if ids is an array, if not, convert it to an array
    const idsArray = Array.isArray(ids) ? ids : [ids]

    // Determine the new value for each ID
    idsArray.forEach((id) => {
      const newValue = typeof value === 'boolean' ? value : !selectedItems[id]
      if (!newValue) {
        delete newSelected[id]
      } else {
        newSelected[id] = true
      }
    })

    setSelectedItems(newSelected)
    onChange && onChange(newSelected)
  }

  const getIndividualValue = (id) => {
    return selectedItems[id] ? CHECKBOX_VALUES.ALL : CHECKBOX_VALUES.NONE
  }

  const getMultipleValues = (ids) => {
    const allSelected = ids.every((id) => selectedItems[id])
    const someSelected = ids.some((id) => selectedItems[id])
    if (allSelected) {
      return CHECKBOX_VALUES.ALL
    } else if (someSelected) {
      return CHECKBOX_VALUES.SOME
    } else {
      return CHECKBOX_VALUES.NONE
    }
  }

  const allCheckedValue = useMemo(() => {
    let someSelected = false
    let allSelected = true

    if (!allItems?.length) {
      return CHECKBOX_VALUES.NONE
    }

    allItems.forEach((item) => {
      if (selectedItems[item[idKey]]) {
        someSelected = true
      } else {
        allSelected = false
      }
    })

    if (allSelected && someSelected) {
      return CHECKBOX_VALUES.ALL
    }

    if (someSelected) {
      return CHECKBOX_VALUES.SOME
    }

    return CHECKBOX_VALUES.NONE
  }, [selectedItems])

  const toggleAll = (value) => {
    const newValue =
      typeof value === 'boolean'
        ? value
        : allCheckedValue !== CHECKBOX_VALUES.ALL // If there's no value, it goes false in case ALL are selected

    if (!newValue) {
      onChange && onChange({})
      return setSelectedItems({})
    }

    const newSelectedItems = (allItems || []).reduce(
      (prev, current) => ({ ...prev, [current[idKey]]: newValue }),
      {}
    )
    setSelectedItems(newSelectedItems)
    onChange && onChange(newSelectedItems)
  }

  return {
    allCheckedValue,
    getIndividualValue,
    getMultipleValues,
    isSomeSelected: allCheckedValue !== CHECKBOX_VALUES.NONE,
    selectedItems,
    toggleSelected,
    toggleAll,
    setSelectedItems,
  }
}

/**
 * Function to help determining the checkbox state
 * @param {Boolean} someSelected Flag signaling if there is at least one item selected
 * @param {Boolean} allSelected Flag signaling if all items are selected
 * @returns {String} See {@link CHECKBOX_VALUES}
 */
export const computeCheckboxState = (someSelected, allSelected) => {
  if (allSelected) {
    return CHECKBOX_VALUES.ALL
  }
  if (someSelected) {
    return CHECKBOX_VALUES.SOME
  }
  return CHECKBOX_VALUES.NONE
}

const iconMap = {
  [CHECKBOX_VALUES.ALL]: <Checkbox_checked />,
  [CHECKBOX_VALUES.NONE]: <Checkbox_unchecked />,
  [CHECKBOX_VALUES.SOME]: <Checkbox_indeterminate />,
}

CheckboxSelectAll.propTypes = {
  value: PropTypes.oneOf(Object.values(CHECKBOX_VALUES)),
  label: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  hasBorders: PropTypes.bool,
  isBlue: PropTypes.bool,
  isBig: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default CheckboxSelectAll
