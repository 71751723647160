import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/* Constants */
import { ACCOUNT_TYPES } from 'modules/accounts/common'

/* Components */
import InformationBlock from 'components/information-block'
import Loader from 'components/loader'
import { useEffectOnUpdate } from 'components/utils/custom-hooks'
import { AccountIcon } from 'components/account-icon'

import './style.scss'

/**
 * Account Type selection component.
 * @param {Object} props Props of the React Component
 * @param {Array} [props.defaultSelected] Array of previously selected Account Types
 * @param {Function} props.onSelect Returns the currently selected items
 * @param {String} [props.label]
 * @param {Boolean} [props.readOnly]
 * @param {String} [props.error]
 * @param {Boolean} [props.onlyOne] If true, only 1 account type can be selected at a time.PropTypes
 * @param {Boolean} [props.returnKeys] If true, the ids of the Account Types will be returned instead of the names Eg. "bing" instead of "Microsoft Ads"
 * @param {Boolean} [props.disabled]
 * @param {Array} props.availableAccounts pre select accounts that are available
 * @param {Object} [props.loadingAccounts] Accounts that are loading individually
 * @param {Boolean} [props.loadingAccounts.$] Account that is loading
 */
const Accounts = ({
  label,
  defaultSelected = [],
  onSelect,
  onlyOne,
  returnKeys,
  disabled,
  availableAccounts,
  loadingAccounts,
  error,
  readOnly,
  allowsOverride,
}) => {
  const [selectedAccounts, setSelectedAccounts] = useState([])
  useEffect(() => {
    defaultSelected &&
      defaultSelected.length &&
      setSelectedAccounts(defaultSelected)
    defaultSelected.length === 0 && setSelectedAccounts([])
  }, [JSON.stringify(defaultSelected)])

  const _selectedAccounts = allowsOverride ? defaultSelected : selectedAccounts

  /** If the available accounts gets changed and we have selected accounts, we have to filter them based on the new availableAccounts */
  useEffectOnUpdate(() => {
    if (_selectedAccounts.length) {
      setSelectedAccounts(
        selectedAccounts.filter((acc) => availableAccounts.includes(acc))
      )
    }
  }, [availableAccounts])

  return (
    <div className="align-column account-component">
      {label && <div className="account-component-label">{label}</div>}
      <div className="account-component__content align-row">
        {[...ACCOUNT_TYPES].map((acc) => {
          const accountIdentifier = returnKeys ? acc.id : acc.name
          const isSelectedAccount = !!_selectedAccounts.find(
            (item) => item === accountIdentifier
          )
          const isAvailableAccount =
            availableAccounts && availableAccounts.includes(acc.id)
          return (
            <Account
              type={acc.id}
              key={acc.id}
              identifier={accountIdentifier}
              selected={isSelectedAccount}
              disabled={disabled || !isAvailableAccount}
              loading={loadingAccounts?.[acc.id]}
              onClick={(account) => {
                let newList
                if (isSelectedAccount) {
                  newList = onlyOne
                    ? _selectedAccounts
                    : [..._selectedAccounts].filter((item) => item !== account)
                } else {
                  newList = onlyOne
                    ? [account]
                    : [..._selectedAccounts, account]
                }
                setSelectedAccounts(newList)
                if (onlyOne) {
                  onSelect(newList[0], account)
                } else {
                  onSelect(newList, account)
                }
              }}
              readOnly={readOnly}
            />
          )
        })}
      </div>
      {error && (
        <div className="margin-top-10">
          <InformationBlock info={error} />
        </div>
      )}
    </div>
  )
}

Accounts.propTypes = {
  label: PropTypes.string,
  defaultSelected: PropTypes.array,
  onSelect: PropTypes.func,
  onlyOne: PropTypes.bool,
  returnKeys: PropTypes.bool,
  disabled: PropTypes.bool,
  availableAccounts: PropTypes.array.isRequired,
  error: PropTypes.string,
  readOnly: PropTypes.bool,
  loadingAccounts: PropTypes.object,
  allowsOverride: PropTypes.bool,
}

export default Accounts

const Account = ({
  identifier,
  selected,
  disabled,
  onClick,
  type,
  readOnly,
  loading,
  bigIcon = false,
}) => {
  const foundType = ACCOUNT_TYPES.find((acc) => acc.id === type)
  const itemClass = cx('account-component__item', {
    'account-component__item--selected': selected,
    'account-component__item--disabled': disabled || readOnly,
    'account-component__item--big': bigIcon,
  })

  return (
    <div
      className={itemClass}
      key={identifier}
      onClick={() => {
        !readOnly && !disabled && onClick && onClick(identifier)
      }}
      data-testid={`account-${identifier}`}
    >
      {loading && <Loader className="account-component__item__loader" />}
      <AccountIcon
        accountType={foundType?.id}
        className="account-component__item-icon"
        alt={foundType?.name}
      />
      <div className="account-component__item__name">
        <p className="account-component__item__text">{foundType?.name}</p>
      </div>
    </div>
  )
}

Account.propTypes = {
  identifier: PropTypes.string,
  type: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  loading: PropTypes.bool,
  bigIcon: PropTypes.bool,
}

export { Account }
