import { useEffect } from 'react'

/**
 * Hook to handle clicking outside of an element
 * Note: The element the ref is on has to be focusable. Eg. add tabIndex to it.
 * @param {Object} ref reference
 * @param {Function} handler function to executor upon clicking outside of ref
 */
export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }

      // Check if just leaving the element
      if (
        ref.current.contains(document.activeElement) ||
        document.activeElement === document.body // Situation where activeElement got unmounted
      ) {
        handler(event)
      }
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}
