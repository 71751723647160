import { ReactComponent as facebookIcon } from 'assets/icon_meta.svg'
import { ReactComponent as googleIcon } from 'assets/icon_google.svg'
import { ReactComponent as googleDevIcon } from 'assets/icon_google_dv360.svg'
import { ReactComponent as linkedInIcon } from 'assets/icon_linked_in.svg'
import { ReactComponent as microsoftIcon } from 'assets/icon_microsoft.svg'
import { ReactComponent as tradeDeskIcon } from 'assets/icon_trade_desk.svg'
import { ReactComponent as tiktokIcon } from 'assets/icon_tiktok.svg'
import { ReactComponent as amazonIcon } from 'assets/icon_amazon.svg'
import { ReactComponent as amazonDspIcon } from 'assets/icon_amazon_dsp.svg'
import { ReactComponent as appleIcon } from 'assets/icon_apple.svg'
import { ReactComponent as googleCampaignManagerIcon } from 'assets/icon_google_campaign_manager.svg'
import { ReactComponent as snapchatIcon } from 'assets/icon_snapchat.svg'
import { ReactComponent as twitterIcon } from 'assets/icon_twitter.svg'
import { ReactComponent as googleSa360Icon } from 'assets/icon_google_sa360.svg'
import { ReactComponent as googleAnalyticsIcon } from 'assets/icon_google_analytics.svg'
import { ReactComponent as redditIcon } from 'assets/icon_reddit.svg'
import { ReactComponent as pinterestIcon } from 'assets/icon_pinterest.svg'
import { ReactComponent as snowflakeIcon } from 'assets/icon_snowflake.svg'
import { accounts } from '@decision-sciences/qontrol-common'

const { ACCOUNT_TYPES_MAP } = accounts

const {
  GOOGLE,
  FACEBOOK,
  MICROSOFT,
  TRADE_DESK,
  LINKED_IN,
  TIKTOK,
  ODAX,
  DV360,
  AMAZON_ADVERTISING,
  AMAZON_DSP,
  APPLE,
  GOOGLE_CAMPAIGN_MANAGER,
  SNAPCHAT,
  TWITTER,
  SA360,
  GOOGLE_ANALYTICS,
  REDDIT,
  PINTEREST,
  SNOWFLAKE,
} = ACCOUNT_TYPES_MAP

export const ACCOUNT_TYPE_ICONS = {
  [ODAX]: facebookIcon,
  [FACEBOOK]: facebookIcon,
  [GOOGLE]: googleIcon,
  [DV360]: googleDevIcon,
  [LINKED_IN]: linkedInIcon,
  [MICROSOFT]: microsoftIcon,
  [TRADE_DESK]: tradeDeskIcon,
  [TIKTOK]: tiktokIcon,
  [AMAZON_DSP]: amazonDspIcon,
  [AMAZON_ADVERTISING]: amazonIcon,
  [APPLE]: appleIcon,
  [GOOGLE_CAMPAIGN_MANAGER]: googleCampaignManagerIcon,
  [SNAPCHAT]: snapchatIcon,
  [TWITTER]: twitterIcon,
  [SA360]: googleSa360Icon,
  [GOOGLE_ANALYTICS]: googleAnalyticsIcon,
  [REDDIT]: redditIcon,
  [PINTEREST]: pinterestIcon,
  [SNOWFLAKE]: snowflakeIcon,
}
