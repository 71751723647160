import { addDays, areIntervalsOverlapping } from 'date-fns'
import { utils } from '@decision-sciences/qontrol-common'

const { getGenericDayDate } = utils.date

export const weekDays = [
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
  { value: 'Sunday', label: 'Sunday' },
]

const getEndDate = (time, supportsZeroEndTime) => {
  if (time === '12:00 AM' && supportsZeroEndTime) {
    return addDays(getGenericDayDate(time), 1)
  }
  return getGenericDayDate(time)
}

/**
 * Validates schedules based on some generic rules
 * No overlaps
 * No starting time AFTER end time
 * @param schedules {Array} Schedules to check
 * @param [supportsZeroEndTime] {Boolean} Supports 12:00 AM as an end time by considering it the 12:00 AM of the next day
 * @param allowOverlappingSchedules {Boolean} Whether to allow overlapping schedules
 * @returns {[boolean,{}]}
 */
export const validateSchedules = (
  schedules,
  supportsZeroEndTime,
  allowOverlappingSchedules = false
) => {
  const errors = {}
  let isValid = true

  if (!schedules?.length) {
    return [true, {}]
  }

  // Go through each and every schedule item and check for inconsistencies, such as invalid intervals or overlapping intervals.
  schedules.forEach((schedule, idx) => {
    if (getGenericDayDate(schedule.from) >= getEndDate(schedule.to, supportsZeroEndTime)) {
      errors[idx] = `End time must be after start time`
      isValid = false
    }

    if (!schedule.days.length) {
      errors[idx] = `At least one day must be selected`
      isValid = false
    }
  })

  schedules.forEach((schedule, idx) => {
    const overlappingIndex = getSchedulesOverlapIndex(schedule, idx, schedules, supportsZeroEndTime)

    if (overlappingIndex !== -1 && !allowOverlappingSchedules) {
      errors[overlappingIndex] = errors[idx] = 'Schedules cannot overlap.'
      isValid = false
    }
  })

  return [isValid, errors]
}

export const getSchedulesOverlapIndex = (schedule, idx, otherSchedules, supportsZeroEndTime) => {

  return otherSchedules.findIndex((otherSchedule, otherIdx) => {
    // Don't check self
    if (idx === otherIdx) {
      return false
    }
    const hasCommonDays = schedule.days.some((day) =>
      otherSchedule.days.includes(day)
    )
    if (!hasCommonDays) {
      return false
    }

    const leftInterval = {
      start: getGenericDayDate(schedule.from),
      end: getEndDate(schedule.to, supportsZeroEndTime),
    }

    const rightInterval = {
      start: getGenericDayDate(otherSchedule.from),
      end: getEndDate(otherSchedule.to, supportsZeroEndTime),
    }

    return areIntervalsOverlapping(leftInterval, rightInterval)
  })
}

export const hasOverlappingSchedules = (schedules) => {
  return schedules.some((schedule, index) => {
    const overlappingIndex = getSchedulesOverlapIndex(schedule, index, schedules, true)

    return overlappingIndex !== -1
  })
}

