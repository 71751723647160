import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

import useSession from 'modules/session'
import { useSocket } from 'components/utils/socket'
import { useStore } from 'store'

import DropdownMultiColor from 'components/dropdown-multi-color'
import LoadingOverlay from 'components/loading-overlay/index'

import {
  alertTriggers,
  socket,
  accounts,
} from '@decision-sciences/qontrol-common'
import { ALERT_TRIGGER_COLORS } from 'constants/alert-trigger'
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'

import { updateAlertTrigger } from '../../actions'

const { ALERT_TRIGGER_STATUS } = alertTriggers
const { NOTIFICATIONS } = socket
const { ACCOUNT_TYPE_NAMES, ACCOUNT_TYPE_CLIENT } = accounts

const DEFAULT_ALERT_SNAPSHOT = { name: '', description: '', category: '' }

const AlertTriggerDetails = ({
  alertTriggerId,
  triggerDetails,
  updateAlertState,
}) => {
  const {
    client: accountType,
    alertSnapshot,
    createdAt,
    accountId,
    alertType,
    elementType,
    entitiesWithDetails,
    activityLog,
    status,
  } = triggerDetails

  const { state } = useStore()
  const {
    companies: { currentCompany },
  } = state

  const {
    name: alertName,
    description,
    category,
  } = alertSnapshot || { ...DEFAULT_ALERT_SNAPSHOT }

  // Alert Trigger Details
  const categories = elementType || Object.keys(entitiesWithDetails).join('; ')

  const [, user] = useSession()

  const socket = useSocket({ room: currentCompany?._id })

  const hasEditAccess = useAccess({
    feature: PERMISSIONS.ALERT_TRIGGERED_DETAILS,
    type: PERMISSION_TYPES.EDIT,
  })

  const [loading, setLoading] = useState(false)

  const updateStatus = async (value) => {
    // Add update to activity log
    const result = await updateAlertTrigger(alertTriggerId, {
      activityLog: [
        ...activityLog,
        {
          user: user.email,
          change: {
            attribute: 'status',
            from: status,
            to: value,
          },
        },
      ],
      status: value,
    })

    if (!result.success || result.error) {
      console.error('Error updating status', result.error)
    }

    updateAlertState({
      status: result.data.status,
      activityLog: result.data.activityLog,
    })

    // Notify the server that changes happened
    socket.emit(NOTIFICATIONS.alertTriggers.initialise, {
      room: user?._id,
      data: {
        id: alertTriggerId,
        from: status,
        to: value,
      },
    })

    setLoading(false)
  }

  return (
    <div className="form__section">
      <div className="heading" data-cy="page-heading">
        Alert Triggered
      </div>
      <LoadingOverlay visible={loading} />
      <DropdownMultiColor
        disabled={!hasEditAccess}
        defaultOptionText="Status"
        options={Object.keys(ALERT_TRIGGER_STATUS)
          .filter(
            (key) =>
              key !== ALERT_TRIGGER_STATUS.ACTIVE &&
              ALERT_TRIGGER_STATUS[key] !== status
          )
          .map((key) => ({
            label: ALERT_TRIGGER_STATUS[key],
            value: ALERT_TRIGGER_STATUS[key],
            color: ALERT_TRIGGER_COLORS[key],
          }))}
        defaultState={alert ? alert.status : null}
        onChange={(val) => {
          // update activity log
          setLoading(true)
          updateStatus(val.value)
        }}
        className="alert-trigger-details__status"
        selected={`${status?.charAt(0)?.toUpperCase()}${status?.slice(1)}`}
      />

      <div className="information">
        <div className="information__section">
          <div className="label">Alert name</div>
          <p className="detail">{alertName}</p>
          <div className="label">Time alert was last triggered</div>
          <p className="detail">
            {createdAt && format(new Date(createdAt), `MMMM dd yyyy hh:mm a z`)}
          </p>
          <div className="label">Category</div>
          <p className="detail">{category?.name}</p>
          <div className="label">Alert type</div>
          <p className="detail">{alertType}</p>
        </div>
        <div className="information__section">
          <div className="label">Description</div>
          <p className="detail">{description}</p>
          <div className="label">Publisher</div>
          <p className="detail">
            {accountType === ACCOUNT_TYPE_CLIENT
              ? 'N/A'
              : ACCOUNT_TYPE_NAMES[accountType] ||
                `${
                  accountType.charAt(0).toUpperCase() + accountType.slice(1)
                } ${accountId?.externalAccountId}`}
          </p>
          <div className="label">Alert Properties</div>
          <p className="detail">
            {accountType === ACCOUNT_TYPE_CLIENT
              ? `CLIENT ${categories} level`
              : categories}
          </p>
          <div className="label">Account Name/ID</div>
          <p className="detail">
            {accountType === ACCOUNT_TYPE_CLIENT
              ? 'N/A'
              : `${accountId?.name} / ${accountId?.externalAccountId}`}
          </p>
        </div>
      </div>
    </div>
  )
}

AlertTriggerDetails.propTypes = {
  alertTriggerId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  triggerDetails: PropTypes.object.isRequired,
  updateAlertState: PropTypes.func.isRequired,
}

export { AlertTriggerDetails }
