import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, redirect } from 'react-router-dom'
import { useStore } from 'store'
import { Helmet } from 'react-helmet'
import AlertThresholdsModule from 'modules/alert-thresholds'
import AlertThresholdsForm from 'modules/alert-thresholds/create-edit'
import {
  getAlertThresholds,
  deleteAlertThreshold,
} from 'modules/alert-thresholds/actions'
import useLeaveConfirm from 'components/leave-confirm'

import Button from 'components/button'
import Modal from 'components/modal'
import Loader from 'components/loader'
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'
import { NOT_FOUND_ROUTE } from 'routes'

const AlertThresholdsPage = () => {
  const { key } = useParams()
  const navigate = useNavigate()
  const { dispatch, state } = useStore()
  const {
    companies: { currentCompany },
    alertThresholds: { list: alertThresholds, query },
  } = state
  const [thresholdToDelete, setThresholdToDelete] = useState(null)
  const [loading, setLoading] = useState(false)
  const hasThresholdsAccess = useAccess({
    feature: PERMISSIONS.ALERT_THRESHOLDS,
    type: PERMISSION_TYPES.READ,
  })
  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.ALERT_THRESHOLD_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.ALERT_THRESHOLD_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.ALERT_THRESHOLD_DATA_FORM,
    type: PERMISSION_TYPES.DELETE,
  })

  const [setDirty, LeaveConfirm] = useLeaveConfirm({})

  const selectedThreshold =
    key &&
    alertThresholds?.find(
      (threshold) => threshold.key.toString() === key.toString()
    )

  useEffect(() => {
    if (!hasThresholdsAccess) {
      redirect('/unauthorized')
    }
    if (
      (!alertThresholds || query) &&
      currentCompany?._id &&
      hasThresholdsAccess
    ) {
      getAlertThresholds(dispatch)
    }
  }, [!currentCompany?._id])

  useEffect(() => {
    if (!selectedThreshold && key && key !== 'new' && alertThresholds) {
      navigate(NOT_FOUND_ROUTE, true)
    }
  }, [alertThresholds, selectedThreshold])

  const headerText = key
    ? key === 'new'
      ? 'Add Alert Threshold'
      : 'Edit Alert Threshold'
    : 'Alert Thresholds'

  if (!hasThresholdsAccess) {
    return <Loader />
  }

  return (
    <React.Fragment>
      <LeaveConfirm />
      <section className="alert-thresholds">
        {thresholdToDelete && (
          <Modal
            opened={!!thresholdToDelete}
            button={
              <Button
                value="Delete"
                onClick={() => {
                  setLoading(true)
                  deleteAlertThreshold(dispatch, thresholdToDelete._id)
                    .then(() => {
                      setDirty(false)
                      setTimeout(() => {
                        navigate('/alert-thresholds')
                      })
                    })
                    .catch((error) => {
                      console.error(error)
                    })
                    .finally(() => {
                      setLoading(false)
                      setThresholdToDelete(null)
                    })
                }}
                disabled={loading}
              />
            }
            buttonSecondary={
              <Button
                value="Cancel"
                disabled={loading}
                onClick={() => setThresholdToDelete(null)}
                secondaryGray
              />
            }
            heading={`Are you sure you want to delete ${thresholdToDelete.name} Alert Threshold?`}
            className="alert-categories__modal"
          >
            <span className="error">{`This will also clear all Company specific values for ${thresholdToDelete.name}`}</span>
          </Modal>
        )}

        <Helmet>
          <title>{headerText}</title>
        </Helmet>
        <div className="heading" data-cy="page-heading">
          {headerText}
          <div className="heading__buttons">
            {!key && hasCreateAccess && (
              <Button
                value="Add Alert Threshold"
                onClick={() => {
                  navigate('/alert-thresholds/new')
                }}
              />
            )}
          </div>
        </div>

        {alertThresholds ? (
          key ? (
            <AlertThresholdsForm
              defaultState={selectedThreshold}
              isNew={!selectedThreshold}
              onDelete={setThresholdToDelete}
              setDirty={setDirty}
              hasEditAccess={hasEditAccess}
              hasCreateAccess={hasCreateAccess}
            />
          ) : (
            <AlertThresholdsModule
              alertThresholds={alertThresholds}
              onDelete={hasDeleteAccess ? setThresholdToDelete : null}
              hasEditAccess={hasEditAccess}
            />
          )
        ) : (
          <Loader />
        )}
      </section>
    </React.Fragment>
  )
}

export default AlertThresholdsPage
