import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useStore } from 'store'
import isEqual from 'lodash.isequal'

import {
  TABLE_CONTAINER,
  defaultFilterSortConfig,
} from 'modules/table-filter-sort/constants'
import { getCalculatedMetrics } from 'modules/calculated-metrics/actions'
import CalculatedMetricsModule from 'modules/calculated-metrics'
import CreateEditCalculatedMetric from 'modules/calculated-metrics/create-edit'
import Loader from 'components/loader'

const CalculatedMetrics = () => {
  const tableContainer = TABLE_CONTAINER.CALCULATED_METRICS
  const { metricId } = useParams()
  const { state, dispatch } = useStore()
  const calculatedMetrics = state.calculatedMetrics.listWithDetails || {}
  const filter = state.tableFilterSort.filterSort[tableContainer].filter
  const [loading, setLoading] = useState(false)

  const getAllCalculatedMetrics = (query = {}) =>
    getCalculatedMetrics(dispatch, { ...query, all: true })

  useEffect(() => {
    const isDefaultFilter = isEqual(
      filter,
      defaultFilterSortConfig.filterSort[tableContainer].filter
    )
    const query = isDefaultFilter ? { all: true } : { ...filter, all: true }
    getAllCalculatedMetrics(query).finally(() => setLoading(false))
  }, [JSON.stringify(filter), tableContainer])

  useEffect(() => {
    setLoading(true)
    return () => {
      getAllCalculatedMetrics()
    }
  }, [])

  return (
    <React.Fragment>
      {!calculatedMetrics ? (
        <Loader />
      ) : !metricId ? (
        <CalculatedMetricsModule
          dispatch={dispatch}
          list={calculatedMetrics}
          tableContainer={tableContainer}
          loading={loading}
        />
      ) : (
        <CreateEditCalculatedMetric
          calculatedMetrics={calculatedMetrics}
          selectedId={metricId}
          filter={filter}
        />
      )}
    </React.Fragment>
  )
}
export default CalculatedMetrics
