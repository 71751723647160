import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { useStore } from 'store'
import isEqual from 'lodash.isequal'

import {
  saveAlertCategory,
  deleteAlertCategory,
} from 'modules/alert-categories/actions'
import { getAlertCategories } from 'modules/alerts/actions'

import Input from 'components/input'
import Button from 'components/button'
import Textarea from 'components/textarea'
import Table from 'components/table/beta'
import Modal from 'components/modal'
import { getTooltipList } from 'components/utils/tooltip'
import Filters from 'components/filters'

import { utils } from '@decision-sciences/qontrol-common'

import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'

import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'

import './style.scss'

const { getCommonFields } = utils.object

const initialState = { name: '', description: '' }

const AlertCategories = ({
  list,
  selectedItem,
  onSave,
  onItemSelected,
  onModalClose,
}) => {
  const [state, setState] = useState(initialState)
  const [errors, setErrors] = useState({ name: null, description: null })
  const [loading, setLoading] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)
  const navigate = useNavigate()
  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.ALERT_CATEGORIES,
    type: PERMISSION_TYPES.CREATE,
  })
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.ALERT_CATEGORIES,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.ALERT_CATEGORIES,
    type: PERMISSION_TYPES.DELETE,
  })
  let addNewContent = null

  /** On Component mount check for access */
  useEffect(() => {
    if (selectedItem && (!hasCreateAccess || !hasEditAccess)) {
      navigate('/unauthorized', { replace: true })
    }
  }, [hasCreateAccess, hasEditAccess])

  const columns = [
    {
      header: '',
      id: 'actions',
      cell: (cell) => {
        if (!hasEditAccess) {
          return null
        }
        return (
          <div className="table__actions">
            <div
              className="table__edit"
              onClick={() => onItemSelected(cell.row.original)}
            />
          </div>
        )
      },
      size: 50,
      minSize: 50,
      maxSize: 120,
    },
    {
      header: 'Name',
      accessorKey: 'name',
      size: 300,
      sortingFn: 'name',
    },
    {
      header: 'Associated Alerts',
      accessorFn: (row) => (row.alerts ? row.alerts.length : 0),
      tooltip: (row) => {
        if (!row || !row.alerts.length) {
          return null
        }
        return getTooltipList(
          'Associated Alerts',
          row.alerts.map((alert) => alert.name)
        )
      },
      sortType: 'number',
      size: 300,
    },
    {
      header: 'Date Added',
      id: 'createdAt',
      accessorKey: 'createdAt',
      accessorFn: (row) => new Date(row.createdAt),
      cell: (cell) =>
        cell.getValue('createdAt') &&
        format(cell.getValue('createdAt'), 'MM/dd/yyyy'),
      size: 300,
    },
    {
      id: 'delete',
      header: '',
      cell: (cell) => {
        if (!hasDeleteAccess || cell.row.original.alerts?.length) {
          return <></>
        }
        return (
          <DeleteIcon
            className="table__delete fill-red"
            alt={'delete'}
            onClick={() => setItemToDelete(cell.row.original)}
          />
        )
      },
      size: 40,
      minSize: 40,
      maxSize: 120,
    },
  ]

  /** On selectedItem update - update the state */
  useEffect(() => {
    if (!selectedItem) {
      setState(initialState)
    } else {
      setState({ ...state, ...selectedItem })
    }
  }, [selectedItem])

  /** On Field Update */
  const updateField = (fieldName, fieldValue) => {
    setState({ ...state, [fieldName]: fieldValue })
    setErrors({ ...errors, [fieldName]: null })
  }

  /** Validate & save handler */
  const saveCategory = () => {
    if (!state.name.trim()) {
      return setErrors({ ...errors, name: 'Name is required' })
    }

    setLoading(true)

    saveAlertCategory(state)
      .then(() => {
        onSave()
        setErrors({})
      })
      .catch((error) => {
        if (error.name) {
          setErrors({ ...errors, name: error.name })
        } else {
          setErrors({ ...errors, general: error })
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  /** Render add/update modal content */
  if (selectedItem) {
    addNewContent = (
      <div>
        <Input
          placeholder="Name"
          value={state.name}
          error={errors.name}
          onChange={(val) => updateField('name', val)}
          disabled={loading}
          className="input-name"
        />
        <Textarea
          placeholder="Alert Category Description"
          value={state.description}
          error={errors.description}
          onChange={(val) => updateField('description', val)}
          disabled={loading}
        />
        {errors.general && <div className="error">{errors.general}</div>}
      </div>
    )
  }

  return (
    <div className="alert-categories">
      {itemToDelete && (
        <Modal
          opened={!!itemToDelete}
          button={
            <Button
              value="Delete"
              onClick={() => {
                setLoading(false)
                deleteAlertCategory(itemToDelete._id)
                  .then(() => {
                    onSave()
                  })
                  .catch(console.error)
                  .finally(() => setLoading(false))
                setItemToDelete(null)
              }}
            />
          }
          buttonSecondary={
            <Button
              value={'Cancel'}
              onClick={() => setItemToDelete(null)}
              secondaryGray
            />
          }
          heading={`Are you sure you want to delete "${itemToDelete.name}"?`}
          className="alert-categories__modal"
        />
      )}
      <AlertCategoryFilters />
      <Table
        columns={columns}
        data={list}
        initialState={{ sortBy: [{ id: 'createdAt', desc: true }] }}
      />

      {selectedItem && (
        <Modal
          opened={!!selectedItem}
          button={
            <Button value="Save" onClick={saveCategory} disabled={loading} />
          }
          buttonSecondary={
            <Button
              value="Cancel"
              disabled={loading}
              onClick={(...args) => {
                onModalClose(...args)
                setErrors({})
              }}
              secondaryGray
            />
          }
          heading={
            selectedItem && selectedItem._id
              ? 'Edit Alert Category'
              : 'Add Alert Category'
          }
          className="alert-categories__modal"
        >
          {addNewContent}
        </Modal>
      )}
    </div>
  )
}

AlertCategories.propTypes = {
  list: PropTypes.array.isRequired,
  selectedItem: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
}

const initialFilterName = ''

/**
 * Filter module for Notification Groups
 * @returns {React.Component}
 */
const AlertCategoryFilters = () => {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(initialFilterName)
  const { dispatch } = useStore()

  const [initial, current] = getCommonFields(
    { name: initialFilterName },
    { name }
  )

  const applyFilters = () => {
    setLoading(true)

    const query = {}

    if (name?.length) {
      query.name = {
        // Escape special characters s.t. they don't break the search
        $regex: name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
        // ignore case
        $options: 'i',
      }
    }

    getAlertCategories(dispatch, query).finally(() => setLoading(false))
  }

  const clearFilters = () => {
    if (name !== initialFilterName) {
      setLoading(true)
      setName(initialFilterName)
      getAlertCategories(dispatch).finally(() => setLoading(false))
    }
  }

  return (
    <Filters
      loading={loading}
      onApply={applyFilters}
      onClear={clearFilters}
      initialApplied={!isEqual(initial, current)}
    >
      <Input onChange={setName} value={name} placeholder="Name" />
    </Filters>
  )
}

export default AlertCategories
