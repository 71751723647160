import { ACTIONS } from './actions'
const name = 'google'

const initialState = {
  verificationURL: null,
  verified: false,
  campaigns: { empty: true },
  /** e.g. conversionGoals: { 'account-id': []}*/
  conversionGoals: {},
  customerConversionGoals: {},
  customConversionGoals: {},
  languages: {},
  page_feeds: {},
  campaignBudgets: { empty: true },
  selectedCampaign: null,
  modifiedCampaign: null,
  audienceDemographic: null,
  audienceInterests: null,
  audienceDataSegments: null,
  domainCategories: null,
  callAssetConversionActions: [],
  biddingStrategies: {},
  sharedCampaignBudgets: {},
  conversionActions: {},
}
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.SET_CALL_ASSET_CONVERSION_ACTIONS]: (
    state,
    { callAssetConversionActions }
  ) => {
    return { ...state, callAssetConversionActions }
  },
  [ACTIONS.SET_VERIFIED]: (state, { verified }) => {
    return { ...state, verified }
  },
  [ACTIONS.SET_CONVERSION_GOALS]: (state, { accountId, data }) => {
    return {
      ...state,
      conversionGoals: {
        ...state?.conversionGoals,
        [accountId]: data,
      },
    }
  },
  [ACTIONS.SET_CONVERSION_ACTIONS]: (state, { accountId, data }) => {
    return {
      ...state,
      conversionActions: {
        ...state?.conversionActions,
        [accountId]: data,
      },
    }
  },
  [ACTIONS.SET_CUSTOMER_CONVERSION_GOALS]: (state, { accountId, data }) => {
    return {
      ...state,
      customerConversionGoals: {
        ...state?.customerConversionGoals,
        [accountId]: data,
      },
    }
  },
  [ACTIONS.SET_CUSTOM_CONVERSION_GOALS]: (state, { accountId, data }) => {
    return {
      ...state,
      customConversionGoals: {
        ...state?.customConversionGoals,
        [accountId]: data,
      },
    }
  },
  [ACTIONS.SET_BIDDING_STRATEGIES]: (state, { accountId, data }) => {
    return {
      ...state,
      biddingStrategies: {
        ...state?.biddingStrategies,
        [accountId]: data,
      },
    }
  },
  [ACTIONS.SET_SHARED_CAMPAIGN_BUDGETS]: (state, { accountId, data }) => {
    return {
      ...state,
      sharedCampaignBudgets: {
        ...state?.sharedCampaignBudgets,
        [accountId]: data,
      },
    }
  },
  [ACTIONS.SET_LANGUAGES]: (state, { accountId, data }) => {
    return {
      ...state,
      languages: {
        ...state?.languages,
        [accountId]: data,
      },
    }
  },
  [ACTIONS.SET_PAGE_FEEDS]: (state, { accountId, data }) => {
    return {
      ...state,
      page_feeds: {
        ...state?.page_feeds,
        [accountId]: data,
      },
    }
  },
  [ACTIONS.SET_CAMPAIGN_AUDIENCE_DEMOGRAPHIC]: (state, { data }) => {
    return {
      ...state,
      audienceDemographic: data,
    }
  },
  [ACTIONS.SET_CAMPAIGN_AUDIENCE_INTERESTS]: (state, { data }) => {
    return {
      ...state,
      audienceInterests: data,
    }
  },
  [ACTIONS.SET_CAMPAIGN_AUDIENCE_DATA_SEGMENTS]: (state, { data }) => {
    return {
      ...state,
      audienceDataSegments: data,
    }
  },
  [ACTIONS.SET_DOMAIN_CATEGORIES]: (
    state,
    { accountId, domain, language, data }
  ) => {
    return {
      ...state,
      domainCategories: {
        ...state.domainCategories,
        [accountId]: {
          ...state.accountId,
          [domain]: {
            ...((state.domainCategories && state.domainCategories[domain]) ||
              {}),
            [language]: data,
          },
        },
      },
    }
  },
}

export default { name, reduce, initialState }
