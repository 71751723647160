import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// Modules
import { getLegalBySlug } from 'modules/legal/actions'

// Utils
import Loader from 'components/loader/index'
import { LegalItemContent } from 'modules/legal/components/legal-item-content'

/**
 * Renders the legal documentation page
 * @returns {React.Component}
 */
const LegalViewPage = () => {
  const { slug } = useParams()
  const [loading, setLoading] = useState(true)
  const [legalItem, setLegalItem] = useState(null)

  useEffect(() => {
    getLegalBySlug(slug)
      .then((legalItem) => {
        setLegalItem(legalItem)
      })
      .finally(() => setLoading(false))
  }, [slug])

  if (loading) {
    return <Loader />
  }
  return (
    <div id={slug} className="legal-page">
      {legalItem && <LegalItemContent withTimestamp item={legalItem} />}
    </div>
  )
}

export { LegalViewPage }
