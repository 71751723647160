import React from 'react'
import PropTypes from 'prop-types'
import { reports } from '@decision-sciences/qontrol-common'

/* Components */
import InputText from 'components/input'
import Button from 'components/button'
import { Dropdown } from 'components/dropdown/index'

/* Icons */
import { ReactComponent as SearchIcon } from 'assets/icon_search.svg'

/* Constants */
import { PROPERTIES } from 'modules/companies/subsections/reports/utils'

const { NAME, TEAM_PERMISSIONS, USER_PERMISSIONS, SOURCES } = PROPERTIES

const { REPORT_SOURCE_LABEL } = reports

/**
 * Report Edit Section
 */
const ReportsFilterSection = ({
  filter = {},
  setFilter = () => {},
  applyFilter,
  users,
  teams,
}) => {
  const onChangeProperty = (property, value) =>
    setFilter({ ...filter, [property]: value })

  return (
    <>
      <div className="reports-edit-section">
        <div className="reports-edit-section__column">
          <div className="general-label">Search </div>
          <InputText
            value={filter.name || ''}
            placeholder="Search"
            search
            onChange={(val) => onChangeProperty(NAME, val)}
            className={'width-50'}
          />
          <div className="general-label">USER PERMISSION</div>
          <Dropdown
            options={users?.map((user) => ({
              label: user.name,
              value: user._id,
            }))}
            onChange={(val) => onChangeProperty(USER_PERMISSIONS, val)}
            defaultOptionText={'Select Users'}
            selectedItems={filter.userPermissions || []}
            multiSelect={true}
            selectAll
          />
        </div>

        <div className="reports-edit-section__column">
          <div className="general-label">TEAM PERMISSIONS</div>
          <Dropdown
            options={teams?.map((user) => ({
              label: user.name,
              value: user._id,
            }))}
            defaultState={filter.teamPermissions?.map((user) => user._id)}
            onChange={(val) => onChangeProperty(TEAM_PERMISSIONS, val)}
            defaultOptionText={'Select Teams'}
            selectedItems={filter.teamPermissions || []}
            multiSelect={true}
            selectAll
          />
          <div className="general-label">SOURCE</div>
          <Dropdown
            options={Object.entries(REPORT_SOURCE_LABEL).map(
              ([value, label]) => ({ label, value })
            )}
            defaultState={filter.sources}
            onChange={(val) => onChangeProperty(SOURCES, val)}
            defaultOptionText={'Select Source'}
            selectedItems={filter.sources || []}
            multiSelect={true}
            selectAll
          />
        </div>
      </div>
      <div className="borders-top-bottom">
        <Button
          secondary
          value={
            <div className="action-button">
              <SearchIcon /> Apply Search
            </div>
          }
          onClick={applyFilter}
        />
      </div>
    </>
  )
}
export default ReportsFilterSection

ReportsFilterSection.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  applyFilter: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
}
