import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import cx from 'classnames'
import isEqual from 'lodash.isequal'
import { useStore } from 'store'

import Table from 'components/table/beta/index'
import Filters, { FilterSection } from 'components/filters'
import Input from 'components/input'
import { Dropdown } from 'components/dropdown/index'
import { PermissionFilter } from 'components/permissions'

import {
  resetFilterSort,
  saveFilter,
  saveSort,
} from 'modules/table-filter-sort/actions'
import { defaultFilterSortConfig } from 'modules/table-filter-sort/constants'

import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'
import { utils, entityStatus } from '@decision-sciences/qontrol-common'

import './style.scss'

const { getCommonFields } = utils.object
const { ENTITY_STATUS_LABEL, ENTITY_STATUS_OPTIONS } = entityStatus

/**
 * Permission Groups List Component
 * @param {Object} params React Params
 * @param {Array} params.list
 * @param {Function} [params.onDelete] To be called with permission group object to be deleted
 * @param {Boolean} params.hasCreateAccess
 * @param {Boolean} params.hasEditAccess
 * @param {String} params.tableContainer The container of the permission groups table
 * @param {Boolean} params.loading
 */
const PermissionGroups = ({
  list,
  onDelete,
  hasCreateAccess,
  hasEditAccess,
  tableContainer,
  loading,
}) => {
  const navigate = useNavigate()
  const { dispatch, state } = useStore()
  const filterSort = state.tableFilterSort.filterSort[tableContainer]

  const columns = [
    {
      header: 'Name',
      accessorKey: 'name',
      size: 300,
      sortingFn: 'name',
    },
    {
      header: 'Date Added',
      accessorKey: 'createdAt',
      size: 100,
      cell: (cell) =>
        format(new Date(cell.row.original.createdAt), 'MM/dd/yyyy'),
      sortingFn: 'date',
    },
    {
      header: 'Status',
      id: 'status',
      cell: (cell) => {
        const { active } = cell.row.original
        return <span>{ENTITY_STATUS_LABEL[active]}</span>
      },
      size: 50,
      accessorFn: (row) => (row.active ? 1 : 0),
    },
    {
      id: 'delete',
      header: '',
      cell: (cell) => {
        const disabled = !!cell.row.original.users?.length
        if (!onDelete) {
          return null
        }
        return (
          <DeleteIcon
            className={cx({
              disabled,
              'fill-grey': disabled,
              'fill-red': !disabled,
            })}
            alt={'delete'}
            onClick={() => !disabled && onDelete(cell.row.original)}
          />
        )
      },
      size: 40,
      maxSize: 40,
    },
  ]

  if (hasCreateAccess) {
    columns.unshift({
      header: '',
      id: 'duplicate',
      cell: (cell) => (
        <div className="table__actions">
          <div
            className="table__duplicate"
            onClick={() => {
              navigate(`/permission-groups/new?base=${cell.row.original._id}`)
            }}
          />
        </div>
      ),
      maxSize: 40,
      textAlign: 'center',
    })
  }
  if (hasEditAccess) {
    columns.unshift({
      header: '',
      id: 'actions',
      cell: (cell) => (
        <div className="table__actions">
          <div
            className="table__edit"
            onClick={() =>
              navigate(`/permission-groups/${cell.row.original._id}`)
            }
          />
        </div>
      ),
      maxSize: 40,
      textAlign: 'center',
    })
  }

  const sortChanged = (newSort) => {
    if (!isEqual(newSort, filterSort.sort)) {
      saveSort(dispatch, tableContainer, newSort)
    }
  }

  return (
    <div className="page">
      <PermissionGroupFilters
        dispatch={dispatch}
        tableContainer={tableContainer}
        filters={filterSort.filter}
      />
      <Table
        columns={columns}
        data={list}
        initialState={{ sortBy: filterSort.sort }}
        tableContainer={tableContainer}
        onSortChanged={sortChanged}
        loading={loading}
      />
    </div>
  )
}

/**
 * Filters Permission Groups
 * @returns {React.Component}
 */
const PermissionGroupFilters = ({ dispatch, tableContainer, filters }) => {
  const initialFilterState =
    defaultFilterSortConfig.filterSort[tableContainer].filter
  const [state, setState] = useState()

  useEffect(() => {
    setState(filters)
  }, [filters])

  const applyFilters = () => {
    saveFilter(dispatch, tableContainer, state)
  }

  const clearFilters = () => {
    resetFilterSort(dispatch, tableContainer)
  }

  const [initial, current] = getCommonFields(initialFilterState, state)

  return (
    <Filters
      onApply={applyFilters}
      onClear={clearFilters}
      disableClearOnUnmount={true}
      initialApplied={!isEqual(initial, current)}
    >
      <FilterSection label="Name">
        <Input
          onChange={(name) => setState({ ...state, name })}
          value={state?.name || ''}
          placeholder="Enter Permission Group Name"
        />
      </FilterSection>
      <FilterSection label="Status">
        <Dropdown
          onChange={(active) => setState({ ...state, active })}
          defaultState={state?.active === undefined ? '' : state.active}
          defaultOptionText="Permission Group Status"
          deselectLabel="Any Permission Group Status"
          options={ENTITY_STATUS_OPTIONS}
        />
      </FilterSection>
      <FilterSection label="Permissions">
        <PermissionFilter
          state={state?.permissions || {}}
          onChange={(permissions) => setState({ ...state, permissions })}
        />
      </FilterSection>
    </Filters>
  )
}
PermissionGroupFilters.propTypes = {
  dispatch: PropTypes.func.isRequired,
  tableContainer: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
}

PermissionGroups.propTypes = {
  list: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  hasCreateAccess: PropTypes.bool,
  hasEditAccess: PropTypes.bool,
  tableContainer: PropTypes.string.isRequired,
  loading: PropTypes.bool,
}

export default PermissionGroups
