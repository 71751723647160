import { useMemo } from 'react'
import useSession from 'modules/session'
import { access, permissions } from '@decision-sciences/qontrol-common'

const { hasAccess } = access
export const PERMISSIONS = permissions.PERMISSIONS
export const PERMISSION_TYPES = permissions.PERMISSION_TYPES

/** Use Access hook */
export const useAccess = (permission) => {
  const [, user] = useSession()
  return useMemo(() => {
    if (!permission) {
      return true
    }
    const permArray = Array.isArray(permission) ? permission : [permission]
    return hasAccess(user, permArray)
  }, [JSON.stringify(permission)])
}
