import React, { useState, useEffect } from 'react'
import { useStore } from 'store'
import { useParams, redirect } from 'react-router-dom'
import {
  getAlertCategories,
  getAlertsPaginated,
  updateStatus,
} from 'modules/alerts/actions'
import { getAlertTemplates } from 'modules/alert-templates/actions'
import { getTeams } from 'modules/teams/actions'
import AlertsModule from 'modules/alerts'
import useSession from 'modules/session'
import { getUserCompanies } from 'components/utils/user'
import Loader from 'components/loader'
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'
import {
  TABLE_CONTAINER,
  defaultFilterSortConfig,
} from 'modules/table-filter-sort/constants'

const AlertsIndexPage = () => {
  const tableContainer = TABLE_CONTAINER.ALERT_MANAGEMENT
  const [, user] = useSession()
  const { dispatch, state } = useStore()
  const [list, setList] = useState(null)
  const [filterLoading, setFilterLoading] = useState(!list)
  const [total, setTotal] = useState(0)
  const { categories: alertCategories } = state.alerts
  const filterSort = state.tableFilterSort.filterSort[tableContainer]
  const filters = filterSort.filter
  const paginationData = filterSort.pagination
  const sortData = filterSort.sort?.length
    ? filterSort.sort
    : defaultFilterSortConfig.filterSort[tableContainer].sort
  const { list: alertTemplates } = state.alertTemplates
  const { list: teams } = state.teams
  const { currentCompany } = state.companies
  const companyId = currentCompany ? currentCompany._id : null
  const { view } = useParams()
  const viewOnly = view === 'view'

  const userCompanies = getUserCompanies(user, state.companies.list)
  const allCompanies = userCompanies.map((company) => ({
    value: company._id,
    label: company.name,
    subsections: company.businessUnits.map((bu) => ({
      value: bu._id,
      label: bu.name,
    })),
  }))

  const hasAlertsAccess = useAccess({
    feature: PERMISSIONS.ALERTS_INDEX,
    type: PERMISSION_TYPES.READ,
  })

  /** On Page mount, get tertiary data */
  useEffect(() => {
    if (!hasAlertsAccess) {
      redirect('/unauthorized')
    } else {
      if (!alertCategories) {
        getAlertCategories(dispatch)
      }
      if (!alertTemplates) {
        getAlertTemplates(dispatch)
      }
      if (!teams) {
        getTeams(dispatch, companyId)
      }
    }
  }, [])

  /** On mount & filters update, get the list of Alerts */
  useEffect(fetchData, [
    JSON.stringify(filters),
    JSON.stringify(paginationData),
    JSON.stringify(sortData),
  ])

  /** Method that fetches the list of Alerts based on filters & pagination */
  function fetchData() {
    if (!hasAlertsAccess) {
      setFilterLoading(false)
      return
    }

    const filterQuery = {}
    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        if (value !== '') {
          filterQuery[key] = value
        }
      })
    }

    setFilterLoading(true)
    getAlertsPaginated(
      user.isSuperAdmin ? null : currentCompany._id,
      filterQuery,
      paginationData,
      sortData
    )
      .then(({ list, total }) => {
        setList(list)
        setTotal(total)
      })
      .catch(console.error)
      .finally(() => {
        setFilterLoading(false)
      })
  }

  const isListLoading = !viewOnly ? false : !list
  const loading = isListLoading || !alertCategories || !alertTemplates || !teams
  const allDataReady = hasAlertsAccess && !loading && allCompanies.length > 0

  // Loading mode
  if (!allDataReady) {
    return (
      <div className="page">
        <Loader />
      </div>
    )
  }

  // List or Create-Edit mode
  return (
    <div className="page">
      <AlertsModule
        list={list || []}
        total={total}
        history={history}
        company={companyId}
        updateStatus={updateStatus}
        dispatch={dispatch}
        allCompanies={allCompanies}
        alertCategories={alertCategories}
        teams={teams}
        tableContainer={tableContainer}
        filterLoading={filterLoading}
        fetchData={fetchData}
      />
    </div>
  )
}

export default AlertsIndexPage
