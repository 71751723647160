import { config } from '@decision-sciences/qontrol-common'
import { ACTIONS } from './actions'
import { defaultFilterSortConfig } from './constants'

const { LC_TABLE_FILTER_SORT, LC_3Q_COMPANY } = config

/** Name of the reducer */
const name = 'tableFilterSort'

const _getTableFilterSort = () => {
  let tableFilterSort = null
  try {
    const companyLCItem = localStorage.getItem(LC_3Q_COMPANY)
    const tableFilterSortLCItem = localStorage.getItem(LC_TABLE_FILTER_SORT)
    if (companyLCItem && tableFilterSortLCItem) {
      const clientId = JSON.parse(companyLCItem)?._id
      const tableFilterSortObject = JSON.parse(tableFilterSortLCItem)
      if (clientId && tableFilterSortObject) {
        tableFilterSort = tableFilterSortObject[clientId]
      }
    }
  } catch (e) {
    console.error(e)
  }
  return tableFilterSort
}

const _saveStateToLocalStorage = (state, newState, tableContainer) => {
  try {
    const companyLCItem = localStorage.getItem(LC_3Q_COMPANY)
    let tableFilterSortLCItem = localStorage.getItem(LC_TABLE_FILTER_SORT)
    if (!tableFilterSortLCItem) {
      localStorage.setItem(LC_TABLE_FILTER_SORT, JSON.stringify({}))
      tableFilterSortLCItem = localStorage.getItem(LC_TABLE_FILTER_SORT)
    }

    if (companyLCItem && tableFilterSortLCItem) {
      const clientId = JSON.parse(companyLCItem)?._id
      const lsFilterSort = JSON.parse(tableFilterSortLCItem) || {}

      if (clientId) {
        if (lsFilterSort[clientId]) {
          lsFilterSort[clientId] = {
            filterSort: {
              ...lsFilterSort[clientId].filterSort,
              [tableContainer]: newState[tableContainer],
            },
          }
        } else {
          lsFilterSort[clientId] = {
            ...structuredClone(defaultFilterSortConfig),
          }
          lsFilterSort[clientId].filterSort[tableContainer] =
            newState[tableContainer]
        }

        // write to local storage only if perfect conditions
        localStorage.setItem(
          LC_TABLE_FILTER_SORT,
          JSON.stringify({
            ...lsFilterSort,
          })
        )
      }
    }
  } catch (e) {
    console.error(e)
  }
}

/** Initial state of the reducer */
const initialState = _getTableFilterSort() || {
  ...defaultFilterSortConfig,
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const updateFilterSort = (state, tableContainer, newFilterSortData) => {
  const newFilterSortState = {
    ...state.filterSort,
    [tableContainer]: newFilterSortData,
  }
  _saveStateToLocalStorage(state, newFilterSortState, tableContainer)
  return { ...state, filterSort: newFilterSortState }
}

const actionsMap = {
  [ACTIONS.SAVE_SORT]: (state, { tableContainer, sort }) => {
    const newSortData = {
      ...state.filterSort[tableContainer],
      sort: [...sort],
    }
    return updateFilterSort(state, tableContainer, newSortData)
  },

  [ACTIONS.SAVE_FILTER]: (state, { tableContainer, filter }) => {
    const newFilterData = {
      ...state.filterSort[tableContainer],
      filter,
    }
    return updateFilterSort(state, tableContainer, newFilterData)
  },

  [ACTIONS.SAVE_PAGINATION]: (state, { tableContainer, pagination }) => {
    const newPaginationData = {
      ...state.filterSort[tableContainer],
      pagination,
    }
    return updateFilterSort(state, tableContainer, newPaginationData)
  },

  [ACTIONS.RESET_FILTER_SORT]: (state, { tableContainer }) => {
    const newFilterSortData = {
      ...state.filterSort[tableContainer],
      ...structuredClone(defaultFilterSortConfig.filterSort[tableContainer]),
    }
    // If the state has a page size different from the default one, keep it
    if (
      state.filterSort[tableContainer].pagination?.size !==
      newFilterSortData.pagination?.size
    ) {
      newFilterSortData.pagination.size =
        state.filterSort[tableContainer].pagination.size
    }
    return updateFilterSort(state, tableContainer, newFilterSortData)
  },
}
export default { name, initialState, reduce }
