import React from 'react'
import { useParams } from 'react-router-dom'
import AlertTriggerDetails from 'modules/alert-triggers/alert-trigger-details'

const AlertTriggerDetailsPage = () => {
  const { alertTriggerId } = useParams()

  return (
    <div className="alert-trigger-details page">
      <AlertTriggerDetails alertTriggerId={alertTriggerId} />
    </div>
  )
}

export default AlertTriggerDetailsPage
