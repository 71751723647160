import { format } from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'
import { legal } from '@decision-sciences/qontrol-common'

const { LEGAL_TYPES } = legal

/**
 * Common component to render a legal item
 * @param {Object} props Component props
 * @param {Object} props.classes CSS classes to apply to the content
 * @param {Boolean} props.withTimestamp Boolean flag to show or hide the last updated timestamp
 * @param {Object} props.item Legal item
 * @param {String} props.item.name Legal item title
 * @param {LEGAL_TYPES} props.item.type Legal item display type
 * @param {String} props.item.content Legal item content
 * @param {{_id:string, name: string, content: string}[]} [props.item.sections] Legal item sections
 * @param {String} props.item.updatedAt Legal item last updated timestamp
 * @returns {React.Component}
 */
const LegalItemContent = ({
  classes,
  withTimestamp,
  item: { name, type, content, sections, updatedAt },
}) => {
  return (
    <div className={classes}>
      <div
        className="align-row space-between align-baseline"
        style={{ gap: '1rem' }}
      >
        <h1>{name}</h1>
        {withTimestamp && (
          <div className="general-label">
            Last updated: {format(new Date(updatedAt), LEGAL_DATE_FORMAT)}
          </div>
        )}
      </div>

      <div className="legal-content ql-editor">
        {type === LEGAL_TYPES.DOCUMENT_WITHOUT_SECTIONS && (
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        )}
        {type === LEGAL_TYPES.DOCUMENT_WITH_SECTIONS &&
          sections?.length > 0 &&
          sections.map(({ _id, name, content }) => (
            <div key={_id}>
              <h1>{name}</h1>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
          ))}
      </div>
    </div>
  )
}

const LEGAL_DATE_FORMAT = 'MMMM dd yyyy'

LegalItemContent.propTypes = {
  classes: PropTypes.string,
  withTimestamp: PropTypes.bool,
  item: PropTypes.object.isRequired,
}

export { LegalItemContent }

