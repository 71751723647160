import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useStore } from 'store'

import InputText from 'components/input'
import Button from 'components/button'
import { isValidPhoneNumber, isPositiveNumber } from 'components/validator'
import {
  sendUserOTPReset,
  checkOtpVerificationCode,
  updatePhoneNumberPhoneAuth,
} from 'modules/users/actions'
import StickyFooter from 'components/sticky-footer'

const ResetPhoneAuthentication = ({ close }) => {
  const { state, dispatch } = useStore()
  const { userData } = state.session
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [phone, setPhone] = useState(null)
  const [verificationCode, setVerificationCode] = useState('')
  const [sentCode, setSentCode] = useState(false)
  const hiddenPhone = phone && phone.toString().slice(-3).padStart(10, 'x')

  const checkPhone = (phone) => {
    if (!isValidPhoneNumber(phone)) {
      setError('Invalid phone number.')
    }

    if (userData.phone === phone) {
      setError('This number is already assigned to your account.')
    }
  }

  const checkCode = (verificationCode) => {
    if (!isPositiveNumber(verificationCode)) {
      setError('Invalid verification code.')
    }
  }

  const sendVerificationCode = () => {
    setLoading(true)
    if (userData.phone === phone) {
      setLoading(false)
      return setError('This number is already assigned to your account.')
    }
    sendUserOTPReset(userData._id, phone)
      .then(() => {
        setSentCode(true)
      })
      .catch((error) => {
        setError(error || 'Something went wrong.')
      })
      .finally(() => setLoading(false))
  }

  /** Set a phone verification code and update the user with this code if it passes */
  const checkCodeAndUpdateUser = (userData, phone) => {
    setLoading(true)
    checkOtpVerificationCode(verificationCode)
      .then(() => {
        updatePhoneNumberPhoneAuth(dispatch, userData, phone)
          .then(close)
          .catch(() => setError('Updating Phone error'))
      })
      .catch(() => {
        setError('Something went wrong. Please click resend code.')
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="align-column">
      <div className="heading-secondary align-row">Set Up Text (SMS)</div>
      {sentCode ? (
        <div className="form__section__body align-column spacing-bottom">
          <div className="my-account-user__cursive-text">Check your phone</div>
          <div className="my-account-user__cursive-text">
            {`A verification code was sent to ${hiddenPhone}`}
          </div>
        </div>
      ) : (
        <div className="form__section__body align-column spacing-bottom">
          <div className="my-account-user__cursive-text">
            1. Enter your phone number below
          </div>
          <div className="my-account-user__cursive-text">
            2. Wait for a verification code to be sent
          </div>
          <div className="my-account-user__cursive-text">
            3. Enter the 6-digit code below
          </div>
        </div>
      )}

      <div className="my-account-user__set-up-sms">
        <div className="form__section__body align-column half-width ">
          <div className="my-account-user__cursive-text align-row">
            {sentCode ? `Authentication Code` : `Phone Number`}
            {sentCode && (
              <div
                className="resend-code"
                onClick={() => sendVerificationCode()}
              >
                Resend Code
              </div>
            )}
          </div>
          <InputText
            type="number"
            disabled={loading}
            value={sentCode ? verificationCode : phone}
            onChange={(e) => {
              sentCode ? setVerificationCode(e) : setPhone(e)
              setError(null)
            }}
            blur={() =>
              sentCode ? checkCode(verificationCode) : checkPhone(phone)
            }
          />
          {error && <div className="error">{error}</div>}
          <Button
            value={`${
              sentCode
                ? 'Turn On Multi-Factor Authentication'
                : 'Send Verification Code'
            }`}
            onClick={() =>
              !sentCode
                ? sendVerificationCode()
                : checkCodeAndUpdateUser(userData, phone)
            }
            disabled={loading}
          />
        </div>
      </div>

      <StickyFooter
        buttons={[
          { value: 'Go Back', onClick: () => close(), disabled: loading },
        ]}
      />
    </div>
  )
}

ResetPhoneAuthentication.propTypes = {
  close: PropTypes.func,
}

export default ResetPhoneAuthentication
