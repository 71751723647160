/** Get user companies based on list of companies and user type */
export const getUserCompanies = (user, companies) => {
  let userCompanies = []
  if (companies && user.clients) {
    if (user.isSuperAdmin) {
      userCompanies = companies || []
    } else {
      userCompanies = companies.reduce((array, company) => {
        const client = user.clients.find(
          (client) => client.clientId === company._id
        )

        if (client) {
          // Keep only the business units the user is assigned to
          const clientBusinessUnits = company.businessUnits.filter(
            (businessUnit) =>
              client.businessUnits.some(
                (businessUnitId) => businessUnitId === businessUnit._id
              )
          )
          return [...array, { ...company, businessUnits: clientBusinessUnits }]
        }
        return array
      }, [])
    }
  }

  return userCompanies
}

/**
 * Checks if a user object is active - i.e. can be used to login or to be emulated
 * @param {Object} user The user to be checked
 * @returns {Object} An object of form:
 * {
 *  isComplete: true/false,
 *  message: In case isComplete is false, a message with the reason
 * }
 */
export const isUserSetupComplete = (user) => {
  if (!user) {
    console.error('No user given')
    return {
      isComplete: false,
      message: 'Something went wrong with the user selection',
    }
  }
  if (!user.active) {
    return {
      isComplete: false,
      message: 'This user is inactive',
    }
  }
  if (user.isSuperAdmin) {
    return {
      isComplete: false,
      message: 'This user is a superadmin',
    }
  }
  if (!(user.clients && !!user.clients.length)) {
    return {
      isComplete: false,
      message: 'This user has no clients assigned',
    }
  }
  if (user.inviteToken) {
    return {
      isComplete: false,
      message: 'This user has not completed the registration process',
    }
  }
  return { isComplete: true }
}
