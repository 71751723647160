import React, { createContext, useState, useEffect } from 'react'
import { useStore } from 'store'
import PropTypes from 'prop-types'

import Overlay from 'components/overlay'
import resetIcon from 'assets/icon_reset.svg'
import { getMainElement } from 'components/utils/dom-manipulation'

import './style.scss'

export const dataRefreshContext = createContext({})

/**
 * Data Refresh Context provider
 * A context provider for controlling the 'refresh' notification on data change
 * When 'dataRefresh' is true, a notification appears, that executes [options.handler] on click, or in [autoRefreshAfter] seconds.
 * While the notification is visible, if [options.overlay] is true the main part of the app becomes unusable, to avoid modifying data that's not up to date
 * By default 'handler' refreshes the page, but it can be set with 'setHandler'
 * @param {Object} props props
 * @param {Object} props.children children
 * @returns {React.Component}
 */
const DataRefreshProvider = ({ children }) => {
  const {
    state: {
      global: {
        environment: { AUTO_REFRESH_DATA_AFTER },
      },
    },
  } = useStore()
  const autoRefreshAfter = AUTO_REFRESH_DATA_AFTER || 15

  const [dataRefresh, setDataRefresh] = useState(false)

  // Seconds 'till the auto-refresh
  const [secondsTillRefresh, setSecondsTillRefresh] = useState(autoRefreshAfter)
  const defaultHandler = () => window.location.reload(false)
  const [executeDefaultHandler, setExecuteDefaultHandler] = useState(true)

  const initialOptions = {
    // Default handler is to refresh the page
    handlers: [],
    // When set and 'dataRefresh' is true, it displays an overlay over the whole main area of the app, so that changes cannot be made
    overlay: true,
  }
  const [options, setOptionsState] = useState({ ...initialOptions })

  const setOptions = (obj) => {
    const newOptions = options

    Object.entries(obj).forEach(([key, value]) => {
      // Functions need to be saved as funcions that return functions
      if (key === 'handlers') {
        setExecuteDefaultHandler(false)
        // Add handler to the list of already existin handlers
        newOptions[key].push((...args) => value.bind(...args))
      } else {
        newOptions[key] = value
      }
    })
    setOptionsState({ ...newOptions })
  }

  // Executed all the handlers
  const handle = () => {
    if (executeDefaultHandler) {
      defaultHandler()
    } else {
      options.handlers.forEach((handler) => {
        handler()()
      })
    }
  }

  const reset = () => {
    setDataRefresh(false)
    setSecondsTillRefresh(autoRefreshAfter)
    setOptionsState({ ...initialOptions })
    setExecuteDefaultHandler(true)
  }

  // Automatically handle after [autoRefreshAfter] seconds
  useEffect(() => {
    // Prohibit scrolling so that the orverlay stays in place
    const main = getMainElement()
    if (main) {
      if (dataRefresh && options.overlay) {
        main.style.overflow = 'hidden'
      } else {
        main.style.overflow = 'auto'
      }
    }

    if (dataRefresh) {
      let localSecondsTillRefresh = secondsTillRefresh

      const interval = setInterval(() => {
        if (localSecondsTillRefresh > 0) {
          localSecondsTillRefresh--
          setSecondsTillRefresh(localSecondsTillRefresh)
        } else {
          localSecondsTillRefresh = autoRefreshAfter
          handle()
          reset()
        }
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [dataRefresh, JSON.stringify(options)])

  return (
    <dataRefreshContext.Provider
      value={{ dataRefresh, setDataRefresh, setOptions }}
    >
      {children}
      {dataRefresh && (
        <>
          <div className="refresh-notification">
            <p className="refresh-notification__text">
              Some alerts have been updated:
            </p>
            <span
              className="refresh-notification__action"
              onClick={() => {
                handle()
                reset()
              }}
            >
              <img
                alt="Icon Reset"
                src={resetIcon}
                className="refresh-notification__icon"
                width="20px"
                height="20px"
              />
              <p className="refresh-notification__text">Refresh</p>
              <p className="refresh-notification__text refresh-notification__text--timer">
                ({secondsTillRefresh})
              </p>
            </span>
          </div>
          {options.overlay && (
            <Overlay
              expanded={dataRefresh}
              className="refresh-notification__overlay"
            />
          )}
        </>
      )}
    </dataRefreshContext.Provider>
  )
}

DataRefreshProvider.propTypes = {
  children: PropTypes.any,
}

export default DataRefreshProvider
