import React, { createContext, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Api, { RESPONSE_TYPES } from 'easy-fetch-api'
import Countdown from 'react-countdown'
import Cookies from 'js-cookie'
import throttle from 'lodash.throttle'
import { useNavigate, useLocation } from 'react-router-dom'
import { config, platform } from '@decision-sciences/qontrol-common'

import Modal from 'components/modal'
import Button from 'components/button'

import { useStore } from 'store'

import { logout, pingServer, getSession } from 'modules/session/actions'
import useSession from 'modules/session'

import { buildLoginRedirectUrl } from 'components/utils/url'

const { COOKIE_NAME, EMULATE_COOKIE_NAME } = config
const { PLATFORM_SECURITY_SETTINGS_MAP, IGNORED_URLS } = platform

const { session_expiration_timeout, session_expiration_warning_timeout } =
  PLATFORM_SECURITY_SETTINGS_MAP

/* Initial State */
const initialState = {
  sessionExpires: false,
}

// 5 minute interval
const USER_ACTION_LISTENER_TIMEOUT = 5 * 60 * 1000

// Save the original function so we can apply our own .then on it
const originalMakeRequest = Api._makeRequest

const store = createContext(initialState)
const { Provider } = store

const SecurityProvider = ({ children }) => {
  const [state, setState] = useState(initialState)
  const {
    dispatch,
    state: {
      session: { isLoggedIn },
    },
  } = useStore()
  const [, user] = useSession()
  const navigate = useNavigate()
  const location = useLocation()

  const timeouts = useRef({})
  const lastActivity = useRef()
  const warningShown = useRef(false)
  const warningTimeout = useRef()

  const parseSession = (session) => {
    const expiryDate = new Date(session.expires)
    if (expiryDate <= new Date()) {
      return logout(dispatch)
    }
    if (expiryDate.getTime() - Date.now() < warningTimeout.current) {
      document.removeEventListener('mousemove', forceActivityHeartbeat)
      document.removeEventListener('keypress', forceActivityHeartbeat)
      setState((state) => ({
        ...state,
        sessionExpires: new Date(session.expires),
      }))
      warningShown.current = true
    } else {
      if (state.sessionExpires) {
        setState((state) => ({ ...state, sessionExpires: false }))
      }
      setLogoutTimeouts(session.expires)
    }
  }

  /**
   * Throttle setting / clearing timeouts as we don't need accuracy lower than a second.
   */
  const setLogoutTimeouts = throttle((expires) => {
    clearTimeout(timeouts.current.sessionWarningTimeout)
    clearTimeout(timeouts.current.sessionTimeout)
    clearTimeout(timeouts.current.userActions)
    const timeUntilExpires = new Date(expires).getTime() - Date.now()

    if (timeUntilExpires < 0) {
      logout(dispatch)
    }

    timeouts.current.sessionTimeout = setTimeout(() => {
      getSession().then(parseSession)
    }, timeUntilExpires)

    timeouts.current.sessionWarningTimeout = setTimeout(() => {
      getSession().then(parseSession)
    }, timeUntilExpires - warningTimeout.current)

    // USER_ACTION_LISTENER_TIMEOUT seconds after the last API activity, attach mouse and keyboard listeners to the app to check if the user is indeed active or not
    timeouts.current.userActions = setTimeout(() => {
      document.addEventListener('mousemove', forceActivityHeartbeat)
      document.addEventListener('keypress', forceActivityHeartbeat)
    }, USER_ACTION_LISTENER_TIMEOUT)
  }, 1000)

  /**
   * In this useEffect, we're effectively hijacking easy-fetch-api's _makeRequest,
   * which is the function that is called on every HTTP call.
   * On every HTTP call, we're extracting the data we need and then we release the response back into the wild so it can serve its' purpose.
   * * No HTTP calls were harmed while running this useEffect
   */
  useEffect(() => {
    // Make a copy of the original make request method
    Api._makeRequest = originalMakeRequest

    // For non-logged in users & their API calls we don't need any extra functionality
    if (!isLoggedIn) {
      return
    }

    // Overwrite the _makeRequest method
    Api._makeRequest = (params) => {
      // If the cookie was invalidated in the meantime by the server, redirect to Login
      if (
        isLoggedIn &&
        !Cookies.get(COOKIE_NAME) &&
        !Cookies.get(EMULATE_COOKIE_NAME)
      ) {
        window.location = buildLoginRedirectUrl()
        return Promise.resolve({ success: false })
      }

      return originalMakeRequest({ ...params, responseType: 'raw' }).then(
        (res) => {
          if (!res) {
            return
          }
          // Server responded with Session Expired status code
          if (res.status === 440) {
            window.location = buildLoginRedirectUrl()
            return
          }

          // Ignore URLs that get requested periodically
          if (IGNORED_URLS.some((url) => url === params.request.url)) {
            if (params.responseType === RESPONSE_TYPES.raw) {
              return res
            } else {
              return res.json()
            }
          }
          // Extract the headers we need
          const headers = parseHeaders(
            res.headers,
            [
              session_expiration_timeout,
              session_expiration_warning_timeout,
              'expires',
            ],
            parseInt
          )

          if (headers?.[session_expiration_timeout]) {
            lastActivity.current = !isNaN(headers.expires)
              ? new Date(headers.expires)
              : new Date(Date.now() + headers[session_expiration_timeout])

            warningTimeout.current = headers[session_expiration_warning_timeout]

            setLogoutTimeouts(lastActivity.current)
          }

          if (warningShown.current) {
            setState((state) => ({ ...state, sessionExpires: false }))
            warningShown.current = false
          }

          if (params.responseType === RESPONSE_TYPES.raw) {
            return res
          } else {
            return res.json()
          }
        }
      )
    }
  }, [isLoggedIn])

  /** If the password-changing timeout is up, redirect the user to the password changing page with a modal they cannot close */
  useEffect(() => {
    // This is only available for logged in users
    if (!isLoggedIn) {
      return
    }

    if (user && user?.passwordExpires) {
      const currentTime = new Date()
      const timeDiff = new Date(user.passwordExpires) - currentTime

      /**
       * Time's up => force the user to change their password
       * Wait a bit before redirecting the user, so that all the login-related redirects are finished beforehand
       * This doesn't apply when emulating a user
       */
      if (
        !user.emulatedBy &&
        timeDiff <= 0 &&
        !location?.state?.forceChangePassword
      ) {
        setTimeout(
          () =>
            navigate('/my-account/security', {
              state: { forceChangePassword: true, lastLocation: location },
              replace: true,
            }),
          1000
        )
      }
    }
  }, [user?.passwordExpires, isLoggedIn])

  // The pingServer API call will retrigger the session expiration recalculation
  const forceActivityHeartbeat = () => {
    if (state.sessionExpires) {
      return
    }
    document.removeEventListener('mousemove', forceActivityHeartbeat)
    document.removeEventListener('keypress', forceActivityHeartbeat)
    pingServer()
  }

  return (
    <Provider
      value={{
        warningShown: state.warningShown,
        sessionExpires: state.sessionExpires,
      }}
    >
      {state.sessionExpires && (
        <Modal
          className="leave-confirm__modal"
          heading="Session Expiring"
          opened={!!state.sessionExpires}
          button={
            <Button
              value="I'm here!"
              onClick={() => {
                pingServer()
              }}
            />
          }
        >
          <div>Your session is about to expire due to inactivity:</div>
          <Countdown
            autoStart
            date={new Date(state.sessionExpires)}
            interval={1000}
            renderer={({ minutes, seconds }) => {
              return (
                <h3 className="generic-heading">
                  {minutes.toString().padStart(2, '0')}:
                  {seconds.toString().padStart(2, '0')}
                </h3>
              )
            }}
            onComplete={() => {
              getSession().then(parseSession)
            }}
          />
          <div>
            If the session expires, you will lose any unsaved changes and be
            logged out.
          </div>
        </Modal>
      )}
      {children}
    </Provider>
  )
}

SecurityProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

/**
 * Goes through headers and picks out specific ones, applying modifiers to values
 * @param {Object} headers Headers (res.headers)
 * @param {String[]} keys Header names to look out for
 * @param {Function} headerModifier Function to apply to header values
 * @returns {Object}
 */
export const parseHeaders = (headers, keys, headerModifier) => {
  const found = {}
  if (headers) {
    headers.forEach(function (value, name) {
      if (keys.some((key) => key === name)) {
        found[name] = headerModifier ? headerModifier(value) : value
      }
    })
    return found
  }
  return null
}

export default SecurityProvider
