import React from 'react'
import cx from 'classnames'
import { campaignExclusions } from '@decision-sciences/qontrol-common'

import { AccountIcon } from 'components/account-icon'
const {
  CAMPAIGN_EXCLUSION_RULE_PROPERTIES,
  CAMPAIGN_EXCLUSIONS_OPERATION_TYPES,
} = campaignExclusions

/**
 * Convenient way to return the tooltip's content for a rule entity
 *
 * @param {Object} props
 * @param {Object} props.exclusion
 * @param {Array} props.publishers
 */
export const RuleTooltip = ({ exclusion, publishers }) => {
  if (
    !exclusion ||
    (!exclusion.globalConfig &&
      !exclusion.publishersConfig &&
      !Object.keys(exclusion.publishersConfig).length)
  ) {
    return ''
  }

  const getIconByPublisherKey = (publisherKey) => {
    return publisherKey
      ? publishers.find((p) => p.id.toUpperCase() === publisherKey).id
      : publishers.find((p) => p.id.toUpperCase() === 'GLOBAL').id
  }

  const getOperationLabelByOperation = (operation) =>
    CAMPAIGN_EXCLUSIONS_OPERATION_TYPES.find(
      (operationType) => operationType.value === operation
    )?.label || ''

  const buildRuleTooltipRow = (rule) => {
    if (rule.property === CAMPAIGN_EXCLUSION_RULE_PROPERTIES.CAMPAIGN_NAME) {
      return `campaign name ${getOperationLabelByOperation(rule.operation)} "${
        rule.value
      }"`
    } else if (
      rule.property === CAMPAIGN_EXCLUSION_RULE_PROPERTIES.ACCOUNT_ID
    ) {
      if (rule.value === 'ALL_ACCOUNTS') {
        return 'account id = all accounts'
      }
      return rule.value.length > 2
        ? `account id = ${rule.value.slice(0, 2)?.join(', ')} and ${
            rule.value.length - 2
          } more`
        : `account id = "${rule.value?.join(', ')}"`
    } else {
      return `channel type = "${rule.value}"`
    }
  }

  /**
   * Renders the tooltip content based on a given publisher/global config
   * config = {
   *     "operand": "AND",
   *     "rules": [
   *         {
   *             "property": "CAMPAIGN_NAME",
   *             "operation": "CT",
   *             "value": "",
   *         }
   *     ],
   *     publisherKey: "FACEBOOK"
   * }
   */
  const generateRules = (config, index) => {
    const currentRules = config.rules
      .map(buildRuleTooltipRow)
      .join(` [${config.operand}] `)

    const iconType = getIconByPublisherKey(config.publisherKey)

    return (
      <div
        key={index}
        className={cx('display-flex', {
          'margin-bottom-10': index < configsToRenderTooltipFor.length - 1,
        })}
      >
        <AccountIcon accountType={iconType} />
        <div className="margin-left-10 margin-top-auto margin-bottom-auto">
          {currentRules}
        </div>
      </div>
    )
  }

  const configsToRenderTooltipFor = exclusion.globalConfig
    ? [exclusion.globalConfig]
    : Object.keys(exclusion.publishersConfig).map((publisherKey) => ({
        ...exclusion.publishersConfig[publisherKey],
        publisherKey: publisherKey,
      }))

  return configsToRenderTooltipFor.map((config, index) =>
    generateRules(config, index)
  )
}
