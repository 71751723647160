import React, { useState, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Dropdown } from 'components/dropdown'
import {
  userPropType,
  companyPropType,
  teamPropType,
} from 'components/utils/prop-types'
import StickyFooter from 'components/sticky-footer'
import { ERRORS } from 'components/validator'
import useLeaveConfirm from 'components/leave-confirm'

const AssignUsersModule = ({ isGlobal, user, clients, teams, onAssign }) => {
  const isEditMode = useMemo(() => user.clients.length > 0, [])
  const accounts = useMemo(
    () => clients.reduce((arr, client) => arr.concat(client.accounts), []),
    []
  )
  const businessUnits = useMemo(
    () => clients.reduce((arr, client) => arr.concat(client.businessUnits), []),
    []
  )
  const [setDirty, LeaveConfirm] = useLeaveConfirm({})

  /** Define user clients, teams, accounts, businessUnits */
  const userClients = user.clients || []
  const userTeams = user.teams.filter((t) => !t.isGlobal).map((t) => t._id)
  const userAccounts = []
  const userBizUnits = []
  userClients.forEach((userClient) => {
    userClient.accounts.forEach((accId) => {
      if (userAccounts.indexOf(accId) === -1) {
        userAccounts.push(accId)
      }
    })
    userClient.businessUnits.forEach((unitId) => {
      if (userBizUnits.indexOf(unitId) === -1) {
        userBizUnits.push(unitId)
      }
    })
  })

  /** Define state */
  const [state, setState] = useState({
    clients: userClients.map((el) => el.clientId),
    accounts: userAccounts,
    businessUnits: userBizUnits,
    teams: userTeams,
    globalTeams: user.teams.filter((t) => t.isGlobal).map((t) => t._id),
  })
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  /** On Clients Changed update state, accounts, bizUnits and Teams */
  const onClientsChanged = (updatedClients) => {
    const updatedAccounts = state.accounts.filter((accId) => {
      const accountEl = accounts.find((el) => el._id === accId)
      return accountEl && updatedClients.indexOf(accountEl.company) > -1
    })
    const updatedBizUnits = state.businessUnits.filter((unitId) => {
      const bizUnitEl = businessUnits.find((el) => el._id === unitId)
      return bizUnitEl && updatedClients.indexOf(bizUnitEl.parentCompany) > -1
    })
    const updatedTeams = state.teams.filter((teamId) => {
      const teamEl = teams.find((el) => el._id === teamId)
      return teamEl.companies.find((company) =>
        updatedClients.find((clientId) => clientId === company._id)
      )
    })
    setState({
      ...state,
      clients: updatedClients,
      accounts: updatedAccounts,
      businessUnits: updatedBizUnits,
      teams: updatedTeams,
    })
    setErrors({ ...errors, clients: null })
  }

  /** On Mount, initialize with proper data */
  useEffect(() => {
    onClientsChanged(userClients.map((el) => el.clientId))
  }, [])

  /** Define items to show in dropdown */
  const globalTeams = teams.filter((t) => t.isGlobal)
  const accountsToShow = accounts.filter(
    (acc) => state.clients.indexOf(acc.company) > -1
  )
  const businessUnitsToShow = businessUnits.filter(
    (bizUnit) => state.clients.indexOf(bizUnit.parentCompany) > -1
  )
  const teamsToShow = teams.filter(
    (t) =>
      !t.isGlobal &&
      t.companies.find((company) =>
        state.clients.find((clientId) => clientId === company._id)
      )
  )

  const onFieldChange = (fieldName, value) => {
    setDirty(true)
    setState({ ...state, [fieldName]: value })
  }

  /** Save handler */
  const onSave = () => {
    if (!state.clients && !isGlobal) {
      return setErrors({ ...errors, clients: ERRORS.REQUIRED })
    }
    setLoading(true)
    onAssign({
      ...state,
      userId: user._id,
      teamsOnly: isGlobal,
      teams: isGlobal ? state.globalTeams : state.teams,
    })
      .then(() => {
        setDirty(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const classes = cx('assign-users form', { 'form--loading': loading })

  return (
    <div className={classes}>
      <LeaveConfirm />
      <Helmet>
        <title>
          {isEditMode ? 'Edit User Assignment' : 'Assign User'}{' '}
          {isGlobal ? 'to Global Teams' : ''}
        </title>
      </Helmet>
      <div className="heading" data-cy="page-heading">
        {isEditMode ? 'Edit User Assignment' : 'Assign User'}{' '}
        {isGlobal ? 'to Global Teams' : ''}
      </div>

      <section className="form__section">
        <div className="form__section__body assign-users__body">
          <div className="form__section__header">{user.name}</div>
          {isGlobal ? (
            <Dropdown
              options={globalTeams.map((t) => ({
                value: t._id,
                label: t.name,
              }))}
              multiSelect={true}
              defaultOptionText="Global Teams"
              className="form__row"
              selectedItems={state.globalTeams}
              onChange={(globalTeams) => setState({ ...state, globalTeams })}
            />
          ) : (
            <>
              <div className="assign-users__half">
                <Dropdown
                  options={clients.map((c) => ({
                    value: c._id,
                    label: c.name,
                  }))}
                  multiSelect
                  selectAll
                  selectedItems={state.clients}
                  defaultOptionText="Clients"
                  className="form__row"
                  onChange={(clients) => {
                    setDirty(true)
                    onClientsChanged(clients)
                  }}
                  error={errors.clients}
                />
                <Dropdown
                  options={accountsToShow.map((el) => ({
                    value: el._id,
                    label: el.name,
                  }))}
                  selectedItems={state.accounts}
                  multiSelect
                  selectAll
                  defaultOptionText="Accounts"
                  className="form__row"
                  onChange={(accounts) => onFieldChange('accounts', accounts)}
                />
              </div>
              <div className="assign-users__half">
                <Dropdown
                  options={businessUnitsToShow.map((el) => ({
                    value: el._id,
                    label: el.name,
                  }))}
                  selectedItems={state.businessUnits}
                  multiSelect
                  selectAll
                  defaultOptionText="Business Units"
                  className="form__row"
                  onChange={(businessUnits) =>
                    onFieldChange('businessUnits', businessUnits)
                  }
                />
                <Dropdown
                  options={teamsToShow.map((t) => ({
                    value: t._id,
                    label: t.name,
                  }))}
                  multiSelect
                  selectAll
                  defaultOptionText="Teams"
                  className="form__row"
                  selectedItems={state.teams}
                  onChange={(teams) => onFieldChange('teams', teams)}
                />
              </div>
            </>
          )}
        </div>
      </section>

      <StickyFooter
        buttons={[
          {
            value: 'Save Changes',
            onClick: onSave,
          },
        ]}
      />
    </div>
  )
}

AssignUsersModule.propTypes = {
  isGlobal: PropTypes.bool.isRequired,
  user: userPropType.isRequired,
  clients: PropTypes.arrayOf(companyPropType).isRequired,
  teams: PropTypes.arrayOf(teamPropType).isRequired,
  onAssign: PropTypes.func.isRequired,
}

export default AssignUsersModule
