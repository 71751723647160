export const BASE_COLORS = ['#D5F7FF', '#D5FFE1', '#FFF8D5', '#FCE7E7']
export function generateRandomColor(baseColors = BASE_COLORS) {
  const minValue = 150 // Minimum value for each RGB component
  const range = 105 // Range of values for each RGB component (0 to 255)

  // Generate random values for each RGB component
  const red = minValue + Math.floor(Math.random() * range)
  const green = minValue + Math.floor(Math.random() * range)
  const blue = minValue + Math.floor(Math.random() * range)

  // Combine the RGB components into a hexadecimal color code
  const color = `#${red.toString(16).padStart(2, '0')}${green
    .toString(16)
    .padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`

  if (baseColors.includes(color)) {
    return generateRandomColor(baseColors)
  }
  return color
}

export function getColorMap(wordsToHighligh = [], baseColors = BASE_COLORS) {
  const newColorsMap = {}
  wordsToHighligh.forEach((word, index) => {
    if (index < baseColors.length) {
      newColorsMap[word] = baseColors[index]
    } else {
      newColorsMap[word] = generateRandomColor(baseColors)
      baseColors.push(newColorsMap[word])
    }
  })
  return newColorsMap
}

export const containsLogicalOperator = (word) => {
  const logicalOperatorRegexp = new RegExp('([\\s]*(or|and)[\\s]*)', 'gi')
  return logicalOperatorRegexp.test(word)
}

export const containsRelationalOperator = (word) =>
  ['<', '>', '='].some((operator) => word.includes(operator))

export const arrayFromRange = (start, stop, step) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  )
