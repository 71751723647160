/* Utils */
import exifParser from 'exif-parser'
import { utils } from '@decision-sciences/qontrol-common'

/* Constants */
import { DUMMY_VIDEO_ELEMENT, FILE_SIZE_UNIT } from './constants'

const { checkIfArrayContainsObjectWithProperty, findInArrayByProperty } =
  utils.array

/**
 * Searches through the uploading files and returns a file based on its name if exists
 * @param {Array} files - The array with all the files
 * @param {String} fileName - The file name to search by
 * @returns {Object|null} - The found file or null in case there is no file with that given name
 */
export const getUploadingFileByName = (files, fileName) =>
  findInArrayByProperty(files, 'fileName', fileName)

/**
 * Returns only the files that have an accepted size
 * @param {Array} files - An array with files
 * @param {Number} maxFileSize - Max allowed file size
 * @param {Function} throwError - A setter function that sets the error in case the file exceeds the limit
 * @return {Array} An array with only files that have a size that is accepted
 */
export const getAcceptedFiles = (files, maxFileSize, throwError) =>
  files.filter((file) => {
    const fileSize = file.size / 1024 / 1024 // Convert to MB

    if (fileSize > maxFileSize) {
      throwError(
        `${
          file.name.length > 16 ? `${file.name.slice(0, 16)}...` : file.name
        } exceeds ${maxFileSize}MB.`
      )
      return false
    }
    return true
  })

/**
 * Determines the resolution of a video
 * @param {Function} resolve - A promise resolver given from the parent
 * @param {Object | String} file - The video file that we want to determine the resolution for. It can also represent the video URL.
 * @param {Boolean} fromUrl - If the video was uploaded via URL, then the src attribute for the video elements needs to be created differently.
 * @return {string|undefined} - Returns the resolution of the video or undefined in case it could not be determined
 */
export const getVideoResolution = (resolve, file, fromUrl = false) => {
  /* In order to get the video resolution we will create a video element */
  let media
  if (!fromUrl) {
    media = URL.createObjectURL(file)
  } else {
    media = file
  }

  let video = document.getElementById(DUMMY_VIDEO_ELEMENT)
  if (!video) {
    video = document.createElement('video')
    video.id = DUMMY_VIDEO_ELEMENT
    document.body.appendChild(video)
  }
  video.src = media

  // After video metadata was loaded the resolution can be extracted
  video.addEventListener('loadedmetadata', () => {
    resolve(`${video.videoWidth} x ${video.videoHeight}`)
  })
}

/**
 * Determines the resolution of a video
 * @param {Function} resolve - A promise resolver given from the parent
 * @param {Object | String} file - The video file that we want to determine the resolution for. It can also represent the video URL.
 * @param {Boolean} fromUrl - If the video was uploaded via URL, then the src attribute for the video elements needs to be created differently.
 * @return {string|undefined} - Returns the resolution of the video or undefined in case it could not be determined
 */
export const getVideoMetadata = (resolve, file, fromUrl = false) => {
  /* In order to get the video resolution we will create a video element */
  let media
  if (!fromUrl) {
    media = URL.createObjectURL(file)
  } else {
    media = file
  }

  let video = document.getElementById(DUMMY_VIDEO_ELEMENT)
  if (!video) {
    video = document.createElement('video')
    video.id = DUMMY_VIDEO_ELEMENT
    document.body.appendChild(video)
  }
  video.src = media

  // After video metadata was loaded the resolution can be extracted
  video.addEventListener('loadedmetadata', () => {
    const { videoWidth, videoHeight, duration } = video

    resolve({
      width: videoWidth,
      height: videoHeight,
      duration,
      resolution: `${videoWidth} x ${videoHeight}`,
    })
  })
}

/**
 * Determines the metadata of an image (any format supported by the browser)
 * @param {Function} resolve - A promise resolver given from the parent
 * @param {Object | String} file - The video file that we want to determine the resolution for. It can also represent the video URL.
 * @param {Boolean} fromUrl - If the video was uploaded via URL, then the src attribute for the video elements needs to be created differently.
 * @return {string|undefined} - Returns the resolution of the video or undefined in case it could not be determined
 */
export const getGenericImageMetadata = (resolve, file, fromUrl = false) => {
  const img = new Image()
  img.onload = (e) => {
    const image = e.composedPath()[0]
    resolve({
      width: image.naturalWidth,
      height: image.naturalHeight,
      resolution: `${image.naturalWidth} x ${image.naturalHeight}`,
    })
  }
  img.src = !fromUrl ? URL.createObjectURL(file) : file
}

/**
 * Determines the resolution of an image
 * @param {Object} reader - A FileReader object which will be parsed to find the resolution
 * @return {Promise} - Returns the resolution of the image in case it has one,
 * or undefined in case it could not be determined
 */
const getImageMetadata = (reader) =>
  new Promise((resolve, reject) => {
    try {
      const metaData = exifParser.create(reader.result).parse()
      if (metaData && metaData.imageSize) {
        return resolve({
          width: metaData.imageSize.width,
          height: metaData.imageSize.height,
          resolution: `${metaData.imageSize.width} x ${metaData.imageSize.height}`,
        })
      }
    } catch (error) {
      reject()
    }
  })

/**
 * Determines the resolution of a file (image or video)
 * @param {Object} reader - A FileReader object which will be parsed to find the resolution
 * @param {Object} file - The file that we want to determine the resolution for
 * @param {boolean} isImage - A flag that tells us if the file it's an image
 * @param {boolean} isVideo - A flag that tells us if the file it's a video
 * @returns {Promise<string|undefined>}
 */
export const getFileMetadata = (reader, file, isImage, isVideo) => {
  return new Promise(async (resolve) => {
    if (isImage) {
      const resolution = await getImageMetadata(reader).catch(() => null)

      if (resolution) {
        return resolve(resolution)
      }

      return getGenericImageMetadata(resolve, file)
    } else if (isVideo) {
      return getVideoMetadata(resolve, file)
    }
    resolve()
  })
}

/**
 * Sets the handlers on the reader for Abort and Error cases
 * @param {Object} reader - A FileReader object on which the handlers will be set
 */
export const setReaderFailHandlers = (reader) => {
  reader.onabort = (error) =>
    console.error(
      `File reading has failed. Error: ${error || 'No specific error given'}`
    )
  reader.onerror = (error) =>
    console.error(
      `File reading has failed. Error: ${error || 'No specific error given'}`
    )
}

/**
 * Clears the errors by setting the error state as false
 * @param {Function} throwError - The setter for the error state
 */
export const clearErrors = (throwError) => throwError && throwError(false)

/**
 * Returns only the unique files from the files that the user tries to upload
 * @param {Array} files - The name of the file we do the check for
 * @param {Array} alreadyExistingFiles - An array with all the already existing files
 * @param {Function} throwError - The display error callback
 * @returns {Array} - The array with the unique files
 */
export const keepUniqueFiles = (files, alreadyExistingFiles, throwError) => {
  let hasDuplicateFiles = false

  const uniqueFiles = files.filter((file) => {
    if (
      checkIfArrayContainsObjectWithProperty(
        alreadyExistingFiles,
        'value',
        file.name
      )
    ) {
      hasDuplicateFiles = true
      return false
    }
    return true
  })

  if (hasDuplicateFiles) {
    throwError('Some files have already been added.')
  }

  return uniqueFiles
}

/**
 * Returns the percent of the upload that was completed from the request progress data
 * Divides the amount loaded to the total size of the file and returns a rounded percent
 * @param {Object} requestProgressData - The request progress details
 * @returns {number|null}
 */
export const getUploadPercentFromRequestProgressData = (
  requestProgressData
) => {
  if (requestProgressData?.loaded && requestProgressData.total) {
    return +(
      (requestProgressData.loaded / requestProgressData.total) *
      100
    ).toFixed(0)
  }
  return null
}

/**
 * Transforms the file size from bytes to other units
 * @param {Number} sizeInBytes File size
 * @param {FILE_SIZE_UNIT} [unit] Enum representing the unit to convert to
 * @returns {Number}
 */
export const getFormattedFileSize = (sizeInBytes, unit = FILE_SIZE_UNIT.MB) => {
  if (!sizeInBytes) {
    return 0
  }
  return (sizeInBytes / 1024 ** unit).toFixed(2)
}
