import React from 'react'
import { useStore } from 'store'
import { setSelectedCampaign } from 'modules/microsoft-config/actions'
import GoogleDataContainer from 'modules/google/google-data-container'
import { campaignConfig } from 'modules/microsoft-config/data-config'

const Campaigns = () => {
  const { state, dispatch } = useStore()
  const { campaigns, selectedCampaign } = state.microsoft

  const renderCampaigns = () => {
    return Object.values(campaigns).map((data, index) => {
      return (
        <div
          key={index}
          className="campaign"
          onClick={() => {
            setSelectedCampaign(data, dispatch)
          }}
        >
          {data.name}
        </div>
      )
    })
  }
  return (
    <section>
      <h2>{selectedCampaign ? selectedCampaign.name : 'Campaigns'} </h2>
      <div className="campaigns">
        {selectedCampaign && campaignConfig ? (
          <GoogleDataContainer
            config={campaignConfig}
            data={selectedCampaign}
            objectKey={`${selectedCampaign.id}`}
          />
        ) : (
          renderCampaigns()
        )}
      </div>
    </section>
  )
}

export default Campaigns
