import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from 'store'

import NotificationGroups from 'modules/notification-groups'
import NotificationGroupsCreateEdit from 'modules/notification-groups/create-edit'
import DeleteModal from 'components/delete-modal'

import { deleteNotificationGroup } from 'modules/notification-groups/actions'
import { TABLE_CONTAINER } from 'modules/table-filter-sort/constants'

const NotificationGroupsPage = () => {
  const tableContainer = TABLE_CONTAINER.NOTIFICATION_GROUPS
  const { key } = useParams()
  const navigate = useNavigate()
  const [groupToDelete, setGroupToDelete] = useState(null)
  const [loading, setLoading] = useState(false)

  const { dispatch } = useStore()

  const onDelete = () => {
    setLoading(true)
    deleteNotificationGroup(dispatch, groupToDelete._id).finally(() => {
      setGroupToDelete(null)
      setLoading(false)

      setTimeout(() => {
        navigate('/notification-groups')
      }, 100)
    })
  }

  return (
    <React.Fragment>
      <DeleteModal
        toDelete={groupToDelete}
        setOpen={setGroupToDelete}
        onDelete={onDelete}
        loading={loading}
        className="alert-categories__modal"
      />
      <div className="notification-groups page">
        {key?.length ? (
          <NotificationGroupsCreateEdit onDelete={setGroupToDelete} />
        ) : (
          <NotificationGroups
            onDelete={setGroupToDelete}
            tableContainer={tableContainer}
          />
        )}
      </div>
    </React.Fragment>
  )
}

export default NotificationGroupsPage
