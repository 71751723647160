import React from 'react'
import plusIconBlue from 'assets/icon_plus_blue.svg'

const options = [
  {
    id: 1,
    label: 'Create Facebook Campaign',
    icon: plusIconBlue,
  },
  {
    id: 2,
    label: 'Create Google Campaign',
    icon: plusIconBlue,
  },
  {
    id: 3,
    label: 'Create Bing Campaign',
    icon: plusIconBlue,
  },
]

const AddMenu = () => {
  return (
    <>
      <div className="header__opened-menu__title">Create Something</div>
      {options.map((option, o) => (
        <div
          key={o}
          className="header__opened-menu__item"
          onClick={() => option.click()}
        >
          <span>{option.label}</span>
          <img src={option.icon} alt={option.label} />
        </div>
      ))}
    </>
  )
}

export default AddMenu
