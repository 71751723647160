export const PAGE_MODE = {
  EDIT: 'edit',
  ADD: 'add',
  DEFAULT: 'default',
}

export const PROPERTIES = {
  NAME: 'name',
  EMBED_CODE: 'embedCode',
  TEAM_PERMISSIONS: 'teamPermissions',
  USER_PERMISSIONS: 'userPermissions',
  // This one is used for filters
  SOURCES: 'sources',
  // This one is used on the model
  SOURCE: 'source',
}

export const DEFAULT_REPORT = {
  [PROPERTIES.NAME]: '',
  [PROPERTIES.EMBED_CODE]: '',
  [PROPERTIES.TEAM_PERMISSIONS]: [],
  [PROPERTIES.USER_PERMISSIONS]: [],
}

export const getReportErrors = (report, reportings) => {
  const errors = {}
  if (!report[PROPERTIES.NAME]) {
    errors[PROPERTIES.NAME] = true
  }

  if (
    reportings.find(
      (rep) =>
        rep.name === report.name && (report._id ? rep._id !== report._id : true)
    )
  ) {
    errors[PROPERTIES.NAME] =
      'A report with the same name already exists on this client'
  }
  if (!report[PROPERTIES.EMBED_CODE]) {
    errors[PROPERTIES.EMBED_CODE] = true
  }
  if (!report[PROPERTIES.SOURCE] && !errors[PROPERTIES.EMBED_CODE]) {
    errors[PROPERTIES.EMBED_CODE] = 'Could not determine valid source from URL'
  }
  return errors
}

export const filterReports = (reports, filter) => {
  const { name, teamPermissions, userPermissions, sources } = filter
  return reports.filter(
    ({
      owner: { firstName, lastName },
      name: reportName,
      teamPermissions: reportTeams,
      userPermissions: reportUsers,
      source,
    }) => {
      const teamIds = reportTeams.map((t) => t._id)
      const userIds = reportUsers.map((u) => u._id)
      if (name) {
        const nameMatch = [firstName, lastName, reportName].some((property) =>
          property.toLowerCase().match(name.toLowerCase())
        )

        if (!nameMatch) {
          return false
        }
      }
      if (
        teamPermissions &&
        teamPermissions.length &&
        !teamPermissions.some((team) => teamIds.includes(team))
      ) {
        return false
      }
      if (
        userPermissions &&
        userPermissions.length &&
        !userPermissions.some((user) => userIds.includes(user))
      ) {
        return false
      }
      if (
        sources &&
        sources.length &&
        !sources.some((sourceEntry) => sourceEntry === source)
      ) {
        return false
      }
      return true
    }
  )
}

export const getReportingsForUser = (user, reportings) => {
  const { _id: userId, teams, isSuperAdmin } = user
  const reportingsWithIds = reportings.filter((r) => !r.deleted)
  if (isSuperAdmin) {
    return reportingsWithIds
  }
  return reportingsWithIds.filter(({ teamPermissions, userPermissions }) => {
    const teamIds = teamPermissions.map((t) => t._id)
    const userIds = userPermissions.map((u) => u._id)
    let isPartOfTeam = false
    let isPartOfUsers = false
    if (userIds.length) {
      isPartOfUsers = userIds.includes(userId)
    }

    if (teamIds.length) {
      isPartOfTeam = teams.some(({ _id }) => teamIds.includes(_id))
    }

    return isPartOfTeam || isPartOfUsers
  })
}
