import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { isMatch } from 'date-fns'

/* Components */
import FileUpload from 'components/file-upload/ad-content-file-upload'
import ItemRow from 'components/item-row/index'
import InformationBlock, {
  INFORMATION_BLOCK_TYPE,
} from 'components/information-block'
import LineDivider from 'components/line-divider/index'

import { BUDGET_CSV_KEYS } from '../../utils'

const BudgetCSVUpload = ({ setCsvFileName, updateBudget }) => {
  const [fileName, setFileName] = useState('')
  const [error, setError] = useState(null)

  const onFileUploadDone = (data) => {
    const parsedFile = getParsedFile(data)
    updateBudget(parsedFile)
  }

  const getParsedFile = (file) => {
    const { name, budgets } = file
    setCsvFileName(name)
    const data = []

    budgets.forEach((el) => {
      const element = {
        id: uuidv4(),
        budgetSegment: el[BUDGET_CSV_KEYS.BUDGET_SEGMENT],
        budgetAmount: el[BUDGET_CSV_KEYS.BUDGET_AMOUNT],
        startDate: isMatch(el[BUDGET_CSV_KEYS.START_DATE], 'MM/dd/yyyy')
          ? new Date(el[BUDGET_CSV_KEYS.START_DATE])
          : '',
        endDate: isMatch(el[BUDGET_CSV_KEYS.END_DATE], 'MM/dd/yyyy')
          ? new Date(el[BUDGET_CSV_KEYS.END_DATE])
          : '',
        kpiType: el[BUDGET_CSV_KEYS.KPI_TYPE],
        kpiTarget: el[BUDGET_CSV_KEYS.KPI_TARGET],
      }
      data.push(element)
    })

    return data
  }

  const onRemoveFile = () => {
    setFileName(null)
    setError(null)
    setCsvFileName(null)
  }

  return (
    <div data-cy="dimension-input-upload-csv">
      {fileName && !error && (
        <>
          <div className="padding-x-30 padding-y-10">{fileName}</div>
          <LineDivider />
        </>
      )}
      <div className="padding-x-30 padding-y-10">
        <FileUpload
          throwError={(error) => {
            error && setError(error)
          }}
          onFileSelected={(file) => {
            setFileName(file?.name)
          }}
          fileTypesAccepted=".csv"
          onFileUploadDone={onFileUploadDone}
          allowMultiple={false}
          uploadApiPath={UPLOAD_CSV_TEMPLATE}
        />
        {error && (
          <>
            {fileName && (
              <div className="form__half margin-top-22">
                <ItemRow
                  hasError={error}
                  item={{ value: fileName }}
                  progressBar={100}
                  onRemove={onRemoveFile}
                />
              </div>
            )}
            <div className="margin-top-10 form__half">
              <InformationBlock
                type={INFORMATION_BLOCK_TYPE.ERROR}
                info={error}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const UPLOAD_CSV_TEMPLATE = '/api/companies/parse-budget-pacing-template'

export default BudgetCSVUpload

BudgetCSVUpload.propTypes = {
  setCsvFileName: PropTypes.func,
  updateBudget: PropTypes.func,
}
