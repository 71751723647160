import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'alertTemplates'

/** Initial state of the reducer */
const initialState = {
  list: null,
  listMinimal: null,
  listPaginated: [],
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.GET_ALERT_TEMPLATES]: (state, { list }) => ({ ...state, list }),
  [ACTIONS.GET_ALERT_TEMPLATES_MINIMAL]: (state, { listMinimal }) => ({
    ...state,
    listMinimal,
  }),
  [ACTIONS.GET_ALERT_TEMPLATES_PAGINATED]: (state, { listPaginated }) => ({
    ...state,
    listPaginated,
  }),
}

export default { name, initialState, reduce }
