import React, { useState, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate, redirect } from 'react-router-dom'
import { useStore } from 'store'

import useSession from 'modules/session'

/* Components */
import { Dropdown } from 'components/dropdown'
import Loader from 'components/loader'
import Button from 'components/button'
import { CheckboxNoHooks } from 'components/checkbox'
import { useSocket } from 'components/utils/socket'

/* Actions */
import { getAlertNames } from 'modules/alerts/actions'
import { getLinkedAccounts } from 'modules/accounts/actions'
import { run } from 'modules/e2e-alert-executor/actions'
import { getNotificationsLazy } from 'modules/notifications/actions'

/* Constants */
import { NOT_FOUND_ROUTE } from 'routes'

import 'modules/platform-settings/global-settings.scss'
import { socket } from '@decision-sciences/qontrol-common'

const { NOTIFICATIONS } = socket

const isLocal = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

const E2eAlertExecutor = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const [alerts, setAlerts] = useState(null)

  /* All linked accounts */
  const [accounts, setAccounts] = useState()

  /* Alert to run test on */
  const [selectedAlert, setSelectedAlert] = useState()

  /* Account to run test on */
  const [selectedAccounts, setSelectedAccounts] = useState([])

  /* Whether to send conventional notificaitons: slack/email/sms */
  const [sendConventionalNotifications, setSendConventionalNotifications] =
    useState(false)

  /* A test is created for the currelt configuration */
  const [testRunning, setTestRunning] = useState(false)

  const location = useLocation()
  const {
    result,
    accountId: resultAccountId,
    alert: resultAlert,
    success,
  } = location?.state || {
    result: null,
    accountId: null,
    alert: null,
    success: null,
  }

  const {
    state: {
      notifications: { userNotifications },
      companies: { currentCompany },
      global: {
        environment: { NR_ALERT_NOTIFICATIONS_TO_LOAD_AT_ONCE },
      },
    },
    dispatch,
  } = useStore()
  const [, user] = useSession()

  /* Redirect non-superadmin users to 404 page */
  useEffect(() => {
    if (user && !user.isSuperAdmin) {
      navigate(NOT_FOUND_ROUTE)
    }
  }, [user])

  /* Populate alerts if they are missing */
  useEffect(() => {
    if (!alerts) {
      setLoading(true)
      getAlertNames(null)
        .then(setAlerts)
        .catch(console.error)
        .finally(() => setLoading(false))
    }
  }, [alerts])

  /* Fetch all linked accounts along with their companies' names */
  useEffect(() => {
    if (!accounts) {
      setLoading(true)
      getLinkedAccounts(dispatch)
        .then(setAccounts)
        .finally(() => setLoading(false))
    }
  }, [accounts])

  const testRunningForCurrentConfiguration = useMemo(
    () =>
      testRunning &&
      testRunning === `${selectedAlert}-${selectedAccounts.join('-')}`,
    [testRunning, selectedAlert, JSON.stringify(selectedAccounts)]
  )

  /* Reset the page state so that another test culd be run */
  const reset = () => {
    if (location?.state?.result) {
      setSelectedAlert(null)
      setSelectedAccounts([])
      setTestRunning(false)

      redirect()
    }
  }

  /* Run test */
  const runTest = () => {
    setLoading(true)

    run(
      dispatch,
      selectedAlert,
      selectedAccounts,
      !sendConventionalNotifications
    )
      .then((result) => {
        setTestRunning(`${selectedAlert}-${selectedAccounts.join('-')}`)
      })
      .finally(setLoading(false))
  }

  const formattedResult = useMemo(() => {
    if (result) {
      const { failed, success } = result?.alertExecResults || {}

      if (!failed && !success) {
        return <p>Alert executed with no data</p>
      }

      return (
        <div>
          <p>
            <b>Success</b>
            <br />
            {success.map((row, idx) => (
              <p key={idx}>{row}</p>
            ))}
          </p>

          <br />
          <p>
            <b>Failed</b>
            <br />
            {failed.map((row, idx) => (
              <p key={idx}>{row}</p>
            ))}
          </p>
        </div>
      )
    }
  }, [result])

  const socket = useSocket({ room: [user._id] })
  useEffect(() => {
    if (!socket) {
      return
    }

    // Signal for end-to-end alert executor related notifications
    socket.on(NOTIFICATIONS.e2eAlertExecutionDone.receive, {}, () => {
      // silently refetch the notifications
      getNotificationsLazy(
        dispatch,
        user._id,
        {
          selectedClient: userNotifications.showCompanySpecificNotifications
            ? currentCompany?._id
            : null,
          viewUnreadOnly: userNotifications.viewUnreadOnly,
          showAlertNotifications: userNotifications.showAlertNotifications,
          showBriefNotifications: userNotifications.showBriefNotifications,
        },
        parseInt(NR_ALERT_NOTIFICATIONS_TO_LOAD_AT_ONCE, 10)
      )
    })

    return () =>
      socket?.removeAllListeners(NOTIFICATIONS.e2eAlertExecutionDone.receive)
  }, [
    socket,
    JSON.stringify(userNotifications),
    user?._id,
    currentCompany?._id,
    NR_ALERT_NOTIFICATIONS_TO_LOAD_AT_ONCE,
  ])

  return (
    <div className="global-settings">
      <Helmet>
        <title>E2E Alert Executor</title>
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <>
          {result ? (
            <>
              <h3 className="heading">
                End to end Alert Executor - Results for <br />
                Alert: {resultAlert.name} <br /> Account: {resultAccountId}{' '}
                <br />
                Execution: {success ? 'Success' : 'Failed'}
              </h3>

              <p>
                To run another test, click the button below. You can view the
                current results by clicking on the notification that brought you
                here in the first place.
              </p>
              <Button value="Run another test" onClick={reset} />

              <p className="general-label">Results</p>
              {formattedResult}
            </>
          ) : (
            <>
              <h3 className="heading">End to end Alert Executor</h3>

              <p className="general-label">Select Alert to run test on</p>
              <Dropdown
                options={alerts?.map(({ _id: value, name, alertType }) => ({
                  label: `${name} - ${alertType}`,
                  value,
                }))}
                className="margin-top-10"
                defaultOptionText="Select Alert"
                defaultState={selectedAlert}
                onChange={(value) => {
                  setSelectedAlert(value)
                  setTestRunning(false)
                }}
              />

              <p className="general-label">Select Account to run test on</p>
              <Dropdown
                options={accounts?.map(
                  ({ externalAccountId: value, name, company }) => ({
                    label: `id: ${value} - name: ${name} - company: ${company?.name}`,
                    value,
                  })
                )}
                className="margin-top-10"
                defaultOptionText="Select Account"
                selectedItems={selectedAccounts}
                onChange={(value) => {
                  setSelectedAccounts(value)
                  setTestRunning(false)
                }}
                style={{ marginBottom: '30px' }}
                multiSelect
              />

              <CheckboxNoHooks
                label="Send conventional notifications (slack, email, sms)"
                isChecked={sendConventionalNotifications}
                hint={
                  <span>
                    In order to send conventional notifications, as configured
                    on the alert (slack/email/sms) after execution, check this.
                  </span>
                }
                onChange={() =>
                  setSendConventionalNotifications(
                    !sendConventionalNotifications
                  )
                }
              />

              {testRunningForCurrentConfiguration ? (
                <>
                  <p>
                    Test running for current configuration. To run another test,
                    change the selected Alert and Account.
                  </p>

                  {isLocal ? (
                    <p>
                      For Dev: run{' '}
                      <b>
                        <i>yarn run e2e-alert-executor</i>
                      </b>{' '}
                      to execute the created jobs
                      <Button
                        value="Rerun test for the same configuration"
                        onClick={runTest}
                        disabled={!selectedAlert || !selectedAccounts.length}
                      />
                    </p>
                  ) : null}
                </>
              ) : (
                <>
                  <p>
                    To run the test, click the button below. After the execution
                    is done, you will receive a notification. Clicking on it
                    would bring you to the results page.
                  </p>
                  <Button
                    value="Run test"
                    onClick={runTest}
                    disabled={!selectedAlert || !selectedAccounts.length}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default E2eAlertExecutor
