import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { campaignExclusions } from '@decision-sciences/qontrol-common'

import InputGroup from 'components/input-group'
import { Dropdown } from 'components/dropdown'
import InputText from 'components/input'
import { CheckboxNoHooks } from 'components/checkbox'
import DropdownWithSubsections from 'components/dropdown-with-subsections'

import { ReactComponent as IconRemove } from 'assets/icon_table_remove.svg'
import { ReactComponent as PlusIcon } from 'assets/icon_plus_blue.svg'
import cx from 'classnames'

import { PROPERTY_TYPES, ALL_ENTITIES_SELECTED } from '../../../../constants'
import { getChannelTypesByPublisher } from './utils'
const {
  CAMPAIGN_EXCLUSION_RULE_PROPERTIES,
  CAMPAIGN_EXCLUSIONS_OPERATION_TYPES,
} = campaignExclusions

/**
 * Renders the rule input group row
 * @param {Object} props
 * @param {Object} props.rule - Current displayed rule
 * @param {Function} props.onRuleChanged - Method to be called when any property is changed on the rule
 * @param {Function} props.onRemoveClicked - Method to be called when the remove button is clicked
 * @param {Function} props.onAddClicked - Method to be called when the add button is clicked
 * @param {number} props.numberOfRules - The number of rules under the current exclusion
 * @param {object} props.publisher - The publisher under which the rule input group is rendered
 * @param {object} props.publishers - All the publishers we have for the current client
 * @param {string} props.accountsByPublisher - A map with accounts by publisher
 * @param {object} props.fields - The fields for the input group property dropdown
 */
const RuleInputGroup = ({
  rule,
  onRuleChanged,
  onRemoveClicked,
  onAddClicked,
  numberOfRules,
  publisher,
  publishers,
  accountsByPublisher,
  fields,
}) => {
  // When the fields are changed, we construct a map with all the channel types dropdown options by publisher
  const channelTypesByPublisher = useMemo(
    () => getChannelTypesByPublisher(fields),
    [JSON.stringify(fields)]
  )

  const onAllOptionsSelected = (isAllSelected) => {
    onRuleChanged({
      ...rule,
      value: isAllSelected ? ALL_ENTITIES_SELECTED.ACCOUNTS : [],
    })
  }

  const onAllChannelTypesOptionsSelected = (isAllSelected) => {
    onRuleChanged({
      ...rule,
      value: isAllSelected ? ALL_ENTITIES_SELECTED.CHANNEL_TYPES : [],
    })
  }

  const onPropertyChanged = (value, property) => {
    const updatedRule = {
      ...rule,
      [property]: value,
    }

    // When the value for property field changes, we reset the other fields
    if (property === 'property') {
      updatedRule.operation = ''
      updatedRule.value = null
    }

    onRuleChanged(updatedRule)
  }

  const onAccountsPropertyChanged = (value, accountsOptions) => {
    let updatedValue = value

    if (
      updatedValue &&
      Array.isArray(updatedValue) &&
      updatedValue.includes(ALL_ENTITIES_SELECTED.ACCOUNTS)
    ) {
      updatedValue.splice(
        updatedValue.indexOf(ALL_ENTITIES_SELECTED.ACCOUNTS),
        1
      )
    } else if (accountsOptions && updatedValue && Array.isArray(updatedValue)) {
      updatedValue =
        accountsOptions.length === updatedValue.length
          ? ALL_ENTITIES_SELECTED.ACCOUNTS
          : updatedValue
    }

    onPropertyChanged(updatedValue, 'value')
  }

  const onChannelTypesPropertyChanged = (value, channelTypesOptions) => {
    let updatedValue = value

    if (
      updatedValue &&
      Array.isArray(updatedValue) &&
      updatedValue.includes(ALL_ENTITIES_SELECTED.CHANNEL_TYPES)
    ) {
      updatedValue.splice(
        updatedValue.indexOf(ALL_ENTITIES_SELECTED.CHANNEL_TYPES),
        1
      )
    } else if (
      channelTypesOptions &&
      updatedValue &&
      Array.isArray(updatedValue)
    ) {
      updatedValue =
        channelTypesOptions.length === updatedValue.length
          ? ALL_ENTITIES_SELECTED.CHANNEL_TYPES
          : updatedValue
    }

    onPropertyChanged(updatedValue, 'value')
  }

  const getChannelTypesForGlobal = () => {
    return publishers.reduce((collector, publisher) => {
      if (
        publisher.id !== 'GLOBAL' &&
        !publisher.disabled &&
        channelTypesByPublisher[publisher.id]
      ) {
        channelTypesByPublisher[publisher.id].forEach((channelType) => {
          if (!collector.some((c) => channelType.value === c.value)) {
            collector.push({ ...channelType })
          }
        })
      }

      return collector
    }, [])
  }

  const optionRenderer = (option) => {
    const Icon = option.Icon

    return (
      <div className="display-flex">
        <div className="margin-y-auto">
          <CheckboxNoHooks
            reversed
            isChecked={
              rule.value?.indexOf(option.value) > -1 ||
              rule.value === ALL_ENTITIES_SELECTED.ACCOUNTS ||
              rule.value === ALL_ENTITIES_SELECTED.CHANNEL_TYPES
            }
          />
        </div>
        <div className="margin-y-auto margin-left-10">
          {Icon ? <Icon /> : null}
        </div>
        <div className="margin-left-10">
          {Icon ? (
            <div>
              <div>{option.label}</div>
              <div className="margin-top-10">{option.value}</div>
            </div>
          ) : (
            <div className="margin-top-5">{option.value}</div>
          )}
        </div>
      </div>
    )
  }

  const getOptions = () => {
    const propertyOptions = [...PROPERTY_TYPES]
    propertyOptions[0].disabled =
      !accountsByPublisher[publisher.id.toUpperCase()]

    const options = [
      {
        render: (
          <Dropdown
            options={propertyOptions}
            onChange={(v) => onPropertyChanged(v, 'property')}
            defaultState={rule.property}
            disableSearch={true}
          />
        ),
        width: 200,
      },
    ]

    const isErrorCondition = (ruleValue) => {
      if (
        (ruleValue && Array.isArray(ruleValue) && ruleValue.length === 0) ||
        (ruleValue && typeof ruleValue === 'string' && ruleValue === '')
      ) {
        return true
      }
      return false
    }

    if (rule.property === CAMPAIGN_EXCLUSION_RULE_PROPERTIES.CAMPAIGN_NAME) {
      if (!rule.operation) {
        onPropertyChanged(
          CAMPAIGN_EXCLUSIONS_OPERATION_TYPES[0].value,
          'operation'
        )
      }

      options.push(
        {
          render: (
            <Dropdown
              className="cursor--pointer"
              options={CAMPAIGN_EXCLUSIONS_OPERATION_TYPES}
              onChange={(v) => onPropertyChanged(v, 'operation')}
              defaultState={rule.operation}
              disableSearch={true}
            />
          ),
          width: 200,
        },
        {
          render: (
            <InputText
              textOnlyError={rule.value === '' || rule.duplicated}
              placeholder="Enter Campaign Name Criteria"
              value={rule.value}
              onChange={(v) => onPropertyChanged(v, 'value')}
            />
          ),
          error: isErrorCondition(rule.value) || rule.duplicated,
        }
      )
    } else if (
      rule.property === CAMPAIGN_EXCLUSION_RULE_PROPERTIES.ACCOUNT_ID
    ) {
      const accountsOptions = [
        ...(accountsByPublisher[publisher.id.toUpperCase()] || []),
      ]
      const areAllSelected =
        rule.value === ALL_ENTITIES_SELECTED.ACCOUNTS ||
        rule.value?.length === accountsOptions.length

      if (!rule.value) {
        onPropertyChanged(ALL_ENTITIES_SELECTED.ACCOUNTS, 'value')
      }

      options.push({
        render: (
          <DropdownWithSubsections
            textOnlyError={rule.value === '' || rule.duplicated}
            selectedItems={rule.value || []}
            placeholder="Select Account"
            options={accountsOptions}
            optionRenderer={optionRenderer}
            selectAllOptions={{
              label: 'All Accounts',
              allSelected: areAllSelected,
              onCheck: (value) => onAllOptionsSelected(value),
            }}
            overwriteSelectedText={areAllSelected ? 'All Accounts' : ''}
            onChange={(v) => onAccountsPropertyChanged(v, accountsOptions)}
            defaultState={rule.value}
            disableSearch={true}
            hideSelectAllOnSearch
          />
        ),
        width: 400,
        error: isErrorCondition(rule.value) || rule.duplicated,
      })
    } else {
      let channelTypesOptions
      if (publisher.id.toUpperCase() === 'GLOBAL') {
        channelTypesOptions = getChannelTypesForGlobal()
      } else {
        channelTypesOptions = channelTypesByPublisher[publisher.id] || []
      }

      const areAllSelected =
        rule.value === ALL_ENTITIES_SELECTED.CHANNEL_TYPES ||
        rule.value?.length === channelTypesOptions.length

      if (!rule.value) {
        onPropertyChanged(ALL_ENTITIES_SELECTED.CHANNEL_TYPES, 'value')
      }

      options.push({
        render: (
          <DropdownWithSubsections
            textOnlyError={rule.value === '' || rule.duplicated}
            selectedItems={rule.value || []}
            placeholder="Select Channel Type"
            options={channelTypesOptions}
            optionRenderer={optionRenderer}
            selectAllOptions={{
              label: 'All Channel Types',
              allSelected: areAllSelected,
              onCheck: (value) => onAllChannelTypesOptionsSelected(value),
            }}
            overwriteSelectedText={areAllSelected ? 'All Channel Types' : ''}
            onChange={(v) =>
              onChannelTypesPropertyChanged(v, channelTypesOptions)
            }
            defaultState={rule.value}
            disableSearch={true}
            hideSelectAllOnSearch
          />
        ),
        width: 250,
        error: isErrorCondition(rule.value) || rule.duplicated,
      })
    }

    return [
      ...options,
      {
        render: (
          <IconRemove
            title="Remove rule"
            className={cx('margin-auto cursor--pointer', {
              disabled: numberOfRules <= 1,
            })}
            onClick={onRemoveClicked}
          />
        ),
        width: 50,
      },
      {
        render: (
          <PlusIcon
            title="Add Rule"
            className={cx('margin-auto cursor--pointer', {
              disabled: numberOfRules >= 3,
            })}
            onClick={onAddClicked}
          />
        ),
        width: 45,
      },
    ]
  }

  return <InputGroup className="rule-input-group" options={getOptions()} />
}

export default RuleInputGroup

RuleInputGroup.propTypes = {
  rule: PropTypes.object.isRequired,
  onRuleChanged: PropTypes.func.isRequired,
  onRemoveClicked: PropTypes.func.isRequired,
  onAddClicked: PropTypes.func.isRequired,
  numberOfRules: PropTypes.number.isRequired,
  publisher: PropTypes.object.isRequired,
  publishers: PropTypes.array.isRequired,
  accountsByPublisher: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
}
