import { createBrowserHistory } from 'history'
import { NOT_FOUND_ROUTE } from 'routes'
export const history = createBrowserHistory()

let prevLocation = null
history.listen((location) => {
  if (location.pathname !== '/login' && location.pathname !== NOT_FOUND_ROUTE) {
    prevLocation = location.pathname
  }
})

export const getPrevLocation = () => prevLocation
