import { useEffect, useState } from 'react'

import {
  useMatch,
  useNavigate,
  useSearchParams,
  redirect,
} from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import isEqual from 'lodash.isequal'
import { useStore } from 'store'

import { usePendingState } from 'components/utils/custom-hooks'
import useSession from 'modules/session'

/* Utils */
import { virtualToNamingConvention } from 'modules/naming-conventions/utils'
import { getCompanySettings } from 'modules/companies/utils'

import { entityStatus, companies } from '@decision-sciences/qontrol-common'

import {
  showErrorMessage,
  showSuccessMessage,
} from 'modules/notifications/actions'
import { saveFilter } from 'modules/table-filter-sort/actions'
import {
  getCompanies,
  getCompaniesWithFilterOptions,
  restoreClient,
} from 'modules/companies/actions'

import { BUSINESS_UNIT_DEFAULT } from 'modules/business-units/constants'
import { defaultFilterSortConfig } from 'modules/table-filter-sort/constants'
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'

const { ENTITY_STATUS_FOR_FILTERS } = entityStatus
const { COMPANY_FILTERS } = companies

export const useRestoreClient = ({
  tableContainer,
  restoreInfo,
  onSuccess,
  onError,
}) => {
  const { state, dispatch } = useStore()

  const filterSort = state.tableFilterSort.filterSort[tableContainer]
  const emptyFilters = {
    ...defaultFilterSortConfig.filterSort[tableContainer].filter,
  }

  const restoreClientHandler = async (resolve) => {
    const { clientId, isBusinessUnit } = restoreInfo
    const entityName = isBusinessUnit ? 'Business unit' : 'Client'
    try {
      const { parentCompanyClientId } = await restoreClient(clientId)
      showSuccessMessage(`${entityName} restored successfully`, dispatch)
      // Must re-fetch whole background stored company list so it contains the newly restored company
      await getCompanies(dispatch, { deleted: false })
      // Must re-fetch visible company filters which dictate the list of visible companies in the table
      try {
        if (!isEqual(filterSort.filter, emptyFilters)) {
          const { options, companyIds } = await getCompaniesWithFilterOptions(
            filterSort.filter
          )
          saveFilter(dispatch, tableContainer, {
            ...filterSort.filters,
            options,
            companyIds,
          })
        }
        onSuccess()
      } catch (err) {
        showErrorMessage(`Error when refreshing client list: ${err}`, dispatch)
      } finally {
        resolve()
        // Force refresh because states are not updated properly when :companyId changes
        if (isBusinessUnit) {
          window.location.href = `/company/${parentCompanyClientId}/business-unit/${clientId}`
        } else {
          window.location.href = `/company/${clientId}`
        }
      }
    } catch (err) {
      showErrorMessage(`${entityName} failed to restore. ${err}`, dispatch)
      onError()
      resolve()
    }
  }

  return [restoreClientHandler]
}

export const useSeeArchivedClients = ({
  tableContainer,
  setDirty,
  closeRestoreNotification,
}) => {
  const navigate = useNavigate()

  const { state, dispatch } = useStore()

  const filterSort = state.tableFilterSort.filterSort[tableContainer]

  const seeArchivedClients = async () => {
    return new Promise((resolve) => {
      setDirty(false)
      const field = COMPANY_FILTERS.STATUS
      const newFilters = { ...filterSort.filter }
      newFilters[field] = [ENTITY_STATUS_FOR_FILTERS.ARCHIVED]
      getCompaniesWithFilterOptions(newFilters)
        .then((res) => {
          if (!res.success) {
            return resolve()
          }
          const { options, companyIds } = res
          saveFilter(dispatch, tableContainer, {
            ...newFilters,
            options,
            companyIds,
          })
          closeRestoreNotification()
          navigate('/client-management')
          resolve()
        })
        .catch(resolve)
    })
  }

  return seeArchivedClients
}

/**
 * Grouped business unit pending changes functionality
 * To be used under client outlet
 * @param {Array<Object>} defaultBusinessUnits Business units the way the come from mongoose
 * @returns {Object} { businessUnitList, editBusinessUnit, initializeBusinessUnit, removeBusinessUnit, cancelBusinessUnitChanges }
 */
export const useBusinessUnits = (defaultBusinessUnits) => {
  // Pending changes of all business units under the client
  const [
    businessUnits,
    setBusinessUnits,
    pendingChanges,
    defaultBus,
    setDefaults,
    reset,
  ] = usePendingState(
    defaultBusinessUnits.reduce(
      (prev, current) => ({ ...prev, [current._id]: current }),
      {}
    )
  )

  useEffect(() => {
    setDefaults(
      defaultBusinessUnits.reduce(
        (prev, current) => ({ ...prev, [current._id]: current }),
        {}
      )
    )
  }, [JSON.stringify(defaultBusinessUnits)])

  // Simple list of all business units and their "fresh" changes
  const businessUnitList = Object.values(businessUnits).filter(
    ({ deleted }) => !deleted
  )

  const editBusinessUnit = (_id) => (updates) => {
    setBusinessUnits({ [_id]: updates })
  }

  const removeBusinessUnit = (id) => {
    const newChanges = structuredClone(pendingChanges)
    if (businessUnits[id].new) {
      delete newChanges[id]
    } else {
      setBusinessUnits({ [id]: { deleted: true } })
    }
  }

  return {
    pendingChanges,
    businessUnits,
    defaultBus,
    businessUnitList,
    setBusinessUnits,
    reset,
    editBusinessUnit,
    removeBusinessUnit,
  }
}

/**
 * Holds Current Business Unit page data
 * @param {Object} params React Params
 * @param {Object} params.businessUnitData result of useBusinessUnits
 * @param {Object} params.usersData useClientUsers
 * @param {Object} params.client Current client / company
 * @param {Function} params.showInheritClientModal Callback to show the inherit client modal
 * @returns {Object} []
 */
export const useCurrentBusinessUnit = (
  businessUnitData,
  usersData,
  client,
  showInheritClientModal
) => {
  // Ascertain whether we're on the business unit page or not
  const matchBusinessUnitRoutes = useMatch('company/:cid/business-unit/:id?/*')
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [, userData] = useSession()
  const { state } = useStore()

  const { businessUnits, businessUnitList, defaultBus } = businessUnitData

  const [anchorClientId, setAnchorClientId] = useState(null) // This tells us whether we've prepared a business unit to show or not, either found or new

  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.BUSINESS_UNIT_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })
  const hasViewAccess = useAccess({
    feature: PERMISSIONS.BUSINESS_UNIT_DATA_FORM,
    type: PERMISSION_TYPES.READ,
  })

  const idParam = matchBusinessUnitRoutes?.params?.id
  const clientIdParam = matchBusinessUnitRoutes?.params?.cid
  const isNew = idParam === 'new'

  const goBackToClient = () => {
    navigate(`/company/${clientIdParam}`, {
      state: { BYPASS_LEAVE_CONFIRM: true },
    })
  }

  const [
    businessUnit,
    setBusinessUnit,
    pendingBusinessUnitData,
    defaultBusinessUnitData,
    ,
    reset,
  ] = usePendingState(
    null,
    _buildDefaultBusinessUnit(null, userData, client.active),
    true
  )

  const displayedBusinessUnit =
    matchBusinessUnitRoutes && (isNew || !!anchorClientId) ? businessUnit : null // If we're not on a business unit route there's none selected.

  const [
    users,
    setUsers,
    pendingBusinessUnitUsers,
    ,
    ,
    resetBusinessUnitUsers,
  ] = usePendingState({})

  const userList = state.users.list.filter(({ _id }) => users[_id])

  /** Handle Route changes */
  useEffect(() => {
    // Check if client hasn't loaded yet
    if (!client || (client.new && clientIdParam !== 'new')) {
      return
    }

    // If we're on the correct business unit, we don't need to reload anything
    if (anchorClientId === idParam && displayedBusinessUnit) {
      return
    }

    // If we're not on business unit routes we ensure everything's clear and fresh
    if (!matchBusinessUnitRoutes) {
      reset({})
      setBusinessUnit(_buildDefaultBusinessUnit(null, userData, client.active))
      resetBusinessUnitUsers({})
      setAnchorClientId(null)
      return
    }

    if (!hasViewAccess) {
      return redirect('/unauthorized')
    }

    // Case for new business units
    if (idParam === 'new') {
      if (!hasCreateAccess) {
        return redirect('/unauthorized')
      }
      const inheritFrom = searchParams.get('inheritFrom')

      if (!inheritFrom) {
        showInheritClientModal()
        goBackToClient()
      }

      let inheritedData = null

      if (inheritFrom === client._id) {
        inheritedData = client
      } else {
        inheritedData = businessUnits[inheritFrom]
      }

      if (inheritedData) {
        setUsers(usersData.clientUsers[inheritedData._id] || {})
      }

      setBusinessUnit(
        _buildDefaultBusinessUnit(inheritedData, userData, client.active)
      )
      setAnchorClientId('new')

      return
    }

    const existingBusinessUnit = businessUnitList.find(
      ({ clientId }) => clientId === idParam
    )

    if (existingBusinessUnit) {
      reset({
        ...existingBusinessUnit,
        namingConventions: virtualToNamingConvention(
          existingBusinessUnit?.namingConventions
        ),
      })
      resetBusinessUnitUsers(usersData.clientUsers[existingBusinessUnit._id])
      setAnchorClientId(existingBusinessUnit.clientId)
    } else {
      setAnchorClientId(null)

      goBackToClient()
    }
  }, [idParam, defaultBus])

  return {
    businessUnit: displayedBusinessUnit,
    setBusinessUnit,
    defaultBusinessUnitData,
    pendingBusinessUnitData,
    users,
    setUsers,
    userList,
    pendingBusinessUnitUsers,
    idParam,
    clientIdParam,
  }
}

/**
 * Builder for a business unit
 * @param {Object} [base] Base business unit or client;
 * @param {Object} user User making the changes
 * @param {Boolean} [defaultActive] Default status of business unit
 * @returns {Object} A new business unit
 */
const _buildDefaultBusinessUnit = (base, user, defaultActive = false) => {
  const _id = uuidv4()

  const inheritedData = getCompanySettings({
    newBusinessUnitId: _id,
    companyToExtractDataFrom: base,
    userData: user,
  })

  return {
    _id,
    id: _id,
    active: base?.active ?? defaultActive,
    parentCompany: base && !base.parentCompany ? base._id : '',
    isNew: true,
    accounts: [],
    ...BUSINESS_UNIT_DEFAULT,
    ...inheritedData,
  }
}

export const useClientUsers = (client) => {
  const { state } = useStore()

  const [
    clientUsers,
    setClientUsers,
    pendingClientUsers,
    ,
    ,
    resetClientUsers,
  ] = usePendingState({})

  useEffect(() => {
    const clientUsers = {}

    if (!client._id) {
      return
    }

    state.users.list.forEach(({ _id, clients, name }) => {
      const foundClient = clients.find(
        ({ clientId }) => clientId === client._id
      )
      if (!foundClient) {
        return
      }

      const { clientId, businessUnits } = foundClient

      if (clientId === client._id) {
        if (!clientUsers[clientId]) {
          clientUsers[clientId] = {}
        }
        clientUsers[clientId][_id] = true
        businessUnits.forEach((buId) => {
          if (!clientUsers[buId]) {
            clientUsers[buId] = {}
          }
          clientUsers[buId][_id] = true
        })
      }
    })

    resetClientUsers(clientUsers)
  }, [client._id, state.users.list])

  const getClientUsers = (clientId) =>
    state.users.list.filter(({ _id }) => clientUsers[clientId]?.[_id])

  return {
    clientUsers,
    setClientUsers,
    pendingClientUsers,
    getClientUsers,
    resetClientUsers,
    // accountUsers,
    // setAccountUsers,
    // getAccountUsers,
    // pendingAccountChanges,
  }
}
