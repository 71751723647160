import Button from 'components/button/index'
import WarningIcon from 'components/warning-icon/index'
import { openModal, closeModal } from 'components/modal/actions'
import { emulateUser } from 'modules/session/actions'

export const openUserIsLoggedInModal = (
  dispatch,
  emulatedUserId,
  currentCompanyId
) => {
  const userIsLoggedInModalUuid = openModal(dispatch, {
    heading: 'User Session Taking Place',
    icon: <WarningIcon />,
    rightAlignButtons: true,
    contentSeparator: true,
    className: 'modal-emulate-user',
    children: (
      <div className="padding-y-20">
        <p>
          The user you are trying to emulate currently has an open session. Do
          you want to continue to emulate the user?
        </p>
        <p>Click confirm to continue or cancel to close this modal.</p>
      </div>
    ),
    button: (
      <Button
        green
        value="Confirm"
        onClick={() => {
          emulateUser(dispatch, emulatedUserId, currentCompanyId, true)
          closeModal(dispatch, userIsLoggedInModalUuid)
        }}
      />
    ),
    buttonSecondary: (
      <Button
        secondaryGray
        value="Cancel"
        onClick={() => {
          closeModal(dispatch, userIsLoggedInModalUuid)
        }}
      />
    ),
  })
}
