import React, { useState } from 'react'
import cx from 'classnames'

import Section from 'components/section/index'
import ButtonToggle from 'components/button-toggle/index'

import './style.scss'

const IconsShowcase = () => {
  const [dark, setDark] = useState(false)

  function importAll(r) {
    return r.keys().map(r)
  }

  const images = new Set(
    importAll(require.context('../../assets', false, /\.(png|jpe?g|svg)$/))
  )

  const onClick = (src) => {
    const items = src.split('/')
    const lastItem = items[items.length - 1]
    if (lastItem) {
      navigator.clipboard.writeText(lastItem.split('.')[0])
    }
  }

  return (
    <Section
      className="margin-top-20"
      header={
        <div className="icons-showcase__header">
          <h3 className="generic-heading generic-heading--no-margin">
            Click an icon to copy its name
          </h3>
          <div className="icons-showcase__header__buttons">
            <ButtonToggle
              selected={!dark}
              onClick={() => setDark(false)}
              label="Light Background"
            />
            <ButtonToggle
              selected={dark}
              onClick={() => setDark(true)}
              label="Dark Background"
            />
          </div>
        </div>
      }
    >
      <div className={cx('icons-showcase', { ['icons-showcase--dark']: dark })}>
        {[...images].map((src) => (
          <div
            key={src}
            className="icons-showcase__icon"
            style={{
              backgroundImage: `url(${src})`,
            }}
            onClick={() => onClick(src)}
          />
        ))}
      </div>
    </Section>
  )
}

export default IconsShowcase
