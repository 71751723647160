import { useEffect } from 'react'
import { useStore } from 'store'
import Cookies from 'js-cookie'
import { config } from '@decision-sciences/qontrol-common'
import { getUserData, showEmulationError } from './actions'

const { EMULATE_COOKIE_NAME } = config

let initialized = false
const FETCH_DATA_INTERVAL = 2 * 60 * 1000 // 2 minutes

/** User session management */
const Session = () => {
  const { state, dispatch } = useStore()

  const {
    session: { isLoggedIn, userData, timeLeft },
  } = state

  /** Fetch user data at a specific interval */
  useEffect(() => {
    if (!initialized) {
      initialized = true
      // Get data
      isLoggedIn && getUserData(dispatch)

      // Ping every now and then and fetch data
      const interval = setInterval(() => {
        isLoggedIn && getUserData(dispatch)
      }, FETCH_DATA_INTERVAL)
      return () => clearInterval(interval)
    }
  }, [dispatch, isLoggedIn])

  useEffect(() => {
    if (userData) {
      const isEmulated = !!Cookies.get(EMULATE_COOKIE_NAME)
      if (isEmulated && !userData.emulatedBy) {
        Cookies.remove(EMULATE_COOKIE_NAME)
        showEmulationError(dispatch)
      }
    }
  }, [userData])

  return [isLoggedIn, userData, timeLeft]
}

export default Session

