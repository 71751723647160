import { ACTIONS as SESSION_ACTIONS } from 'modules/session/actions'
import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'teams'

/** Initial state of the reducer */
const initialState = {
  list: null,
  team: null,
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.GET_TEAMS]: (state, { teams }) => {
    return { ...state, list: teams }
  },
  [ACTIONS.GET_TEAM_BY_KEY]: (state, { team }) => {
    return { ...state, team }
  },
  [SESSION_ACTIONS.LOGOUT]: () => {
    return { ...initialState }
  },
}

export default { name, initialState, reduce }
