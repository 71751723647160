import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Loader from 'components/loader/index'
import { useTooltip } from 'components/tooltip'

import './style.scss'

/**
 * Renders a button
 * @param {Object} params React params
 * @param {String | Node} [params.value] Button label or content (can be node element)
 * @param {Function} params.onClick Button onClick handler (triggers on clicking button)
 * @param {String} [params.className] Extra className(s) to add
 * @param {Function} [params.onEnterKeyPressed] Function handler that gets triggered on pressing enter
 * @param {Number} [params.tabIndex] TabIndex
 * @param {String} [params.ariaLabel] Text that gets played by screen readers when focusing button.
 * @param {Boolean} [params.secondary] The button style is hollow with border
 * @param {Boolean} [params.secondaryRed] The button style is hollow with red border
 * @param {Boolean} [params.secondaryOrange] The button style is hollow with orange border
 * @param {Boolean} [params.secondaryGreen] The button style is hollow with green border
 * @param {Boolean} [params.secondaryGray] The button background is grey with white text
 * @param {Boolean} [params.secondaryGrayEmpty] The button style is hollow with grey border and gray text
 * @param {Boolean} [params.secondaryLiliac] The button style is hollow with liliac border and liliac text
 * @param {Boolean} [params.green] The button background is green
 * @param {Boolean} [params.big] The button is big
 * @param {Boolean} [params.small] The button is small
 * @param {Boolean} [params.disabled] The button is disabled
 * @param {Boolean} [params.red] The button background is red
 * @param {Boolean} [params.orange] The button background is orange
 * @param {Boolean} [params.liliac] The button background is liliac
 * @param {Boolean} [params.isIconButton] If isIconButton is true, value expects an image src; Style is 40px / 40px with icon in the middle.
 * @param {Boolean} [params.loading] If loading, shows a spinner to the right of the button value
 * @param {Node} [params.tooltip] Tooltip to show when hovering button
 * @param {Boolean} [params.compact = false] Flag to render the {@link params.value} as a small label
 * @param {Boolean} [params.dark = false] Flag to enable or disable dark mode
 * @param {Boolean} [params.round = false] Flag to enable or disable rounded borders
 * @param {String} [params.dataTestId] Custom ID used in tests
 */
function Button({
  value = '',
  onClick,
  disabled,
  className,
  onEnterKeyPressed,
  tabIndex,
  ariaLabel,
  secondary,
  secondaryRed,
  secondaryOrange,
  secondaryGray,
  secondaryGrayEmpty,
  secondaryGreen,
  secondaryLiliac,
  green,
  big,
  small,
  isIconButton,
  red,
  tooltip,
  orange,
  liliac,
  loading,
  dataTestId,
  compact = false,
  dark = false,
  round = false,
  ...other
}) {
  const [showTooltip, hideTooltip, Tooltip] = useTooltip(tooltip)

  const enterPressed = (ev) => {
    const code = ev.keyCode || ev.which
    if (code === 13) {
      onEnterKeyPressed()
    }
  }

  const buttonClass = classnames('button align-center', className, {
    'button--compact': compact,
    'button--dark': dark,
    'button--secondary': secondary,
    'button--secondary-red': secondaryRed,
    'button--secondary-orange': secondaryOrange,
    'button--secondary-green': secondaryGreen,
    'button--secondary-gray': secondaryGrayEmpty,
    'button--secondary-liliac': secondaryLiliac,
    'button--big': big,
    'button--small': small,
    'button--green': green,
    'button--icon': isIconButton,
    'button--red': red,
    'button--orange': orange,
    'button--gray': secondaryGray,
    'button--liliac': liliac,
    'button--loading': loading,
    'button--round': round,
  })

  return (
    <>
      <Tooltip />
      <button
        data-testid={dataTestId || 'button'}
        data-cy={dataTestId}
        onClick={!disabled ? onClick : () => {}}
        disabled={disabled ? 'disabled' : undefined}
        onKeyUp={(ev) => enterPressed(ev)}
        className={buttonClass}
        tabIndex={tabIndex || '0'}
        aria-label={ariaLabel}
        type="button"
        {...other}
        onMouseOver={showTooltip}
        onMouseOut={hideTooltip}
      >
        <span
          className={classnames({
            button__value: !small,
            'button__value--compact': compact,
          })}
        >
          {isIconButton && typeof value === 'string' ? (
            <img alt="Icon" src={value} />
          ) : (
            value
          )}
        </span>
        {loading && <Loader size={35} />}
      </button>
    </>
  )
}

Button.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  tabIndex: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.any,
  onEnterKeyPressed: PropTypes.func,
  ariaLabel: PropTypes.string,
  secondary: PropTypes.bool,
  secondaryRed: PropTypes.bool,
  secondaryOrange: PropTypes.bool,
  secondaryGreen: PropTypes.bool,
  secondaryGray: PropTypes.bool,
  secondaryGrayEmpty: PropTypes.bool,
  secondaryLiliac: PropTypes.bool,
  big: PropTypes.bool,
  small: PropTypes.bool,
  green: PropTypes.bool,
  isIconButton: PropTypes.bool,
  red: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  orange: PropTypes.bool,
  liliac: PropTypes.bool,
  loading: PropTypes.bool,
  dataTestId: PropTypes.string,
  compact: PropTypes.bool,
  dark: PropTypes.bool,
  round: PropTypes.bool,
}

export default Button
