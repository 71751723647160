import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { CheckboxNoHooks } from 'components/checkbox'
import { AccountIcon } from 'components/account-icon'
import Tooltip from 'components/tooltip'

import './index.scss'

/**
 * Renders a bar with selectable icons based on the given publishers
 * @param {Object} props - Component's props
 * @param {Object} props.publishers - The publishers (accounts and maybe global) displayed
 * @param {Function} props.onSelectionChanged - Method to be called when the selection is changed
 * @param {String} [props.label] - Option label to be displayed above the bar
 * @param {String} [props.selectAllButton] - render seelct all
 */
const PublisherSelection = ({
  publishers,
  onSelectionChanged,
  label = '',
  selectAllButton = true,
}) => {
  const [tooltipContent, setTooltipContent] = useState('')
  const isGlobalPublisher = (publisher) => {
    return publisher.id === 'GLOBAL'
  }

  const hasEnabledPublisher = publishers.some(
    (p) => !p.disabled && !isGlobalPublisher(p)
  )

  const areAllSelected =
    publishers.every((p) => p.selected || isGlobalPublisher(p) || p.disabled) &&
    hasEnabledPublisher

  const onPublisherClicked = (clickedPublisher) => {
    if (clickedPublisher.disabled) {
      return
    }

    // Whenever a custom publisher is selected, the global publisher option must be unselected.
    // And whenever the global publisher is selected, all the custom publishers must be unselected.

    const updatedPublishers = publishers.map((publisher) => {
      if (publisher.id === clickedPublisher.id) {
        // Toggle selection for clicked publisher no matter if it's global or not.
        return { ...publisher, selected: !publisher.selected }
      } else if (
        !isGlobalPublisher(clickedPublisher) &&
        isGlobalPublisher(publisher) &&
        publisher.selected === true
      ) {
        // When the clicked publisher is not the global one, then mark the global publisher as unselected.
        return { ...publisher, selected: false }
      } else if (
        isGlobalPublisher(clickedPublisher) &&
        publisher.selected === true
      ) {
        // When the clicked publisher is the global one, then mark any other publisher as unselected.
        return { ...publisher, selected: false }
      } else {
        // When the clicked publisher is not the global one and not the current publisher in the loop then we keep it as it is.
        // Ex: Facebook was selected, we select Google, then we'll keep any other publisher as it was - no need to change them.
        return { ...publisher }
      }
    })

    onSelectionChanged(updatedPublishers)
  }

  const onSelectAllClicked = () => {
    onSelectionChanged(
      publishers.map((publisher) => {
        if (!isGlobalPublisher(publisher)) {
          // Toggle all specific publishers
          return {
            ...publisher,
            selected: !areAllSelected && !publisher.disabled,
          }
        } else if (isGlobalPublisher(publisher)) {
          // Set global publisher to false
          return { ...publisher, selected: false }
        } else {
          return { ...publisher }
        }
      })
    )
  }

  const _renderPublisher = (publisher) => {
    const className = cx('publisher-selection__publishers__element', {
      selected: !publisher.disabled && publisher.selected,
      'publisher-selection__publishers__element--disabled': publisher.disabled,
    })

    return (
      <div
        key={publisher.id}
        onClick={() => onPublisherClicked(publisher)}
        className={className}
        onMouseOver={() => setTooltipContent(publisher.name)}
        onMouseLeave={() => setTooltipContent('')}
      >
        <AccountIcon accountType={publisher.id} alt={publisher?.name} />
        <Tooltip
          content={tooltipContent}
          show={!!tooltipContent && !publisher.disabled}
        />
      </div>
    )
  }

  const _renderSelectAllButton = () => {
    const tooltip = !hasEnabledPublisher
      ? 'No publishers available to be selected.'
      : ''

    return (
      <CheckboxNoHooks
        defaultValue={areAllSelected}
        label="SELECT ALL PUBLISHERS"
        disabled={!hasEnabledPublisher}
        tooltip={tooltip}
        onChange={onSelectAllClicked}
        reversed={true}
      />
    )
  }

  return (
    <div className="publisher-selection">
      <>{label ? <div className="general-label">{label}</div> : null}</>
      <div className="publisher-selection__publishers">
        {publishers.map(_renderPublisher)}

        <div className="publisher-selection__publishers__all-action">
          {selectAllButton ? _renderSelectAllButton() : null}
        </div>
      </div>
    </div>
  )
}

export default PublisherSelection

PublisherSelection.propTypes = {
  label: PropTypes.string,
  onSelectionChanged: PropTypes.func,
  publishers: PropTypes.array.isRequired,
  selectAllButton: PropTypes.bool,
}
