import React, { useState, useEffect } from 'react'
import { useStore } from 'store'
import PropTypes from 'prop-types'
import { useNavigate, redirect } from 'react-router-dom'

import { utils, metrics } from '@decision-sciences/qontrol-common'

import Input from 'components/input'
import StickyFooter from 'components/sticky-footer'
import { Dropdown } from 'components/dropdown'
import { CheckboxNoHooks } from 'components/checkbox/index'

import { FIELD_TYPES, validate } from 'components/validator'
import { showErrorMessage } from 'modules/notifications/actions'
import { createUpdateAlertThreshold } from 'modules/alert-thresholds/actions'
import { useEffectOnUpdate } from 'components/utils/custom-hooks'

const {
  DAILY_PERFORMANCE_REFERENCE_SUPPORTED_METRICS,
  DAILY_PERFORMANCE_REFERENCE_SUPPORTED_CONVERSION_METRICS,
} = metrics
const { stringToID } = utils.string

const ALERT_THRESHOLDS_EMPTY_STATE = {
  name: '',
  key: '',
  value: '',
  metric: DAILY_PERFORMANCE_REFERENCE_SUPPORTED_METRICS[0],
  percentage: false,
}

/**
 * Create / Edit Alert Thresholds
 * @param {Object} options Options object
 * @param {Object} [options.defaultState] Pre-existing alert threshold settings
 * @param {Boolean} [options.isNew] True if the alert threshold is new.
 * @param {Function} options.setDirty On changes made on the Form
 * @param {Function} options.onDelete Called on clicking attempting to delete alert threshold
 * @param {Boolean} [options.hasEditAccess]
 * @param {Boolean} [options.hasCreateAccess]
 * @returns {React.Component}
 */
const AlertThresholdsForm = ({
  defaultState = ALERT_THRESHOLDS_EMPTY_STATE,
  isNew = true,
  onDelete,
  setDirty,
  hasEditAccess,
  hasCreateAccess,
}) => {
  const [state, setState] = useState(defaultState)
  const [customKey, setCustomKey] = useState(
    !isNew &&
      defaultState.key !== stringToID(defaultState.name, null, '_threshold')
  )
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { dispatch } = useStore()

  /** Check for permissions */
  useEffect(() => {
    if ((isNew && !hasCreateAccess) || (!isNew && !hasEditAccess)) {
      return redirect('/unauthorized')
    }
  }, [hasEditAccess, hasCreateAccess])

  useEffectOnUpdate(() => {
    const nameToKey = state.name
      ? stringToID(state.name, null, '_threshold')
      : ''
    if (!customKey && state.key !== nameToKey) {
      editField('key', nameToKey)
    }
  }, [customKey, state.name])

  const editField = (field, value) => {
    setDirty(true)
    setState((state) => ({ ...state, [field]: value }))
    setErrors({ ...errors, [field]: null })
  }

  const onSave = (e) => {
    e.preventDefault()
    // Validate
    setLoading(true)
    const [isValid, errors] = validate(ERROR_MAP, state)

    if (!isValid) {
      setLoading(false)
      setErrors(errors)
      return
    }

    createUpdateAlertThreshold(dispatch, state)
      .then(() => {
        setDirty(false)
        setTimeout(() => {
          navigate('/alert-thresholds', {
            state: { clearFilters: true },
          })
        }, 100)
      })
      .catch((error) => {
        let errorToDisplay = error
        if (typeof error === 'string') {
          setErrors({ ...errors, general: error.toString() })
        } else {
          setErrors({ ...errors, ...error })
          errorToDisplay = JSON.stringify(error)
        }

        showErrorMessage(
          `Could not save ${state.name} due to error ${errorToDisplay}`,
          dispatch
        )
        setLoading(false)
      })
  }

  const footerButtons = [
    {
      value: isNew ? 'Save Alert Threshold' : 'Save Changes',
      onClick: onSave,
    },
    {
      value: 'Cancel',
      onClick: () => navigate('/alert-thresholds'),
      secondaryGray: true,
    },
  ]
  if (onDelete) {
    footerButtons.push({
      value: 'Delete Alert Threshold',
      onClick: () => onDelete(defaultState),
      type: 'secondaryRed',
      renderCondition: defaultState._id && !state.templates?.length,
    })
  }

  return (
    <>
      <form
        onSubmit={onSave}
        className="form alert-thresholds form__section__body"
      >
        <div className="form__row">
          <Input
            label="Alert Threshold Name"
            placeholder={'Enter Threshold Name'}
            value={state.name}
            onChange={(name) => {
              editField('name', name)
            }}
            error={errors.name}
            disabled={loading}
            className="form__full input-wrapper--uppercase"
          />
          <Input
            label="Alert Threshold Key"
            placeholder="Key"
            value={state.key}
            onChange={(key) => {
              setCustomKey(!!key)
              editField('key', key)
            }}
            error={errors.key}
            disabled={loading}
            className="form__full input-wrapper--uppercase"
          />
        </div>
        <div className="form__row">
          <Input
            label="Default Value"
            placeholder={'Enter Default Value'}
            value={state.value}
            onChange={(value) => {
              editField('value', value)
            }}
            type="budget"
            error={errors.value}
            disabled={loading}
            className="form__half input-wrapper--uppercase"
          />
          <Dropdown
            label="Metric type"
            deselectLabel="-"
            defaultOptionText="Metric type"
            options={[
              ...DAILY_PERFORMANCE_REFERENCE_SUPPORTED_METRICS,
              ...DAILY_PERFORMANCE_REFERENCE_SUPPORTED_CONVERSION_METRICS,
            ].map((value) => ({ value, label: value }))}
            onChange={(metric) => editField('metric', metric)}
            defaultState={state.metric}
            className="form__half input-wrapper--uppercase"
          />
        </div>
        <div className="form__row form__half">
          <CheckboxNoHooks
            label="Is percentage threshold"
            isChecked={state.percentage}
            onChange={() => editField('percentage', !state.percentage)}
          />
        </div>

        {errors.general ? <div className="error">{errors.general}</div> : null}
      </form>
      <StickyFooter buttons={footerButtons} />
    </>
  )
}

const ERROR_MAP = {
  key: [FIELD_TYPES.REQUIRED, FIELD_TYPES.ID],
  name: FIELD_TYPES.REQUIRED,
  value: FIELD_TYPES.REQUIRED,
}

AlertThresholdsForm.propTypes = {
  defaultState: PropTypes.object,
  isNew: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
  hasEditAccess: PropTypes.bool,
  hasCreateAccess: PropTypes.bool,
}

export default AlertThresholdsForm
