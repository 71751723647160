import Api from 'easy-fetch-api'
import { OBJECT_STORE_URLS } from './index'

export const getClientCampaigns = (clientId, signal) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/get-client-campaigns/${clientId}`,
      signal,
    }).then((result) => {
      if (!result) {
        return reject()
      }
      if (!result.success) {
        reject(result?.error || 'Error getting campaigns for client.')
      } else {
        resolve(result.data)
      }
    })
  })
}

export const getAds = (clientId, signal, campaignId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/get-ads/${campaignId}`,
      signal,
    }).then((result) => {
      if (!result) {
        return reject()
      }
      if (!result.success) {
        reject(result.error || `Error getting ads for client ${clientId}.`)
      } else {
        resolve(result.data)
      }
    })
  })
}

export const getPlatforms = (clientId, signal) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/get-available-platforms`,
      signal,
    }).then((result) => {
      if (!result) {
        return reject()
      }
      if (!result.success) {
        reject(
          result.error ||
            `Error getting platforms for facebook accounts from snowflake.`
        )
      } else {
        resolve(result.data)
      }
    })
  })
}

export const getDevices = (clientId, signal) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/get-available-devices`,
      signal,
    }).then((result) => {
      if (!result) {
        return reject()
      }
      if (!result.success) {
        reject(
          result.error ||
            `Error getting devices for facebook accounts from snowflake.`
        )
      } else {
        resolve(result.data)
      }
    })
  })
}

export const getFormats = (clientId, signal) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/get-available-formats`,
      signal,
    }).then((result) => {
      if (!result) {
        return reject()
      }
      if (!result.success) {
        reject(
          result.error ||
            `Error getting formats for facebook accounts from snowflake.`
        )
      } else {
        resolve(result.data)
      }
    })
  })
}

export const getAdSets = (clientId, signal, campaignId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/get-adsets/${campaignId}`,
      signal,
    })
      .then((result) => {
        if (!result) {
          return reject()
        }
        if (!result.success) {
          reject(result ? result.error.error_user_msg : 'Error getting adsets')
        }
        resolve(result.data)
      })
      .catch(reject)
  })
}

/* STORE ACTIONS */
export const ACTIONS = {
  SET_CATALOG: 'facebook.setCatalog',
  SET_PRODUCT_SET: 'facebook.setProductSet',
  SET_ACCOUNT: 'facebook.setAccount',
  SET_MESSAGE_TEMPLATE: 'facebook.setMessageTemplate',
  SET_ACCOUNT_DATA: 'facebook.setAccountData',
  SET_CUSTOM_CONVERSIONS: 'facebook.setCustomConversions',
  SET_AVAILABLE_AD_LANGUAGES: 'facebook.setAvailableAdLanguages',
}

/**
 * Actions that gets custom conversions for account id
 * @param {Function} dispatch
 * @param {String} accountId Account key for which to fetch custom conversions
 */
export const getCustomConversions = (dispatch, accountId) => {
  Api.get({
    url: '/api/facebook/get-custom-conversions',
    query: { accountId },
  }).then((result) => {
    if (result.data) {
      const { data } = result
      dispatch({
        type: ACTIONS.SET_CUSTOM_CONVERSIONS,
        accountId,
        data,
      })
      return result
    } else {
      return console.error(
        (result && result.error) ||
          `Error getting custom conversions for ${accountId}`
      )
    }
  })
}
/**
 * Action which checks if catalogs exist in store for the given `accountId`.
 * If not, it fetches them and inserts into the store.
 *
 * @param {Function} dispatch Dispatch function
 * @param {String} accountId Account key for which to fetch catalogs
 * @returns {Promise<Array>}
 */
export const getCatalogs = (dispatch, accountId) => {
  return new Promise((resolve, reject) => {
    Api.get({ url: '/api/facebook/get-catalogs/', query: { accountId } })
      .then((result) => {
        if (result?.catalogs && !result?.catalogs.error) {
          const accountCatalogs = result.catalogs.sort((a, b) =>
            a.name < b.name ? -1 : 1
          )

          dispatch({
            type: ACTIONS.SET_CATALOG,
            accountId,
            accountCatalogs,
            businessId: result?.businessId,
          })
          return resolve(accountCatalogs)
        } else {
          console.error(result)
          return reject(result)
        }
      })
      .catch((error) => {
        console.error(error)
        return reject(error)
      })
  })
}

export const getAvailableLanguages = (dispatch, clientId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: '/api/facebook/get-available-languages',
      query: { clientId },
    })
      .then((result) => {
        if (result && !result.error) {
          const data = result.languages
          dispatch({
            type: ACTIONS.SET_AVAILABLE_AD_LANGUAGES,
            data,
          })
          return resolve(data)
        }
      })
      .catch((error) => {
        console.error(error)
        return reject(error)
      })
  })
}
export const setAccountData = (dispatch, accountId, data) => {
  dispatch({
    type: ACTIONS.SET_ACCOUNT_DATA,
    accountId,
    data,
  })
}

/**
 * Get Advertisable Apps and their Supported Platforms
 * The result object is mapped in the format (exception being if the "getOriginal" param is passed)
 *
 * {
 *    PLATFORM: [ // each platform has an array of APPS
 *      {
 *        appId: 'string',
 *        name: 'string',
 *        objectStoreUrl: 'string',
 *        defaultAppEvents: [],
 *        customAppEvents: []
 *      }
 *    ]
 * }
 */
export const getAdvertisableApps = (accountId, clientId, getOriginal) =>
  Api.get({
    url: '/api/facebook/advertisable-apps',
    query: { accountId, clientId },
  }).then((result) => {
    if (!result.success || !result.apps) {
      return {}
    }

    if (getOriginal) {
      return result.apps
    }

    const map = {}
    result.apps.forEach((app) => {
      app.supportedPlatforms.forEach((platform) => {
        const objectStoreUrl = app.objectStoreUrls[OBJECT_STORE_URLS[platform]]
        if (objectStoreUrl) {
          if (!map[platform]) {
            map[platform] = []
          }
          map[platform].push({
            appId: app.id,
            appName: app.name,
            objectStoreUrl,
            defaultAppEvents: app.defaultAppEvents,
            customAppEvents: app.customAppEvents,
          })
        }
      })
    })

    return map
  })

/** Get insights for a certain entity (Account/Campaign/AdSet/Ad) */
export const getInsights = ({
  entityId,
  clientId,
  startDate = new Date(),
  endDate = new Date(),
}) =>
  Api.get({
    url: `/api/facebook/get-insights/${entityId}`,
    query: {
      clientId,
      startDate: startDate.getTime(),
      endDate: endDate.getTime(),
    },
  })

/**
 * Action which checks if account can use Reach & Frequency option for buying type.
 *
 * @param {Function} dispatch Dispatch function
 * @param {String} accountId Account key for which to check option
 * @returns {Promise<Object>}
 */
export const checkReservedOption = (dispatch, accountId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/check-reserved-option`,
      query: { accountId },
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject('Error fetching data.')
        }
        dispatch({
          type: ACTIONS.SET_ACCOUNT,
          accountId,
          data: { hasReservedCampaignRights: true },
        })
        return resolve(res)
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

/**
 * Action which checks if product sets exist in store for the given `catalogId`.
 * If not, it fetches them and inserts into the store.
 *
 * @param {Function} dispatch Dispatch function
 * @param {String} catalogId Catalog ID for which to fetch product sets
 * @returns {Promise<Array>}
 */
export const getProductSets = async (dispatch, catalogId) => {
  return new Promise((resolve, reject) => {
    Api.get({ url: `/api/facebook/get-product-sets/${catalogId}` })
      .then((result) => {
        if (result.productSets && !result.productSets.error) {
          const productSets = result.productSets.data
            .map((el) => ({ id: el.id, name: el.name }))
            .sort((a, b) => (a.name < b.name ? -1 : 1))
          dispatch({ type: ACTIONS.SET_PRODUCT_SET, catalogId, productSets })
          return resolve(productSets)
        } else {
          console.error(result)
          return reject(result)
        }
      })
      .catch((error) => {
        console.error(error)
        return reject(error)
      })
  })
}

/**
 * Action for get Message Template for a page
 *
 * @param {Function} dispatch Dispatch function
 * @param {String} accountId Account key
 * @param {String} clientId Company id to get the access token from
 * @returns {Promise<Object>}
 */
export const getMessageTemplate = (dispatch, accountId, clientId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/get-message-template`,
      query: { accountId, clientId },
    })
      .then((result) => {
        if (!result.success || result.error) {
          return reject('Error fetching data.')
        }
        dispatch({
          type: ACTIONS.SET_MESSAGE_TEMPLATE,
          accountId,
          data: result.list,
        })
        return resolve(result.list)
      })
      .catch(reject)
  })
}

/**
 * Check if a given account has opted-in to ODAX
 *
 * @param {Function} dispatch Dispatch function
 * @param {String} accountId Account ID (act_XXXXXX)
 * @returns {Promise<Object>}
 */
export const checkOdaxStatus = (dispatch, accountId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/check-odax-status/${accountId}`,
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject('Error checking ODAX status')
        }
        dispatch({
          type: ACTIONS.SET_ACCOUNT,
          accountId,
          data: { odaxEnabled: res.odaxEnabled },
        })
        return resolve(res)
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

/**
 * Get auto-translations for ad based on default language and translation languages
 *
 * @param {Object} ad
 * @param {String} accountId Account ID (act_XXXXXX)
 * @param {String} clientId
 * @returns {Promise<Object>}
 */
export const getTranslation = (ad, accountId, clientId) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: `/api/facebook/odax/get-auto-translation`,
      data: { ad, accountId, clientId },
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject('error')
        }
        return resolve(res.translation)
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

/**
 * Save media from library into database
 *
 * @param {String} mediaItem Media item that needs to be save in database
 * @returns {Promise<Object>}
 */
export const saveMediaFromLibrary = (mediaItem) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: `/api/media/save-media`,
      data: { mediaItem },
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject('error')
        }
        return resolve(res.data)
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

/**
 * Get value optimization capabilities for an account
 *
 * @param {String} accountId Account ID (act_XXXXXX)
 * @param {Function} dispatch Dispatch function
 * @returns {Promise<Array<String>>}
 * @see {@link https://developers.facebook.com/docs/marketing-api/bidding/overview/bid-strategy}
 */
export const getValueOptimizationCapabilities = (accountId, dispatch) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/facebook/value-optimization-capabilities/${accountId}`,
    })
      .then((res) => {
        if (!res.success || res.error) {
          return reject('error')
        }
        dispatch({
          type: ACTIONS.SET_ACCOUNT,
          accountId,
          data: { capabilities: res.data.capabilities },
        })
        return resolve(res.data.capabilities)
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

/**
 * Get catalogs for account and cache it in store
 * @param {Function} dispatch dispatch
 * @param {String} accountId Account ID (act_XXXXXX)
 * @returns {Promise<Array>}
 */
export const getODAXCatalogs = (dispatch, accountId) => {
  return Api.get({ url: `/api/facebook/odax/get-catalogs/${accountId}` })
    .then((res) => {
      if (!res.success || res.error) {
        console.error(res)
        return []
      }
      if (res.catalogs) {
        const accountCatalogs = res.catalogs.sort((a, b) =>
          a.name < b.name ? -1 : 1
        )

        dispatch({
          type: ACTIONS.SET_CATALOG,
          accountId,
          accountCatalogs,
          businessId: res?.businessId,
        })
        return accountCatalogs
      } else {
        console.error(res)
        return []
      }
    })
    .catch((err) => {
      console.error(err)
      return []
    })
}
