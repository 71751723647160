import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { utils, budgetPacing } from '@decision-sciences/qontrol-common'
import EntitySelectorFilters, {
  FILTER_TYPE,
} from 'components/entity-selector/filters'
import EntitySelectorActions from 'components/entity-selector/action-bar'
import InformationBlock, {
  INFORMATION_BLOCK_TYPE,
} from 'components/information-block/index'
import LineDivider from 'components/line-divider/index'
import Button from 'components/button/index'

import { ReactComponent as ClearIcon } from 'assets/icon_clear_red.svg'

const { isDateAfter } = utils.date
const dateGranularities = Object.values(
  budgetPacing.BUDGET_PACING_DATE_GRANULARITY
)

const BudgetFilters = ({
  filter,
  setFilter,
  onApplyFilters,
  onClearFilters,
}) => {
  const [error, setError] = useState({})

  const handleFiltersChange = (fieldKey) => (value) => {
    const changes = { ...filter, [fieldKey]: value }

    if (fieldKey === 'budgetAmount_from' || fieldKey === 'budgetAmount_to') {
      setError({ ...error, invalidBudget: null })
    }

    if (fieldKey === 'startDate' || fieldKey === 'endDate') {
      setError({ ...error, invalidDate: null })
    }

    setFilter(changes)
  }

  const applyFilters = () => {
    let isValid = true
    const newError = {}

    if (
      filter?.budgetAmount_to &&
      filter?.budgetAmount_from &&
      filter.budgetAmount_from > filter.budgetAmount_to
    ) {
      isValid = false
      newError.invalidBudget = true
    }
    if (
      filter?.startDate &&
      filter?.endDate &&
      isDateAfter(filter?.startDate, filter?.endDate)
    ) {
      isValid = false
      newError.invalidDate = true
    }

    setError(newError)

    if (isValid) {
      onApplyFilters(filter)
    }
  }

  const clearFilters = () => {
    setError({})
    setFilter({})
    onClearFilters()
  }

  const filters = [
    {
      fieldKey: 'budgetSegment',
      label: 'Search',
      placeholder: 'Search',
      type: FILTER_TYPE.SEARCH,
    },
    {
      fieldKey: 'budgetAmount_from',
      label: 'Budget From',
      placeholder: '$',
      type: FILTER_TYPE.BUDGET,
      error: error?.invalidBudget,
    },
    {
      fieldKey: 'budgetAmount_to',
      label: 'Budget to',
      placeholder: '$',
      type: FILTER_TYPE.BUDGET,
      error: error?.invalidBudget,
    },
    {
      fieldKey: 'startDate',
      label: 'Date From',
      type: FILTER_TYPE.DATE,
      error: error?.invalidDate,
    },
    {
      fieldKey: 'endDate',
      label: 'Date To',
      type: FILTER_TYPE.DATE,
      minDate: filter.startDate,
      error: error?.invalidDate,
    },
    {
      fieldKey: 'dateGranularity',
      label: 'Date Granularity',
      type: FILTER_TYPE.DROPDOWN_MULTISELECT,
      options: dateGranularities,
      selectAll: true,
      overwriteSelectedText:
        filter.dateGranularity?.length === dateGranularities.length
          ? 'All'
          : null,
    },
  ]

  return (
    <>
      <EntitySelectorFilters
        filters={filters}
        selectedFilters={filter}
        onFiltersChange={handleFiltersChange}
      />
      {error.invalidBudget ? (
        <div className="padding-x-30 margin-bottom-16 budget-information-block">
          <InformationBlock
            type={INFORMATION_BLOCK_TYPE.ERROR}
            info="Invalid budget range"
          />
        </div>
      ) : null}
      {error.invalidDate ? (
        <div className="padding-x-30 margin-bottom-16 budget-information-block">
          <InformationBlock
            type={INFORMATION_BLOCK_TYPE.ERROR}
            info="End date cannot come before the start date"
          />
        </div>
      ) : null}
      <LineDivider />
      <EntitySelectorActions
        hasFilters
        onApplyFilters={applyFilters}
        actions={
          <Button
            onClick={clearFilters}
            className="clear-filters"
            value={
              <div className="align-row">
                <ClearIcon width={16} height={16} className="fill-red" />
                <div className="margin-left-10">Clear</div>
              </div>
            }
          />
        }
      />
    </>
  )
}
export default BudgetFilters

BudgetFilters.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
}
