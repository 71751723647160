import PropTypes from 'prop-types'

export const companyPropType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  contact: PropTypes.object,
  address: PropTypes.string,
  accounts: PropTypes.array,
  businessUnits: PropTypes.array,
})

export const userPropType = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string,
  organization: PropTypes.string,
  active: PropTypes.bool.isRequired,
  teams: PropTypes.array,
  companies: PropTypes.array,
})

export const teamPropType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isGlobal: PropTypes.bool,
  companies: PropTypes.array,
  owner: PropTypes.any,
})

export const accountPropType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  company: PropTypes.any.isRequired,
})

/**
 * Conditional Validator function for a prop based on another Prop
 * Example: conditionalProp(props, propName, componentName, 'readOnly', 'function') will validate the current prop to be a 'function' only if 'readOnly' is not specified.
 *
 * @param {Object} props - default PropTypes param that links to all props of the current Component
 * @param {String} propName - default PropTypes param that specifies the current Prop being validated
 * @param {String} componentName - default PropTypes param that specifies the current Component Name
 * @param {String} conditionalProp - prop that the current Prop depends on
 * @param {String} requiredPropType - the supposed prop type of the current Prop
 * @return {Error|null}
 */
export const conditionalProp = (
  props,
  propName,
  componentName,
  conditionalProp,
  requiredPropType
) => {
  // If the conditional prop (eg "readOnly") exists, ignore the rest of the validation method
  if (props[conditionalProp]) {
    return null
  }
  if (!props[propName]) {
    return new Error(`${propName} is required in component ${componentName}`)
  }
  const theType = typeof props[propName]
  if (theType !== requiredPropType) {
    return new Error(
      `Invalid prop type ${theType} supplied for ${propName} instead of ${requiredPropType}, in component ${componentName}`
    )
  }
}
