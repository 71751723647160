let saveSelection
let restoreSelection

if (window.getSelection) {
  saveSelection = function (containerEl) {
    containerEl.focus()
    const sel = window.getSelection()
    const childNodes = sel.anchorNode
      ? Array.from(sel.anchorNode?.parentNode?.childNodes)
      : null
    const rangeIndex = childNodes ? childNodes.indexOf(sel.anchorNode) : 0

    if (sel?.rangeCount > 0) {
      const range = sel.getRangeAt(0)
      const endOffset = childNodes[rangeIndex].data?.length || 0
      return {
        start: range.startOffset,
        end: range.startOffset + endOffset,
        rangeIndex,
      }
    }

    return {
      start: 0,
      end: 0,
      rangeIndex,
    }
  }

  restoreSelection = function (containerEl, savedSel) {
    let charIndex = 0
    const range = document.createRange()
    range.setStart(containerEl, 0)
    range.collapse(true)
    const nodeStack = [containerEl]
    let node
    let foundStart = false
    let stop = false

    while (!stop && (node = nodeStack.pop())) {
      if (node.nodeType === 3) {
        const nextCharIndex = charIndex + node.length
        if (
          !foundStart &&
          savedSel.start >= charIndex &&
          savedSel.start <= nextCharIndex
        ) {
          range.setStart(node, savedSel.start - charIndex)
          foundStart = true
        }
        if (
          foundStart &&
          savedSel.end >= charIndex &&
          savedSel.end <= nextCharIndex
        ) {
          range.setEnd(node, savedSel.end - charIndex)
          stop = true
        }
        charIndex = nextCharIndex
      } else {
        let i = node.childNodes.length
        while (i--) {
          nodeStack.push(node.childNodes[i])
        }
      }
    }

    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)
  }
} else if (document.selection) {
  saveSelection = function (containerEl) {
    const selectedTextRange = document.selection.createRange()
    const preSelectionTextRange = document.body.createTextRange()
    preSelectionTextRange.moveToElementText(containerEl)
    preSelectionTextRange.setEndPoint('EndToStart', selectedTextRange)
    const start = preSelectionTextRange.text.length

    return {
      start: start,
      end: start + selectedTextRange.text.length,
    }
  }

  restoreSelection = function (containerEl, savedSel) {
    const textRange = document.body.createTextRange()
    textRange.moveToElementText(containerEl)
    textRange.collapse(true)
    textRange.moveEnd('character', savedSel.end)
    textRange.moveStart('character', savedSel.start)
    textRange.select()
  }
}

module.exports = {
  saveSelection,
  restoreSelection,
}
