import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import cx from 'classnames'
import { utils } from '@decision-sciences/qontrol-common'

import CheckboxSelectAll, {
  useSelectAll,
} from 'components/checkbox-select-all/index'

import Tooltip from 'components/tooltip/index'
import { calculateListPosition } from 'components/utils/list-position'
import { preventScrollingBody } from 'components/utils/dom-manipulation'
import { useOnClickOutside } from 'hooks/outside-click'
import { ReactComponent as SearchIcon } from 'assets/icon_search.svg'

import ScssConstants from 'styles/shared.module.scss'
import './style.scss'

const { escapeRegExp } = utils.string

/**
 * Dropdown List component that displays only the options when opened, needs to be opened from outside.
 * @param {Object} params
 * @param {Array} params.options Can be array of Strings or Array of objects: {value: String, label: String}}
 * @param {String} [params.defaultState] default element selected
 * @param {Array} [params.selectedItems] used for multi dropdown
 * @param {Function} [params.onChange] Optional onChange callback
 * @param {Boolean} [params.disabled] Flag to not allow the dropdown list to open
 * @param {Boolean} [params.multiSelect] Multiple-select dropdown
 * @param {Boolean} [params.selectAll] Show selectAll/deselectAll
 * @param {String} [params.className] Extra className for the component
 * @param {Boolean} [params.hasSearch] Show search
 * @param {Boolean} [params.disableSearch] Flag to disable search
 * @param {String} [params.deselectLabel] Label for option that resets the input
 * @param {Boolean} [params.showAsFilters] Show selected elements as dismissible filter tiles
 * @param {Boolean} [params.autoSelectFirst] Auto-select first item on render
 * @param {Function} [params.optionRenderer] Custom renderer method for the Options
 * @param {Boolean} [params.loading] If true, display loading spinner
 * @param {Boolean} [params.listOpened] State of the dropdown list
 * @param {Function} [params.setListOpened] Function to set the state of the dropdown list
 * @param {Array} [params.sections] Array of section headings to display before each option.
 * EG ['Section 1', null, null, 'Section 2'] would display section headings before option[0] and option[3]
 * You could also use option.isSectionDivider flag instead of this array.
 * @param {Function} [params.sortOptions] Flag to sort the options
 * @param {Number} [params.optionsHeight] Max height of selectable option section in pixels
 * @param {Boolean} [params.standalone] If true, the dropdown list is always displayed and is position relative.
 * @returns {any[]}
 */
export const DropdownList = ({
  options = [],
  defaultState = '',
  selectedItems = [],
  onChange,
  disabled,
  multiSelect = false,
  selectAll,
  className,
  hasSearch,
  disableSearch = false,
  deselectLabel,
  showAsFilters,
  autoSelectFirst = false,
  optionRenderer,
  loading,
  listOpened = false,
  setListOpened = () => {},
  sections = [],
  sortOptions,
  optionsHeight,
  standalone,
  ...other
}) => {
  const optionsToRender = useMemo(
    () =>
      deselectLabel
        ? [{ label: deselectLabel, value: null }, ...options]
        : options,
    [deselectLabel, JSON.stringify(options)]
  )

  const [displayedOptions, setDisplayedOptions] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [highlightedElIndex, setHighlightedElIndex] = useState(0)
  const [optionsDisplayedUpwards, setOptionsDisplayedUpwards] = useState(null)
  const searchRef = useRef()
  const selectedRef = useRef()
  const optionsRef = useRef()
  const useObjectOptions = typeof optionsToRender?.[0] === 'object'
  const searchable =
    !disableSearch && (hasSearch || optionsToRender?.length >= 6)
  const [showTooltip, setShowTooltip] = useState({})
  /** Create a unique ID */
  const id = useCallback(`use-dropdown-${uuidv4()}`, [])

  const {
    allCheckedValue,
    toggleAll,
    toggleSelected,
    getIndividualValue,
    selectedItems: localSelectedItems,
    setSelectedItems,
  } = useSelectAll(displayedOptions, 'value', (values) =>
    onChange(Object.keys(values))
  )

  /** In case selected items are initialized / changed from the outside */
  useEffect(() => {
    const localSelected = Object.keys(localSelectedItems)
    if (
      selectedItems.length !== localSelected.length ||
      selectedItems.some((v) => !localSelected[v])
    ) {
      setSelectedItems(
        selectedItems.reduce(
          (prev, current) => ({ ...prev, [current]: true }),
          {}
        )
      )
    }
  }, [JSON.stringify(selectedItems)])

  /** On mount - if autoSelectFirst - select first option */
  useEffect(() => {
    if (autoSelectFirst && optionsToRender.length && !defaultState) {
      const firstAvailableOption = optionsToRender.find(
        (option) =>
          !useObjectOptions || (!option?.disabled && !option?.unselectable)
      )
      const value = useObjectOptions
        ? firstAvailableOption.value
        : firstAvailableOption
      if (multiSelect) {
        onChange([value])
      } else {
        onChange(value)
      }
    }
  }, [autoSelectFirst])

  const sortDisplayedOptions = (options) => {
    if (!sortOptions) {
      return options
    }

    return [
      ...options.sort((o1, o2) => {
        if (o1.sortTop) {
          return -1
        }
        if (o2.sortTop) {
          return 1
        }
        if (multiSelect) {
          const o1Selected = localSelectedItems[o1.value || o1]
          const o2Selected = localSelectedItems[o2.value || o2]
          if (o1Selected && !o2Selected) {
            return -1
          } else if (!o1Selected && o2Selected) {
            return 1
          }
        }

        if ((o1.label || o1) > (o2.label || o2)) {
          return 1
        }
        return -1
      }),
    ]
  }

  /** On selected options change - sort displayed options */
  useEffect(
    () => setDisplayedOptions(sortDisplayedOptions),
    [localSelectedItems]
  )

  useEffect(
    () =>
      setDisplayedOptions(() =>
        sortDisplayedOptions(
          displayedOptions?.length ? displayedOptions : optionsToRender
        )
      ),
    [optionsToRender]
  )

  /** On list opened/closed */
  useEffect(() => {
    if (!standalone) {
      // Prevent scrolling body
      preventScrollingBody(listOpened)
    }
  }, [listOpened])

  useEffect(() => {
    setSearchValue(searchValue)

    let newOptions = optionsToRender
    setHighlightedElIndex(defaultState && deselectLabel ? 1 : 0)
    if (searchValue?.length) {
      newOptions = optionsToRender.filter((option) => {
        if (typeof option === 'string') {
          return option.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        }

        const regExp = new RegExp(`.*${escapeRegExp(searchValue)}.*`, 'ig')
        // Check if searchValue appears in label
        if (option.label && option.label.match(regExp)) {
          return true
        }

        // Check if searchValue appears in description
        if (option.description && option.description.match(regExp)) {
          return true
        }

        return false
      })
    }
    setDisplayedOptions(() => sortDisplayedOptions(newOptions))
  }, [searchValue, listOpened])

  const toggleListOpened = (value = null) => {
    if (disabled) {
      return
    }

    if (!listOpened) {
      setSearchValue('')
      setHighlightedElIndex(defaultState && deselectLabel ? 1 : 0)
      setTimeout(() => {
        if (selectedRef.current) {
          optionsRef.current &&
            optionsRef.current.scrollTo({
              top: selectedRef.current.offsetTop,
            })
          setHighlightedElIndex(
            parseInt(selectedRef.current.getAttribute('data-index') - 1)
          )
        }

        if (searchable) {
          searchRef && searchRef.current?.focus()
        }
      })
    } else {
      setSearchValue('')
    }

    setOptionsDisplayedUpwards(
      calculateListPosition(
        listOpened,
        null,
        optionsRef.current?.clientHeight ||
          optionsHeight ||
          ScssConstants.dropdownHeight,
        optionsRef
      )
    )

    if (value !== null) {
      setListOpened(value)
    } else {
      setListOpened(!listOpened)
    }
  }

  /** Key-down handler */
  const onKeyDown = (e) => {
    const { keyCode } = e
    const isSearchFocused = document.activeElement === searchRef.current
    if (isSearchFocused) {
      return
    }

    /** Space key */
    if (keyCode === 32 && !standalone) {
      e.preventDefault()
      return toggleListOpened()
    }
    let nextIndex = 0
    /** Down arrow */
    if (keyCode === 40) {
      e.preventDefault()
      if (highlightedElIndex < displayedOptions.length - 1) {
        nextIndex = highlightedElIndex + 1
      } else {
        nextIndex = 0
      }
    }
    /** Up arrow */
    if (keyCode === 38) {
      e.preventDefault()
      if (highlightedElIndex === 0) {
        nextIndex = displayedOptions.length - 1
      } else {
        nextIndex = highlightedElIndex - 1
      }
    }
    setHighlightedElIndex(nextIndex)
    const selected =
      optionsRef.current &&
      optionsRef.current.querySelector(`[data-index='${nextIndex + 1}']`)
    if (selected && optionsRef.current) {
      if (
        selected.offsetTop + selected.clientHeight >
        optionsRef.current.scrollTop + optionsRef.current.clientHeight
      ) {
        optionsRef.current.scrollTo({ top: selected.offsetTop })
      }
      if (selected.offsetTop < optionsRef.current.scrollTop) {
        optionsRef.current.scrollTo({ top: selected.offsetTop })
      }
    }
    if (listOpened && !standalone) {
      /** Enter key */
      if (keyCode === 13) {
        if (listOpened && displayedOptions) {
          onOptionSelected(displayedOptions[highlightedElIndex || 0].value, e)
        }
        e.preventDefault()
        return toggleListOpened()
      }
    }
  }

  /** On item changed handler */
  const onOptionSelected = (value, e) => {
    if (e) {
      e.preventDefault()
      showAsFilters && e.stopPropagation()
    }

    if (!multiSelect) {
      onChange(value)
      !standalone && setListOpened(false)
    } else {
      toggleSelected(value)
    }
  }

  let selectedElement = defaultState
  if (useObjectOptions) {
    const found = optionsToRender.find((el) => el.value === selectedElement)
    if (found) {
      selectedElement = found
    }
  }

  useOnClickOutside(optionsRef, () => {
    listOpened && !standalone && toggleListOpened(false)
  })

  return (
    <div
      data-cy="dropdown-options"
      ref={optionsRef}
      data-testid="dropdown-options"
      className={cx('dropdown-list__options ', className, {
        'dropdown-list__options--open': listOpened || standalone,
        'dropdown-list__options--up': listOpened && optionsDisplayedUpwards,
        'dropdown-list__options--standalone': standalone,
        'dropdown-list__options--sorted': sortOptions,
        'dropdown-list__options--multi-select': multiSelect,
      })}
      style={optionsHeight ? { maxHeight: `${optionsHeight}px` } : {}}
      onKeyDown={onKeyDown}
    >
      {searchable && (
        <>
          <div
            className="dropdown-list__search--bottom"
            onClick={(e) => e.stopPropagation()}
          >
            <SearchIcon className="fill-light-blue search-icon" alt="" />
            <input
              placeholder="Search..."
              ref={searchRef}
              className={cx({
                'dropdown-list__search': true,
                hidden: !listOpened,
              })}
              onChange={(e) => {
                setSearchValue(e.target.value)
              }}
              value={searchValue}
            />
          </div>
          <div
            className={cx('dropdown-list__separator', {
              'dropdown-list__separator--with-deselect': deselectLabel,
            })}
          />
        </>
      )}

      {/* Select All toggles */}
      {multiSelect && selectAll && (
        <div className="dropdown-list__toggles">
          <CheckboxSelectAll
            value={allCheckedValue}
            label={typeof selectAll === 'string' ? selectAll : 'Select All'}
            onClick={() => toggleAll()}
            isBlue
            isBig
          />
        </div>
      )}

      {/* Other options */}
      {displayedOptions?.length && (listOpened || standalone)
        ? displayedOptions.map((option, index) => {
            // Skip disabled options.
            if (useObjectOptions && option.disabled) {
              return null
            }
            const value = useObjectOptions ? option.value : option
            const label = useObjectOptions ? option.label : option
            const unselectable = useObjectOptions ? option.unselectable : false
            const tooltip = option?.tooltip
            // Default Option HTML is its label
            // If we have a custom renderer method, use that
            let optionHtml = !optionRenderer
              ? label
              : optionRenderer(option, localSelectedItems)

            // For multi-select we render Checkboxes
            if (multiSelect && !optionRenderer) {
              optionHtml = (
                <CheckboxSelectAll
                  label={label}
                  disabled={option?.isDisabled}
                  value={getIndividualValue(value)}
                  isBlue
                  isBig
                />
              )
            }
            const isSelected = localSelectedItems[option.value || option]
            const key =
              typeof option === 'object'
                ? option.key || option.value || option.label
                : option

            return (
              <React.Fragment key={key.toString()}>
                {/* Displays a section label based on given positions */}
                {sections[index] ? (
                  <div className="dropdown-list__dropdown-section-label">
                    {sections[index]}
                  </div>
                ) : null}

                <div
                  tabIndex="0"
                  role="option"
                  id={`${id}-${index + 1}`}
                  data-index={index + 1}
                  ref={isSelected ? selectedRef : undefined}
                  onMouseEnter={() =>
                    tooltip &&
                    !showTooltip[key] &&
                    setShowTooltip({ [key]: true })
                  }
                  onMouseLeave={() =>
                    tooltip &&
                    showTooltip[key] &&
                    setShowTooltip({ [key]: false })
                  }
                  className={cx({
                    // Used when we want a special row that only serves as a group label for the following options
                    selected: isSelected,
                    'dropdown-list--disabled': option?.isDisabled,
                    highlighted: index === highlightedElIndex,
                    unselect: deselectLabel && index === 0 && !searchValue,
                    hidden:
                      deselectLabel ===
                        (option.label ? option.label : option) &&
                      !selectedElement &&
                      index === 0 &&
                      !searchValue,
                    hasLabel: option.sectionLabel,
                    unselectable: unselectable && !option.isSectionDivider,
                    'section-divider': option.isSectionDivider,
                    'sorted-to-top': option.sortTop,
                  })}
                  onClick={(e) => {
                    e.preventDefault()
                    !unselectable &&
                      !option.isDisabled &&
                      onOptionSelected(value, e)
                  }}
                >
                  <React.Fragment>
                    <div
                      data-cy="dropdown-option-value"
                      title={!optionRenderer ? label : undefined}
                      className={cx({
                        'text-ellipsis': !optionRenderer && !multiSelect,
                      })}
                    >
                      {optionHtml}
                    </div>
                    {option.content && <div>{option.content}</div>}
                  </React.Fragment>
                  {option.description && (
                    <div
                      className={cx('dropdown-list__description', {
                        'dropdown-list--disabled': option?.isDisabled,
                        'margin-left-30': multiSelect,
                      })}
                    >
                      {option.description}
                    </div>
                  )}
                  {option.sectionLabel && (
                    <div className="dropdown-list__section-label">
                      {option.sectionLabel}
                    </div>
                  )}
                  {tooltip && (
                    <Tooltip content={tooltip} show={showTooltip[key]} />
                  )}
                </div>
              </React.Fragment>
            )
          })
        : null}

      {optionsToRender.length && !displayedOptions.length ? (
        <div
          className="dropdown-list__no-results"
          onClick={(e) => e.stopPropagation()}
        >
          No Results
        </div>
      ) : null}
    </div>
  )
}

const propTypes = {
  options: PropTypes.array,
  defaultState: PropTypes.any, // Used for single type dropdown
  selectedItems: PropTypes.array, // Used for multi type dropdown
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  multiSelect: PropTypes.bool,
  selectAll: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  hasSearch: PropTypes.bool,
  disableSearch: PropTypes.bool,
  deselectLabel: PropTypes.string, // Label for option that resets the input
  showAsFilters: PropTypes.bool,
  optionRenderer: PropTypes.func, // Overwrite how each option renders via a function
  autoSelectFirst: PropTypes.bool, // Auto-select first value
  loading: PropTypes.bool,
  listOpened: PropTypes.bool,
  setListOpened: PropTypes.func,
  sections: PropTypes.array, // Offers the possibility to have sections labeled within the dropdown options
  sortOptions: PropTypes.bool,
  optionsHeight: PropTypes.number,
  standalone: PropTypes.bool,
}

DropdownList.propTypes = propTypes
