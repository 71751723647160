import { useEffect, useRef, useState } from 'react'
import io from 'socket.io-client'

const isLocal = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

const ioSocket = isLocal
  ? /** On localhost use the default transport methods, which are 'polling' and 'websocket' in this order. */
    io('http://localhost:3000')
  : /** On heroku use only 'websocket' as transport method, as multi-dyno systems only support this one */
    io(process.env.HOSTNAME, {
      transports: ['websocket'],
      timeout: 1000,
    })

/**
 * Returns a socket connection
 * After the initial connection, we wait for the server's approval. After the server responds, we join a room, if needed.
 * Automatically closes when its parent component closes
 * @param {Object} options options
 * @param {String|Array<String>} [options.room] room(s) client joins to
 * @param {Boolean} [options.roomNeeded] whether the socket connection should only be made when room is specified
 */
export const useSocket = ({ room = null, roomNeeded = false }) => {
  /**
   * If room is needed and we have no room, the socket would not connect on first trial and since updating a reference
   * doesn't trigger a rerender, we have to force it in order for the connected socket to be passed down to the component using the socket
   */
  const [, setConnected] = useState(false)

  const socketRef = useRef()

  const _handleConnection = () => {
    if (
      (!roomNeeded || (roomNeeded && room)) &&
      !socketRef?.current?.connected
    ) {
      socketRef.current = ioSocket.connect()

      if (room) {
        socketRef.current.emit('join', room)
      }

      socketRef.current.on('connect', () => {
        setConnected(new Date())
      })

      socketRef.current.on('connect_error', (e) => {
        console.error(`Socket Connection Error: `, e)
      })

      socketRef.current.on('error', (e) => {
        console.error(`Socket Error: `, e)
      })

      return () => {
        socketRef?.current?.close()
      }
    } else if (room) {
      socketRef.current?.emit('join', room)
    }
  }

  useEffect(() => {
    _handleConnection()
  }, [JSON.stringify(room)])

  return socketRef.current
}
