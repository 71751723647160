import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import Table from 'components/table/beta'
import Button from 'components/button'
import { Dropdown } from 'components/dropdown'
import Modal from 'components/modal'
import StickyFooter from 'components/sticky-footer'
import Loader from 'components/loader'

import './style.scss'
/**
 * Bulk edit component
 * @param {Object} params Component props
 * @param {Array} params.data Data to fill the table
 * @param {Array} params.columns Table columns
 * @param {Object} params.labels Object with labels
 * @param {Array} params.options Array of types of actions that can be applied in bulk
 * @param {String} params.options.label Label of dropdown option for a specific action. Eg. 'Update Status'
 * @param {Promise} params.options.action Action that gets applied to each selected row
 * @param {Function} params.options.renderInput Render for specific action's input
 * @param {Function} params.options.icon Icon to display
 * @param {Function} params.options.defaultValue Default value
 * @param {Function} params.onCancel Function that gets called on clicking "Cancel"
 * @param {Function} params.onDelete Function that gets called on clicking the delete button
 * @param {React.ReactNode} params.children children
 * @param {Boolean} params.loading loading state
 * @param {Function} params.selectedOption send selected state for Parent component
 * @param {Function} params.onError Callback triggered when an error occurs
 * @param {Array} [params.actionButtons] Array of button configurations to render as actions
 */
const BulkEdit = ({
  data,
  columns,
  options,
  onCancel,
  onDelete,
  onError,
  labels,
  children,
  loading,
  actionButtons,
  selectedOption,
}) => {
  const [rows, setRows] = useState(data || [])
  const [editType, setEditType] = useState()
  const [state, setState] = useState()
  const [error, setError] = useState()
  const editOptions = options || []
  const currentOption = options.find((option) => option.value === editType)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  useEffect(() => {
    if (currentOption && currentOption.defaultValue) {
      setState(currentOption.defaultValue)
    }
  }, [JSON.stringify(options)])

  const runActions = (action = currentOption && currentOption.action) => {
    if (action) {
      action(data, state)
        .then((result) => {
          result && setRows(result)
          setEditType(null)
          setState(null)
          onCancel()
        })
        .catch((err) => {
          onError ? onError(err) : console.error(err)
        })
    } else {
      setError('Something went wrong')
    }
  }

  useEffect(() => {
    if (!data) {
      return
    }

    setRows(data)
  }, [JSON.stringify(data)])

  if (openDeleteModal) {
    return (
      <Modal
        opened={openDeleteModal}
        button={
          <Button
            value="Delete"
            onClick={() => {
              setOpenDeleteModal(false)
              runActions(onDelete)
            }}
          />
        }
        buttonSecondary={
          <Button
            value={labels.cancel || 'Cancel'}
            onClick={() => setOpenDeleteModal(false)}
            secondaryGray
          />
        }
        heading="Are you sure you want to delete these items?"
        className="alert-categories__modal"
      />
    )
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div className="bulk-edit form">
      {children}
      <div className="form__section">
        <div className="heading" data-cy="page-heading">
          {labels.heading || 'Edit'}
        </div>
        <Table columns={columns} data={rows} />
      </div>
      <section className="form__section form__section--edit">
        <div className="form__section__header">Bulk Edit</div>
        <div className="form__section__body">
          <div className="bulk-edit__actions">
            <div className="bulk-edit__actions__left">
              <Dropdown
                label="Select Operation"
                labelTop
                options={editOptions}
                defaultOptionText="Select Bulk Edit Option"
                defaultState={editType}
                optionRenderer={(option) => (
                  <div
                    className="bulk-edit__actions__option"
                    key={option.value}
                  >
                    {option.icon && <option.icon />}
                    <div>{option.label}</div>
                  </div>
                )}
                error={error}
                onChange={(type) => {
                  if (type !== editType) {
                    setEditType(type)
                    selectedOption && selectedOption(type)
                    setState()
                  }
                }}
                optionsHeight={300}
              />
              {currentOption?.renderCallout && currentOption.renderCallout()}
            </div>
            {currentOption?.renderInput && (
              <div className="bulk-edit__actions__right">
                {currentOption?.inputLabel && (
                  <div className="general-label">
                    {currentOption?.inputLabel}
                  </div>
                )}
                {currentOption.renderInput(state, setState)}
              </div>
            )}
          </div>
        </div>
      </section>
      {currentOption?.renderInputBelow &&
        (currentOption?.className ? (
          <div className={currentOption?.className}>
            <div className="general-label">
              {currentOption?.inputLabel || '\u00A0'}
            </div>
            {currentOption.renderInputBelow(state, setState)}
          </div>
        ) : (
          <div className={currentOption?.inputBelowForm ? 'form__section' : ''}>
            <div
              className={
                currentOption?.inputBelowForm ? 'form__section__body' : ''
              }
            >
              <div className="general-label">
                {currentOption?.inputLabel || '\u00A0'}
              </div>
              {currentOption.renderInputBelow(state, setState)}
            </div>
          </div>
        ))}

      <StickyFooter
        buttons={
          actionButtons
            ? actionButtons
            : [
                {
                  renderCondition: currentOption && (state || state === false),
                  value: labels.save || 'Save',
                  onClick: () => {
                    runActions()
                  },
                },
                {
                  value: 'Cancel',
                  onClick: onCancel,
                  secondaryGray: true,
                },
                {
                  value: labels.delete || 'Delete All',
                  onClick: () => setOpenDeleteModal(true),
                  renderCondition: !!onDelete,
                  type: 'secondaryRed',
                },
              ]
        }
      />
    </div>
  )
}

BulkEdit.propTypes = {
  data: propTypes.array,
  columns: propTypes.array,
  options: propTypes.array,
  onCancel: propTypes.func,
  onDelete: propTypes.func,
  onError: propTypes.func,
  labels: propTypes.object,
  children: propTypes.object,
  loading: propTypes.bool,
  actionButtons: propTypes.array,
  selectedOption: propTypes.func,
}
export default BulkEdit
