export const MAX_ALLOWED_UPLOAD_SIZE_IN_MB = 4000 // 4GB Max Size Allowed
export const MAX_ALLOWED_UPLOAD_SIZE_IN_BYTES = 4000 * 1024 * 1024 // 4GB Max Size Allowed
export const DEFAULT_ACCEPTED_UPLOAD_FILE_TYPE = 'image/*'
export const DUMMY_VIDEO_ELEMENT = 'file-upload-video'
export const DUMMY_IMAGE_ELEMENT = 'file-upload-image'
export const FILE_UPLOAD_STATE = {
  DEFAULT: 0,
  DRAG_OVER: 1,
  UPLOADING: 2,
}
export const GENERIC_UPLOAD_ERROR = 'Could not upload your file.'
export const UPLOAD_FILE_URL = '/api/briefs/upload-file'
export const UPLOAD_FILE_VIA_URL = '/api/briefs/upload-file-url'
export const FILE_SIZE_UNIT = {
  B: 0,
  KB: 1,
  MB: 2,
  GB: 3,
  TB: 4,
}
