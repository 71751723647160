import { useEffect, useState } from 'react'
import { getCustomConversionsForAccounts } from 'modules/accounts/actions'
import { accounts } from '@decision-sciences/qontrol-common'

const { ACCOUNT_TYPES_MAP } = accounts

const {
  AMAZON_DSP,
  AMAZON_ADVERTISING,
  GOOGLE,
  FACEBOOK,
  MICROSOFT,
  TRADE_DESK,
  LINKED_IN,
  TIKTOK,
  GOOGLE_ANALYTICS,
  SA360,
  GOOGLE_CAMPAIGN_MANAGER,
  APPLE,
  TWITTER,
  DV360,
  REDDIT,
  SNAPCHAT,
  PINTEREST,
} = ACCOUNT_TYPES_MAP

/**
 * Encapsulates logic to fetch KPIs for specific accounts
 * @param {Object} arguments Hook arguments
 * @param {Boolean} arguments.viewMode When `true`, skips data fetching
 * @param {Array} arguments.accounts List of accounts to fetch KPIs for
 * @returns {[Array, Number]}
 */
const useFetchKpis = ({ viewMode, accounts }) => {
  const [kpis, setKpis] = useState([])
  const [loadingKpis, setLoadingKpis] = useState(0)

  /** Get all kpis for the selected accounts */
  useEffect(() => {
    if (viewMode || !accounts?.length) {
      setKpis([])
      return
    }
    const getSpecialMetrics = async (type) => {
      setLoadingKpis((loadingKPIs) => loadingKPIs + 1)
      try {
        const results = await getCustomConversionsForAccounts(accounts, type)
        if (!results.length) {
          return []
        }
        return results
      } catch (e) {
        console.error(e)
        return []
      } finally {
        setLoadingKpis((loadingKPIs) => loadingKPIs - 1)
      }
    }

    const providers = [
      GOOGLE,
      FACEBOOK,
      MICROSOFT,
      TRADE_DESK,
      LINKED_IN,
      TIKTOK,
      GOOGLE_ANALYTICS,
      SA360,
      GOOGLE_CAMPAIGN_MANAGER,
      APPLE,
      TWITTER,
      AMAZON_DSP,
      AMAZON_ADVERTISING,
      DV360,
      REDDIT,
      SNAPCHAT,
      PINTEREST,
    ]

    const setSpecialMetrics = async () => {
      let data = []
      for (const provider of providers) {
        const res = await getSpecialMetrics(provider)
        data = [...data, ...res]
      }

      setKpis(data)
    }

    setSpecialMetrics().catch(console.error)
  }, [JSON.stringify(accounts), viewMode])

  return [kpis, loadingKpis]
}

export { useFetchKpis }
