import {
  accounts,
  granularities,
  alert,
  flow,
  metrics,
} from '@decision-sciences/qontrol-common'

const { PLACEMENTS_MAP } = flow.facebook.odaxPlacements
const { ACCOUNT_TYPES_MAP } = accounts
const { GRANULARITIES } = granularities
const { ALERT_TYPES_MAP } = alert
const { METRICS, SPECIAL_METRICS } = metrics

const {
  ACCOUNT,
  CAMPAIGN,
  AD_GROUP,
  AD,
  AD_TYPE,
  KEYWORD,
  NETWORK,
  DEVICE,
  OBJECTIVE,
  BUY_TYPE,
  PLATFORM,
  CAMPAIGN_TYPE,
  AD_COPY,
  PLACEMENTS,
  AUDIENCE,
  FORMAT,
  CAMPAIGN_SUB_TYPE,
  PRODUCT_GROUP,
  AGE,
  GENDER,
  AGE_GENDER,
  COUNTRY,
  REVENUE,
  INSERTION_ORDER,
} = GRANULARITIES
const {
  GOOGLE,
  FACEBOOK,
  MICROSOFT,
  TRADE_DESK,
  LINKED_IN,
  TIKTOK,
  AMAZON_DSP,
  AMAZON_ADVERTISING,
  DV360,
  SA360,
} = ACCOUNT_TYPES_MAP

export const ALERTS_FACEBOOK_SELECT_ELEMENTS = [
  { label: 'Account', value: ACCOUNT },
  { label: 'Campaign', value: CAMPAIGN },
  { label: 'Ad Set', value: AD_GROUP },
  { label: 'Ad', value: AD },
  { label: 'Objective', value: OBJECTIVE },
  { label: 'Buy Type', value: BUY_TYPE },
  { label: 'Platform', value: PLATFORM, disabled: false },
  {
    label: 'Placements',
    value: PLACEMENTS,
    disabled: false,
  },
  { label: 'Audience', value: AUDIENCE, disabled: true },
  { label: 'Format', value: FORMAT, disabled: false },
  { label: 'Device', value: DEVICE, disabled: false },
]

export const ALERTS_GOOGLE_SELECT_ELEMENTS = [
  { label: 'Account', value: ACCOUNT },
  { label: 'Campaign', value: CAMPAIGN },
  {
    label: 'Campaign Type',
    value: CAMPAIGN_TYPE,
    disabled: false,
  },
  {
    label: 'Campaign Subtype',
    value: CAMPAIGN_SUB_TYPE,
    disabled: true,
  },
  { label: 'Network', value: NETWORK, disabled: false },
  { label: 'Ad Group', value: AD_GROUP, disabled: false },
  {
    label: 'Product Group',
    value: PRODUCT_GROUP,
    disabled: true,
  },
  { label: 'Ad', value: AD, disabled: false },
  { label: 'Ad Type', value: AD_TYPE, disabled: false },
  { label: 'Audience', value: AUDIENCE, disabled: false },
  { label: 'Keyword', value: KEYWORD, disabled: false },
  { label: 'Ad Copy', value: AD_COPY, disabled: true },
  { label: 'Device', value: DEVICE, disabled: false },
]

export const ALERTS_MICROSOFT_SELECT_ELEMENTS = [
  { label: 'Account', value: ACCOUNT, disabled: false },
  {
    label: 'Campaign',
    value: CAMPAIGN,
    disabled: false,
  },
  {
    label: 'Campaign Type',
    value: CAMPAIGN_TYPE,
    disabled: true,
  },
  {
    label: 'Ad Group',
    value: AD_GROUP,
    disabled: false,
  },
  { label: 'Ad', value: AD, disabled: false },
  { label: 'Keyword', value: KEYWORD, disabled: false },
  { label: 'Network', value: NETWORK, disabled: true },
  { label: 'Device', value: DEVICE, disabled: true },
]

export const ALERTS_TRADE_DESK_SELECT_ELEMENTS = [
  {
    label: 'Account',
    value: ACCOUNT,
    disabled: false,
  },
  {
    label: 'Campaign',
    value: CAMPAIGN,
    disabled: false,
  },
  {
    label: 'Ad Group',
    value: AD_GROUP,
    disabled: false,
  },
  { label: 'Ad', value: AD, disabled: false },
]

export const ALERTS_AMAZON_DSP_SELECT_ELEMENTS = [
  {
    label: 'Account',
    value: ACCOUNT,
    disabled: false,
  },
  {
    label: 'Order',
    value: CAMPAIGN,
    disabled: false,
  },
  {
    label: 'Line Item',
    value: AD_GROUP,
    disabled: false,
  },
  {
    label: 'Creative',
    value: AD,
    disabled: false,
  },
]

export const ALERTS_LINKED_IN_SELECT_ELEMENTS = [
  {
    label: 'Account',
    value: ACCOUNT,
    disabled: false,
  },
  {
    label: 'Campaign',
    value: CAMPAIGN,
    disabled: false,
  },
  { label: 'Ad', value: AD, disabled: false },
]

export const ALERTS_TIKTOK_SELECT_ELEMENTS = [
  {
    label: 'Account',
    value: ACCOUNT,
    disabled: false,
  },
  {
    label: 'Campaign',
    value: CAMPAIGN,
    disabled: false,
  },
  {
    label: 'Ad Group',
    value: AD_GROUP,
    disabled: false,
  },
  {
    label: 'Ad',
    value: AD,
    disabled: false,
  },
  {
    label: 'Objective',
    value: OBJECTIVE,
    disabled: false,
  },
  {
    label: 'Placements',
    value: PLACEMENTS,
    disabled: false,
  },
  { label: 'Buy Type', value: BUY_TYPE, disabled: false },
  { label: 'Age', value: AGE, disabled: false },
  { label: 'Gender', value: GENDER, disabled: false },
  { label: 'Age/Gender', value: AGE_GENDER, disabled: false },
  { label: 'Country', value: COUNTRY, disabled: false },
  { label: 'Network', value: NETWORK, disabled: false },
  { label: 'Platform', value: PLATFORM, disabled: false },
]

export const ALERTS_AMAZON_ADVERTISING_SELECT_ELEMENTS = [
  {
    label: 'Account',
    value: ACCOUNT,
    disabled: false,
  },
  {
    label: 'Campaign',
    value: CAMPAIGN,
    disabled: false,
  },
  {
    label: 'Campaign Type',
    value: CAMPAIGN_TYPE,
    disabled: true,
  },
  {
    label: 'Revenue',
    value: REVENUE,
    disabled: true,
  },
  {
    label: 'Ad Group',
    value: AD_GROUP,
    disabled: true,
  },
  {
    label: 'Ad',
    value: AD,
    disabled: true,
  },
  {
    label: 'Keyword',
    value: KEYWORD,
    disabled: true,
  },
]

export const ALERTS_DV360_SELECT_ELEMENTS = [
  {
    label: 'Account',
    value: ACCOUNT,
    disabled: false,
  },
  {
    label: 'Campaign',
    value: CAMPAIGN,
    disabled: false,
  },
  {
    label: 'Line Item',
    value: AD_GROUP,
    disabled: false,
  },
  {
    label: 'Creative',
    value: AD,
    disabled: false,
  },
  {
    label: 'Insertion Order',
    value: INSERTION_ORDER,
    disabled: false,
  },
]

export const ALERTS_SA360_SELECT_ELEMENTS = [
  {
    label: 'Account',
    value: ACCOUNT,
    disabled: false,
  },
  {
    label: 'Campaign',
    value: CAMPAIGN,
    disabled: false,
  },
  {
    label: 'Ad Group',
    value: AD_GROUP,
    disabled: false,
  },
  {
    label: 'Ad',
    value: AD,
    disabled: false,
  },
]

export const ACCOUNTS_TO_SELECT_ELEMENTS = {
  [FACEBOOK]: ALERTS_FACEBOOK_SELECT_ELEMENTS,
  [GOOGLE]: ALERTS_GOOGLE_SELECT_ELEMENTS,
  [MICROSOFT]: ALERTS_MICROSOFT_SELECT_ELEMENTS,
  [TRADE_DESK]: ALERTS_TRADE_DESK_SELECT_ELEMENTS,
  [LINKED_IN]: ALERTS_LINKED_IN_SELECT_ELEMENTS,
  [TIKTOK]: ALERTS_TIKTOK_SELECT_ELEMENTS,
  [AMAZON_DSP]: ALERTS_AMAZON_DSP_SELECT_ELEMENTS,
  [AMAZON_ADVERTISING]: ALERTS_AMAZON_ADVERTISING_SELECT_ELEMENTS,
  [DV360]: ALERTS_DV360_SELECT_ELEMENTS,
  [SA360]: ALERTS_SA360_SELECT_ELEMENTS,
}
export const AVAILABLE_PUBLISHERS_FOR_ALERT = {
  [ALERT_TYPES_MAP.PERFORMANCE]: [
    FACEBOOK,
    GOOGLE,
    MICROSOFT,
    TRADE_DESK,
    LINKED_IN,
    TIKTOK,
    AMAZON_DSP,
    AMAZON_ADVERTISING,
    DV360,
    SA360,
  ],
  [ALERT_TYPES_MAP.SETTINGS]: [
    FACEBOOK,
    GOOGLE,
    MICROSOFT,
    TIKTOK,
    AMAZON_DSP,
    DV360,
  ],
}

export const getFacebookPlacements = () => {
  return new Promise((resolve) => {
    return resolve(
      Object.values(PLACEMENTS_MAP)
        .reduce((acc, el) => {
          if (el.hideOnAlerts) {
            return acc
          }
          return [
            ...acc,
            {
              id: el.snowflakeValue || el.label,
              name: el.label,
              non_account_related: true,
            },
          ]
        }, [])
        .sort((a, b) => {
          const nameA = a.name.toUpperCase()
          const nameB = b.name.toUpperCase()
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
    )
  })
}

export const DEFAULT_ELEMENTS_MULTI = {
  [FACEBOOK]: {
    elements: [ACCOUNT],
    accounts: [],
    allAccountsSelected: true,
  },
  [GOOGLE]: {
    elements: [ACCOUNT],
    accounts: [],
    allAccountsSelected: true,
  },
  [MICROSOFT]: {
    elements: [ACCOUNT],
    accounts: [],
    allAccountsSelected: true,
  },
  [TRADE_DESK]: {
    elements: [ACCOUNT],
    accounts: [],
    allAccountsSelected: true,
  },
  [LINKED_IN]: {
    elements: [ACCOUNT],
    accounts: [],
    allAccountsSelected: true,
  },
  [TIKTOK]: {
    elements: [ACCOUNT],
    accounts: [],
    allAccountsSelected: true,
  },
  [AMAZON_DSP]: {
    elements: [ACCOUNT],
    accounts: [],
    allAccountsSelected: true,
  },
  [DV360]: {
    elements: [ACCOUNT],
    accounts: [],
    allAccountsSelected: true,
  },
  [SA360]: {
    elements: [ACCOUNT],
    accounts: [],
    allAccountsSelected: true,
  },
  [AMAZON_ADVERTISING]: {
    elements: [ACCOUNT],
    accounts: [],
    allAccountsSelected: true,
  },
}

export const DEFAULT_ELEMENTS_SINGLE = {
  [FACEBOOK]: {
    elements: [],
    accounts: [],
    allAccountsSelected: false,
    abortControllers: {},
  },
  [GOOGLE]: {
    elements: [],
    accounts: [],
    allAccountsSelected: false,
    abortControllers: {},
  },
  [MICROSOFT]: {
    elements: [],
    accounts: [],
    allAccountsSelected: false,
    abortControllers: {},
  },
  [TRADE_DESK]: {
    elements: [],
    accounts: [],
    allAccountsSelected: false,
    abortControllers: {},
  },
  [LINKED_IN]: {
    elements: [],
    accounts: [],
    allAccountsSelected: false,
    abortControllers: {},
  },
  [TIKTOK]: {
    elements: [],
    accounts: [],
    allAccountsSelected: false,
    abortControllers: {},
  },
  [AMAZON_DSP]: {
    elements: [],
    accounts: [],
    allAccountsSelected: false,
    abortControllers: {},
  },
  [DV360]: {
    elements: [],
    accounts: [],
    allAccountsSelected: false,
    abortControllers: {},
  },
  [SA360]: {
    elements: [],
    accounts: [],
    allAccountsSelected: false,
    abortControllers: {},
  },
  [AMAZON_ADVERTISING]: {
    elements: [],
    accounts: [],
    allAccountsSelected: false,
    abortControllers: {},
  },
}

/**
 * Array to determine the order for the Metrics from the Client > Alerts > Filters dropdown.
 */
export const ALERT_FILTER_ORDER = [
  // This is a dummy value to include Settings alerts as well
  'Settings',
  METRICS.Impressions.key,
  METRICS.Views.key,
  METRICS.Video_Views.key,
  'Engagements',
  METRICS.Clicks.key,
  METRICS.Cost.key,
  SPECIAL_METRICS.Primary_KPI.key,
  SPECIAL_METRICS.Secondary_KPI.key,
  SPECIAL_METRICS.Dynamic_Metric.key,
  SPECIAL_METRICS.Primary_KPI_Revenue.key,
  SPECIAL_METRICS.Secondary_KPI_Revenue.key,
  SPECIAL_METRICS.Dynamic_KPI_Revenue.key,
  // These will come from the calculated-metrics collection
  'ctr',
  'cpm',
  'cpv',
  'cpc',
  'cpa',
  'cpi',
  'cvr',
  'roas',
  'aov',
  METRICS.Search_Exact_Match_Impression_Share.key,
  METRICS.Search_Impression_Share.key,
  METRICS.Search_Rank_Lost_Absolute_Top_Impression_Share.key,
  METRICS.Search_Rank_Lost_Impression_Share.key,
  METRICS.Search_Rank_Lost_Top_Impression_Share.key,
  METRICS.Search_Top_Impression_Share.key,
]

export const NOTIFICATION_TYPES = [
  { label: 'Teams', value: 'teams' },
  { label: 'Users', value: 'users' },
]
