import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from 'components/button/index'
import Modal from 'components/modal/index'
import WarningIcon from 'components/warning-icon/index'
import { RadioNoHooks } from 'components/radio/index'

import { ReactComponent as CheckmarkIcon } from 'assets/icon_checkmark.svg'

const contextTexts = {
  alerts: {
    description:
      'Some of the thresholds have values entered under Client. You can add the Recommended values to the fields under Client that do not have values already entered, or you can overwrite all Client values with the Recommended values.',
    radioText1:
      'Add Recommended values only to fields without values already entered',
    radioText2: 'Overwrite all',
  },
  alertThresholds: {
    description:
      'Some of the alert thresholds already have client-specific thresholds entered.',
    radioText1:
      'Add Recommended thresholds where no client-specific thresholds are set',
    radioText2: 'Overwrite all',
  },
}

/**
 * Renders a modal prompting a user choice to confirm bulk assigning threshold recommendations
 * @param {Object} props Component props
 * @param {Boolean} props.visible Visibility flag
 * @param {Function} props.onAssign Callback triggered when confirming the action
 * @param {Function} props.onClose Callback triggered when closing the modal
 * @param {String} props.context Context to determine which texts to display
 * @param {Number} props.unsetCount Count of thresholds where no client-specific thresholds are set
 * @param {Number} props.totalCount Total count of all alert thresholds for overwrite

 * @returns {React.FunctionComponent}
 */
const BulkAssignRecommendationsModal = ({
  visible,
  onAssign,
  onClose,
  context,
  unsetCount,
  totalCount,
}) => {
  const [
    overwriteOnAssignRecommendations,
    setOverwriteOnAssignRecommendations,
  ] = useState(false)

  const { description, radioText1, radioText2 } =
    contextTexts[context] || contextTexts.alerts

  if (!visible) {
    return null
  }

  return (
    <Modal
      opened
      contentSeparator
      rightAlignButtons
      heading="Recommended Values"
      icon={<WarningIcon />}
      button={
        <Button
          compact
          secondaryGreen
          data-cy="alert-threshold-bulk-change-confirm"
          value={
            <div className="align-row align-center gap-8">
              <CheckmarkIcon width={18} height={18} />
              <span>Confirm</span>
            </div>
          }
          onClick={() => {
            onAssign(overwriteOnAssignRecommendations)
          }}
        />
      }
      buttonSecondary={
        <Button
          compact
          secondaryGray
          data-cy="alert-threshold-bulk-change-cancel"
          value="Cancel"
          onClick={onClose}
        />
      }
      className="alerts-bulk-recommendations"
    >
      <div className="align-column gap-10">
        <p>{description}</p>
        <div className="margin-top-8">
          <RadioNoHooks
            className="blue "
            label={`${radioText1} ${unsetCount ? `(${unsetCount})` : ''}`}
            checked={overwriteOnAssignRecommendations === false}
            onChange={() => {
              setOverwriteOnAssignRecommendations(false)
            }}
          />
          <RadioNoHooks
            className="blue"
            label={`${radioText2} ${totalCount ? `(${totalCount})` : ''}`}
            checked={overwriteOnAssignRecommendations === true}
            onChange={() => {
              setOverwriteOnAssignRecommendations(true)
            }}
          />
        </div>
        <p>
          Click the Confirm button to continue with your action. This action
          cannot be undone.
        </p>
      </div>
    </Modal>
  )
}

BulkAssignRecommendationsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onAssign: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  context: PropTypes.oneOf(['alerts', 'alertThresholds']),
  unsetCount: PropTypes.number,
  totalCount: PropTypes.number,
}

export { BulkAssignRecommendationsModal }
