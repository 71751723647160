import { flow } from '@decision-sciences/qontrol-common'

const {
  ADSET_CONVERSION_EVENT,
  ADSET_DESTINATION_TYPE,
  APP_INSTALL_TYPES,
  LEAD_METHODS,
  OBJECTIVES_MAP,
} = flow.facebook

// Render conditions for separate fields inside the Ad Options panel
export const AD_OPTION_FIELD_CONDITIONS = {
  WEBSITE_URL: {
    disabled: [
      {
        'adSet.adSetOptions.driveTrafficTo': [
          ADSET_DESTINATION_TYPE.APP.value,
          ADSET_DESTINATION_TYPE.WHATSAPP.value,
          ADSET_DESTINATION_TYPE.MESSENGER.value,
        ],
        campaignObjective: OBJECTIVES_MAP.TRAFFIC,
      },
      {
        campaignObjective: OBJECTIVES_MAP.APP_INSTALLS,
      },
      {
        campaignObjective: OBJECTIVES_MAP.VIDEO_VIEWS,
      },
      {
        campaignObjective: OBJECTIVES_MAP.CONVERSIONS,
      },
      {
        'adSet.adSetOptions.leadMethod': [LEAD_METHODS.AUTOMATED_CHAT.value],
        campaignObjective: OBJECTIVES_MAP.LEAD_GENERATION,
      },
      {
        'adSet.adSetOptions.conversionEvent.type': [
          ADSET_CONVERSION_EVENT.WHATSAPP.value,
          ADSET_CONVERSION_EVENT.APP.value,
          // TODO [Titus] Uncomment after Destination panel is implemented
          // ADSET_CONVERSION_EVENT.WEBSITE.value,
        ],
        // campaignObjective: OBJECTIVES_MAP.CONVERSIONS,
        // TODO [Titus] End Uncomment after Destination panel is implemented
      },
      {
        'adSet.adSetOptions.conversionEvent.type': [
          ADSET_CONVERSION_EVENT.MESSENGER.value,
        ],
        campaignObjective: OBJECTIVES_MAP.CONVERSIONS,
      },
      {
        'adSet.adSetOptions.driveTrafficTo': [
          ADSET_DESTINATION_TYPE.MESSENGER.value,
          ADSET_DESTINATION_TYPE.WHATSAPP.value,
        ],
        campaignObjective: OBJECTIVES_MAP.MESSAGES,
      },
    ],
  },
  DISPLAY_LINK: {
    disabled: [
      {
        'adSet.adSetOptions.driveTrafficTo': [
          ADSET_DESTINATION_TYPE.APP.value,
          ADSET_DESTINATION_TYPE.MESSENGER.value,
          ADSET_DESTINATION_TYPE.WHATSAPP.value,
        ],
        campaignObjective: OBJECTIVES_MAP.TRAFFIC,
      },
      {
        'adSet.adSetOptions.conversionEvent.type': [
          ADSET_CONVERSION_EVENT.APP.value,
        ],
        campaignObjective: OBJECTIVES_MAP.CONVERSIONS,
      },
      {
        campaignObjective: OBJECTIVES_MAP.VIDEO_VIEWS,
      },
    ],
  },
  // Disabled Display Link and Deep link section
  LINK_SECTION: {
    disabled: [
      {
        'adSet.adSetOptions.driveTrafficTo': [
          ADSET_DESTINATION_TYPE.MESSENGER.value,
          ADSET_DESTINATION_TYPE.WHATSAPP.value,
        ],
        campaignObjective: OBJECTIVES_MAP.TRAFFIC,
      },
      { campaignObjective: OBJECTIVES_MAP.MESSAGES },
      {
        campaignObjective: OBJECTIVES_MAP.APP_INSTALLS,
      },
      {
        campaignObjective: OBJECTIVES_MAP.VIDEO_VIEWS,
      },
      {
        'adSet.adSetOptions.leadMethod': [LEAD_METHODS.AUTOMATED_CHAT.value],
        campaignObjective: OBJECTIVES_MAP.LEAD_GENERATION,
      },
      {
        'adSet.adSetOptions.conversionEvent.type': [
          ADSET_CONVERSION_EVENT.MESSENGER.value,
          ADSET_CONVERSION_EVENT.WHATSAPP.value,
          ADSET_CONVERSION_EVENT.APP.value,
          ADSET_CONVERSION_EVENT.WEBSITE.value,
        ],
        campaignObjective: OBJECTIVES_MAP.CONVERSIONS,
      },
    ],
  },
}

/**
 * Checks whether an Ad can have the Website URL section depending on the Ad itself and the parent AdSet and Campaign
 * @return {boolean}
 */
export const canHaveWebsiteSection = (ad, adSet, campaign) => {
  const objective = campaign.data.objective
  if (!OBJECTIVES_FOR_WEBSITE_URL.includes(objective)) {
    return false
  }

  // For Traffic - Website we don't render it because it's already in another place
  return !(
    (objective === OBJECTIVES_MAP.TRAFFIC &&
      adSet.adSetOptions?.driveTrafficTo?.some((driveTrafficTo) =>
        [
          ADSET_DESTINATION_TYPE.WEBSITE.value,
          ADSET_DESTINATION_TYPE.APP.value,
          ADSET_DESTINATION_TYPE.MESSENGER.value,
          ADSET_DESTINATION_TYPE.WHATSAPP.value,
        ].includes(driveTrafficTo)
      )) ||
    (objective === OBJECTIVES_MAP.MESSAGES &&
      adSet.adSetOptions?.driveTrafficTo?.some((driveTrafficTo) =>
        [
          ADSET_DESTINATION_TYPE.MESSENGER.value,
          ADSET_DESTINATION_TYPE.WHATSAPP.value,
        ].includes(driveTrafficTo)
      )) ||
    (objective === OBJECTIVES_MAP.CONVERSIONS &&
      adSet.adSetOptions?.driveTrafficTo?.some((driveTrafficTo) =>
        [
          ADSET_DESTINATION_TYPE.WEBSITE.value,
          ADSET_DESTINATION_TYPE.MESSENGER.value,
          ADSET_DESTINATION_TYPE.WHATSAPP.value,
          ADSET_DESTINATION_TYPE.APP.value,
        ].includes(driveTrafficTo)
      )) ||
    (objective === OBJECTIVES_MAP.APP_INSTALLS &&
      campaign.data.appInstallsType === APP_INSTALL_TYPES[1].value)
  )
}

// Objectives for which the "Add Website URL" checkbox should appear -> which toggles the Ad Options Panel
const OBJECTIVES_FOR_WEBSITE_URL = [
  OBJECTIVES_MAP.APP_INSTALLS,
  OBJECTIVES_MAP.BRAND_AWARENESS,
  OBJECTIVES_MAP.REACH,
  OBJECTIVES_MAP.CONVERSIONS,
  OBJECTIVES_MAP.TRAFFIC,
  OBJECTIVES_MAP.MESSAGES,
]
