import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

/** Components */
import { RadioNoHooks } from 'components/radio/index'

/* Store & Actions */
import { editCampaign } from 'modules/flow/actions'
import { useStore } from 'store'

/* Constants */
import { flow, accounts, googleEnums } from '@decision-sciences/qontrol-common'

const {
  CAMPAIGN_LOCATION_TARGETING_METHOD_MAP,
  CAMPAIGN_LOCATION_EXCLUSION_METHOD_MAP,
} = flow.google
const { ACCOUNT_TYPES_MAP } = accounts
const { AdvertisingChannelType } = googleEnums

const LocationOptions = ({ campaign }) => {
  const { dispatch } = useStore()

  const availableSections = useMemo(() => {
    return {
      [SECTIONS.TARGET]: true,
      [SECTIONS.EXCLUDE]:
        AdvertisingChannelType[campaign.advertising_channel_type] !==
        AdvertisingChannelType.SEARCH,
    }
  }, [])

  const onCampaignChanged = (newCampaign) => {
    editCampaign({
      dispatch,
      id: campaign.id,
      platform: ACCOUNT_TYPES_MAP.GOOGLE,
      data: newCampaign,
    })
  }

  const onOptionChanged = (option, value) =>
    onCampaignChanged({
      geo_target_type_setting: {
        [option]: value,
      },
    })

  return (
    <>
      {availableSections[SECTIONS.TARGET] && (
        <div>
          <div className="general-label">Target</div>
          {Object.values(CAMPAIGN_LOCATION_TARGETING_METHOD_MAP).map(
            ({ label, value, renderCondition }, idx) => {
              if (renderCondition && !renderCondition(campaign)) {
                return null
              }
              return (
                <React.Fragment key={idx}>
                  <RadioNoHooks
                    className="blue"
                    label={label}
                    checked={
                      campaign.geo_target_type_setting
                        ?.positive_geo_target_type === value
                    }
                    onChange={() =>
                      onOptionChanged('positive_geo_target_type', value)
                    }
                  />
                </React.Fragment>
              )
            }
          )}
        </div>
      )}
      {availableSections[SECTIONS.EXCLUDE] && (
        <div>
          <div className="general-label">Exclude</div>
          {Object.values(CAMPAIGN_LOCATION_EXCLUSION_METHOD_MAP).map(
            ({ label, value, renderCondition }, idx) => {
              if (renderCondition && !renderCondition(campaign)) {
                return null
              }
              return (
                <React.Fragment key={idx}>
                  <RadioNoHooks
                    className="blue"
                    label={label}
                    checked={
                      campaign.geo_target_type_setting
                        ?.negative_geo_target_type === value
                    }
                    onChange={() =>
                      onOptionChanged('negative_geo_target_type', value)
                    }
                  />
                </React.Fragment>
              )
            }
          )}
        </div>
      )}
    </>
  )
}

const SECTIONS = {
  TARGET: 'TARGET',
  EXCLUDE: 'EXCLUDE',
}

LocationOptions.propTypes = {
  campaign: PropTypes.object.isRequired,
}
export default LocationOptions
