import { TYPES_OF_AD_CONTENT, INPUT_PROPS } from 'modules/flow/constants'
import { accounts, utils, flow } from '@decision-sciences/qontrol-common'

const { ACCOUNT_TYPES_MAP } = accounts
const { getDomainFromUrl, validateMatchingDomains, validateUrlWithProtocol } =
  utils.url
const { AD_CONTENT_TYPES: INPUT_TYPES, GOOGLE_ADSET_TYPE } = flow.google
const { keywordConfig } = flow.google.keywords

const { GOOGLE } = ACCOUNT_TYPES_MAP
const AD_CONTENT_TYPES = TYPES_OF_AD_CONTENT[GOOGLE]

export const DYNAMIC_SEARCH_ADS_HINT = `Dynamic Search Ads (DSA) target relevant searches automatically
based on your website, then use headlines automatically customized
to people's actual searches. This setting determines what domain,
language, and targeting source to use for your DSA. To create a
DSA, you’ll need to create a dynamic ad group in the next step.`

/**
 * @type {{type: 'items' | 'selectedItems', idx: number, isSelected: boolean | null | undefined}}
 */
export const INITIAL_EDITING_ELEM = {
  type: null,
  idx: -1,
  isSelected: null,
}

export const CONTENT_CONFIG = {
  [AD_CONTENT_TYPES.HEADLINES]: {
    inputType: INPUT_TYPES.INPUT,
    inputProps: INPUT_PROPS[GOOGLE].HEADLINE,
    [GOOGLE_ADSET_TYPE.SEARCH_STANDARD.value]: { maxLength: 15, minLength: 3 },
  },
  [AD_CONTENT_TYPES.DESCRIPTION]: {
    inputType: INPUT_TYPES.TEXTAREA,
    inputProps: INPUT_PROPS[GOOGLE].DESCRIPTION,
    [GOOGLE_ADSET_TYPE.SEARCH_STANDARD.value]: { maxLength: 4, minLength: 2 },
    [GOOGLE_ADSET_TYPE.SEARCH_DYNAMIC_ADS.value]: {
      maxLength: 2,
      minLength: 1,
    },
  },
}

export const INITIAL_URL_OPTIONS = {
  final_url: '',
  final_mobile_url: '',
  final_url_suffix: '',
  tracking_url_template: '',
  url_custom_parameters: [],
}

export const CONTENT_LABELS = {
  [AD_CONTENT_TYPES.HEADLINES]: 'Headlines',
  [AD_CONTENT_TYPES.DESCRIPTION]: 'Descriptions',
}

export const CONTENT_LABELS_FOR_ADD_NEW = {
  [AD_CONTENT_TYPES.HEADLINES]: 'Headline',
  [AD_CONTENT_TYPES.DESCRIPTION]: 'Description',
}

export const PIN_POSITIONS = {
  [AD_CONTENT_TYPES.HEADLINES]: {
    count: 4,
    label: 'headline',
  },
  [AD_CONTENT_TYPES.DESCRIPTION]: {
    count: 3,
    label: 'description',
  },
}

/**
 * Helper function to remove match type characters from texts
 * @param {String} text Keyword text
 * @returns {String}
 */
export const removeMatchTypeCharacters = (text) => {
  if (
    (text.startsWith('[') && text.endsWith(']')) ||
    (text.startsWith(`"`) && text.endsWith(`"`))
  ) {
    return text.replace(/^["[-]/, '').replace(/["\]-]$/, '')
  }
  return text
}

/**
 * Function which checks if a keyword is both included and excluded, regadless of match type
 * @param {Object} editedKeyword Keyword to be checked
 * @param {Array} keywords Keywords to check against
 * @returns {Boolean}
 */
export const checkKeywordExclusionConflict = (editedKeyword, keywords) => {
  if (!keywords) {
    return false
  }
  const { local__uuid, match_type, text, negative } = editedKeyword
  return keywords.some(
    (keyword) =>
      keyword.local__uuid !== local__uuid &&
      keyword.match_type === match_type &&
      keyword.negative !== negative &&
      keyword.text === text
  )
}

/**
 * Function which checks if a keyword is duplicated - same match type, text and negative flag
 * @param {Object} editedKeyword Keyword to be checked
 * @param {Array} keywords Keywords to check against
 * @returns {Boolean}
 */
export const isKeywordDuplicated = (editedKeyword, keywords) => {
  if (!keywords) {
    return false
  }
  const { local__uuid, match_type, text, negative } = editedKeyword
  return keywords.some(
    (keyword) =>
      keyword.local__uuid !== local__uuid &&
      keyword.match_type === match_type &&
      keyword.negative === negative &&
      keyword.text === text
  )
}

export const validateTrackingUrlTemplate = (trackingUrl) => {
  let isValid = true
  const errors = {}
  const pattern =
    /^(?!.*(&&|\?\?))(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?(?:\?|&(?:amp;)?)url=(?:{lpurl}|{unescapedlpurl}|{escapedlpurl}|{lpurl\+2}|{lpurl\+3})(?:&(?:amp;)?\w+=\S*)*$/

  if (!pattern.test(trackingUrl)) {
    isValid = false
    errors.tracking_url_template =
      'Tracking URL Template contains invalid format'
  }

  return [isValid, errors]
}

export const validateFinalMobileUrl = (final_url, final_mobile_url) => {
  let isValid = true
  const errors = {}
  if (final_mobile_url) {
    if (!final_url) {
      isValid = false
      errors.final_mobile_url = 'A final URL is required to use this field.'
    }

    const [isUrlFormatValid, urlFormatErrorMessage] =
      validateUrlFormat(final_mobile_url)

    if (!isUrlFormatValid) {
      isValid = isUrlFormatValid
      errors.final_mobile_url = urlFormatErrorMessage
    }

    if (
      !errors.final_mobile_url &&
      !validateMatchingDomains(final_mobile_url, final_url)
    ) {
      isValid = false
      errors.final_mobile_url =
        'Mobile URL must have the same domain as the Final URL.'
    }
  }
  return [isValid, errors]
}

export const validateUrlFormat = (url) => {
  if (!validateUrlWithProtocol(url)) {
    return [false, 'The website URL format is incorrect.']
  }
  return [true, null]
}

export const validateBidLimit = (bidding) => {
  if (bidding) {
    const { upper_bid_limit, keyword_cpc, bid_action } = bidding

    switch (bid_action) {
      case keywordConfig.bid_action.INCREASE:
        // If there is an upper bid limit set, check to repsect it
        if (
          upper_bid_limit &&
          parseFloat(keyword_cpc) > parseFloat(upper_bid_limit)
        ) {
          return [
            false,
            `This keyword’s bid is higher than the Upper Bid Limit`,
          ]
        }
        break
      case keywordConfig.bid_action.DECREASE:
        if (
          upper_bid_limit &&
          parseFloat(keyword_cpc) < parseFloat(upper_bid_limit)
        ) {
          return [
            false,
            `This keyword’s bid is lower than the Lower Bid Limit.`,
          ]
        }
        break
    }
  }

  return [true, null]
}

export const buildUrlPreview = (url, path1, path2) => {
  let previewUrl = getDomainFromUrl(url, true) || url
  if (path1) {
    previewUrl = `${previewUrl}${
      previewUrl.slice(-1) === '/' ? '' : '/'
    }${path1}`
    if (path2) {
      previewUrl = `${previewUrl}/${path2}`
    }
  }
  previewUrl = previewUrl.replaceAll(' ', '_')
  return previewUrl
}
