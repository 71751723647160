import { ACTIONS as SESSION_ACTIONS } from 'modules/session/actions'
import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'alerts'

/** Initial state of the reducer */
const initialState = {
  list: null,
  total: 0,
  query: null,
  owners: [],
  categories: null,
  bulkEditList: [],
  navigateOnAction: null,
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.GET_ALERTS]: (state, { list, total }) => ({
    ...state,
    list,
    total,
  }),
  [ACTIONS.GET_ALERT_CATEGORIES]: (state, { list, query }) => ({
    ...state,
    categories: list,
    query,
  }),
  [SESSION_ACTIONS.LOGOUT]: () => {
    return { ...initialState }
  },

  [ACTIONS.SET_BULK_ALERTS]: (state, { bulkEditList, navigateOnAction }) => {
    return { ...state, bulkEditList, navigateOnAction }
  },
}

export default { name, initialState, reduce }
