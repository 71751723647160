import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import ItemRow from '../../item-row'
import { FILE_UPLOAD_STATE } from '../constants'

/**
 * Displays the uploading files rows with the details (file name and progress bar) for every file
 * @param {Object} props - The component's react props
 * @param {Array} props.files - The files based on which the item rows should be displayed
 * @param {Number} props.state - The current display state of the parent component
 */
export const UploadingFiles = ({ files, state, onRemove = () => {}, dark }) => {
  return state === FILE_UPLOAD_STATE.UPLOADING ? (
    <div className="upload-modal__uploading">
      {files.map((file) => (
        <ItemRow
          key={file.fileName}
          progressBar={file.uploadPercent}
          item={{ value: file.fileName }}
          onRemove={() => onRemove(file.fileName)}
          dark
        />
      ))}
    </div>
  ) : null
}

UploadingFiles.propTypes = {
  files: PropTypes.array.isRequired,
  state: PropTypes.number.isRequired,
  onRemove: PropTypes.func,
}

export default UploadingFiles
