import { STORAGE_MENU_STATE } from './constants'

export const ACTIONS = {
  SET_LEFT_MENU: 'leftMenu.setLeftMenu',
  EXPAND_COLLAPSIBLE_DRAWER: 'leftMenu.expandLeftMenuDrawer',
}

/**
 * Sets the state of the menu (only sets the fields it contains)
 * @param {Function} dispatch dispatch
 * @param {Object} menuState Left menu state
 * @param {Boolean} overrideWithLocalStorage Override the state of expanded collapsible drawers with values from local storage. Used when the state is not changed by explicitly expanding/collapsing the drawers
 */
export const setLeftMenu = async (
  dispatch,
  menuState,
  overrideWithLocalStorage = false
) => {
  let newMenuState = { ...menuState }

  // Check if there is an expanded drawer saved onto local storage. If so, override the menu state so it remains expanded
  if (overrideWithLocalStorage) {
    const localStorageJson = JSON.parse(
      localStorage[STORAGE_MENU_STATE] || null
    )

    const openDrawersKeys = localStorageJson?.selectedSlidingTabs?.reduce(
      (acc, { expanded, key }) => (expanded ? [...acc, key] : acc),
      []
    )
    if (openDrawersKeys?.length) {
      const newSelectedSLidingTabs = newMenuState.selectedSlidingTabs.map(
        (tab) =>
          openDrawersKeys.includes(tab.key) ? { ...tab, expanded: true } : tab
      )

      newMenuState = {
        ...newMenuState,
        selectedSlidingTabs: newSelectedSLidingTabs,
      }
    }
  }

  localStorage.setItem(STORAGE_MENU_STATE, JSON.stringify(newMenuState))

  dispatch({ type: ACTIONS.SET_LEFT_MENU, menuState: newMenuState })
}

/**
 * expand a collapsible drawer from the left menu's Analyctics tab
 * @param {Function} dispatch dispatch
 * @param {String} collapsibleDrawerKey key of collapsible drawer
 */
export const expandLeftMenuDrawer = (dispatch, collapsibleDrawerKey) =>
  dispatch({ type: ACTIONS.EXPAND_COLLAPSIBLE_DRAWER, collapsibleDrawerKey })
