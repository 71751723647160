import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import './style.scss'

/**
 * Color Swatch Component
 * @param {Object} params Component Params
 * @param {String} [params.className] Extra classnames to add to Color Swatch's container
 * @param {String} params.color Main color to display (hex or rgb)
 * @param {String} [params.secondaryColor] Secondary Color to display (hex or rgb)
 * @param {Function} [params.onClick] Callback for clicking the swatch
 * @param {Boolean} [params.active] Whether the Color Swatch is active
 */
const ColorSwatch = ({ className, color, secondaryColor, onClick, active }) => {
  const gradient = useMemo(() => {
    const _secondary = secondaryColor || color
    return `linear-gradient(180deg, ${color} 0%, ${color} 50%, ${_secondary} 50%, ${_secondary} 100%)`
  }, [color, secondaryColor])

  return (
    <div
    data-testid="color-swatch-container"
      className={cx('color-swatch__container', {
        [className]: className,
        'color-swatch__container--active': Boolean(active),
        'color-swatch__container--hoverable': Boolean(onClick),
      })}
    >
      <button
        data-testid="color-swatch-button"
        type="button"
        style={{ backgroundImage: gradient }}
        onClick={onClick ? onClick : () => {}}
        className="color-swatch"
      />
    </div>
  )
}

ColorSwatch.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
}

export default ColorSwatch
