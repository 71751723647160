import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useStore } from 'store'

import Button from 'components/button'

import './style.scss'

/**
 * Sticky footer component for rendering buttons
 * @param params
 * @param {Array} params.buttons list of buttons
 * @param {String} params.className class
 * @param {Node} params.children React Children
 * @returns {React.Component}
 */
function StickyFooter({ buttons, className, children }) {
  const { state } = useStore()
  const leftMenuExpanded = state.leftMenu?.opened
  return (
    <div
      data-testid={TEST_ID}
      className={cx('sticky-footer', {
        [className]: className,
        'sticky-footer--expanded-left-menu': leftMenuExpanded,
      })}
    >
      {children && (
        <div data-testid={CHILDREN_TEST_ID} className="sticky-footer__content">
          {children}
        </div>
      )}
      {buttons?.map((button, index) => {
        const {
          type = null,
          value,
          onClick,
          renderCondition,
          ...props
        } = button

        if (type) {
          props[type] = true
        }

        // Needed to avoid rendering in cases where 'renderCondition' is present but its value is undefined
        const renderConditionValue =
          ['renderCondition'] in button ? renderCondition : true

        return (
          renderConditionValue && (
            <Button
              key= {index}
              dataTestId={`${BUTTON_TEST_ID}-${index}`}
              onClick={onClick}
              value={value}
              {...props}
            />
          )
        )
      })}
    </div>
  )
}

StickyFooter.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      type: PropTypes.string,
      renderCondition: PropTypes.bool,
    })
  ),
  children: PropTypes.node,
  className: PropTypes.string,
}

export const TEST_ID = 'sticky-footer-container'
export const CHILDREN_TEST_ID = 'sticky-footer-children'
export const BUTTON_TEST_ID = 'sticky-footer-button'

export default StickyFooter
