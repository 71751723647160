import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ReactComponent as LoaderIcon } from 'assets/icon_ring_loader.svg'
import './style.scss'

/**
 * Displayes a loading spinner
 * @param {Object} props - React Props
 * @param {String} props.className - Extra class name
 * @param {Number} props.size - Specific size in pixels
 * @returns {React.ComponentElement}
 */
const Loader = ({ className, inline, size, ...otherProps }) => {
  const svgProps = {}
  if (size) {
    svgProps.width = size
    svgProps.height = size

    if (!otherProps.style) {
      otherProps.style = {}
    }

    otherProps.style.maxWidth = `${size}px`
    otherProps.style.maxHeight = `${size}px`
  }
  return (
    <div
      data-testid="three-q-loader"
      className={cx('three-q-loader align-center', {
        [className]: Boolean(className),
        'three-q-loader--inline': inline,
      })}
      {...otherProps}
    >
      <LoaderIcon data-testid="three-q-loader-icon" {...svgProps} />
    </div>
  )
}
Loader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  inline: PropTypes.bool,
}

export default Loader
