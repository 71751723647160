import React from 'react'
import PropTypes from 'prop-types'

import Table from 'components/table/beta'
import { AccountIcon } from 'components/account-icon/index'

/**
 * Excluded Campaigns Table Component
 * @param {Object} params React Params
 * @param {Array} params.campaigns Array of excluded campaigns to display
 * @param {Number} params.total Total number of excluced campaigns from all Campaign Exclusions
 * @param {Object} params.paginationData Pagination data object: contains table pagination data (current page, page length, etc)
 * @param {Function} params.setPaginationData Callback to update pagination data (current page, page length, etc) for data fetching purposes
 * @param {Boolean} params.loading Whether we have data loading or not
 */
const ExcludedCampaignsTable = ({
  campaigns,
  total,
  paginationData,
  setPaginationData,
  loading,
}) => {
  const columns = [
    {
      id: 'exclusion',
      header: 'Exclusion',
      accessorFn: (row) => row.campaignExclusion,
      textAlign: 'left',
      cellTextAlign: 'left',
      minSize: 280,
      maxSize: 280,
    },
    {
      id: 'campaign',
      header: 'Campaign',
      accessorFn: (row) => row.campaignName,
      textAlign: 'left',
      cellTextAlign: 'left',
      minSize: 400,
      maxSize: 400,
    },
    {
      id: 'campaignId',
      header: 'Campaign ID',
      accessorFn: (row) => row.campaignId,
      textAlign: 'left',
      cellTextAlign: 'left',
      minSize: 152,
      maxSize: 152,
    },
    {
      id: 'publisher',
      header: 'Publisher',
      accessorFn: (row) => row.publisher,
      cell: (cell) => <AccountIcon accountType={cell.row.original.publisher} />,
      textAlign: 'center',
      cellTextAlign: 'center',
      minSize: 120,
      maxSize: 120,
    },
    {
      id: 'account',
      header: 'Account',
      accessorFn: (row) => row.accountName,
      textAlign: 'left',
      cellTextAlign: 'left',
      minSize: 192,
      maxSize: 192,
    },
    {
      id: 'accountId',
      header: 'Account ID',
      accessorFn: (row) => row.accountId,
      textAlign: 'left',
      cellTextAlign: 'left',
      minSize: 220,
      maxSize: 220,
    },
  ]
  return (
    <Table
      id="excluded-campaigns-table"
      columns={columns}
      paginationValues={[10, 20, 50, 100]}
      showPagination={true}
      showSearchInput={false}
      initialState={paginationData}
      data={campaigns}
      manualCount={Math.ceil(total / paginationData.pageSize)}
      totalRows={total}
      onChangeTableView={({ pageSize, pageIndex, sort }) => {
        setPaginationData({
          pageSize,
          pageIndex,
          sort: sort?.reduce(
            (prev, column) => ({
              ...prev,
              [column.id]: column.desc ? 1 : -1,
            }),
            {}
          ),
        })
      }}
      manualPagination
      loading={loading}
    />
  )
}

ExcludedCampaignsTable.propTypes = {
  campaigns: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  paginationData: PropTypes.object.isRequired,
  setPaginationData: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default ExcludedCampaignsTable
