import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

/** Store */
import { useStore } from 'store'

/** Components */
import Button from 'components/button/index'

/** Actions */
import { logout } from 'modules/session/actions'

/** Utils */
import { FEATURE_NOT_IMPLEMENTED_TOOLTIP } from 'components/utils/tooltip'

/** Assets */
import iconCheck from 'assets/icon_check_white.svg'
import iconCross from 'assets/icon_x_close.svg'

/** Style */
import './style.scss'

/**
 * Shortcut card component
 * @param options
 * @param {String} options.description Text describing the shortcut
 * @param {Object} options.Icon Shortcut Icon as ReactComponent
 * @param {String} [options.iconType] special icon type, one of : ['avatar','embarq','logout']
 * @param {String} [options.redirectRoute] route where to redirect on click event
 * @param {Boolean} [options.disabled] disabled flag
 * @param {Boolean} [options.isLogout] flag for special logout shortcut, to display confirmation on click
 * @returns {Node}
 */
const ShortcutCard = ({
  description,
  Icon,
  iconType = '',
  redirectRoute = null,
  disabled = false,
  isLogout = false,
}) => {
  const { dispatch } = useStore()
  const [showConfirmLogout, setShowConfirmLogout] = useState(false)
  const navigate = useNavigate()

  const [showTooltip, setShowTooltip] = useState(false)

  const handleClick = () => {
    if (isLogout) {
      setShowConfirmLogout(true)
    } else {
      redirectRoute && navigate(redirectRoute)
    }
  }

  const handleMouseOver = () => {
    if (disabled) {
      setShowTooltip(true)
    }
  }

  const handleMouseLeave = () => {
    if (disabled) {
      setShowTooltip(false)
    }
  }

  return (
    <>
      <div
        className={cx('shortcut-card', {
          'shortcut-card--disabled': disabled,
          'shortcut-card--confirm-logout': showConfirmLogout,
        })}
        onClick={!disabled ? handleClick : null}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        <div className="shortcut-card__icon-wrapper">
          {showConfirmLogout ? (
            <>
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  logout(dispatch)
                }}
                isIconButton
                value={iconCheck}
                green
              />
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  setShowConfirmLogout(false)
                }}
                value={iconCross}
                isIconButton
                red
              />
            </>
          ) : (
            <Icon
              className={cx('shortcut-card__icon ', {
                [`shortcut-card__icon--${iconType}`]: iconType,
              })}
            />
          )}
        </div>
        <div className="shortcut-card__description">
          {showConfirmLogout ? 'Log out?' : description}
        </div>
        {showTooltip && (
          <div className="shortcut-card--disabled__tooltip">
            {FEATURE_NOT_IMPLEMENTED_TOOLTIP}
          </div>
        )}
      </div>
    </>
  )
}

ShortcutCard.propTypes = {
  description: PropTypes.string.isRequired,
  Icon: PropTypes.object.isRequired,
  iconType: PropTypes.string,
  redirectRoute: PropTypes.string,
  disabled: PropTypes.bool,
  isLogout: PropTypes.bool,
}

export default ShortcutCard
