import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/* Components */
import Input from 'components/input'
import { Dropdown } from 'components/dropdown'
import DropdownWithSubsections from 'components/dropdown-with-subsections/index'
import CalendarComp from 'components/calendar/index'
import DropdownTree from 'components/dropdown-tree'

import './style.scss'

/**
 * Section which composes the EntitySelector component.
 * It renders filtering options based on the given configuration.
 * If no configuration is given, it will not render anything.
 * @param {Object} props Component props
 * @param {Boolean} props.loading Loading state flag
 * @param {Array} [props.filters = []] Configuration for filters
 * @param {Object} props.selectedFilters Current filter states
 * @param {Function} props.onFiltersChange Callback to update filters
 * @returns {React.FunctionComponent}
 */
const EntitySelectorFilters = ({
  loading,
  filters,
  selectedFilters,
  onFiltersChange,
}) => {
  if (!filters.length) {
    return null
  }

  const renderInput = ({ fieldKey, type, label, placeholder, error }) => {
    return (
      <div key={fieldKey} className="entity-field">
        {label && (
          <div
            className={cx('general-label', {
              'general-label--error': error,
            })}
          >
            {label}
          </div>
        )}
        <Input
          disabled={loading}
          error={error}
          searchBlue={type === FILTER_TYPE.SEARCH}
          placeholder={placeholder}
          type={type === FILTER_TYPE.SEARCH ? 'text' : type.toLowerCase()}
          value={selectedFilters[fieldKey] || ''}
          onChange={onFiltersChange(fieldKey)}
        />
      </div>
    )
  }

  const renderDropdown = ({
    fieldKey,
    label,
    type,
    placeholder,
    options,
    deselectOption,
    selectAllOptions,
    showOptionsInPlaceholder,
    hideSelectAllOnSearch,
    selectAll,
    disableSearch,
    overwriteSelectedText,
    optionRenderer
  }) => {
    const isMultiselect = type === FILTER_TYPE.DROPDOWN_MULTISELECT
    const hasSubsections = type === FILTER_TYPE.DROPDOWN_SUBSECTIONS
    const isTreeDropdown = type === FILTER_TYPE.DROPDOWN_TREE

    return (
      <div key={fieldKey} className="entity-field">
        {label && <div className="general-label">{label}</div>}
        {!hasSubsections ? (
          isTreeDropdown ? (
            <DropdownTree
              disabled={loading}
              options={options}
              onChange={onFiltersChange(fieldKey)}
              selected={selectedFilters[fieldKey]}
            />
          ) : (
            <Dropdown
              loading={loading}
              disabled={loading}
              multiSelect={isMultiselect}
              selectAll={isMultiselect && selectAll}
              options={options}
              optionRenderer={optionRenderer}
              defaultOptionText={placeholder}
              deselectLabel={!isMultiselect ? deselectOption : undefined}
              onChange={onFiltersChange(fieldKey)}
              defaultState={selectedFilters[fieldKey] || ''}
              selectedItems={
                isMultiselect ? selectedFilters[fieldKey] : undefined
              }
              overwriteSelectedText={overwriteSelectedText}
            />
          )
        ) : (
          <DropdownWithSubsections
            disabled={loading}
            options={options}
            defaultOptionText={placeholder}
            disableSearch={disableSearch}
            selectAllOptions={
              selectAllOptions
                ? {
                    label: selectAllOptions.label,
                    allSelected: selectedFilters[fieldKey]
                      ? selectedFilters[fieldKey].length ===
                        options.filter((opt) => !opt.disabled).length
                      : false,
                    onCheck: (value) => {
                      return onFiltersChange(fieldKey)(
                        value
                          ? [
                              ...options
                                .filter((opt) => !opt.disabled)
                                .map((opt) => opt.value),
                            ]
                          : []
                      )
                    },
                  }
                : undefined
            }
            showOptionsInPlaceholder={showOptionsInPlaceholder}
            hideSelectAllOnSearch={hideSelectAllOnSearch}
            onChange={onFiltersChange(fieldKey)}
            defaultState={selectedFilters[fieldKey] || ''}
            selectedItems={selectedFilters[fieldKey]}
          />
        )}
      </div>
    )
  }

  const renderDate = ({
    fieldKey,
    minDate,
    maxDate,
    label,
    placeholder,
    error,
  }) => {
    return (
      <div key={fieldKey} className="entity-field">
        {label && (
          <div
            className={cx('general-label', {
              'general-label--error': error,
            })}
          >
            {label}
          </div>
        )}
        <CalendarComp
          icon
          placeholder={placeholder}
          date={selectedFilters[fieldKey] || ''}
          minDate={minDate}
          maxDate={maxDate}
          error={error}
          returnDate={onFiltersChange(fieldKey)}
        />
      </div>
    )
  }

  const renderCluster = ({ fieldKey, label, fields, className, error }) => {
    return (
      <div key={fieldKey}>
        {label && (
          <div
            className={cx('general-label', {
              'general-label--error': error,
            })}
          >
            {label}
          </div>
        )}
        <div className={className ? className : 'cluster-section'}>
          {fields.map((fieldConfig) => _renderFieldByType(fieldConfig))}
        </div>
      </div>
    )
  }

  const _renderFieldByType = (filterConfig) => {
    switch (filterConfig.type) {
      case FILTER_TYPE.SEARCH:
      case FILTER_TYPE.TEXT:
      case FILTER_TYPE.BUDGET:
        return renderInput(filterConfig)
      case FILTER_TYPE.DROPDOWN:
      case FILTER_TYPE.DROPDOWN_SUBSECTIONS:
      case FILTER_TYPE.DROPDOWN_MULTISELECT:
      case FILTER_TYPE.DROPDOWN_TREE:
        return renderDropdown(filterConfig)
      case FILTER_TYPE.DATE:
        return renderDate(filterConfig)
      case FILTER_TYPE.CLUSTER:
        return renderCluster(filterConfig)
    }
  }
  return (
    <div className="entity-selector__filters">
      {filters.map((filterConfig) => _renderFieldByType(filterConfig))}
    </div>
  )
}

export const FILTER_TYPE = {
  SEARCH: 'SEARCH',
  DROPDOWN: 'DROPDOWN',
  DROPDOWN_MULTISELECT: 'DROPDOWN_MULTISELECT',
  DROPDOWN_SUBSECTIONS: 'DROPDOWN_SUBSECTIONS',
  DROPDOWN_TREE: 'DROPDOWN_TREE',
  TEXT: 'TEXT',
  BUDGET: 'BUDGET',
  DATE: 'DATE',
  CLUSTER: 'CLUSTER',
}

export const FiltersOuterPropTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      fieldKey: PropTypes.string.isRequired,
      label: PropTypes.node,
      type: PropTypes.oneOf(Object.values(FILTER_TYPE)).isRequired,
      initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      placeholder: PropTypes.node,
      deselectOption: PropTypes.string,
      options: PropTypes.array,
      multiple: PropTypes.bool,
      selectAllOptions: PropTypes.object,
      showOptionsInPlaceholder: PropTypes.bool,
      hideSelectAllOnSearch: PropTypes.bool,
    })
  ),
}

EntitySelectorFilters.propTypes = {
  ...FiltersOuterPropTypes,
  loading: PropTypes.bool,
  selectedFilters: PropTypes.object,
  onFiltersChange: PropTypes.func.isRequired,
}

export default EntitySelectorFilters
