import { dataStore } from '@decision-sciences/qontrol-common'

const { DATA_STORE_FORMAT, DATA_STORE_ITEM_TYPE } = dataStore

export const DEFAULT_CATEGORY = {
  name: '',
  display: true,
  dimensions: [],
}

export const DEFAULT_DIMENSION = {
  id: null,
  dimension: null,
  type: DATA_STORE_ITEM_TYPE.DIMENSION,
  placement: null,
  format: DATA_STORE_FORMAT.ALPHABETIC,
  numberFormat: null,
  decimalPlaces: 0,
  categories: [],
}

export const DECIMAL_PLACES_OPTIONS = [0, 1, 2].map((value) => ({
  value,
  label: `${value}`,
}))

