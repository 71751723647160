import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { utils, budgetPacing } from '@decision-sciences/qontrol-common'

/* Components */
import Button from 'components/button'
import InputGroup from 'components/input-group'
import { CheckboxNoHooks } from 'components/checkbox'
import DropdownPreviewSelected from 'components/dropdown-preview-selected'
import DropdownWithSubsections from 'components/dropdown-with-subsections/index'

/* Icons */
import { ReactComponent as IconRemove } from 'assets/icon_remove_circle_outline.svg'
import { ReactComponent as PlusIcon } from 'assets/icon_plus_blue.svg'

/* Utils */
import { ACCOUNT_TYPE_ICONS } from 'constants/account.js'
import {
  defaultDimensionGroupOption,
  getCustomDimensionOptions,
  getDimensionInputs,
  getIconBySource,
  getInputTextTag,
} from '../../utils'

const { isEmpty } = utils.object
const { DATA_WAREHOUSE } = budgetPacing

const DimensionInputGroups = ({
  budget,
  error = {},
  clientDimensions,
  onBudgetChanged,
}) => {
  const updateBudget = (updatedBudget, error) => {
    onBudgetChanged({
      budget: updatedBudget,
      error,
    })
  }
  const updateDimension = (dimension, error, groupIndex, index) => {
    const updatedBudget = { ...budget }
    // Access the object at the specified index
    const objectToUpdate = updatedBudget.dimensionGroups[groupIndex]

    // Modify the properties of the object
    if (index || index === 0) {
      objectToUpdate.dimensionGroupOptions[index] = { ...dimension }
    } else {
      objectToUpdate.dimensionGroupOptions.push(dimension)
    }

    // Assign the updated object back to the same index in the array
    updatedBudget.dimensionGroups[groupIndex] = objectToUpdate

    updateBudget(updatedBudget, error)
  }

  const onDimensionChange = (value, source, groupIndex, index) => {
    const order = clientDimensions[source][value].dimensionOrder
    const updatedDimension = {
      dimensionName: value,
      dimensionInput: [],
      source: source,
    }
    if (order) {
      updatedDimension.dimensionOrder = order
    }
    updateDimension(updatedDimension, error, groupIndex, index)
  }

  const onDimensionInputChange = (value, dimension, groupIndex, index) => {
    const updatedDimension = { ...dimension }
    updatedDimension.dimensionInput = value
    const dimensionErrors = { ...error }
    if (dimensionErrors?.dimensionErrors?.[groupIndex]?.[index]) {
      delete dimensionErrors?.dimensionErrors?.[groupIndex]?.[index]
    }
    updateDimension(updatedDimension, dimensionErrors, groupIndex, index)
  }

  const removeDimension = (groupIndex, index) => {
    const updatedBudget = { ...budget }
    updatedBudget.dimensionGroups[groupIndex].dimensionGroupOptions.splice(
      index,
      1
    )
    if (
      !updatedBudget.dimensionGroups[groupIndex].dimensionGroupOptions.length
    ) {
      updateDimension(defaultDimensionGroupOption, error, groupIndex)
    }

    const dimensionErrors = { ...error }
    if (dimensionErrors?.dimensionErrors?.[groupIndex]?.[index]) {
      delete dimensionErrors?.dimensionErrors?.[groupIndex]?.[index]
    }

    updateBudget(updatedBudget, { ...dimensionErrors })
  }
  const addDimension = (index) => {
    updateDimension(defaultDimensionGroupOption, error, index)
  }

  const removeDimensionInput = (dimension, value, groupIndex, index) => {
    const updatedDimension = { ...dimension }
    updatedDimension.dimensionInput = updatedDimension.dimensionInput.filter(
      (string) => string !== value
    )
    updateDimension(updatedDimension, error, groupIndex, index)
  }

  const _renderDimensionInputOption = (option, selectedItems, dimension) => {
    const customRender = dimension.source === DATA_WAREHOUSE
    const { hint, label, accountType } = option
    const Icon = customRender ? ACCOUNT_TYPE_ICONS[accountType] : null

    return (
      <div>
        <CheckboxNoHooks
          label={label}
          isChecked={selectedItems.indexOf(option.value) > -1}
          icon={Icon ? <Icon width={20} height={20} /> : null}
          hint={hint}
        />
      </div>
    )
  }

  const _renderSelectedDimensions = (dimension, groupIndex, index) => {
    const { dimensionInput } = dimension

    return (
      <div className="display-flex">
        {dimensionInput.slice(0, 3).map((el) => {
          return getInputTextTag(
            dimension,
            el,
            () => removeDimensionInput(dimension, el, groupIndex, index),
            clientDimensions
          )
        })}
        {dimensionInput.length > 3 ? (
          <span className="text-link margin-auto">
            + {dimensionInput.length - 3} More
          </span>
        ) : null}
      </div>
    )
  }

  const _renderDimensionOption = (dimension, index, groupIndex) => {
    const existingDimensions = budget.dimensionGroups[
      groupIndex
    ].dimensionGroupOptions.filter((_, i) => i !== index)
    const isDisabledPlusIcon =
      !dimension.dimensionName && dimension.dimensionInput < 1
    const dimensionInputs = getDimensionInputs(dimension, clientDimensions)
    return (
      <InputGroup
        key={`${index}_${groupIndex}`}
        className="dimension-input-group"
        options={[
          {
            render: (
              <DropdownWithSubsections
                options={getCustomDimensionOptions(
                  clientDimensions,
                  existingDimensions
                )}
                defaultOptionText="Select Dimension"
                onChange={(value, source) =>
                  onDimensionChange(value, source, groupIndex, index)
                }
                multiSelect={false}
                selectedItems={dimension.dimensionName}
                disableSearch
                customIcon={getIconBySource(dimension)}
              />
            ),
            width: 300,
          },
          {
            render: (
              <DropdownPreviewSelected
                options={dimensionInputs || []}
                selectedItems={dimension.dimensionInput || []}
                selectAll={true}
                optionRenderer={(option, selectedItems) =>
                  _renderDimensionInputOption(option, selectedItems, dimension)
                }
                onChange={(v) =>
                  onDimensionInputChange(v, dimension, groupIndex, index)
                }
                error={
                  error?.dimensionErrors?.[groupIndex]?.[index] &&
                  dimension.dimensionInput.length === 0
                }
                defaultOptionToShow={_renderSelectedDimensions(
                  dimension,
                  groupIndex,
                  index
                )}
                placeholder="Select Dimension Inputs"
              />
            ),
            error:
              error?.dimensionErrors?.[groupIndex]?.[index] &&
              dimension.dimensionInput.length === 0,
          },
          {
            render: (
              <IconRemove
                onClick={() => removeDimension(groupIndex, index)}
                className="margin-auto cursor--pointer"
              />
            ),
            width: 50,
          },
          {
            render: (
              <PlusIcon
                onClick={() => !isDisabledPlusIcon && addDimension(groupIndex)}
                className={cx('margin-auto cursor--pointer', {
                  disabled: isDisabledPlusIcon,
                })}
              />
            ),
            width: 45,
          },
        ]}
      />
    )
  }
  const _renderDimensionGroupOptions = (group, groupIndex) => {
    return (
      <div key={groupIndex}>
        <div className="dimension-input-groups">
          <div className="dimension-input-groups__labels display-flex">
            <div className="general-label">Dimension</div>
            <div
              className={cx('general-label', {
                'general-label--error': !isEmpty(
                  error?.dimensionErrors?.[groupIndex]
                ),
              })}
            >
              Dimension Inputs
            </div>
          </div>

          {group?.dimensionGroupOptions.map((option, index) =>
            _renderDimensionOption(option, index, groupIndex)
          )}
        </div>

        {groupIndex < budget?.dimensionGroups.length - 1 ? (
          <Button
            className="budget-button-value button-selected margin-top-16"
            value={'OR'}
          />
        ) : null}
      </div>
    )
  }

  return (
    <>
      {budget.dimensionGroups.map((dimension, index) =>
        _renderDimensionGroupOptions(dimension, index)
      )}
    </>
  )
}
export default DimensionInputGroups
DimensionInputGroups.propTypes = {
  budget: PropTypes.object.isRequired,
  onBudgetChanged: PropTypes.func.isRequired,
  error: PropTypes.object,
  clientDimensions: PropTypes.object,
}
