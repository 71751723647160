import React, { useState, useEffect, useMemo, useContext } from 'react'
import useSession from 'modules/session'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import { userPropType } from 'components/utils/prop-types'
import { createUpdateVariable } from 'modules/calculated-metrics/actions'
import InputText from 'components/input'
import { FIELD_TYPES, validate, isPositiveNumber } from 'components/validator'
import Recurrence from 'components/recurrence'
import { Dropdown } from 'components/dropdown'
import StickyFooter from 'components/sticky-footer'
import useLeaveConfirm from 'components/leave-confirm'
import { editLockContext } from 'contexts/edit-lock'
import { NOT_FOUND_ROUTE } from 'routes'
import { getUserCompanies } from 'components/utils/user'
import DropdownWithSubsections from 'components/dropdown-with-subsections'
import { OwnerDropdown } from 'components/utils/owner'
import { getCompanies } from 'modules/companies/actions'
import { getUsersWithPermissions } from 'modules/users/actions'
import {
  access,
  permissions,
  utils,
  metrics,
  notifications,
  variables,
} from '@decision-sciences/qontrol-common'
import './style.scss'

const { hasAccess } = access
const { PERMISSIONS, PERMISSION_TYPES } = permissions
const { compareIgnoreCase, stringToID } = utils.string
const { METRICS, SPECIAL_METRICS, isDynamicMetric } = metrics
const { DATE_TYPE_MAP, FREQUENCY_OPTIONS } = notifications
const { getVariableStartEndDates } = variables
const { isEmpty } = utils.object

// Remove first element from frequency options (Minutes)
const frequencyWithoutMinutes = [...FREQUENCY_OPTIONS]
frequencyWithoutMinutes.shift()

const CreateEditVariable = ({
  variableKey,
  variables,
  calculatedMetrics,
  dispatch,
  userData,
}) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [autoKey, setAutoKey] = useState(true)
  const [customStartDate, setCustomStartDate] = useState(false)
  const existing = variableKey !== 'new'
  const [, user] = useSession()
  const [companies, setCompanies] = useState(null)
  const [setDirty, LeaveConfirm] = useLeaveConfirm({})
  const [possibleOwners, setPossibleOwners] = useState(null)

  /** Default State */
  const [state, setState] = useState({
    name: '',
    key: '',
    metric: '',
    dateType: DATE_TYPE_MAP.VALUE_FOR_THE_PAST,
    hasCalculatedMetric: false,
    recurrenceAmount: '',
    recurrenceType: '',
    startAmount: '',
    startType: '',
    isGlobal: false,
    owner: null,
  })
  const isUsedInTemplates = state.deletable === false
  const hasEditAccess = hasAccess(userData, [
    { feature: PERMISSIONS.VARIABLES, type: PERMISSION_TYPES.EDIT },
  ])
  const entityId = useMemo(
    () => variables?.find((variable) => variable.key === variableKey)?._id,
    [variableKey, JSON.stringify(variables)]
  )

  /** Edit locks */
  const { setEntityType, setEntityId, EDIT_LOCK_ENTITIES } =
    useContext(editLockContext)

  /**
   * Tell the Edit Lock context:
   * - what entity we're currently on
   * - the entity type we're currently on
   * - the actions to happen before unlocking the entity
   */
  useEffect(() => {
    if (existing && entityId) {
      setEntityId(entityId)
      setEntityType(EDIT_LOCK_ENTITIES.VARIABLE)

      /** It's important to clear everything when leaving the page */
      return () => {
        setEntityId(null)
        setEntityType(null)
      }
    }
  }, [entityId, existing])

  useEffect(() => {
    if (!companies) {
      getCompanies(dispatch, { deleted: false })
        .then((result) => {
          return setCompanies(result.companies)
        })
        .catch((e) => console.error(e))
    }
    if (existing) {
      getUsersWithPermissions([
        {
          feature: PERMISSIONS.VARIABLES,
          type: PERMISSION_TYPES.CREATE,
        },
      ]).then((res) => {
        res.list && setPossibleOwners(res.list)
      })
    }
  }, [])

  const userCompanies = getUserCompanies(user, companies)

  const allCompanies = useMemo(() => {
    return !isEmpty(userCompanies)
      ? userCompanies.map((company) => ({
          value: company._id,
          label: company.name,
          disabled: true, // Remove this to enable setting clients
          subsections: company.businessUnits.map((bu) => ({
            value: bu._id,
            label: bu.name,
            disabled: true, // Remove this to enable setting business units
          })),
        }))
      : []
  }, [JSON.stringify(userCompanies)])

  // Get clients
  const ownerCompanies = useMemo(() => {
    return user.isSuperAdmin
      ? allCompanies
      : allCompanies.reduce((companies, option) => {
          const foundClient = user.clients.find(
            ({ clientId }) => clientId === option.value
          )
          const newSubsections = option.subsections.reduce(
            (subsections, option) => {
              const disabled = !foundClient?.businessUnits.some(
                (buId) => buId === option.value
              )
              if (disabled) {
                return subsections
              }
              return [...subsections, option]
            },
            []
          )
          if (!foundClient) {
            return companies
          }
          return [
            ...companies,
            {
              ...option,
              subsections: newSubsections,
            },
          ]
        }, [])
  }, [JSON.stringify(allCompanies)])

  // Get clients names
  const allOptions = useMemo(() => {
    const allOptions = []
    ownerCompanies.forEach((option) => {
      if (!option.disabled) {
        allOptions.push(option.value)
        if (option?.subsections.length) {
          option.subsections.forEach(
            (subsection) =>
              !subsection.disabled && allOptions.push(subsection.value)
          )
        }
      }
    })
    return allOptions
  }, [JSON.stringify(ownerCompanies)])

  // Display Metrics + Calculated Metrics + Special Metrics
  const allMetrics = useMemo(() => {
    const filteredSpecialMetrics = Object.keys(SPECIAL_METRICS).filter(
      (metric) => !isDynamicMetric(metric) || !state.isGlobal
    )
    return Object.values(calculatedMetrics)
      .filter((metric) => {
        if (!state.isGlobal) {
          return true
        }

        return !metric.metrics.some(isDynamicMetric)
      })
      .map(({ key }) => key)
      .concat(Object.keys(METRICS))
      .concat(filteredSpecialMetrics)
      .sort(compareIgnoreCase)
  }, [calculatedMetrics, state.isGlobal])

  const onChangeMetric = (metric) => {
    const hasCalculatedMetric = !!Object.values(calculatedMetrics).find(
      (el) => el.key === metric
    )
    setDirty(true)
    setState({ ...state, metric, hasCalculatedMetric })
    setErrors({ ...errors, metric: null })
  }

  const onChangeStartType = (startType) => {
    setDirty(true)
    setState({ ...state, startType })
    setErrors({ ...errors, startType: null })
  }

  /** Edit mode - Listen for updates on the current variable */
  useEffect(() => {
    const existing = variableKey
      ? variables.find(
          (el) => el.key === variableKey || (state && el._id === state._id)
        )
      : null
    if (existing) {
      setState({
        ...existing,
        owner: existing.owner._id,
        ownerName: `${existing.owner.firstName} ${existing.owner.lastName}`,
      })
      setCustomStartDate(!!existing.startAmount)
      // Disable key auto-edit if variable is not deletable
      if (existing.deletable === false && autoKey) {
        setAutoKey(false)
      }
    }
    if (!existing && variableKey !== 'new') {
      return navigate(NOT_FOUND_ROUTE, true)
    }
  }, [variableKey, variables])

  /** On Global flag update, ensure that a previously-selected Metric is still valid */
  useEffect(() => {
    if (state.metric && !allMetrics.includes(state.metric)) {
      setState({ ...state, metric: null, hasCalculatedMetric: false })
    }
  }, [state.isGlobal, JSON.stringify(allMetrics)])

  /** Validate & Save handler */
  const onSave = (e) => {
    e.preventDefault()

    // Validate
    setLoading(true)
    let [isValid, errors] = validate(ERROR_MAP, state)

    // Validate custom start date separately
    if (customStartDate) {
      if (!isPositiveNumber(state.startAmount)) {
        isValid = false
        errors = { ...errors, startAmount: 'Required' }
      }
      if (!state.startType) {
        isValid = false
        errors = { ...errors, startType: 'This field is required' }
      }
    }

    if (!isValid) {
      setLoading(false)
      return setErrors(errors)
    }

    const variable = {
      ...state,
      startAmount: customStartDate ? state.startAmount : null,
    }

    createUpdateVariable(dispatch, variable)
      .then((res) => {
        if (!res.success) {
          return setErrors({
            ...errors,
            general: res.error?._message || 'Something went wrong',
          })
        }
        setDirty(false)
        // If success, redirect back to list page
        setTimeout(() => {
          navigate('/variables')
        }, 100)
      })
      .catch((err) => {
        if (typeof err === 'object') {
          setErrors({ ...errors, ...err })
        } else {
          setErrors({ ...errors, general: err })
        }
      })
      .finally(() => setLoading(false))
  }

  /** Edit Key field */
  const editKey = (value) => {
    setDirty(true)
    setState({ ...state, key: value })
    setErrors({ ...errors, key: null })
    setAutoKey(false)
  }

  /** Edit Name field */
  const editName = (value) => {
    setDirty(true)
    let keyField = state.key
    if (autoKey) {
      keyField = stringToID(value)
    }
    setState({ ...state, name: value, key: keyField })
    setErrors({ ...errors, name: null, key: autoKey ? null : errors.key })
  }

  const editStart = (value) => {
    // Prevent value to accept  0 as first character
    if (!value.toString().startsWith('0')) {
      setDirty(true)
      setState({ ...state, startAmount: value })
      setErrors({ ...errors, startAmount: null, startType: null })
    }
  }

  /** Change recurrence field */
  const onRecurrence = (recurrence) => {
    setDirty(true)
    const { amount, frequency, type: dateType } = recurrence
    setState({
      ...state,
      recurrenceAmount: amount,
      recurrenceType: frequency,
      dateType,
    })
    setErrors({ ...errors, recurrenceAmount: null, recurrenceType: null })
  }

  /** Calculate Variable target execution date */
  const variableDateInfo = useMemo(() => {
    const { startDate, endDate } = getVariableStartEndDates({
      ...state,
      startAmount: customStartDate ? state.startAmount : null,
    })
    const dateFormat = 'MM/dd/yyyy HH:mm'

    return (
      <div className="variables__target-section">
        <div>
          <div className="variables__label">start date & Time</div>
          <div className="variables__target-time-box ">
            {format(startDate, dateFormat)}
          </div>
        </div>
        <div>
          <div className="variables__label">end date & Time</div>
          <div className="variables__target-time-box  variables__target-time-box--right">
            {format(endDate, dateFormat)}
          </div>
        </div>
      </div>
    )
  }, [
    state.recurrenceAmount,
    state.recurrenceType,
    state.dateType,
    state.startAmount,
    state.startType,
  ])

  return (
    <form
      onSubmit={onSave}
      className={`form variables ${loading ? 'form--loading' : ''}`}
    >
      <Helmet>
        <title>{existing ? 'Edit Variable' : 'Create Variable'}</title>
      </Helmet>
      {/* Header */}
      <div className="heading" data-cy="page-heading">
        {existing ? 'Edit Variable' : 'Create Variable'}
      </div>
      {errors.general ? <div className="error">{errors.general}</div> : null}

      <section className="form__section">
        <LeaveConfirm />
        <div className="form__section__body">
          <div className="form__row">
            <InputText
              label="Variable Name"
              placeholder={'Enter Variable Name'}
              value={state.name}
              onChange={editName}
              error={errors.name}
              disabled={!hasEditAccess}
              className="form__half input-wrapper--uppercase"
            />
            <InputText
              label="Variable Key"
              placeholder={'Enter variable key'}
              value={state.key}
              onChange={editKey}
              error={errors.key}
              disabled={!hasEditAccess || isUsedInTemplates}
              className="form__half input-wrapper--uppercase"
            />
          </div>
          <div className="form__row">
            <Dropdown
              label="Metric"
              options={allMetrics}
              error={errors.metric}
              defaultState={state.metric}
              defaultOptionText="Select Metric"
              className="form__row form__half input-wrapper--uppercase"
              onChange={onChangeMetric}
              disabled={!hasEditAccess}
            />
            <DropdownWithSubsections
              label="Clients that can use this template"
              className="form__half input-wrapper--uppercase"
              selectedItems={state.isGlobal ? allOptions : state.companies}
              options={[
                {
                  value: null,
                  disabled: true,
                  label: 'On Roadmap',
                  noCheckbox: true,
                },
                ...ownerCompanies,
              ]}
              defaultOptionText="Select Client(s)"
              disabled={
                !hasEditAccess || isUsedInTemplates || ownerCompanies.length < 1
              }
              onChange={(value) => {
                setDirty(true)
                const newState = {
                  ...state,
                  companies: value,
                  isGlobal: false,
                }
                setState(newState)
              }}
              selectAllOptions={{
                label: 'All Clients',
                allSelected: state.isGlobal,
                ignoreDisabled: true,
                onCheck: (value) => {
                  setDirty(true)
                  const newState = { ...state, isGlobal: value }

                  if (value) {
                    newState.companies = []
                  }

                  setState(newState)
                },
              }}
            />
          </div>

          <div className="form__row">
            <div className="form__half">
              <label className="variables__label">
                Collect data for the following timespan
              </label>
              <Recurrence
                label="In the past"
                onChange={onRecurrence}
                showDateHoursFields={false}
                showTypeDropdown={false}
                frequencyOptions={frequencyWithoutMinutes}
                amountPlaceholder={'#'}
                errors={{
                  amount: errors.recurrenceAmount,
                  frequency: errors.recurrenceType,
                  type: errors.dateType,
                }}
                defaultState={{
                  amount: state.recurrenceAmount,
                  frequency: state.recurrenceType,
                  type: state.dateType,
                }}
                disabled={!hasEditAccess}
              />
            </div>
            <OwnerDropdown
              currentUser={user}
              isNew={!existing}
              allUsers={possibleOwners || []}
              selectedId={state.owner}
              onChange={(owner) => setState({ ...state, owner })}
              loading={!existing ? false : !possibleOwners}
              loadingText={state.ownerName}
            />
          </div>

          <div className="form__half form__column">
            <label className="variables__label">Start reference time</label>

            <div className="form__row--radio-row">
              <input
                type="radio"
                name="startTime"
                onChange={() => {
                  editStart('')
                  setDirty(true)
                  setCustomStartDate(false)
                }}
                checked={!customStartDate}
                disabled={!hasEditAccess}
              />
              <span
                className={`${customStartDate ? 'variables__disabled' : ''}`}
              >
                At runtime specified in alert
              </span>
            </div>

            <div className="">
              <input
                type="radio"
                name="startTime"
                onChange={() => {
                  setDirty(true)
                  setCustomStartDate(true)
                }}
                checked={customStartDate}
                disabled={!hasEditAccess}
              />
              <span
                className={`${customStartDate ? 'variables__disabled' : ''}`}
              >
                Set reference time prior to runtime specified in alert
              </span>

              {customStartDate && (
                <div className="recurrence">
                  <InputText
                    className="recurrence__amount"
                    onChange={editStart}
                    type="number"
                    placeholder={'#'}
                    min={0}
                    value={state.startAmount}
                    error={errors.startAmount}
                    disabled={!hasEditAccess || !customStartDate}
                  />

                  <Dropdown
                    options={frequencyWithoutMinutes}
                    defaultState={state.startType}
                    defaultOptionText="Time Frame"
                    error={errors.startType}
                    onChange={onChangeStartType}
                    disabled={!hasEditAccess}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <div className="form__section__body">
        <label className="variables__start-time--label">Target Period</label>
        <div className="variables__target-hint">
          The target period below gives an example of how the variable would run
          based on the timespan and start reference time selected above.
        </div>
        {variableDateInfo}
      </div>

      <StickyFooter
        buttons={[
          {
            value: existing ? 'Save Changes' : 'Save Variable',
            onClick: onSave,
            disabled: !hasEditAccess,
          },
          {
            value: 'Cancel',
            onClick: () => navigate('/variables'),
            secondaryGray: true,
            disabled: !hasEditAccess,
          },
        ]}
      />
    </form>
  )
}

CreateEditVariable.propTypes = {
  variableKey: PropTypes.string,
  variables: PropTypes.array.isRequired,
  calculatedMetrics: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  userData: userPropType.isRequired,
}

const ERROR_MAP = {
  key: [FIELD_TYPES.REQUIRED, FIELD_TYPES.ID],
  name: FIELD_TYPES.REQUIRED,
  metric: FIELD_TYPES.REQUIRED,
  recurrenceAmount: FIELD_TYPES.REQUIRED,
  recurrenceType: FIELD_TYPES.REQUIRED,
}

export default CreateEditVariable
