import Api from 'easy-fetch-api'

/**
 * Gets all accounts for a specific client
 * @param {*} clientId Company Id
 */
export const getAccounts = async (clientId) =>
  new Promise((resolve, reject) => {
    Api.get({
      url: `/api/trade-desk/client-caccounts/${clientId}`,
    }).then((res) => {
      if (!res || res.error) {
        reject(res.error)
      } else {
        resolve(res.data)
      }
    })
  })

/**
 * Gets all campaigns for a specific client
 * @param {*} clientId Company Id
 * @param {Object} signal - abort signal
 */
export const getCampaigns = async (clientId, signal) =>
  new Promise((resolve, reject) => {
    Api.get({
      url: `/api/trade-desk/client-campaigns/${clientId}`,
      signal,
    }).then((res) => {
      if (!res || res.error) {
        reject(res?.error)
      } else {
        resolve(res.data)
      }
    })
  })

/**
 * Gets all adgroups for a specific account - campaign
 * @param {*} companyId Company Id
 * @param {*} adGroupId Campaign Id
 * @param {Object} signal - abort signal
 */
export const getAdGroups = async (companyId, signal, adGroupId) =>
  new Promise((resolve, reject) => {
    Api.get({
      url: `/api/trade-desk/client-ad-groups/${companyId}/${adGroupId}`,
      signal,
    }).then((res) => {
      if (!res || res.error) {
        reject(res?.error)
      } else {
        resolve(res.data)
      }
    })
  })

/**
 * Gets all ads for a specific campaign and adset
 * @param {String} companyId Company Id
 * @param {String} [campaignId] Campaign Id
 * @param {Object} signal - abort signal
 */
export const getAds = async (companyId, signal, campaignId) =>
  new Promise((resolve, reject) => {
    Api.get({
      url: `/api/trade-desk/client-ads/${companyId}/${campaignId}`,
      signal,
    }).then((res) => {
      if (!res || res.error) {
        reject(res?.error)
      } else {
        resolve(res.data)
      }
    })
  })
