import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useStore } from 'store'
import { getAlertCategories } from 'modules/alerts/actions'
import AlertCategoriesModule from 'modules/alert-categories'
import Button from 'components/button'
import Loader from 'components/loader'
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'

const AlertCategoriesPage = () => {
  const { dispatch, state } = useStore()
  const { categories: list, query } = state.alerts
  const [selectedItem, setSelectedItem] = useState(null)
  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.ALERT_CATEGORIES,
    type: PERMISSION_TYPES.CREATE,
  })

  /** Save Alert Category callback */
  const onAlertSaved = () => {
    getAlertCategories(dispatch).then(() => {
      setSelectedItem(null)
    })
  }

  /** On Page mount, get data  */
  useEffect(() => {
    if (!list || query) {
      getAlertCategories(dispatch)
    }
  }, [])

  return (
    <section data-cy="alert-categories-page">
      <div className="page">
        {list ? (
          <>
            <Helmet>
              <title>Alert Categories</title>
            </Helmet>
            <div className="heading" data-cy="page-heading">
              Alert Categories
              {hasCreateAccess ? (
                <div className="heading__buttons">
                  <Button
                    value="Add Alert Category"
                    onClick={() => setSelectedItem({})}
                  />
                </div>
              ) : null}
            </div>
            <AlertCategoriesModule
              list={list}
              selectedItem={selectedItem}
              onSave={onAlertSaved}
              onItemSelected={(item) => setSelectedItem(item)}
              onModalClose={() => setSelectedItem(null)}
            />
          </>
        ) : (
          <Loader />
        )}
      </div>
    </section>
  )
}

export default AlertCategoriesPage
