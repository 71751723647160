import { format } from 'date-fns'
import { accounts, flow, utils } from '@decision-sciences/qontrol-common'
import { BILLING_EVENTS } from 'modules/flow/components/facebook/adset/facebook-adset-optimization/constants'
import {
  INITIAL_URL_PARAMS,
  AD_DESTINATION_TYPE,
  BUDGET_TYPE_OPTIONS,
} from 'modules/facebook'
import {
  TYPES_OF_AD_CONTENT,
  AD_CONTENT_DEFAULTS,
} from 'modules/flow/constants'
import { weekDays } from 'components/schedule/utils'

import { CONNECTION_AUDIENCE_TABS } from './index'

const { ACCOUNT_TYPES_MAP } = accounts
const { BID_STRATEGY_TYPE } = flow.facebook
const { addHoursToDate } = utils.date
const { CAMPAIGN_STATUS, AD_STATUS } = flow.facebook.status
const { FACEBOOK } = ACCOUNT_TYPES_MAP

const today = new Date()
//  Start Time: user’s current time + 3h
const startTime = addHoursToDate(today, 3)

export const CAMPAIGN_DEFAULTS = {
  id: null,
  name: null,
  type: FACEBOOK,
  status: CAMPAIGN_STATUS.PAUSED,
  account: '',
  deleted: false,
  copiedId: null,
  selectedExperience: null,
  data: {
    adsDeliveryType: false,
    appInstallsType: null,
    bidStrategy: null,
    budgetAmount: null,
    budgetType: null,
    buyingType: null,
    catalog: null,
    objective: null,
    scheduledAds: false,
    spendingLimit: null,
    store: null,
    budgetFlag: false,
    ios14Flag: false,
    iosAppId: '',
    noIOSApps: null,
    iosAppUrl: '',
    specialAdCategories: null,
    specialAdCategoryCountry: null,
    specialAdCategoryPage: null,
    specialAdCategoryPaid: '',
  },
}

export const SAVED_AUDIENCE_DEFAULTS = {
  connections: {
    allPeople: true,
    customAudiences: {
      local: [],
      remote: [],
    },
    connectionType: {
      selected: 0,
      selectedTab: CONNECTION_AUDIENCE_TABS.fbPages,
      options: {
        connections: [],
        excluded_connections: [],
        friends_of_connections: [],
        fbPages: {
          people: true,
          friends: false,
          exclude: false,
          selected: [],
        },
        apps: {
          people: true,
          friends: false,
          exclude: false,
          selected: [],
        },
        events: {
          people: true,
          exclude: false,
          selected: [],
        },
        advanced: {
          people: [],
          friends: [],
          exclude: [],
        },
      },
    },
  },
  inclusions: [],
  exclusions: [],
  detailedTargeting: {
    selectedItems: [],
  },
  locations: {
    type: 'home#recent',
    ageMin: '13',
    ageMax: '65+',
    locationsArray: [],
    gender: 0,
  },
  catalogSalesAudienceType: null,
  limitToEventsFromProductSet: false,
  savedAudienceLanguages: [],
}

export const ADSET_DEFAULTS = {
  id: null,
  campaignId: null,
  accountId: null,
  name: null,
  type: 'facebook',
  budget: {
    type: BUDGET_TYPE_OPTIONS[0].value,
    amount: '',
    budgetFlag: true,
  },
  deleted: false,
  copiedId: null,
  schedule: {
    startDate: format(today, 'MM/dd/yyyy'),
    startTime: format(startTime, `hh:mm a`),
    endDate: '',
    endTime: '',
    adSchedule: {
      isScheduled: null,
    },
    adSpendLimits: {
      hasSpendLimits: false,
    },
  },
  placements: {
    automatic: true, // true/false
    manual: {
      device_platforms: { mobile: true, desktop: true },
      publisher_platforms: {},
      list: {}, // holds the entire manual placements selection
    },
  },
  exclusions: {},
  blockListIds: [],
  adSetOptions: {
    optimization: null,
    customFrequencyCap: {
      enabled: true,
      impressions: 1,
      days: 7,
    },
    bidStrategy: BID_STRATEGY_TYPE.COST_CAP,
    deliveryType: 'Standard',
    appEvent: null,
    appToPromote: {
      platform: null,
      appId: null,
      objectStoreUrl: null,
      customAppEvents: [],
      defaultAppEvents: [],
    },
    eventType: null,
    whenYouGetCharged: BILLING_EVENTS.IMPRESSIONS.value,
    conversionWindow: null,
    conversionEvent: {
      type: '',
      platform: null,
      appId: null,
      objectStoreUrl: null,
      customAppEvents: [],
      defaultAppEvents: [],
    },
    facebookPage: null,
    whatsAppPage: null,
    instagramAccount: null,
    messengerMethod: null,
  },
  adSetAudience: { ...SAVED_AUDIENCE_DEFAULTS },
  // this field will be used if we save audiences on adSet
  savedAudiences: {
    local: null,
    remote: null,
  },
  customAudiences: {
    local: [],
    remote: [],
  },
  useBidMultiplier: false,
  bidMultiplier: {
    custom_audience: {},
    booking_window: [],
    travel_start_date: [],
    start_days: [],
    user_recency: [],
    length_of_stay: [],
    position_type: [],
    ages: [],
  },
}

export const AD_DEFAULTS = {
  id: null,
  adSetId: null,
  name: null,
  accountId: null,
  type: 'facebook',
  status: AD_STATUS.ACTIVE,
  deleted: false,
  placements: null,
  identity: {
    page_id: null,
    instagram_actor_id: null,
    use_page_actor_override: false,
    isMainPage: true,
  },
  destination: {
    type: AD_DESTINATION_TYPE.STORE_LOCATOR,
    websiteURL: '',
    displayLink: '',
    callToActionButton: '',
    showCallExtension: false,
  },
  hasWebsiteUrl: false,
  websiteUrl: {
    url: '',
    buildUrlParam: false,
    needsDestination: false,
    displayLink: '',
    primaryDestination: '',
    secondaryDestination: '',
    callToActionBtn: null,
    ...INITIAL_URL_PARAMS,
  },
  templateId: null,
  conversionTracking: {
    pixelId: '',
    conversionsEvent: null,
    appEvents: null,
    offlineEvents: null,
    urlParams: '',
    buildUrlParam: false,
    ...INITIAL_URL_PARAMS,
  },
  adSetup: {
    type: 'Create Ad',
    format: {
      singleImageOrVideo: false,
      carousel: false,
      collection: false,
    },
    experience: {
      instant: {
        selected: false,
        template: null,
        search: null,
      },
      playableSourceFile: {
        selected: false,
        file: null,
      },
    },
    singleImageOrVideoOptions: {
      website: {
        selected: true,
        website: '',
        linkDisplayText: '',
      },
      facebookEvent: {
        selected: false,
        eventNameOrEventUrl: '',
      },
      phoneCall: {
        selected: false,
      },
    },
    carouselOptions: {
      bestPerformingCardsFirst: true,
      cardAtEndWithPageProfilePic: false,
      mapCardNearestStores: false,
    },
    seeMoreUrl: null,
    seeMoreDisplayText: null,
    deferredDeepLinkUrl: null,
    deepLinkWebsite: '',
    deepLinkParameters: [],
    mobileApp: null,
    deepLinkDestination: {
      webBrowser: true,
      mobileAppFallbackToUrl: false,
      mobileAppFallbackToAppStore: false,
    },
    catalogCardsCreativeOptions: {
      singleImage: true,
      slideshow: false,
    },
  },
  adContent: {
    selectedType: TYPES_OF_AD_CONTENT[FACEBOOK].PRIMARY_TEXT,
  },
  selectedItems: AD_CONTENT_DEFAULTS[FACEBOOK],
}

export const DEFAULT_AD_SET_SCHEDULE = {
  schedules: [
    {
      days: weekDays.map((day) => day.value),
      from: '',
      to: '',
    },
  ],
  timezone: 'USER',
}
