import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ReactComponent as ArrowIcon } from 'assets/icon_arrow_blue.svg'

import './style.scss'

const Arrow = ({ expanded, onChange, className, disabled }) => {
  const onClick = () => {
    if (disabled) {
      return
    }

    onChange(!expanded)
  }

  return (
    <div
      data-testid="up-down-arrow"
      className={cx('up-down-arrow', {
        'up-down-arrow--expanded': expanded,
        'up-down-arrow--disabled': disabled,
        [className]: className,
      })}
      onClick={onClick}
    >
      <ArrowIcon data-testid="up-down-arrow-icon" className="fill-light-blue" />
    </div>
  )
}

Arrow.propTypes = {
  onChange: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

export default Arrow
