import React from 'react'
import { Helmet } from 'react-helmet'
import { Outlet, useMatches } from 'react-router-dom'

import GlobalAppWrapper from 'modules/global/global-wrapper'

const IndexPage = () => {
  const matches = useMatches()

  const mainClasses = matches.reduce((prev, current) => {
    const mainClass = current.handle?.mainClass
    if (mainClass) {
      return `${prev} ${mainClass}`
    }
    return prev
  }, '')

  return (
    <GlobalAppWrapper mainClass={mainClasses}>
      <Helmet>
        <title>Qontrol</title>
      </Helmet>
      <Outlet />
    </GlobalAppWrapper>
  )
}

export default IndexPage
