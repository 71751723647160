import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'store'
import { Helmet } from 'react-helmet'

import PermissionGroupsModule from 'modules/permission-groups'

import {
  getPermissionGroups,
  deletePermissionGroup,
} from 'modules/permission-groups/actions'
import { TABLE_CONTAINER } from 'modules/table-filter-sort/constants'

import Button from 'components/button'
import Modal from 'components/modal'
import Loader from 'components/loader'

import { useAccess, PERMISSION_TYPES, PERMISSIONS } from 'hooks/access'
import useLeaveConfirm from 'components/leave-confirm'

/**
 * Entry point for permission group index page - shows a filterable table with all available entries
 * @returns {React.FunctionComponent}
 */
const PermissionGroupsIndexPage = () => {
  const tableContainer = TABLE_CONTAINER.PERMISSION_GROUPS
  const navigate = useNavigate()
  const { dispatch, state } = useStore()
  const {
    permissionGroups: { list },
  } = state
  const filter = state.tableFilterSort.filterSort[tableContainer].filter

  const [loading, setLoading] = useState(false)
  const [permissionGroupToDelete, setPermissionGroupToDelete] = useState(null)

  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.PERMISSION_GROUPS,
    type: PERMISSION_TYPES.CREATE,
  })
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.PERMISSION_GROUPS,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.PERMISSION_GROUPS,
    type: PERMISSION_TYPES.DELETE,
  })

  const [setDirty, LeaveConfirm] = useLeaveConfirm({})

  useEffect(() => {
    setLoading(true)
    getPermissionGroups(dispatch, filter).finally(() => setLoading(false))
  }, [JSON.stringify(filter)])

  if (!list) {
    return <Loader />
  }

  return (
    <section className="permission-groups">
      <LeaveConfirm />
      {permissionGroupToDelete && hasDeleteAccess && (
        <Modal
          opened={!!permissionGroupToDelete}
          button={
            <Button
              value="Delete"
              onClick={() => {
                setLoading(true)
                deletePermissionGroup(dispatch, permissionGroupToDelete._id)
                  .then(() => {
                    setDirty(false)
                    setTimeout(() => {
                      navigate('/permission-groups')
                      setPermissionGroupToDelete(null)
                    }, 100)
                  })
                  .catch((error) => {
                    console.error(error)
                    setPermissionGroupToDelete(null)
                  })
                  .finally(() => {
                    setLoading(false)
                  })
              }}
              disabled={loading}
            />
          }
          buttonSecondary={
            <Button
              value="Cancel"
              disabled={loading}
              onClick={() => setPermissionGroupToDelete(null)}
              secondaryGray
            />
          }
          heading={`Are you sure you want to delete the ${permissionGroupToDelete.name} Permission Group?`}
          className="alert-categories__modal"
        />
      )}
      <Helmet>
        <title>Permission Groups</title>
      </Helmet>
      <div className="heading" data-cy="page-heading">
        Permission Groups
        <div className="heading__buttons">
          {hasCreateAccess && (
            <Button
              value="Add Permission Group"
              onClick={() => {
                navigate('/permission-groups/new')
              }}
            />
          )}
        </div>
      </div>
      <PermissionGroupsModule
        list={list}
        onDelete={hasDeleteAccess ? setPermissionGroupToDelete : null}
        hasCreateAccess={hasCreateAccess}
        hasEditAccess={hasEditAccess}
        tableContainer={tableContainer}
        loading={loading}
      />
    </section>
  )
}

export default PermissionGroupsIndexPage
