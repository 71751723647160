import { ACTIONS as SESSION_ACTIONS } from 'modules/session/actions'
import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'modal'

/** Initial State of the reducer */
const initialState = {}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.OPEN_MODAL]: (state, { instance }) => {
    // If this instance does not have a valid uid, prevent opening it to not have orphaned modals
    if (!instance.uid) {
      return state
    }
    const { uid, ...props } = instance
    return { ...state, [uid]: { ...props, opened: true } }
  },
  [ACTIONS.CLOSE_MODAL]: (state, { uid: modalUid }) => {
    if (!modalUid || !state[modalUid]) {
      return state
    }
    delete state[modalUid]
    return state
  },
  [ACTIONS.CLOSE_ALL_MODALS]: () => {
    return { ...initialState }
  },
  [SESSION_ACTIONS.LOGOUT]: () => {
    return { ...initialState }
  },
}

export default { initialState, name, reduce }
