import React, { useState, useRef, useEffect } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { utils } from '@decision-sciences/qontrol-common'

/* Utils */
import {
  filterInputNumbers,
  filterInputOnlyNumber,
  filterNegativeNumber,
} from 'components/utils/filter-input-number'
import InformationBlock, {
  INFORMATION_BLOCK_TYPE,
} from 'components/information-block'

/* Assets */
import eyeIcon from 'assets/icon_password_eye.svg'
import eyeIconClosed from 'assets/icon_password_eye_closed.svg'
import { ReactComponent as SearchIcon } from 'assets/icon_search.svg'
import closeIcon from 'assets/icon_clear_blue.svg'
import dateIcon from 'assets/icon_date_range.svg'

import './style.scss'

const { isEmpty } = utils.object
const { formatValue } = utils.string
const { getUrlWithProtocol } = utils.url

const ALLOWED_INPUT_KEYS = new Set([
  'Backspace',
  'ArrowLeft',
  'ArrowRight',
  'Meta',
  'Control',
  'Alt',
])

const ALLOWED_PHONE_KEYS = new Set(['+', '(', ')', '-'])

/**
 * Input Text Component
 */
const InputText = React.forwardRef(
  (
    {
      onEnterKeyPressed,
      id,
      customRef,
      className,
      value,
      label,
      type,
      name,
      description,
      placeholder,
      disabled,
      onChange = () => {},
      onClick,
      tabIndex = '0',
      error,
      textOnlyError,
      errorClass,
      prefix,
      suffix,
      onKeyUp,
      onKeyDown,
      autoComplete,
      maxLength,
      min,
      max,
      blur,
      search,
      searchBlue,
      emptyOutField,
      hasCount,
      date,
      pattern,
      viewOnly,
      warning,
      hint,
      dark,
      white,
      onlyNumber = false,
      negativeNumber = false,
      showErrorMessage = true,
      showErrorInfoBlock = false,
      emptyFieldIcon = closeIcon,
      showPlaceholderError = false,
      labelTop = true,
      isSquare,
      hasChanges = false,
      isBordered = false,
      ...other
    },
    ref
  ) => {
    const [focused, setFocused] = useState(null)
    const [showPassword, setShowPassword] = useState(false)
    const inputRef = useRef()
    const isNumeric = type === 'number'
    const isPhone = type === 'phone'
    const isBudget = type === 'budget'
    const isUrl = type === 'url'
    const isRequiredError =
      error === true ||
      (!isEmpty(error) && error !== false
        ? error.toLowerCase().includes('required')
        : false)
    const placeholderError = error && (showPlaceholderError || isRequiredError)

    const getDisplayValue = (value, displayValue = '') => {
      if (isNumeric || isBudget) {
        if (isEmptyValue(value)) {
          return ''
        }
        if (!displayValue || value !== displayValue) {
          return formatValue(value)
        }
      }

      return value
    }

    const [displayedValue, setDisplayValue] = useState(getDisplayValue(value))

    useEffect(() => {
      if (isNumeric && value !== displayedValue) {
        if (!isEmptyValue(value)) {
          setDisplayValue(value?.toString() || '')
        }
        return
      }
      if (!displayedValue) {
        if (isBudget) {
          setDisplayValue(formatValue(value))
        } else {
          setDisplayValue(value?.toString() || '')
        }
      }
    }, [value])

    /** If the passed value gets reset, clear the displayed value */
    useEffect(() => {
      if ((isNumeric || isBudget) && isEmptyValue(value)) {
        setDisplayValue('')
      }
    }, [value, isNumeric, isBudget])

    const getValidValue = (value) => {
      if (isNumeric || isBudget) {
        let _value = isBudget
          ? parseFloat((value || '').replaceAll(',', ''))
          : value

        if (isNaN(_value)) {
          _value = min || ''
        }
        // Take min into account
        if (_value < min && typeof min === 'number') {
          _value = min
        }
        // Take max into account
        if (max && _value > max && typeof max === 'number') {
          _value = max
        }

        if (isBudget) {
          const stringValue = _value?.toString() || ''
          return stringValue
        }

        const finalValue = getLengthTrimmedValue(_value?.toString())

        return isNaN(finalValue) ? '' : finalValue
      }

      return getLengthTrimmedValue(value)
    }

    const onFocus = () => {
      setDisplayValue(value?.toString() || '')
      setFocused(true)
    }

    const actualizeValidValue = () => {
      // If we have a min value set, set it on blur in case there's no value
      const validValue = getValidValue(displayedValue)
      let newValue = validValue

      if (isBudget) {
        newValue = formatValue(validValue)
      }

      if (isUrl) {
        const urlWithProtocol = getUrlWithProtocol(value)
        if (urlWithProtocol && !disabled) {
          newValue = urlWithProtocol
        }
      }

      if (newValue !== value) {
        setDisplayValue(newValue?.toString() || '')
      }

      return newValue?.toString() || ''
    }

    const onBlur = () => {
      let newValue = actualizeValidValue()

      if (isBudget) {
        newValue = newValue.replace(/\,/g, '')
      }

      onChange(newValue)

      setFocused(false)
      blur && blur()
    }

    /** Key down listener - handle Enter press */
    const keyupListener = (ev) => {
      const code = ev.keyCode || ev.which
      if (code === 13) {
        ev.preventDefault()

        onEnterKeyPressed && onEnterKeyPressed(ev)
      }
    }

    const onPaste = (ev) => {
      // Validate the clipboard content that's pasted
      const value = getValidValue(ev.clipboardData.getData('Text'))

      if (isNumeric && !_isValidNumber(value)) {
        ev.preventDefault()
      }

      if (isBudget && !_isValidBudget(value)) {
        ev.preventDefault()
      }

      if (isPhone && !_isValidPhoneNumber(value)) {
        ev.preventDefault()
      }
    }

    /** Key down listener - filter out non-numeric characters for input type=number */
    const keydownListener = (ev) => {
      if (
        (ev.ctrlKey || ev.metaKey) &&
        ['c', 'v', 'z', 'a', 'x'].includes(ev.key.toLowerCase())
      ) {
        return
      }
      if ((isNumeric || type === 'budget') && !ALLOWED_INPUT_KEYS.has(ev.key)) {
        if (onlyNumber) {
          if (negativeNumber) {
            filterNegativeNumber(ev)
          } else {
            filterInputOnlyNumber(ev)
          }
        } else {
          filterInputNumbers(ev)
        }
      }

      // Allow the plus sign for phone number
      if (
        isPhone &&
        !ALLOWED_INPUT_KEYS.has(ev.key) &&
        !ALLOWED_PHONE_KEYS.has(ev.key)
      ) {
        filterInputNumbers(ev)
      }
    }

    /** On input change */
    const onInputChange = (ev) => {
      let newValue = ev.target.value

      if (isBudget) {
        newValue = newValue.replace(/\,/g, '')
      }

      newValue = getLengthTrimmedValue(newValue)

      onChange(newValue)
      setDisplayValue(newValue.toString())
    }

    const getLengthTrimmedValue = (value) => {
      if (!maxLength || !value) {
        return value
      }
      if (value.length > maxLength) {
        return value.substring(0, maxLength)
      }
      return value
    }

    const classes = cx('input-wrapper', {
      focused,
      'input-wrapper--disabled': disabled,
      'input-wrapper--viewOnly': viewOnly,
      'input-wrapper--dark': dark,
      'input-wrapper--white': white,
      'input-wrapper--error': !!error,
      'input-wrapper--search': search || searchBlue,
      'input-wrapper--has-prefix': !!prefix,
      'input-wrapper--has-suffix': !!suffix,
      'input-wrapper--has-warning': !!warning,
      'input-wrapper--label-top': !!labelTop,
      'input-wrapper--square': !!isSquare,
      'input-wrapper--is-bordered': !!isBordered,
      'input-wrapper--has-changes': isBordered && !!hasChanges,
      'input-wrapper--is-filled': isBordered && !!value,
    })
    const wrapperClasses = cx('wrapper', className)

    return (
      <div
        data-testid="input-wrapper-block"
        className={wrapperClasses}
        {...other}
      >
        <div data-testid="input-main-block" className={classes}>
          {label && (
            <div
              data-testid="input-label-parent-block"
              className="label-wrapper"
            >
              <label
                data-testid="input-label-block"
                className={cx({
                  'color-red-important':
                    isRequiredError && error && !description,
                })}
                data-cy="input-text-label"
              >
                {label}
              </label>
            </div>
          )}
          {description && <div className="secondary-text">{description}</div>}
          <div className="wrapper__prefix" ref={ref}>
            {prefix && (
              <span
                data-testid="input-prefix-block"
                className={cx('prefix', {
                  'error-text': error,
                })}
              >
                {prefix}
              </span>
            )}
            <input
              data-cy="input-text-input"
              data-testid="input"
              id={id}
              ref={customRef ? customRef : inputRef}
              tabIndex={tabIndex}
              type={showPassword ? 'text' : type || 'text'}
              name={name}
              pattern={isBudget ? budgetPattern : pattern}
              className={cx('input-text', {
                'error-placeholder': placeholderError || textOnlyError,
                'color-red-important': error || textOnlyError,
              })}
              placeholder={placeholder || ''}
              value={isBudget || isNumeric ? displayedValue : value}
              disabled={disabled || viewOnly ? 'disabled' : undefined}
              onClick={!disabled ? onClick : () => {}}
              onChange={!disabled ? onInputChange : () => {}}
              onFocus={(...args) => {
                onFocus(...args)

                other.onFocus && other.onFocus(...args)
              }}
              onPaste={onPaste}
              onBlur={onBlur}
              onKeyDown={onKeyDown || keydownListener}
              onKeyUp={onKeyUp || keyupListener}
              autoComplete={autoComplete}
              aria-autocomplete={autoComplete}
              maxLength={maxLength}
              onWheel={(e) => {
                if (type === 'number') {
                  e.target.blur()
                }
              }}
              {...(isNumeric && { min })}
              {...(isNumeric && max && { max })}
              autoFocus={other.autoFocus}
              dir="auto"
            />
            {suffix && (
              <span
                data-testid="input-suffix-block"
                className={cx('suffix', {
                  'error-text': error,
                })}
              >
                {suffix}
              </span>
            )}
            {warning && (
              <span data-testid="input-warning-block" className={'warning'}>
                {warning}
              </span>
            )}
            {type === 'password' && (
              <img
                data-testid="input-password-image"
                src={showPassword ? eyeIconClosed : eyeIcon}
                className={`pass-eye ${showPassword ? ' closed' : ''}`}
                alt="see password"
                onMouseDown={() => setShowPassword(!showPassword)}
                onMouseUp={() => {
                  if (showPassword) {
                    setShowPassword(!showPassword)
                  }
                }}
                onMouseMove={() => showPassword && setShowPassword(false)}
                onMouseOut={() => showPassword && setShowPassword(false)}
              />
            )}
            {search && (
              <SearchIcon
                className="search fill-light-blue"
                alt="search glass"
              />
            )}
            {searchBlue && (
              <SearchIcon
                className={cx('search search--blue fill-light-blue', {
                  'search--blue-with-label': label,
                })}
                alt="search glass"
              />
            )}
            {emptyOutField && (
              <img
                src={emptyFieldIcon}
                className="empty-out"
                alt="search glass"
                onClick={() => onChange('')}
              />
            )}
            {date && (
              <img
                height="24px"
                width="24px"
                src={dateIcon}
                className="date cursor--default"
                alt="date"
                onClick={() => onClick && onClick()}
              />
            )}
          </div>
        </div>
        {error || hasCount || hint ? (
          <div
            data-testid="input-bottom-wrapper-block"
            className="wrapper__bottom"
          >
            {!error && hint && (
              <div data-testid="input-hint-block" className="hint">
                {hint}
              </div>
            )}
            {error && showErrorMessage && !showErrorInfoBlock && (
              <div
                data-testid="input-error-block"
                className={cx('error', errorClass)}
              >
                {!isRequiredError ? error : ''}
              </div>
            )}
            {hasCount ? (
              <div data-testid="input-count-block" className="count">{`${
                value?.length || 0
              }${maxLength ? `/${maxLength}` : ''}`}</div>
            ) : null}
          </div>
        ) : null}
        {error &&
          typeof error === 'string' &&
          showErrorMessage &&
          showErrorInfoBlock && (
            <InformationBlock
              style={
                hasCount ? { marginBottom: '10px' } : { marginTop: '10px' }
              }
              type={INFORMATION_BLOCK_TYPE.ERROR}
              info={error}
            />
          )}
      </div>
    )
  }
)

// Pattern for input type budget for number with decimals
const budgetPattern = '^([0-9]+.?[0-9]*|.[0-9]*)$'

const isEmptyValue = (value) => {
  return value === null || value === undefined || value === ''
}

/**
 * Check if a string can be part of a phone number
 * @param {String | Number} string String to check
 * @returns {Boolean}
 */
const _isValidNumber = (string) => !!string.match(/^([+()\-0-9]*)$/)

/**
 * Check if a string can be part of a budget
 * @param {String | Number} string String to check
 * @returns {Boolean}
 */
const _isValidBudget = (string) => !!string.match(new RegExp(budgetPattern))

/**
 * Check if a string can be part of a phone number
 * @param {String | Number} string String to check
 * @returns {Boolean}
 */
const _isValidPhoneNumber = (string) => !!string.match(/^([+()\-0-9]*)$/)

InputText.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.any,
  customRef: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onEnterKeyPressed: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  textOnlyError: PropTypes.bool,
  errorClass: PropTypes.string,
  prefix: PropTypes.any,
  tabIndex: PropTypes.string,
  suffix: PropTypes.any,
  autoComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  min: PropTypes.number,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  blur: PropTypes.func,
  search: PropTypes.bool,
  searchBlue: PropTypes.bool,
  dark: PropTypes.bool,
  white: PropTypes.bool,
  emptyOutField: PropTypes.bool,
  hasCount: PropTypes.bool,
  date: PropTypes.bool,
  pattern: PropTypes.string,
  viewOnly: PropTypes.bool,
  warning: PropTypes.any,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onlyNumber: PropTypes.bool,
  negativeNumber: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
  showErrorInfoBlock: PropTypes.bool,
  emptyFieldIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      type: PropTypes.oneOf(['img', 'svg']),
    }),
  ]),
  showPlaceholderError: PropTypes.bool,
  labelTop: PropTypes.bool,
  isSquare: PropTypes.bool,
  hasChanges: PropTypes.bool,
  isBordered: PropTypes.bool,
}

InputText.displayName = 'InputText'

export default InputText
