import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/button'
import LineDivider from 'components/line-divider'

import { ReactComponent as IconSave } from 'assets/icon-save-white.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon_delete_white.svg'

import './style.scss'

/**
 * Renders the create campaign exclusions actions bar
 * @param {Boolean} props
 * @param {Boolean} props.editMode - Flag specifying if it's edit mode or not
 * @param {Boolean} props.loading - Loading state to disable the actions
 * @param {Function} props.onSaveClicked - Method to be called when save button is clicked
 * @param {Function} props.onCancelClicked - Method to be called when cancel button is clicked
 * @param {Boolean} props.lineDivider - Flag to show or hide line diver. False by default.
 */
const CreateActions = ({
  editMode,
  loading,
  saveDisabled,
  onSaveClicked = () => {},
  onCancelClicked = () => {},
  hasDelete = false,
  onDeleteClicked = () => {},
  lineDivider = true,
  className,
  extra,
}) => {
  const _renderSaveButtonContent = () => (
    <div className="display-flex">
      <IconSave />
      <div className="campaign-exclusions-create-actions__save__label">
        {editMode ? 'SAVE CHANGES' : 'SAVE'}
      </div>
    </div>
  )

  const _renderDeleteButtonContent = () => (
    <div className="display-flex">
      <DeleteIcon />
      <div className="campaign-exclusions-create-actions__delete__label">
        DELETE
      </div>
    </div>
  )

  return (
    <>
      {lineDivider && (
        <LineDivider className="margin-bottom-16" width="unset" widthUnit="" />
      )}
      <div
        className={`campaign-exclusions-create-actions ${
          className ? className : ''
        }`}
      >
        <div className="campaign-exclusions-create-actions__wrapper">
          <div className="campaign-exclusions-create-actions__action-buttons">
            <Button
              className="campaign-exclusions-create-actions__save"
              value={_renderSaveButtonContent()}
              disabled={loading || saveDisabled}
              onClick={onSaveClicked}
              secondaryGreen
            />
            <Button
              className="campaign-exclusions-create-actions__cancel"
              secondaryGray
              value="CANCEL"
              disabled={loading}
              onClick={onCancelClicked}
            />
            {hasDelete && (
              <Button
                className="campaign-exclusions-create-actions__delete"
                value={_renderDeleteButtonContent()}
                disabled={loading}
                onClick={onDeleteClicked}
                secondaryRed
              />
            )}
          </div>
          {extra}
        </div>
      </div>
    </>
  )
}

export default CreateActions

CreateActions.propTypes = {
  editMode: PropTypes.bool,
  loading: PropTypes.bool,
  saveDisabled: PropTypes.bool,
  onSaveClicked: PropTypes.func,
  onCancelClicked: PropTypes.func,
  hasDelete: PropTypes.bool,
  onDeleteClicked: PropTypes.func,
  lineDivider: PropTypes.bool,
  className: PropTypes.string,
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}
