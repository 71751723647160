import React, { useState, useRef, useEffect } from 'react'
import classnames from 'classnames'

import PropTypes from 'prop-types'
import InformationBlock, {
  INFORMATION_BLOCK_TYPE,
} from 'components/information-block'

import closeIcon from 'assets/icon_clear_red.svg'

import 'components/input/style.scss'
import './style.scss'

const Textarea = React.forwardRef(
  (
    {
      className,
      disabled,
      error,
      label,
      onChange = () => {},
      placeholder,
      rows = 5,
      tabIndex = '0',
      onEnterKeyPressed,
      maxLength,
      hasCount,
      suffix,
      value = '',
      warning,
      showErrorInfoBlock,
      white = false,
      autoFocus,
      useClear = false,
      ...other
    },
    ref
  ) => {
    const [focused, setFocused] = useState(null)
    const inputRef = useRef()
    const isRequiredError =
      typeof error !== 'boolean'
        ? (error || '').toLowerCase().includes('required')
        : false

    useEffect(() => {
      if (inputRef.current && autoFocus) {
        const end = inputRef.current.value.length
        inputRef.current.setSelectionRange(end, end)
      }
    }, [])

    const onFocus = () => {
      setFocused(true)
    }

    const onBlur = () => {
      setFocused(false)
    }

    const onInputChange = (ev) => {
      onChange(ev.target.value)
    }

    const onClear = () => {
      onChange('')
    }

    const onKeyPress = (ev) => {
      const code = ev.keyCode || ev.which
      if (onEnterKeyPressed && code === 13 && !ev.shiftKey) {
        ev.preventDefault()
        onEnterKeyPressed(ev)
      }
    }

    const classes = classnames('input-wrapper', className, {
      focused,
      'input-wrapper--disabled': disabled,
      'input-wrapper--error': !!error,
      'input-wrapper--has-suffix': !!suffix,
      'input-wrapper--has-warning': !!warning,
      'input-wrapper--white': white,
      'input-wrapper--empty': !value.trim(),
    })

    return (
      <div className="textarea wrapper" data-testid="textarea-wrapper">
        <div className={classes}>
          {label && (
            <div className="label-wrapper">
              <label
                className={classnames('input-name-label', {
                  'color-red-important': isRequiredError && error,
                })}
              >
                {label}
              </label>
            </div>
          )}
          <div className="wrapper__prefix" ref={ref}>
            <textarea
              ref={inputRef}
              data-testid="textarea-input"
              tabIndex={tabIndex}
              placeholder={placeholder || ''}
              value={value || ''}
              disabled={disabled ? 'disabled' : undefined}
              onChange={!disabled ? onInputChange : () => {}}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
              rows={rows}
              maxLength={maxLength}
              className={classnames(className, {
                'error-placeholder': isRequiredError && error,
              })}
              autoFocus={autoFocus}
              {...other}
            />
            {useClear && inputRef.current && value && (
              <button
                className="textarea-close__icon"
                type="button"
                onClick={onClear}
              >
                <img
                  src={closeIcon}
                  onClick={onClear}
                  alt="ClearInput"
                  title="Clear input field"
                />
              </button>
            )}
            {suffix && <span className={'suffix'}>{suffix}</span>}
          </div>
          {warning && (
            <span className={'warning'} data-testid="textarea-warning">
              {warning}
            </span>
          )}
          <div className="wrapper__bottom">
            {error && !showErrorInfoBlock && (
              <div className="error" data-testid="textarea-error">
                {!isRequiredError ? error : ''}
              </div>
            )}
            {hasCount && (
              <div className="count" data-testid="textarea-count">{`${
                value.length
              }${maxLength ? `/${maxLength}` : ''}`}</div>
            )}
          </div>
          {error && showErrorInfoBlock && (
            <InformationBlock
              type={INFORMATION_BLOCK_TYPE.ERROR}
              info={!isRequiredError ? error : ''}
            />
          )}
        </div>
      </div>
    )
  }
)

Textarea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  tabIndex: PropTypes.string,
  value: PropTypes.any,
  onEnterKeyPressed: PropTypes.func,
  maxLength: PropTypes.number,
  hasCount: PropTypes.bool,
  showErrorInfoBlock: PropTypes.bool,
  suffix: PropTypes.node,
  warning: PropTypes.any,
  white: PropTypes.bool,
  autoFocus: PropTypes.bool,
  useClear: PropTypes.bool,
}

export default Textarea
