import { containsLogicalOperator } from 'components/panel-with-highlighted-content/utils'

const _unifyExpressions = (expressions) => {
  let length = expressions.length
  let lastExpression = expressions[length - 1]

  if (length == 1) {
    return [{ ...expressions[0], allNeedsToBeTrue: false }]
  }

  let sameOrGreaterLevelExpressions = [lastExpression]
  let i = length - 2
  let endOfCurrentLevel = false
  while (i >= 0 && !endOfCurrentLevel) {
    if (
      expressions[i].level >= lastExpression.level &&
      (!lastExpression.levelOneNumber ||
        expressions[i].levelOneNumber === lastExpression.levelOneNumber)
    ) {
      sameOrGreaterLevelExpressions = [
        expressions[i],
        ...sameOrGreaterLevelExpressions,
      ]
    } else {
      endOfCurrentLevel = true
    }
    i--
  }

  const remainingExpressions = expressions.filter(
    (expression) =>
      !sameOrGreaterLevelExpressions.some(
        (_expression) =>
          expression.nextLogicalOperator.index ===
          _expression.nextLogicalOperator.index
      )
  )

  length = sameOrGreaterLevelExpressions.length
  lastExpression = sameOrGreaterLevelExpressions[length - 1]

  if (length == 1) {
    return [
      ...remainingExpressions,
      { ...sameOrGreaterLevelExpressions[0], allNeedsToBeTrue: false },
    ]
  }

  const splitOn = []
  for (let i = 0; i < length - 1; i++) {
    const expression = sameOrGreaterLevelExpressions[i]
    if (
      expression.level === lastExpression.level ||
      (i + 1 < length && expressions[i + 1].level === lastExpression.level)
    ) {
      splitOn.push({
        index: expression.nextLogicalOperator.index,
        value: expression.nextLogicalOperator.value,
      })
    }
  }
  const newExpression = {
    originalIndexesInterval: [
      sameOrGreaterLevelExpressions[0].originalIndexesInterval[0],
      lastExpression.originalIndexesInterval[1],
    ],
    level: lastExpression.level,
    allNeedsToBeTrue: false,
    nextLogicalOperator: {
      index: lastExpression.nextLogicalOperator.index,
      value: lastExpression.nextLogicalOperator.value,
    },
    splitOn,
    levelOneNumber: lastExpression.levelOneNumber,
  }
  return [...remainingExpressions, newExpression]
}

const _processExpression = ({
  currentWordIndex,
  currentLevel,
  wordsToDisplay,
  endIndex,
  nrOfLevelOnes,
}) => {
  let bracketsWithoutPair = 0
  let currenExpressionStartIndex = currentWordIndex
  let currenExpressionEndIndex
  let expressionLevel
  // Go until end of expression (when we reach the next logical operator)
  while (
    (!containsLogicalOperator(wordsToDisplay[currentWordIndex]) ||
      currentLevel > 1) &&
    currentWordIndex < endIndex
  ) {
    if (wordsToDisplay[currentWordIndex].includes('(')) {
      bracketsWithoutPair++
      currentLevel++
      if (currentLevel === 1) {
        nrOfLevelOnes++
      }
    } else if (wordsToDisplay[currentWordIndex].includes(')')) {
      bracketsWithoutPair--
      currentLevel--
    } else if (!expressionLevel) {
      expressionLevel = Math.min(1, currentLevel)
    }
    currentWordIndex++
  }

  currenExpressionEndIndex = currentWordIndex - 1
  // Adjust start and end indexes to trim extra brackets
  if (bracketsWithoutPair < 0) {
    currenExpressionEndIndex = currenExpressionEndIndex + bracketsWithoutPair
  } else {
    currenExpressionStartIndex =
      currenExpressionStartIndex + bracketsWithoutPair
  }

  const currentLogicalOperator = wordsToDisplay[currentWordIndex]

  return {
    expression: {
      originalIndexesInterval: [
        currenExpressionStartIndex,
        currenExpressionEndIndex,
      ],
      level: expressionLevel,
      allNeedsToBeTrue: true,
      nextLogicalOperator: {
        index: currentWordIndex,
        value: currentLogicalOperator?.trim().toUpperCase(),
      },
      levelOneNumber: expressionLevel > 0 ? nrOfLevelOnes : null,
    },
    currentLevel: currentLevel,
    currentWordIndex,
    nrOfLevelOnes,
  }
}

const _checKForExtraBracketsAtBaseLevel = (wordsToDisplay) => {
  let startIndex = 0
  let endIndex = wordsToDisplay.length
  if (!wordsToDisplay[startIndex]?.includes('(')) {
    return {
      startIndex,
      endIndex,
    }
  }
  let openedBrackets = 1
  let firstBracketClosed = false
  let index = startIndex + 1
  while (index < endIndex && !firstBracketClosed) {
    if (wordsToDisplay[index].includes('(')) {
      openedBrackets++
    } else if (wordsToDisplay[index].includes(')')) {
      if (index === endIndex - 1 && !firstBracketClosed) {
        startIndex++
        endIndex--
      }
      openedBrackets--
      if (openedBrackets === 0) {
        firstBracketClosed = true
      }
    }
    index++
  }
  return { startIndex, endIndex }
}

export const processFormula = (wordsToDisplay) => {
  let expressions = []
  let tempExpressions = []
  const { startIndex, endIndex } =
    _checKForExtraBracketsAtBaseLevel(wordsToDisplay)
  let currentWordIndex = startIndex
  let currentLevel = 0
  let nrOfLevelOnes = 0
  let hadOrOnLevel = {}
  while (currentWordIndex < endIndex) {
    const result = _processExpression({
      currentWordIndex,
      currentLevel,
      wordsToDisplay,
      endIndex,
      nrOfLevelOnes,
    })
    const expression = result.expression
    currentWordIndex = result.currentWordIndex
    currentLevel = result.currentLevel
    nrOfLevelOnes = result.nrOfLevelOnes
    tempExpressions.push(expression)
    const endOfLevel =
      currentLevel < expression.level || currentWordIndex === endIndex
    const isORNext = expression.nextLogicalOperator.value?.includes('OR')
    if (!endOfLevel) {
      if (isORNext) {
        hadOrOnLevel = {
          ...hadOrOnLevel,
          [expression.level]: true,
        }
        expressions = [...expressions, ..._unifyExpressions(tempExpressions)]
        tempExpressions = []
      }
    } else {
      if (hadOrOnLevel[expression.level]) {
        expressions = [...expressions, ..._unifyExpressions(tempExpressions)]
      } else {
        expressions = [...expressions, ...tempExpressions]
      }
      if (expression.level > 0 && currentWordIndex !== endIndex) {
        tempExpressions = [...expressions]
        expressions = []
      } else {
        tempExpressions = []
      }
      hadOrOnLevel = {
        ...hadOrOnLevel,
        [expression.level]: false,
        [currentLevel]: isORNext,
      }
    }

    currentWordIndex++
  }
  leveOnlyOneGroupOfOneNeedsToBeTrue(expressions)
  return expressions
}

const leveOnlyOneGroupOfOneNeedsToBeTrue = (expressions) => {
  let oneNeedsToBeTrueSubExpression = null
  // After everything is processed we need to go through the expressions once starting form the back
  // And leave only one subexpression group in the one needs to be true column
  let i = expressions.length - 1
  while (i >= 0) {
    const { allNeedsToBeTrue, level, levelOneNumber } = expressions[i]
    if (allNeedsToBeTrue) {
      i--
      continue
    }
    if (!oneNeedsToBeTrueSubExpression) {
      oneNeedsToBeTrueSubExpression = {
        level,
        levelOneNumber,
      }
    } else if (
      level !== oneNeedsToBeTrueSubExpression.level ||
      levelOneNumber !== oneNeedsToBeTrueSubExpression.levelOneNumber
    ) {
      const startLevel = level
      const startLevelOneNumber = levelOneNumber
      const endIndex = i
      i--
      while (
        expressions[i].level === startLevel &&
        expressions[i].levelOneNumber === startLevelOneNumber &&
        i >= 0
      ) {
        i--
      }
      i++
      const startIndex = i
      const toBeUnified = expressions.splice(
        startIndex,
        endIndex - startIndex + 1
      )
      const unifiedExpression = _unifyExpressions(toBeUnified)
      expressions.splice(startIndex, 0, {
        ...unifiedExpression[0],
        allNeedsToBeTrue: true,
      })
    }
    i--
  }
}
