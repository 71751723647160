import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'components/dropdown/index'
import { fiscal, utils } from '@decision-sciences/qontrol-common'
import CreateActions from 'modules/companies/subsections/campaign-exclusions-section/components/campaign-exclusions-create/create-actions'

import { getGlobalReportOptions } from 'modules/companies/actions'

import {
  FISCAL_DROPDOWNS,
  GLOBAL_REPORTS_INDUSTRIES,
  getFiscalErrors,
} from './utils'

const { DAY_TO_WEEKDAY, WEEKDAY_TO_DAY } = utils
const { FISCAL_QUARTERS, FISCAL_MONTHS } = fiscal
const daysOfWeekOptions = Object.keys(DAY_TO_WEEKDAY).map((day) => ({
  label: day,
  value: day,
}))

/**
 * Reporting sub-section for a Client/Business Unit
 * @param {Object} props Component props
 * @param {Object} props.reportingConfig Reporting config for client/bu
 * @param {Function} props.onChange Callback inovked whenever a configuration field changes
 * @param {Boolean} props.disabled Flag to disable configuration
 * @param {Boolean} props.displayIndustry Flag to display the industry dropdown
 * @param {Object} [props.errors] Validation errors
 * @param {Function} [props.setErrors] Setter for errors
 * @returns {React.ReactElement}
 */
const Reporting = ({
  reportingConfig,
  errors,
  setErrors,
  onChange,
  onSave,
  onCancel,
  canSave,
  disabled,
  displayIndustry = true,
}) => {
  const [loading, setLoading] = useState(false)
  const [globalReports, setGlobalReports] = useState({})

  /** On component mount, if `viewMode` is false, fetch available vertical/reporting options */
  useEffect(() => {
    if (!disabled) {
      setLoading(true)
      getGlobalReportOptions()
        .then((options) => setGlobalReports(options))
        .catch((err) => console.error(err))
        .finally(() => setLoading(false))
    }
  }, [disabled])

  /**
   * Check if any dropdown from fiscal is selected
   */
  useEffect(() => {
    const [, fiscalErrors] = getFiscalErrors(reportingConfig)

    setErrors({
      ...errors,
      fiscalErrors,
    })
  }, [JSON.stringify(reportingConfig)])

  /**
   * Change handler
   * @param {Object} config New value
   */
  const handleChange = (config) => {
    if (disabled) {
      return
    }
    onChange && onChange(config)
  }

  const handleFiscalData = (value, field) => {
    const newValues = {
      ...reportingConfig,
    }

    // Setting all values to empty => remove fiscalPeriod altogether
    if (
      value === '' &&
      reportingConfig?.fiscalPeriod?.initialFiscalPeriod &&
      Object.entries(reportingConfig?.fiscalPeriod?.initialFiscalPeriod).every(
        ([key, value]) =>
          key === field ||
          (FISCAL_DROPDOWNS.includes(key) && (value === '' || value === null))
      )
    ) {
      newValues.fiscalPeriod = null
    } else {
      newValues.fiscalPeriod = {
        ...reportingConfig?.fiscalPeriod,
        initialFiscalPeriod: {
          ...reportingConfig?.fiscalPeriod?.initialFiscalPeriod,
          [field]: value,
        },
      }
    }

    handleChange({ ...newValues })
  }

  const verticalOptions =
    Object.keys(globalReports).map((vertical) => ({
      value: vertical,
      label: vertical,
    })) || []

  const industryOptions = reportingConfig?.vertical
    ? GLOBAL_REPORTS_INDUSTRIES[reportingConfig.vertical]?.map((industry) => ({
        value: industry,
        label: industry,
      }))
    : []

  const reportOptionOptions = reportingConfig?.vertical
    ? globalReports[reportingConfig.vertical]?.map((value) => ({
        value,
        label: value,
      }))
    : []

  const currentYear = new Date().getFullYear()

  const yearOptions = [
    {
      value: currentYear - 1,
      label: currentYear - 1,
    },
    { value: currentYear, label: currentYear },
    {
      value: currentYear + 1,
      label: currentYear + 1,
    },
  ]

  return (
    <div className="form__section__body align-column">
      <h3 data-cy="reporting-heading" className="generic-heading">
        Reporting
      </h3>
      <div data-cy="reporting-section" className="align-row">
        <div className="form__section__body__half-width-section">
          <div className="general-label" data-cy="reporting-vertical-label">
            Vertical
          </div>
          <Dropdown
            data-cy="reporting-vertical-dropdown"
            loading={loading}
            disabled={disabled}
            defaultOptionText="Select Vertical"
            deselectLabel="None"
            defaultState={reportingConfig?.vertical || ''}
            options={verticalOptions}
            onChange={(value) => {
              const newConfig = displayIndustry
                ? { vertical: value, reporting: '', industry: '' }
                : { vertical: value, reporting: '' }
              if (!value) {
                Object.keys(newConfig).forEach(
                  (key) => (newConfig[key] = value)
                )
              }
              handleChange(newConfig)
            }}
          />
          {displayIndustry && (
            <>
              <div
                className="general-label margin-top-10"
                data-cy="reporting-industry-label"
              >
                Industry
              </div>
              <Dropdown
                data-cy="reporting-industry-dropdown"
                loading={loading}
                disabled={loading || disabled || !reportingConfig?.vertical}
                defaultOptionText="Select Industry"
                defaultState={reportingConfig?.industry || ''}
                options={industryOptions}
                onChange={(value) => {
                  handleChange({ ...reportingConfig, industry: value })
                }}
              />
            </>
          )}
        </div>
        <div className="form__section__body__half-width-section right-side">
          <div className="general-label" data-cy="report-option-label">
            Report option
          </div>
          <Dropdown
            data-cy="report-option-dropdown"
            loading={loading}
            disabled={disabled || !reportingConfig?.vertical}
            defaultOptionText="Select Report Option"
            defaultState={reportingConfig?.reporting || ''}
            options={reportOptionOptions}
            onChange={(value) =>
              handleChange({ ...reportingConfig, reporting: value })
            }
            error={errors.tableauConfig?.reporting}
          />
        </div>
      </div>
      <hr />
      <div className="align-row">
        <div className="form__section__body__half-width-section">
          <div className="align-row wrap">
            <Dropdown
              className="input-half-width input-wrapper--uppercase"
              defaultOptionText="Select Year"
              defaultState={
                reportingConfig?.fiscalPeriod?.initialFiscalPeriod?.fiscalYear
              }
              deselectLabel="-"
              error={errors?.fiscalErrors?.fiscalYear}
              label="Fiscal Year"
              onChange={(value) => handleFiscalData(value, 'fiscalYear')}
              options={yearOptions}
            />
            <Dropdown
              className="input-half-width right-half-input input-wrapper--uppercase"
              defaultOptionText="Select Quarter"
              defaultState={
                reportingConfig?.fiscalPeriod?.initialFiscalPeriod
                  ?.fiscalQuarter
              }
              deselectLabel="-"
              error={errors?.fiscalErrors?.fiscalQuarter}
              label="Fiscal Quarter"
              onChange={(value) => handleFiscalData(value, 'fiscalQuarter')}
              options={FISCAL_QUARTERS}
            />
          </div>
          <div className="align-row wrap" style={{ marginTop: '16px' }}>
            <Dropdown
              className="input-half-width input-wrapper--uppercase"
              defaultOptionText="Select Year"
              defaultState={
                reportingConfig?.fiscalPeriod?.initialFiscalPeriod
                  ?.fiscalQuarterStartYear || ''
              }
              deselectLabel="-"
              error={errors?.fiscalErrors?.fiscalQuarterStartYear}
              label="Start Year"
              onChange={(value) =>
                handleFiscalData(value, 'fiscalQuarterStartYear')
              }
              options={yearOptions}
            />
            <Dropdown
              className="input-half-width right-half-input input-wrapper--uppercase"
              defaultOptionText="Select Month"
              defaultState={
                reportingConfig?.fiscalPeriod?.initialFiscalPeriod
                  ?.fiscalQuarterStartMonth
              }
              deselectLabel="-"
              error={errors?.fiscalErrors?.fiscalQuarterStartMonth}
              label="Start Month"
              onChange={(value) =>
                handleFiscalData(value, 'fiscalQuarterStartMonth')
              }
              options={FISCAL_MONTHS}
            />
          </div>
        </div>
        <div className="form__section__body__half-width-section right-side">
          <div className="general-label" data-cy="report-option-label">
            Week Start Default
          </div>
          <Dropdown
            data-cy="week-start-dropdown"
            defaultState={reportingConfig?.weekStartOn || WEEKDAY_TO_DAY[0]}
            options={daysOfWeekOptions}
            onChange={(value) =>
              handleChange({ ...reportingConfig, weekStartOn: value })
            }
          />
        </div>
      </div>
      {onSave && onCancel ? (
        <>
          <hr />
          <CreateActions
            saveDisabled={!canSave}
            onSaveClicked={onSave}
            onCancelClicked={onCancel}
            lineDivider={false}
          />
        </>
      ) : null}
    </div>
  )
}

Reporting.propTypes = {
  reportingConfig: PropTypes.object,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  disabled: PropTypes.bool,
  displayIndustry: PropTypes.bool,
  errors: PropTypes.object,
  setErrors: PropTypes.func.isRequired,
}

export { Reporting }
