import React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import BudgetInputGroup from '../budget-input-group/index'
import { defaultDimensionGroupOption } from '../../utils'

/**
 * Budget List section and its functionality
 * @param {Object} props
 * @param {Array} props.budgets - A list with the budgets
 * @param {Object} props.errors - Object with errors for budget
 * @param {Boolean} props.isEditingBudget- Is budget in edit mode
 * @param {Array} props.conversionList - Conversion list used for Assoc. Conversions
 * @param {Array} props.budgetSegmentsList - A list with the budget segments
 * @param {Object} props.dimensions - Current client dimension object
 * @param {Function} props.updateBudget - Method to be called to update the budget
 */
const BudgetPacingList = ({
  budgets,
  budgetSegmentsList,
  dimensions,
  conversionList,
  errors,
  isEditingBudget,
  updateBudget,
  setFooterInfoText,
}) => {
  const onBudgetChanged = (newObject) => {
    const { budget, error } = newObject
    // Set the new budget's data
    const updatedBudgets = budgets.map((b) =>
      budget.local__uuid === b.local__uuid ? { ...budget } : { ...b }
    )
    updateBudget({
      budgets: updatedBudgets,
      error: { [budget.local__uuid]: error },
    })

    setFooterInfoText(
      newObject.budget.dateGranularityAutoGenerated
        ? 'Date Granularity auto updated based on date selection'
        : null
    )
  }

  const onRemoveClicked = (budget) => {
    const updatedBudgets = budgets.filter(
      (item) => item.local__uuid !== budget.local__uuid
    )

    const updatedError = errors[budget?.local__uuid]
    for (const key in updatedError) {
      if (updatedError[key]) {
        updatedError[key] = null
      }
    }

    if (!isEditingBudget && updatedBudgets.length === 0) {
      const isCustomBudget = budget?.isCustomBudget
      const newBudget = {
        local__uuid: uuidv4(),
        clientId: budget.clientId,
        isCustomBudget,
      }
      if (isCustomBudget) {
        newBudget.dimensionGroups = [
          {
            dimensionGroupOptions: [defaultDimensionGroupOption],
          },
        ]
      }

      updatedBudgets.push(newBudget)
    }
    updateBudget({
      budgets: updatedBudgets,
      error: { [budget.local__uuid]: updatedError },
    })
  }

  return budgets.map((budget, index) => (
    <div
      data-cy="budget-input-list"
      key={index}
      className="margin-bottom-16 margin-top-22"
    >
      <BudgetInputGroup
        error={errors[budget?.local__uuid]}
        budget={budget}
        dimensions={dimensions}
        isEditingBudget={isEditingBudget}
        budgetSegmentsList={budgetSegmentsList}
        conversionList={conversionList}
        onBudgetChanged={onBudgetChanged}
        onRemoveClicked={
          isEditingBudget || budget.isCustomBudget
            ? null
            : () => onRemoveClicked(budget)
        }
      />
    </div>
  ))
}
export default BudgetPacingList

BudgetPacingList.propTypes = {
  budgets: PropTypes.array.isRequired,
  budgetSegmentsList: PropTypes.array.isRequired,
  conversionList: PropTypes.array.isRequired,
  updateBudget: PropTypes.func.isRequired,
  errors: PropTypes.object,
  isEditingBudget: PropTypes.bool,
  setFooterInfoText: PropTypes.func.isRequired,
}
