import { ACTIONS } from 'modules/facebook/actions'

const name = 'facebook'

const initialState = {
  catalogs: {},
  productSets: {},
  /** e.g. accounts: { 'account-id': { hasReservedCampaignRights: true/false, odaxEnabled: true/false/undefined, capabilities: ['CAN_USE_ROAS_VALUE_OPTIMIZATION','CAN_USE_DYNAMIC_ADS_VALUE_OPTIMIZATION'] }}*/
  accounts: {},
  /** e.g. messageTemplates: { 'account-id': [list]}*/
  messageTemplates: {},
  /** e.g. accountData: { 'account-id': { facebookPages: [], instagramAccounts: [], apps: [], whatsAppPages: []}}*/
  accountData: {},
  customConversions: {},
  availableAdLanguages: {},
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.SET_CATALOG]: (
    state,
    { accountId, accountCatalogs, businessId }
  ) => ({
    ...state,
    catalogs: { ...state?.catalogs, [accountId]: accountCatalogs },
    businessIds: {
      ...state?.business,
      [accountId]: businessId,
    },
  }),
  [ACTIONS.SET_PRODUCT_SET]: (state, { catalogId, productSets }) => ({
    ...state,
    productSets: { ...state?.productSets, [catalogId]: productSets },
  }),
  [ACTIONS.SET_ACCOUNT]: (state, { accountId, data }) => ({
    ...state,
    accounts: {
      ...state?.accounts,
      [accountId]: { ...(state?.accounts?.[accountId] || {}), ...data },
    },
  }),
  [ACTIONS.SET_MESSAGE_TEMPLATE]: (state, { accountId, data }) => ({
    ...state,
    messageTemplates: {
      ...state?.messageTemplates,
      [accountId]: data,
    },
  }),
  [ACTIONS.SET_ACCOUNT_DATA]: (state, { accountId, data }) => ({
    ...state,
    accountData: {
      ...state?.accountData,
      [accountId]: data,
    },
  }),
  [ACTIONS.SET_CUSTOM_CONVERSIONS]: (state, { accountId, data }) => {
    return {
      ...state,
      customConversions: {
        ...state?.customConversions,
        [accountId]: data,
      },
    }
  },
  [ACTIONS.SET_AVAILABLE_AD_LANGUAGES]: (state, { data }) => {
    return {
      ...state,
      availableAdLanguages: data,
    }
  },
}

export default { name, initialState, reduce }
