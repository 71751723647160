import { granularities } from '@decision-sciences/qontrol-common'

const { GRANULARITIES: GLOBAL_GRANULARITIES } = granularities

export const GRANULARITIES = {
  Account: {
    label: 'Account(s)',
    key: 'account',
    order: 0,
    self: GLOBAL_GRANULARITIES.ACCOUNT,
    id: 'accountId',
    hierarchy: 0,
  },
  Campaign: {
    label: 'Campaign(s)',
    key: 'campaigns',
    order: 1,
    self: GLOBAL_GRANULARITIES.CAMPAIGN,
    id: 'campaignId',
    hierarchy: 1,
  },
  ['Ad Group / Ad Set']: {
    label: 'Ad Group(s) / Ad Set(s)',
    key: 'adGroups',
    order: 2,
    self: GLOBAL_GRANULARITIES.AD_GROUP,
    id: 'adgroupId',
    hierarchy: 2,
  },
  Ad: {
    label: 'Ad(s)',
    key: 'ads',
    order: 3,
    self: GLOBAL_GRANULARITIES.AD,
    hierarchy: 3,
  },
  Keyword: {
    label: 'Keyword(s)',
    key: 'keywords',
    order: 4,
    self: GLOBAL_GRANULARITIES.KEYWORD,
    hierarchy: 3,
  },
}
