import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import { useIntermediaryStates } from 'hooks/intermediary-states'
import Button from 'components/button/index'
import LineDivider from 'components/line-divider/index'
import Icon from 'components/icon/index'
import { ReactComponent as WarningIconRed } from 'assets/icon_warning_round_red.svg'
import { ReactComponent as CheckIcon } from 'assets/icon_check_black.svg'
import { ReactComponent as CloseIcon } from 'assets/icon_close_small.svg'
import { ReactComponent as ViewIcon } from 'assets/icon_password_eye.svg'
import { ReactComponent as BlockIcon } from 'assets/icon_block.svg'

import ScssConstants from '../../styles/shared.module.scss'

/**
 * Renders a restore notification with various options when a duplicate Client ID is entered
 * @param {Object} props Component props
 * @param {Boolean} [props.triggeredForBusinessUnit = false] Must be set to true if this is used at the BU level
 * @param {Boolean} [props.visible = false] Flag to render or hide
 * @param {Object} [props.restoreInfo = {}] Information about the existing entity with the same Client ID
 * @param {Function} props.onRestore Callback triggered when "Yes" is clicked
 * @param {Function} props.onCancel Callback triggered when "No" is clicked
 * @param {Function} props.onArchivedFilter Callback triggered when "See all archived clients" is clicked
 * @param {Function} props.onClose Callback triggered when the notification is closed
 * @returns {React.FunctionComponent}
 */
const RestoreNotification = ({
  triggeredForBusinessUnit = false,
  visible = false,
  restoreInfo = {},
  onRestore,
  onCancel,
  onArchivedFilter,
  onClose,
}) => {
  const notificationsContainer = document.getElementById(
    'notifications-container'
  )

  const { getLoadingFor, setLoadingFor } = useIntermediaryStates()

  const handleRestore = () => {
    setLoadingFor(ELEMENTS.RESTORE, true)
    onRestore().finally(() => setLoadingFor(ELEMENTS.RESTORE, false))
  }

  const handleSeeArchived = () => {
    setLoadingFor(ELEMENTS.SEE_ARCHIVED, true)
    onArchivedFilter().finally(() =>
      setLoadingFor(ELEMENTS.SEE_ARCHIVED, false)
    )
  }

  if (!notificationsContainer || !visible || !restoreInfo) {
    return null
  }

  const { clientId, isBusinessUnit, isParentArchived, name } = restoreInfo

  const getCallout = () => {
    if (triggeredForBusinessUnit) {
      if (isParentArchived) {
        return 'Parent client is archived.'
      }
      if (!isBusinessUnit) {
        return 'Client cannot be un-archived at Business Unit level.'
      }
      if (!isParentArchived) {
        return 'Would you like to restore this business unit?'
      }
    } else {
      if (!isBusinessUnit) {
        return 'Would you like to restore this client?'
      }
      if (isBusinessUnit) {
        if (isParentArchived) {
          return 'Parent client is archived.'
        }
        if (!isParentArchived) {
          return 'Would you like to restore this business unit?'
        }
      }
    }
  }

  const getRestoreOrCancelPossibility = () => {
    if (triggeredForBusinessUnit) {
      if (isParentArchived) {
        return false
      }
      if (!isBusinessUnit) {
        return false
      }
      if (!isParentArchived) {
        return true
      }
    } else {
      if (!isBusinessUnit) {
        return true
      }
      if (isBusinessUnit) {
        if (isParentArchived) {
          return false
        }
        if (!isParentArchived) {
          return true
        }
      }
    }
  }

  return createPortal(
    <div className="client-restore-notification">
      <div className="client-restore-notification__icon">
        <Icon>
          <WarningIconRed />
        </Icon>
      </div>
      <LineDivider
        height={'100'}
        width={1}
        widthUnit="px"
        backgroundColor={ScssConstants.red}
      />
      <div className="client-restore-notification__content general-description">
        <div>
          {isBusinessUnit ? 'Business Unit' : 'Client'} already exists with the
          entered ID:
        </div>
        <b>
          {clientId} ({name})
        </b>
        <div>{getCallout()}</div>
        <div className="client-restore-notification__actions">
          {getRestoreOrCancelPossibility() && (
            <Button
              compact
              secondaryRed
              disabled={getLoadingFor(ELEMENTS.RESTORE)}
              onClick={handleRestore}
              value={
                <Icon>
                  <CheckIcon className="svg--red" /> Yes
                </Icon>
              }
            />
          )}
          {getRestoreOrCancelPossibility() && (
            <Button
              compact
              secondaryRed
              onClick={onCancel}
              value={
                <Icon>
                  <BlockIcon width={14} height={14} /> No
                </Icon>
              }
            />
          )}
          <Button
            compact
            secondaryRed
            disabled={getLoadingFor(ELEMENTS.SEE_ARCHIVED)}
            onClick={handleSeeArchived}
            value={
              <Icon>
                <ViewIcon width={16} height={16} /> See all archived clients
              </Icon>
            }
          />
        </div>
      </div>
      <Icon onClick={onClose} className="margin-left-22">
        <CloseIcon width={14} height={14} className="svg--red" />
      </Icon>
    </div>,
    notificationsContainer
  )
}

const ELEMENTS = {
  RESTORE: 'RESTORE',
  SEE_ARCHIVED: 'SEE_ARCHIVED',
}

RestoreNotification.propTypes = {
  triggeredForBusinessUnit: PropTypes.bool,
  visible: PropTypes.bool,
  restoreInfo: PropTypes.object,
  onRestore: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onArchivedFilter: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export { RestoreNotification }
