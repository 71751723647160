import React from 'react'
import PropTypes from 'prop-types'

import { Dropdown } from 'components/dropdown/index'
import { AlertDynamicMetricsSelection } from 'modules/alerts/dynamic-metrics-selection/index'

import { getTemplateExpression } from 'modules/alerts/utils'

import { notifications, granularities } from '@decision-sciences/qontrol-common'

const { HOURLY_UNSELECTABLE_GRANULARITIES } = granularities
const { FREQUENCY_OPTIONS_MAP } = notifications

export const AlertTemplateSection = ({
  state,
  setState,
  errors,
  setErrors,
  filteredTemplates,
  isPerformanceAlert,
  isRestrictiveEdit,
  companiesList,
  hasDynamicMetrics,
}) => {
  const singleCompanyAlert =
    state.companies.length === 1 &&
    !state.allCompaniesSelected &&
    isPerformanceAlert
  /** Callback for changing templates */
  const onChangeTemplate = (template) => {
    const newState = { template }
    const isHourly =
      filteredTemplates.find(({ _id }) => _id === template).recurrenceType ===
      FREQUENCY_OPTIONS_MAP.HOURS

    if (isHourly) {
      newState.selectedElements = getAvailableGranularities(
        isHourly,
        singleCompanyAlert
      )
    }

    setState(newState)

    setErrors({ ...errors, template: null })
  }

  /**
   * Get a state of selectedElements that can exist based on isHourlyTemplate and singleCompany Alert
   * The purpose is to filter out any gralularities that can't appear on a hourly in case of an hourly template
   * @param {Boolean} isHourlyTemplate
   * @param {Boolean} singleCompanyAlert
   * @returns {Object}
   */
  const getAvailableGranularities = (isHourlyTemplate, singleCompanyAlert) => {
    if (isHourlyTemplate && !singleCompanyAlert) {
      return state.selectedAccounts.reduce((obj, acc) => {
        const publisherState = state.selectedElements[acc]
        publisherState.elements = publisherState.elements?.filter(
          (el) => !HOURLY_UNSELECTABLE_GRANULARITIES[acc]?.includes(el)
        )
        return { ...obj, [acc]: publisherState }
      }, {})
    }

    // Leave them as they are otherwise
    return state.selectedElements
  }

  if (isRestrictiveEdit || !isPerformanceAlert) {
    return null
  }
  return (
    <section className="form__section">
      <div className="form__section__header">Formula</div>
      <div className="form__section__body">
        <Dropdown
          defaultState={state.template}
          options={filteredTemplates.map((item) => ({
            value: item.unselectable ? undefined : item._id,
            label: item.name,
            unselectable: item.unselectable || false,
          }))}
          defaultOptionText="Select Alert Template"
          error={errors.template}
          className="input-half-width"
          onChange={onChangeTemplate}
        />
        {state.template && (
          <code>
            {getTemplateExpression(filteredTemplates, state.template)}
          </code>
        )}

        <AlertDynamicMetricsSelection
          state={state}
          setState={(state) => {
            setErrors({ ...errors, dynamicMetrics: null })
            setState(state)
          }}
          hasDynamicMetrics={hasDynamicMetrics}
          companiesList={companiesList}
          error={errors.dynamicMetrics}
        />
      </div>
    </section>
  )
}

AlertTemplateSection.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  filteredTemplates: PropTypes.array.isRequired,
  isPerformanceAlert: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  isRestrictiveEdit: PropTypes.bool,
  hasDynamicMetrics: PropTypes.bool,
  companiesList: PropTypes.array,
}
