import Api from 'easy-fetch-api'

import {
  showSuccessMessage,
  showErrorMessage,
} from 'modules/notifications/actions'

import { getAlertTemplates } from 'modules/alert-templates/actions'

export const ACTIONS = {
  GET_ALERT_THRESHOLDS: 'alertThresholds.getAlertThresholds',
  PUT_ALERT_THRESHOLD: 'alertThresholds.putAlertThreshold', // Updates or adds a threshold
  DELETE_ALERT_THRESHOLD: 'alertThresholds.deleteAlertThreshold',
  GET_ACTIVE_ALERT_THRESHOLDS: 'alertThresholds.getActiveAlertThresholds', // New action for getting active alert thresholds
}

/**
 * Fetches and stores into reducer the list of alert thresholds
 * @param {*} dispatch Dispatch
 * @param {*} query Query to find alert thresholds by
 * @returns {void}
 */
export const getAlertThresholds = (dispatch, query = {}) =>
  Api.get({ url: '/api/alert-thresholds', query }).then((result) => {
    if (!result || result.error) {
      console.error(
        (result && result.error) || 'Error fetching alert thresholds list'
      )
    } else {
      dispatch({
        type: ACTIONS.GET_ALERT_THRESHOLDS,
        alertThresholds: result.list,
        query,
      })
    }
  })

/**
 * Fetches and stores into reducer the list of all alert thresholds with associated active alerts
 * @param {*} dispatch Dispatch
 * @returns {void}
 */
export const getAllAlertThresholdsWithActiveAlerts = (dispatch, companyId) =>
  Api.get({
    url: `/api/alert-thresholds/with-active-alerts/${companyId}`,
  }).then((result) => {
    if (!result || result.error) {
      console.error(
        (result && result.error) || 'Error fetching alert thresholds list'
      )
    } else {
      dispatch({
        type: ACTIONS.GET_ACTIVE_ALERT_THRESHOLDS,
        activeAlertThresholds: result.list,
      })
    }
  })

export const initGetRecommendedThresholds = (clientId, alerts) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: '/api/alerts/init-get-recommended-thresholds',
      data: {
        clientId,
        clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        alerts,
      },
    })
      .then((result) => {
        if (!result || !result.success) {
          const defaultMessage = `Error initialising Recommended Thresholds.`
          console.error(defaultMessage, result.error)
          return reject(result.error || defaultMessage)
        } else {
          resolve(result.data)
        }
      })
      .catch(reject)
  })
}

/**
 * Updates an alert threshold
 * If the update is successful, it also changes / adds the new threshold in the reducer
 * @param {*} dispatch Dispatch
 * @param {Object} alertThreshold New alert threshold value
 * @returns {Promise}
 */
export const createUpdateAlertThreshold = async (dispatch, alertThreshold) => {
  return new Promise((resolve, reject) => {
    const { _id } = alertThreshold
    const payload = {
      url: '/api/alert-thresholds',
      data: alertThreshold,
    }
    const promise = _id ? Api.put(payload) : Api.post(payload)
    promise
      .then((result) => {
        if (!result || !result.success) {
          reject(result?.error || `Could not save ${alertThreshold.name}`)
        } else {
          dispatch({
            type: ACTIONS.PUT_ALERT_THRESHOLD,
            alertThreshold: result.data,
          })
          getAlertTemplates(dispatch).then(resolve(result.data))
        }
      })
      .catch(reject)
  })
}

export const deleteAlertThreshold = (dispatch, _id) => {
  return new Promise((resolve, reject) => {
    Api.delete({ url: `/api/alert-thresholds/${_id}` })
      .then((res) => {
        if (res.success) {
          showSuccessMessage('Alert Threshold successfully deleted', dispatch)
          dispatch({
            type: ACTIONS.DELETE_ALERT_THRESHOLD,
            _id,
          })
          resolve()
        } else {
          const ERROR_MESSAGE = 'Error deleting Alert Threshold'
          showErrorMessage(ERROR_MESSAGE, dispatch)
          reject(ERROR_MESSAGE)
        }
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}
