import { publisherFieldMap } from '@decision-sciences/qontrol-common'

const { ALL_OPERATIONS } = publisherFieldMap.operations

/**
 * Used to set the origin from which an item was dragged.
 * Thus, it can be determined if an item can be dropped in a specific area.
 * @enum
 */
export const DATA_STORE_DND_ORIGINS = {
  LIBRARY: 'LIBRARY',
  ROW: 'ROW',
  COLUMN: 'COLUMN',
}

export const TABLE_PAGINATION_VALUES = [10, 20, 50]

export const TMP_TOTAL_ROWS = 20

export const MAX_HISTORY_RECORDS = 10

export const ALPHABETIC_DIMENSION_FILTERS = [
  {
    label: 'contains',
    value: ALL_OPERATIONS.CT,
  },
  {
    label: 'does not contain',
    value: ALL_OPERATIONS.NON_CT,
  },
  {
    label: 'equals',
    value: ALL_OPERATIONS.EQ,
  },
  {
    label: 'does not equal',
    value: ALL_OPERATIONS.NON_EQ,
  },
]

export const NUMERIC_DIMENSION_FILTERS = [
  {
    label: 'is greater than',
    value: ALL_OPERATIONS.GT,
  },
  {
    label: 'is less than',
    value: ALL_OPERATIONS.LT,
  },
  {
    label: 'is greater than or equal to',
    value: ALL_OPERATIONS.GTE,
  },
  {
    label: 'is less than or equal to',
    value: ALL_OPERATIONS.LTE,
  },
  {
    label: 'equals',
    value: ALL_OPERATIONS.EQ,
  },
  {
    label: 'does not equal',
    value: ALL_OPERATIONS.NON_EQ,
  },
]

export const STORAGE_DATA_STORE_STATE = 'lc-3q-data-store'
