import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { useTooltip } from 'components/tooltip'

import './style.scss'

/** Non-hook Checkbox component */
export const CheckboxNoHooks = ({
  alignCenter,
  label,
  hint,
  icon,
  onChange,
  defaultValue = false,
  dark = false,
  onClick,
  customIcon = null,
  reversed,
  tooltip,
  disabled,
  white,
  preLabel,
  ...other
}) => {
  const isChecked = defaultValue
  const changeHandler = () => {
    onChange && onChange(!isChecked)
  }
  return _renderContent({
    alignCenter,
    label,
    hint,
    icon,
    isChecked,
    onChange: changeHandler,
    dark,
    customIcon,
    onClick,
    reversed,
    tooltip,
    disabled,
    white,
    ...other,
  })
}

/** Private method to render content */
const _renderContent = ({
  alignCenter,
  label,
  hint,
  icon,
  isChecked,
  onChange,
  dark,
  customIcon,
  className = '',
  onClick,
  disabled,
  reversed,
  blue,
  white,
  tooltip,
  ...other
}) => {
  const [showTooltip, hideTooltip, Tooltip] = useTooltip(tooltip)

  const checkBoxClass = classnames('checkbox input-wrapper', className, {
    [`checkbox${dark ? '--dark' : ''}${isChecked ? '--checked' : ''}`]:
      dark || isChecked,
    'checkbox--centered': alignCenter,
    'checkbox--checked': isChecked,
    'checkbox--white': white,
    'no-label': !label,
    'checkbox--disabled': disabled,
    'checkbox--reversed': reversed,
    'checkbox--blue': blue,
    'checkbox--center-icon': icon,
  })

  return (
    <>
      <Tooltip />
      <div
        className={checkBoxClass}
        onMouseOver={showTooltip}
        onMouseLeave={hideTooltip}
      >
        <label className="checkbox__label">
          {label && (
            <div className="label-content-wrapper">
              <div className="label-wrapper">{label}</div>
              {hint && <div className="hint-wrapper">{hint}</div>}
            </div>
          )}
          <input
            type="checkbox"
            data-testid="checkbox"
            tabIndex="0"
            checked={isChecked}
            onChange={(e) => {
              e.preventDefault()
            }}
            onClick={(e) => {
              if (!disabled) {
                onClick && onClick(e)
                onChange(e)
              }
            }}
            {...other}
          />
          {icon && <div className="checkbox__icon">{icon}</div>}
          <div className="check">
            {!customIcon ? (
              <svg
                id="svg135"
                fill="none"
                viewBox="0 0 16 16"
                height="16"
                width="16"
              >
                <path
                  style={{ fillRule: 'evenodd' }}
                  id="checkboxpath"
                  d="M 7,14 2,9 3.4,7.6 7,11.2 14.6,3.6 16,5 Z"
                />
              </svg>
            ) : (
              <img alt="Icon" className="image-icon" src={customIcon} />
            )}
          </div>
        </label>
      </div>
    </>
  )
}

const props = {
  alignCenter: PropTypes.bool,
  label: PropTypes.node,
  hint: PropTypes.node,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  defaultValue: PropTypes.bool,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  dark: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  reversed: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  white: PropTypes.bool,
  customIcon: PropTypes.string,
  blue: PropTypes.bool,
}
CheckboxNoHooks.propTypes = props
_renderContent.propTypes = props
