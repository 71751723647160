/** Icons for campaign objectives  */
import FacebookSales from 'assets/google/google-sales-campaign.svg'
import FacebookLeads from 'assets/google/google-leads-campaign.svg'
import FacebookTraffic from 'assets/google/google-website-traffic.svg'
import FacebookEngagement from 'assets/google/google-product-and-brand.svg'
import FacebookAwareness from 'assets/google/google-brand-awareness.svg'
import FacebookAppPromotion from 'assets/google/google-app-promotions.svg'

/* Icons for experience picker */
import LegacyIcon from 'assets/facebook/icon_legacy.svg'
import OdaxIcon from 'assets/icon_stars.svg'
import { accounts, utils, flow } from '@decision-sciences/qontrol-common'

/** Constants */
import {
  ODAX_CONNECTION_AUDIENCE_TABS,
  ODAX_INITIAL_URL_PARAMS,
} from 'modules/facebook'
import { format } from 'date-fns'
import { BILLING_EVENTS } from 'modules/flow/components/facebook/adset/facebook-adset-optimization/constants'
import {
  AD_CONTENT_DEFAULTS,
  TYPES_OF_AD_CONTENT,
} from 'modules/flow/constants'

const { ACCOUNT_TYPES_MAP } = accounts
const { addHoursToDate } = utils.date
const {
  ODAX_OBJECTIVES_MAP,
  ODAX_OBJECTIVES_MAP_LABELS,
  CAMPAIGN_EXPERIENCE,
  BID_STRATEGY_TYPE,
  BUDGET_TYPE,
} = flow.facebook
const { CAMPAIGN_STATUS, ADSET_STATUS, AD_STATUS } = flow.facebook.status
const { ADSET_CONVERSION } = flow.facebook.odax

const { ODAX } = ACCOUNT_TYPES_MAP

const ODAX_CAMPAIGN_ICONS = {
  ODAX_AWARENESS: FacebookAwareness,
  ODAX_TRAFFIC: FacebookTraffic,
  ODAX_ENGAGEMENT: FacebookEngagement,
  ODAX_LEADS: FacebookLeads,
  ODAX_APP_PROMOTION: FacebookAppPromotion,
  ODAX_SALES: FacebookSales,
}

const today = new Date()
//  Start Time: user’s current time + 3h
const startTime = addHoursToDate(today, 3)

export const SAVED_AUDIENCE_DEFAULTS = {
  connections: {
    all_people: true,
    custom_audiences: {
      local: [],
      remote: [],
    },
    connection_type: {
      selected: 0,
      selectedTab: ODAX_CONNECTION_AUDIENCE_TABS.facebook_pages,
      options: {
        connections: [],
        excluded_connections: [],
        friends_of_connections: [],
        facebook_pages: {
          people: true,
          friends: false,
          exclude: false,
          selected: [],
        },
        apps: {
          people: true,
          friends: false,
          exclude: false,
          selected: [],
        },
        events: {
          people: true,
          exclude: false,
          selected: [],
        },
        advanced: {
          people: [],
          friends: [],
          exclude: [],
        },
      },
    },
  },
  inclusions: [],
  exclusions: [],
  detailed_targeting: {
    selected_items: [],
  },
  locations: {
    type: 'home#recent',
    age_min: '18',
    age_max: '65+',
    locations_array: [],
    gender: 0,
  },
  catalog_sales_audience_type: null,
  limit_to_events_from_product_set: false,
  saved_audience_languages: [],
}

const ODAX_IMPLEMENTED_CAMPAIGN_OBJECTIVES = [
  ODAX_OBJECTIVES_MAP.ODAX_AWARENESS,
  ODAX_OBJECTIVES_MAP.ODAX_SALES,
]

export const ODAX_CAMPAIGN_OBJECTIVES = Object.keys(ODAX_OBJECTIVES_MAP).map(
  (key) => {
    return {
      label: ODAX_OBJECTIVES_MAP_LABELS[ODAX_OBJECTIVES_MAP[key]],
      icon: ODAX_CAMPAIGN_ICONS[key],
      value: ODAX_OBJECTIVES_MAP[key],
      onRoadMap: !ODAX_IMPLEMENTED_CAMPAIGN_OBJECTIVES.includes(
        ODAX_OBJECTIVES_MAP[key]
      ),
    }
  }
)

export const CAMPAIGN_EXPERIENCE_ICONS = {
  [CAMPAIGN_EXPERIENCE.LEGACY]: LegacyIcon,
  [CAMPAIGN_EXPERIENCE.ODAX]: OdaxIcon,
}

export const CAMPAIGN_BUDGET_DEFAULTS = {
  budget_type: BUDGET_TYPE.DAILY_BUDGET,
  daily_budget: null,
  lifetime_budget: null,
  bid_strategy: null,
  ads_delivery_type: false,
  scheduled_ads: false,
}

export const CAMPAIGN_DEFAULTS = {
  type: ODAX,
  isOdax: true,
  status: CAMPAIGN_STATUS.PAUSED,
  objective: null,
  special_ad_categories: null,
  special_ad_category_country: null,
  buying_type: null,
  has_spend_cap: false,
  spend_cap: null,
  /* For scheduled ads */
  pacing_type: null,
  promoted_object: {
    product_catalog_id: null,
  },
  ...CAMPAIGN_BUDGET_DEFAULTS,
}

export const ADSET_DEFAULTS = {
  type: ODAX,
  status: ADSET_STATUS.PAUSED,
  id: null,
  campaignId: null,
  account_id: null,
  name: null,
  lifetime_budget: 0,
  daily_budget: 0,
  deleted: false,
  copiedId: null,
  promoted_object: {
    page_id: null,
    pixel_id: null,
    custom_event_type: null,
    local__conversion: ADSET_CONVERSION.WEBSITE.value,
  },
  instagram_actor_id: null,
  schedule: {
    start_date: format(new Date(), 'MM/dd/yyyy'),
    start_time: format(startTime, `hh:mm a`),
    end_date: '',
    end_time: '',
    ad_schedule: {
      is_scheduled: null,
    },
    ad_spend_limits: {
      has_spend_limits: false,
    },
  },
  placements: {
    automatic: true, // true/false
    manual: {
      device_platforms: { mobile: true, desktop: true },
      publisher_platforms: {},
      list: {}, // holds the entire manual placements selection
    },
  },
  exclusions: {},
  block_list_ids: [],
  ad_set_options: {
    optimization: null,
    custom_frequency_cap: {
      enabled: true,
      impressions: 1,
      days: 7,
    },
    bid_strategy: BID_STRATEGY_TYPE.COST_CAP,
    delivery_type: 'Standard',
    app_event: null,
    app_to_promote: {
      platform: null,
      app_id: null,
      object_store_urls: null,
      custom_app_event: [],
      default_app_events: [],
    },
    event_type: null,
    when_you_get_charged: BILLING_EVENTS.IMPRESSIONS.value,
    conversion_window: null,
    conversion_event: {
      type: '',
      platform: null,
      app_id: null,
      object_store_url: null,
      custom_app_events: [],
      default_app_events: [],
    },
    whatsAppPage: null,
    messengerMethod: null,
  },
  ad_set_audience: {
    ...SAVED_AUDIENCE_DEFAULTS,
  },
  // this field will be used if we save audiences on adSet
  saved_audiences: {
    local: null,
    remote: null,
  },
  custom_audiences: {
    local: [],
    remote: [],
  },
  useBidMultiplier: false,
  bidMultiplier: {
    custom_audience: {},
    booking_window: [],
    travel_start_date: [],
    start_days: [],
    user_recency: [],
    length_of_stay: [],
    position_type: [],
    ages: [],
  },
}

export const AD_DEFAULTS = {
  id: null,
  adSetId: null,
  name: null,
  accountId: null,
  type: ODAX,
  status: AD_STATUS.PAUSED,
  deleted: false,
  placements: null,
  identity: {
    page_id: null,
    instagram_actor_id: null,
    use_page_actor_override: false,
    isMainPage: true,
  },
  destination: {
    type: null,
    show_call_extension: false,
  },
  has_website_url: false,
  website_url: {
    url: '',
    build_url_param: false,
    display_link: '',
    primary_destination: '',
    secondary_destination: '',
    call_to_action_btn: null,
    ...ODAX_INITIAL_URL_PARAMS,
  },
  template_id: null,
  conversion_tracking: {
    pixel_id: '',
    conversions_events: null,
    app_events: null,
    offline_events: null,
    url_params: '',
    build_url_param: false,
    ...ODAX_INITIAL_URL_PARAMS,
  },
  ad_setup: {
    type: 'Create Ad',
    format: {
      single_image_or_video: false,
      carousel: false,
      collection: false,
    },
    experience: {
      instant: {
        selected: false,
        template: null,
        search: null,
      },
      playable_source_file: {
        selected: false,
        file: null,
      },
    },
    single_image_or_video_options: {
      website: {
        selected: true,
        website: '',
        linkDisplayText: '',
      },
      facebook_event: {
        selected: false,
        eventNameOrEventUrl: '',
      },
      phone_call: {
        selected: false,
      },
    },
    carousel_options: {
      best_performing_cards_first: true,
      card_end_profile_pic: false,
      map_card_nearest_store: false,
    },
    see_more_url: null,
    see_more_display_text: null,
    deferred_deep_link_url: null,
    deep_link_website: '',
    deep_link_parameters: [],
    mobile_app: null,
    deep_link_destination: {
      web_browser: true,
      mobile_app_fallback_to_url: false,
      mobile_app_fallback_to_app_store: false,
    },
    catalog_cards_creative_options: {
      single_image: true,
      slideshow: false,
    },
  },
  ad_content: {
    selected_type: TYPES_OF_AD_CONTENT[ODAX].PRIMARY_TEXT,
  },
  selected_items: AD_CONTENT_DEFAULTS[ODAX],
  ad_languages: {
    translations: [],
  },
}
