import Api from 'easy-fetch-api'
import { expandLeftMenuDrawer } from 'components/left-menu/actions'
import { ANALYTICS_KEYS } from 'components/left-menu/constants'
import { GRANULARITIES } from 'modules/traq-dashboard/constants'
import { utils, granularities } from '@decision-sciences/qontrol-common'

const { escapeRegExp } = utils.string
const GLOBAL_GRANULARITIES = granularities.GRANULARITIES

export const ACTIONS = {
  GET_TRAQ_TEMPLATES: 'traqTemplates.getAll',
  PUT_TRAQ_TEMPLATE: 'traqTemplates.putTraqTemplate',
  DELETE_TRAQ_TEMPLATE: 'traqTemplates.deleteTraqTemplate',
  MARK_AS_STARRED: 'traqTemplates.markAsStarred',
  GET_FAVOURITE_TRAQ_TEMPLATES: 'traqTemplates.getFavouriteTraQTemplates',
  UPDATE_LAST_VIEWED: 'traqTemplates.updateLastViewed',
}

/**
 * Fetches and stores traq templates into the reducer
 * @param {Function} dispatch Dispatch
 * @param {Object} [query] Query to find permission groups by
 * @returns {Array}
 */
export const getTraqTemplates = async (dispatch, query = {}) => {
  return await Api.get({ url: '/api/embarq', query })
    .then((result) => {
      if (!result || result.error) {
        console.error(
          (result && result.error) || 'Error fetching embarQ templates'
        )
        return []
      } else {
        dispatch({
          type: ACTIONS.GET_TRAQ_TEMPLATES,
          traqTemplates: result.list,
        })
        return result.list
      }
    })
    .catch((err) => {
      console.error(err)
      return []
    })
}

/**   Get Traq Template by id */
export const getTraqTemplate = (_id) => {
  return Api.get({ url: `/api/embarq/${_id}` })
    .then((result) => {
      if (!result || result.error || !result.data) {
        console.error('Error embarq template')
      }
      return result
    })
    .catch(console.error)
}

/**
 * Updates a traq template
 * Also updates the reducer value matching the _id
 * @param {*} dispatch Dispatch
 * @param {Object} traqTemplate New Permission Group values
 * @returns {Promise}
 */
export const createUpdateTraqTemplate = async (dispatch, traqTemplate) => {
  return new Promise((resolve, reject) => {
    const newPermissions = cleanUpPermissions(traqTemplate.permissions)
    const { _id } = traqTemplate
    const payload = {
      url: '/api/embarq',
      data: { ...traqTemplate, properties: newPermissions },
    }

    const promise = _id ? Api.put(payload) : Api.post(payload)
    promise
      .then((result) => {
        if (!result || !result.success) {
          reject(result?.error || `Could not save ${traqTemplate.name}`)
        } else {
          dispatch({
            type: ACTIONS.PUT_TRAQ_TEMPLATE,
            traqTemplate: result.data,
          })
          resolve()
        }
      })
      .catch(reject)
  })
}

export const deleteTraqTemplate = (dispatch, _id) => {
  return new Promise((resolve, reject) => {
    Api.delete({ url: `/api/embarq/${_id}` })
      .then((res) => {
        if (res.success) {
          dispatch({
            type: ACTIONS.DELETE_TRAQ_TEMPLATE,
            _id,
          })
          resolve()
        } else {
          reject('Could not delete EmbarQ template')
        }
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

/**
 * Removes false values and empty objects from permissions object
 * @param {Object} permissions Permissions object
 * @returns {Object} permissions object without false values or empty objects
 */
export const cleanUpPermissions = (permissions) => {
  const newPermissions = { ...permissions }
  Object.entries(newPermissions).forEach(([key, values]) => {
    const newValues = { ...values }
    Object.entries(values).forEach(([permissionKey, value]) => {
      if (!value) {
        // We don't need false permissions
        delete newValues[permissionKey]
      }
    })

    if (!Object.keys(newValues).length) {
      delete newPermissions[key]
    } else {
      newPermissions[key] = newValues
    }
  })
  return newPermissions
}

/**
 * Get all the user-traQTemplate relation documents, that contain the favourite templates and lastViewed for each template
 * @param {Function} dispatch dispatch
 */
export const getFavouriteTraQTemplates = (dispatch) => {
  return new Promise((resolve, reject) => {
    Api.get({ url: `/api/user-embarq-templates` })
      .then((res) => {
        if (res?.success) {
          dispatch({
            type: ACTIONS.GET_FAVOURITE_TRAQ_TEMPLATES,
            userTraq: res.list,
          })
          resolve()
        } else {
          reject('Could not get favourite EmbarQ templates')
        }
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

/**
 * Toggle starred state of a TraQ Template for the current user
 * @param {Function} dispatch disptach
 * @param {String} traqTemplate id of TraQ Template
 * @param {Boolean} favorite 'favourite' state to be set of the TraQ Template
 */
export const setTraQTemplateAsFavourite = (
  dispatch,
  traqTemplate,
  favorite
) => {
  Api.post({
    url: '/api/user-embarq-templates',
    data: { traqTemplate, favorite },
  }).then((result) => {
    if (!result || result.error) {
      console.error(
        (result && result.error) || 'Error marking EmbarQ template as starred'
      )
    } else {
      dispatch({
        type: ACTIONS.GET_FAVOURITE_TRAQ_TEMPLATES,
        userTraq: result.data,
      })

      expandLeftMenuDrawer(dispatch, ANALYTICS_KEYS.TRAQ_DASHBOARD)
    }
  })
}

/**
 * Fetch Alert Triggers for a user
 * @param {Object} options options
 * @param {Object} options.numberOfItemsToLoad object containing number of items to load for different types
 *    Each tuple contains a [min, max] number of items to load for that specific type
 * @param {String} options.selectedClient selected client
 * @param {Object} options.filters query filters
 * @param {String} options.traQTemplateId traQ template id
 * @param {Function} options.dispatch dispatch
 * @returns {Promise}
 */
export const getAlertResultsForUserPaginated = ({
  numberOfItemsToLoad,
  selectedClient = null,
  filters = null,
  traQTemplateId,
  dispatch,
}) =>
  new Promise(async (resolve) => {
    const filter = { ...filters }

    /** Break up the call into 3 batches for each column type */
    const {
      needs_immediate_attention: needs_immediate_attention_pagination,
      review_recommended: review_recommended_pagination,
      validated: validated_pagination,
    } = numberOfItemsToLoad

    /** Some filters need to be renamed to match the model */
    if (filter.channel?.length) {
      filter.client = { $in: [...filter.channel] }
    } else {
      delete filter.client
    }
    delete filter.channel

    if (filter.account?.length) {
      const list = filter.account.map(({ value }) => value)
      filter.accountId = {
        $in: [...list],
      }
      filter['entity.id'] = {
        $in: [...list],
      }
    }
    delete filter.account

    if (filter.granularity.length) {
      filter.granularity = {
        $in: filter.granularity.map((gran) => GRANULARITIES[gran].self),
      }
    } else {
      delete filter.granularity
    }

    if (filter.alertType?.length) {
      filter.alertType = { $in: [...filter.alertType] }
    } else {
      delete filter.alertType
    }

    if (filter.campaigns?.length) {
      filter['entity.id'] = {
        $in: [
          ...(filter['entity.id']?.$in || []),
          ...filter.campaigns.map(({ value }) => value),
        ],
      }
    }
    delete filter.campaigns

    if (filter.adGroups?.length) {
      filter['entity.id'] = {
        $in: [
          ...(filter['entity.id']?.$in || []),
          ...filter.adGroups.map(({ value }) => value),
        ],
      }
    }
    delete filter.adGroups

    if (filter.ads?.length) {
      filter['entity.id'] = {
        $in: [
          ...(filter['entity.id']?.$in || []),
          ...filter.ads.map(({ value }) => value),
        ],
      }
    }
    delete filter.ads

    if (filter.keywords?.length) {
      filter['entity.id'] = {
        $in: [
          ...(filter['entity.id']?.$in || []),
          ...filter.keywords.map(({ value }) => value),
        ],
      }
    }
    delete filter.keywords

    if (!filter.search) {
      delete filter.search
    }

    delete filter.allEntitiesPerGranularity

    /** Needs Immediate Attention */
    const needs_immediate_attention_promise = Api.get({
      url: `/api/alert-results/${traQTemplateId}/needs_immediate_attention`,
      query: {
        filter,
        skip: needs_immediate_attention_pagination[0],
        limit:
          needs_immediate_attention_pagination[1] -
          needs_immediate_attention_pagination[0],
        selectedClient,
      },
    }).catch((err) =>
      console.error(
        `Error getting Alert Results - needs_immediate_attention column.`,
        err
      )
    )

    /** Review Recommended */
    const review_recommended_promise = Api.get({
      url: `/api/alert-results/${traQTemplateId}/review_recommended`,
      query: {
        filter,
        skip: review_recommended_pagination[0],
        limit:
          review_recommended_pagination[1] - review_recommended_pagination[0],
        selectedClient,
      },
    }).catch((err) =>
      console.error(
        `Error getting Alert Results - review_recommended column.`,
        err
      )
    )

    /** Validated */
    const validated_promise = Api.get({
      url: `/api/alert-results/${traQTemplateId}/validated`,
      query: {
        filter,
        skip: validated_pagination[0],
        limit: validated_pagination[1] - validated_pagination[0],
        selectedClient,
      },
    }).catch((err) =>
      console.error(`Error getting Alert Results - validated column.`, err)
    )

    const [needs_immediate_attention, review_recommended, validated] =
      await Promise.all([
        needs_immediate_attention_promise,
        review_recommended_promise,
        validated_promise,
      ])

    const { userTraqTemplate } =
      needs_immediate_attention || review_recommended || validated || {}
    if (userTraqTemplate?.lastViewed) {
      dispatch({
        type: ACTIONS.UPDATE_LAST_VIEWED,
        userTraqTemplate,
      })
    }

    return resolve({
      success: true,
      result: {
        needs_immediate_attention: needs_immediate_attention?.success
          ? needs_immediate_attention?.list || []
          : [],
        needsImmediateAttentionElementsLeft:
          (needs_immediate_attention?.list || []).length ===
          needs_immediate_attention_pagination[1] -
            needs_immediate_attention_pagination[0],
        review_recommended: review_recommended?.success
          ? review_recommended?.list || []
          : [],
        reviewRecommendedElementsLeft:
          (review_recommended?.list || []).length ===
          review_recommended_pagination[1] - review_recommended_pagination[0],
        validated: validated?.success ? validated?.list || [] : [],
        validatedElementsLeft:
          (validated?.list || []).length ===
          validated_pagination[1] - validated_pagination[0],
      },
    })
  })

/**
 * Fetch entities for a granulrity for the filtering module on the traq-dashboard
 * @param {String} traQTemplateId traq template ID
 * @param {String} granularity granularity to fetch entities for
 * @param {String} selectedClient client
 * @param {Object} allSelectedParents All selected parent entities per granularity
 * @returns {Promise}
 */
export const fetchEntitiesForGranularity = async (
  traQTemplateId,
  granularity,
  selectedClient,
  allSelectedParents,
  search,
  channel
) => {
  // Accounts have to be fetched the old way since there's no separate tree entity for them
  granularity = GRANULARITIES[granularity].self

  const isAccount = granularity === GRANULARITIES.Account.self

  const url = isAccount
    ? `/api/alert-results/${traQTemplateId}/entities/${granularity}`
    : `/api/alert-results/tree/${granularity.replace('/', 'or')}`

  // Filter based on selected parents as well
  const crossFilters = Object.entries(allSelectedParents).reduce(
    (acc, [gran, selectedParents]) => {
      const { id } = Object.values(GRANULARITIES).find(
        ({ self }) => self === GLOBAL_GRANULARITIES[gran]
      )

      return {
        ...acc,
        [id]: selectedParents.map(({ value }) => value).join(','),
      }
    },
    {}
  )

  const entitiesPerGranularity = await Api.get({
    url,
    query: isAccount
      ? {
          selectedClient,
          ...(search ? { 'entity.name': `/${escapeRegExp(search)}/i` } : {}),
          ...(channel
            ? {
                client: (Array.isArray(channel)
                  ? [...channel]
                  : [channel]
                ).join(','),
              }
            : {}),
        }
      : {
          company: selectedClient,
          traqTemplates: traQTemplateId,
          limit: 50,
          sort: 'name',
          fields: 'id,name,accountId,campaignId,adgroupId,publisher',
          ...(search ? { name: `/${escapeRegExp(search)}/i` } : {}),
          ...(channel
            ? {
                publisher: (Array.isArray(channel)
                  ? [...channel]
                  : [channel]
                ).join(','),
              }
            : {}),
          ...crossFilters,
        },
  }).catch((err) => {
    console.error(`Error getting entities for ${granularity}.`, err)
    return []
  })

  if (!entitiesPerGranularity.success) {
    console.error(`Error getting entities for ${granularity}.`)
    return []
  }

  return entitiesPerGranularity.entities
}

/**
 * Fetch publishers that have alert-results for thegiven embarQ template and client
 * @param {String} traQTemplateId
 * @param {String} selectedClient
 * @param {Array<String>} alertTypes filter by alert type
 * @returns {Promise}
 */
export const getPublishers = async (
  traQTemplateId,
  selectedClient,
  alertType = null
) => {
  const url = `/api/alert-results/${traQTemplateId}/${selectedClient}/publishers`

  const publishers = await Api.get({
    url,
    query: { ...(alertType ? { alertType } : {}) },
  }).catch((err) => {
    console.error(`Error getting publishers.`, err)
    return []
  })

  if (!publishers.success) {
    console.error(`Error getting entities publishers.`)
    return []
  }

  return publishers.entities
}

/**
 * Fetch alert types that have alert-results for thegiven embarQ template and client
 * @param {String} traQTemplateId
 * @param {String} selectedClient
 * @param {Array<String>} publishers filter by publishers
 * @returns {Promise}
 */
export const getAlertTypes = async (
  traQTemplateId,
  selectedClient,
  publishers
) => {
  const url = `/api/alert-results/${traQTemplateId}/${selectedClient}/alert-types`

  const alertTypes = await Api.get({
    url,
    query: { ...(publishers ? { client: publishers.join(',') } : {}) },
  }).catch((err) => {
    console.error(`Error getting Alert Types.`, err)
    return []
  })

  if (!alertTypes.success) {
    console.error(`Error getting entities Alert Types.`)
    return []
  }

  return alertTypes.entities
}

export const getGranularitiesOfAlert = (alert) => {
  const granularities = []

  if (!alert) {
    return granularities
  }

  /** Performance alerts */
  for (const value of Object.values(alert.selectedElements || {})) {
    if (value?.elements) {
      granularities.push(...value.elements)
    }
  }

  /** Settings alerts */
  for (const properties of Object.values(alert.properties || {})) {
    for (const property of properties) {
      const { type, props } = property
      if (type) {
        granularities.push(type)
      }

      /** Conditional chain */
      for (const innerProperty of Object.values(props || {})) {
        const { innerType } = innerProperty
        if (innerType) {
          granularities.push(innerType)
        }
      }
    }
  }

  return [...new Set([...granularities])]
}
