import Loader from 'components/loader/index'
import PropTypes from 'prop-types'
import cx from 'classnames'

import './style.scss'

/**
 * Renders an overlay over the whole parent showing a `Loader` or children
 * To be used with relative positioned parents.
 * @param {Object} props Component props
 * @param {Node} [props.children] Elements to render inside the overlay
 * @param {Boolean} [props.visible] Flag to show or hide the overlay
 * @param {Boolean} [props.loading] Flag to
 * @returns {React.FunctionComponent}
 */
const LoadingOverlay = ({ children, visible, loading }) => {
  if (!visible) {
    return null
  }

  if (children) {
    return (
      <div
        className={cx('loading__overlay', {
          'loading__overlay--loading': loading,
        })}
      >
        {children}
      </div>
    )
  }

  return (
    <div className="loading__overlay">
      <Loader />
    </div>
  )
}

LoadingOverlay.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
}

export default LoadingOverlay
