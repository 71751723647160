import { v4 as uuidv4 } from 'uuid'

import { ACTIONS } from './actions'
import { findPendingItems } from './utils'

/** Name of the reducer */
const name = 'customColumns'

/** Initial state of the reducer */
const initialState = {
  opened: false,
  pauseAutomaticLoading: false,
  executeExternalAction: null,
  customColumns: {},
  loading: false,
  sheet: '',
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.TOGGLE_CUSTOM_COLUMNS]: (state) => {
    return { ...state, opened: !state.opened }
  },
  [ACTIONS.EXECUTE_EXTERNAL_ACTION]: (state, { action }) => {
    return { ...state, executeExternalAction: action }
  },
  [ACTIONS.EXTERNAL_ACTION_FINISHED]: (state) => {
    return { ...state, executeExternalAction: null }
  },
  [ACTIONS.TOGGLE_PAUSE_AUTOMATIC_LOADING]: (state) => {
    return { ...state, pauseAutomaticLoading: !state.pauseAutomaticLoading }
  },
  [ACTIONS.SET_PAUSE_AUTOMATIC_LOADING]: (state, { value }) => {
    return { ...state, pauseAutomaticLoading: value }
  },
  [ACTIONS.SET_LOADING]: (state, { loading }) => {
    return { ...state, loading }
  },
  [ACTIONS.SEARCH_LIBRARY_ITEMS]: (state, { query }) => {
    if (!query) {
      return {
        ...state,
        customColumns: {
          ...state.customColumns,
          [state.sheet]: {
            ...(state.customColumns[state.sheet] || {}),
            availableItems: [...(state.customColumns[state.sheet]?.data || [])],
          },
        },
      }
    }

    const queryLowercased = query.toLowerCase()
    const newAvailableItems = state.customColumns[state.sheet]?.data.map(
      (category) => {
        const filteredDimensions = category.dimensions.filter((el) =>
          el.dimension.name.toLowerCase().includes(queryLowercased)
        )

        return { ...category, dimensions: [...filteredDimensions] }
      }
    )

    return {
      ...state,
      customColumns: {
        ...state.customColumns,
        [state.sheet]: {
          ...(state.customColumns[state.sheet] || {}),
          availableItems: [...(newAvailableItems || [])],
        },
      },
    }
  },
  [ACTIONS.ADD_ROW]: (state, { item }) => {
    const newItem = { ...item }
    newItem._id = uuidv4()
    const newState = {
      ...state,
      customColumns: {
        ...state.customColumns,
        [state.sheet]: {
          ...(state.customColumns[state.sheet] || {}),
          rows: [...state.rows, item],
        },
      },
    }
    return { ...newState }
  },
  [ACTIONS.ADD_COLUMN]: (state, { item }) => {
    const newItem = { ...item }
    newItem._id = uuidv4()
    const newState = {
      ...state,
      customColumns: {
        ...(state.customColumns || {}),
        [state.sheet]: {
          ...(state.customColumns[state.sheet] || {}),
          columns: [...state.customColumns[state.sheet].columns, newItem],
        },
      },
    }
    return { ...newState }
  },
  [ACTIONS.SET_PENDING_COLUMNS]: (state) => {
    const sheetState = state.customColumns[state.sheet]
    const newState = {
      ...state,
      customColumns: {
        ...(state.customColumns || {}),
        [state.sheet]: {
          ...(sheetState || {}),
          pendingColumns: {
            ...(sheetState.pendingColumns || {}),
            pendingItems: findPendingItems(
              sheetState.pendingColumns?.originalItems || [],
              [...state.customColumns[state.sheet].columns]
            ),
            originalItems: state.pauseAutomaticLoading
              ? [...sheetState.pendingColumns?.originalItems]
              : [...sheetState.columns],
          },
          pendingRows: {
            ...(sheetState.pendingRows || {}),
            pendingItems: findPendingItems(
              sheetState.pendingRows?.originalItems || [],
              [...sheetState.rows]
            ),
            originalItems: state.pauseAutomaticLoading
              ? [...sheetState.pendingRows?.originalItems]
              : [...sheetState.rows],
          },
        },
      },
    }
    return { ...newState }
  },
  [ACTIONS.RESET_PENDING_COLUMNS]: (state) => {
    const newState = {
      ...state,
      customColumns: {
        ...(state.customColumns || {}),
        [state.sheet]: {
          ...(state.customColumns[state.sheet] || {}),
          pendingColumns: {
            ...(state.customColumns[state.sheet].pendingColumns || {}),
            originalItems: [...state.customColumns[state.sheet].columns] || [],
            pendingItems: [],
          },
        },
      },
    }
    return { ...newState }
  },
  [ACTIONS.RESET_PENDING_ROWS]: (state) => {
    const newState = {
      ...state,
      customColumns: {
        ...(state.customColumns || {}),
        [state.sheet]: {
          ...(state.customColumns[state.sheet] || {}),
          pendingRows: {
            ...(state.customColumns[state.sheet].pendingRows || {}),
            originalItems: [...state.customColumns[state.sheet].rows] || [],
            pendingItems: [],
          },
        },
      },
    }
    return { ...newState }
  },
  [ACTIONS.REPLACE_ROW]: (state, { item }) => {
    const newState = {
      ...state,
      customColumns: {
        ...state.customColumns,
        [state.sheet]: {
          ...(state.customColumns[state.sheet] || {}),
          rows: [item],
        },
      },
    }
    return { ...newState }
  },
  [ACTIONS.REMOVE_COLUMN]: (state, { item }) => {
    const newColumns = state.customColumns?.[state?.sheet]?.columns?.filter(
      (column) => column._id !== item._id
    )
    const newState = {
      ...state,
      customColumns: {
        ...state.customColumns,
        [state.sheet]: {
          ...(state.customColumns[state.sheet] || {}),
          columns: [...(newColumns || [])],
        },
      },
    }
    return { ...newState }
  },
  [ACTIONS.SET_REPORT_SHEET]: (state, { sheet }) => {
    const newState = {
      ...state,
      sheet: sheet,
      opened: false,
    }
    return { ...newState }
  },
  [ACTIONS.SET_ITEMS]: (state, { sheet, items }) => {
    if (sheet) {
      const reportSheetState = state.customColumns[sheet]
      const newState = {
        ...state,
        customColumns: {
          ...state.customColumns,
          [sheet]: {
            ...(reportSheetState || {}),
            data: [...(items?.availableItems || reportSheetState?.data || [])],
            availableItems: [
              ...(items?.availableItems ||
                reportSheetState?.availableItems ||
                []),
            ],
            rows: [...(items?.rows || reportSheetState?.rows || [])],
            columns: [...(items?.columns || reportSheetState?.columns || [])],
            pendingColumns: {
              originalItems: [
                ...(items?.originalColumns ||
                  items?.columns ||
                  reportSheetState?.columns ||
                  []),
              ],
              pendingItems: [...(items?.pendingColumns || [])],
            },
            pendingRows: {
              originalItems: [
                ...(items?.orginalRows ||
                  items?.rows ||
                  reportSheetState?.rows ||
                  []),
              ],
              pendingItems: [...(items?.pendingRows || [])],
            },
          },
        },
      }
      return { ...newState }
    }
    return { ...state }
  },
  [ACTIONS.INSERT_AFTER_ITEM]: (state, { item, previousItem }) => {
    const newItem = { ...item }
    newItem._id = uuidv4()
    const currentSheetColumns =
      state.customColumns?.[state.sheet]?.columns || []

    // Find the index of the item after which the new item should be inserted
    const insertIndex = currentSheetColumns.findIndex(
      (column) => column._id === previousItem._id
    )

    const newColumns = [...currentSheetColumns]
    // If the item is found, insert the new item after it; otherwise, just add the item to the end
    if (insertIndex !== -1) {
      newColumns.splice(insertIndex + 1, 0, newItem)
    } else {
      newColumns.push(newItem)
    }

    const newState = {
      ...state,
      customColumns: {
        ...(state.customColumns || {}),
        [state.sheet]: {
          ...(state.customColumns[state.sheet] || {}),
          columns: newColumns,
        },
      },
    }

    return { ...newState }
  },
  [ACTIONS.MOVE_ITEM]: (state, { item, previousItem }) => {
    const currentSheetColumns =
      state.customColumns?.[state.sheet]?.columns || []

    const itemIndex = currentSheetColumns.findIndex(
      (column) => column._id === item._id
    )

    let insertIndex = currentSheetColumns.findIndex(
      (column) => column._id === previousItem._id
    )

    const newColumns = [...currentSheetColumns]

    // Move item only if the new position is different than current position of the item
    if (itemIndex !== insertIndex + 1) {
      newColumns.splice(itemIndex, 1)

      if (itemIndex > insertIndex) {
        insertIndex = insertIndex + 1
      }
      newColumns.splice(insertIndex, 0, item)
    }

    const newState = {
      ...state,
      customColumns: {
        ...(state.customColumns || {}),
        [state.sheet]: {
          ...(state.customColumns[state.sheet] || {}),
          columns: newColumns,
        },
      },
    }

    return { ...newState }
  },
}

export default { name, initialState, reduce }
