import React, { useState, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useNavigate, useOutletContext, useLocation } from 'react-router-dom'

/* Stores */
import { useStore } from 'store'

/* Custom Hooks */
import useLeaveConfirm from 'components/leave-confirm'
import { useAccess, PERMISSIONS, PERMISSION_TYPES } from 'hooks/access'
import { useSocket } from 'components/utils/socket'
import { showErrorMessage } from 'modules/notifications/actions'

import { ReactComponent as UnlinkIcon } from 'assets/icon_unlink.svg'
import { ReactComponent as CheckIcon } from 'assets/icon_check_black.svg'
import { ReactComponent as WarningIcon } from 'assets/icon_warning.svg'
import editIcon from 'assets/icon_edit.svg'

/* Components */
import Button from 'components/button'
import { Dropdown } from 'components/dropdown'
import Modal from 'components/modal'
import Accounts from 'components/accounts'
import AssignUserModal from 'modules/companies/assign-user-modal'
import StickyFooter from 'components/sticky-footer'
import LineDivider from 'components/line-divider'
import Loader from 'components/loader'
import { AccountIcon } from 'components/account-icon'
import Section from 'components/section'
import InputGroup from 'components/input-group'
import Toggle from 'components/toggle'
import CheckboxSelectAll, { useSelectAll } from 'components/checkbox-select-all'
import ButtonToggle from 'components/button-toggle'

import {
  getClientUnlinkedAccounts,
  initialiseClientUnlinkedAccounts,
  setGoogleMccId,
} from 'modules/accounts/actions'

/* Constants */
import { ACCOUNT_TYPES } from 'modules/accounts/common'
import {
  utils,
  accounts,
  entityStatus,
  socket,
} from '@decision-sciences/qontrol-common'
import GoogleMCCIDOverride from './google-mcc-id'
import { AmazonPropertyIds } from './amazon-property-id'

import './style.scss'

const { NOTIFICATIONS } = socket

const { compareIgnoreCase } = utils.string
const { ACCOUNT_TYPES_MAP } = accounts
const { ENTITY_STATUS } = entityStatus
const { objectDifference } = utils.array

const CreateEditAccount = ({ accountKey }) => {
  const { dispatch, state } = useStore()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { list: usersList } = state.users
  const { userData } = state.session
  const {
    accounts: { updatedGoogleMccId },
  } = state
  const [selectedTypes, setSelectedTypes] = useState(null)
  const [originalUnlinkedAccounts, setOriginalUnlinkedAccounts] = useState(null)
  const {
    company,
    setCompany,
    defaultCompany,
    businessUnitData,
    currentBusinessUnitData,
    usersData,
  } = useOutletContext()

  const { businessUnitList } = businessUnitData
  const { businessUnit, setBusinessUnit, idParam } = currentBusinessUnitData
  const crtCompany = businessUnit || company

  const location = useLocation()
  const { accounts } = location.state || {}

  // Id of the account that the delete popup appears for.
  const [accountsToDelete, setAccountsToDelete] = useState()
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false)
  // Id of the account that the assign users modal appears for.
  const [assignUsersId, setAssignUsersId] = useState(null)
  // Currently edited accounts (determines whether editMode is active or not)
  const [editedAccounts, setEditedAccounts] = useState(
    accounts?.reduce(
      (prev, current) => ({
        ...prev,
        [current.externalAccountId]: current,
      }),
      {}
    ) || {}
  )
  // Holds the currently selected account types when creating, and the selected accounts
  const [accountsToLink, setAccountsToLink] = useState({})
  const [mccError, setMccError] = useState(null)
  const [accountsToBUs, setAccountsToBUs] = useState({})
  const [isBulkEdit, setIsBulkEdit] = useState(false)
  const [bulkEditState, setBulkEditState] = useState({})

  // Holds currently loading accounts
  const [loadingAccounts, setLoadingAccounts] = useState({})

  const [setDirty, LeaveConfirm] = useLeaveConfirm({})
  const editMode = !!Object.keys(editedAccounts).length

  // On mount, reset mcc id
  useEffect(() => {
    if (updatedGoogleMccId) {
      setGoogleMccId(dispatch, '')
    }
  }, [])

  const onCancel = () => {
    // Reset mcc id if it was set
    if (updatedGoogleMccId) {
      setGoogleMccId(dispatch, '')
    }
    goBack()
  }

  /**
   * Prepare the URL and data to return to either the BU or Client that the user was on
   */
  const goBack = () => {
    const baseUrl = `/company/${company.new ? 'new' : company.clientId}`
    navigate(businessUnit ? `${baseUrl}/business-unit/${idParam}` : baseUrl)
  }

  // Whenever an account type is removed, also remove the list of linked accounts.
  useEffect(() => {
    const newAccountsToLink = accountsToLink ? { ...accountsToLink } : {}

    if (accountsToLink) {
      Object.keys(accountsToLink).forEach((accountType) => {
        if (!selectedTypes.some((type) => type === accountType)) {
          delete newAccountsToLink[accountType]
        }
      })
    }

    setAccountsToLink(newAccountsToLink)
  }, [JSON.stringify(selectedTypes)])

  const assignableUsers = usersData.getClientUsers(crtCompany._id)

  // Filter out accounts that have been linked only locally
  const unLinkedAccounts = useMemo(() => {
    const { accounts } = crtCompany
    const unlinked = structuredClone(originalUnlinkedAccounts)

    if (unlinked && accounts?.length) {
      accounts.forEach((acc) => {
        if (acc.type) {
          const idx = unlinked[acc.type]?.findIndex((account) => {
            return (
              `${account.id}` === `${acc.externalAccountId}` &&
              (!account.profileId ||
                `${account.profileId}` === `${acc.gcmProfileId}`)
            )
          })

          if (idx >= 0) {
            unlinked[acc.type].splice(idx, 1)
          }
        }
      })
    }

    const recentlyRemovedAccounts = objectDifference(
      structuredClone(defaultCompany.accounts),
      company.accounts,
      'externalAccountId'
    )

    if (unlinked) {
      recentlyRemovedAccounts.forEach((account) => {
        if (!unlinked[account.type]) {
          unlinked[account.type] = []
        }

        const exists = unlinked[account.type].some((unlinkedAccount) => {
          return (
            `${unlinkedAccount.id}` === `${account.externalAccountId}` &&
            (!unlinkedAccount.profileId ||
              `${unlinkedAccount.profileId}` === `${account.profileId}`)
          )
        })

        if (!exists) {
          // Select only the relevant fields to prevent duplication based on additional fields
          const accountToInsert = {
            id: account.externalAccountId,
            name: account.name,
          }

          // Add other relevant fields if needed
          if (account.profileId) {
            accountToInsert.profileId = account.profileId
          }

          unlinked[account.type].splice(0, 0, accountToInsert)
        }
      })
    }

    return unlinked
  }, [
    JSON.stringify(originalUnlinkedAccounts),
    JSON.stringify(company.accounts),
    JSON.stringify(defaultCompany.accounts),
  ])

  /**
   * Returns a function usable in useEffect to initialise retrieving accounts for a specific account type
   */
  const initialiseFetchingAccounts = () => {
    if (!editMode && !originalUnlinkedAccounts) {
      Object.values(ACCOUNT_TYPES_MAP).forEach((publisher) =>
        setLoadingAccounts((loadingAccounts) => ({
          ...loadingAccounts,
          [publisher]: true,
        }))
      )

      setLoading(true)

      initialiseClientUnlinkedAccounts(dispatch, crtCompany?._id)
        .catch((e) => {
          console.error(e)
          setLoading(false)
          Object.values(ACCOUNT_TYPES_MAP).forEach((publisher) =>
            setLoadingAccounts((loadingAccounts) => ({
              ...loadingAccounts,
              [publisher]: false,
            }))
          )
        })
        .then(() => {
          setLoading(false)
        })
    }
  }

  const getUnlinkedAccountsForType = (
    type,
    force = false,
    extraConfig = {}
  ) => {
    return getClientUnlinkedAccounts(
      dispatch,
      type,
      crtCompany?._id,
      force,
      extraConfig
    )
      .then((accounts) => {
        setOriginalUnlinkedAccounts((state) => ({
          ...state,
          [type]: accounts,
        }))
      })
      .catch(console.error)
  }

  const socket = useSocket({ room: crtCompany._id })

  useEffect(() => {
    if (socket?.connected) {
      socket.on(NOTIFICATIONS.linkAccounts.receive, ({ success, type }) => {
        if (!success) {
          setLoading(false)
          setLoadingAccounts((loadingAccounts) => ({
            ...loadingAccounts,
            [type]: false,
          }))
          showErrorMessage(`Error fetching accounts of type ${type}`, dispatch)
        } else {
          !editMode &&
            getUnlinkedAccountsForType(type).finally(() => {
              setLoading(false)
              setLoadingAccounts((loadingAccounts) => ({
                ...loadingAccounts,
                [type]: false,
              }))
            })
        }
      })
    }

    return () => socket?.removeAllListeners(NOTIFICATIONS.linkAccounts.receive)
  }, [socket?.connected, editMode])

  // Get Unlinked Accounts of all publishers on entering page
  useEffect(() => {
    if (socket?.connected) {
      initialiseFetchingAccounts()
    }
  }, [
    JSON.stringify(crtCompany),
    editMode,
    socket?.connected,
    originalUnlinkedAccounts,
  ])

  // Check whether all edited accounts exist within the client and its business units and cancel editing them if they don't.
  useEffect(() => {
    const companyAccounts = structuredClone(company.accounts) // Clone to avoid mutations

    const businessUnitAccounts = company.businessUnits.reduce((arr, bu) => {
      return [
        ...arr,
        ...bu.accounts.map((acc) => ({
          ...acc,
          businessUnitName: bu.name,
          businessUnitId: bu._id,
        })),
      ]
    }, [])

    const accountKeys = Object.keys(editedAccounts).length
      ? Object.values(editedAccounts).map((item) => item.key)
      : [accountKey]

    const newAccounts = accountKeys?.reduce((acc, key) => {
      if (!key) {
        return acc
      }

      const existingAccount = [
        ...companyAccounts,
        ...businessUnitAccounts,
      ].find((el) => el.externalAccountId === key)
      if (!existingAccount) {
        return acc
      }

      const usersInAccount =
        usersList.filter((user) =>
          user.clients.some((client) =>
            client?.accounts.includes(existingAccount._id)
          )
        ) || []

      acc[key] = {
        ...existingAccount,
        pendingUsers: [],
        usersInAccount,
        removedUsers: new Set(existingAccount.removedUsers || []),
        alreadyAddedToClient: true,
      }
      return acc
    }, {})

    setEditedAccounts(newAccounts)

    setLoading(false)
  }, [
    accountKey,
    JSON.stringify(company.accounts),
    JSON.stringify(company.businessUnits),
    JSON.stringify(usersList),
  ])

  const {
    allCheckedValue,
    toggleSelected,
    toggleAll,
    selectedItems,
    isSomeSelected,
    getIndividualValue,
    getMultipleValues,
  } = useSelectAll(Object.values(editedAccounts), 'externalAccountId')

  const onLinkAccounts = (e) => {
    e.preventDefault()

    const accounts = {}
    Object.entries(accountsToLink).forEach(([type, list]) => {
      list.forEach((account) => {
        if (type === 'googleCampaignManager') {
          ;[account] = account.split('-')
        }

        const accObject = unLinkedAccounts[type].find(
          (acc) => acc.id === account
        )

        accounts[account.toString()] = {
          externalAccountId: account.toString(),
          name: accObject?.name,
          type,
          active: ENTITY_STATUS.ACTIVE,
          company: crtCompany._id,
          timezone: accObject?.timezone || accObject?.timezone_name,
          changed: true,
          options: accObject?.options || {},
          gcmProfileId: accObject?.profileId,
        }
      })
    })
    setDirty(true)
    setEditedAccounts(accounts)
  }

  /** On save handler */
  const onSave = (e) => {
    e.preventDefault()

    const newAccounts = structuredClone(
      (crtCompany.accounts || [])
        .concat(
          ...(crtCompany.businessUnits || []).map((unit) => unit.accounts || [])
        )
        .filter((account) => account.company)
    )

    Object.values(editedAccounts).forEach((account) => {
      const idx = (newAccounts || []).findIndex(
        (acc) => acc.externalAccountId === account.externalAccountId
      )

      if (businessUnit) {
        account.company = businessUnit._id
      }

      if (idx !== -1) {
        if (newAccounts[idx]) {
          newAccounts[idx] = account
          if (newAccounts[idx].removedUsers?.size) {
            newAccounts[idx].removedUsers = [...newAccounts[idx].removedUsers]
            newAccounts[idx].changed = true
          }
        }
      } else {
        newAccounts.push(account)
      }
    })

    if (businessUnit) {
      setBusinessUnit({
        accounts: newAccounts,
      })
    } else {
      setCompany({
        accounts: newAccounts,
      })
    }

    setDirty(false)
    setTimeout(() => {
      goBack()
    }, 100)
  }

  /** On delete account handler */
  const deleteAccounts = (ids) => {
    if (Object.keys(accountsToLink || {}).length) {
      const newEditedAccounts = { ...editedAccounts }
      Object.keys(selectedItems).map((item) => {
        delete newEditedAccounts[item]
      })
      setEditedAccounts(newEditedAccounts)
    } else {
      const newAccounts = structuredClone(company.accounts)

      const businessUnitChanges = {}
      // Parse through edited accounts
      Object.keys(ids).map((id) => {
        // If edited account has an id (was created before), delete it from the company
        if (
          editedAccounts[id].alreadyAddedToClient ||
          !editedAccounts[id].businessUnitName
        ) {
          // If account belongs to the company, set its company to null
          if (editedAccounts[id].company === company._id) {
            const foundIndex = newAccounts.findIndex(
              (account) => account.externalAccountId === id
            )

            if (foundIndex > -1) {
              newAccounts[foundIndex].changed = true
              newAccounts[foundIndex].company = null
            }
            delete editedAccounts[id]
          } else {
            // If account belongs to the company's business units, apply company: null directly under the business units' accounts
            const accountBu = businessUnitList.find(
              (bu) => bu._id === editedAccounts[id].company
            )

            if (!accountBu) {
              return
            }

            if (!businessUnitChanges[accountBu._id]) {
              businessUnitChanges[accountBu._id] = {
                accounts: accountBu.accounts,
              }
            }
            businessUnitChanges[accountBu._id] = {
              accounts: businessUnitChanges[accountBu._id].accounts.map(
                (buAccount) => {
                  if (
                    (editedAccounts[id]?._id &&
                      editedAccounts[id]._id === buAccount._id) ||
                    editedAccounts[id].externalAccountId ===
                      buAccount.externalAccountId
                  ) {
                    return { ...buAccount, changed: true, company: null }
                  }
                  return { ...buAccount }
                }
              ),
            }
            delete editedAccounts[id]
          }

          // Only change company accounts if they've actually been changed
          if (newAccounts.some(({ changed }) => changed)) {
            setCompany({
              accounts: newAccounts,
            })
          }

          // If we're on a specific business unit, save the changes there before applying to whole client
          if (businessUnit) {
            setBusinessUnit(businessUnitChanges[businessUnit._id])
          } else {
            businessUnitData.setBusinessUnits(businessUnitChanges)
          }

          setDirty(false)
          setEditedAccounts(editedAccounts)
        } else {
          // If account is fresh, just don't add it to the company
          const newAccounts = { ...editedAccounts }
          delete newAccounts[id]
          setEditedAccounts(newAccounts)
          setAccountsToDelete(null)
          setDisplayDeleteModal(false)
        }
      })
    }
  }

  const onGoogleMccUpdate = (mccId) => {
    setLoadingAccounts((loadingAccounts) => ({
      ...loadingAccounts,
      [ACCOUNT_TYPES_MAP.GOOGLE]: true,
    }))
    return getUnlinkedAccountsForType(ACCOUNT_TYPES_MAP.GOOGLE, true, {
      mccId,
    }).finally(() => {
      setLoadingAccounts((loadingAccounts) => ({
        ...loadingAccounts,
        [ACCOUNT_TYPES_MAP.GOOGLE]: false,
      }))
    })
  }
  /** Assign an account to a BU or directly to the Company if no businessUnitId is provided */
  const handleAssignToBU = (accountId, businessUnitId) => {
    const newAccounts = { ...editedAccounts }
    if (businessUnitId) {
      newAccounts[accountId].company = businessUnitId
    } else {
      newAccounts[accountId].company = company._id
    }
    setEditedAccounts(newAccounts)
    setDirty(true)
  }

  const handleUsers = (externalAccountId, selectedUserIds) => {
    // Clone editedAccounts to make modifications
    const newAccounts = { ...editedAccounts }

    // Ensure externalAccountId exists in newAccounts
    if (!newAccounts[externalAccountId]) {
      newAccounts[externalAccountId] = {
        pendingUsers: [],
        removedUsers: new Set(),
        usersInAccount: [],
      }
    }

    const currentAccount = newAccounts[externalAccountId]

    // Convert existing lists to Sets for fast lookups
    const userIdsInAccount = new Set(
      (currentAccount.usersInAccount || []).map((user) => user._id)
    )

    // Users that are now selected but were not previously selected
    const addedUserIds = selectedUserIds.filter(
      (userId) => !userIdsInAccount.has(userId)
    )

    // Users that are now deselected
    const deselectedUserIds = Array.from(userIdsInAccount)?.filter(
      (element) => !selectedUserIds.includes(element)
    )

    // Move deselected users to `removedUsers`
    currentAccount.removedUsers = new Set([...deselectedUserIds])

    // Add selected users to `pendingUsers` if they are not already there
    const newUsers = addedUserIds
      .map((userId) => assignableUsers.find((user) => user._id === userId))
      .filter(Boolean)

    currentAccount.pendingUsers = [...newUsers]

    // Remove users that have just been added from `removedUsers`
    addedUserIds.forEach((userId) => currentAccount.removedUsers.delete(userId))

    // If `selectedUserIds` is empty, add all remaining users to `removedUsers`
    if (selectedUserIds.length === 0) {
      // Add all remaining users to `removedUsers`
      const allUsersToRemove = [
        ...currentAccount.pendingUsers.map((user) => user._id),
        ...userIdsInAccount,
      ]

      currentAccount.removedUsers = new Set([...allUsersToRemove])

      // Reset `pendingUsers`
      currentAccount.pendingUsers = []
    }

    // Update state with the new modifications
    setEditedAccounts(newAccounts)
  }

  const assignBU = (externalAccountId, buId) => {
    setAccountsToBUs({
      ...accountsToBUs,
      [externalAccountId]: buId,
    })
    handleAssignToBU(externalAccountId, buId)
  }

  const onConfirm = () => {
    const newAccounts = { ...editedAccounts }

    const { users, status, buId } = bulkEditState
    Object.keys(selectedItems).forEach((id) => {
      if (selectedItems[id] && newAccounts[id]) {
        if (status !== undefined) {
          editedAccounts[id].active = status
        }

        if (users !== undefined) {
          handleUsers(id, users)
        }

        if (bulkEditState.buId !== undefined) {
          setAccountsToBUs({
            ...accountsToBUs,
            [id]: buId,
          })
          const newAccounts = { ...editedAccounts }
          newAccounts[id].company = buId || company._id
        }
        setDirty(true)
        setEditedAccounts(newAccounts)
      }
    })
  }

  const _renderBulkEditSection = () => {
    if (!isBulkEdit) {
      return null
    }
    const hasBusinessUnits = crtCompany.businessUnits?.length > 0

    // Check if any accounts has associated Users
    let anyHasUsers = false
    for (const id of Object.keys(selectedItems)) {
      if (
        (selectedItems[id] &&
          editedAccounts[id] &&
          editedAccounts[id].pendingUsers &&
          Object.keys(editedAccounts[id].pendingUsers).length > 0) ||
        editedAccounts[id].usersInAccount?.length > 0
      ) {
        anyHasUsers = true
        break
      }
    }

    // Check if any accounts has associated Business Unit
    let anyHasBusinessUnits = false
    for (const id of Object.keys(selectedItems)) {
      if (
        selectedItems[id] &&
        editedAccounts[id] &&
        editedAccounts[id].businessUnitName &&
        Object.keys(editedAccounts[id].businessUnitName).length > 0
      ) {
        anyHasBusinessUnits = true
        break
      }
    }

    return (
      <div className="accounts__bulk_edit">
        <Dropdown
          disabled={!hasBusinessUnits || anyHasBusinessUnits}
          defaultState={bulkEditState.buId || ''}
          onChange={(buId) => {
            setBulkEditState({
              buId: buId,
            })
          }}
          labelClassName="accounts__bulk_edit__BUAssotiation"
          label="Business Unit Association"
          className="drop-down--white"
          options={company.businessUnits.map((bu) => ({
            label: bu.name,
            value: bu._id,
          }))}
          defaultOptionText={
            anyHasBusinessUnits ? '<varies>' : 'Select Business Unit'
          }
        />

        <Dropdown
          label="Users"
          multiSelect={true}
          disabled={anyHasUsers}
          defaultOptionText={anyHasUsers ? '<varies>' : 'Select Users'}
          selectedItems={bulkEditState.users || []}
          className="drop-down--white"
          options={assignableUsers?.map((user) => ({
            label: user.name,
            value: user._id,
          }))}
          onChange={(users) => {
            setBulkEditState({
              ...bulkEditState,
              users: users,
            })
          }}
          overwriteSelectedText={`${bulkEditState.users?.length || 0} Users`}
        />

        <Dropdown
          label="Status"
          defaultOptionText="Status"
          defaultState={bulkEditState.status}
          options={[
            { value: true, label: 'Active' },
            { value: false, label: 'Inactive' },
          ]}
          className="drop-down--white accounts__bulk_edit__status"
          onChange={(status) => {
            setBulkEditState({
              ...bulkEditState,
              status: status,
            })
          }}
        />
      </div>
    )
  }
  const _renderBulkEditActions = () => {
    if (!isBulkEdit) {
      return null
    }

    return (
      <div className="teams-alert-notifications__bulk__actions">
        <Button
          disabled={!Object.keys(bulkEditState).length || !isSomeSelected}
          compact
          secondaryGreen
          onClick={() => {
            onConfirm()
            toggleAll(false)
            setBulkEditState({})
            setIsBulkEdit(false)
          }}
          value={
            <div className="align-row gap-10 align-items-center">
              <CheckIcon
                className="teams-alert-notifications__bulk__check"
                width={16}
                height={16}
              />
              <span>Save</span>
            </div>
          }
        />
        <Button
          secondaryGray
          compact
          onClick={() => {
            setBulkEditState({})
            setIsBulkEdit(false)
          }}
          value="Cancel"
        />
      </div>
    )
  }

  const _renderAccountsSection = () => {
    const accountsGroupedByType = Object.values(editedAccounts).reduce(
      (acc, obj) => {
        if (businessUnit?._id) {
          obj.company = businessUnit._id
        }
        if (!acc[obj.type]) {
          acc[obj.type] = []
        }
        acc[obj.type].push(obj)
        return acc
      },
      {}
    )

    return Object.entries(accountsGroupedByType)?.map(([type, accounts]) => {
      const foundType = ACCOUNT_TYPES.find((accType) => accType.id === type)
      const accountsIds = Object.values(accounts).map(
        (obj) => obj.externalAccountId
      )
      return (
        <div className="accounts__section" key={type}>
          <div className="accounts__header">
            <AccountIcon
              accountType={foundType?.id}
              className="account__type__item"
              alt={foundType?.name}
            />
            {foundType?.name}
            <CheckboxSelectAll
              disabled={isBulkEdit}
              isBig
              isBlue
              value={getMultipleValues(accountsIds)}
              label="Select All"
              onClick={() => toggleSelected(accountsIds)}
            />
          </div>
          {accounts?.map((account) => {
            return (
              <AccountItem
                key={account.externalAccountId}
                account={account}
                accountsToBUs={accountsToBUs}
                assignableUsers={assignableUsers}
                assignBU={assignBU}
                handleUsers={handleUsers}
                company={company}
                crtCompany={crtCompany}
                editedAccounts={editedAccounts}
                getIndividualValue={getIndividualValue}
                isBulkEdit={isBulkEdit}
                setEditedAccounts={setEditedAccounts}
                setDirty={setDirty}
                setLoading={setLoading}
                toggleSelected={toggleSelected}
                type={type}
              />
            )
          })}
        </div>
      )
    })
  }
  const _renderEditMode = () => {
    return (
      <Section
        header={
          <>
            Actions
            <div className="teams-alert-notifications__actions__secondary">
              <CheckboxSelectAll
                disabled={!editedAccounts || isBulkEdit}
                className="button button--secondary perform-qa__button"
                value={allCheckedValue}
                label="Select All"
                onClick={() => toggleAll()}
              />
              <ButtonToggle
                className="teams-alert-notifications__actions__toggle-edit"
                icon={editIcon}
                disabled={!editedAccounts || !isSomeSelected}
                onClick={() => {
                  setIsBulkEdit(!isBulkEdit)
                  setBulkEditState({})
                }}
                selected={isBulkEdit}
                label="Edit Selected"
              />
              <Button
                disabled={!editedAccounts || !isSomeSelected}
                compact
                secondaryRed
                onClick={() => {
                  setAccountsToDelete(selectedItems)
                  if (!Object.keys(accountsToLink || {}).length) {
                    setDisplayDeleteModal(true)
                  } else {
                    deleteAccounts(selectedItems)
                  }
                }}
                value={
                  <div className="align-row gap-10 align-items-center">
                    <UnlinkIcon width={16} height={16} className="svg--red" />
                    <span>Unlink Selected</span>
                  </div>
                }
              />
            </div>
          </>
        }
      >
        {_renderBulkEditSection()}
        {_renderBulkEditActions()}
        {_renderAccountsSection()}
      </Section>
    )
  }

  const _renderCreateMode = () => {
    return (
      <>
        <div className="form__section__body">
          <Accounts
            onSelect={setSelectedTypes}
            returnKeys
            availableAccounts={
              unLinkedAccounts ? Object.keys(unLinkedAccounts) : []
            }
            loadingAccounts={loadingAccounts}
            label="Select Publisher"
            disabled={loading}
          />
        </div>

        {selectedTypes?.map((type, idx) => {
          const foundType = ACCOUNT_TYPES.find((accType) => {
            return accType.id === type
          })
          return (
            <div key={idx} className="form__section__body">
              <div className="accounts__create-edit__selection-header">
                <AccountIcon
                  accountType={foundType?.id}
                  className="accounts__create-edit__selection-header-icon"
                  alt={foundType?.name}
                />
                <div className="heading-secondary accounts__create-edit__selection-header-text">
                  {foundType?.name || 'Account'}
                </div>
              </div>
              <LineDivider
                exactWidth="calc(100% + 60px)"
                margin="0 auto 15px -30px"
              />

              {type === ACCOUNT_TYPES_MAP.GOOGLE && userData.isSuperAdmin && (
                <GoogleMCCIDOverride
                  company={crtCompany}
                  onGoogleMccUpdate={onGoogleMccUpdate}
                  error={mccError}
                  setError={setMccError}
                />
              )}

              <div className="general-label">Account Name & ID</div>
              <Dropdown
                defaultOptionText={'Select Accounts'}
                className="half-width"
                multiSelect={true}
                options={(unLinkedAccounts?.[type] || [])
                  .map((acc) => {
                    let label = `${acc.name} - ${acc.id}`

                    if (type === ACCOUNT_TYPES_MAP.GOOGLE_CAMPAIGN_MANAGER) {
                      label += ` (profileId: ${acc.profileId})`
                    }

                    return {
                      value: acc.id,
                      ...(acc.profileId && { extraValue: acc.profileId }),
                      key: acc.profileId
                        ? `${acc.id}-${acc.profileId}`
                        : acc.id,
                      label,
                    }
                  })
                  .sort((a, b) => compareIgnoreCase(a.label, b.label))}
                selectedItems={accountsToLink[type]}
                disabled={loading}
                hasSearch={true}
                getSelectedItemsByKey={
                  type === ACCOUNT_TYPES_MAP.GOOGLE_CAMPAIGN_MANAGER
                }
                onChange={(accounts) => {
                  setDirty(true)
                  setAccountsToLink({
                    ...accountsToLink,
                    [type]: accounts,
                  })
                }}
                loading={!!loadingAccounts[type]}
              />
            </div>
          )
        })}
      </>
    )
  }

  const editHeader =
    editedAccounts && Object.keys(editedAccounts).length === 1
      ? 'Edit Account'
      : 'Edit Accounts'

  return (
    <>
      {assignUsersId && (
        <AssignUserModal
          open={!!assignUsersId}
          users={assignableUsers}
          hiddenUsers={editedAccounts[assignUsersId].pendingUsers || []}
          onClose={() => {
            setAssignUsersId(null)
          }}
          title="Assign Users to Account"
          onSave={(users) => {
            const newAccounts = { ...editedAccounts }

            newAccounts[assignUsersId].pendingUsers = [
              ...users,
              ...(editedAccounts[assignUsersId].pendingUsers || []),
            ]

            newAccounts[assignUsersId].changed = true
            users.forEach((user) => {
              if (newAccounts[assignUsersId].removedUsers) {
                newAccounts[assignUsersId].removedUsers.delete(user._id)
              }
            })
            setEditedAccounts(newAccounts)
            setAssignUsersId(null)
          }}
        />
      )}
      {displayDeleteModal && (
        <Modal
          opened={displayDeleteModal}
          button={
            <Button
              value={
                <div className="align-row gap-10 align-items-center">
                  <CheckIcon
                    className="teams-alert-notifications__bulk__check"
                    width={16}
                    height={16}
                  />
                  <span>Confirm</span>
                </div>
              }
              secondaryGreen={true}
              onClick={() => {
                setAccountsToDelete(null)
                setDisplayDeleteModal(false)
                deleteAccounts(accountsToDelete)
                setDirty(false)
                const isLinkingAccountsMode =
                  Object.keys(accountsToLink).length > 0
                if (
                  (isLinkingAccountsMode &&
                    Object.keys(editedAccounts).length === 0) ||
                  !isLinkingAccountsMode
                ) {
                  setTimeout(() => {
                    goBack()
                  }, 50)
                }
              }}
              disabled={loading}
            />
          }
          buttonSecondary={
            <Button
              value="Cancel"
              disabled={loading}
              onClick={() => {
                setAccountsToDelete(null)
                setDisplayDeleteModal(false)
              }}
              secondaryGray
            />
          }
          heading="Unlink Accounts"
          className="alert-categories__modal"
          icon={<WarningIcon />}
        >
          <div>
            <p>
              You are about to unlink one or more accounts. <br />
              Click the Confirm button to continue with your action. This action
              cannot be undone.
            </p>
          </div>
        </Modal>
      )}

      <Helmet>
        <title>{editMode ? editHeader : 'Link Accounts'}</title>
      </Helmet>
      <div className="heading" data-cy="page-heading">
        {editMode ? editHeader : 'Link Accounts'}
      </div>
      <form
        className="accounts__create-edit"
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <LeaveConfirm />
        {loading ? (
          <Loader />
        ) : editMode ? (
          _renderEditMode()
        ) : (
          _renderCreateMode()
        )}

        <StickyFooter
          buttons={[
            {
              value: 'Link Accounts',
              onClick: onLinkAccounts,
              disabled:
                loading ||
                !!mccError ||
                accountsToLink.length === 0 ||
                !Object.values(accountsToLink).some((type) => {
                  return type.length > 0
                }),
              className: 'space-right',
              renderCondition: !editMode,
            },
            {
              value: 'Save',
              onClick: (e) => {
                onSave(e)
              },
              disabled: loading,
              className: 'space-right',
              renderCondition: editMode,
            },
            {
              value: 'Cancel',
              onClick: onCancel,
              disabled: loading,
              secondaryGray: true,
            },
          ]}
        />
      </form>
    </>
  )
}

CreateEditAccount.propTypes = {
  accountKey: PropTypes.string,
}

export default CreateEditAccount

const AccountItem = ({
  account,
  accountsToBUs,
  assignableUsers,
  assignBU,
  handleUsers,
  company,
  crtCompany,
  editedAccounts,
  getIndividualValue,
  isBulkEdit,
  setEditedAccounts,
  setDirty,
  toggleSelected,
  type,
}) => {
  const {
    name,
    active,
    externalAccountId,
    pendingUsers = [],
    usersInAccount = [],
    removedUsers,
    options,
  } = account

  let allUsers = [...usersInAccount, ...pendingUsers]

  if (removedUsers) {
    const removedUsersArray = Array.isArray(removedUsers)
      ? removedUsers
      : Array.from(removedUsers)
    allUsers = allUsers.filter((user) => !removedUsersArray.includes(user._id))
  }

  const hasAssignAccess = useAccess({
    feature: PERMISSIONS.ASSIGN_USERS_TEAMS_CLIENT,
    type: PERMISSION_TYPES.EDIT,
  })
  const editAccount = (id, field, value) => {
    const newAccounts = { ...editedAccounts }
    newAccounts[id] = { ...newAccounts[id], [field]: value, changed: true }
    setEditedAccounts(newAccounts)
    setDirty(true)
  }

  return (
    <InputGroup
      key={externalAccountId}
      className="accounts__account-section"
      options={[
        {
          render: (
            <CheckboxSelectAll
              disabled={isBulkEdit}
              isBig
              isBlue
              value={getIndividualValue(externalAccountId)}
              onClick={() => toggleSelected(externalAccountId)}
            />
          ),
          width: '35px',
        },
        {
          render: (
            <div className="accounts__create-edit__name">
              <div>{name}</div>
              <div className="accounts__create-edit__id">
                {externalAccountId}
              </div>
            </div>
          ),
        },
        {
          render: (
            <Dropdown
              defaultState={
                accountsToBUs[externalAccountId] || account.company || ''
              }
              disabled={
                isBulkEdit ||
                !!crtCompany.parentCompany ||
                company.businessUnits.length === 0
              }
              onChange={(buId) => {
                assignBU(externalAccountId, buId)
              }}
              options={[
                { label: 'None', value: company?._id },
                ...company.businessUnits?.map((bu) => ({
                  label: bu.name,
                  value: bu._id,
                })),
              ]}
              defaultOptionText={'Select Business Unit'}
            />
          ),
        },
        {
          render: (
            <AmazonPropertyIds
              value={options?.property_id}
              onChange={(property_id) =>
                editAccount(externalAccountId, 'options', {
                  ...options,
                  property_id,
                })
              }
              companyId={company._id}
              accountId={externalAccountId}
            />
          ),
          condition: type === ACCOUNT_TYPES_MAP.GOOGLE_ANALYTICS,
        },

        {
          render: (
            <Dropdown
              multiSelect={true}
              defaultOptionText={'Users'}
              disabled={isBulkEdit}
              className="accounts__create-edit__users-dropdown"
              selectedItems={allUsers?.map((user) => user._id) || []}
              selectAll={true}
              options={assignableUsers?.map((user) => ({
                label: user.name,
                value: user._id,
              }))}
              onChange={(users) => {
                handleUsers(externalAccountId, users)
              }}
              overwriteSelectedText={`${allUsers?.length || 0} Users`}
            />
          ),
          width: 120,
          condition: hasAssignAccess,
        },
        {
          render: (
            <div className="accounts__create-edit__toggle">
              {active ? 'Active' : 'Inactive'}
              <Toggle
                disabled={isBulkEdit}
                value={active}
                defaultChecked={active}
                onChange={(value) =>
                  editAccount(externalAccountId, 'active', value)
                }
              />
            </div>
          ),
          width: 120,
        },
      ]}
    />
  )
}
AccountItem.propTypes = {
  account: PropTypes.object.isRequired,
  accountsToBUs: PropTypes.object.isRequired,
  assignableUsers: PropTypes.object.isRequired,
  assignBU: PropTypes.func.isRequired,
  handleUsers: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  crtCompany: PropTypes.object.isRequired,
  editedAccounts: PropTypes.object.isRequired,
  getIndividualValue: PropTypes.func.isRequired,
  isBulkEdit: PropTypes.bool.isRequired,
  setEditedAccounts: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
  toggleSelected: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
}
