import { getConfig } from 'modules/global/actions'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useStore } from 'store'
import { showSuccessMessage } from 'modules/notifications/actions'
import { getServerInstanceId } from './actions'

const isLocal = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

const VERSION_KEY = 'lc-v'
const VERSION_CHECK_INTERVAL = 1000 * 1 * 60

const AppConfig = () => {
  const { dispatch, state } = useStore()

  const {
    global: { config },
  } = state

  useEffect(() => {
    let interval
    if (!isLocal) {
      checkForNewAppVersion()
      interval = setInterval(checkForNewAppVersion, VERSION_CHECK_INTERVAL)
    }
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!Object.keys(config)?.length) {
      getConfig(dispatch)
    } else {
      const { GOOGLE_TAG_MANAGER_STATUS, GOOGLE_TAG_MANAGER_ID } = config
      if (GOOGLE_TAG_MANAGER_STATUS === 'on' && GOOGLE_TAG_MANAGER_ID) {
        initializeGoogleTag()
      }
    }
  }, [config])

  const initializeGoogleTag = () => {
    const tagManagerArgs = {
      gtmId: config.GOOGLE_TAG_MANAGER_ID,
    }
    TagManager.initialize(tagManagerArgs)
  }

  const checkForNewAppVersion = () => {
    getServerInstanceId()
      .then(({ v }) => {
        const existingVersion = sessionStorage.getItem(VERSION_KEY)
        if (existingVersion && v && existingVersion !== v) {
          showSuccessMessage(
            'New application version available. Please refresh the page to get the update.',
            dispatch,
            false
          )
        }
        sessionStorage.setItem(VERSION_KEY, v)
      })
      .catch(() => {
        console.error('Error getting application version')
      })
  }

  return null
}

export default AppConfig
