import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { CheckboxNoHooks } from 'components/checkbox'

import { ACCOUNT_TYPE_ICONS } from 'constants/account'
import { ReactComponent as GlobalProviderIcon } from 'assets/global-provider.svg'
import { ReactComponent as RemoveIcon } from 'assets/icon_minus.svg'

import './style.scss'

/**
 * Displays a simple icon based on accountType
 * @param {*} props Props
 * @param {String} props.accountType Type of account (facebook, google, etc)
 */
export const AccountIcon = ({ accountType, ...other }) => {
  if (
    !accountType ||
    (!ACCOUNT_TYPE_ICONS[accountType] && accountType !== 'GLOBAL')
  ) {
    return null
  }

  const PublisherIcon =
    accountType === 'GLOBAL'
      ? GlobalProviderIcon
      : ACCOUNT_TYPE_ICONS[accountType]

  return <PublisherIcon className="account-icon" alt={'icon'} {...other} />
}

export const AccountInformation = ({
  accountType,
  accountName,
  className,
  externalAccountId,
  onRemove,
}) => {
  return (
    <div className={cx(className, 'align-row item-wd__accounts')}>
      <AccountIcon accountType={accountType} />
      <div className="item-wd__accounts__names">
        <span>{accountName}</span>
        <span>{externalAccountId}</span>
      </div>
      {!!onRemove && (
        <div className="item-wd__accounts__remove-icon" onClick={onRemove}>
          <RemoveIcon />
        </div>
      )}
    </div>
  )
}

export const AccountDropdownRow = ({
  option,
  selectedItems = [],
  noCheckbox = false,
}) => {
  const renderAccountName = () => {
    if (!option.accountName) {
      return null
    }

    return `Account: ${option.accountName} `
  }

  const renderAccountId = () => {
    if (!option.accountId) {
      return null
    }

    return option.accountName ? `(${option.accountId})` : option.accountId
  }

  const renderConversionId = () => {
    if (!option.id) {
      return null
    }

    if (option.id === option.accountId) {
      return null
    }

    return ` | ${option.id}`
  }

  const renderSource = () => {
    if (!option.source) {
      return null
    }
    return ` | ${option.source}`
  }

  return (
    <div className="align-row account-dropdown">
      <CheckboxNoHooks
        icon={<AccountIcon accountType={option.type} />}
        label={option.label}
        isChecked={selectedItems.indexOf(option.key) > -1}
        hint={
          <span data-testid="hint-element">
            {renderAccountName()}
            {renderAccountId()}
            {renderConversionId()}
            {renderSource()}
          </span>
        }
        className={noCheckbox ? 'hide-checkbox' : ''}
      />
    </div>
  )
}

export const CampaignDropdownRow = ({ option }) => {
  return (
    <div className="align-row account-dropdown">
      <AccountIcon accountType={option.type} />
      <div className="campaign-details">
        <div className="campaign-title">{option.label}</div>
        <div className="campaign-subtitle">
          {option.accountName} : {option.account}
        </div>
      </div>
    </div>
  )
}

AccountIcon.propTypes = {
  accountType: PropTypes.string,
}

AccountDropdownRow.propTypes = {
  option: PropTypes.object.isRequired,
  selectedItems: PropTypes.array,
  noCheckbox: PropTypes.bool,
}

CampaignDropdownRow.propTypes = {
  option: PropTypes.object.isRequired,
}

AccountInformation.propTypes = {
  accountType: PropTypes.string.isRequired,
  accountName: PropTypes.string.isRequired,
  className: PropTypes.string,
  externalAccountId: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
}
