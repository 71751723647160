import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { format } from 'date-fns'
import { useStore } from 'store'

import InputText from 'components/input'
import { FIELD_TYPES, validate } from 'components/validator'
import Table from 'components/table/beta'
import { Dropdown } from 'components/dropdown'
import Spacer from 'components/spacer'
import StickyFooter from 'components/sticky-footer'

import useLeaveConfirm from 'components/leave-confirm'

import { getUserCompanies } from 'components/utils/user'
import { getTooltipList } from 'components/utils/tooltip'

import { getCompanies } from 'modules/companies/actions'
import {
  updateUserFromMyAccount,
  getPopulatedTeams,
} from 'modules/users/actions'

import {
  utils,
  entityStatus,
  timezoneCountry,
} from '@decision-sciences/qontrol-common'

import Avatar from './user-avatar'

const { compareIgnoreCase } = utils.string
const { ENTITY_STATUS_LABEL } = entityStatus
const { COUNTRIES, TIMEZONES } = timezoneCountry

const MyAccountEdit = () => {
  const { dispatch, state } = useStore()
  const { userData } = state.session
  const { list: companies } = state.companies
  const [user, setUser] = useState({
    _id: userData._id,
    personalEmail: userData.personalEmail ? userData.personalEmail : '',
    phone: userData.phone ? userData.phone : '',
    jobTitle: userData.jobTitle ? userData.jobTitle : '',
    country: userData.country ? userData.country : null,
    timeZone: userData.timeZone ? userData.timeZone : null,
  })
  const [teamsPopulated, setTeamsPopulated] = useState(null)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const companiesToShow = getUserCompanies(userData, companies)
  const showCompanies = userData.clients.length > 0 && !userData.isSuperAdmin
  const [setDirty, LeaveConfirm] = useLeaveConfirm({})

  useEffect(() => {
    !companies &&
      userData.isSuperAdmin &&
      getCompanies(dispatch, { deleted: false })
  }, [companies])

  useEffect(() => {
    if (!teamsPopulated) {
      getPopulatedTeams()
        .then((res) => setTeamsPopulated(res))
        .catch((err) => console.error(err))
    }
  }, [teamsPopulated])

  /** On any field update, remove the error corresponding to it */
  const onFieldUpdate = (fieldName) => {
    setErrors({ ...errors, [fieldName]: null, general: null })
  }

  /** Edit a field */
  const editField = (fieldName, value) => {
    setDirty(true)
    const userFields = { ...user }
    userFields[fieldName] = value
    setUser(userFields)
    onFieldUpdate(fieldName)
  }

  /** Save handler */
  const onSave = (ev) => {
    ev.preventDefault()
    setErrors({})
    setLoading(true)

    /* Validate */
    const checkValidFields = {
      phone: user.phone,
      personalEmail: user.personalEmail,
    }
    const [isValid, errors] = validate(ERROR_MAP, checkValidFields)
    if (!isValid) {
      setLoading(false)
      return setErrors(errors)
    }
    /* Save the user */
    updateUserFromMyAccount(dispatch, user)
      .then((res) => {
        setDirty(false)
        if (res.error) {
          return setErrors({ ...errors, general: res.error })
        }
      })
      .catch((err) => {
        setErrors({ ...errors, general: err })
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="align-column">
      <LeaveConfirm />
      <Helmet>
        <title>My Account</title>
      </Helmet>
      <Avatar />
      <form onSubmit={onSave} className="form__section__body">
        <div className="align-row">
          <div className="my-account-user__fields">
            <InputText
              placeholder={'Personal Email'}
              value={user.personalEmail}
              onChange={editField.bind(null, 'personalEmail')}
              error={errors.personalEmail}
              disabled={loading}
            />
            <InputText
              placeholder={'Mobile Phone'}
              value={user.phone}
              onChange={() => {}}
              disabled={true}
            />
            <InputText
              placeholder={'Job Title'}
              value={user.jobTitle}
              onChange={editField.bind(null, 'jobTitle')}
              error={errors.jobTitle}
              disabled={loading}
            />
          </div>
          <div className="my-account-user__dropdowns">
            <Dropdown
              defaultOptionText="Country"
              defaultState={user.country ? user.country : ''}
              options={COUNTRIES.map((country) => ({
                value: country.label,
                label: country.label,
              }))}
              onChange={(country) => editField('country', country)}
              disabled={loading}
              className="my-account-user__dropdown"
              hasSearch={true}
            />
            <Dropdown
              defaultOptionText="Timezone"
              defaultState={user.timeZone ? user.timeZone : ''}
              options={TIMEZONES.map((tmz) => ({
                value: tmz.value,
                label: tmz.label,
              }))}
              onChange={(timeZone) => editField('timeZone', timeZone)}
              disabled={loading}
            />
          </div>
        </div>
        {errors && errors.general ? (
          <div className="error">{errors.general}</div>
        ) : null}
      </form>
      {showCompanies && (
        <>
          <Spacer />
          <div className="heading-secondary">Clients</div>
          {teamsPopulated &&
            _renderTable(
              userData,
              companiesToShow,
              teamsPopulated.filter((item) => !item.isGlobal)
            )}
          <Spacer />

          <div className="heading-secondary">Global Teams</div>
          {teamsPopulated && (
            <Table
              columns={[
                {
                  header: 'Team Name',
                  accessorFn: (row) => row.name,
                },
              ]}
              data={teamsPopulated.filter((item) => item.isGlobal)}
            />
          )}
        </>
      )}

      <StickyFooter
        buttons={[
          {
            value: 'Save',
            onClick: onSave,
            disabled: loading,
          },
        ]}
      />
    </div>
  )
}

const _renderTable = (userData, companies, teams = []) => {
  const companiesWithTeams = companies.map((comp) => ({ ...comp, teams: [] }))
  teams.forEach((team) => {
    team.companies &&
      team.companies.forEach((tc) => {
        const index = companiesWithTeams.findIndex(
          (comp) => comp._id === tc._id
        )
        if (index > -1) {
          companiesWithTeams[index].teams.push(team)
        }
      })
  })

  const columns = [
    {
      header: 'Client Name',
      accessorFn: (row) => row.name,
    },
    {
      header: 'Team',
      accessorFn: (row) => {
        if (row.teams.length === 0) {
          return 'No Teams'
        }
        if (row.teams.length === 1) {
          return row.teams[0].name
        }
        if (row.teams.length > 1) {
          return 'Multiple'
        }
      },
      tooltip: (row) => {
        if (!row || row.teams.length <= 1) {
          return null
        }
        const teams = row.teams.map((team) => team.name).sort(compareIgnoreCase)
        return getTooltipList('Teams', teams)
      },
    },
    {
      header: 'Date Added',
      id: 'updatedAt',
      accessorFn: (row) =>
        row.updatedAt && format(new Date(row.updatedAt), 'MM/dd/yyyy'),
    },
    {
      header: 'Status',
      id: 'status',
      cell: (cell) => {
        const { active } = cell.row.original
        return <span>{ENTITY_STATUS_LABEL[active]}</span>
      },
      accessorFn: (row) => (row.active ? 1 : 0),
    },
  ]

  return (
    <>
      <Table columns={columns} data={companiesWithTeams} />
    </>
  )
}

const ERROR_MAP = {
  personalEmail: [FIELD_TYPES.EMAIL],
  phone: [FIELD_TYPES.PHONE],
}

export default MyAccountEdit
