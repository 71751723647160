import Api from 'easy-fetch-api'
import { PERMISSION_GROUP_DEFAULT } from './constants'

export const ACTIONS = {
  GET_PERMISSION_GROUPS: 'permissionGroups.getPermissionGroups',
  GET_PERMISSION_GROUP: 'permissionGroups.getPermissionGroup',
  PUT_PERMISSION_GROUP: 'permissionGroups.putPermissionGroups',
  DELETE_PERMISSION_GROUP: 'permissionGroups.deletePermissionGroups',
}

/**
 * Fetches and stores the permission groups list into the reducer
 * @param {Function} dispatch Dispatch
 * @param {Object} query Query to find permission groups by
 * @returns {Promise}
 */
export const getPermissionGroups = async (dispatch, query = {}) => {
  return new Promise((resolve, reject) => {
    Api.get({ url: '/api/permission-groups', query }).then((result) => {
      if (!result || result.error) {
        const error =
          (result && result.error) || 'Error fetching permission group list'
        console.error(error)
        reject(error)
      } else {
        dispatch({
          type: ACTIONS.GET_PERMISSION_GROUPS,
          permissionGroups: result.list,
        })
        resolve(result.list)
      }
    })
  })
}

/**
 * Fetches and stores a permission group by it's id
 * @param {Function} dispatch Dispatch
 * @param {String} id ObjectId
 * @returns {Promise}
 */
export const getPermissionGroup = async (dispatch, id) => {
  if (id === 'new') {
    dispatch({
      type: ACTIONS.GET_PERMISSION_GROUP,
      permissionGroup: PERMISSION_GROUP_DEFAULT,
    })
    return Promise.resolve()
  }
  return new Promise((resolve, reject) =>
    Api.get({ url: `/api/permission-groups/${id}` }).then((result) => {
      if (!result || result.error) {
        const error =
          (result && result.error) || 'Error fetching permission group'
        console.error(error)
        reject(error)
      } else {
        dispatch({
          type: ACTIONS.GET_PERMISSION_GROUP,
          permissionGroup: result.data,
        })
        resolve(result.data)
      }
    })
  )
}

/**
 * Fetches and stores a permission group by it's id
 * @param {String} id ObjectId
 * @returns {Promise}
 */
export const getDuplicatedPermissionGroup = async (id) => {
  return new Promise((resolve, reject) =>
    Api.get({ url: `/api/permission-groups/${id}` }).then((result) => {
      if (!result || result.error) {
        const error =
          (result && result.error) ||
          'Error fetching permission group to duplicate'
        console.error(error)
        reject(error)
      } else {
        resolve(result.data)
      }
    })
  )
}

export const setEditedPermissionGroup = (dispatch, permissionGroup) =>
  dispatch({
    type: ACTIONS.GET_PERMISSION_GROUP,
    permissionGroup,
  })

/**
 * Updates a permission group
 * Also updates the reducer value matching the _id
 * @param {*} dispatch Dispatch
 * @param {Object} permissionGroup New Permission Group values
 * @returns {Promise}
 */
export const createUpdatePermissionGroup = async (
  dispatch,
  permissionGroup
) => {
  return new Promise((resolve, reject) => {
    const newPermissions = cleanUpPermissions(permissionGroup.permissions)
    const { _id } = permissionGroup
    const payload = {
      url: '/api/permission-groups',
      data: { ...permissionGroup, permissions: newPermissions },
    }
    const promise = _id ? Api.put(payload) : Api.post(payload)
    promise
      .then((result) => {
        if (!result || !result.success) {
          reject(result?.error || `Could not save ${permissionGroup.name}`)
        } else {
          dispatch({
            type: ACTIONS.PUT_PERMISSION_GROUP,
            permissionGroup: result.data,
          })
          resolve(result.data)
        }
      })
      .catch(reject)
  })
}

export const deletePermissionGroup = (dispatch, _id) => {
  return new Promise((resolve, reject) => {
    Api.delete({ url: `/api/permission-groups/${_id}` })
      .then((res) => {
        if (res.success) {
          dispatch({
            type: ACTIONS.DELETE_PERMISSION_GROUP,
            _id,
          })
          resolve()
        } else {
          reject('Could not delete Permission Group')
        }
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

/**
 * Removes false values and empty objects from permissions object
 * @param {Object} permissions Permissions object
 * @returns {Object} permissions object without false values or empty objects
 */
export const cleanUpPermissions = (permissions) => {
  const newPermissions = { ...permissions }
  Object.entries(newPermissions).forEach(([key, values]) => {
    const newValues = { ...values }
    Object.entries(values).forEach(([permissionKey, value]) => {
      if (!value) {
        // We don't need false permissions
        delete newValues[permissionKey]
      }
    })

    if (!Object.keys(newValues).length) {
      delete newPermissions[key]
    } else {
      newPermissions[key] = newValues
    }
  })
  return newPermissions
}
