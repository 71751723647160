import Api from 'easy-fetch-api'

export const runThresholdSimulation = async ({
  alertId,
  companyId,
  modifiedThresholds,
}) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: '/api/alert-thresholds/simulate-change',
      data: {
        alertId,
        companyId,
        modifiedThresholds,
        clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
      .then((result) => {
        if (result.error || !result.success) {
          const defaultMessage = `Error runing simulation with ${JSON.stringify(
            modifiedThresholds
          )}. Try again.`
          console.error(defaultMessage, result.error)
          return reject(result.error || defaultMessage)
        }
        resolve(result)
      })
      .catch((error) => {
        console.error(error)
      })
  })
}

export const initFetchingTriggerFrequency = async ({
  alertTriggerId,
  accountId,
  selectedEntity,
  elementType,
}) => {
  return new Promise((resolve, reject) => {
    Api.post({
      url: '/api/alert-thresholds/init-trigger-frequency',
      data: {
        alertTriggerId,
        accountId,
        selectedEntity,
        elementType,
        clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    }).then((result) => {
      if (result.error || !result.success) {
        const defaultMessage = `Error getting trigger frequency. Try again.`
        console.error(defaultMessage, result.error)
        return reject(result.error || defaultMessage)
      }
      resolve(result)
    })
  })
}

export const initDailyPerformanceReferenceTableData = (
  alertId,
  clientId,
  entityIds = null
) =>
  new Promise((resolve, reject) => {
    Api.post({
      url: '/api/alerts/init-daily-performance-reference-data',
      data: {
        alertId,
        clientId,
        clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        entityIds,
      },
    }).then((result) => {
      if (result.error || !result.success) {
        const defaultMessage = `Errorinitialising Daily Performance reference table data. Try opening the modal again.`
        console.error(defaultMessage, result.error)
        return reject(result.error || defaultMessage)
      }
      resolve(result.data)
    })
  })

export const initDailyPerformanceReferenceTableConversions = (
  alertId,
  clientId,
  entityIds = null
) =>
  new Promise((resolve, reject) => {
    Api.post({
      url: '/api/alerts/init-daily-performance-reference-data-conversions',
      data: {
        alertId,
        clientId,
        entityIds,
        clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    }).then((result) => {
      if (result.error || !result.success) {
        const defaultMessage = `Error initialising Daily Performance reference table conversions. Try opening the modal again.`
        console.error(defaultMessage, result.error)
        return reject(result.error || defaultMessage)
      }
      resolve(result.data)
    })
  })
