import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Api from 'easy-fetch-api'

import Button from 'components/button'
import Input from 'components/input'
import {
  FIELD_TYPES,
  ERRORS,
  validate,
  isValidName,
  isValidPhoneNumber,
  isValidPassword,
} from 'components/validator'
import AuthenticationContainer from 'components/authentication'

import { setTempUserData } from 'modules/session/actions'

import 'styles/authentication.scss'

const SignUpPage = () => {
  // Read user data from URL
  const navigate = useNavigate()
  const location = useLocation()

  const decodedUri = decodeURIComponent(location.search).split('&')
  const validUrl = decodedUri.length >= 4
  let email
  let inviteToken
  let firstName
  let lastName
  if (validUrl) {
    email = decodedUri[0].replace('?email=', '')
    inviteToken = decodedUri[1].replace('token=', '')
    firstName = decodedUri[2].replace('firstName=', '')
    lastName = decodedUri[3].replace('lastName=', '')
  }

  const [user, setUser] = useState({
    phone: '',
    firstName,
    lastName,
    password: '',
    passwordRepeat: '',
  })

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [submitError, setSubmitError] = useState(null)
  const disabledSignUp =
    !(
      user.phone &&
      user.firstName &&
      user.lastName &&
      user.password &&
      user.passwordRepeat
    ) || loading

  if (!validUrl) {
    navigate('/')
    return null
  }

  /** On any field update, remove the error corresponding to it */
  const onFieldUpdate = (fieldName) => {
    setErrors({ ...errors, [fieldName]: null })
  }

  /** Edit a field */
  const editField = (fieldName, value) => {
    const userFields = { ...user }
    userFields[fieldName] = value
    setUser(userFields)
    onFieldUpdate(fieldName)
  }

  const checkPhone = (phone) => {
    if (!isValidPhoneNumber(phone)) {
      setErrors({ phone: 'Invalid phone number.' })
    }
  }
  const checkName = (name, field) => {
    if (!isValidName(name)) {
      setErrors({ [field]: 'Invalid name format.' })
    }
  }

  const checkPasswords = (pass, field) => {
    if (!isValidPassword(pass)) {
      return setErrors({
        [field]: ERRORS.PASSWORD,
      })
    }
    if (field === 'passwordRepeat' && user.password !== pass) {
      setErrors({ passwordRepeat: 'The passwords do not match.' })
    }
  }

  const checkInviteToken = (email, inviteToken) => {
    return Api.post({
      url: `/api/users/check-invite-token`,
      data: { email, inviteToken },
    })
      .then((res) => {
        if (res.success) {
          return res.user
        }
        setSubmitError('There is no invite token for this email.')
        return false
      })
      .catch((err) => setSubmitError(err))
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault()

    if (user.password !== user.passwordRepeat) {
      setLoading(false)
      return setSubmitError('The passwords do not match.')
    }
    const [isValid, errors] = validate(ERROR_MAP, user)
    if (!isValid) {
      setLoading(false)
      if (errors.firstName || errors.lastName) {
        setSubmitError('Invalid name format.')
      } else {
        setSubmitError(ERRORS.PASSWORD)
      }
      return setErrors(errors)
    } else {
      setSubmitError(null)
    }
    const tokenExists = await checkInviteToken(email, inviteToken)
    if (tokenExists) {
      setTempUserData(user, tokenExists._id)
      navigate('/sign-up/terms-of-use')
    } else {
      setLoading(false)
      setSubmitError('There is no invite token for this email')
    }
  }

  return (
    <AuthenticationContainer
      title="Sign Up"
      onSubmit={handleSubmit}
      error={submitError || Object.values(errors)?.[0]}
    >
      <Input
        type="text"
        label="Email Address"
        disabled={true}
        value={email}
        onChange={() => {}}
        className={'input-wrapper--uppercase'}
      />

      <div className="authentication-container__two-inputs align-row">
        <Input
          type="text"
          disabled={loading}
          value={user.firstName}
          onChange={editField.bind(null, 'firstName')}
          blur={() => checkName(user.firstName, 'firstName')}
          placeholder="Enter First Name"
          label="First Name"
          error={!!errors.firstName}
          autoComplete="given-name"
          className={'input-wrapper--uppercase'}
        />
        <Input
          type="text"
          className="align-right input-wrapper--uppercase"
          disabled={loading}
          value={user.lastName}
          onChange={editField.bind(null, 'lastName')}
          blur={() => checkName(user.lastName, 'lastName')}
          placeholder={'Enter Last Name'}
          label="Last Name"
          error={!!errors.lastName}
          autoComplete="family-name"
        />
      </div>

      <Input
        disabled={loading}
        value={user.phone}
        type="phone"
        onChange={editField.bind(null, 'phone')}
        blur={() => checkPhone(user.phone)}
        placeholder="Enter Phone Number"
        label="Mobile Phone"
        error={!!errors.phone}
        autoComplete="tel"
        className={'input-wrapper--uppercase'}
      />

      <Input
        type="password"
        disabled={loading}
        value={user.password}
        onChange={editField.bind(null, 'password')}
        blur={() => checkPasswords(user.password, 'password')}
        placeholder={'Enter Password'}
        label="Password"
        error={!!errors.password}
        autoComplete="new-password"
        className={'input-wrapper--uppercase'}
      />
      <Input
        type="password"
        disabled={loading}
        value={user.passwordRepeat}
        onChange={editField.bind(null, 'passwordRepeat')}
        blur={() => checkPasswords(user.passwordRepeat, 'passwordRepeat')}
        placeholder={'Enter Password'}
        error={!!errors.passwordRepeat}
        label="Confirm Password"
        autoComplete="new-password"
        className={'input-wrapper--uppercase'}
      />

      <div className="align-row">
        <div className="authentication-container__resend-code">
          <Link to={'/login'}>Go Back to Login</Link>
        </div>
        <Button
          className="authentication-container__button button--big"
          value="Create Account"
          type="submit"
          disabled={disabledSignUp}
          onClick={handleSubmit}
        />
      </div>
    </AuthenticationContainer>
  )
}

const ERROR_MAP = {
  firstName: [FIELD_TYPES.NAME, FIELD_TYPES.REQUIRED],
  lastName: [FIELD_TYPES.NAME, FIELD_TYPES.REQUIRED],
  password: [FIELD_TYPES.PASSWORD, FIELD_TYPES.REQUIRED],
  passwordRepeat: [FIELD_TYPES.PASSWORD, FIELD_TYPES.REQUIRED],
  phone: [FIELD_TYPES.PHONE, FIELD_TYPES.REQUIRED],
}
SignUpPage.propTypes = {
  email: PropTypes.string,
}

export default SignUpPage
