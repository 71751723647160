import { v4 as uuidv4 } from 'uuid'

import {
  publisherFieldMap,
  globalReports,
  utils,
} from '@decision-sciences/qontrol-common'

import {
  CUSTOM_COLUMNS_ITEM_PLACEMENT,
  CUSTOM_COLUMNS_DND_ORIGINS,
} from 'modules/custom-columns/constants'

/* Components */
import Button from 'components/button/index'

/* Store & Actions */
import { closeModal, openModal } from 'components/modal/actions'
import { deleteGlobalReport } from 'modules/global-reports/actions'

const { capitalize } = utils.string
const { ALL_OPERATIONS } = publisherFieldMap.operations
const { SAVED_VIEW_TYPES } = globalReports
export const FILTER_URL_PARAMS = {
  SAVED_VIEW: 'saved_view',
  REPORT: 'report',
}

const SELECT_DIMENSIONS_WIDGET_NAMES = [
  'Select Dimension_Widget',
  'Add Second Dimension Pacing_Widget',
  'Add Tertiary Dimension Pacing_Widget',
  'Add Second Dimension_Widget',
  'Add Tertiary Dimension_Widget',
]
const BU_AGGREGATION = 'BUAggregation'

const EXTRA_DIMENSIONS = {
  OTHER: [
    'Device',
    'Account',
    'Campaign',
    'Ad group / Ad set',
    'Placement/Creative',
  ],
  BU_AGGREGATION: [
    'Device',
    'Business Unit',
    'Account',
    'Campaign',
    'Ad group / Ad set',
    'Placement/Creative',
  ],
}

export const REPORT_TEMPLATES = {
  CROSS_CHANNEL: 'Cross-Channel',
  CROSS_CHANNEL_CSV: 'Cross-Channel-CSV',
  FORECAST: 'Forecast',
  ISM: 'ISM',
}

export const TABLEAU_SHEETS = {
  DASHBOARD: 'Dashboard',
  DIMENSION: 'Dimension',
  PERIOD_COMPARISONS: 'Period Comparisons',
  DIMENSIONS_TRENDS: 'Dimension Trends',
  RUN_RATE: 'Run Rate',
  GOOGLE_ANALYTICS: 'Google Analytics',
  KPI_TRENDS: 'KPI Trends',
}

const {
  DASHBOARD,
  DIMENSION,
  PERIOD_COMPARISONS,
  DIMENSIONS_TRENDS,
  RUN_RATE,
  GOOGLE_ANALYTICS,
} = TABLEAU_SHEETS

export const VISUALS_MAPPING = {
  [DASHBOARD]: [
    {
      value: 'Select Counting Metric_Widget',
      label: 'Metric',
      isParam: true,
    },
    {
      value: 'Select Dimension_Widget',
      label: 'Dimension',
      isParam: true,
    },
  ],
  [DIMENSION]: [
    {
      value: 'Select Dimension_Widget',
      label: 'Dimension',
      isParam: true,
    },
    { value: 'Select Primary Metric_Widget', label: 'Metric', isParam: true },
    {
      value: 'Add Second Dimension_Widget',
      label: 'Second Dimension',
      isParam: true,
    },
    {
      value: 'Add Tertiary Dimension_Widget',
      label: 'Tertiary Dimension',
      isParam: true,
    },
  ],
  [PERIOD_COMPARISONS]: [
    { value: 'Select Primary Metric_Widget', label: 'Metric', isParam: true },
  ],
  [DIMENSIONS_TRENDS]: [
    { value: 'Select Dimension_Widget', label: 'Dimension', isParam: true },
    {
      value: 'Select Dimension_Widget',
      label: 'Dimension Inputs',
      isParam: false,
    },
  ],
  [RUN_RATE]: [
    {
      value: 'Select Dimension_Widget',
      label: 'Dimension',
      isParam: true,
    },
    {
      value: 'Add Second Dimension Pacing_Widget',
      label: 'Second Dimension',
      isParam: true,
    },
    {
      value: 'Add Tertiary Dimension Pacing_Widget',
      label: 'Tertiary Dimension',
      isParam: true,
    },
  ],
  [GOOGLE_ANALYTICS]: [
    {
      value: 'SELECT ANALYTICS DIMENSION_Widget',
      label: 'Select Analytics Dimension',
      isParam: true,
    },
  ],
}

export const WORKBOOK_PILL_NAMES = {
  DATE: 'Date',
  WIDGET: 'Widget',
  CONVERSION: 'Conversion',
  MAIN: 'Main',
  COLUMN: 'Column',
}

export const COLUMN_PARAMETER_SHEET_MAPPING = {
  [TABLEAU_SHEETS.KPI_TRENDS]: 'CC KPI Metric',
  [TABLEAU_SHEETS.DIMENSION]: 'CC DIM Metric',
  [TABLEAU_SHEETS.PERIOD_COMPARISONS]: 'CC PC Metric',
}

export const ROW_PARAMETER_SHEET_MAPPING = {
  [TABLEAU_SHEETS.DIMENSION]: 'CC DIM Dimension',
}

export const DIMENSION_ROW_PARAMATER_NAME = 'CC DIM Dimension Parameter_Column'

/**
 * Utility function to open a delete confirmation modal when deleting a Global Report.
 * @param {Boolean} hasDeleteAccess Flag to check if the current user has the right permissions
 * @param {String} id ObjectId of the Global Report to be deleted
 * @param {Function} dispatch Dispatch function
 * @returns {Promise | void}
 */
export const deleteConfirmation = (hasDeleteAccess, id, dispatch) => {
  if (!hasDeleteAccess) {
    return
  }
  return new Promise((resolve, reject) => {
    const deleteConfirmationModalUuid = openModal(dispatch, {
      heading: 'Delete Global Report Template',
      rightAlignButtons: true,
      children: (
        <div style={{ maxWidth: '520px' }}>
          <p>
            Please confirm that you want to delete this Global Report Template.
            This action cannot be undone.
          </p>
        </div>
      ),
      button: (
        <Button
          green
          value="Confirm"
          onClick={() => {
            deleteGlobalReport(dispatch, id)
              .catch((err) => reject(err))
              .finally(() => {
                resolve()
              })
            closeModal(dispatch, deleteConfirmationModalUuid)
          }}
        />
      ),
      buttonSecondary: (
        <Button
          secondaryGray
          value="Cancel"
          onClick={() => {
            resolve()
            closeModal(dispatch, deleteConfirmationModalUuid)
          }}
        />
      ),
    })
  })
}

const mergeConventions = (conventions) => {
  const {
    AD_GROUP: { dimensions: adGroupDimensions },
    CAMPAIGN: { dimensions: campaignDimensions },
  } = conventions
  return [...(campaignDimensions || []), ...(adGroupDimensions || [])].map(
    (dim, idx) => ({
      ...dim,
      dimensionOrder: idx + 1,
    })
  )
}

export const matchTaxonomyToDimension = (dimensions, conventions) => {
  const mergedConventions = mergeConventions(conventions)
  return dimensions.map((dimension) => {
    const { name } = dimension
    const dimensionPosition = name?.split('_')?.[1]
    let convention = null
    if (dimensionPosition) {
      convention = mergedConventions.find(
        ({ dimensionOrder }) => dimensionOrder.toString() === dimensionPosition
      )
    }
    const dim = {
      ...dimension,
      value: name,
      conventionMatched: !!convention?.dimensionName,
      label: convention?.dimensionName || name,
    }
    if (convention) {
      dim.position = convention.dimensionOrder
    }
    return dim
  })
}

const matchTaxonomyToNamingConvention = (conventions) => {
  const mergedConventions = mergeConventions(conventions)
  return mergedConventions.map((convention) => {
    return {
      isWorkbookParameter: true,
      label: convention.dimensionName,
      value: convention.dimensionOrder.toString(),
    }
  })
}

export const removePillNamesFromDimension = (dimensions) => {
  if (!dimensions || !dimensions?.length) {
    return dimensions
  }

  return dimensions.map((dimension) => {
    const { label } = dimension

    if (!label) {
      return dimension
    }

    const parts = label.split('_')
    const possiblePillName = parts[parts.length - 1].toLowerCase()

    const isPillName = Object.values(WORKBOOK_PILL_NAMES).some(
      (pillName) => pillName.toLowerCase() === possiblePillName
    )

    if (isPillName) {
      return {
        ...dimension,
        label: parts.slice(0, -1).join('_'), // Reconstruct the label without the pill name.
      }
    }

    return dimension
  })
}

export const getOptionsForWidget = (tableauWidget, conventions, reporting) => {
  const { appliedValues, name, isWorkbookParameter } = tableauWidget
  if (SELECT_DIMENSIONS_WIDGET_NAMES.includes(name) && isWorkbookParameter) {
    const options = matchTaxonomyToNamingConvention(conventions)
    const extraDimensionsStartIndex = 51
    const { OTHER, BU_AGGREGATION: BU_OPTIONS } = EXTRA_DIMENSIONS
    const extraDimensions = reporting === BU_AGGREGATION ? BU_OPTIONS : OTHER
    return options.concat(
      ...extraDimensions.map((dim, idx) => ({
        label: dim,
        isWorkbookParameter: true,
        value: (extraDimensionsStartIndex + idx).toString(),
      }))
    )
  }

  return appliedValues
    ? appliedValues.map(({ value }) => ({
        value: value,
        label: value,
        condition: {
          operation: ALL_OPERATIONS.EQ,
          dimensions: [value],
        },
      }))
    : []
}

export const getVisualsWithConfig = (
  workbook,
  sheet,
  dimensions,
  conventions
) => {
  const templateReporting = workbook.split('_')
  const template = templateReporting[0]
  const reporting = templateReporting[2]
  if (
    (template &&
      template === REPORT_TEMPLATES.ISM &&
      sheet === GOOGLE_ANALYTICS) ||
    !VISUALS_MAPPING[sheet]
  ) {
    return []
  }

  const visualsWithConfig = VISUALS_MAPPING[sheet].map((widget) => {
    const { value, isParam } = widget
    const tableauWidget = dimensions.find(
      ({ name, isWorkbookParameter }) =>
        name === value && !!isWorkbookParameter === isParam
    )
    if (tableauWidget) {
      const options = getOptionsForWidget(tableauWidget, conventions, reporting)
      return {
        ...widget,
        defaultValue: tableauWidget?.defaultValue,
        options: options,
      }
    }
    return { ...widget, options: [] }
  })
  return visualsWithConfig
}

export const getDefaultEntity = (defaultEntities) => {
  const entityTypes = [
    SAVED_VIEW_TYPES.VIEW,
    SAVED_VIEW_TYPES.FILTERS,
    SAVED_VIEW_TYPES.VISUALS,
    SAVED_VIEW_TYPES.CONVERSIONS,
  ]

  for (const entityType of entityTypes) {
    const defaultEntity = defaultEntities.find(
      ({ type }) => type === entityType
    )
    if (defaultEntity) {
      return defaultEntity
    }
  }

  return null
}

export const getDimensionsSubsections = (dimensions) => {
  const sections = {
    taxonomy: {
      noCheckbox: true,
      disabled: true,
      subsections: [],
      label: 'Taxonomy',
      value: 'taxonomy',
    },
    main: {
      noCheckbox: true,
      disabled: true,
      subsections: [],
      label: 'Main',
      value: 'main',
    },
    other: {
      noCheckbox: true,
      disabled: true,
      subsections: [],
      label: 'Other',
      value: 'other',
    },
  }

  for (const dim of dimensions) {
    const element = {
      label: dim.label,
      value: dim.name,
      isWorkbookParameter: dim.isWorkbookParameter,
      isSingleSelection: dim.isSingleSelection,
      defaultValue: dim.defaultValue,
      position: dim.position,
    }
    const isConventionsMatched = dim.conventionMatched
    if (isConventionsMatched) {
      sections.taxonomy.subsections.push(element)
    } else {
      const parts = dim.name.split('_')
      const hasMainPillName =
        parts[parts.length - 1] === WORKBOOK_PILL_NAMES.MAIN
      if (hasMainPillName) {
        sections.main.subsections.push(element)
      } else {
        sections.other.subsections.push(element)
      }
    }
  }
  if (sections.taxonomy.subsections.length) {
    sections.taxonomy.subsections.sort(
      ({ position: p1 }, { position: p2 }) => p1 - p2
    )
  }
  // Remove sections without subsections aka dimensions that match it
  return Object.values(sections).filter(({ subsections }) => !!subsections)
}

export const getFriendlyNameKey = (metric, hasNonParam) =>
  hasNonParam
    ? `${metric.isParam ? '[PARAMETER]' : '[FILTER]'} ${metric.value}`
    : metric.value

export const getColumnParameters = (sheet, dimensions) => {
  const metrics = dimensions?.filter(
    (el) =>
      el.isWorkbookParameter &&
      el.name.includes(`_${WORKBOOK_PILL_NAMES.COLUMN}`) &&
      el.name.indexOf(COLUMN_PARAMETER_SHEET_MAPPING?.[sheet]) === 0
  )
  return metrics || []
}

export const getRowParameters = (sheet, dimensions) => {
  const metrics = dimensions?.filter(
    (el) =>
      el.isWorkbookParameter &&
      el.name.includes(`_${WORKBOOK_PILL_NAMES.COLUMN}`) &&
      el.name.indexOf(ROW_PARAMETER_SHEET_MAPPING?.[sheet]) === 0
  )
  return metrics || []
}

export const getAvailableItems = ({
  selectedSheet,
  dimensions,
  dateGranularities,
}) => {
  let availableItems = [
    {
      key: 'dimensions',
      name: 'Dimensions',
      dimensions: [],
    },
    {
      key: 'metrics',
      name: 'Metrics',
      dimensions: [],
    },
  ]

  if (selectedSheet === TABLEAU_SHEETS.KPI_TRENDS) {
    // Set date granularities
    for (const dateGranularity of dateGranularities) {
      availableItems[0].dimensions.push({
        dimension: {
          name: capitalize(dateGranularity.label.toLowerCase()),
        },
        accept: CUSTOM_COLUMNS_DND_ORIGINS.ROW,
        placement: CUSTOM_COLUMNS_ITEM_PLACEMENT.ROW,
        _id: dateGranularity.value,
        isExternal: true,
        originalItem: { ...dateGranularity },
      })
    }

    const columnParameters = getColumnParameters(selectedSheet, dimensions)

    if (columnParameters?.length) {
      for (const metric of columnParameters[0].appliedValues) {
        availableItems[1].dimensions.push({
          dimension: {
            name: metric.formattedValue,
          },
          originalItem: { ...metric },
          accept: CUSTOM_COLUMNS_DND_ORIGINS.COLUMN,
          placement: CUSTOM_COLUMNS_ITEM_PLACEMENT.COLUMN,
          key: metric.value,
          _id: uuidv4(),
        })
      }
    }
  }

  if (selectedSheet === TABLEAU_SHEETS.DIMENSION) {
    const rowParameters = getRowParameters(selectedSheet, dimensions)

    if (rowParameters?.length) {
      for (const metric of rowParameters[0].appliedValues) {
        availableItems[0].dimensions.push({
          dimension: {
            name: metric.formattedValue,
          },
          originalItem: { ...metric },
          accept: CUSTOM_COLUMNS_DND_ORIGINS.ROW,
          placement: CUSTOM_COLUMNS_ITEM_PLACEMENT.ROW,
          key: metric.value,
          _id: uuidv4(),
        })
      }
    }

    const columnParameters = getColumnParameters(selectedSheet, dimensions)

    if (columnParameters?.length) {
      for (const metric of columnParameters[0].appliedValues) {
        availableItems[1].dimensions.push({
          dimension: {
            name: metric.formattedValue,
          },
          originalItem: { ...metric },
          accept: CUSTOM_COLUMNS_DND_ORIGINS.COLUMN,
          placement: CUSTOM_COLUMNS_ITEM_PLACEMENT.COLUMN,
          key: metric.value,
          _id: uuidv4(),
        })
      }
    }
  }

  if (selectedSheet === TABLEAU_SHEETS.PERIOD_COMPARISONS) {
    const columnParameters = getColumnParameters(selectedSheet, dimensions)
    if (columnParameters?.length) {
      for (const metric of columnParameters[0].appliedValues) {
        availableItems[1].dimensions.push({
          dimension: {
            name: metric.formattedValue,
          },
          originalItem: { ...metric },
          accept: CUSTOM_COLUMNS_DND_ORIGINS.COLUMN,
          placement: CUSTOM_COLUMNS_ITEM_PLACEMENT.COLUMN,
          key: metric.value,
          _id: uuidv4(),
        })
      }
    }
    availableItems = [availableItems[1]]
  }

  return availableItems
}

export const extractDimensionNamePattern = (dimensionParameterNames) => {
  if (!dimensionParameterNames || dimensionParameterNames.length === 0) {
    return null
  }

  // Identify the last numeric segment in the string as the variable part.
  const patternRegex = /^(.*?)(\d+)(\D*)$/

  // Store the static parts of the pattern.
  let prefix = ''
  let suffix = ''
  let isConsistent = true

  // Analyze the first string to establish a base pattern.
  const firstMatch = dimensionParameterNames[0].match(patternRegex)
  if (firstMatch) {
    prefix = firstMatch[1]
    suffix = firstMatch[3]
  }

  // Verify all other strings conform to the same static parts.
  dimensionParameterNames.forEach((string) => {
    const match = string.match(patternRegex)
    if (!match || match[1] !== prefix || match[3] !== suffix) {
      isConsistent = false
    }
  })

  if (!isConsistent) {
    console.error(
      'Column dimension parameters do not follow a consistent pattern.'
    )
    return ''
  }

  // Construct and return the pattern using placeholders for the variable part.
  return `${prefix}%{position}%${suffix}`
}

export const createDimensionNameString = (pattern, position) => {
  return pattern?.replace('%{position}%', position)
}

export const getColumnDimensionNamePattern = ({
  selectedSheet,
  dimensions,
}) => {
  const columnDimensions = dimensions?.filter(
    (el) =>
      el.isWorkbookParameter &&
      el.name.indexOf(COLUMN_PARAMETER_SHEET_MAPPING[selectedSheet]) === 0
  )
  if (!columnDimensions?.length) {
    return ''
  }

  return extractDimensionNamePattern(columnDimensions.map((el) => el.name))
}

export const getRowDimensionNamePattern = () => {
  return DIMENSION_ROW_PARAMATER_NAME
}

export const getDefaultCustomColumnsFromReport = ({
  selectedSheet,
  dimensions,
}) => {
  const columnDimensions = dimensions?.filter(
    (el) =>
      el.isWorkbookParameter &&
      el.name.indexOf(COLUMN_PARAMETER_SHEET_MAPPING[selectedSheet]) === 0
  )

  // Get the number of default columns set on the report
  // The number of default columns should be equal to the number of applied values
  // for any of the 'CC {SHEET} Metric X Parameter_Column' parameter excluding the 'None' from the applied values array
  const defaultColumnsCount =
    columnDimensions[0]?.appliedValues?.length - 1 || 0

  if (!columnDimensions?.length) {
    return []
  }

  const pattern = getColumnDimensionNamePattern({ selectedSheet, dimensions })
  if (!pattern) {
    return []
  }

  // Get the default values for each column
  const results = []
  for (let index = 0; index < defaultColumnsCount; index++) {
    const columnParameterName = createDimensionNameString(pattern, index + 1)
    const defaultColumn = columnDimensions?.find(
      (el) => el.name === columnParameterName
    )
    if (defaultColumn?.defaultValue) {
      const defaultMetric = defaultColumn.appliedValues.find(
        (el) => el.value === defaultColumn.defaultValue
      )
      if (defaultMetric) {
        results.push({
          dimension: {
            name: defaultMetric.value,
          },
          originalItem: { ...defaultMetric },
          accept: CUSTOM_COLUMNS_DND_ORIGINS.COLUMN,
          placement: CUSTOM_COLUMNS_ITEM_PLACEMENT.COLUMN,
          key: defaultMetric.value,
          _id: uuidv4(),
        })
      }
    }
  }

  return results
}

export const getDefaultCustomRowsFromReport = ({
  selectedSheet,
  dimensions,
}) => {
  const rowDimensions = dimensions?.filter(
    (el) =>
      el.isWorkbookParameter &&
      el.name.indexOf(ROW_PARAMETER_SHEET_MAPPING[selectedSheet]) === 0
  )

  if (!rowDimensions?.length) {
    return []
  }

  const defaultMetric = rowDimensions[0].appliedValues.find(
    (el) => el.value === rowDimensions[0].defaultValue
  )

  return [
    {
      dimension: {
        name: defaultMetric.value,
      },
      originalItem: { ...defaultMetric },
      accept: CUSTOM_COLUMNS_DND_ORIGINS.ROW,
      placement: CUSTOM_COLUMNS_ITEM_PLACEMENT.ROW,
      key: defaultMetric.value,
      _id: uuidv4(),
    },
  ]
}

export const getMappedVisuals = (visuals, friendlyNames) => {
  const hasNonParam = visuals.some(({ isParam }) => !isParam)

  return visuals
    .filter(
      (visual) =>
        friendlyNames[getFriendlyNameKey(visual, hasNonParam)]?.display !==
        false
    ) // If display is false (not falsy), it means it was manually disabled
    .map((visual) => {
      const friendlyNameConfig =
        friendlyNames[getFriendlyNameKey(visual, hasNonParam)]
      const friendlyLabel = friendlyNameConfig?.label
      const friendlyOrder = friendlyNameConfig?.order

      return {
        ...visual,
        label:
          (typeof friendlyLabel !== 'undefined'
            ? friendlyLabel
            : visual.label) || visual.value,
        order:
          typeof friendlyOrder !== 'undefined' ? friendlyOrder : visual.order,
      }
    })
    .sort((a, b) => (a.order < b.order ? -1 : 1))
}
