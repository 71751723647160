import React from 'react'
import PropTypes from 'prop-types'

import Modal from 'components/modal'
import Button from 'components/button'
import WarningIcon from 'components/warning-icon'

const ConfirmationModal = ({
  showModal,
  onConfirmClicked,
  onCancelClicked,
  message,
  heading,
  displayIcon = true,
}) => {
  return (
    <Modal
      opened={showModal}
      rightAlignButtons
      heading={heading}
      icon={displayIcon ? <WarningIcon /> : null}
      button={
        <Button
          data-testid="modal-primary-button"
          green
          value="Confirm"
          onClick={onConfirmClicked}
        />
      }
      contentSeparator
      buttonSecondary={
        <Button
          data-testid="modal-secondary-button"
          value="Cancel"
          onClick={onCancelClicked}
          secondaryGray
        />
      }
    >
      <div>{message}</div>
    </Modal>
  )
}
export default ConfirmationModal

ConfirmationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onConfirmClicked: PropTypes.func.isRequired,
  onCancelClicked: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  heading: PropTypes.string.isRequired,
  displayIcon: PropTypes.bool,
}
