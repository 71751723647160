import Api from 'easy-fetch-api'
import { utils } from '@decision-sciences/qontrol-common'

const { keyBy } = utils.array

export const ACTIONS = {
  GET_CALCULATED_METRICS: 'calculatedMetrics.getCalculatedMetrics',
  GET_CALCULATED_METRICS_WITH_DETAILS:
    'calculatedMetrics.getCalculatedMetricsWithDetails',
  GET_VARIABLE_METRICS: 'calculatedMetrics.getVariableMetrics',
}

/** Get a list of all calculated metrics */
export const getCalculatedMetricsList = async (query = {}) => {
  const result = await Api.get({ url: '/api/calculated-metrics', query })
  if (!result || result.error) {
    return console.error('Error fetching calculated metrics list ')
  }
  return result
}
/**
 * Get list of Calculated Metrics.
 * Store them separately if query.all is set or not (query.all is set for list view with more data attached)
 * @param {Function} dispatch
 * @param {Object} [query] - optional query object
 * @return {Promise}
 */
export const getCalculatedMetrics = (dispatch, query = {}) =>
  Api.get({ url: '/api/calculated-metrics', query }).then((result) => {
    if (!result || result.error) {
      console.error(
        (result && result.error) || 'Error fetching calculated metrics list'
      )
    } else {
      const type = query.all
        ? ACTIONS.GET_CALCULATED_METRICS_WITH_DETAILS
        : ACTIONS.GET_CALCULATED_METRICS
      // Only Store Calculated Metrics in the Store if query.all is set, which sets all required data for the list page
      dispatch({
        type,
        calculatedMetrics: keyBy(result.data, 'key'),
      })
    }
  })

export const createUpdateCalculatedMetric = async (
  calculatedMetric,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    const { _id } = calculatedMetric
    const payload = {
      url: '/api/calculated-metrics',
      data: calculatedMetric,
    }
    const promise = _id ? Api.put(payload) : Api.post(payload)
    promise
      .then((result) => {
        if (!result || !result.success) {
          reject(result.error)
        } else {
          getCalculatedMetrics(dispatch, { all: true }).then(resolve)
        }
      })
      .catch(reject)
  })
}

export const deleteCalculatedMetric = (dispatch, id) => {
  return new Promise((resolve, reject) => {
    Api.delete({ url: `/api/calculated-metrics/${id}` })
      .then((result) => {
        if (!result || !result.success) {
          console.error(result.error)
          reject(result.error)
        } else {
          getCalculatedMetrics(dispatch, { all: true }).then(resolve)
        }
      })
      .catch(reject)
  })
}

export const getVariableMetrics = (dispatch) =>
  Api.get({ url: '/api/calculated-metrics/variables' }).then((result) => {
    if (!result || !result.success) {
      console.error('Error fetching variable metrics list')
    } else {
      if (dispatch) {
        dispatch({
          type: ACTIONS.GET_VARIABLE_METRICS,
          variables: result.list,
        })
      } else {
        return result.list
      }
    }
  })

export const createUpdateVariable = (dispatch, variable) => {
  return new Promise((resolve, reject) => {
    let promise
    const payload = {
      url: '/api/calculated-metrics/variables',
      data: variable,
    }
    if (variable._id) {
      promise = Api.put(payload)
    } else {
      promise = Api.post(payload)
    }
    promise
      .then((res) => {
        if (res.error) {
          return reject(res.error)
        }
        getVariableMetrics(dispatch).then(() => resolve(res))
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

export const deleteVariableMetric = (dispatch, _id) => {
  return new Promise((resolve, reject) => {
    Api.delete({ url: `/api/calculated-metrics/variables/${_id}` })
      .then(() => {
        getVariableMetrics(dispatch).then(resolve)
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}
