import Api from 'easy-fetch-api'
import {
  showErrorMessage,
  showSuccessMessage,
} from 'modules/notifications/actions'
import { expandLeftMenuDrawer } from 'components/left-menu/actions'
import { ANALYTICS_KEYS } from 'components/left-menu/constants'

export const ACTIONS = {
  SET_GLOBAL_REPORTS: 'globalReport.setList',
  SET_VIEW_GLOBAL_REPORTS: 'globalReport.setViewList',
  SET_EDITED_GOBAL_REPORT: 'globalReport.setById',
  DELETE_GLOBAL_REPORT: 'globalReport.deleteOne',
  VIEW_LIST_ITEM_CHANGED: 'globalReport.viewListItemChange',
}

const GLOBAL_REPORTS_API = '/api/global-reports'
const CUSTOM_SAVED_FILTERS = '/api/reporting-filters'
const SNAPSHOT_API = '/api/report-snapshot'
const ANALYSIS_API = '/api/report-analysis'
const CUSTOM_REPORTINGS = '/api/favorite-reportings'

/**
 * Fetches the list of global reports
 * @param {Function} dispatch Dispatch function
 * @param {Object} filters
 * @returns {Object} API response
 */
export const filterGlobalReports = async (dispatch, filters) => {
  const result = await Api.post({
    url: `${GLOBAL_REPORTS_API}/filter`,
    data: filters,
  })

  if (!result || !result.success) {
    errorHandler(result, 'Error filtering global reports', dispatch)
    return
  }

  dispatch({ type: ACTIONS.SET_GLOBAL_REPORTS, list: result.data })
  return result
}

/**
 * Fetches a Global Report by its ID
 * @param {Function} dispatch Dispatch function
 * @param {String} id Global Report id
 * @param {Boolean} [forDuplication]
 * @returns {Object}
 */
export const getGlobalReport = async (dispatch, id, forDuplication = false) => {
  const result = await Api.get({
    url: `${GLOBAL_REPORTS_API}/${id}`,
    query: { duplicate: forDuplication },
  })

  if (!result || !result.success) {
    errorHandler(result, 'Error getting the global report', dispatch)
    throw result?.error || result
  }

  const globalReport = result.data
  if (forDuplication) {
    delete globalReport._id
    delete globalReport.__v
    delete globalReport.createdAt
    delete globalReport.updatedAt
  }

  dispatch({
    type: ACTIONS.SET_EDITED_GOBAL_REPORT,
    currentGlobalReport: globalReport,
  })
  return result
}

/**
 * Gets the global and custom reports view list based on the current client and user
 * @param {Function} dispatch Dispatch function
 * @param {Object} user Current user
 * @param {String} companyId Current selected client ObjectId
 * @returns {Object}
 */
export const getGlobalAndCustomReports = async ({ dispatch, companyId }) => {
  const allReportings = await Api.post({
    url: `${CUSTOM_REPORTINGS}/all-user-reports`,
    data: {
      companyId,
    },
  })

  if (!allReportings || !allReportings.success) {
    console.error(
      allReportings?.error ||
        'Error getting global and custom reports view list'
    )
    return
  }

  if (dispatch) {
    const favList =
      allReportings.data?.filter(({ favorite }) => !!favorite) || []

    dispatch({
      type: ACTIONS.SET_VIEW_GLOBAL_REPORTS,
      viewList: allReportings.data,
      favList,
    })
  }

  return allReportings
}

/**
 * Fetches necessary data to embed the Tableau report in the page
 * @param {Function} dispatch Dispatch function
 * @param {String} id ObjectId of the global report to view
 * @param {Object} currentClient Current selected client
 * @returns {Object}
 */
export const viewGlobalReport = async (
  dispatch,
  id,
  currentClient,
  withToken = true
) => {
  const result = await Api.post({
    url: `${GLOBAL_REPORTS_API}/view/${id}`,
    data: {
      client: currentClient?._id,
      clientId: currentClient?.clientId,
      withToken: withToken,
    },
  })

  if (!result || !result.success) {
    dispatch && errorHandler(result, 'Error getting global report', dispatch)
    throw result
  }

  dispatch &&
    dispatch({
      type: ACTIONS.VIEW_LIST_ITEM_CHANGED,
      id,
      changes: { lastViewed: new Date() },
    })

  return result
}

/**
 * Updates last viewed property for custom reports
 * @param {Function} dispatch Dispatch function
 * @param {String} reportId ObjectId of the report
 * @param {Object} company Current selected client
 * @returns {Object}
 */
export const updateLastViewed = async (dispatch, company, reportId) => {
  const result = await Api.post({
    url: `${CUSTOM_REPORTINGS}/update-last-viewed`,
    data: {
      company,
      reportId,
      isCustom: true,
    },
  })

  if (!result || !result.success) {
    dispatch && errorHandler(result, 'Error updating last viewed', dispatch)
    throw result
  }

  dispatch &&
    dispatch({
      type: ACTIONS.VIEW_LIST_ITEM_CHANGED,
      id: reportId,
      changes: { lastViewed: new Date() },
    })
}
/**
 * Creates or updates a Global Report based on the {@link isCreate} flag.
 * @param {Function} dispatch Dispatch function
 * @param {Object} globalReport Global Report payload
 * @param {Boolean} isCreate Flag to signal if it's create mode
 * @returns {Object}
 */
export const upsertGlobalReport = async (dispatch, globalReport, isCreate) => {
  const result = isCreate
    ? await Api.post({ url: `${GLOBAL_REPORTS_API}`, data: globalReport })
    : await Api.put({
        url: `${GLOBAL_REPORTS_API}/${globalReport._id}`,
        data: globalReport,
      })
  if (!result || !result.success) {
    errorHandler(
      result,
      `Error ${isCreate ? 'saving' : 'updating'} global report`,
      dispatch
    )
    throw result
  }
  showSuccessMessage(
    `Global report ${isCreate ? 'saved' : 'updated'}`,
    dispatch
  )
  return result
}

/**
 * Deletes a global report
 * @param {Function} dispatch Dispatch function
 * @param {String} id Global report ID
 * @returns {Object}
 */
export const deleteGlobalReport = async (dispatch, id) => {
  const result = await Api.delete({ url: `${GLOBAL_REPORTS_API}/${id}` })
  if (!result || !result.success) {
    errorHandler(result, `Error deleting global report`, dispatch)
    throw result
  }

  showSuccessMessage(`Global report deleted`, dispatch)
  dispatch({ type: ACTIONS.DELETE_GLOBAL_REPORT, id })

  return result
}

/**
 * Fetches the list of available Tableau templates
 * @returns {Array}
 */
export const getTableauTemplates = async () => {
  const result = await Api.get({
    url: `${GLOBAL_REPORTS_API}/tableau-templates`,
  })
  if (!result || !result.success) {
    console.error((result && result.error) || `Error deleting global report`)
    throw result
  }
  return result.data
}

/**
 * Toggles the favorite status of a Global Report
 * @param {Function} dispatch Dispatch function
 * @param {String} id ObjectId of the Global Report
 * @param {Boolean} currentFavorite Flag determining the next favorite state
 * @returns {Boolean}
 */
export const toggleFavorite = async (dispatch, reportInfo) => {
  const result = await Api.post({
    url: `${CUSTOM_REPORTINGS}/favorite`,
    data: { ...reportInfo },
  })

  if (!result || !result.success) {
    errorHandler(result, 'Error when changing favorite status', dispatch)
    return false
  }

  const {
    data: { favouriteFor },
  } = result
  const { reportId, userId } = reportInfo

  dispatch({
    type: ACTIONS.VIEW_LIST_ITEM_CHANGED,
    id: reportId,
    changes: { favorite: favouriteFor.includes(userId) },
  })

  expandLeftMenuDrawer(dispatch, ANALYTICS_KEYS.TABLEAU_DASHBOARD)

  return true
}

export const getAllGlobalReports = () =>
  Api.get({ url: `${GLOBAL_REPORTS_API}/all` })

export const getWorkbooks = () =>
  Api.get({ url: `${GLOBAL_REPORTS_API}/workbooks` })

export const getAllClientWorkbooks = () =>
  Api.get({ url: `${GLOBAL_REPORTS_API}/all-client-workbooks` })

export const cacheSheetsForReport = async (workbookName, sheets) =>
  await Api.post({
    url: `${GLOBAL_REPORTS_API}/cache-tableau-sheets`,
    data: { workbookName, sheets },
  })

export const cacheParametersForReport = async (
  workbookName,
  workbookParameters
) =>
  await Api.post({
    url: `${GLOBAL_REPORTS_API}/cache-tableau-parameters`,
    data: { workbookName, workbookParameters },
  })

export const cacheFiltersForReport = async (workbookName, filtersObject) =>
  await Api.post({
    url: `${GLOBAL_REPORTS_API}/cache-tableau-filters`,
    data: { workbookName, filtersObject },
  })

export const getFiltersForReport = (workbookName, sheet, clientId) =>
  Api.get({
    url: `${GLOBAL_REPORTS_API}/get-filters/${workbookName}/${sheet}/${clientId}`,
  })

export const getSheetsForReport = (workbookName) =>
  Api.get({ url: `${GLOBAL_REPORTS_API}/get-sheets/${workbookName}` })

export const saveNewCustomEntity = async (dispatch, entity) => {
  const result = await Api.post({
    url: `${CUSTOM_SAVED_FILTERS}/new`,
    data: { entity: entity },
  })

  if (!result || !result.success || !result.data) {
    errorHandler(result, 'Error saving entity', dispatch)
    return
  }
  return result.data
}

export const updateCustomEntity = async (dispatch, entity) => {
  const result = await Api.post({
    url: `${CUSTOM_SAVED_FILTERS}/update-entity`,
    data: { entity: entity },
  })
  if (!result || !result.success || !result.data) {
    errorHandler(result, 'Error updating entity', dispatch)
    return
  }
  return result.data
}

export const setDefaultFilter = ({ entityId, entityType, userId }) =>
  Api.put({
    url: `${CUSTOM_SAVED_FILTERS}/set-default`,
    data: { entityId: entityId, entityType: entityType, userId: userId },
  })

export const removeDefaultFilter = ({ entityId, userId }) =>
  Api.put({
    url: `${CUSTOM_SAVED_FILTERS}/remove-default`,
    data: { entityId: entityId, userId: userId },
  })

export const getEntitiesForUser = ({ user, currentClient, getAll, workbook }) =>
  Api.post({
    url: `${CUSTOM_SAVED_FILTERS}/user-entities`,
    data: {
      user: user,
      currentClient: currentClient,
      workbook: workbook,
      getAllForUser: getAll,
    },
  })

/**
 * Error handler for these actions to log the error and show feedback to the user
 * @param {Object} errorResponse Error received from server
 * @param {String} defaultErrorMessage Fallback error message in case none was sent from the API
 * @param {Function} dispatch Dispatch function
 */
const errorHandler = (errorResponse, defaultErrorMessage, dispatch) => {
  const errorMessage = errorResponse?.error || defaultErrorMessage
  console.error(errorMessage)
  showErrorMessage(errorMessage, dispatch)
}

export const updateUserDateFilters = (userId, reportsDate, dateGranularity) => {
  return Api.put({
    url: `${CUSTOM_SAVED_FILTERS}/update-user-date-filters`,
    data: { userId, reportsDate, dateGranularity },
  })
}

export const deleteAWSFiles = async (locations) =>
  await Api.post({
    url: `${SNAPSHOT_API}/delete-aws-files`,
    data: { locations },
  })

export const updateSnapshot = async (snapshot) =>
  await Api.post({
    url: `${SNAPSHOT_API}/update-snapshot`,
    data: { snapshot },
  })

export const saveSnapshot = async (snapshot) =>
  await Api.post({
    url: `${SNAPSHOT_API}/new-snapshot`,
    data: { snapshot },
  })

export const getAnalysis = async (user, companyId) => {
  const { teams, _id, isSuperAdmin } = user
  const userInfo = { teams, _id, isSuperAdmin }
  return await Api.post({
    url: `${ANALYSIS_API}/all-for-user-company`,
    data: { userInfo, companyId },
  })
}

export const saveAnalysis = async (analysis, userId) =>
  await Api.post({
    url: `${ANALYSIS_API}/new-analysis`,
    data: { analysis, userId },
  })

export const editAnalysis = async (analysis, userId) =>
  await Api.post({
    url: `${ANALYSIS_API}/edit-analysis`,
    data: { analysis, userId },
  })

export const saveEditAnalysis = async (analysis, userId) => {
  const existingAnalysis = analysis._id
  if (existingAnalysis) {
    return await editAnalysis(analysis, userId)
  }
  return await saveAnalysis(analysis, userId)
}

export const duplicateAnalysisName = async (name) =>
  await Api.post({
    url: `${ANALYSIS_API}/duplicate-name`,
    data: { name },
  })

export const duplicateSnapshotName = async (
  snapshotName,
  analysisId,
  selectedSection
) =>
  await Api.post({
    url: `${ANALYSIS_API}/duplicate-snapshot`,
    data: {
      snapshotName: snapshotName,
      analysisId: analysisId,
      selectedSection: selectedSection,
    },
  })
