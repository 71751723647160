export const TABLEAU_REPORTING_DEFAULT = {
  vertical: null,
  reporting: null,
}
export const COMPANY_DEFAULT = {
  name: '',
  key: 'new',
  address: '',
  contact: {
    name: '',
    email: '',
    phone: '',
    alternatePhone: '',
    primaryContactPhone: '',
    position: '',
  },
  slack: '',
  webSite: '',
  country: '',
  clientId: '',
  state: '',
  timeZone: '',
  active: null,
  backupApprover: null,
  defaultApprover: null,
  accountLead: null,
  primaryKPIs: [],
  secondaryKPIs: [],
  reportingKPIs: [],
  accounts: [],
  businessUnits: [],
  namingConventions: [],
  facebookKPIAttribution: {
    dynamicMetrics: {},
    primaryKPIs: {},
    secondaryKPIs: {},
  },
  tableauConfig: TABLEAU_REPORTING_DEFAULT,
  users: [],
  reportings: [],
  calculatedMetrics: [],
}
export const KPI_ERROR_MESSAGE = 'Attribution window must be selected.'

export const CREATE_BU_OPTIONS = {
  START_FROM_SCRATCH: 'START_FROM_SRATCH',
  COPY_CLIENT: 'COPY_CLIENT',
  DUPLICATE_BU: 'DUPLICATE_BU',
  SPLIT_BU: 'SPLIT_BU',
}

export const COMPANY_SECTIONS = {
  ALERTS: 'ALERTS',
  ALERT_THRESHOLDS: 'ALERT_THRESHOLDS',
  NAMING_CONVENTIONS: 'NAMING_CONVENTIONS',
}

export const COPY_SETTINGS_STATUS = {
  COPIED: 'COPIED',
  REVERTED: 'REVERTED',
  REVERTING: 'REVERTING',
}
