import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import isEqual from 'lodash.isequal'

import Button from 'components/button'
import Table from 'components/table/beta'
import Input from 'components/input'
import { Dropdown } from 'components/dropdown'
import Filters from 'components/filters'
import { getTooltipList } from 'components/utils/tooltip'

import { useStore } from 'store'
import { deleteCalculatedMetric } from 'modules/calculated-metrics/actions'
import {
  resetFilterSort,
  saveFilter,
  saveSort,
} from 'modules/table-filter-sort/actions'
import { defaultFilterSortConfig } from 'modules/table-filter-sort/constants'

import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'
import Modal from 'components/modal'
import { renderOwnerCell } from 'components/utils/owner'
import { useAccess, PERMISSION_TYPES, PERMISSIONS } from 'hooks/access'
import { utils, entityStatus } from '@decision-sciences/qontrol-common'
import './styles.scss'

const { getCommonFields } = utils.object
const { ENTITY_STATUS_LABEL, ENTITY_STATUS_OPTIONS } = entityStatus

export const DUPLICATE_METRICS_KEY = 'lc-3q-duplicate-metrics'

const CalculatedMetrics = ({ list, dispatch, tableContainer, loading }) => {
  const navigate = useNavigate()
  const { state } = useStore()
  const filterSort = state.tableFilterSort.filterSort[tableContainer]
  const [itemToDelete, setItemToDelete] = useState(null)
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.CALCULATED_METRICS,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.CALCULATED_METRICS,
    type: PERMISSION_TYPES.CREATE,
  })
  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.CALCULATED_METRICS,
    type: PERMISSION_TYPES.DELETE,
  })
  const [metricsCreators, setCreators] = useState([])

  /** On component mount get list of all owners for all calculated metrics */
  useEffect(() => {
    const creators = []
    Object.values(list).forEach((metric) => {
      if (!creators.find((creator) => creator._id === metric.owner._id)) {
        creators.push(metric.owner)
      }
    })
    setCreators(creators)
  }, [])

  const sortChanged = (newSort) => {
    if (!isEqual(newSort, filterSort.sort)) {
      saveSort(dispatch, tableContainer, newSort)
    }
  }

  const columns = [
    {
      header: 'Name',
      accessorKey: 'name',
      size: 200,
      sortingFn: 'name',
    },
    {
      header: 'Owner',
      cell: renderOwnerCell,
      tooltip: (row) => {
        if (!row.owner.active) {
          return 'Inactive User'
        }
      },
      sortingFn: 'alphanumeric',
    },
    {
      header: 'Associated Templates',
      accessorFn: (row) => (row.templates ? row.templates.length : 0),
      tooltip: (row) => {
        if (!row.templates || !row.templates.length) {
          return null
        }
        return getTooltipList(
          'Associated Templates',
          row.templates.map((template) => template.name)
        )
      },
      sortingFn: 'alphanumeric',
    },
    {
      header: 'Associated Variables',
      accessorFn: (row) => (row.variables ? row.variables.length : 0),
      tooltip: (row) => {
        if (!row.variables || !row.variables.length) {
          return null
        }
        return getTooltipList(
          'Associated Variables',
          row.variables.map((variable) => variable.name)
        )
      },
      sortingFn: 'alphanumeric',
    },
    {
      header: 'Date Modified',
      id: 'updatedAt',
      accessorKey: 'updatedAt',
      cell: (cell) => {
        if (cell.row.original.updatedAt) {
          return format(new Date(cell.row.original.updatedAt), 'MM/dd/yyyy')
        }
      },
      sortingFn: 'date',
    },
    {
      header: 'Status',
      cell: (cell) => ENTITY_STATUS_LABEL[cell.row.original.active],
      accessorFn: (row) => (row.active ? 1 : 0),
      size: 80,
    },
  ]

  if (hasCreateAccess) {
    columns.unshift({
      header: '',
      id: 'duplicate',
      cell: (cell) => (
        <div className="table__actions">
          <div
            className="table__duplicate"
            onClick={() => {
              localStorage.setItem(
                DUPLICATE_METRICS_KEY,
                JSON.stringify(cell.row.original)
              )
              navigate('/calculated-metrics/new')
            }}
          />
        </div>
      ),
      maxSize: 40,
      textAlign: 'center',
    })
  }
  if (hasEditAccess) {
    columns.unshift({
      header: '',
      id: 'actions',
      cell: (cell) => (
        <div className="table__actions">
          <div
            className="table__edit"
            onClick={() =>
              navigate(`/calculated-metrics/${cell.row.original._id}`)
            }
          />
        </div>
      ),
      maxSize: 40,
      textAlign: 'center',
    })
  }

  if (hasDeleteAccess) {
    columns.push({
      header: '',
      id: 'delete',
      cell: (cell) => {
        const { _id, active } = cell.row.original

        if (active) {
          return null
        }

        return (
          <DeleteIcon
            className="table__delete fill-red"
            alt={'delete'}
            onClick={() => {
              setItemToDelete(_id)
            }}
          />
        )
      },
      maxSize: 40,
    })
  }
  if (!list) {
    return null
  }

  return (
    <div className="calculated-metrics page">
      {itemToDelete && (
        <Modal
          opened={!!itemToDelete}
          button={
            <Button
              value="Delete"
              onClick={() => {
                deleteCalculatedMetric(dispatch, itemToDelete)
                setItemToDelete(null)
              }}
            />
          }
          buttonSecondary={
            <Button
              value={'Cancel'}
              onClick={() => setItemToDelete(null)}
              secondaryGray
            />
          }
          heading={`Are you sure you want to delete "${
            Object.values(list).find((item) => item._id === itemToDelete).name
          }"?`}
          className="alert-categories__modal"
        />
      )}
      <Helmet>
        <title>Calculated Metrics</title>
      </Helmet>
      <div className="heading" data-cy="page-heading">
        Calculated Metrics
        {hasCreateAccess ? (
          <div className="heading__buttons">
            <Button
              value="Create Metric"
              onClick={() => navigate('/calculated-metrics/new')}
            />
          </div>
        ) : null}
      </div>
      <MetricFilters
        creators={metricsCreators}
        dispatch={dispatch}
        tableContainer={tableContainer}
        filters={filterSort.filter}
      />
      <Table
        columns={columns}
        data={Object.values(list)}
        tableContainer={tableContainer}
        initialState={{ sortBy: filterSort.sort }}
        onSortChanged={sortChanged}
        loading={loading}
      />
    </div>
  )
}

const MetricFilters = ({ creators, dispatch, tableContainer, filters }) => {
  const initialFilterState =
    defaultFilterSortConfig.filterSort[tableContainer].filter
  const [state, setState] = useState()

  useEffect(() => {
    setState(filters)
  }, [filters])

  const applyFilters = () => {
    const { name, owner, active } = state
    const query = {}
    if (name) {
      query.name = name
    }
    if (owner) {
      query.owner = owner
    }
    if (typeof active === 'boolean') {
      query.active = active
    }
    saveFilter(dispatch, tableContainer, query)
  }

  const clearFilters = () => {
    resetFilterSort(dispatch, tableContainer)
  }

  const [initial, current] = getCommonFields(initialFilterState, state)

  return (
    <Filters
      onApply={applyFilters}
      onClear={clearFilters}
      disableClearOnUnmount={true}
      initialApplied={!isEqual(initial, current)}
    >
      <Input
        onChange={(value) => setState({ ...state, name: value })}
        value={state?.name || ''}
        placeholder="Name"
      />

      <Dropdown
        defaultOptionText="Creator"
        options={
          creators &&
          creators.map((o) => ({
            label: o.firstName.concat(' ', o.lastName),
            value: o._id,
          }))
        }
        defaultState={
          creators && creators.length > 0 && state?.owner ? state.owner : ''
        }
        deselectLabel="Creator"
        onChange={(owner) => setState({ ...state, owner })}
      />
      <Dropdown
        defaultOptionText="Status"
        options={ENTITY_STATUS_OPTIONS}
        defaultState={state?.active === undefined ? '' : state.active}
        deselectLabel="Status"
        onChange={(active) => setState({ ...state, active })}
      />
    </Filters>
  )
}

MetricFilters.propTypes = {
  creators: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  tableContainer: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
}

CalculatedMetrics.propTypes = {
  list: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  tableContainer: PropTypes.string.isRequired,
  loading: PropTypes.bool,
}

export default CalculatedMetrics
