import React, { useState } from 'react'
import PropTypes from 'prop-types'
import RoundRectangleButton from 'components/round-rectangle-button'
import { useStore } from 'store'
import { exportAlertThresholdsCsv } from 'modules/companies/actions'
import { showErrorMessage } from 'modules/notifications/actions'
import { ReactComponent as DownloadIcon } from 'assets/icon_download.svg'

import '../../style.scss'

/**
 * Component which handles the CSV export of a clients Alert Thresholds
 * @param {Object} props Component props
 * @param {Object} props.clientId ObjectId of the client entity - must always be the parent company ID
 * @returns {React.FunctionComponent}
 */
const ExportAlertThresholdsButton = ({ clientId }) => {
  const { dispatch } = useStore()

  const [exportLoading, setExportLoading] = useState(false)

  const exportCsv = () => {
    setExportLoading(true)
    exportAlertThresholdsCsv(clientId)
      .catch(() =>
        showErrorMessage('Error exporting Alert Thresholds CSV', dispatch)
      )
      .finally(() => setExportLoading(false))
  }

  return (
    <RoundRectangleButton
      data-cy="alerts-section-export"
      onClick={exportCsv}
      disabled={exportLoading}
      className='round-rectangle-button__white'
      contentRender={() => <DownloadIcon />}
    />
  )
}

ExportAlertThresholdsButton.propTypes = {
  clientId: PropTypes.string.isRequired,
}

export default ExportAlertThresholdsButton
