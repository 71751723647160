import Api from 'easy-fetch-api'
import { editLocks } from '@decision-sciences/qontrol-common'

const { EDIT_LOCK_ENTITIES_DISPLAY } = editLocks
export const ACTIONS = {
  GET_LOCKS_FOR_TYPE: 'editLock.getForType',
  ADD_LOCK_FOR_ENTITY: 'editLock.addLock',
  REMOVE_LOCK_FOR_ENTITY: 'editLock.removeLock',
}

/**
 * Fetch all locks for an entity type and store it to the global state
 * @param {Function} dispatch dispatch
 * @param {String} entityType entity type to get locks for
 */
export const getForType = (dispatch, entityType) =>
  Api.get({ url: '/api/edit-lock', query: { entityType } }).then((result) => {
    if (!result || !result.success) {
      return console.error(
        (result && result.error) || `Error getting locks for ${entityType}`
      )
    }

    dispatch({
      type: ACTIONS.GET_LOCKS_FOR_TYPE,
      entityType,
      locks: result.result.reduce(
        (acc, { _id, editLock: { user } }) => ({
          ...acc,
          [_id]: editLockObject(entityType, user),
        }),
        {}
      ),
    })
  })

/**
 * Lock entity and store it in global state
 * @param {Function} dispatch dispatch
 * @param {String} entityId id fo entity to lock
 * @param {String} entityType type of entity to lock
 */
export const lockEntity = (dispatch, entityId, entityType) =>
  new Promise(async (resolve, reject) => {
    const result = await Api.post({
      url: '/api/edit-lock/lock',
      data: {
        entityId,
        entityType,
      },
    }).catch((err) => {
      console.error(err)
      return reject(err)
    })

    if (!result || !result.success) {
      console.error(
        (result && result.error) ||
          `Error settings locks for ${entityType} ${entityId}`
      )
      return reject(
        (result && result.error) ||
          `Error settings locks for ${entityType} ${entityId}`
      )
    }

    dispatchLock(
      dispatch,
      entityType,
      entityId,
      editLockObject(entityType, result.user)
    )

    resolve()
  })

/**
 * Unlock entity and remove is from the global state
 * @param {Function} dispatch dispatch
 * @param {String} entityId id fo entity to unlock
 * @param {String} entityType type of entity to unlock
 */
export const unLockEntity = (dispatch, entityId, entityType) =>
  new Promise(async (resolve) => {
    const result = await Api.post({
      url: '/api/edit-lock/unlock',
      data: {
        entityId,
        entityType,
      },
    }).catch((err) => {
      console.error(err)
      return resolve()
    })

    if (!result || !result.success) {
      console.error(
        (result && result.error) ||
          `Error releasing locks for ${entityType} ${entityId}`
      )
      return resolve()
    }

    dispatchUnLock(dispatch, entityType, entityId)

    resolve()
  })

/**
 * Add a locked entity to the store
 * @param {Function} dispatch dispatch
 * @param {String} entityType entity type
 * @param {String} entityId entity id
 * @param {Object} object object to add to locked entity store
 */
export const dispatchLock = (dispatch, entityType, entityId, object) =>
  dispatch({
    type: ACTIONS.ADD_LOCK_FOR_ENTITY,
    entityType,
    entityId,
    object,
  })

/**
 * Remove a locked entity from the store
 * @param {Function} dispatch dispatch
 * @param {String} entityType entity type
 * @param {String} entityId entity id
 */
export const dispatchUnLock = (dispatch, entityType, entityId) =>
  dispatch({
    type: ACTIONS.REMOVE_LOCK_FOR_ENTITY,
    entityType,
    entityId,
  })

/**
 * Builds object to add to edit lock store for an entity
 * @param {String} entityType entity type
 * @param {Object} user user data
 * @returns {Object} object to add to store
 */
export const editLockObject = (entityType, user) => ({
  tooltipHeader: `${EDIT_LOCK_ENTITIES_DISPLAY[entityType]} Locked`,
  tooltipText: `Currently being edited by: ${user?.firstName} ${user?.lastName}`,
  user: user?._id,
})

/**
 * Notify server that the user is still on entity page
 * @param {String} entityType entity type
 * @param {String} entityId entity id
 */
export const pingServer = (entityType, entityId) =>
  new Promise(async (resolve, reject) => {
    const result = await Api.post({
      url: '/api/edit-lock/ping',
      data: {
        entityId,
        entityType,
      },
    }).catch((err) => {
      console.error(err)
      reject(err)
    })

    if (!result || !result.success) {
      console.error(
        (result && result.error) ||
          `Error pingin server for ${entityType} ${entityId}`
      )
      reject(
        (result && result.error) ||
          `Error pingin server for ${entityType} ${entityId}`
      )
    }

    resolve()
  })

