import React, { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { useTooltip } from 'components/tooltip'

import iconPlayGrey from 'assets/icon_play_grey.svg'
import { ReactComponent as WarningIcon } from 'assets/icon_warning.svg'
import { ReactComponent as WarningIconRed } from 'assets/icon_warning_round_red.svg'

import './style.scss'

/**
 * Item row generic component
 *
 * @param options
 * @param {Object} options.item "value" attribute is required
 * @param {Function} [options.onAdd] - optional onAdd callback - if set it will add the Add Option
 * @param {Function} options.onEdit - on Edit callback. Returns entire "item" object clicked
 * @param {Function} [options.onRemove] - optional on Remove callback. Returns entire "item" object clicked
 * @param {Function} [options.onCancel] - optional cancel callback - used to cancel edit mode
 * @param {Function} [options.onPreview] - optional open preview callback
 * @param {Function} [options.previewDisabled] - flag to indicate that preview is disabled
 * @param {number} [options.progressBar] - optional progress bar value (represents the completed percentage to be displayed)
 * @param {Object} [options.tooltips] - a map with the tooltip texts for all the actions
 * @param {String|Node} [options.warning] - Warning to show
 * @param {Boolean} [options.fakeDisabled] - optional that disables the item row, but instead of hiding the preffix button, it makes them opaque
 * @param {Boolean} [options.error] - Boolean to indicate that the warning icon should be the round red rather than triangle yellow icon
 * @param {Boolean} [options.hasError] - Boolean to indicate that the item row should be the with border red
 * @param {Boolean} [options.showRemoveOnEditing] - Boolean to show the remove button as opaque while editing
 * @param {Function} [options.onRevert] - Callback for a Revert Button
 * @param {Bolean} [options.dark] - Boolean to indicate styling for component should be in dark mode
 */
const ItemRow = ({
  item,
  onAdd,
  onEdit,
  onRemove,
  onCancel,
  onPreview,
  progressBar,
  tooltips,
  disabled,
  editDisabled,
  addDisabled,
  removeDisabled,
  showRemoveOnEditing,
  previewDisabled,
  fakeDisabled,
  hasError,
  warning,
  error,
  edited,
  onPin,
  pinIcon,
  onRevert,
  hasPreffixIcon,
  preffixIcon,
  dark = false,
}) => {
  const itemThumbnail = useMemo(() => {
    if (!item) {
      return undefined
    }
    const { thumbnail, remote } = item
    return thumbnail ? thumbnail : remote?.facebook?.thumbnail
  }, [item])

  const [showWarning, hideWarning, WarningTooltip] = useTooltip(warning)
  const [
    showEditDisabledReason,
    hideEditDisabledReason,
    EditDisabledReasonTooltip,
  ] = useTooltip(editDisabled)
  const [
    showRemoveDisabledReason,
    hideRemoveDisabledReason,
    RemoveDisabledReasonTooltip,
  ] = useTooltip(removeDisabled)

  const [showPreviewDisabled, hideInfoPreviewDisabled, PreviewDisableTooltip] =
    useTooltip(tooltips.previewDisabled)
  const pinRef = useRef()

  return (
    <div
      className={cx('item-row', {
        'has-progress-bar': progressBar,
        'item-row--edited': edited,
        'item-row--fake-disabled': fakeDisabled,
        'item-row--error': hasError,
        'item-row--dark': dark,
      })}
    >
      {itemThumbnail ? (
        <div className="item-row__info item-row__info--thumbnail">
          <img src={itemThumbnail} alt="thumbnail" />
          {item.video ? (
            <img src={iconPlayGrey} alt="play-icon" className="video-icon" />
          ) : null}
        </div>
      ) : null}
      {hasPreffixIcon ? (
        <div className="item-row__info item-row__info--preffix">
          {preffixIcon}
        </div>
      ) : null}
      <div className="item-row__details">
        <div className="item-row__details__copy">
          <div className="item-row__details__text">{item.value} </div>
          <div className="item-row__details__description">
            {item.description}
          </div>
        </div>
      </div>

      {progressBar ? (
        <div
          className="item-row__progress-bar"
          data-testid="item-row__progress-bar"
        >
          <div className="item-row__progress-bar__bar">
            <div
              style={{
                width: `${progressBar}%`,
                borderRadius: progressBar > 98 ? '5px' : '',
              }}
              className="item-row__progress-bar__bar__completed-bar"
            />
          </div>
        </div>
      ) : (
        ''
      )}

      {onRevert && !disabled ? (
        <div
          className="item-row__action item-row__action--revert"
          title={tooltips.revert}
          onClick={() => onRevert(item)}
        />
      ) : null}

      {onPreview && !disabled ? (
        <>
          <div
            className={cx('item-row__action  item-row__action--preview', {
              'item-row__action--disabled': previewDisabled,
            })}
            data-testid="preview"
            title={!previewDisabled ? tooltips.preview : null}
            onClick={() => !previewDisabled && onPreview(item)}
            onMouseEnter={showPreviewDisabled}
            onMouseLeave={hideInfoPreviewDisabled}
          />
          <PreviewDisableTooltip />
        </>
      ) : null}

      {onPin && (
        <div
          className={`item-row__action item-row__action--pin`}
          ref={pinRef}
          data-testid="pin-button"
          onClick={() => onPin(pinRef, item)}
        >
          <img src={pinIcon} alt="pin-icon" />
          <div className="item-row__action--pin--text">
            {item.pinned_field > 0 ? item.pinned_field : `PIN`}
          </div>
        </div>
      )}

      {onEdit && !disabled ? (
        <>
          {typeof editDisabled === 'string' && <EditDisabledReasonTooltip />}
          <div
            className={cx('item-row__action', {
              'item-row__action--cancel': onCancel,
              'item-row__action--edit': !onCancel,
              'item-row__action--disabled': editDisabled,
            })}
            title={tooltips.edit}
            onMouseEnter={() => {
              showEditDisabledReason()
            }}
            onMouseLeave={() => {
              hideEditDisabledReason()
            }}
            onClick={() =>
              fakeDisabled || editDisabled
                ? null
                : onCancel
                ? onCancel()
                : onEdit(item)
            }
          />
        </>
      ) : null}

      {onAdd && !disabled ? (
        <div
          className={cx('item-row__action item-row__action--add', {
            'item-row__action--disabled': addDisabled,
          })}
          title={tooltips.add}
          onClick={() => (!addDisabled ? onAdd(item) : null)}
        />
      ) : null}

      {onRemove && !disabled ? (
        <>
          {typeof removeDisabled === 'string' && (
            <RemoveDisabledReasonTooltip />
          )}

          <div
            className={cx(`item-row__action`, {
              'item-row__action--delete': showRemoveOnEditing,
              'item-row__action--delete-opaque': !showRemoveOnEditing,
              'item-row__action--disabled': removeDisabled,
              'item-row__action--error': hasError,
            })}
            onMouseEnter={showRemoveDisabledReason}
            onMouseLeave={hideRemoveDisabledReason}
            title={tooltips.remove}
            onClick={() =>
              showRemoveOnEditing && !fakeDisabled && !removeDisabled
                ? onRemove(item)
                : null
            }
          />
        </>
      ) : null}

      {warning ? (
        <div
          onMouseEnter={() => showWarning()}
          onMouseLeave={() => hideWarning()}
          className="align-center item-row__action"
        >
          {error ? <WarningIconRed /> : <WarningIcon />}
        </div>
      ) : null}

      <WarningTooltip />
    </div>
  )
}

ItemRow.propTypes = {
  item: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    thumbnail: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    remote: PropTypes.object,
    video: PropTypes.bool,
    description: PropTypes.string, // A secondary info text that appears under the main item text
    pinned_field: PropTypes.number,
  }).isRequired,
  progressBar: PropTypes.number,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onCancel: PropTypes.func,
  onPreview: PropTypes.func,
  tooltips: PropTypes.shape({
    edit: PropTypes.string,
    preview: PropTypes.string,
    previewDisabled: PropTypes.string,
    remove: PropTypes.string,
    add: PropTypes.string,
    revert: PropTypes.string,
  }),
  previewDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  editDisabled: PropTypes.bool,
  addDisabled: PropTypes.bool,
  removeDisabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  pinIcon: PropTypes.string,
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.bool,
  edited: PropTypes.bool,
  showRemoveOnEditing: PropTypes.bool,
  fakeDisabled: PropTypes.bool,
  hasError: PropTypes.bool,
  onPin: PropTypes.func,
  onRevert: PropTypes.func,
  hasPreffixIcon: PropTypes.bool,
  preffixIcon: PropTypes.node,
  dark: PropTypes.bool,
}

ItemRow.defaultProps = {
  tooltips: {
    edit: '',
    preview: '',
    remove: '',
    add: '',
    fakeDisabled: false,
  },
  showRemoveOnEditing: true,
}

export default ItemRow
