import React, { useEffect, useState } from 'react'
import { useStore } from 'store'
import { checkVerification } from 'modules/microsoft-config/actions'

const Account = () => {
  const { state, dispatch } = useStore()
  const [verified, setVerified] = useState(false)
  const [verificationURL, setVerificationURL] = useState('')
  const { companies: organizations } = state.companies

  const { user } = state.microsoft

  const renderUsers = () => {
    return user &&
      user.Accounts &&
      Array.isArray(user.Accounts.AdvertiserAccount) ? (
      Object.values(user.Accounts.AdvertiserAccount).map((data, index) => {
        return (
          <div key={index} className="campaign">
            {`Account Name: ${data.Name} - Account ID: ${data.Id}`}
          </div>
        )
      })
    ) : user && user.Accounts && user.Accounts.AdvertiserAccount ? (
      <div key={0} className="campaign">
        {`Account Name: ${user.Accounts.AdvertiserAccount.Name} - Account ID: ${user.Accounts.AdvertiserAccount.Id}`}
      </div>
    ) : (
      ''
    )
  }
  useEffect(() => {
    if (!verified && !verificationURL) {
      checkVerification(dispatch)
        .then(() => {
          setVerified(true)
        })
        .catch((data) => {
          if (data.url) {
            setVerificationURL(data.url)
            // window.location.href = data.url
          }
        })
    }
  }, [organizations, verificationURL, verified])
  return (
    <section>
      <h2>Bing Account Settings</h2>
      <h3>
        {/* User id: {selectedOrg.google.customer_account_id}{' '}*/}
        {verified ? '(Verified)' : '(Not Verified)'}
      </h3>
      {!verified && verificationURL && <a href={verificationURL}>Verify</a>}
      {user && !user.empty ? renderUsers() : null}
    </section>
  )
}

export default Account
