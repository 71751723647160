export const USER_DEFAULT = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  alternatePhone: '',
  timeZone: '',
  jobTitle: '',
  isSuperAdmin: false,
  clients: [],
  teams: [],
}
