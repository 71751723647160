import React, { useState, useImperativeHandle, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Arrow from 'components/arrow'

import { getMainElement } from 'components/utils/dom-manipulation'

import ScssConstants from 'styles/shared.module.scss'

import './style.scss'

/**
 * Displays a section with a header and a collapse button to hide the content
 * @param {Object} params React Params
 * @param {Node | String} params.header Header to display
 * @param {Boolean} [params.defaultCollapsed] Whether the component starts off as collapsed or not
 * @param {String} [params.className] Additional className(s) for section body
 * @param {Node} [params.extras] To display next to collapse chevron
 */
const CollapsibleSection = React.forwardRef(
  (
    {
      defaultCollapsed = true,
      header,
      children,
      className,
      wrapperClassName = '',
      extras,
      uncollapsible,
      onCollapseListener,
      small,
      id,
    },
    ref
  ) => {
    const self = useRef()
    const [isOpen, setIsOpen] = useState(
      uncollapsible ? true : !defaultCollapsed
    )

    useImperativeHandle(ref, () => ({
      isSectionOpen() {
        return isOpen
      },

      onCollapse(bool) {
        setIsOpen(!bool)
      },

      focus() {
        setIsOpen(true)

        setTimeout(() => {
          const main = getMainElement()
          main &&
            main.scrollTo({
              top:
                main.scrollTop +
                self.current.getBoundingClientRect().y -
                parseInt(ScssConstants.headerHeight),
              behavior: 'smooth',
            })
        })
      },
    }))

    const onArrowClicked = () =>
      setIsOpen((current) => {
        if (onCollapseListener) {
          onCollapseListener(!current)
        }
        return !current
      })

    return (
      <section
        ref={self}
        id={id}
        data-cy={id}
        data-testid="collapsible-section"
        className={cx('collapsible-section', wrapperClassName, {
          'collapsible-section--open': isOpen,
          'collapsible-section--small': small,
          'collapsible-section--has-extras': extras,
        })}
      >
        <div
          className={cx('collapsible-section__body', {
            [className]: className,
          })}
        >
          {children}
        </div>
        <div className="collapsible-section__heading" data-testid="arrow">
          <h3>{header}</h3>
          {extras && (
            <div className="collapsible-section__extras">{extras}</div>
          )}
          {!uncollapsible && (
            <Arrow expanded={isOpen} onChange={onArrowClicked} />
          )}
        </div>
      </section>
    )
  }
)

CollapsibleSection.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  defaultCollapsed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  extras: PropTypes.node,
  uncollapsible: PropTypes.bool,
  onCollapseListener: PropTypes.func,
  small: PropTypes.bool,
  id: PropTypes.string,
}

CollapsibleSection.defaultProps = {
  defaultCollapsed: true,
}

export default CollapsibleSection
