import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'platform'

/** Initial state of the reducer */
const initialState = {
  changeHistory: { filters: {}, data: [], filtersData: {}, count: 0 },
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.SET_HISTORY]: (state, { history }) => {
    return {
      ...state,
      changeHistory: { ...state.changeHistory, data: history },
    }
  },
  [ACTIONS.SET_HISTORY_FILTER_DATA]: (state, { filter_data }) => {
    return {
      ...state,
      changeHistory: { ...state.changeHistory, filtersData: filter_data },
    }
  },
  [ACTIONS.SET_HISTORY_FILTER]: (state, { filters }) => {
    return {
      ...state,
      changeHistory: { ...state.changeHistory, filters },
    }
  },
  [ACTIONS.SET_HISTORY_COUNT]: (state, { count }) => {
    return {
      ...state,
      changeHistory: { ...state.changeHistory, count },
    }
  },
}

export default { name, initialState, reduce }
