import React, { useCallback, useState } from 'react'
import debounce from 'lodash.debounce'

import Tooltip from 'components/tooltip'

/**
 * Generate general styling for cells and headers
 * @param {Object} element cell or header
 * @param {Object} defaultStyles Pre-applied styles
 * @returns {Object}
 */
export const generateElementStyling = (element, defaultStyles = {}) => {
  const styles = structuredClone(defaultStyles)

  const { table } = element.getContext()

  const sizes = {
    flexBasis: element.column.columnDef.size,
    minWidth: element.column.columnDef.minSize,
    maxWidth: element.column.columnDef.maxSize,
  }

  if (element.column.columnDef.hide) {
    sizes.display = 'none'
  }

  const alignments = {}

  if (element.column.columnDef.textAlign) {
    const alignmentMap = {
      left: 'flex-start',
      center: 'center',
      right: 'flex-end',
    }
    alignments.justifyContent = alignmentMap[element.column.columnDef.textAlign]
  }

  if (table.options.rowHeight) {
    sizes.height = table.options.rowHeight
  }

  return Object.assign(styles, sizes, alignments)
}

/**
 * Renders a sort icon, based on sortedStatus
 * @param {'asc'|'desc'} sortedStatus Sorted status
 * @returns {Node}
 */
export const renderSortIcon = (sortedStatus) => {
  if (!sortedStatus) {
    return null
  }

  return (
    <span className="table__sort-icon">
      {sortedStatus === 'desc' ? '▼' : '▲'}
    </span>
  )
}

/**
 * Custom hook especially for displaying tooltips in a table
 * @returns {Array} [<Tooltip />, tooltip content setter]
 * Setting it to a falsy value hides the tooltip.
 */
export const useTableTooltip = () => {
  const [state, setState] = useState(null)

  // Setting the tooltip has a debounce so that we don't needlessly re-update the state every time
  const setTooltip = debounce(function (content) {
    setState(content)
  }, 50)

  const tooltip = useCallback(
    () => <Tooltip content={state} show={Boolean(state)} />,
    [state]
  )

  return [tooltip, setTooltip]
}

/**
 * Render empty content, for when there is no data in the table
 * @param {Boolean} loading Whether the table data is loading
 * @returns {Node}
 */
export const renderEmptyContent = (loading, emptyText) => {
  return (
    <div className="table__row table__row--no-data">
      {loading ? null : emptyText ?? 'No Data'}
    </div>
  )
}
