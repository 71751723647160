import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Dropdown } from 'components/dropdown/index'

import { getGAPropertyIds } from './actions'

/**
 * Amazon Property Ids component
 * @param {Object} params React Params
 * @param {String} params.value Selected GA4 Property ID
 * @param {Function} params.onChange Callback to change Property ID
 * @param {String} params.companyId _id of the current client
 * @param {String} params.accountId external account id to retrieve for
 */
export const AmazonPropertyIds = ({
  value,
  onChange,
  companyId,
  accountId,
}) => {
  const [properties, setProperties] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!properties) {
      setLoading(true)
      getGAPropertyIds(companyId, accountId)
        .then(setProperties)
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [])

  const mapProperty = ({ name, displayName }) => ({
    value: name,
    label: displayName,
    description: name,
  })

  return (
    <Dropdown
      disabled={!properties?.length}
      onChange={onChange}
      defaultState={value}
      labelTop
      label="Property ID"
      defaultOptionText="GA4 Property ID"
      options={(properties || []).map(mapProperty)}
      loading={loading}
    />
  )
}

AmazonPropertyIds.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
}
