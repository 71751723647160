import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'
import {
  expressionParser,
  metrics,
  variables,
} from '@decision-sciences/qontrol-common'
import Tooltip from 'components/tooltip'
import { format, startOfDay, endOfDay } from 'date-fns'
import {
  FEATURE_NOT_IMPLEMENTED_TOOLTIP,
  PRIMARY_COMPARISON_PERIOD_TOOLTIP,
} from 'components/utils/tooltip'
import { Dropdown } from 'components/dropdown'
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from 'components/utils/date'

const { extractVariables } = expressionParser
const { getAllMetrics, METRICS_SETTINGS } = metrics
const { getVariableStartEndDates } = variables

export const PrimaryComparisonPeriodSection = ({
  state = {},
  onChange,
  errors,
  hasEditAccess,
  variables,
  calculatedMetrics = [],
  displayIcon = false,
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const { currentMetric, compareMetric } = state.comparison || {}
  const metricsUsed = state.expression ? extractVariables(state.expression) : {}
  const { isActualResult, comparisonMetricDates } = state

  const getOptions = (excludeMetric) =>
    Object.keys(metricsUsed)
      ?.filter(
        (metric) =>
          metric !== excludeMetric &&
          !metric.includes('_threshold') &&
          !METRICS_SETTINGS[metric]
      )
      .map((metric) => {
        const isCalculatedMetric = calculatedMetrics.some(
          (calculatedMetric) => calculatedMetric.key === metric
        )
        return {
          value: metric,
          label: `(${metric})`,
          tooltip: isCalculatedMetric
            ? FEATURE_NOT_IMPLEMENTED_TOOLTIP
            : undefined,
          isDisabled: isCalculatedMetric,
        }
      })

  const getVariableDetails = (key) => {
    const foundVariable = variables?.find((item) => item.key === key)
    const isVariable = !!foundVariable
    const nonVariableMetrics = getAllMetrics({})
    const variable =
      foundVariable || nonVariableMetrics.find((item) => item === key)

    if (!variable) {
      return null
    }
    let startDate
    let endDate
    if (isActualResult) {
      startDate = comparisonMetricDates?.[key]?.startDate
      endDate = comparisonMetricDates?.[key]?.endDate
      // Convert the strings to Dates
      if (startDate) {
        startDate = new Date(startDate)
      }
      if (endDate) {
        endDate = new Date(endDate)
      }
    } else {
      if (isVariable) {
        const dates = getVariableStartEndDates({
          ...variable,
          startAmount: variable?.startAmount || null,
        })
        startDate = dates.startDate
        endDate = dates.endDate
      } else {
        startDate = startOfDay(new Date())
        endDate = endOfDay(new Date())
      }
    }

    return {
      metric: isVariable ? variable.metric : variable,
      timespan: isVariable
        ? `${variable.recurrenceAmount} ${variable.recurrenceType}`
        : 'Current Day',
      startReferenceTime: variable.startAmount
        ? `${variable.startAmount} ${variable.startType} prior`
        : 'At runtime specified in alert',
      startDate,
      endDate,
    }
  }

  return (
    <section className="form__section">
      <div className="form__section__header">
        Primary Comparison Period
        {displayIcon && (
          <InfoIcon
            className="calculated-metrics__info fill-light-blue"
            alt={'info'}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          />
        )}
        <Tooltip
          content={PRIMARY_COMPARISON_PERIOD_TOOLTIP}
          show={showTooltip}
        />
      </div>
      <div className="form__row">
        <ComparisonBlock
          label="Current"
          currentValue={currentMetric}
          disabled={!hasEditAccess || !state.expressionValid}
          error={!!errors?.current}
          onChange={(value) => onChange('currentMetric', value)}
          options={getOptions(compareMetric)}
          variable={getVariableDetails(currentMetric)}
          isActualResult={isActualResult}
        />
        <ComparisonBlock
          label="Compare"
          currentValue={compareMetric}
          disabled={!hasEditAccess}
          error={!!errors?.compare}
          onChange={(value) => onChange('compareMetric', value)}
          options={getOptions(currentMetric)}
          variable={getVariableDetails(compareMetric)}
          isActualResult={isActualResult}
        />
      </div>
    </section>
  )
}

PrimaryComparisonPeriodSection.propTypes = {
  state: PropTypes.shape({
    comparison: PropTypes.shape({
      currentMetric: PropTypes.string,
      compareMetric: PropTypes.string,
    }),
    expression: PropTypes.string,
    isActualResult: PropTypes.bool, // true if on the AlertTrigger page, false otherwise
    comparisonMetricDates: PropTypes.object, // Populated with real AlertTrigger data if on the AlertTrigger page, null otherwise
  }),
  errors: PropTypes.object,
  hasEditAccess: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  variables: PropTypes.array,
  calculatedMetrics: PropTypes.array,
  displayIcon: PropTypes.bool,
}

const ComparisonBlock = ({
  label,
  currentValue,
  disabled,
  error,
  onChange,
  options,
  variable, // { metric: String, timespan: String, startReferenceTime: String, startDate: Date, endDate: Date }
  isActualResult,
}) => {
  return (
    <div className="form__half form__half--small-gap">
      <div className="form__section__body">
        <Dropdown
          className="input-wrapper--uppercase drop-down--with-error-block"
          defaultOptionText="Select Option"
          defaultState={currentValue}
          disabled={disabled}
          hasSearch={true}
          displaySearchInOptions={true}
          label={label}
          onChange={onChange}
          options={options}
          error={error ? 'Variable is missing from the formula' : null}
        />
        {currentValue && variable ? (
          <div>
            <div className="input-wrapper--uppercase">
              <p className="label-wrapper">Metric</p>
              <p>{variable.metric}</p>
            </div>
            <div className="input-wrapper--uppercase">
              <p className="label-wrapper">
                Collect data for the following timespan
              </p>
              <p>{variable.timespan}</p>
            </div>
            <div className="input-wrapper--uppercase">
              <p className="label-wrapper">Start Reference Time</p>
              <p>{variable.startReferenceTime}</p>
            </div>
            {_renderVariableDateInfo(variable, isActualResult)}
          </div>
        ) : null}
      </div>
    </div>
  )
}

const _renderVariableDateInfo = (variable, isActualResult) => {
  const { startDate, endDate } = variable
  const dateFormat = `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`

  return (
    <div className="form__row input-wrapper--uppercase">
      <div className="form__half">
        <p className="label-wrapper">
          {isActualResult ? 'Actual' : 'Target'} Period Start
        </p>
        <p>{startDate ? format(startDate, dateFormat) : '-'}</p>
      </div>
      <div className="form__half">
        <p className="label-wrapper">
          {isActualResult ? 'Actual' : 'Target'} Period end
        </p>
        <p>{endDate ? format(endDate, dateFormat) : '-'}</p>
      </div>
    </div>
  )
}

ComparisonBlock.propTypes = {
  label: PropTypes.string.isRequired,
  currentValue: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  variable: PropTypes.object,
  isActualResult: PropTypes.bool,
}
