import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ReactComponent as EditIcon } from 'assets/icon_edit.svg'
import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'
import { ReactComponent as CheckBlueIcon } from 'assets/icon_check_blue_filled.svg'

import ConfirmationModal from 'components/confirmation-modal'
import Table from 'components/table/beta'

import ModifiedAtCell from './cells'
import { PUBLISHERS } from '../../constants'
import { RuleTooltip } from './rule-tooltip'

/**
 * Renders the campaign exclusions table
 * @param {Object} props
 * @param {Boolean} props.isViewMode
 * @param {Array} props.exclusions - Campaign exclusions
 * @param {Function} props.onDeleteClicked - Method to be called when the delete button is clicked
 * @param {Function} props.onEditClicked -  Method to be called when the edit button is clicked
 * @param {Boolean} props.disabledActions -  A flag that tells us when to disable table's actions
 */
const CampaignExclusionsTable = ({
  isViewMode,
  exclusions,
  onDeleteClicked,
  onEditClicked,
  disabledActions,
}) => {
  const [exclusionIdToBeDeleted, setExclusionIdToBeDeleted] = useState('')
  const publishers = PUBLISHERS

  const columns = [
    {
      header: '',
      accessorKey: 'edit-rule',
      minSize: 40,
      maxSize: 70,
      textAlign: 'center',
      cellTextAlign: 'center',
      // eslint-disable-next-line react/prop-types
      cell: (cell) => {
        if (isViewMode) {
          return null
        }
        return (
          <EditIcon
            disabled={disabledActions}
            className={cx('cursor--pointer', {
              'disabled-exclusion-action': disabledActions,
            })}
            onClick={() => !disabledActions && onEditClicked(cell.row.original)}
          />
        )
      },
    },
    {
      header: 'Name',
      accessorKey: 'name',
      textAlign: 'left',
      cellTextAlign: 'left',
      sortingFn: 'name',
      size: 500,
    },
    {
      header: 'Rule',
      accessorKey: 'info-rule',
      textAlign: 'center',
      cellTextAlign: 'center',
      size: 40,
      maxSize: 80,
      cell: () => <InfoIcon className="fill-light-blue cursor--pointer" />,
      tooltip: (exclusion) => (
        <RuleTooltip exclusion={exclusion} publishers={publishers} />
      ),
    },
    {
      header: 'Reporting',
      textAlign: 'center',
      cellTextAlign: 'center',
      cell: (cell) =>
        cell.row.original.excludeFromReporting ? <CheckBlueIcon /> : '',
      size: 40,
      maxSize: 100,
    },
    {
      header: 'Alerts',
      textAlign: 'center',
      cellTextAlign: 'center',
      cell: (cell) =>
        cell.row.original.excludeFromAlerts ? <CheckBlueIcon /> : '',
      size: 40,
      maxSize: 100,
    },
    {
      header: 'Modified',
      accessorKey: 'updatedAt',
      size: 80,
      maxSize: 160,
      cell: ModifiedAtCell,
    },
    {
      header: '',
      accessorKey: 'delete-rule',
      textAlign: 'center',
      cellTextAlign: 'center',
      size: 40,
      maxSize: 80,
      // eslint-disable-next-line react/prop-types
      cell: (cell) => {
        if (isViewMode) {
          return null
        }
        return (
          <DeleteIcon
            disabled={disabledActions}
            onClick={() =>
              !disabledActions &&
              setExclusionIdToBeDeleted(cell.row.original._id)
            }
            className={cx('fill-red  cursor--pointer', {
              'disabled-exclusion-action': disabledActions,
            })}
          />
        )
      },
    },
  ]

  return (
    <>
      <Table
        id="campaign-exclusions-table"
        columns={columns}
        data={exclusions}
      />

      <ConfirmationModal
        heading="Remove Exclusion"
        message="You are about to remove an exclusion. Confirm if you want to continue
            with this action. This action cannot be undone."
        showModal={Boolean(exclusionIdToBeDeleted)}
        onCancelClicked={() => setExclusionIdToBeDeleted('')}
        onConfirmClicked={() => {
          onDeleteClicked(exclusionIdToBeDeleted)
          setExclusionIdToBeDeleted('')
        }}
      />
    </>
  )
}

export default CampaignExclusionsTable

CampaignExclusionsTable.propTypes = {
  isViewMode: PropTypes.bool.isRequired,
  exclusions: PropTypes.array.isRequired,
  onDeleteClicked: PropTypes.func.isRequired,
  onEditClicked: PropTypes.func.isRequired,
  disabledActions: PropTypes.bool.isRequired,
}
